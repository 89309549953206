import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, themedValue } from '../../__styling'
import {
    StyledTimelineCardContainer,
    StyledTimelineCardFirstname,
    StyledTimelineCardLastname,
    StyledTimelineCardScoreType,
} from '../TimelineCard/TimelineCard.styled'

export const StyledTimelineFeedContainer = styled('div')(({ theme }) => ({
    scrollbarColor: themedValue(theme, {
        sevennews: `${'#C97F87'} ${'#CCCCCC'}`,
        thewest: `${'##74787A'} ${'##D9D9D9'}`,
        perthnow: `${'##777777'} ${'##D9D9D9'}`,
        // Todo - Update for theNightly
        fallback: `${'##74787A'} ${'##D9D9D9'}`,
    }),
    height: calcRem(1080),
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: themedValue(theme, {
        perthnow: calcRem(40, 0, 32, 0),
        thewest: calcRem(40, 0),
        fallback: 0,
    }),

    [breakpoint('sm')]: {
        height: calcRem(700),
    },

    '&::-webkit-scrollbar': {
        width: calcRem(5),
    },
    '&::-webkit-scrollbar-track': {
        width: calcRem(5),
        backgroundColor: themedValue(theme, {
            sevennews: '#CCCCCC',
            perthnow: '#D9D9D9',
            thewest: '#D9D9D9',
            // Todo - Update for theNightly
            fallback: '#CCCCCC',
        }),
    },
    '&::-webkit-scrollbar-thumb': {
        width: calcRem(5),
        backgroundColor: themedValue(theme, {
            sevennews: '#C97F87',
            perthnow: '#777777',
            thewest: '#74787A',
            // Todo - Update for theNightly
            fallback: '#74787A',
        }),
    },
}))

export const StyledFeedQuarterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    margin: `${calcRem(40)} 0`,
    [breakpoint('sm')]: {
        justifyContent: 'flex-end',
    },
})

// timeline vertical line
export const StyledFeedInnerContainer = styled('div')({
    position: 'relative',
    left: calcRem(20),

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: `calc(50% - ${calcRem(179)})`,
        height: `100%`,
        width: calcRem(1),
        background: '#33333380',
        [breakpoint('sm')]: {
            width: calcRem(2),
            left: `calc(50% - ${calcRem(226)})`,
        },
    },

    'div:first-of-type': {
        [`${StyledFeedQuarterContainer}`]: {
            marginTop: 0,
        },
    },
})

export const StyledFeedCardContainer = styled('div')({
    margin: `${calcRem(22)} auto`,
    width: 'max-content',
    position: 'relative',

    // card overrides
    [`${StyledTimelineCardContainer}`]: {
        padding: calcRem(12),
        width: calcRem(310),
        [breakpoint('sm')]: {
            padding: `${calcRem(5)} ${calcRem(10)}`,
            width: calcRem(404),
        },
    },
    [`${StyledTimelineCardScoreType}`]: {
        fontSize: calcRem(19),
        lineHeight: calcRem(18),
        [breakpoint('sm')]: {
            fontSize: calcRem(24),
            lineHeight: calcRem(30),
        },
    },
    [`${StyledTimelineCardFirstname}`]: {
        fontSize: calcRem(14),
        [breakpoint('sm')]: {
            fontSize: calcRem(18),
        },
    },
    [`${StyledTimelineCardLastname}`]: {
        fontSize: calcRem(14),
        [breakpoint('sm')]: {
            fontSize: calcRem(18),
        },
    },
})

export const StyledFeedQuarterIndicator = styled('div')(({ theme }) => ({
    width: 'max-content',
    position: 'relative',

    left: `calc(50% - ${calcRem(188)})`,
    backgroundColor: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.red,
        perthnow: tokens.perthnow.colors.palette.pinkThulite,
        thewest: tokens.thewest.colors.palette.westblue,
        // Todo - Update for theNightly
        fallback: tokens.thewest.colors.palette.westblue,
    }),
    height: calcRem(20),
    color: 'white',
    borderRadius: `${calcRem(20)} ${calcRem(3)} ${calcRem(3)} ${calcRem(20)}`,
    paddingLeft: calcRem(16),
    paddingRight: calcRem(16),
    fontWeight: 700,
    fontSize: calcRem(12),
    lineHeight: calcRem(20),

    [breakpoint('sm')]: {
        left: 'unset',
        right: `calc(50% + ${calcRem(214)})`,
        borderRadius: `${calcRem(3)} ${calcRem(20)} ${calcRem(20)} ${calcRem(
            3,
        )}`,
    },
}))

interface StyledFeedCardIndicatorProps {
    isRushed: boolean
    brandHexCode: string
}

export const StyledFeedCardIndicator = styled(
    'span',
)<StyledFeedCardIndicatorProps>(({ isRushed, brandHexCode }) => ({
    display: 'block',
    width: calcRem(18),
    height: calcRem(18),
    borderRadius: calcRem(12),
    backgroundColor: isRushed
        ? tokens.sevennews.colors.palette.white
        : brandHexCode,
    position: 'absolute',
    top: `calc(50% - ${calcRem(12)})`,
    left: calcRem(-32),
    textAlign: 'center',
    fontSize: calcRem(10),
    fontWeight: 700,
    lineHeight: calcRem(12),
    border: `${calcRem(3)} solid ${brandHexCode}`,
    color: isRushed ? brandHexCode : tokens.sevennews.colors.palette.white,

    [breakpoint('sm')]: {
        width: calcRem(24),
        height: calcRem(24),
        fontSize: calcRem(10),
        lineHeight: calcRem(18),
        left: calcRem(-36),
    },
}))
