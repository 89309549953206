import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../../feature-toggling'
import { TheWestLiveBillboardProps } from './TheWestLiveBillboard'

const LazyTheWestLiveBillboardComponent = loadable(
    () => import('./TheWestLiveBillboard'),
)

export const LazyTheWestLiveBillboard = (props: TheWestLiveBillboardProps) => {
    return (
        <FeatureToggle
            feature="the-west-live"
            on={() => <LazyTheWestLiveBillboardComponent {...props} />}
        />
    )
}
