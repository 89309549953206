import { tokens } from '@news-mono/design-tokens'
import {
    BreakpointState,
    metrics,
    calcRem,
    ThemeConfig,
    TheNightlyThemeConfig,
    TheNightlyTheme,
} from '../../__styling'
import { TextTransformProperty } from 'csstype'

export type TheNightlyBreadcrumbTheme<T extends ThemeConfig> = {
    breadcrumbContainer: {
        gap: string
    }
    topic: {
        textColor: string
        fontWeight: number
        gap: string

        hover: {
            opacity: number
        }
        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                lineHeight: string
                letterSpacing: string
                textTransform: TextTransformProperty
            }
        >
    }
    secondaryTopic: {
        color: string
        active: {
            color: string
        }
        breakpointState: BreakpointState<
            T,
            {
                fontWeight?: number
                textTransform: TextTransformProperty
            }
        >
    }
    secondaryTopicContainer: {
        gap: string
    }
    chevron: {
        hover: {
            opacity: number
        }
    }
    link: {
        color: string
        textDecoration: string

        hover: {
            opacity: number
        }
    }
    topicDivider: {
        backgroundColor: string
    }
}

export const thenightlyBreadcrumbTheme = (scheme: TheNightlyTheme) => {
    const theme: TheNightlyBreadcrumbTheme<TheNightlyThemeConfig> = {
        breadcrumbContainer: {
            gap: calcRem(metrics.thenightly.margins.md),
        },
        topic: {
            textColor: tokens.thenightly.colors.palette.neutral[60],
            fontWeight: 600,
            gap: calcRem(metrics.thenightly.margins.sm),

            hover: {
                opacity: 0.7,
            },
            breakpointState: {
                default: {
                    fontSize: calcRem(16),
                    lineHeight: calcRem(24),
                    letterSpacing: '-0.32px',
                    textTransform: 'capitalize',
                },
                sm: {
                    fontSize: calcRem(20),
                    lineHeight: calcRem(28),
                    letterSpacing: '-0.4px',
                    textTransform: 'none',
                },
            },
        },
        secondaryTopic: {
            color: tokens.thenightly.colors.palette.neutral[70],

            active: {
                color: tokens.thenightly.colors.palette.neutral[100],
            },
            breakpointState: {
                default: {
                    textTransform: 'capitalize',
                },
                sm: {
                    fontWeight: 400,
                    textTransform: 'none',
                },
            },
        },
        secondaryTopicContainer: {
            gap: calcRem(metrics.thenightly.margins.m2l),
        },
        chevron: {
            hover: {
                opacity: 0.7,
            },
        },
        link: {
            color: 'unset',
            textDecoration: 'none',

            hover: {
                opacity: 0.7,
            },
        },
        topicDivider: {
            backgroundColor: scheme.sectionValues.primaryColor,
        },
    }
    return theme
}
