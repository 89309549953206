import { WebLink } from '@news-mono/web-common'
import React from 'react'
import { Breakpoints } from '../../__styling/settings/breakpoints'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint as caclBreakpoint } from '../../__styling/style-functions'
import { StyledPicture } from './ImageBanner.styled'

interface Source {
    src:
        | string
        | {
              src: string
              density: '2x' | '1x'
          }[]
    breakpoint?: keyof Breakpoints
}

export interface ImageBannerProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    defaultSrc: string
    altText: string
    sources?: Source[]
    url?: string
    forceInternalSSR?: boolean
    hiddenHeading?: string
    disableHeader?: boolean
}

const ImageBannerWrapper: React.FC<Partial<ImageBannerProps>> = ({
    children,
    disableHeader,
}) => {
    if (disableHeader) {
        return <p style={{ margin: 0 }}>{children}</p>
    } else {
        return <h1 style={{ margin: 0 }}>{children}</h1>
    }
}

export const ImageBanner: React.FC<ImageBannerProps> = ({
    defaultSrc,
    altText,
    sources,
    verticalGutters,
    url,
    forceInternalSSR,
    hiddenHeading,
    disableHeader = false,
}) => {
    const renderImage = (
        <ImageBannerWrapper disableHeader={disableHeader}>
            <StyledPicture verticalGutters={verticalGutters}>
                {sources &&
                    sources.map(({ src, breakpoint }, index) => {
                        const srcProps =
                            typeof src === 'string'
                                ? { srcSet: src }
                                : {
                                      srcSet: src
                                          .map(
                                              (src) =>
                                                  `${src.src} ${src.density}`,
                                          )
                                          .join(','),
                                  }

                        return (
                            <source
                                key={index}
                                media={
                                    breakpoint
                                        ? caclBreakpoint(breakpoint, false)
                                        : undefined
                                }
                                {...srcProps}
                            />
                        )
                    })}
                <img src={defaultSrc} alt={hiddenHeading || altText} />
            </StyledPicture>
            <span hidden>{hiddenHeading}</span>
        </ImageBannerWrapper>
    )

    return url ? (
        <WebLink to={url} forceInternalSSR={forceInternalSSR}>
            {renderImage}
        </WebLink>
    ) : (
        renderImage
    )
}
ImageBanner.displayName = 'ImageBanner'
