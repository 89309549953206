import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { SidebarHeaderProps } from './SidebarHeader'
import { breakpoint } from '../../__styling'

export const StyledSidebarHeader = styled('div')<SidebarHeaderProps>(
    ({ theme, svgColourOverride = '#fff', size }) => ({
        display: 'flex',
        justifyContent: themedValue(theme, {
            thewest: 'flex-start',
            perthnow: 'flex-start',
            thenightly: 'space-between',
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            perthnow:
                size === 'embed' ? calcRem(6, 0, 6, 20) : calcRem(10, 0, 0, 20),
            thenightly: size === 'full' ? calcRem(5, 0, 0, 0) : 0,
            fallback: undefined,
        }),
        borderRadius: themedValue(theme, {
            perthnow: calcRem(5),
            fallback: undefined,
        }),

        [breakpoint('sm')]: {
            padding: themedValue(theme, {
                perthnow: calcRem(10, 0, 0, 0),
                thenightly: size === 'full' ? calcRem(5, 0, 0, 0) : 0,
                fallback: undefined,
            }),
        },

        // Style the WebLink to also make sure there's no odd sizing issues while wrapping the svg
        '& svg, a': {
            height: themedValue(theme, {
                thewest: 30,
                perthnow: 36,
                thenightly: size === 'small' ? 20 : 30,
                fallback: undefined,
            }),
            width: themedValue(theme, {
                thewest: 137,
                perthnow: 167,
                thenightly: size === 'small' ? 110 : 150,
                fallback: undefined,
            }),
            '.cls-2': {
                fill: svgColourOverride,
            },

            [breakpoint('sm')]: {
                height: themedValue(theme, {
                    thewest: size === 'full' ? 40 : 30,
                    perthnow: size === 'full' ? 40 : 36,
                    thenightly: size === 'small' ? 20 : 48,
                    fallback: undefined,
                }),
                width: themedValue(theme, {
                    thewest: size === 'full' ? 193 : 137,
                    perthnow: size === 'full' ? 193 : 167,
                    thenightly: size === 'small' ? 110 : 200,
                    fallback: undefined,
                }),
            },
        },
    }),
)
