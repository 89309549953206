import {
    ConsentLevel,
    createRegisterableComponent,
} from '@news-mono/web-common'
import React from 'react'
import { Iframe } from '../../content/Iframe/Iframe'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface IframeRouteProps {
    link: string
    height: number
    marginBottom?: keyof ThemeMargins
    sandbox?: string
    name?: string
    consentRequiredLevel?: ConsentLevel
    ariaLabel?: string
}

export const IframeRegistration = createRegisterableComponent(
    'iframe',
    (props: IframeRouteProps, services) => (
        <Iframe key={`iframe${services.location.pathname}`} {...props} /> // key required to use location to force remount
    ),
)
