import styled from '@emotion/styled'
import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import React from 'react'
import { InlineMatchCentreBanner } from '..'
import { breakpoint, calcRem } from '../../..'
import { ImageBanner } from '../../../banners/ImageBanner/ImageBanner'
import { InlineContentTypes } from '../SharedPublication.routing'

const image = require('./assets/the-game-cricket-reg.png')

const StyledInlineMatchCentreBanner = styled('div')(({ theme }) => ({
    div: {
        margin: '0 auto',
        maxWidth: calcRem(680),
    },
    [breakpoint('md')]: {
        padding: calcRem(0, theme.margins.xxl),
    },
}))

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineMatchCentreBanner(
    content: InlineContentTypes,
): content is InlineMatchCentreBanner {
    return content.kind === 'inline-match-centre-banner'
}

export function getInlinedPositionedMatchCentreBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    const validTopics = [
        'sport/afl',
        'sport/aflw',
        'sport/fremantle-dockers',
        'sport/psa-footy',
        'sport/wafl',
        'sport/wafl-colts',
        'sport/west-coast-eagles',
        'sport/cricket',
        'sport/soccer',
        'sport/rugby-union',
        'sport/live',
        'sport/horse-racing',
        'sport/motorsport',
        'sport/tennis',
        'sport/basketball',
        'sport/nrl',
        'sport/golf',
        'sport/netball',
        'sport/cycling',
        'sport/mixed-martial-arts',
    ]

    if (
        !validTopics.includes(publication.primaryTopic.id) &&
        !publication.secondaryTopics.find((topic) =>
            validTopics.includes(topic.id),
        )
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineMatchCentreBanner)
        .filter((content) => content.props.toggle)
        .map((content) => {
            return {
                position: content.insertAfter,
                element: (
                    <StyledInlineMatchCentreBanner>
                        <div>
                            <ImageBanner
                                altText="The Game Cricket 2023-24"
                                defaultSrc={image}
                                url={content.props.url}
                                verticalGutters={['unset', 'md']}
                            />
                        </div>
                    </StyledInlineMatchCentreBanner>
                ),
            }
        })
}
