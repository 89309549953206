import {
    AppState,
    ShareOption,
    SharingEvent,
    useFeature,
} from '@news-mono/web-common'
import { useSectionMetaInfo } from '../../__helpers/use-section-meta'
import {
    StyledButton,
    StyledClipboardButtonText,
    StyledClipboardButton,
    StyledLinkedInIcon,
    StyledEmailIcon,
    StyledLinkIcon,
    StyledSharing,
    StyledCheckmarkIcon,
    StyledFacebookIcon,
    StyledTwitterIcon,
    StyledShareButton,
    StyledButtonText,
    StyledForwardIcon,
} from './Sharing.styled'
import { getButtonOnClick } from '../../buttons/Sharing/utils/get-button-on-click'
import React, { useEffect } from 'react'
import { tokens } from '@news-mono/design-tokens'
import { SharingAnimationType } from './SharingAnimation.styled'
import { useSelector } from 'react-redux'

export interface TNSharingProps {
    /** When the loading flag is passed, the sharing icons will be
     * rendered but not be responsive, even if an invalid url is passed
     */
    loading?: boolean
    url: string
    text: string
    shareOptions: ShareOption[]
    layout?: 'stacked-md-desktop'
    onEvent: (event: SharingEvent) => void
    customCampaign?: string
    publicationId?: string
    postId?: string
    animationType?: SharingAnimationType
    visible?: string
    hideClipboardTextOnMobile?: boolean
    originator?: string
}

export const Sharing: React.FC<TNSharingProps> = (props) => {
    const [copied, setCopied] = React.useState(false)
    const { originator } = props

    const sectionMeta = useSectionMetaInfo()
    const showNativeShareButton =
        useFeature('show-native-share-button') &&
        originator === 'publicationShare'

    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (copied) setCopied(false)
        }, 2000)

        return () => clearTimeout(timeout)
    }, [copied])

    if (!props.loading && !props.url) {
        return null
    }
    const getIcon = (shareOption: string) => {
        switch (shareOption) {
            case 'email_tn':
                return StyledEmailIcon
            case 'linkedin':
                return StyledLinkedInIcon
            case 'checkmark':
                return StyledCheckmarkIcon
            case 'facebook':
                return StyledFacebookIcon
            case 'clipboard':
                return StyledLinkIcon
            case 'x':
            case 'twitter':
                return StyledTwitterIcon
            default:
                return
        }
    }
    const handleClick = (props: TNSharingProps, shareOption: ShareOption) => {
        if (props.loading) {
            return
        }
        const eventOriginator = props.originator ?? 'Sharing'
        props.onEvent({
            type: 'shareButton.click',
            originator: eventOriginator,
            payload: {
                shareType: shareOption,
                publicationId: props?.publicationId,
                postId: props?.postId,
            },
        })
        const onClick = getButtonOnClick(
            shareOption,
            props.url,
            props.text,
            sectionMeta.FacebookAppId,
            props.customCampaign,
        )
        onClick()
    }

    return (
        <StyledSharing compactButtonsOnMobile={props.hideClipboardTextOnMobile}>
            {inApp && showNativeShareButton ? (
                <StyledShareButton
                    className="gtm-mobile-share-button true"
                    onClick={() => {
                        handleClick(props, 'system')
                    }}
                >
                    <StyledButtonText>
                        <span>
                            <StyledForwardIcon />
                            <p>Share</p>
                        </span>
                    </StyledButtonText>
                </StyledShareButton>
            ) : (
                props.shareOptions.map((shareOption, index) => {
                    const gtmHook = `gtm-${shareOption}-share-button ${props.visible}`
                    const Icon = getIcon(shareOption)
                    const TickIcon = getIcon('checkmark')
                    if (!Icon || !TickIcon) {
                        return null
                    }
                    return shareOption === 'clipboard' ? (
                        <StyledClipboardButton
                            animationType={props.animationType}
                            animationIndex={index}
                            animationTotal={props.shareOptions.length}
                            key={shareOption + index}
                            className={gtmHook}
                            onClick={() => {
                                setCopied(true), handleClick(props, shareOption)
                            }}
                            aria-label={'Copy link to clipboard'}
                            hideClipboardTextOnMobile={
                                props.hideClipboardTextOnMobile
                            }
                        >
                            <StyledClipboardButtonText
                                copied={copied}
                                hideOnMobile={props.hideClipboardTextOnMobile}
                            >
                                <span>
                                    <Icon />
                                    <p>Copy Link</p>
                                </span>
                                <span>
                                    <TickIcon />
                                    <p>Copied</p>
                                </span>
                            </StyledClipboardButtonText>
                        </StyledClipboardButton>
                    ) : (
                        <StyledButton
                            animationType={props.animationType}
                            animationIndex={index}
                            animationTotal={props.shareOptions.length}
                            key={shareOption + index}
                            className={gtmHook}
                            onClick={() => handleClick(props, shareOption)}
                        >
                            <Icon
                                backgroundFillColor={
                                    tokens.thenightly.colors.palette.black
                                }
                            />
                        </StyledButton>
                    )
                })
            )}
        </StyledSharing>
    )
}
