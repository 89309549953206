import {
    SectionMetaDTO,
    TopicV4DTO,
} from '@west-australian-newspapers/publication-types'
import { Topic } from '../client-data-types'
import { isServerEnvironment } from '../environment'
import { RouteResolution } from './route-resolver'

export interface parsedUrl {
    hash: string
    hostname: string
    pathname: string
    port: string
    protocol: string
    search: string
}

function supportsURLConstructor() {
    let url
    try {
        url = new URL('https://thewest.com.au/')
        return url.href === 'https://thewest.com.au/'
    } catch (e) {
        return false
    }
}

export function parseURL(url: string, base?: string): parsedUrl {
    const decodedUrl = decodeURIComponent(url)

    if (!isServerEnvironment() && !supportsURLConstructor()) {
        const aLink = document.createElement('a')
        aLink.href = decodedUrl
        return {
            hash: aLink.hash,
            hostname: aLink.hostname,
            pathname: aLink.pathname,
            port: aLink.port,
            protocol: aLink.protocol,
            search: aLink.search,
        }
    }
    // Safari throws a type error if base is undefined | null
    // eslint-disable-next-line
    const location = base ? new URL(decodedUrl, base) : new URL(decodedUrl)
    return {
        hash: location.hash,
        hostname: location.hostname,
        pathname: location.pathname,
        port: location.port,
        protocol: location.protocol,
        search: location.search,
    }
}

export function stripTrailingSlash(path: string) {
    if (path.length > 0 && path.charAt(path.length - 1) === '/') {
        return path.substring(0, path.length - 1)
    }
    return path
}

export function stripLeadingAmp(path: string) {
    if (path.indexOf('&') === 0) {
        return path.substring(1)
    }
    return path
}

// Helper to get the site section from the path or hostname if the specific section-domains toggle is enabled
export function getSiteSection<Sections extends string>(
    path: string,
    hostname: string | undefined,
    hostnameToSectionLookup: Record<string, string>,
    sectionMeta: SectionMetaDTO,
) {
    if (hostname) {
        // Need to get the domain from the hostname
        // If our current hostname matches a section hostname, append that section to the routeIdentificationKey
        const sectionForDomain =
            hostnameToSectionLookup[hostname.replace(/:\d+$/, '')]
        if (sectionForDomain) {
            return { resolveType: 'hostname', section: sectionForDomain }
        }
    }

    return {
        resolveType: 'path',
        section: getSiteSectionFromPathname<Sections>(path, sectionMeta),
    }
}

/**
 * Gets the site section from pathname only if its present in the sectionOverrides within
 * the sectionMeta object, which is true for 7NEWS and TheWest
 */
function getSiteSectionFromPathname<Sections extends string>(
    path: string,
    sectionMeta: SectionMetaDTO,
): Sections | undefined {
    // Resolve section via pathname
    const [, sectionSegment] = path.split('/')
    const pathnameResolvedSection = (sectionMeta.sectionOverrides as any)[
        sectionSegment
    ]

    return pathnameResolvedSection ? (sectionSegment as Sections) : undefined
}

/**
 * Helper function to render an element given a primary topic and a options object that contains a allowedTopics/disallowedTopics
 */
export function renderForPrimaryTopic<T>(
    primaryTopic: TopicV4DTO,
    options: {
        // if the topic matches any of the topics within the allowedTopics it will be considered allowed
        allowedTopics: string[]
        // if the topic matches any within the disallowedTopics the element will not be returned
        disallowedTopics?: string[]
    },
    element: T | null,
) {
    const { allowedTopics, disallowedTopics } = options
    if (
        allowedTopics.includes(primaryTopic.id) &&
        (!disallowedTopics || !disallowedTopics.includes(primaryTopic.id))
    ) {
        return element
    }
    return []
}

/**
 * Retrieve topic data from route resolution
 * @param resolution - RouteResolution
 * @returns TopicV4DTO: Topic data shape
 */
export function getResolvedTopicPageMeta(
    resolution: RouteResolution,
): Topic | undefined {
    const { type } = resolution

    switch (type) {
        case 'server':
        case 'static':
            return resolution.resolution?.type === 'topic'
                ? resolution.resolution.topic
                : undefined
        default:
            return undefined
    }
}
