import {
    acceptCookieSettingsDialog,
    ConsentLevel,
    CookieConsentEvent,
    hasConsentLevel,
    toCookieConsentEvent,
    useConsentState,
    useDefaultConsentLevel,
} from '@news-mono/web-common'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

interface ConsentFormOptions {
    onEvent?: (event: CookieConsentEvent) => void
    origin: string
}

export function useConsentForm({ onEvent, origin }: ConsentFormOptions) {
    const dispatch = useDispatch()
    const defaultConsentLevel = useDefaultConsentLevel()
    const { consentLevel, savingConsent } = useConsentState()
    const [initialConsent] = React.useState(consentLevel || defaultConsentLevel)
    const [consent, setConsent] = useState<ConsentLevel>(initialConsent)

    function consentHandler(level: ConsentLevel) {
        const changeHandler = (changed: boolean) => {
            setConsent(changed ? consent | level : consent & ~level)
        }
        return [hasConsentLevel(consent, level), changeHandler] as const
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        // Raise an event
        if (onEvent) {
            onEvent(toCookieConsentEvent(consent, origin))
        }

        dispatch(acceptCookieSettingsDialog({ consentLevel: consent }))
    }

    return { consentHandler, consent, handleSubmit, savingConsent }
}
