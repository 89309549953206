import React from 'react'

export interface IconSubscribeProps {
    className?: string
}

export const IconSubscribe: React.FC<IconSubscribeProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 93 54"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Subscribe</title>
        <g fillRule="evenodd">
            <path d="M2.426 18.879V4.248C2.426 1.906 4.357 0 6.73 0h49.964C59.069 0 61 1.906 61 4.248v29.805c0 2.342-1.932 4.248-4.305 4.248h-14.16l1.62 1.721h2.43c.551 0 1 .442 1 .987a.993.993 0 0 1-1 .986h-13.91v9.513c0 1.374-1.133 2.492-2.525 2.492H19.403c-1.392 0-2.526-1.118-2.526-2.492v-2.004H3.424C1.536 49.504 0 47.988 0 46.124V22.112c0-1.52 1.023-2.81 2.426-3.232zm-1.085 2.63v.241h24.282v-.241c0-1.082-1.417-1.57-2.19-1.57H2.872c-.772 0-1.53.876-1.53 1.57zm18.022 8.906l6.672-.104-.445-7.379-24.387.104V43.5h16.05V32.57c0-1.372.741-2.155 2.11-2.155zm-.536 3.335h12.05v-1.557c0-.237-.241-.43-.538-.43H19.365c-.297 0-.538.193-.538.43v1.557zM3.765 4.45v14.322h19.767c1.877 0 3.403 1.519 3.403 3.386v8.348h3.141A2.505 2.505 0 0 1 32.585 33h26.909V4.45H3.765zm27.112 44.306h-12.05V35.178h12.05v13.578zM56.662 1.5H6.597c-.915 0-1.705.614-2.078 1.5H58.74c-.375-.886-1.164-1.5-2.08-1.5zm2.079 33H32.383v2.103h24.205c.974 0 2.385-.603 2.153-2.103zM41.42 39.75L39.8 38.1h-7.417v1.65h9.037zM19.365 52.497h10.974c.296 0 .538-.197.538-.747v-1.5h-12.05v1.5c0 .237.241.747.538.747zm-16.416-4.54h14.372v-3.181H1.228v1.215c0 .694.925 1.965 1.721 1.965z" />
            <path d="M54.758 42.767c.482-.253 1.094-.197 1.678.152l10.796 6.46c.3.179.39.556.2.842a.663.663 0 0 1-.885.191l-10.796-6.46c-.228-.136-.353-.126-.371-.116-.019.01-.094.105-.094.361 0 .688.537 1.613 1.15 1.98l10.465 6.262c.557.333 1.066.424 1.396.25.33-.173.52-.63.52-1.255 0-1.583-1.19-3.633-2.6-4.477L54.3 39.827a.605.605 0 0 1-.3-.516c0-.21.113-.406.3-.517l22.897-13.7a.672.672 0 0 1 .685 0l12.062 7.216C91.657 33.336 93 35.647 93 37.571v.326c0 1.925-1.343 4.235-3.056 5.26l-17.94 10.734a.663.663 0 0 1-.886-.191.593.593 0 0 1 .201-.843l17.94-10.733c1.33-.797 2.455-2.732 2.455-4.227v-.326c0-1.495-1.124-3.43-2.456-4.227L77.54 26.333 55.85 39.31 66.9 45.923c1.796 1.074 3.202 3.495 3.202 5.51 0 1.1-.42 1.926-1.184 2.326-.307.161-.645.241-1.004.241-.534 0-1.113-.177-1.698-.528L55.75 47.211C54.77 46.623 54 45.299 54 44.197c0-.656.276-1.177.758-1.43z" />
            <path d="M62.69 39.796a.65.65 0 0 1-.544-.284.59.59 0 0 1 .201-.838l14.813-8.846a.663.663 0 0 1 .884.19.59.59 0 0 1-.201.84l-14.812 8.845a.666.666 0 0 1-.341.093zM65.156 41.571a.625.625 0 0 1-.535-.307.67.67 0 0 1 .199-.904l6.165-4.015a.618.618 0 0 1 .87.207.67.67 0 0 1-.199.904l-6.165 4.015a.609.609 0 0 1-.335.1zM73.389 38.12a.695.695 0 0 1 .936.208.64.64 0 0 1-.216.902l-6.688 4.017a.697.697 0 0 1-.937-.208.64.64 0 0 1 .217-.901l6.688-4.018zM80.506 31.605l5.382 3.237a.613.613 0 0 1 .302.525.613.613 0 0 1-.305.524l-6.42 3.812a.672.672 0 0 1-.693-.006l-1.43-.883a.602.602 0 0 1-.192-.855.667.667 0 0 1 .896-.184l1.085.67 5.19-3.083-4.161-2.503-5.116 3.063.596.369c.3.185.387.568.193.855a.666.666 0 0 1-.896.184l-1.45-.896a.612.612 0 0 1-.297-.524.611.611 0 0 1 .304-.52l6.323-3.785a.673.673 0 0 1 .689 0zM69.578 44.814a.64.64 0 0 1 .217-.898l6.696-4.022a.69.69 0 0 1 .93.21.64.64 0 0 1-.216.899l-6.697 4.02a.69.69 0 0 1-.93-.209zM88.571 36.537a.612.612 0 0 1-.203.853l-15.45 9.414a.638.638 0 0 1-.87-.199.612.612 0 0 1 .202-.852l15.451-9.415a.64.64 0 0 1 .87.2z" />
        </g>
    </svg>
)
