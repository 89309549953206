import React from 'react'

export interface IconEmailTN {
    title?: string
    className?: string
}

export const IconEmailTN: React.FC<IconEmailTN> = ({ title, className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 26 26"
    >
        <title>{title || 'Email Us'}</title>
        <path
            d="M2.58398 7.36829L11.0891 13.3219C11.7778 13.804 12.1222 14.045 12.4968 14.1384C12.8276 14.2209 13.1737 14.2209 13.5045 14.1384C13.8791 14.045 14.2235 13.804 14.9122 13.3219L23.4173 7.36829M7.58398 20.91H18.4173C20.1675 20.91 21.0426 20.91 21.711 20.5693C22.299 20.2697 22.7771 19.7917 23.0767 19.2037C23.4173 18.5352 23.4173 17.6601 23.4173 15.91V9.24329C23.4173 7.49312 23.4173 6.61804 23.0767 5.94957C22.7771 5.36156 22.299 4.8835 21.711 4.58389C21.0426 4.24329 20.1675 4.24329 18.4173 4.24329H7.58398C5.83382 4.24329 4.95874 4.24329 4.29026 4.58389C3.70226 4.8835 3.22419 5.36156 2.92459 5.94957C2.58398 6.61804 2.58398 7.49312 2.58398 9.24329V15.91C2.58398 17.6601 2.58398 18.5352 2.92459 19.2037C3.22419 19.7917 3.70226 20.2697 4.29026 20.5693C4.95874 20.91 5.83382 20.91 7.58398 20.91Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconEmailTN.displayName = 'IconEmailTN'
