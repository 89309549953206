import React from 'react'
import { ImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { ThemeMargins } from '../../../__styling'
import { useProduct } from '../../../__product'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { StyledFourCardCollection } from './FourCardCollection.styled'
import { ContentCard } from '../../cards/ContentCard/ContentCard'

interface FourCardProps {
    items: MaybeLoaded<CardItem[]>
    onEvent: (event: CollectionEvent) => void
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    shouldHideTeaser?: boolean
}

export const FourCardCollection = (props: FourCardProps) => {
    const { items, verticalGutters, verticalSpacing, shouldHideTeaser } = props

    const product = useProduct()

    if (!items.loaded) {
        return null
    }
    const loadedItems = invertMaybeLoadedItems(items, 4)

    return (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'FourCardCollection',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledFourCardCollection
                    ref={ref}
                    verticalSpacing={verticalSpacing}
                    verticalGutters={verticalGutters}
                >
                    {loadedItems.map((item, itemIndex) => {
                        return (
                            <ContentCard
                                item={item}
                                cardNumber={itemIndex}
                                cardType={
                                    shouldHideTeaser
                                        ? 'medium-video-hub'
                                        : 'medium'
                                }
                                onEvent={props.onEvent}
                                shouldHideTeaser={shouldHideTeaser}
                            />
                        )
                    })}
                </StyledFourCardCollection>
            )}
        </ImpressionAvailable>
    )
}
