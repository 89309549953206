import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { ComponentEvent } from './component-event'

export enum AnalyticsEventDef {
    UNKNOWN = 0,
    IMPRESSION_PAYWALL = 1,
    IMPRESSION_AD = 2,
    IMPRESSION_OFFERS = 3,
    IMPRESSION_SUBSCRIBE_BUTTON = 4,
    IMPRESSION_SMARTBOX = 5,
    IMPRESSION_SWG_BUTTON = 6,
    IMPRESSION_CLICK_TO_SHOW_OFFERS = 7,
    IMPRESSION_CLICK_TO_SHOW_OFFERS_OR_ALREADY_SUBSCRIBED = 8,
    IMPRESSION_SUBSCRIPTION_COMPLETE = 9,
    IMPRESSION_ACCOUNT_CHANGED = 10,
    IMPRESSION_PAGE_LOAD = 11,
    IMPRESSION_LINK = 12,
    IMPRESSION_SAVE_SUBSCR_TO_GOOGLE = 13,
    IMPRESSION_GOOGLE_UPDATED = 14,
    IMPRESSION_SHOW_OFFERS_SMARTBOX = 15,
    IMPRESSION_SHOW_OFFERS_SWG_BUTTON = 16,
    IMPRESSION_SELECT_OFFER_SMARTBOX = 17,
    IMPRESSION_SELECT_OFFER_SWG_BUTTON = 18,
    IMPRESSION_SHOW_CONTRIBUTIONS_SWG_BUTTON = 19,
    IMPRESSION_SELECT_CONTRIBUTION_SWG_BUTTON = 20,
    IMPRESSION_METER_TOAST = 21,
    IMPRESSION_REGWALL = 22,
    IMPRESSION_SHOWCASE_REGWALL = 23,
    IMPRESSION_SWG_SUBSCRIPTION_MINI_PROMPT = 24,
    IMPRESSION_SWG_CONTRIBUTION_MINI_PROMPT = 25,
    IMPRESSION_CONTRIBUTION_OFFERS = 26,
    ACTION_SUBSCRIBE = 1000,
    ACTION_PAYMENT_COMPLETE = 1001,
    ACTION_ACCOUNT_CREATED = 1002,
    ACTION_ACCOUNT_ACKNOWLEDGED = 1003,
    ACTION_SUBSCRIPTIONS_LANDING_PAGE = 1004,
    ACTION_PAYMENT_FLOW_STARTED = 1005,
    ACTION_OFFER_SELECTED = 1006,
    ACTION_SWG_BUTTON_CLICK = 1007,
    ACTION_VIEW_OFFERS = 1008,
    ACTION_ALREADY_SUBSCRIBED = 1009,
    ACTION_NEW_DEFERRED_ACCOUNT = 1010,
    ACTION_LINK_CONTINUE = 1011,
    ACTION_LINK_CANCEL = 1012,
    ACTION_GOOGLE_UPDATED_CLOSE = 1013,
    ACTION_USER_CANCELED_PAYFLOW = 1014,
    ACTION_SAVE_SUBSCR_TO_GOOGLE_CONTINUE = 1015,
    ACTION_SAVE_SUBSCR_TO_GOOGLE_CANCEL = 1016,
    ACTION_SWG_BUTTON_SHOW_OFFERS_CLICK = 1017,
    ACTION_SWG_BUTTON_SELECT_OFFER_CLICK = 1018,
    ACTION_SWG_BUTTON_SHOW_CONTRIBUTIONS_CLICK = 1019,
    ACTION_SWG_BUTTON_SELECT_CONTRIBUTION_CLICK = 1020,
    ACTION_USER_CONSENT_DEFERRED_ACCOUNT = 1021,
    ACTION_USER_DENY_DEFERRED_ACCOUNT = 1022,
    ACTION_DEFERRED_ACCOUNT_REDIRECT = 1023,
    ACTION_GET_ENTITLEMENTS = 1024,
    ACTION_METER_TOAST_SUBSCRIBE_CLICK = 1025,
    ACTION_METER_TOAST_EXPANDED = 1026,
    ACTION_METER_TOAST_CLOSED_BY_ARTICLE_INTERACTION = 1027,
    ACTION_METER_TOAST_CLOSED_BY_SWIPE_DOWN = 1028,
    ACTION_METER_TOAST_CLOSED_BY_X_CLICKED = 1029,
    ACTION_SWG_SUBSCRIPTION_MINI_PROMPT_CLICK = 1030,
    ACTION_SWG_CONTRIBUTION_MINI_PROMPT_CLICK = 1031,
    ACTION_SWG_SUBSCRIPTION_MINI_PROMPT_CLOSE = 1032,
    ACTION_SWG_CONTRIBUTION_MINI_PROMPT_CLOSE = 1033,
    ACTION_CONTRIBUTION_OFFER_SELECTED = 1034,
    ACTION_SHOWCASE_REGWALL_GSI_CLICK = 1035,
    ACTION_SHOWCASE_REGWALL_EXISTING_ACCOUNT_CLICK = 1036,
    EVENT_PAYMENT_FAILED = 2000,
    EVENT_CUSTOM = 3000,
    EVENT_CONFIRM_TX_ID = 3001,
    EVENT_CHANGED_TX_ID = 3002,
    EVENT_GPAY_NO_TX_ID = 3003,
    EVENT_GPAY_CANNOT_CONFIRM_TX_ID = 3004,
    EVENT_GOOGLE_UPDATED = 3005,
    EVENT_NEW_TX_ID = 3006,
    EVENT_UNLOCKED_BY_SUBSCRIPTION = 3007,
    EVENT_UNLOCKED_BY_METER = 3008,
    EVENT_NO_ENTITLEMENTS = 3009,
    EVENT_HAS_METERING_ENTITLEMENTS = 3010,
    EVENT_OFFERED_METER = 3011,
    EVENT_UNLOCKED_FREE_PAGE = 3012,
    EVENT_SUBSCRIPTION_STATE = 4000,
}

export const enum EventOriginatorDef {
    UNKNOWN_CLIENT = 0,
    SWG_CLIENT = 1,
    AMP_CLIENT = 2,
    PROPENSITY_CLIENT = 3,
    SWG_SERVER = 4,
    PUBLISHER_CLIENT = 5,
    SHOWCASE_CLIENT = 6,
}
export interface GoogleEvent {
    eventType?: AnalyticsEventDef
    eventOriginator?: EventOriginatorDef
    isFromUserAction?: boolean
    additionalParameters?: Object
}

export interface GTMGoogleEvent {
    eventType?: string
    eventOriginator?: EventOriginatorDef
    isFromUserAction?: boolean
    additionalParameters?: Object
}

export interface ExtendedAccessEvent extends ComponentEvent<GTMGoogleEvent> {
    type: DataLayerEventName.extendedAccess
}

export interface SubscribeWithGoogleEvent
    extends ComponentEvent<GTMGoogleEvent> {
    type: DataLayerEventName.subscribeWithGoogle
}

export enum SwGAnalyticsEventDef {
    IMPRESSION_SMARTBOX = 5,
    IMPRESSION_SWG_BUTTON = 6,
    ACTION_SWG_BUTTON_CLICK = 1007,
    ACTION_OFFER_SELECTED = 1006,
    ACTION_ALREADY_SUBSCRIBED = 1009,
    ACTION_PAYMENT_FLOW_STARTED = 1005,
    ACTION_USER_CANCELED_PAYFLOW = 1014,
    IMPRESSION_SUBSCRIPTION_COMPLETE = 9,
    IMPRESSION_ACCOUNT_CHANGED = 10,
    ACTION_ACCOUNT_CREATED = 1002,
    ACTION_ACCOUNT_ACKNOWLEDGED = 1003,
    ACTION_PAYMENT_COMPLETE = 1001,
    IMPRESSION_SAVE_SUBSCR_TO_GOOGLE = 13,
    ACTION_SAVE_SUBSCR_TO_GOOGLE_CONTINUE = 1015,
    ACTION_SAVE_SUBSCR_TO_GOOGLE_CANCEL = 1016,
    IMPRESSION_GOOGLE_UPDATED = 14,
}

export function isSwGEvent(eventType: AnalyticsEventDef) {
    return Object.values(SwGAnalyticsEventDef).includes(
        eventType as unknown as SwGAnalyticsEventDef,
    )
}
