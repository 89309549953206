import { Profile as ProfileData } from '@news-mono/web-common'
import React from 'react'
import { BioBlock } from '../../content/BioBlock/BioBlock'

export interface PerthNowProfileProps {
    profile: ProfileData
    profileUrl: string
}

export const PerthNowProfile: React.FC<PerthNowProfileProps> = (props) => {
    return (
        <BioBlock
            profile={props.profile}
            url={props.profileUrl}
            title={props.profile.name}
        />
    )
}
