import React, { ReactNode, useState } from 'react'
import {
    AccordionContainer,
    ContentWrapper,
    ExpanderButton,
    ExpanderIcon,
    HeadingLabel,
    HeadingWrapper,
    IconWrapper,
} from './Accordion.styled'
import { useLocalStorage } from '../../../../web-common/src/hooks/useLocalStorage'

export const ACCORDION_STATE_STORAGE_KEY = 'accordionIsExpanded'

export type AccordionProps = {
    headerText: string
    children: ReactNode
}

export const Accordion = ({ headerText, children }: AccordionProps) => {
    const [isOpen, setIsOpen] = useLocalStorage({
        key: 'search-filter-accordion-isopen',
        initialValue: 'false',
    })

    return (
        <AccordionContainer>
            <ExpanderButton
                onClick={() => setIsOpen(isOpen === 'true' ? 'false' : 'true')}
            >
                <HeadingWrapper>
                    <HeadingLabel>{headerText}</HeadingLabel>
                </HeadingWrapper>
                <IconWrapper selected={isOpen === 'true' ? true : false}>
                    <ExpanderIcon />
                </IconWrapper>
            </ExpanderButton>
            {isOpen === 'true' && <ContentWrapper>{children}</ContentWrapper>}
        </AccordionContainer>
    )
}
