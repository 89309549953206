import React from 'react'

export interface IconSearchProps {
    className?: string
}

export const IconSearch: React.FC<IconSearchProps> = ({ className }) => (
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>Search</title>
        <path
            d="M13.492 13.492c-1.159 1.16-2.553 1.739-4.184 1.739-1.63 0-3.026-.58-4.185-1.739-1.159-1.159-1.738-2.554-1.738-4.184 0-1.63.58-3.026 1.738-4.185 1.16-1.159 2.554-1.739 4.185-1.739 1.63 0 3.025.58 4.184 1.74 1.16 1.158 1.74 2.553 1.74 4.184 0 1.63-.58 3.025-1.74 4.184m3.484 1.091c1.093-1.577 1.64-3.336 1.64-5.275a9.14 9.14 0 0 0-.734-3.616 9.308 9.308 0 0 0-1.983-2.975A9.307 9.307 0 0 0 12.924.734 9.146 9.146 0 0 0 9.308 0c-1.26 0-2.466.244-3.616.734a9.304 9.304 0 0 0-2.975 1.983A9.31 9.31 0 0 0 .734 5.692 9.15 9.15 0 0 0 0 9.308c0 1.26.245 2.465.734 3.616a9.303 9.303 0 0 0 1.983 2.974 9.305 9.305 0 0 0 2.975 1.984c1.15.489 2.356.734 3.616.734 1.939 0 3.697-.547 5.275-1.64l6.2 6.235 1.178-1.175s1.312-1.324 1.294-1.324l-6.279-6.129z"
            fillRule="evenodd"
        />
    </svg>
)
