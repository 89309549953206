import { useCallback, useEffect, useReducer, useState } from 'react'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import {
    updatePostList,
    isPublishedMilestone,
    isPublishedPinnedPost,
    useFetchEventPosts,
    deleteFromPostList,
    toEpoch,
    aggressiveScrollTo,
    DebugEventAdder,
    clearDeepLink,
} from '../helpers'
import { Paging } from '../../../data-controllers/Pagination/usePagination'
import { useLiveEventPosts } from './useLiveEventPosts'
import { useHistory, useLocation } from 'react-router'
import { PostState } from './PostState'

type UseLiveEventPaginationControllerParams = {
    /** The publication ID of the live event being controlled. */
    publicationId: string
    /** The initial state of posts on the page. */
    initialPostState: PostState
    /** A callback fired whenever a new post is recieved via websocket. */
    onLiveEventUpdate?: (post: KilledEventPostV4DTO | EventPostV4DTO) => void
    /** The Size of the pages to be paginateted. */
    pageSize: number
    /** Debug menu controls. */
    addDebugEvent: DebugEventAdder
}

type UseLiveEventPaginationControls = {
    /** If the controller is fetching new posts. */
    isFetching: boolean
    /** The various live event posts. */
    postState: PostState
    /** A function which accepts the posts recieved via websocket since the page was last loaded. */
    loadUpdates: () => void
    /** Deep links to the respective post. */
    handleDeepLink: (postId: string) => void
    /** Information on pagination. */
    paging: Paging
    /** Loads the specified page. */
    setPage: (page: number) => void
}

export const useLiveEventPaginationController = ({
    publicationId,
    pageSize,
    initialPostState,
    onLiveEventUpdate,
    addDebugEvent,
}: UseLiveEventPaginationControllerParams): UseLiveEventPaginationControls => {
    const {
        fetchEventPostsPage,
        fetchEventPostPageById,
        fetchEventPosts,
        isFetching,
    } = useFetchEventPosts()
    const { search, pathname } = useLocation()
    const history = useHistory()
    const initialPage = parseInt(new URLSearchParams(search).get('page') ?? '1')
    // State values.
    const [paging, setPaging] = useState<Paging>({
        page: initialPage,
        pageSize,
    })
    const [activePosts, setActivePosts] = useState(initialPostState.activePosts)
    // The total amount of posts for the live event on all pages.
    // Not to be confused with the number of posts on screen.
    const [postCount, setPostCount] = useState(initialPostState.postCount)
    const [stickyPosts, setStickyPosts] = useState(initialPostState.stickyPosts)
    const [milestonePosts, setMilestonePosts] = useState(
        initialPostState.milestonePosts,
    )
    const [queuedPosts, setQueuedPosts] = useState(initialPostState.queuedPosts)

    // Page switcher.
    const setPage = useCallback(
        async (page: number) => {
            if (page < 1) return

            const newPage = await fetchEventPostsPage({
                publicationId,
                page,
                pageSize: paging.pageSize,
            })

            setPaging((oldPaging) => ({
                ...oldPaging,
                page,
            }))
            setActivePosts(newPage.documents)
            setPostCount(newPage.postCount)

            addDebugEvent(`Page navigation to page ${page}.`)
        },
        [addDebugEvent, fetchEventPostsPage, paging.pageSize, publicationId],
    )

    const loadUpdates = useCallback(() => {
        setQueuedPosts((queuedPosts) => {
            if (paging.page === 1) {
                // Incorporate updates and return new first page.
                setActivePosts((oldPosts) =>
                    updatePostList(oldPosts, ...queuedPosts).slice(
                        0,
                        paging.pageSize,
                    ),
                )

                // Update post count.
                setPostCount(
                    (oldCount) =>
                        oldCount +
                        // New posts have the same update/publication date.
                        queuedPosts.filter(
                            (queuedPost) =>
                                queuedPost.lastUpdated ===
                                queuedPost.publishedDate,
                        ).length,
                )
            } else {
                history.push(pathname)
                // Otherwise just load the first page.
                setPage(1)
            }

            // Clear queued posts list.
            return []
        })
    }, [paging.page, paging.pageSize, history, pathname, setPage])

    // Handle websocket events.
    const onPostCreate = useCallback(
        (post: EventPostV4DTO) =>
            setQueuedPosts((oldPosts) => {
                if (
                    oldPosts.find(
                        (oldPost) =>
                            oldPost.id === post.id &&
                            oldPost.lastUpdated === post.lastUpdated,
                    )
                ) {
                    // Duplicate update event.
                    return oldPosts
                }

                addDebugEvent(
                    `Post ${post.id} recieved as update. Incorporating and adding to update queue.`,
                )
                // Update milestone/sticky posts if applicable.
                if (isPublishedPinnedPost(post)) {
                    setStickyPosts((oldPosts) => updatePostList(oldPosts, post))
                }

                if (isPublishedMilestone(post)) {
                    setMilestonePosts((oldPosts) =>
                        updatePostList(oldPosts, post),
                    )
                }

                onLiveEventUpdate?.(post)
                return [...oldPosts, post]
            }),
        [addDebugEvent, onLiveEventUpdate],
    )

    /**
     * Given a list of posts, fetch the post that occurs before the oldest post, and update the main post list.
     * If no older posts exist, only update the main post list.
     */
    const loadNewLastPost = useCallback(
        async (postList: EventPostV4DTO[]) => {
            // No reference to last if there are no posts.
            if (postList.length < 1) return

            const result = await fetchEventPosts({
                publicationId,
                after: 0,
                before: 1,
                reference: postList[postList.length - 1].id,
            })

            if (result.documents.length < 1 || result.documents.length === 1) {
                // No posts were returned or
                // No older posts exist.
                return
            }

            setActivePosts([...postList, result.documents[1]])
        },
        [fetchEventPosts, publicationId],
    )

    /**
     * When a post is deleted, update the states accordingly.
     */
    const onPostDelete = useCallback(
        (deletedPost: KilledEventPostV4DTO) => {
            addDebugEvent(
                `Post ${deletedPost.id} recieved deletion update. Deleting post.`,
            )
            // Remove from milestones/sticky lists.
            setStickyPosts((oldPosts) =>
                deleteFromPostList(deletedPost, oldPosts),
            )
            setMilestonePosts((oldPosts) =>
                deleteFromPostList(deletedPost, oldPosts),
            )

            // Update posts.
            setActivePosts((oldPosts) => {
                // Cannot delete from an empty list.
                // Likely recieved an event that occurred before the SSR.
                // (Websocket events can happen somewhat retroactively.)
                if (oldPosts.length < 1) {
                    return oldPosts
                }

                const lastPost = oldPosts[oldPosts.length - 1]
                const firstPost = oldPosts[0]

                if (
                    // If the deleted post is on a page before the current one.
                    toEpoch(deletedPost.publishedDate) >
                    toEpoch(firstPost.publishedDate)
                ) {
                    addDebugEvent(
                        'Deleted event on prior page. Shifting and loading new post.',
                    )
                    // Shift all posts up by 1 and load an extra to take it's place
                    const [_, ...newPosts] = oldPosts

                    // Dispatch a fetch to load a new post.
                    loadNewLastPost(newPosts)
                } else if (
                    // If the deleted post is on the current page.
                    toEpoch(deletedPost.publishedDate) >=
                    toEpoch(lastPost.publishedDate)
                ) {
                    addDebugEvent(
                        'Deleted event on current page. Deleting and loading new post to fill.',
                    )
                    const newPosts = deleteFromPostList(deletedPost, oldPosts)

                    // Dispatch a fetch to load a new post.
                    loadNewLastPost(newPosts)
                }

                // If the deleted post is after the current page, do nothing.
                // Otherwise new state is set inside async callback.
                // Return old state to not re-render until then.
                return oldPosts
            })
            setPostCount((oldCount) => {
                const newCount = oldCount - 1

                if (Math.ceil(newCount / paging.pageSize) < paging.page) {
                    // If the only post on the page was deleted, navigate to the previous page.
                    setPage(paging.page - 1)
                }

                return newCount
            })

            onLiveEventUpdate?.(deletedPost)
        },
        [
            addDebugEvent,
            loadNewLastPost,
            onLiveEventUpdate,
            paging.page,
            paging.pageSize,
            setPage,
        ],
    )

    useLiveEventPosts({
        publicationId,
        onPostCreate,
        onPostDelete,
    })

    // Handle deep links.
    const handleDeepLink = useCallback(
        async (postId: string) => {
            // Only enter a deep link state if the requested post isn't already loaded.
            if (activePosts.findIndex((post) => post.id === postId) === -1) {
                addDebugEvent(
                    `Post ${postId} not found on current page. Deep-linking to new page.`,
                )
                const result = await fetchEventPostPageById({
                    pageSize: paging.pageSize,
                    publicationId,
                    postId,
                })

                setPostCount(result.postCount)
                setActivePosts(result.documents)
                setPaging((oldPaging) => ({
                    ...oldPaging,
                    page: result.page,
                }))
                // No need to clear the hash as the history change does this too.
                history.push(`${pathname}?page=${result.page}`)
            }

            addDebugEvent(`Post ${postId} loaded. Scrolling to post.`)
            // On the next frame (after render) scroll to post.
            await aggressiveScrollTo(postId)
            clearDeepLink()
        },
        [
            activePosts,
            addDebugEvent,
            fetchEventPostPageById,
            history,
            paging.pageSize,
            pathname,
            publicationId,
        ],
    )

    return {
        isFetching,
        postState: {
            activePosts,
            stickyPosts,
            milestonePosts,
            queuedPosts,
            postCount,
        },
        loadUpdates,
        handleDeepLink,
        paging,
        setPage,
    }
}
