import { PredicateHierarchy, ReadableTime } from './types'
import {
    aDay,
    anHour,
    aWeek,
    catchAll,
    justNow,
    lessThanAnHour,
    moreThanADay,
    moreThanAnHour,
    moreThanAWeek,
    pastCheck,
} from './predicates'

export const JUST_NOW_DELTA = 15
export const JUST_NOW_MESSAGE = 'Just now'
export const DEFAULT_DATE_FORMAT = 'dd MMM yyyy'

const baseHierarchy: ReadableTime[] = [
    pastCheck,
    justNow,
    lessThanAnHour,
    anHour,
    moreThanAnHour,
]

const defaultHierarchy: ReadableTime[] = [...baseHierarchy, catchAll]

const perthNowCardsHierarchy: ReadableTime[] = [
    ...baseHierarchy,
    aDay,
    moreThanADay,
    catchAll,
]

const theNightlyNotificationCentreHierarchy: ReadableTime[] = [
    ...baseHierarchy,
    aDay,
    moreThanADay,
    aWeek,
    moreThanAWeek,
    catchAll,
]

export const GetReadableTimePredicateHierarchy: PredicateHierarchy = {
    default: defaultHierarchy,
    perthNowCards: perthNowCardsHierarchy,
    theNightlyNotificationCentre: theNightlyNotificationCentreHierarchy,
}
