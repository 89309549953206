import {
    ConsentLevel,
    CookieConsentEvent,
    getFullDateTime,
    hasConsentLevel,
    useConsentState,
    useDefaultConsentLevel,
    useIsEuMemberState,
} from '@news-mono/web-common'
import React, { Fragment } from 'react'
import { Button } from '../../buttons/Button/Button'
import { TextLink } from '../../typography/TextLink/TextLink'
import { ConsentCheckbox } from '../CookieConsentManager'
import { useConsentForm } from '../useConsentForm'
import {
    StyledFieldset,
    StyledUserSettingsCategoryList,
} from './CookieConsentUserSettingsPage.styled'

export interface CookieConsentUserSettingsPageProps {
    onEvent?: (event: CookieConsentEvent) => void
}

export const CookieConsentUserSettingsPage: React.FC<CookieConsentUserSettingsPageProps> =
    ({ onEvent }) => {
        const isEuMemberState = useIsEuMemberState()
        const { consentHandler, handleSubmit, savingConsent } = useConsentForm({
            origin: 'CookieSettingsPage',
            onEvent,
        })

        const { consentLevel, consentTimestamp } = useConsentState()
        const defaultConsentLevel = useDefaultConsentLevel()
        const [initialConsent] = React.useState(
            consentLevel || defaultConsentLevel,
        )

        const essentialChecked = hasConsentLevel(
            initialConsent,
            ConsentLevel.Essential,
        )
        const analyticsChecked = hasConsentLevel(
            initialConsent,
            ConsentLevel.Analytics,
        )
        const advertisingChecked = hasConsentLevel(
            initialConsent,
            ConsentLevel.Advertising,
        )

        const [essentialFormValue] = consentHandler(ConsentLevel.Essential)
        const [analyticsFormValue, handleAnalyticsChange] = consentHandler(
            ConsentLevel.Analytics,
        )
        const [advertisingFormValue, handleAdvertisingChange] = consentHandler(
            ConsentLevel.Advertising,
        )

        if (!isEuMemberState) {
            return (
                <p>
                    If you are located in Australia or anywhere else outside the
                    European Union, by using the website you are visiting, you
                    consent to our use of cookies but you may withdraw that
                    consent using the information and links provided in our{' '}
                    <TextLink
                        href="https://www.sevenwestmedia.com.au/cookies-guide"
                        openInNewWindow={true}
                    >
                        Cookies Guide
                    </TextLink>
                    .
                </p>
            )
        }

        return (
            <Fragment>
                <p>
                    We use cookies so we can improve your experience on this
                    site, analyse traffic and provide you with relevant
                    advertising. To find out more, please see our{' '}
                    <TextLink
                        href="https://www.sevenwestmedia.com.au/cookies-guide"
                        openInNewWindow={true}
                    >
                        Cookies Guide
                    </TextLink>
                    .
                </p>

                <p>
                    You can manage your cookie settings any time from this page.
                    Your current preferences are outlined below along with the
                    date and time this was last set.
                </p>

                {consentTimestamp && (
                    <p>
                        <time dateTime={consentTimestamp}>
                            <small>
                                Updated {getFullDateTime(consentTimestamp)}
                            </small>
                        </time>
                    </p>
                )}
                <StyledUserSettingsCategoryList>
                    <UserSettingsCategoryListItem checked={essentialChecked}>
                        Essentials:
                    </UserSettingsCategoryListItem>
                    <UserSettingsCategoryListItem checked={analyticsChecked}>
                        Analytics:
                    </UserSettingsCategoryListItem>
                    <UserSettingsCategoryListItem checked={advertisingChecked}>
                        Advertising:
                    </UserSettingsCategoryListItem>
                </StyledUserSettingsCategoryList>

                <form onSubmit={handleSubmit}>
                    <p>
                        If you’d like to re-configure your cookie settings, you
                        can do so below:
                    </p>
                    <StyledFieldset>
                        <div>
                            <ConsentCheckbox
                                name="Essentials"
                                checked={essentialFormValue}
                                disabled
                            />
                            <ConsentCheckbox
                                name="Analytics"
                                checked={analyticsFormValue}
                                onChange={handleAnalyticsChange}
                            />
                            <ConsentCheckbox
                                name="Advertising"
                                checked={advertisingFormValue}
                                onChange={handleAdvertisingChange}
                            />
                        </div>
                    </StyledFieldset>
                    <Button type="submit" disabled={savingConsent}>
                        Save
                    </Button>
                </form>
            </Fragment>
        )
    }

CookieConsentUserSettingsPage.displayName = 'CookieConsentUserSettingsPage'

export default CookieConsentUserSettingsPage

export const UserSettingsCategoryListItem: React.FC<{ checked: boolean }> = ({
    children,
    checked,
}) => {
    return (
        <li>
            <strong>{children}</strong> {checked ? 'Accepted' : 'Denied'}
        </li>
    )
}
