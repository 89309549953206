import React from 'react'
import { useOptaWidgetStart } from '../../cricket-match-centre/useOptaWidgetStart'
import { AflMatchStatsWidgetContainer } from './AflMatchStatsWidget.styled'

export interface AflMatchStatsWidgetProps {
    competition: string
    season: string
    match: string
}
export const AflMatchStatsWidget = ({
    competition,
    match,
    season,
}: AflMatchStatsWidgetProps) => {
    useOptaWidgetStart()
    return (
        <AflMatchStatsWidgetContainer>
            <opta-widget
                widget="matchstats"
                template="team_graphs"
                competition={competition}
                season={season}
                match={match}
                live="true"
                show_match_header="false"
                show_score="true"
                show_crests="false"
                graph_style="full"
                show_date="true"
                date_format="dddd D MMMM YYYY"
                show_venue="true"
                show_competition_name="true"
                competition_naming="full"
                team_naming="full"
                show_live="false"
                show_logo="false"
                show_title="false"
                breakpoints="400, 700"
                sport="afl"
            ></opta-widget>
        </AflMatchStatsWidgetContainer>
    )
}
