import {
    AdEvent,
    AdUnitEventType,
    getAdSize,
    GptAdSlotDefinition,
    pickFirstSizeMapping,
} from '@news-mono/web-common'

/**
 * Creates a datalayer event shape for viewable ad units
 *
 * @param {string} type Type of event that has occurred
 * @param {Object} unit Ad unit
 * @param {number} threshold Percent value that triggers when the viewability setTimeout activates
 */
export function createAdViewableEvent(
    type: AdUnitEventType,
    unit: GptAdSlotDefinition,
    threshold: number,
): AdEvent {
    const unitPos = unit.targeting && unit.targeting.pos

    const sizeMap = pickFirstSizeMapping(getAdSize(unit.sizeMapping))

    const payload = {
        unitId: unit.id,
        unitPath: unit.adUnitPath,
        unitPos,
        unitSize: sizeMap,
        lazyThreshold: threshold,
    }

    return {
        type,
        originator: 'adUnit',
        payload: {
            adUnit: payload,
        },
    }
}
