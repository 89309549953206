import { useTheme } from '@emotion/react'
import {
    getShortDate,
    Profile,
    PublicationCardItem,
    scaleImage,
    Topic,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import {
    StyledByline,
    StyledBylineName,
    StyledBylinePublicationDate,
    StyledBylineText,
    StyledBylineTextWithHeadshot,
    StyledHeadshot,
    StyledHeadshotWrapper,
} from '../../cards/CardByline/CardByline.styled'
import { CommonCardProps } from '../../cards/CardItem.Props'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { themedValue } from '../../__styling/themed-value'

export interface CardBylineProps extends CommonCardProps {
    className?: string
    hasBackground?: boolean
    showProfile?: boolean
    isEdgeToEdgeCard?: boolean
    item: MaybeLoaded<PublicationCardItem>
    showPublicationDate?: boolean
    displayPublicationTime?: boolean
}

export const CardByline: React.FC<CardBylineProps> = (props) => {
    const theme = useTheme()
    const {
        className,
        hasBackground,
        item,
        showProfile,
        isEdgeToEdgeCard,
        showPublicationDate,
    } = props

    const hasBackgroundColor = themedValue(theme, {
        perthnow: hasBackground,
        fallback: undefined,
    })

    if (!item.loaded) {
        return (
            <StyledByline className={className} hasPadding={false}>
                <Skeleton
                    count={1}
                    height={53}
                    dark={hasBackgroundColor || false}
                />
            </StyledByline>
        )
    }

    const publicationDate = item.result.publicationDate

    if (
        showProfile &&
        item.result.profiles &&
        item.result.profiles.length !== 0
    ) {
        return renderBylineWithProfile(
            item.result.profiles,
            item.result.byline,
            !!isEdgeToEdgeCard,
            className,
        )
    }

    return (
        <StyledByline className={className} hasPadding={!!isEdgeToEdgeCard}>
            <StyledBylineText>
                {item.result.byline}
                {showPublicationDate && publicationDate && (
                    <StyledBylinePublicationDate>
                        {' | ' + getShortDate(publicationDate)}
                    </StyledBylinePublicationDate>
                )}
            </StyledBylineText>
        </StyledByline>
    )
}

const getTopicLink = (publicationKind: string, topic: Topic) => {
    let topicLink = `/${topic.id}`
    if (publicationKind === 'video') {
        topicLink = '/video' + topicLink
    }
    return topicLink
}

function getProfileImage(profile: Profile[]): string | undefined {
    const profileImage = profile.slice(0).map((item) => {
        return item.thumbnailPhoto ? item.thumbnailPhoto : undefined
    })[0]

    return profileImage ? scaleImage(profileImage, 110) : profileImage
}

function getProfileText(
    profile: Profile[] | undefined,
    text: string | undefined,
) {
    if (profile && profile.length !== 0) {
        const profileBlock = profile.map((item, index) => {
            return (
                <React.Fragment key={index}>
                    <StyledBylineName>{item.name}</StyledBylineName>
                </React.Fragment>
            )
        })

        return profileBlock
    }

    return text
}

function renderBylineWithProfile(
    profile: Profile[],
    byline: string,
    isEdgeToEdgeCard: boolean,
    className?: string,
) {
    const imageSrc = getProfileImage(profile)
    return (
        <StyledByline hasPadding={isEdgeToEdgeCard} className={className}>
            {imageSrc ? (
                <React.Fragment>
                    <StyledHeadshotWrapper>
                        <StyledHeadshot src={imageSrc} alt={byline} />
                    </StyledHeadshotWrapper>

                    <StyledBylineTextWithHeadshot>
                        {getProfileText(profile, byline)}
                    </StyledBylineTextWithHeadshot>
                </React.Fragment>
            ) : (
                <StyledBylineText>
                    {getProfileText(profile, byline)}
                </StyledBylineText>
            )}
        </StyledByline>
    )
}
CardByline.displayName = 'CardByline'
