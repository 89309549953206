import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import React from 'react'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const promo = require('./images/promo.jpg')

export const StyledMadMondayCard = styled('div')({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    padding: calcRem(8),
    display: 'none',
    [breakpoint('md')]: {
        display: 'block',
    },

    ['& img']: {
        maxWidth: '100%',
    },
})

export const TWStaticMadMondayCard: React.FC = () => {
    const promo = require('./images/promo.jpg')

    return (
        <StyledMadMondayCard>
            <WebLink to="https://7plus.com.au/mad-monday?utm_source=TheWest&utm_medium=show-page-promo-button&utm_campaign=mad-monday">
                <img src={promo} alt="Mad Monday - Monday 7pm" />
            </WebLink>
        </StyledMadMondayCard>
    )
}
