import { TogglesReduxState } from '@news-mono/web-common'
import { getThemedSegment } from './get-themed-segment'

export const grandFinalSegment = (toggles: TogglesReduxState) => {
    const themeOverride = 'grand-final'

    const eightCard = Boolean(toggles['the-west-grand-final-collection-home-8'])

    if (eightCard) {
        return getThemedSegment({
            featureToggle: 'the-west-grand-final-collection-home-8',
            layoutVersion: '8-card',
            curation: 'afl-grand-final',
            themeOverride,
            cardExtensionProps: {
                eightCardExtension: {
                    kind: '8-card',
                    thorProps: {
                        layouts: '8-card',
                        unsetFeatureContainerBackground: true,
                    },
                },
            },
        })
    }

    return getThemedSegment({
        featureToggle: 'the-west-grand-final-collection-home-4',
        layoutVersion: '4-card',
        curation: 'afl-grand-final',
        themeOverride,
        cardExtensionProps: {
            fourCardExtension: {
                kind: '4-card',
                thorProps: {
                    unsetFeatureContainerBackground: true,
                    extraBottomBorder: true,
                    hasBackgroundFill: false,
                    layouts: '4-card',
                },
                limaProps: {
                    hideBottomBorder: true,
                },
            },
        },
    })
}
