import styled from '@emotion/styled'
import { Headline } from '../../publication/Headline/Headline'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { StyledLabel } from '../CookieConsentManager.styled'

export const Styledheading = styled(Headline)(({ theme }) => ({
    marginBottom: theme.margins.lg,
}))

export const StyledUserSettingsCategoryList = styled('ul')({
    listStyleType: 'none',
    padding: 0,
})

export const StyledFieldset = styled('fieldset')(({ theme }) => ({
    padding: 0,
    margin: 0,
    marginBottom: calcRem(theme.margins.md),
    border: 0,

    '& > div': {
        // Some browers (looking at you Edge) don't support flexbox fieldset
        display: 'flex',
        flexDirection: 'column',
    },

    [`${StyledLabel} + ${StyledLabel}`]: {
        marginLeft: 0,
    },
}))
