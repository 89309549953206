import styled from '@emotion/styled'
import { calcRem, fonts } from '@news-mono/component-library'
import { tokens } from '@news-mono/design-tokens'

const chevron = require('./assets/ChevronDown.svg')

export const StyledSelect = styled.select(({ theme }) => ({
    padding: calcRem(12, 12),
    border: `1px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
    borderRadius: calcRem(4),
    backgroundColor: tokens.thenightly.colors.palette.neutral[10],
    width: '100%',
    fontFamily: fonts.thenightly.publication.headline,
    fontWeight: 400,
    fontSize: calcRem(16),

    appearance: 'none',
    backgroundImage: `url(${chevron})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: calcRem(16),
    backgroundPosition: 'right 1rem center',
}))

export const StyledLabel = styled.label(({ theme }) => ({
    color: tokens.thenightly.colors.palette.neutral[100],
    fontSize: calcRem(20),
    lineHeight: calcRem(28),
    fontWeight: 'bold',
}))

export const StyledSelectContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
}))
