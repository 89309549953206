import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakpoint } from '../../__styling'

const cardBackground = require('./images/subscribe-card-background.png')

export const StyledContainer = styled('div')({
    fontFamily: 'futura-pt, sans-serif',
    backgroundRepeat: 'no-repeat',
    padding: calcRem(metrics.thewest.margins.md),
    backgroundImage: `url(${cardBackground})`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 170,
    height: '100%',
    color: colors.white,
    backgroundPosition: 'top center',
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    marginBottom: calcRem(15),
})

const StyledCardText = styled('p')({
    fontFamily: 'futura-pt, sans-serif',
    fontSize: calcRem(24),
    margin: calcRem(5),
    textTransform: 'uppercase',
    textAlign: 'center',
})

export const StyledWatchNowText = styled('div')({
    backgroundColor: colors.thewest.westblue,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: calcRem(15),

    fontWeight: 'bold',
    fontSize: calcRem(15),
    maxWidth: 'fit-content',
    padding: `${calcRem(2, 16)}`,
    margin: `0 auto ${calcRem(16)} auto`,

    [breakpoint('md')]: {
        margin: '0 auto',
    },

    textDecoration: 'none',
    color: colors.white,
    textTransform: 'uppercase',
})

export const StyledButton = styled(WebLink)({
    backgroundColor: colors.thewest.westblue,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: calcRem(15),

    fontWeight: 'bold',
    fontSize: calcRem(15),
    maxWidth: 'fit-content',
    padding: `${calcRem(2, 16)}`,
    margin: `0 auto ${calcRem(16)} auto`,

    [breakpoint('md')]: {
        margin: '0 auto',
    },

    textDecoration: 'none',
    color: colors.white,
    textTransform: 'uppercase',

    '&:hover': {
        backgroundColor: colors.thewest.greyKoala,
    },
})

// Entitled View

export const StyledEntitledText = styled(StyledCardText)({
    marginBottom: calcRem(20),
    lineHeight: 1.2,

    display: 'flex',
    flexDirection: 'column',
    wordSpacing: '100vw',
    justifyContent: 'center',
    letterSpacing: calcRem(3),
    fontWeight: 500,
})

// Opportunity View

export const StyledOpportunityText = styled(StyledCardText)({
    marginBottom: calcRem(24),
    marginTop: calcRem(3.2),
    lineHeight: 1.2,
    fontSize: calcRem(16.4),
    fontWeight: 500,

    display: 'inline',
    letterSpacing: calcRem(2),

    b: {
        fontSize: calcRem(16.8),
        fontWeight: 700,
    },
})

export const StyledHeaderText = styled('h1')({
    fontFamily: 'futura-pt-bold, sans-serif',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    fontSize: calcRem(52),
    lineHeight: 0.9,
    letterSpacing: calcRem(0.5),
    fontWeight: 700,
})

export const StyledSubText = styled('p')({
    margin: `0 0 ${calcRem(24)}`,

    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    fontSize: calcRem(18.4),
    lineHeight: 0.6,
    fontWeight: 500,
})

export const StyledTextEmphasis = styled('em')({
    fontStyle: 'normal',
    borderBottom: `3px solid ${colors.thewest.westblue}`,
})

// West Icon

export const StyledWestMark = styled('div')({
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'nowrap',
    padding: calcRem(metrics.thewest.margins.md),
    justifyContent: 'center',
})

export const StyledSlimWestMark = styled(StyledWestMark)({
    padding: calcRem(12),
})
