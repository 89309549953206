import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { TheWestGenwestLogo } from '../../logos/TheWestGenwestLogo/TheWestGenWestLogo'
import { TheWestLogo } from '../../logos/TheWestLogo/TheWestLogo'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const navHeight = 50

interface StickyProps {
    height: number
    isHidden: boolean
    scrollUp: boolean
    isTop: boolean
}

export const StyledNavigation = styled('div')<StickyProps>((props) => ({
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.thewest.greyDolphin}`,

    [breakpoint('md')]: [
        {
            position: 'sticky',
            zIndex: zIndex.thewest.stickySiteHeader,
            top: props.height,

            animationTimingFunction: 'linear',
            animationDuration: '200ms',
            animationFillMode: 'forwards',
        },
        // Prevents animations
        props.isTop && {
            animation: 'none',
            transform: 'translateY(0)',
        },

        // Animation to Hide once past scroll threshold
        props.isHidden && {
            transform: 'translateY(0)',
            animationName: keyframes({
                '0%': { transform: 'translateY(0)' },
                '100%': {
                    transform: `translateY(-${props.height + navHeight}px)`,
                },
            }),
        },

        !props.isHidden &&
            !props.isTop && {
                transform: `translateY(-${props.height + navHeight}px)`,
                animationName: keyframes({
                    '0%': {
                        transform: `translateY(-${props.height + navHeight}px)`,
                    },
                    '100%': { transform: 'translateY(0)' },
                }),
            },
    ],
}))

//TODO: After test has run merge with current StyledAd
// These are currently different due to slightly different style requirements
export const StyledAd = styled('div')<StickyProps>(
    {
        display: 'none',

        [breakpoint('sm')]: {
            background: colors.thewest.greyErmine,
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            height: 116, // stops jank
            /** leaderboardBillboard ad unit on The West has paddingBottom applied when
             *  ad unit is displaying leaderboard size
             */
            paddingBottom: calcRem(13),

            '&::after': {
                content: `''`,
                boxShadow: `0 ${calcRem(1)} ${calcRem(16)} 0 ${colors.black35}`,
                height: calcRem(10),
                position: 'absolute',
                bottom: -10,
                opacity: 1,
                width: '100%',
            },
        },

        /** remove padding when we're displaying the billboard ad */
        '@media screen and (min-width: 970px )': {
            paddingBottom: 0,
        },
    },
    (props) => ({
        [breakpoint('md')]: [
            {
                position: 'sticky',
                zIndex: zIndex.thewest.stickySiteHeader,
                top: 0,

                animationTimingFunction: 'linear',
                animationDuration: '200ms',
                animationFillMode: 'forwards',
            },
            // Stop it from animating
            props.isTop && {
                animation: 'none',
                transform: 'translateY(0)',
            },

            // Animation to Hide once past scroll threshold
            props.isHidden && {
                transform: 'translateY(0)',
                animationName: keyframes({
                    '0%': { transform: 'translateY(0)' },
                    '100%': { transform: `translateY(-${props.height}px)` },
                }),
            },

            // Animation to animate back in on scroll up
            !props.isHidden &&
                !props.isTop && {
                    animationName: keyframes({
                        '0%': {
                            transform: `translateY(-${props.height}px)`,
                        },
                        '100%': {
                            transform: 'translateY(0)',
                        },
                    }),
                },
        ],
    }),
)

export const StyledHeaderContainer = styled('div')({
    background: colors.white,
})

export const StyledTWLogoWrapHeading = styled('div')({
    background: colors.thewest.greyErmine,
    display: 'block',
})

export const StyledTheWestLogo = styled(TheWestLogo)({
    display: 'block',
    height: calcRem(29),
    padding: calcRem(metrics.thewest.margins.sm),
    margin: '0 auto',
    [breakpoint('sm')]: {
        width: calcRem(200),
        height: 'auto',
    },
})

export const StyledTheWestGenwestLogo = styled(TheWestGenwestLogo)({
    display: 'block',
    height: calcRem(29),
    padding: calcRem(metrics.thewest.margins.sm),
    margin: '0 auto',
    [breakpoint('sm')]: {
        width: calcRem(200),
        height: 'auto',
    },
})
