import styled, { CSSObject } from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { fonts, metrics } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { IconEmailSevennews, IconX } from '../../icons'

export const StyledBioBlock = styled.div({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',

    [breakpoint('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: calcRem(0, 16, 0, 0),
    },
})

export const StyledText = styled.div({
    textAlign: 'center',
    width: '100%',
})

export const StyledAuthor = styled.h1({
    textAlign: 'center',
    width: '100%',
    margin: calcRem(metrics.sevennews.margins.sm, 0, 0, 0),
})

export const StyledName = styled.span({
    color: tokens.sevennews.colors.palette.nero,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(22),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: calcRem(25),
    textTransform: 'capitalize',
    display: 'block',
})

export const StyledPosition = styled.span({
    color: tokens.sevennews.colors.palette.nero,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 400,
    display: 'block',
    marginTop: calcRem(8),
})

export const StyledBioText = styled.p({
    color: tokens.sevennews.colors.palette.nero,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(14),
    fontWeight: 400,
    display: 'block',
    margin: 0,
})

export const StyledImageContainer = styled.div({
    flex: '0 0 auto',
    alignSelf: 'center',
    width: calcRem(104),
    height: calcRem(104),
    overflow: 'hidden',
    borderRadius: '50%',
    order: 0,
})

export const StyledImage = styled.img({
    transform: `translate(${calcRem(-111 / 4)})`, // Offset so the image centres.
    width: 'auto',
    height: '100%',
})

export const StyledSocialLink = styled(WebLink)({
    display: 'inline-block',
    color: colors.sevennews.nero50,
    fontSize: calcRem(14),
    fontWeight: 600,
    textDecoration: 'none',
    padding: 0,
    verticalAlign: 'middle',

    '&:hover': {
        textDecoration: 'underline',
    },
    '&:focus': {
        textDecoration: 'none',
    },
})

const SocialIconStyles: CSSObject = {
    width: calcRem(30),
    height: calcRem(30),
    padding: calcRem(5),
    margin: calcRem(2, 0),
    verticalAlign: 'middle',
}

export const StyledXIcon = styled(IconX)(SocialIconStyles, {
    fill: colors.sevennews.nero50,
    padding: calcRem(8),
})
export const StyledEmailIcon = styled(IconEmailSevennews)(SocialIconStyles, {
    fill: colors.sevennews.nero50,
})

export const StyledProfileEmailButton = styled(WebLink)({})
