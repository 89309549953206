import { Profile } from '@news-mono/web-common'
import React from 'react'
import {
    StyledFactCheckerHeadshot,
    StyledFactCheckerHeadshotWrapper,
    StyledFactCheckerSectionHeader,
} from './FactCheckerSectionHeader.styled'

export interface FactCheckerSectionHeaderProps {
    profiles: Profile[] | false | undefined
    sizing?: 'default' | 'smaller'
}

export function FactCheckerSectionHeader(props: FactCheckerSectionHeaderProps) {
    const { profiles, sizing = 'default' } = props

    const imgSrc = profiles && profiles[0]?.thumbnailPhoto
    const name = profiles && profiles[0]?.name

    return (
        <StyledFactCheckerSectionHeader sizing={sizing}>
            {imgSrc && (
                <StyledFactCheckerHeadshotWrapper sizing={sizing}>
                    <StyledFactCheckerHeadshot
                        src={imgSrc}
                        alt={name ? name : ''}
                        sizing={sizing}
                    />
                </StyledFactCheckerHeadshotWrapper>
            )}
            <h2>Fact Checker {name && <span>with {name}</span>}</h2>
        </StyledFactCheckerSectionHeader>
    )
}
