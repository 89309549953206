import React, { useContext, useEffect, useState } from 'react'
import {
    StyledToggleContainer,
    StyledToggleLabel,
} from './SaveArticleToggle.styled'
import { IconBookmark } from '../../icons/IconBookmark/IconBookmark'
import {
    AppState,
    AuthenticationState,
    ConfigurationContext,
    DataLayerEventName,
    LinkClickedEvent,
    PublicationEvents,
    SavedArticlesSaveEvent,
    SavedArticlesUnsaveEvent,
} from '@news-mono/web-common'
import { useSelector } from 'react-redux'
import {
    errorToastSubText,
    getSavedPublications,
    isPublicationSaved,
    saveArticleToUserProfile,
    unsaveArticleFromUserProfile,
} from '../helpers'
import {
    ToastMessage,
    UserSettingsSlideToast,
} from '../../content/UserSettingsSlideToast/UserSettingsSlideToast'
import { useAuthActions } from '../../user-registration'

export interface SaveArticleToggleProps {
    publicationId?: string
    onEvent?: (
        event:
            | PublicationEvents
            | SavedArticlesSaveEvent
            | SavedArticlesUnsaveEvent,
    ) => void
}

const raiseOnClickAnalytics = (
    onEvent: (event: LinkClickedEvent) => void,
    text: string,
    url: string,
) => {
    onEvent({
        type: DataLayerEventName.linkClicked,
        originator: 'SaveArticleToggle',
        payload: {
            text,
            linkType: 'in-article',
            opensInNewWindow: false,
            url,
        },
    })
}

export const SaveArticleToggle: React.FC<SaveArticleToggleProps> = ({
    publicationId,
    onEvent,
}) => {
    const saveLabel = 'Save'
    const unSaveLabel = 'Unsave'

    const [saved, setSaved] = useState<boolean>(false)
    const [interactionId, setInteractionId] = useState<string | undefined>(
        undefined,
    )
    const [toast, setToast] = useState<ToastMessage>({
        text: '',
        subText: undefined,
        type: 'success',
        show: false,
    })

    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'

    const { onRegisterClick } = useAuthActions()

    function onDismissToast() {
        setToast({ ...toast, show: false })
    }

    const config = useContext(ConfigurationContext)
    const {
        wanUserId,
        isLoggedIn,
        isLoading: isAuthLoading,
    } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const label = saved ? unSaveLabel : saveLabel

    const onToggleClick = async () => {
        const analyticsText = `${label} article to user profile`
        onEvent &&
            raiseOnClickAnalytics(
                onEvent,
                analyticsText,
                '/user-profile/user-interaction',
            )

        if (!saved) {
            const saveArticleResult = await saveArticleToUserProfile(
                config,
                wanUserId,
                publicationId,
            )
            if (saveArticleResult && saveArticleResult.success) {
                if (saveArticleResult.id) {
                    setSaved(true)
                    setInteractionId(String(saveArticleResult.id))

                    if (onEvent) {
                        onEvent({
                            type: DataLayerEventName.savedArticlesSave,
                            originator: 'SavedArticleToggle',
                            payload: {
                                publicationId: publicationId!,
                            },
                        })
                    }
                }
            } else {
                setToast({
                    text: saveArticleResult.statusText,
                    subText: errorToastSubText,
                    type: 'error',
                    show: true,
                })
            }
        } else {
            const unsaveArticleResult = await unsaveArticleFromUserProfile(
                config,
                interactionId,
            )
            if (unsaveArticleResult && unsaveArticleResult.success) {
                setSaved(false)
                setInteractionId(undefined)

                if (onEvent) {
                    onEvent({
                        type: DataLayerEventName.savedArticlesUnsave,
                        originator: 'SavedArticleToggle',
                        payload: {
                            publicationId: publicationId!,
                        },
                    })
                }
            } else {
                setToast({
                    text: unsaveArticleResult.statusText,
                    subText: errorToastSubText,
                    type: 'error',
                    show: true,
                })
            }
        }
    }

    useEffect(() => {
        if (!isAuthLoading) {
            setIsLoading(true)
            isLoggedIn && wanUserId && publicationId && config
                ? getSavedPublications(config, wanUserId).then((response) => {
                      if (response.success) {
                          const foundPublication =
                              response.documents &&
                              isPublicationSaved(
                                  publicationId,
                                  response.documents,
                              )
                          if (foundPublication) {
                              setSaved(true)
                              setInteractionId(String(foundPublication.id))
                          } else {
                              setSaved(false)
                              setInteractionId(undefined)
                          }
                      }
                      setIsLoading(false)
                  })
                : setIsLoading(false)
        }
    }, [config, publicationId, wanUserId, isAuthLoading, isLoggedIn])

    return (
        <>
            {!isAuthLoading && !isLoading && (
                <>
                    <UserSettingsSlideToast
                        text={toast.text}
                        subText={toast.subText}
                        type={toast.type}
                        show={toast.show}
                        onClose={onDismissToast}
                        fixedAtBottom={true}
                    />
                    <StyledToggleContainer
                        id="save-article-toggle"
                        onClick={() => {
                            isLoggedIn
                                ? onToggleClick()
                                : onRegisterClick('save-article', renditionType)
                        }}
                    >
                        <IconBookmark filled={saved} />
                        <StyledToggleLabel>{label}</StyledToggleLabel>
                    </StyledToggleContainer>
                </>
            )}
        </>
    )
}
