import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../__styling'
interface StyledPollieSliderTooltipProps {
    value: number
    isDisabled: boolean
}

export const StyledPollieSliderTooltip = styled(
    'div',
)<StyledPollieSliderTooltipProps>(({ theme, value, isDisabled }) => ({
    gridColumnStart: `${value * 2 - 1}`,
    gridColumnEnd: `${value * 2 - 1}`,
    gridRowStart: 1,
    gridRowEnd: 2,
    width: 36,
    height: '100%',
    backgroundColor: isDisabled
        ? tokens.thewest.colors.palette.greyQuokka
        : theme.colors.brand,
    borderRadius: 2,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&::after': {
        position: 'absolute',
        right: '3px',
        top: '26px',
        content: `''`,
        borderWidth: calcRem(15),
        borderStyle: 'solid',
        borderColor: `${
            isDisabled
                ? tokens.thewest.colors.palette.greyQuokka
                : theme.colors.brand
        } transparent transparent transparent `,
        width: calcRem(24),
        height: calcRem(24),
    },
    '& > span': {
        zIndex: 3,
        color: theme.colors.palette.white,
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 600,
        fontSize: 24,
    },
}))

interface StyledPollieSliderLabelProps {
    backgroundPosition: number
}

export const StyledPollieSliderLabel = styled(
    'div',
)<StyledPollieSliderLabelProps>(({ theme, backgroundPosition }) => ({
    gridColumnStart: `${
        backgroundPosition * 2 - 2 === 0 ? 1 : backgroundPosition * 2 - 2
    }`,
    gridColumnEnd: `${backgroundPosition * 2 + 1}`,
    gridRowStart: 1,
    gridRowEnd: 2,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',

    '& > span': {
        color: tokens.thewest.colors.palette.greyQuokka,
        fontFamily: theme.fonts.sansSerif,
    },
}))

export const StyledPollieToolTipGrid = styled('div')(({ theme }) => ({
    gridArea: '1 / 1 / 2 / 2',
    height: 36,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(19, 1fr)`,
    gridTemplateRows: `repeat(1, 1fr)`,
    gridColumnGap: 0,
    gridRowGap: 0,
}))

export const StyledPollieFormContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoint('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
}))
export const StyledPollieSliderContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: 72,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    alignItems: 'center',
    justifyItems: 'center',
}))

export const StyledPollieInputLinesContainer = styled('div')(({ theme }) => ({
    gridArea: '2 / 1 / 2 / 2',
    height: 28,
    // magic linear relationship to get scaling working
    width: 'calc(103.75% - 31px)',
    display: 'grid',
    gridTemplateColumns: `repeat(19, 1fr)`,
    gridTemplateRows: `repeat(1, 1fr)`,
    gridColumnGap: 0,
    gridRowGap: 0,
    // markers for scale 'ruler'
    '& > div:nth-child(odd)': {
        background: `linear-gradient(${tokens.thewest.colors.palette.greyQuokka},${tokens.thewest.colors.palette.greyQuokka}) center/2px 70% no-repeat`,
    },

    '& > div:first-child': {
        background: `linear-gradient(${tokens.thewest.colors.palette.greyQuokka},${tokens.thewest.colors.palette.greyQuokka}) center/2px 100% no-repeat`,
    },

    '& > div:last-child': {
        background: `linear-gradient(${tokens.thewest.colors.palette.greyQuokka},${tokens.thewest.colors.palette.greyQuokka}) center/2px 100% no-repeat`,
    },
}))
interface StyledPollieSliderProps {
    backgroundPosition: number
}

export const StyledPollieSlider = styled('input')<StyledPollieSliderProps>(
    ({ theme, backgroundPosition }) => ({
        cursor: 'pointer',
        position: 'relative',
        gridArea: '2 / 1 / 2 / 2',
        // magic linear relationship to get scaling working
        width: 'calc(100% - 17px)',
        height: 2,
        '-webkit-appearance': 'none !important',
        background: `linear-gradient(to right, ${theme.colors.brand} ${
            (backgroundPosition - 1) * 11.1
        }%, ${tokens.thewest.colors.palette.greyQuokka} ${
            (backgroundPosition - 1) * 11.1
        }%)`,

        '&::-webkit-slider-runnable-track': {
            '-webkit-appearance': 'none',
            height: '20px',
        },

        '&::-webkit-slider-thumb': {
            '-webkit-appearance': 'none !important',
            width: 18,
            height: 18,
            background: theme.colors.brand,
            border: `2px solid ${theme.colors.brand}`,
            borderRadius: '50%',
            cursor: 'pointer',
        },
        '&:disabled': {
            background: tokens.thewest.colors.palette.greyQuokka,
            '&::-webkit-slider-thumb': {
                background: tokens.thewest.colors.palette.greyQuokka,
                border: `2px solid ${tokens.thewest.colors.palette.greyQuokka}`,
            },
        },
    }),
)

export const Spinner = styled('div')(({ theme }) => ({
    display: 'inline-block',
    width: '15px',
    height: '15px',
    border: `2px solid ${tokens.thewest.colors.palette.greyQuokka}`,
    borderRadius: '50%',
    borderTopColor: '#fff',
    animation: `${spin} 0.8s infinite`,
    '-webkit-animation': `${spin} 0.8s infinite`,
    position: 'relative',
    top: '2px',
    left: '0%',
}))
const spin = keyframes({
    to: {
        transform: 'rotate(360deg)',
    },
})
