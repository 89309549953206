import {
    AdDefinition,
    AdEvent,
    AdState,
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React, { FC } from 'react'
import {
    AdUnitWrapper,
    AdUnitWrapperProps,
} from '../../../advertising/AdUnit/AdUnitWrapper'
import { FeatureToggle } from '../../../feature-toggling/Toggle/Toggle'
import {
    StyledAmpAd,
    StyledAmpAdWrapper,
    StyledAmpSpace,
} from '../../../google-amp/amp.styled'
import { AmpAd } from '../../../google-amp/components/AmpAd'
import { AmpBigMobileAd } from '../../../google-amp/components/AmpBigMobileAd'
import { createRenderTarget } from '../../../render-target'
import { InlinePositionedContent } from '../../../templates/Publication/Publication.props'
import {
    InlineAdContent,
    InlineContentTypes,
} from '../../../templates/Publication/SharedPublication.routing'

export interface InlinePositionedAdsProps {
    unit: AdUnitWrapperProps & {
        slot: AdDefinition
    }
    ads: AdState
    position: number
    adEvent: (event: AdEvent) => void
}

const WebAdUnit: FC<InlinePositionedAdsProps> = ({ unit, ads, adEvent }) => (
    <AdUnitWrapper
        {...unit}
        key={unit.slot.id}
        adState={ads}
        unitId={unit.slot.id}
        onEvent={adEvent}
        adType={'inline'}
    />
)

const AmpAdUnit: FC<InlinePositionedAdsProps> = ({ unit, ads, position }) => (
    <StyledAmpAdWrapper>
        <StyledAmpSpace />
        <StyledAmpAd id={`inlineAdContent[${position}]`}>
            <AmpAd adState={ads} unitId={unit.slot.id} />
        </StyledAmpAd>
    </StyledAmpAdWrapper>
)

const AdUnit = createRenderTarget('adUnit', {
    web: WebAdUnit,
    amp: AmpAdUnit,
    preview: WebAdUnit,
    rss: WebAdUnit,
    app: WebAdUnit,
})

const mapAdDefinitionToInlineElement = (
    adContent: InlineAdContent,
    ads: AdState,
    publication: ArticleLikePublication,
    adEvent: (event: AdEvent) => void,
): InlinePositionedContent | undefined => {
    const insertAfter = adContent.insertAfter ? adContent.insertAfter : 4

    const firstContentBlocks = publication.content.slice(0, insertAfter)
    let alignedImagePosition: number | undefined

    firstContentBlocks.some((block, index) => {
        if (
            block.kind === 'inline' &&
            block.inlineKind === 'image' &&
            block.align !== undefined
        ) {
            alignedImagePosition = index
            return true
        }
        return false
    })

    if (alignedImagePosition && alignedImagePosition < insertAfter) {
        return undefined
    }

    const position =
        alignedImagePosition === insertAfter ? insertAfter - 1 : insertAfter

    return {
        element: (
            <React.Fragment>
                {adContent.content.map((unit) => (
                    <AdUnit
                        key={unit.slot.id}
                        unit={unit}
                        ads={ads}
                        position={position}
                        adEvent={adEvent}
                    />
                ))}
            </React.Fragment>
        ),
        position,
    }
}

export function isInlineAdContent(
    content: InlineContentTypes,
): content is InlineAdContent {
    return content.kind === 'ad'
}

export function isInlineAdType(content: InlineAdContent) {
    return content.content[0].adType === 'inline'
}

// TODO not sure if this is correct but the previous product version had an extra data field which seemed to be duped.
interface GetInlinePositionedContentProps {
    adState: AdState
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AdEvent) => void
}

export const getInlinePositionedAds = (
    props: GetInlinePositionedContentProps,
): InlinePositionedContent[] => {
    const { adState, publication, inlinePublicationContent, onEvent } = props

    if (!isArticleLikeType(publication)) {
        return []
    }

    /** Don't insert ads if there are less than 8 content blocks in the story */
    if (publication.content.length < 8) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineAdContent)
        .filter(isInlineAdType)
        .reduce((acc: InlinePositionedContent[], content: InlineAdContent) => {
            const mappedAdContent = mapAdDefinitionToInlineElement(
                content,
                adState,
                publication,
                onEvent,
            )
            const position = mappedAdContent?.position || 0

            if (mappedAdContent) {
                if (position < publication.content.length) {
                    acc.push(mappedAdContent)
                }
            }
            return acc
        }, [])
}
