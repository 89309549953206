import React from 'react'
import { PlayButton } from './assets/PlayButton'
import { SevenPlusLogo } from './assets/SevenPlusLogo'
import { StyledButton } from './ScoreBoard.styled'

interface ScoreBoardMoreButtonProps {
    type: 'cricket' | 'afl'
    gameId?: string
    isAflw?: boolean
}

export const ScoreBoardMoreButton = ({
    type,
    gameId,
    isAflw,
}: ScoreBoardMoreButtonProps) => {
    const aflwMoreAflwLink =
        'https://7plus.com.au/aflw?utm_source=7NEWS&utm_medium=match-centre-button&utm_campaign=aflw'
    const aflwWatchLiveButtonLink =
        'https://7plus.com.au/aflw?utm_source=7NEWS&utm_medium=match-centre-button-live&utm_campaign=aflw'

    const isAflFinal = gameId === '120390401'

    switch (type) {
        case 'cricket':
            return (
                <StyledButton to="https://7plus.com.au/cricket?utm_source=7NEWS&utm_medium=match-centre&utm_campaign=cricket">
                    <PlayButton />
                    {gameId === '57006' ? 'Watch Live on ' : 'More Cricket on '}
                    <SevenPlusLogo />
                </StyledButton>
            )
        case 'afl':
            return (
                <StyledButton
                    to={
                        isAflw
                            ? gameId && liveAflwGamesId.includes(gameId)
                                ? aflwWatchLiveButtonLink
                                : aflwMoreAflwLink
                            : 'https://7plus.com.au/afl?utm_source=7NEWS&utm_medium=afl-match-centre-button&utm_campaign=afl'
                    }
                >
                    <PlayButton />{' '}
                    {isAflw
                        ? gameId && liveAflwGamesId.includes(gameId)
                            ? 'Watch Live on'
                            : 'More AFLW on'
                        : isAflFinal
                        ? 'Watch Live on'
                        : 'More AFL on'}
                    <SevenPlusLogo />
                </StyledButton>
            )
        default:
            return null
    }
}

const liveAflwGamesId = [
    '122950101',
    '122950105',
    '122950106',
    '122950107',
    '122950204',
    '122950206',
    '122950208',
    '122950304',
    '122950306',
    '122950307',
    '122950404',
    '122950406',
    '122950408',
    '122950504',
    '122950506',
    '122950507',
    '122950508',
    '122950601',
    '122950606',
    '122950608',
    '122950607',
    '122950701',
    '122950706',
    '122950707',
    '122950708',
    '122950801',
    '122950806',
    '122950807',
    '122950808',
    '122950906',
    '122950907',
    '122950908',
]
