import { Product } from '@news-mono/web-common'
import { Theme } from '../__styling/themes'
import { sections } from './settings/sections'
import { PerthNowSectionValues } from './settings/sections/perthnow'
import { SevenNewsSectionValues } from './settings/sections/sevennews'
import { TheWestSectionValues } from './settings/sections/thewest'
import { TheNightlySectionValues } from './settings/sections/thenightly'

/*
Usage:

IMPORTANT: If a invalid section is provided to a particular theme and it doesnt resolve against
the expected list of sections for that theme, then the default section will be returned as the value
to be provided within the callback

With theme:

const value = themedValue(theme, {
    perthnow: (section) => section.globalNav.primaryColor,
    thewest: colors.thewest.blue,
    fallback: 'red'
})
*/

interface PropsWithSectionFull<
    TheWestType,
    SevenNewsType,
    PerthNowType,
    TheNightlyType,
    FallbackType,
> {
    perthnow: ((section: PerthNowSectionValues) => PerthNowType) | PerthNowType
    sevennews:
        | ((section: SevenNewsSectionValues) => SevenNewsType)
        | SevenNewsType
    thewest: ((section: TheWestSectionValues) => TheWestType) | TheWestType
    thenightly:
        | ((section: TheNightlySectionValues) => TheNightlyType)
        | TheNightlyType
    fallback?: FallbackType
}

interface PropsWithSectionPartial<
    TheWestType,
    SevenNewsType,
    PerthNowType,
    TheNightlyType,
    FallbackType,
> {
    perthnow?: ((section: PerthNowSectionValues) => PerthNowType) | PerthNowType
    sevennews?:
        | ((section: SevenNewsSectionValues) => SevenNewsType)
        | SevenNewsType
    thewest?: ((section: TheWestSectionValues) => TheWestType) | TheWestType
    thenightly?:
        | ((section: TheNightlySectionValues) => TheNightlyType)
        | TheNightlyType
    fallback: FallbackType
}

// When using these helpers, we want people to make a concious decision to set fallback
// to undefined, rather than it just being the default when not specified
export type PropsWithSection<
    TheWestType,
    SevenNewsType,
    PerthNowType,
    TheNightlyType,
    FallbackType,
> =
    | PropsWithSectionFull<
          TheWestType,
          SevenNewsType,
          PerthNowType,
          TheNightlyType,
          FallbackType
      >
    | PropsWithSectionPartial<
          TheWestType,
          SevenNewsType,
          PerthNowType,
          TheNightlyType,
          FallbackType
      >

function getFallbackValue(fallback: any | (() => any)) {
    return typeof fallback === 'function' ? fallback() : fallback
}

function returnValue<SectionValues>(
    value: any | ((section: SectionValues) => any),
    sectionValues: SectionValues,
) {
    if (typeof value === 'function') {
        return value(sectionValues)
    }
    return value
}

/** @deprecated Use theming system instead. https://wanews.atlassian.net/wiki/spaces/REF/pages/153714860/Theming+Restructure */
export function themedValue<
    TheWestType = never,
    SevenNewsType = never,
    PerthNowType = never,
    TheNightlyType = never,
    FallbackType = TheWestType | SevenNewsType | PerthNowType | TheNightlyType,
>(
    theme: Theme,
    options: PropsWithSection<
        TheWestType,
        SevenNewsType,
        PerthNowType,
        TheNightlyType,
        FallbackType
    >,
): TheWestType | SevenNewsType | PerthNowType | TheNightlyType | FallbackType {
    const { perthnow, thewest, sevennews, thenightly, fallback } = options

    switch (theme.kind) {
        case Product.PerthNow: {
            if (options.hasOwnProperty('perthnow')) {
                return returnValue<PerthNowSectionValues>(
                    perthnow,
                    (theme.sectionValues as PerthNowSectionValues) ||
                        sections.perthnow.default,
                )
            }

            return getFallbackValue(fallback!)
        }
        case Product.TheWest: {
            if (options.hasOwnProperty('thewest')) {
                return returnValue<TheWestSectionValues>(
                    thewest,
                    (theme.sectionValues as TheWestSectionValues) ||
                        sections.thewest.default,
                )
            }

            return getFallbackValue(fallback!)
        }
        case Product.SevenNews: {
            if (options.hasOwnProperty('sevennews')) {
                return returnValue<SevenNewsSectionValues>(
                    sevennews,
                    (theme.sectionValues as SevenNewsSectionValues) ||
                        sections.sevennews.default,
                )
            }

            return getFallbackValue(fallback!)
        }
        case Product.TheNightly: {
            if (options.hasOwnProperty('thenightly')) {
                return returnValue<TheNightlySectionValues>(
                    thenightly,
                    (theme.sectionValues as TheNightlySectionValues) ||
                        sections.thenightly.default,
                )
            }

            return getFallbackValue(fallback!)
        }

        default:
            ;((theme: never) => {
                throw new Error(
                    `Invalid theme provided - themeValue: ${theme} `,
                )
            })(theme.kind)
    }
}
