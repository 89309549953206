import { cx } from '@emotion/css'
import React from 'react'
import {
    StyledAside,
    StyledFeatureComposition,
    StyledMain,
    StyledSidebar,
} from '../../compositions/FeatureComposition/FeatureComposition.styled'
import {
    ContainerWidth,
    ThemeMarginKeys,
} from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'

export interface FeatureCompositionProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarContent: React.ReactElement<any>
    skinnyContent: React.ReactElement<any>
    hasTopSpacing?: boolean
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | ThemeMarginKeys
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | ThemeMarginKeys
    verticalSpacing?: ThemeMarginKeys
    containerWidth?: ContainerWidth
    horizontalGap?: ThemeMarginKeys
}

export const FeatureComposition: React.FC<FeatureCompositionProps> = ({
    mainContent,
    sidebarContent,
    skinnyContent,
    hasBackgroundFill,
    horizontalGutters,
    verticalGutters,
    verticalSpacing,
    horizontalGap,
}) => {
    return (
        <StyledFeatureComposition
            horizontalGutters={horizontalGutters}
            verticalGutters={verticalGutters}
            verticalSpacing={verticalSpacing}
            horizontalGap={horizontalGap}
        >
            <StyledAside>{skinnyContent}</StyledAside>
            <StyledMain hasBackgroundFill={hasBackgroundFill}>
                {mainContent}
            </StyledMain>
            <StyledSidebar className={cx('hide-print')}>
                {sidebarContent}
            </StyledSidebar>
        </StyledFeatureComposition>
    )
}
