import { SizeMapping } from '../advertising/size-mapping'

export function getAdSize(
    sizemapping: SizeMapping[] | undefined,
    windowWidth = window.innerWidth,
): SizeMapping | null {
    if (sizemapping) {
        const sortedByViewport = sizemapping.sort((a, b) =>
            a.viewport[0] < b.viewport[0] ? 1 : -1,
        )

        for (const s of sortedByViewport) {
            if (s.viewport[0] <= windowWidth) {
                if (s.slot.length > 0) {
                    return s
                }

                return null
            }
        }
    }
    return null
}

export function pickFirstSizeMapping(
    size: SizeMapping | null,
): [number, number] | null {
    if (!size) {
        return null
    }

    return pickFirstSize(size.slot)
}

export function pickFirstSize(
    size: [number, number] | Array<[number, number]> | null,
): [number, number] | null {
    if (size && typeof size[0] !== 'number') {
        return size[0]
    }

    return size as any
}
