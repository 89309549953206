import styled, { CSSObject } from '@emotion/styled'
import {
    BreakpointRatios,
    FixedRatio,
    ImageRatio,
    ImageSource,
} from '@news-mono/web-common'
import { KnownRatioToRatio } from '@west-australian-newspapers/publication-types'
import {
    calculateIntrinsicRatio,
    forEachCropBreakpoint,
} from '../../content/Picture/utils'
import { breakpointMax } from '../../__styling/style-functions'
import {
    BreakpointState,
    breakpointSwitch,
} from '../../__styling/style-functions/breakpointState'
import { ThemeConfig } from '../../__styling/themes'

export interface StyledPictureProps {
    backgroundColor?: string
    fixedRatio: FixedRatio | BreakpointRatios
    /** If undefined, original crops will not have intrinsic placeholders */
    image: ImageSource | undefined
    CSSCrop?: BreakpointState<ThemeConfig, ImageRatio>
}

export const PictureWithIntrinsicRatio = styled('picture')<StyledPictureProps>(
    ({ fixedRatio, image, backgroundColor, CSSCrop }) => {
        if (fixedRatio === 'original') {
            if (!image) {
                return {}
            }
            const originalCrop = image.crops['original']
            if (!originalCrop) {
                return {}
            }
            return {
                backgroundColor,
                content: `''`,
                display: 'block',
                paddingBottom: calculateIntrinsicRatio({
                    width: originalCrop.width,
                    height: originalCrop.height,
                }),
            }
        }
        if (typeof fixedRatio === 'string') {
            return {
                backgroundColor,
                content: `''`,
                display: 'block',
                paddingBottom: calculateIntrinsicRatio(
                    KnownRatioToRatio[fixedRatio],
                ),
            }
        }

        if (CSSCrop) {
            return [
                {
                    backgroundColor,
                    content: `''`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    transition: 'aspect-ratio 0.25s',
                },
                breakpointSwitch(CSSCrop, ({ width, height }) => ({
                    aspectRatio: `${width} / ${height}`,
                    paddingBottom: calculateIntrinsicRatio({ width, height }),
                })),
            ]
        }

        const breakpointStyles: CSSObject = {
            backgroundColor,
            content: `''`,
            display: 'block',
        }

        forEachCropBreakpoint(fixedRatio, (cropBreakpoint, knownRatio) => {
            if (!knownRatio) {
                return
            }

            if (knownRatio === 'original') {
                if (!image) {
                    return
                }
                const originalCrop = image.crops['original']
                if (!originalCrop) {
                    return
                }
                breakpointStyles.paddingBottom = calculateIntrinsicRatio({
                    width: originalCrop.width,
                    height: originalCrop.height,
                })
                return
            }

            const ratio = KnownRatioToRatio[knownRatio]
            if (cropBreakpoint === 'default') {
                breakpointStyles.paddingBottom = calculateIntrinsicRatio(ratio)
            } else {
                breakpointStyles[breakpointMax(cropBreakpoint)] = {
                    paddingBottom: calculateIntrinsicRatio(ratio),
                }
            }
        })

        return {
            '&::after': breakpointStyles,
        }
    },
)
