import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ThemeConfig } from '../../__styling'
import {
    ComponentOverrider,
    ComponentOverriderProps,
} from './ComponentOverrider'

export const getComponentOverriderRegistration = <T extends ThemeConfig>() =>
    createRegisterableComposition<'children'>()(
        'component-overrider',
        (contentAreas, { override }: ComponentOverriderProps<T>) => (
            <ComponentOverrider<T> override={override}>
                {contentAreas.children}
            </ComponentOverrider>
        ),
    )
