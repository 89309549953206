import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import {
    horizontalDividers,
    verticalDividers,
} from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    hasBorder: boolean
    stopGridConfiguration?: boolean // Disables specific grid configuration, leaving it to the child StyledUniformCardContainer to do the Grid styling.
    hasCustomBorders?: boolean
}

export const StyledUniformClassName = 'StyledUniform'

export const StyledUniform = withClass(StyledUniformClassName)(
    styled('div')<GridProps>(
        ({
            theme,
            verticalSpacing,
            hasBorder,
            stopGridConfiguration,
            hasCustomBorders,
        }) => [
            {
                display: 'grid',
                gridGap: theme.margins.gridGap,
                gridTemplateColumns: stopGridConfiguration ? undefined : '1fr',
                position: 'relative',
                width: '100%',
                margin: '0 auto',
                marginBottom:
                    verticalSpacing &&
                    themedValue(theme, {
                        thewest: calcRem(theme.margins[verticalSpacing]),
                        fallback: undefined,
                    }),

                [breakpoint('sm')]: {
                    gridTemplateColumns: stopGridConfiguration
                        ? undefined
                        : 'repeat(2, 1fr)',
                },

                [breakpoint('md')]: {
                    gridTemplateColumns: stopGridConfiguration
                        ? undefined
                        : `repeat(4, 1fr)`,
                },
                [breakpointMax('xxs')]: {
                    gridGap:
                        theme.kind === 'thewest'
                            ? metrics.thewest.margins.gridGapMobile
                            : theme.margins.gridGap,
                },
            },
            // Dividers and borders for the west
            theme.kind === 'thewest' &&
                !hasCustomBorders && {
                    paddingBottom: metrics.thewest.margins.sm,
                    borderBottom: `4px double ${theme.colors.borders.secondary}`,
                    [breakpoint('md')]: {
                        borderBottom: hasBorder
                            ? `4px double ${theme.colors.borders.secondary}`
                            : 'none',
                    },
                },
        ],
    ),
)

StyledUniform.displayName = 'StyledUniform'

export const StyledUniformCardContainerClassName = 'StyledUniformCardContainer'

export const StyledUniformCardcontainer = withClass(
    StyledUniformCardContainerClassName,
)(
    styled('div')<{
        hasCustomBorders?: boolean
    }>(
        ({ theme }) => [
            {
                display: 'grid',
                gridGap: theme.margins.gridGap,
                gridTemplateColumns: '1fr',

                [breakpointMax('xxs')]: {
                    gridGap:
                        theme.kind === 'thewest'
                            ? metrics.thewest.margins.gridGapMobile
                            : theme.margins.gridGap,
                },
            },
        ],
        ({ theme, hasCustomBorders }) => [
            // Dividers
            theme.kind === 'thewest' &&
                !hasCustomBorders && {
                    position: 'relative',

                    // Horizontal Divider For stacked cards inside a column
                    '& > *:not(:last-child)::before': {
                        ...horizontalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },

                    '@media screen and (min-width: 768px)': {
                        ':not(:first-child)::after': {
                            ...verticalDividers,
                            backgroundColor: theme.colors.borders.primary,
                        },
                    },

                    // Small devices for bottom of columns
                    [breakpointMax('sm')]: {
                        '&:not(:last-child)::before': {
                            ...horizontalDividers,
                            backgroundColor: theme.colors.borders.primary,
                        },
                    },

                    [breakpointBetween('sm', 'md')]: {
                        // Add horizontal dividers on the bottom of the first row of cards
                        ':nth-child(-n + 2)::before': {
                            ...horizontalDividers,
                        },

                        // Remove Vertical Dividers so it wont appear above "Medium breakpoint"
                        ':nth-child(2n + 1)::after': {
                            content: 'none',
                        },
                    },
                },

            theme.kind === 'sevennews' && {
                position: 'relative',
            },
        ],
    ),
)

StyledUniformCardcontainer.displayName = 'UniformCardContainer'

export const StyledHeader = styled('div')(({ theme }) => ({
    display: 'block',
    marginBottom: theme.margins.md,
}))
