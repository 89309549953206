import styled from '@emotion/styled'
import {
    FundingLabel,
    FundingLabelProps,
} from '../../advertising/FundingLabel/FundingLabel'
import { playButtonIconClass } from '../../buttons/PlayButton/PlayButton.styled'
import { CardMedia, CardMediaProps } from '../../cards/CardMedia/CardMedia'
import { CardHeadline } from '../../cards/CardText/CardHeadline'
import { CardTeaser } from '../../cards/CardText/CardTeaser'
import { StyledHeadlineText } from '../../cards/CardText/CardText.styled'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { Timestamp } from '../../typography/Timestamp/Timestamp'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { CardHeadlineSkeleton } from '../CardText/CardHeadlineSkeleton'

export const StyledLandscapeStacked = styled('div')<{
    hasBackground?: boolean
}>(
    {
        ...breakWords,
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        flexGrow: 1,
    },
    ({ theme, hasBackground }) => ({
        borderRadius: theme.radius?.lg,
        borderBottom: themedValue(theme, {
            perthnow: `1px solid ${colors.perthnow.greyMarble}`,
            sevennews: `1px solid ${colors.sevennews.alto}`,
            thewest: undefined,
            fallback: undefined,
        }),
        background: hasBackground
            ? theme.colors.background.secondary
            : theme.colors.background.primary,
    }),
)

// Adds in the width and alignment styles for the wrapping link - hovers, focus, link styles come from CardTextInteractions component
export const StyledLink = styled(CardTextInteractions)(
    {
        display: 'flex',
        flex: '1 3 auto',
        flexWrap: 'wrap',
        alignItems: 'stretch',
    },
    ({ theme }) => ({
        padding: themedValue(theme, {
            thewest: calcRem(metrics.thewest.margins.sm),
            perthnow: calcRem(metrics.perthnow.margins.sm),
            sevennews: 0,
            fallback: undefined,
        }),

        [`& .${playButtonIconClass}`]: {
            opacity: 0,
        },

        ['&:hover']: {
            [`& ${StyledHeadlineText}`]: {
                color: theme.colors.actions.link.hover,
            },
        },

        [`&:hover .${playButtonIconClass}, &:focus .${playButtonIconClass}`]: {
            opacity: 1,
        },
    }),
)

export const StyledHeader = styled('div')({
    color: colors.thewest.greyGorilla,
    fontSize: calcRem(16),
    lineHeight: 1.375,
    display: 'flex',
    flexWrap: 'wrap',
    flex: `1 1 ${calcRem(100)}`,
})

export const StyledHeadlineSkeleton = styled(CardHeadlineSkeleton)(
    ({ theme }) => ({
        alignSelf: 'flex-start',

        [`& ${StyledHeadlineText}`]: {
            transform: themedValue(theme, {
                perthnow: `translateY(-${calcRem(
                    metrics.perthnow.cards.textOffset,
                )})`,
                fallback: undefined,
            }),
        },
    }),
)
// CardHeadline component alignment and fontsize overrides
export const StyledHeadline = StyledHeadlineSkeleton.withComponent(CardHeadline)

// Wrapper component for card text includes teaser and funding label
export const StyledText = styled('div')<{ sponsoredStyles: boolean }>(
    ({ theme, sponsoredStyles }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        minHeight: themedValue(theme, {
            perthnow: sponsoredStyles ? undefined : calcRem(80),
            fallback: undefined,
        }),
    }),
)
StyledText.displayName = 'StyledText'

// CardTeaser component font size overrides
export const StyledTeaser = styled(CardTeaser)({
    fontSize: calcRem(14),
})

// FundingLabel component overrides for margins and wrapping
export const StyledFundingLabel = styled(FundingLabel)<FundingLabelProps>(
    {
        flex: 'auto',
        alignSelf: 'flex-end',
    },
    ({ theme }) => ({
        textAlign: undefined,
        marginTop: themedValue(theme, {
            fallback: theme.margins.sm,
            perthnow: calcRem(18),
        }),
    }),
)

// CardMedia component width & margin overrides
export const StyledMedia = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
    borderRadius: theme.radius?.sm,
    flex: `1 3 ${calcRem(106)}`,
    marginRight: theme.margins.sm,
    marginBottom: themedValue(theme, {
        thewest: calcRem(metrics.thewest.margins.sm),
        perthnow: calcRem(metrics.perthnow.cards.spacing),
        sevennews: calcRem(metrics.sevennews.margins.sm),
        thenightly: undefined,
    }),
    maxWidth: themedValue(theme, {
        thewest: calcRem(106),
        perthnow: calcRem(106),
        sevennews: calcRem(126),
        thenightly: undefined,
    }),
    height: theme.kind === 'perthnow' ? 'fit-content' : undefined,
}))

// SevenNews Only
export const StyledTimestamp = styled(Timestamp)({
    marginBottom: metrics.sevennews.margins.sm + 2,
    alignSelf: 'flex-end',
})
