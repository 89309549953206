import styled from '@emotion/styled'
import { WebLink, withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
const styledCardTakeoverClassName = 'PortraitCard'

export interface StyledCardTakeoverProps {
    backgroundColor?: string
}

export const StyledCardTakeover = withClass(styledCardTakeoverClassName)(
    styled('div')<StyledCardTakeoverProps>((props) => ({
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        justifyContent: 'center',
        flexGrow: 0,
        backgroundColor: props.backgroundColor || colors.white,
    })),
)

export const StyledCardTakeoverLink = styled(WebLink)({
    display: 'block',
})

export const StyledCardTakeoverPicture = styled('picture')({
    display: 'block',
    position: 'relative',
})

export const StyledCardTakeoverImg = styled('img')({
    width: '100%',
    height: 'auto',
    display: 'block',
    position: 'relative',
    top: 0,
    left: 0,
})
