import React, { useState } from 'react'

import { getAuth0EmailVerifyActionPath } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'
import { TNToastComponentProps } from '../../../../content'
import { TNToastComponent } from '../../../../content/TNToastNotification/TNToastNotification'
import {
    StyledAccountModifyBodyText,
    StyledAccountModifyHeading,
    StyledAccountModifyTextWrapper,
    StyledAccountModifyWardenButton,
    onClickEmailVerify,
} from '../../../warden/account-modify'
import { CTAText } from '../../../warden'

type VerifyEmailContentProps = {
    text: CTAText
    userId: string
}

export const VerifyEmailContent = ({
    text,
    userId,
}: VerifyEmailContentProps) => {
    const [emailSendAttempted, setEmailSendAttempted] = useState<Boolean>(false)
    const [emailStatusBanner, setEmailStatusBanner] =
        useState<TNToastComponentProps>({
            type: 'success',
            text: 'Email has been sent',
            useMaxWidth: false,
            forceMaxWidth: true,
        })

    const onClickSuccess = () => {
        setEmailSendAttempted(true)
        setEmailStatusBanner({
            type: 'success',
            text: 'Email has been sent',
            useMaxWidth: false,
            forceMaxWidth: true,
        })
    }
    const onClickError = () => {
        setEmailSendAttempted(true)
        setEmailStatusBanner({
            type: 'error',
            text: 'Something went wrong.',
            useMaxWidth: false,
            forceMaxWidth: true,
        })
    }

    return (
        <>
            {emailSendAttempted && (
                <TNToastComponent
                    type={emailStatusBanner?.type}
                    text={emailStatusBanner?.text}
                    useMaxWidth={emailStatusBanner?.useMaxWidth}
                    forceMaxWidth={emailStatusBanner?.forceMaxWidth}
                />
            )}
            <StyledAccountModifyTextWrapper>
                <StyledAccountModifyHeading>
                    {text.title}
                </StyledAccountModifyHeading>
                <StyledAccountModifyBodyText>
                    {text.body}
                </StyledAccountModifyBodyText>
            </StyledAccountModifyTextWrapper>

            <StyledAccountModifyWardenButton
                onClick={() =>
                    onClickEmailVerify({
                        verifyEmailHttpPath: getAuth0EmailVerifyActionPath,
                        userId,
                        onVerifySendError: onClickError,
                        onVerifySendSuccessful: onClickSuccess,
                    })
                }
                disabled={emailSendAttempted ? true : false}
                backgroundColors={{
                    default:
                        tokens.thenightly.colors.palette.utility.error.default,
                    hover: tokens.thenightly.colors.palette.utility.error.hover,
                }}
                roundEdges={true}
                useWidePadding={true}
            >
                {emailSendAttempted
                    ? 'RESEND VERIFICATION EMAIL'
                    : 'SEND VERIFICATION EMAIL'}
            </StyledAccountModifyWardenButton>
        </>
    )
}
