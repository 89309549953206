import Cookies from 'js-cookie'

const COOKIE_NAME = 'origin_url'
const COOKIE_EXPIRY = 1 // days

/**
 * Save the current url as an origin_url. This is used for things like email verified,
 * sign-up success, etc.
 */
export function saveOriginUrl(override: boolean) {
    const currentCookie = Cookies.get(COOKIE_NAME)

    // There is already a current cookie, so we don't want to override it
    if (currentCookie && !override) {
        return false
    }

    // save the exact URL
    Cookies.set(COOKIE_NAME, window.location.href, { expires: COOKIE_EXPIRY })
}

/**
 * @returns The origin url, or defaults to the homepage if no origin url is currently stored.
 */
export function useOriginUrl() {
    const rawCookie = Cookies.get(COOKIE_NAME)

    return rawCookie
}
