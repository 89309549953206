import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { playButtonStates } from '../../buttons/PlayButton/PlayButton.styled'
import {
    styledGalleryButtonClassName,
    styledMediaClassName,
    styledPlayButtonClassName,
} from '../../cards/CardMedia/StyledMedia.styled'
import { StyledHeadlineText } from '../../cards/CardText/CardText.styled'
import { styledCardTimestampTextLabel } from '../../content/CardTimestamp/CardTimestamp.styled'
import { colors } from '../../__styling/settings/colors'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { StyledKicker } from '../Kicker/Kicker.styled'
import { styledWrapperClassName } from '../LandscapeWide/Styled/StyledText.styled'

export interface StyledPortraitCardProps {
    isSponsored: boolean | null
    hasBackground?: boolean
    primaryTopic: string | undefined
    hasPadding?: boolean
}

// Interaction Styles for Cards on PerthNow
export const cardInteractionFocusStylesPerthNow: CSSObject = {
    '&:focus': {
        outline: 'none',

        [`& ${StyledHeadlineText}`]: {
            textDecoration: 'underline',
        },

        [`& .${styledMediaClassName}, & + .${styledWrapperClassName} .${styledMediaClassName}`]:
            {
                '&::after': {
                    borderColor: colors.perthnow.greyCoal,
                },
            },
    },
}

export const CardInteractionHoverStylesPerthNow: CSSObject = {
    '&:hover': {
        [`& ${StyledHeadlineText}`]: {
            color: colors.perthnow.blueKyanite,
        },

        [`& .${styledMediaClassName}`]: {
            '&::after': {
                height: '100%',
                borderColor: colors.white50,
            },
        },
    },
}

export const CardInteractionPlayButtonStylesPerthNow: CSSObject = {
    '&:hover, &:focus': {
        [`& .${styledPlayButtonClassName}`]: {
            backgroundColor: colors.perthnow.pinkThulite,

            '&::before': {
                borderLeftColor: colors.white, // Play button CSS color, uses border to create the triangle
            },
        },
    },
}

export interface StyledLinkProps {
    hasBackground?: boolean
    isLoading?: boolean
    isLarge?: boolean
    enforceCustomActionStateTreatment?: boolean
}

export const StyledLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'hasBackground',
        'isLoading',
        'isLarge',
        'section',
        'enforceCustomActionStateTreatment',
        'cardPosition',
    ]),
})<StyledLinkProps>(
    {
        textDecoration: 'none',
        textDecorationSkipInk: 'auto',
        ...transition({ properties: ['color'] }),
        position: 'relative',
    },
    ({ theme }) => ({
        color: theme.colors.text.primary,
        '&:hover, &:focus': {
            [`& .${styledGalleryButtonClassName}`]: {
                backgroundColor: themedValue(theme, {
                    thewest: (section) => section.primaryColor,
                    perthnow: colors.perthnow.pinkThulite,
                    fallback: colors.black,
                }),
            },
        },
    }),
    ({ isLoading }) =>
        isLoading && {
            cursor: 'default',
        },

    // Interactions on Cards - TheNightly
    ({ theme }) =>
        theme.kind === 'thenightly' && [
            {
                '&:hover, &:focus': {
                    [`& ${StyledHeadlineText}`]: {
                        textDecoration: 'underline',
                    },
                },
            },
        ],

    // Interactions on Cards - PerthNow
    ({ theme, hasBackground }) =>
        theme.kind === 'perthnow' && [
            cardInteractionFocusStylesPerthNow,
            !hasBackground && {
                '&:hover': {
                    [`& ${StyledHeadlineText}`]: {
                        color: theme.colors.actions.link.hover,
                    },

                    [`& .${styledMediaClassName}`]: {
                        '&::after': {
                            height: '100%',
                            borderColor: colors.white50,
                        },
                    },
                },
            },
            CardInteractionPlayButtonStylesPerthNow,
        ],

    // PerthNow only for cards with a background colour
    ({ theme, hasBackground, enforceCustomActionStateTreatment }) =>
        theme.kind === 'perthnow' &&
        hasBackground &&
        (!enforceCustomActionStateTreatment
            ? {
                  color: colors.white,

                  '&:hover': {
                      [`& ${StyledHeadlineText}`]: {
                          boxShadow: `6px 0 ${theme.colors.actions.link.hover},
                        6px 2px ${theme.colors.actions.link.hover},
                        -3px 0 ${theme.colors.actions.link.hover},
                        -3px 2px ${theme.colors.actions.link.hover}`,
                          color: theme.colors.actions.button.secondary?.text
                              .hover,
                          background: theme.colors.actions.link.hover,
                      },
                  },
              }
            : {
                  '&:hover': {
                      [`& ${StyledHeadlineText}`]: {
                          textDecoration: 'underline',
                      },
                  },
              }),

    // Interactions on Card Image - The West
    ({ theme }) =>
        theme.kind === 'thewest' && {
            [`& .${styledMediaClassName}`]: {
                '&::after': {
                    height: '100%',
                    borderWidth: '0 0 4px 0',
                    borderColor: 'transparent',
                },
            },

            '&:focus': {
                outline: 'none',

                [`& ${StyledHeadlineText}`]: {
                    textDecoration: 'underline',
                },

                [`& .${styledMediaClassName}`]: {
                    '&::after': {
                        borderColor: theme.colors.text.primary,
                    },
                },

                [`& ${StyledKicker}`]: {
                    textDecoration: 'underline',
                    color: theme.colors.text.primary,
                },
            },

            '&:hover': {
                [`& ${StyledHeadlineText}`]: {
                    color: theme.sectionValues.primaryColor,
                },

                [`& .${styledMediaClassName}`]: {
                    '&::after': {
                        borderColor: theme.sectionValues.primaryColor,
                    },
                },

                [`& ${StyledKicker} svg`]: {
                    fill: theme.sectionValues.primaryColor,
                },
            },
        },

    // Interactions on Card Image - Sevennews
    ({ theme, hasBackground }) =>
        theme.kind === 'sevennews' && {
            ...playButtonStates(theme),

            [`& .${styledMediaClassName}`]: {
                '&::after': {
                    borderWidth: '0 0 4px 0',
                    borderColor: 'transparent',
                },
            },

            '&:hover': {
                [`& .${styledMediaClassName}`]: {
                    '&::before': {
                        height: '120%',
                    },
                },
                [`& ${StyledHeadlineText}`]: {
                    color: themedValue(theme, {
                        sevennews: (section) =>
                            hasBackground
                                ? undefined
                                : section.cards.headline.hoverColor,
                        fallback: undefined,
                    }),
                    textDecoration: 'underline',
                },
                [`& .${styledCardTimestampTextLabel}`]: {
                    color: themedValue(theme, {
                        sevennews: (section) =>
                            section.cards.timestamp.hoverColor,
                        fallback: undefined,
                    }),
                },
            },

            '&:focus': {
                outline: `solid 2px`,
                outlineColor: themedValue(theme, {
                    sevennews: (section) => section.focusColor,
                    fallback: undefined,
                }),
            },

            // to prevent flash on desktop when clicking through
            '&:active': {
                outline: themedValue(theme, {
                    sevennews: 'none',
                    fallback: undefined,
                }),
            },
        },
)
