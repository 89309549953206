import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    breakpoint,
    calcRem,
    fonts,
    metrics,
    themedValue,
} from '../../__styling'

export const AflMatchSummaryWidgetContainer = styled('div')(({ theme }) => ({
    display: 'block',
    '& .Opta': {
        '& div': {
            fontSize: themedValue(theme, {
                sevennews: calcRem(16),
                perthnow: calcRem(18),
                fallback: undefined,
            }),
        },
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            perthnow: fonts.perthnow.sansSerif,
            thewest: fonts.perthnow.sansSerif,
            fallback: undefined,
        }),
        '& .Opta-MatchHeader ': {
            '& td.Opta-Team': {
                fontSize: calcRem(17),
                lineHeight: '22px',
                color: themedValue(theme, {
                    sevennews: tokens.sevennews.colors.palette.bauhausBlack,
                    perthnow: tokens.perthnow.colors.palette.black,
                    thewest: tokens.thewest.colors.palette.silver,
                    fallback: undefined,
                }),
                fontWeight: themedValue(theme, {
                    perthnow: 600,
                    thewest: 600,
                    fallback: undefined,
                }),
                [breakpoint('sm')]: {
                    fontSize: calcRem(20),
                    lineHeight: '25px',
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(24),
                    lineHeight: '30px',
                },
            },
            '& .Opta-Score': {
                width: calcRem(66),
                [breakpoint('sm')]: {
                    width: calcRem(74),
                },
                [breakpoint('md')]: {
                    width: calcRem(90),
                },
                '& .Opta-Team-Score': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: calcRem(46),
                    minHeight: calcRem(46),
                    fontSize: calcRem(16),
                    fontWeight: themedValue(theme, {
                        perthnow: 600,
                        thewest: 600,
                        fallback: undefined,
                    }),
                    background: themedValue(theme, {
                        sevennews: tokens.sevennews.colors.palette.red,
                        perthnow: tokens.perthnow.colors.palette.pinkThulite,
                        thewest: tokens.thewest.colors.palette.westblue,
                        fallback: undefined,
                    }),
                    [breakpoint('sm')]: {
                        minWidth: calcRem(54),
                        minHeight: calcRem(54),
                        fontSize: calcRem(18),
                    },
                    [breakpoint('md')]: {
                        minWidth: calcRem(70),
                        minHeight: calcRem(70),
                        fontSize: calcRem(26),
                    },
                },
                '&.Opta-Away .Opta-Team-Score': {
                    background: themedValue(theme, {
                        sevennews:
                            tokens.sevennews.colors.palette.matchCentre.darkred,
                        perthnow: tokens.perthnow.colors.palette.pinkThulite,
                        thewest: tokens.thewest.colors.palette.westblue,
                        fallback: undefined,
                    }),
                },
            },
            '& tr.Opta-MatchHeader-Details td>div': {
                background: 'none',
                fontSize: calcRem(12),
                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    display: 'none',
                },
            },
        },

        '& .Opta_AFL_MS': {
            '& .Opta-quarters>div': {
                borderBottom: 'none',
                height: themedValue(theme, {
                    perthnow: 'auto',
                    thewest: 'auto',
                    fallback: undefined,
                }),
                padding: themedValue(theme, {
                    perthnow: calcRem(8, 0),
                    thewest: calcRem(7, 0),
                    fallback: undefined,
                }),
                '&:nth-child(even)': {
                    background: themedValue(theme, {
                        sevennews:
                            tokens.sevennews.colors.palette.matchCentre
                                .black005,
                        perthnow: tokens.perthnow.colors.palette.white,
                        thewest: tokens.thewest.colors.palette.greyErmine,
                        fallback: undefined,
                    }),
                },
                '&>div': {
                    maxWidth: themedValue(theme, {
                        thewest: calcRem(130),
                        fallback: undefined,
                    }),
                    fontSize: themedValue(theme, {
                        thewest: calcRem(14),
                        fallback: undefined,
                    }),
                    [breakpoint('sm')]: {
                        fontSize: themedValue(theme, {
                            thewest: calcRem(18),
                            fallback: undefined,
                        }),
                    },
                    '&:nth-child(4)': {
                        fontWeight: themedValue(theme, {
                            thewest: 600,
                            sevennews: 700,
                            perthnow: 700,
                            fallback: undefined,
                        }),
                    },
                },
                '&.Opta-quarter-headings>div': {
                    fontSize: calcRem(18),
                    lineHeight: '23px',
                    fontWeight: themedValue(theme, {
                        perthnow: 600,
                        thewest: 500,
                        fallback: undefined,
                    }),
                    abbr: {
                        fontSize: calcRem(18),
                    },
                },
            },
            '& .Opta-scorers ': {
                paddingTop: calcRem(17),
                [breakpoint('sm')]: {
                    paddingTop: calcRem(26),
                },
                [breakpoint('md')]: {
                    paddingTop: calcRem(26),
                },
                '& div div': {
                    fontSize: themedValue(theme, {
                        thewest: calcRem(14),
                        fallback: undefined,
                    }),
                    [breakpoint('sm')]: {
                        fontSize: themedValue(theme, {
                            thewest: calcRem(18),
                            fallback: undefined,
                        }),
                    },
                    lineHeight: themedValue(theme, {
                        thewest: calcRem(18),
                        fallback: undefined,
                    }),
                    color: themedValue(theme, {
                        thewest: tokens.thewest.colors.palette.silver,
                        fallback: undefined,
                    }),
                },
                '& h3': {
                    fontSize: calcRem(17),
                    lineHeight: '30px',
                    background: themedValue(theme, {
                        sevennews: tokens.sevennews.colors.palette.bauhausWorld,
                        perthnow: tokens.perthnow.colors.palette.pinkThulite,
                        thewest: 'transparent',
                        fallback: undefined,
                    }),
                    textTransform: 'uppercase',
                    color: themedValue(theme, {
                        sevennews: tokens.sevennews.colors.palette.white,
                        perthnow: tokens.sevennews.colors.palette.white,
                        thewest: tokens.thewest.colors.palette.greyElephant,
                        fallback: undefined,
                    }),
                    textAlign: themedValue(theme, {
                        perthnow: 'left',
                        thewest: 'left',
                        fallback: undefined,
                    }),
                    paddingLeft: themedValue(theme, {
                        perthnow: calcRem(metrics.perthnow.margins.sm),
                        fallback: undefined,
                    }),
                    fontWeight: themedValue(theme, {
                        thewest: 400,
                        fallback: undefined,
                    }),
                    fontFamily: themedValue(theme, {
                        thewest: fonts.thewest.serif,
                        fallback: undefined,
                    }),
                    borderBottom: themedValue(theme, {
                        thewest: `3px solid ${tokens.thewest.colors.palette.greyElephant}`,
                        fallback: undefined,
                    }),
                    marginBottom: themedValue(theme, {
                        thewest: calcRem(16),
                        fallback: undefined,
                    }),
                    [breakpoint('md')]: {
                        fontSize: themedValue(theme, {
                            sevennews: calcRem(24),
                            perthnow: calcRem(24),
                            thewest: calcRem(16),
                            fallback: undefined,
                        }),
                        marginBottom: themedValue(theme, {
                            thewest: calcRem(24),
                            fallback: undefined,
                        }),
                    },
                },
            },
        },
        '.Opta-error': {
            marginTop: 0,
            marginBottom: calcRem(24),
            p: {
                fontFamily: themedValue(theme, {
                    perthnow: fonts.perthnow.serif,
                    fallback: undefined,
                }),
                fontSize: themedValue(theme, {
                    sevennews: calcRem(15),
                    fallback: calcRem(16),
                }),
                fontWeight: themedValue(theme, {
                    sevennews: 500,
                    fallback: undefined,
                }),
                lineHeight: 1.5,
                padding: 0,
                color: themedValue(theme, {
                    perthnow: tokens.perthnow.colors.palette.greyCoal,
                    fallback: undefined,
                }),
            },
        },
        '.Opta_W, .Opta_W>div': {
            background: themedValue(theme, {
                perthnow: 'transparent',
                fallback: undefined,
            }),
        },
    },
}))
