import React from 'react'

export interface IconCommentProps {
    className?: string
}

export const IconComment: React.FC<IconCommentProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 55 49"
        className={className}
    >
        <title>Comments</title>
        <path d="M54 24.5v-11C54 6.4 48.4 1 41.4 1H13.6C6.6 1 1 6.6 1 13.4v11.1c0 6.9 5.6 12.4 12.6 12.4h1.3v9.7c0 1.2 1.6 1.9 2.4 1l10.8-10.7h13.3c7 0 12.6-5.5 12.6-12.4" />
    </svg>
)
IconComment.displayName = 'IconComment'
