import React from 'react'

export interface IconHomeProps {
    title?: string
    className?: string
}

export const IconHome: React.FC<IconHomeProps> = ({
    title = 'Home',
    className,
}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 13"
        role="img"
        aria-labelledby="icon-home-title"
    >
        <title id="icon-home-title">{title}</title>
        <path d="M12.9 6.3c.1-.2.1-.7-.2-.9L7 .2c-.3-.3-.7-.3-1 0L.2 5.7c-.3.2-.3.7-.1.9l.2.2c.3.2.7.3.9 0l.4-.4v5.9c0 .4.3.7.6.7h2.3c.4 0 .6-.3.6-.7V8.2H8v4.1c0 .4.2.7.6.7H11c.4 0 .6-.3.6-.7V6.5s.1.1.3.2c.2.1.5 0 .7-.1 0 0 .1 0 .3-.3z" />
    </svg>
)
IconHome.displayName = 'IconHome'
