export function clipStringToLength(text: string, length: number) {
    if (text.length < length) {
        // doesn't need clipping
        return text
    }
    // clip the string to the maximum length
    let clipped = text.substr(0, length)
    // re-trim if we are in the middle of a word
    clipped = clipped.substr(
        0,
        Math.min(clipped.length, clipped.lastIndexOf(' ')),
    )
    clipped = clipped + '...'
    return clipped
}
