import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    AflMatchStatsWidget,
    AflMatchStatsWidgetProps,
} from './AflMatchStatsWidget'

export const AflMatchStatsWidgetRegistration = createRegisterableComponent(
    'afl-match-stats-widget',
    (props: AflMatchStatsWidgetProps, services) => {
        return <AflMatchStatsWidget {...props} />
    },
)
