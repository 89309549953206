import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { ProfileCard } from '../../cards/ProfileCard/ProfileCard'
import {
    StyledGridItem,
    StyledWhiskey,
} from '../../collections/whiskey/Whiskey.styled'
import { ResponsiveContainer } from '../../content/Picture/responsive'
// Cards
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
// Themes, Metrics, Events
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { invertMaybeLoadedItems } from '../helpers/loading'

export type ListLength = 1 | 2 | 3 | 4 | 5 | 6

export interface WhiskeyProps extends ResponsiveContainer {
    className?: string
    sectionHeader?: SectionHeaderProps
    onEvent: (event: CollectionEvent) => void
    initialColumns?: number
    intermediateColumns?: number
    finalColumns?: number
    verticalSpacing?: keyof ThemeMargins | undefined
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
}

export const Whiskey: React.FC<WhiskeyProps> = (props) => {
    const {
        className,
        sectionHeader,
        onEvent,
        initialColumns = 1,
        intermediateColumns = 4,
        finalColumns = 4,
        verticalSpacing,
        expectedCards,
    } = props
    const product = useProduct()
    const items = invertMaybeLoadedItems(props.items, expectedCards)

    const remainingItems = () => {
        return items.slice(0, items.length)
    }

    const hasItems = items.length >= 1

    const containerValues = props.containerWidthRatios
        ? props.containerWidthRatios
        : { desktop: 1, tablet: 1, mobile: 1 }

    return (
        <React.Fragment>
            {sectionHeader && hasItems && (
                <SectionHeader {...sectionHeader} hasBottomMargin />
            )}
            <ImpressionAvailable
                loading={!props.items.loaded}
                available={() => {
                    if (!props.items.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }
                    onEvent(
                        createCollectionAvailableEvent(
                            props.items.result,
                            'Whiskey',
                            product,
                            onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <StyledWhiskey
                        ref={ref}
                        className={className}
                        initialColumns={initialColumns}
                        intermediateColumns={intermediateColumns}
                        finalColumns={finalColumns}
                        verticalSpacing={verticalSpacing}
                    >
                        {remainingItems().map((item, index) => {
                            const numberOfItems = items.length

                            const cardNumber = index + 1
                            return (
                                <StyledGridItem key={index}>
                                    <CardSwitcher
                                        key={index}
                                        onEvent={onEvent}
                                        item={item}
                                        cardContext="whiskey-remaining"
                                        cardNumber={cardNumber}
                                        publicationCard={(publicationItem) => (
                                            <ProfileCard
                                                cardNumber={cardNumber}
                                                item={publicationItem}
                                                onEvent={onEvent}
                                                kickerMode={{
                                                    kickerType: 'block',
                                                    icons: [],
                                                }}
                                                containerWidthRatios={{
                                                    mobile:
                                                        (1 / initialColumns) *
                                                        containerValues.mobile,
                                                    tablet:
                                                        (1 /
                                                            intermediateColumns) *
                                                        containerValues.tablet,
                                                    desktop:
                                                        (1 / finalColumns) *
                                                        containerValues.desktop,
                                                }}
                                            />
                                        )}
                                    />
                                </StyledGridItem>
                            )
                        })}
                    </StyledWhiskey>
                )}
            </ImpressionAvailable>
        </React.Fragment>
    )
}
Whiskey.displayName = 'Whiskey'
