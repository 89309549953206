import { pinnedChipTypes } from '../../Byline/helpers/getEventPostChip'

export function getPinnedChipType(
    isPinned: boolean,
    isPinnedTop: boolean | undefined,
): pinnedChipTypes {
    /* Pinned post in context in timeline */
    if (isPinned && !isPinnedTop) {
        return 'icon-only'
    }
    /* Pinned post in truncating container ie. pinned out of context at top of timeline */
    if (isPinned && isPinnedTop) {
        return 'full'
    }
}
