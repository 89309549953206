// When making changes to e-commerce packages, whether it's images or a package update itself, you need to make the
//  change to both SSW & news-mono to allow for both repositories to have in-sync information and images.

// When updating packages, the changes need to be made to both SSW & news-mono. The package paths are:
// - news-mono: `/libs/web-common/src/data/ssw/index.ts`
// - ssw: `/app/data/packages-2022-redesign.json`

// When adding images they need to be added to both SSW & news-mono. The image folders are located in:
// - news-mono: `/libs/component-library/src/user-registration/breach/components/SubscriptionPackage/images/updated2022/redesign`
// - ssw: `/web/assets/src/images/packages/updated2022/redesign`

export const isSswPackage = (obj: any): boolean => {
    return (
        (typeof obj !== 'undefined' && sswPackages.includes(obj)) ||
        // Do multiple checks to be sure that it is actually a ssw package
        sswPackages.filter(
            (pkg) =>
                pkg.name === obj.name &&
                pkg.path === obj.path &&
                pkg.stdRateCode === obj.stdRateCode,
        ).length > 0
    )
}

export const getSswPackageByHref = (href: string): SswPackage | undefined => {
    return sswPackages.find((x) => x.path === href)
}
export const getSswPackageByName = (name: string): SswPackage | undefined => {
    return sswPackages.find((x) => x.name === name)
}

export const sswPackages = [
    {
        name: 'sat-sun+digital',
        title: 'Everyday Digital & Weekend Papers',
        price: '$10',
        weeklyPrice: 10.0,
        priceFrequency: 'per week',
        stdRateCode: 'WST_PrSS+DIGI_$10',
        path: 'subscribenow/sat-sun+digital',
        /**
         * Make sure the assets are registered in
         * libs/component-library/src/user-registration/breach/components/SubscriptionPackage/images/updated2022/redesign/index.ts
         */
        imgPaths: {
            x1: 'mobile/everyday_digital_weekend_papers_may_2024.png',
            x2: 'everyday_digital_weekend_papers_may_2024.png',
        },
        features: [
            { text: 'Access to all digital content' },
            { text: 'Regional online news' },
            { text: 'The West app' },
            { text: 'Daily digital edition of the newspaper' },
            { text: 'Subscriber rewards' },
            { text: 'Weekend papers delivered' },
        ],
        type: 'digital+print',
    },
    {
        name: 'digital',
        title: 'Everyday Digital',
        price: '$8',
        weeklyPrice: 8.0,
        priceFrequency: 'per week',
        stdRateCode: 'WST_DIGI_$8',
        path: 'subscribenow/digital',
        imgPaths: {
            x1: 'mobile/everyday_digital_may_2024.png',
            x2: 'everyday_digital_may_2024.png',
        },
        features: [
            { text: 'Access to all digital content' },
            { text: 'Regional online news' },
            { text: 'The West app' },
            { text: 'Daily digital edition of the newspaper' },
            { text: 'Subscriber rewards' },
            { text: 'Weekend papers delivered', unavailable: true },
        ],
        type: 'digital-only',
    },
    {
        name: 'mon-sat+digital',
        title: 'Mon-Sat Papers + Everyday Digital',
        price: '$14.40',
        weeklyPrice: 14.4,
        priceFrequency: 'per week',
        stdRateCode: 'WST-MTWTFS_STD_14.4',
        path: 'subscribenow/mon-sat+digital',
        imgPaths: {
            x1: 'mobile/mon_sat_everyday_digital_footy2024.png',
            x2: 'mon_sat_everyday_digital_footy2024.png',
        },
        features: [
            { text: 'Access to all digital content' },
            { text: 'Mon-Sat papers delivered' },
            { text: 'Regional online news' },
            { text: 'The West app' },
            { text: 'Daily digital edition of the newspaper' },
            { text: 'Subscriber rewards' },
        ],
        type: 'print',
    },
    {
        name: 'mon-sun+digital',
        title: 'Mon-Sun Papers + Everyday Digital',
        price: '$17.50',
        weeklyPrice: 17.5,
        priceFrequency: 'per week',
        stdRateCode: 'WST-MTWTFSS_STD_17.5',
        path: 'subscribenow/mon-sun+digital',
        imgPaths: {
            x1: 'mobile/mon_sun_everyday_digital_footy2024.png',
            x2: 'mon_sun_everyday_digital_footy2024.png',
        },
        features: [
            { text: 'Access to all digital content' },
            { text: 'Mon-Sun papers delivered' },
            { text: 'Regional online news' },
            { text: 'The West app' },
            { text: 'Daily digital edition of the newspaper' },
            { text: 'Subscriber rewards' },
        ],
        type: 'print',
    },
    {
        name: 'the-weekender',
        title: 'Weekend Papers',
        price: '$7.0',
        weeklyPrice: 7.0,
        priceFrequency: 'per week',
        stdRateCode: 'WST------SS_$7',
        path: 'subscribenow/the-weekender',
        imgPaths: {
            x1: 'mobile/weekend_papers_footy2024.png',
            x2: 'weekend_papers_footy2024.png',
        },
        features: [
            { text: 'Weekend papers delivered' },
            { text: 'Subscriber rewards' },
            { text: 'Access to all digital content', unavailable: true },
            { text: 'Regional online news', unavailable: true },
            { text: 'The West app', unavailable: true },
            {
                text: 'Daily digital edition of the newspaper',
                unavailable: true,
            },
        ],
        type: 'print',
    },
    {
        name: 'the-sunday-times',
        title: 'The Sunday Times',
        price: '$3.50',
        weeklyPrice: 3.5,
        priceFrequency: 'per week',
        stdRateCode: 'WST-------S_$3.50',
        path: 'subscribenow/the-sunday-times',
        imgPaths: {
            x1: 'mobile/sunday_times_footy2024.png',
            x2: 'sunday_times_footy2024.png',
        },
        features: [
            { text: 'Sunday paper delivered' },
            { text: 'Subscriber rewards' },
            { text: 'Access to all digital content', unavailable: true },
            { text: 'Regional online news', unavailable: true },
            { text: 'The West app', unavailable: true },
            {
                text: 'Daily digital edition of the newspaper',
                unavailable: true,
            },
        ],
        type: 'print',
    },
    {
        name: 'the-weekend-west',
        title: 'The Saturday West',
        price: '$3.50',
        weeklyPrice: 3.5,
        priceFrequency: 'per week',
        stdRateCode: 'WST------S_$3.50',
        path: 'subscribenow/the-weekend-west',
        imgPaths: {
            x1: 'mobile/saturday_west_footy2024.png',
            x2: 'saturday_west_footy2024.png',
        },
        features: [
            { text: 'Saturday paper delivered' },
            { text: 'Subscriber rewards' },
            { text: 'Access to all digital content', unavailable: true },
            { text: 'Regional online news', unavailable: true },
            { text: 'The West app', unavailable: true },
            {
                text: 'Daily digital edition of the newspaper',
                unavailable: true,
            },
        ],
        type: 'print',
    },
]
// Remove the old priceing after confirmation from CE that the new price is permanent
// export const sswPackages = [
//     {
//         name: 'sat-sun+digital',
//         title: 'Everyday Digital & Weekend Papers',
//         price: '$9',
//         weeklyPrice: 9.0,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST_PrSS+DIGI_DD',
//         path: 'subscribenow/sat-sun+digital',
//         /**
//          * Make sure the assets are registered in
//          * libs/component-library/src/user-registration/breach/components/SubscriptionPackage/images/updated2022/redesign/index.ts
//          */
//         imgPaths: {
//             x1: 'mobile/everyday_digital_weekend_papers_may_2024.png',
//             x2: 'everyday_digital_weekend_papers_may_2024.png',
//         },
//         features: [
//             { text: 'Access to all digital content' },
//             { text: 'Regional online news' },
//             { text: 'The West app' },
//             { text: 'Daily digital edition of the newspaper' },
//             { text: 'Subscriber rewards' },
//             { text: 'Weekend papers delivered' },
//         ],
//         type: 'digital+print',
//     },
//     {
//         name: 'digital',
//         title: 'Everyday Digital',
//         price: '$7',
//         weeklyPrice: 7.0,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST_DIGI_DD',
//         path: 'subscribenow/digital',
//         imgPaths: {
//             x1: 'mobile/everyday_digital_may_2024.png',
//             x2: 'everyday_digital_may_2024.png',
//         },
//         features: [
//             { text: 'Access to all digital content' },
//             { text: 'Regional online news' },
//             { text: 'The West app' },
//             { text: 'Daily digital edition of the newspaper' },
//             { text: 'Subscriber rewards' },
//             { text: 'Weekend papers delivered', unavailable: true },
//         ],
//         type: 'digital-only',
//     },
//     {
//         name: 'mon-sat+digital',
//         title: 'Mon-Sat Papers + Everyday Digital',
//         price: '$12.00',
//         weeklyPrice: 12.0,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST-MTWTFS_STD',
//         path: 'subscribenow/mon-sat+digital',
//         imgPaths: {
//             x1: 'mobile/mon_sat_everyday_digital_footy2024.png',
//             x2: 'mon_sat_everyday_digital_footy2024.png',
//         },
//         features: [
//             { text: 'Access to all digital content' },
//             { text: 'Mon-Sat papers delivered' },
//             { text: 'Regional online news' },
//             { text: 'The West app' },
//             { text: 'Daily digital edition of the newspaper' },
//             { text: 'Subscriber rewards' },
//         ],
//         type: 'print',
//     },
//     {
//         name: 'mon-sun+digital',
//         title: 'Mon-Sun Papers + Everyday Digital',
//         price: '$15.10',
//         weeklyPrice: 15.1,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST-MTWTFSS_STD',
//         path: 'subscribenow/mon-sun+digital',
//         imgPaths: {
//             x1: 'mobile/mon_sun_everyday_digital_footy2024.png',
//             x2: 'mon_sun_everyday_digital_footy2024.png',
//         },
//         features: [
//             { text: 'Access to all digital content' },
//             { text: 'Mon-Sun papers delivered' },
//             { text: 'Regional online news' },
//             { text: 'The West app' },
//             { text: 'Daily digital edition of the newspaper' },
//             { text: 'Subscriber rewards' },
//         ],
//         type: 'print',
//     },
//     {
//         name: 'the-weekender',
//         title: 'Weekend Papers',
//         price: '$6.20',
//         weeklyPrice: 6.2,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST------SS_STD',
//         path: 'subscribenow/the-weekender',
//         imgPaths: {
//             x1: 'mobile/weekend_papers_footy2024.png',
//             x2: 'weekend_papers_footy2024.png',
//         },
//         features: [
//             { text: 'Weekend papers delivered' },
//             { text: 'Subscriber rewards' },
//             { text: 'Access to all digital content', unavailable: true },
//             { text: 'Regional online news', unavailable: true },
//             { text: 'The West app', unavailable: true },
//             {
//                 text: 'Daily digital edition of the newspaper',
//                 unavailable: true,
//             },
//         ],
//         type: 'print',
//     },
//     {
//         name: 'the-sunday-times',
//         title: 'The Sunday Times',
//         price: '$3.10',
//         weeklyPrice: 3.1,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST-------S_STD',
//         path: 'subscribenow/the-sunday-times',
//         imgPaths: {
//             x1: 'mobile/sunday_times_footy2024.png',
//             x2: 'sunday_times_footy2024.png',
//         },
//         features: [
//             { text: 'Sunday paper delivered' },
//             { text: 'Subscriber rewards' },
//             { text: 'Access to all digital content', unavailable: true },
//             { text: 'Regional online news', unavailable: true },
//             { text: 'The West app', unavailable: true },
//             {
//                 text: 'Daily digital edition of the newspaper',
//                 unavailable: true,
//             },
//         ],
//         type: 'print',
//     },
//     {
//         name: 'the-weekend-west',
//         title: 'The Saturday West',
//         price: '$3.10',
//         weeklyPrice: 3.1,
//         priceFrequency: 'per week',
//         stdRateCode: 'WST------S_STD',
//         path: 'subscribenow/the-weekend-west',
//         imgPaths: {
//             x1: 'mobile/saturday_west_footy2024.png',
//             x2: 'saturday_west_footy2024.png',
//         },
//         features: [
//             { text: 'Saturday paper delivered' },
//             { text: 'Subscriber rewards' },
//             { text: 'Access to all digital content', unavailable: true },
//             { text: 'Regional online news', unavailable: true },
//             { text: 'The West app', unavailable: true },
//             {
//                 text: 'Daily digital edition of the newspaper',
//                 unavailable: true,
//             },
//         ],
//         type: 'print',
//     },
// ]
export type SswPackage = typeof sswPackages[1]
