import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../../__styling/settings/colors'

export interface IconWestLiveProps {
    showEqualizer?: boolean
}

const IconWestLiveStyled = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',

    '& .badge-bg': {
        boxSizing: 'content-box',
        transform: 'skewX(-15deg)',
        background: colors.thewest.westblue,
        padding: '2px 5px',
        display: 'flex',
    },
    '& svg': {
        fill: 'white',
        transform: 'skewX(15deg)',
    },
    '& .eq': {
        alignItems: 'flex-end',
        display: 'flex',
        width: '20px',
        height: '12px',
        overflow: 'hidden',
        transform: 'skewX(-15deg)',
        marginLeft: '1px',
    },
    '& .eq-col': {
        flex: 1,
        position: 'relative',
        height: '100%',
        marginRight: '1px',
    },
    '& .eq-col div': {
        animationName: 'west-live-eq-animation',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        backgroundColor: colors.thewest.westblue,
        position: 'absolute',
        width: '100%',
        height: '100%',
        transform: 'translateY(100%)',
        willChange: 'transform',
    },
    '& .eq-1-1': {
        animationDuration: '0.3s',
    },
    '& .eq-1-2': {
        animationDuration: '0.45s',
    },
    '& .eq-2-1': {
        animationDuration: '0.5s',
    },
    '& .eq-2-2': {
        animationDuration: '0.4s',
    },
    '& .eq-3-1': {
        animationDuration: '0.3s',
    },
    '& .eq-3-2': {
        animationDuration: '0.35s',
    },
    '@keyframes west-live-eq-animation': {
        '0%': {
            transform: 'translateY(68%)',
        },
        '100%': {
            transform: 'translateY(0)',
        },
    },
}))

export const IconWestLive: React.FC<IconWestLiveProps> = ({
    showEqualizer = false,
}) => {
    const WestLiveBadge = (
        <div className="badge-bg">
            <svg width="26" height="8" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.686 0H26l-.392 1.859h-2.884l-.245 1.165h2.27l-.383 1.825h-2.272l-.272 1.292h3.021L24.451 8H19l1.686-8zm-5.624 4.964L17.605 0H20l-4.436 8h-2.735L12 0h2.667l.338 4.964h.057zM7.63 0H10L8.37 8H6l1.63-8zM1.543 0h2.254L2.612 6.141H5L4.641 8H0l1.543-8z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </svg>
        </div>
    )
    const WestLiveEq = (
        <div className="eq">
            <div className="eq-col">
                <div className="eq-1-1"></div>
                <div className="eq-1-2"></div>
            </div>
            <div className="eq-col">
                <div className="eq-2-1"></div>
                <div className="eq-2-2"></div>
            </div>
            <div className="eq-col">
                <div className="eq-3-1"></div>
                <div className="eq-3-2"></div>
            </div>
        </div>
    )
    return (
        <IconWestLiveStyled>
            {WestLiveBadge}
            {showEqualizer && WestLiveEq}
        </IconWestLiveStyled>
    )
}
