import { useTheme } from '@emotion/react'
import { isCorporateAccount } from '@news-mono/component-library'
import {
    AppState,
    BreachEvent,
    SubscriptionPromptCookieData,
    THEWEST_ALL,
    THEWEST_PREMIUM,
    THEWEST_REPLICA,
    useFeature,
    WESTREWARDS,
} from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import Cookies from 'js-cookie'
import React from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useFeatureForSection } from '../../feature-toggling'
import {
    OnSubscribeEventHandler,
    useAuthActions,
} from '../../user-registration/authentication/AuthenticationProvider'
import { SubscriptionPrompt } from '../../user-registration/breach/components/SubscriptionPackage/SubscriptionPrompt'
import { createSubscribeLinkClickEvent } from '../../user-registration/subscribe/createSubscribeClickLinkHandler'
import { TheWestHeaderDropdown } from '../TheWestHeaderDropdown'
import { LogoutActionLink } from './LogoutActionLink'
import { RegionalActionLink } from './RegionalActionLink'
import {
    NewStyledActionButton,
    NewStyledActionLinks,
    NewStyledPremiumIcon,
    StyledActionButton,
    StyledActionLinks,
    StyledCartContainer,
    StyledEmptyCartIcon,
    StyledFullCartIcon,
    StyledPremiumIcon,
    StyledSkeleton,
    subscribeButtonClassname,
} from './TheWestHeaderLogin.styled'
import { UserName, UserNameLink } from './UserNameLink'

export interface AuthSiteLoginActionLinksProps {
    onEvent: (event: any) => void
}

export interface EntitlementDetail {
    isAllEntitled: boolean
    isReplicaEntitled: boolean
    isPremiumEntitled: boolean
    isSiteLicence: boolean
    isWestRewardsEntitled: boolean
}

export const AuthSiteLoginActionLinks: React.FC<AuthSiteLoginActionLinksProps> =
    ({ onEvent }) => {
        const {
            userName,
            isLoggedIn,
            hasAttemptedValidation,
            entitlements,
            subscriptionType,
        } = useSelector((state: AppState) => state.authentication)
        const { section } = useTheme()
        const isRegionalSite = section !== 'default'
        const { onLoginClick, onSubscribeClick, onLogoutClick } =
            useAuthActions()

        /**
         * Check to see if the user likely has an account on SSW before we link there,
         * Regwall users only have `thewest:registered`
         */
        const showMyAccountLink = isLoggedIn && !!entitlements

        const entitlementsDetail: EntitlementDetail = {
            isAllEntitled:
                entitlements.includes(THEWEST_ALL) &&
                !isCorporateAccount(subscriptionType),
            isReplicaEntitled:
                entitlements.includes(THEWEST_ALL) ||
                entitlements.includes(THEWEST_REPLICA),
            isPremiumEntitled:
                entitlements.includes(THEWEST_ALL) ||
                entitlements.includes(THEWEST_PREMIUM),
            isWestRewardsEntitled: entitlements.includes(WESTREWARDS),
            isSiteLicence: isCorporateAccount(subscriptionType),
        }
        return isLoggedIn ? (
            <ActionLinksAuthenticated
                isRegionalSite={isRegionalSite}
                onLogoutClick={() => onLogoutClick(onEvent)}
                userName={userName}
                hasAccount={showMyAccountLink}
                // isEntitled={entitlements.includes(THEWEST_ALL)}
                entitlements={entitlementsDetail}
                onEvent={onEvent}
            />
        ) : (
            <ActionLinksUnauthenticated
                onLoginClick={onLoginClick}
                onSubscribeClick={onSubscribeClick}
                isRegionalSite={isRegionalSite}
                hasAttemptedValidation={hasAttemptedValidation}
                onEvent={onEvent}
            />
        )
    }

export interface ActionLinksUnauthenticatedProps {
    isRegionalSite: boolean
    onLoginClick: () => void
    onSubscribeClick: OnSubscribeEventHandler
    hasAttemptedValidation: boolean
    onEvent: (event: any) => void
}

export const ActionLinksUnauthenticated: React.FC<ActionLinksUnauthenticatedProps> =
    ({
        isRegionalSite,
        hasAttemptedValidation,
        onLoginClick,
        onSubscribeClick,
        onEvent,
    }) => {
        const newSubscribeButtonDesignEnabled = useFeatureForSection({
            feature: 'tp-subscription-subscribe-button-design',
            sections: TheWestSections.default,
        })
        const newSubscribeCartIconEnabled = useFeatureForSection({
            feature: 'tp-subscription-abandoned-cart',
            sections: TheWestSections.default,
        })

        if (newSubscribeButtonDesignEnabled) {
            return (
                <NewStyledActionLinks>
                    {isRegionalSite && <RegionalActionLink />}

                    {hasAttemptedValidation ? (
                        <React.Fragment>
                            <StyledActionButton
                                isPrimary={false}
                                onClick={onLoginClick}
                            >
                                Login
                            </StyledActionButton>
                            <NewStyledActionButton
                                className={subscribeButtonClassname}
                                onClick={() => {
                                    createSubscribeLinkClickEvent(
                                        'header-utility',
                                        onEvent,
                                    )()
                                    onSubscribeClick({
                                        callToAction: 'header-utility',
                                    })
                                }}
                                isPrimary={true}
                            >
                                <React.Fragment>
                                    <NewStyledPremiumIcon />
                                    Subscribe
                                </React.Fragment>
                            </NewStyledActionButton>
                            {newSubscribeCartIconEnabled && (
                                <CartIconLink
                                    isLoggedIn={false}
                                    onEvent={onEvent}
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <StyledSkeleton count={1} />
                            <StyledSkeleton count={1} />
                        </React.Fragment>
                    )}
                </NewStyledActionLinks>
            )
        } else {
            return (
                <StyledActionLinks>
                    {isRegionalSite && <RegionalActionLink />}

                    <StyledActionButton
                        isPrimary={false}
                        onClick={onLoginClick}
                    >
                        {hasAttemptedValidation ? (
                            'Login'
                        ) : (
                            <StyledSkeleton count={1} />
                        )}
                    </StyledActionButton>

                    <StyledActionButton
                        className={subscribeButtonClassname}
                        onClick={() => {
                            createSubscribeLinkClickEvent(
                                'header-utility',
                                onEvent,
                            )()
                            onSubscribeClick({
                                callToAction: 'header-utility',
                            })
                        }}
                        isPrimary={true}
                    >
                        {hasAttemptedValidation ? (
                            <React.Fragment>
                                <StyledPremiumIcon />
                                Subscribe
                            </React.Fragment>
                        ) : (
                            <StyledSkeleton count={1} />
                        )}
                    </StyledActionButton>
                    {newSubscribeCartIconEnabled && (
                        <CartIconLink isLoggedIn={false} onEvent={onEvent} />
                    )}
                </StyledActionLinks>
            )
        }
    }

export interface ActionLinksAuthenticatedProps {
    isRegionalSite: boolean
    userName: string
    hasAccount: boolean
    entitlements: EntitlementDetail
    onLogoutClick: () => void
    onEvent: (event: any) => void
}

export const ActionLinksAuthenticated: React.FC<ActionLinksAuthenticatedProps> =
    ({
        isRegionalSite,
        userName,
        hasAccount,
        onLogoutClick,
        entitlements,
        onEvent,
    }) => {
        const subscriberDropdownEnabled = useFeature('subscriber-dropdown')
        const newSubscribeCartIconEnabled = useFeatureForSection({
            feature: 'tp-subscription-abandoned-cart',
            sections: TheWestSections.default,
        })

        if (subscriberDropdownEnabled) {
            return (
                <StyledActionLinks>
                    {isRegionalSite && <RegionalActionLink />}
                    {hasAccount ? (
                        <>
                            <TheWestHeaderDropdown
                                userName={userName}
                                onLogoutClick={onLogoutClick}
                                entitlements={entitlements}
                                // isEntitled={isEntitled}
                                onEvent={onEvent}
                            />
                            {newSubscribeCartIconEnabled && (
                                <CartIconLink
                                    isLoggedIn={true}
                                    onEvent={onEvent}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <UserName userName={userName} />
                            {newSubscribeCartIconEnabled && (
                                <CartIconLink
                                    isLoggedIn={true}
                                    onEvent={onEvent}
                                />
                            )}
                            <LogoutActionLink onLogoutClick={onLogoutClick} />
                        </>
                    )}
                </StyledActionLinks>
            )
        }
        return (
            <StyledActionLinks>
                {isRegionalSite && <RegionalActionLink />}
                {hasAccount ? (
                    <UserNameLink userName={userName} />
                ) : (
                    <UserName userName={userName} />
                )}
                <CartIconLink isLoggedIn={true} onEvent={onEvent} />
                <LogoutActionLink onLogoutClick={onLogoutClick} />
            </StyledActionLinks>
        )
    }

export const CartIconLink = ({
    isLoggedIn,
    onEvent,
}: {
    isLoggedIn: boolean
    onEvent: (event: BreachEvent) => void
}) => {
    const { getSubscribeLink } = useAuthActions()

    const cookieString = Cookies.get('subscribeCart')
    let cartCookie: SubscriptionPromptCookieData | undefined
    try {
        cartCookie = cookieString && JSON.parse(cookieString)
    } catch (error) {
        cartCookie = undefined
    }

    if (cartCookie && cartCookie.package_info) {
        const subscribeUrl = getSubscribeLink({
            packagePath: cartCookie.href || undefined,
        })

        return (
            <StyledCartContainer>
                {ReactDOM.createPortal(
                    <SubscriptionPrompt
                        promptData={cartCookie}
                        checkoutLink={subscribeUrl}
                        onEvent={onEvent}
                    />,
                    document.body,
                )}
                <a href={subscribeUrl} target="_blank" title="Shopping cart">
                    <StyledFullCartIcon />
                </a>
            </StyledCartContainer>
        )
    }

    return isLoggedIn ? null : (
        <StyledCartContainer>
            <StyledEmptyCartIcon />
        </StyledCartContainer>
    )
}
