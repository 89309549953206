import {
    SectionMetaDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'
import { Action } from 'redux'

export interface MetaState {
    sectionMeta: SectionMetaDTO
    hostnameToSectionLookup: Record<string, string>
}

interface MetaInitPayload {
    sectionMeta: SectionMetaDTO
}

export const enum MetaActions {
    INIT = '@@META/INIT',
}
export interface INIT extends Action {
    type: MetaActions.INIT
    payload: MetaInitPayload
}

type AuthAction = INIT

export function initMeta(sectionMeta: SectionMetaDTO): INIT {
    return {
        type: MetaActions.INIT,
        payload: { sectionMeta },
    }
}

// Don't want to complicate types by allowing undefined, this will get state as part of request init
const defaultState: MetaState = {} as any

export const metaReducer = (
    state = defaultState,
    action:
        | AuthAction
        | { type: '@@INIT' /** This represents all other actions */ },
): MetaState => {
    switch (action.type) {
        case MetaActions.INIT: {
            return {
                sectionMeta: action.payload.sectionMeta,
                hostnameToSectionLookup: getHostnameToSections(
                    action.payload.sectionMeta,
                ),
            }
        }
        default:
            return state
    }
}

export function getHostnameToSections(sectionMeta: SectionMetaDTO) {
    const hostnameToSections: Record<string, string> = {}
    if (sectionMeta.defaultSectionMeta.Hostname) {
        hostnameToSections[sectionMeta.defaultSectionMeta.Hostname] = 'default'
    }

    for (const sectionOverride in sectionMeta.sectionOverrides) {
        if (sectionMeta.sectionOverrides.hasOwnProperty(sectionOverride)) {
            const sectionValues: Partial<SectionMetaInfoDTO> | undefined = (
                sectionMeta.sectionOverrides as any
            )[sectionOverride]

            if (sectionValues && sectionValues.Hostname) {
                hostnameToSections[sectionValues.Hostname] =
                    sectionOverride as any
            }
        }
    }

    return hostnameToSections
}
