import React from 'react'

export const HeartIcon = (props: { className: string }) => (
    <svg
        className={props.className}
        width="16"
        height="14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 4.364C16 1.954 14.065 0 11.68 0A4.304 4.304 0 008 2.085 4.305 4.305 0 004.32 0C1.934 0 0 1.954 0 4.364 0 5.677.576 6.851 1.483 7.65l6.123 6.185a.555.555 0 00.788 0l6.123-6.185A4.367 4.367 0 0016 4.364z"
            fill="#DE1529"
            fillRule="nonzero"
        />
    </svg>
)
