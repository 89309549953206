import React, { useEffect, useState } from 'react'
import { ThemeMargins } from '../../__styling'
import { RealEstateViewContainer } from './RealEstateView.styled'

export interface RealEstateViewProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const RealEstateView = (props: RealEstateViewProps) => {
    return (
        <RealEstateViewContainer
            verticalSpacing={props.verticalSpacing}
            verticalGutters={props.verticalGutters}
        >
            <div id="rev-widget-el" />
        </RealEstateViewContainer>
    )
}
