import { css, Global } from '@emotion/react'
import React from 'react'
import { useScript } from 'use-script'

interface RecaptchaParams {
    action: 'subscribe'
}

interface Grecaptcha {
    ready: (callback: () => void) => void
    execute: (key: string, params: RecaptchaParams) => Promise<string>
}

declare global {
    interface Window {
        grecaptcha: Grecaptcha
    }
}

const RecaptchaContext = React.createContext<{
    executeRecaptcha: (params: RecaptchaParams) => Promise<string>
}>({
    executeRecaptcha: () => Promise.reject('Recaptcha not available'),
})

interface RecaptchaProviderProps {
    children?: React.ReactNode
    siteKey: string
}

export function RecaptchaProvider({
    children,
    siteKey,
}: RecaptchaProviderProps) {
    const { loading, error } = useScript({
        src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
    })
    const [grecaptcha, setGrecaptcha] = React.useState<Grecaptcha>()

    const executeRecaptcha = React.useCallback(
        async ({ action }: RecaptchaParams) => {
            if (grecaptcha) {
                return await grecaptcha.execute(siteKey, {
                    action,
                })
            }

            return Promise.reject('Recaptcha unavailable')
        },
        [grecaptcha, siteKey],
    )

    React.useEffect(() => {
        if (!loading && !error) {
            window.grecaptcha.ready(() => {
                setGrecaptcha(window.grecaptcha)
            })
        }

        if (error) {
            console.error({ error }, 'Error loading recaptcha')
        }
    }, [loading, error])

    return (
        <RecaptchaContext.Provider value={{ executeRecaptcha }}>
            <Global
                styles={css`
                    .grecaptcha-badge {
                        visibility: hidden;
                    }
                `}
            />
            {children}
        </RecaptchaContext.Provider>
    )
}

export function useRecaptcha() {
    const context = React.useContext(RecaptchaContext)

    if (context === undefined) {
        throw new Error('useRecaptcha must be used within a RecaptchaProvider')
    }

    return context
}

export async function verifyRecaptcha(token: string, secret: string) {
    const resp = await fetch(
        `https://www.google.com/recaptcha/api/siteverify?response=${token}&secret=${secret}`,
        {
            method: 'POST',
        },
    )

    const jsonResp = await resp.json()

    if (jsonResp.success === false) {
        throw new Error('Recaptcha was unsuccesful')
    }
}
