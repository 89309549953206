import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { ComponentServices } from '@news-mono/web-common'
import { DataDefinition } from 'json-react-layouts-data-loader'
import { getRelatedPublicationListing } from '../../templates/Publication/lib/get-related-publication-listing'
import { RelatedPublications } from './Publication.props'

export interface PublicationDataContent {
    publicationId: string
}

export interface PublicationDataResult {
    relatedPublications?: RelatedPublications
}

export type ArticlePublicationDataDefinition = DataDefinition<
    PublicationDataContent,
    PublicationDataResult,
    ComponentServices
>

export const ArticlePublicationDataDefinitionLoader: ArticlePublicationDataDefinition =
    {
        loadData: async (props, services) => {
            let relatedPublications: RelatedPublications | undefined

            const appState = services.store.getState()
            if (
                isFeatureEnabled(
                    toFeatureState(appState.toggles),
                    'related-content',
                )
            ) {
                relatedPublications = await getRelatedPublicationListing(
                    services,
                    props.publicationId,
                )
            }

            return { relatedPublications }
        },
    }
