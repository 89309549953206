import {
    Classification,
    createRegisterableComponent,
} from '@news-mono/web-common'
import React from 'react'
import { FeatureHeadline } from './FeatureHeadline'

export interface FeatureHeadlingProps {
    text: string
    classification: Classification | undefined
}

export const FeatureHeadlineRegistration = createRegisterableComponent(
    'feature-headline',
    (props: FeatureHeadlingProps) => {
        return (
            <FeatureHeadline
                text={props.text}
                classification={props.classification}
            />
        )
    },
)
