import * as React from 'react'
import { IconChevronDownWide } from '../../icons/IconChevronDownWide/IconChevronDownWide'
import { AccordionProps } from '../FederalElectionAccordion'
import {
    StyledAccordionChevron,
    StyledAccordionPanel,
    StyledAccordionPanelCount,
    StyledAccordionPanelCountSubtitle,
    StyledAccordionPanelCountValue,
    StyledAccordionPanelHeading,
    StyledAccordionPanelSubeading,
    StyledAccordionPanelTitle,
    StyledAccordionPanelTitleLine,
    StyledAccordionPanelVotes,
    StyledAccordionPanelVotesDivider,
    StyledAccordionPanelVotesNumber,
    StyledAccordionPanelVotesParty,
} from './FederalElectionAccordionPanel.styled'

export const FederalElectionAccordionPanel: React.FC<AccordionProps> = (
    props,
) => {
    const {
        isOpen,
        isFullWidth,
        electionDayData,
        hideHoverAndFocusBackground,
    } = props

    return (
        <StyledAccordionPanel
            hideHoverAndFocusBackground={hideHoverAndFocusBackground || false}
        >
            <StyledAccordionPanelTitle>
                <div>
                    <StyledAccordionPanelHeading isFullWidth={isFullWidth}>
                        LIVE
                    </StyledAccordionPanelHeading>
                    <StyledAccordionPanelSubeading isFullWidth={isFullWidth}>
                        ELECTION PROJECTIONS
                    </StyledAccordionPanelSubeading>
                </div>
                <StyledAccordionPanelTitleLine />
            </StyledAccordionPanelTitle>
            <StyledAccordionPanelVotes>
                <div>
                    <StyledAccordionPanelVotesNumber isFullWidth={isFullWidth}>
                        {electionDayData.labor}
                    </StyledAccordionPanelVotesNumber>
                    <StyledAccordionPanelVotesParty isFullWidth={isFullWidth}>
                        LABOR
                    </StyledAccordionPanelVotesParty>
                </div>
                <StyledAccordionPanelVotesDivider></StyledAccordionPanelVotesDivider>
                <div>
                    <StyledAccordionPanelVotesNumber isFullWidth={isFullWidth}>
                        {electionDayData.coalition}
                    </StyledAccordionPanelVotesNumber>
                    <StyledAccordionPanelVotesParty isFullWidth={isFullWidth}>
                        COALITION
                    </StyledAccordionPanelVotesParty>
                </div>
            </StyledAccordionPanelVotes>
            <StyledAccordionPanelCount>
                <StyledAccordionPanelCountValue isFullWidth={isFullWidth}>
                    {electionDayData.votesCounted}%
                </StyledAccordionPanelCountValue>
                <StyledAccordionPanelCountSubtitle>
                    votes counted
                </StyledAccordionPanelCountSubtitle>
            </StyledAccordionPanelCount>
            <StyledAccordionChevron isOpen={isOpen}>
                <IconChevronDownWide />
            </StyledAccordionChevron>
        </StyledAccordionPanel>
    )
}
