import React, { useEffect, useState } from 'react'
import { StyledWidgetErrorContainer } from '../MatchTimelineWidget/MatchTimelineWidget.styled'
import { AflPlayerStatsWidgetProps } from './AflPlayerStatsWidget.routing'
import {
    StyledAflPlayerStatsWidgetTable,
    StyledAflPlayerStatsWidgetTableWrapper,
    StyledAflPlayerStatsWidgetWrapper,
    StyledLegend,
    StyledSwipeIndicator,
} from './AflPlayerStatsWidget.styled'
import { formatPlayerData } from './helpers/formatPlayerData'
import { useSortableTable } from './helpers/useSortableTable'
import { PlayerStatsTableBody } from './PlayerStatsTableBody'
import { PlayerStatsTableHead } from './PlayerStatsTableHead'
import { TeamControls } from './TeamControls'

export type TeamSide = 'home' | 'away' | 'both'
export interface ColumnProps {
    label: string
    accessor: string
    sortable: boolean
}

export interface TableDataProps {
    jumperNumber: string
    playerName: string
    goals: string
    behinds: string
    disposals: string
    kicks: string
    handballs: string
    marks: string
    tackles: string
    hitouts: string
    teamId: string
    freesFor: string
    freesAgainst: string
}

const columns: ColumnProps[] = [
    {
        label: '#',
        accessor: 'jumperNumber',
        sortable: true,
    },
    { label: 'Player', accessor: 'playerName', sortable: true },
    { label: 'G', accessor: 'goals', sortable: true },
    { label: 'B', accessor: 'behinds', sortable: true },
    { label: 'D', accessor: 'disposals', sortable: true },
    { label: 'K', accessor: 'kicks', sortable: true },
    { label: 'H', accessor: 'handballs', sortable: true },
    { label: 'M', accessor: 'marks', sortable: true },
    { label: 'T', accessor: 'tackles', sortable: true },
    { label: 'HO', accessor: 'hitouts', sortable: true },
    { label: 'FF', accessor: 'freesFor', sortable: true },
    { label: 'FA', accessor: 'freesAgainst', sortable: true },
]

export const AflPlayerStatsWidget = ({
    apiData,
}: AflPlayerStatsWidgetProps) => {
    const [selectedTeam, setSelectedTeam] = useState<TeamSide>('both')
    const [sortedColumnIndex, setSortedColumnIndex] = useState(3)
    const [sortField, setSortField] = useState('goals')
    const [sortOrder, setSortOrder] = useState('desc')
    const [dataForSort, setDataForSort] = useState(
        formatPlayerData(apiData?.report?.aflMatchStats, selectedTeam),
    )

    useEffect(() => {
        setDataForSort(
            formatPlayerData(apiData?.report?.aflMatchStats, selectedTeam),
        )
    }, [selectedTeam, apiData])

    const [tableData, handleSorting] = useSortableTable(
        dataForSort,
        sortField,
        sortOrder,
    )

    const handleTeamChange = (side: TeamSide) => {
        setSelectedTeam(side)
    }

    const handleSortedColumnIndex = (index: number) => {
        setSortedColumnIndex(index)
    }

    if (
        !tableData ||
        !apiData ||
        !apiData?.report?.aflMatchStats?.playerStats
    ) {
        return (
            <StyledWidgetErrorContainer>
                No data found
            </StyledWidgetErrorContainer>
        )
    }
    return (
        <StyledAflPlayerStatsWidgetWrapper>
            <TeamControls
                handleTeamChange={handleTeamChange}
                teamSide={selectedTeam}
            />
            <StyledSwipeIndicator>*Swipe to scroll</StyledSwipeIndicator>
            <StyledAflPlayerStatsWidgetTableWrapper>
                <StyledAflPlayerStatsWidgetTable cellSpacing="0">
                    <PlayerStatsTableHead
                        columns={columns}
                        handleSorting={handleSorting}
                        setSelectedColumnIndex={handleSortedColumnIndex}
                        order={sortOrder}
                        setOrder={setSortOrder}
                        sortField={sortField}
                        setSortField={setSortField}
                    />
                    <PlayerStatsTableBody
                        columns={columns}
                        tableData={tableData}
                        sortedColumnIndex={sortedColumnIndex}
                    />
                </StyledAflPlayerStatsWidgetTable>
            </StyledAflPlayerStatsWidgetTableWrapper>
            <StyledLegend>
                <strong>Legend</strong> #: Jumper Number, G: Goals, B: Behinds,
                D: Disposals, K: Kicks, H: Handball, M: Marks, T: Tackles, HO:
                Hitouts, FF: Free Kicks For, FA: Free Kicks Against
            </StyledLegend>
        </StyledAflPlayerStatsWidgetWrapper>
    )
}
