import { AdTargeting, PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../CardItem.Props'
import { ListCardProps } from './ListCard/ListCard'
import { ArticleCard, ArticleCardProps } from './ArticleCard/ArticleCard'
import { LeadCard, LeadCardProps } from './LeadCard/LeadCard'
import { ListCard } from './ListCard/ListCard'
import debug from 'debug'

export const cardViewabilityDebug = debug('card-viewability')

export interface BaseCardProps<CardKind extends string>
    extends CommonCardProps {
    kind: CardKind
    item: MaybeLoaded<PublicationCardItem>
    adTargeting?: AdTargeting
}

export type CardProps = LeadCardProps | ArticleCardProps | ListCardProps

export const Card = ({ kind, ...props }: CardProps) => {
    switch (kind) {
        case 'lead-card':
            return <LeadCard {...props} />
        case 'list-card':
            return <ListCard {...props} />
        case 'article-card':
        default:
            return <ArticleCard {...props} isStandaloneCard={true} />
    }
}
