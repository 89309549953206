import { RequiredAccessLevel } from '@west-australian-newspapers/publication-types'
import {
    CheckedCompositionInformation,
    RendererMiddleware,
} from 'json-react-layouts'
import React from 'react'
import { EntitledToView } from '../entitlements/EntitledToView'
import { ComponentServices } from '../routing/page-definition'

export const requireEntitlementsMiddleware: RendererMiddleware<
    ComponentServices,
    {
        requiresAccess?: {
            level: RequiredAccessLevel
            notAuthorized?: CheckedCompositionInformation
        }
    }
> = (componentProps, middlewareProps, services, next) => {
    if (middlewareProps.requiresAccess) {
        return (
            <EntitledToView
                requiredAccess={{ level: middlewareProps.requiresAccess.level }}
                entitled={() => (
                    <>{next(componentProps, middlewareProps, services)}</>
                )}
                notEntitled={() => (
                    <>
                        {middlewareProps.requiresAccess?.notAuthorized &&
                            services.layout
                                .createRenderers({
                                    services: services.services,
                                })
                                .renderCompositions(
                                    middlewareProps.requiresAccess
                                        .notAuthorized,
                                )}
                    </>
                )}
            />
        )
    }

    return next(componentProps, middlewareProps, services)
}
