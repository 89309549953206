import { AppState } from '@news-mono/web-common'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import { useSelector } from 'react-redux'

interface AuthEventDetails {
    wanUserId: string
    auth0UserId: string | undefined
    loginProvider: string
    socialProviders: string
    isLoggedIn: boolean
    occupantId: string | undefined
    entitlements: string[]
    subscriptionType: string
    subscriptionId?: string
}

export interface AuthGtmShape {
    gigya: string
    wanUserId: string
    auth0UserId: string | undefined
    loginProvider: string
    socialProviders: string
    loggedInStatus: string
    dti: string | undefined
    entitlements: string[]
    subscriptionType: string
}

export const UserAnalytics: React.FC = () => {
    const {
        wanUserId,
        auth0UserId,
        isLoggedIn,
        loginProvider,
        socialProviders,
        occupantId,
        entitlements,
        subscriptionType,
        subscriptionId,
    } = useSelector((state: AppState) => state.authentication)

    return (
        <PageProps
            pageProperties={toGtmShape({
                wanUserId,
                auth0UserId,
                loginProvider,
                socialProviders,
                isLoggedIn,
                occupantId,
                entitlements,
                subscriptionType,
                subscriptionId,
            })}
        />
    )
}

UserAnalytics.displayName = 'InternalUserAnalytics'
export function toGtmShape(event: AuthEventDetails): AuthGtmShape {
    return {
        gigya: event.wanUserId,
        wanUserId: event.wanUserId,
        auth0UserId: event.auth0UserId,
        loginProvider: event.loginProvider,
        socialProviders: event.socialProviders,
        loggedInStatus: getLoggedInStatus(event.isLoggedIn),
        dti: event.occupantId || '',
        entitlements: event.entitlements,
        subscriptionType: event.subscriptionType,
    }
}
export function getLoggedInStatus(isLoggedIn: boolean) {
    return isLoggedIn ? 'Logged in' : 'Logged out'
}
