import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    ImageBanner,
    ImageBannerProps,
} from '../../banners/ImageBanner/ImageBanner'

export const ImageBannerRegistration = createRegisterableComponent(
    'image-banner',
    (props: ImageBannerProps) => <ImageBanner {...props} />,
)
