import { layout } from '../../App.routing'

export const upLateSegment = [
    layout.component({
        type: 'sierra',
        props: {
            hasBackground: true,
            verticalSpacing: 'lg',
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },

            cardLayout: [
                'the-west-hero-lgNoBorder-withPublicationDate',
                'large-card-lgNoText',
                'large-card-lgNoText',
            ],
            hideByline: false,
            hasCollectionBorderBottom: true,
            hasHeroBorder: false,
            overrideRestrictedVideoPlayback: true,
            dataDefinitionArgs: {
                type: 'listing',
                topics: ['news/up-late'],
                includeSubTopics: false,
                paging: {
                    page: 0,
                    pageSize: 3,
                },
            },
            sectionHeader: {
                heading: 'Up Late',
                headingUrl: '/news/up-late',
            },
        },
    }),
]
