import * as React from 'react'

export interface IconArrowTopRightProps {
    width?: number
    height?: number
}

export const IconArrowTopRight: React.FC<IconArrowTopRightProps> = ({
    width = 23,
    height = 23,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 23 23"
            fill="none"
        >
            <path
                stroke="#161616"
                d="M4.5.5h18m0 0v18m0-18-22 22"
                vectorEffect="non-scaling-stroke"
            />
        </svg>
    )
}
