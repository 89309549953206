import {
    CardBreakpointRatios,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    FixedRatio,
} from '@news-mono/web-common'
import React from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { CardRouteProps } from '../card-props.routing'
import { MediaMode } from '../CardMedia/CardMedia'
import { PromotionalCard } from './PromotionalCard'

type PromotionalCardRoutingProps = CardRouteProps & {
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    verticalSpacing?: keyof ThemeMargins
    hasPadding?: boolean
    mediaMode?: MediaMode
    showCTA?: boolean
    mobileOnly?: true
}

export const CuratedPromotionalCardRegistration =
    createRegisterableComponentWithData(
        'curated-promotional-card',
        ContentDataDefinitionLoader,
        (props: PromotionalCardRoutingProps, data, services) => {
            const product = useProduct()
            if (!data.loaded) {
                return null
            }

            if (data.loaded && data.result.publications.length === 0) {
                // Can happen if we are driven by a curation and publication(s) have been killed
                // or curation metadata which can be empty by choice
                return null
            }

            const results = data.loaded && data.result.publications[0]
            if (results && results.cardType !== 'publication') {
                return null
            }

            const promotionType = results && results.promotionType

            return (
                <ImpressionAvailable
                    loading={!data.loaded}
                    available={() => {
                        if (!data.loaded) {
                            console.warn(
                                'Available should never be called when loading is true',
                            )
                            return
                        }

                        services.onEvent(
                            createCollectionAvailableEvent(
                                data.result.publications,
                                'PromotionalCard',
                                product,
                                services.onEvent,
                            ),
                        )
                    }}
                >
                    {(ref) => (
                        <PromotionalCard
                            innerRef={ref}
                            item={
                                results
                                    ? { loaded: true, result: results }
                                    : { loaded: false }
                            }
                            fixedRatio={props.fixedRatio}
                            fontScale={props.fontScale}
                            onEvent={services.onEvent}
                            hasPadding={props.hasPadding}
                            verticalSpacing={props.verticalSpacing}
                            cardNumber={1}
                            mediaMode={props.mediaMode}
                            showCTA={props.showCTA}
                            mobileOnly={props.mobileOnly}
                            promotionType={
                                promotionType ? promotionType : undefined
                            }
                        />
                    )}
                </ImpressionAvailable>
            )
        },
    )
