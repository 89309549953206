import styled from '@emotion/styled'
import { colors } from '../../../../__styling/settings/colors'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'

export const StyledNavListItem = styled('li')({
    listStyle: 'none',
    borderTop: `2px solid ${colors.thewest.greyElephant}`,
    borderBottom: `2px solid ${colors.thewest.greyElephant}`,
    marginBottom: calcRem(-2),
    fontSize: calcRem(16),
})
