import styled from '@emotion/styled'
import {
    StyledButton,
    StyledSharing,
} from '../../../buttons/Sharing/Sharing.styled'
import { breakpoint, calcRem, colors } from '../../../__styling'

interface StyledSharingWrapperProps {
    isLiveBlog?: boolean
}
export const StyledSharingWrapper = styled('div')<StyledSharingWrapperProps>(
    ({ isLiveBlog }) => ({
        height: '100%',

        [`${StyledSharing}`]: {
            margin: 0,
            height: '100%',
            display: isLiveBlog ? 'flex' : undefined,
            gap: isLiveBlog ? calcRem(4) : 0,

            [breakpoint('xxs')]: {
                gap: isLiveBlog ? calcRem(16) : 0,
            },
            '&::before': {
                display: 'none',
            },
            [`${StyledButton}`]: {
                background: 'transparent',
                border: 'none',
                margin: 0,
                marginLeft: isLiveBlog ? 0 : calcRem(16),

                color: colors.sevennews.nero70,
                '&:hover': {
                    background: 'transparent',
                    border: 'none',
                    color: colors.sevennews.red,
                },
                [breakpoint('md')]: {
                    marginLeft: isLiveBlog ? 0 : calcRem(24),
                },
            },
        },
    }),
)
