import React from 'react'
import {
    JanusBreakpointOptions,
    StyledGridItem,
    StyledJanus,
} from '../../compositions/Janus/Janus.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'

export type JanusLayoutRatio = '1:1' | '3:1' | '1:3' | '2:3'

export interface JanusProps {
    content: [React.ReactElement<any>, React.ReactElement<any>]
    hasBackgroundFill?: boolean
    verticalSpacing?: keyof ThemeMargins
    layoutRatio?: JanusLayoutRatio
    breakpoints?: JanusBreakpointOptions
    hasBorder?: boolean
    hasSidebar?: boolean
}

export const Janus: React.FC<JanusProps> = ({
    content,
    verticalSpacing,
    hasBackgroundFill,
    layoutRatio = '1:1',
    breakpoints,
    hasBorder,
    hasSidebar,
}) => {
    return (
        <StyledJanus
            hasBackgroundFill={hasBackgroundFill}
            verticalSpacing={verticalSpacing}
            layoutRatio={layoutRatio}
            breakpoints={breakpoints}
            hasBorder={hasBorder}
        >
            <StyledGridItem>{content[0]}</StyledGridItem>
            <StyledGridItem hasSidebar={hasSidebar}>
                {content[1]}
            </StyledGridItem>
        </StyledJanus>
    )
}

Janus.displayName = 'Janus'
