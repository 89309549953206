import styled from '@emotion/styled'
import React from 'react'
import { calcRem } from '../../../__styling'
import { CardMedia } from '../../CardMedia/CardMedia'
import { BaseCardProps } from '../Card'

type LargeCardMediaProps = Pick<
    BaseCardProps<any>,
    'onEvent' | 'item' | 'disableImageLazyLoad' | 'imageFetchPriority'
> & {
    willPlayVideoInline: boolean
    adUnitPath?: string
}

export const LargeCardMedia = ({
    item,
    onEvent,
    willPlayVideoInline,
    adUnitPath,
    disableImageLazyLoad,
    imageFetchPriority,
}: LargeCardMediaProps) => {
    return (
        <StyledCardMedia
            fixedRatio={{
                default: willPlayVideoInline ? '16:9' : '4:3',
            }}
            CSSCrop={{
                default: {
                    width: 161,
                    height: 121,
                },
                xxs: {
                    width: 770,
                    height: 577,
                },
            }}
            imageWidths={{
                desktop: '100%',
                tablet: '100%',
                mobile: '100%',
                fallbackWidth: 770,
            }}
            item={item}
            onEvent={onEvent}
            disableImageLazyLoad={disableImageLazyLoad}
            imageFetchPriority={imageFetchPriority}
            mediaMode={'fullHeight'}
            isLarge={true}
            willPlayVideoInline={willPlayVideoInline}
            adUnitPath={adUnitPath}
        />
    )
}

export const StyledCardMedia = styled(CardMedia)({
    // Workaround for styles not overriding CardMedia styles properly.
    '&': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: calcRem(577),
    },
})
