const ContentPartners: Set<string> = new Set([
    'The New York Times',
    'The Economist',
    'AAP',
    'CNBC',
    'CarExpert',
    'view.com.au',
    'Raiz Invest',
    'Daily Mail',
    'The Washington Post',
])

export function isContentPartner(source: string | undefined): boolean {
    if (!source) {
        return false
    }
    return ContentPartners.has(source)
}
