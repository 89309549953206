import { useTheme } from '@emotion/react'
import {
    createCardClickedEvent,
    FixedRatio,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { FundingLabel } from '../../advertising/FundingLabel/FundingLabel'
import { CardByline } from '../../cards/CardByline/CardByline'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    CardTextContainer,
    StyledHeroCard,
    StyledHeroCardMedia,
    StyledHeroCardText,
    StyledLink,
} from '../../cards/HeroCard/HeroCard.styled'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { FontScales } from '../../__styling/settings/fontScale'
import { themedValue } from '../../__styling/themed-value'
import { MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'

export interface HeroLayout {
    type: 'hero'
    hasBackground?: boolean
    fixedRatio?: '16:9'
    mediaMode?: MediaMode
}

export interface HeroCardProps extends CommonCardProps, ResponsiveContainer {
    hasBackground?: boolean
    mediaMode?: MediaMode
    teaserMode: TeaserMode
    imageLayout?: ResponsivePictureLayout
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem>
    heroLayout?: HeroLayout
    /**
     * HeroCard is typically first in the page so default is true
     */
    hideByline?: boolean
    disableImageLazyLoad?: boolean
    hasHeroBorder?: boolean
}

export const HeroCard: React.FC<HeroCardProps> = ({
    className,
    hasBackground,
    imageLayout,
    hideByline,
    teaserMode,
    mediaMode,
    onEvent,
    item,
    fixedRatio,
    cardNumber,
    containerWidthRatios,
    disableImageLazyLoad = true,
    hasHeroBorder = true,
}) => {
    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'InternalHeroCard',
                'HeroCard',
                cardNumber,
                fundingType,
            ),
        )
    }

    const theme = useTheme()

    const showByline = theme.kind !== 'perthnow' && !hideByline
    const storyClassification = true
    const fundingType = item.loaded
        ? getFundingType(item.result, storyClassification)
        : undefined
    const primaryTopic = item.loaded ? item.result.primaryTopic.id : undefined

    const fontScale = themedValue(theme, {
        thewest: 1.75,
        sevennews: 1.7,
        fallback: 2,
    }) as FontScales

    return (
        <StyledHeroCard
            className={className}
            data-topic={primaryTopic}
            isSponsored={!!fundingType}
            hasBackground={hasBackground}
            primaryTopic={primaryTopic}
            hasHeroBorder={hasHeroBorder}
        >
            <StyledLink
                to={toLinkWithHint(item)}
                onClick={linkClicked}
                hasBackground={hasBackground}
                isLoading={!item.loaded}
            >
                <CardTextContainer>
                    <StyledHeroCardText
                        item={item}
                        hasBackground={hasBackground}
                        kickerMode={{ size: 'large' }}
                        teaserMode={teaserMode}
                        fontScale={fontScale}
                        onEvent={onEvent}
                    />
                    {showByline && (
                        <CardByline
                            item={item}
                            onEvent={onEvent}
                            showProfile={true}
                        />
                    )}
                    {fundingType && (
                        <FundingLabel
                            hasBackground={hasBackground}
                            fundingType={fundingType}
                        />
                    )}
                </CardTextContainer>
                {mediaMode !== 'hidden' && (
                    <StyledHeroCardMedia
                        isLarge
                        item={item}
                        fixedRatio={fixedRatio}
                        hasBackground={hasBackground}
                        imageWidths={applyContainerRatio(containerWidthRatios, {
                            mobile: '100vw',
                            tablet: '50vw',
                            desktop: `${theme.siteMetrics.mainContentWidth}px`,
                            fallbackWidth: theme.siteMetrics.mainContentWidth,
                        })}
                        onEvent={onEvent}
                        className={className}
                        mediaMode={mediaMode}
                        disableImageLazyLoad={disableImageLazyLoad}
                        imageLayout={
                            imageLayout ||
                            ResponsivePictureLayout.ObjectFitContain
                        }
                    />
                )}
            </StyledLink>
        </StyledHeroCard>
    )
}
HeroCard.displayName = 'HeroCard'
