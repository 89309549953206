import { NielsenDCRMetaData, NielsenRenderType } from '@news-mono/web-common'
import { perthNowCentral, perthNowNorth, perthNowSouth } from '../ipsos/types'

/**
 * Expected output for the following Dcr metadata is
 *
 * for PerthNow/TheWest: (SWM-5165, SWM-5061)
 *  section: 'home' for / or first level topic (e.g 'news' if /news)
 *  segA: undefined
 *  segB: 'The West Australian - website' or 'PerthNow - website'
 *  segC: The West Australian - Google AMP || undefined
 *  subbrand: CNG: c26 || undefined
 *
 */
export function getWANDcrMeta(
    siteName: string,
    pathName: string,
    renderType: NielsenRenderType,
): NielsenDCRMetaData {
    // const siteName = getNielsenSiteName(product)
    // generate correct segC based on renderType
    let segC = undefined
    switch (renderType) {
        case 'google-amp':
            segC = `${siteName} - Google AMP`
            break
        default:
            break
    }

    const topLevelSection = getWANTopLevelSection({ pathname: pathName })
    let section = topLevelSection

    if (topLevelSection === 'community-news') {
        const cngMasthead = getCNGMastheadFromPath(pathName)
        section = cngMasthead
    }

    // dcr meta for perthnow / the west, segA not used
    return {
        section,
        ...(segC && { segC }),
    }
}

export function getWANTopLevelSection(location: { pathname: string }) {
    const path = location.pathname
    // Should not occur but if it does return unknown section
    if (path.indexOf('/') === -1) {
        return 'unknown'
    }

    if (path.includes('lifestyle/motoring')) {
        return 'lifestyle/motoring'
    }

    const base = 'home'

    let item = path.split('/')[1]
    const secondLevelSection = path.split('/')[2]

    if (item === 'local-news') {
        item = getPNLocalNewsSection(secondLevelSection)
    }

    if (item === 'community-news' && secondLevelSection === 'mandurah-times') {
        item = 'mandurah'
    }

    if (item === 'editions' && secondLevelSection) {
        item = `edition_${secondLevelSection}`
    }

    const resolved = item

    return path === '/' ? base : resolved
}

function getPNLocalNewsSection(path: string) {
    if (perthNowNorth.includes(path)) {
        return 'north'
    }
    if (perthNowCentral.includes(path)) {
        return 'central'
    }
    if (perthNowSouth.includes(path)) {
        return 'south'
    }
    return 'local-news'
}

export function getCNGMastheadFromPath(path: string) {
    const defaultSection = 'community-news'

    if (path.indexOf('/') === -1) {
        return defaultSection
    }

    // It could be a cng section or other string
    const secondLevelSection = path.split('/')[2]

    switch (secondLevelSection) {
        case 'canning-gazette':
            return 'Canning Gazette'
        case 'cockburn-gazette':
            return 'Cockburn Gazette'
        case 'comment-news':
            return 'Comment News'
        case 'eastern-reporter':
            return 'Eastern Reporter'
        case 'fremantle-gazette':
            return 'Fremantle Gazette'
        case 'hills-avon-valley-gazette':
            return 'Hills Avon Valley Gazette'
        case 'joondalup-times':
            return 'Joondalup Times'
        case 'mandurah-times':
            return 'Mandurah Times'
        case 'melville-gazette':
            return 'Melville Gazette'
        case 'midland-kalamunda-reporter':
            return 'Midland Kalamunda Reporter'
        case 'north-coast-times':
            return 'North Coast Times'
        case 'southern-gazette':
            return 'Southern Gazette'
        case 'stirling-times':
            return 'Stirling Times'
        case 'the-advocate':
            return 'The Advocate'
        case 'wanneroo-times':
            return 'Wanneroo Times'
        case 'weekend-kwinana-courier':
            return 'Weekend Kwinana Courier'
        case 'western-suburbs-weekly':
            return 'Western Suburbs Weekly'
        default:
            return defaultSection
    }
}
