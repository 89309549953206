import { layout } from '../../App.routing'

export const politicsSegmentContent = layout.component({
    type: 'lima',
    props: {
        fixedRatios: ['16:9'],
        intermediateColumns: 3,
        finalColumns: 3,
        hideByline: false,
        hasBackground: true,

        cardLayout: [
            ['teaserWithBackground'],
            [
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
            ],
            [
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-xsTeaser-smNoTeaser',
                },
            ],
        ],
        cardOrientation: { type: 'Landscape-Portrait' },
        sectionHeader: {
            heading: 'Politics',
            headingUrl: '/politics',
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'politics',
            offset: 0,
            pageSize: 7,
        },
    },
})

export function promoComponent(renderTarget: string) {
    if (renderTarget === 'app') {
        return [
            layout.component({
                type: 'section-header',
                props: {
                    heading: 'Promotion',
                    hasBottomMargin: true,
                    invisibleHeaderBreakpoint: 'md',
                },
            }),
            layout.component({
                type: 'promotional-card',
                props: {
                    fixedRatio: '4:3',
                    hasBackground: true,
                    hasPadding: true,
                    mediaMode: 'edgeToEdge',
                    fontScale: 0.88,
                    showCTA: true,
                    cardOrientation: {
                        defaultLayout: 'landscape',
                        alternativeLayoutOptions: {
                            breakpoint: 'sm',
                            layout: 'portrait',
                        },
                    },
                    dataDefinitionArgs: {
                        type: 'curation',
                        name: 'promotional-cards',
                        offset: 4,
                        pageSize: 1,
                    },
                },
            }),
        ]
    } else {
        return [
            layout.component({
                type: 'paper-promo',
                props: {},
            }),
        ]
    }
}

export function politicsSegment(renderTarget: string) {
    return layout.nestedComposition({
        type: 'janus',
        props: {
            layoutRatio: '3:1',
            stretchColumns: true,
            verticalSpacing: 'xl',
        },
        contentAreas: {
            left: [politicsSegmentContent],
            right: promoComponent(renderTarget),
        },
    })
}
