import {
    isPublicationCardItemWithVideo,
    PublicationCardItem,
    PublicationType,
    TogglesReduxState,
} from '@news-mono/web-common'

// Suggestions for this function name? Or should I just make the original fucntion generic?
const isPublicationCardItemLocal = (
    input: PublicationCardItem | PublicationType,
): input is PublicationCardItem => 'cardType' in input

/**
 *
 * @param publicationInfo
 * @param toggleVal
 * @returns
 */
export const shouldExcludeVideoSeries = (
    publicationInfo: PublicationCardItem | PublicationType,
    toggleVal: TogglesReduxState,
) => {
    const retrieveVideoSeries = (
        input: PublicationCardItem | PublicationType,
    ) => {
        if (isPublicationCardItemLocal(input)) {
            return isPublicationCardItemWithVideo(input)
                ? input.video.videoSeries
                : undefined
        }

        return input.heroVideo?.seriesInfo
    }

    const seriesInfo = retrieveVideoSeries(publicationInfo)

    // We want to exclude in the following situations
    // Condition 1
    // 1. Not a video series
    // 2. Is a video series but is a trailer
    // Condition 2
    // Toggle is on
    return (
        (!seriesInfo || seriesInfo.episode === 'trailer') &&
        Boolean(toggleVal['exclude-video-series-from-playlists'])
    )
}
