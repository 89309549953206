import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const styledListClassName = 'RelatedContent'
export const styledWrapperClassName = 'RelatedContentWrapper'

interface RelatedContainerProps {
    hideSeparator?: boolean
}

export const StyledRelatedContentContainer = withClass(styledWrapperClassName)(
    styled('aside')<RelatedContainerProps>(
        ({ hideSeparator, theme }) =>
            theme.kind === 'thewest' &&
            !hideSeparator && {
                padding: calcRem(theme.margins.md, 0, 0),
                borderTop: `1px solid ${colors.thewest.greyDolphin}`,
            },
    ),
)

export const StyledRelatedContent = withClass(styledListClassName)(
    styled('ul')(
        {
            ...breakWords,
            listStyle: 'none',
            clear: 'both',
        },
        (props) => ({
            margin: themedValue(props.theme, {
                thewest: '0',
                perthnow: calcRem(
                    0,
                    0,
                    metrics.perthnow.textMargin,
                    metrics.perthnow.margins.md,
                ),
                sevennews: `0 0 ${calcRem(metrics.sevennews.textMargin)} 0`,
                fallback: undefined,
            }),
            padding: themedValue(props.theme, {
                thewest: calcRem(0, props.theme.margins.md),
                fallback: calcRem(0, 16),
            }),
        }),
    ),
)

export const StyledListItem = styled('li')((props) => ({
    position: 'relative',

    '&::after': {
        content: `" "`,
        transform: `translate(-100%, -50%)`,
        left: '-7px',
        top: calcRem(12), // half the line-height of the text
        position: 'absolute',
        pointerEvents: 'none',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '4.5px 0 4.5px 7.8px',
        borderColor: `transparent transparent transparent ${colors.thewest.greyHippo}`,
    },

    margin: themedValue(props.theme, {
        thewest: calcRem(0, 0, props.theme.margins.sm, 0),
        perthnow: calcRem(0, 0, metrics.perthnow.margins.sm, 0),
        sevennews: calcRem(0, 0, metrics.sevennews.margins.sm, 0),
        fallback: undefined,
    }),
}))

export const StyledSectionHeader = styled(SectionHeader)((props) => ({
    marginBottom: calcRem(props.theme.margins.md),
}))

export const StyledIconPremium = styled(IconPremium)({
    width: 17,
    height: 17,
    marginLeft: calcRem(1),
    verticalAlign: 'text-bottom',
})
