import React from 'react'
import { StyledHeading } from '../../typography/Heading/Heading.styled'

export interface HeadingProps {
    text: string
    className?: string
}

export const Heading: React.FC<HeadingProps> = ({ text, className }) => (
    <StyledHeading className={className}>{text}</StyledHeading>
)
Heading.displayName = 'Heading'
