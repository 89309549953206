import React from 'react'
import CommentsCount from '../../cards/TheNightly/components/card-body/byline/CommentsCount'
import {
    StyledCommentsWrapper,
    StyledText,
    StyledWebLink,
} from './CommentButton.styled'

export type CommentsButtonProps = {
    url?: string
}

export const CommentButtonTN: React.FC<CommentsButtonProps> = ({
    ...props
}: CommentsButtonProps) => {
    return (
        <StyledCommentsWrapper>
            <StyledWebLink to={'#coral_thread'}>
                <CommentsCount articleUrl={props.url} size={24} />
                <StyledText>Comments</StyledText>
            </StyledWebLink>
        </StyledCommentsWrapper>
    )
}
