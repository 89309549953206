import {
    ContentDataDefinitionLoader,
    PublicationCardItem,
    createRegisterableComponentWithData,
    useFeature,
} from '@news-mono/web-common'
import { OlympicsCollection } from './OlympicsCollection'
import React from 'react'

/** The props utilised to configure the olympics collection layout */
export interface OlympicsCollectionWithDataProps {
    /** Any special className needed to pass through to the top */
    className?: string
    /** Default's to a value of true */
    isHomepage?: boolean
    /** If true, the OlympicsCollectionParent div will have no margin. */
    removeTopBottomMargin?: boolean
}

export const OlympicsCollectionRegistration =
    createRegisterableComponentWithData(
        'olympics-collection',
        ContentDataDefinitionLoader,
        (props: OlympicsCollectionWithDataProps, data, services) => {
            const results = data.loaded && data.result.kind === 'curation'
            const isPublication =
                results &&
                data.result.publications.filter(
                    (cardItem) => cardItem.cardType !== 'publication',
                ).length === 0
            const items =
                results && isPublication
                    ? (data.result.publications as PublicationCardItem[])
                    : []
            const onEvent = services.onEvent

            const isEnabled = useFeature('paris-olympics-homepage-collection')
            const useTallyWidget = useFeature('paris-olympics-collection-tally')

            // Do a few checks that might cause issues with the OlympicsCollection if
            // passed down without checking.
            if (
                !results ||
                !isPublication ||
                !isEnabled ||
                items.length === 0
            ) {
                return <></>
            }

            return (
                <OlympicsCollection
                    onEvent={onEvent}
                    items={{
                        loaded: true,
                        result: items,
                    }}
                    tallyToggle={useTallyWidget}
                    isHomepage={props.isHomepage}
                    removeTopBottomMargin={props.removeTopBottomMargin}
                />
            )
        },
    )
