import { handleUnknownError } from '@news-mono/common'
import { DeferredAccountCreationResponse, Entitlements, Subscriptions } from '.'
import { debugSwg, stopGoogleAutoLogin, storeCookie } from '../..'
import { AuthenticationState } from '../authentication'
import {
    buildRedirectUrl,
    DeferredAccountCreationReturnDTO,
    googleLoginRedirect,
} from './subscriptions'

export async function createOrUpdateAccount(
    authBaseUrl: string,
    userResponse: DeferredAccountCreationResponse,
    entitlements: Entitlements,
) {
    debugSwg('Sending SwG create or update account request', {
        userResponse,
        entitlements,
    })

    const url = `${authBaseUrl}/google/deferred-account-creation`

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userToken: userResponse.userData.idToken,
            entitlementsToken: entitlements.raw,
        }),
    })

    if (response.ok) {
        const result =
            (await response.json()) as DeferredAccountCreationReturnDTO
        debugSwg('Completed SwG create or update', result)
        return result
    } else {
        debugSwg('Failed SwG Create or update', response)
        throw Error('Failed SwG Create or update')
    }
}

export async function deferredAccountCreation(
    authBaseUrl: string,
    subscriptions: Subscriptions,
    entitlements: Entitlements,
    authenticationState: AuthenticationState,
) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const deferredAccountCreation = async () => {
            debugSwg('Initiating SwG deferred account creation')
            try {
                const response =
                    await subscriptions.completeDeferredAccountCreation({
                        entitlements,
                        consent: true,
                    })

                // 1. The user has consented to account creation. Create account
                // based on the response.
                try {
                    const { sub } = await createOrUpdateAccount(
                        authBaseUrl,
                        response,
                        entitlements,
                    )
                    // 2. Signal that the account creation is complete.
                    await response.complete()
                    // 3. The flow is complete.
                    debugSwg('Deferred account complete, redirecting to login')

                    // Need to see if the user is logged in to the account they bought the SKU with
                    const isSameAccount =
                        response.userData.email ===
                        authenticationState.userEmail

                    // If the user is logged in, refresh the page and this will trigger an update of the user's entitlements
                    if (authenticationState.isLoggedIn && isSameAccount) {
                        window.location.href = buildRedirectUrl(window.location)
                    } else {
                        await googleLoginRedirect(subscriptions, sub)
                    }

                    return true
                } catch (error) {
                    // If the account creation fails, redirect the user to an error page
                    //window.location = errorDetails.redirectUrl
                    debugSwg('Failed create or update account', { error })
                    return false
                }
            } catch (error) {
                const err = handleUnknownError(error)
                // If the error occured as part of the user saying no to creating an account - then we need to implement a cookie to stop the autoLogin flow
                if (err.name === 'AbortError') {
                    storeCookie(
                        stopGoogleAutoLogin,
                        {},
                        {
                            // This is in days, not millieseconds
                            expires: 0.5,
                        },
                    )
                }
                debugSwg('Failed initiating SwG deferred account creation', {
                    err,
                })
                return false
            }
        }
        resolve(await deferredAccountCreation())
    })
}
