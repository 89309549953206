import styled, { StyledComponent } from '@emotion/styled'
import { WebNavLink } from '@news-mono/web-common'
import { IconUser } from '../../../../icons/IconUser/IconUser'
import { colors } from '../../../../__styling/settings/colors'
import { metrics } from '../../../../__styling/settings/metrics'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { transition } from '../../../../__styling/style-mixins'

export const StyledFlyoutNavActions = styled('div')({
    display: 'flex',
    flexShrink: 0,
    padding: calcRem(0, 13),
    marginBottom: calcRem(metrics.thewest.margins.md),
})

export const StyledActionLink = styled(WebNavLink)(
    {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 0,
        alignItems: 'center',
        justifyContent: 'center',
        padding: calcRem(4, metrics.thewest.margins.sm),
        borderRadius: 2,
        border: 'none',
        color: colors.white,
        outline: 'none',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: 700,
        cursor: 'pointer',

        ...transition({ properties: ['background-color', 'color'] }),

        '&:not(:first-child)': {
            marginLeft: calcRem(metrics.thewest.margins.md),
        },

        '&:hover': {
            backgroundColor: colors.thewest.greyElephant,
        },

        '&:focus': {
            backgroundColor: colors.thewest.greyElephant,
            textDecoration: 'underline',
        },
    },
    ({ theme }) => ({
        backgroundColor: theme.sectionValues.primaryColor,
    }),
)

export const StyledActionButton = StyledActionLink.withComponent(
    'button',
) as StyledComponent<JSX.IntrinsicElements['button']>

export const StyledActionLinkOutline = styled(StyledActionLink)({
    backgroundColor: 'transparent',
    border: `1px solid ${colors.white}`,
    cursor: 'pointer',

    '&:focus': {
        backgroundColor: colors.thewest.greyElephant,
        borderColor: colors.thewest.greyElephant,
    },

    '&:hover': {
        backgroundColor: colors.white,
        color: colors.thewest.greyGorilla,
    },
})

export const StyledActionLinkOutlineButton =
    StyledActionLinkOutline.withComponent('button') as StyledComponent<
        JSX.IntrinsicElements['button']
    >
export const StyledActionLinkOutlineSkeleton =
    StyledActionLinkOutline.withComponent('span') as StyledComponent<{}>

export const StyledUserLink = styled(StyledActionLink)({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
})

export const StyledUserName = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})

export const StyledIconUser = styled(IconUser)({
    width: calcRem(20),
    height: calcRem(20),
    flexShrink: 0,
    marginLeft: calcRem(8),
    verticalAlign: 'middle',
    stroke: colors.white,
})
