import {
    createRegisterableComponent,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import { getPublicationInfoFromRouteResolution } from '../../templates/Publication/SharedPublication.routing'
import { FigCaption, FigCaptionProps } from './FigCaption'

export const FigCaptionRegistration = createRegisterableComponent(
    'figcaption',
    (props: FigCaptionProps, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)

        if (!isArticleLikeType(publicationInfo.publication)) {
            throw new Error('Article component rendered for non-article')
        }
        const heroImage = publicationInfo.publication.heroImage
        const byline = heroImage && heroImage.byline
        const credit = heroImage && heroImage.credit
        const captionText = heroImage && heroImage.captionText

        return (
            <FigCaption
                captionText={captionText}
                credit={credit}
                byline={byline}
                kind={props.kind}
                figcaptionLayout={props.figcaptionLayout}
                separatedFromMedia={props.separatedFromMedia}
            />
        )
    },
)
