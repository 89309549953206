import React from 'react'
import { StyledRewardsText } from './IconWestRewards.styled'

export interface IconWestRewardsProps {
    className?: string
}

export const IconWestRewards: React.FC<IconWestRewardsProps> = ({
    className,
}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 202 92"
    >
        <title>West Rewards</title>
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor">
                <StyledRewardsText transform="translate(109 52)">
                    <tspan x="0" y="15">
                        west
                    </tspan>
                    <tspan x="0" y="31.988">
                        rewards
                    </tspan>
                </StyledRewardsText>
                <path d="M191.993 78.222l-5.35-4.462a.257.257 0 01-.03-.369l1.179-1.35a.268.268 0 01.376-.027l7.072 6.01a.258.258 0 010 .395l-7.072 6.013a.27.27 0 01-.174.063.278.278 0 01-.202-.091l-1.178-1.35a.257.257 0 01.03-.369l5.35-4.463z" />
            </g>
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.35"
            >
                <path d="M48.659 78.28c-4.536 3.321-10.125 5.346-16.119 5.346-14.985 0-27.135-12.15-27.135-27.135 0-14.985 12.15-27.216 27.135-27.216a27.08 27.08 0 0119.035 7.776" />
                <path d="M48.578 72.124a22.379 22.379 0 01-16.038 6.723c-12.393 0-22.437-10.044-22.437-22.437S20.147 33.973 32.54 33.973c6.885 0 13.041 3.078 17.172 8.019" />
            </g>
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.35"
            >
                <path d="M16.259 49.039l5.913 5.913a4.987 4.987 0 007.047 0 4.987 4.987 0 000-7.047l-5.913-5.913M19.823 45.556l24.705 24.705M37.805 54.952L22.334 70.423M40.154 45.556a4.987 4.987 0 017.047 0 4.987 4.987 0 010 7.047l-2.349 2.349a4.987 4.987 0 01-7.047 0 4.987 4.987 0 010-7.047l2.349-2.349z" />
            </g>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.35"
                d="M101.593 43.653h21.06V36.12c-2.755 0-5.023-2.268-5.023-5.022 0-2.754 2.269-5.022 5.023-5.022v-7.614H84.014v7.533c2.755 0 5.023 2.268 5.023 5.022"
            />
            <path
                fill="#000"
                fillRule="nonzero"
                d="M113.418 22.674h1.701v-1.701h-1.7zM113.418 26.076h1.701v-1.701h-1.7zM113.418 29.397h1.701v-1.701h-1.7zM113.418 32.799h1.701v-1.701h-1.7zM113.418 36.12h1.701v-1.701h-1.7zM113.418 39.522h1.701V37.82h-1.7zM113.418 42.843h1.701V41.14h-1.7z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.35"
                d="M100.945 38.63h8.262V23.564H94.14v10.044M99.163 28.587h5.022M99.163 33.608h5.022"
            />
            <g>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M77.13 82.978H59.15a6.534 6.534 0 01-6.562-6.561V55.924c0-6.318 1.863-12.474 4.941-18.063 3.24-5.913 4.86-12.555 4.86-19.197V7.567h9.883v12.312c0 6.399 1.943 12.555 4.94 18.144"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M57.529 37.861l1.781 2.592c3.889 5.589 12.15 5.589 16.039.081l1.781-2.511M52.587 56.734H75.51M52.587 73.177H75.51M62.389 14.128h9.881"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M70.65 35.431c0 1.782-1.458 3.321-3.32 3.321-1.864 0-3.24-1.458-3.24-3.321a3.25 3.25 0 013.24-3.24c1.781 0 3.32 1.458 3.32 3.24z"
                />
                <path
                    stroke="#000"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M88.713 69.856v13.122"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M80.532 82.978h16.363"
                />
                <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M91.953 55.114c-.89 0-1.62.729-1.62 1.62 0 .891.73 1.62 1.62 1.62.891 0 1.62-.729 1.62-1.62 0-.891-.728-1.62-1.62-1.62M87.012 60.055c-.89 0-1.62.729-1.62 1.62 0 .891.73 1.62 1.62 1.62.891 0 1.62-.729 1.62-1.62 0-.891-.648-1.62-1.62-1.62"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.35"
                    d="M95.274 37.051H82.152l-3.24 22.923c0 5.427 4.374 9.882 9.882 9.882s9.802-4.374 9.802-9.882l-3.322-22.923zM80.532 50.173h16.363"
                />
            </g>
        </g>
    </svg>
)
