import { getTimeAgo, getTimeAgoLong } from '@news-mono/web-common'
import { format as formatToTimeZone, utcToZonedTime } from 'date-fns-tz'
import format from 'date-fns/format'
import enAU from 'date-fns/locale/en-AU'
import React from 'react'
import {
    ClockIcon,
    ClockIconAlt,
    StyledTimestamp,
} from '../../typography/Timestamp/Timestamp.styled'
import { useProduct } from '../../__product/useProduct'
import { ThemedRender } from '../../__styling/themed-render'

const componentTheme = {
    sevennews: {
        timestamp: {
            dayMonthYear: 'dd/MM/yyyy',
            dateOnly: 'EEEE, d MMMM yyyy',
            dateTime: 'EEEE, d MMMM yyyy h:mm a',
            dateTimeOnly: 'd MMMM yyyy h:mm a',
            dateTimeOnlyWithComma: 'd MMMM yyyy, h:mma',
            dateTimeShortDay: 'EEE, d MMMM yyyy h:mm a',
            dateTimeShortDayNoTime: 'EEE, d MMMM yyyy',
            dateTimeWithZone: 'EEEE, d MMMM yyyy h:mm a zzz',
            dateTimeOnlyWithZone: 'd MMMM yyyy h:mm a zzz',
            timeOnly: 'hh:mm a',
        },
        timezone: 'Australia/Sydney',
    },
    thewest: {
        timestamp: {
            dayMonthYear: 'dd/MM/yyyy',
            dateOnly: 'EEEE, d MMMM yyyy',
            dateTime: 'EEEE, d MMMM yyyy h:mma',
            dateTimeOnly: 'd MMMM yyyy h:mma',
            dateTimeOnlyWithComma: 'd MMMM yyyy, h:mma',
            dateTimeShortDay: 'EEE, d MMMM yyyy h:mma',
            dateTimeShortDayNoTime: 'EEE, d MMMM yyyy',
            dateTimeWithZone: 'EEEE, d MMMM yyyy h:mm a zzz',
            dateTimeOnlyWithZone: 'd MMMM yyyy h:mm a zzz',
            timeOnly: 'hh:mm a',
        },
        timezone: 'Australia/Perth',
    },
    perthnow: {
        timestamp: {
            dayMonthYear: 'dd/MM/yyyy',
            dateOnly: 'MMMM d, yyyy',
            dateTime: 'MMMM d, yyyy h:mma',
            dateTimeOnly: 'd MMMM yyyy h:mma',
            dateTimeOnlyWithComma: 'd MMMM yyyy, h:mma',
            dateTimeShortDay: 'EEE, d MMMM yyyy h:mma',
            dateTimeShortDayNoTime: 'EEE, d MMMM yyyy',
            dateTimeWithZone: 'EEEE, d MMMM yyyy h:mm a zzz',
            dateTimeOnlyWithZone: 'd MMMM yyyy h:mm a zzz',
            timeOnly: 'hh:mm a',
        },
        timezone: 'Australia/Perth',
    },
    // Todo: update this for theNightly
    thenightly: {
        timestamp: {
            dayMonthYear: 'dd MMM, yyyy',
            dateOnly: 'EEEE, d MMMM yyyy',
            dateTime: 'EEEE, d MMMM yyyy h:mma',
            dateTimeOnly: 'd MMMM yyyy h:mma',
            dateTimeOnlyWithComma: 'd MMMM yyyy, h:mma',
            dateTimeShortDay: 'EEE, d MMMM yyyy h:mma',
            dateTimeShortDayNoTime: 'EEE, d MMMM yyyy',
            dateTimeWithZone: 'EEEE, d MMMM yyyy h:mm a zzz',
            dateTimeOnlyWithZone: 'd MMMM yyyy h:mm a zzz',
            timeOnly: 'hh:mm a',
        },
        timezone: 'Australia/Perth',
    },
} as const

export type TimestampFormat =
    | 'dateOnly'
    | 'relative'
    | 'relativeLongFormat'
    | 'relativeLongFormatAndTimestamp'
    | 'dateTimeOnly'
    | 'dateTimeOnlyWithComma'
    | 'dayMonthYear'
    | 'dateTimeShortDay'
    | 'dateTimeShortDayNoTime'
    | 'default'
    | 'customSevenNewsDateTime'
    | 'customSevenNewsDateTimeOnly'
    | 'dateOnly7News'

export type TimestampLabel =
    | 'Published:'
    | 'Updated:'
    | 'Last updated'
    | 'Updated'
    | ''

export interface TimestampProps {
    timestamp: string | number
    iconVisible?: boolean
    className?: string
    timestampFormat?: TimestampFormat
    timestampLabel?: TimestampLabel
    id?: string
}

export const Timestamp: React.FC<TimestampProps> = ({
    timestamp,
    iconVisible,
    className,
    timestampFormat = 'default',
    timestampLabel = undefined,
    id,
}) => {
    const product = useProduct()

    if (!timestamp) {
        return null
    }
    const themeValues = componentTheme[product].timestamp
    const themeTimezoneValue = componentTheme[product].timezone

    const getHumanReadableTimestamp = (timestampFormat: TimestampFormat) => {
        const dateValue = new Date(timestamp)

        switch (timestampFormat) {
            case 'relative':
                return getTimeAgo(dateValue)
            case 'relativeLongFormat':
                return getTimeAgoLong(dateValue)
            case 'relativeLongFormatAndTimestamp':
                return `${getTimeAgoLong(dateValue, true)} - ${format(
                    dateValue,
                    themeValues.timeOnly,
                )}`
            case 'dateOnly':
                return format(dateValue, themeValues.dateOnly)
            case 'dateTimeShortDayNoTime':
                return format(dateValue, themeValues.dateTimeShortDayNoTime)
            case 'dayMonthYear':
                return format(dateValue, themeValues.dayMonthYear)
            case 'dateTimeOnly':
                return format(dateValue, themeValues.dateTimeOnly)
            case 'dateTimeOnlyWithComma':
                return format(
                    dateValue,
                    themeValues.dateTimeOnlyWithComma,
                ).replace(/AM|PM/, (match) => match.toLowerCase())
            case 'dateTimeShortDay':
                return format(dateValue, themeValues.dateTimeShortDay)
            case 'customSevenNewsDateTime':
                return formatToTimeZone(
                    utcToZonedTime(dateValue, themeTimezoneValue),
                    themeValues.dateTimeWithZone,
                    {
                        timeZone: themeTimezoneValue,
                        locale: enAU,
                    },
                )
            case 'customSevenNewsDateTimeOnly':
                return formatToTimeZone(
                    utcToZonedTime(dateValue, themeTimezoneValue),
                    themeValues.dateTimeOnlyWithZone,
                    {
                        timeZone: themeTimezoneValue,
                        locale: enAU,
                    },
                )
            case 'dateOnly7News': {
                const date = format(dateValue, themeValues.dateOnly)
                const dateArray = date.split(',')
                return (
                    <>
                        <span>{dateArray[0]}</span>
                        {dateArray[1]}
                    </>
                )
            }
            case 'default':
                return format(dateValue, themeValues.dateTime)
            default:
                return
        }
    }

    const html5datetime = format(new Date(timestamp), "yyyy-MM-dd'T'HH:mmXXXX")

    const Icon = (
        <ThemedRender
            thewest={() => <ClockIcon />}
            sevennews={() => <ClockIconAlt />}
        />
    )

    return (
        <StyledTimestamp
            dateTime={html5datetime}
            className={className}
            suppressHydrationWarning={true}
            id={id}
        >
            {iconVisible && Icon}
            {timestampLabel && timestampLabel + ' '}

            {getHumanReadableTimestamp(timestampFormat)}
        </StyledTimestamp>
    )
}
Timestamp.displayName = 'Timestamp'
