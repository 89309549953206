import { TheWestSection } from '@news-mono/common'
import {
    JanusRouteProps,
    LimaRouteProps,
    metrics,
    ResponsivePictureLayout,
    SierraRouteProps,
    ThorRouteProps,
} from '@news-mono/component-library'
import { ContentDataTypes } from '@news-mono/web-common'
import {
    PerthNowSections,
    SevenNewsSections,
} from '@west-australian-newspapers/publication-types'
import { layouts, themeOverrides } from './get-themed-segment'

export interface CardExtensionProps {
    fourCardExtension?: FourCardExtensions
    eightCardExtension?: EightCardExtensions
    sevenCardExtension?: SevenCardExtensions
}

export type WrappedCardProps<T> = T & {
    section?: TheWestSection | PerthNowSections | SevenNewsSections
    dataDefinitionArgs?: ContentDataTypes
}

export type FourCardExtensions = {
    kind: '4-card'
    thorProps?: ThorRouteProps
    limaProps?: WrappedCardProps<LimaRouteProps>
}

export type EightCardExtensions = {
    kind: '8-card'
    /** An eight card curation consists of four different card types
     * An opening Sierra
     * Then a Janus that holds two Lima, one on the left and one on the right
     */
    thorProps?: ThorRouteProps
    sierraProps?: WrappedCardProps<SierraRouteProps>
    janusProps?: JanusRouteProps // Janus doesn't need a section or dataDefinitionArgs
    leftLimaProps?: WrappedCardProps<LimaRouteProps>
    rightLimaProps?: WrappedCardProps<LimaRouteProps>
}

export type SevenCardExtensions = {
    kind: '7-card'
    /** A seven card curation consists of four different card types
     * an opening Sierra and then a Lima underneath
     */
    thorProps?: ThorRouteProps
    sierraProps?: WrappedCardProps<SierraRouteProps>
    limaProps?: WrappedCardProps<LimaRouteProps>
}

export type Extensions =
    | FourCardExtensions
    | EightCardExtensions
    | SevenCardExtensions

export const isFourCardExtension = (
    extension: Extensions,
): extension is FourCardExtensions => extension.kind === '4-card'

export const isEightCardExtension = (
    extension: Extensions,
): extension is EightCardExtensions => extension.kind === '8-card'

export const isSevenCardExtension = (
    extension: Extensions,
): extension is SevenCardExtensions => extension.kind === '7-card'

export const returnFourCardExtensionProps = (
    extension: Extensions | undefined,
) => {
    const fourCardExtensions =
        extension && isFourCardExtension(extension) ? extension : undefined

    return {
        limaProps: fourCardExtensions?.limaProps,
        thorProps: fourCardExtensions?.thorProps,
    }
}

export const returnSevenCardExtensionProps = (
    extension: Extensions | undefined,
) => {
    const sevenCardExtension =
        extension && isSevenCardExtension(extension) ? extension : undefined

    return {
        limaProps: sevenCardExtension?.limaProps,
        sierraProps: sevenCardExtension?.sierraProps,
        thorProps: sevenCardExtension?.thorProps,
    }
}

export const returnEightCardExtensionProps = (
    extension: Extensions | undefined,
) => {
    const eightCardExtension =
        extension && isEightCardExtension(extension) ? extension : undefined
    return {
        thorProps: eightCardExtension?.thorProps,
        sierraProps: eightCardExtension?.sierraProps,
        janusProps: eightCardExtension?.janusProps,
        leftLimaProps: eightCardExtension?.leftLimaProps,
        rightLimaProps: eightCardExtension?.rightLimaProps,
    }
}

export function returnOverrideCardProps<T>(
    cardLayout: layouts,
    card: string,
    curation: string,
    themeOverride: themeOverrides,
    overrideProps: T | undefined,
): Required<T> {
    return {
        ...(returnDefaultCardProps(
            cardLayout,
            card,
            curation,
            themeOverride,
        ) as unknown as Required<T>),
        ...overrideProps,
    }
}

export const returnDefaultCardProps = (
    cardLayout: layouts,
    card: string,
    curation: string,
    themeOverride: themeOverrides,
) => {
    switch (cardLayout) {
        case '4-card': {
            switch (card) {
                case 'thor': {
                    return {
                        hasBackgroundFill: true,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: true,
                        themeOverride: themeOverride,
                        verticalSpacing: 'lg',
                        layouts: '4-card',
                    }
                }
                case 'lima': {
                    return {
                        section: 'default',
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },
                        cardLayout: [
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                        ],
                        intermediateColumns: 2,
                        finalColumns: 4,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            pageSize: 4,
                            offset: 0,
                        },
                        hasBorder: false,
                    }
                }
            }
            break
        }
        case '8-card': {
            switch (card) {
                case 'thor': {
                    return {
                        hasBackgroundFill: true,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: true,
                        themeOverride: themeOverride,
                        verticalSpacing: 'lg',
                        hideHeadingLink: false,
                    }
                }
                case 'lima-left': {
                    return {
                        section: 'default',
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },
                        cardLayout: [
                            ['teaserWithBackground'],
                            [
                                'xsImage-mdNoImage-withBG',
                                'xsImage-mdNoImage-withBG',
                            ],
                            [
                                'xsImage-mdNoImage-withBG',
                                'xsImage-mdNoImage-withBG',
                            ],
                        ],
                        intermediateColumns: 3,
                        finalColumns: 3,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            pageSize: 5,
                            offset: 2,
                        },
                        hasBackground: false,
                        hasBorder: false,
                    }
                }
                case 'lima-right': {
                    return {
                        section: 'default',
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },
                        cardLayout: [['teaserWithBackground']],
                        intermediateColumns: 1,
                        finalColumns: 1,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            pageSize: 1,
                            offset: 7,
                        },
                        hasBackground: false,
                        hasBorder: false,
                    }
                }
                case 'sierra': {
                    return {
                        verticalSpacing: 'gridGap',
                        fixedRatios: ['16:9'],
                        heroImageLayout:
                            ResponsivePictureLayout.ObjectFitContain,
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                            maxBreakpoint: 'md',
                        },
                        cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            offset: 0,
                            pageSize: 2,
                        },
                    }
                }
                case 'janus': {
                    return {
                        layoutRatio: '3:1',
                        stretchColumns: true,
                        verticalSpacing: 'sm',
                        hasBorder: false,
                    }
                }
                default: {
                    return
                }
            }
        }
        case '7-card': {
            switch (card) {
                case 'thor': {
                    return {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                        themeOverride,
                        layouts: '7-card',
                    }
                }
                case 'lima': {
                    return {
                        verticalSpacing: 'xl',
                        fontScale: 1.2,
                        fixedRatios: ['16:9'],
                        hasBackground: true,
                        initialColumns: 1,
                        intermediateColumns: 2,
                        finalColumns: 4,
                        teaserMode: 'visible',
                        cardOrientation: { type: 'Landscape-Portrait' },
                        cardLayout: [
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['commonWithBackground', 'titleOnly'],
                        ],

                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            offset: 2,
                            pageSize: 5,
                        },
                    }
                }
                case 'sierra': {
                    return {
                        verticalSpacing: 'gridGap',
                        fixedRatios: ['16:9'],
                        heroImageLayout:
                            ResponsivePictureLayout.ObjectFitContain,
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                            maxBreakpoint: 'md',
                        },
                        cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            offset: 0,
                            pageSize: 2,
                        },
                    }
                }
            }
        }
    }
}
