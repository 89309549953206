import { cx } from '@emotion/css'
import React from 'react'

export interface IconCheckmarkProps {
    title?: string
    className?: string
    fill?: string
}

export const IconCheckmark: React.FC<IconCheckmarkProps> = ({
    className,
    title,
    fill = '#2B2D2E',
}) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="10"
        className={cx(className, 'icon-check')}
    >
        <title>{title || 'Checkmark Icon'}</title>

        <path
            d="m0 5.071 4.243 4.243 7.07-7.071L9.9.828 4.244 6.485 1.414 3.657 0 5.07Z"
            fill={fill}
        />
    </svg>
)
