import React, { useEffect } from 'react'
import { AllEventTypes } from '../events/all-events'
import { SubscribeFunction } from '../observable'

export type PageEventUnsubscribe = () => void
export type PageEventSubscribe = (
    fn: SubscribeFunction<AllEventTypes>,
) => PageEventUnsubscribe

// The no-op default avoids issues during SSR doing nothing until the context provider has a value
export const PageEventContext = React.createContext<PageEventSubscribe>(
    () => () => {},
)
PageEventContext.displayName = 'PageEventContext'

export function usePageEvents(onEvent: () => void, types?: AllEventTypes[]) {
    const subscribe = React.useContext(PageEventContext)

    useEffect(() => {
        const unsubscribe = subscribe((evt) => {
            if (types && !types.includes(evt)) {
                return
            }

            onEvent()
        })

        return unsubscribe
    })
}
