import { createRegisterableComponent, ShareOption } from '@news-mono/web-common'
import React from 'react'
import { Sharing } from './Sharing'

export interface SharingRouteProps {
    url: string
    text: string

    shareOptions: ShareOption[]
    isGhostStyle?: boolean
    isSticky?: boolean
    layout?: 'stacked-md-desktop'
}

export const SharingRegistration = createRegisterableComponent(
    'sharing',
    (props: SharingRouteProps, services) => {
        return (
            <Sharing
                url={props.url}
                text={props.text}
                onEvent={services.onEvent}
                shareOptions={props.shareOptions}
                isGhostStyle={props.isGhostStyle}
                isSticky={props.isSticky}
                layout={props.layout}
            />
        )
    },
)
