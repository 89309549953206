import debug from 'debug'
import { Logger } from 'typescript-log'

type EventType =
    | 'sophi-page-view'
    | 'load-index-exchange-script'
    | 'load-magnite-script'
    | 'sophi-wall-hit'
    | 'testing-type' //Thought it'd be good to have a generic testing type.

interface EventQueueObject {
    eventName: EventType
    callbackFunctions: Array<Function>
}

export const eventQueueDebug = debug('event-queue')

export class EventQueue {
    queue: Array<EventQueueObject>
    results: Array<any>
    log: Logger

    constructor(log: Logger) {
        this.queue = []
        this.results = []
        this.log = log
    }

    addEvent = (event: EventQueueObject) => {
        this.queue.push(event)
        eventQueueDebug('Event Added: %O', event)
    }

    resolveEvent = async (eventName: string): Promise<Array<any> | string> => {
        const events = this.queue.filter(
            (event) => event.eventName === eventName,
        )

        if (!events) {
            return 'Event could not be found'
        }

        //Clear return result array
        this.results.splice(0, this.results.length)

        events.forEach((event) => {
            event.callbackFunctions.forEach(async (executorFunction) => {
                const result = await executorFunction()
                this.results.push(result)
            })
        })

        eventQueueDebug('Events Resolved: %O', events)

        //Clear the event
        this.clearEvent(eventName)

        return this.results
    }

    doesEventExist = (eventName: string): boolean => {
        const event = this.queue.find((event) => event.eventName === eventName)

        return Boolean(event)
    }

    clearEvent = (eventName: string) => {
        return (this.queue = this.queue.filter(
            (event) => event.eventName !== eventName,
        ))
    }
}
