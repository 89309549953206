import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Cerberus } from '../../compositions/Cerberus/Cerberus'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface CerberusFourRouteProps {
    verticalSpacing?: keyof ThemeMargins
    stretchColumns: boolean
    hasSeparators?: boolean
    hasBackgroundFill?: boolean
}

export const CerberusFourRegistration = createRegisterableComposition<
    'content1' | 'content2' | 'content3' | 'content4'
>()(
    'cerberus-four',
    (contentAreas, props: CerberusFourRouteProps) => (
        <Cerberus
            {...props}
            content={[
                contentAreas.content1,
                contentAreas.content2,
                contentAreas.content3,
                contentAreas.content4,
            ]}
        />
    ),
    (
        _contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: CerberusFourRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        return {
            containerWidthRatios: {
                desktop: 0.25 * containerWidthRatios.desktop,
                tablet: 0.5 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
