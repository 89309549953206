import React, { RefObject, useRef } from 'react'
import {
    StyledModal,
    StyledIconCrossSpanWrapper,
    StyledIconCross,
} from './LatestEdition.styled'
import { Issue } from '@news-mono/web-common'
import { StyledEpaperCollectionPapersStyledImg } from './EpaperCollection.styled'
import { format } from 'date-fns'
import {
    StyledEditionGroupButton,
    StyledEditionGroupContainer,
    StyledEditionGroupHeader,
    StyledEditionGroupImage,
    StyledEditionGroupModalMain,
    StyledEditionGroupPaperContainer,
    StyledEditionGroupTitle,
} from './EditionGroupModal.styled'
import { getMastheadMetadataFromMastheadName } from './EpaperCollection'

export interface EditionGroupModalProps {
    showEditionGroupModal: boolean
    setShowEditionGroupModal: (showModal: boolean) => void
    handleClickOutsideModal: (e: React.MouseEvent<HTMLDivElement>) => void
    innerModalRef: React.MutableRefObject<null>
    crossIconRef: RefObject<HTMLButtonElement>
    editionData: Issue
}

export const EditionGroupModal: React.FC<EditionGroupModalProps> = ({
    showEditionGroupModal,
    setShowEditionGroupModal,
    handleClickOutsideModal,
    innerModalRef,
    crossIconRef,
    editionData,
}) => {
    return (
        <StyledModal
            showModal={showEditionGroupModal}
            onClick={handleClickOutsideModal}
        >
            <StyledEditionGroupModalMain
                className="modal-main"
                ref={innerModalRef}
            >
                <StyledIconCrossSpanWrapper
                    tabIndex={0}
                    ref={crossIconRef}
                    onClick={() => {
                        setShowEditionGroupModal(false)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setShowEditionGroupModal(false)
                        }
                    }}
                >
                    <StyledIconCross />
                </StyledIconCrossSpanWrapper>
                <StyledEditionGroupHeader>{`${format(
                    editionData.date,
                    'EEEE, d MMMM yyyy',
                )}`}</StyledEditionGroupHeader>
                <StyledEditionGroupPaperContainer>
                    <StyledEditionGroupContainer>
                        <StyledEditionGroupTitle>
                            {
                                getMastheadMetadataFromMastheadName(
                                    editionData.publicationName,
                                )?.mastheadName
                            }
                        </StyledEditionGroupTitle>
                        <StyledEditionGroupImage
                            src={editionData.coverUrl}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = require('../assets/paper.jpg')
                            }}
                            style={{ outline: '1px solid #D6D6D6' }}
                        />
                        <StyledEditionGroupButton to={editionData.url}>
                            Read
                        </StyledEditionGroupButton>
                    </StyledEditionGroupContainer>
                    {editionData.magazine && (
                        <StyledEditionGroupContainer>
                            <StyledEditionGroupTitle>
                                {
                                    getMastheadMetadataFromMastheadName(
                                        editionData.magazine.publicationName,
                                    )?.mastheadName
                                }
                            </StyledEditionGroupTitle>
                            <StyledEditionGroupImage
                                src={editionData.magazine.coverUrl}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = require('../assets/paper.jpg')
                                }}
                            />
                            <StyledEditionGroupButton
                                to={editionData.magazine.url}
                            >
                                Read
                            </StyledEditionGroupButton>
                        </StyledEditionGroupContainer>
                    )}
                </StyledEditionGroupPaperContainer>
            </StyledEditionGroupModalMain>
        </StyledModal>
    )
}
