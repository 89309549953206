import React from 'react'
import { FiltersContainer, StyledHeader } from './SearchFilters.styled'
import { TimeFilter } from '../../result-filters/TimeFilter/TimeFilter'
import { ResultSort } from '../../result-filters/ResultSort/ResultSort'
import { TopicFilter } from '../../result-filters/TopicFilter/TopicFilter'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import {
    latestFirstSortOption,
    oldestFirstSortOption,
    relevanceSortOption,
} from '../../result-filters'
import { DataLayerEventName } from '@news-mono/web-common'
import { FilterEvent } from '../../result-filters/filter-events-util'

export type SearchFiltersProps = {
    showHeader: boolean
    onEvent: (event: FilterEvent) => void
}

export const SearchFilters = ({ showHeader, onEvent }: SearchFiltersProps) => {
    return (
        <FiltersContainer>
            {showHeader && (
                <StyledHeader>
                    <SectionHeader heading="Filter" />
                </StyledHeader>
            )}

            <TimeFilter
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.searchFilter}
            />
            <ResultSort
                defaultOption={relevanceSortOption}
                options={[
                    relevanceSortOption,
                    latestFirstSortOption,
                    oldestFirstSortOption,
                ]}
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.searchFilter}
            />
            <TopicFilter
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.searchFilter}
            />
        </FiltersContainer>
    )
}
