import { Profile as ProfileData } from '@news-mono/web-common'
import React, { Fragment } from 'react'
import { Box } from '../../compositions/Box/Box'
import { BioBlock } from '../../content/BioBlock/BioBlock'
import { Breadcrumb } from '../../navigation/Breadcrumb/Breadcrumb'
import { PromoBlock } from '../PromoBlock/PromoBlock'

export interface TheWestProfileProps {
    profile: ProfileData
    profileUrl: string
}

export const TheWestProfile: React.FC<TheWestProfileProps> = (props) => {
    return (
        <Fragment>
            <Breadcrumb
                items={[{ text: 'Profiles' }, { text: props.profile.name }]}
            />
            <Box verticalSpacing="md" hasTopSpacing>
                <BioBlock
                    profile={props.profile}
                    url={props.profileUrl}
                    title={props.profile.name}
                />
                <PromoBlock profile={props.profile} />
            </Box>
        </Fragment>
    )
}
