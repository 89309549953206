import * as React from 'react'

export interface IconUpArrowProps {
    className?: string
}

export const IconUpArrow: React.FC<IconUpArrowProps> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 12 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 5.91699L10.4965 7.38934L7.04404 3.97668L7.02951 14L4.90818 13.9979L4.92271 4.01343L1.49649 7.36858L0 5.88937L6.01403 0L12 5.91699Z" />
        </svg>
    )
}
