import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheWestPageNotFound } from './TheWestPageNotFound'

export const TheWestPageNotFoundRegistration = createRegisterableComponent(
    'west-404-page',
    () => (
        <RouteStatus statusCode={404}>
            <TheWestPageNotFound />
        </RouteStatus>
    ),
)
