import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { keyframes } from '@emotion/react'
import { zIndex } from '../../__styling/settings/z-index'
import { AlignSelfProperty, FlexDirectionProperty } from 'csstype'
import { IconDotsVertical } from '../../icons/IconDotsVertical/IconDotsVertical'
import { IconLink } from '../../icons'
import { metrics, breakpoint, calcRem, fonts } from '../../__styling'

// Styling for component fade-in & fade-out
const componentFadeIn = keyframes({
    '0%': {
        opacity: 0,
    },
    '100%': {
        opacity: 1,
    },
})

export const StyledContextMenuContainer = styled.div(() => ({
    background: tokens.thenightly.colors.palette.neutral[100],

    borderRadius: calcRem(12),

    display: 'flex',
    flexDirection: 'column',

    width: 'fit-content',

    [breakpoint('lg')]: {
        animation: `${componentFadeIn} 0.15s`,
    },
}))

export const StyledModalContainer = styled.div(() => ({
    top: calcRem(-24),
    right: calcRem(-16),
    zIndex: zIndex.common.toastNotification,
    position: 'absolute',
}))

export const StyledSharingButtonContainer = styled('div')({
    borderBottomLeftRadius: calcRem(12),
    borderBottomRightRadius: calcRem(12),
    padding: calcRem(
        metrics.thenightly.margins.m2l,
        metrics.thenightly.margins.md,
    ),
    justifyContent: 'space-between',
})

interface StyledIconDotsVerticalContainerProps {
    position?: AlignSelfProperty
}
export const StyledIconDotsVerticalContainer = styled(
    'div',
)<StyledIconDotsVerticalContainerProps>(({ position = 'flex-end' }) => ({
    display: 'flex',
    cursor: 'pointer',
    alignSelf: position,
}))

interface RowButtonProps {
    flexDirection?: FlexDirectionProperty
    marginBottom?: number
}
export const StyledButton = styled('div')<RowButtonProps>(
    ({ flexDirection = 'row', marginBottom = undefined }) => ({
        display: 'flex',
        flexDirection: flexDirection,
        marginBottom: marginBottom ? calcRem(marginBottom) : undefined,
        width: 'fit-content',
        cursor: 'pointer',
    }),
)

interface ButtonTextProps {
    useUnderlineOnHover?: boolean
    marginLeft?: number
    color?: string
}
export const StyledButtonLabel = styled('p')<ButtonTextProps>(
    ({
        useUnderlineOnHover = true,
        marginLeft = 8,
        color = tokens.thenightly.colors.palette.neutral[0],
    }) => ({
        fontFamily: fonts.thenightly.sansSerif,
        fontSize: calcRem(20),
        fontWeight: 400,
        lineHeight: calcRem(24),
        margin: 0,

        color: color ?? undefined,
        marginLeft: marginLeft ? calcRem(marginLeft) : undefined,

        '&:hover, &:focus, &:active': {
            textDecoration: useUnderlineOnHover ? 'underline' : undefined,
        },
    }),
)

export const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `0.5px solid ${tokens.thenightly.colors.palette.neutral[10]}`,

    padding: calcRem(metrics.thenightly.margins.md),

    [breakpoint('xxs')]: {
        padding: calcRem(
            metrics.thenightly.margins.m2l,
            metrics.thenightly.margins.md,
        ),
    },
}))

export const StyledLinkIcon = styled(IconLink)(() => ({
    width: calcRem(24),
    height: calcRem(24),
    fill: tokens.thenightly.colors.palette.neutral[0],
}))
