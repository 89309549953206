import { PublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { PublicationType } from '../../../client-data-types/publication'
import { TogglesReduxState } from '../../../feature-togglings/reducer'
import { mapArticle } from './map/article'
import { mapGallery } from './map/gallery'
import { mapRedirect } from './map/redirect'
import { mapUnknown } from './map/unknown'
export * from './map'

export interface MapPublicationOptions {
    additionalMap?: MapPublicationAdditionalMap
}

export type MapPublicationAdditionalMap = (props: {
    apiResponse: PublicationV4DTO
    mappedPublication: PublicationType
}) => PublicationType

export function mapPublication(
    apiResponse: PublicationV4DTO,
    toggles: TogglesReduxState,
    options: MapPublicationOptions = {},
): PublicationType {
    const mappedPublication = toInternal(apiResponse, toggles)

    if (options.additionalMap) {
        return options.additionalMap({
            apiResponse,
            mappedPublication,
        })
    }

    return mappedPublication
}

function toInternal(apiResponse: PublicationV4DTO, toggles: TogglesReduxState) {
    switch (apiResponse.kind) {
        case 'article':
        case 'event':
            return mapArticle(apiResponse, toggles)

        case 'gallery':
            return mapGallery(apiResponse)

        case 'redirect':
            return mapRedirect(apiResponse)

        default:
            return mapUnknown(apiResponse)
    }
}
