import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { calcRem, colors } from '../../../__styling'

interface StyledButtonProps {
    isLoading: boolean
}

export const StyledButton = styled('button')<StyledButtonProps>(
    ({ theme, isLoading }) => [
        // General Styling
        {
            backgroundColor:
                theme.colors.actions.button.primary.background.default,
            transition: 'background-color 0.3s ease',
            opacity: isLoading ? 0.3 : 1,
            marginTop: theme.margins.md,
            marginBottom: theme.margins.sm,
            cursor: 'pointer',
            border: `1px solid ${theme.colors.brand}`,
            position: 'relative',
            width: 'unset',
            height: calcRem(40),
            borderRadius: 'unset',

            '&:hover': {
                backgroundColor: isLoading
                    ? theme.colors.actions.button.primary.background.default
                    : theme.colors.actions.button.primary.background.hover,

                '& > div': {
                    color: isLoading
                        ? theme.colors.actions.button.primary.text.default
                        : theme.colors.actions.button.primary.text.hover,
                },
            },

            '&:focus': {
                outline: 'none',

                '&::after': {
                    display: 'block',
                },
            },
        },
        // Styling for 7N
        theme.kind === 'sevennews' && {
            '&::after': {
                content: `''`,
                position: 'absolute',
                top: -3,
                left: -3,
                width: 'calc(100% + 6px)',
                height: 'calc(100% + 6px)',
                borderRadius: calcRem(4),
                border: `1px solid ${theme.colors.palette.black}`,
            },

            backgroundColor: 'rgba(39, 38, 38, 0.5)',
            opacity: isLoading ? 0.3 : 0.5,
            borderRadius: calcRem(4.171),
            border: 'none',
            width: calcRem(324),
            height: calcRem(42),

            '&:hover': {
                backgroundColor: isLoading
                    ? colors.sevennews.nero
                    : colors.redButton,
            },
        },
    ],
)

export const StyledButtonInnerContainer = styled('div')<StyledButtonProps>(
    ({ theme }) => [
        {
            display: 'flex',
            padding: `${calcRem(theme.margins.sm)}`,
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'unset',
            color: theme.colors.actions.button.primary.text.default,
            gap: calcRem(theme.margins.sm),

            '& > span': {
                fontFamily: theme.fonts.sansSerif,
                fontWeight: 700,
                fontSize: calcRem(15),
                lineHeight: calcRem(16),
            },

            '&:hover': {},
        },
        // Styling for 7N
        theme.kind === 'sevennews' && {
            justifyContent: 'center',
        },
    ],
)

export const StyledSpinner = styled('div')(({ theme }) => ({
    display: 'inline-block',
    width: calcRem(12),
    height: calcRem(12),
    borderLeft: `2px solid ${theme.colors.background.primary}`,
    borderRight: `2px solid ${theme.colors.background.primary}`,
    borderTop: `2px solid ${theme.colors.background.primary}`,
    borderRadius: '50%',
    animation: `${spin} 0.8s infinite`,
    WebkitAnimation: `${spin} 0.8s infinite`,
}))

const spin = keyframes({
    to: {
        transform: 'rotate(360deg)',
    },
})
