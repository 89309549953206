import { useTheme } from '@emotion/react'
import loadable from '@loadable/component'
import { ConfigurationContext, useFeature } from '@news-mono/web-common'
import React from 'react'
import {
    useSection,
    useSectionMetaInfo,
} from '../../../__helpers/use-section-meta'
import {
    StyledTheWest190Logo,
    StyledTheWestLogo,
} from '../TheWestHeader.styled'
import {
    StyledFeatureLogoWrapLink,
    StyledLogoWrapLink,
} from './MastheadLogo.styled'

const LazyAlbanyLogo = loadable(() => import('./masthead-albany-advertiser'))

const LazyAugustaMargaretRiverTimesLogo = loadable(
    () => import('./masthead-augusta-margaret-river-times'),
)

const LazyBroomeAdvertiserLogo = loadable(
    () => import('./masthead-broome-advertiser'),
)

const LazyBunburyHeraldLogo = loadable(
    () => import('./masthead-bunbury-herald'),
)

const LazyBusseltonDunsboroughTimesLogo = loadable(
    () => import('./masthead-busselton-dunsborough-times'),
)

const LazyCountrymanLogo = loadable(() => import('./masthead-countryman'))

const LazyGeraldtonGuardianLogo = loadable(
    () => import('./masthead-geraldton-guardian'),
)

const LazyGreatSouthernHeraldLogo = loadable(
    () => import('./masthead-great-southern-herald'),
)

const LazyHarveyWaroonaReporterLogo = loadable(
    () => import('./masthead-harvey-waroona-reporter'),
)

const LazyKalgoorlieMinerLogo = loadable(
    () => import('./masthead-kalgoorlie-miner'),
)

const LazyManjimupBridgetownTimesLogo = loadable(
    () => import('./masthead-manjimup-bridgetown-times'),
)

const LazyMidwestTimesLogo = loadable(() => import('./masthead-midwest-times'))

const LazyNarroginObserverLogo = loadable(
    () => import('./masthead-narrogin-observer'),
)

const LazyNorthWestTelegraphLogo = loadable(
    () => import('./masthead-north-west-telegraph'),
)

const LazyPilbaraNewsLogo = loadable(() => import('./masthead-pilbara-news'))

const LazySoundTelegraphLogo = loadable(
    () => import('./masthead-sound-telegraph'),
)

const LazySouthWesternTimesLogo = loadable(
    () => import('./masthead-south-western-times'),
)

const LazyKimberleyEchoTimes = loadable(
    () => import('./masthead-the-kimberley-echo'),
)

const LazyGenwestLogo = loadable(() => import('./masthead-genwest'))

interface MastheadLogoProps {
    width?: number
    height?: number
    isFooterLogo?: boolean
}

export const MastHeadLogo: React.FC<MastheadLogoProps> = ({
    width,
    height,
    isFooterLogo,
}) => {
    const { section } = useTheme()
    const websiteSection = useSection()
    const sectionInfo = useSectionMetaInfo()
    const config = React.useContext(ConfigurationContext)
    const grandFinalThemeEnabled = useFeature('the-west-grand-final')

    if (grandFinalThemeEnabled) {
        return (
            <StyledFeatureLogoWrapLink
                to={
                    section === 'default' ||
                    (config.sectionDomainsRedirect && sectionInfo.Hostname)
                        ? '/'
                        : `/${section}`
                }
                id={`${isFooterLogo ? 'footer' : 'header'}-logo`}
            >
                <InternalMastheadLogo
                    width={width}
                    height={height}
                    isFooterLogo={isFooterLogo}
                />
            </StyledFeatureLogoWrapLink>
        )
    }

    return (
        <StyledLogoWrapLink
            section={websiteSection}
            to={
                section === 'default' ||
                (config.sectionDomainsRedirect && sectionInfo.Hostname)
                    ? '/'
                    : `/${section}`
            }
            id={`${isFooterLogo ? 'footer' : 'header'}-logo`}
        >
            <InternalMastheadLogo width={width} height={height} />
        </StyledLogoWrapLink>
    )
}

export const InternalMastheadLogo: React.FC<MastheadLogoProps> = ({
    width = 610,
    height = 52,
    isFooterLogo,
}) => {
    const { section } = useTheme()
    const theWest190Enabled = useFeature('190-years-of-the-west-masthead')

    switch (section) {
        case 'albany-advertiser': {
            return <LazyAlbanyLogo />
        }
        case 'augusta-margaret-river-times': {
            return <LazyAugustaMargaretRiverTimesLogo />
        }
        case 'broome-advertiser': {
            return <LazyBroomeAdvertiserLogo />
        }
        case 'bunbury-herald': {
            return <LazyBunburyHeraldLogo />
        }
        case 'busselton-dunsborough-times': {
            return <LazyBusseltonDunsboroughTimesLogo />
        }
        case 'countryman': {
            return <LazyCountrymanLogo />
        }
        case 'geraldton-guardian': {
            return <LazyGeraldtonGuardianLogo />
        }
        case 'great-southern-herald': {
            return <LazyGreatSouthernHeraldLogo />
        }
        case 'harvey-waroona-reporter': {
            return <LazyHarveyWaroonaReporterLogo />
        }
        case 'kalgoorlie-miner': {
            return <LazyKalgoorlieMinerLogo />
        }
        case 'manjimup-bridgetown-times': {
            return <LazyManjimupBridgetownTimesLogo />
        }
        case 'midwest-times': {
            return <LazyMidwestTimesLogo />
        }
        case 'narrogin-observer': {
            return <LazyNarroginObserverLogo />
        }
        case 'north-west-telegraph': {
            return <LazyNorthWestTelegraphLogo />
        }
        case 'pilbara-news': {
            return <LazyPilbaraNewsLogo />
        }
        case 'sound-telegraph': {
            return <LazySoundTelegraphLogo />
        }
        case 'south-western-times': {
            return <LazySouthWesternTimesLogo />
        }
        case 'the-kimberley-echo': {
            return <LazyKimberleyEchoTimes />
        }
        case 'genwest': {
            return <LazyGenwestLogo />
        }
        default: {
            if (theWest190Enabled) {
                return (
                    <StyledTheWest190Logo
                        logoWidth={width}
                        logoHeight={height}
                    />
                )
            }
            return <StyledTheWestLogo logoWidth={width} logoHeight={height} />
        }
    }
}
