import { StyledKicker, StyledKickerText } from '../cards/Kicker/Kicker.styled'
import { playOverlayClassName } from '../content/Video/components/PlayOverlay/PlayOverlay.common'

// Because the video sits inside a Link we need to check to see if the video element,
// interface or play button is clicked in order to ignore the link and hide the kicker
export function isVideoClick(
    target: EventTarget | null,
    exclude: string[] = [],
) {
    if (!(target instanceof HTMLElement)) {
        return false
    }

    const videoTriggerClasses = [
        'video',
        'vjs',
        StyledKicker,
        StyledKickerText,
        playOverlayClassName,
    ]

    const className = target.className
    return (
        new RegExp(videoTriggerClasses.join('|')).test(className) &&
        !exclude.includes(className)
    )
}
