import {
    CardEvents,
    ConfigurationContext,
    isBetweenUTC,
    scaleImage,
} from '@news-mono/web-common'
import { format, subDays } from 'date-fns'
import React, { useContext } from 'react'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import {
    StyledCTA,
    StyledFallbackImage,
    StyledImage,
    StyledImageFrame,
    StyledLink,
    StyledNewspaperImage,
    StyledPaperPromoContainer,
} from './PaperPromo.styled'

const paperBackground = require('./assets/blank-background.png')
const fallback = require('./assets/cover-fallback.png')
const fallback2x = require('./assets/cover-fallback@2x.png')

// Function export and prop is for unit testing
export function determineCoverDate(now = new Date()) {
    const isBetweenCutoff = isBetweenUTC(now, [16, 0], [18, 0]) // Between 12am and 2am (while SMedia prepare digital edition)
    const imageDate = isBetweenCutoff ? subDays(now, 1) : now

    return format(imageDate, 'yyyyMMdd')
}

function buildTodaysPaperSrcSets() {
    const imageDateSuffix = determineCoverDate()
    const imagePath = `https://images.thewest.com.au/assets/frontpage/frontpage-${imageDateSuffix}.jpg`
    const cover1x = scaleImage(imagePath, 320)
    const cover2x = scaleImage(imagePath, 640)

    return [cover1x, cover2x]
}

export interface PaperPromoProps {
    onEvent: (event: CardEvents) => void
}

export const PaperPromo: React.FC<PaperPromoProps> = (props) => {
    const [coverImage1x, coverImage2x] = buildTodaysPaperSrcSets()
    const [displayFallback, setDisplayFallback] = React.useState(false)

    const digitalEditionUrl = `/editions?utm_source=TheWest&utm_medium=Referral&utm_campaign=digital-edition&utm_term=todays-paper&utm_content=homepagecard`

    return (
        <StyledPaperPromoContainer>
            <SectionHeader heading="Today's Paper" hasBottomMargin={true} />
            {/**
             * If this component is extended to work with regionals the following link will need
             * to be updated to include the section as well as an updated utm_source
             */}
            <StyledLink to={digitalEditionUrl} forceInternalSSR>
                {displayFallback ? (
                    <StyledFallbackImage
                        enableFadeIn={false}
                        src={fallback}
                        srcSet={`${fallback2x} 2x`}
                        alt=""
                        loading="lazy"
                    />
                ) : (
                    <StyledImageFrame>
                        <StyledNewspaperImage
                            enableFadeIn={false}
                            src={paperBackground}
                            alt=""
                            loading="lazy"
                        />
                        <StyledImage
                            enableFadeIn={false}
                            src={coverImage1x}
                            srcSet={`${coverImage2x} 2x`}
                            alt=""
                            loading="lazy"
                            onError={() => {
                                setDisplayFallback(true)
                            }}
                        />
                    </StyledImageFrame>
                )}
                <StyledCTA>
                    <span>Read the Paper</span>
                </StyledCTA>
            </StyledLink>
        </StyledPaperPromoContainer>
    )
}
