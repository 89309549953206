import styled, { CSSObject } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { IconComment } from '../../icons/IconComment/IconComment'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { EditorialLabel } from '../../typography/EditorialLabel/EditorialLabel'
import { Breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { zIndex } from '../../__styling/settings/z-index'
import {
    breakpoint,
    breakpointMax,
    getSmallestBreakpointValue,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import {
    KickerBreakpoints,
    KickerPosition,
    KickerSizeOptions,
    KickerStyleGuide,
    KickerType,
    KickerVisibility,
} from './Kicker'

export interface StyledKickerProps {
    kickerVisibility?: KickerVisibility
    kickerPosition?: KickerPosition
    size?: KickerSizeOptions
    kickerType?: KickerType
    kickerBackgroundColorOverrideForPN?: string
    kickerStyleGuide?: KickerStyleGuide
}

export type BreakpointKey = keyof Breakpoints
export const kickerBreakpoints: KickerBreakpoints[] = [
    'initial',
    'xs',
    'sm',
    'md',
]

export function getVisibilityStyles({
    kickerVisibility,
}: StyledKickerProps): CSSObject {
    if (kickerVisibility === 'hidden') {
        return { display: 'none' }
    }

    if (!kickerVisibility || kickerVisibility === 'visible') {
        return { display: 'inline-block' } // default
    }

    return (
        kickerVisibility &&
        Object.entries(kickerVisibility)
            .slice(1)
            .reduce<CSSObject>(
                (styles, [breakpointValue, visibilityForBreakpoint]) => {
                    styles[breakpoint(breakpointValue as BreakpointKey)] = {
                        display:
                            visibilityForBreakpoint === 'hidden'
                                ? 'none'
                                : 'inline-block',
                    }

                    return styles
                },
                {},
            )
    )
}

export const StyledKicker = styled('span')<StyledKickerProps>(
    getVisibilityStyles,
    ({
        kickerVisibility,
        kickerPosition,
        kickerType,
        kickerStyleGuide,
        size,
        theme,
        kickerBackgroundColorOverrideForPN,
    }) => ({
        borderRadius: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics' ? 0 : theme.radius?.kicker,
            fallback: theme.radius?.kicker,
        }),
        [breakpointMax('xs')]: {
            display:
                getSmallestBreakpointValue(kickerVisibility) === 'hidden'
                    ? 'none'
                    : kickerStyleGuide === 'olympics'
                    ? 'inline-flex'
                    : 'inline-block',
        },
        lineHeight: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? calcRem(16)
                    : theme.radius
                    ? size === 'large'
                        ? 1.9
                        : 1.7
                    : 1,
            fallback: theme.radius ? (size === 'large' ? 1.9 : 1.7) : 1,
        }),
        // verticalAlign: 'top',
        marginBottom: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? calcRem(4)
                    : kickerPosition === 'bottomleftinset-herovideo'
                    ? 0
                    : theme.margins.xs,
            fallback:
                kickerPosition === 'bottomleftinset-herovideo' ||
                kickerStyleGuide === 'olympics'
                    ? 0
                    : theme.margins.xs,
        }),
        textTransform: themedValue(theme, {
            perthnow: kickerStyleGuide === 'olympics' ? 'none' : undefined,
            fallback: 'uppercase',
        }),
        fontFamily: themedValue(theme, {
            perthnow: fonts.perthnow.sansSerif,
            thewest: fonts.thewest.sansSerif,
            sevennews: fonts.sevennews.sansSerif,
            fallback: undefined,
        }),
        fontSize: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? calcRem(13)
                    : size === 'large'
                    ? calcRem(16)
                    : calcRem(13),
            thewest: calcRem(11),
            sevennews: calcRem(12),
            fallback: undefined,
        }),
        fontWeight: themedValue(theme, {
            perthnow: 600,
            thewest: 500,
            sevennews: 500,
            fallback: undefined,
        }),
        color: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? colors.olympics.kicker.perthnow
                    : theme.colors.text.tertiary,
            thewest:
                kickerStyleGuide === 'olympics'
                    ? colors.olympics.kicker.thewest
                    : theme.colors.text.secondary,
            sevennews: (section) =>
                kickerType === 'text'
                    ? section.cards.kicker.primaryColor
                    : colors.white,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? calcRem(2, 4, 2, 4)
                    : size === 'large'
                    ? theme.radius
                        ? `0 ${calcRem(12)} 0 ${calcRem(12)}`
                        : calcRem(7)
                    : theme.radius
                    ? `0 ${calcRem(7)} 0 ${calcRem(7)}`
                    : calcRem(4),
            sevennews: kickerType === 'block' ? calcRem(4, 9, 3) : undefined,
            thewest: kickerType === 'block' ? calcRem(5, 10) : undefined,
            fallback: undefined,
        }),
        backgroundColor: themedValue(theme, {
            perthnow:
                kickerStyleGuide === 'olympics'
                    ? colors.olympics.link
                    : kickerBackgroundColorOverrideForPN
                    ? kickerBackgroundColorOverrideForPN
                    : theme.colors.actions.button.primary.background.default,
            sevennews:
                kickerType === 'block'
                    ? (section) => section.cards.kicker.primaryColor
                    : undefined,
            thewest:
                kickerType === 'block' ? colors.thewest.westblue : undefined,
            fallback: undefined,
        }),
        zIndex: themedValue(theme, {
            sevennews: zIndex.sevennews.cardKicker,
            fallback: undefined,
        }),
        ...transition({ properties: ['background, color'] }),
    }),
    ({ kickerPosition, kickerStyleGuide, theme }) => {
        // Add styling to the kicker position only when for the olympics
        if (kickerStyleGuide === 'olympics') {
            return theme.kind === 'thewest'
                ? {
                      position: 'relative',
                      display: 'inline-flex',
                      flexDirection: 'row-reverse',
                      height: '100%',
                      gap: calcRem(4),

                      width: '100%',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                  }
                : theme.kind === 'perthnow'
                ? {
                      position: 'relative',
                      height: 'fit-content',
                  }
                : {}
        }

        switch (kickerPosition) {
            case 'topleft':
                return {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }
            case 'bottomleft':
                return {
                    position: 'absolute',
                    marginTop: calcRem(-34),
                }
            case 'bottomleftinset-hero': {
                return {
                    position: 'relative',
                    marginTop: calcRem(-43),
                    left: 0,
                }
            }
            case 'bottomleftinset-herovideo': {
                return {
                    position: 'absolute',
                    left: calcRem(16),
                    bottom: calcRem(16),
                }
            }
            case 'mobilebottomleft-insetdesktop':
                return {
                    position: 'relative',
                    marginTop: calcRem(-43),
                    left: 0,

                    [breakpoint('sm')]: {
                        left: calcRem(16),
                    },
                }
            case 'bottomleftinset':
                return {
                    position: 'relative',
                    marginTop: calcRem(-43),
                    left: calcRem(16),
                }

            case 'topleftinset':
                return {
                    position: 'absolute',
                    top: calcRem(16),
                    left: calcRem(16),
                }
            default:
                return {}
        }
    },
)

export const StyledKickerText = styled('span')<{
    kickerStyleGuide?: KickerStyleGuide
}>(({ kickerStyleGuide, theme }) => [
    {
        display: kickerStyleGuide === 'olympics' ? 'flex' : undefined,
        height: kickerStyleGuide === 'olympics' ? 'fit-content' : undefined,
    },
    // Style the west to the center
    theme.kind === 'thewest' && {
        alignSelf: kickerStyleGuide === 'olympics' ? 'center' : undefined,
        marginRight: kickerStyleGuide === 'olympics' ? 'auto' : undefined,
        alignItems: kickerStyleGuide === 'olympics' ? 'center' : undefined,
    },
])

export interface StyledPremiumIconProps {
    width: number
    height: number
}

export const StyledPremiumIcon = styled(IconPremium)<StyledPremiumIconProps>(
    ({ width, height, theme }) => ({
        width,
        height,
        verticalAlign: 'text-bottom',
        transform: 'translate(2px, 1px)',
        backgroundColor: colors.white,
        borderRadius: 10,
    }),
)

export const StyledCommentsIconClassName = 'StyledCommentsIcon'

export const StyledCommentsIcon = withClass(StyledCommentsIconClassName)(
    styled(IconComment)(({ theme }) => ({
        marginLeft: calcRem(4),
        transform: 'translate(0, 3px)',
        width: 13,
        height: 12,
        fill: theme.colors.icons?.default,
        strokeWidth: 0,
    })),
)

/**
 * This editorial label only appears on The West next to kickers
 */
export const StyledEditorialLabel = styled(EditorialLabel)({
    display: 'inline-block',
    paddingRight: calcRem(8),

    '&::after': {
        content: `''`,
        display: 'inline-block',
        width: 1,
        height: calcRem(13),
        transform: 'translate(4px, 2px)',
        background: colors.thewest.greyElephant,
    },
})
