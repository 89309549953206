import { CSSObject } from '@emotion/styled'
import { breakpointMax } from '../../../__styling/style-functions'
import { PictureLayout } from './layouts'

const containImageStyles = {
    '& img': {
        width: '100%',
        height: '100%',
    },
}
const breakpoints: Array<'sm' | 'lg'> = ['sm', 'lg']
const pictureBaseStyles: CSSObject = {
    position: 'relative',
    display: 'block',

    '& img': {
        display: 'block',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}

export const pictureLayoutObjectFitContain: PictureLayout = {
    pictureStyles: () => {
        const styles: CSSObject = { ...containImageStyles }
        breakpoints.forEach((breakpointCrop) => {
            styles[breakpointMax(breakpointCrop)] = containImageStyles
        })

        return [pictureBaseStyles, styles]
    },
}
