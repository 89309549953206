import {
    CardClickedEvent,
    CardClickedEventProps,
    CardEvents,
    CardItem,
    ClickableCardItems,
} from '../client-data-types/card-types'
import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { getProductName, Product } from '../__product'
import { ComponentEvent } from './component-event'
import { raiseSelectItemEvent } from './raiseSelectItemEvent'
import { PlayerEvent } from './video-event-types'
import { ContentDataTypes } from '../data'

export interface CardInformation {
    item_brand: string
    index: number
    item_category: string
    item_category2: string
    item_id: string
    item_name: string // This is the article title
    item_heading: string
    item_variant: boolean
    price: 0.0
}

export interface ClickedCardInformation extends CardInformation {
    link_text: string
    link_url: string
}

export function createCollectionAvailableEvent(
    cards: (CardItem | string)[],
    collectionName: string,
    product: Product,
    onEvent?: (event: CollectionEvent) => void,
    payloadOverrides: Omit<
        CollectionAvailableEventProps,
        'collectionCardCount'
    > = {},
): CollectionEvent {
    const payload: CollectionAvailableEventProps = {
        collectionCardCount: cards.length,
        ...payloadOverrides,
    }

    for (let cardIndex = 0; cardIndex < cards.length; cardIndex++) {
        const card = cards[cardIndex]
        const cardKey = `card${cardIndex + 1}Info`
        payload[cardKey] = getCardInfo(card)
    }

    if (onEvent) {
        onEvent({
            type: DataLayerEventName.viewItemList,
            originator: collectionName,
            payload: {
                cardInformation: generateViewItemCardInfo(cards, product),
            },
        })
    }

    return {
        type: DataLayerEventName.collectionAvailable,
        originator: collectionName,
        payload,
    }
}

/**
 * This function is similar to returnCardKeyInfo, but used for the view_item_list event instead
 * @param cards
 * Cards attached to the collection
 * @return Array of mapped card information
 */
export const generateViewItemCardInfo = (
    cards: (CardItem | string)[],
    product: Product,
): Partial<CardInformation>[] => {
    const information: Partial<CardInformation>[] = []

    for (let i = 0; i < cards.length; i++) {
        const card = cards[i]

        if (typeof card === 'string') {
            information.push({
                item_id: card,
                index: i,
            })
            continue
        }

        if (card.cardType === 'marketing-redirect-tile') {
            information.push({
                item_id: card.id,
                index: i,
            })
            continue
        }

        const item_brand = card.source ? card.source : getProductName(product) // some card types - like promo cards - have empty string as the source

        information.push({
            index: i,
            item_brand,
            item_category: card.primaryTopic.id,
            item_category2: card.publicationKind,
            item_id: card.id,
            item_name: card.headline,
            item_heading: card.shortHeadline,
            item_variant:
                (card.classification &&
                    card.classification?.kind === 'sales') ||
                false,
        })
    }

    return information
}

export function createCardClickedEvent(
    event: (event: CardEvents) => void,
    item: ClickableCardItems,
    originator: string,
    cardFormat: CardClickedEventProps['cardFormat'],
    cardNumber: number,
    fundingType?: string,
    componentType?: string,
): CardClickedEvent {
    const cardInfo = returnCardInformation(item)

    // Fire the select item list event
    raiseSelectItemEvent(event, originator, cardInfo, item, cardNumber)

    return {
        type: DataLayerEventName.cardClicked,
        originator,
        componentType,
        payload: {
            cardItem: item,
            cardFormat,
            cardNumber,
            cardInfo: getCardInfo(item),
            fundingType,
        },
    }
}

export function getCardInfo(card: ClickableCardItems) {
    if (typeof card === 'string') {
        return card
    }

    switch (card.cardType) {
        case 'publication':
            return `Publication: ${card.shortHeadline}`
        case 'marketing-redirect-tile':
            return `Marketing tile: ${card.url}`
        case 'breaking-news':
            return `BreakingNews: ${card.shortHeadline}`
        default:
            return `Unknown card type`
    }
}

export interface CollectionAvailableEventProps {
    collectionCardCount: number
    [cardDescriptions: string]: string | number
}

export interface CollectionAvailableEvent
    extends ComponentEvent<CollectionAvailableEventProps> {
    type: DataLayerEventName.collectionAvailable
}

export type CollectionEvent =
    | PlayerEvent
    | CardEvents
    | CollectionAvailableEvent
    | ViewItemListAvailableEvent
    | ViewItemListWithContextAvailableEvent

// This is relevant to both the ViewItemListEvent and the SelectItemEvents
export interface CardInformationProps {
    cardInformation: Array<Partial<CardInformation>>
}
export interface ViewItemListEventProps {
    cardInformation: Array<Partial<CardInformation>>
}

export interface ViewItemListAvailableEvent
    extends ComponentEvent<CardInformationProps> {
    type: DataLayerEventName.viewItemListEvent
}

export interface ViewItemListWithContextAvailableEvent
    extends ComponentEvent<CardInformationProps> {
    type: DataLayerEventName.viewItemListEvent
    context: {
        timestamp: number
        componentType: string
        componentRenderPath: string
        dataDefinition: ContentDataTypes
        collectionCardCount: number
    }
}

export function returnCardInformation(
    card: ClickableCardItems,
): Partial<ClickedCardInformation> {
    if (typeof card === 'string') {
        return {
            item_id: card,
        }
    }

    switch (card.cardType) {
        case 'publication':
            return {
                item_brand: card.source,
                item_category: card.primaryTopic.id,
                item_category2: card.publicationKind,
                item_id: card.id,
                item_name: card.headline,
                item_variant: !!(card.classification?.label === 'sponsored'),
                link_text: card.shortHeadline,
                link_url: card._self,
            }
        case 'breaking-news':
            return {
                link_text: card.shortHeadline,
                link_url: card.link,
                item_category: card.listId,
            }

        case 'marketing-redirect-tile':
            return {
                link_text: card.altText,
                link_url: card.url,
                item_id: card.id,
                item_category2: card.promotionType,
                item_brand: card.source,
                item_category: card.primaryTopic.id,
                item_name: card.headline,
                item_variant: !!(card.classification?.label === 'sponsored'),
            }
    }
}
