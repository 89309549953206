import {
    ConsentLevel,
    DataLayerEventName,
    useConsentState,
} from '@news-mono/web-common'
import React from 'react'
import { AmpBrightcoveElementClassName } from '../../content/Video/Video.amp'
import { AmpAnalytics } from '../analytics'

interface AmpGAProps {
    gtmTrackingId: string
}

export const AmpGA: React.FC<AmpGAProps> = ({ gtmTrackingId }) => {
    const consent = useConsentState()

    const selector = `.${AmpBrightcoveElementClassName}`

    const event_category = 'NTG Video'
    // eslint-disable-next-line no-template-curly-in-string
    const event_label = '${heading}'

    const ampConfig = {
        vars: {
            consent: consent.consentLevel ?? ConsentLevel.Essential,
            gtag_id: gtmTrackingId,
            config: {
                [gtmTrackingId]: {},
            },
            send_page_view: false,
        },
        triggers: {
            videoPlayEvent: {
                on: 'video-play',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.videoPlay,
                    event_label,
                },
            },
            videoEndedEvent: {
                on: 'video-ended',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.videoEnd,
                    event_label,
                },
            },
            video25PercentageEvent: {
                on: 'video-percentage-played',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.videoMilestone25,
                    event_label,
                },
                videoSpec: {
                    percentages: [25],
                },
            },
            video50PercentageEvent: {
                on: 'video-percentage-played',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.videoMilestone50,
                    event_label,
                },
                videoSpec: {
                    percentages: [50],
                },
            },
            video75PercentageEvent: {
                on: 'video-percentage-played',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.videoMilestone75,
                    event_label,
                },
                videoSpec: {
                    percentages: [75],
                },
            },
            videoAdStartEvent: {
                on: 'video-ad-start',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.adStart,
                    event_label,
                },
            },
            videoAdEndEvent: {
                on: 'video-ad-end',
                selector,
                request: 'event',
                vars: {
                    event_category,
                    event_name: DataLayerEventName.adEnd,
                    event_label,
                },
            },
        },
    }

    return (
        <AmpAnalytics
            type="gtag"
            data-credentials="include"
            dangerouslySetInnerHTML={{
                __html: `
                <script type="application/json">
                    ${JSON.stringify(ampConfig)}
                </script>`,
            }}
        ></AmpAnalytics>
    )
}
