import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ThemeMargins } from '../__styling'
import { FederalElectionAccordion } from './FederalElectionAccordion'

export interface AccordionRouteProps {
    isFullWidth: boolean
    hidePadding?: boolean
    verticalSpacing?: keyof ThemeMargins
    hideHoverAndFocusBackground?: boolean
}

export const FederalElectionAccordionRegistration = createRegisterableComponent(
    'federal-election-accordion',
    (props: AccordionRouteProps) => {
        return (
            <FederalElectionAccordion
                isFullWidth={props.isFullWidth}
                verticalSpacing={props.verticalSpacing}
                hidePadding={props.hidePadding}
                hideHoverAndFocusBackground={props.hideHoverAndFocusBackground}
            />
        )
    },
)
