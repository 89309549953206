import React from 'react'

export interface IconYoutubeProps {
    title?: string
    className?: string
}

export const IconYoutube: React.FC<IconYoutubeProps> = ({
    className,
    title,
}) => (
    <svg viewBox="0 0 32 23" className={className}>
        <title>{title || 'Share to Youtube'}</title>
        <path
            d="M31.68 4.961s.32 2.647.32 5.293v2.481c0 2.647-.32 5.292-.32 5.292s-.313 2.254-1.272 3.246c-1.217 1.303-2.581 1.309-3.206 1.386C22.724 22.989 16 23 16 23s-8.32-.078-10.88-.33c-.712-.135-2.311-.094-3.529-1.397C.632 20.281.32 18.027.32 18.027S0 15.382 0 12.735v-2.481C0 7.608.32 4.96.32 4.96s.312-2.253 1.271-3.245C2.809.413 4.172.406 4.798.33 9.276 0 15.993 0 15.993 0h.014s6.717 0 11.195.331c.625.076 1.989.082 3.206 1.385.959.992 1.272 3.245 1.272 3.245zm-18.984 10.78l8.645-4.578-8.646-4.61.001 9.188z"
            fillRule="evenodd"
        />
    </svg>
)
