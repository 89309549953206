/* eslint-disable no-redeclare */
import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CardOrientationOptions, Portrait } from '../../cards/Portrait/Portrait'
import {
    getCardItemProps,
    LayoutName,
} from '../../cards/Portrait/Portrait.layouts'
import { LargeCard } from '../../cards/TheWest/LargeCard'
import { SmallCard } from '../../cards/TheWest/SmallCard'
import { ContainerWidthRatios } from '../../content/Picture/responsive'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { OverrideThemeSection } from '../../__helpers/override-theme-section'
import { useProduct } from '../../__product'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { Section } from '../../__styling/settings/sections'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { November } from '../November/November'
import {
    StyledHeader,
    StyledUniform,
    StyledUniformCardcontainer,
} from './Uniform.styled'

export type ListLength = 1 | 2 | 3 | 4 | 5 | 6

export type NovemberLayout = {
    type: 'november'
    listLength: ListLength
    hasTimestamp?: boolean
    sectionHeader?: SectionHeaderProps
    noStretch?: boolean
}

export type updatedLayout = 'large-card' | 'large-card-mdNoImage' | 'small-card'

export type UniformLayout = LayoutName[] | NovemberLayout | updatedLayout[]

export type UniformLayouts =
    | [UniformLayout]
    | [UniformLayout, UniformLayout]
    | [UniformLayout, UniformLayout, UniformLayout]
    | [UniformLayout, UniformLayout, UniformLayout, UniformLayout]
    | [
          UniformLayout,
          UniformLayout,
          UniformLayout,
          UniformLayout,
          UniformLayout,
      ]

export interface UniformPropsInternalProps {
    className?: string
    section?: Section
    sectionHeader?: SectionHeaderProps
    onEvent: (event: CollectionEvent) => void
    verticalSpacing?: keyof ThemeMargins | undefined
    disableImageLazyLoad?: boolean
    cardLayout: UniformLayouts
    cardOrientation?: CardOrientationOptions
    expectedCards: number
    containerWidthRatios?: ContainerWidthRatios
    items: MaybeLoaded<CardItem[]>
    hasBorder?: boolean
    stopBaseGridConfiguration?: boolean
    hasCustomBorders?: boolean
}

export type CollectionItemProps = Array<undefined | PublicationCardItem>

export function isNovemberLayout(
    layout: UniformLayout,
): layout is NovemberLayout

export function isNovemberLayout(layout: any) {
    return (
        typeof layout === 'object' &&
        'type' in layout &&
        layout.type === 'november'
    )
}

export function calculateLayoutsLength(layouts: UniformLayout[]): number {
    return layouts.reduce((accumulator, layout) => {
        const addition = isNovemberLayout(layout)
            ? layout.listLength
            : layout.length
        return accumulator + addition
    }, 0)
}

export const Uniform: React.FC<UniformPropsInternalProps> = (props) => {
    const {
        onEvent,
        verticalSpacing,
        className,
        section,
        sectionHeader,
        cardLayout,
        expectedCards,
        hasBorder = true,
        stopBaseGridConfiguration,
        hasCustomBorders,
    } = props

    const product = useProduct()

    const renderUniform = () => {
        const items = invertMaybeLoadedItems(props.items, expectedCards)

        // Pre-defined column setup
        const initialColumns = 1
        const intermediateColumns = 2
        const finalColumns = 4

        const containerValues = props.containerWidthRatios
            ? props.containerWidthRatios
            : { desktop: 1, tablet: 1, mobile: 1 }

        return (
            <ImpressionAvailable
                loading={!props.items.loaded}
                available={() => {
                    if (!props.items.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }
                    props.onEvent(
                        createCollectionAvailableEvent(
                            props.items.result,
                            'Uniform',
                            product,
                            props.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <div ref={ref}>
                        {sectionHeader && items && (
                            <StyledHeader>
                                <SectionHeader
                                    heading={sectionHeader.heading}
                                    headingUrl={sectionHeader.headingUrl}
                                    navLinks={sectionHeader.navLinks}
                                    onClick={sectionHeader.onClick}
                                />
                            </StyledHeader>
                        )}
                        <StyledUniform
                            verticalSpacing={verticalSpacing}
                            className={className}
                            hasBorder={hasBorder ? hasBorder : false}
                            stopGridConfiguration={stopBaseGridConfiguration}
                            hasCustomBorders={hasCustomBorders}
                        >
                            {cardLayout.map((layout, layoutIndex) => {
                                const prevLayouts = cardLayout.slice(
                                    0,
                                    layoutIndex,
                                )
                                const prevLayoutsLength =
                                    calculateLayoutsLength(prevLayouts)

                                return (
                                    <StyledUniformCardcontainer
                                        key={layoutIndex}
                                        hasCustomBorders={hasCustomBorders}
                                    >
                                        {isNovemberLayout(layout) ? (
                                            <November
                                                hasBackground
                                                disableImpressionEvent={true}
                                                cardNumberOffset={
                                                    prevLayoutsLength
                                                }
                                                sectionHeader={
                                                    layout.sectionHeader
                                                }
                                                section={section}
                                                onEvent={onEvent}
                                                timestamp={'list-style'}
                                                noStretch={layout.noStretch}
                                                items={
                                                    props.items.loaded
                                                        ? {
                                                              loaded: true,
                                                              result: props.items.result.slice(
                                                                  prevLayoutsLength,
                                                                  prevLayoutsLength +
                                                                      layout.listLength,
                                                              ),
                                                          }
                                                        : { loaded: false }
                                                }
                                                expectedCards={
                                                    layout.listLength
                                                }
                                            />
                                        ) : (
                                            layout.map(
                                                (columnLayout, columnIndex) => {
                                                    const columnLayoutLength =
                                                        calculateLayoutsLength([
                                                            layout.slice(
                                                                0,
                                                                columnIndex,
                                                            ),
                                                        ]) + prevLayoutsLength
                                                    const cardNumber =
                                                        columnLayoutLength + 1

                                                    const cardOrientation =
                                                        props.cardOrientation
                                                    const item = items[
                                                        columnLayoutLength
                                                    ] || {
                                                        loaded: false,
                                                    }

                                                    return (
                                                        <CardSwitcher
                                                            key={
                                                                columnLayoutLength
                                                            }
                                                            onEvent={onEvent}
                                                            item={item}
                                                            cardContext="uniform-portrait"
                                                            cardNumber={
                                                                cardNumber
                                                            }
                                                            publicationCard={(
                                                                publicationItem,
                                                            ) => {
                                                                switch (
                                                                    columnLayout
                                                                ) {
                                                                    case 'large-card':
                                                                        return (
                                                                            <LargeCard
                                                                                item={
                                                                                    publicationItem
                                                                                }
                                                                                cardNumber={
                                                                                    cardNumber
                                                                                }
                                                                                onEvent={
                                                                                    onEvent
                                                                                }
                                                                            />
                                                                        )
                                                                    case 'large-card-mdNoImage':
                                                                        return (
                                                                            <LargeCard
                                                                                item={
                                                                                    publicationItem
                                                                                }
                                                                                cardNumber={
                                                                                    cardNumber
                                                                                }
                                                                                onEvent={
                                                                                    onEvent
                                                                                }
                                                                                hideImage={{
                                                                                    size: 'md',
                                                                                }}
                                                                            />
                                                                        )
                                                                    case 'small-card':
                                                                        return (
                                                                            <SmallCard
                                                                                item={
                                                                                    publicationItem
                                                                                }
                                                                                cardNumber={
                                                                                    cardNumber
                                                                                }
                                                                                onEvent={
                                                                                    onEvent
                                                                                }
                                                                            />
                                                                        )
                                                                    default:
                                                                        return (
                                                                            <Portrait
                                                                                fixedRatio={
                                                                                    '16:9'
                                                                                }
                                                                                containerWidthRatios={{
                                                                                    mobile:
                                                                                        (1 /
                                                                                            initialColumns) *
                                                                                        containerValues.mobile,
                                                                                    tablet:
                                                                                        (1 /
                                                                                            intermediateColumns) *
                                                                                        containerValues.tablet,
                                                                                    desktop:
                                                                                        (1 /
                                                                                            finalColumns) *
                                                                                        containerValues.desktop,
                                                                                }}
                                                                                {...getCardItemProps(
                                                                                    publicationItem,
                                                                                    columnLayout,
                                                                                    {
                                                                                        onEvent,
                                                                                    },
                                                                                    cardNumber,
                                                                                    cardOrientation,
                                                                                )}
                                                                            />
                                                                        )
                                                                }
                                                            }}
                                                        />
                                                    )
                                                },
                                            )
                                        )}
                                    </StyledUniformCardcontainer>
                                )
                            })}
                        </StyledUniform>
                    </div>
                )}
            </ImpressionAvailable>
        )
    }

    return section ? (
        <OverrideThemeSection section={section}>
            {renderUniform()}
        </OverrideThemeSection>
    ) : (
        renderUniform()
    )
}

Uniform.displayName = 'Uniform'
