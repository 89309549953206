import React from 'react'
import { StyledHeroCaption } from '../../../content/Gallery/Gallery.styled'

export const GalleryCaption: React.FC<{
    captionText?: string
    credit?: string
    byline?: string
}> = ({ captionText, credit, byline }) => {
    return (
        <StyledHeroCaption>
            {captionText}
            {captionText && (byline || credit) ? ' ' : null}
            {byline || credit ? 'Picture: ' : null}
            {byline}
            {byline && credit && <span>&#47;</span>}
            {credit}
        </StyledHeroCaption>
    )
}
GalleryCaption.displayName = 'GalleryCaption'
