import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getRegionalRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'South West',
                    headingUrl: '/news/south-west',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/south-west'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Great Southern',
                    headingUrl: '/news/great-southern',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/great-southern'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Goldfields',
                    headingUrl: '/news/goldfields',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/goldfields'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Peel/Rockingham',
                    headingUrl: '/news/peel-rockingham',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/peel-rockingham'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Gascoyne',
                    headingUrl: '/news/gascoyne',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/gascoyne'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Mid West',
                    headingUrl: '/news/mid-west',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/mid-west'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Kimberley',
                    headingUrl: '/news/kimberley',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/kimberley'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Pilbara',
                    headingUrl: '/news/pilbara',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/pilbara'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
