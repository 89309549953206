import { cx } from '@emotion/css'
import {
    CardEvents,
    CollectionEvent,
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    Topic,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import {
    StyledCard,
    StyledCardContainer,
    StyledCardMedia,
    StyledCardText,
} from './SevenNewsInlineRelatedContentCards.styled'

interface SevenNewsInlineRelatedContentCardProps {
    items: MaybeLoaded<PublicationCardItem[]>
    expectedCards: number
    onEvent: (event: CardEvents | CollectionEvent) => void
}
export const SevenNewsInlineRelatedContentCards: React.FC<SevenNewsInlineRelatedContentCardProps> =
    (props) => {
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)
        const layout = props.expectedCards >= 2 ? 'portrait' : 'landscape'

        const storyClassification = true

        function linkClicked(
            e: React.MouseEvent<HTMLElement>,
            item: MaybeLoaded<PublicationCardItem>,
            cardNumber: number,
        ) {
            if (!item.loaded) {
                e.preventDefault()
                return
            }
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    item.result,
                    'SevenNewsInlineRelatedContentCard',
                    'InlineRelatedContent',
                    cardNumber,
                    getFundingType(item.result, storyClassification),
                    'InlineRelatedCollection',
                ),
            )
        }

        return (
            <StyledCardContainer layout={layout} className={cx('hide-print')}>
                {items.map((item, index) => {
                    const topic = item.loaded && item.result.primaryTopic
                    const key = (item.loaded && item.result.id) || index
                    const kickerText = topic && overrideKickerText(topic)

                    return (
                        <StyledCard
                            topic={topic}
                            layout={layout}
                            to={toLinkWithHint(item)}
                            onClick={(event) => {
                                linkClicked(event, item, index)
                            }}
                            key={key}
                        >
                            <StyledCardMedia
                                item={item}
                                fixedRatio={'16:9'}
                                imageWidths={{
                                    mobile: '100vw',
                                    tablet: '100vw',
                                    desktop: '628px',
                                    fallbackWidth: 628,
                                }}
                                onEvent={props.onEvent}
                                mediaMode={'default'}
                                willPlayVideoInline={false}
                            />

                            <StyledCardText
                                item={item}
                                kickerOverrideText={kickerText}
                                teaserMode={'hidden'}
                                onEvent={props.onEvent}
                                innerRef={undefined}
                                fontScale={1}
                                topic={topic}
                            />
                        </StyledCard>
                    )
                })}
            </StyledCardContainer>
        )
    }

/**
 * DPT-3160: Uses topic title as kicker text
 */
function overrideKickerText(topic: Topic) {
    const topicsToUseOwnTitle = ['space', 'world', 'win']

    if (!topic.parent) {
        return topic.title
    }

    // Certain topics need to use their title instead of their parent title
    if (topicsToUseOwnTitle.includes(topic.title.toLowerCase())) {
        return topic.title
    }

    // DPT3247 - display secondary topic instead of primary for 'lifestyle'
    //           if it has secondary topic classification
    if (
        topic.id.includes('lifestyle/') &&
        topic.title.toLowerCase() !== 'lifestyle'
    ) {
        return topic.title
    }

    return topic.parent.title
}
