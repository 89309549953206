import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoute, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../get-topic-sponsor'

const cskBannerDesktopX2 = require<string>('./assets/csk/csk-desktop-1220x150@2x-80.jpg')
const cskBannerTabletX2 = require<string>('./assets/csk/csk-tablet-738x57@2x-80.jpg')
const cskBannerMobileX2 = require<string>('./assets/csk/csk-mobile-411x93@2x-80.jpg')

const cskBannerDesktop = require<string>('./assets/csk/csk-desktop-1220x150-80.jpg')
const cskBannerTablet = require<string>('./assets/csk/csk-tablet-738x57-80.jpg')
const cskBannerMobile = require<string>('./assets/csk/csk-mobile-411x93-80.jpg')

export const getClaremontSerialKillingsRouteInfo =
    (topic: Topic): StaticRoute<TheWestSection> =>
    ({ getAdTargeting }) => ({
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        includeFromSectionInMetaDescription: true,
        pageMeta: {
            description: 'The Latest in Claremont Serial Killings',
        },
        adTargeting: getAdTargeting('home', 'default', topic),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: cskBannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText:
                                    'Claremont Serial Killings: The Trial as it unfolds',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: cskBannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cskBannerDesktop,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: cskBannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cskBannerTablet,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: cskBannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cskBannerMobile,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.component({
                            type: 'sierra',
                            props: {
                                verticalSpacing: 'gridGap',
                                fixedRatios: ['16:9'],
                                cardOrientation: {
                                    type: 'Landscape-Portrait',
                                    maxBreakpoint: 'md',
                                },
                                cardLayout: [
                                    'hero',
                                    'teaser-xsVisible-mdHidden-Image',
                                ],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'claremont-serial-killings',
                                    offset: 0,
                                    pageSize: 2,
                                },
                            },
                        }),
                        layout.component({
                            type: 'uniform',
                            props: {
                                verticalSpacing: 'xl',
                                section: 'default',
                                cardOrientation: { type: 'Landscape-Portrait' },
                                cardLayout: [
                                    ['teaserWithBackground'],
                                    ['teaserWithBackground'],
                                    [
                                        'xsImage-mdNoImage-withBG',
                                        'xsImage-mdNoImage-withBG',
                                    ],
                                    ['teaserWithBackground'],
                                ],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'claremont-serial-killings',
                                    offset: 2,
                                    pageSize: 7,
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),

                        layout.component({
                            type: 'omny-web',
                            props: {
                                height: 391, // magic number to get the height we want while avoiding the last item displaying a bottom border
                                verticalSpacing: 'xs',
                                link: 'https://omny.fm/shows/the-claremont-serial-killings/playlists/podcast/embed?style=cover',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                cardOrientation: { type: 'Landscape-Portrait' },

                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['news/claremont-serial-killings'],
                                    includeSubTopics: false,
                                    paging: {
                                        page: 1,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('two'),
                    ],
                },
            }),
        ],
    })
