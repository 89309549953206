import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Cerberus } from '../../compositions/Cerberus/Cerberus'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'

export interface CerberusRouteProps extends BaseCompositionProps {
    verticalSpacing?: keyof ThemeMargins
    stretchColumns: boolean
    hasSeparators?: boolean
    hasBackgroundFill?: boolean
}

export const CerberusRegistration = createRegisterableComposition<
    'left' | 'middle' | 'right'
>()(
    'cerberus',
    (contentAreas, props: CerberusRouteProps) => (
        <ContentWrapper
            stretchColumns={props.stretchColumns}
            stretchSelf={props.stretchSelf}
        >
            <Cerberus
                {...props}
                content={[
                    contentAreas.left,
                    contentAreas.middle,
                    contentAreas.right,
                ]}
            />
        </ContentWrapper>
    ),
    (
        _contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: CerberusRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        return {
            containerWidthRatios: {
                desktop: 0.33 * containerWidthRatios.desktop,
                tablet: 0.33 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
