import { PublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { UnknownPublication } from '../../../../client-data-types/publication'

export function mapUnknown(publication: PublicationV4DTO): UnknownPublication {
    return {
        _self: publication._self,
        id: publication.id,
        isSponsored: publication.isSponsored,
        slug: publication.slug,
        lastUpdated: publication.lastUpdated,
        publicationDate: publication.publicationDate,
        source: publication.source,
        primaryTopic: publication.topics.primary,
        secondaryTopics: publication.topics.secondary,
        kind: 'unknown',
    }
}
