import React from 'react'
import { FeatureToggle } from '../..'
import { BylineProps } from './Byline'
import { StyledButtonContainer, StyledByline } from './Byline.styled'
import { BylineMail } from './components/BylineMail'
import { BylineProfile } from './components/BylineProfile'
import { BylineText } from './components/BylineText'

export const TheWestByline: React.FC<BylineProps> = ({
    text,
    isFullWidth,
    requiredAccess,
    profile,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    allowCommenting,
    url,
    title,
    publicationKind,
    noLine,
}) => {
    const isEvent = publicationKind === 'event'

    const isSingleAuthorWithEmail =
        profile?.length === 1 && profile?.[0].email ? true : false

    return (
        <StyledByline isFullWidth={isFullWidth} noLine={noLine}>
            {profile && (
                <BylineProfile profile={profile} profilePageLink={true} />
            )}
            <BylineText
                profile={profile}
                fundingType={fundingType}
                source={source}
                timestamp={isEvent ? undefined : timestamp}
                text={text}
                requiredAccess={requiredAccess}
                timestampUpdated={timestampUpdated}
                allowCommenting={allowCommenting}
                timestampUpdatedFormat={
                    isEvent ? 'relativeLongFormat' : undefined
                }
                timestampUpdatedLabel={isEvent ? 'Updated' : 'Updated:'}
            />
            <StyledButtonContainer showEmailButton={isSingleAuthorWithEmail}>
                <FeatureToggle
                    feature="email-author"
                    on={() => (
                        <BylineMail
                            profiles={profile}
                            url={url}
                            title={title}
                        />
                    )}
                />
            </StyledButtonContainer>
        </StyledByline>
    )
}
