import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts, colors } from '../../../__styling'

export const StyledInlineTaboola = styled('div')(() => ({
  [breakpoint('md')]: {
      width: '300px',
      margin: 'auto',
  },

  '&::before': {
      display: 'block',
      marginTop: calcRem(6),
      width: '100%',
      fontSize: calcRem(10),
      color: colors.perthnow.greySlate,
      content: `'advertisement'`,
      textTransform: 'uppercase',
      fontFamily: fonts.perthnow.sansSerif,
      textAlign: 'center'
  }
}))

export const StyledInlineTaboolaAMP = styled('div')(({ theme }) => ({
    marginBottom: calcRem(10),
}))
