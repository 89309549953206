import {
    createRegisterableComponentWithData,
    ePaperLatestEditionDataDefinitionLoader,
    Issue,
} from '@news-mono/web-common'
import React from 'react'
import EPaperPage, { PNRegionalSections } from './ePaperPage'

export interface ePaperPageRouteProps {
    latestIssues?: Issue[]
    section?: PNRegionalSections
    title: string
}

export const ePaperPageRegistration = createRegisterableComponentWithData(
    'epaper-page',
    ePaperLatestEditionDataDefinitionLoader,
    (props: ePaperPageRouteProps, data, services) => {
        if (!data.loaded) return null
        if (data.result === undefined) return null

        return (
            <EPaperPage
                latestEdition={data.result.latestIssues[0]}
                allEditions={data.result.latestIssues}
                onEvent={services.onEvent}
                regionalSection={props.section}
                title={props.title}
            />
        )
    },
)
