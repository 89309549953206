import styled from '@emotion/styled'
import { Sharing } from '../../../buttons/Sharing/Sharing'
import {
    articleVideoClassName,
    stickyVideoClassName,
} from '../../../publication/ArticleVideo/ArticleVideo.styled'
import { SectionHeaderContainer } from '../../../section-header/SectionHeader/SectionHeader.styled'
import { styledListClassName } from '../../../typography/List/List.styled'
import { metrics } from '../../../__styling/settings/metrics'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../../__styling/style-mixins'

const gridGapSize = calcRem(metrics.thewest.margins.sm)

export const StyledGrid = styled('article')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: gridGapSize,
    marginBottom: calcRem(metrics.thewest.margins.md),
})

export const StyledNestedGrid = styled('div')<{ hideSocialLinks?: boolean }>(
    ({ hideSocialLinks }) => ({
        display: 'grid',
        gridTemplateColumns: '100%',
        gridGap: gridGapSize,

        '& > aside': {
            display: hideSocialLinks ? 'none' : 'inherit',
        },

        [breakpoint('xs')]: {
            gridTemplateColumns: hideSocialLinks ? 'auto' : '40px auto',
            padding: `${calcRem(metrics.thewest.margins.md)} 0`,
        },
    }),
)

export const TheWestStyledSharing = styled(Sharing)({
    position: 'sticky',
    top: metrics.thewest.margins.md,
})

export const StyledArticleContent = styled('div')({
    /*
            - Adds the horizontal padding to the article content
            - Excludes the figure component so we can center images & video of unknown width
              but also have the image asset full bleed to the edge on desktop.
        */
    width: '100%',

    '& > *:last-child': {
        marginBottom: 0,
    },

    // If the last item in an article is an image then remove the border on the figcaption so it doesn't double up with topics
    '& > figure:last-child figcaption': {
        borderBottom: 'none',
    },

    // Fix for SWM-4447
    '& .twin-image-wrapper': {
        marginBottom: calcRem(metrics.thewest.margins.md),

        [breakpoint('sm')]: {
            marginBottom: 0,
        },
    },

    [`${SectionHeaderContainer}`]: {
        marginTop: metrics.thewest.margins.xl,
    },

    [`& .${styledListClassName}`]: {
        paddingLeft: calcRem(metrics.thewest.margins.lg),
    },
})

export const StyledStickyCell = styled('div')({
    '--stickyHeight': 0,
    display: 'block',
    minHeight: `var(--stickyHeight)`,

    [`&.${stickyVideoClassName}`]: {
        left: '100%',
        transform: 'translateX(366px)',
        position: 'sticky',
        top: 168,
        width: 321,
        zIndex: zIndex.common.stickyVideo,
        pointerEvents: 'none', // DPT-1879: prevent StyledStickyCell from preventing interactions with neighbouring elements

        // Remove margin from StyledArticleVideoContainer so it doesnt overlap the mrec
        [`& .${articleVideoClassName} > div:first-child`]: {
            marginBottom: 0,
        },

        // If there is a figcaption we don't want it to be sticky.
        '& .figcaption': {
            ...visuallyhidden,
        },
    },
})
StyledStickyCell.displayName = 'StickyContainer'

export const StyledStickyTop = styled('div')({
    height: 2, // set to 2px to reduce weird rendering bugs and flickering of the video
})
StyledStickyTop.displayName = 'StickyWatcher'
