import { useCallback, useEffect, useRef, useState } from 'react'
import { AflFixtureData } from './types'

const UPDATE_RESULT_INTERVAL = 1000 * 10

export const useFetchAflFixtureData = (
    gameId: string | undefined,
    apiData: any,
) => {
    const [data, setData] = useState<AflFixtureData | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const fetchData = useCallback(
        (data: AflFixtureData | null) => {
            if (gameId) {
                fetch(`/afl/fixture?game_id=${gameId}`, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((result) => result.json())
                    .then((rawData) => {
                        const fixtureData = formatData(rawData)
                        if (
                            JSON.stringify(fixtureData) !== JSON.stringify(data)
                        ) {
                            setData(fixtureData)
                        }
                    })
                    .catch(() => setError(true))
            }
        },
        [gameId],
    )

    useEffect(() => {
        setLoading(true)
        if (apiData && data?.match_status !== 'playing') {
            const formatedData = formatData(apiData)
            setData(formatedData)
        } else {
            fetchData(data)
        }
        setLoading(false)
    }, [apiData, data, fetchData])

    useInterval(() => {
        if (
            data?.match_status === 'playing' ||
            data?.match_status === 'scheduled' ||
            data?.match_status === 'prematch'
        ) {
            fetchData(data)
        }
    }, UPDATE_RESULT_INTERVAL)

    return { data, error, loading }
}

const formatData = (data: any): AflFixtureData => {
    return {
        date: data.report.aflMatchStats.matchInfo['@attributes'].startTimeUTC,
        match_status:
            data.report.aflMatchStats.matchInfo['@attributes'].matchStatus,
        round: data.report.aflMatchStats.matchInfo['@attributes'].roundNumber,
        period: data.report.aflMatchStats.matchInfo['@attributes'].period,
        periodCompleted:
            data.report.aflMatchStats.matchInfo['@attributes'].periodCompleted,
        periodSeconds:
            data.report.aflMatchStats.matchInfo['@attributes'].periodSeconds,
        venue: {
            name: data.report.aflMatchStats.matchInfo['@attributes'].venueName,
            place: data.report.aflMatchStats.matchInfo['@attributes']
                .venueTimezone,
        },
        homeTeam: {
            id: data.report.aflMatchStats.matchInfo['@attributes'].homeSquadId,
            name: data.report.aflMatchStats.matchInfo['@attributes']
                .homeSquadName,
            score: {
                behinds:
                    data.report.aflMatchStats.matchInfo['@attributes']
                        .homeBehinds,
                goals: data.report.aflMatchStats.matchInfo['@attributes']
                    .homeGoals,
                score: data.report.aflMatchStats.matchInfo['@attributes']
                    .homeScore,
            },
        },
        awayTeam: {
            id: data.report.aflMatchStats.matchInfo['@attributes'].awaySquadId,
            name: data.report.aflMatchStats.matchInfo['@attributes']
                .awaySquadName,
            score: {
                behinds:
                    data.report.aflMatchStats.matchInfo['@attributes']
                        .awayBehinds,
                goals: data.report.aflMatchStats.matchInfo['@attributes']
                    .awayGoals,
                score: data.report.aflMatchStats.matchInfo['@attributes']
                    .awayScore,
            },
        },
        tabTouchOdds: data.tabTouchOdds,
        rawApiData: data,
    }
}

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}
