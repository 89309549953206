import {
    LinkType,
    PartyItem,
    PoliticianItem,
    PollieRaterItem,
} from '@west-australian-newspapers/contentful-retrieval-utilities'

export interface PollScore {
    id: string
    userScore: number | null
    averageScore: number
}

export interface Politician {
    id: string
    name: string
    party?: string
    position: string
    description: string
    expertRating: number
    electorate?: string
    incumbent: boolean
    profilePicture?: string
}

export type PoliticianPoll = PollScore & Politician

export interface Party {
    id: string
    name: string
    members: Array<LinkType>
    order: number
}

export const isPoliticianItem = (
    item: PollieRaterItem,
): item is PoliticianItem =>
    item.contentModel === 'politician' && item.contentType === 'Entry'

export const isPartyItem = (item: PollieRaterItem): item is PartyItem =>
    item.contentModel === 'party' && item.contentType === 'Entry'
