import {
    Block,
    CommentData,
    isArticleLikeType,
    PublicationType,
} from '@news-mono/web-common'

export interface VideoData {
    present: boolean
    count: number
    types: {
        heroVideo: boolean
        inlineVideo: boolean
    }
}

export function getPublicationPageProperties(
    article: PublicationType,
    commentData?: CommentData,
) {
    const heroVideoPresent = !!article.heroVideo

    const isInlineVideo = (contentItem: Block) =>
        contentItem.kind === 'inline' && contentItem.inlineKind === 'video'

    const videoCount =
        isArticleLikeType(article) && article.content
            ? article.content.reduce(
                  (acc, val) => (isInlineVideo(val) ? acc + 1 : acc),
                  0,
              )
            : 0

    const count = (heroVideoPresent ? 1 : 0) + videoCount

    const videoData: VideoData = {
        present: count > 0,
        count: (heroVideoPresent ? 1 : 0) + videoCount,
        types: {
            heroVideo: heroVideoPresent,
            inlineVideo: !!videoCount,
        },
    }

    if (article.kind === 'unknown' || article.kind === 'redirect') {
        return {
            primaryTopic: article.primaryTopic,
            secondaryTopics: article.secondaryTopics,
            articleKind: article.kind,
            source: article.source,
            publicationDate: article.publicationDate,
        }
    }

    videoData.present = heroVideoPresent || videoData.types.inlineVideo

    return {
        primaryTopic: article.primaryTopic,
        secondaryTopics: article.secondaryTopics,
        articleKind: article.kind,
        classification:
            (isArticleLikeType(article) && article.classification) || 'none',
        isSponsored: article.isSponsored,
        source: article.source,
        byline: article.byline || 'none',
        title: article.heading,
        publicationDate: article.publicationDate,
        requiredAccess: article.requiredAccess,
        video: videoData,
        liveBlog: {
            isLive: article.isLive || 'none',
            isPresent: article.kind === 'article' && !!article.blogIsPresent,
        },
        oovvuuRecommendationsLoaded:
            article.kind === 'article' &&
            article.hasOovvuuRecommendations === true,
        commentData: commentData || 'none',
    }
}
