import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TWStaticMadMondayCard } from '../TWStaticMadMondayCard/TWStaticMadMondayCard'

export const StaticMadMondayPromoCardRegistration = createRegisterableComponent(
    'tw-mad-monday-promo-card',
    ({}) => {
        return <TWStaticMadMondayCard />
    },
)
