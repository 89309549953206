export interface Breakpoints {
    xl: number
    lg: number
    md: number
    sm: number
    xs: number
    xxs: number

    /** @deprecated 1 usage. */
    xxl: number
    /** @deprecated 6 usages to be replaced with lg. */
    mlg: number
}

export const breakpoints: Breakpoints = {
    xxl: 1560,
    xl: 1440,
    lg: 1280,
    mlg: 1140,
    md: 1020,
    sm: 768,
    xs: 660,
    xxs: 480,
}

export type BreakpointKeys = keyof Breakpoints

export type NightlyBreakpointKeys = Extract<
    BreakpointKeys,
    'xs' | 'sm' | 'md' | 'lg'
>

export const availableBreakpoints: BreakpointKeys[] = Object.keys(
    breakpoints,
) as any
