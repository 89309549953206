import styled from '@emotion/styled'
import debug from 'debug'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { calcRem } from '../../..'

export const liveEventDebug = debug('event')

export type DebugEvent = { title: string; details?: unknown; timeStamp: number }

export type DebugEventAdder = (title: string, details?: unknown) => void

const isPanelEnabled = (debugKey: string) =>
    typeof window !== 'undefined' &&
    !!window.localStorage.getItem('debug')?.includes(debugKey)

export interface DebugPanelProps {
    debugObject: unknown
    eventFeed: DebugEvent[]
    isEnabled: boolean
}

export const DebugPanel: FC<DebugPanelProps> = ({
    debugObject,
    eventFeed,
    isEnabled,
}) => {
    const [hidePanel, setHidePanel] = useState(false)
    const sortedEventFeed = useMemo(
        () => eventFeed.sort((a, b) => b.timeStamp - a.timeStamp),
        [eventFeed],
    )

    if (!isEnabled) {
        return null
    }

    return (
        <StyledDebugPanelContainer>
            <StyledDebugPanel>
                <StyledHideButton
                    onClick={() => setHidePanel((hidePanel) => !hidePanel)}
                >
                    Toggle
                </StyledHideButton>
                {!hidePanel && (
                    <>
                        <details open>
                            <summary>Debug object:</summary>
                            {JSON.stringify(debugObject, null, 2)}
                        </details>
                        <h4>Latest events:</h4>
                        <StyledDebugEventsContainer>
                            {sortedEventFeed.map((event, i) => (
                                <details key={i}>
                                    <summary>{event.title}</summary>
                                    {event.details && (
                                        <pre>
                                            {JSON.stringify(
                                                event.details,
                                                null,
                                                2,
                                            )}
                                        </pre>
                                    )}
                                </details>
                            ))}
                        </StyledDebugEventsContainer>
                    </>
                )}
            </StyledDebugPanel>
        </StyledDebugPanelContainer>
    )
}

type UseDebugPanelControls = {
    debugPanelProps: Omit<DebugPanelProps, 'debugObject'>
    addDebugEvent: DebugEventAdder
}
/**
 * A hook used to control a <DebugPanel/> element.
 */
export const useDebugPanel = (debugKey = '*'): UseDebugPanelControls => {
    const [eventFeed, setEventFeed] = useState<DebugEvent[]>([])

    const addDebugEvent = useCallback((title: string, details?: unknown) => {
        liveEventDebug(title, details)
        setEventFeed((prev) => [
            ...prev,
            { title, details, timeStamp: Date.now() },
        ])
    }, [])

    return {
        debugPanelProps: {
            eventFeed,
            isEnabled: isPanelEnabled(debugKey),
        },
        addDebugEvent,
    }
}

export const StyledDebugPanelContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    height: 0,
    width: 0,
}))

export const StyledDebugEventsContainer = styled('div')(({ theme }) => ({
    maxHeight: '80vh',
    overflowY: 'scroll',

    '> details': {
        padding: 5,
        borderBottom: `1px solid`,
    },
}))

export const StyledDebugPanel = styled('pre')(({ theme }) => ({
    backgroundColor: '#2a303c',
    color: '#bfc5cf',
    borderRadius: calcRem(5),
    boxShadow: '-4px 5px 10px 3px #0000004d',
    position: 'fixed',
    top: 20,
    right: 20,
    marginLeft: 'auto',
    left: '66vw',
    maxWidth: '33vw',
    minHeight: 20,
    maxHeight: '90vh',
    overflowY: 'scroll',
    zIndex: 10000,
    fontSize: 13,
}))

export const StyledHideButton = styled('button')(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: 0,
}))
