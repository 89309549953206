import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { EditorialType, FundingType } from '../../../../../templates'
import { textMixin } from '../../../../../mixins/TextMixin/TextMixin'
import { calcRem, TheNightlyThemeConfig } from '../../../../../__styling'

export const Classification = styled.div<{ type: EditorialType | FundingType }>(
    ({ theme, type }) => [
        {
            textTransform: 'uppercase',
            color:
                type === EditorialType.Breaking ||
                type === EditorialType.Live ||
                type === EditorialType.Exclusive
                    ? tokens.thenightly.colors.palette.utility.error.default
                    : undefined,
            display: 'inline-flex',
            gap: calcRem(4),
            span: {
                alignItems: 'center',
            },
        },
        textMixin<TheNightlyThemeConfig>(theme, 'byline-primary'),
    ],
)
