import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { Section } from '../__styling/settings/sections'

interface OverrideThemeSectionProps {
    section: Section
    children?: React.ReactNode
}

/**
 * Adds data resolved from the the routing to our theme provider
 */
export const OverrideThemeSection: React.FC<OverrideThemeSectionProps> = (
    props,
) => {
    const { children, section } = props
    return <ThemeProvider theme={{ section }}>{children}</ThemeProvider>
}
