import React from "react"
import { StyledBlackFridayAquaText, StyledBlackFridayGlowText, StyledBlackFridayStrap, StyledBlackFridayStrapWrapper, StyledTheWestMiniLogo } from "./BlackFridayStrap.styled"
import { colors } from "../../__styling"
import { AllEvents, BreakingNewsCard, createCardClickedEvent } from "@news-mono/web-common"

export interface BlackFridayStrapProps {
    bannerLink: string
    onEvent: (event: AllEvents) => void
}

export const BlackFridayStrap = (props: BlackFridayStrapProps) => {
    const { bannerLink, onEvent } = props

    const BlackFridayStrapComponent = () => (
        <StyledBlackFridayStrap>
            <StyledBlackFridayAquaText>BLACK FRIDAY</StyledBlackFridayAquaText>
            <StyledBlackFridayGlowText>&nbsp;SALE</StyledBlackFridayGlowText>
            <StyledBlackFridayAquaText>&nbsp;50% OFF</StyledBlackFridayAquaText>
            <StyledBlackFridayGlowText hideForGalaxyFold={true}>&nbsp;ALL SUBSCRIPTIONS</StyledBlackFridayGlowText>
        </StyledBlackFridayStrap>
    )
    const TheWestMiniLogoLeft = () => (
        <StyledTheWestMiniLogo fill={colors.white} className={'left'} />
    )
    const TheWestMiniLogoRight = () => (
        <StyledTheWestMiniLogo fill={colors.white} className={'right'} />
    )

    const newItem: BreakingNewsCard = {
        shortHeadline: 'BLACK FRIDAY SALE 50% OFF ALL SUBSCRIPTIONS',
        link: bannerLink,
        cardType: 'breaking-news',
        listId: 'black-friday-promo-strap'
    }
    const linkClicked = () => {
        onEvent(createCardClickedEvent(
            onEvent,
            newItem,
            'BlackFridayPromoStrap',
            'BlackFridayPromoStrap',
            1,
        ))
    }

    return (
        <StyledBlackFridayStrapWrapper href={bannerLink} onClick={linkClicked}>
            <TheWestMiniLogoLeft />
            <BlackFridayStrapComponent />
            <p>&nbsp;&nbsp;</p>
            <BlackFridayStrapComponent />
            <TheWestMiniLogoRight />
        </StyledBlackFridayStrapWrapper>
    )
}
