import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import * as React from 'react'
import { useEffect } from 'react'
import {
    breakpoint,
    colors,
    ThemedRender,
    themedValue,
} from '../../../__styling'
import { RoadSafetyLogo } from './components/RoadSafetyLogo/RoadSafetyLogo'

declare const Opta: OptaScript

export interface OptaScript {
    start: () => void
    destroy: () => void
}

export function OptaIsAvailable() {
    return typeof Opta !== 'undefined' && Opta
}

export const StyledCricketWidgetContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    margin: themedValue(theme, {
        perthnow: theme.margins.outerMargin,
        fallback: undefined,
    }),
    // The widgets have logos that sit outside of the container, these need some extra space so they don't interfere with other components
    [breakpoint('sm')]: {
        marginTop: themedValue(theme, {
            sevennews: undefined,
            fallback: theme.margins.xl,
        }),
    },

    '.Opta-Title': {
        backgroundColor: themedValue(theme, {
            sevennews: colors.black,
            fallback: undefined,
        }),
    },
}))

export function LazyHorizontalCricketWidget() {
    useEffect(() => {
        if (OptaIsAvailable()) {
            Opta.start()
        }
    }, [])

    return (
        <StyledCricketWidgetContainer>
            <ThemedRender
                thewest={() =>
                    OptaIsAvailable() ? (
                        <WebLink to="https://www.rsc.wa.gov.au/Your-Safety/Behaviours/Drink-Driving?utm_source=thewest&utm_medium=display&utm_campaign=drinkdriving2020&utm_content=widget">
                            <RoadSafetyLogo />
                        </WebLink>
                    ) : null
                }
                perthnow={() =>
                    OptaIsAvailable() ? (
                        <WebLink to="https://www.rsc.wa.gov.au/Your-Safety/Behaviours/Drink-Driving?utm_source=perthnow&utm_medium=display&utm_campaign=drinkdriving2020&utm_content=widget">
                            <RoadSafetyLogo />
                        </WebLink>
                    ) : null
                }
            />
            <opta-widget
                widget="fixtures"
                fixtures_type="all"
                // Disabled all comps and seasons until issue is resolved:
                // https://jira.swmdigital.io/browse/DPT-2870?focusedCommentId=193043&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-193043
                // competition="2602,2603,2604,2605,2606,2607,2608,2609,2610,2678,2679"
                // season="0,0,0,0,0,0,0,0,0,0,0"
                // 2603 - mens tests
                // 2604 - mens ODI
                // 2602 - mens T20
                // 2608 - womens internationals (completed)
                // 2609 - womens internationals (completed)
                // 2610 - womens internationals
                // 2678 - WBBL
                // 2679 - mens bbl
                competition="2813,2814,2815,2816,2852,2831,2832"
                season="0,0"
                template="strip"
                live="true"
                match_status="all"
                start_on_current="true"
                order_by="date_ascending"
                show_crests="true"
                date_format="LLLL"
                team_naming="full"
                pre_match="false"
                show_live="true"
                show_logo="false"
                show_title="true"
                breakpoints="400"
                sport="cricket"
                match_link="/sport/cricket/live-scores"
            ></opta-widget>
        </StyledCricketWidgetContainer>
    )
}

export default LazyHorizontalCricketWidget
