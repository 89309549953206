import {
    ConsentLevel,
    sophiDebug,
    useConsentGiven,
    useFeature,
} from '@news-mono/web-common'
import React, { useCallback, useEffect } from 'react'
import { eventQueue } from '../__App/client'

export const Sophi = () => {
    const isToggleEnabled = useFeature('sophi')

    const consentRequiredLevel = ConsentLevel.Advertising
    const consentGiven = useConsentGiven({ consentRequiredLevel })
    const scriptText = `!function(f,g){window.sophi=window.sophi||{};var c=window.sophi;c.q=c.q||[],c.sendEvent=function(a){c.q.push(a)},c.data=c.data||{},c.settings=c.settings||{};var b=c.settings,a;b.trackerName=b.trackerName||"sophiTag";try{window.localStorage&&((a=localStorage.getItem(b.trackerName+".tagCdn"))&&"string"==typeof a&&7<a.length||(a=void 0))}catch(e){a=void 0}b.loadFrom=a?a+"sophi.min.js":b.loadFrom||"https://cdn.sophi.io/latest/sophi.min.js",b.legacy=a?a+"sophi.legacy.min.js":b.loadFrom||"https://cdn.sophi.io/latest/sophi.legacy.min.js";try{eval('let id=Symbol("id"), a=[...new Set([0,1])].includes(0);')}catch(a){b.loadFrom=b.legacy}finally{if(!window[b.trackerName]){a=document.createElement("script");var d=document.getElementsByTagName("script")[0];a.async=1,a.src=b.loadFrom,d.parentNode.insertBefore(a,d)}}}();`

    const isScriptAdded = useCallback(() => {
        try {
            const scripts = document.scripts
            for (let i = 0; i < scripts.length; i++) {
                if (
                    scripts[i].innerText === scriptText ||
                    scripts[i].innerHTML === scriptText
                ) {
                    return true
                }
            }
            return false
        } catch (err) {
            return false
        }
    }, [scriptText])

    useEffect(() => {
        if (isScriptAdded() || !consentGiven || !isToggleEnabled) {
            return
        }
        const script = document.createElement('script')
        script.text = scriptText
        script.type = 'text/javascript'
        document.head.appendChild(script)

        const onLoadCallback = () => {
            sophiDebug('Sophi Library Loaded')
            eventQueue.resolveEvent('sophi-page-view')
        }

        //Set the settings object for Sophi
        window.sophi = {
            settings: {
                collectorEndpoint: 'collector.sophi.io',
                client: 'wan',
                noConfigFile: true,
                appId: 'wan:thewest_com_au:website',
                plugin: { adblock: false, private: false, video: true },
                onLoadCallback,
            },
        }
    }, [consentGiven, isToggleEnabled, isScriptAdded, scriptText])

    return <></>
}
