import React from 'react'
import {
    StyledLabelledToggle,
    StyledToggle,
    StyledToggleCheckbox,
    StyledToggleSwitch,
} from '../../buttons/LabelledToggle/LabelledToggle.styled'

export interface LabelledToggleProps {
    checked?: boolean
    onToggle: (value: boolean) => void
    on?: string
    off?: string
    className?: string
    loading?: true | undefined
    disabled?: boolean
}

export const LabelledToggle: React.FC<LabelledToggleProps> = ({
    onToggle,
    checked = false,
    on = 'on',
    off = 'off',
    className,
    disabled = false,
    loading = undefined,
}) => (
    <StyledToggle className={className}>
        <StyledToggleCheckbox
            type="checkbox"
            checked={checked}
            onChange={() => onToggle(!checked)}
        />
        <StyledLabelledToggle
            loading={loading ? true : undefined}
            disabled={disabled}
            onClick={(e: React.ChangeEvent<any>) => {
                e.preventDefault()
                if (disabled || loading) return
                onToggle(!checked)
            }}
        >
            <StyledToggleSwitch loading={loading} active={checked}>
                {off}
            </StyledToggleSwitch>
            <StyledToggleSwitch
                loading={loading}
                active={checked}
                primary={true}
            >
                {on}
            </StyledToggleSwitch>
        </StyledLabelledToggle>
    </StyledToggle>
)

LabelledToggle.displayName = 'LabelledToggle'
