import React from 'react'
import {
    BreakpointKeys,
    Breakpoints,
    ContainerWidth,
    ThemeMargins,
} from '../../__styling'
import { BaseCompositionProps } from '../types'
import { StyledDoublet, StyledDoubletColumn } from './Doublet.styled'

export interface DoubletRawProps extends BaseCompositionProps {
    leftContent: React.ReactElement<any>
    rightContent: React.ReactElement<any>
    containerWidth?: ContainerWidth
}

export interface DoubletSpacingProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    horizontalGutters?: keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
}

export type DoubletBreakPointProps = {
    [key in BreakpointKeys]?: DoubletSpacingProps
}

export interface DoubletProps extends DoubletRawProps, DoubletSpacingProps {
    breakpoints?: DoubletBreakPointProps
    rowBreakpoint?: keyof Breakpoints
}

export const Doublet = ({
    leftContent,
    rightContent,
    containerWidth,
    verticalGutters,
    horizontalGutters,
    verticalSpacing,
    horizontalSpacing,
    rowBreakpoint,
}: DoubletProps) => {
    return (
        <StyledDoublet
            containerWidth={containerWidth}
            verticalGutters={verticalGutters}
            horizontalGutters={horizontalGutters}
            verticalSpacing={verticalSpacing}
            horizontalSpacing={horizontalSpacing}
            rowBreakpoint={rowBreakpoint}
        >
            <StyledDoubletColumn rowBreakpoint={rowBreakpoint}>
                {leftContent}
            </StyledDoubletColumn>
            <StyledDoubletColumn rowBreakpoint={rowBreakpoint}>
                {rightContent}
            </StyledDoubletColumn>
        </StyledDoublet>
    )
}
