import * as React from 'react'

export interface GoMatildasLogoProps {
    className?: string
    fill?: string
    width?: number
    height?: number
}

export const GoMatildasLogo = (props: GoMatildasLogoProps) => (
    <svg width={178} height={32} viewBox="0 0 223 40" fill="none" {...props}>
        <title>Go Matildas</title>
        <path fill="#FFDA00" d="M222.457 0H0v40h222.457V0Z" />
        <path
            fill="#003C10"
            fillRule="evenodd"
            d="M47.473 8.562c-7.473 0-12.748 5.542-12.748 13.282 0 5.772 3.975 8.887 9.346 8.887 7.492 0 12.747-5.542 12.747-13.282 0-5.772-3.956-8.887-9.345-8.887Zm-.899 5.982c2.256 0 3.555 1.605 3.555 3.88 0 3.038-1.93 6.058-5.103 6.058-2.255 0-3.554-1.606-3.554-3.88 0-3.039 1.93-6.058 5.102-6.058ZM31.477 26.947l1.452-9.575h-9.44l-.823 5.256h3.04l-.25 1.49c-.515.631-2.102.784-2.828.784-2.293 0-3.383-1.777-3.383-3.975 0-3.02 1.682-6.536 5.008-6.536 1.529 0 2.809.65 3.764 1.892l4.97-3.918C30.921 9.594 28.456 8.6 25.113 8.6c-7.55 0-12.614 5.6-12.614 13.283 0 5.656 3.803 8.829 9.078 8.829 4.166 0 6.727-1.09 9.9-3.765ZM76.465 8.906l.783 11.39 4.224-11.39h8.447l-2.14 21.443h-6.48l1.72-12.767-4.51 12.767h-5.695l-.688-12.04-2.083 12.04h-6.479l4.453-21.443h8.448Zm34.038 21.443-3.67-21.443h-7.32L89.27 30.349h6.88l1.242-3.02h5.638l.306 3.02h7.167Zm-10.913-8.41 2.293-5.599.593 5.6H99.59Zm12.423-7.529.841-5.504h16.015l-.841 5.504h-4.701l-2.446 15.939h-6.613l2.446-15.939h-4.701Zm22.015 15.939 3.287-21.443h-6.612l-3.287 21.443h6.612Zm13.856-21.443-.003.019h-6.572l-3.287 21.443h13.397l.879-5.79h-6.822l2.405-15.653h.003v-.02Zm22.436 18.366c2.427-2.217 3.784-5.6 3.784-10.09 0-6.25-4.453-8.276-9.861-8.276h-7.568l-3.287 21.443h7.721c3.841 0 6.975-1.032 9.211-3.077Zm-2.924-9.059c0 1.777-.401 3.402-1.299 4.549-.803 1.012-2.026 1.662-3.689 1.681h-1.51l1.51-9.727h1.472c1.223 0 2.14.306 2.733.955.516.574.783 1.415.783 2.542Zm22.819-9.307 3.67 21.443h-7.167l-.306-3.02h-5.638l-1.242 3.02h-6.88l10.244-21.443h7.319Zm-4.949 7.434-2.294 5.6h2.886l-.592-5.6Zm19.913 7.683c0 .707-1.07.84-1.528.84-1.644 0-3.918-1.165-5.008-2.312l-3.765 5.313c2.045 1.835 4.587 2.886 7.301 2.886 5.007 0 9.804-2.981 9.804-8.638 0-3.689-3.306-4.874-5.619-5.695-1.223-.44-2.178-.784-2.178-1.338 0-.726 1.07-.841 1.529-.841 1.567 0 3.459 1.013 4.605 2.045l3.249-5.026c-1.567-1.548-4.548-2.58-6.727-2.58-5.007 0-9.403 2.828-9.403 8.39 0 3.68 3.139 4.734 5.412 5.498l.016.005c1.3.44 2.312.784 2.312 1.453Z"
            clipRule="evenodd"
        />
    </svg>
)
