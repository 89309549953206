import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../../..'

export type customStyles = 'pinned-post' | 'milestones'

export interface TruncContainerProps {
    mobileFullWidth?: boolean
    /** Custom styles to be applied to truncating container */
    customStyle?: customStyles
}

export const StyledTruncContainer = styled('div')<TruncContainerProps>(
    ({ theme, customStyle }) => [
        // Overall styling shared between all custom styles & themes
        {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
        },

        // ------------------------------------------------------------------
        // -   M I L E S T O N E S
        // ------------------------------------------------------------------
        // Specific style for the Milestones TruncContainer without themes
        customStyle === 'milestones' && {
            margin: calcRem(theme.margins.md, theme.margins.md * -1),
            backgroundColor: colors.thewest.greyErmine,

            button: {
                marginLeft: theme.margins.md,
            },

            [breakpoint('md')]: {
                margin: calcRem(theme.margins.md, theme.margins.md * -1),
            },
        },
        // Styling for 7N Milestones
        customStyle === 'milestones' &&
            theme.kind === 'sevennews' && {
                button: {
                    marginLeft: theme.margins.sm,
                },
                [breakpoint('md')]: {
                    margin: calcRem(theme.margins.md, theme.margins.sm * -1),
                },
            },
        // Styling for TN Milestones
        customStyle === 'milestones' &&
            theme.kind === 'thenightly' && {
                margin: 0,
                [breakpoint('md')]: {
                    margin: 0,
                },
            },
        // Styling for 7N and TN Milestones
        customStyle === 'milestones' &&
            (theme.kind === 'sevennews' || theme.kind === 'thenightly') && {
                backgroundColor: colors.white,
            },

        // ------------------------------------------------------------------
        // -   P I N N E D   P O S T S
        // ------------------------------------------------------------------
        // Specific style for the Pinned Posts TruncContainer without themes
        customStyle === 'pinned-post' && {
            margin: calcRem(0, 0, theme.margins.sm),
            boxShadow: 'none',

            [breakpoint('sm')]: {
                margin: theme.margins.md,

                /* Children Container */
                '& > div:first-of-type': {
                    margin: calcRem(
                        theme.margins.md * -1,
                        theme.margins.md * -1,
                        0,
                        theme.margins.md * -1,
                    ),
                },
            },
            [breakpoint('md')]: {
                backgroundColor: colors.white,
                boxShadow: 'none',
                padding: calcRem(theme.margins.sm, 0),
                margin: 0,
                borderBottom: undefined,

                /* Children Container */
                '& > div:first-of-type': {
                    margin: calcRem(
                        0,
                        theme.margins.md * -1,
                        0,
                        theme.margins.md * -1,
                    ),
                    padding: calcRem(0, theme.margins.md),
                },
            },
        },
        // Styling for 7N Pinned Post
        customStyle === 'pinned-post' &&
            theme.kind === 'sevennews' && {
                margin: calcRem(theme.margins.md, 0, theme.margins.lg),

                [breakpoint('md')]: {
                    backgroundColor: (theme.colors.palette as any).greyLight,
                    padding: 'inherit',
                    margin: calcRem(theme.margins.md, theme.margins.sm),
                    borderBottom: 'none',
                },
            },
        // Styling for TN Pinned Post
        customStyle === 'pinned-post' &&
            theme.kind === 'thenightly' && {
                backgroundColor: '#FBF8FC',
                borderRadius: calcRem(8),
                margin: 0,

                [breakpoint('sm')]: {
                    margin: 0,

                    /* Children Container */
                    '& > div:first-of-type': {
                        margin: 0,
                    },
                },
                [breakpoint('md')]: {
                    padding: 0,
                    backgroundColor: '#FBF8FC',
                    margin: 0,

                    /* Children Container */
                    '& > div:first-of-type': {
                        padding: 0,
                    },
                },
            },
        // Styling for TW and PN Pinned Post
        customStyle === 'pinned-post' &&
            (theme.kind === 'thewest' || theme.kind === 'perthnow') && {
                boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.08)`,

                [breakpoint('md')]: {
                    borderBottom: `1px solid ${colors.thewest.greyQuokka}`,
                },
            },
    ],
)
interface StyledTruncChildrenProps {
    truncated: boolean
    /** Max height on [xs - Mobile, sm - Tablet, md - Desktop] px unit only*/
    maxHeights: {
        xs: number
        sm: number
        md: number
    }
}

export const StyledChildrenContainer = styled('div')<StyledTruncChildrenProps>(
    ({ theme, truncated, maxHeights }) => {
        return {
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            maxHeight: truncated ? maxHeights.xs : '',

            [breakpoint('sm')]: {
                maxHeight: truncated ? maxHeights.sm : '',
            },

            [breakpoint('md')]: {
                maxHeight: truncated ? maxHeights.md : '',
            },
        }
    },
)

export const StyledButtonContainer = styled('div')<{
    isVisible: boolean
    truncated: boolean
    customStyle?: customStyles
}>(({ theme, isVisible, truncated, customStyle }) => {
    const is7News = theme.kind === 'sevennews'
    let backgroundColor: string

    switch (customStyle) {
        case 'milestones':
            backgroundColor = is7News
                ? truncated
                    ? colors.sevennews.greyErmine
                    : colors.sevennews.white
                : colors.thewest.greyErmine
            break
        case 'pinned-post':
        default:
            backgroundColor = is7News
                ? truncated
                    ? `linear-gradient(to top, ${colors.sevennews.gray98}, ${colors.sevennews.white})`
                    : colors.sevennews.gray98
                : theme.colors.palette.white
            break
    }

    return {
        display: isVisible ? 'block' : 'none',
        background: backgroundColor,
        padding: theme.margins.md,
        position: 'relative',

        '&::before': {
            background: `linear-gradient(transparent, ${backgroundColor})`,
            display: truncated ? 'block' : 'none',
            position: 'absolute',
            height: '120px',
            margin: 'auto',
            content: `''`,
            left: '0px',
            right: '0px',
            top: '-120px',
            pointerEvents: 'none',
            zIndex: 12,
        },
    }
})

export const StyledShowMoreButton = styled('button')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    height: calcRem(32),
    minWidth: calcRem(100),
    cursor: 'pointer',
    border: `1px solid ${theme.colors.brand}`,
    borderRadius: calcRem(2),

    zIndex: 13,
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.brand,
    backgroundColor: 'transparent',
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(13),
    fontWeight: 700,

    /* Increase size of +/- symbol  */
    '& > span::first-letter': {
        fontSize: calcRem(16),
    },

    '&:after': {
        display: 'none',
        border: `1px solid ${theme.colors.palette.black}`,
        content: `''`,
        position: 'absolute',
        top: -3,
        left: -3,
        width: 'calc(100% + 6px)',
        height: 'calc(100% + 6px)',
        borderRadius: calcRem(4),
    },

    '&:hover': {
        backgroundColor: `rgba(4, 61, 226, 0.04)`,
    },

    '&:focus': {
        outline: 'none',

        '&:after': {
            display: 'block',
        },
    },
}))
