import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Link } from 'react-router-dom'
import { IconChevronRight } from '../../icons/IconChevronRight/IconChevronRight'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { Breakpoints, getComponentTheme } from '../../__styling'
import { easing } from '../../__styling/settings/easing'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden, visuallyhiddenOff } from '../../__styling/style-mixins'
import { breakpointSwitch } from '../../__styling/style-functions/breakpointState'
import { IconArrowCircle } from '../../icons'

export interface SectionHeaderWrapperProps {
    hasBottomMargin?: boolean
    hasTopMargin?: boolean
    invisibleHeaderBreakpoint?: keyof Breakpoints
}

export const SectionHeaderContainer = styled.div<SectionHeaderWrapperProps>(
    ({ theme, invisibleHeaderBreakpoint, hasBottomMargin, hasTopMargin }) => {
        const sectionHeaderTheme = getComponentTheme(theme, 'sectionHeader')

        return [
            {
                display: 'flex',
                flex: '1 0 auto',
                flexWrap: 'wrap',
                alignItems: 'center',
                maxWidth: '100%',
                minHeight: sectionHeaderTheme.minHeight,
                marginBottom: hasBottomMargin
                    ? calcRem(theme.margins.md)
                    : undefined,
                marginTop: hasTopMargin ? calcRem(theme.margins.lg) : undefined,

                transition: 'margin 0.25s, padding 0.25s',

                background: sectionHeaderTheme.background,
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
            },
            invisibleHeaderBreakpoint !== undefined && {
                ...visuallyhidden,

                [breakpoint(invisibleHeaderBreakpoint || 'md')]: {
                    ...visuallyhiddenOff,
                },
            },
            sectionHeaderTheme.breakpointState &&
                breakpointSwitch(
                    sectionHeaderTheme.breakpointState,
                    (styles) => styles,
                ),
        ]
    },
)

export const StyledChevronRight = styled(IconChevronRight)(({ theme }) => {
    const chevronTheme = getComponentTheme(theme, 'sectionHeader').chevron
    return {
        width: calcEm(16),
        height: calcEm(16),
        fill: chevronTheme?.color,
    }
})

export const StyledCircleChevronRight = styled(IconArrowCircle)(({ theme }) => {
    const chevronTheme = getComponentTheme(theme, 'sectionHeader').chevron
    return {
        width: calcEm(16),
        height: calcEm(16),
        fill: chevronTheme?.color,
    }
})

export interface StyledHeadingLinkProps {
    colorOverride?: string
}

export const Heading = styled.h2<StyledHeadingLinkProps>(
    ({ theme, colorOverride }) => {
        const headingTheme = getComponentTheme(theme, 'sectionHeader').heading
        const sectionTheme = headingTheme?.section

        return [
            {
                display: 'flex',
                flexDirection: headingTheme?.flexDirection,
                gap: headingTheme?.gap,
                margin: 0,
                textTransform: headingTheme?.textTransform,
                position: 'relative',
                color: colorOverride ?? headingTheme?.textColor,
            },
            headingTheme?.textType &&
                breakpointSwitch(headingTheme.textType, (type) =>
                    textMixin(theme, type),
                ),
            sectionTheme && {
                p: {
                    color: colorOverride ?? headingTheme?.textColor,
                    fontSize: sectionTheme.fontSize,
                    fontWeight: sectionTheme.fontWeight,
                    lineHeight: sectionTheme.lineHeight,
                    textTransform: sectionTheme.textTransform,
                    margin: 0,
                    letterSpacing: 'normal',
                },
            },
            sectionTheme &&
                sectionTheme &&
                breakpointSwitch(sectionTheme.display, (display) => ({
                    p: { display },
                })),
        ]
    },
)

export const Divider = styled.span(({ theme }) => {
    const dividerTheme = getComponentTheme(theme, 'sectionHeader').divider

    return {
        width: dividerTheme?.width,
        height: dividerTheme?.height,
        backgroundColor: dividerTheme?.color,
    }
})

export const HeadingWrapper = styled.div(({ theme }) => {
    const headingTheme = getComponentTheme(theme, 'sectionHeader').heading
    return {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        gap: calcRem(4),
        padding: headingTheme?.padding,
    }
})

export const HeadingLink = styled(WebLink)(({ theme }) => {
    const headingTheme = getComponentTheme(theme, 'sectionHeader').heading
    const textDecorationColor =
        theme.kind === 'thenightly' ? headingTheme?.textColor : undefined

    return {
        textDecoration: 'none',
        zIndex: zIndex.common.link,
        cursor: 'pointer',

        transition: `background-color ${easing.easeOut.fast}`,

        '&:focus': {
            textDecoration: 'underline',
            textDecorationColor: textDecorationColor,
        },

        '&:hover': {
            background: headingTheme?.hover?.background,
        },

        '&:active': {
            background: headingTheme?.active?.background,
            textDecorationColor: textDecorationColor,
        },
    }
})

export const NavLinkList = styled.ul(({ theme }) => {
    const navTheme = getComponentTheme(theme, 'sectionHeader').nav
    return {
        display: 'none',

        [breakpoint('md')]: {
            listStyle: 'none',
            padding: navTheme?.padding ?? 0,
            margin: 0,
            width: '100%',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            display: 'flex',
            textTransform: 'uppercase',
            gap: calcRem(4),
        },
    }
})

export const ReadMoreWrapper = styled.div(({ theme }) => {
    const readMoreTheme = getComponentTheme(theme, 'sectionHeader').readMore
    return {
        display: 'flex',
        alignItems: 'center',
        gap: readMoreTheme?.gap,
    }
})

export const ReadMoreText = styled.span(({ theme }) => {
    const readMoreTheme = getComponentTheme(theme, 'sectionHeader').readMore
    return [
        {
            color: readMoreTheme?.textColor,
        },
        readMoreTheme?.textType && textMixin(theme, readMoreTheme.textType),
    ]
})

export const NavLinkWrapper = styled.li({
    width: 'auto',
    flex: '0 1 auto',
    textAlign: 'center',
    textTransform: 'uppercase',
})

export const NavLinkDivider = styled.span(({ theme }) => {
    const dividerTheme = getComponentTheme(theme, 'sectionHeader').nav?.divider
    return {
        height: dividerTheme?.height,
        width: calcRem(1),
        background: dividerTheme?.color,
    }
})
export interface NavLinkProps {
    colorOverride?: string
}

export const LocalNavLink = styled(Link)<NavLinkProps>(
    ({ theme, colorOverride }) => {
        const linkTheme = getComponentTheme(theme, 'sectionHeader').nav?.link

        return [
            {
                display: 'block',
                whiteSpace: 'nowrap',
                position: 'relative',
                textDecoration: 'none',
                height: '100%',
                padding: linkTheme?.padding,
                zIndex: zIndex.common.link,

                '&:focus': {
                    textDecoration: 'underline',
                    outline: 'none',
                },

                color: colorOverride ?? linkTheme?.textColor,

                '&:hover': {
                    background: linkTheme?.hover?.background,
                },

                '&:active': {
                    background: linkTheme?.active?.background,
                },

                transition: `background-color ${easing.easeOut.fast}`,
            },
            linkTheme?.textType && textMixin(theme, linkTheme?.textType),
        ]
    },
)

export const WebNavLink = LocalNavLink.withComponent(WebLink)

export const SponsorWrapper = styled.div<{
    hideOnMobile?: boolean
}>(({ hideOnMobile }) => ({
    lineHeight: 1,
    display: hideOnMobile ? 'none' : undefined, // TODO hide on mobile on perth now

    [breakpoint('xs')]: {
        display: 'block',
        marginLeft: 'auto',
    },

    [breakpointMax('xs')]: {
        paddingTop: calcRem(9),
        flexBasis: '100%',
    },
}))
