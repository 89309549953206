import { createRenderTarget } from '../../render-target'
import { WebPullQuote } from './PullQuote.web'

export interface PullquoteProps {
    text: string
    attribution?: string
}

export const Pullquote = createRenderTarget<PullquoteProps>('PullQuote', {
    web: WebPullQuote,
    amp: WebPullQuote,
    rss: null,
    preview: WebPullQuote,
    app: WebPullQuote,
})
Pullquote.displayName = 'Pullquote'
