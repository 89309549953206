import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { FillProperty } from 'csstype'
import { IconChevronRightAlt } from '../../icons/IconChevronRightAlt/IconChevronRightAlt'
import { IconQuoteLeft } from '../../icons/IconQuoteLeft/IconQuoteLeft'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { getComponentTheme } from '../../__styling'

export interface StyledPullquoteProps {
    attribution?: string
}

export const StyledPullquote = styled('blockquote')<StyledPullquoteProps>(
    ({ theme }) => ({
        ...breakWords,
        clear: 'both',
        position: 'relative',
        transition: `font-size ${easing.easeOut.fast}`,
        fontFamily: theme.fonts.pullQuote.text,
        // on perth now, we have to take into account the width of the icon @ minimum size (40px)
        // sevennews we need to take into account the chevron width (19px)
        width: themedValue(theme, {
            perthnow: `calc(100% - 40px)`,
            sevennews: `calc(100% - 19px)`,
            fallback: '100%',
        }),
        letterSpacing: theme.kind === 'thenightly' ? '-0.48px' : undefined,
        fontStyle: themedValue(theme, {
            sevennews: 'normal',
            thenightly: 'normal',
            fallback: 'italic',
        }),
        fontSize: themedValue(theme, {
            thewest: calcRem(26),
            thenightly: calcRem(32),
            fallback: calcRem(22),
        }),
        fontWeight: themedValue(theme, {
            sevennews: 700,
            thenightly: 600,
            fallback: 400,
        }),
        lineHeight: themedValue(theme, {
            thewest: 1.25,
            perthnow: 1.308,
            sevennews: 1,
            thenightly: '120%',
            fallback: undefined,
        }),
        margin:
            theme.kind === 'thenightly'
                ? `${calcRem(theme.margins.lg)} 0`
                : `${calcRem(theme.margins.lg)} auto`,
        maxWidth: themedValue(theme, {
            thewest: calcRem(597),
            thenightly: undefined,
            perthnow: undefined,
            sevennews: calcRem(577),
            fallback: undefined,
        }),
        transform: themedValue(theme, {
            thewest: undefined,
            thenightly: undefined, // Padding seems like a better experience?
            fallback: `translateX(${calcRem(theme.margins.md)})`,
        }),
        paddingLeft: themedValue(theme, {
            thenightly: calcRem(theme.margins.md),
            fallback: undefined,
        }),

        [breakpoint('sm')]: {
            maxWidth: themedValue(theme, {
                thewest: undefined,
                sevennews: calcRem(304),
                perthnow: calcRem(435),
                fallback: undefined,
            }),
            fontSize: themedValue(theme, {
                thewest: calcRem(28),
                thenightly: calcRem(40),
                fallback: calcRem(26),
            }),
            lineHeight: themedValue(theme, {
                thewest: 1.28,
                perthnow: 1.308,
                sevennews: 1,
                fallback: undefined,
            }),
            transform: themedValue(theme, {
                thewest: undefined,
                fallback: `none`,
            }),
            padding: themedValue(theme, {
                thenightly: 0,
                fallback: undefined,
            }),
        },

        [breakpoint('md')]: {
            maxWidth: themedValue(theme, {
                thewest: undefined,
                thenightly: undefined,
                fallback: calcRem(435),
            }),
        },
    }),
)

export const StyledParagraph = styled('p')(({ theme }) => ({
    margin: themedValue(theme, {
        thewest: calcRem(0, 0, 0, metrics.thewest.margins.lg),
        fallback: 0,
    }),
    fontFamily: themedValue(theme, {
        sevennews: 'Roboto Condensed',
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(24),
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        sevennews: calcRem(24),
        fallback: undefined,
    }),
    padding: themedValue(theme, {
        thenightly: calcRem(metrics.thenightly.margins.lg, 0, 0, 0),
        fallback: undefined,
    }),

    '& span': {
        color: colors.sevennews.red,
    },

    '& a': {
        color: themedValue(theme, {
            thenightly: tokens.thenightly.colors.actions.link.default,
            fallback: undefined,
        }),
    },
}))

export const StyledFooter = styled('footer')(({ theme }) => ({
    margin: themedValue(theme, {
        thewest: calcRem(0, 0, 0, metrics.thewest.margins.lg),
        fallback: 0,
    }),
}))
StyledFooter.displayName = 'StyledFooter'

// Using only for the west at the moment
export const StyledLine = styled('div')(({ theme }) => ({
    width: '8px',
    height: 'calc(100% - 2px)',
    position: 'absolute',
    top: calcRem(2),
    background: theme.sectionValues.primaryColor,
}))

export const Attribution = styled('cite')(({ theme }) => ({
    display: 'block',
    fontStyle: 'normal',
    lineHeight: 1,
    paddingTop: calcRem(metrics.globalPadding / 1.5),
    fontFamily: theme.fonts.pullQuote.attribution,
    fontSize: themedValue(theme, {
        thewest: calcRem(15),
        perthnow: calcRem(14),
        sevennews: calcRem(13),
        thenightly: calcRem(24),
        fallback: undefined,
    }),
    color: themedValue(theme, {
        thewest: colors.thewest.greyGorilla,
        thenightly: theme.colors.text.secondary,
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        thewest: undefined,
        perthnow: 700,
        sevennews: 400,
        thenightly: 400,
        fallback: undefined,
    }),
}))

// Perthnow only
export const Icon = styled(IconQuoteLeft)({
    width: calcRem(30),
    height: calcRem(18),
    display: 'block',
    verticalAlign: 'middle',
    float: 'left',
    marginRight: calcRem(18),
    fill: colors.perthnow.redRuby,
    position: 'absolute',
    top: 0,
    transform: 'translateX(-100%) translateX(-10px)',

    [breakpoint('sm')]: {
        width: calcRem(54),
        height: calcRem(33),
        marginRight: calcRem(28),
        transform: 'translateX(-100%) translateX(-20px)',
    },
})

export const NightlyIcon = styled(IconQuoteLeft)(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'pullQuote').icon
    return {
        width: calcRem(19),
        height: calcRem(16),
        display: 'block',
        verticalAlign: 'middle',
        float: 'left',
        marginRight: calcRem(18),
        fill: iconTheme.fill,
        position: 'absolute',
        top: 0,

        [breakpoint('sm')]: {
            width: calcRem(19),
            height: calcRem(16),
        },
    }
})

// For SevenNews
export const Chevron = styled(IconChevronRightAlt)(({ theme }) => ({
    display: 'block',
    position: 'absolute',
    transform: 'translateX(-100%)',
    float: 'left',
    width: calcRem(25),
    height: calcRem(25),
    paddingRight: calcRem(theme.margins.xs),
    top: -2,

    // fill is on Array.fill, and conflicts with
    fill: themedValue(theme, {
        sevennews: (section) => section.sectionHeader.element.primaryColor,
        fallback: colors.sevennews.red,
    }) as FillProperty,

    [breakpoint('sm')]: {
        width: calcRem(50),
        height: calcRem(33),
        transform: 'translateX(-100%) translateX(10px)',
    },
}))
