import { layout } from '../../App.routing'

export const educationSegment = layout.component({
    type: 'uniform',
    props: {
        section: 'news',
        verticalSpacing: 'xl',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
        ],

        sectionHeader: {
            heading: 'Education',
            headingUrl: '/news/education',
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'education',
            offset: 0,
            pageSize: 4,
        },
    },
})
