import {
    CardEvents,
    createCardClickedEvent,
    MarketingRedirectTileCard,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledCardTakeover,
    StyledCardTakeoverImg,
    StyledCardTakeoverLink,
    StyledCardTakeoverPicture,
} from '../../cards/CardTakeover/CardTakeover.styled'

interface CardTakeoverProps {
    item: MarketingRedirectTileCard
    imageSrc: string
    imageAlt: string
    linkUrl: string
    backgroundColor?: string
    cardNumber: number
    onEvent: (event: CardEvents) => void
}

export const CardTakeover: React.FC<CardTakeoverProps> = (props) => {
    const { imageSrc, imageAlt, linkUrl, backgroundColor, onEvent } = props

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        onEvent(
            createCardClickedEvent(
                onEvent,
                props.item,
                'InternalCardTakeover',
                'PromotionalCard',
                props.cardNumber,
            ),
        )
    }

    return (
        <StyledCardTakeover backgroundColor={backgroundColor}>
            <StyledCardTakeoverLink to={linkUrl} onClick={linkClicked}>
                <StyledCardTakeoverPicture>
                    <StyledCardTakeoverImg src={imageSrc} alt={imageAlt} />
                </StyledCardTakeoverPicture>
            </StyledCardTakeoverLink>
        </StyledCardTakeover>
        // Safe link
    )
}
