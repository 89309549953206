import H from 'history'
import React from 'react'
import { useLocation } from 'react-router'
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom'
import {
    isAnchorLink,
    isExternalLink,
    isNonHttpLink,
    searchCrawlerMastheadWhitelist,
} from '../helpers/link-utils'

export interface WebLinkProps extends LinkProps {
    openExternalInNewWindow?: boolean
    openInternalInNewWindow?: boolean
    forceInternalSSR?: boolean
}
export interface WebNavLinkProps extends NavLinkProps {
    openExternalInNewWindow?: boolean
    forceInternalSSR?: boolean
}

function getUrl(
    to:
        | string
        | H.LocationDescriptor
        | ((location: H.Location) => H.LocationDescriptor),
    location: H.Location,
) {
    let current = to

    if (typeof current === 'function') {
        current = current(location)
    }

    if (typeof current === 'string') {
        return current
    }

    return current.pathname
}

/**
 * Wrapper around internal and external links
 * - internal links use react-router <Link>
 * - external links use <a> (absolute url or rel="external")
 */
export const WebLink: React.FC<WebLinkProps> = (props) => {
    const { to } = props

    const location = useLocation()

    const url = typeof to === 'string' && to

    const isExternalHttp = url && isExternalLink(url, props.rel)
    const isExternal =
        url && (isExternalHttp || isAnchorLink(url) || isNonHttpLink(url))
    const isCrawlerWhitelisted = url && searchCrawlerMastheadWhitelist(url)

    const externalInNewWindow = props.openExternalInNewWindow !== false
    const internalInNewWindow = props.openInternalInNewWindow

    if (isExternal || internalInNewWindow || props.forceInternalSSR) {
        /**
         * If you explicity mark a nav item as openExternalInNewWindow = false, it won't
         * matter if its an external link, it will stay within the window
         * InternalInNewWindow will force an internal link to open in a new tab/window
         */
        const target = props.forceInternalSSR
            ? '_self'
            : (isExternalHttp && externalInNewWindow) || internalInNewWindow
            ? '_blank'
            : undefined
        const rel =
            target && target === '_blank' && !isCrawlerWhitelisted
                ? 'noopener'
                : undefined

        const {
            openExternalInNewWindow: _,
            openInternalInNewWindow: __,
            to: ___,
            replace: ____,
            style: _____,
            ...justLinkProps
        } = props as WebLinkProps
        const aProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
            ...justLinkProps,
            href: getUrl(to, location),
            target,
            ...(!props.rel && { rel }),
        }

        return <a {...aProps} />
    }

    const { openExternalInNewWindow, ...linkProps } = props

    return <Link {...linkProps} />
}
WebLink.displayName = 'WebLink'

/**
 * Wrapper around internal and external links
 * - internal links use react-router <Link>
 * - external links use <a> (absolute url or rel="external")
 */
export const WebNavLink: React.FC<WebNavLinkProps> = (props) => {
    const { to, forceInternalSSR } = props
    const url = typeof to === 'string' && to
    const isExternal =
        url && (isExternalLink(url, props.rel) || isNonHttpLink(url))
    const isCrawlerWhitelisted = url && searchCrawlerMastheadWhitelist(url)
    const location: H.Location = useLocation()

    if (isExternal || forceInternalSSR) {
        const target = forceInternalSSR
            ? '_self'
            : props.openExternalInNewWindow
            ? '_blank'
            : undefined
        const rel =
            target &&
            (target === '_blank' || target === '_self') &&
            !isCrawlerWhitelisted
                ? 'noopener'
                : undefined
        const {
            openExternalInNewWindow: _,
            to: __,
            replace: ___,
            style: ____,
            activeClassName: _____,
            isActive: ______,
            ...justLinkProps
        } = props

        const aProps = {
            ...justLinkProps,
            href: getUrl(to, location),
            target,
            ...(!props.rel && { rel }),
        }

        return <a {...aProps} />
    }

    const { openExternalInNewWindow, ...linkProps } = props

    return <NavLink {...linkProps} />
}
WebNavLink.displayName = 'WebNavLink'
