import styled from '@emotion/styled'
import {
    PerthNowColourMap,
    TheWestColourMap,
    tokens,
} from '@news-mono/design-tokens'
import { breakpoint, calcRem, themedValue } from '../__styling'
import { FederalElectionWidgetCollectionProps } from './FederalElectionWidgetCollection'

export const StyledCollectionContainer = styled(
    'div',
)<FederalElectionWidgetCollectionProps>(
    ({ theme, layout }) =>
        layout === 'horizontal' && {
            marginTop: theme.margins.xs,
            paddingBottom: theme.margins.md + theme.margins.xs,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            [breakpoint('sm')]: {
                display: 'grid',
                gridGap: theme.margins.xl,
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridTemplateRows: '1fr 1.2fr',
                'div:nth-child(1)': {
                    gridArea: '1 / 1 / 1 / 5',
                },
                'div:nth-child(2)': {
                    gridArea: '2 / 1 / 2 / 4',
                },
                'div:nth-child(3)': {
                    gridArea: '2 / 4 / 2 / 5',
                },
            },
            [breakpoint('md')]: {
                gridTemplateColumns: '1.5fr 1.2fr 1fr',
                gridTemplateRows: '1fr',
                gridGap: theme.margins.lg * 2,
                'div:nth-child(1)': {
                    gridArea: 'auto',
                },
                'div:nth-child(2)': {
                    gridArea: 'auto',
                },
                'div:nth-child(3)': {
                    gridArea: 'auto',
                },
            },
        },

    ({ theme, layout }) =>
        layout === 'vertical' && {
            gridGap: theme.margins.lg,
        },
)

export const StyledHorizontalGraphContainer = styled('div')(({ theme }) => ({
    [breakpoint('md')]: {
        paddingRight: themedValue(theme, {
            thewest: calcRem(theme.margins.xxl),
            perthnow: calcRem(theme.margins.md),
            fallback: undefined,
        }),

        borderRight: `1px solid ${theme.colors.borders.primary}`,
    },
}))

export const StyledHorseshoeContainer = styled('div')(({ theme }) => ({
    '& > div': {
        width: '100%',
        '& > div': {
            '& > p': {
                fontSize: calcRem(40),
            },
            '& > h3': {
                fontSize: calcRem(18),
            },
        },
    },

    [breakpoint('sm')]: {
        borderRight: `1px solid ${theme.colors.borders.primary}`,
        paddingRight: themedValue(theme, {
            thewest: calcRem(24),
            perthnow: calcRem(theme.margins.md),
            fallback: undefined,
        }),
    },

    [breakpoint('md')]: {
        paddingRight: themedValue(theme, {
            thewest: calcRem(theme.margins.xxl),
            perthnow: calcRem(theme.margins.md),
            fallback: undefined,
        }),
    },
}))

export const StyledVotesCountedContainer = styled('div')(() => ({
    width: '294px',
    margin: 'auto',
}))

export const StyledVotesCountedWidget = styled('div')(({ theme }) => ({
    marginBottom: themedValue(theme, {
        thewest: calcRem(theme.margins.lg),
        fallback: undefined,
    }),

    '& > div': {
        display: 'grid',
        gridTemplateColumns: '1fr 0.7fr',
        gridTemplateRows: 'repeat(2, 1fr)',
        marginTop: 0,
        [breakpoint('sm')]: {
            marginBottom: calcRem(75),
            gridTemplateColumns: 'repeat(3, 1fr)',
            marginTop: 'auto',
        },
        'div:nth-child(1)': {
            gridColumn: '1/3',
            gridRow: '1/3',
        },
        'div:nth-child(2)': {
            gridColumn: '2/4',
            gridRow: '1/3',
        },
    },
}))

// Need to do a type assertion to get the federal election color to appear
const palette = tokens['thewest'].colors as TheWestColourMap
const federalElectionSilver = palette.palette.silver

export const StyledElectionTextContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.margins.xxl,
    marginTop: calcRem(31),

    'span:nth-of-type(1)': {
        fontFamily: theme.fonts.sansSerif,
        lineHeight: calcRem(theme.margins.xxl),
        backgroundImage: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        backgroundClip: 'text',
        backgroundSize: '100%',
        textFillColor: 'transparent',
        fontWeight: 700,
        fontSize: themedValue(theme, {
            thewest: calcRem(theme.margins.xxl),
            perthnow: calcRem(theme.margins.xl),
            fallback: undefined,
        }),
    },

    [breakpoint('md')]: {
        marginTop: calcRem(0),
    },
}))

export const StyledElectionResultsText = styled('span')(({ theme }) => ({
    color: federalElectionSilver,
    fontSize: themedValue(theme, {
        thewest: calcRem(theme.margins.xxl),
        perthnow: calcRem(theme.margins.xl),
        fallback: undefined,
    }),
    fontFamily: theme.fonts.sansSerif,
    lineHeight: calcRem(theme.margins.xxl),
    fontWeight: 400,
    position: 'relative',

    '&::after': {
        content: `''`,
        position: 'absolute',
        left: 0,
        backgroundColor: federalElectionSilver,
        bottom: calcRem(-8),
        width: calcRem(80),
        height: calcRem(2),
    },
}))

export const StyledSeatsCountedTextOuterContainer = styled('div')(
    ({ theme }) => ({
        fontFamily: theme.fonts.sansSerif,
        background: themedValue(theme, {
            thewest: theme.colors.borders.primary,
            fallback: undefined,
        }),
        paddingBottom: calcRem(1),
    }),
)

const perthNowPalette = tokens['perthnow'].colors as PerthNowColourMap
const federalElectionPerthNowUnderline =
    perthNowPalette.palette.federalElection.federalElectionBorders

export const StyledSeatsCountedTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: themedValue(theme, {
        thewest: theme.colors.palette.white,
        fallback: undefined,
    }),

    borderBottom: `1px solid ${federalElectionPerthNowUnderline}`,

    'span:nth-of-type(1)': {
        fontWeight: 700,
    },
}))

export const StyledHorseShoeWidgetTextOuterContainer = styled('div')(
    ({ theme }) => ({
        background: themedValue(theme, {
            thewest: theme.colors.borders.primary,
            fallback: undefined,
        }),
        fontFamily: theme.fonts.sansSerif,
        paddingBottom: calcRem(1),
        marginBottom: calcRem(18),
    }),
)

export const StyledHorseShoeWidgetTextInnerContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        backgroundColor: themedValue(theme, {
            thewest: theme.colors.palette.white,
            fallback: undefined,
        }),

        borderBottom: `1px solid ${federalElectionPerthNowUnderline}`,

        'span:nth-of-type(1)': {
            fontWeight: 400,
        },
    }),
)
