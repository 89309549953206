import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'

const socialImage = require('./assets/CITA-LandingPage-Social-Image.jpg')

function getSocialImageMeta(src: string, height: string, width: string) {
    return [
        {
            property: 'og:image',
            content: src,
        },
        { name: 'twitter:image', content: src },
        {
            property: 'og:image:height',
            content: height,
        },
        {
            property: 'og:image:width',
            content: width,
        },
        {
            property: 'og:image:alt',
            content: 'A promotion image for the Court in the Act Podcast.',
        },
        {
            property: 'twitter:image:alt',
            content: 'A promotion image for the Court in the Act Podcast.',
        },
    ]
}

export const courtInTheAct: StaticRoutes<TheWestSection> = {
    '/podcasts/court-in-the-act': ({
        getAdTargeting,
        config,
        store,
        resolution,
    }) => {
        const canonicalUrl = config.publicUrl + '/podcasts/court-in-the-act'

        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'court-in-the-act',
        )

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'podcasts/court-in-the-act',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                '',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        if (!enableRoute) {
            return null
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta(socialImage, '630', '1200'),
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting: getAdTargeting('home', 'default', 'court-in-the-act'),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'zeus',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                        hasBackgroundFill: true,
                        stretchSelf: true,
                    },
                    contentAreas: {
                        main: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    verticalSpacing: 'md',
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'court-in-the-act-page',
                                            props: {},
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'iframe',
                                props: {
                                    link: 'https://omny.fm/shows/court-in-the-act/playlists/podcast/embed?style=artwork&image=1&share=1&download=1&description=1&follow=1&playlistimages=1&playlistshare=1&foreground=f5f5f5&background=0a0c20&highlight=c63723',
                                    height: 800,
                                    marginBottom: 'lg',
                                    title: 'Court in the Act podcast episodes',
                                },
                            }),
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'leaderboard-one',
                                        size: 'leaderboard768Above',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'mrec-one',
                                        size: 'below768Mrec',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: 'Court In The Act',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['podcasts/court-in-the-act'],
                                        includeSubTopics: true,
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                            }),
                            layout.component({
                                type: 'link-banner',
                                props: {
                                    url: '/manage-email-preferences',
                                    message:
                                        'Get the latest news from thewest.com.au in your inbox.',
                                    cta: 'Sign up for our emails',
                                    verticalGutters: ['md', 'lg'],
                                },
                            }),
                            layout.component({
                                type: 'related-content',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'home',
                                        offset: 0,
                                        pageSize: 3,
                                    },
                                },
                            }),
                        ],
                        sidebar: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'sidebar-top',
                                        size: 'desktopMrecHalfPage',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset: 350,
                                    fillContainer: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                padding: [
                                                    0,
                                                    0,
                                                    metrics.thewest.margins.md,
                                                    0,
                                                ],
                                                slot: {
                                                    id: 'sidebar-middle',
                                                    size: 'desktopMrec',
                                                    pageType: 'static',
                                                },
                                                adType: 'inline',
                                            },
                                        }),

                                        layout.component({
                                            type: 'juliet',
                                            props: {
                                                cardType: {
                                                    type: 'landscape',
                                                    format: 'landscape-smallImage',
                                                },
                                                removeHorizontalGutters: true,
                                                kickerMode: 'hidden',
                                                contentIsTangential: true,
                                                sectionHeader: {
                                                    heading: 'Top Stories',
                                                },
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'home',
                                                    offset: 0,
                                                    pageSize: 3,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
