import styled from '@emotion/styled'
import {
    breakpoint,
    BreakpointState,
    breakpointSwitch,
    calcRem,
    getComponentTheme,
    metrics,
    ThemeConfig,
    TheNightlyThemeConfig,
} from '../../__styling'
import {
    StyledInlineWrapper,
    StyledMediaProps,
} from '../Landscape/Landscape.styled'
import { ImageRatio } from '@news-mono/web-common'
import { CardMedia } from '../CardMedia/CardMedia'
import { EditorialType, FundingType } from '../../templates'
import { tokens } from '@news-mono/design-tokens'
import { CardHeadline } from '../CardText/CardHeadline'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { StyledLink } from '../../cards/CardText/CardTextInteractions.styled'

export const StyledHeader = styled.div<{ contentPosition: 'top' | 'center' }>(
    ({ contentPosition }) => ({
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignSelf: 'stretch',
        flex: `1 2 ${calcRem(120)}`,
        justifyContent: contentPosition === 'top' ? 'flex-start' : 'center',
    }),
)
StyledHeader.displayName = 'LandscapeHeaderWrapper'

export const StyledLandscapeNumber = styled.div(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'landscape').number
    return [
        {
            color: iconTheme.color,
            fontWeight: 400,
            paddingRight: calcRem(16),
            lineHeight: '105%',
            minWidth: calcRem(40.5),
        },
        breakpointSwitch(iconTheme.breakpointState, ({ fontSize }) => ({
            fontSize,
        })),
    ]
})

export const StyledHeadline = styled(CardHeadline)<{
    renderLightHeadline?: boolean
}>(
    ({ renderLightHeadline = false, theme }) =>
        textMixin<TheNightlyThemeConfig>(
            theme,
            renderLightHeadline ? 'item-small-light' : 'item-small',
        ),
    {
        margin: 0,
        flex: 'none', // overide default headline flexbox from CardText component
        width: '100%',
    },
)
StyledHeadline.displayName = 'LandscapeHeadline'

export const StyledLiveHeadline = styled(StyledHeadline)({
    position: 'relative',
    top: calcRem(-3),
})
StyledLiveHeadline.displayName = 'LandscapeLiveHeadline'

export const StyledLiveClassificationContainer = styled('div')({
    display: 'inline-flex',
    gap: calcRem(4),

    svg: {
        alignSelf: 'center',
        maxHeight: calcRem(16),
        maxWidth: calcRem(16),
    },

    span: {
        alignSelf: 'center',
    },
})

export const StyledLiveInlineWraper = styled(StyledInlineWrapper)({
    paddingTop: 0,
})

export const StyledClassificationLabel = styled.span<{
    classificationType: EditorialType | FundingType
}>(({ theme, classificationType }) => ({
    textTransform: 'uppercase',
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(12),
    fontWeight: 600,
    lineHeight: '133%',
    color:
        classificationType === EditorialType.Breaking ||
        classificationType === EditorialType.Live ||
        classificationType === EditorialType.Exclusive
            ? tokens.thenightly.colors.palette.utility.error.default
            : undefined,
}))

export interface StyledNightlyMediaProps extends StyledMediaProps {
    CSSCrop: BreakpointState<ThemeConfig, ImageRatio>
    numbered: boolean
    hasBiggerGap?: boolean
}

export const StyledNightlyMedia = styled(CardMedia)<StyledNightlyMediaProps>(
    ({
        imagePosition,
        theme,
        imageWidths,
        CSSCrop,
        numbered,
        hasBiggerGap = false,
    }) => ({
        width: imageWidths.mobile,
        //height: getImageHeight(CSSCrop, imageWidths.mobile),
        margin:
            imagePosition === 'right'
                ? `0 0 0 ${calcRem(theme.margins.sm)}`
                : `0 ${
                      hasBiggerGap
                          ? calcRem(theme.margins.md)
                          : calcRem(theme.margins.sm)
                  } 0 0`,

        [breakpoint('sm')]: {
            width: imageWidths.tablet,
            //height: getImageHeight(CSSCrop, imageWidths.tablet),
        },
        [breakpoint('lg')]: {
            width: imageWidths.desktop,
            //height: getImageHeight(CSSCrop, imageWidths.desktop),
            display: numbered ? 'none' : undefined,
        },
    }),
)

// Adds in the width and alignment styles for the wrapping link - hovers, focus, link styles come from CardTextInteractions component
export const StyledLandscapeLink = styled(StyledLink)({})

export const StyledLandscapeContent = styled.div({
    display: 'flex',
    flex: '1 3 auto',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: 0,
})

function getImageHeight(ratio: ImageRatio, width: string) {
    return (parseInt(width, 10) / ratio.width) * ratio.height
}

export const StyledContentTeaser = styled('div')({
    color: tokens.thenightly.colors.palette.neutral[70],
    fontWeight: 400,
    fontSize: metrics.thenightly.baseFontSize,
    lineHeight: calcRem(24),
})

export const StyledLandscapeTimeInfo = styled.div({
    display: 'flex',
    padding: 0,
    paddingTop: calcRem(2),
    gap: calcRem(8),
})

export const StyledFundingLabelWrapper = styled.div({
    marginTop: calcRem(4),
})
