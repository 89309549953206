import {
    getAdSize,
    GptAdSlotDefinition,
    pickFirstSizeMapping,
    sizeProps,
    SlotRenderEndedEvent,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledDebugContainer,
    StyledDebugItem,
    StyledText as StyledDebugText,
} from './DebugAdUnit.styled'
import { ViewSizeMappings } from './ViewSizeMappings'

interface DebugAdUnitProps {
    unit: GptAdSlotDefinition
    isViewable: boolean
    onSlotRenderEnded: (event: SlotRenderEndedEvent) => void
}

export const DebugAdUnit: React.FC<DebugAdUnitProps> = ({
    unit,
    isViewable,
    onSlotRenderEnded,
}) => {
    const size = getAdSize(unit.sizeMapping)
    const firstSize = pickFirstSizeMapping(size)
    const [renderedId, setRenderedId] = React.useState<string>()

    React.useEffect(() => {
        if (unit.id !== renderedId && firstSize) {
            setRenderedId(unit.id)
            onSlotRenderEnded({
                isEmpty: false,
                serviceName: 'debug',
                size: firstSize,
                slot: {} as any,
                unit: unit,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unit, renderedId, firstSize])

    if (!firstSize) {
        return null
    }

    return (
        <StyledDebugContainer
            width={firstSize[0] < 300 ? 300 : firstSize[0]}
            height={firstSize[1] < 100 ? 100 : firstSize[1]}
        >
            <StyledDebugItem>
                id: <StyledDebugText color="blue">{unit.id}</StyledDebugText>
            </StyledDebugItem>
            <StyledDebugItem>
                sitewide unit:{' '}
                {unit.originalDefinition.isSitewideAd ? 'yes' : 'no'}
            </StyledDebugItem>
            <StyledDebugItem>
                out of page slot: {unit.outOfPage ? 'yes' : 'no'}
            </StyledDebugItem>
            <StyledDebugItem>
                size name:{' '}
                <StyledDebugText color="red">
                    {unit.originalDefinition.size}
                </StyledDebugText>
            </StyledDebugItem>
            <StyledDebugItem>
                targeting: {JSON.stringify(unit.targeting, null, ' ')}
            </StyledDebugItem>
            <StyledDebugItem>
                ad pos category:{' '}
                {sizeProps[unit.originalDefinition.size].adPosCategory}
            </StyledDebugItem>
            <StyledDebugItem>path: {unit.adUnitPath}</StyledDebugItem>
            <StyledDebugItem>
                size mappings:
                <ViewSizeMappings unit={unit} />
            </StyledDebugItem>
            <StyledDebugItem>
                viewability reached: {isViewable ? 'true' : 'false'}
            </StyledDebugItem>
        </StyledDebugContainer>
    )
}

DebugAdUnit.displayName = 'DebugAdUnit'
