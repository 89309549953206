import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../../__styling/style-mixins'
import { TextItem } from '../../../../../typography/TextItem/TextItem'
import { perthNowNGNTextMixin } from '../../../../../mixins'

export const styledPNListClassName = 'PNList'

export const StyledPNBaseList = styled('ol')(({ as = 'ol' }) => ({
    ...breakWords,
    ...perthNowNGNTextMixin.body.L.regular,
    listStyle: 'none',
    counterReset: as === 'ol' ? 'number' : undefined,
    clear: 'both',
}))

export const StyledPNListItem = styled('li')(() => ({
    listStylePosition: 'inside',

    'ol &': {
        '&:before': {
            ...perthNowNGNTextMixin.body.L.regular,
            content: 'counter(number) "."',
            counterIncrement: 'number',
            color: colorsPN.text.brand,
        },
    },
    'ul &': {
        '&:before': {
            content: '"•"',
            ...perthNowNGNTextMixin.body.L.regular,
            color: colorsPN.text.brand,
        },
    },
    '&:before': {
        margin: calcRem(0, metricsPN.spacing['2XS']),
    },
}))
StyledPNListItem.displayName = 'StyledPNListItem'

export const StyledPNTextListItem = StyledPNListItem.withComponent(TextItem)
