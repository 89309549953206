import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

const StyledCTAClassName = 'LinkBannerCTA'

export interface LinkBannerProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['verticalGutters']),
})<LinkBannerProps>(
    {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        clear: 'both',
        textDecoration: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'background 250ms ease-out',
    },
    ({ verticalGutters, theme }) => ({
        padding: themedValue(theme, {
            thewest: calcRem(theme.margins.sm, 0),
            fallback: `${calcRem(theme.margins.sm)} ${calcRem(
                theme.margins.md,
            )}`,
        }),
        backgroundColor: themedValue(theme, {
            thewest: colors.white,
            perthnow: colors.perthnow.greyBasalt90,
            sevennews: colors.sevennews.charade,
            fallback: undefined,
        }),
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            fallback: colors.white,
        }),
        marginTop: calcRem(
            verticalGutters ? theme.margins[verticalGutters[0]] : 0,
        ),
        marginBottom: calcRem(
            verticalGutters ? theme.margins[verticalGutters[1]] : 0,
        ),

        '&:hover, &:focus': {
            [`& .${StyledCTAClassName}`]: {
                background: themedValue(theme, {
                    thewest: colors.white,
                    perthnow: colors.perthnow.blueKyanite,
                    sevennews: colors.sevennews.red,
                    fallback: undefined,
                }),
                color: themedValue(theme, {
                    thewest: (section) => section.primaryColor,
                    fallback: colors.white,
                }),
                borderColor: themedValue(theme, {
                    thewest: (section) => section.primaryColor,
                    perthnow: colors.perthnow.blueKyanite,
                    sevennews: colors.sevennews.red,
                    fallback: undefined,
                }),
            },
        },
    }),
)

export const StyledBannerMessage = styled('h2')(
    {
        fontSize: calcRem(22),
        color: 'currentColor',
        lineHeight: 1.15,
        flexBasis: 255,
        flexGrow: 1,
        display: 'block',
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.sansSerif,
        margin: themedValue(theme, {
            thewest: calcRem(
                theme.margins.sm,
                theme.margins.lg,
                theme.margins.sm,
                0,
            ),
            fallback: calcRem(
                theme.margins.sm,
                theme.margins.sm,
                theme.margins.sm,
                0,
            ),
        }),
    }),
)

export const StyledCTA = withClass(StyledCTAClassName)(
    styled('p')(
        {
            textTransform: 'uppercase',
            fontSize: calcRem(12),
            borderRadius: '2px',
            borderWidth: 1,
            borderStyle: 'solid',
            color: colors.white,
            fontWeight: 700,
            transition: 'background 250ms ease-out',
            flexShrink: 0,
        },
        ({ theme }) => ({
            fontFamily: theme.fonts.sansSerif,
            padding: calcRem(theme.margins.sm),
            margin: `${calcRem(theme.margins.sm)} 0`,
            backgroundColor: themedValue(theme, {
                thewest: (section) => section.primaryColor,
                perthnow: colors.perthnow.pinkThulite,
                sevennews: colors.sevennews.red,
                fallback: undefined,
            }),
            border: themedValue(theme, {
                thewest: (section) => `1px solid ${section.primaryColor}`,
                perthnow: `1px solid ${colors.perthnow.pinkThulite}`,
                sevennews: `1px solid ${colors.sevennews.red}`,
                fallback: undefined,
            }),
        }),
    ),
)
