import { ConsentLevel } from '../data/consent/consent.redux'
import { hasConsentLevel } from '../data/consent/has-consent-level'
import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { ComponentEvent } from './component-event'

interface CookieConsentEventProps {
    permissionEssential: boolean
    permissionAnalytics: boolean
    permissionAdvertising: boolean
}

export interface CookieConsentEvent
    extends ComponentEvent<CookieConsentEventProps> {
    type: DataLayerEventName.cookieConsent
}

interface CookieConsentGTMProps extends CookieConsentEventProps {
    eventCategory: 'cookie consent'
}

export function toCookieConsentEvent(
    consent: ConsentLevel,
    originator: string,
): CookieConsentEvent {
    return {
        type: DataLayerEventName.cookieConsent,
        originator,
        payload: {
            permissionAdvertising: hasConsentLevel(
                consent,
                ConsentLevel.Advertising,
            ),
            permissionAnalytics: hasConsentLevel(
                consent,
                ConsentLevel.Analytics,
            ),
            permissionEssential: hasConsentLevel(
                consent,
                ConsentLevel.Essential,
            ),
        },
    }
}

export function toCookieConsentEventShape(
    props: CookieConsentEventProps,
): CookieConsentGTMProps {
    return {
        ...props,
        eventCategory: 'cookie consent',
    }
}
