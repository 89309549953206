/* eslint-disable @typescript-eslint/no-var-requires */
import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

const gerardRossBannerDesktopX2 = require<string>('./assets/gerard-ross/TW-GerardRoss-desktop-1220x1500@2x-80.jpg')

const gerardRossBannerDesktopX1 = require<string>('./assets/gerard-ross/TW-GerardRoss-desktop-1220x1500.jpg')

const gerardRossBannerTabletX2 = require<string>('./assets/gerard-ross/TW-GerardRoss-tablet-738x57@2x-80.jpg')

const gerardRossBannerTabletX1 = require<string>('./assets/gerard-ross/TW-GerardRoss-tablet-738x57.jpg')

const gerardRossBannerMobileX2 = require<string>('./assets/gerard-ross/TW-GerardRoss-mobile-411x93@2x-80.jpg')

const gerardRossBannerMobileX1 = require<string>('./assets/gerard-ross/TW-GerardRoss-mobile-411x93.jpg')

export const getGerardRossRouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: gerardRossBannerDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText: 'The Boy in the Blue Cap - The Gerard Ross Story',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: gerardRossBannerDesktopX2, density: '2x' },
                            { src: gerardRossBannerDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: gerardRossBannerTabletX2, density: '2x' },
                            { src: gerardRossBannerTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: gerardRossBannerMobileX2, density: '2x' },
                            { src: gerardRossBannerMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.nestedComposition({
            type: 'janus',
            props: {
                layoutRatio: '3:1',
                stretchColumns: true,
                verticalSpacing: 'xl',
            },
            contentAreas: {
                left: [
                    layout.component({
                        type: 'sierra',
                        props: {
                            fixedRatios: ['16:9'],
                            heroImageLayout:
                                ResponsivePictureLayout.ObjectFitContain,
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                                maxBreakpoint: 'md',
                            },

                            cardLayout: ['hero-video'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'gerard-ross',
                                offset: 0,
                                pageSize: 1,
                            },
                        },
                    }),
                ],
                right: [
                    layout.component({
                        type: 'tw-subscribe-promo-card',
                        props: {},
                    }),
                ],
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                sectionHeader: {
                    heading: 'Watch the episodes',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'gerard-ross',
                    offset: 1,
                    pageSize: 8,
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,
                sectionHeader: {
                    heading: 'Latest Gerard Ross News',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/gerard-ross'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                        pageOffset: 0,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
