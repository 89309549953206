import React from 'react'
import { ColumnProps } from './AflPlayerStatsWidget'
import {
    StyledAflPlayerStatsWidgetTableHead,
    StyledArrowIndicator,
} from './AflPlayerStatsWidget.styled'
const arrow = require('./assets/arrow.svg')

interface PlayerStatsTableHeadProps {
    columns: ColumnProps[]
    handleSorting: (accessor: string, sordOrder: string) => void
    setSelectedColumnIndex: (index: number) => void
    sortField: string
    setSortField: (accessor: string) => void
    order: string
    setOrder: (order: string) => void
}

export const PlayerStatsTableHead = ({
    columns,
    handleSorting,
    setSelectedColumnIndex,
    sortField,
    order,
    setSortField,
    setOrder,
}: PlayerStatsTableHeadProps) => {
    const handleSortingChange = (accessor: string) => {
        const sortOrder =
            accessor === sortField && order === 'asc' ? 'desc' : 'asc'
        setSortField(accessor)
        setOrder(sortOrder)
        handleSorting(accessor, sortOrder)
        const sortedColumnIndex = columns.findIndex(
            (column) => column.accessor === accessor,
        )
        //array indexes are 0-based and for CSS selecting a column in the table indexes are 1-based so need to increase the index from the array
        setSelectedColumnIndex(sortedColumnIndex + 1)
    }
    return (
        <StyledAflPlayerStatsWidgetTableHead>
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const isAsc = sortable
                        ? sortField === accessor && order === 'asc'
                            ? true
                            : false
                        : false
                    return (
                        <th
                            key={accessor}
                            onClick={
                                sortable
                                    ? () => handleSortingChange(accessor)
                                    : () => {}
                            }
                        >
                            {label}
                            <StyledArrowIndicator
                                isAsc={isAsc}
                                src={arrow}
                                alt="Sort arrow"
                            />
                        </th>
                    )
                })}
            </tr>
        </StyledAflPlayerStatsWidgetTableHead>
    )
}
