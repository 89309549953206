import { useTheme } from '@emotion/react'
import { TheWestSection } from '@news-mono/common'
import { isCorporateAccount } from '@news-mono/component-library'
import { AppState, useIsEuMemberState, WebLink } from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import {
    StyledLinkList,
    StyledLinks,
    StyledTerms,
    StyledTermsFooter,
} from '../../navigation/TermsFooter/TermsFooter.styled'
import { useSection } from '../../__helpers'
import { theWestSectionDefinitions } from '../../__styling/settings/sections/thewest'
import { ThemedRender } from '../../__styling/themed-render'
import { TermLink, TermsFooterProps } from './TermsFooter'

function getAdvertiseLink(section: TheWestSection): TermLink {
    const genLink = (title: string) => ({
        name: `Advertise in ${title}`,
        link: 'https://advertising.sevenwestmedia.com.au',
    })

    const sectionTitle =
        theWestSectionDefinitions[section as TheWestSection].title

    switch (section) {
        case 'the-kimberley-echo':
        case 'default':
            return genLink(sectionTitle) // already has a 'The' in the title
        default:
            return genLink(`the ${sectionTitle}`) // we want other regional sites to be prefixed with 'the'
    }
}

function getPlaceanadLink(section: TheWestSection): TermLink {
    const genLink = (title: string) => ({
        name: `Place an ad in ${title}`,
        link: 'https://placeanad.thewest.com.au/WebOE/?utm_source=thewest&utm_medium=referral&utm_campaign=placeanad&utm_content=footerlink',
    })

    switch (section) {
        case 'default':
            return genLink(`the Classifieds`)
        default:
            return genLink(`The West`)
    }
}

export const TermsFooterWeb: React.FC<TermsFooterProps> = ({
    footerTermsLinks,
    footerCompanyLinks,
    additionalTerms,
}) => {
    const theme = useTheme()
    const section = useSection()
    const { subscriptionType } = useSelector(
        (state: AppState) => state.authentication,
    )
    const isCorporate = isCorporateAccount(subscriptionType)

    const renderStyledLinkItem = (linkItem: TermLink) => (
        <StyledLinks key={linkItem.name}>
            <WebLink to={linkItem.link}>{linkItem.name}</WebLink>
        </StyledLinks>
    )

    const renderFooterCompanyLinks = () => {
        if (!footerCompanyLinks) {
            return null
        }

        const footerLinks = [...footerCompanyLinks]

        // Hide Ad link for site license users
        if (theme.kind === 'thewest' && !isCorporate) {
            // If the west or regional then we do the following
            const insertIndex =
                footerCompanyLinks.length > 0
                    ? footerCompanyLinks.length - 1
                    : 0

            // get the sectioned advertising link
            const advertisingLink = getAdvertiseLink(
                theme.section as TheWestSection,
            )
            const placeanadLink = getPlaceanadLink(
                theme.section as TheWestSection,
            )

            // Insert as second last item, mutates footerCompanyLinks
            footerLinks.splice(insertIndex, 0, placeanadLink, advertisingLink)
        }
        return (
            <StyledLinkList>
                {footerLinks.map(renderStyledLinkItem)}
            </StyledLinkList>
        )
    }

    const userInGdprZone = useIsEuMemberState()

    return (
        <StyledTermsFooter>
            <React.Fragment>
                {section !== 'genwest' && renderFooterCompanyLinks()}
                <StyledTerms>
                    <StyledLinkList>
                        <ThemedRender
                            thewest={() => {
                                if (
                                    !theWestSectionDefinitions[
                                        theme.section as TheWestSection
                                    ]
                                ) {
                                    return null
                                }

                                return (
                                    <p>
                                        &copy;
                                        {` West Australian Newspapers Limited ${new Date().getFullYear()}`}
                                    </p>
                                )
                            }}
                        />
                        {Array.isArray(footerTermsLinks) &&
                            footerTermsLinks.map(renderStyledLinkItem)}
                        {userInGdprZone && (
                            <StyledLinks>
                                <WebLink to="/cookie-settings">
                                    Cookie Settings
                                </WebLink>
                            </StyledLinks>
                        )}
                        {additionalTerms}
                    </StyledLinkList>
                </StyledTerms>
            </React.Fragment>
        </StyledTermsFooter>
    )
}
TermsFooterWeb.displayName = 'TermsFooter'
