import styled from '@emotion/styled'
import { Product, WebLink } from '@news-mono/web-common'
import { TheWestLiveLogo } from '../../../logos/TheWestLiveLogo/TheWestLiveLogo'
import {
    TheWestLiveLogoWithPresenter,
    WEST_LIVE_LOGO_PRESENTER_PRIMARY_FILL_CLASS,
} from '../../../logos/TheWestLiveLogo/TheWestLiveLogoWithPresenter'
import { colors } from '../../../__styling/settings/colors'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { themedValue } from '../../../__styling/themed-value'

export const StyledWestLiveStickyBanner = styled('div')(
    {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        height: calcRem(55),
        left: '2%',
        right: '2%',
        bottom: 58,
        zIndex: zIndex.thewest.theWestLiveBanner,
        borderRadius: calcRem(4),
        boxShadow: `0 -1px 9px 0 ${colors.black50}`,
        overflow: 'hidden',

        [breakpoint('sm')]: {
            height: calcRem(69),
            bottom: '2%',
        },
    },
    ({ theme }) =>
        theme.kind === Product.TheWest && {
            background: `
		linear-gradient(${colors.white}, ${colors.white}),
		radial-gradient(circle, ${colors.white} 0%, ${colors.white} 43%, ${colors.black35} calc(43% + 1px), rgba(0, 0, 0, 0) 50%),
        linear-gradient(180deg, ${colors.thewest.westblueTint} 0%, ${colors.thewest.westblue} 45%)`,
            backgroundSize: `calc(50% - 20px) 100%, 142px 142px, auto`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left, left calc(50% - 44px) top 50%, right',
        },
    ({ theme }) =>
        theme.kind === Product.PerthNow && {
            background: `linear-gradient(120deg, ${colors.white} 0%, ${colors.white} 50%, ${colors.perthnow.greyBasalt} calc(50% + 1px), ${colors.perthnow.greyBasalt} 100%)`,
            backgroundRepeat: 'no-repeat',
        },
    () =>
        process.env.NODE_ENV === 'vr_test' && {
            // offset by sticky advertisement on mobile - dont want this on VR tests
            bottom: 0,
        },
)
StyledWestLiveStickyBanner.displayName = 'StyledWestLiveStickyBanner'

export const StyledWestLiveBranding = styled(WebLink)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(50% - 30px)',
    height: '100%',
    marginRight: calcRem(8),

    [breakpoint('sm')]: {
        width: 'calc(50% - 74px)',
        marginRight: themedValue(theme, {
            perthnow: calcRem(58),
            thewest: calcRem(46),
            fallback: undefined,
        }),
    },
}))
StyledWestLiveBranding.displayName = 'StyledWestLiveBranding'

export const StyledWestLiveLogoMob = styled(TheWestLiveLogo)({
    display: 'block',
    width: '20vw',
    minWidth: calcRem(50),
    maxWidth: calcRem(200),
    height: calcRem(38),
    marginLeft: calcRem(-15),
})
StyledWestLiveLogoMob.displayName = 'StyledWestLiveLogoMob'

export const StyledWestLiveLogo = styled(TheWestLiveLogoWithPresenter)(
    {
        display: 'block',
        width: '20vw',
        minWidth: calcRem(105),
        maxWidth: calcRem(200),
        height: calcRem(38),
        marginLeft: calcRem(-8),

        [breakpoint('sm')]: {
            marginLeft: calcRem(-12),
        },
    },
    ({ theme }) =>
        theme.kind === Product.TheWest && {
            [`.${WEST_LIVE_LOGO_PRESENTER_PRIMARY_FILL_CLASS}`]: {
                fill: colors.thewest.westblue,
            },
        },
    ({ theme }) =>
        theme.kind === Product.PerthNow && {
            '#Presenter': {
                fill: colors.perthnow.redRuby,
            },
        },
)
StyledWestLiveLogo.displayName = 'StyledWestLiveLogo'

export const StyledPresenterImage = styled('img')({
    height: '92%',
    alignSelf: 'flex-end',
    zIndex: 2,
})
StyledPresenterImage.displayName = 'StyledPresenterImage'

export const StyledPlayerWrapper = styled('div')(({ theme }) => ({
    marginRight: 'auto',

    [breakpoint('sm')]: {
        minWidth: calcRem(240),
        marginRight: theme.margins.outerMargin,
    },
}))
StyledPlayerWrapper.displayName = 'StyledPlayerWrapper'

export const SponsoredLogoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100% - 10px)',
    borderLeft: `1px solid ${colors.white20}`,
    paddingRight: theme.margins.sm,
    paddingLeft: theme.margins.sm,
    paddingBottom: calcRem(8),

    [breakpoint('sm')]: {
        paddingLeft: calcRem(25),
    },
}))
SponsoredLogoContainer.displayName = 'SponsoredLogoContainer'
