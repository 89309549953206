import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { MediaMode } from '../../cards/CardMedia/CardMedia'
import { Landscape } from '../../cards/Landscape/Landscape'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { fonts } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export interface StyledGridRowProps {
    hasBackground?: boolean
}

export const GridStyles: CSSObject = {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
}

export const StyledGridHeaderRow = styled('div')<StyledGridRowProps>(
    GridStyles,
    ({ hasBackground, theme }) => [
        hasBackground && {
            padding: theme.cards.outerMargin,
            backgroundColor: theme.colors.background.secondary,
        },
    ],
)

export const StyledSectionHeader = styled(SectionHeader)<SectionHeaderProps>(
    (props) => ({
        borderBottom: props.heading
            ? themedValue(props.theme, {
                  perthnow: `1px solid ${props.theme.colors.borders.secondary}`,
                  fallback: undefined,
              })
            : undefined,
        alignSelf: 'flex-start',
    }),
)

export const StyledGrid = styled('div')<{
    noStretch?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    as: string
}>(
    {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        width: '100%',
        lineHeight: 1.154,
    },
    ({ theme, verticalSpacing, noStretch }) => ({
        flex: `${noStretch ? 0 : 1} 1 auto`,
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
    }),
)

export const StyledGridRow = styled('div')<StyledGridRowProps>(
    {
        ...GridStyles,
        flexGrow: 1,
    },
    ({ hasBackground, theme }) =>
        hasBackground && {
            backgroundColor: theme.colors.background.secondary,
        },
)

export const StyledGridColumn = styled('div')({
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexBasis: '100%',
    flex: '0 0 auto',
    flexWrap: 'nowrap',
    margin: 0,
    alignItems: 'stretch',
})

export const StyledSimpleHeader = styled('div')(({}) => ({
    color: colors.darkGrey,
    fontSize: calcRem(32),
    marginBottom: calcRem(8),
    borderBottom: `6px solid ${colors.darkGrey}`,
    fontFamily: fonts.sevennews.sansSerif,
    fontWeight: 400,
    width: '100%',
}))

interface StyledListProps {
    numberedList?: boolean
    removeBorderLast?: boolean // remove border from last item in list
}

export const StyledList = styled('ol')<StyledListProps>((props) => ({
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    // Remove top padding on the first item
    '&:first-child': {
        paddingTop: themedValue(props.theme, {
            thewest: 0,
            fallback: undefined,
        }),
    },

    // Dividers
    '& > *': [
        (props.theme.kind === 'thewest' || props.theme.kind === 'perthnow') && {
            '&:not(:last-child)::after': {
                content: `''`,
                width: '100%',
                height: 1,
                position: 'absolute',
                left: 0,
                bottom: 0,
                backgroundColor: themedValue(props.theme, {
                    thewest: props.theme.colors.borders.primary,
                    perthnow: props.theme.colors.borders.secondary,
                    fallback: colors.black,
                }),
            },
        },

        props.theme.kind === 'sevennews' && {
            position: 'relative',

            '&::after': {
                content: `''`,
                width: '100%',
                height: 1,
                position: 'absolute',
                left: 0,
                bottom: 0,
                backgroundColor: props.removeBorderLast
                    ? 'transparent'
                    : colors.sevennews.greyMarble,
            },
        },
    ],
}))

interface StyledLandscapeItemProps {
    removeBorderLast?: boolean // remove border from last item in list
    mediaMode?: MediaMode
}

export const StyledLandscapeItem = styled(Landscape)<StyledLandscapeItemProps>(
    (props) => ({
        padding: calcRem(8, 8, 4, 0),

        [breakpoint('sm')]: {
            padding: themedValue(props.theme, {
                thewest: calcRem(
                    props.theme.margins.sm,
                    props.theme.margins.sm,
                    props.theme.margins.xs,
                    props.theme.margins.sm,
                ),
                fallback: undefined,
            }),
        },
        [breakpointMax('xxs')]: {
            padding:
                props.theme.kind === 'thewest'
                    ? calcRem(
                          16,
                          props.theme.margins.sm,
                          0,
                          props.theme.margins.sm,
                      )
                    : undefined,
        },

        '&:first-of-type': {
            paddingTop: themedValue(props.theme, {
                thewest: 0,
                fallback: undefined,
            }),
        },
    }),
)
