import { Logger } from 'typescript-log'
import { validateKeyTypes } from '../../../helpers/validation'
import { EpaperPublicationType } from './epaper-loader-definition'
import { getIssue } from './get-issue'
import { format, isEqual } from 'date-fns'

export async function getLatestIssuesFromMultiplePublications({
    logger,
    publications,
    caller,
}: {
    logger: Logger
    publications: EpaperPublicationType[]
    caller: string
}) {
    const data = await Promise.all(
        publications.map(async (publication) => {
            const issues = await getLatestIssuesFromPublication({
                logger: logger,
                caller: caller,
                numberOfIssues: 1,
                publication: publication,
            })
            return {
                ...issues.latestIssues[0],
                publicationName: publication,
                isPremium: PremiumPublicationsSet[publication],
            }
        }),
    )

    return data
}

export async function getLatestIssuesForTheWest({
    numberOfIssues,
    logger,
    caller,
}: {
    numberOfIssues: number
    logger: Logger
    caller: string
}): Promise<{
    latestIssues: Issue[]
    STM: { latestIssues: Issue[] }
    PLAY: { latestIssues: Issue[] }
}> {
    const dataTW = await getLatestIssuesFromPublication({
        logger: logger,
        caller: caller,
        numberOfIssues: numberOfIssues,
        publication: 'the-west-australian',
    })

    const dataST = await getLatestIssuesFromPublication({
        logger: logger,
        caller: caller,
        numberOfIssues: Math.floor(numberOfIssues / 7) + 1,
        publication: 'sunday-times',
    })

    const STM = await getLatestIssuesFromPublication({
        logger: logger,
        caller: caller,
        numberOfIssues: Math.floor(numberOfIssues / 7) + 1,
        publication: 'stm',
    })

    const PLAY = await getLatestIssuesFromPublication({
        logger: logger,
        caller: caller,
        numberOfIssues: Math.floor(numberOfIssues / 7) + 1,
        publication: 'play',
    })

    const latestIssues = combinePublications(
        dataTW.latestIssues,
        dataST.latestIssues,
        STM.latestIssues,
        PLAY.latestIssues,
        numberOfIssues,
    )
    return { latestIssues, STM, PLAY }
}

//combines ST and TW, also bundles in the relevant PLAY and STM mags
export function combinePublications(
    dataTW: Issue[],
    dataST: Issue[],
    STM: Issue[],
    PLAY: Issue[],
    numberOfIssues: number,
): Issue[] {
    let indexTW = 0
    let indexST = 0

    const latestIssues: Issue[] = []

    while (latestIssues.length != numberOfIssues) {
        if (indexTW < dataTW.length) {
            if (
                indexST < dataST.length &&
                dataST[indexST].date > dataTW[indexTW].date
            ) {
                //find an STM mag
                for (const magazine of STM) {
                    if (isEqual(dataST[indexST].date, magazine.date)) {
                        dataST[indexST].magazine = magazine
                    }
                }
                latestIssues.push(dataST[indexST])
                indexST++
            }
            //push TW, increment index
            else {
                //if the day is Sat, find a PLAY Mag
                if (format(dataTW[indexTW].date, 'EEEE') === 'Saturday') {
                    for (const magazine of PLAY) {
                        if (isEqual(dataTW[indexTW].date, magazine.date)) {
                            dataTW[indexTW].magazine = magazine
                        }
                    }
                }
                latestIssues.push(dataTW[indexTW])
                indexTW++
            }
        }
    }

    return latestIssues
}

export async function getLatestIssuesFromPublication({
    logger,
    publication,
    numberOfIssues,
    caller,
}: {
    logger: Logger
    publication: EpaperPublicationType
    numberOfIssues: number
    caller: string
}) {
    try {
        const cuePublication =
            InternalPulicationToCuePublicationMap[publication]
        const organisation = PublicationToOrganisationMap[publication]
        if (publication === 'default') {
            return await getLatestIssuesForTheWest({
                numberOfIssues,
                logger,
                caller,
            })
        }
        const data = await getIssue(
            logger,
            validateGetEPaper,
            numberOfIssues,
            cuePublication,
            organisation,
            caller,
        )

        const issues = data.issues.web

        const result: { latestIssues: Issue[] } = { latestIssues: [] }

        issues.forEach(function (item) {
            //we realistically shoulnd't have more than 1 section
            const {
                coverImageSmallUri,
                coverImageThumbnailUri,
                readerUrl,
                coverImageUri,
            } = item
            result.latestIssues.push({
                date: new Date(item.publishedDate),
                coverUrl: coverImageSmallUri ?? coverImageUri,
                lgCoverUrl: coverImageUri,
                coverTumbnailUrl: coverImageThumbnailUri,
                url: readerUrl,
                publicationName: publication,
                isPremium: PremiumPublicationsSet[publication],
            })
        })

        return result
    } catch (error) {
        if (process.env.NODE_ENV === 'production') {
            logger.warn('Error while loading editions data')

            return {
                latestIssues: [],
            }
        }

        logger.debug('Error while loading editions data')

        return {
            latestIssues: [],
        }
    }
}

//this probably isn't enough validation
export function validateGetEPaper(value: any): EPaperResponseDTO {
    const errors = validateKeyTypes('curation', value, {
        organizationTitle: 'string',
        id: 'string',
        title: 'string',
    })

    if (errors) {
        throw new Error('EPaper response invalid\n    ' + errors.join('    \n'))
    }

    return value
}

export type Issue = {
    date: Date
    coverUrl: string
    lgCoverUrl: string
    coverTumbnailUrl: string
    url: string
    publicationName: EpaperPublicationType
    isPremium?: boolean
    magazine?: Issue
}

export interface EPaperResponseDTO {
    organizationTitle: string
    id: string
    title: string
    issues: { [key in 'web' | 'ios' | 'android']: IssueData[] }
}

interface IssueData {
    pageCount: number
    downloadPagesUrl: string
    coverImageSmallUri: string
    os: 'web' | 'android' | 'ios'
    coverImageUri: string
    published: 1
    updatedDate: Date
    title: string
    url: string //not the url to the reader
    sections: [
        {
            sectionName: string
            coverImageThumbnailUri: string
            coverImageSmallUri: string
            coverImageUri: string
            readerUrl: string
        },
    ]
    coverImageThumbnailUri: string
    publicUrl: string
    readerUrl: string
    publishedDate: Date
}

export const PremiumPublicationsSet: {
    [key in EpaperPublicationType]?: boolean
} = {
    'albany-advertiser': true,
    'augusta-margaret-river-times': false,
    'broome-advertiser': false,
    'bunbury-herald': false,
    'busselton-dunsborough-times': false,
    countryman: true,
    'geraldton-guardian': true,
    'great-southern-herald': true,
    'harvey-waroona-reporter': false,
    'kalgoorlie-miner': true,
    'the-kimberley-echo': true,
    'manjimup-bridgetown-times': true,
    'midwest-times': false,
    'narrogin-observer': true,
    'north-west-telegraph': false,
    'pilbara-news': false,
    'sound-telegraph': false,
    'south-western-times': true,
    'sunday-times': true,
    default: true,
    'the-west-australian': true,
    perthnow: false,
    joondalup: false,
    'western-suburbs': false,
    canning: false,
    'mandurah-times': false,
    wanneroo: false,
    stirling: false,
    central: false,
    southern: false,
    cockburn: false,
    fremantle: false,
    melville: false,
}

export const PublicationToOrganisationMap: {
    [key in EpaperPublicationType]: string
} = {
    'albany-advertiser': 'wan',
    'broome-advertiser': 'wan',
    'kalgoorlie-miner': 'wan',
    'pilbara-news': 'wan',
    'sound-telegraph': 'wan',
    'south-western-times': 'wan',
    'the-kimberley-echo': 'wan',
    countryman: 'wan',
    default: 'wan', //will fetch TW and ST combined
    'augusta-margaret-river-times': 'wan',
    'bunbury-herald': 'wan',
    'busselton-dunsborough-times': 'wan',
    'geraldton-guardian': 'wan',
    'great-southern-herald': 'wan',
    'harvey-waroona-reporter': 'wan',
    'manjimup-bridgetown-times': 'wan',
    'midwest-times': 'wan',
    'narrogin-observer': 'wan',
    'north-west-telegraph': 'wan',
    perthnow: 'wan',
    joondalup: 'wan',
    'western-suburbs': 'wan',
    canning: 'wan',
    'mandurah-times': 'wan',
    wanneroo: 'wan',
    stirling: 'wan',
    central: 'wan',
    southern: 'wan',
    cockburn: 'wan',
    fremantle: 'wan',
    melville: 'wan',
    'sunday-times': 'wan',
    'the-west-australian': 'wan',
    stm: 'wan',
    play: 'wan',
    'the-extra': 'wan',
    'the-nightly': 'the_nightly',
}

export const InternalPulicationToCuePublicationMap: {
    [key in EpaperPublicationType]: string
} = {
    'albany-advertiser': 'wan_albany',
    'broome-advertiser': 'wan_broome',
    'kalgoorlie-miner': 'wan_kal-miner',
    'pilbara-news': 'wan_pilbara-news',
    'sound-telegraph': 'wan_sound-telegraph',
    'south-western-times': 'wan_southwest-times',
    'the-kimberley-echo': 'wan_kimberley',
    countryman: 'wan_countryman',
    default: 'wan_west-australian', //will fetch TW and ST combined
    'augusta-margaret-river-times': 'wan_margaret-river',
    'bunbury-herald': 'wan_bunbury-herald',
    'busselton-dunsborough-times': 'wan_busselton',
    'geraldton-guardian': 'wan_geraldton',
    'great-southern-herald': 'wan_greatsh',
    'harvey-waroona-reporter': 'wan_harvey-waroona',
    'manjimup-bridgetown-times': 'wan_manjimup',
    'midwest-times': 'wan_midwest',
    'narrogin-observer': 'wan_narrogin',
    'north-west-telegraph': 'wan_northwesttele',
    perthnow: 'wan_joondalup',
    joondalup: 'wan_joondalup',
    'western-suburbs': 'wan_western-suburbs',
    canning: 'wan_canning',
    'mandurah-times': 'wan_coastal-times',
    wanneroo: 'wan_wanneroo',
    stirling: 'wan_stirling',
    central: 'wan_central',
    southern: 'wan_southern',
    cockburn: 'wan_cockburn',
    fremantle: 'wan_fremantle',
    melville: 'wan_melville',
    'sunday-times': 'wan_sunday-times',
    'the-west-australian': 'wan_west-australian',
    stm: 'wan_stm',
    play: 'wan_play',
    'the-extra': 'wan_the-extra',
    'the-nightly': 'the_nightly',
}

export function createDisplayName(publication: string) {
    const title = publication
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    return title
}
