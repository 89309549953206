export interface CommonZIndex {
    stickyVideo: number
    flyoutNav: number
    videoPlayButton: number
    videoTitleOverlay: number
    videoTopBar: number
    videoControlBar: number
    videoContextMenu: number
    mainNavigationListItem: number
    widgetLinkButtonIcon: number
    link: number
    collectionDividers: number
    toastNotification: number
    messageBannerArrow: number
    featureFigCaption: number
    telethonKids: number
    videoTrailerCardContent: number
    /**
     * This is only shown on the west but is used inside common code (ArticleBlockContent)
     */
    regWall: {
        fade: number
        box: number
    }
}

export interface TheWestZindex {
    flyoutNav: number
    stickySiteHeader: number
    stickyFooter: number
    searchDropdown: number
    breachMobileVariant: number
    cardPlayButton: number
    featureHeadline: number
    mainNavContent: number
    mainNavScrollShadow: number
    stickyHeaderShadow: number
    liveBlogQuestionForm: number
    theWestLiveBanner: number
    breachSportVariant: {
        subtitleContainer: number
        subtitleText: number
    }
    newFeatureTooltip: number
    tooltipFullscreenOverlay: number
    epaperSubscribeModal: number
}

export interface PerthNowZindex {
    mainNavigation: number
    cardIcon: number
    cardImageHoverOutline: number
    stickyFooter: number
    stickySiteHeader: number
    linkHighlight: number
    sidebar: number
    ampPlayerOverride: number
    stickyCreateAccountBanner: number
}

export interface SevenNewsZindex {
    hamburgerButton: number
    headerMobile: number
    flyoutNav: number
    globalNavLocationDropDown: number
    subNavDropDown: number
    stickySiteHeader: number
    stickyMobileFooterAd: number
    mainNavContent: number
    cardPlayButton: number
    heroCardText: number
    mainNavScrollShadow: number
    cardKicker: number
    heroCardGradientOverlay: number
    stickyHeaderShadow: number
}

export interface TheNightlyZindex {
    stickySiteHeader: number
    stickyCreateAccountBanner: number
    stickyCreateAccountModal: number
    flyoutNav: number
    stickyFooter: number
    searchDropdown: number
    breachMobileVariant: number
    cardPlayButton: number
    featureHeadline: number
    mainNavContent: number
    mainNavScrollShadow: number
    stickyHeaderShadow: number
    liveBlogQuestionForm: number
    theWestLiveBanner: number
    breachSportVariant: {
        subtitleContainer: number
        subtitleText: number
    }
    newFeatureTooltip: number
    tooltipFullscreenOverlay: number
    epaperSubscribeModal: number
    notificationCentre: number
}
interface ZIndex {
    common: CommonZIndex
    thewest: TheWestZindex
    sevennews: SevenNewsZindex
    perthnow: PerthNowZindex
    thenightly: TheNightlyZindex
}

export const zIndex: ZIndex = {
    common: {
        toastNotification: 100001, // higher than all other numbers here
        stickyVideo: 199, // Should be higher than other sticky containers (except sticky header)
        flyoutNav: 31, // TODO: Why is this defined in common and in the west and seven?
        videoPlayButton: 20,
        videoTitleOverlay: 10,
        videoTopBar: 10,
        videoControlBar: 5,
        messageBannerArrow: 5,
        videoContextMenu: 8,
        mainNavigationListItem: 2,
        telethonKids: 1, // for the telethon countdown
        widgetLinkButtonIcon: 1,
        collectionDividers: 1,
        featureFigCaption: 1,
        videoTrailerCardContent: 1,
        link: 0,
        regWall: {
            fade: 1,
            box: 1,
        },
    },
    thewest: {
        // As below, these needed to be set silly high to combat Kinetic Ads that have elements with z-index of 99999
        flyoutNav: 9000003, // Higher than all components
        newFeatureTooltip: 9000002,
        tooltipFullscreenOverlay: 9000001,
        stickySiteHeader: 200, // Higher than sticky video
        liveBlogQuestionForm: 99,
        stickyFooter: 101,
        searchDropdown: 28,
        breachMobileVariant: 27, // Must sit below stickyFooter
        cardPlayButton: 20,
        featureHeadline: 10,
        mainNavContent: 2,
        mainNavScrollShadow: 1,
        stickyHeaderShadow: -1, // pseudo element
        theWestLiveBanner: 100,
        breachSportVariant: {
            subtitleContainer: 1,
            subtitleText: 5,
        },
        epaperSubscribeModal: 201,
    },
    sevennews: {
        // These are really height because of injected ads which add ridiculous z-index's :(
        hamburgerButton: 9000006,
        headerMobile: 9000005, // on mobile the header sits over the nav
        flyoutNav: 9000002,
        globalNavLocationDropDown: 400,
        subNavDropDown: 300,
        stickySiteHeader: 250, // Higher than sticky video
        stickyMobileFooterAd: 200,
        mainNavContent: 40,
        cardPlayButton: 50,
        heroCardText: 20,
        mainNavScrollShadow: 10,
        cardKicker: 11, // Needs to sit above the overlay
        heroCardGradientOverlay: 10,
        stickyHeaderShadow: -1, // pseudo element
    },
    perthnow: {
        stickyCreateAccountBanner: 29, // Higher than most page content but less than mainNavigation
        mainNavigation: 30,
        cardIcon: 20,
        cardImageHoverOutline: 5,
        stickyFooter: 30,
        sidebar: 30,
        stickySiteHeader: 100000, // some takeover ads have a z-index of 99999 applied to them
        ampPlayerOverride: 103000, // Needs to be set to this so that it can override the z-index of the sticky side header.
        linkHighlight: -1, // pseudo elements for interaction states
    },
    thenightly: {
        stickySiteHeader: 200, // Higher than sticky video
        stickyCreateAccountBanner: 150,
        stickyCreateAccountModal: 201,
        flyoutNav: 9000003, // Higher than all components
        newFeatureTooltip: 9000002,
        tooltipFullscreenOverlay: 9000001,
        liveBlogQuestionForm: 99,
        stickyFooter: 101,
        searchDropdown: 28,
        breachMobileVariant: 27, // Must sit below stickyFooter
        cardPlayButton: 20,
        featureHeadline: 10,
        mainNavContent: 2,
        mainNavScrollShadow: 1,
        stickyHeaderShadow: -1, // pseudo element
        theWestLiveBanner: 100,
        breachSportVariant: {
            subtitleContainer: 1,
            subtitleText: 5,
        },
        epaperSubscribeModal: 201,
        notificationCentre: 201,
    },
}
