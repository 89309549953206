import React from 'react'
import { colors } from '../../__styling/settings/colors'

export interface IconArrowProps {
    className?: string
    fill?: string
}

export const IconArrow: React.FC<IconArrowProps> = ({ className, fill }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 11"
    >
        <path
            fill={fill ? fill : colors.black}
            d="M.31.05l7.42 5.3-7.41 5.33L.3 5.37z"
        />
    </svg>
)
IconArrow.displayName = 'IconArrow'
