import React, { FC } from 'react'
import { QueryParamDropdown } from '../components/DropdownMenu/QueryParamDropdown'
import {
    defaultTimeFilterOption,
    TIME_FILTER_PARAM,
    timeFilterOptions,
} from './util'
import { FilterEventProps } from '../filter-events-util'

export const TimeFilter: FC<FilterEventProps> = ({
    onEvent,
    dataLayerEventName,
}) => {
    return (
        <QueryParamDropdown
            defaultOption={defaultTimeFilterOption}
            menuOptions={timeFilterOptions}
            name="Time Filter"
            label="When"
            originator="TimeFilter"
            queryParamName={TIME_FILTER_PARAM}
            clearPage={true}
            onEvent={onEvent}
            dataLayerEventName={dataLayerEventName}
        />
    )
}
