export type SubscribeFunction<T> = (event: T) => void
type UnsubscribeEvent = () => void
export interface EventObserver<T> {
    subscribe: (fn: SubscribeFunction<T>) => UnsubscribeEvent
    broadcast: (data: T) => void
    getObservers: () => SubscribeFunction<T>[]
}

export function createEventObserver<T>(): EventObserver<T> {
    let observers: SubscribeFunction<T>[] = []

    function subscribe(fn: SubscribeFunction<T>) {
        observers.push(fn)
        // return observer's own unsubscribe method
        return () => {
            observers = observers.filter((observer) => observer !== fn)
        }
    }

    function broadcast(data: T) {
        observers.forEach((observer) => observer(data))
    }

    // Primarily meant for testing
    function getObservers(): SubscribeFunction<T>[] {
        return [...observers]
    }

    return { subscribe, broadcast, getObservers }
}
