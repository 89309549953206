import React from 'react'
import { StyledContentWrapper } from '../../compositions/ContentWrapper/ContentWrapper.styled'

export interface ContentWrapperProps {
    stretchColumns?: boolean
    stretchSelf?: boolean
    fillContainer?: boolean
    hasBackgroundFill?: boolean
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
    children,
    stretchColumns,
    stretchSelf,
    fillContainer,
    hasBackgroundFill,
}) => (
    <StyledContentWrapper
        className="js-content-area"
        stretchColumns={stretchColumns}
        stretchSelf={stretchSelf}
        fillContainer={fillContainer}
        hasBackgroundFill={hasBackgroundFill}
    >
        {children}
    </StyledContentWrapper>
)
ContentWrapper.displayName = 'ContentWrapper'
