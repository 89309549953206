import styled from '@emotion/styled'
import { calcRem } from '../../../../../__styling'

export const StyledLiveClassificationContainer = styled('div')({
    svg: {
        alignSelf: 'center',
        maxHeight: calcRem(16),
        maxWidth: calcRem(16),
    },
    display: 'flex',
})
