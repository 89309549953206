import { useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    createCardClickedEvent,
    entitledToAll,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../content/Picture/ResponsivePictureLayouts'
import { GetVideoQueue } from '../../../content/Video/PlayerInterface'
import { CardByline } from '../../CardByline/CardByline'
import { CommonCardProps } from '../../CardItem.Props'
import { TeaserMode } from '../../CardText/CardTeaser'
import { StyledLink } from '../../CardText/CardTextInteractions.styled'
import {
    returnFundingTypeIfItemLoaded,
    returnImageWidthsFromContainerRatios,
} from '../utils'
import {
    StyledContentWrap,
    StyledLargeCard,
    StyledLargeCardContent,
    StyledLargeCardMedia,
    StyledLargeCardMediaWrap,
} from './LargeCard.styled'

export interface LargeCardProps extends CommonCardProps, ResponsiveContainer {
    item: MaybeLoaded<PublicationCardItem>
    cardNumber: number
    getVideoQueue?: GetVideoQueue
    adUnitPath?: string | undefined
    mediaPosition?: 'left' | 'right'
    isLarge?: boolean
    hideImage?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
    hideText?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
    horizontalLayoutAtSize?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
    overrideRestrictedVideoPlayback?: boolean
    teaserMode?: TeaserMode
    showPublicationDate?: boolean
}

export function LargeCard(props: LargeCardProps) {
    const {
        item,
        cardNumber,
        onEvent,
        adUnitPath,
        containerWidthRatios,
        overrideRestrictedVideoPlayback,
        hideImage,
        horizontalLayoutAtSize,
        teaserMode,
        showPublicationDate,
    } = props
    const theme = useTheme()

    const storyClassification = true

    const fundingType = returnFundingTypeIfItemLoaded({
        item,
        storyClassification,
    })

    const imageWidths = returnImageWidthsFromContainerRatios({
        theme,
        containerWidthRatios,
    })

    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isUpLateOverride =
        item.loaded && item.result.shortHeadline.includes('Up Late:')

    const isEntitled = isUpLateOverride && entitledToAll(authentication)

    function linkClicked(e: React.MouseEvent<HTMLElement>) {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }
        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalPortrait',
                'Portrait',
                props.cardNumber,
                fundingType,
                undefined,
            ),
        )
    }

    const getVideoQueue = !props.item.loaded ? undefined : props.getVideoQueue

    return (
        <StyledLink
            to={toLinkWithHint(item)}
            onClick={linkClicked}
            isLoading={!item.loaded}
        >
            <StyledLargeCard
                cardNumber={cardNumber}
                horizontalLayoutAtSize={horizontalLayoutAtSize}
            >
                <StyledLargeCardMediaWrap hideImage={hideImage}>
                    <StyledLargeCardMedia
                        item={item}
                        fixedRatio={{ default: '16:9', sm: '4:3' }}
                        hasBackground={false}
                        imageWidths={imageWidths}
                        onEvent={onEvent}
                        mediaMode={'default'}
                        disableImageLazyLoad={true}
                        imageLayout={ResponsivePictureLayout.ObjectFitContain}
                        willPlayVideoInline={isEntitled}
                        getVideoQueue={getVideoQueue}
                        adUnitPath={adUnitPath}
                    />
                </StyledLargeCardMediaWrap>
                <StyledContentWrap>
                    <StyledLargeCardContent
                        item={item}
                        hasBackground={true}
                        kickerMode={{ size: 'large' }}
                        teaserMode={teaserMode || 'visible'}
                        fontScale={0.88}
                        onEvent={onEvent}
                    />

                    <CardByline
                        item={item}
                        onEvent={onEvent}
                        showProfile={false}
                        isEdgeToEdgeCard={false}
                        showPublicationDate={showPublicationDate}
                    />
                </StyledContentWrap>
            </StyledLargeCard>
        </StyledLink>
    )
}

LargeCard.displayName = 'LargeCard'
