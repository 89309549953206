/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AdDefinition, AdState } from '@news-mono/web-common'
import React from 'react'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { DebugAdUnitRow } from './DebugAdUnitsRow'

export const DebugAdUnitsTable: React.FC<{
    adState: AdState
    ads: AdDefinition[]
    forBreakpoint: number
}> = ({ ads, adState, forBreakpoint }) => {
    return (
        <table
            css={{
                borderCollapse: 'collapse',
                borderSpacing: 0,
                width: '100%',
            }}
        >
            <thead
                css={{
                    tr: {
                        td: { [breakpoint('sm')]: { minWidth: calcRem(50) } },
                    },
                }}
            >
                <tr>
                    <td>Ad slot type</td>
                    <td>pos</td>
                    <td>slot id</td>
                    <td>ad sizes</td>
                    <td>ad pos category</td>
                    <td>exclusion labels</td>
                </tr>
            </thead>
            <tbody>
                {ads.map((ad) => (
                    <DebugAdUnitRow
                        ad={ad}
                        adState={adState}
                        forBreakpoint={forBreakpoint}
                        key={ad.id}
                    />
                ))}
            </tbody>
        </table>
    )
}
