import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { IconChevronDown } from '../../../../icons/IconChevronDown/IconChevronDown'
import { colors } from '../../../../__styling/settings/colors'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import {
    textCrop,
    transition,
    visuallyhidden,
} from '../../../../__styling/style-mixins'

export const StyledWrapper = styled('div')({
    padding: calcRem(7, 0),
    marginBottom: calcRem(-2),
    height: calcRem(56),
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    color: 'inherit',
    fontWeight: 500,
})

export const StyledLinkContainer = styled('div')<{
    linkStyle?: 'heading' | 'standard'
}>(({ linkStyle }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
    flexShrink: 0,

    '&::before':
        linkStyle === 'heading'
            ? {
                  position: 'absolute',
                  left: 0,
                  width: calcRem(3),
                  height: '100%',
                  top: 0,
                  backgroundColor: colors.white,
                  content: `''`,
              }
            : undefined,
}))

interface StyledLinkProps {
    isCurrent: boolean
    linkStyle: 'heading' | 'standard'
}

export const StyledLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'isCurrent',
        'linkStyle',
    ]),
})<StyledLinkProps>(
    ({ linkStyle }) => ({
        paddingLeft: linkStyle === 'heading' ? calcRem(9) : undefined,
        position: 'relative',
        background: 'transparent',
        color: 'inherit',
        textAlign: 'left',
        textDecoration: 'none',
        textTransform: linkStyle === 'heading' ? 'uppercase' : undefined,
        ...textCrop({
            fontSize: 16,
            desiredLineHeight: 1,
            cropLineHeight: 10,
            topAdjustment: -2,
            bottomAdjustment: -1,
        }),

        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    }),
    ({ isCurrent }) =>
        isCurrent && {
            textDecoration: 'underline',
        },
)

export const StyledToggleButton = styled('button')({
    display: 'flex',
    position: 'relative',
    height: calcRem(42),
    width: calcRem(42),
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    ...transition({ properties: ['background-color'] }),

    '&:hover': {
        backgroundColor: colors.thewest.greyElephant,
    },

    '&:focus': {
        outline: `2px solid ${colors.thewest.greyElephant}`,
    },
})

export const StyledButtonText = styled('span')(visuallyhidden)

export const StyledChevronDown = styled(IconChevronDown)({
    fill: colors.white,
    width: calcRem(12),
    height: calcRem(12),
})

export const StyledRollUp = styled('div')({
    height: calcRem(3),
    width: calcRem(12),
    backgroundColor: colors.white,
})
