import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../App.routing'

type BestAustralianYarnSegmentArgs = {
    isGenwest: boolean
}

export const bestAustralianYarnSegment = ({
    isGenwest,
}: BestAustralianYarnSegmentArgs) => {
    return layout.nestedComposition({
        type: 'thor',
        feature: isGenwest
            ? undefined
            : 'best-australian-yarn-curation-segment',
        props: {
            hasBackgroundFill: true,
            containerWidth: '100%',
            verticalGutters: false,
            horizontalGutters: true,
            verticalSpacing: 'lg',
            themeOverride: 'best-australian-yarn',
            isGenwest: isGenwest,
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'sierra',
                    props: {
                        verticalSpacing: 'gridGap',
                        fixedRatios: ['16:9'],
                        heroImageLayout:
                            ResponsivePictureLayout.ObjectFitContain,
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                            maxBreakpoint: 'md',
                        },
                        cardLayout: [
                            'the-west-hero-lgNoBorder',
                            'hero-support',
                            'hero-support',
                        ],
                        dataDefinitionArgs: isGenwest
                            ? {
                                  type: 'listing',
                                  topics: ['genwest/best-australian-yarn'],
                                  includeSubTopics: false,
                                  paging: {
                                      page: 1,
                                      pageSize: 3,
                                  },
                              }
                            : {
                                  type: 'curation',
                                  name: 'best-australian-yarn',
                                  offset: 0,
                                  pageSize: 3,
                              },
                    },
                }),
            ],
        },
    })
}
