import { Logger } from 'typescript-log'
import { httpGet } from '../../../helpers'
import { EPaperResponseDTO } from './helpers'
import { format, getTimezoneOffset } from 'date-fns-tz'

export async function getIssue(
    logger: Logger,
    validateGetEPaper: (value: any) => EPaperResponseDTO,
    numberOfIssues: number,
    cuePublication: string,
    organisation: string,
    caller: string,
    fromDate?: string,
    baseUrl = 'https://editions.thewest.com.au',
    toDate: string = todaysDate(),
): Promise<EPaperResponseDTO> {
    // eg https://editions.thewest.com.au/ccidist-ws/wan/wan_west-australian/issues/
    const path = `ccidist-ws/${organisation}/${cuePublication}/issues/`

    /**
     * CUE Digital's automatic cloudfront invalidation covers /issues/ but not
     * /issues -- make sure we always have a trailing slash. :point-up:
     * See http://jira.stibodx.com/browse/WANNG-2106
     */
    return httpGet({
        log: logger,
        validate: validateGetEPaper,
        baseUrl,
        path,
        query: {
            draft: false,
            json: true,
            limit: numberOfIssues,
            skipSections: false,
            sortBy: `created`,
            sortOrder: `desc`,

            /**
             * Note to future self: when testing, you may need to escape the
             * `+` character in `toDate`, or it will be interpreted as a
             * space. For example: `encodeURIComponent('2023-12-12+0800')` or
             * `qs.stringify({ toDate: '2023-12-12+0800' })`. If you forget,
             * CUE Digital returns a 400 Bad Request error, with the message
             * `Invalid Date format.`
             *
             * (Not required here though: `httpGet` escapes its query inputs).
             */
            toDate,
            ...(fromDate !== undefined ? { fromDate } : {}),

            start: 1,
            submit: 'submit',
        },
        customHeaders: {
            caller,
        },
        fetchOptions: {
            /**
             * https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
             * browser cache is probably not supported in node, so this
             * probably does nothing. But if it's added in the future, we want
             * an up-to-date response.
             */
            cache: 'no-cache',
        },
    })
}

/**
 * Returns today's date in the format required by the CCIDist API.
 *
 * If `now` is provided, it will be used as the current time. Otherwise, the
 * current time will be obtained using `Date.now()`.
 *
 * Returns the current date in Perth, Australia, regardless of the local
 * timezone of the javascript runtime. This is done by adding an 8-hour offset
 * to the current time. This offset can be overridden by providing
 * timezoneOffsetHours.
 *
 * @returns {string} Today's date in the format required by the CCIDist API
 * (eg `2023-11-29+0800`)
 */
export function todaysDate(args?: {
    now?: number
    timezoneOffsetHours?: number
}): string {
    const now = args?.now ?? Date.now()
    const timezoneOffsetHours = args?.timezoneOffsetHours ?? 8

    let timeZone: string
    if (timezoneOffsetHours === 0) {
        timeZone = 'UTC'
    } else {
        const sign = timezoneOffsetHours < 0 ? '-' : '+'
        timeZone = `${sign}${String(Math.abs(timezoneOffsetHours)).padStart(
            2,
            '0',
        )}`
    }

    /**
     * date-fns-tz's `format` function formats the date without applying the
     * offset. So we need to apply the offset ourselves.
     */
    const systemOffset = getTimezoneOffset(undefined as unknown as string)
    const targetOffset = getTimezoneOffset(timeZone)
    const timestamp = now - systemOffset + targetOffset

    /**
     * `yyyy`: year
     *   `MM`: month
     *   `dd`: date (day of month)
     *   `xx`: timezone offset (ISO-8601 w/o Z)
     * more info: https://github.com/marnusw/date-fns-tz/blob/a92e0ad017d101a0c50e39a63ef5d322b4d849f6/src/format/index.js#L154C40-L154C69
     */
    const formatStr = 'yyyy-MM-ddxx'

    return format(timestamp, formatStr, { timeZone })
}
