import React from 'react'
import { addListener, removeListener } from '../__helpers/global-dom-events'

export interface ViewportProps {
    /** percentage from 0-100 */
    inViewport: number

    /** Relative to the element, for example if viewport has past the element, distance will be
     * positive. If viewport is before element, it will be negative
     */
    distanceFromViewport: number | undefined
    viewportHeight: number | undefined

    innerRef: (ref: any) => void
}

interface State {
    inViewport: number
    distanceFromViewport: number | undefined
    viewportHeight: number | undefined
}

export interface WithAddedProps {
    innerRef?: (ref: any) => void
    setViewport?: number
}

export function inViewport<P>(
    WrappedComponent:
        | React.ComponentClass<P & ViewportProps>
        | React.FC<P & ViewportProps>,
): React.ComponentClass<P & WithAddedProps> {
    return class ComponentWithViewport extends React.Component<
        P & WithAddedProps,
        State
    > {
        innerRef!: HTMLElement

        constructor(props: P & WithAddedProps) {
            super(props)

            this.state = {
                inViewport: 0,
                distanceFromViewport: undefined,
                viewportHeight: undefined,
            }
        }

        componentDidMount() {
            this.detectInViewport()
            addListener('scroll', this.detectInViewport)
            addListener('resize', this.detectInViewport)
        }

        componentWillUnmount() {
            removeListener('scroll', this.detectInViewport)
            removeListener('resize', this.detectInViewport)
        }

        setInnerRef = (innerRef: any) => {
            if (innerRef === this.innerRef) {
                return
            }
            this.innerRef = innerRef
            this.detectInViewport()

            if (this.props.innerRef) {
                // TypeScript can't infer function type correctly
                ;(this.props.innerRef as any)(innerRef)
            }
        }

        detectInViewport = () => {
            if (typeof window === 'undefined') {
                return
            }

            if (!this.innerRef || !this.innerRef.getBoundingClientRect) {
                return
            }

            const { top, bottom, height } =
                this.innerRef.getBoundingClientRect()

            let viewportPosition = 0
            const viewportHeight = window.innerHeight
            const visibleBottom = Math.min(bottom, viewportHeight)
            const visibleTop = Math.max(top, 0)
            const heightOnScreen = visibleBottom - visibleTop

            // If bottom is -, we have passed it, so it can't be in the viewport
            if (bottom > 0 && top < viewportHeight) {
                viewportPosition =
                    height === 0
                        ? 100
                        : // top/bottom of element or viewport
                          Math.min(
                              Math.round((heightOnScreen / height) * 100),
                              100,
                          )
            }

            const distanceFromViewport =
                // Outside viewport calculation
                viewportPosition === 0
                    ? Math.round(bottom < 0 ? -bottom : -(top - viewportHeight))
                    : 0

            if (
                viewportPosition === this.state.inViewport &&
                distanceFromViewport === this.state.distanceFromViewport &&
                viewportHeight === this.state.viewportHeight
            ) {
                return
            }

            this.setState({
                inViewport: viewportPosition,
                viewportHeight,
                distanceFromViewport,
            })
        }

        render() {
            const inViewport = this.props.setViewport
                ? (this.props.setViewport as number)
                : this.state.inViewport
            return (
                <WrappedComponent
                    {...this.props}
                    inViewport={inViewport}
                    distanceFromViewport={this.state.distanceFromViewport}
                    viewportHeight={this.state.viewportHeight}
                    innerRef={this.setInnerRef}
                />
            )
        }
    }
}

export default inViewport
