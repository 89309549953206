import { AppState, isPuzzlePage } from '@news-mono/web-common'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
    StyledAthena,
    StyledMain,
    StyledSidebar,
} from '../../compositions/Athena/Athena.styled'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'

export type SidebarOptions = 'none' | 'visibleMobile' | 'hiddenMobile'
export type ElementOptions = 'div' | 'article'

export interface AthenaProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarContent: React.ReactElement<any>
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    sidebarOptions: SidebarOptions
    elementType: 'div' | 'article'
    sidebarHeightLimitElementId?: string
}

export const Athena: React.FC<AthenaProps> = ({
    mainContent,
    sidebarContent,
    verticalSpacing,
    containerWidth,
    elementType,
    sidebarOptions = 'hiddenMobile',
    sidebarHeightLimitElementId,
}) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )
    const location = useLocation()

    const isPuzzlePageAppRendition =
        renditionType === 'app' && isPuzzlePage({ location })

    const [sidebarHeight, setSidebarHeight] = useState<number | undefined>(
        undefined,
    )
    const sidebarRef = React.useRef<HTMLElement | null>(null)
    const mainRef = React.useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!sidebarHeightLimitElementId) return

        const taboolaFeedWrapper = document.getElementById(
            sidebarHeightLimitElementId,
        )

        if (!taboolaFeedWrapper) return
        if (!mainRef.current) return
        if (!sidebarRef.current) return

        const resizeObserver = new ResizeObserver(() => {
            const taboolaRect = taboolaFeedWrapper.getBoundingClientRect()
            const sidebarRect = sidebarRef.current?.getBoundingClientRect()

            if (!taboolaRect || !sidebarRect) return

            const sidebarTop = sidebarRect.top
            const taboolaTop = taboolaRect.top

            // We want the height of the sidebar component to be the height
            // of the taboola feed minus the start height of the sidebar
            const sidebarHeight = taboolaTop - sidebarTop
            setSidebarHeight(sidebarHeight)
        })
        resizeObserver.observe(mainRef.current)
        return () => resizeObserver.disconnect()
    }, [mainRef, sidebarHeightLimitElementId, sidebarRef])

    return (
        <StyledAthena
            sidebarOptions={sidebarOptions}
            hideSidebar={isPuzzlePageAppRendition}
            verticalSpacing={verticalSpacing}
            containerWidth={containerWidth}
        >
            <StyledMain ref={mainRef}>{mainContent}</StyledMain>

            {sidebarOptions !== 'none' && (
                <StyledSidebar
                    ref={sidebarRef}
                    sidebarOptions={sidebarOptions}
                    maxHeight={sidebarHeight}
                >
                    {sidebarContent}
                </StyledSidebar>
            )}
        </StyledAthena>
    )
}
Athena.displayName = 'Athena'
