import { Product } from '@news-mono/web-common'
import React from 'react'

export interface IconQuoteLeftProps {
    className?: string
    product?: Product
}

export const IconQuoteLeft: React.FC<IconQuoteLeftProps> = ({
    className,
    product,
}) => {
    if (product === Product.TheWest) {
        return (
            <svg
                className={className}
                viewBox="0 0 21 13"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 12.84L2.52 0h7.32l-.9 12.84H0zm11.16 0L13.68 0H21l-.9 12.84h-8.94z"
                    fillRule="evenodd"
                />
            </svg>
        )
    }

    if (product === Product.TheNightly) {
        return (
            <svg
                className={className}
                width="19"
                height="16"
                viewBox="0 0 19 16"
            >
                <path d="M10.304 10.304C10.304 7.95733 11.2 5.78133 12.992 3.776C14.784 1.77067 16.32 0.512001 17.6 0L18.56 1.344C17.28 2.02667 16.192 3.008 15.296 4.288C14.4427 5.568 13.952 6.89067 13.824 8.256C14.1653 8.04267 14.6987 7.936 15.424 7.936C16.192 7.936 16.8533 8.27733 17.408 8.96C17.9627 9.6 18.24 10.4533 18.24 11.52C18.24 12.5867 17.8347 13.4613 17.024 14.144C16.256 14.784 15.296 15.104 14.144 15.104C12.992 15.104 12.0533 14.6987 11.328 13.888C10.6453 13.0773 10.304 11.8827 10.304 10.304ZM3.84 15.104C2.73067 15.104 1.81333 14.5067 1.088 13.312C0.362667 12.1173 0 10.7947 0 9.344C0 7.89333 0.298667 6.61333 0.896 5.504C1.49333 4.352 2.24 3.41333 3.136 2.688C4.84267 1.32267 6.22933 0.426666 7.296 0L8.256 1.344C6.976 2.02667 5.888 3.008 4.992 4.288C4.13867 5.568 3.648 6.89067 3.52 8.256C3.86133 8.04267 4.39467 7.936 5.12 7.936C5.888 7.936 6.54933 8.27733 7.104 8.96C7.65867 9.6 7.936 10.4533 7.936 11.52C7.936 12.5867 7.53067 13.4613 6.72 14.144C5.952 14.784 4.992 15.104 3.84 15.104Z" />
            </svg>
        )
    }

    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 54 44"
        >
            <path d="M24.12 39.593c0 2.24-3.31 3.522-5.124 3.522C11.74 43.115 0 26.573 0 14.087 0 6.83 5.016 0 12.7 0c7.15 0 12.272 5.336 12.272 12.486 0 9.712-8.217 10.352-8.217 14.3 0 6.724 7.364 10.566 7.364 12.807zm29.026 0c0 2.24-3.308 3.522-5.122 3.522-7.257 0-18.996-16.542-18.996-29.028C29.028 6.83 34.043 0 41.728 0 48.877 0 54 5.336 54 12.486c0 9.712-8.217 10.352-8.217 14.3 0 6.724 7.363 10.566 7.363 12.807z" />
        </svg>
    )
}
