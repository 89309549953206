export function isRichMediaAd(parent: HTMLElement | null) {
    if (!parent) {
        return false
    }

    const isBonzaiTruSkin =
        parent.querySelector('.bz-viewability-container') !== null &&
        typeof bonzai !== 'undefined'

    const isBonzaiScrollX =
        parent.classList.contains('.bz-viewability-container') !== null &&
        parent.hasAttribute('[data-bz-name]') !== null &&
        parent.querySelector('.bz-topmsgcont') !== null &&
        parent.querySelector('.bz-botmsgcont') !== null

    const isCeltra = typeof __CELTRA !== 'undefined'

    const isBonzaiPortal =
        parent.querySelector('.bz-format-abridged_portal') !== null

    return isBonzaiTruSkin || isBonzaiScrollX || isCeltra || isBonzaiPortal
}
