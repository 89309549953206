import { seteuid } from 'process'
import { setInterval } from 'timers'
import { liveEventDebug } from '.'

/**
 * Scrolls the screen to the provided element/id reference to an element.
 */
export const scrollToElement = (
    idOrElement: string | HTMLElement | undefined | null,
) => {
    if (!idOrElement) {
        return
    }

    let element

    if (typeof idOrElement === 'string') {
        element = document.getElementById(idOrElement)
    } else {
        element = idOrElement
    }

    if (element) {
        const elementRect = element.getBoundingClientRect()
        const elementOffset = window.scrollY + elementRect.top
        // By default center the element as the top-nav can obscure it at times otherwise.
        const screenOffset = window.innerHeight / 2

        window.scrollTo({
            left: 0,
            top: elementOffset - screenOffset,
        })
        liveEventDebug('Element found, scrolling into view: %s', idOrElement)
    }
}

/**
 * A function which aggressively scrolls to an element until it is on the screen for
 * a duration.
 * Resolves upon duration expiry.
 */
export const aggressiveScrollTo = async (
    elementId: string,
    duration = 3000, // Enough for most embeds to load in.
) => {
    const element = document.getElementById(elementId)

    if (!element) return

    scrollToElement(element)

    return new Promise<void>((resolve) => {
        let resolved = false
        let lastScrollY = window.scrollY
        // Cleanup timeouts etc.
        const cleanup = () => {
            clearInterval(interval)
            resolved = true
        }
        // When the duration has been fulfilled without any changes.
        const onTimeoutEnd = () => {
            cleanup()
            resolve()
        }

        setTimeout(onTimeoutEnd, duration)

        const interval = setInterval(() => {
            // Additional safety check to prevent infinite recursion.
            if (resolved) {
                cleanup()
                return
            }

            if (!isVisible(element)) {
                scrollToElement(element)
                lastScrollY = window.scrollY
            }
        }, 500)
    })
}

const isVisible = (element: HTMLElement) => {
    const box = element.getBoundingClientRect()

    // If any of the four corners are on the screen, it must overlap somewhere.
    // (Assuming the element's corners actually can move onto the viewport, does not check edges.)
    return (
        isPointInWindow(box.left, box.top) ||
        isPointInWindow(box.left + box.width, box.top) ||
        isPointInWindow(box.left, box.top + box.height) ||
        isPointInWindow(box.left + box.width, box.top + box.height)
    )
}

const isPointInWindow = (x: number, y: number) =>
    x > 0 && x < window.innerWidth && y > 0 && y < window.innerHeight
