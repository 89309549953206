import React from 'react'
import { colors } from '../../__styling'

export const IconCrossCircled = ({
    width,
    height,
    color,
}: {
    width: number
    height: number
    color?: string
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 17 17"
        fill="none"
        stroke={color || colors.thewest.greyKoala}
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8.5" cy="8.5" r="7.5" />
        <path d="M5.3 5.3L11.7 11.7" strokeLinecap="round" />
        <path d="M11.7 5.3L5.3 11.7" strokeLinecap="round" />
    </svg>
)
