import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { GameOnParisLogo } from '../../logos/GameOnParis/GameOnParisLogo'
import { IconChevronRight } from '../../icons'
import { WebLink } from '@news-mono/web-common'

/** Parent component to style the Olympics Header. This header consists of the logo and 'FULL COVERAGE' text button on TheWest. */
export const StyledOlympicsHeader = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        width: '100%',
        padding: calcRem(14, 0),
        display: 'flex',
        position: 'relative',
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        backgroundColor: colors.olympics.background,

        // Mobile styling
        padding: calcRem(8, 12),

        // Tablet and bigger styling
        [breakpoint('sm')]: {
            padding: calcRem(12, theme.margins.outerMargin),
        },
    },
])

/** Style the Game on Paris logo used on the left-hand side of the Olympics header. */
export const StyledOlympicsHeaderIcon = styled(GameOnParisLogo)(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        height: calcRem(36),
        maxHeight: calcRem(36),
        width: 'auto',

        margin: '0 auto 0 0',
        display: 'flex',
        position: 'relative',
    },
])

/** Style the WebLink 'parent' component used on the right-hand side of the Olympics header. */
export const StyledOlympicsHeaderFullCoverage = styled(WebLink)(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        margin: 'auto 0 auto auto',
        textDecoration: 'none',
        color: colors.olympics.link,

        display: 'inline-flex',
        flexDirection: 'row',
        position: 'relative',
    },
])

/** Style the Full Coverage text used on the right-hand side of the Olympics header. */
export const StyledOlympicsHeaderFullCoverageText = styled('p')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        fontFamily: theme.fonts.serif,
        fontSize: calcRem(16),
        fontWeight: 500,
        lineHeight: calcRem(16),
        textTransform: 'uppercase',

        margin: calcRem(0, 2, 0, 0),
        height: 'fit-content',
        display: 'flex',
    },
])

/** Style the Chevron that's supporting the full coverage text on the right-hand side of the Olympics header. */
export const StyledOlympicsFullCoverageChevron = styled(IconChevronRight)(
    ({ theme }) => [
        // THE WEST & SHARED STYLING
        {
            width: calcRem(16),
            height: calcRem(16),
            display: 'flex',
            position: 'relative',
        },
    ],
)
