import { useTheme } from '@emotion/react'
import React from 'react'
import { amphtml } from '../../../google-amp/react_amp'
import {
    StyledButton,
    StyledIconArrow,
} from '../../../publication/Embed/amp/AmpIFrame.styled'

//  The amp-iframe must have an overflow child element, permit this
declare module 'react' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface HTMLAttributes<T> {
        overflow?: any
        fallback?: ''
    }
}

interface InnerProps {
    title?: string
    height: number
    width?: number
    layout: 'responsive' | 'fixed' | 'fixed-height'
    sandbox: string
    frameborder: 0 | 1
    src: string
    resizable?: string
}

interface ImgProps {
    src: string
    layout?: string
    placeholder?: string
    width?: number
    height?: number
    alt?: string
}

const AmpIFrameElement = amphtml<InnerProps>('iframe')
const AmpImgElement = amphtml<ImgProps>('img')

export interface BaseIFrameProps {
    title?: string
    height: number
    frameborder?: 0 | 1
    resizable?: boolean
    src: string
    allowSameOrigin: boolean
    allowPopups: boolean
    allowForms: boolean
    /** if resizable=true this will be rendered if provided */
    innerHtml?: () => JSX.Element
    /** Dont display the fallback - to be used when its un-intentionally being rendered */
    noFallback?: boolean
}

export interface FrameLayoutPropsFixedResponsive {
    width?: number
    layout: 'responsive' | 'fixed'
}

export interface FrameLayoutPropsFixedHeight {
    width: undefined
    layout: 'fixed-height'
}

export type IFrameProps = (
    | FrameLayoutPropsFixedResponsive
    | FrameLayoutPropsFixedHeight
) &
    BaseIFrameProps

const getSandbox = (
    allowSameOrigin: boolean,
    allowPopups: boolean,
    allowForms: boolean,
) => {
    let sandbox = 'allow-scripts'

    if (allowSameOrigin) {
        sandbox += ' allow-same-origin'
    }
    if (allowPopups) {
        sandbox += ' allow-popups allow-popups-to-escape-sandbox'
    }
    if (allowForms) {
        sandbox += ' allow-forms'
    }
    return sandbox
}

export const AmpIFrame: React.FC<IFrameProps> = (props) => {
    const theme = useTheme()

    return (
        <AmpIFrameElement
            title={props.title}
            width={props.width}
            height={props.height}
            layout={props.layout}
            sandbox={getSandbox(
                props.allowSameOrigin,
                props.allowPopups,
                props.allowForms,
            )}
            frameborder={
                props.frameborder !== undefined ? props.frameborder : 0
            }
            src={props.src}
            // Attribute spread onto the component so it won't exist given it's false. It is set to an empty string so the markup doesn't have a value
            // See: https://github.com/facebook/react/issues/9230
            {...(props.resizable ? { resizable: '' } : {})}
        >
            {!props.noFallback && (
                <div fallback="">
                    <p>
                        Your user agent does not support frames or is currently
                        configured not to display frames. This frame is
                        attempting to link to{' '}
                        <a href={props.src}>{props.src}</a>
                    </p>
                </div>
            )}
            {props.resizable ? (
                props.innerHtml ? (
                    props.innerHtml()
                ) : (
                    <React.Fragment>
                        {/* A child with overflow is required for amp iframe to handle a resize event. If
                    amp determines the resize is too intrusive (on screen) there must be user
                    initiated resizing */}
                        <StyledButton overflow="true" type="button">
                            Resize <StyledIconArrow />
                        </StyledButton>
                        {/* A placeholder permits this to be placed within the top 75 pixels of the
                    screen if necessary. eg hard refresh while iframe is in view.
                    Fixing 4:3 here to suit FanHub widget as a convenience  */}
                        <AmpImgElement
                            src={theme.fallbackImages.crops['4:3'].reference}
                            placeholder="true"
                            layout="responsive"
                            height={props.height}
                            width={props.width}
                            alt={theme.fallbackImages.altText}
                        />
                    </React.Fragment>
                )
            ) : null}
        </AmpIFrameElement>
    )
}
