import { Product } from '@news-mono/web-common'
import { InlineContentTypes } from '../templates/Publication/SharedPublication.routing'
import { metrics } from '../__styling'

interface inlineEventAdsProps {
    noticePosition: 'above-center' | 'below-center' | 'none'
    hiddenUntilLoaded: boolean
    mastHead: Product
    slotID: string
    size: 'alwaysMrec'
    pageType: 'liveblog'
    lazyLoadingDistance?: number
}

export function inlineEventAds(props: inlineEventAdsProps): InlineContentTypes {
    const paddingOptions: Record<
        inlineEventAdsProps['mastHead'],
        [number, number, number, number]
    > = {
        thewest: [0, 0, metrics.thewest.margins.md, 0],
        sevennews: [0, 0, metrics.sevennews.margins.md, 0],
        perthnow: [18, 0, 18, 0],
        thenightly: [0, 0, 0, 0],
    }

    return {
        kind: 'ad',
        content: [
            {
                noticePosition: props.noticePosition,
                hiddenUntilLoaded: props.hiddenUntilLoaded,
                padding: paddingOptions[props.mastHead],
                slot: {
                    id: props.slotID,
                    size: props.size,
                    pageType: props.pageType,
                },
                adType: 'event',
            },
        ],
    }
}

export function getInlineEventAds(masthead: Product, count = 10) {
    return [...Array(count).keys()].map<InlineContentTypes>((key) =>
        inlineEventAds({
            noticePosition: 'below-center',
            hiddenUntilLoaded: false,
            mastHead: masthead,
            slotID: `event-mrec-${key}`,
            size: 'alwaysMrec',
            pageType: 'liveblog',
            lazyLoadingDistance: 0,
        }),
    )
}
