import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledFederalElectionDriverContainer = styled('div')({
    position: 'relative',
    cursor: 'pointer',

    width: calcRem(260),

    [breakpoint('sm')]: {
        width: 'auto',
    },
})

export const StyledLiveCoverageContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.brand,
    width: calcRem(167),
    borderBottomRightRadius: calcRem(24),
    borderTopRightRadius: calcRem(24),
    position: 'absolute',
    top: calcRem(30),
    left: calcRem(-14),
    display: 'flex',
    alignItems: 'center',
}))

interface StyledDriverImageContainer {
    url: string
}

export const StyledDriverImageContainer = styled(
    'div',
)<StyledDriverImageContainer>(({ url }) => ({
    backgroundImage: `url('${url}')`,
    height: calcRem(102),
}))

interface StyledTriangle {
    translateHeight: Array<number>
    rotate: number
}

export const StyledTriangle = styled('div')<StyledTriangle>(
    ({ theme, translateHeight, rotate }) => ({
        width: 0,
        height: 0,
        borderBottom: `${calcRem(13)} solid ${theme.colors.icons?.secondary}`,
        borderLeft: `${calcRem(13)} solid transparent `,
        transform: `rotate(${rotate}deg) translate(${translateHeight[0]}px, ${translateHeight[1]}px)`,
    }),
)

export const StyledTriangleContainer = styled('div')(({ theme }) => ({
    position: 'relative',
}))

export const StyledLiveCoverageTextContainer = styled('div')(({ theme }) => ({
    textAlign: 'left',
    marginRight: calcRem(13),
    paddingRight: theme.margins.sm,
    paddingTop: calcRem(6),
    paddingBottom: calcRem(1),

    '& > br': {
        content: '" "',
        display: 'block',
        marginTop: calcRem(-5),
    },

    '& > span:nth-child(1)': {
        fontStyle: 'italic',
        fontWeight: 800,
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(20),
        lineHeight: calcRem(18),
        color: theme.colors.actions.button.primary.text.active,
    },
    '& > span:nth-child(3)': {
        fontStyle: 'normal',
        fontWeight: 700,
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(20),
        lineHeight: calcRem(18),
        color: theme.colors.actions.button.primary.text.active,
    },
}))

export const StyledTriangleRight = styled('div')(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: `${calcRem(9.5)} solid transparent`,
    borderLeft: `${calcRem(19)} solid ${
        theme.colors.actions.button.primary.text.default
    }`,
    borderBottom: `${calcRem(9.5)} solid transparent`,
}))

export const StyledPlayButtonContainer = styled('div')
