import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { Breakpoints, ContainerWidth } from '../../__styling'
import { Doublet, DoubletSpacingProps } from './Doublet'

export interface DoubletRouteProps extends DoubletSpacingProps {
    containerWidth?: ContainerWidth
    rowBreakpoint?: keyof Breakpoints
}

export const DoubletRegistration = createRegisterableComposition<
    'leftContent' | 'rightContent'
>()(
    'doublet',
    (contentAreas, props: DoubletRouteProps) => (
        <Doublet
            {...props}
            leftContent={contentAreas.leftContent}
            rightContent={contentAreas.rightContent}
        />
    ),
    (
        contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: DoubletRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        if (contentArea === 'leftContent') {
            return {
                containerWidthRatios: {
                    desktop: 0.5 * containerWidthRatios.desktop,
                    tablet: 0.5 * containerWidthRatios.tablet,
                    mobile: 1 * containerWidthRatios.mobile,
                },
            }
        }

        return {
            containerWidthRatios: {
                desktop: 0.5 * containerWidthRatios.desktop,
                tablet: 0.5 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
