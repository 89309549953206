import {
    ConsentLevel,
    CookieConsentEvent,
    hasConsentLevel,
    useConsentState,
    useFeature,
    useIsEuMemberState,
} from '@news-mono/web-common'
import React, { Fragment, useRef, useState } from 'react'
import { useOnAirState } from '../content/TheWestLive/TheWestLiveProvider'
import { ToastNotification } from '../content/ToastNotification/ToastNotification'
import { TextLink } from '../typography/TextLink/TextLink'
import {
    CheckMark,
    GiveConsentButton,
    StyledFieldset,
    StyledLabel,
    StyledLegend,
    StyledParagraph,
    StyledHeading,
    StyledBodyContainer,
} from './CookieConsentManager.styled'
import { useConsentForm } from './useConsentForm'

interface CookieConsentManagerProps {
    onEvent?: (event: CookieConsentEvent) => void
    product?: string
}

export const CookieConsentManager: React.FC<CookieConsentManagerProps> = ({
    onEvent,
    product,
}) => {
    const { showConsentDialog } = useConsentState()
    const isEuMemberState = useIsEuMemberState()
    const toastRef = useRef<HTMLElement>(null)
    const previousElement = useRef<HTMLElement | null>(null)
    const ariaLabel = isEuMemberState
        ? 'Cookie Settings'
        : 'Cookie Notification'
    const westLiveEnabled = useFeature('the-west-live')
    const { onAir } = useOnAirState()
    const westLiveBannerUp = westLiveEnabled && onAir
    const isTheNightly = product === 'thenightly'

    React.useEffect(() => {
        if (showConsentDialog && toastRef.current) {
            previousElement.current =
                (document.activeElement as HTMLElement) ?? null
            toastRef.current.focus()
        }

        if (!showConsentDialog && previousElement.current) {
            previousElement.current.focus()
        }
    }, [showConsentDialog, toastRef, previousElement])

    return !showConsentDialog ? null : isTheNightly ? (
        <ToastNotification
            ariaLabel={ariaLabel}
            ref={toastRef}
            className={`cookie-consent--${isEuMemberState ? 'GDPR' : 'normal'}`}
            westLiveBannerUp={westLiveBannerUp}
        >
            <StyledBodyContainer>
                <StyledHeading>Cookie Settings</StyledHeading>
                <StyledParagraph>
                    We use cookies so we can improve your experience on this
                    site, analyse traffic and provide you with relevant
                    advertising. To find out more, please see our{' '}
                    <TextLink href="https://www.sevenwestmedia.com.au/cookies-guide">
                        Cookies Guide
                    </TextLink>
                    .
                </StyledParagraph>
                <ManageConsentForm onEvent={onEvent} />
            </StyledBodyContainer>
        </ToastNotification>
    ) : (
        // The className is added so we can track state with tests
        <ToastNotification
            ariaLabel={ariaLabel}
            ref={toastRef}
            className={`cookie-consent--${isEuMemberState ? 'GDPR' : 'normal'}`}
            westLiveBannerUp={westLiveBannerUp}
        >
            <StyledParagraph>
                We use cookies so we can improve your experience on this site,
                analyse traffic and provide you with relevant advertising. To
                find out more, please see our{' '}
                <TextLink href="https://www.sevenwestmedia.com.au/cookies-guide">
                    Cookies Guide
                </TextLink>
                .
            </StyledParagraph>
            <ManageConsentForm onEvent={onEvent} />
        </ToastNotification>
    )
}
CookieConsentManager.displayName = 'CookieConsentManager'

export function useManageConsentForm(consentLevel?: ConsentLevel) {
    const [consent, setConsent] = useState<ConsentLevel>(
        consentLevel ?? ConsentLevel.Essential,
    )

    const getFormHandler = (level: ConsentLevel) => {
        const changeHandler = (changed: boolean) => {
            setConsent(changed ? consent | level : consent & ~level)
        }
        return [hasConsentLevel(consent, level), changeHandler] as const
    }

    return {
        consent,
        getFormHandler,
    }
}

interface ManageConsentFormProps {
    onEvent?: (event: CookieConsentEvent) => void
}

export const ManageConsentForm: React.FC<ManageConsentFormProps> = ({
    onEvent,
}) => {
    const isEuMemberState = useIsEuMemberState()
    const { consentHandler, handleSubmit, savingConsent } = useConsentForm({
        origin: 'CookieConsentManager',
        onEvent,
    })

    const [essentialChecked] = consentHandler(ConsentLevel.Essential)
    const [analyticsChecked, handleAnalyticsChange] = consentHandler(
        ConsentLevel.Analytics,
    )
    const [advertisingChecked, handleAdvertisingChange] = consentHandler(
        ConsentLevel.Advertising,
    )

    return (
        <form onSubmit={handleSubmit}>
            {isEuMemberState ? (
                <React.Fragment>
                    <StyledParagraph>
                        By choosing ACCEPT, you are accepting our use of
                        cookies. Manage your settings any time at Cookie
                        Settings.
                    </StyledParagraph>
                    <StyledFieldset>
                        <StyledLegend>
                            Configure your cookie settings
                        </StyledLegend>
                        <ConsentCheckbox
                            name="Essentials"
                            checked={essentialChecked}
                            disabled
                        />
                        {/* you should no be able to change the essential option */}
                        <ConsentCheckbox
                            name="Analytics"
                            checked={analyticsChecked}
                            onChange={handleAnalyticsChange}
                        />
                        <ConsentCheckbox
                            name="Advertising"
                            checked={advertisingChecked}
                            onChange={handleAdvertisingChange}
                        />
                    </StyledFieldset>
                </React.Fragment>
            ) : (
                <StyledParagraph>
                    By continuing to use this site, you agree to our use of
                    cookies.
                </StyledParagraph>
            )}
            <GiveConsentButton type="submit" disabled={savingConsent}>
                {isEuMemberState ? (
                    <Fragment>
                        Accept and close <CheckMark />
                    </Fragment>
                ) : (
                    'Close'
                )}
            </GiveConsentButton>
        </form>
    )
}
ManageConsentForm.displayName = 'ManageConsentForm'

interface ConsentCheckboxProps {
    name: string
    checked: boolean
    disabled?: boolean
    onChange?: (checked: boolean) => void
}

export const ConsentCheckbox: React.FC<ConsentCheckboxProps> = (props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e.target.checked)
    }

    return (
        <StyledLabel>
            <input
                type="checkbox"
                name={props.name.toLowerCase()}
                checked={props.checked}
                disabled={props.disabled}
                onChange={handleChange}
            />
            {props.name}
        </StyledLabel>
    )
}
ConsentCheckbox.displayName = 'ConsentCheckbox'
