import { ConfigurationContext, getBaseUrl } from '@news-mono/web-common'
import React, { useContext } from 'react'
import { AdUnitWrapper } from '../../../advertising'
import { Box } from '../../../compositions/Box/Box'
import { Gallery } from '../../../content/Gallery/Gallery'
import { GalleryBreach } from '../../../user-registration/breach/GalleryBreach'
import { useSectionMetaInfo } from '../../../__helpers/use-section-meta'
import { GalleryPublicationProps } from '../SharedPublication.routing'

export const TheWestGallery: React.FC<GalleryPublicationProps> = ({
    gallery,
    location,
    onEvent,
    adState,
    log,
    galleryMeta,
}) => {
    const { publicUrl } = useContext(ConfigurationContext)
    const sectionMeta = useSectionMetaInfo()
    const url =
        getBaseUrl(publicUrl, sectionMeta) +
        '/' +
        gallery.primaryTopic.id +
        '/' +
        gallery.slug

    return (
        <Box verticalSpacing="md">
            <GalleryBreach
                location={location}
                onEvent={onEvent}
                gallery={gallery}
                images={gallery.images}
                slug={gallery.slug}
                requiredAccess={gallery.requiredAccess}
                log={log}
            >
                {({ images }, entitled) => (
                    <Gallery
                        entitled={entitled}
                        requiredAccess={gallery.requiredAccess}
                        title={gallery.heading}
                        images={images}
                        adFrequency={3}
                        publicationDate={gallery.publicationDate}
                        profile={gallery.profile}
                        byline={gallery.byline}
                        source={gallery.source}
                        mainImage={gallery.heroImage}
                        shareUrl={url}
                        onEvent={onEvent}
                        blocks={gallery.content}
                        log={log}
                        adUnitPath={adState.targeting.adUnitPath}
                        ads={galleryMeta.galleryAds.map((ad) => (
                            <AdUnitWrapper
                                hiddenUntilLoaded={ad.hiddenUntilLoaded}
                                noticePosition={ad.noticePosition}
                                padding={ad.padding}
                                adState={adState}
                                unitId={ad.slot.id}
                                onEvent={onEvent}
                                adType={'inline'}
                            />
                        ))}
                    />
                )}
            </GalleryBreach>
        </Box>
    )
}
