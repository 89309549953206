import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { perthnow, sevennews, thewest } from '../../__styling/themes'

export const styledCardTimestampTextLabel = 'CardTimestamp-Text'

export const StyledTimestamp = withClass(styledCardTimestampTextLabel)(
    styled('time')(
        {
            display: 'block',
            fontWeight: 700,
        },
        ({ theme }) => [
            {
                fontSize: themedValue(theme, {
                    perthnow: calcRem(14),
                    fallback: calcRem(12),
                }),
                fontWeight: themedValue(theme, {
                    perthnow: 600,
                    fallback: 700,
                }),
                background: themedValue(theme, {
                    perthnow: colors.perthnow.greyBasalt,
                    fallback: colors.white,
                }),
                fontFamily: themedValue(theme, {
                    perthnow: perthnow.fonts.sansSerif,
                    thewest: thewest.fonts.sansSerif,
                    sevennews: sevennews.fonts.sansSerifCond,
                    fallback: undefined,
                }),
                color: themedValue(theme, {
                    perthnow: colors.white,
                    thewest: (section) => section.primaryColor,
                    sevennews: (resolved) =>
                        resolved.cards.timestamp.primaryColor,
                    fallback: undefined,
                }),
            },
            // Carried over from existing list card implementation
            theme.kind === 'perthnow' && {
                flex: '0 0 auto',
                display: 'block',
                padding: calcRem(22, 0),
                alignSelf: 'center',
                textAlign: 'center',
                width: calcRem(68),
                lineHeight: 1,
            },
        ],
    ),
)
