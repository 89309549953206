import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { colors } from '../../../__styling/settings/colors'
import { metrics } from '../../../__styling/settings/metrics'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'

export interface StyledCardProps {
    isSponsored: boolean | null
}

export const styledTimestampClassName = 'Card-Timestamp'

const cardMediaStyles: CSSObject = {
    flex: `1 3 ${calcRem(106)}`,
    marginRight: calcRem(metrics.perthnow.margins.sm),
    marginBottom: calcRem(metrics.perthnow.cards.spacing),
    maxWidth: calcRem(86),
}

export const StyledMedia = styled(CardMedia)({
    ...cardMediaStyles,
})

export const StyledTimestampBlock = withClass(styledTimestampClassName)(
    styled('div')({
        ...cardMediaStyles,
        overflow: 'hidden',
        width: '100%',
        backfaceVisibility: 'hidden',
        position: 'relative',
        color: colors.black,
        backgroundColor: tokens.perthnow.colors.palette.blackShade[5],
        textAlign: 'center',
        height: calcRem(65),
        alignItems: 'center',
        display: 'flex',
    }),
)

export const StyledTime = withClass(styledTimestampClassName)(
    styled('time')(
        {
            fontSize: calcRem(14),
            fontWeight: 700,
            textAlign: 'center',
            width: '100%',
        },
        ({ theme }) => ({
            fontFamily: theme.fonts.sansSerifCond,
        }),
    ),
)
