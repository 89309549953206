import {
    DataLayerEventName,
    SavedArticlesFilterEvent,
    SearchEventArgs,
    SearchFilterEvent,
} from '@news-mono/web-common'
import { getPublishedSince, TIME_FILTER_PARAM } from './TimeFilter'
import { RESULT_SORT_PARAM, relevanceSortOption } from './ResultSort'
import {
    defaultOption as defaultTopicOption,
    TOPIC_FILTER_PARAM,
} from './TopicFilter'

export type FilterEvent = SearchFilterEvent | SavedArticlesFilterEvent

export interface FilterEventProps {
    onEvent: (event: FilterEvent) => void
    dataLayerEventName:
        | DataLayerEventName.searchFilter
        | DataLayerEventName.savedArticlesFilter
}

const topicEventName = 'Topics'
const resultSortEventName = 'Result Sort'
const timeFilterEventName = 'Time Filter'

export const filterParams = [
    TIME_FILTER_PARAM,
    RESULT_SORT_PARAM,
    TOPIC_FILTER_PARAM,
]

export const getEventName = (param: string) => {
    switch (param) {
        case TIME_FILTER_PARAM:
            return timeFilterEventName
        case RESULT_SORT_PARAM:
            return resultSortEventName
        case TOPIC_FILTER_PARAM:
            return topicEventName
    }
}

export const getSearchEventArgs = (
    searchTerm: string,
    searchParams: URLSearchParams,
): SearchEventArgs => {
    const publishedSince = getPublishedSince(
        searchParams.get(TIME_FILTER_PARAM) ?? undefined,
    )
    const topics = searchParams.get(TOPIC_FILTER_PARAM) ?? defaultTopicOption
    const sortBy =
        searchParams.get(RESULT_SORT_PARAM) ?? relevanceSortOption.paramName

    return {
        searchTerm,
        topics,
        sortBy,
        publishedSince,
    }
}
