import styled from '@emotion/styled'
import { breakpointBetween } from '../../__styling'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledCountDown = styled('div')(({ theme }) => ({
    display: 'flex',

    [breakpointBetween('md', 'lg')]: {
        transform: themedValue(theme, {
            perthnow: `translate(${calcRem(8)}, ${calcRem(
                -1 * theme.margins.sm,
            )})`,
            fallback: undefined,
        }),
    },
}))

export const StyledLabel = styled('span')(({ theme }) => ({
    textAlign: 'center',
    display: 'block',
    fontSize: calcRem(13),
    color: theme.colors.palette.white,
}))

export const StyledItem = styled('span')(({ theme }) => ({
    fontSize: themedValue(theme, {
        fallback: calcRem(36),
        perthnow: calcRem(30),
        sevennews: calcRem(34),
    }),
    color: theme.colors.palette.white,
    fontWeight: 700,
    whiteSpace: 'pre-wrap',
}))

export const StyledElement = styled('div')<{ separator?: boolean }>(
    ({ separator, theme }) => ({
        textAlign: 'center',
        width:
            separator !== true
                ? themedValue(theme, {
                      fallback: calcRem(52),
                      sevennews: calcRem(45),
                  })
                : undefined,
        display: 'inline-block',
        flexDirection: 'column',
    }),
)
