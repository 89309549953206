import React from 'react'

export interface IconEmailSevennewsProps {
    title?: string
    className?: string
}

export const IconEmailSevennews: React.FC<IconEmailSevennewsProps> = ({
    className,
    title,
}) => (
    <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title || 'Email Us'}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.917969 1.08194C0.917969 0.54373 1.35427 0.107422 1.89248 0.107422H23.6757C24.2139 0.107422 24.6502 0.54373 24.6502 1.08194V14.8399C24.6502 16.6445 23.1873 18.1074 21.3828 18.1074H4.18546C2.38087 18.1074 0.917969 16.6445 0.917969 14.8399V1.08194ZM4.99046 2.05647L12.2327 7.11318C12.6941 7.43533 13.3091 7.42914 13.7639 7.09777L20.6832 2.05647H4.99046ZM22.7012 2.9977L14.9116 8.67306C13.7845 9.49427 12.2604 9.5096 11.1169 8.71124L2.867 2.95093V14.8399C2.867 15.5681 3.45729 16.1584 4.18546 16.1584H21.3828C22.1109 16.1584 22.7012 15.5681 22.7012 14.8399V2.9977Z"
        />
    </svg>
)
IconEmailSevennews.displayName = 'IconEmailSevennews'
