import { ConfigurationContext, useFeature } from '@news-mono/web-common'
import { ElectionDayData } from '@west-australian-newspapers/contentful-retrieval-utilities'
import * as React from 'react'
import { useContext } from 'react'
import { FederalElectionDriver } from '../navigation'
import { BarGraphWidget, HorseShoeWidget, PieChartWidget } from '../widgets'
import {
    StyledCollectionContainer,
    StyledElectionResultsText,
    StyledElectionTextContainer,
    StyledHorizontalGraphContainer,
    StyledHorseshoeContainer,
    StyledHorseShoeWidgetTextInnerContainer,
    StyledHorseShoeWidgetTextOuterContainer,
    StyledSeatsCountedTextContainer,
    StyledSeatsCountedTextOuterContainer,
    StyledVotesCountedContainer,
    StyledVotesCountedWidget,
} from './FederalElectionWidgetCollection.styled'
import { useElectionDayData } from './helpers/useElectionDayData'

export interface FederalElectionWidgetCollectionProps {
    layout?: 'horizontal' | 'vertical'
}

export const FederalElectionWidgetCollection = (
    props: FederalElectionWidgetCollectionProps,
) => {
    const driverEnabled = useFeature('live-coverage-driver')

    const config = useContext(ConfigurationContext)

    const result = useElectionDayData(config.contentfulStaticSiteEndpoint)

    const {
        isLoading,
        isError,
        data,
    }: {
        isLoading: boolean
        isError: boolean
        data: ElectionDayData | undefined
    } = result

    return (
        <StyledCollectionContainer layout={props.layout}>
            {!isLoading && !isError && data && (
                <React.Fragment>
                    <StyledHorizontalGraphContainer>
                        <StyledElectionTextContainer>
                            <span>LIVE</span>
                            <br />
                            <StyledElectionResultsText>
                                ELECTION PROJECTIONS
                            </StyledElectionResultsText>
                        </StyledElectionTextContainer>
                        <StyledSeatsCountedTextOuterContainer>
                            <StyledSeatsCountedTextContainer>
                                <span>SEATS WON</span>
                                <span>&nbsp;+ SEATS LIKELY</span>
                            </StyledSeatsCountedTextContainer>
                        </StyledSeatsCountedTextOuterContainer>
                        <BarGraphWidget {...data} />
                    </StyledHorizontalGraphContainer>
                    <StyledHorseshoeContainer>
                        <StyledHorseShoeWidgetTextOuterContainer>
                            <StyledHorseShoeWidgetTextInnerContainer>
                                <span>HOUSE OF REPRESENTATIVES</span>
                            </StyledHorseShoeWidgetTextInnerContainer>
                        </StyledHorseShoeWidgetTextOuterContainer>
                        <HorseShoeWidget {...data} />
                    </StyledHorseshoeContainer>
                    <StyledVotesCountedContainer>
                        <StyledVotesCountedWidget>
                            <PieChartWidget
                                percentage={data.votesCounted}
                                theme={'default'}
                                noMarginOnVoteCountedText={true}
                            />
                        </StyledVotesCountedWidget>
                        {driverEnabled && <FederalElectionDriver />}
                    </StyledVotesCountedContainer>
                </React.Fragment>
            )}
        </StyledCollectionContainer>
    )
}
