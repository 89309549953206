import {
    Product,
    Profile,
    PublicationEvents,
    RequiredAccess,
    SavedArticlesSaveEvent,
    SavedArticlesUnsaveEvent,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { FundingType } from '../../templates/Publication/lib/get-funding-type'
import { useProduct } from '../../__product/useProduct'
import { EventPostByline } from './EventPostByline'
import { pinnedChipTypes } from './helpers/getEventPostChip'
import { PerthNowByline } from './PerthNowByline'
import { SevenByline } from './SevenByline'
import { TheWestByline } from './TheWestByline'
import { TheNightlyByline } from './TheNightlyByline'

export interface BylineProps {
    text?: string
    profile?: Profile[]
    fundingType?: FundingType
    source?: string
    timestamp?: string
    timestampUpdated?: string
    /** Article URL used in `BylineMail` button */
    url?: string
    /** Article Title used in `BylineMail` button */
    title?: string
    isFullWidth: boolean
    requiredAccess?: RequiredAccess
    allowCommenting: boolean
    publicationKind?: 'article' | 'event' | 'event-post' | 'gallery'
    /** Is a Pinned Event Post */
    pinnedPost?: pinnedChipTypes
    /** Happening title of Happening Event Post */
    happeningPost?: string | null
    noLine?: boolean
    entryType?: string
    topic?: string
    readTimeMinutes?: number
    id?: string
    onEvent?: (
        event:
            | PublicationEvents
            | SavedArticlesSaveEvent
            | SavedArticlesUnsaveEvent,
    ) => void
}

export const Byline: React.FC<BylineProps> = ({
    text,
    isFullWidth,
    requiredAccess,
    profile,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    title,
    url,
    allowCommenting,
    publicationKind = 'article',
    pinnedPost,
    happeningPost,
    noLine,
    entryType,
    topic,
    readTimeMinutes,
    id,
    onEvent,
}) => {
    const product = useProduct()
    const commentToggle = useFeature('comments')
    const showComments = allowCommenting && commentToggle

    if (!text && !source && !fundingType) {
        return null
    }

    /**
     * The byline component is structured as follows:
     *
     *                    ↓----Byline Text----↓
     *  |~~~~~~~~~~~|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~~~~~~~~~~~~~~~|
     *  | profile   |   Top Row // Byline Content   | Button        |
     *  | pic       |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| Container     |
     *  |           |   Bottom Row // MetaData      |               |
     *  |___________|_______________________________|_______________|
     */

    /**
     * Custom Bylines
     */

    if (publicationKind === 'event-post') {
        return (
            <EventPostByline
                profile={profile}
                text={text}
                timestamp={timestamp}
                pinnedPost={pinnedPost}
                happeningPost={happeningPost}
                entryType={entryType}
            />
        )
    }

    /**
     * Masthead Specific Bylines -- Seven Bauhaus uses its own component
     */

    switch (product) {
        /**
         * Seven News Byline component -- currently in very limited use as Bauhaus uses
         * it's own custom byline component. DEPRECATED and should be removed once they have
         * moved everything over to the bauhaus 'theme'.
         */
        case Product.SevenNews:
            return (
                <SevenByline
                    isFullWidth={isFullWidth}
                    profile={profile}
                    fundingType={fundingType}
                    source={source}
                    timestamp={timestamp}
                    text={text}
                    requiredAccess={requiredAccess}
                    timestampUpdated={timestampUpdated}
                    allowCommenting={false} // hardcoded as seven has no commenting features
                    publicationKind={publicationKind}
                />
            )

        case Product.PerthNow:
            return (
                <PerthNowByline
                    isFullWidth={isFullWidth}
                    profile={profile}
                    fundingType={fundingType}
                    source={source}
                    timestamp={timestamp}
                    text={text}
                    requiredAccess={requiredAccess}
                    timestampUpdated={timestampUpdated}
                    allowCommenting={showComments}
                    publicationKind={publicationKind}
                    url={url}
                    title={title}
                />
            )

        case Product.TheNightly:
            return (
                <TheNightlyByline
                    isFullWidth={isFullWidth}
                    profile={profile}
                    source={source}
                    timestamp={timestamp}
                    timestampUpdated={timestampUpdated}
                    publicationKind={publicationKind}
                    text={text}
                    topic={topic}
                    readTimeMinutes={readTimeMinutes}
                    isLink={true}
                    allowCommenting={showComments}
                    url={url}
                    fundingType={fundingType}
                    id={id}
                    onEvent={onEvent}
                />
            )
        case Product.TheWest:
        default:
            return (
                <TheWestByline
                    isFullWidth={isFullWidth}
                    profile={profile}
                    fundingType={fundingType}
                    source={source}
                    timestamp={timestamp}
                    text={text}
                    requiredAccess={requiredAccess}
                    timestampUpdated={timestampUpdated}
                    allowCommenting={showComments}
                    publicationKind={publicationKind}
                    url={url}
                    title={title}
                    noLine={noLine}
                />
            )
    }
}
Byline.displayName = 'Byline'
