import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SponsoredTaboola, TaboolaProps } from './Taboola'

export type TaboolaRouteProps = Omit<TaboolaProps, 'ampPosition'>

export const TaboolaRegistration = createRegisterableComponent(
    'taboola',
    (props: TaboolaRouteProps) => <SponsoredTaboola {...props} />,
)
