import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledAccordionPanel = styled('div')<{
    hideHoverAndFocusBackground: boolean
}>(({ theme, hideHoverAndFocusBackground }) => ({
    position: 'relative',
    cursor: 'pointer',
    padding: calcRem(
        theme.margins.md + theme.margins.sm,
        theme.margins.md + theme.margins.sm,
        theme.margins.md,
        theme.margins.md + theme.margins.sm,
    ),
    fontFamily: theme.fonts.sansSerif,
    background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
    color: theme.colors.palette.white,
    marginTop: calcRem(theme.margins.sm),
    '&:hover': {
        background: hideHoverAndFocusBackground
            ? 'inherit'
            : `linear-gradient(246.22deg, #6C1D0F 0%, #0F017B 99.34%)`,
        color: theme.colors.palette.white,
    },
    '&:focus': {
        background: hideHoverAndFocusBackground
            ? 'inherit'
            : `linear-gradient(246.22deg, #6C1D0F 0%, #0F017B 99.34%)`,
        color: theme.colors.palette.white,
    },
    [breakpoint('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: calcRem(
            theme.margins.md + theme.margins.sm,
            100,
            theme.margins.md + theme.margins.sm,
            theme.margins.md + theme.margins.sm,
        ),
    },
}))

export const StyledAccordionPanelTitleLine = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.palette.white,
    width: '80px',
    height: '2px',
    marginTop: theme.margins.sm,
}))

export const StyledAccordionPanelTitle = styled('div')(() => ({
    lineHeight: 1,
    '& > div': {
        display: 'flex',
        [breakpoint('sm')]: {
            flexDirection: 'column',
        },
    },
}))

export const StyledAccordionPanelHeading = styled('span')<{
    isFullWidth: boolean
}>(({ theme, isFullWidth }) => ({
    fontWeight: 700,
    fontSize: calcRem(20),
    marginRight: theme.margins.xs,
    [breakpoint('lg')]: isFullWidth && {
        fontSize: calcRem(24),
        marginRight: 'auto',
    },
}))

export const StyledAccordionPanelSubeading = styled('span')<{
    isFullWidth: boolean
}>(({ isFullWidth }) => ({
    fontWeight: 400,
    fontSize: calcRem(20),
    [breakpoint('lg')]: isFullWidth && {
        fontSize: calcRem(24),
    },
}))

export const StyledAccordionPanelVotes = styled('div')(({ theme }) => ({
    margin: `${calcRem(theme.margins.md)} 0`,
    display: 'flex',
    alignItems: 'center',
    '& > div': {
        alignItems: 'center',
        display: 'flex',
    },
}))

export const StyledAccordionPanelVotesNumber = styled('span')<{
    isFullWidth: boolean
}>(({ isFullWidth }) => ({
    fontSize: calcRem(32),
    fontWeight: 700,
    [breakpoint('lg')]: isFullWidth && {
        fontSize: calcRem(48),
    },
}))

export const StyledAccordionPanelVotesParty = styled('span')<{
    isFullWidth: boolean
}>(({ theme, isFullWidth }) => ({
    fontSize: calcRem(16),
    fontWeight: 400,
    marginLeft: theme.margins.xs,
    paddingTop: theme.margins.sm,
    [breakpoint('lg')]: isFullWidth && {
        fontSize: calcRem(32),
        marginLeft: theme.margins.md,
    },
}))

export const StyledAccordionPanelVotesDivider = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.palette.white,
    width: '1px',
    height: '32px',
    margin: calcRem(theme.margins.xs, theme.margins.md, 0),
    [breakpoint('sm')]: {
        margin: calcRem(theme.margins.xs, theme.margins.lg, 0),
    },

    [breakpoint('md')]: {
        margin: calcRem(theme.margins.xs, theme.margins.md, 0),
    },
}))

export const StyledAccordionPanelCount = styled('div')(() => ({
    [breakpoint('sm')]: {
        lineHeight: 1,
        display: 'flex',
        flexDirection: 'column',
    },
}))

export const StyledAccordionChevron = styled('div')<{ isOpen: boolean }>(
    ({ theme, isOpen }) => ({
        cursor: 'pointer',
        position: 'absolute',
        bottom: calcRem(20),
        right: calcRem(theme.margins.md + theme.margins.sm),
        '& > svg': {
            fill: theme.colors.palette.white,
            width: calcRem(24),
        },
        transition: 'transform 0.35s ease',
        transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
        [breakpoint('sm')]: {
            bottom: '36%',
            right: calcRem(36),
        },
    }),
)

export const StyledAccordionPanelCountValue = styled('span')<{
    isFullWidth: boolean
}>(({ isFullWidth }) => ({
    fontSize: calcRem(24),
    fontWeight: 700,
    [breakpoint('lg')]: isFullWidth && {
        fontSize: calcRem(32),
    },
}))

export const StyledAccordionPanelCountSubtitle = styled('span')(
    ({ theme }) => ({
        fontSize: calcRem(16),
        fontWeight: 400,
        marginLeft: theme.margins.xs,
    }),
)
