import styled, { CSSObject } from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { breakpoint, calcRem, themedValue } from '../../__styling'
import { Breakpoints } from '../../__styling/settings/breakpoints'
import { ContainerWidth, metrics } from '../../__styling/settings/metrics'
import { TabBreakPointProps, TabSpacingProps } from './Tab'

export interface StyledTabProps extends TabSpacingProps {
    containerWidth?: ContainerWidth
    breakpoints?: TabBreakPointProps
}

const getStylesFromProps = (
    props: StyledTabProps,
    kind: Product,
): Array<CSSObject | false | undefined> => {
    const margins = metrics[kind].margins

    const horizontalSpacing =
        props.horizontalSpacing && calcRem(margins[props.horizontalSpacing])
    const verticalSpacing =
        props.verticalSpacing && calcRem(margins[props.verticalSpacing])
    const horizontalGutters =
        props.horizontalGutters && calcRem(margins[props.horizontalGutters])
    const topVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[0]])
    const bottomVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[1]])

    return [
        {
            marginLeft: horizontalSpacing,
            marginRight: horizontalSpacing,
            marginBottom: verticalSpacing,
            marginTop: verticalSpacing,
            paddingLeft: horizontalGutters,
            paddingRight: horizontalGutters,
            paddingTop: topVerticalGutters,
            paddingBottom: bottomVerticalGutters,
        },
    ]
}

type BreakpointKey = keyof Breakpoints

export const StyledTab = styled('div')<StyledTabProps>(
    ({ theme }) => ({
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        [breakpoint('md')]: {
            padding: themedValue(theme, {
                sevennews: calcRem(metrics.sevennews.margins.lg),
                perthnow: 0,
                fallback: undefined,
            }),
            paddingTop: 0,
        },
    }),
    ({ theme, breakpoints }) =>
        ({
            theme,
            verticalSpacing,
            horizontalGutters,
            verticalGutters,
            containerWidth,
            horizontalSpacing,
        }) => {
            const breakpointStyles = Object.keys(
                breakpoints || {},
            ).reduce<CSSObject>((styles, key) => {
                const passedBreakpoints = breakpoints as TabBreakPointProps
                const props = passedBreakpoints[key as BreakpointKey]
                if (props) {
                    styles[breakpoint(key as BreakpointKey)] =
                        getStylesFromProps(props, theme.kind)
                }
                return styles
            }, {})

            return [
                ...getStylesFromProps(
                    {
                        horizontalGutters,
                        verticalGutters,
                        verticalSpacing,
                        horizontalSpacing,
                    },
                    theme.kind,
                ),
                {
                    maxWidth: containerWidth,
                },
                breakpointStyles,
            ]
        },
)

export const StyledTabContent = styled('div')({
    flexBasis: '100%',
})
