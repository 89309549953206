import { createRegisterableComponentWithData } from '@news-mono/web-common'
import React from 'react'
import { ArticlePublicationDataDefinitionLoader } from '../../templates/Publication/ArticlePublicationDataLoader'
import { RelatedContent } from './RelatedContent'
import { RelatedContentRegistrationProps } from './RelatedContent.routing'

export const PublicationRelatedContentRegistration =
    createRegisterableComponentWithData(
        'publication-related-content',
        ArticlePublicationDataDefinitionLoader,
        // eslint-disable-next-line no-empty-pattern
        (
            {
                title,
                displayMode,
                numberOfItems,
            }: RelatedContentRegistrationProps,
            data,
            services,
        ) => {
            if (data.loaded) {
                const { relatedPublications } = data.result
                const relatedItems =
                    relatedPublications && relatedPublications.items

                if (relatedItems) {
                    return (
                        <RelatedContent
                            hideSeparator={true}
                            numberOfItems={numberOfItems ?? 6}
                            items={relatedItems}
                            onEvent={services.onEvent}
                            title={title}
                            displayMode={displayMode}
                        />
                    )
                }
            }

            return null
        },
    )
