import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../__styling'

interface StyledTimelineCardContainerProps {
    isRushed: boolean
    brandHexCode: string
}
export const StyledTimelineCardContainer = styled(
    'div',
)<StyledTimelineCardContainerProps>(({ isRushed, brandHexCode }) => ({
    width: calcRem(320),
    height: calcRem(80),
    background: isRushed
        ? `linear-gradient(90deg, ${brandHexCode} 0%, rgba(255,255,255,1) 250%)`
        : tokens.sevennews.colors.palette.white,
    padding: `${calcRem(5)} ${calcRem(10)}`,
    borderRadius: calcRem(3),
    color: isRushed
        ? tokens.sevennews.colors.palette.white
        : tokens.sevennews.colors.palette.bauhausBlack,
    overflow: 'hidden',
    userSelect: 'none',
    position: 'relative',
}))

export const StyledTimelineCardOuter = styled('div')({
    filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
    position: 'relative',
})

interface StyledTimelineCardScoreTypeProps {
    isRushed: boolean
}
export const StyledTimelineCardScoreType = styled(
    'div',
)<StyledTimelineCardScoreTypeProps>(({ isRushed }) => ({
    fontSize: calcRem(24),
    fontWeight: 900,
    textTransform: 'uppercase',
    lineHeight: calcRem(30),
    color: isRushed ? tokens.sevennews.colors.palette.white : 'unset',
}))

export const StyledTimelineCardFirstname = styled('span')({
    fontSize: calcRem(18),
    lineHeight: calcRem(22),
    textTransform: 'uppercase',
})

export const StyledTimelineCardLastname = styled('span')({
    fontSize: calcRem(18),
    lineHeight: calcRem(22),
    fontWeight: 700,
    textTransform: 'uppercase',
})

interface StyledTimelineCardScoreContainerProps {
    isRushed: boolean
}
export const StyledTimelineCardScoreContainer = styled(
    'div',
)<StyledTimelineCardScoreContainerProps>(({ isRushed }) => ({
    position: 'absolute',
    bottom: calcRem(5),
    fontSize: calcRem(12),
    color: isRushed ? tokens.sevennews.colors.palette.white : 'unset',
}))

export const StyledTimelineCardRushedLabel = styled('span')({
    fontSize: calcRem(18),
    marginRight: calcRem(12),
})

export const StyledTimelineCardGoalScore = styled('span')({
    fontWeight: 700,
})

export const StyledTimelineCardBehindScore = styled('span')({})

export const StyledTimelineCardQuarter = styled('span')({
    fontWeight: 700,
})

export const StyledTimelineCardTime = styled('span')({})

interface StyledTimelineCardLogoBackgroundProps {
    brandHexCode: string
}

export const StyledTimelineCardLogoBackground = styled(
    'div',
)<StyledTimelineCardLogoBackgroundProps>(({ brandHexCode }) => ({
    transform: 'rotate(30deg)',
    backgroundColor: brandHexCode,
    position: 'absolute',
    width: calcRem(120),
    height: calcRem(140),
    top: calcRem(-20),
    right: calcRem(-20),
    background: `linear-gradient(90deg, ${brandHexCode} 0%, rgba(255,255,255,1) 250%)`,
}))

export const StyledTimelineCardLogo = styled('img')({
    height: calcRem(56),
    position: 'absolute',
    right: calcRem(16),
    top: calcRem(12),
    maxWidth: calcRem(72),
})
