import { layout } from '../../App.routing'

export const moreSportSegment = layout.component({
    type: 'lima',
    props: {
        verticalSpacing: 'xl',
        fixedRatios: ['16:9'],
        cardOrientation: {
            type: 'Landscape-Portrait',
        },
        finalColumns: 4,
        intermediateColumns: 2,
        hasBackground: true,

        cardLayout: [
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            {
                type: 'november',
                listLength: 5,
            },
        ],
        dataDefinitionArgs: {
            type: 'curation',
            name: 'sport',
            offset: 0,
            pageSize: 8,
        },
        sectionHeader: {
            heading: 'More Sport',
            headingUrl: '/sport',
        },
    },
})
