import React from 'react'
export const PlayButton = () => (
    <svg
        width="44"
        height="40"
        viewBox="0 0 44 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 0.999999C0 0.447714 0.447715 0 1 0H43.3333L20 40H1C0.447715 40 0 39.5523 0 39V0.999999Z"
            fill="#FF0000"
        />
        <path
            d="M13.333 25.9421V14.0573C13.333 13.79 13.6561 13.6561 13.8451 13.8451L19.7875 19.7875C19.9047 19.9047 19.9047 20.0946 19.7875 20.2118L13.8451 26.1542C13.6562 26.3432 13.333 26.2093 13.333 25.9421Z"
            fill="#E5E5E5"
            stroke="#E5E5E5"
        />
    </svg>
)
