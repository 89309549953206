import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { sortBy } from 'ramda'

/**
 * @param item Either a published or killed Event Post
 * @returns True if item is a published event post
 */
export const isPublishedEventPost = (
    item: EventPostV4DTO,
): item is EventPostV4DTO => {
    return (
        'kind' in item &&
        item.kind === 'event-post' &&
        item.state === 'published'
    )
}

/**
 * @param item Either a published or killed Event Post
 * @returns True if item is a published milestone
 */
export const isPublishedMilestone = (
    item: EventPostV4DTO,
): item is EventPostV4DTO => {
    return (
        'kind' in item &&
        item.kind === 'event-post' &&
        item.state === 'published' &&
        item.milestone === true
    )
}
/**
 * @param item Either a published or killed Event Post
 * @returns True if item is a published Pinned / Stick post
 */
export const isPublishedPinnedPost = (
    item: EventPostV4DTO,
): item is EventPostV4DTO => {
    return (
        'kind' in item &&
        item.kind === 'event-post' &&
        item.state === 'published' &&
        item.sticky === true
    )
}

/**
 * Find the index of an event post in an array of posts
 * @param posts An array of event posts
 * @param id the ID of the post
 * @returns Index
 */
export const findIndex = (posts: EventPostV4DTO[], id: string) =>
    posts.findIndex((a) => a.id === id)

/**
 * Sort Event Posts by publishDate
 * @param posts An array of event posts
 * @returns A array of event posts sorted by publishedDate in descending order
 */

export const sortPostsDescFunc = (
    post: EventPostV4DTO | KilledEventPostV4DTO,
) => toEpoch(post.publishedDate) * -1

export const sortPostsDesc = <T extends { publishedDate: string }>(
    posts: Array<T>,
): Array<T> => {
    return sortBy<T>((post) => toEpoch(post.publishedDate) * -1, posts)
}

/**
 * @param array An array of objects
 * @returns The last object in the array if found
 */
export const getLast = <T>(array: T[]): T | undefined => array[array.length - 1]

/**
 * Convert a date time string to epoch time
 * @param dt Date/Time string
 * @returns
 */
export const toEpoch = (dt: string) => new Date(dt).getTime() / 1000

/**
 * Adds posts to an array of posts, updating an existing matching post if found,
 * otherwise adding a new one.
 */
export const updatePostList = (
    list: EventPostV4DTO[],
    ...newPosts: EventPostV4DTO[]
) => {
    const newList = [...list]

    toNextPost: for (const newPost of newPosts) {
        // Insert/update into array at correct position.
        // If this ever becomes slow, could probably use binary indexing via dates.
        // currently this is O(n^2) worst case scenario.
        for (const [i, post] of newList.entries()) {
            if (toEpoch(newPost.publishedDate) > toEpoch(post.publishedDate)) {
                // Post occurs chronologically after current post. Insert before it.
                newList.splice(i, 0, newPost)
                continue toNextPost
            }
            if (post.id === newPost.id) {
                // Outdated / Duplicate post.
                if (toEpoch(newPost.lastUpdated) <= toEpoch(post.lastUpdated)) {
                    continue toNextPost
                }

                // Update element in array.
                newList.splice(i, 1, newPost)

                continue toNextPost
            }
        }

        newList.push(newPost)
    }

    return newList
}

/**
 * Removes a post from a list.
 */
export const deleteFromPostList = (
    deletedPost: KilledEventPostV4DTO,
    list: EventPostV4DTO[],
) => {
    const index = findIndex(list, deletedPost.id)

    // Deleted post not found in list.
    if (index === -1) {
        return list
    }

    // Deletion request is outdated.
    if (toEpoch(deletedPost.lastUpdated) <= toEpoch(list[index].lastUpdated)) {
        return list
    }

    const newList = [...list]
    newList.splice(index, 1)

    return newList
}
