export interface PNBorderRadius {
    sm: number
    lg: number
    kicker: number
}

export const borderRadius = {
    perthnow: {
        sm: 4,
        lg: 6,
        kicker: 15,
    } as PNBorderRadius,
}
