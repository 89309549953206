import React from 'react'

export function TheWestHeaderPremiumIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="white" />
            <path
                d="M22 11.7993C21.922 10.0713 21.1759 9.52508 20.6192 9.19064C20.0624 8.85619 19.3942 8.61093 17.0779 8.61093C15.6971 8.61093 15.2962 7.23969 15.2962 6.13601C15.2962 5.03233 15.441 4.14047 14.6281 3.19287C13.9761 2.48426 13.0725 2.05997 12.1114 2.01115L12 2L11.8886 2.01115C11.8886 2.01115 10.3185 2.03344 9.3608 3.18172C8.57016 4.12932 8.69265 5.02118 8.69265 6.13601C8.69265 7.23969 8.30289 8.61093 6.91091 8.61093C4.60579 8.61093 3.9265 8.85619 3.36971 9.19064C2.81292 9.52508 2.07795 10.0825 2 11.7993V12.223C2.07795 13.9398 2.81292 14.4861 3.36971 14.8205C3.9265 15.155 4.60579 15.4002 6.91091 15.4002C8.30289 15.4002 8.69265 16.7715 8.69265 17.8751C8.69265 18.99 8.55902 19.8818 9.3608 20.8294C10.0442 21.5637 10.9976 21.9865 12 22H12.1114C12.1114 22 13.6704 21.9666 14.6281 20.8294C15.441 19.8707 15.2962 18.9788 15.2962 17.8751C15.2962 16.7715 15.6971 15.4002 17.0779 15.4002C19.3831 15.4002 20.0624 15.155 20.6192 14.8205C21.1759 14.4861 21.922 13.9398 22 12.223V12V11.7993Z"
                fill="url(#paint0_linear_131_12855)"
            />
            <path
                d="M15.2962 6.13601C15.2962 5.03233 15.441 4.14047 14.6281 3.19287C13.9761 2.48426 13.0725 2.05997 12.1114 2.01115L12 2L11.8886 2.01115C11.8886 2.01115 10.3185 2.03344 9.3608 3.18172C8.57015 4.12932 8.69265 5.02118 8.69265 6.13601C8.69265 7.23969 8.30289 8.61093 6.91091 8.61093C4.60579 8.61093 3.9265 8.85619 3.36971 9.19064C2.81292 9.52508 2.07795 10.0825 2 11.7993V12.223C2.07795 13.9398 2.80178 14.4749 3.36971 14.8205C4.01559 15.2107 4.97327 15.4225 6.91091 15.4002C10.196 15.3668 15.2962 10.0045 15.2962 6.13601Z"
                fill="url(#paint1_linear_131_12855)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_131_12855"
                    x1="11.9349"
                    y1="-1.50499"
                    x2="-1.6051"
                    y2="12.0652"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#043DE2" />
                    <stop offset="1" stopColor="#1257E0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_131_12855"
                    x1="9.28672"
                    y1="-4.28076"
                    x2="-4.42534"
                    y2="9.10329"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F400AB" />
                    <stop offset="1" stopColor="#AA01E1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
