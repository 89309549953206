import { useEffect, useState } from 'react'
import { TableDataProps } from '../AflPlayerStatsWidget'

export const useSortableTable = (
    data: TableDataProps[],
    defaultSortColumn: string,
    defaultSortOrder: string,
): [
    tableData: TableDataProps[],
    handleSorting: (sortField: string, sortOrder: string) => void,
] => {
    const [tableData, setTableData] = useState<TableDataProps[]>(data)

    useEffect(() => {
        //default sorting
        const defaultSortedData = sortTableByColumn(
            data,
            defaultSortColumn,
            defaultSortOrder,
        )
        setTableData(defaultSortedData)
    }, [data, defaultSortColumn, defaultSortOrder])
    useEffect(() => {
        setTableData(
            sortTableByColumn(data, defaultSortColumn, defaultSortOrder),
        )
    }, [data, defaultSortColumn, defaultSortOrder])

    const handleSorting = (sortField: string, sortOrder: string) => {
        if (sortField) {
            const sorted = sortTableByColumn(tableData, sortField, sortOrder)
            setTableData(sorted)
        }
    }

    return [tableData, handleSorting]
}

export const sortTableByColumn = (
    tableData: TableDataProps[],
    sortField: string,
    sortOrder: string,
) => {
    const sorted = [...tableData].sort(
        (a: TableDataProps, b: TableDataProps) => {
            if (a[sortField as keyof TableDataProps] === null) return 1
            if (b[sortField as keyof TableDataProps] === null) return -1
            if (
                a[sortField as keyof TableDataProps] === null &&
                b[sortField as keyof TableDataProps]
            )
                return 0

            return (
                a[sortField as keyof TableDataProps]
                    .toString()
                    .localeCompare(
                        b[sortField as keyof TableDataProps].toString(),
                        'en',
                        { numeric: true },
                    ) * (sortOrder === 'asc' ? 1 : -1)
            )
        },
    )
    return sorted
}
