import { Action } from 'redux'
import { RenderTarget } from '../../context-providers/render-target-context'

const SET_RENDITION_TYPE = 'SET_RENDITION_TYPE'

export interface SET_RENDITION_TYPE_ACTION extends Action {
    type: typeof SET_RENDITION_TYPE
    payload: Pick<RenderState, 'renditionType'>
}

export const setRenditionType = (renditionType: RenderTarget) => ({
    type: SET_RENDITION_TYPE,
    payload: { renditionType },
})

export interface RenderState {
    renditionType: RenderTarget
    appPlatform?: 'ios' | 'android'
}

export const renditionTypeReducer = (
    state: RenderState = { renditionType: 'web' },
    action: SET_RENDITION_TYPE_ACTION | { type: '@@INIT' },
) => {
    switch (action.type) {
        case SET_RENDITION_TYPE:
            return { renditionType: action.payload.renditionType }
        default:
            return state
    }
}
