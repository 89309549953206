import React from 'react'

export interface IconFacebookProps {
    title?: string
    className?: string
}

export const IconFacebook: React.FC<IconFacebookProps> = ({
    className,
    title,
}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 438 817.8"
    >
        <title>{title || 'Share to Facebook'}</title>
        <path d="M409.3 460L432 312H290v-96c0-40.5 19.8-80 83.4-80H438V10S379.4 0 323.4 0C206.4 0 130 70.9 130 199.2V312H0v148h130v357.8h160V460h119.3z" />
    </svg>
)
IconFacebook.displayName = 'IconFacebook'
