import {
    CollectionEvent,
    createCardClickedEvent,
    createCollectionAvailableEvent,
    Product,
    PublicationCardItem,
    PublicationEvents,
    requiredAccessValue,
    toLinkWithHint,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import {
    StyledIconPremium,
    StyledListItem,
    StyledRelatedContent,
    StyledRelatedContentContainer,
    StyledSectionHeader,
} from '../../content/RelatedContent/RelatedContent.styled'
import { StyledTextLink } from '../../typography/TextLink/TextLink.styled'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import {
    StyledGridItem,
    StyledJuliet,
} from '../../collections/Juliet/Juliet.styled'
import {
    renderLandscape,
    renderLandscapeNightly,
} from '../../collections/Juliet/Juliet'

export interface RelatedContentProps {
    items: PublicationCardItem[]
    onEvent: (event: PublicationEvents) => void
    title?: string
    numberOfItems?: number
    hideSeparator?: boolean
    displayMode?: 'list' | 'juliet'
}

let sortFunc = () => 0.5 - Math.random()

// The randomisation is problematic in testing so we make it less random
if (
    process &&
    process.env &&
    (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'vr_test')
) {
    sortFunc = () => 0
}

const shuffleAndSlice = (items: PublicationCardItem[], numberOfItems: number) =>
    [...items].sort(sortFunc).slice(0, numberOfItems)

export const RelatedContent: React.FC<RelatedContentProps> = ({
    items,
    onEvent,
    title,
    numberOfItems,
    hideSeparator,
    displayMode,
}) => {
    const product = useProduct()
    const [relatedContent, setRelatedContent] = useState<PublicationCardItem[]>(
        shuffleAndSlice(items, numberOfItems || 3),
    )

    useEffect(() => {
        setRelatedContent(shuffleAndSlice(items, numberOfItems || 3))
    }, [items, numberOfItems])

    const ListItems = relatedContent.map<JSX.Element>((item, index) => {
        const icon = requiredAccessValue(item.requiredAccess, {
            subscriber: <StyledIconPremium />,
            registered: <StyledIconPremium />,
            anonymous: undefined,
        })
        return (
            <StyledListItem key={index}>
                <StyledTextLink
                    to={toLinkWithHint({ loaded: true, result: item })}
                    onClick={() => {
                        onEvent(
                            createCardClickedEvent(
                                onEvent,
                                item,
                                'RelatedContent',
                                'List',
                                index,
                                undefined,
                                'relatedcontent',
                            ),
                        )
                    }}
                >
                    {item.shortHeadline}
                </StyledTextLink>
                {icon}
            </StyledListItem>
        )
    })

    const LandscapeItems = relatedContent.map<JSX.Element>((item, index) => {
        return (
            <StyledGridItem key={index} noHorizontalDividers={false}>
                {product === Product.TheNightly
                    ? renderLandscapeNightly(
                          { result: item, loaded: true },
                          index + 1,
                          { type: 'landscape', format: 'landscape-common' },
                          {
                              cardType: {
                                  type: 'landscape',
                                  format: 'landscape-common',
                              },
                              onEvent: () =>
                                  onEvent(
                                      createCardClickedEvent(
                                          onEvent,
                                          item,
                                          'RelatedContent',
                                          'Landscape',
                                          index,
                                          undefined,
                                          'relatedcontent',
                                      ),
                                  ),
                              items: { result: items, loaded: true },
                              expectedCards: 3,
                              hideByline: false,
                              teaserMode: 'hidden',
                          },
                      )
                    : renderLandscape(
                          { result: item, loaded: true },
                          index + 1,
                          { type: 'landscape', format: 'landscape-common' },
                          {
                              cardType: {
                                  type: 'landscape',
                                  format: 'landscape-common',
                              },
                              onEvent: () =>
                                  onEvent(
                                      createCardClickedEvent(
                                          onEvent,
                                          item,
                                          'RelatedContent',
                                          'Landscape',
                                          index,
                                          undefined,
                                          'relatedcontent',
                                      ),
                                  ),
                              items: { result: items, loaded: true },
                              expectedCards: 3,
                              hideByline: false,
                              teaserMode: 'hidden',
                          },
                      )}
            </StyledGridItem>
        )
    })

    const ListType = <StyledRelatedContent>{ListItems}</StyledRelatedContent>
    const JulietType = (
        <StyledJuliet as={'aside'} verticalSpacing={'lg'}>
            {LandscapeItems}
        </StyledJuliet>
    )

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent(
                    createCollectionAvailableEvent(
                        relatedContent,
                        'RelatedContent',
                        product,
                        onEvent,
                        {
                            componentType: 'relatedcontent',
                        },
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledRelatedContentContainer
                    hideSeparator={hideSeparator}
                    ref={ref}
                >
                    <StyledSectionHeader
                        heading={title ? title : 'From around the site'}
                    />
                    {displayMode === 'juliet' ? JulietType : ListType}
                </StyledRelatedContentContainer>
            )}
        </ImpressionAvailable>
    )
}
RelatedContent.displayName = 'RelatedContent'
