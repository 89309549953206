import { useContext } from 'react'
import {
    errorToastSubText,
    errorToastText,
    unsaveArticleFromUserProfile,
} from '../helpers'
import { ConfigurationContext } from '@news-mono/web-common'
import React from 'react'
import { useOnClickOutside } from '../../__helpers/on-click-outside'
import { getButtonOnClick } from '../../buttons/Sharing/utils/get-button-on-click'
import { ToastMessage } from '../../content/UserSettingsSlideToast/UserSettingsSlideToast'

interface UseSavedArticleContextMenuProps {
    savedArticleContextMenuContainer: React.MutableRefObject<HTMLElement | null>
    setToast: (toast: ToastMessage) => void
}

export const useSavedArticleContextMenu = ({
    savedArticleContextMenuContainer,
    setToast,
}: UseSavedArticleContextMenuProps) => {
    const config = useContext(ConfigurationContext)

    const [isHidden, setHidden] = React.useState(true)
    const hide = React.useCallback(() => {
        setHidden(true)
    }, [])
    const show = React.useCallback(() => {
        setHidden(false)
    }, [])

    useOnClickOutside(
        savedArticleContextMenuContainer,
        () => {
            hide()
        },
        { enabled: !isHidden },
    )

    const onUnsaveClick = async (interactionId?: number) => {
        hide()
        if (interactionId) {
            const unsaveArticleResult = await unsaveArticleFromUserProfile(
                config,
                String(interactionId),
            )
            if (unsaveArticleResult && unsaveArticleResult.success) {
                location.reload()
            } else {
                setToast({
                    text: unsaveArticleResult.statusText,
                    subText: errorToastSubText,
                    type: 'error',
                    show: true,
                })
            }
        } else {
            setToast({
                text: errorToastText,
                subText: errorToastSubText,
                type: 'error',
                show: true,
            })
        }
    }

    const onCopyLinkClick = async (url: string) => {
        const onClick = getButtonOnClick('clipboard', url, '', '')

        onClick()
        setToast({
            text: 'Link copied',
            type: 'information',
            show: true,
        })
        hide()
    }

    return {
        isHidden,
        show,
        hide,
        onUnsaveClick,
        onCopyLinkClick,
        useOnClickOutside,
    }
}
