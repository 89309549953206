import { ElectionDayData } from '@west-australian-newspapers/contentful-retrieval-utilities'
import React, { useEffect, useState } from 'react'
// Contains 151 seats for rendering the SVG
import { circles } from './data/circles'
import {
    StyledGraphDescriptionDesktop,
    StyledGraphDescriptionMobile,
    StyledLowerHouseSeatsContainer,
    StyledLowerHouseSeatsCount,
    StyledLowerHouseSeatsCountNumber,
    StyledLowerHouseSeatsCountTitle,
    StyledLowerHouseSeatsSvg,
} from './HorseShoeWidget.styled'

const getFixedArray = (length: number) => {
    const array = []
    while (length--) {
        array.push('')
    }
    return array
}

export interface HorseShoeWidgetProps {
    theme: string
    percentage: number
}

export const HorseShoeWidget: React.FunctionComponent<ElectionDayData> = (
    props,
) => {
    const [selectedParty, setSelectedParty] = useState<string>('')
    const [selectedPartySeatCount, setSelectedPartySeatCount] = useState<
        number | undefined
    >()

    useEffect(() => {
        let seatCount
        switch (selectedParty.toLowerCase()) {
            case 'labor':
                seatCount = props.labor + props.laborLikely
                break
            case 'coalition':
                seatCount = props.coalition + props.coalitionLikely
                break
            case 'greens':
                seatCount = props.greens + props.greensLikely
                break
            case 'other':
                seatCount = props.other + props.otherLikely
                break
            case 'undecided':
                seatCount = props.undecided
                break
        }
        setSelectedPartySeatCount(seatCount)
    }, [selectedParty, props])

    const renderPaths = (pollingData: ElectionDayData) => {
        let index = 0
        const renderPartyPaths = (seats: number, party: string) => {
            return getFixedArray(seats).map((_) => {
                const circle = circles[index++]
                return [
                    React.cloneElement(circle, {
                        'data-party': party,
                        key: index,
                    }),
                    // duplicate larger transparent circle to fix mobile tap
                    React.cloneElement(circle, {
                        'data-mappedparty': party,
                        key: 'transparent' + index,
                        onClick: (
                            event: React.MouseEvent<SVGCircleElement>,
                        ) => {
                            const targetParty = String(
                                (event.target as SVGCircleElement).dataset
                                    .mappedparty,
                            )
                            setSelectedParty(
                                selectedParty === targetParty
                                    ? ''
                                    : targetParty,
                            )
                        },
                        fill: 'transparent',
                        r: '24',
                    }),
                ]
            })
        }

        /**
         * Create an array containing the 151 SVG paths that create the
         * Lower House Seats map with associated colours
         * Number of seats is fixed, API will always return 151 total seats
         */
        return [
            ...renderPartyPaths(pollingData.labor, 'Labor'),
            ...renderPartyPaths(pollingData.laborLikely, 'Labor'),
            ...renderPartyPaths(pollingData.undecided, 'Undecided'),
            ...renderPartyPaths(pollingData.other, 'Other'),
            ...renderPartyPaths(pollingData.otherLikely, 'Other'),
            ...renderPartyPaths(pollingData.greensLikely, 'Greens'),
            ...renderPartyPaths(pollingData.greens, 'Greens'),
            ...renderPartyPaths(pollingData.coalitionLikely, 'Coalition'),
            ...renderPartyPaths(pollingData.coalition, 'Coalition'),
        ]
    }

    return (
        <StyledLowerHouseSeatsContainer>
            <StyledLowerHouseSeatsSvg
                xmlns="http://www.w3.org/2000/svg"
                width="472"
                height="467"
                viewBox="0 0 472 467"
                selectedParty={selectedParty}
            >
                <title>Lower House Seats</title>
                <g>{renderPaths(props)}</g>
            </StyledLowerHouseSeatsSvg>
            {selectedParty !== '' && (
                <StyledLowerHouseSeatsCount data-party={selectedParty}>
                    <StyledLowerHouseSeatsCountNumber>
                        {selectedPartySeatCount}
                    </StyledLowerHouseSeatsCountNumber>
                    <StyledLowerHouseSeatsCountTitle>
                        {selectedParty}
                    </StyledLowerHouseSeatsCountTitle>
                </StyledLowerHouseSeatsCount>
            )}
            {selectedParty === '' && (
                <>
                    <StyledGraphDescriptionMobile>
                        Tap graph for seat count
                    </StyledGraphDescriptionMobile>
                    <StyledGraphDescriptionDesktop>
                        Click graph for seat count
                    </StyledGraphDescriptionDesktop>
                </>
            )}
        </StyledLowerHouseSeatsContainer>
    )
}
