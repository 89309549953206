import { AppState, ConsentLevel, useConsentGiven } from '@news-mono/web-common'
import { useSelector } from 'react-redux'
/**
 * For users who have disallowed Ad Tracking, we use this to shift the video player
 * into GDPR mode. Ads will still be delivered but GAM will not attach user tracking to it
 * @returns boolean
 */
export function useGdprMode(): boolean {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const consentedToAdTracking = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Advertising,
    })

    if (renditionType === 'app' && !consentedToAdTracking) {
        return true
    }

    return false // fall back to allowing ad tracking everywhere
}
