import { CSSObject, FunctionInterpolation, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { createPropFilter, WebLink } from '@news-mono/web-common'
import { FilterProperty } from 'csstype'
import {
    FundingLabel,
    FundingLabelProps,
} from '../../advertising/FundingLabel/FundingLabel'
import {
    StyledLinkTitle as FundingLinkTitle,
    StyledTitle as FundingTitle,
} from '../../advertising/FundingLabel/FundingLabel.styled'
import {
    ProfileEmailButton,
    ProfileEmailButtonProps,
} from '../../buttons/ProfileEmailButton/ProfileEmailButton'
import { AmpImage } from '../../content/Picture/Picture.amp'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { TextLink } from '../../typography/TextLink/TextLink'
import { Timestamp } from '../../typography/Timestamp/Timestamp'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import {
    NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE,
    PROFILE_IMAGE_DISPLAY_SIZE,
} from './components/BylineProfile'
import { SrcLogoProps } from './components/SourceLogo'

export interface StyledBylineProps {
    isFullWidth: boolean
    noLine?: boolean
}

export const StyledByline = styled('address')<StyledBylineProps>(
    {},
    ({ theme, isFullWidth, noLine }) => ({
        breakWords,
        display: 'flex',
        flexWrap: themedValue(theme, {
            thenightly: 'nowrap',
            fallback: 'wrap',
        }),
        justifyContent: 'space-between',
        fontWeight: 400,
        fontFamily: theme.fonts.publication.byline,
        fontSize: calcRem(
            themedValue(theme, {
                sevennews: 10,
                perthnow: 16,
                thewest: 15,
                fallback: 10,
            }),
        ),
        color: themedValue(theme, {
            sevennews: colors.sevennews.charade,
            perthnow: colors.perthnow.greyCoal,
            thewest: colors.thewest.greyGorilla,
            thenightly: undefined,
        }),

        lineHeight: themedValue(theme, { fallback: 1, thewest: 1.22 }),
        borderBottom: themedValue(theme, {
            sevennews: `1px solid ${colors.sevennews.alto}`,
            perthnow: `4px solid ${colors.perthnow.greyPyrite}`,
            thewest: undefined,
            thenightly: undefined,
        }),
        paddingBottom: themedValue(theme, {
            sevennews: calcRem(metrics.sevennews.margins.md),
            perthnow: calcRem(metrics.perthnow.margins.sm),
            thewest: undefined,
            thenightly: undefined,
        }),
        width: isFullWidth ? '100%' : undefined,

        [breakpoint('xs')]: {
            flexWrap: 'nowrap',
            borderBottom: themedValue(theme, {
                sevennews: `1px solid ${colors.sevennews.alto}`,
                perthnow: `4px solid ${colors.perthnow.greyPyrite}`,
                thewest: noLine ? `` : `2px solid ${colors.thewest.greySeal}`,
                thenightly: undefined,
            }),
            paddingBottom: themedValue(theme, {
                sevennews: calcRem(metrics.sevennews.margins.md),
                perthnow: calcRem(metrics.perthnow.margins.sm),
                thewest: calcRem(metrics.thewest.margins.md),
                thenightly: undefined,
            }),
        },
    }),
)

export const StyledBylineText = styled('div')(
    ({ theme }) =>
        theme.kind !== 'sevennews' && {
            flexGrow: 0,
            flexShrink: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexBasis: 'auto',
            marginRight: 'auto',
        },
)

export const StyledHeadshot = styled('img')(({ theme }) => [
    {
        width: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),
        // Filter is an array function, so need to be explict with types
        filter: themedValue<
            FilterProperty | undefined,
            FilterProperty | undefined,
            FilterProperty | undefined,
            FilterProperty | undefined
        >(theme, {
            fallback: 'grayscale(100%)',
            perthnow: undefined,
        }),
    },
    theme.kind === 'thenightly' && {
        width: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
    },
])

export const StyledHeadshotAmp = StyledHeadshot.withComponent(AmpImage)

export const StyledHeadshotWrapper = styled('div')(({ theme }) => [
    {
        display: 'none',
        width: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),

        [breakpoint('xs')]: {
            display: 'block',
            borderRadius: '50%',
            marginRight: calcRem(10),
            overflow: 'hidden',
        },
    },
    theme.kind === 'thenightly' && {
        display: 'block',
        borderRadius: '50%',
        marginRight: calcRem(10),
        overflow: 'hidden',
        width: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
        minWidth: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
    },
])

export const StyledHeadshotWrapperLink = styled(WebLink)(({ theme }) => [
    {
        display: 'none',
        width: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(PROFILE_IMAGE_DISPLAY_SIZE),

        [breakpoint('xs')]: {
            display: 'block',
            borderRadius: '50%',
            marginRight: calcRem(10),
            overflow: 'hidden',
        },
    },
    theme.kind === 'thenightly' && {
        display: 'block',
        borderRadius: '50%',
        marginRight: calcRem(10),
        overflow: 'hidden',
        width: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
        height: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
        minWidth: calcRem(NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE),
    },
])

export const StyledBylineTextWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflowX: 'hidden',
    lineHeight: themedValue(theme, {
        sevennews: 1.25,
        fallback: 'normal',
    }),
}))
StyledBylineTextWrap.displayName = 'BylineContentWrapper'

/**
 * Styled text block styles are used as an abstract set of styles which get re-used
 * for the byline text content. The author text, funding label, and any fallback
 * text get these styles applied.
 */
const styledTextBlockStyles: FunctionInterpolation<{ theme: Theme }> = ({
    theme,
}) => [
    {
        '&:first-child': {
            fontWeight: 600,

            '&::before': {
                content: 'none',
            },
        },
    },
    theme.kind !== 'sevennews' && {
        marginRight: 16,

        '&::before': {
            content: `''`,
            display: 'inline-block',
            width: 1,
            height: calcRem(13),
            transform: 'translate(-7px, 1px)',
            background: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.greyCoal,
                fallback: colors.black,
            }),
        },
    },
    theme.kind === 'sevennews' && {
        marginRight: calcRem(14),
        padding: themedValue(theme, {
            sevennews: `${calcRem(5)} 0`,
            fallback: undefined,
        }),
    },
]

const middleDot: CSSObject = {
    '&::before': {
        content: "''",
        display: 'block',
        top: '50%',
        position: 'absolute',
        transform: 'translate(-7px, -2px)',
        width: calcRem(3),
        height: calcRem(3),
        borderRadius: calcRem(1.5),
        backgroundColor: colors.sevennews.charade,
    },
}

export const StyledBylineTextBlock = styled('span')<{ isSource?: boolean }>(
    styledTextBlockStyles,

    ({ isSource, theme }) => [
        isSource !== true &&
            theme.kind === 'sevennews' && {
                '> span[itemprop="name"]': {
                    color: '#000000',
                },
                [breakpointMax('xxs')]: {
                    display: 'block',
                },
            },
    ],
)

export const StyledBylinePosition = styled('span')(
    styledTextBlockStyles,
    ({ theme }) => ({
        marginLeft: themedValue(theme, {
            sevennews: calcRem(8),
            fallback: undefined,
        }),
    }),
)

export const StyledBylineTextWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    overflow: 'hidden',
})
StyledBylineTextWrapper.displayName = 'BylineText'

export const StyledBylineAuthor = styled(TextLink)(
    styledTextBlockStyles,
    ({ theme }) => {
        if (theme.kind !== 'perthnow') {
            return {
                textDecoration: 'none',

                '&:hover, &:focus': {
                    textDecoration: 'underlined',
                },
            }
        }
        return {}
    },
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            //For change color for author name please update also for authors who don't have a profile (in StyledBylineTextBlock component)
            '> span[itemprop="name"]': {
                color: '#000000',
            },
            [breakpointMax('xxs')]: {
                display: 'block',
            },
        },
)

export const StyledFundingLabel = styled(FundingLabel)<FundingLabelProps>(
    styledTextBlockStyles,
    {
        display: 'inline-block',
        // The children of the funding label need to have their vertical alignment reset - otherwise they sit awkwardly
        [`& ${FundingTitle}, & ${FundingLinkTitle}`]: {
            verticalAlign: 'unset',
            // perth now has a margin-top set - that's why we reset it
            marginTop: 0,
        },
    },
)

export const StyledTimestamp = styled(Timestamp)(({ theme }) => [
    {
        fontFamily: theme.fonts.publication.byline,
        marginRight: calcRem(14),
        padding: themedValue(theme, {
            sevennews: `${calcRem(5)} 0`,
            fallback: undefined,
        }),
    },
    theme.kind === 'thewest' && {
        // Sets numbers to render at the same size - fonts must have number glyphs included that support this.
        // Currently no support exists for our web verison guardian egyptian text - but have kept this here for the future.
        fontVariantNumeric: 'tabular-nums',
        fontSize: calcRem(14),
    },
])

export const StyledMultipleTimestampWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
})

export const StyledTimestampUpdated = styled(StyledTimestamp)(
    ({ theme }) => ({
        fontWeight: themedValue(theme, {
            sevennews: 700,
            fallback: 400,
        }),
    }),
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            position: 'relative',

            '&:not(:first-child)': {
                margin: 0,
                ...middleDot,
            },
        },
    ({ theme }) =>
        theme.kind !== 'sevennews' && {
            marginRight: 16,

            '&::before': {
                content: `''`,
                display: 'inline-block',
                width: 1,
                height: calcRem(13),
                transform: 'translate(-6px, 1px)',
                background: themedValue(theme, {
                    thewest: colors.thewest.greyGorilla,
                    perthnow: colors.perthnow.greyCoal,
                    fallback: colors.black,
                }),
            },
        },
)

// For The West
export const StyledProfileEmailButton = styled(
    ProfileEmailButton,
)<ProfileEmailButtonProps>({
    alignSelf: 'center',
    marginTop: calcRem(metrics.thewest.margins.sm),
    flexShrink: 0,

    [breakpoint('xs')]: {
        marginTop: 0,
    },
})

interface SourceLogoProps {
    maxWidth?: number
}

export const StyledSourceLogo = styled('img', {
    shouldForwardProp: createPropFilter<SrcLogoProps>()(['maxWidth']),
})<SourceLogoProps>(({ maxWidth }) => ({
    maxWidth: maxWidth || calcRem(70),
    marginTop: 3,
}))

export const StyledSourceWrapper = styled('div')(({ theme }) => [
    {
        display: 'inline-block',
        width: 'auto',
        fontWeight: 500,
        fontStyle: 'italic',
        verticalAlign: 'middle',
        marginRight: calcRem(8),
        padding: themedValue(theme, {
            sevennews: `${calcRem(5)} 0`,
            fallback: undefined,
        }),
        position: 'relative',
    },
    theme.kind === 'sevennews' && {
        ...middleDot,
    },
])

export const StyledLabelWrapper = styled('div')(({ theme }) => [
    {
        overflow: 'hidden',
        paddingLeft: calcRem(8),
        transform: 'translate(-8px)',
        display: 'flex',
    },
    (theme.kind === 'thewest' || theme.kind === 'perthnow') && {
        '&::before': {
            content: `''`,
            display: 'inline-block',
            width: 1,
            height: calcRem(13),
            transform: 'translate(-7px, 1px)',
            background: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.greyCoal,
                sevennews: colors.sevennews.charade,
                thenightly: undefined,
            }),
        },
    },
])

export const StyledMetaDataWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    overflow: 'hidden',
})

export const StyledPremiumWrapper = styled('span')(({ theme }) => ({
    display: 'block',

    '& > *': {
        marginRight: 16,
    },

    '&::before': {
        content: `''`,
        display: 'inline-block',
        width: 1,
        height: calcRem(13),
        transform: 'translate(-3px, -1px)',
        background: themedValue(theme, {
            sevennews: colors.sevennews.charade,
            perthnow: colors.perthnow.greyCoal,
            thewest: colors.thewest.greyGorilla,
            thenightly: undefined,
        }),
    },
}))

export interface StyledPremiumIconProps {
    width: number
    height: number
}

export interface StyledButtonContainerProps {
    showEmailButton?: boolean
}

export const StyledButtonContainer = styled('div')<StyledButtonContainerProps>(
    ({ showEmailButton }) => ({
        display: 'flex',
        flexDirection: showEmailButton ? 'column' : 'row',
        justifyContent: 'space-around',
    }),
)

export const StyledPremiumIcon = styled(IconPremium)<StyledPremiumIconProps>(
    ({ width, height }) => ({
        width,
        height,
        transform: 'translate(0, 2px)',
    }),
)
