import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { StyledPollieRaterButtonLink } from '../../buttons/PollieRaterButton/PollieRaterButton.styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledFederalElectionInArticleBannerContainer = styled('div')(
    ({ theme }) => ({
        clear: 'both',
        borderTop: `1px solid ${theme.colors.borders.primary}`,
        borderBottom: `1px solid ${theme.colors.borders.primary}`,
        marginBottom: calcRem(40),
        padding: calcRem(16, 22),

        [breakpoint('xs')]: {
            padding: calcRem(40, 22, 30),
        },
    }),
)

export const StyledFederalElectionInArticleBannerTitle = styled('h2')({
    fontSize: calcRem(32),
    lineHeight: calcRem(40),
    margin: 0,
    fontWeight: 400,
    position: 'relative',
    paddingTop: calcRem(16),

    [breakpoint('xs')]: {
        fontSize: calcRem(40),
        lineHeight: calcRem(48),
        marginBottom: calcRem(24),
    },

    '&::before': {
        content: `''`,
        width: calcRem(56),
        height: calcRem(4),
        display: 'block',
        position: 'absolute',
        background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        top: 0,
    },
})

export const StyledFederalElectionInArticleBannerTextContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontSize: calcRem(22),
            fontWeight: 700,
            color: theme.colors.text.secondary,
            marginRight: calcRem(22),

            [breakpoint('xs')]: {
                marginTop: 0,
                marginBottom: 0,
            },
        },

        [breakpoint('xs')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
)

export const StyledFederalElectionInArticleBannerButtonLink = styled(
    StyledPollieRaterButtonLink,
)({
    minHeight: calcRem(32),
    fontSize: calcRem(13),
    lineHeight: 1.3,
})

export const StyledFederalElectionInArticleBannerSecondaryTextContainer =
    styled('p')(({ theme }) => ({
        background: tokens.thewest.colors.palette.greyErmine,
        display: 'block',
        padding: '8px',
        marginTop: '16px',

        '& a': {
            color: theme.colors.brand,
        },
    }))
