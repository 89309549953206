import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

export const getKingsCoronationRouteInfo: GetMainSectionFn = (
    sectionBuilder,
) => {
    return [
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/king-charles-iii'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/king-charles-iii'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,

                loadMoreEnabled: true,
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/queen-elizabeth-ii'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
