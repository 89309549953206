import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { Product } from '@news-mono/web-common'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import {
    horizontalDividers,
    verticalDividers,
} from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

interface GridProps {
    initialColumns: number
    intermediateColumns: number
    finalColumns: number
    verticalSpacing: keyof ThemeMargins | undefined
    stretchSelf?: boolean
    hasBackground?: boolean
    hasBorder?: boolean
    hasItems?: boolean
    hideBottomBorder?: boolean
    hasTopBorder?: boolean
    marginBottom?: number
}

export const StyledLima = styled('div')<GridProps>(
    ({
        hasItems,
        initialColumns,
        intermediateColumns,
        finalColumns,
        verticalSpacing,
        hasBorder,
        hideBottomBorder,
        hasBackground,
        theme,
        marginBottom,
    }) => [
        {
            display: 'grid',
            gridTemplateColumns: `repeat(${initialColumns}, 1fr)`,
            gridRowGap: themedValue(theme, {
                thewest: metrics.thewest.margins.gridGap,
                sevennews: metrics.sevennews.margins.gridGap,
                perthnow: metrics.perthnow.margins.gridGap,
                fallback: undefined,
            }),
            position: 'relative',
            width: '100%',
            margin: '0 auto',
            marginBottom:
                typeof marginBottom === 'number'
                    ? calcRem(marginBottom)
                    : verticalSpacing &&
                      calcRem(theme.margins[verticalSpacing]),
            paddingBottom: themedValue(theme, {
                thewest: metrics.thewest.margins.gridGap,
                sevennews: metrics.sevennews.margins.md,
                perthnow: undefined,
                fallback: undefined,
            }),

            background: hasBackground
                ? theme.colors.background.secondary
                : 'transparent',
            borderRadius: theme.radius?.lg,
            overflow: theme.kind === Product.PerthNow ? 'hidden' : undefined,
            [breakpoint('sm')]: {
                gridColumnGap: themedValue(theme, {
                    thewest: metrics.thewest.margins.gridGap,
                    sevennews: metrics.sevennews.margins.gridGap,
                    perthnow: metrics.perthnow.margins.gridGap,
                    fallback: undefined,
                }),

                gridTemplateColumns: themedValue(theme, {
                    perthnow: `repeat(${finalColumns}, 1fr)`,
                    thewest: `repeat(${intermediateColumns}, 1fr)`,
                    sevennews: `repeat(${intermediateColumns}, 1fr)`,
                    fallback: undefined,
                }),
            },

            [breakpoint('md')]: {
                gridTemplateColumns: `repeat(${finalColumns}, 1fr)`,
                gridRowGap: themedValue(theme, {
                    thewest: '0px',
                    sevennews: '0px',
                    perthnow: metrics.perthnow.margins.gridGap,
                    fallback: undefined,
                }),
                height: themedValue(theme, {
                    thewest: 'auto',
                    fallback: undefined,
                }),
                borderBottom:
                    hasBorder && hasItems && !hideBottomBorder
                        ? themedValue(theme, {
                              thewest: `4px double ${theme.colors.borders.secondary}`,
                              fallback: undefined,
                          })
                        : 'none',
            },
            [breakpointMax('xxs')]: {
                gridGap:
                    theme.kind === 'thewest'
                        ? metrics.thewest.margins.gridGapMobile
                        : undefined,
            },
        },
        theme.kind === 'thewest' &&
            hasItems &&
            !hideBottomBorder && {
                borderBottom: `4px double ${theme.colors.borders.secondary}`,
            },
    ],
)
StyledLima.displayName = 'StyledLima'

export const StyledHeader = styled('div')((props) => ({
    display: 'block',
    marginBottom: calcRem(props.theme.margins.md),
}))
StyledHeader.displayName = 'StyledHeader'

export const StyledLinkRow = styled('div')((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))
StyledLinkRow.displayName = 'StyledLinkRow'

export interface CellProps {
    numberOfItems: number
    initialColumns: number
    intermediateColumns: number
    finalColumns: number
    hideSeparators?: boolean
}

const getLastRowOfCards = (numberOfItems: number, columns: number) => {
    const calcItems = (numberOfItems / columns) % 1
    const lastRowOfCards = calcItems > 0 ? calcItems * columns : columns

    return lastRowOfCards
}

export const StyledGridItem = styled('div')<CellProps>(
    ({
        theme,
        initialColumns,
        intermediateColumns,
        numberOfItems,
        finalColumns,
        hideSeparators,
    }) => {
        return [
            {
                display: 'grid',
                justifyContent: 'stretch',
                gridTemplateColumns: '1fr',
                gridGap: themedValue(theme, {
                    thewest: metrics.thewest.margins.gridGap,
                    sevennews: metrics.sevennews.margins.md,
                    perthnow: metrics.perthnow.margins.md,
                    thenightly: undefined,
                }),
                marginBottom:
                    theme.kind === 'thenightly'
                        ? calcRem(metrics.thenightly.margins.lg)
                        : 0,
                [breakpoint('sm')]: {
                    marginBottom:
                        theme.kind === 'thenightly' ? undefined : calcRem(12),
                },
                [breakpointMax('xxs')]: {
                    gridGap:
                        theme.kind === 'thewest'
                            ? metrics.thewest.margins.gridGapMobile
                            : undefined,
                },
            },
            theme.kind === 'thewest' &&
                initialColumns > 1 &&
                hideSeparators !== true && {
                    position: 'relative',

                    '&:not(:first-child)::after': {
                        ...verticalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },
                },
            // Dividers for The West
            theme.kind === 'thewest' &&
                initialColumns === 1 &&
                hideSeparators !== true && {
                    position: 'relative',

                    // Horizontal dividers for nested cards at all breakpoints
                    '& > *:not(:last-child)::before': {
                        ...horizontalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },

                    //Adds horizontal dividers for first level cards on small breakpoints only
                    [breakpointMax('sm')]: {
                        '&:not(:last-child)::before': {
                            ...horizontalDividers,
                            backgroundColor: theme.colors.borders.primary,
                        },
                    },
                },
            //adds border-bottom to the search results
            theme.kind === 'thewest' &&
                finalColumns === 1 &&
                hideSeparators !== true && {
                    '& ::before': {
                        ...horizontalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },
                    '&:last-child ::before': {
                        content: 'none',
                    },

                    // Horizontal dividers for nested cards at all breakpoints
                    '& > *:not(:last-child)::before': {
                        ...horizontalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },
                },

            theme.kind === 'thewest' &&
                finalColumns > 1 &&
                hideSeparators !== true && {
                    [breakpointBetween('sm', 'md')]: {
                        // Add horizontal dividers on the bottom of the first row of cards for intermediate columns lengths - don't add it if there is only one row.
                        // prettier-ignore
                        [':not(:nth-last-child(-n+' + getLastRowOfCards(numberOfItems, intermediateColumns) +'))::before']: {
                        ...horizontalDividers,

                    },

                        // Remove vertical Dividers for The West - Resets so they dont appear above "Medium breakpoint"
                        [':nth-child(' + intermediateColumns + 'n + 1)::after']:
                            {
                                content: 'none',
                            },

                        '&:not(:first-child)::after': {
                            ...verticalDividers,
                            backgroundColor: theme.colors.borders.primary,
                        },

                        // Removes vertical from nested items
                        '& > &:not(:first-child)::after': {
                            content: 'none',
                        },
                    },
                },
            theme.kind === 'thewest' &&
                finalColumns > 1 &&
                hideSeparators !== true && {
                    [breakpoint('md')]: [
                        theme.kind === 'thewest' && {
                            // Remove Dividers for The West
                            // prettier-ignore
                            ['&:nth-child(' + finalColumns + 'n + 1)::after']: {
                            content: 'none',
                        },

                            // Horizontal dividers if there is more than one row
                            // prettier-ignore
                            [':not(:nth-last-child(-n+' + getLastRowOfCards(numberOfItems, finalColumns) +'))::before']: {
                                    background: 'red',
                            ...horizontalDividers,
                        },

                            '&:not(:first-child)::after': {
                                ...verticalDividers,
                                backgroundColor: theme.colors.borders.primary,
                            },

                            // Removes vertical from nested items
                            '& > &:not(:first-child)::after': {
                                content: 'none',
                            },
                        },
                    ],
                },
        ]
    },
)

export const DisclaimerWrapper = styled.div(({ theme }) => ({
    margin: 0,
    marginBottom: calcRem(20),
    textTransform: 'uppercase',
    position: 'relative',
    fontFamily: theme.fonts.heading,
    fontSize: calcRem(16),
    fontWeight: themedValue(theme, {
        perthnow: 400,
        sevennews: 400,
        thewest: 400,
        fallback: undefined,
    }),
    lineHeight: 1,
    fontStyle: 'italic',
    color: tokens.perthnow.colors.palette.black,
}))
