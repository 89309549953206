import {
    CardEvents,
    ClickableCardItems,
    SelectItemAvailableEvent,
} from '../client-data-types/card-types'
import { DataLayerEventName } from '../helpers/DataLayerEventName'
import {
    ClickedCardInformation,
    returnCardInformation,
} from './collection-events'

// helper that fires the select_item list event
// from collection card clicked event
// seperated to assist testing
export function raiseSelectItemEvent(
    event: (event: CardEvents) => void,
    originator: string,
    cardInfo: Partial<ClickedCardInformation>,
    item: ClickableCardItems,
    cardNumber: number,
): SelectItemAvailableEvent {
    const selectItemEvent = toSelectItemEvent(
        originator,
        cardInfo,
        item,
        cardNumber,
    )
    event(selectItemEvent)

    // return the event purely for testing
    return selectItemEvent
}

export function toSelectItemEvent(
    originator: string,
    cardInfo: Partial<ClickedCardInformation>,
    item: ClickableCardItems,
    cardNumber: number,
): SelectItemAvailableEvent {
    const info = returnCardInformation(item)
    return {
        type: DataLayerEventName.selectItemEvent,
        originator,
        payload: {
            ...info,
            index: cardNumber,
        },
    }
}
