import React from 'react'

export interface IconWhatsappProps {
    title?: string
    className?: string
}

export const IconWhatsapp: React.FC<IconWhatsappProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        className={className}
    >
        <title>{title || 'Share to WhatsApp'}</title>
        <path d="M13.97 13.88c-1.33 1.32-3.1 2.04-4.98 2.04-1.1 0-2.2-.24-3.2-.73l-.67-.4-2.8.64.6-2.8-.3-.6c-.5-1-.78-2.07-.78-3.18 0-1.87.73-3.6 2.05-4.95 1.3-1.3 3.1-2.03 5-2.03 1.88 0 3.64.73 4.97 2.04 1.36 1.38 2.1 3.1 2.1 5 0 1.85-.76 3.63-2.08 4.94M0 9c0 1.44.36 2.8.98 4l-.96 4.6c-.05.25.18.47.43.4L5 16.93c1.16.58 2.47.9 3.85.93 4.9.08 8.98-3.77 9.13-8.62.17-5.2-4.16-9.43-9.43-9.18C3.77.26 0 4.22 0 8.96z" />
        <path d="M13.62 10.9l-1.75-.5c-.23-.05-.48 0-.65.18l-.42.43c-.18.2-.46.3-.7.2-.82-.3-2.56-1.8-3-2.6-.13-.2-.1-.5.05-.7l.37-.5c.15-.2.18-.4.08-.6l-.73-1.7c-.18-.4-.7-.5-1.02-.22-.5.4-1.07 1.02-1.14 1.7-.1 1.2.4 2.73 2.4 4.56 2.3 2.12 4.2 2.4 5.4 2.1.7-.15 1.2-.8 1.58-1.33.24-.4.05-.9-.36-1" />
    </svg>
)
