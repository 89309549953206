import * as React from 'react'

export interface IconRefreshProps {
    className?: string
    rotate?: boolean
}

export const IconRefresh: React.FC<IconRefreshProps> = ({
    className,
    rotate,
}) => {
    return (
        <svg
            className={className}
            viewBox="0 0 15 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            {rotate && (
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0"
                    to="360"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            )}
            <path
                d="M0 7.48159L1.70448 0L3.5106 2.12424C6.81584 -0.32523 11.2361 0.143022 14.1149 3.52899L12.7707 5.11005C10.6393 2.60327 7.42172 2.15264 4.89983 3.75815L6.36119 5.47693L0 7.48159Z"
                fill="white"
            />
            <path
                d="M14.1429 10.5184L12.4383 18L10.6322 15.8757C7.32703 18.3252 2.90674 17.857 0.0278482 14.471L1.37214 12.89C3.50351 15.3968 6.72105 15.8473 9.24296 14.2419L7.78158 12.523L14.1429 10.5184Z"
                fill="white"
            />
        </svg>
    )
}
