import { debugSwg } from '../../authentication/debug'

export async function fetchGoogleAccessToken(
    accessToken: string,
    authOrigin: string,
) {
    const request = new Request(`${authOrigin}/google/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: accessToken,
        }),
    })

    const result = await fetch(request)
    const json = await result.json()

    if (!result.ok) {
        debugSwg('Google account linking token request failed', {
            status: result.status,
            json,
        })
    } else {
        debugSwg('Google account linking token received')
        return json as { token: string }
    }
}
