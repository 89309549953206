import {
    mapContentBlocks,
    Product,
    ShareOption,
    SharingEvent,
} from '@news-mono/web-common'
import React, { FC } from 'react'
import { Logger } from 'typescript-log'
import { ArticleBlockContent } from '../../ArticleBlockContent/ArticleBlockContent'
import { Byline } from '../../Byline/Byline'
import { getPinnedChipType } from '../helpers/get-pinned-type'
import {
    StyledLiveEventArticleBlockContent,
    StyledLiveEventPost,
    StyledLiveEventSocialIcons,
    StyledLiveEventTitle,
} from './LiveEventPost.styled'
import { Theme, useTheme } from '@emotion/react'
import { TNShareButton } from '../../../content'
import { pinnedChipTypes } from '../../Byline/helpers/getEventPostChip'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'

export interface LiveEventPostProps {
    post: EventPostV4DTO
    eventTitle: string
    log: Logger
    adUnitPath: string
    lazyLoadRef?: React.RefObject<HTMLDivElement>
    onEvent: (event: SharingEvent) => void
    /** Build event post with an id attribute for deeplinking
     * @default true
     */
    renderIDAnchorTag?: boolean
    /** Element is located inside a truncating container and overflowing */
    canTruncate?: boolean
    isActivePost?: boolean
    isPinnedPost?: boolean
    entryType?: string
}

export const LiveEventPost: FC<LiveEventPostProps> = ({
    post,
    eventTitle,
    log,
    adUnitPath,
    lazyLoadRef,
    onEvent,
    renderIDAnchorTag = true,
    canTruncate,
    isActivePost,
    isPinnedPost,
    entryType,
}) => {
    const theme = useTheme()
    const authorName =
        post.profiles.length >= 1
            ? post.profiles.map((profile) => profile.name).join(' &')
            : post.byline

    const pinnedVariant = getPinnedChipType(post.sticky, canTruncate)
    const socialIconsMode = getSocialIconsDisplayMode(theme, pinnedVariant)
    const socialOptions = getSocialIconsToDisplay(theme)
    return (
        <StyledLiveEventPost
            ref={lazyLoadRef}
            key={post.id}
            id={renderIDAnchorTag ? post.id : undefined}
            noBotMargin={canTruncate}
            isActivePost={isActivePost}
            isPinnedPost={isPinnedPost}
        >
            <Byline
                profile={post.profiles}
                publicationKind={post.kind}
                timestamp={post.publishedDate}
                isFullWidth={true}
                allowCommenting={false}
                fundingType={undefined}
                text={authorName || undefined}
                happeningPost={post.happening?.label}
                pinnedPost={pinnedVariant}
                entryType={entryType}
            ></Byline>
            {post.title && (
                <StyledLiveEventTitle>{post.title}</StyledLiveEventTitle>
            )}
            <StyledLiveEventArticleBlockContent>
                <ArticleBlockContent
                    blocks={mapContentBlocks(post.content.blocks)}
                    onEvent={() => {}}
                    log={log}
                    adUnitPath={adUnitPath}
                    publicationKind={'event'}
                    isEventPost={true}
                />
            </StyledLiveEventArticleBlockContent>

            {socialIconsMode === 'show-thenightly' && (
                <TNShareButton
                    url={post._self}
                    text={post.title || eventTitle}
                    onEvent={onEvent}
                    shareOptions={socialOptions}
                    customCampaign="liveblog"
                    publicationId={post.eventId}
                    postId={post.id}
                />
            )}
            {socialIconsMode === 'show-regular' && (
                <StyledLiveEventSocialIcons
                    url={post._self}
                    text={post.title || eventTitle}
                    onEvent={onEvent}
                    shareOptions={socialOptions}
                    customCampaign="liveblog"
                    publicationId={post.eventId}
                    postId={post.id}
                />
            )}
        </StyledLiveEventPost>
    )
}

type SocialIconsDisplayMode = 'show-thenightly' | 'show-regular' | 'none'
const getSocialIconsDisplayMode = (
    theme: Theme,
    pinnedVariant: pinnedChipTypes,
): SocialIconsDisplayMode => {
    const canShowBasedOnVariant = pinnedVariant !== 'full'

    // Nothing to show
    if (!canShowBasedOnVariant) {
        return 'none'
    }

    // Show depending on the theme
    return theme.kind === Product.TheNightly
        ? 'show-thenightly'
        : 'show-regular'
}

const getSocialIconsToDisplay = (theme: Theme): ShareOption[] => {
    // Show more options for TheNightly LiveEventPost's
    if (theme.kind === Product.TheNightly) {
        return ['facebook', 'linkedin', 'x', 'email_tn', 'clipboard']
    } else {
        return ['twitter', 'facebook', 'clipboard']
    }
}
