import React from 'react'
import { BarGraphWidget, HorseShoeWidget, PieChartWidget } from '../../widgets'
import { WidgetsLabel } from '../../widgets/federal-election/WidgetsLabel'
import { AccordionProps } from '../FederalElectionAccordion'
import {
    StyledAccordionBody,
    StyledAccordionBodyContainer,
    StyledAccordionInnerBody,
    StyledAccordionWidgetsContainer,
    StyledHorseshoeContainer,
    StyledHorseShoeWidgetTextInnerContainer,
    StyledHorseShoeWidgetTextOuterContainer,
    StyledTwoWidgetsContainer,
    StyledVotesCountedContainer,
    StyledVotesCountedWidget,
    StyledWidgetsLabelContainer,
} from './FederalElectionAccordionBody.styled'

export const FederalElectionAccordionBody: React.FC<AccordionProps> = (
    props,
) => {
    const { isOpen, isFullWidth, electionDayData, hidePadding } = props

    return (
        <StyledAccordionBodyContainer
            isOpen={isOpen}
            hidePadding={hidePadding || false}
        >
            <StyledAccordionBody isOpen={isOpen}>
                <StyledAccordionInnerBody isOpen={isOpen}>
                    <StyledAccordionWidgetsContainer>
                        <StyledHorseshoeContainer>
                            <StyledHorseShoeWidgetTextOuterContainer>
                                <StyledHorseShoeWidgetTextInnerContainer>
                                    <span>HOUSE OF REPRESENTATIVES</span>
                                </StyledHorseShoeWidgetTextInnerContainer>
                            </StyledHorseShoeWidgetTextOuterContainer>
                            <HorseShoeWidget {...electionDayData} />
                        </StyledHorseshoeContainer>
                        <StyledTwoWidgetsContainer>
                            <StyledWidgetsLabelContainer>
                                <WidgetsLabel />
                            </StyledWidgetsLabelContainer>
                            <BarGraphWidget {...electionDayData} />
                            <StyledVotesCountedContainer
                                isFullWidth={isFullWidth}
                            >
                                <StyledVotesCountedWidget
                                    isFullWidth={isFullWidth}
                                >
                                    <PieChartWidget
                                        percentage={
                                            electionDayData.votesCounted
                                        }
                                        theme={'default'}
                                    />
                                </StyledVotesCountedWidget>
                            </StyledVotesCountedContainer>
                        </StyledTwoWidgetsContainer>
                    </StyledAccordionWidgetsContainer>
                </StyledAccordionInnerBody>
            </StyledAccordionBody>
        </StyledAccordionBodyContainer>
    )
}
