import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { IconCross } from '../../icons/IconCross/IconCross'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition, visuallyhidden } from '../../__styling/style-mixins'

const closeButtonSize = 48
export const StyledNavWrapperClass = 'stylednavwrapper'

export const StyledNavWrapper = withClass(StyledNavWrapperClass)(
    styled('div')({
        position: 'fixed',
        width: `calc(100% - ${calcRem(closeButtonSize)})`,
        maxWidth: 375,
        height: '100vh',
        padding: 0,
        paddingTop: calcRem(closeButtonSize),
        margin: 0,
        fontFamily: fonts.thewest.sansSerif,
        top: 0,
        zIndex: zIndex.thewest.flyoutNav,
        backgroundColor: colors.thewest.greyGorilla,
        color: colors.white,
        display: 'none',
        transform: `translateX(calc(-100% - ${closeButtonSize}px))`,
        ...transition({ properties: ['transform'], speed: 'slow' }),

        '&.isOpen': {
            display: 'flex',
            flexDirection: 'column',
        },

        '&.isTransitioning': {
            transform: 'translateX(0)',
        },
    }),
)

export const StyledCloseButton = styled('button')({
    height: calcRem(closeButtonSize),
    width: calcRem(closeButtonSize),
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 0,
    right: calcRem(-closeButtonSize),
    lineHeight: 1,
    border: '0',
    cursor: 'pointer',
    backgroundColor: colors.thewest.greyGorilla,
    color: colors.white,
    outline: 'none',
    ...transition({ properties: ['background-color'] }),

    '&:hover, &:focus': {
        backgroundColor: colors.thewest.greyElephant,
    },
})

export const StyledIconCross = styled(IconCross)({
    height: calcRem(12),
    width: calcRem(12),
    fill: colors.white,
})

export const StyledButtonText = styled('span')(visuallyhidden)

export const StyledNavList = styled('ul')({
    margin: calcRem(0, 0, 90, 0), // large bottom margin so last nav list items are not covered by menu in Safari on iOS
    display: 'block',
    width: '100%',
    flexGrow: 1,
    padding: calcRem(0, 4, 15, 13),
    overflowY: 'scroll',
    MsOverflowStyle: '-ms-autohiding-scrollbar',

    '&::-webkit-scrollbar': {
        width: 9,
        backgroundColor: colors.thewest.greyGorilla,
    },

    '&::-webkit-scrollbar-track': {
        width: 9,
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.thewest.greyElephant,
        borderRadius: 10,
    },
    [breakpoint('sm')]: {
        margin: 0,
    },
})
StyledNavList.displayName = 'StyledNavList'

export const StyledSearchAction = styled('div')({
    padding: calcRem(0, 10, 16, 10),
})
