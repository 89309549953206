import {
    DataLoaderGlobalParams,
    getRelatedPublications,
    mapListPublication,
} from '@news-mono/web-common'
import { RelatedPublications } from '../../../templates/Publication/Publication.props'

/**
 * Gets the related publications for a publication
 */
export async function getRelatedPublicationListing(
    services: DataLoaderGlobalParams,
    publicationId: string,
): Promise<RelatedPublications> {
    const relatedPublicationListing = await getRelatedPublications(
        services,
        publicationId,
        {
            paging: { page: 1, pageSize: 10 },
        },
    )

    const itemsToMap = relatedPublicationListing.documents

    const mappedItems = itemsToMap.map((item) => mapListPublication(item))

    return {
        items: mappedItems,
    }
}
