import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { tokens } from '@news-mono/design-tokens'
import { fonts } from '../../__styling/settings/fonts'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { metrics } from '../../__styling/settings/metrics'

export const styledToastNotificationClassName = 'ToastNotification'

export const StyledToastContainer = withClass(styledToastNotificationClassName)(
    styled('div')<{ westLiveBannerUp: boolean }>(
        ({ theme, westLiveBannerUp }) => ({
            background: colors.white,
            boxShadow: `0 0 8px 0 ${colors.black20}`,
            color: colors.black,
            fontFamily: themedValue(theme, {
                thewest: fonts.thewest.serif,
                perthnow: fonts.perthnow.sansSerif,
                sevennews: fonts.sevennews.sansSerif,
                thenightly: fonts.thenightly.sansSerif,
                fallback: undefined,
            }),
            fontSize: calcRem(14),
            padding: themedValue(theme, {
                thenightly: calcRem(metrics.thenightly.margins.m2l),
                fallback: theme.margins.md,
            }),

            position: 'fixed',
            boxSizing: 'border-box',
            width: '95%',
            left: '2.5%',
            bottom: westLiveBannerUp ? calcRem(140) : calcRem(70),
            zIndex: theme.zIndex.toastNotification,

            animationTimingFunction: 'ease-out',
            animationDuration: '250ms',
            animationFillMode: 'forwards',

            animationName: keyframes({
                '0%': { transform: 'translateY(45px)', opacity: 0 },
                '100%': { transform: `translateY(0)`, opacity: 1 },
            }),

            [breakpoint('sm')]: {
                left: 'auto',
                right: calcRem(20),
                bottom: westLiveBannerUp ? calcRem(90) : calcRem(20),
                maxWidth: 650,
            },
        }),

        ({ theme }) =>
            theme.kind === 'thenightly' && {
                left: '50%',
                right: '50%',
                width: '100%',
                background: tokens.thenightly.colors.palette.starlight.light,
                animationName: keyframes({
                    '0%': {
                        transform: 'translateY(45px) translateX(-50%)',
                        opacity: 0,
                    },
                    '100%': {
                        transform: `translateY(0) translateX(-50%)`,
                        opacity: 1,
                    },
                }),

                [breakpoint('sm')]: {
                    bottom: 0,
                    left: '50%',
                    right: '50%',
                    maxWidth: '100%',
                    padding: calcRem(metrics.thenightly.margins.xxl),
                },
            },
    ),
)
