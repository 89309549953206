import React, { useState } from 'react'
import {
    AdDefinition,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import { CollectionRouteProps } from '../../../collections'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../../data-controllers/LoadMore/LoadMore'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { ThemeMargins, ThemeOverrideTypes } from '../../../__styling'
import { SixCardCollection } from './SixCardCollection'
import { useProduct } from '../../../__product'
import { FiveCardCollection } from '../FiveCardCollection'
import { StyledSixCardCollectionWrapper } from './SixCardCollection.styled'
import { AdUnitWrapper } from '../../../advertising'
import { chunkArray } from '../helpers'
import { injectIpsos } from '../../../tracking'
import { SixCardCollectionSkeleton } from './SixCardCollectionSkeleton'
import { FourCardCollection } from '../FourCardCollection'
import { InfiniteScroll } from '../../../data-controllers/InfiniteScroll/InfiniteScroll'

export interface SixCardCollectionRouteProps
    extends CollectionRouteProps,
        LoadMoreRouteProps,
        ResponsiveContainer {
    themeOverride?: ThemeOverrideTypes
    loadMoreEnabled?: boolean
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    isSubtopicPage?: boolean
    currentUrl?: string
    pathname?: string
}

export const sixCardCollectionAdStateDefinition: AdDefinition[] = [
    {
        id: 'leaderboard-billboard-four',
        size: 'leaderboardBillboard',
    },
    {
        id: 'leaderboard-billboard-five',
        size: 'leaderboardBillboard',
    },
    {
        id: 'leaderboard-billboard-six',
        size: 'leaderboardBillboard',
    },
    {
        id: 'leaderboard-billboard-seven',
        size: 'leaderboardBillboard',
    },
    {
        id: 'mobile-mrec-four',
        size: 'below768Mrec',
    },
    {
        id: 'mobile-mrec-five',
        size: 'below768Mrec',
    },
    {
        id: 'mobile-mrec-six',
        size: 'below768Mrec',
    },
    {
        id: 'mobile-mrec-seven',
        size: 'below768Mrec',
    },
]

export const SixCardCollectionRegistration =
    createRegisterableComponentWithData(
        'six-card-collection',
        ContentDataDefinitionLoader,
        (props: SixCardCollectionRouteProps, data, services) => {
            const [currentPage, setCurrentPage] = useState(
                data.dataDefinitionArgs?.paging?.page,
            )

            const product = useProduct()

            if (
                !data.loaded ||
                (data.loaded && data.result.publications.length <= 0)
            ) {
                return (
                    <SixCardCollectionSkeleton
                        verticalGutters={props.verticalGutters}
                        verticalSpacing={props.verticalSpacing}
                    />
                )
            }

            const adSlotIdHash: { [key: number]: string } = {
                1: 'four',
                3: 'five',
                5: 'six',
                7: 'seven',
            }

            const adStateDefinition: AdDefinition[] = [
                ...services.adState.definition,
                ...sixCardCollectionAdStateDefinition,
            ]

            const adState = {
                ...services.adState,
                definition: adStateDefinition,
            }

            const dataDefinitionArgsOverride = data.dataDefinitionArgs

            if (dataDefinitionArgsOverride.paging) {
                dataDefinitionArgsOverride.paging!.pageSize = 9
            }

            return (
                <StyledSixCardCollectionWrapper>
                    {props.isSubtopicPage ? (
                        <InfiniteScroll
                            dataDefinition={dataDefinitionArgsOverride}
                            services={services}
                            loadMoreEnabled={props.loadMoreEnabled === true}
                            data={data}
                            currentPage={currentPage}
                            currentUrl={props.currentUrl}
                            pathname={props.pathname}
                            setCurrentPage={setCurrentPage}
                            onLoadMore={(loadMoreData) => {
                                injectIpsos(
                                    window.location.hostname,
                                    window.location.pathname,
                                    'ajax',
                                )
                                services.onEvent(
                                    createCollectionAvailableEvent(
                                        loadMoreData.result.publications,
                                        'SixCardCollection',
                                        product,
                                        services.onEvent,
                                    ),
                                )
                            }}
                            render={(loadMoreData) => {
                                const fiveAndFourCardData = loadMoreData.loaded
                                    ? chunkArray(
                                          [
                                              ...loadMoreData.result
                                                  .publications,
                                          ].slice(6),
                                          9,
                                      )
                                    : []

                                return (
                                    <>
                                        <SixCardCollection
                                            items={
                                                data.loaded
                                                    ? {
                                                          loaded: true,
                                                          result: data.result
                                                              .publications,
                                                      }
                                                    : { loaded: false }
                                            }
                                            onEvent={services.onEvent}
                                            verticalGutters={
                                                props.verticalGutters
                                            }
                                            verticalSpacing={
                                                props.verticalSpacing
                                            }
                                        />
                                        {fiveAndFourCardData.length
                                            ? fiveAndFourCardData.map(
                                                  (
                                                      fiveAndFourCardDataPublications,
                                                      chunkIndex,
                                                  ) => {
                                                      const slotId =
                                                          adSlotIdHash[
                                                              chunkIndex
                                                          ]

                                                      const fiveCardPublications =
                                                          fiveAndFourCardDataPublications.slice(
                                                              0,
                                                              5,
                                                          )

                                                      const fourCardPublications =
                                                          fiveAndFourCardDataPublications.slice(
                                                              5,
                                                          )

                                                      return (
                                                          <>
                                                              {slotId && (
                                                                  <>
                                                                      <AdUnitWrapper
                                                                          key={`adsChunkIndex${chunkIndex}`}
                                                                          adState={
                                                                              adState
                                                                          }
                                                                          unitId={`leaderboard-billboard-${slotId}`}
                                                                          onEvent={
                                                                              services.onEvent
                                                                          }
                                                                          hiddenUntilLoaded={
                                                                              false
                                                                          }
                                                                          noticePosition={
                                                                              'none'
                                                                          }
                                                                          adType={
                                                                              'inline'
                                                                          }
                                                                      />
                                                                      <AdUnitWrapper
                                                                          key={`adsMobileChunkIndex${chunkIndex}`}
                                                                          adState={
                                                                              adState
                                                                          }
                                                                          unitId={`mobile-mrec-${slotId}`}
                                                                          onEvent={
                                                                              services.onEvent
                                                                          }
                                                                          hiddenUntilLoaded={
                                                                              false
                                                                          }
                                                                          noticePosition={
                                                                              'none'
                                                                          }
                                                                          adType={
                                                                              'inline'
                                                                          }
                                                                      />
                                                                  </>
                                                              )}
                                                              <FiveCardCollection
                                                                  items={
                                                                      loadMoreData.loaded
                                                                          ? {
                                                                                loaded: true,
                                                                                result: fiveCardPublications,
                                                                            }
                                                                          : {
                                                                                loaded: false,
                                                                            }
                                                                  }
                                                                  onEvent={
                                                                      services.onEvent
                                                                  }
                                                                  verticalGutters={
                                                                      props.verticalGutters
                                                                  }
                                                                  verticalSpacing={
                                                                      props.verticalSpacing
                                                                  }
                                                              />
                                                              <FourCardCollection
                                                                  items={
                                                                      loadMoreData.loaded
                                                                          ? {
                                                                                loaded: true,
                                                                                result: fourCardPublications,
                                                                            }
                                                                          : {
                                                                                loaded: false,
                                                                            }
                                                                  }
                                                                  onEvent={
                                                                      services.onEvent
                                                                  }
                                                                  verticalGutters={
                                                                      props.verticalGutters
                                                                  }
                                                                  verticalSpacing={
                                                                      props.verticalSpacing
                                                                  }
                                                              />
                                                          </>
                                                      )
                                                  },
                                              )
                                            : null}
                                    </>
                                )
                            }}
                        />
                    ) : (
                        <LoadMore
                            dataDefinition={data.dataDefinitionArgs}
                            services={services}
                            loadMoreEnabled={props.loadMoreEnabled === true}
                            data={data}
                            onLoadMore={(loadMoreData) => {
                                services.onEvent(
                                    createCollectionAvailableEvent(
                                        loadMoreData.result.publications,
                                        'SixCardCollection',
                                        product,
                                        services.onEvent,
                                    ),
                                )
                            }}
                            render={(loadMoreData) => {
                                const fiveCardData = loadMoreData.loaded
                                    ? chunkArray(
                                          [
                                              ...loadMoreData.result
                                                  .publications,
                                          ].slice(6),
                                          5,
                                      )
                                    : []

                                return (
                                    <>
                                        <SixCardCollection
                                            items={
                                                data.loaded
                                                    ? {
                                                          loaded: true,
                                                          result: data.result
                                                              .publications,
                                                      }
                                                    : { loaded: false }
                                            }
                                            onEvent={services.onEvent}
                                            verticalGutters={
                                                props.verticalGutters
                                            }
                                            verticalSpacing={
                                                props.verticalSpacing
                                            }
                                        />
                                        {fiveCardData.length
                                            ? fiveCardData.map(
                                                  (
                                                      fiveCardDataPublications,
                                                      chunkIndex,
                                                  ) => {
                                                      const slotId =
                                                          adSlotIdHash[
                                                              chunkIndex
                                                          ]

                                                      return (
                                                          <>
                                                              {slotId && (
                                                                  <>
                                                                      <AdUnitWrapper
                                                                          key={`adsChunkIndex${chunkIndex}`}
                                                                          adState={
                                                                              adState
                                                                          }
                                                                          unitId={`leaderboard-billboard-${slotId}`}
                                                                          onEvent={
                                                                              services.onEvent
                                                                          }
                                                                          hiddenUntilLoaded={
                                                                              false
                                                                          }
                                                                          noticePosition={
                                                                              'none'
                                                                          }
                                                                          adType={
                                                                              'inline'
                                                                          }
                                                                      />
                                                                      <AdUnitWrapper
                                                                          key={`adsMobileChunkIndex${chunkIndex}`}
                                                                          adState={
                                                                              adState
                                                                          }
                                                                          unitId={`mobile-mrec-${slotId}`}
                                                                          onEvent={
                                                                              services.onEvent
                                                                          }
                                                                          hiddenUntilLoaded={
                                                                              false
                                                                          }
                                                                          noticePosition={
                                                                              'none'
                                                                          }
                                                                          adType={
                                                                              'inline'
                                                                          }
                                                                      />
                                                                  </>
                                                              )}
                                                              <FiveCardCollection
                                                                  items={
                                                                      loadMoreData.loaded
                                                                          ? {
                                                                                loaded: true,
                                                                                result: fiveCardDataPublications,
                                                                            }
                                                                          : {
                                                                                loaded: false,
                                                                            }
                                                                  }
                                                                  onEvent={
                                                                      services.onEvent
                                                                  }
                                                                  verticalGutters={
                                                                      props.verticalGutters
                                                                  }
                                                                  verticalSpacing={
                                                                      props.verticalSpacing
                                                                  }
                                                              />
                                                          </>
                                                      )
                                                  },
                                              )
                                            : null}
                                    </>
                                )
                            }}
                        />
                    )}
                </StyledSixCardCollectionWrapper>
            )
        },
    )
