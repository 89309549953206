import { useFeature } from '@news-mono/web-common'
import React from 'react'
import {
    StyledFundingLabel,
    StyledLinkTitle,
    StyledSource,
    StyledTitle,
} from '../../advertising/FundingLabel/FundingLabel.styled'
import {
    FundingType,
    getFundingLabel,
} from '../../templates/Publication/lib/get-funding-type'
import { ThemedRender } from '../../__styling/themed-render'

export interface FundingLabelProps {
    className?: string
    source?: string
    hasBackground?: boolean
    fundingType: FundingType
    linkToDisclaimer?: boolean
}

export const FundingLabel: React.FC<FundingLabelProps> = ({
    className,
    source,
    hasBackground,
    fundingType,
    linkToDisclaimer,
}) => {
    const fundingLabel = getFundingLabel(fundingType)
    const showLink = !!linkToDisclaimer

    const sponsoredText = (
        <ThemedRender
            thewest={() => (
                <React.Fragment>
                    {source && <StyledSource>{source}</StyledSource>}
                    {!showLink ? (
                        <StyledTitle>{fundingLabel}</StyledTitle>
                    ) : (
                        <StyledLinkTitle to={'/content-funding'}>
                            {fundingLabel}
                        </StyledLinkTitle>
                    )}
                </React.Fragment>
            )}
            perthnow={() => (
                <React.Fragment>
                    {!showLink ? (
                        <StyledTitle>{fundingLabel}</StyledTitle>
                    ) : (
                        <StyledLinkTitle to={'/content-funding'}>
                            {fundingLabel}
                        </StyledLinkTitle>
                    )}
                    {source && <StyledSource>{source}</StyledSource>}
                </React.Fragment>
            )}
            sevennews={() => (
                <React.Fragment>
                    {source && <StyledSource>{source}</StyledSource>}
                    {/* Seven News does not have a content funding page and doesn't show funding labels in articles, where the link is used. */}
                    <StyledTitle>&nbsp;{fundingLabel}</StyledTitle>
                </React.Fragment>
            )}
            thenightly={() => (
                <React.Fragment>
                    {!showLink ? (
                        <StyledTitle>{fundingLabel}</StyledTitle>
                    ) : (
                        <StyledLinkTitle to={'/content-funding'}>
                            {fundingLabel}
                        </StyledLinkTitle>
                    )}
                </React.Fragment>
            )}
        />
    )

    return (
        <StyledFundingLabel hasBackground={hasBackground} className={className}>
            {sponsoredText}
        </StyledFundingLabel>
    )
}
FundingLabel.displayName = 'FundingLabel'
