import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { OwnProps, Redirector } from './Redirector'

export interface RedirectorRouteProps extends OwnProps {}

export const RedirectorRegistration = createRegisterableComponent(
    'redirector',
    (props: RedirectorRouteProps) => <Redirector {...props} />,
)

export default RedirectorRegistration
