import {
    AdTargeting,
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { ArticleCard } from '../../cards/TheNightly/ArticleCard/ArticleCard'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { ArticleCardListContainer, Divider } from './ArticleCardList.styled'
import { useProduct } from '../../__product'
import { useImpressionAvailable } from '../../__helpers/impression-available-helper'
import { cardViewabilityDebug } from '../../cards/TheNightly/Card'

type InterlacedComponent = {
    /** The component to interlace. */
    component: (index: number) => ReactNode
    /** After how many cards the first instance of this component appears. Defaults to 1. */
    initialOffset?: number
    /** How many cards between each instance of this component. Defaults to 1. */
    cardsBetween?: number
    /** How many times to interlace this component. Defaults to 1. */
    repetitions?: number
}

export type ArticleCardListProps = {
    items: MaybeLoaded<CardItem[]>
    expectedItemCount: number
    onEvent: (event: CollectionEvent) => void
    adTargeting?: AdTargeting
    isStandalonePage?: boolean
    interlacedComponents?: Record<'advert' | string, InterlacedComponent>
    debuggingCustomId?: string
    impressionRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const ArticleCardList = ({
    items,
    expectedItemCount,
    onEvent,
    adTargeting,
    isStandalonePage,
    interlacedComponents = {},
    debuggingCustomId,
    impressionRef,
}: ArticleCardListProps) => {
    const cardItems = invertMaybeLoadedItems(items, expectedItemCount)
    const product = useProduct()
    const availableFunc = React.useCallback(() => {
        if (!items.loaded) {
            console.warn(
                'Available should never be called when loading is true',
            )
            return
        }

        // Add a debug for viewability!
        cardViewabilityDebug(
            'ArticleCardList',
            `in frame and has been viewed with a size of ${items.result.length}`,
        )

        onEvent(
            createCollectionAvailableEvent(
                items.result,
                'ArticleCardList',
                product,
                onEvent,
            ),
        )
    }, [items, onEvent, product])
    const impressionAvailableRef = useImpressionAvailable({
        loading: !items.loaded,
        available: availableFunc,
        percentageVisibleThreshold: isStandalonePage ? 1 : 5,
        debug: debuggingCustomId
            ? cardViewabilityDebug.extend(debuggingCustomId)
            : cardViewabilityDebug,
    })

    // Lima should look like this lol.

    const buildList = () => {
        // Keep track of how many times left to repeat.
        const interlacedRepetitionsRemaining: Record<string, number> = {}

        for (const [id, interlacedComponent] of Object.entries(
            interlacedComponents,
        )) {
            // Display once if no repetitions specified.
            interlacedRepetitionsRemaining[id] =
                interlacedComponent.repetitions ?? 1
        }

        const listResult: ReactNode[] = []

        for (let i = 0; i < cardItems.length; i++) {
            let shouldInsertDivider = true

            // Insert interlaced components.
            for (const [id, interlacedComponent] of Object.entries(
                interlacedComponents,
            )) {
                if (
                    isValidInterlaceIndex(
                        i,
                        interlacedComponent.initialOffset,
                        interlacedComponent.cardsBetween,
                    ) &&
                    interlacedRepetitionsRemaining[id] !== 0
                ) {
                    listResult.push(
                        <Fragment key={`${i}-${id}`}>
                            {interlacedComponent.component(i)}
                        </Fragment>,
                    )
                    interlacedRepetitionsRemaining[id]--
                    // Do not insert a divider if there is an interlaced component.
                    shouldInsertDivider = false
                }
            }

            // Insert card.
            const loadableItem = cardItems[i]

            if (!loadableItem.loaded) {
                // TODO: show placeholders?
                return null
            }

            const { result } = loadableItem

            if (result.cardType === 'marketing-redirect-tile') {
                // Do not render marketing redirects.
                continue
            } else {
                listResult.push(
                    <Fragment key={i}>
                        {shouldInsertDivider && i !== 0 && <Divider />}
                        <ArticleCard
                            key={result.id}
                            item={{ loaded: true, result }}
                            onEvent={onEvent}
                            isStandalonePage={isStandalonePage}
                            adTargeting={adTargeting}
                        />
                    </Fragment>,
                )
            }
        }

        return listResult
    }

    return (
        <ArticleCardListContainer
            ref={isStandalonePage ? impressionRef : impressionAvailableRef}
        >
            {buildList()}
        </ArticleCardListContainer>
    )
}

/** Checks if a 0-indexed position is valid, provided an initial offset and spaces between. */
const isValidInterlaceIndex = (
    index: number,
    initialOffset = 1,
    spotsBetween = 1,
) =>
    // Sanity checks.
    initialOffset > 0 &&
    spotsBetween > 0 &&
    // Interlaced components can only be between cards.
    index !== 0 &&
    // Actual condition. Object.is() distinguishes between -0 and 0
    Object.is((index - initialOffset) % spotsBetween, 0)
