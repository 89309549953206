import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, themedValue } from '../../__styling'

export const StyledWidgetContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: calcRem(16),
    justifyContent: 'center',
})

export const StyledWidgetTeamLogoContainer = styled('div')({
    display: 'none',
    width: 'auto',
    margin: `0 ${calcRem(50)}`,
    height: '100%',

    ':first-of-type': {
        [breakpoint('sm')]: {
            display: 'block',
            marginLeft: '0',
        },
        [breakpoint('md')]: {
            margin: `0 ${calcRem(50)}`,
        },
    },
    ':last-of-type': {
        [breakpoint('sm')]: {
            display: 'block',
            marginRight: '0',
        },
        [breakpoint('md')]: {
            margin: `0 ${calcRem(50)}`,
        },
    },
})

export const StyledWidgetTeamLogoContainerMobile = styled('div')({
    display: 'block',
    width: 'auto',
    margin: `0 ${calcRem(50)}`,
    height: '100%',

    [breakpoint('sm')]: {
        display: 'none',
    },
})

export const StyledWidgetTeamLogo = styled('img')({
    width: calcRem(48),

    [breakpoint('sm')]: {
        width: calcRem(64),
    },
    [breakpoint('md')]: {
        width: calcRem(100),
    },
})

export const StyledWidgetInnerContainer = styled('div')({
    display: 'block',
    textAlign: 'center',
})

export const StyledWidgetTopRowContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: calcRem(20),

    [breakpoint('sm')]: {
        marginBottom: 0,
    },
})

export const StyledWidgetChangesInfo = styled('span')({
    fontSize: calcRem(12),
    fontWeight: 300,
    color: tokens.sevennews.colors.palette.bauhausBlack + '80',
    display: 'inline-block',
    marginBottom: calcRem(10),
})

export const StyledWidgetRowContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1.2fr 0.6fr 1.2fr',
    alignItems: 'center',
    marginBottom: calcRem(16),

    [breakpoint('sm')]: {
        gridTemplateColumns: '1.1fr 0.8fr 1.1fr',
    },
    [breakpoint('md')]: {
        gridTemplateColumns: '1.2fr 0.6fr 1.2fr',
    },
})

export const StyledWidgetInRowContainer = styled(StyledWidgetRowContainer)({
    borderBottom: `1px solid #E0E0E0`,
    paddingBottom: calcRem(16),
})

export const StyledHomePlayersContainer = styled('div')({
    display: 'block',
    textAlign: 'right',
    fontSize: calcRem(13),
    fontWeight: 400,

    [breakpoint('sm')]: {
        fontSize: calcRem(14),
    },
})

export const StyledAwayPlayersContainer = styled('div')({
    display: 'block',
    textAlign: 'left',
    fontSize: calcRem(13),
    fontWeight: 400,

    [breakpoint('sm')]: {
        fontSize: calcRem(14),
    },
})

export const StyledOutIndicator = styled('span')({
    display: 'block',
    fontSize: calcRem(11),
    fontWeight: 700,
    textAlign: 'center',
    backgroundColor: tokens.sevennews.colors.palette.bauhausBlack,
    color: tokens.sevennews.colors.palette.white,
    width: calcRem(50),
    height: calcRem(20),
    margin: '0 auto',
    lineHeight: calcRem(20),

    [breakpoint('sm')]: {
        fontSize: calcRem(14),
        width: calcRem(80),
        height: calcRem(24),
        lineHeight: calcRem(24),
    },
})

export const StyledInIndicator = styled(StyledOutIndicator)({
    backgroundColor: tokens.sevennews.colors.palette.white,
    color: tokens.sevennews.colors.palette.red,
    border: `1px solid ${tokens.sevennews.colors.palette.red}`,
    width: calcRem(40),
    lineHeight: calcRem(19),

    [breakpoint('sm')]: {
        lineHeight: calcRem(22),
        width: calcRem(64),
    },
})

export const StyledWidgetLegendContainer = styled('div')({
    display: 'block',
    maxWidth: calcRem(640),
    margin: '0 auto',
    color: tokens.sevennews.colors.palette.bauhausBlack + '80',
    fontWeight: 300,
    fontSize: calcRem(11),
    lineHeight: calcRem(14),
    textAlign: 'center',
    marginTop: calcRem(16),
})

export const StyledWidgetSquadButton = styled('a')(({ theme }) => ({
    display: 'block',
    fontSize: themedValue(theme, {
        sevennews: calcRem(12),
        perthnow: calcRem(14),
        thewest: calcRem(13),
        fallback: undefined,
    }),
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.bauhausSport,
        perthnow: tokens.perthnow.colors.palette.pinkThulite,
        thewest: tokens.thewest.colors.palette.westblue,
        fallback: undefined,
    }),
    color: tokens.sevennews.colors.palette.white,
    margin: '0 auto',
    marginTop: calcRem(16),
    borderRadius: themedValue(theme, {
        sevennews: calcRem(4),
        thewest: calcRem(3),
        fallback: undefined,
    }),
    width: themedValue(theme, {
        sevennews: calcRem(102),
        perthnow: calcRem(135),
        thewest: calcRem(135),
        fallback: undefined,
    }),
    height: themedValue(theme, {
        sevennews: calcRem(24),
        perthnow: calcRem(40),
        thewest: calcRem(32),
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        sevennews: calcRem(24),
        perthnow: calcRem(40),
        thewest: calcRem(32),
        fallback: undefined,
    }),
    cursor: 'pointer',
    marginBottom: themedValue(theme, {
        perthnow: calcRem(32),
        fallback: undefined,
    }),
}))
