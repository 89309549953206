import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { bestAustralianYarnSegment } from '../../home/best-australian-yarn-segment'
import { GetMainSectionFn } from '../custom-topic-routes'

type GenWestTopic =
    | 'news'
    | 'features'
    | 'sport'
    | 'arts'
    | 'books'
    | 'careers'
    | 'snapshot'
    | 'what-s-hot'
    | 'puzzles'
    | 'best-australian-yarn'

const getBanner = () => {
    const largeImage2x = require<string>('./assets/genwest/genwest-large-banner-image-2x.jpg')
    const largeImage1x = require<string>('./assets/genwest/genwest-large-banner-image-1x.jpg')
    const mediumImage2x = require<string>('./assets/genwest/genwest-medium-banner-image-2x.jpg')
    const mediumImage1x = require<string>('./assets/genwest/genwest-medium-banner-image-1x.jpg')
    const smallImage2x = require<string>('./assets/genwest/genwest-small-banner-image-2x.jpg')
    const smallImage1x = require<string>('./assets/genwest/genwest-small-banner-image-1x.jpg')

    return {
        type: 'image-banner',
        props: {
            defaultSrc: largeImage2x,
            verticalGutters: ['unset', 'md'],
            altText: 'GenWest banner image',
            sources: [
                {
                    breakpoint: 'md',
                    src: [
                        { src: largeImage2x, density: '2x' },
                        { src: largeImage1x, density: '1x' },
                    ],
                },
                {
                    breakpoint: 'xs',
                    src: [
                        { src: mediumImage2x, density: '2x' },
                        { src: mediumImage1x, density: '1x' },
                    ],
                },
                {
                    src: [
                        { src: smallImage2x, density: '2x' },
                        { src: smallImage1x, density: '1x' },
                    ],
                },
            ],
        },
    }
}

const getSection = (topic: GenWestTopic, heading?: string) => {
    return {
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fontScale: 1.2,
            fixedRatios: ['16:9'],
            hasBackground: true,
            teaserMode: {
                initial: 'visible',
                xs: 'visible',
                sm: 'hidden',
                md: 'hidden',
            },
            initialColumns: 1,
            intermediateColumns: 2,
            finalColumns: 4,
            cardOrientation: { type: 'Landscape-Portrait' },

            sectionHeader: {
                heading: heading ? heading : topic,
                headingUrl: '/genwest/' + topic,
            },

            dataDefinitionArgs: {
                type: 'listing',
                topics: ['genwest/' + topic],
                includeSubTopics: false,
                paging: {
                    page: 1,
                    pageSize: 4,
                },
            },
        },
    }
}

export const getGenwestRouteInfo: GetMainSectionFn = (
    sectionBuilder,
    store,
): any => {
    const bayIsEnabled = isFeatureEnabled(
        toFeatureState(store!.getState().toggles),
        'best-australian-yarn-gen-west',
        true,
    )

    const BAY = []
    if (bayIsEnabled) {
        BAY.push(bestAustralianYarnSegment({ isGenwest: true }))
    }

    const sections = [
        ...sectionBuilder.getCuratedSection(undefined, true),
        ...BAY,
        getSection('news'),
        getSection('features'),
        getSection('sport'),
        getSection('arts'),
        getSection('books'),
        getSection('careers'),
        getSection('snapshot'),
        getSection('what-s-hot', `What's Hot`),
        getSection('puzzles'),
    ]
    return sections
}
