import pino from 'pino'
import { getWANTopLevelSection } from '../nielsen'
import {
    debugIpsos,
    IpsosPathNames,
    IpsosRenderType,
    IpsosSet,
    IpsosSiteNames,
    isPerthNowCategories,
    isSevenNewsCategories,
    isTheWestCategories,
    isTheNightlyCategories,
} from './types'

// Similar to nielsen, we want to follow the below system when we are looking into triggering
// the AJAX call on the frontend

// On a hard refresh (which the below variable will be recompiled?) it will be reset to -1, and we don't want to do an
// an AJAX call then as the script load will count as one load. It's only after that on subsequent page loads
// that we will trigger AJAX calls.

let pageLoadCounter = -1

const logger = pino()

/**
 * @param siteName
 * Generally will be req.hostname or window.location.hostnmae

 * Function is used to retrieve the corresponding IpsosSiteName for a hostname.
 */
export const returnIpsosSiteName = (
    siteName: string,
): IpsosSiteNames | undefined => {
    if (siteName.includes('localhost')) {
        return 'thewest'
    }

    // Gets the domain name for branch deploys/dev domains as well.
    const matchedSiteName = siteName.match(
        /(?:www.)*([a-zA-Z0-9_.]*)(?:-pr\d+|.dev|.com.au)/,
    )

    debugIpsos('Site Name %o', matchedSiteName)

    // Capturing 7news exception
    if (matchedSiteName && matchedSiteName[1] === '7news') {
        matchedSiteName[1] = 'sevennews'
    }

    // Capturing group is in index one
    return !matchedSiteName || matchedSiteName.length > 2
        ? undefined
        : (matchedSiteName[1] as IpsosSiteNames)
}

/**
 * @param siteName
 * Generally will be req.hostname or window.location.hostname
 * @param pathName
 * The path of the request
 * @param callType
 * How Ipsos is being injected, calls one of the corresponding script/AJAX functions.

 * E2E function for triggering Ipsos, handles the Web/Amp/AJAX situations.
 */
export const injectIpsos = (
    siteName: string,
    pathName: string,
    callType: IpsosRenderType,
) => {
    try {
        const typedSiteName = returnIpsosSiteName(siteName)

        if (!typedSiteName) {
            throw new Error('Invalid site names')
        }

        const topLevelPathName = getWANTopLevelSection({ pathname: pathName })

        // Get sectionId
        const sectionId = getIpsosSectionID(typedSiteName, topLevelPathName)

        debugIpsos(`Performing Ipsos ${callType} action %o`, {
            typedSiteName,
            topLevelPathName,
            sectionId,
            pageLoadCounter,
            callType,
        })

        if (sectionId) {
            switch (callType) {
                case 'ajax': {
                    pageLoadCounter++
                    if (pageLoadCounter === 0) {
                        return
                    }

                    return window.dm.AjaxEvent('pageview', null, sectionId)
                }
                case 'amp': {
                    if (
                        typedSiteName === 'sevennews' &&
                        pathName.includes('/motoring')
                    ) {
                        return returnMotoringAmpIpsosScript()
                    }
                    return returnAmpIpsosScript(sectionId.toString())
                }

                default: {
                    return returnWebIpsosScript(sectionId.toString())
                }
            }
        }
    } catch (e) {
        logger.warn(
            { err: e },
            `Unable to return Ipsos Script for ${siteName} + ${getWANTopLevelSection(
                { pathname: pathName },
            )} + ${callType}` +
                ' Error: ' +
                e,
        )
    }
}

export const returnWebIpsosScript = (sectionId: string) =>
    `
    <script type="text/javascript">
    /* <![CDATA[ */
    (function () {
        window.dm = window.dm || { AjaxData: [] }
        window.dm.AjaxEvent = function (et, d, ssid, ad) {
            dm.AjaxData.push({ et: et, d: d, ssid: ssid, ad: ad })
            if (typeof window.DotMetricsObj != 'undefined') {
                DotMetricsObj.onAjaxDataUpdate()
            }
        }
        var d = document,
            h = d.getElementsByTagName('head')[0],
            s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src =
            'https://au-script.dotmetrics.net/door.js?id=${sectionId}'
        h.appendChild(s)
    })()
/* ]]> */
</script>
`

export const returnAmpIpsosScript = (sectionId: string) =>
    `https://au-script.dotmetrics.net/AmpConfig.json?id=${sectionId}`

const returnMotoringAmpIpsosScript = () =>
    `https://au-script.dotmetrics.net/AmpConfig.json?dom=AMPDOC_HOST&tag=motoring`

/**
 * @param hostName
 * One of the IpsosSiteNames
 * @param pathName
 * One of the IpsosPathNames, or a section that is not covered by Ipsos.

 * Returns the sectionId for a corresponding hostName + pathName
 */
export const getIpsosSectionID = (
    hostName: IpsosSiteNames,
    pathName: IpsosPathNames | string,
) => {
    try {
        if (pathName === 'home') {
            return IpsosSet[hostName].homepage
        }

        // In case we ever get a category of other in the future.
        if (pathName === 'other' || !(pathName in IpsosSet[hostName])) {
            return IpsosSet[hostName].other
        }

        switch (hostName) {
            case 'sevennews': {
                if (isSevenNewsCategories(pathName)) {
                    return IpsosSet['sevennews'][pathName]
                }

                break
            }

            case 'thenightly': {
                if (isTheNightlyCategories(pathName)) {
                    return IpsosSet['thenightly'][pathName]
                }

                break
            }

            case 'perthnow': {
                if (isPerthNowCategories(pathName)) {
                    return IpsosSet['perthnow'][pathName]
                }
                break
            }

            case 'thewest': {
                if (isTheWestCategories(pathName)) {
                    return IpsosSet['thewest'][pathName]
                }
                break
            }
        }
    } catch (e) {
        logger.warn(
            { err: e },
            `Invalid set of parameters passed to getIpsosSectionID ${hostName} + ' ' + ${pathName}` +
                ' Error: ' +
                e,
        )
    }
}
