import {
    CardBreakpointRatios,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    FixedRatio,
    Product,
} from '@news-mono/web-common'
import React from 'react'
import { MediaMode } from '../../cards/CardMedia/CardMedia'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { KickerMode } from '../../cards/Kicker/Kicker'
import { LandscapeLayout } from '../../cards/Landscape/Landscape.layouts'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../data-controllers/LoadMore/LoadMore'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import { Juliet } from '../../collections/Juliet/Juliet'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import {
    ContainerWidthRatios,
    ResponsivePictureSizes,
} from '../../content/Picture/responsive'

export interface JulietRouteProps
    extends CollectionRouteProps,
        LoadMoreRouteProps {
    hideByline?: boolean
    showLargeCard?: boolean
    timestamp?: TimestampType
    cardType: LandscapeLayout | 'LandscapeWide'
    fixedRatios?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    imageWidths?: ResponsivePictureSizes
    removeHorizontalGutters?: boolean
    removeTopListPadding?: boolean
    disableImageLazyLoad?: boolean
    stickyOffset?: number | undefined
    mediaMode?: MediaMode
    teaserMode?: TeaserMode
    kickerMode?: KickerMode
    contentIsTangential?: boolean
    containerWidthRatios?: ContainerWidthRatios
    noHorizontalDividers?: boolean
    topicLevel?: 'parent'
    hideLastDivider?: boolean
    numbered?: boolean
}

export const JulietRegistration = createRegisterableComponentWithData(
    'juliet',
    ContentDataDefinitionLoader,
    (props: JulietRouteProps, data, services) => {
        return (
            <LoadMore
                dataDefinition={data.dataDefinitionArgs}
                services={services}
                loadMoreEnabled={props.loadMoreEnabled === true}
                data={data}
                onLoadMore={(loadMoreData) => {
                    services.onEvent(
                        createCollectionAvailableEvent(
                            loadMoreData.result.publications,
                            'juliet',
                            Product.TheWest,
                            services.onEvent,
                        ),
                    )
                }}
                render={(loadMoreData) => {
                    return (
                        <Juliet
                            items={
                                loadMoreData.loaded
                                    ? {
                                          loaded: true,
                                          result: loadMoreData.result
                                              .publications,
                                      }
                                    : { loaded: false }
                            }
                            expectedCards={calculateExpectedCardCountFromContentData(
                                data.dataDefinitionArgs,
                            )}
                            sectionHeader={props.sectionHeader}
                            kickerMode={props.kickerMode}
                            teaserMode={props.teaserMode}
                            hideByline={props.hideByline}
                            fixedRatios={props.fixedRatios}
                            imageWidths={props.imageWidths}
                            cardType={props.cardType}
                            showLargeCard={props.showLargeCard}
                            timestamp={props.timestamp}
                            removeHorizontalGutters={
                                props.removeHorizontalGutters
                            }
                            removeTopListPadding={props.removeTopListPadding}
                            verticalSpacing={
                                props.loadMoreEnabled
                                    ? props.verticalSpacing
                                    : 'md'
                            }
                            disableImageLazyLoad={props.disableImageLazyLoad}
                            onEvent={services.onEvent}
                            mediaMode={props.mediaMode}
                            contentIsTangential={props.contentIsTangential}
                            containerWidthRatios={props.containerWidthRatios}
                            noHorizontalDividers={props.noHorizontalDividers}
                            topicLevel={props.topicLevel}
                            hideLastDivider={props.hideLastDivider}
                            numbered={props.numbered}
                        />
                    )
                }}
            />
        )
    },
)
