import {
    CropDTO,
    ImageV4DTO,
    ImageWithCropsV4DTO,
    KnownRatio,
    KnownRatioToRatio,
    RatioDTO,
    simplifyIfKnownRatio,
} from '@west-australian-newspapers/publication-types'
import {
    ImageCrop,
    ImageSourceWithCaptions,
} from '../../../client-data-types/image-item'

export function cropDtoToImageCrop(
    dto: CropDTO | undefined,
): ImageCrop | undefined {
    if (!dto) {
        return
    }

    return {
        reference: dto.reference,
        ratio:
            typeof dto.ratio === 'object'
                ? dto.ratio
                : KnownRatioToRatio[dto.ratio],
        height: dto.height,
        width: dto.width,
    }
}

export function imageDtoToImageCrop(
    image: ImageV4DTO,
    altFallback?: string,
): ImageSourceWithCaptions {
    return {
        // fallback to copyrightByline removed as per https://jira.swmdigital.io/browse/DPO-502
        byline: image.byline || undefined,
        captionText: image.captionText || '',
        credit: image.credit || image.copyrightCredit || undefined,
        altText: image.altText || image.captionText || altFallback,
        crops: {
            original: cropDtoToImageCrop(image),
            '4:3': cropDtoToImageCrop(pickImage(['4:3'], [image])),
            '161:229': cropDtoToImageCrop(pickImage(['161:229'], [image])),
            '16:9': cropDtoToImageCrop(pickImage(['16:9'], [image])),
            '316:421': cropDtoToImageCrop(pickImage(['316:421'], [image])),
        },
    }
}

export function imageWithCropsDtoToImageCrop(
    imageWithCrops: ImageWithCropsV4DTO,
): ImageSourceWithCaptions {
    return {
        // fallback to copyrightByline removed as per https://jira.swmdigital.io/browse/DPO-502
        byline: imageWithCrops.byline || undefined,
        captionText: imageWithCrops.captionText || '',
        credit:
            imageWithCrops.credit ||
            imageWithCrops.copyrightCredit ||
            undefined,
        altText:
            imageWithCrops.altText || imageWithCrops.captionText || undefined,

        crops: {
            original: cropDtoToImageCrop(imageWithCrops.original),
            '16:9': cropDtoToImageCrop(
                pickImage(['16:9'], imageWithCrops.crops) ||
                    pickImage(['16:9'], [imageWithCrops.original]),
            ),
            '4:3': cropDtoToImageCrop(
                pickImage(['4:3'], imageWithCrops.crops) ||
                    pickImage(['4:3'], [imageWithCrops.original]),
            ),
            '316:421': cropDtoToImageCrop(
                pickImage(['316:421'], imageWithCrops.crops) ||
                    pickImage(['316:421'], [imageWithCrops.original]),
            ),
            '161:229': cropDtoToImageCrop(
                pickImage(['161:229'], imageWithCrops.crops) ||
                    pickImage(['161:229'], [imageWithCrops.original]),
            ),
        },
    }
}

export function mergeImageDtosToImageSource(
    ...dtoImages: ImageV4DTO[]
): ImageSourceWithCaptions | undefined {
    if (dtoImages.length === 0) {
        return
    }
    const original = dtoImages[0]
    // This allows crops to get selected first over original
    const originalLast = [...dtoImages.splice(1), original]

    const mergedImage: ImageSourceWithCaptions = {
        // SWM-5224 - fallback to copywright byline/credit when byline/credit not available
        byline: original.byline || undefined,
        captionText: original.captionText || '',
        credit: original.credit || original.copyrightCredit || undefined,
        altText: original.altText || original.captionText,
        crops: {
            original: cropDtoToImageCrop(original),
            '4:3': cropDtoToImageCrop(pickImage(['4:3'], originalLast)),
            '161:229': cropDtoToImageCrop(pickImage(['161:229'], originalLast)),
            '16:9': cropDtoToImageCrop(pickImage(['16:9'], originalLast)),
            '316:421': cropDtoToImageCrop(pickImage(['316:421'], originalLast)),
        },
    }

    return mergedImage
}

export function pickImage<T extends { ratio: KnownRatio | RatioDTO }>(
    preferredRatios: KnownRatio[],
    imageSet: T[],
) {
    return imageSet.find((image) => {
        if (typeof image.ratio === 'string') {
            return preferredRatios.includes(image.ratio)
        }

        const simplified = simplifyIfKnownRatio(image.ratio)
        if (typeof simplified === 'string') {
            return preferredRatios.includes(simplified)
        }

        return false
    })
}
