import {
    AddToCartEvent,
    BreachEvent,
    BreachEventProps,
    DataLayerEventName,
    RemoveFromCartEvent,
} from '@news-mono/web-common'

export function createLinkClickEvent(
    creative: string,
    deal: string,
    onEvent: (event: BreachEvent) => void,
    breachScreenType: BreachEventProps['breachScreenType'] = 'Premium - Inline',
    leadDepth?: number,
    addToCartPayload?: AddToCartEvent['payload'],
    removeFromCartPayload?: RemoveFromCartEvent['payload'],
): () => void {
    const eventPayload: BreachEvent['payload'] = {
        paywallType: 'freemium',
        leadDepth: leadDepth ?? 0,
        creative: creative,
        breachScreenType,
        popupMethod: 'auto',
        deal: deal,
        pricepoint: [7, 8],
    }

    return () => {
        onEvent({
            type: DataLayerEventName.breachScreenClick,
            originator: 'breachscreen',
            payload: eventPayload,
        })

        // ga4 - `add_to_cart` event
        addToCartPayload &&
            onEvent({
                type: DataLayerEventName.addToCart,
                originator: 'breachscreen',
                payload: addToCartPayload,
            })
        // ga4 - `remove_from_cart` event
        removeFromCartPayload &&
            onEvent({
                type: DataLayerEventName.removeFromCart,
                originator: 'breachscreen',
                payload: removeFromCartPayload
            })
    }
}
