import React from 'react'
import { TeamBrandData } from '../MatchTimelineWidget/helpers/teamBrandData'
import {
    StyledTimelineCardBehindScore,
    StyledTimelineCardContainer,
    StyledTimelineCardFirstname,
    StyledTimelineCardGoalScore,
    StyledTimelineCardLastname,
    StyledTimelineCardLogo,
    StyledTimelineCardLogoBackground,
    StyledTimelineCardOuter,
    StyledTimelineCardQuarter,
    StyledTimelineCardRushedLabel,
    StyledTimelineCardScoreContainer,
    StyledTimelineCardScoreType,
    StyledTimelineCardTime,
} from '../TimelineCard/TimelineCard.styled'

export interface CardData {
    type: string
    quarter: string
    timeInQuarter: string
    playerFirstname: string
    playerLastname: string
    playerGoalsScored: number
    playerBehindScored: number
    teamId: string
}

export interface TimelineCardProps {
    cardData: CardData
    teamBrandData: TeamBrandData
}

export const TimelineCard: React.FC<TimelineCardProps> = ({
    cardData,
    teamBrandData,
}) => {
    return (
        <StyledTimelineCardOuter>
            <StyledTimelineCardContainer
                isRushed={cardData.type === 'rushed'}
                brandHexCode={teamBrandData.hexCode}
            >
                <div>
                    <StyledTimelineCardScoreType
                        isRushed={cardData.type === 'rushed'}
                    >
                        {cardData.type}
                    </StyledTimelineCardScoreType>
                    <StyledTimelineCardFirstname>
                        {(
                            cardData.playerFirstname +
                            ' ' +
                            cardData.playerLastname
                        ).length > 18
                            ? cardData.playerFirstname.charAt(0) + '.'
                            : cardData.playerFirstname}{' '}
                        <StyledTimelineCardLastname>
                            {cardData.playerLastname}
                        </StyledTimelineCardLastname>
                    </StyledTimelineCardFirstname>
                    <StyledTimelineCardScoreContainer
                        isRushed={cardData.type === 'rushed'}
                    >
                        {cardData.type === 'rushed' ? (
                            <>
                                <StyledTimelineCardRushedLabel>
                                    Behind{'   '}
                                </StyledTimelineCardRushedLabel>
                            </>
                        ) : (
                            <>
                                <StyledTimelineCardGoalScore>
                                    {cardData.playerGoalsScored} Goal{' '}
                                </StyledTimelineCardGoalScore>
                                <StyledTimelineCardBehindScore>
                                    : {cardData.playerBehindScored} Behind{' '}
                                </StyledTimelineCardBehindScore>
                            </>
                        )}
                        <StyledTimelineCardQuarter>
                            / {cardData.quarter} :{' '}
                        </StyledTimelineCardQuarter>
                        <StyledTimelineCardTime>
                            {cardData.timeInQuarter}
                        </StyledTimelineCardTime>
                    </StyledTimelineCardScoreContainer>
                </div>
                {cardData.type !== 'rushed' ? (
                    <StyledTimelineCardLogoBackground
                        brandHexCode={teamBrandData.hexCode}
                    />
                ) : undefined}
                <StyledTimelineCardLogo src={teamBrandData.logo} />
            </StyledTimelineCardContainer>
        </StyledTimelineCardOuter>
    )
}
