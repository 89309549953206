import { tokens } from '@news-mono/design-tokens'
import { OutlineStyleProperty } from 'csstype'
import {
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../../__styling'
import { InteractionState } from '../../../__styling/style-functions/interactionState'
import { ButtonColorVariant, ButtonFillVariant } from './DefaultButton'

export type ButtonTheme<T extends ThemeConfig> = Record<
    ButtonColorVariant,
    Record<
        ButtonFillVariant,
        {
            interactionState: InteractionState<{
                background: string
                textColor: string
                border?: {
                    width?: number
                    color?: string
                    style: OutlineStyleProperty
                }
            }>
        }
    >
> & {
    textType: T['textType']
    borderRadius: string
    gap: string
    padding: string
}

const { neutral, uplate, utility } = tokens.thenightly.colors.palette

const whiteOverlay = (opacity: number, color: string) => {
    const hexOpacity = Math.round((1 - opacity) * 255).toString(16)
    return `${color}${hexOpacity}`
}

export const theNightlyButtonTheme = (scheme: TheNightlyTheme) => {
    const theme: ButtonTheme<TheNightlyThemeConfig> = {
        textType: 'button-label',
        gap: calcRem(scheme.margins.xs),
        padding: calcRem(12, 24),
        borderRadius: calcRem(8),
        primary: {
            outline: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: neutral[100],
                        border: {
                            width: 1,
                            color: neutral[100],
                            style: 'solid',
                        },
                    },
                    hover: {
                        background: neutral[20],
                        textColor: neutral[100],
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, neutral[100]),
                            style: 'solid',
                        },
                    },
                    focus: {
                        background: neutral[20],
                        textColor: neutral[100],
                        border: {
                            width: 1,
                            color: neutral[100],
                            style: 'solid',
                        },
                    },
                    active: {
                        background: neutral[20],
                        textColor: neutral[100],
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, neutral[100]),
                            style: 'solid',
                        },
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                        border: {
                            width: 1,
                            color: neutral[40],
                            style: 'solid',
                        },
                    },
                },
            },
            filled: {
                interactionState: {
                    default: {
                        background: neutral[100],
                        textColor: neutral[0],
                    },
                    hover: {
                        background: whiteOverlay(0.3, neutral[100]),
                        textColor: neutral[0],
                    },
                    focus: {
                        background: whiteOverlay(0.3, neutral[100]),
                        textColor: neutral[0],
                        border: {
                            width: 1,
                            color: neutral[100],
                            style: 'solid',
                        },
                    },
                    active: {
                        background: whiteOverlay(0.4, neutral[100]),
                        textColor: neutral[0],
                    },
                    disabled: {
                        background: whiteOverlay(0.3, neutral[30]),
                        textColor: neutral[40],
                    },
                },
            },
            text: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: neutral[100],
                    },
                    hover: {
                        background: neutral[10],
                        textColor: neutral[100],
                    },
                    focus: {
                        background: neutral[30],
                        textColor: neutral[100],
                    },
                    active: {
                        background: neutral[10],
                        textColor: neutral[100],
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                    },
                },
            },
        },
        secondary: {
            outline: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: uplate.default,
                        border: {
                            width: 1,
                            color: uplate.default,
                            style: 'solid',
                        },
                    },
                    hover: {
                        background: uplate.light,
                        textColor: uplate.default,
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, uplate.default),
                            style: 'solid',
                        },
                    },
                    focus: {
                        background: uplate.light,
                        textColor: uplate.default,
                        border: {
                            width: 1,
                            color: uplate.default,
                            style: 'solid',
                        },
                    },
                    active: {
                        background: uplate.light,
                        textColor: uplate.default,
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, uplate.default),
                            style: 'solid',
                        },
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                        border: {
                            width: 1,
                            color: neutral[40],
                            style: 'solid',
                        },
                    },
                },
            },
            filled: {
                interactionState: {
                    default: {
                        background: uplate.default,
                        textColor: neutral[0],
                    },
                    hover: {
                        background: whiteOverlay(0.3, uplate.default),
                        textColor: neutral[0],
                    },
                    focus: {
                        background: whiteOverlay(0.3, uplate.default),
                        textColor: neutral[0],
                        border: {
                            width: 1,
                            color: uplate.default,
                            style: 'solid',
                        },
                    },
                    active: {
                        background: whiteOverlay(0.4, uplate.default),
                        textColor: neutral[0],
                    },
                    disabled: {
                        background: whiteOverlay(0.4, neutral[40]),
                        textColor: neutral[40],
                    },
                },
            },
            text: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: uplate.default,
                    },
                    hover: {
                        background: neutral[10],
                        textColor: uplate.default,
                    },
                    focus: {
                        background: uplate.light,
                        textColor: uplate.default,
                    },
                    active: {
                        background: neutral[10],
                        textColor: uplate.default,
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                    },
                },
            },
        },
        emphasis: {
            outline: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: utility.error.default,
                        border: {
                            width: 1,
                            color: utility.error.default,
                            style: 'solid',
                        },
                    },
                    hover: {
                        background: utility.error.light,
                        textColor: utility.error.default,
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, utility.error.default),
                            style: 'solid',
                        },
                    },
                    focus: {
                        background: utility.error.light,
                        textColor: utility.error.default,
                        border: {
                            width: 1,
                            color: utility.error.default,
                            style: 'solid',
                        },
                    },
                    active: {
                        background: utility.error.light,
                        textColor: utility.error.default,
                        border: {
                            width: 1,
                            color: whiteOverlay(0.3, utility.error.default),
                            style: 'solid',
                        },
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                        border: {
                            width: 1,
                            color: neutral[40],
                            style: 'solid',
                        },
                    },
                },
            },
            filled: {
                interactionState: {
                    default: {
                        background: utility.error.default,
                        textColor: neutral[0],
                    },
                    hover: {
                        background: whiteOverlay(0.3, utility.error.default),
                        textColor: neutral[0],
                    },
                    focus: {
                        background: whiteOverlay(0.3, utility.error.default),
                        textColor: neutral[0],
                        border: {
                            width: 1,
                            color: utility.error.default,
                            style: 'solid',
                        },
                    },
                    active: {
                        background: whiteOverlay(0.4, utility.error.default),
                        textColor: neutral[0],
                    },
                    disabled: {
                        background: whiteOverlay(0.4, neutral[40]),
                        textColor: neutral[40],
                    },
                },
            },
            text: {
                interactionState: {
                    default: {
                        background: neutral[0],
                        textColor: utility.error.default,
                    },
                    hover: {
                        background: neutral[10],
                        textColor: utility.error.default,
                    },
                    focus: {
                        background: utility.error.light,
                        textColor: utility.error.default,
                    },
                    active: {
                        background: neutral[10],
                        textColor: utility.error.default,
                    },
                    disabled: {
                        background: neutral[0],
                        textColor: neutral[40],
                    },
                },
            },
        },
    }
    return theme
}
