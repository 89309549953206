import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { metrics } from '../../../__styling'
import {
    breakpoint,
    breakpointMax,
} from '../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { StyledBylineClassName } from '../../CardByline/CardByline.styled'
import { CardMedia } from '../../CardMedia/CardMedia'
import { CardText } from '../../CardText/CardText'
import { StyledHeadlineText } from '../../CardText/CardText.styled'

interface LargeCardProps {
    cardNumber: number
    horizontalLayoutAtSize?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
}
interface LargeCardMediaWrapProps {
    hideImage?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
}

interface StyledLargeCardContentWrapProps {
    hideText?: {
        size: 'sm' | 'md' | 'lg' | 'all'
    }
}

export const StyledLargeCardClassName = 'StyledLargeCard'

export const StyledLargeCard = withClass(StyledLargeCardClassName)(
    styled('div')<LargeCardProps>(
        ({ theme, cardNumber, horizontalLayoutAtSize }) => ({
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            [breakpoint('sm')]: {
                flexDirection:
                    horizontalLayoutAtSize &&
                    horizontalLayoutAtSize.size === 'md'
                        ? 'row'
                        : 'column',
            },
            [breakpoint('md')]: {
                flexDirection:
                    horizontalLayoutAtSize &&
                    horizontalLayoutAtSize.size === 'lg'
                        ? 'row'
                        : 'column',
            },
            [breakpointMax('xxs')]: {
                paddingBottom:
                    theme.kind === 'thewest' ? theme.margins.md : undefined,
            },
        }),
    ),
)

export const StyledLargeCardMediaWrap = styled('div')<LargeCardMediaWrapProps>(
    ({ theme, hideImage }) => ({
        display:
            (hideImage && hideImage.size === 'all') ||
            (hideImage && hideImage.size === 'sm')
                ? 'none'
                : 'block',
        minWidth: calcRem(110),
        [breakpoint('sm')]: {
            minWidth: calcRem(210),
            display:
                (hideImage && hideImage.size === 'all') ||
                (hideImage && hideImage.size === 'md')
                    ? 'none'
                    : 'block',
        },
        [breakpoint('md')]: {
            minWidth: 'unset',
        },
    }),
)
export const StyledLargeCardMedia = styled(CardMedia)(({ theme }) => ({
    marginBottom: theme.margins.sm,
    width: calcRem(110),
    [breakpoint('sm')]: {
        width: '100%',
    },
    [breakpointMax('xxs')]: {
        marginBottom: theme.kind === 'thewest' ? 0 : undefined,
    },
}))

export const StyledLargeCardContentWrap = styled(
    'div',
)<StyledLargeCardContentWrapProps>(({ theme, hideText }) => ({
    display:
        (hideText && hideText.size === 'all') ||
        (hideText && hideText.size === 'sm')
            ? 'none'
            : 'block',
    [breakpoint('sm')]: {
        display:
            (hideText && hideText.size === 'all') ||
            (hideText && hideText.size === 'md')
                ? 'none'
                : 'block',
    },
    [breakpoint('md')]: {
        display:
            (hideText && hideText.size === 'all') ||
            (hideText && hideText.size === 'lg')
                ? 'none'
                : 'block',
    },
}))

export const StyledContentWrapClassName = 'StyledContentWrap'

export const StyledContentWrap = withClass(StyledContentWrapClassName)(
    styled('div')(({ theme }) => ({
        padding: `0 0 ${theme.margins.sm}px ${theme.margins.sm}px`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        //hide byline up to tablet
        [`.${StyledBylineClassName}`]: {
            display: 'none',
            [breakpoint('sm')]: {
                display: 'flex',
            },
        },

        [breakpoint('sm')]: {
            padding: `${theme.margins.sm}px`,
        },
        [breakpoint('md')]: {
            padding: `0px ${theme.margins.sm}px ${theme.margins.sm}px ${theme.margins.sm}px`,
        },
        [breakpointMax('xxs')]: {
            paddingBottom: theme.kind === 'thewest' ? 0 : undefined,
        },
    })),
)

export const StyledLargeCardContent = styled(CardText)(({ theme }) => ({
    [`& ${StyledHeadlineText}`]: {
        [breakpointMax('xxs')]: {
            fontSize:
                theme.kind === 'thewest'
                    ? calcRem(metrics.thewest.cards.headingFontSize)
                    : undefined,
        },

        fontSize: calcRem(14),

        [breakpoint('sm')]: {
            fontSize: calcRem(16),
        },
    },
}))
