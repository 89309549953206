import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    RouteInformation,
    RouteResolution,
    RouteServices,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { staticContentRoutes } from '../static/static-content-routes'

export interface RegionalContactPageOptions {
    section: TheWestSection
    mastheadName: string
}

export const getRegionalContactUsPage = (
    services: RouteServices<RouteResolution>,
    options: RegionalContactPageOptions,
): RouteInformation<TheWestSection> | null => {
    if (
        !isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'regionals',
        )
    ) {
        // DPT-1409: if the section is countryman, we need to return the exsting page for it
        if (options.section === 'countryman') {
            return staticContentRoutes['/contact/countryman'](services)
        }

        return null
    }

    const adTargeting = services.getAdTargeting(
        'home',
        options.section,
        `${options.section}-contact`,
    )

    return {
        kind: 'page',
        heading: 'Contact Us',
        hideHeading: true,
        pageType: 'static',
        pageMeta: {
            title: 'Contact Us',
            description: `Contact Us | ${options.mastheadName}`,
        },
        noMetaTitleTemplate: true,
        adTargeting,
        section: options.section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: 'Contact Us',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'static-content',
                            props: {
                                dataDefinitionArgs: {
                                    id:
                                        options.section === 'countryman'
                                            ? `contact-countryman`
                                            : `${options.section}-contact`,
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
