import { CollectionEvent, PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../../cards/CardItem.Props'
import { MediaMode } from '../../../cards/CardMedia/CardMedia'
import { LandscapeStacked } from '../../../cards/LandscapeStacked/LandscapeStacked'
import { Portrait } from '../../../cards/Portrait/Portrait'
import { StyledGridItem } from '../../../collections/Oscar/Oscar.styled'
import { ResponsiveContainer } from '../../../content/Picture/responsive'

export interface HeroCardProps extends CommonCardProps, ResponsiveContainer {
    disableImageLazyLoad?: boolean
    heroCardType: 'landscape-stacked' | 'portrait'
    mediaMode?: MediaMode
    hasPadding?: boolean
    onEvent: (event: CollectionEvent) => void
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem>
}

export const HeroCard: React.FC<HeroCardProps> = (props) => {
    const {
        onEvent,
        disableImageLazyLoad,
        heroCardType,
        mediaMode,
        hasPadding,
        item,
        cardNumber,
    } = props

    const containerValues = props.containerWidthRatios
        ? props.containerWidthRatios
        : { desktop: 1, tablet: 1, mobile: 1 }

    switch (heroCardType) {
        case 'landscape-stacked':
            return (
                <StyledGridItem>
                    <LandscapeStacked
                        item={item}
                        onEvent={onEvent}
                        disableImageLazyLoad={disableImageLazyLoad}
                        teaserMode="visible"
                        cardNumber={cardNumber}
                    />
                </StyledGridItem>
            )

        case 'portrait':
            return (
                <StyledGridItem>
                    <Portrait
                        item={item}
                        onEvent={onEvent}
                        disableImageLazyLoad={disableImageLazyLoad}
                        hideByline={true}
                        teaserMode="hidden"
                        kickerMode="hidden"
                        fixedRatio={'16:9'}
                        mediaMode={mediaMode}
                        hasBackground={true}
                        hasPadding={hasPadding}
                        fontScale={1}
                        cardNumber={cardNumber}
                        containerWidthRatios={containerValues}
                    />
                </StyledGridItem>
            )

        default:
            return null
    }
}
