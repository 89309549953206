import styled from '@emotion/styled'
import { supportsMutationObserver, usePrevious } from '@news-mono/web-common'
import React from 'react'
import { isRichMediaAd } from '../../advertising/AdUnit/isRichMediaAd'
import { GptSlotManagerContext } from './gpt-slot-manager-context'
import { teadsAdSlotID } from '../../__helpers'

// If we're styling the TEADs ad slot, we want to make sure it and it's children div
// all have a minHeight value in order to allow TEADs to fill it.
export const StyledGptAd = styled('div')<{ id: string }>(({ id }) => ({
    minHeight: id.includes(teadsAdSlotID) ? '4px !important' : undefined,

    '& div': {
        minHeight: id.includes(teadsAdSlotID) ? '4px !important' : undefined,
    },
}))

export type SlotMutationEvent = MutationEvent

export interface GptAdSlotProps {
    id: string
    disabled?: boolean
    adContainerRef: React.MutableRefObject<HTMLDivElement | null>
    onSlotRenderEnded?: (e: googletag.events.SlotRenderEndedEvent) => void
    onSlotMutation?: (id: string, mutations: MutationRecord[]) => void
}

export const GptAdSlot: React.FC<GptAdSlotProps> = (props) => {
    useRegisterSlot(props)
    useHandleAdMutation(props.id, props.onSlotMutation)
    useTriggerAdsOnEnable(props.disabled)

    return <StyledGptAd id={props.id} />
}

/**
 * Manage the registration of the ad slot with the GptAdProvider
 **/
function useRegisterSlot({
    id,
    onSlotRenderEnded = () => {},
    disabled = false,
    adContainerRef,
}: GptAdSlotProps) {
    const gptSlotManager = React.useContext(GptSlotManagerContext)

    // We need to use the adContainer rather than the slot ref because when
    // the slot is hidden until loaded getBoundingClientRect will always return
    // 0, meaning the ad is treated in the viewport. The container is never hidden
    // so it's safe to use
    const getViewportPosition = React.useCallback(
        () => getAdRefViewportPosition(adContainerRef.current),
        [adContainerRef],
    )

    React.useEffect(() => {
        gptSlotManager.registerSlot({
            id,
            disabled,
            onSlotRenderEnded,
            getViewportPosition,
        })

        return () => gptSlotManager.unregisterSlot(id)
        // Need to sort out onSlotRenderEnded so it doesn't cause re-register/unregister constantly
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, getViewportPosition, gptSlotManager, id])
}

/**
 * Tell the gptAdProvider to trigger an ad load if an ad goes form disabled to enabled
 **/
function useTriggerAdsOnEnable(disabled?: boolean) {
    const gptAdProvider = React.useContext(GptSlotManagerContext)
    const prevDisabled = usePrevious(disabled)
    React.useEffect(() => {
        if (prevDisabled && !disabled) {
            gptAdProvider.cleanupAndDisplaySlotsWhenIdle(
                'useTriggerAdsOnEnable',
            )
        }
    }, [prevDisabled, disabled, gptAdProvider])
}

/**
 * Sets up a mutation handler for Ad unit elements. Will use a mutation observer to track these changes and call
 * the onSlotMutation callback.
 **/
function useHandleAdMutation(
    adId: string,
    onSlotMutation?: (id: string, mutations: MutationRecord[]) => void,
) {
    const [forceObserverCleanup, setForceObserverCleanup] =
        React.useState(false)
    const handleMutation = React.useCallback(
        (mutations: MutationRecord[]) => {
            if (isRichMediaAd(document.getElementById(adId))) {
                setForceObserverCleanup(true)
            }
            if (onSlotMutation) onSlotMutation(adId, mutations)
        },
        [adId, onSlotMutation],
    )

    React.useEffect(() => {
        if (forceObserverCleanup) {
            return
        }

        const ad = document.getElementById(adId)!
        const cleanupObserver = observeAdSlotMutations(ad, handleMutation)
        return cleanupObserver
    }, [handleMutation, forceObserverCleanup, adId])
}

function observeAdSlotMutations(
    ad: HTMLElement,
    handleMutation: (mutations: MutationRecord[]) => void,
) {
    if (supportsMutationObserver()) {
        const observer = new MutationObserver(handleMutation)
        observer.observe(ad, {
            attributes: true,
            childList: true,
            subtree: true,
        })
        return () => {
            observer.disconnect()
        }
    }

    // return noop if there is no mutation observer in the window context
    return () => {}
}

function getAdRefViewportPosition(
    ref: HTMLDivElement | null,
): undefined | number {
    if (typeof window === 'undefined') {
        return undefined
    }

    if (!ref) {
        return undefined
    }

    const pos = ref.getBoundingClientRect()
    const viewportHeight = window.innerHeight

    if (process.env.NODE_ENV === 'test') {
        return 0
    }

    // in viewport
    if (pos.top <= viewportHeight && pos.bottom >= 0) {
        return 0
    }

    // above viewport
    if (pos.bottom < 0) {
        return pos.bottom
    }

    // below viewport
    return pos.top - viewportHeight
}
