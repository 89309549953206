import { WebLink } from '@news-mono/web-common'
import React from 'react'
import { StyledSidebarHeader } from './SidebarHeader.styled'
import { GameOnParisLogo } from '../../logos/GameOnParis/GameOnParisLogo'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'

export interface SidebarHeaderProps {
    svgColourOverride?: string
    size: OlympicsWidgetSize
}
export function SidebarHeader({ svgColourOverride, size }: SidebarHeaderProps) {
    return (
        <StyledSidebarHeader svgColourOverride={svgColourOverride} size={size}>
            <WebLink to="/sport/olympics">
                <GameOnParisLogo />
            </WebLink>
        </StyledSidebarHeader>
    )
}
