import React from 'react'
import { Chip } from '../../../chips/Chip/Chip'

export type pinnedChipTypes = 'full' | 'icon-only' | undefined

export function getEventPostChip(
    pinned: pinnedChipTypes,
    happening: string | null | undefined,
    entryType?: string,
) {
    if (pinned === 'full') {
        return <Chip text="Pinned" variant="pinned" />
    }
    if (pinned === 'icon-only') {
        return <Chip variant="pinned" />
    }
    if (happening && !pinned) {
        return (
            <Chip text={happening} variant="happening" entryType={entryType} />
        )
    }
    return null
}
