import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    AflMatchLadderWidget,
    AflMatchLadderWidgetProps,
} from './AflMatchLadderWidget'

export const AflMatchLadderWidgetRegistration = createRegisterableComponent(
    'afl-match-ladder-widget',
    (props: AflMatchLadderWidgetProps, services) => {
        return <AflMatchLadderWidget {...props} />
    },
)
