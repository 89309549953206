import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { get as getCookie, set as setCookie } from 'js-cookie'
import { layout } from '../../../../App.routing'
import { billboardLeaderboardMrec } from '../../../ad-units'

export const desktopLandingBanner1x = require('./assets/bannerDesktop@1x.png')
export const desktopLandingBanner2x = require('./assets/bannerDesktop@2x.png')

export const mobileLandingBanner1x = require('./assets/bannerMobile@1x.png')
export const mobileLandingBanner2x = require('./assets/bannerMobile@2x.png')

export const tabletLandingBanner1x = require('./assets/bannerTablet@1x.png')
export const tabletLandingBanner2x = require('./assets/bannerTablet@2x.png')

export const socialShareImage = require('./assets/SocialImage.png')

export const bestAustralianYarn2023Route: StaticRoutes<TheWestSection> = {
    '/lifestyle/best-australian-yarn/2023': ({
        getAdTargeting,
        store,
        config,
        resolution,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/lifestyle/best-australian-yarn/2023'

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'lifestyle/best-australian-yarn/2023',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                getResolvedTopicPageMeta(resolution)?.title ||
                'Best Australian Yarn 2023',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        const getSocialImageMeta = [
            {
                property: 'og:image',
                content: socialShareImage,
            },
            { name: 'twitter:image', content: socialShareImage },
            {
                property: 'og:image:height',
                content: '643',
            },
            {
                property: 'og:image:width',
                content: '1224',
            },
            {
                property: 'og:description',
                content: topic.seoDescription,
            },
            {
                property: 'twitter:description',
                content: topic.seoDescription,
            },
        ]

        const baySeedVal = store.getState().misc.baySeed

        if (
            typeof window !== 'undefined' &&
            baySeedVal &&
            !getCookie('bayseed')
        ) {
            setCookie('bayseed', baySeedVal)
        }

        return {
            kind: 'page',
            heading: 'Best Australian Yarn 2023',
            pageType: 'topic',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: desktopLandingBanner2x,
                                    verticalGutters: ['unset', 'unset'],
                                    altText: 'Best Australian Yarn - Top 50',
                                    hiddenHeading: 'Best Australian Yarn 2023',
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: desktopLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: desktopLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'sm',
                                            src: [
                                                {
                                                    src: tabletLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: tabletLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: mobileLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: mobileLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            layout.nestedComposition({
                                type: 'thor',
                                props: {
                                    hasBackgroundFill: true,
                                    containerWidth: '100%',
                                    verticalGutters: false,
                                    horizontalGutters: true,
                                    verticalSpacing: 'lg',
                                    themeOverride: 'best-australian-yarn',
                                    hideFeatureHeader: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                verticalSpacing: 'gridGap',
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },
                                                cardLayout: [
                                                    'the-west-hero-lgNoBorder',
                                                    'hero-support',
                                                    'hero-support',
                                                ],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'best-australian-yarn-2023-page',
                                                    offset: 0,
                                                    pageSize: 3,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 0.75,
                                    fixedRatios: ['16:9'],
                                    hasBackground: true,
                                    kickerMode: 'hidden',
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },
                                    hideByline: true,
                                    hideBottomBorder: true,
                                    sectionHeader: {
                                        heading: '2023 Winners',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'best-australian-yarn-2023-page',
                                        offset: 3,
                                        pageSize: 15,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 0.75,
                                    fixedRatios: ['16:9'],
                                    hasBackground: true,
                                    kickerMode: 'hidden',
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },
                                    hideByline: true,
                                    hideBottomBorder: true,
                                    sectionHeader: {
                                        heading: '2023 Top 50',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: [
                                            'lifestyle/best-australian-yarn/2023',
                                        ],
                                        includeSubTopics: false,
                                        paging: {
                                            page: 1,
                                            pageSize: 50,
                                        },
                                        random: true,
                                        random_offset: 0,
                                        random_size: 50,
                                        seed: baySeedVal,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                        ],
                        sidebar: [],
                    },
                }),
            ],
        }
    },
}
