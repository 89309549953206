import React from 'react'
import { Route } from 'react-router-dom'

export interface RouteStatusProps {
    statusCode: number
    component?: any
}

export const RouteStatus: React.FC<RouteStatusProps> = (props) => (
    <Route
        render={({ staticContext }: any) => {
            if (staticContext) {
                staticContext.statusCode = props.statusCode
            }

            if (props.component) {
                return React.createElement(props.component)
            }

            return props.children ? React.Children.only(props.children) : null
        }}
    />
)
