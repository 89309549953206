import { Block, PublicationType } from '@news-mono/web-common'

export const mappedPublicationPropsToGa4 = (publication: PublicationType) => {
    const validPublication =
        publication.kind === 'article' ||
        publication.kind === 'gallery' ||
        publication.kind === 'event'

    if (validPublication) {
        const hero_video_present = publication.heroVideo ? true : false
        let inline_video_present = false

        if (publication.kind === 'article' || publication.kind === 'event') {
            inline_video_present = inlineVideoFound(publication.content) > -1
        }

        return {
            author: publication.byline,
            item_brand: publication.source,
            item_category: publication.primaryTopic.id,
            item_category2: publication.kind,
            item_id: publication.id,
            item_name: publication.heading,
            item_heading: publication.homepageHead,
            item_variant:
                (publication.kind === 'article' ||
                    publication.kind === 'event') &&
                publication.classification?.kind === 'sales'
                    ? true
                    : false,
            publication_date: publication.publicationDate,
            hero_video_present,
            inline_video_present,
            video_present: hero_video_present || inline_video_present,
            // Default values that are required for GA4 but not used for this event
            quantity: 1,
            price: 0.0,
            item_list_id: '',
            item_list_name: '',
            component_path: '',
        }
    }
}

const inlineVideoFound = (blocks: Block[]) =>
    blocks.findIndex((block) => {
        return block.kind === 'inline' && block.inlineKind === 'video'
    })
