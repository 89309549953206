import { mapContentBlocks, SharingEvent } from '@news-mono/web-common'
import React, { FC, useState } from 'react'
import { Logger } from 'typescript-log'

import {
    StyledFooterCardContainer,
    StyledBylineWrap,
    StyledLiveEventArticleBlockContent,
    StyledLiveEventPost,
    StyledLiveEventTimestamp,
    StyledLiveEventTitle,
    StyledShareWrapper,
    StyledFooterCardMobileWrap,
    StyledShareMobileWrapper,
} from './LiveEventPostV2.styled'

import { getPinnedChipType } from '../../publication/LiveEvent/helpers/get-pinned-type'
import { isTabletOrMobileViewport } from '../../__helpers'
import { ShareIcon } from './assets/ShareIcon'
import { Share } from '../HeroCard/Share/Share'
import { ArticleBlockContent } from '../../publication'
import { ByLine } from '../HeroCard/ByLine/ByLine'
import { getEventPostChip } from '../../publication/Byline/helpers/getEventPostChip'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'

export interface LiveEventPostProps {
    post: EventPostV4DTO
    eventTitle: string
    log: Logger
    adUnitPath: string
    lazyLoadRef?: React.RefObject<HTMLDivElement>
    onEvent: (event: SharingEvent) => void
    /** Build event post with an id attribute for deeplinking
     * @default true
     */
    renderIDAnchorTag?: boolean
    /** Element is located inside a truncating container and overflowing */
    canTruncate?: boolean
    isActivePost?: boolean
    entryType?: string
}

export const LiveEventPostV2: FC<LiveEventPostProps> = ({
    post,
    eventTitle,
    log,
    adUnitPath,
    lazyLoadRef,
    onEvent,
    renderIDAnchorTag = true,
    canTruncate,
    isActivePost,
    entryType,
}) => {
    const [showSharePopup, setShowSharePopup] = useState(false)

    const authorName =
        post.profiles.length >= 1
            ? post.profiles.map((profile) => profile.name).join(' &')
            : post.byline

    const pinnedVariant = getPinnedChipType(post.sticky, canTruncate)
    const isMobileOrTablet = isTabletOrMobileViewport()

    const handleShare = async () => {
        if (navigator && typeof navigator.share !== 'undefined') {
            try {
                await navigator.share({
                    url: post._self,
                    text: post.title || undefined,
                })
                setShowSharePopup(false)
            } catch (e) {
                setShowSharePopup(false)
                console.error(e, 'Error while sharing')
            }
        } else {
            setShowSharePopup((prev) => !prev)
        }
    }

    const socialMediaShare = () => {
        return isMobileOrTablet ? (
            <StyledFooterCardMobileWrap>
                <StyledShareMobileWrapper
                    isOpened={showSharePopup}
                    onClick={handleShare}
                >
                    <ShareIcon />
                </StyledShareMobileWrapper>
                {post.publishedDate && (
                    <StyledLiveEventTimestamp
                        timestamp={post.publishedDate}
                        timestampFormat={'relativeLongFormat'}
                    />
                )}
            </StyledFooterCardMobileWrap>
        ) : (
            <StyledShareWrapper isOpened={true}>
                <Share
                    url={post._self}
                    text={post.title || eventTitle}
                    onEvent={() => {}}
                    sharingOptions={['facebookRounded', 'clipboard', 'x']}
                    isLiveBlog={true}
                />
                {post.publishedDate && (
                    <StyledLiveEventTimestamp
                        timestamp={post.publishedDate}
                        timestampFormat={'relativeLongFormat'}
                    />
                )}
            </StyledShareWrapper>
        )
    }
    return (
        <StyledLiveEventPost
            ref={lazyLoadRef}
            key={post.id}
            id={renderIDAnchorTag ? post.id : undefined}
            noBotMargin={canTruncate}
            isActivePost={isActivePost}
        >
            {post.title && (
                <StyledLiveEventTitle>{post.title}</StyledLiveEventTitle>
            )}
            <StyledLiveEventArticleBlockContent>
                <ArticleBlockContent
                    blocks={mapContentBlocks(post.content.blocks)}
                    onEvent={() => {}}
                    log={log}
                    adUnitPath={adUnitPath}
                    publicationKind={'event'}
                />
            </StyledLiveEventArticleBlockContent>

            <StyledFooterCardContainer>
                <StyledBylineWrap>
                    <ByLine
                        profiles={post.profiles}
                        byline={authorName || undefined}
                        isLiveBlog={true}
                        source="7NEWS"
                    />
                    {socialMediaShare()}
                </StyledBylineWrap>
                {getEventPostChip(
                    pinnedVariant,
                    post.happening?.label,
                    entryType,
                )}
            </StyledFooterCardContainer>
        </StyledLiveEventPost>
    )
}
