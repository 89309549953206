import React from 'react'

export function TheWestHeaderProfileIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM4.90798 17.5417C3.71255 16.014 3 14.0902 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.0524 20.313 15.9443 19.1565 17.4583C17.5092 15.3531 14.9448 14 12.0645 14C9.14627 14 6.55245 15.3889 4.90798 17.5417ZM6.32767 18.9878C7.87565 20.2459 9.84977 21 12 21C14.1881 21 16.1939 20.2191 17.7538 18.9209C16.4833 17.1521 14.4084 16 12.0645 16C9.69106 16 7.59352 17.1812 6.32767 18.9878ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13ZM12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11Z"
                fill="#043DE2"
            />
        </svg>
    )
}
