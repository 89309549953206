import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../../App.routing'

const customSportTopicForAds = {
    id: 'sport/cricket',
    title: 'Cricket',
    parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: 'Sport' },
    metadata: {},
    seoTitle: 'Cricket',
}

export const liveScoresRoute: StaticRoutes<TheWestSection> = {
    '/sport/cricket/live-scores': ({ getAdTargeting }) => ({
        kind: 'page',
        heading: 'Cricket Live Scores',
        hideHeading: true,
        pageType: 'static',
        pageMeta: {
            title: 'Cricket Live Scores',
            description: 'Cricket Live Scores for The West Australian',
        },
        noMetaTitleTemplate: true,
        adTargeting: getAdTargeting(
            'live-scores',
            'lifestyle',
            'live-scores',
            customSportTopicForAds,
        ),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: 'Cricket Live Scores',
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: true,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'thor',
                            props: {
                                containerWidth: '100%',
                                horizontalGutters: true,
                                hasBackgroundFill: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'HorizontalCricketWidget',
                                        props: {},
                                    }),
                                    layout.component({
                                        type: 'livescores',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: true,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'thor',
                            props: {
                                containerWidth: '100%',
                                horizontalGutters: true,
                                hasBackgroundFill: false,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1.2,
                                            fixedRatios: ['16:9'],
                                            hasBackground: true,
                                            teaserMode: 'hidden',
                                            loadMoreEnabled: true,
                                            initialColumns: 1,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'Latest Cricket News',
                                            },
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/cricket'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 8,
                                                },
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }),
}
