import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

export const StyledFootnote = styled.p(({ theme }) => {
    return [
        {
            fontWeight: 400,
            a: {
                color: themedValue(theme, {
                    thenightly: tokens.thenightly.colors.palette.deepPurple,
                    fallback: undefined,
                }),
            },
        },
        breakWords,
        ({ theme }) => ({
            fontFamily: themedValue(theme, {
                thewest: theme.fonts.body,
                thenightly: theme.fonts.serif,
                fallback: theme.fonts.sansSerif,
            }),
            fontSize: themedValue(theme, {
                thenightly: calcRem(20),
                fallback: calcRem(14),
            }),
            lineHeight: themedValue(theme, {
                thewest: 1.25,
                perthnow: 1.429, // 20px / 14px
                thenightly: 1.5,
                fallback: undefined,
            }),
            color: themedValue(theme, {
                thewest: colors.black,
                perthnow: colors.perthnow.greyMoonstone,
                thenightly: tokens.thenightly.colors.palette.neutral[70],
                fallback: undefined,
            }),
            margin: themedValue(theme, {
                thewest: `${calcRem(metrics.thewest.textMargin)} 0 0 0`,
                perthnow: `${calcRem(metrics.perthnow.textMargin)} 0 0 0`,
                fallback: undefined,
            }),
            // Needs to clear potential floated images
            clear: theme.kind === Product.TheWest ? 'both' : undefined,
        }),
    ]
})
