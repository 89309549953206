import React from 'react'
import { StyledSixCardCollection } from './SixCardCollection.styled'
import { SkeletonCard } from '../../cards'
import { ThemeMargins } from '../../../__styling'

interface SixCardCollectionSkeletonProps {
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

const gridAreasNames: {
    [key: number]: string
} = {
    0: 'one',
    1: 'two',
    2: 'three',
    3: 'four',
    4: 'five',
    5: 'six',
}

export const SixCardCollectionSkeleton: React.FC<SixCardCollectionSkeletonProps> =
    () => {
        return (
            <StyledSixCardCollection verticalGutters={['unset', 'lg']}>
                {[...new Array(6)].map((_, itemIndex) => {
                    const gridAreaName = gridAreasNames[itemIndex]
                    return (
                        <SkeletonCard
                            showTimeToRead={true}
                            gridAreaName={gridAreaName}
                            textLines={
                                itemIndex === 0 || itemIndex === 1 ? 3 : 2
                            }
                        />
                    )
                })}
            </StyledSixCardCollection>
        )
    }
