import { isCorporateAccount } from '@news-mono/component-library'
import { AppState } from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import {
    StyledFlyoutNavActions,
    StyledIconUser,
    StyledUserLink,
    StyledUserName,
} from './FlyoutNavActions.styled'

interface FlyoutNavActionProps {
    LoginAction: React.ReactType
    LogoutAction: React.ReactType
    SubscribeAction: React.ReactType
}
export const FlyoutNavActions: React.FC<FlyoutNavActionProps> = ({
    LoginAction,
    LogoutAction,
    SubscribeAction,
}) => {
    const { isLoggedIn, userName, subscriptionType } = useSelector(
        (state: AppState) => state.authentication,
    )

    return (
        <StyledFlyoutNavActions>
            {!isLoggedIn ? (
                <React.Fragment>
                    <SubscribeAction />
                    <LoginAction />
                </React.Fragment>
            ) : (
                !isCorporateAccount(subscriptionType) && (
                    <React.Fragment>
                        <StyledUserLink
                            to={'https://subscriber.thewest.com.au/myaccount'}
                            rel="noopener"
                            openExternalInNewWindow={true}
                        >
                            <StyledUserName>{userName}</StyledUserName>{' '}
                            <StyledIconUser />
                        </StyledUserLink>
                        <LogoutAction />
                    </React.Fragment>
                )
            )}
        </StyledFlyoutNavActions>
    )
}
