import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AflSquadsWidget } from './AflSquadsWidget'

export interface AflSquadsWidgetProps {
    apiData: any
    season: string
    competition: string
    isAflw?: boolean
}

export const AflSquadsWidgetRegistration = createRegisterableComponent(
    'afl-squads',
    (props: AflSquadsWidgetProps) => {
        return <AflSquadsWidget {...props} />
    },
)
