import loadable from '@loadable/component'
import {
    CardEvents,
    CollectionEvent,
    LinkClickedEvent,
    PlayerEvent,
} from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { createRenderTarget } from '../../render-target'

// Implementing lazy loaded components is explained in README.md
export const LazyAmpEmbed = loadable(() => import('./Embed.amp'))

export const LazyRssEmbed = loadable(() => import('./Embed.rss'))

export const LazyWebEmbed = loadable(() => import('./Embed.web'))

export interface EmbedProps {
    link: string
    onEvent: (
        event: PlayerEvent | LinkClickedEvent | CardEvents | CollectionEvent,
    ) => void
    log: Logger
    adUnitPath: string
}

export const Embed = createRenderTarget<EmbedProps>('Embed', {
    amp: LazyAmpEmbed,
    web: LazyWebEmbed,
    rss: LazyRssEmbed,
    preview: LazyWebEmbed,
    app: LazyWebEmbed,
})
