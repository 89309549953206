import styled from '@emotion/styled'
import { createPropFilter, WebLinkProps } from '@news-mono/web-common'
import { FundingLabel } from '../../advertising/FundingLabel/FundingLabel'
import { playButtonIconClass } from '../../buttons/PlayButton/PlayButton.styled'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { StyledArticleVideo } from '../../publication/ArticleVideo/ArticleVideo.styled'
import { colors } from '../../__styling/settings/colors'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { CardByline } from '../CardByline/CardByline'
import {
    StyledBylineClassName,
    StyledHeadshotWrapperClassName,
} from '../CardByline/CardByline.styled'
import { CardMedia, MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'
import { CardText } from '../CardText/CardText'
import { StyledHeadlineText, StyledTeaser } from '../CardText/CardText.styled'
import { CardOrientationOptions } from './Portrait'

export interface StyledPortraitCardProps {
    hasBackground?: boolean
    hasSeparator?: boolean
    hasPadding?: boolean
    mediaMode?: MediaMode
    verticalSpacing?: keyof ThemeMargins
    isLarge?: boolean
    cardOrientation?: CardOrientationOptions
    fullWidthOnMobile?: boolean
    noRoundedCorners?: boolean
}

export const StyledPortrait = styled('div')<StyledPortraitCardProps>(
    {
        ...breakWords,
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        alignItems: 'stretch',
    },
    ({
        verticalSpacing,
        theme,
        hasBackground,
        fullWidthOnMobile,
        hasSeparator,
        noRoundedCorners,
    }) => ({
        borderRadius: theme.radius?.lg,
        overflow: !noRoundedCorners && theme.radius ? 'hidden' : undefined,
        marginBottom: verticalSpacing && theme.margins[verticalSpacing],
        fontSize: themedValue(theme, {
            thewest: calcRem(13),
            fallback: calcRem(14),
        }),
        borderBottom: themedValue(theme, {
            perthnow: hasBackground
                ? undefined
                : `1px solid ${theme.colors.borders.secondary}`,

            sevennews: hasSeparator
                ? `1px solid ${colors.sevennews.greyMarble}`
                : undefined,
            fallback: undefined,
        }),
        background: themedValue(theme, {
            perthnow: hasBackground
                ? theme.colors.background.secondary
                : theme.colors.background.primary,
            sevennews: hasBackground
                ? theme.colors.background.secondary
                : theme.colors.background.primary,
            fallback: undefined,
        }),

        [breakpointMax('sm')]: {
            width: fullWidthOnMobile
                ? `calc(100% + ${theme.margins.outerMargin * 2}px)`
                : undefined,
            margin: fullWidthOnMobile
                ? calcRem(0, -theme.margins.outerMargin)
                : undefined,
            marginBottom: verticalSpacing && theme.margins[verticalSpacing],
        },

        /**
         * Videos in articles are wider than they are on the cards, this
         * is to reset it so that they don't extend outside of the card.
         */
        [`${StyledArticleVideo}`]: theme.kind === 'perthnow' && {
            marginLeft: 0,
            marginRight: 0,
        },
    }),
)

export interface StyledLinkProps {
    isLarge?: boolean
    hasBackground?: boolean
    cardOrientation?: CardOrientationOptions
    hasPadding?: boolean
    fullWidthOnMobile?: boolean
    isFunded?: boolean
    // If true, the typical boxShadow is removed from the cardHeadline and a underline effect is enforced instead (for cards with backgrounds on PN)
    enforceCustomActionStateTreatment?: boolean
}

export const StyledLink = styled(CardTextInteractions, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'isLarge',
        'hasBackground',
        'cardOrientation',
        'hasPadding',
        'fullWidthOnMobile',
        'isFunded',
    ]),
})<StyledLinkProps>(
    {
        textDecoration: 'none',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        textDecorationSkipInk: 'auto',
    },
    ({ isLarge, theme, hasPadding }) => ({
        padding: themedValue(theme, {
            thewest: undefined,
            sevennews: hasPadding
                ? calcRem(theme.cards.outerMarginLarge)
                : undefined,
            fallback: hasPadding ? calcRem(theme.cards.outerMargin) : undefined,
        }),
        paddingBottom: themedValue(theme, {
            perthnow: calcRem(theme.cards.outerMargin),
            thewest: calcRem(theme.cards.outerMargin),
            fallback: undefined,
        }),

        [breakpoint('sm')]: [
            {
                padding: themedValue(theme, {
                    thewest: hasPadding
                        ? calcRem(theme.cards.outerMargin)
                        : undefined,
                    perthnow: hasPadding
                        ? calcRem(theme.cards.outerMargin)
                        : undefined,
                    fallback: undefined,
                }),
            },
            isLarge &&
                hasPadding &&
                theme.kind === 'sevennews' && {
                    padding: calcRem(16),
                },
        ],
        [breakpointMax('xxs')]: {
            paddingBottom: theme.kind === 'thewest' ? calcRem(16) : undefined,
        },
    }),
    // Add padding in to the card so that the text doesn't hit up against the edge
    ({
        fullWidthOnMobile,
        hasPadding,
        cardOrientation,
        theme,
        isLarge,
        hasBackground,
        isFunded,
    }) => [
        fullWidthOnMobile &&
            !hasPadding && {
                [breakpointMax('sm')]: {
                    padding: themedValue(theme, {
                        sevennews: isLarge
                            ? calcRem(16)
                            : calcRem(theme.cards.outerMargin),
                        fallback: calcRem(theme.cards.outerMargin),
                    }),
                },
            },
        {
            // want to give the portrait card a bigger focus border when isLarge because of
            // having a black background on sevennews when hasBackground is set
            '&:focus': {
                outline:
                    isLarge && hasBackground && theme.kind === 'sevennews'
                        ? `solid 3px ${colors.sevennews.blueRibbon}`
                        : undefined,
            },

            [`& .${playButtonIconClass}`]: {
                opacity: isLarge ? 1 : 0,
            },

            [`&:hover .${playButtonIconClass}, &:focus .${playButtonIconClass}`]:
                {
                    opacity: 1,
                },
        },
        cardOrientation &&
            cardOrientation.type === 'Landscape-Portrait' && {
                [breakpointMax(cardOrientation.maxBreakpoint || 'sm')]: {
                    flexFlow: 'row wrap',
                    paddingBottom: isFunded ? theme.margins.sm : undefined,

                    [`& .${StyledBylineClassName}`]: {
                        display: isFunded ? 'block' : 'none',
                    },
                },
            },
    ],
)
StyledLink.displayName = 'StyledLink'

interface StyledCardMediaProps {
    isLarge?: boolean
    hasBackground?: boolean
    cardOrientation?: CardOrientationOptions
    mediaMode?: MediaMode
    fullWidthOnMobile?: boolean
}

const mobileImageWidth = 110
const tabletImageWidth = 210

export const StyledCardMedia = styled(CardMedia)<StyledCardMediaProps>(
    ({ theme, cardOrientation }) => ({
        // Different image widths based on the Breakpoint for the card
        ...(cardOrientation && cardOrientation.type === 'Landscape-Portrait'
            ? {
                  [breakpointMax('sm')]: {
                      width: mobileImageWidth,
                      alignSelf: 'flex-start', //ensures image container does not stretch to height of text column breaking interaction states
                      margin: calcRem(0, theme.margins.sm, 0, 0),
                  },
              }
            : {}),

        ...(cardOrientation &&
        cardOrientation.type === 'Landscape-Portrait' &&
        cardOrientation.maxBreakpoint === 'md'
            ? {
                  [breakpointBetween('sm', 'md')]: {
                      width: tabletImageWidth,
                      marginRight: calcRem(theme.margins.md),
                  },
              }
            : {}),
    }),
    ({ mediaMode, theme }) =>
        mediaMode === 'edgeToEdge' &&
        theme.kind === 'sevennews' && {
            [breakpoint('sm')]: {
                width: `calc(100% + ${
                    metrics.sevennews.cards.outerMarginLarge * 2
                }px)`,
                margin: calcRem(
                    -metrics.sevennews.cards.outerMarginLarge,
                    -metrics.sevennews.cards.outerMarginLarge,
                    metrics.sevennews.margins.sm,
                    -metrics.sevennews.cards.outerMarginLarge,
                ),
            },
        },

    // Some of the cards on SevenNews need to be full width on Mobile but also wont have padding on tablet or desktop
    // This will ensure those cards still render correctly
    ({ fullWidthOnMobile, theme }) =>
        fullWidthOnMobile &&
        theme.kind === 'sevennews' && {
            [breakpointMax('sm')]: {
                width: `calc(100% + ${
                    metrics.sevennews.cards.outerMarginLarge * 2
                }px)`,
                margin: calcRem(
                    -metrics.sevennews.cards.outerMarginLarge,
                    -metrics.sevennews.cards.outerMarginLarge,
                    metrics.sevennews.margins.sm,
                    -metrics.sevennews.cards.outerMarginLarge,
                ),
            },
        },
)

interface StyledCardTextProps {
    cardOrientation?: CardOrientationOptions
    fullWidthOnMobile?: boolean
    teaserMode: TeaserMode
}

export const StyledCardText = styled(CardText)<StyledCardTextProps>(
    ({ theme, cardOrientation, fontScale }) => ({
        [`& ${StyledHeadlineText}`]: {
            [breakpointMax('sm')]: {
                fontSize: theme.kind === 'thewest' ? calcRem(14) : undefined, // Make all portrait card font sizes the same on smaller screens
            },
            [breakpointMax('xxs')]: {
                fontSize:
                    theme.kind === 'thewest'
                        ? calcRem(metrics.thewest.cards.headingFontSize)
                        : undefined,
            },
        },

        ...(cardOrientation && cardOrientation.type === 'Landscape-Portrait'
            ? {
                  [breakpointMax('xs')]: {
                      width: `calc(100% - (${
                          mobileImageWidth + theme.margins.md
                      }px))`,
                      fontSize:
                          theme.kind === 'thewest' ? calcRem(14) : undefined, // Make all portrait card font sizes the same on smaller screens

                      [`& .${StyledTeaser}`]: {
                          display: 'none',
                      },
                  },
              }
            : {}),

        ...(cardOrientation &&
        cardOrientation.type === 'Landscape-Portrait' &&
        cardOrientation.maxBreakpoint === 'md'
            ? {
                  // Stops breakpoints being overwritten
                  [breakpointBetween('sm', 'md')]: {
                      width: `calc(100% - (${
                          tabletImageWidth + theme.margins.md
                      }px))`,

                      [`& ${StyledHeadlineText}`]: {
                          fontSize:
                              fontScale && calcRem(Math.ceil(18 * fontScale)),
                      },
                  },
              }
            : {}),
    }),
)

export interface StyledCardBylineProps {
    cardOrientation?: CardOrientationOptions
    hasPadding?: boolean
    mediaMode?: MediaMode
    showFundingLabel?: boolean
}
export const StyledCardByline = styled(CardByline)<StyledCardBylineProps>(
    ({ cardOrientation, theme, hasPadding, mediaMode, showFundingLabel }) => ({
        width: showFundingLabel ? '100%' : undefined,

        ...(cardOrientation && cardOrientation.type === 'Landscape-Portrait'
            ? {
                  [breakpointMax('sm')]: {
                      marginLeft: themedValue(theme, {
                          thewest:
                              mediaMode === 'default'
                                  ? calcRem(mobileImageWidth + theme.margins.sm)
                                  : undefined,
                          fallback: undefined,
                      }),
                      marginTop: themedValue(theme, {
                          thewest: hasPadding ? calcRem(theme.margins.xs) : 0,
                          fallback: undefined,
                      }),
                      padding: themedValue(theme, {
                          thewest: 0,
                          fallback: undefined,
                      }),
                      // If byline contains a headshot, hide the headshot on mobile
                      [`& .${StyledHeadshotWrapperClassName}`]: {
                          display: 'none',
                      },
                  },
              }
            : {}),

        ...(cardOrientation &&
        cardOrientation.type === 'Landscape-Portrait' &&
        cardOrientation.maxBreakpoint === 'md'
            ? {
                  [breakpointBetween('sm', 'md')]: {
                      marginLeft: themedValue(theme, {
                          thewest: calcRem(tabletImageWidth + theme.margins.md),
                          fallback: undefined,
                      }),
                  },
              }
            : {}),
    }),
)

export const StyledFundingLabel = styled(FundingLabel)(({ theme }) => ({
    marginTop: theme.kind === 'perthnow' ? calcRem(18) : undefined,
}))
