import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling'

export const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: calcRem(24),

    padding: calcRem(32, 24),
    borderRadius: calcRem(8),

    background: `#FDFDFD`,

    outlineStyle: 'solid',
    outlineColor: '#DCDCDC',
    outlineWidth: calcRem(1),
    outlineOffset: calcRem(-1),
})
