import { tokens } from '@news-mono/design-tokens'
import React, { useContext } from 'react'
import { Party } from '../PollieRater/helpers'
import { AuthenticationProviderContext } from '../user-registration'
import {
    StyledCheckmark,
    StyledFilterOption,
    StyledFilterOptionInput,
    StyledPollieRaterHeader,
    StyledPollieRaterTextBlock,
    StyledTextContainer,
} from './PollieRaterFilter.styled'

interface FilterOptionProps {
    children: React.ReactNode
    name: string
    selected: string
    onChange: React.ChangeEventHandler
}

function FilterOption(props: FilterOptionProps) {
    const isSelected = props.selected === props.name
    return (
        <label>
            <StyledFilterOptionInput
                type="radio"
                value={props.name}
                checked={isSelected}
                onChange={props.onChange}
            />
            <StyledFilterOption selected={isSelected}>
                {isSelected && (
                    <StyledCheckmark
                        fill={tokens.thewest.colors.palette.white}
                    />
                )}
                {props.children}
            </StyledFilterOption>
        </label>
    )
}

export interface PollieRaterFilterInterface {
    parties: Array<Party>
    selectedParty: string
    setSelected: React.Dispatch<React.SetStateAction<string>>
}

export const PollieRaterFilter: React.FC<PollieRaterFilterInterface> = ({
    selectedParty,
    setSelected,
    parties,
}) => {
    const authContext = useContext(AuthenticationProviderContext)

    const handleChange = (event: any) => {
        setSelected(event.target.value)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        authContext?.onRegisterClick()
    }

    return (
        <StyledPollieRaterHeader>
            <StyledPollieRaterTextBlock>
                <h3>Select a party</h3>
                <StyledTextContainer>
                    <span>
                        Select a party below and rate the politicians, then see
                        what other West Aussies think of them!
                    </span>
                    <span>
                        {''} You’ll need to register for a {''}
                        <a onClick={(e) => handleClick(e)} href="">
                            free account
                        </a>{' '}
                        to submit your rating.
                    </span>
                </StyledTextContainer>
            </StyledPollieRaterTextBlock>
            <div>
                {parties.map((party) => {
                    return (
                        <FilterOption
                            key={party.id}
                            selected={selectedParty}
                            onChange={handleChange}
                            name={party.name}
                        >
                            {party.name}
                        </FilterOption>
                    )
                })}
            </div>
            <output></output>
        </StyledPollieRaterHeader>
    )
}
