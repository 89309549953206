import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../../../feature-toggling/Toggle/Toggle'
import { createRenderTarget } from '../../../../render-target'
import { SportBreachCallToActionProps } from './SportBreachCallToAction'

const LazySportBreachCallToActionComponent = loadable(
    () => import('./SportBreachCallToAction'),
)

const SportBreachCallToActionRender = createRenderTarget(
    'SportBreachCallToAction',
    {
        web: LazySportBreachCallToActionComponent,
        amp: null,
        rss: null,
        preview: LazySportBreachCallToActionComponent,
        app: LazySportBreachCallToActionComponent,
    },
)

export const LazySportBreachCallToAction: React.FC<SportBreachCallToActionProps> =
    (props) => {
        return (
            <FeatureToggle
                feature="sport-breach-screen-variant"
                on={() => <SportBreachCallToActionRender {...props} />}
            />
        )
    }
