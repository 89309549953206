import { combineReducers, Reducer, ReducersMapObject } from 'redux'
import { withReduxStateSync } from 'redux-state-sync'
import { miscellaneousReducer, SwGReducer } from '../../data'
import { authenticationReducer } from '../../data/authentication/reducer'
import { consentReducer } from '../../data/consent/consent.redux'
import { locationReducer as geoLocation } from '../../data/location-detection/reducer'
import { navigationReducer as navigation } from '../../data/navigation/navigation-open'
import { renditionTypeReducer } from '../../data/render/render'
import { theWestLiveReducer } from '../../data/west-live/reducer'
import toggles from '../../feature-togglings/reducer'
import { metaReducer } from '../../meta/meta.redux'
import { AppState } from '../../store'

export function createRootReducer(): Reducer<AppState> {
    const reducers: ReducersMapObject<AppState> = {
        toggles,
        geoLocation,
        navigation,
        authentication: authenticationReducer,
        meta: metaReducer,
        consent: consentReducer,
        theWestLive: theWestLiveReducer,
        render: renditionTypeReducer,
        SwG: SwGReducer,
        misc: miscellaneousReducer,
    }

    return withReduxStateSync(combineReducers<AppState>(reducers)) as any
}
