import { MediaQueryMap, SlotMap } from '@news-mono/web-common'

/**
 * SWM-5394
 * Bonzai skins have started to hang around intermittently when resizing the viewport (02/06/18)
 * This function finds the injected Bonzai iframes and removes them. This kills the ad.
 */
export function cleanupBonzaiSkins(
    displayedSlots: SlotMap,
    mediaQueries: MediaQueryMap,
) {
    const outOfPageSlotIds = Object.keys(displayedSlots).filter((slotId) => {
        const slot = displayedSlots[slotId]
        return slot.definition.outOfPage
    })

    for (const slotId of outOfPageSlotIds) {
        for (const width of Object.keys(mediaQueries)) {
            const mq = mediaQueries[Number(width)]
            const matches = mq.slotIds.filter((id) => id === slotId)
            if (!matches.length) {
                continue
            }

            if (mq.mq.matches) {
                continue
            }

            // destroy skins if they are not within the breakpoint range
            const sideSkins = document.querySelectorAll(
                'iframe[pagetype=leftsideskin], iframe[pagetype=rightsideskin]',
            )
            for (const element of Array.from(
                sideSkins as any,
            ) as HTMLIFrameElement[]) {
                // IE doesn't have Element.remove()
                element.parentNode!.removeChild(element)
            }
        }
    }
}
