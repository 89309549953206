import styled from '@emotion/styled'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { CardMedia } from '../../cards/CardMedia/CardMedia'
import { styledMediaClassName } from '../../cards/CardMedia/StyledMedia.styled'
import { withClass } from '@news-mono/web-common'
import { StyledKicker } from '../../cards/Kicker/Kicker.styled'

const HeadlineClassName = 'OlympicsFeatureArticleHeadline'

/** Extra styling for the 'overall' component wrapper for the article link */
export const StyledLink = styled(CardTextInteractions)(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        textDecoration: 'none',
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',

        // Style the hover & focus of the card
        ['&:is(:hover, :focus)']: {
            // Style the image hovering
            [`& .${styledMediaClassName}`]: {
                '&::after': {
                    borderColor: colors.olympics.orange,
                },
            },
            [`& .${HeadlineClassName}`]: {
                color: colors.olympics.orange,
            },
            [`& ${StyledKicker} svg`]: {
                fill: colors.olympics.orange,
            },
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Make sure the ::after is styled to 50% opacity
        [`& .${styledMediaClassName}`]: {
            '&::after': {
                opacity: '50%',
            },
        },
    },

    // THE WEST STYLING
    theme.kind === 'thewest' && {
        // Style the kicker inside the links
        span: {
            color: colors.olympics.kicker.thewest,
            textDecoration: 'none !important',
        },
    },
])
StyledLink.displayName = 'StyledLink'

/** Highest level feature article div, used as a 'parent' to store widths, heights, and backgrounds */
export const StyledOlympicsFeatureArticle = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'flex',

        // Mobile styling
        width: '100%',
        height: 'auto',
        borderRight: 'none',
        paddingRight: 0,

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '75%',
            borderRight: `${calcRem(0.5)} solid ${colors.olympics.border}`,
            paddingRight: calcRem(8),
        },
        // Laptop or bigger styling
        [breakpoint('md')]: {
            height: calcRem(282),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        backgroundColor: colors.olympics.background,

        // Mobile styling
        height: 'fit-content',
        maxHeight: 'none',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '50%',
            borderRight: 'none',
            paddingRight: 0,
        },
        // Laptop or bigger styling
        [breakpoint('md')]: {
            height: calcRem(420),
            maxHeight: calcRem(420),
        },
    },
])

/** Used as an internal container, underneath the Parent -> StyledLink, used to sort the actual content */
export const StyledOlympicsFeatureArticleInner = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'flex',
        width: '100%',

        borderBottom: `${calcRem(1)} solid ${colors.olympics.border}`,

        // Mobile styling
        flexDirection: 'column',
        paddingBottom: calcRem(12),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            paddingBottom: 0,
            flexDirection: 'row',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        borderBottom: 'none',
        gap: calcRem(8),

        // Mobile styling
        padding: calcRem(12, 12, 0, 12),
        flexDirection: 'row-reverse',
        height: 'fit-content',

        // Large mobile styling
        [breakpoint('xxs')]: {
            padding: calcRem(12),
            height: '100%',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
            gap: 0,
            padding: 0,
            flexDirection: 'column-reverse',
        },
    },
])

/** Used to as a wrapper container for all the text aspects of the article, to maintain appropriate content split width rules. */
export const StyledOlympicsFeatureArticleText = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'inline-flex',
        flexDirection: 'column',

        // Mobile styling
        width: '100%',
        padding: calcRem(0, 0, 0, 0),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '45%',

            padding: calcRem(28, 16, 0, 0),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        padding: calcRem(0),
        height: 'fit-content',

        // Large mobile styling
        [breakpoint('xxs')]: {
            height: '100%',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '100%',
            height: 'fit-content',

            padding: calcRem(16, 8),
        },
    },
])

/** Styling for the article kicker, this is mainly just the parent topic */
export const StyledOlympicsArticleKicker = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',

        height: 'fit-content',
        width: '100%',
        alignSelf: 'stretch',

        '&:focus-within': {
            color: colors.olympics.orange,
            textDecoration: 'none',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        width: 'auto',
        marginRight: 'auto',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '100%',
        },
    },
])

/** Styling for the article title, this uses the `article.shortHeadline` as the title, rather than the `article.headline`. */
export const StyledOlympicsFeatureArticleTitle = withClass(HeadlineClassName)(
    styled('h2')(({ theme }) => [
        // THE WEST & SHARED STYLING
        {
            fontFamily: theme.fonts.serif,
            fontSize: calcRem(28),
            fontWeight: 400,
            lineHeight: calcRem(30),
            color: colors.olympics.text,

            display: 'flex',
            paddingBottom: calcRem(8),
            margin: 0,
        },

        // PERTH NOW STYLING
        theme.kind === 'perthnow' && {
            fontFamily: theme.fonts.sansSerifCond,
            fontSize: calcRem(22),
            fontWeight: 700,
            lineHeight: calcRem(24),

            // Mobile styling
            marginRight: 'auto',

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                marginRight: 0,
            },
        },
    ]),
)
/** Styling for the article teaser, a short summary of what the article is about. PerthNow consists of two teasers in order
 *  to wrap nicely with different widths, that's why we have a `wrappedTeaser` parameter.
 */
export const StyledOlympicsFeatureArticleTeaser = styled('p')<{
    wrappedTeaser?: boolean
}>(({ theme, wrappedTeaser = true }) => [
    // THE WEST & SHARED STYLING
    {
        fontFamily: theme.fonts.serif,
        fontSize: calcRem(12),
        fontWeight: 400,
        lineHeight: calcRem(16),
        color: colors.olympics.text,

        display: 'flex',

        // Mobile styling
        marginBottom: calcRem(6),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            marginBottom: 'auto',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(14),

        // Mobile styling
        padding: calcRem(4, 12, 12, 12),
        marginBottom: 0,
        marginTop: 0,
        display: wrappedTeaser ? 'none' : 'flex',

        // Large mobile styling
        [breakpoint('xxs')]: {
            padding: calcRem(4, 0, 0, 0),
            marginBottom: 'auto',
            display: wrappedTeaser ? 'flex' : 'none',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            padding: 0,
            // display: wrappedTeaser ? 'none' : 'flex',
            marginBottom: 0,
        },
    },
])

/** Styling for the feature article image, needed to make sure it wraps appropriately. */
export const StyledOlympicsFeatureArticleImage = styled(CardMedia)(
    // THE WEST & SHARED STYLING
    ({ theme }) => [
        {
            margin: 0,

            // Mobile styling
            width: '100%',

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                width: '55%',
            },

            '& [class*="image"]': {
                aspectRatio: 'auto',

                '& img': {
                    width: 'auto',
                    height: '100%',
                },
            },
        },

        // PERTH NOW STYLING
        theme.kind === 'perthnow' && {
            // Mobile styling
            width: '40%',
            height: 'fit-content',

            // Large mobile styling
            [breakpoint('xxs')]: {
                height: '100%',
                marginBottom: 'auto',
            },

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                height: 'fit-content',
                marginBottom: 0,
                width: '100%',
            },
        },
    ],
)
