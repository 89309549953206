import { cx } from '@emotion/css'
import React from 'react'

export interface IconChevronLeftDoubleTNProps {
    className?: string
    strokeColor?: string
}

export const IconChevronLeftDoubleTN: React.FC<IconChevronLeftDoubleTNProps> =
    ({ className, strokeColor = '#6F6F6F' }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={cx(className, 'icon-chevron-left-double-tn')}
        >
            <path
                d="M18 17L13 12L18 7M11 17L6 12L11 7"
                stroke={strokeColor}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
IconChevronLeftDoubleTN.displayName = 'IconChevronLeftDoubleTN'
