import styled from '@emotion/styled'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface GridProps {
    initialColumns: number
    intermediateColumns: number
    finalColumns: number
    verticalSpacing: keyof ThemeMargins | undefined
}

export const StyledWhiskey = styled('div')<GridProps>(
    ({
        verticalSpacing,
        initialColumns,
        intermediateColumns,
        finalColumns,
        theme,
    }) => ({
        margin: '0 auto',
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        display: 'grid',
        gridTemplateColumns: `repeat(${initialColumns}, 1fr)`,
        gridGap: calcRem(theme.margins.gridGap),

        [breakpoint('sm')]: {
            gridTemplateColumns: `repeat(${intermediateColumns}, 1fr)`,
        },

        [breakpoint('md')]: {
            gridTemplateColumns: `repeat(${finalColumns}, 1fr)`,
        },
    }),
)
StyledWhiskey.displayName = 'StyledWhiskey'

export const StyledGridItem = styled('div')({
    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column',
})
