import { Action } from 'redux'
import { CartEvent } from '../../events'
import { SswPackage } from '../ssw'

export interface SubscriptionPromptCookieData {
    href: string
    time: number
    package_info?: {
        item_id?: string
        item_name?: string
        item_brand?: string
        is_offer?: boolean
        price?: number
        promo_price?: number
        discount?: number
        coupon?: string
        quantity?: number
        package: SswPackage
        cart_payload: CartEvent['payload']
    }
}

// State object used to keep track of miscellaneous information
export interface MiscellaneousState {
    baySeed?: string // Used as the seed value for the randomisation function inside the best-austarlian-yarn initiative
    dismissedSubscriptionPrompt?: SubscriptionPromptCookieData
}

const SET_BAYSEED_VAL = 'SET_BAYSEED_VAL'
const SET_DISMISSED_SUBSCRIPTION_PROMPT =
    '@@MISC/SET_DISMISSED_SUBSCRIPTION_PROMPT'

export interface SET_BAYSEED_VAL_ACTION extends Action {
    type: typeof SET_BAYSEED_VAL
    payload: { seedVal: string }
}

export interface SET_DISMISSED_SUBSCRIPTION_PROMPT_ACTION extends Action {
    type: typeof SET_DISMISSED_SUBSCRIPTION_PROMPT
    payload: { dismissedSubscriptionPrompt: SubscriptionPromptCookieData }
}

export const setBaySeedVal = (val: string) => ({
    type: SET_BAYSEED_VAL,
    payload: {
        seedVal: val,
    },
})

export const setDismissedSubscriptionPrompt = (
    val: SubscriptionPromptCookieData,
) => {
    global?.localStorage?.setItem(
        'dismissed-subscription-prompt',
        JSON.stringify(val),
    )
    return {
        type: SET_DISMISSED_SUBSCRIPTION_PROMPT,
        payload: { dismissedSubscriptionPrompt: val },
    }
}

export const miscellaneousReducer = (
    state: MiscellaneousState = {
        baySeed: undefined,
        dismissedSubscriptionPrompt: getDefaultDismissedSubscriptionPrompt(),
    },
    action:
        | SET_BAYSEED_VAL_ACTION
        | SET_DISMISSED_SUBSCRIPTION_PROMPT_ACTION
        | { type: '@@INIT' },
) => {
    switch (action.type) {
        case SET_BAYSEED_VAL:
            return { ...state, baySeed: action.payload.seedVal }
        case SET_DISMISSED_SUBSCRIPTION_PROMPT: {
            if (action.payload.dismissedSubscriptionPrompt) {
                return {
                    ...state,
                    dismissedSubscriptionPrompt:
                        action.payload.dismissedSubscriptionPrompt ||
                        state.dismissedSubscriptionPrompt,
                }
            }
            return state
        }
        default:
            return {
                ...state,
                dismissedSubscriptionPrompt:
                    state.dismissedSubscriptionPrompt ||
                    getDefaultDismissedSubscriptionPrompt(),
            }
    }
}

function getDefaultDismissedSubscriptionPrompt(): SubscriptionPromptCookieData {
    return JSON.parse(
        global?.localStorage?.getItem('dismissed-subscription-prompt') ||
            'null',
    )
}
