import styled from '@emotion/styled'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const margin = metrics.thewest.margins
export const StyledAFLDraftHeader = styled('div')({
    padding: calcRem(margin.md, margin.md, margin.sm),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',

    [breakpoint('sm')]: {
        padding: calcRem(margin.sm),
    },
})

export const StyledAFLDraftTextWrapper = styled('div')({
    paddingBottom: calcRem(margin.md),
    lineHeight: 1.2,
    fontFamily: fonts.thewest.serif,
    fontSize: calcRem(19),

    [breakpoint('sm')]: {
        fontSize: calcRem(17),
    },

    [breakpoint('md')]: {
        fontSize: calcRem(15),
    },
})

export const StyledAFLDraftText = styled('p')({
    margin: '0 0 16px 0',
})

export const StyledAFLDraftHeading = styled('h2')({
    margin: `0 0 ${calcRem(6)} 0`,
})
