import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import CourtInTheActPage from './CourtInTheActPage'

export const CourtInTheActPageRegistration = createRegisterableComponent(
    'court-in-the-act-page',
    (props, services) => {
        const shareUrl = services.config.publicUrl + services.location.pathname
        return (
            <CourtInTheActPage shareUrl={shareUrl} onEvent={services.onEvent} />
        )
    },
)
