import { cx } from '@emotion/css'
import React from 'react'

export interface IconChevronDownProps {
    title?: string
    className?: string
}

export const IconChevronDown: React.FC<IconChevronDownProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 15"
        className={cx(className, 'icon-chevron-down')}
    >
        <title>{title || 'Chevron Down Icon'}</title>
        <path d="M9.8 9.231L16.63.954a.39.39 0 0 1 .565-.046l2.066 1.822a.416.416 0 0 1 .043.583l-9.202 10.942a.394.394 0 0 1-.605 0L.3 3.313A.416.416 0 0 1 .34 2.73L2.407.907a.39.39 0 0 1 .565.046L9.8 9.231z" />
    </svg>
)
IconChevronDown.displayName = 'IconChevronDown'
