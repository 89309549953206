import {
    BackgroundRepeatProperty,
    FlexDirectionProperty,
    FlexWrapProperty,
    PointerEventsProperty,
    PositionProperty,
    TextAlignProperty,
    TextTransformProperty,
} from 'csstype'
import {
    BreakpointState,
    calcRem,
    PerthNowTheme,
    PerthNowThemeConfig,
    textCrop,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
} from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { tokens } from '@news-mono/design-tokens'
import { CSSObject } from '@emotion/css'
import { transition } from '../../__styling/style-mixins'

const { neutral, utility } = tokens.thenightly.colors.palette

const westBgImage: string = require('./images/TW_Newsletter-image2.jpg')
const pnBgImage: string = require('./images/PN_Newsletter-662-V2.jpg')
const westAngleDistance = 11
const westAngleDegrees = '134deg'
const pnAngleDegrees = '124deg'

export type NewsletterTheme<T extends ThemeConfig> = {
    position?: PositionProperty
    backgroundPosition?: string
    backgroundRepeat?: BackgroundRepeatProperty
    width?: string

    breakpointState?: BreakpointState<
        T,
        {
            height?: string
            padding?: string
            backgroundColor?: string
            backgroundImage?: string | undefined
        }
    >

    icon?: {
        breakpointState: BreakpointState<
            T,
            {
                display: string
                position?: PositionProperty
                top?: string
                left?: string
            }
        >
    }
    contentContainer: {
        height: string
        marginLeft?: string
        position?: PositionProperty
        display: string
        justifyContent?: string

        breakpointState: BreakpointState<
            T,
            {
                backgroundImage?: string
                width?: string
            }
        >
    }
    formContainer: {
        width: BreakpointState<T, string | undefined>
        maxWidth?: string
        transition?: CSSObject

        breakpointState: BreakpointState<
            T,
            {
                padding?: string
                marginLeft?: string
                display?: string
                flexWrap?: FlexWrapProperty
                textAlign?: TextAlignProperty
                gap?: string
            }
        >
        journoProfile?: {
            padding: BreakpointState<T, string>
            width: string
        }
        inArticle?: {
            padding: BreakpointState<T, string>
        }
        inlineArticle?: {
            display: BreakpointState<T, string>
        }
        sidebarArticle?: {
            display: BreakpointState<T, string>
        }
    }
    text: {
        color: string
        fontWeight: number
        fontFamily: string
        margin?: string
        textAlign?: TextAlignProperty

        breakpointState: BreakpointState<
            T,
            {
                marginLeft?: string
                marginBottom?: string
                maxWidth?: string
                lineHeight?: string
                fontSize?: string
            }
        >
    }
    subText?: {
        color: string
        fontFamily: string
        margin: string
        textAlign: TextAlignProperty
        fontWeight: number
        lineHeight: string
        fontSize: string
    }
    form: {
        display?: string
        width?: string
        height?: string
        gap?: string

        breakpointState: BreakpointState<
            T,
            {
                marginLeft?: string
                display?: string
                flexDirection?: FlexDirectionProperty
            }
        >
        sidebarArticle?: {
            flexDirection?: FlexDirectionProperty
        }
    }
    inputWrapper?: {
        padding: string
        backgroundColor?: string
        border: string
        borderRadius: string
        fontSize: string
        display: string
        alignItems: string
        gap: string
        color: string
        width: BreakpointState<T, string>
        transition?: CSSObject

        inArticle: {
            width: string
        }
        focus?: {
            outline: string
        }
    }
    input: {
        marginRight: string
        padding: string
        height: string
        border: string
        borderRadius: string
        fontSize: string
        backgroundColor?: string
        lineHeight?: string
        color?: string

        focus?: {
            outline: string
        }
        breakpointState: BreakpointState<
            T,
            {
                marginLeft?: string
                width?: string
            }
        >
    }
    button: {
        margin: string
        padding: string
        height?: string
        border: string
        borderRadius?: string
        maxWidth?: string
        color: string
        backgroundColor?: string
        textCrop?: CSSObject
        width?: BreakpointState<T, string>
        lineHeight?: string
        fontSize: BreakpointState<T, string>
        fontWeight?: number
        textTransform: TextTransformProperty
        flex: string
        fontFamily?: string
        transition: CSSObject

        hover: {
            backgroundColor?: string
            color?: string
            textDecoration?: string
            border?: string
            opacity?: number
        }
        focus: {
            backgroundColor?: string
            color?: string
            textDecoration?: string
            border?: string
            opacity?: number
        }
        disabled: {
            pointerEvents: PointerEventsProperty
            cursor: string
            opacity: number
        }

        sidebarArticle?: {
            width: string
        }
    }
    termsText?: {
        color: string
        fontSize: string
        fontWeight: number
        lineHeight: string
        width: string
        textAlign: TextAlignProperty

        a: {
            textDecoration: string
            color: string
        }
    }
}

export const theWestNewsletterTheme = (scheme: TheWestTheme) => {
    const theme: NewsletterTheme<TheWestThemeConfig> = {
        position: 'relative',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',

        breakpointState: {
            default: {
                padding: calcRem(12),
                backgroundColor: scheme.sectionValues.primaryColor,
            },
            xxs: {
                height: calcRem(126),
                padding: '0',
                backgroundImage: `url(${westBgImage})`,
                backgroundColor: 'transparent',
            },
            xs: {
                height: calcRem(108),
            },
        },
        icon: {
            breakpointState: {
                default: {
                    display: 'none',
                },
                xxs: {
                    display: 'block',
                    position: 'absolute',
                    top: calcRem(12),
                    left: calcRem(12),
                },
            },
        },
        contentContainer: {
            height: '100%',
            marginLeft: 'auto',
            position: 'relative',
            display: 'flex',

            breakpointState: {
                default: {
                    backgroundImage: `linear-gradient(${westAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) ${westAngleDistance}%, ${scheme.sectionValues.primaryColor} ${westAngleDistance}%)`,
                },
                xxs: {
                    backgroundImage: `linear-gradient(${westAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) 28%, ${scheme.sectionValues.primaryColor} 28%)`,
                    width: calcRem(318),
                },
                xs: {
                    width: '70%',
                },
                md: {
                    backgroundImage: `linear-gradient(${westAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) 22%, ${scheme.sectionValues.primaryColor} 22%)`,
                },
            },
        },
        formContainer: {
            width: {
                default: undefined,
                xxs: '80%',
            },
            breakpointState: {
                default: {},
                xxs: {
                    padding: calcRem(8, 12, 12),
                    marginLeft: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                },
            },
        },
        text: {
            color: colors.white,
            fontWeight: 700,
            fontFamily: scheme.fonts.heading,
            margin: '0',

            breakpointState: {
                default: {
                    lineHeight: calcRem(21),
                    fontSize: calcRem(18),
                    marginBottom: calcRem(10),
                },
                xxs: {
                    marginLeft: 'auto',
                    maxWidth: calcRem(200),
                },
                xs: {
                    lineHeight: calcRem(24),
                    maxWidth: calcRem(280),
                    marginBottom: calcRem(6),
                },
            },
        },
        form: {
            display: 'flex',
            breakpointState: {
                default: {
                    marginLeft: undefined,
                },
                xs: {
                    marginLeft: 'auto',
                },
            },
        },
        input: {
            marginRight: calcRem(10),
            padding: calcRem(0, 6),
            height: calcRem(33),
            border: `1px solid ${colors.white}`,
            borderRadius: '0',
            fontSize: calcRem(14),

            breakpointState: {
                default: {
                    width: '100%',
                },
                xxs: {
                    width: calcRem(150),
                },
                xs: {
                    width: calcRem(190),
                    marginLeft: 'auto',
                },
            },
        },
        button: {
            margin: '0',
            padding: calcRem(9),
            height: calcRem(33),
            border: `1px solid ${colors.white}`,
            backgroundColor: scheme.sectionValues.primaryColor,
            fontFamily: scheme.fonts.sansSerif,
            color: colors.white,
            maxWidth: calcRem(80),
            borderRadius: calcRem(2),
            textCrop: textCrop({
                fontSize: 14,
                desiredLineHeight: 1,
                cropLineHeight: 1.1,
                topAdjustment: 9,
                bottomAdjustment: 8,
            }),
            fontSize: {
                default: calcRem(11),
                xxs: calcRem(14),
            },
            textTransform: 'uppercase',
            flex: '0 0 auto',
            fontWeight: 700,
            transition: transition({ properties: ['background', 'color'] }),

            hover: {
                backgroundColor: colors.white,
                color: scheme.sectionValues.primaryColor,
            },
            focus: {
                backgroundColor: colors.white,
                color: colors.darkGrey,
                textDecoration: 'underline',
            },
            disabled: {
                pointerEvents: 'none',
                cursor: 'not-allowed',
                opacity: 0.5,
            },
        },
    }
    return theme
}
export const perthNowNewsletterTheme = (scheme: PerthNowTheme) => {
    const theme: NewsletterTheme<PerthNowThemeConfig> = {
        position: 'relative',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',

        breakpointState: {
            default: {
                padding: calcRem(12),
                backgroundColor: colors.perthnow.blueSapphire,
            },
            xxs: {
                height: calcRem(126),
                padding: '0',
                backgroundImage: `url(${pnBgImage})`,
                backgroundColor: 'transparent',
            },
            xs: {
                height: calcRem(108),
            },
        },
        icon: {
            breakpointState: {
                default: {
                    display: 'none',
                },
                xxs: {
                    display: 'block',
                    position: 'absolute',
                    top: calcRem(12),
                    left: calcRem(12),
                },
            },
        },
        contentContainer: {
            height: '100%',
            marginLeft: 'auto',
            position: 'relative',
            display: 'flex',

            breakpointState: {
                default: {
                    backgroundImage: `linear-gradient(${pnAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) 22%, ${colors.perthnow.blueSapphire} 22%)`,
                },
                xxs: {
                    backgroundImage: `linear-gradient(${pnAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) 28%, ${colors.perthnow.blueSapphire} 28%)`,
                    width: calcRem(340),
                },
                xs: {
                    width: '70%',
                },
                md: {
                    backgroundImage: `linear-gradient(${pnAngleDegrees}, rgba(2,0,36,0) 0%, rgba(4,61,226,0) 22%, ${colors.perthnow.blueSapphire} 22%)`,
                },
            },
        },
        formContainer: {
            width: {
                default: undefined,
                xxs: '80%',
            },
            breakpointState: {
                default: {},
                xxs: {
                    padding: calcRem(8, 12, 12),
                    marginLeft: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                },
            },
        },
        text: {
            color: colors.white,
            fontWeight: 700,
            fontFamily: scheme.fonts.sansSerif,
            margin: '0',

            breakpointState: {
                default: {
                    lineHeight: calcRem(21),
                    fontSize: calcRem(18),
                    marginBottom: calcRem(10),
                },
                xxs: {
                    marginLeft: 'auto',
                    maxWidth: calcRem(200),
                },
                xs: {
                    lineHeight: calcRem(24),
                    maxWidth: calcRem(280),
                    marginBottom: calcRem(6),
                    fontSize: calcRem(20),
                },
            },
        },
        form: {
            display: 'flex',

            breakpointState: {
                default: {
                    marginLeft: undefined,
                },
                xs: {
                    marginLeft: 'auto',
                },
            },
        },
        input: {
            marginRight: calcRem(10),
            padding: calcRem(0, 6),
            height: calcRem(33),
            border: `1px solid ${colors.white}`,
            borderRadius: '0',
            fontSize: calcRem(14),

            breakpointState: {
                default: {
                    width: '100%',
                },
                xxs: {
                    width: calcRem(150),
                },
                xs: {
                    width: calcRem(190),
                    marginLeft: 'auto',
                },
            },
        },
        button: {
            margin: '0',
            padding: calcRem(9, 0),
            height: calcRem(33),
            border: `1px solid ${colors.white}`,
            color: colors.white,
            maxWidth: calcRem(80),
            width: {
                default: calcRem(130),
            },
            textCrop: textCrop({
                fontSize: 14,
                desiredLineHeight: 1,
                cropLineHeight: 1.1,
                topAdjustment: 9,
                bottomAdjustment: 8,
            }),
            fontSize: {
                default: calcRem(14),
            },
            fontWeight: 700,
            textTransform: 'uppercase',
            flex: '0 0 auto',
            fontFamily: scheme.fonts.heading,
            backgroundColor: 'transparent',
            transition: transition({
                properties: ['background', 'color', 'border'],
            }),

            hover: {
                backgroundColor: colors.white,
                color: colors.perthnow.blueSapphire,
                border: `1px solid ${colors.white}`,
            },
            focus: {
                backgroundColor: colors.white,
                color: colors.darkGrey,
                textDecoration: 'underline',
                border: `1px solid ${colors.white}`,
            },
            disabled: {
                pointerEvents: 'none',
                cursor: 'not-allowed',
                opacity: 0.5,
            },
        },
    }
    return theme
}

export const theNightlyNewsletterTheme = (scheme: TheNightlyTheme) => {
    const theme: NewsletterTheme<TheNightlyThemeConfig> = {
        width: '100%',

        contentContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',

            breakpointState: {
                default: {
                    width: '100%',
                },
            },
        },
        formContainer: {
            transition: transition({
                properties: ['width', 'padding'],
            }),
            width: {
                default: '100%',
            },
            maxWidth: calcRem(770),
            breakpointState: {
                default: {
                    padding: calcRem(40, 24),
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    gap: calcRem(16),
                },
                sm: {
                    padding: calcRem(64, 24),
                },
                lg: {
                    padding: calcRem(80, 24),
                },
            },
            journoProfile: {
                width: '100%',
                padding: {
                    default: calcRem(40, 24),
                    sm: calcRem(48, 24),
                },
            },
            inArticle: {
                padding: {
                    default: calcRem(40, 24),
                    sm: calcRem(32, 24),
                },
            },
            inlineArticle: {
                display: {
                    lg: 'none',
                },
            },
            sidebarArticle: {
                display: {
                    default: 'none',
                    lg: 'flex',
                },
            },
        },
        text: {
            color: neutral[0],
            fontWeight: 400,
            fontFamily: scheme.fonts.sansSerif,
            margin: '0',
            textAlign: 'left',

            breakpointState: {
                default: {
                    lineHeight: '1.25',
                    fontSize: calcRem(32),
                    marginBottom: calcRem(0),
                },
                sm: {
                    lineHeight: '1.1112',
                    fontSize: calcRem(36),
                },
                lg: {
                    lineHeight: '1.3',
                    fontSize: calcRem(40),
                },
            },
        },
        subText: {
            color: neutral[10],
            fontFamily: scheme.fonts.body,
            margin: '0',
            textAlign: 'left',
            fontWeight: 400,
            lineHeight: '1.5',
            fontSize: calcRem(20),
        },
        form: {
            display: 'flex',
            width: '100%',
            gap: calcRem(16),

            breakpointState: {
                default: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                xs: {
                    flexDirection: 'row',
                },
            },
            sidebarArticle: {
                flexDirection: 'column',
            },
        },
        inputWrapper: {
            padding: calcRem(8, 16),
            backgroundColor: neutral[10],
            border: `1px solid ${neutral[20]}`,
            borderRadius: calcRem(8),
            fontSize: calcRem(14),
            display: 'flex',
            alignItems: 'center',
            gap: calcRem(16),
            color: neutral[70],

            width: {
                default: '100%',
                lg: '75%',
            },
            focus: {
                outline: `2px solid ${neutral[30]}`,
            },

            inArticle: {
                width: '100%',
            },
        },
        input: {
            marginRight: '0',
            padding: calcRem(4, 0),
            height: '100%',
            border: 'none',
            borderRadius: '0',
            fontSize: calcRem(16),
            backgroundColor: 'inherit',
            lineHeight: calcRem(24),
            color: neutral[70],

            focus: {
                outline: 'none',
            },

            breakpointState: {
                default: {
                    width: '100%',
                },
            },
        },
        button: {
            margin: '0',
            padding: calcRem(12, 24),
            borderRadius: calcRem(8),
            backgroundColor: utility.error.default,
            border: 'none',
            color: colors.white,
            lineHeight: calcRem(24),
            fontSize: {
                default: calcRem(16),
            },
            fontWeight: 600,
            textTransform: 'uppercase',
            flex: '0 0 auto',

            transition: transition({
                properties: ['width', 'opacity'],
            }),

            hover: {
                opacity: 0.8,
            },
            focus: {
                opacity: 0.8,
            },
            disabled: {
                pointerEvents: 'none',
                cursor: 'not-allowed',
                opacity: 0.5,
            },

            width: {
                default: '100%',
                xs: 'fit-content',
            },

            sidebarArticle: {
                width: '100%',
            },
        },
        termsText: {
            color: neutral[0],
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            width: '100%',
            textAlign: 'center',

            a: {
                textDecoration: 'underline',
                color: 'unset',
            },
        },

        breakpointState: {
            default: {
                backgroundColor: neutral[100],
            },
        },
    }
    return theme
}
