import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

interface StyledActiveProps {
    active?: boolean
}

const StyledToggleSwitchClassName = 'StyledToggleSwitch'

export const StyledToggleCheckbox = styled('input')(visuallyhidden)
export const StyledToggleLabel = styled('label')(visuallyhidden)

export const StyledToggle = styled('div')({
    margin: calcRem(10, 0, 20),
    height: calcRem(30),
})

export const StyledToggleButton = styled('button')({
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    padding: 0,
})

export const StyledToggleSwitch = withClass(StyledToggleSwitchClassName)(
    styled('div')({
        position: 'absolute',
        top: 0,
        margin: calcRem(3),
        width: calcRem(24),
        height: calcRem(24),
        borderRadius: calcRem(26),
        background: colors.white,
        transition: `left ${easing.easeOut.fast}, transform ${easing.easeOut.fast}, margin ${easing.easeOut.fast}`,
    }),
)

export const StyledToggleBackground = styled('div')<StyledActiveProps>(
    {
        position: 'relative',
        overflow: 'hidden',
        width: calcRem(55),
        height: calcRem(30),
        borderRadius: calcRem(50),
        label: 'StyledToggleBackground',
        transition: `background-color ${easing.easeOut.fast}`,
    },
    (props) => ({
        background: themedValue(props.theme, {
            thewest: (section) =>
                props.active
                    ? section.primaryColor
                    : colors.thewest.greyDolphin,
            sevennews: props.active
                ? colors.sevennews.red
                : colors.thewest.greyDolphin,
            perthnow: props.active
                ? colors.perthnow.pinkThulite
                : colors.thewest.greyDolphin,
            fallback: props.active
                ? colors.thewest.westblue
                : colors.thewest.greyDolphin,
        }),

        [`.${StyledToggleSwitchClassName}`]: {
            transform: props.active ? 'translateX(100%)' : 'translateX(0)',
        },
    }),
)
