import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getThemedSegment } from '../../home/get-themed-segment'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getAFLRouteInfo: GetMainSectionFn = (sectionBuilder, store) => {
    const themeOverride = store?.getState().toggles[
        'the-west-grand-final-freo-collection-theme'
    ]
        ? 'grand-final-freo'
        : 'grand-final'

    return [
        getThemedSegment({
            featureToggle: 'the-west-grand-final-collection',
            layoutVersion: '8-card',
            curation: 'afl-grand-final',
            themeOverride,
            cardExtensionProps: {
                eightCardExtension: {
                    kind: '8-card',
                    thorProps: {
                        unsetFeatureContainerBackground: true,
                        hideHeadingLink: true,
                        layouts: '8-card',
                    },
                },
            },
        }),
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'iframe',
            props: {
                link: 'https://omny.fm/shows/the-game-afl-podcast-with-duff-and-quarters/playlists/podcast-1/embed?style=cover',
                height: 467,
                marginBottom: 'lg',
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'West Coast Eagles',
                    headingUrl: '/sport/west-coast-eagles',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/west-coast-eagles'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Fremantle Dockers',
                    headingUrl: '/sport/fremantle-dockers',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/fremantle-dockers'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'WAFL',
                    headingUrl: '/sport/wafl',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'wafl',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'AFLW',
                    headingUrl: '/sport/aflw',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'aflw',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                loadMoreEnabled: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Latest',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/afl'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 12,
                    },
                },
            },
        }),
    ]
}
