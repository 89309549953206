import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem, breakpointMax } from '../../../..'
import { TextLink } from '../../../../typography/TextLink/TextLink'
import { StyledButton } from '../../../../buttons/Button/Button.styled'
import { StyledSubscribeWithGoogleButton2022 } from '../SubscriptionPackage/SubscriptionPackage.styled'

export const PuzzlesBreachWrap = styled('div')(({ theme }) => ({
    width: '100%',
    '&>p': {
        paddingLeft: calcRem(18),
    },
}))

export const PuzzlesBreachCTAWrap = styled('div')<{
    bgImage?: string
    noBanner?: boolean
    enabledSwgCampaign?: boolean
}>(({ bgImage, noBanner, enabledSwgCampaign }) => [
    {
        position: 'relative',
        width: 'calc(100% - 24px)',
        margin: 'auto',
        marginTop: noBanner ? 0 : calcRem(40),
        padding: calcRem(30),
        borderRadius: calcRem(16),
        background: tokens.thewest.colors.palette.breachLightBlue,
        overflow: 'hidden',

        '&::after': {
            content: `url(${bgImage})`,
            position: 'absolute',
            right: '11%',
            top: calcRem(-125),
            width: calcRem(300),
            height: calcRem(300),

            [breakpointMax('lg')]: {
                right: calcRem(150),
                width: calcRem(200),
                height: calcRem(200),
            },
            [breakpointMax('md')]: {
                width: calcRem(60),
                height: calcRem(60),
            },
            [breakpointMax('xxs')]: {
                display: 'none',
            },
        },

        [breakpointMax('xxs')]: {
            width: '100%',
            padding: '20px',
        },
    },
    enabledSwgCampaign && {
        [breakpointMax('xxs')]: {
            marginTop: 0,
        },
    },
])

export const CTAHeading = styled('h2')(({ theme }) => ({
    fontSize: calcRem(36),
    fontWeight: 700,
    lineHeight: calcRem(36),
    fontFamily: theme.fonts.sansSerif,
    margin: `0 0 ${calcRem(16)}`,
}))

export const CTASubHeading = styled('p')(({ theme }) => ({
    fontSize: calcRem(18),
    fontWeight: 400,
    fontFamily: theme.fonts.sansSerif,
    lineHeight: calcRem(24),
}))

export const ButtonWrap = styled('div')<{ enabledSwgCampaign?: boolean }>(
    ({ theme, enabledSwgCampaign }) => ({
        display: 'flex',
        marginTop: calcRem(40),

        [breakpointMax('xs')]: {
            flexWrap: 'wrap',
            marginTop: calcRem(20),
            marginBottom: calcRem(20),
        },

        [breakpointMax('xxs')]: {
            flexDirection: enabledSwgCampaign ? 'column-reverse' : 'unset',
        },
    }),
)
export const SubscribeButton = styled(StyledButton)(({ theme }) => ({
    height: calcRem(54),
    marginRight: calcRem(12),
    width: calcRem(200),
    border: 'none',
    borderRadius: '8px',

    [breakpointMax('xxs')]: {
        width: '100%',
    },
}))
export const SubscribeWithGoogleButton = styled(
    StyledSubscribeWithGoogleButton2022,
)(({ theme }) => ({
    height: calcRem(54),
    margin: 0,
    maxWidth: calcRem(270),
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.3)',
    [breakpointMax('xs')]: {
        marginTop: calcRem(10),
    },
    [breakpointMax('xxs')]: {
        width: '100%',
        marginTop: calcRem(10),
        maxWidth: '100%',
    },
}))

export const BottomTextWrap = styled('div')(({ theme }) => ({
    display: 'block',
    marginTop: calcRem(18),
}))

export const InfoText = styled('p')(({ theme }) => ({
    fontFamily: theme.fonts.sansSerif,
}))

export const InfoTextLink = styled(TextLink)(({ theme }) => ({
    fontFamily: theme.fonts.sansSerif,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}))

export const PromoRibbon = styled('div')(({ theme }) => ({
    height: calcRem(50),
    padding: calcRem(4, 20),
    marginLeft: calcRem(20),
    background: tokens.thewest.colors.palette.breachBgLightBlue,
    color: tokens.thewest.colors.palette.westblue,
    borderRadius: '100px',
    border: 'none',
    fontSize: calcRem(22),
    fontWeight: 700,
    fontFamily: theme.fonts.sansSerif,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',

    [breakpointMax('xxs')]: {
        margin: 0,
        marginBottom: calcRem(10),
        alignSelf: 'flex-start',
    },
}))
