import {
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import { CommonCardProps } from '../../CardItem.Props'
import { StyledLink } from '../../CardText/CardTextInteractions.styled'
import {
    StyledContentWrap,
    StyledSmallCard,
    StyledSmallCardContent,
} from './SmallCard.styled'

export interface SmallCardProps extends CommonCardProps, ResponsiveContainer {
    item: MaybeLoaded<PublicationCardItem>
    cardNumber: number
}

export function SmallCard(props: SmallCardProps) {
    const { item, cardNumber, onEvent } = props

    const storyClassification = true
    const fundingType = item.loaded
        ? getFundingType(item.result, storyClassification)
        : undefined

    function linkClicked(e: React.MouseEvent<HTMLElement>) {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }
        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalPortrait',
                'Portrait',
                props.cardNumber,
                fundingType,
                undefined,
            ),
        )
    }

    return (
        <StyledLink
            to={toLinkWithHint(item)}
            onClick={linkClicked}
            isLoading={!item.loaded}
        >
            <StyledSmallCard cardNumber={cardNumber}>
                <StyledContentWrap>
                    <StyledSmallCardContent
                        item={item}
                        hasBackground={true}
                        kickerMode={{ size: 'large' }}
                        teaserMode={'visible'}
                        fontScale={0.88}
                        onEvent={onEvent}
                    />
                </StyledContentWrap>
            </StyledSmallCard>
        </StyledLink>
    )
}

SmallCard.displayName = 'SmallCard'
