import {
    click_origin_subscribe,
    DataLayerEventName,
    SSWQueryString,
    SubscribeEvent,
} from '@news-mono/web-common'
import H from 'history'
import queryString from 'query-string'

// Used to track subscribe.complete events, which are events that result in a ssw redirect after a subscription
// that did not originate from a breach screen
export function createIsEntitledSubscribeChangeHandler(
    location: H.Location,
    onEvent: (event: SubscribeEvent) => void,
): (event: { entitled: boolean; loggedInStatusChecked: boolean }) => void {
    return ({ entitled, loggedInStatusChecked }) => {
        if (!loggedInStatusChecked) {
            return
        }

        if (entitled) {
            const query = queryString.parse(location.search)

            // Need to ensure we convert the necessary items to numbers for the logic check below
            const sswQueryString: SSWQueryString = {
                transactionId: Number(query.transactionId),
                transactionTotal: Number(query.transactionTotal),
                transactionTax: Number(query.transactionTax),
                productSku: query.productSku,
                productName: query.productName,
                clickOrigin: query.clickOrigin,
            }

            const { clickOrigin, ...sswTransaction } = sswQueryString

            // Only raise when origin is online (non-breach screen)
            if (
                sswTransaction.transactionId &&
                clickOrigin === click_origin_subscribe
            ) {
                const eventPayload: SubscribeEvent['payload'] = {
                    paywallType: 'freemium',
                    leadDepth: 0,
                    creative: undefined,
                    breachScreenType: undefined,
                    popupMethod: undefined,
                    deal: undefined,
                    pricepoint: [7, 8],
                }

                onEvent({
                    type: DataLayerEventName.subscribeComplete,
                    originator: 'subscribe-button',
                    payload: {
                        ...eventPayload,
                        ...sswTransaction,
                    },
                })
            }
        }
    }
}
