import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { PromoStrap } from '../../content/PromoStrap/PromoStrap'

type SlotType = { subSlot: string | undefined; unsubSlot: string | undefined }

export const PromoStrapRegistration = createRegisterableComponentWithData(
    'breaking-news-promo-strap',
    CurationAndCurationMetadataLoader,
    ({}, data, services) => {
        if (data.loaded) {
            return (
                <PromoStrap
                    link={getLinkIds(data.result.metadata).subSlot}
                    unsubLink={getLinkIds(data.result.metadata).unsubSlot}
                    titlePrefix={data.result.metadata['sub-promo-strap-title']}
                    unsubTitlePrefix={
                        data.result.metadata['unsub-promo-strap-title']
                    }
                    onEvent={services.onEvent}
                />
            )
        }
        return null
    },
)

const getLinkIds = (metadata: { [key: string]: any }): SlotType => {
    const obj = {
        subSlot: undefined,
        unsubSlot: undefined,
    }
    if (metadata['sub-slot']) {
        obj.subSlot = metadata['sub-slot'].redirectUrl
            ? metadata['sub-slot'].redirectUrl
            : metadata['sub-slot']._self
    }
    if (metadata['unsub-slot']) {
        obj.unsubSlot = metadata['unsub-slot'].redirectUrl
            ? metadata['unsub-slot'].redirectUrl
            : metadata['unsub-slot']._self
    }

    return obj
}
