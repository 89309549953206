import { useTheme } from '@emotion/react'
import React from 'react'
import { EditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { useProduct } from '../../__product/useProduct'
import { colors } from '../../__styling/settings/colors'
import { perthNowSectionDefinitions } from '../../__styling/settings/sections/perthnow'
import {
    StyledEditorialLabel,
    StyledEditorialLabelText,
    StyledIconLightning,
    StyledLiveBubble,
    StyledPerthNowBlock,
    StyledPerthNowIcon,
    StyledTheWestMark,
} from './EditorialLabel.styled'
import { IconLiveTN } from '../../icons'

export interface EditorialLabelProps {
    className?: string
    type: EditorialType
    cardHasBackground?: boolean
    isLarge?: boolean
    isOlympics?: boolean
}

interface LabelIconProps {
    type: EditorialType
    isLarge?: boolean
    isOlympics?: boolean
}

const LabelIcon: React.FC<LabelIconProps> = ({ type, isLarge, isOlympics }) => {
    const product = useProduct()
    const theme = useTheme()
    switch (type) {
        case EditorialType.Analysis:
        case EditorialType.Replay:
        case EditorialType.Investigation:
        case EditorialType.Upcoming:
        case EditorialType.Updated:
        case EditorialType.Opinion:
        case EditorialType.Review:
            return product === 'perthnow' ? (
                <StyledPerthNowBlock isLarge={isLarge} />
            ) : null
        case EditorialType.Exclusive:
            return product === 'perthnow' ? (
                <StyledPerthNowIcon isLarge={isLarge} />
            ) : (
                <StyledTheWestMark
                    isLarge={isLarge}
                    fill={theme.colors.brand}
                />
            )
        case EditorialType.Live:
            return product === 'thenightly' ? (
                <IconLiveTN />
            ) : (
                <StyledLiveBubble isOlympics={isOlympics} />
            )
        case EditorialType.Breaking:
            return (
                <StyledIconLightning
                    isLarge={isLarge}
                    fill={
                        product === 'perthnow'
                            ? perthNowSectionDefinitions.default.primaryColor
                            : theme.colors.components?.card?.label?.breaking ??
                              colors.thewest.ladybird
                    }
                />
            )
    }
}

export const EditorialLabel: React.FC<EditorialLabelProps> = ({
    className,
    type,
    cardHasBackground,
    isLarge,
    isOlympics = false,
}) => {
    const product = useProduct()

    if (product === 'sevennews' && type !== EditorialType.Live) {
        return null
    }

    const isBreaking = type === EditorialType.Breaking

    return (
        <StyledEditorialLabel
            className={className}
            isBreaking={isBreaking}
            cardHasBackground={cardHasBackground}
            isLarge={isLarge}
        >
            <LabelIcon type={type} isLarge={isLarge} isOlympics={isOlympics} />{' '}
            <StyledEditorialLabelText isOlympics={isOlympics}>
                {type}
            </StyledEditorialLabelText>
        </StyledEditorialLabel>
    )
}
EditorialLabel.displayName = 'EditorialLabel'
