import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpointMax, calcRem } from '../../../__styling'

export const PuzzleListingContainer = styled('div')(() => ({
    width: '100%',
}))

interface bannerProps {
    leftImage?: string
    rightImage?: string
    isApp?: boolean
}

export const PuzzleListingBanner = styled('div')<bannerProps>(
    ({ leftImage, rightImage, isApp, theme }) => [
        {
            width: '100%',
            height: calcRem(150),
            padding: calcRem(40),
            marginTop: isApp ? calcRem(20) : calcRem(40),
            marginBottom: calcRem(20),
            position: 'relative',
            background: tokens.thewest.colors.palette.breachLightBlue,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            overflow: 'hidden',

            '& p': {
                fontSize: calcRem(56),
                fontWeight: 400,
                fontFamily: theme.fonts.sansSerif,

                '& span': {
                    fontWeight: 600,
                },
            },

            [breakpointMax('md')]: {
                p: {
                    fontSize: calcRem(40),
                },
            },

            [breakpointMax('xs')]: {
                p: {
                    fontSize: calcRem(20),
                },
                height: calcRem(82),
                padding: calcRem(0, 40),
            },
        },
        (leftImage || rightImage) && {
            '&::before': {
                content: `url(${leftImage})`,
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
            },
            '&::after': {
                content: `url(${rightImage})`,
                position: 'absolute',
                zIndex: 1,
                top: 0,
                right: 0,
            },
            [breakpointMax('md')]: {
                '&::before': {
                    left: '-60px',
                },
                '&::after': {
                    right: '-70px',
                },
            },
            [breakpointMax('xs')]: {
                '&::before': {
                    top: 'unset',
                    bottom: '-4px',
                    left: 0,
                },
                '&::after': {
                    top: 'unset',
                    bottom: '-4px',
                    right: 0,
                },
            },
        },
    ],
)

export const PuzzleListingDescription = styled('p')(() => ({
    fontSize: calcRem(16),
    lineHeight: calcRem(19),
    marginTop: 0,
    color: tokens.thewest.colors.text.primary,
}))

export const PuzzleListingItem = styled('a')(() => ({
    backgroundColor: tokens.thewest.colors.palette.greySeal,
    border: `solid 1px ${tokens.thewest.colors.palette.greyErmine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: calcRem(30),
    textDecoration: 'none',
    [breakpointMax('md')]: {
        padding: calcRem(16),
    },
    '& img': {
        width: '100%',
    },
    '& h3': {
        fontWeight: 600,
        fontSize: calcRem(16),
        color: tokens.thewest.colors.text.primary,
        textDecoration: 'none',
        margin: calcRem(10, 0, 0, 0),
    },
}))
