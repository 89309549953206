import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { metrics, themedValue } from '../../..'
import {
    breakpoint,
    breakpointMax,
} from '../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { StyledBylineClassName } from '../../CardByline/CardByline.styled'
import { CardMedia } from '../../CardMedia/CardMedia'
import { StyledMediaContainer } from '../../CardMedia/StyledMedia.styled'
import { CardText } from '../../CardText/CardText'
import {
    StyledHeadlineText,
    StyledTeaser,
} from '../../CardText/CardText.styled'

export const StyledHeroSupportCardClassName = 'StyledHeroSupportCard'

export const StyledHeroSupportCard = withClass(StyledHeroSupportCardClassName)(
    styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `1px solid ${theme.colors.borders.primary}`,
        height: '100%',
        [breakpoint('md')]: {
            flexDirection: 'column',
        },
        //hide byline up to tablet
        [`.${StyledBylineClassName}`]: {
            display: 'none',
            [breakpoint('md')]: {
                display: 'flex',
            },
        },
        [`.${StyledMediaContainer}`]: {
            width: calcRem(110),
            [breakpoint('sm')]: {
                width: calcRem(210),
            },
            [breakpoint('md')]: {
                width: 'unset',
            },
        },
    })),
)

export const StyledHeroSupportCardMedia = styled(CardMedia)(({ theme }) => ({
    margin: `0 0 ${theme.margins.sm}px 0`,
    width: calcRem(110),
    [breakpoint('sm')]: {
        width: calcRem(210),
        margin: `0 ${theme.margins.sm}px ${theme.margins.md}px 0`,
    },
    [breakpoint('md')]: {
        width: 'unset',
        margin: `0 0 ${theme.margins.sm}px 0`,
    },
}))

export const StyledHeroSupportCardContent = styled(CardText)(({ theme }) => ({
    [`& ${StyledHeadlineText}`]: {
        [breakpointMax('xxs')]: {
            fontSize:
                theme.kind === 'thewest'
                    ? calcRem(metrics.thewest.cards.headingFontSize)
                    : undefined,
        },
        fontSize: calcRem(14),

        [breakpoint('sm')]: {
            fontSize: calcRem(16),
        },
    },
}))
export const StyledContentWrap = styled('div')(({ theme }) => ({
    padding: `0px 0px ${theme.margins.sm}px ${theme.margins.sm}px`,
    [breakpoint('sm')]: {
        padding: `${theme.margins.sm}px`,
    },
    [breakpoint('md')]: {
        padding: `0px ${theme.margins.sm}px ${theme.margins.sm}px ${theme.margins.sm}px`,
    },

    [`& ${StyledTeaser}`]: {
        [breakpoint('md')]: {
            display: 'none',
        },
    },
}))
