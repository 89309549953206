import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { QueryString, StaticPageRoute } from '@news-mono/web-common'
import xss from 'xss'
import { layout } from '../../App.routing'

interface SearchQuery extends QueryString {
    search: string
}

function queryHasSearch(query: QueryString): query is SearchQuery {
    if (query.search && typeof query.search === 'string') {
        return true
    }
    return false
}

export const getSearchPageRouteInfo =
    (query: QueryString): StaticPageRoute<TheWestSection> =>
    ({ config, getAdTargeting }) => {
        const searchValue = queryHasSearch(query) ? xss(query.search) : false
        const searchBreadcrumb = searchValue
            ? [{ text: 'Search' }, { text: searchValue }]
            : undefined

        // Ensure we keep the search terms in the canonicalUrl on the search result page
        const canonicalUrl =
            config.publicUrl +
            '/search' +
            (searchValue ? '?search=' + encodeURIComponent(searchValue) : '')

        const adTargeting = getAdTargeting('search', 'default', 'search')

        return {
            kind: 'page',
            heading: `Search: ${
                searchValue ? searchValue : 'You need to provide a search term!'
            }`,
            hideHeading: true,
            pageType: 'search',
            pageMeta: {
                title: 'Search',
                description: 'Search Results',
                link: [{ rel: 'canonical', href: canonicalUrl }],
            },
            adTargeting,
            section: 'default',
            compositions: [
                ...(searchBreadcrumb
                    ? [
                          layout.composition({
                              type: 'box',
                              props: {
                                  containerWidth:
                                      metrics.thewest.siteMetrics
                                          .mainContentWidth,
                                  verticalGutters: ['outerMargin', 'unset'],
                              },
                              contentAreas: {
                                  main: [
                                      layout.component({
                                          type: 'breaking-news',
                                          props: {
                                              dataDefinitionArgs: {
                                                  type: 'curation',
                                                  name: 'breaking-news',
                                                  offset: 0,
                                                  pageSize: 1,
                                              },
                                          },
                                      }),
                                      layout.component({
                                          type: 'breaking-news-promo-strap',
                                          props: {
                                              dataDefinitionArgs: {
                                                  type: 'curation',
                                                  name: 'breaking-news-promo-strap',
                                                  offset: 0,
                                                  pageSize: 1,
                                              },
                                          },
                                      }),
                                      layout.component({
                                          type: 'breadcrumb',
                                          props: {
                                              items: searchBreadcrumb,
                                              enableHeaderTag: true,
                                              horizontalGutters: 'outerMargin',
                                          },
                                      }),
                                  ],
                              },
                          }),
                      ]
                    : []),
                layout.composition({
                    type: 'zeus',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                        hasBackgroundFill: true,
                        stretchSelf: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'search',
                                props: {
                                    searchTerm: searchValue ? searchValue : '',
                                    dataDefinitionArgs: {
                                        searchTerm: searchValue
                                            ? searchValue
                                            : '',
                                        options: {},
                                    },
                                },
                            }),
                        ],
                        sidebar: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset: 350,
                                    fillContainer: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                padding: [
                                                    0,
                                                    0,
                                                    metrics.thewest.margins.md,
                                                    0,
                                                ],
                                                slot: {
                                                    id: 'sidebar-middle',
                                                    size: 'desktopMrec',
                                                    pageType: 'article',
                                                },
                                                adType: 'inline',
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    hiddenUntilLoaded: true,
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.lg,
                                        0,
                                    ],
                                    slot: {
                                        id: 'leaderboard-one',
                                        size: 'leaderboard',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'lg',
                                    fixedRatios: ['16:9'],
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    teaserMode: 'hidden',
                                    hideByline: false,
                                    hasBackground: true,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },

                                    sectionHeader: {
                                        heading: 'Top Stories',
                                        navLinks: [],
                                    },
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'top-stories',
                                        offset: 0,
                                        pageSize: 4,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'lg',
                                    fixedRatios: ['16:9'],
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    teaserMode: 'hidden',
                                    hideByline: false,
                                    hasBackground: true,

                                    cardLayout: [
                                        ['teaserWithBackground'],
                                        ['teaserWithBackground'],
                                        [
                                            {
                                                type: 'landscape',
                                                format: 'landscape-smTeaser-mdNoTeaser',
                                            },
                                            {
                                                type: 'landscape',
                                                format: 'landscape-smTeaser-mdNoTeaser',
                                            },
                                            {
                                                type: 'landscape',
                                                format: 'landscape-smTeaser-mdNoTeaser',
                                            },
                                        ],
                                        ['teaserWithBackground'],
                                    ],
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },
                                    sectionHeader: {
                                        heading: 'In Case You Missed It',
                                        navLinks: [],
                                    },
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'featured',
                                        offset: 0,
                                        pageSize: 6,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    hiddenUntilLoaded: true,
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.lg,
                                        0,
                                    ],
                                    slot: {
                                        id: 'leaderboard-two',
                                        size: 'leaderboard',
                                    },
                                    adType: 'inline',
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    }
