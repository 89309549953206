import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AflFixturesWidget, AflFixturesWidgetProps } from './AflFixturesWidget'

export const AflFixturesWidgetRegistration = createRegisterableComponent(
    'afl-fixtures-widget',
    (props: AflFixturesWidgetProps, services) => {
        return <AflFixturesWidget {...props} />
    },
)
