import React from 'react'

export default function TVGuideSideBarWidgetThree() {
    return (
        <iframe
            id="tvguidetonight-widget-combined"
            src="https://tvguidetonight.com.au/widgets/combined.html?region=nsw"
            style={{ height: '968px', border: 'none' }}
            title=""
        ></iframe>
    )
}
