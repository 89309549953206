import { FeatureState } from '@etrigan/feature-toggles-client'
import { useProduct } from '@news-mono/component-library'
import {
    CardItem,
    CollectionEvent,
    Product,
    createCollectionAvailableEvent,
    isPublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { HeaderV2 } from '../../SevenNewsV2/HeaderV2/HeaderV2'
import { InlineContentCard } from '../../SevenNewsV2/cards/InlineContentCard/InlineContentCard'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { OverrideThemeSection } from '../../__helpers/override-theme-section'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { Section } from '../../__styling/settings/sections'
import { ThemedRender } from '../../__styling/themed-render'
import { ListCard } from '../../cards/ListCard/ListCard'
import {
    StyledGrid,
    StyledGridColumn,
    StyledGridHeaderRow,
    StyledGridRow,
    StyledLandscapeItem,
    StyledList,
    StyledSectionHeader,
} from '../../collections/November/November.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { SectionHeaderProps } from '../../section-header/SectionHeader/SectionHeader'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { StyledCardListItemWrapper } from '../../SevenNewsV2/cards/InlineContentCard/InlineContentCard.styled'

export interface NovemberProps {
    className?: string
    timestamp?: TimestampType
    hasBackground?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    sectionHeader?: SectionHeaderProps
    section?: Section
    disableImpressionEvent?: boolean
    cardNumberOffset?: number
    noStretch?: boolean
    truncateListCards?: boolean
    onEvent: (event: CollectionEvent) => void
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    contentIsTangential?: boolean
    currentPath?: string
    toggles?: FeatureState
}

export const November: React.FC<NovemberProps> = (props) => {
    const product = useProduct()

    function renderListCards() {
        const { timestamp, truncateListCards, onEvent } = props
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)
        return (
            <React.Fragment>
                {items.map((item, index) => {
                    const cardNum = index + 1
                    return (
                        <CardSwitcher
                            onEvent={onEvent}
                            key={cardNum}
                            item={item}
                            cardContext="november-list"
                            cardNumber={cardNum}
                            publicationCard={(publicationItem) => (
                                <ListCard
                                    key={index}
                                    item={publicationItem}
                                    timestamp={timestamp}
                                    onEvent={onEvent}
                                    cardNumber={cardNum}
                                    isTruncated={truncateListCards}
                                />
                            )}
                        />
                    )
                })}
            </React.Fragment>
        )
    }

    function renderSevenListCards() {
        const { onEvent } = props
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)

        return (
            <React.Fragment>
                {items.map((item, index) => {
                    const cardNum = index + 1
                    return (
                        <CardSwitcher
                            onEvent={onEvent}
                            key={cardNum}
                            item={item}
                            cardContext="november-list"
                            cardNumber={cardNum}
                            publicationCard={(publicationItem) => {
                                if (
                                    publicationItem.loaded &&
                                    isPublicationCardItem(
                                        publicationItem.result,
                                    )
                                ) {
                                    return (
                                        <StyledCardListItemWrapper>
                                            {
                                                <InlineContentCard
                                                    item={publicationItem}
                                                    onEvent={props.onEvent}
                                                    cardNumber={cardNum}
                                                    isSideBarCmp={true}
                                                    numberOfCards={items.length}
                                                />
                                            }
                                        </StyledCardListItemWrapper>
                                    )
                                } else {
                                    return null
                                }
                            }}
                        />
                    )
                })}
            </React.Fragment>
        )
    }

    function renderLandscapeCards() {
        const { timestamp, onEvent } = props
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)

        return (
            <React.Fragment>
                {items.map((item, index) => {
                    const cardNum = index + 1
                    return (
                        <CardSwitcher
                            onEvent={onEvent}
                            key={cardNum}
                            item={item}
                            cardContext="november-landscape"
                            cardNumber={cardNum}
                            publicationCard={(publicationItem) => (
                                <StyledLandscapeItem
                                    key={cardNum}
                                    item={publicationItem}
                                    mediaMode="hidden"
                                    asListElement
                                    cardNumber={
                                        cardNum + (props.cardNumberOffset || 0)
                                    }
                                    onEvent={onEvent}
                                    timestamp={timestamp}
                                    hideByline
                                    teaserMode="hidden"
                                />
                            )}
                        />
                    )
                })}
            </React.Fragment>
        )
    }

    function renderSectionHeader() {
        const { sectionHeader, currentPath, toggles } = props

        // Only render section header when there are items and it's specified
        return (
            sectionHeader &&
            (!props.items.loaded || props.items.result.length) && (
                <StyledGridHeaderRow>
                    <StyledGridColumn>
                        {product === Product.SevenNews ? (
                            <HeaderV2
                                sectionHeader={{ heading: 'Top Headlines' }}
                            />
                        ) : (
                            <StyledSectionHeader {...sectionHeader} />
                        )}
                    </StyledGridColumn>
                </StyledGridHeaderRow>
            )
        )
    }

    function renderNovember() {
        const { className, verticalSpacing, contentIsTangential } = props

        return (
            <ImpressionAvailable
                loading={!props.items.loaded}
                available={() => {
                    if (props.disableImpressionEvent) {
                        return
                    }
                    if (!props.items.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }
                    props.onEvent(
                        createCollectionAvailableEvent(
                            props.items.result,
                            'November',
                            product,
                            props.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <StyledGrid
                        ref={ref}
                        className={className}
                        verticalSpacing={verticalSpacing}
                        noStretch={props.noStretch}
                        as={contentIsTangential ? 'aside' : 'div'}
                    >
                        <StyledGridRow hasBackground={props.hasBackground}>
                            {renderSectionHeader()}
                            <StyledGridColumn>
                                <StyledList
                                    removeBorderLast={
                                        product === Product.SevenNews
                                            ? true
                                            : props.hasBackground
                                    }
                                >
                                    <ThemedRender
                                        perthnow={() => renderListCards()}
                                        sevennews={() => renderSevenListCards()}
                                        thewest={() => renderLandscapeCards()}
                                    />
                                </StyledList>
                            </StyledGridColumn>
                        </StyledGridRow>
                    </StyledGrid>
                )}
            </ImpressionAvailable>
        )
    }

    return (
        <OverrideThemeSection
            section={props.section ? props.section : 'default'}
        >
            {renderNovember()}
        </OverrideThemeSection>
    )
}
November.displayName = 'November'
