import styled, { StyledComponent } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink, WebLinkProps } from '@news-mono/web-common'
import { AmpImage } from '../../content/Picture/Picture.amp'
import { IconChevronRight } from '../../icons/IconChevronRight/IconChevronRight'
import { IconSubscribe } from '../../icons/IconSubscribe/IconSubscribe'
import { IconWestRewards } from '../../icons/IconWestRewards/IconWestRewards'
import { TheWest190Logo } from '../../logos/TheWest190Logo/TheWest190Logo'
import { TheWestGoMatildasLogo } from '../../logos/TheWestGoMatildasLogo/TheWestGoMatildas'
import { TheWestLogo } from '../../logos/TheWestLogo/TheWestLogo'
import { Timestamp } from '../../typography/Timestamp/Timestamp'
import { filterComponent } from '../../__helpers/filter-component'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { LogoProps } from './logos/createHeaderLogo'
import { StyledLogoWrapLink } from './logos/MastheadLogo.styled'

export const StyledTheWestHeaderWrapper = styled('div')({
    display: 'block',
    width: '100%',
    maxWidth: metrics.thewest.siteMetrics.mainContentWidth,
    margin: '0 auto',
    padding: 0,

    [breakpoint('md')]: {
        padding: `0 ${calcRem(15)}`,
    },
})

interface StyledTheWestHeaderProps {
    showDivider: boolean
    featureEnabled: boolean
}
export const StyledTheWestHeader = styled('div')<StyledTheWestHeaderProps>(
    ({ showDivider, featureEnabled }) => ({
        width: '100%',
        padding: calcRem(metrics.thewest.margins.md, 0),
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        // Show a divider if requested, mainly for when the pageType is a feature-publication and we dont have the cricket ball feature toggled on
        borderBottom: showDivider
            ? `solid 1px ${colors.thewest.greyDolphin}`
            : undefined,

        ['&.regionalSwgCampaignPage']: {
            paddingBottom: 0,
            borderBottom: 'none',
        },
    }),
)

export const StyledTheWestLogo = styled(TheWestLogo)({
    display: 'block',
})

export const StyledTheWest190Logo = styled(TheWest190Logo)({
    display: 'block',
})

export interface ImageLogoProps {
    /* This allows amp to get the aspect ratio */
    ampInitialSize: { width: number; height: number }
    heights?: {
        desktop: number
        tablet: number
        mobile: number
    }
    verticalSpacing?: number
}

export const StyledImageLogo = styled('img')<LogoProps>((props) => ({
    display: 'block',
    height: props.heights ? calcRem(props.heights.desktop) : 'auto',
    margin: '0 auto',
    paddingTop: props.verticalSpacing ? calcRem(props.verticalSpacing) : 0,
    paddingBottom: props.verticalSpacing ? calcRem(props.verticalSpacing) : 0,
    maxWidth: '100%',
    maxHeight: '82px',

    [breakpointMax('sm')]: {
        height: props.heights ? calcRem(props.heights.tablet) : 'auto',
    },
    [breakpointMax('xxs')]: {
        height: props.heights ? calcRem(props.heights.mobile) : 'auto',
    },
}))

export const StyledAmpLogo = styled(AmpImage)({
    display: 'block',
})

export const StyledTimestampWrap = styled('div')(() => ({
    textAlign: 'right',
    marginLeft: 'auto',
    flex: 1,
}))

export const StyledLeftWrapper = styled('div')(() => ({
    marginRight: 'auto',
    flex: 1,
}))

export const StyledHeaderTimestamp = styled(Timestamp)({
    display: 'none',

    [breakpoint('md')]: {
        display: 'block',
        alignContent: 'center',
        fontSize: calcRem(14),
        color: colors.black,
    },
})

export const StyledInHouseLinks = styled('nav')({
    display: 'none',

    [breakpoint('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: calcRem(metrics.thewest.margins.md),
    },
})

export const StyledLink = styled(WebLink)(({ theme }) => ({
    paddingLeft: calcRem(metrics.thewest.margins.sm),
    lineHeight: 1,
    fontFamily: fonts.thewest.sansSerif,
    fontSize: calcRem(14),
    fontWeight: 500,
    color: colors.black,
    textDecoration: 'none',
    transition: `color ${easing.easeOut.fast}`,

    '&:not(:last-child)': {
        borderRight: `1px solid ${colors.black}`,
        paddingRight: calcRem(metrics.thewest.margins.sm),
    },

    '&:hover, &:focus': {
        color: theme.sectionValues.primaryColor,
        textDecoration: 'underline',
    },

    '&:focus': {
        outline: 'none',
    },
}))

interface StyledSubscribeLinkProps extends WebLinkProps {
    shouldShrink?: boolean
}

const FilteredSubscribeLink = filterComponent<StyledSubscribeLinkProps, string>(
    WebLink,
    ['shouldShrink'],
)

export const StyledSubscribeLink = styled(FilteredSubscribeLink)(
    ({ theme }) => ({
        display: 'none',

        [breakpoint('md')]: {
            display: 'block',
            color: theme.sectionValues.primaryColor,
            fontFamily: fonts.thewest.sansSerif,
            fontSize: calcRem(15),
            fontWeight: 700,
            textTransform: 'uppercase',
            textDecoration: 'none',
            background: 'none',
            border: 'none',
            cursor: 'pointer',

            '&:hover': {
                color: colors.thewest.greyGorilla,

                '.icon-chevron-right': {
                    fill: colors.thewest.greyGorilla,
                },
            },

            '&:focus': {
                color: colors.thewest.greyKoala,
                textDecoration: 'underline',
                outline: 'none',

                '.icon-chevron-right': {
                    fill: colors.thewest.greyKoala,
                },
            },
        },
    }),
    (props) => ({
        [breakpoint('md')]: props.shouldShrink && {
            fontSize: calcRem(12),
            textAlign: 'center',

            [`& > ${StyledSubscribeIcon}`]: {
                height: calcRem(36),
            },
        },
    }),
)

export const StyledWestRewardsLink = styled(WebLink)(({ theme }) => ({
    display: 'none',

    [breakpoint('md')]: {
        display: 'block',
        color: theme.sectionValues.primaryColor,
        textDecoration: 'none',
        background: 'none',
        border: 'none',
        cursor: 'pointer',

        '&:hover': {
            color: colors.thewest.greyGorilla,
        },

        '&:focus': {
            color: colors.thewest.greyKoala,
        },
    },
}))

export const StyledSubscribeButton = StyledSubscribeLink.withComponent(
    'button',
) as StyledComponent<
    JSX.IntrinsicElements['button'],
    { shouldShrink?: boolean }
>

export const StyledChevronRight = styled(IconChevronRight)(({ theme }) => ({
    width: calcRem(8),
    height: calcRem(11),
    fill: theme.sectionValues.primaryColor,
}))

export const StyledSubscribeIcon = styled(IconSubscribe)({
    display: 'block',
    width: calcRem(88),
    height: calcRem(51),
})

export const StyledWestRewardsIcon = styled(IconWestRewards)({
    display: 'block',
    width: calcRem(160),
    height: calcRem(72),
})

export const StyledRegionalSwgPromoMastheads = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [breakpoint('md')]: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        borderBottom: `1px solid ${tokens.thewest.colors.palette.greyDolphin}`,
        paddingBottom: calcRem(24),
        marginBottom: calcRem(8),
    },

    [`${StyledLogoWrapLink}`]: {
        marginBottom: metrics.thewest.margins.md,

        [breakpoint('md')]: {
            marginBottom: 0,
            maxWidth: 478,
            paddingRight: calcRem(20),
            borderRight: `1px solid ${tokens.thewest.colors.palette.greyDolphin}`,
        },
    },

    [`${StyledImageLogo}`]: {
        [breakpoint('md')]: {
            height: 'auto',
        },
    },

    [`${StyledTheWestLogo}`]: {
        [breakpoint('md')]: {
            maxWidth: 487,
            marginLeft: calcRem(20),
        },
    },
})
