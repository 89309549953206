import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AflInjuriesSuspensionsWidget } from './AflInjuriesSuspensionsWidget'

export interface AflInjuriesSuspensionsWidgetRouteProps {
    apiData: any
    competition: string
    season: string
}

export const AflInjuriesSuspensionsWidgetRegistration =
    createRegisterableComponent(
        'afl-injuries-suspensions-widget',
        (props: AflInjuriesSuspensionsWidgetRouteProps, services) => {
            return <AflInjuriesSuspensionsWidget {...props} />
        },
    )
