import * as React from 'react'

export interface LinkedInIconProps {
    linkedInFillColor?: string
    backgroundFillColor?: string
}

export const LinkedinIcon: React.FC<LinkedInIconProps> = ({
    linkedInFillColor = 'none',
    backgroundFillColor = 'white',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={linkedInFillColor}
    >
        <title>LinkedIn</title>
        <g clipPath="url(#clip0_11769_61749)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.995 0C23.1023 0 24 0.897026 24 2.00495V21.9951C24 23.1024 23.103 24 21.995 24H2.00495C0.897645 24 0 23.103 0 21.9951V2.00495C0 0.897645 0.897026 0 2.00495 0H21.995ZM16.2225 9C14.5192 9 13.3537 9.75283 12.8348 10.6275L12.75 10.783V9.15H9.5V20.65H13V15.0795C13 13.2125 13.6875 12.025 15.34 12.025C16.4797 12.025 16.9579 13.0328 16.9973 14.8296L17 15.0795V20.65H20.5V14.2985C20.5 10.869 19.6795 9 16.2225 9ZM7 9H3.5V20.5H7V9ZM5.25 3C4.0075 3 3 4.0075 3 5.25C3 6.4925 4.0075 7.5 5.25 7.5C6.4925 7.5 7.5 6.4925 7.5 5.25C7.5 4.0075 6.4925 3 5.25 3Z"
                fill={backgroundFillColor}
            />
        </g>
        <defs>
            <clipPath id="clip0_11769_61749">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
