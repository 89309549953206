import { cx } from '@emotion/css'
import React from 'react'

export interface IconChevronUpTNProps {
    className?: string
}

export const IconChevronUpTN: React.FC<IconChevronUpTNProps> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={cx(className, 'icon-chevron-up-tn')}
    >
        <path
            d="M18 15L12 9L6 15"
            stroke="currentColor" // inherit text color.
            strokeWidth={2}
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconChevronUpTN.displayName = 'IconChevronUpTN'
