import { AppState, AuthenticationState } from '@news-mono/web-common'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { THEWEST_ALL } from './common'

interface EntitledToPremiumProps {
    onIsEntitledChange?: (event: {
        entitled: boolean
        loggedInStatusChecked: boolean
    }) => void
}

export function entitledToAll(authentication: AuthenticationState) {
    return authentication.entitlements.indexOf(THEWEST_ALL) >= 0
}

export const EntitledToPremium: React.FC<EntitledToPremiumProps> = ({
    onIsEntitledChange,
}) => {
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isEntitled = entitledToAll(authentication)

    useEffect(() => {
        if (onIsEntitledChange) {
            onIsEntitledChange({
                entitled: isEntitled,
                loggedInStatusChecked: authentication.hasAttemptedValidation,
            })
        }
        // Ignoring eslint-disable-next-line react-hooks/exhaustive-deps for this effect
        // because it will continue to trigger. We can come back and look at useCallback and
        // resolving the issue. Any news deps should still be added
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authentication.hasAttemptedValidation])

    return null
}
