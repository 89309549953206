import { ConsentLevel, useConsentGiven } from '@news-mono/web-common'
import { BlogStateV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { StyledText } from '../../content/Blog/Blog.styled'
import { AmpIFrame } from '../../publication/Embed/amp/AmpIFrame'
import { createRenderTarget } from '../../render-target'
import { ConsentFallback } from '../../tracking/ConsentFallback/ConsentFallback'

export interface BlogProps {
    blogId: string
    provider: string
    blogState?: BlogStateV4DTO // Not used in this component at the moment but could be used for something in future
}

export const LIVEBLOG24 = '24liveblog'

export function is24LiveBlog(url: string) {
    return url.indexOf('24liveblog') !== -1
}

export function getLiveBlogIdFromLink(link: string): undefined | string {
    // matches the id in the square brackets below
    // https://portal.24liveblog.com/liveblog#/event/[2196978465202656040]/news/add
    const result = link.match(/event\/(\w+)(?=\/news)/)
    if (result) {
        const [, id] = result
        return id
    }
    return undefined
}

// Message that is styled in italics
const getText = (text: string) => {
    return <StyledText>{text}</StyledText>
}

// Get the normal iframe for web content
const LiveBlog24Web: React.FC<{ src: string }> = (props) => {
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Analytics | ConsentLevel.Advertising,
    })

    if (!consentGiven) {
        return <ConsentFallback />
    }

    return (
        <iframe
            frameBorder="0"
            height="960px"
            width="100%"
            scrolling="auto"
            src={props.src}
            sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox" // match amp iframe security settings
        />
    )
}

// Render the Amp Iframe for the 24 live blog
const LiveBlog24Amp: React.FC<{ src: string }> = (props) => {
    return (
        <AmpIFrame
            src={props.src}
            height={960}
            width={undefined}
            frameborder={0}
            layout={'fixed-height'}
            allowSameOrigin={true}
            allowPopups={true}
            allowForms={true}
        />
    )
}

// In the case that its a liveblog24 and needs an a tag for RSS
const LiveBlog24Rss: React.FC<{ src: string }> = (props) => {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={props.src} />
}

const LiveBlog24 = createRenderTarget('LiveBlog24', {
    web: LiveBlog24Web,
    amp: LiveBlog24Amp,
    rss: LiveBlog24Rss,
    preview: LiveBlog24Web,
    app: LiveBlog24Web,
})

export const Blog: React.FC<BlogProps> = (props) => {
    const { provider, blogId } = props

    switch (provider) {
        case LIVEBLOG24: {
            const src = `https://v.24liveblog.com/iframe/?id=${blogId}`
            return <LiveBlog24 src={src} />
        }
        default:
            // if the provider is unknown or a different type this will appear
            return getText('Live Blog - This blog is not currently available')
    }
}
