import { InlineContentTypes } from '../templates/Publication/SharedPublication.routing'
import { metrics } from '../__styling'

export const teadsAdSlotID = 'teads4x4'

export interface InlineArticleAdsProps {
    insertAfter: number
    noticePosition: 'above-center' | 'below-center' | 'none'
    hiddenUntilLoaded: boolean
    mastHead: 'sevennews' | 'thewest' | 'perthnow' | 'thenightly'
    slotID: string
    size:
        | 'alwaysMrecMultiSize'
        | 'alwaysMrec'
        | 'below768Mrec'
        | 'leaderboardAbove768MrecBelow'
        | 'teads'
    pageType: 'article' | 'liveblog' | 'gallery'
    lazyLoadingDistance?: number
}

type PaddingOptions = Record<
    InlineArticleAdsProps['mastHead'],
    [number, number, number, number]
>

export const adPaddingOptions: PaddingOptions = {
    thewest: [0, 0, metrics.thewest.margins.md, 0],
    sevennews: [
        metrics.sevennews.margins.xs,
        0,
        metrics.sevennews.margins.lg,
        0,
    ],
    perthnow: [18, 0, 18, 0],
    thenightly: [
        metrics.thenightly.textMargin,
        0,
        metrics.thenightly.margins.xxl,
        0,
    ],
}

export function inlineArticleAds({
    insertAfter,
    noticePosition,
    hiddenUntilLoaded,
    mastHead,
    slotID,
    size,
    pageType,
    lazyLoadingDistance,
}: InlineArticleAdsProps): InlineContentTypes {
    return {
        kind: 'ad',
        insertAfter: insertAfter,
        content: [
            {
                noticePosition,
                hiddenUntilLoaded,
                padding: adPaddingOptions[mastHead],
                slot: {
                    id: slotID,
                    size,
                    pageType,
                },
                adType: 'inline',
                lazyLoadingDistance,
            },
        ],
    }
}
