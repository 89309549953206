import { ContentDataTypes, DataLoaderGlobalParams } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { useEffect, useState } from 'react'
import {
    CollectionDataLoaded,
    loadCollectionData,
} from 'web-common/src/data/loader-definitions'

export type LoadedData = Exclude<
    MaybeLoaded<CollectionDataLoaded>,
    { loaded: false }
>

/**
 * A hook which returns all that is necessary to 'load more' data.
 * @param initialDataDefinition The initial data-definition to load data.
 * @param initialData The initial data already loaded.
 * @param onLoadMore A callback function that is run once new data is loaded.
 * @param services Services required for loading mroe data.
 * @returns
 */
export const useLoadMore = (
    initialDataDefinition: ContentDataTypes,
    initialData: MaybeLoaded<CollectionDataLoaded>,
    services: DataLoaderGlobalParams,
    onLoadMore?: (data: LoadedData) => void,
) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(initialData)
    const [dataDefinition, setDataDefinition] = useState(
        excludeAggregated(initialDataDefinition),
    )

    // When the data or data definition from the props change, reset to initial state.
    useEffect(() => {
        setIsLoading(false)
        setData(initialData)
        setDataDefinition(excludeAggregated(initialDataDefinition))
    }, [initialData, initialDataDefinition])

    async function nextPage() {
        if (
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.paging
        ) {
            const newDataDefinition = {
                ...dataDefinition,
                paging: {
                    ...dataDefinition.paging,
                    page: dataDefinition.paging.page + 1,
                },
            }

            setDataDefinition(newDataDefinition)
            return await loadCollectionData(services, newDataDefinition)
        }
        if (dataDefinition && dataDefinition.type === 'edition') {
            const newDataDefinition = {
                ...dataDefinition,
                page: dataDefinition.page + 1,
            }
            setDataDefinition(newDataDefinition)
            return await loadCollectionData(services, newDataDefinition)
        }
        return undefined
    }

    async function loadMore(loadedData: LoadedData) {
        setIsLoading(true)

        const result = await nextPage()
        if (
            result &&
            result.kind === 'listing' &&
            loadedData.result.kind === 'listing'
        ) {
            // Lets update the data with some more data, adhering to the loadedData type format
            const updatedData: LoadedData = {
                loaded: true,
                result: {
                    publications: loadedData.result.publications.concat(
                        result.publications,
                    ),
                    kind: 'listing',
                    loadMorePossible: result.loadMorePossible,
                },
            }
            onLoadMore?.({
                loaded: true,
                result: {
                    publications: result.publications,
                    kind: 'listing',
                    loadMorePossible: result.loadMorePossible,
                },
            })
            setIsLoading(false)
            setData(updatedData)
        } else if (
            result &&
            result.kind === 'edition' &&
            loadedData.result.kind === 'edition'
        ) {
            // Lets update the data with some more data, adhering to the loadedData type format
            const updatedData: LoadedData = {
                loaded: true,
                result: {
                    publications: [],
                    editions: loadedData.result.editions.concat(
                        result.editions,
                    ),
                    kind: 'edition',
                    loadMorePossible: result.loadMorePossible,
                },
            }
            onLoadMore?.({
                loaded: true,
                result: {
                    editions: result.editions,
                    publications: [],
                    kind: 'edition',
                    loadMorePossible: result.loadMorePossible,
                },
            })
            setIsLoading(false)
            setData(updatedData)
        } else {
            throw new Error('Error loading next page')
        }
    }

    return {
        loadMore,
        isLoading,
        dataDefinition,
        data,
    }
}

const excludeAggregated = (dataDefinition: ContentDataTypes) =>
    dataDefinition.type !== 'aggregated' ? dataDefinition : undefined
