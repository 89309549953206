import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { RecommendationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../data/resources'
import { httpGet } from '../../helpers/http'
import { validateKeyTypes } from '../../helpers/validation'

export function validateGetRecommendationsResponse(
    value: any,
): RecommendationV4ResponseDTO {
    const errors = validateKeyTypes('recommend', value, {
        shortform: ['string'],
        longform: ['string'],
        related: [{}],
    })

    if (errors) {
        throw new Error(
            'Recommend response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}

export interface GetRecommendationsOptions {
    publicationId: string
    includePremium?: boolean
    excludeVideoSeries?: boolean
}

export const getRecommendations = (
    services: DataLoaderGlobalParams,
    options: GetRecommendationsOptions,
) => {
    const include_premium =
        // option defaults to true, which is then confirmed/denied by the feature toggle
        // see commments in https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/1501/overview
        options.includePremium !== false &&
        isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'digital-subscriptions',
            false,
        )

    return httpGet({
        log: services.log,
        validate: validateGetRecommendationsResponse,
        baseUrl: services.config.contentApi,
        path: `v4/publication/${options.publicationId}/recommend-video`,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
        query: {
            include_premium: include_premium || undefined,
            exclude_video_series: options.excludeVideoSeries,
        },
    })
}
