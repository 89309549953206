import styled from '@emotion/styled'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const westOuterMarginSm = metrics.thewest.margins.mobSm
const westOuterMargin = metrics.thewest.margins.md

export const StyledGalleryBreach = styled('div')({})

export const StyledGalleryBreachNotification = styled('div')({
    margin: calcRem(-40, -westOuterMarginSm, 0, -westOuterMarginSm),
    [breakpoint('md')]: {
        margin: calcRem(-40, -westOuterMargin, 0, -westOuterMargin),
        '> div': {
            padding: calcRem(32, 160, 32, 160),
        },
    },
})
