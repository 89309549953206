import { SectionMetaInfoDTO } from '@west-australian-newspapers/publication-types'

export function getPageUrl(
    sectionMetaInfo: SectionMetaInfoDTO,
    publicUrl: string,
    locationPathname: string,
) {
    const hostname = sectionMetaInfo.Hostname
        ? `https://${sectionMetaInfo.Hostname}`
        : publicUrl

    const pageUrl = `${hostname}${locationPathname}`

    return pageUrl
}
