import { layout } from '../../App.routing'

export const cnbcSegment = () => {
    return layout.nestedComposition({
        type: 'thor',
        feature: 'the-west-cnbc',
        props: {
            hasBackgroundFill: true,
            containerWidth: '100%',
            verticalGutters: false,
            horizontalGutters: true,
            themeOverride: 'cnbcEconomist',
            verticalSpacing: 'xl',
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'lima',
                    props: {
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },
                        cardLayout: [
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                        ],
                        intermediateColumns: 2,
                        finalColumns: 4,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'cnbc',
                            offset: 0,
                            pageSize: 4,
                        },
                        hasBorder: false,
                    },
                }),
            ],
        },
    })
}
