import {
    BreakpointState,
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../__styling'
import { tokens } from '@news-mono/design-tokens'
import { FlexDirectionProperty, PositionProperty } from 'csstype'
import { zIndex } from '../__styling/settings/z-index'

export type NotificationCentreTheme<T extends ThemeConfig> = {
    container: {
        display: string
        flexDirection: FlexDirectionProperty
        backgroundColor: string
        overflow: string
        zIndex: number
        position: PositionProperty

        breakpointState: BreakpointState<
            T,
            {
                width: string
                height: string
                maxHeight: string
                borderRadius: string
                top: string
                boxShadow?: string
                right?: string
            }
        >
    }
    noNotifications: {
        display: string
        flexDirection: FlexDirectionProperty
        padding: string
        alignItems: string
        color: string
    }
    icon: {
        position: PositionProperty
        cursor: string
        hoverStroke: string
        padding: string
        appPadding: string
        height: string
    }
    card: {
        textDecoration: string
        display: string
        flexDirection: FlexDirectionProperty
        gap: string

        hover: {
            backgroundColor: string
            borderRadius: string
            margin: string
            padding: string
        }

        visited: {
            color: string
        }

        image: {
            width: string
            height: string
            borderRadius: string
            overflow: string
            paddingBottom: string
        }

        body: {
            display: string
            flexDirection: FlexDirectionProperty
            gap: string
            alignItems: string
            justifyContent: string

            hoverColor: string
        }
    }
    cardList: {
        breakpointState: BreakpointState<
            T,
            {
                padding: string
            }
        >
    }
    sectionList: {
        padding: string
        display: string
        flexDirection: FlexDirectionProperty
        gap: string
    }
    section: {
        display: string
        flexDirection: FlexDirectionProperty
        gap: string
    }
    header: {
        padding: string
        borderBottom: string
        display: string
        flexDirection: FlexDirectionProperty
        justifyContent: string
        alignItems: string
        backgroundColor: string
        zIndex: number
        width: string

        closeIcon: {
            hoverStroke: string
            cursor: string
            alignItems: string
            display: string
        }

        breakpointState: BreakpointState<
            T,
            {
                position: PositionProperty
                top?: number
            }
        >
    }
    badge: {
        position: PositionProperty
        height: string
        width: string
        backgroundColor: string
        borderRadius: string
        right: number
        top: number
    }
}

export const theNightlyNotificationCentreTheme = (scheme: TheNightlyTheme) => {
    const theme: NotificationCentreTheme<TheNightlyThemeConfig> = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: scheme.colors.palette.white,
            overflow: 'scroll',
            zIndex: zIndex.thenightly.notificationCentre,
            position: 'absolute',

            breakpointState: {
                default: {
                    width: '100vw',
                    height: '100vh',
                    maxHeight: '100vh',
                    borderRadius: calcRem(0),
                    top: calcRem(0),
                    right: calcRem(0),
                },
                sm: {
                    width: calcRem(390),
                    height: '80vh',
                    maxHeight: '80vh',
                    borderRadius: calcRem(24),
                    top: calcRem(0),
                    right: calcRem(56),
                    boxShadow: `${calcRem(0)} ${calcRem(24)} ${calcRem(
                        40,
                    )} ${calcRem(0)} rgb(0 0 0 / 40%)`,
                },
                md: {
                    width: calcRem(480),
                    height: '100vh',
                    maxHeight: calcRem(726),
                    borderRadius: calcRem(24),
                    top: calcRem(0),
                    right: calcRem(56),
                    boxShadow: `${calcRem(0)} ${calcRem(24)} ${calcRem(
                        40,
                    )} ${calcRem(0)} rgb(0 0 0 / 40%)`,
                },
                lg: {
                    width: calcRem(480),
                    height: '100vh',
                    maxHeight: calcRem(726),
                    borderRadius: calcRem(24),
                    top: calcRem(0),
                    right: calcRem(24),
                    boxShadow: `${calcRem(0)} ${calcRem(24)} ${calcRem(
                        40,
                    )} ${calcRem(0)} rgb(0 0 0 / 40%)`,
                },
            },
        },
        noNotifications: {
            display: 'flex',
            flexDirection: 'column',
            padding: calcRem(84, 0, 0, 0),
            alignItems: 'center',
            color: scheme.colors.text.secondary,
        },
        icon: {
            position: 'relative',
            cursor: 'pointer',
            hoverStroke: scheme.colors.text.secondary,
            padding: calcRem(0, 24, 0, 0),
            appPadding: calcRem(0),
            height: calcRem(24),
        },
        card: {
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'row',
            gap: calcRem(16),

            hover: {
                backgroundColor: tokens.thenightly.colors.palette.neutral[10],
                borderRadius: calcRem(12),
                margin: calcRem(-8),
                padding: calcRem(8),
            },

            visited: {
                color: scheme.colors.text.secondary,
            },

            image: {
                width: calcRem(64),
                height: calcRem(64),
                borderRadius: calcRem(12),
                overflow: 'hidden',
                paddingBottom: calcRem(0),
            },

            body: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                gap: calcRem(4),

                hoverColor: scheme.colors.text.secondary,
            },
        },
        cardList: {
            breakpointState: {
                default: {
                    padding: calcRem(72 + 20, 24, 24, 24), // 72 is semi magic. It's the size of the header
                },
                sm: {
                    padding: calcRem(20, 24, 24, 24),
                },
            },
        },
        sectionList: {
            padding: calcRem(20, 24, 24, 24),
            display: 'flex',
            flexDirection: 'column',
            gap: calcRem(32),
        },
        section: {
            display: 'flex',
            flexDirection: 'column',
            gap: calcRem(16),
        },
        header: {
            padding: calcRem(24),
            borderBottom: `0.5px solid ${scheme.colors.borders.secondary}`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: scheme.colors.palette.white,
            zIndex: 1,
            width: '100%',

            closeIcon: {
                hoverStroke: scheme.colors.text.secondary,
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
            },

            breakpointState: {
                default: {
                    position: 'fixed',
                    top: 0,
                },
                sm: {
                    position: 'static',
                },
            },
        },
        badge: {
            position: 'absolute',
            height: calcRem(8),
            width: calcRem(8),
            backgroundColor:
                tokens.thenightly.colors.palette.utility.error.default,
            borderRadius: calcRem(50),
            right: 20,
            top: -4,
        },
    }

    return theme
}
