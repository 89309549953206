import { layout } from '../../App.routing'

export const brandContentSegment = [
    layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'gridGap',
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },

            cardLayout: [
                { type: 'hero', disableImageLazyLoad: false },
                'teaser-xsVisible-mdHidden-noKicker-Image',
                'teaser-xsVisible-mdHidden-noKicker-Image',
            ],
            dataDefinitionArgs: {
                type: 'curation',
                name: 'brand-partners',
                offset: 0,
                pageSize: 3,
            },
            sectionHeader: {
                heading: 'Brand Partners',
            },
        },
    }),

    layout.component({
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fixedRatios: ['16:9'],
            intermediateColumns: 2,
            finalColumns: 4,
            hasBackground: true,
            kickerMode: 'hidden',

            cardLayout: [
                ['teaserEdgeToEdge'],
                ['teaserEdgeToEdge'],
                ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
                ['teaserEdgeToEdge'],
            ],
            cardOrientation: { type: 'Landscape-Portrait' },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'brand-partners',
                offset: 3,
                pageSize: 5,
            },
        },
    }),
]
