import { DisplayProperty, PositionProperty } from 'csstype'
import {
    InteractionState,
    PerthNowTheme,
    PerthNowThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
    ThemeConfig,
    calcRem,
} from '../../__styling'
import { easing } from '../../__styling/settings/easing'
import { colors } from '../../__styling/settings/colors'
import { tokens } from '@news-mono/design-tokens'
const { neutral, uplate } = tokens.thenightly.colors.palette

export type ProfileEmailButtonTheme<T extends ThemeConfig> = {
    borderRadius: string
    border: string
    fontSize: string
    fontWeight: number
    fontFamily?: string
    lineHeight: string
    textDecoration: string
    display: DisplayProperty
    alignItems: string
    position: PositionProperty
    transition: string
    alignSelf?: string
    padding: string
    gap?: string

    interactionState: InteractionState<{
        background?: string
        textColor?: string
        borderColor?: string
        outline?: string
    }>
    icon: {
        width: string
        height: string
        margin: string
    }
}

export const theWestProfileEmailButtonTheme = (scheme: TheWestTheme) => {
    const { button } = scheme.colors.actions
    const theme: ProfileEmailButtonTheme<TheWestThemeConfig> = {
        borderRadius: calcRem(2),
        border: '0',
        fontSize: calcRem(12),
        fontWeight: 600,
        lineHeight: '1',
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        transition: `all ${easing.easeOut.fast}`,
        padding: calcRem(scheme.margins.xs, 6),
        fontFamily: scheme.fonts.sansSerif,

        interactionState: {
            default: {
                background: scheme.sectionValues.primaryColor,
                textColor: scheme.colors.palette.white,
            },
            hover: {
                background: button.primary.background.focus,
                textColor: scheme.colors.palette.white,
            },
            focus: {
                background: button.primary.background.focus,
                textColor: scheme.colors.palette.white,
            },
        },

        icon: {
            width: calcRem(12),
            height: calcRem(8),
            margin: calcRem(0, scheme.margins.xs, 0, 0),
        },
    }
    return theme
}

export const perthNowProfileEmailButtonTheme = (scheme: PerthNowTheme) => {
    const theme: ProfileEmailButtonTheme<PerthNowThemeConfig> = {
        borderRadius: calcRem(2),
        border: '0',
        fontSize: calcRem(12),
        fontWeight: 600,
        lineHeight: '1.25',
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        transition: `all ${easing.easeOut.fast}`,
        alignSelf: 'flex-start',
        padding: calcRem(2, 6),
        fontFamily: scheme.fonts.sansSerif,

        interactionState: {
            default: {
                background: colors.social.email,
                textColor: scheme.colors.palette.white,
            },
            hover: {
                borderColor: colors.social.email,
                background: scheme.colors.palette.white,
                textColor: colors.social.email,
            },
            focus: {
                background: scheme.colors.palette.white,
                textColor: scheme.colors.background.secondary,
                outline: 'none',
            },
            active: {
                background: scheme.colors.palette.white,
                textColor: scheme.colors.background.secondary,
                outline: 'none',
            },
        },

        icon: {
            width: calcRem(12),
            height: calcRem(11),
            margin: calcRem(0, scheme.margins.xs, 0, 0),
        },
    }

    return theme
}

export const theNightlyProfileEmailButtonTheme = (scheme: TheNightlyTheme) => {
    const theme: ProfileEmailButtonTheme<TheNightlyThemeConfig> = {
        borderRadius: calcRem(49),
        border: '0',
        fontSize: calcRem(16),
        fontWeight: 400,
        lineHeight: calcRem(24),
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        transition: `all ${easing.easeOut.fast}`,
        padding: calcRem(10, 12),
        gap: calcRem(scheme.margins.sm),

        interactionState: {
            default: {
                background: neutral[10],
                textColor: neutral[100],
                outline: 'none',
            },
            hover: {
                background: neutral[30],
                textColor: neutral[100],
                outline: 'none',
            },
            focus: {
                background: neutral[30],
                textColor: neutral[100],
                outline: 'none',
            },
            active: {
                background: neutral[30],
                textColor: neutral[100],
                outline: 'none',
            },
        },

        icon: {
            width: calcRem(24),
            height: calcRem(24),
            margin: calcRem(0),
        },
    }
    return theme
}
