import { Profile } from '@news-mono/web-common'
import React from 'react'
import { StyledProfileEmailButton } from '../Byline.styled'

interface BylineMailProps {
    profiles: Profile[] | undefined
    url: string | undefined
    title: string | undefined
}

export const BylineMail: React.FC<BylineMailProps> = ({
    profiles,
    url,
    title,
}) => {
    if (!profiles || profiles.length === 0) {
        return null
    }

    return (
        <React.Fragment>
            {profiles.map((profile) => {
                if (!profile.email) {
                    return null
                }

                return (
                    <StyledProfileEmailButton
                        key={profile.name}
                        text={profile.name}
                        email={profile.email}
                        url={url}
                        title={title}
                    />
                )
            })}
        </React.Fragment>
    )
}
