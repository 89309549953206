import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AdState,
    ComponentServices,
    DataLayerEventName,
    getAdState,
    getSophiContent,
    GptAdSlotDefinition,
    PageMeta,
    PageRendererEvent,
    PageRouteInformation,
    RouteInformation,
    RouteResolution,
    RouteServices,
    routingDebug,
} from '@news-mono/web-common'
import { PageMetaDTO } from '@west-australian-newspapers/publication-types'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import { getRouteInfoAdUnits } from '../advertising/get-route-ads'
import { GptAdProvider } from '../advertising/gpt/gpt-ad-provider'
import { Box } from '../compositions/Box/Box'
import { ContentWrapper } from '../compositions/ContentWrapper/ContentWrapper'
import CountdownInactivity from '../tools/CountdownInactivity'
import { Heading } from '../typography/Heading/Heading'
import { PageResolverProps } from '../__App/routing/page-resolver'

const getSmartOctoProperties = (
    resolution: RouteResolution,
    ampId?: string,
) => {
    if (
        resolution.type === 'server' &&
        resolution.resolution.type === 'publication' &&
        resolution.resolution.publication.kind === 'article'
    ) {
        const publication = resolution.resolution.publication
        const properties = {
            id: ampId,
            postid: publication.id.split('-')[1],
            title: publication.heading,
            pubdate: publication.publicationDate,
            authors: publication.byline
                ? publication.byline?.text.replace(' and ', ',')
                : publication.profiles.map((profile) => profile.name).join(','),
            sections: publication.topics.primary.id.replace('/', '>'),
            tags: publication.topics.secondary
                .map((topic) => topic.title)
                .join(','),
        }
        return properties
    }
}

export function renderRouteInfo<Sections extends string>(
    routeServices: RouteServices,
    routeInfo: RouteInformation<Sections> | undefined,
    props: PageResolverProps,
) {
    routingDebug('Rendering route info: ' + props.location.pathname)

    let adState: AdState | undefined
    let unitsArray: GptAdSlotDefinition[] | undefined
    const storeCurrentState = routeServices.store.getState()
    const toggles = storeCurrentState.toggles
    const renderTarget = storeCurrentState.render.renditionType

    if (routeInfo && routeInfo.kind === 'page') {
        const routeAds = getRouteInfoAdUnits(toggles, routeInfo.compositions)
        const pageAds = routeAds
            ? [...props.siteAds, ...routeAds]
            : props.siteAds
        ;({ unitsArray, adState } = getAdState(
            routeInfo.adTargeting,
            pageAds,
            props.location.pathname,
            renderTarget,
        ))
        const componentServices: ComponentServices = {
            ...routeServices,
            adState,
            onEvent: props.onEvent,
            layout: props.layout,
            location: props.location,
        }
        const renderers = props.layout.createRenderers({
            services: componentServices,
        })

        // Pipe any server resolved metadata straight through to the page meta
        const serverResolutionMeta: PageMetaDTO =
            (routeServices.resolution &&
                routeServices.resolution.type === 'server' &&
                routeServices.resolution.resolution.type !== 'preview' &&
                routeServices.resolution.resolution.type !== 'profile' &&
                routeServices.resolution.resolution.type !== 'editions' &&
                routeServices.resolution.resolution.meta) ||
            (routeServices.resolution.type === 'static' &&
                routeServices.resolution.resolution &&
                routeServices.resolution.resolution.type === 'topic' &&
                routeServices.resolution.resolution.meta) ||
            {}

        const allowInactiveRefresh =
            isFeatureEnabled(toFeatureState(toggles), 'refresh-on-inactive') &&
            routeInfo.refreshOnInactive

        const smartOctoProperties = getSmartOctoProperties(
            routeServices.resolution,
            routeServices.config.smartOctoAmpId,
        )

        return (
            <GptAdProvider
                renderKey={adState.pageKey}
                slots={unitsArray}
                gptApi={routeServices.gptApi}
                services={props.services}
            >
                {props.renderPage(
                    <>
                        <PageMeta
                            serverMeta={serverResolutionMeta}
                            routeMeta={routeInfo}
                            location={props.location}
                            services={props.services}
                        />
                        <PageProps
                            pageProperties={{
                                sophiContent: getSophiContent(routeServices),
                            }}
                        />
                        {renderDisplayHeading(routeInfo)}
                        {allowInactiveRefresh && (
                            // 5 Minute inactivity reload w/ instant tracking
                            <CountdownInactivity
                                timeToInactive={300}
                                timeRequiredInactive={300}
                                onEvent={(e) => countdownEventHandler(e, props)}
                            />
                        )}

                        {renderers.renderCompositions(
                            ...routeInfo.compositions,
                        )}
                    </>,
                    adState,
                    routeInfo.pageType,
                    routeInfo.section,
                    {
                        title: routeInfo.heading,
                        ...routeInfo.additionalPageProperties,
                        ...(renderTarget === 'amp' && { smartOctoProperties }),
                    },
                )}
            </GptAdProvider>
        )
    }

    // Required to utilize the section on loading routes so we dont get jank
    const sectionForNonPageRoute =
        (routeInfo && routeInfo.kind === 'loading' && routeInfo.section) ||
        'default'

    return (
        <GptAdProvider
            renderKey={adState ? adState.pageKey : null}
            slots={unitsArray || []}
            gptApi={routeServices.gptApi}
            services={props.services}
        >
            {props.renderPage(
                <Box verticalSpacing="md" hasTopSpacing horizontalSpacing="md">
                    <div style={{ minHeight: 1500 }} />
                </Box>,
                adState,
                'page',
                sectionForNonPageRoute,
                {},
            )}
        </GptAdProvider>
    )
}

/**
 * Provides a header if the heading is meant to be displayed on the route otherwise renders nothing
 * @param {PageRouteInformation} routeInfo the page route information object
 * @memberof PageRenderer
 */
export function renderDisplayHeading(routeInfo: PageRouteInformation<string>) {
    return !routeInfo.hideHeading && routeInfo.heading !== undefined ? (
        <Box verticalSpacing="md" hasTopSpacing horizontalSpacing="md">
            <Heading text={routeInfo.heading} />
        </Box>
    ) : null
}

export function countdownEventHandler(
    event: PageRendererEvent,
    props: PageResolverProps,
) {
    if (event.type === DataLayerEventName.countdownInactivityFinished) {
        if (props.refreshOverride) {
            props.refreshOverride()
        } else {
            // Reload has POST data in comparison to window.location.href = window.location.href
            // Reload maintains scroll position where href will scroll to top
            window.location.reload()
        }
        return
    }

    props.onEvent(event)
}
