import React from 'react'
import {
    StyledIconEmail,
    StyledNightlyIconEmail,
    StyledProfileEmailButton,
} from '../../buttons/ProfileEmailButton/ProfileEmailButton.styled'
import { Theme, useTheme } from '@emotion/react'
import { Product } from '@news-mono/web-common'

export interface ProfileEmailButtonProps {
    className?: string
    email: string
    text: string
    url?: string
    title?: string
}

const getBody = (text: string, url?: string, title?: string) => {
    if (!url || !title) {
        return ''
    }

    const bodyText = `Dear ${text},\n\nIn reference to:\n${title}\n${url}\n\nPlease type your message here...`

    return `&body=${encodeURIComponent(bodyText)}`
}

const getEmailIcon = (theme: Theme) => {
    switch (theme.kind) {
        case Product.TheNightly:
            return <StyledNightlyIconEmail />

        default:
            return <StyledIconEmail title="Email" />
    }
}
export const ProfileEmailButton: React.FC<ProfileEmailButtonProps> = ({
    className,
    email,
    text,
    url,
    title,
}) => {
    const theme = useTheme()

    if (!email) {
        return null
    }

    const body = getBody(text, url, title)
    const mailto = `mailto:${email}?subject=Website%20Enquiry%20for%20${text}${body}`

    return (
        <StyledProfileEmailButton className={className} to={mailto}>
            {getEmailIcon(theme)} {text}
        </StyledProfileEmailButton>
    )
}
ProfileEmailButton.displayName = 'ProfileEmailButton'
