import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { SSWTransaction } from '../ssw'
import { BreachScreenType } from './breach-event-types'
import { ComponentEvent } from './component-event'

export interface SubscribeEventProps {
    /* What paywall method was implemented ie freemium vs metered */
    paywallType: 'freemium'
    /* lead in depth - how many paragraphs were shown in the lead in */
    leadDepth: number
    /* creative name */
    creative: undefined
    breachScreenType: undefined
    popupMethod: undefined
    deal: undefined
    upcomingNotify?: number
    /* Subscription price point */
    pricepoint: number[]
}

export interface ViewSubscriptionItem {
    /** Index of the item in the list */
    index: number
    /** Deal Name: 190-year-promo, etc. */
    deal_name: string
    /** Package name: Everyday Digital, Weekend Papers + Everyday Digital, etc. */
    item_name: string
    /** Rate code */
    item_id: string
    component_path: string
    /** Site name */
    item_brand: string
    /** paywall method - freemium|metered */
    item_list_category: string
    /** Premium - Inline */
    item_list_category2: string
    /** 50-off-breach-discount-govan50, etc.  */
    item_list_id: string
    /** TW_Article_Breach_WestBlue_Design_PhotoPackages, etc. */
    item_list_name: string
    /**  weekly original price */
    price: number
    /** weekly promo price (if on sale) or 0 */
    promo_price: number
    /** total value saved from the sale or 0 */
    discount: number
    /** coupon used in the sale, or empty */
    coupon: string
    /** quantity of item in the cart */
    quantity: number
    /** currency type of transaction */
    currency: 'AUD'
}

type SubscribeCompleteEventProps = SubscribeEventProps & SSWTransaction

export interface SubscribeClickEvent
    extends ComponentEvent<SubscribeEventProps> {
    type: DataLayerEventName.subscribeClick
}

export interface SubscribeCompleteEvent
    extends ComponentEvent<SubscribeCompleteEventProps> {
    type: DataLayerEventName.subscribeComplete
}

export interface CartItem {
    /** Rate code */
    item_id: string
    /** package name: Everyday Digital, Weekend Papers + Everyday Digital, etc. */
    item_name: string
    /** Site name */
    item_brand: string
    /** weekly original price*/
    price: number
    /** Discounted price, or 0 */
    promo_price: number
    /** Amount off original price */
    discount: number
    /** offer code */
    coupon: string
    /** amount of items */
    quantity: number
    item_variant: 'native' | 'swg'
}

export interface CartEvent
    extends ComponentEvent<Record<string, unknown>> {
        payload: {
            /** currency to complete the transaction with */
            currency: 'AUD'
            /** total cart value */
            value: number
            /** all items being added */
            items: CartItem[]
            /** type of breach-screen, if provided */
            breachScreenType?: BreachScreenType
        },
}

// GA4 Cart Events
export interface AddToCartEvent extends CartEvent {
    type: DataLayerEventName.addToCart
}
export interface RemoveFromCartEvent extends CartEvent {
    type: DataLayerEventName.removeFromCart
}
export interface ViewCartEvent extends CartEvent {
    type: DataLayerEventName.viewCart
}

export interface SubscriptionPromptCheckoutEvent extends CartEvent {
    type: DataLayerEventName.subscriptionPromptCheckout
}

// GA4 view_item_list event - subscription package items
export interface ViewSubscriptionItemListEvent
    extends ComponentEvent<Record<string, unknown> | any[]> {
    type: DataLayerEventName.viewSubscriptionItemListEvent
    parentType: DataLayerEventName.viewItemListEvent
    payload: {
        items: ViewSubscriptionItem[]
        breachScreenType?: BreachScreenType
    }
}

interface Transaction {
    sku: string
    name: string
    price: number
    quantity: 1
}

interface SubscribeBaseGTMProps
    extends Omit<SubscribeEventProps, 'pricepoint'> {
    transactionAffiliation: 'The West'
    pricepoint: string
}

interface SubscribeCompleteGTMProps
    extends SubscribeBaseGTMProps,
        Omit<SSWTransaction, 'productSku' | 'productName'> {
    transactionProducts: Transaction[]
    transactionShipping: 0
    transactionAffiliation: 'The West'
    pricepoint: string
}

export type SubscribeEvent = SubscribeClickEvent | SubscribeCompleteEvent

export function toSubscribeBaseEventShape(
    props: SubscribeEventProps,
): SubscribeBaseGTMProps {
    return {
        ...props,
        transactionAffiliation: 'The West',
        pricepoint: props.pricepoint.join(','),
    }
}

export function toSubscribeCompleteEventShape(
    props: SubscribeCompleteEventProps,
): SubscribeCompleteGTMProps {
    const {
        transactionId,
        transactionTotal,
        transactionTax,
        productSku,
        productName,
        pricepoint,
        ...subscribeProps
    } = props

    return {
        ...subscribeProps,
        transactionId,
        pricepoint: pricepoint.join(','),
        transactionAffiliation: 'The West',
        transactionTotal,
        transactionTax,
        transactionShipping: 0,
        transactionProducts: [
            {
                sku: productSku,
                name: productName,
                price: transactionTotal,
                quantity: 1,
            },
        ],
    }
}
