import { FlexDirectionProperty } from 'csstype'
import {
    BreakpointState,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    ThemeConfig,
    calcRem,
} from '../../__styling'

export type MarsTheme<T extends ThemeConfig> = {
    border: string

    breakpointState: BreakpointState<
        T,
        {
            padding: string
            gap: string
        }
    >
    header: {
        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                width?: string
                letterSpacing: string
                padding?: number
                lineHeight?: string
            }
        >
    }
    slotContainer: {
        flexDirection: BreakpointState<T, FlexDirectionProperty>
    }
    slot: {
        width: BreakpointState<T, string>
    }
}

export const theNightlyMarsTheme = (scheme: TheNightlyTheme) => {
    const theme: MarsTheme<TheNightlyThemeConfig> = {
        border: `0.5px solid ${scheme.colors.borders.secondary}`,

        breakpointState: {
            default: {
                padding: calcRem(0, 24, 40),
                gap: calcRem(24),
            },
            sm: {
                padding: calcRem(0, 32, 64),
                gap: calcRem(32),
            },
            lg: {
                padding: calcRem(0, 0, 80),
                gap: calcRem(48),
            },
        },
        header: {
            breakpointState: {
                default: {
                    fontSize: calcRem(40),
                    width: '70%',
                    letterSpacing: '-1.6px',
                    lineHeight: calcRem(48),
                },
                sm: {
                    fontSize: calcRem(45),
                    width: '100%',
                    letterSpacing: '-1.8px',
                },
                lg: {
                    padding: 0,
                    fontSize: calcRem(51),
                    lineHeight: calcRem(56),
                    letterSpacing: '-2.04px',
                },
            },
        },
        slotContainer: {
            flexDirection: {
                default: 'column',
                sm: 'row',
            },
        },
        slot: {
            width: {
                default: '100%',
                sm: '50%',
            },
        },
    }
    return theme
}
