import { PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { FC } from 'react'
import { ThemedRender } from '../../../__styling/themed-render'
import { StyledListCardKicker, StyledTopicLabel } from '../ListCard.styled'
import { NightlyTopicTag } from './NightlyTopicTag'

interface ListCardTopicTagProps {
    item: MaybeLoaded<PublicationCardItem>
    level?: 'parent'
}

export const ListCardTopicTag: FC<ListCardTopicTagProps> = (props) => {
    const { item, level } = props

    if (!item || !item.loaded) {
        return null
    }

    const result = item.result
    const topic = result.primaryTopic
    const text = result.primaryTopic.title

    return (
        <ThemedRender
            perthnow={() => <StyledTopicLabel topic={topic} text={text} />}
            sevennews={() => (
                <StyledListCardKicker>{result.kicker}</StyledListCardKicker>
            )}
            thenightly={() => <NightlyTopicTag data={result} level={level} />}
        />
    )
}
ListCardTopicTag.displayName = 'ListCardTopicTag'
