import { cx } from '@emotion/css'
import React from 'react'
import { useProduct } from '../../__product/useProduct'
import { FigCaptionProps } from './FigCaption'
import {
    Caption,
    StyledFigCaption,
    StyledFigCaptionText,
} from './FigCaption.styled'
import { getTypeAndIcon } from './util'

export const FigCaptionWeb: React.FC<FigCaptionProps> = ({
    byline,
    captionText,
    className,
    credit,
    kind,
    showMediaIcon = true,
    figcaptionLayout,
    separatedFromMedia,
}) => {
    const product = useProduct()
    const { mediaType, mediaIcon } = getTypeAndIcon(
        showMediaIcon,
        product,
        kind,
        captionText,
    )

    // If there is a caption, add a space before the text "Credit" otherwise no space!
    const mediaLabel = `${captionText ? ' ' : ''}Credit: `
    const displayMediaType = kind === 'hero-image' && captionText

    return (
        <StyledFigCaption
            className={cx(className, 'figcaption')}
            figcaptionLayout={figcaptionLayout}
            as={separatedFromMedia ? 'p' : 'figcaption'}
            id={separatedFromMedia ? 'media-caption' : undefined}
        >
            {/* dont show the icon when the feature-article-position is set */}
            {figcaptionLayout !== 'feature-article-position' && mediaIcon}

            <StyledFigCaptionText>
                {/* For hero image kind we want the text "Main Image:" before the caption and the credit.  */}
                {displayMediaType ? mediaType : undefined}
                {captionText && <Caption>{captionText}</Caption>}

                {(credit || byline) && (
                    <span>
                        {kind !== 'hero-image' ? mediaType : mediaLabel}

                        {byline && byline}

                        {credit && byline && <span>&#47;</span>}

                        {credit && credit}
                    </span>
                )}
            </StyledFigCaptionText>
        </StyledFigCaption>
    )
}
