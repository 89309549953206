import React from 'react'

export interface IconRedditProps {
    title?: string
    className?: string
}

export const IconReddit: React.FC<IconRedditProps> = ({ className, title }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 17"
        className={className}
    >
        <title>{title || 'Share to Reddit'}</title>
        <path d="M19.42 8.9c0 1.03-.52 1.96-1.32 2.46-.3 3.05-3.92 5.47-8.34 5.47-4.4 0-8-2.38-8.34-5.42C.57 10.9 0 10 0 8.9c0-1.67 1.27-2.97 2.84-3 .56 0 1.1.2 1.54.5l-.08.1c1.32-.82 3-1.32 4.85-1.42L10.6.8l3.2.8C14.17.96 14.85.56 15.65.56c1.22 0 2.22.98 2.22 2.2 0 1.22-1 2.2-2.22 2.2-1.22 0-2.2-.98-2.2-2.2v-.22L11.25 2 10.2 5.1c1.86.06 3.56.55 4.9 1.33.44-.27.95-.43 1.5-.43 1.56 0 2.84 1.3 2.84 2.94zm-3.76-7.4h-.07c-.7 0-1.2.56-1.2 1.24 0 .67.5 1.23 1.2 1.23s1.3-.55 1.3-1.23c0-.68-.58-1.23-1.26-1.23zM2.84 6.96V6.9c-1.04 0-1.87.9-1.87 2 0 .5.2.9.5 1.3.2-1.2.93-2.26 2-3.12-.2-.08-.4-.13-.63-.13zm10.9 3c0 .73-.57 1.3-1.3 1.3-.72 0-1.3-.57-1.3-1.3 0-.72.58-1.3 1.3-1.3.73 0 1.3.58 1.3 1.3zm-3.98 5.9c4.07 0 7.4-2.2 7.4-4.9s-3.33-4.9-7.4-4.9-7.4 2.2-7.4 4.9 3.33 4.9 7.4 4.9zm8.27-5.76V10c.25-.3.4-.7.4-1.1 0-1.07-.83-1.95-1.85-1.95-.2 0-.38.04-.55.1 1.04.84 1.76 1.9 2 3.04zm-9.9-.14c0 .72-.6 1.3-1.3 1.3-.73 0-1.3-.58-1.3-1.3 0-.73.57-1.3 1.3-1.3.7 0 1.3.57 1.3 1.3zm4.1 2.9c-2.77 1.84-5 .1-5.1.03-.22-.2-.53-.2-.7 0-.16.2-.13.5.08.7.1 0 1.3 1 3.2 1 .9 0 2-.3 3.1-1 .2-.2.3-.5.2-.7-.1-.2-.4-.3-.6-.2l-.15.14z" />
    </svg>
)
