import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface GridProps {
    hasBackgroundFill?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    numberOfColumns: number
}

export const StyledCerberus = styled('div')<GridProps>(
    ({ theme, numberOfColumns, hasBackgroundFill, verticalSpacing }) => ({
        margin: '0 auto',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: calcRem(theme.margins.gridGap),
        alignItems: 'stretch',
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        backgroundColor: hasBackgroundFill ? colors.white : undefined,

        [breakpoint('sm')]: {
            gridTemplateColumns:
                numberOfColumns === 4
                    ? '1fr 1fr'
                    : `repeat(${numberOfColumns}, minmax(0, 1fr))`,
        },

        [breakpoint('md')]: {
            gridTemplateColumns: `repeat(${numberOfColumns},minmax(0, 1fr))`,
        },
    }),
)

export const StyledHeader = styled('div')({
    display: 'block',
    marginBottom: calcRem(metrics.perthnow.margins.lg),
})

export const StyledGridItem = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
})
