import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
    calcRem,
    colors,
    fonts,
    themedValue,
} from '../../__styling'

import { StyledKicker } from '../../cards/Kicker/Kicker.styled'
import { StyledArticleVideoContainer } from '../../publication/ArticleVideo/ArticleVideo.styled'

interface StyledVideoPlayerWrapperProps {
    isSevenNewsV2?: boolean
}

export const StyledVideoPlayerWrapper = styled(
    'div',
)<StyledVideoPlayerWrapperProps>(({ theme, isSevenNewsV2 }) => ({
    width: '100%',
    height: '100%',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    display: 'block',
    //stops video from filling the whole container on load
    maxWidth: themedValue(theme, {
        fallback: '810px',
        sevennews: '100%',
    }),
    maxHeight: themedValue(theme, {
        sevennews: 'fit-content',
        fallback: '720px',
    }),
    borderRadius: theme.radius?.lg,
    overflow: theme.radius ? 'hidden' : undefined,
    [breakpoint('md')]: {
        maxWidth: themedValue(theme, {
            fallback: '810px',
            sevennews: isSevenNewsV2 ? '70%' : '60%',
        }),
        flex: themedValue(theme, {
            fallback: '2',
            perthnow: undefined,
        }),
    },

    [breakpointBetween('sm', 'md')]: {
        maxWidth: isSevenNewsV2 ? '80%' : undefined,
    },
    '& [class*="StyledSkeleton"]': {
        height: calcRem(400),
        backgroundColor: '#000',
        backgroundImage: 'linear-gradient(90deg, #000, #333, #000)',
        [breakpoint('xxs')]: {
            height: calcRem(470),
        },
        [breakpoint('xs')]: {
            height: calcRem(550),
        },
        [breakpoint('sm')]: {
            height: calcRem(670),
        },
        [breakpoint('md')]: {
            height: calcRem(579),
        },
        [breakpoint('lg')]: {
            height: calcRem(598),
        },
        [breakpoint('xl')]: {
            height: calcRem(650),
        },
    },
}))

export const StyledVideoPlayer = styled('figure')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    pointerEvents: 'auto',
    position: 'relative',
    padding: theme.kind === Product.PerthNow ? theme.margins.md : undefined,
    paddingTop: theme.kind === Product.PerthNow ? 0 : undefined,
    backgroundColor: theme.kind === Product.PerthNow ? 'white' : undefined,
    marginBottom: theme.kind === Product.PerthNow ? calcRem(16) : undefined,
    overflow: theme.kind === Product.PerthNow ? 'hidden' : undefined,
    borderRadius: theme.radius?.lg,
    borderBottom: themedValue(theme, {
        thewest: `${theme.colors.components?.videoHub?.borderBottom} 4px double`,
        perthnow: `${theme.colors.components?.videoHub?.borderBottom} 1px solid`,
        sevennews: undefined,
        fallback: undefined,
    }),
    [breakpoint('md')]: {
        padding: 0,
        paddingBottom: themedValue(theme, {
            thewest: calcRem(7),
            perthnow: calcRem(16),
            fallback: undefined,
        }),
    },

    '& [class*="StyledBrightcoveVideo"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 'auto',
        height: 'auto',
    },

    [`[class*="video-specificity"]`]: {
        width: '100%',
        height: 'auto',
        flex: '2',
        [breakpoint('md')]:
            theme.kind === Product.PerthNow
                ? undefined
                : {
                      flex: '2',
                  },
    },

    [`${StyledArticleVideoContainer}`]: {
        backgroundColor: 'transparent',
        marginBottom: 0,
    },
}))

export const StyledVideoPlayerTextContainer = styled('div')(({ theme }) => ({
    marginTop: calcRem(16),
    marginBottom: calcRem(16),
    paddingLeft: theme.kind === Product.PerthNow ? calcRem(8) : undefined,
    paddingRight: theme.kind === Product.PerthNow ? calcRem(8) : undefined,

    [breakpoint('sm')]: {
        marginTop: theme.kind === 'thewest' ? theme.margins.md : calcRem(8),
        marginBottom: theme.kind === 'thewest' ? calcRem(5) : 0,
    },
    [`${StyledKicker}`]: {
        color:
            theme.kind === 'sevennews'
                ? theme.sectionValues.primaryColor
                : undefined,
        fontSize: theme.kind === 'sevennews' ? calcRem(14) : undefined,
    },
}))

export const StyledVideoPlayerHeadline = styled('h1')(({ theme }) => ({
    fontSize: theme.kind === 'thewest' ? calcRem(18) : calcRem(21),
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.serif,
        perthnow: theme.fonts.sansSerifCond,
        sevennews: undefined,
        fallback: undefined,
    }),
    lineHeight: theme.kind === 'thewest' ? calcRem(22) : '25px',
    marginTop: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    fontWeight: theme.kind === 'thewest' ? 500 : undefined,
    '-webkit-line-clamp': '2',
    [breakpoint(theme.kind === Product.PerthNow ? 'xs' : 'sm')]: {
        fontSize: themedValue(theme, {
            thewest: calcRem(32),
            perthnow: calcRem(30),
            sevennews: calcRem(24),
            fallback: undefined,
        }),
        lineHeight: themedValue(theme, {
            thewest: calcRem(38),
            perthnow: calcRem(36),
            sevennews: calcRem(30),
            fallback: undefined,
        }),
        marginBottom: calcRem(8),
        fontWeight: theme.kind === 'thewest' ? 400 : undefined,
    },
    [breakpoint('md')]: {
        fontSize: themedValue(theme, {
            thewest: undefined,
            perthnow: calcRem(30),
            sevennews: calcRem(27),
            fallback: undefined,
        }),
        lineHeight: themedValue(theme, {
            thewest: undefined,
            perthnow: undefined,
            sevennews: calcRem(32),
            fallback: undefined,
        }),
    },
}))

export const StyledVideoPlayerDescription = styled('div')(({ theme }) => ({
    fontFamily: themedValue(theme, {
        thewest: undefined,
        perthnow: theme.fonts.sansSerif,
        sevennews: fonts.sevennews.sansSerif,
        fallback: undefined,
    }),
    color:
        theme.kind === Product.PerthNow ? colors.perthnow.greyCoal : undefined,
    fontSize: theme.kind === 'thewest' ? calcRem(12) : calcRem(16),
    marginBottom: calcRem(16),
    minHeight: calcRem(20),
    fontWeight: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    opacity: theme.kind === 'thewest' ? '80%' : undefined,
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    [breakpoint('sm')]: {
        fontSize: theme.kind === 'thewest' ? calcRem(12) : calcRem(18),
        marginBottom: calcRem(8),
        minHeight: calcRem(45),
    },
    [breakpoint('md')]: {
        fontSize: themedValue(theme, {
            thewest: undefined,
            perthnow: calcRem(14),
            sevennews: calcRem(20),
            fallback: undefined,
        }),
        lineHeight: themedValue(theme, {
            thewest: calcRem(18),
            perthnow: calcRem(16),
            sevennews: calcRem(25),
            fallback: undefined,
        }),
    },
    [breakpointMax('xxs')]: {
        fontSize: theme.kind === 'thewest' ? '0.9rem' : undefined,
    },
}))

export const StyledVideoPlayerDescriptionMoreLess = styled('div')(
    ({ theme }) => ({
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            fallback: undefined,
        }),

        marginBottom: calcRem(16),
        minHeight: calcRem(20),
        fontWeight: 300,
        overflow: 'hidden',

        [breakpoint('sm')]: {
            fontSize: theme.kind === 'thewest' ? calcRem(12) : calcRem(18),
            marginBottom: calcRem(8),
            minHeight: calcRem(45),
        },
        [breakpoint('md')]: {
            marginBottom: themedValue(theme, {
                sevennews: calcRem(70),
                fallback: undefined,
            }),
            fontSize: themedValue(theme, {
                sevennews: calcRem(20),
                fallback: undefined,
            }),
            lineHeight: themedValue(theme, {
                sevennews: calcRem(25),
                fallback: undefined,
            }),
        },
    }),
)
