/**
 * Add darkness or brightness to a given hex color
 * `filterColor('#066cb6', 20) -> '#0058a2'`  (20% darker)
 * `filterColor('#066cb6', -20) -> '#1a80ca'`  (20% brighter)
 */
export const filterColor = (
    hexColor: string | undefined,
    darkenPercent: number,
): string => {
    if (!hexColor) return '#000'
    const hexValue = hexColor.slice(1)
    const R = parseInt(hexValue.substring(0, 2), 16)
    const G = parseInt(hexValue.substring(2, 4), 16)
    const B = parseInt(hexValue.substring(4, 6), 16)

    // return original value if it fails to parse
    if (isNaN(R) || isNaN(G) || isNaN(B)) return hexColor

    const RR = calculateRGB(R, darkenPercent)
    const GG = calculateRGB(G, darkenPercent)
    const BB = calculateRGB(B, darkenPercent)

    return '#' + RR + GG + BB
}

function calculateRGB(RGB: number, darkenPercent: number): string {
    RGB -= darkenPercent
    if (RGB > 255) RGB = 255
    else if (RGB < 0) RGB = 0

    return RGB.toString(16).length == 1
        ? '0' + RGB.toString(16)
        : RGB.toString(16)
}
