import { showCookieSettingsDialog } from '@news-mono/web-common'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ButtonLink } from '../../buttons/ButtonLink/ButtonLink'
import { TextLink } from '../../typography/TextLink/TextLink'
import {
    StyledConsentFallback,
    StyledConsentHeader,
} from './ConsentFallback.styled'

export const ConsentFallback: React.FC = () => {
    const dispatch = useDispatch()

    function cookieSettingClickHandler() {
        dispatch(showCookieSettingsDialog())
    }

    return (
        <StyledConsentFallback>
            <StyledConsentHeader>
                Your cookie settings are preventing this third party content
                from displaying.
            </StyledConsentHeader>
            <p>
                If you’d like to view this content, please adjust your{' '}
                <ButtonLink type="button" onClick={cookieSettingClickHandler}>
                    Cookie Settings
                </ButtonLink>
                .
            </p>
            <p>
                To find out more about how we use cookies, please see our{' '}
                <TextLink
                    href="https://www.sevenwestmedia.com.au/cookies-guide"
                    openInNewWindow={true}
                >
                    Cookie Guide
                </TextLink>
                .
            </p>
        </StyledConsentFallback>
    )
}

ConsentFallback.displayName = 'ConsentFallback'
