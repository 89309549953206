import React from 'react'

export interface PerthNowIconProps {
    className?: string
}

export const PerthNowIcon: React.FC<PerthNowIconProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 9"
        className={className}
    >
        <title>Perth Now</title>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M0 1h8v6H0z" />
            <path
                fill="#D42626"
                fillRule="nonzero"
                d="M1.189.041A1.55 1.55 0 00.04 1.201c-.053.262-.053 5.353 0 5.615.12.566.596 1.041 1.162 1.162.262.053 5.353.053 5.615 0a1.545 1.545 0 001.162-1.162c.053-.262.053-5.353 0-5.615A1.545 1.545 0 006.817.039C6.566-.015 1.434-.012 1.189.04zM2.842 2.42c.59.125 1.098.614 1.234 1.194.051.214.051.523 0 .734-.07.294-.192.508-.427.743-.243.246-.44.363-.75.44-.42.108-.789.054-1.246-.18-.013-.006-.02.242-.02.552v.564H.883v-1.33c0-1.24.003-1.347.054-1.54A1.615 1.615 0 012.842 2.42zm3.63.04a1 1 0 01.484.42c.139.258.152.381.152 1.522v1.05h-.745l-.01-.97c-.012-1.068-.017-1.106-.18-1.277-.222-.235-.684-.187-.884.091-.147.203-.158.288-.158 1.266v.89h-.748V2.459h.721v.465l.075-.12c.075-.115.222-.249.345-.316.232-.12.668-.136.948-.03z"
            />
            <path
                fill="#D42626"
                fillRule="nonzero"
                d="M2.359 3.132a.884.884 0 00-.636.454c-.07.128-.078.166-.078.395 0 .225.008.27.073.388a.876.876 0 001.367.25c.203-.183.27-.344.27-.65 0-.21-.01-.26-.072-.375a.882.882 0 00-.924-.462z"
            />
        </g>
    </svg>
)
