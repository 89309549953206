import React from 'react'

export interface IconPersonSilhouetteProps {
    className?: string
}

export const IconPersonSilhouette: React.FC<IconPersonSilhouetteProps> = ({
    className,
}) => (
    <svg
        width="34"
        height="36"
        viewBox="0 0 34 36"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
    >
        <path
            d="M34 17.9998C34 22.5084 32.2089 26.8325 29.0208 30.0206C25.8327 33.2087 21.5087 34.9998 17 34.9998C12.4913 34.9998 8.1673 33.2087 4.97919 30.0206C1.79107 26.8325 0 22.5084 0 17.9998C0 13.4911 1.79107 9.16706 4.97919 5.97894C8.1673 2.79082 12.4913 0.999756 17 0.999756C21.5087 0.999756 25.8327 2.79082 29.0208 5.97894C32.2089 9.16706 34 13.4911 34 17.9998Z"
            fill="url(#pattern0)"
        />
        <path
            d="M34 17.9998C34 22.5084 32.2089 26.8325 29.0208 30.0206C25.8327 33.2087 21.5087 34.9998 17 34.9998C12.4913 34.9998 8.1673 33.2087 4.97919 30.0206C1.79107 26.8325 0 22.5084 0 17.9998C0 13.4911 1.79107 9.16706 4.97919 5.97894C8.1673 2.79082 12.4913 0.999756 17 0.999756C21.5087 0.999756 25.8327 2.79082 29.0208 5.97894C32.2089 9.16706 34 13.4911 34 17.9998Z"
            fill="url(#pattern1)"
        />
        <path
            d="M34 17.9998C34 22.5084 32.2089 26.8325 29.0208 30.0206C25.8327 33.2087 21.5087 34.9998 17 34.9998C12.4913 34.9998 8.1673 33.2087 4.97919 30.0206C1.79107 26.8325 0 22.5084 0 17.9998C0 13.4911 1.79107 9.16706 4.97919 5.97894C8.1673 2.79082 12.4913 0.999756 17 0.999756C21.5087 0.999756 25.8327 2.79082 29.0208 5.97894C32.2089 9.16706 34 13.4911 34 17.9998Z"
            fill="#E6001E"
        />
        <g clip-path="url(#clip0_17457_225564)">
            <path
                d="M18.8445 27L25.2681 16.0262L21.6462 10L11.6948 27H18.8445Z"
                fill="white"
            />
            <path
                d="M9 10L12.5489 16.0262H17.6411L21.1605 10H9Z"
                fill="white"
            />
        </g>
        <defs>
            <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
            >
                <use
                    xlinkHref="#image0_17457_225564"
                    transform="scale(0.0025)"
                />
            </pattern>
            <pattern
                id="pattern1"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
            >
                <use
                    xlinkHref="#image1_17457_225564"
                    transform="scale(0.0025)"
                />
            </pattern>
            <clipPath id="clip0_17457_225564">
                <rect
                    width="16.2683"
                    height="17"
                    fill="white"
                    transform="translate(9 10)"
                />
            </clipPath>
        </defs>
    </svg>
)
IconPersonSilhouette.displayName = 'IconPersonSilhouette'
