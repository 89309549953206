import React from 'react'

const SevenNewsInlineRelatedContextTextCardArrow = () => (
    <svg height="10" width="17" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 1 15 5M15 5 11 9M15 5H1"
            stroke="#C0A9F6"
            strokeLinecap="round"
        />
    </svg>
)
export default SevenNewsInlineRelatedContextTextCardArrow
