import { useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    ComponentServices,
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../content/Picture/ResponsivePictureLayouts'
import { VideoQueue } from '../../../templates/Publication/lib/get-video-queue'
import { CardByline } from '../../CardByline/CardByline'
import { CommonCardProps } from '../../CardItem.Props'
import { StyledLink } from '../../CardText/CardTextInteractions.styled'
import {
    returnEntitledStatus,
    returnFundingTypeIfItemLoaded,
    returnImageWidthsFromContainerRatios,
    returnVideoQueue,
} from '../utils'
import {
    StyledContentWrap,
    StyledHeroSupportCard,
    StyledHeroSupportCardContent,
    StyledHeroSupportCardMedia,
} from './HeroSupportCard.styled'

export interface HeroSupportCardProps
    extends CommonCardProps,
        ResponsiveContainer {
    item: MaybeLoaded<PublicationCardItem>
    cardNumber: number
    cardServices?: ComponentServices
    adUnitPath: string | undefined
    mediaPosition?: 'left' | 'right'
    isLarge?: boolean

    overrideRestrictedVideoPlayback?: boolean
}

export function HeroSupportCard(props: HeroSupportCardProps) {
    const {
        item,
        cardNumber,
        onEvent,
        cardServices,
        adUnitPath,
        containerWidthRatios,
        overrideRestrictedVideoPlayback,
    } = props

    const theme = useTheme()

    const storyClassification = true

    const fundingType = returnFundingTypeIfItemLoaded({
        item,
        storyClassification,
    })

    const imageWidths = returnImageWidthsFromContainerRatios({
        theme,
        containerWidthRatios,
    })

    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )

    const isEntitled = returnEntitledStatus({
        overrideRestrictedVideoPlayback,
        authentication,
    })

    function linkClicked(e: React.MouseEvent<HTMLElement>) {
        if (!item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }
        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'InternalPortrait',
                'Portrait',
                cardNumber,
                fundingType,
                undefined,
            ),
        )
    }

    let getVideoQueue: Promise<VideoQueue>

    if (cardServices) {
        getVideoQueue = returnVideoQueue({ item, cardServices })
    }

    return (
        <StyledLink
            to={toLinkWithHint(item)}
            onClick={linkClicked}
            isLoading={!item.loaded}
        >
            <StyledHeroSupportCard>
                <StyledHeroSupportCardMedia
                    item={item}
                    fixedRatio={{ default: '16:9', sm: '4:3' }}
                    hasBackground={false}
                    imageWidths={imageWidths}
                    onEvent={onEvent}
                    mediaMode={'default'}
                    disableImageLazyLoad={true}
                    imageLayout={ResponsivePictureLayout.ObjectFitContain}
                    willPlayVideoInline={isEntitled}
                    getVideoQueue={() => getVideoQueue}
                    adUnitPath={adUnitPath}
                />
                <StyledContentWrap>
                    <StyledHeroSupportCardContent
                        item={item}
                        hasBackground={false}
                        kickerMode={{ size: 'large' }}
                        teaserMode={'visible'}
                        fontScale={1.75}
                        onEvent={onEvent}
                    />
                    <CardByline
                        item={item}
                        onEvent={onEvent}
                        showProfile={false}
                        isEdgeToEdgeCard={false}
                    />
                </StyledContentWrap>
            </StyledHeroSupportCard>
        </StyledLink>
    )
}

HeroSupportCard.displayName = 'HeroSupportCard'
