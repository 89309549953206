import { cx } from '@emotion/css'
import React from 'react'

export interface LogoSevenMarkProps {
    className?: any
}

export const LogoSevenMark: React.FC<LogoSevenMarkProps> = ({ className }) => {
    return (
        <svg
            className={cx(className, 'seven-news-mark')}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17"
        >
            <title>Seven News</title>
            <path
                fill="#DA3036"
                fillRule="evenodd"
                d="M12.57 0l3.58 6.04L9.92 17h-7.2l9.85-17zm-.48 0L8.6 6H3.5L0 0h12.1z"
            />
        </svg>
    )
}
