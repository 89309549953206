import { addUrlParam, insertPathIntoUrl } from '../helpers/utils'
import { imagePolicy } from './image'

export function scaleImage(
    url: string,
    width: number,
    overrideExisting = true,
): string {
    const prefix = url.substr(0, 5)
    if (prefix === 'data:' || prefix === 'blob:') {
        return url
    }
    // SnappyTV images can't be scaled and might behave weirdly if we add a query string
    if (url.indexOf('snappytv') !== -1) {
        return url
    }
    // oovvuu images use their own cdn which has it's own rules about how images are managed:
    // https://docs.google.com/document/d/13Co9_zE0q-A3YrVLR3MX3Qdk4oX5n7mzOXVJEhWx8qI/edit# (see last page)
    // expects a url structure like: https://cdn-images.oovvuu.com/BBCN0700.jpg
    if (url.indexOf('oovvuu') !== -1 && url.indexOf('x0') === -1) {
        return insertPathIntoUrl(url, 1, `${width}x0`)
    }
    if (process.env.NODE_ENV === 'vr_test') {
        // We do mobile emulation, and VR tests will fail when serving JP2 for safari.
        // This forces akamai to return images supported by chrome
        url = addUrlParam(url, 'imformat', 'chrome')
    }
    // Standard AKAMAI logic here
    const urlWithWidth = addUrlParam(
        url,
        'imwidth',
        width.toString(),
        overrideExisting,
    )
    if (!imagePolicy) {
        return urlWithWidth
    }
    return addUrlParam(urlWithWidth, 'impolicy', imagePolicy)
}
