import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getLiveSportRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        layout.component({
            type: 'hera',
            props: {
                hasBorder: true,
                verticalSpacing: 'xl',
                sectionHeader: {
                    heading: 'Live & Upcoming',
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'live-sports-hub',
                    offset: 0,
                    pageSize: 8,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Tennis Replays',
                    headingUrl: '/sport/tennis/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/tennis'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'PSA Footy Replays',
                    headingUrl: '/sport/psa-footy/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/psa-footy'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'WAFL Replays',
                    headingUrl: '/sport/wafl/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/wafl'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'WAFLW Replays',
                    headingUrl: '/sport/waflw/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/waflw'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'WAFL Colts Replays',
                    headingUrl: '/sport/wafl-colts/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/wafl-colts'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('three'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'SWFL Replays',
                    headingUrl: '/sport/swfl/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/swfl'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Hockey Replays',
                    headingUrl: '/sport/field-hockey/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/field-hockey'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('four'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Basketball Replays',
                    headingUrl: '/sport/basketball/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/basketball'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Volleyball Replays',
                    headingUrl: '/sport/volleyball/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/volleyball'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Netball Replays',
                    headingUrl: '/sport/netball/replays',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/netball'],
                    classification: { kind: 'editorial', label: 'replay' },
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
