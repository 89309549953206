import {
    Intention,
    ListBlock,
    SubheadBlock,
    TextBlock,
} from '../../../client-data-types/blocks'

export const createHeading = (text: string): SubheadBlock => ({
    kind: 'subhead',
    text,
    intentions: [],
})

export const createParagraph = (
    text: string,
    intentions?: Intention[],
): TextBlock => ({
    kind: 'text',
    text,
    intentions: intentions || [],
})

export const createLinkText = (text: string, href: string): TextBlock => ({
    text,
    kind: 'text',
    intentions: [{ index: 0, length: text.length, href, kind: 'link' }],
})

export const createList = (
    items: TextBlock[],
    kind: 'unordered-list' | 'ordered-list',
): ListBlock => ({
    items: items.map((item) => ({ kind: 'list-item', blocks: [item] })),
    kind,
})
