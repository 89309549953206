import { Theme } from '@emotion/react'
import {
    AuthenticationState,
    ComponentServices,
    entitledToAll,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    applyContainerRatio,
    ContainerWidthRatios,
} from '../../../content/Picture/responsive'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import {
    getVideoQueue,
    VideoQueue,
} from '../../../templates/Publication/lib/get-video-queue'
import { shouldExcludeVideoSeries } from '../../../templates/Publication/lib/should-include-video-series'

interface BaseUtilsProps {
    item: MaybeLoaded<PublicationCardItem>
}

interface ReturnFundingTypeProps extends BaseUtilsProps {
    storyClassification: boolean
}

interface ReturnVideoQueueProps extends BaseUtilsProps {
    cardServices: ComponentServices
}

interface ReturnEntitledStatusProps {
    overrideRestrictedVideoPlayback: boolean | undefined
    authentication: AuthenticationState
}

interface ReturnImageWidthsFromContainerRatiosProps {
    theme: Theme
    containerWidthRatios: ContainerWidthRatios | undefined
}

export function returnFundingTypeIfItemLoaded({
    item,
    storyClassification,
}: ReturnFundingTypeProps) {
    return item.loaded
        ? getFundingType(item.result, storyClassification)
        : undefined
}

export function returnVideoQueue({
    item,
    cardServices,
}: ReturnVideoQueueProps) {
    if (!item.loaded) {
        return Promise.resolve<VideoQueue>({
            type: 'not-playlist-video',
            items: [],
        })
    }

    if (item.loaded && item.result.id && cardServices) {
        const toggles = cardServices.store.getState().toggles

        // There should be a return here too, otherwise it will always fall through to the empty Promise.resolve below.
        return getVideoQueue(
            cardServices,
            item.result.id,
            shouldExcludeVideoSeries(item.result, toggles),
        )
    }

    return Promise.resolve<VideoQueue>({
        type: 'not-playlist-video',
        items: [],
    })
}

export function returnEntitledStatus({
    overrideRestrictedVideoPlayback,
    authentication,
}: ReturnEntitledStatusProps) {
    return overrideRestrictedVideoPlayback
        ? overrideRestrictedVideoPlayback
        : entitledToAll(authentication)
}

export function returnImageWidthsFromContainerRatios({
    theme,
    containerWidthRatios,
}: ReturnImageWidthsFromContainerRatiosProps) {
    return applyContainerRatio(containerWidthRatios, {
        mobile: '100vw',
        tablet: '100vw',
        desktop: `${theme.siteMetrics.mainContentWidth}px`,
        fallbackWidth: theme.siteMetrics.mainContentWidth,
    })
}
