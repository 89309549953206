import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '@news-mono/web-common'
import {
    TNPageCreateAccountCTA,
    puzzleTNCreateAccountCTAProps,
} from '../../../user-registration'
import { ContextualBack } from '../../components/TheNightly/ContextualBack'
import { PuzzleListingContainer } from '../../components/TheNightly/PuzzleListingContainer'
import { Title } from '../../PuzzleListing/TheNightly/PuzzleListing.styled'
import {
    AmuseLabsIframe,
    AmuseLabsIframeProps,
    isAmuseLabsEvent,
} from '../components/AmuseLabsIframe'
import { TitleContainer } from './PuzzleIframe.styled'

export type PuzzleIframeProps = {
    title: string
    backLinkDestination: string
} & AmuseLabsIframeProps

export const PuzzleIframe = ({
    title,
    backLinkDestination,
    ...iframeProps
}: PuzzleIframeProps) => {
    const [inPuzzle, setInPuzzle] = useState(false)

    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    // Handle updating inPuzzle.
    useEffect(() => {
        const messageHandler = (event: unknown) => {
            if (!isAmuseLabsEvent(event, iframeProps.link)) return

            const data = JSON.parse(event.data) as unknown

            if (!hasSource(data)) return

            // 'picker' is the src ID used for the puzzles list page.
            setInPuzzle(data.src !== 'picker')
        }

        window.addEventListener('message', messageHandler)

        return () => window.removeEventListener('message', messageHandler)
    }, [iframeProps.link])

    return (
        <PuzzleListingContainer inApp={inApp}>
            {!inApp && (
                <TitleContainer>
                    <ContextualBack
                        // If in a puzzle, reload page to reset iframe to list page.
                        to={inPuzzle ? location.href : backLinkDestination}
                        onClick={() => inPuzzle && location.reload()}
                    >
                        {inPuzzle ? 'Puzzle list' : 'Puzzles'}
                    </ContextualBack>
                    <Title>{title}</Title>
                </TitleContainer>
            )}
            <TNPageCreateAccountCTA {...puzzleTNCreateAccountCTAProps} />

            <AmuseLabsIframe {...iframeProps} requiresEntitlement={true} />
        </PuzzleListingContainer>
    )
}

const hasSource = (data: unknown): data is { src: string } =>
    data !== null && typeof data === 'object' && 'src' in data
