import styled from '@emotion/styled'
import { metrics } from '../../../__styling/settings/metrics'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

const gridGapSize = calcRem(metrics.thewest.margins.sm)

export const StyledNestedGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: gridGapSize,

    [breakpoint('xs')]: {
        padding: `${calcRem(metrics.thewest.margins.md)} 0`,
    },
})

/**
 * A container that adds extra 'sm' padding on mobile viewports only.
 * Useful for layouts where padding disappears at the 'sm' breakpoint such as luna
 */
export const StyledMobilePaddingContainer = styled('div')({
    paddingLeft: metrics.thenightly.margins.md,
    paddingRight: metrics.thenightly.margins.md,
    [breakpoint('sm')]: {
        padding: 0,
    },
})

export const StyledGrid = styled('article')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: calcRem(40),
    marginBottom: calcRem(metrics.thewest.margins.md),
})

export const StyledArticleContent = styled('div')({
    width: '100%',

    //offest the text margin on the last article block
    '& > *:last-child': {
        marginBottom: `-${metrics.thenightly.textMargin}px`,
    },
})

export const StyledSharingContainer = styled(StyledMobilePaddingContainer)<{
    commentsAllowed: boolean
}>(({ commentsAllowed }) => ({
    marginBottom: commentsAllowed ? metrics.thenightly.margins.lg : undefined,
}))
