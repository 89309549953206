import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { useQueryParams } from 'web-common/src/hooks/useQueryParams'
import { FilterQueryParam, getParameterValue } from '../../FilterQueryParam'
import { DropdownMenu, DropdownMenuOption } from './DropdownMenu'
import { FilterEventProps, getEventName } from '../../filter-events-util'

interface QueryParamDropdownProps extends FilterEventProps {
    queryParamName: string
    name: string
    label: string
    menuOptions: FilterQueryParam[]
    defaultOption: FilterQueryParam
    originator: string
    clearPage?: boolean
}

export const QueryParamDropdown: FC<QueryParamDropdownProps> = ({
    queryParamName,
    name,
    label,
    menuOptions,
    defaultOption,
    originator,
    onEvent,
    clearPage,
    dataLayerEventName,
}) => {
    const history = useHistory()
    const { queryParams, pathname } = useQueryParams()

    const paramValue = queryParams.get(queryParamName)

    const selectedValue = getParameterValue(
        paramValue,
        menuOptions,
        defaultOption,
    )

    const options: DropdownMenuOption[] = menuOptions.map((option) => {
        const isSelected = option === selectedValue
        return {
            displayName: option.displayName,
            value: option.paramName,
            isSelected,
        }
    })

    const handleSelectOption = (value: string) => {
        if (clearPage) {
            // remove the page parameter
            queryParams.delete('page')
        }

        if (value === defaultOption.paramName) {
            queryParams.delete(queryParamName)
        } else {
            queryParams.set(queryParamName, value)
        }

        history.push({
            pathname: pathname,
            search: queryParams.toString(),
        })

        const eventFilterName = getEventName(queryParamName)

        if (eventFilterName) {
            onEvent({
                type: dataLayerEventName,
                originator,
                payload: {
                    name: eventFilterName,
                    value: value,
                },
            })
        }
    }

    return (
        <DropdownMenu
            onChange={handleSelectOption}
            options={options}
            name={name}
            label={label}
        />
    )
}
