import { Theme, useTheme } from '@emotion/react'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    BaseClientConfig,
    CardEvents,
    Product,
    Topic,
    useToggleState,
    VideoQueueItemMeta,
} from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { getVideoWatchPageUrl } from '../../../../../libs/web-common/src/__api-mapping/v4/list-video'
import {
    AutoplayOptions,
    GetVideoQueue,
    VideoOptions,
} from '../../content/Video/PlayerInterface'
import { useGdprMode } from '../../content/Video/utils/gdpr-mode'
import { ArticleVideo } from '../../publication/ArticleVideo/ArticleVideo'
import { StyledVideoPlayer } from './VideoPlayer.styled'
import { useViewport } from '../../__helpers/use-viewport'

export interface VideoHubPlayerProps {
    onEvent: (event: CardEvents) => void
    item: VideoMetaDTO
    config: BaseClientConfig
    adUnitPath: string
    currentPath: string
    getVideoQueue: GetVideoQueue
    isVideoWatchPage?: boolean
    autoplayWhenInView?: boolean
    adElement?: JSX.Element
}

const onPlayNextVideo = (nextVideo: VideoQueueItemMeta) => {
    if (nextVideo.topicMeta?.id?.includes('entertainment')) {
        const videoUrl = getVideoWatchPageUrl(
            nextVideo.topicMeta as Topic,
            nextVideo,
        )
        window && window.history.pushState(null, nextVideo.name ?? '', videoUrl)
    }
}

export const VideoPlayer: React.FC<VideoHubPlayerProps> = ({
    onEvent,
    config,
    item,
    adUnitPath,
    currentPath,
    getVideoQueue,
    isVideoWatchPage,
    autoplayWhenInView,
    adElement,
}) => {
    const { isMobile } = useViewport()
    const toggles = useToggleState()
    const gdprMode = useGdprMode()
    const theme = useTheme()

    const isVideoHubMobileAutoplayEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7news-mobile-video-autoplay',
    )

    const shouldDisableAutoplay = (theme: Theme) => {
        if (!isMobile) {
            return false
        }

        // EDIT-663 & EDIT-729:
        // On mobile devices, we disable autoplay.
        // If we dont, the videohub will attempt to play a video, freeze playback and plaback controls will be hidden (?!?!?)
        // This bug does not affect 7News, as the current 7News implementation does not need to scroll into view.
        if (theme.kind === 'perthnow' || theme.kind === 'thewest') {
            return true
        }

        if (
            theme.kind === Product.SevenNews &&
            !isVideoHubMobileAutoplayEnabled
        ) {
            return true
        }

        return false
    }

    const autoplayOptions: false | AutoplayOptions = shouldDisableAutoplay(
        theme,
    )
        ? false
        : {
              delayDuration:
                  theme.kind === Product.SevenNews && !autoplayWhenInView
                      ? 3
                      : 0,
              //pause countdown still needs to be on for initial video load pause
              pauseCountdownOutsideViewport:
                  theme.kind === Product.SevenNews && !autoplayWhenInView
                      ? false
                      : true,
              pauseWhenOutOfView:
                  theme.kind === Product.SevenNews && !autoplayWhenInView
                      ? false
                      : true,
          }
    const autoplayNextOptions: false | AutoplayOptions = {
        delayDuration: 3,
        pauseCountdownOutsideViewport:
            theme.kind === Product.SevenNews && !autoplayWhenInView
                ? false
                : true,
        pauseWhenOutOfView:
            theme.kind === Product.SevenNews && !autoplayWhenInView
                ? false
                : true,
    }

    const videoOptions: VideoOptions = {
        adUnitPath,
        canAutoplayVideos: !shouldDisableAutoplay(theme),
        enableSnowplow: false,
        enablePrebid: isFeatureEnabled(toFeatureState(toggles), 'prebid-video'),
        isMagniteEnabled: isFeatureEnabled(
            toFeatureState(toggles),
            'magnite-header-tag-wrapper',
        ),
        gdprMode,
    }

    return (
        <StyledVideoPlayer>
            <ArticleVideo
                videoDetails={{
                    id: item.id,
                    accountId: item.accountId,
                    heading: String(item.name),
                    videoType: item.videoType as 'vod' | 'live' | null,
                }}
                showTitles={false}
                autoplayOptions={autoplayOptions}
                autoplayNextOptions={autoplayNextOptions}
                videoOptions={videoOptions}
                pauseOverlay={false}
                isVideoStickyEnabled={false}
                onEvent={onEvent}
                getVideoQueue={getVideoQueue}
                showPlaylist={true}
                publicationPathname={currentPath}
                isHeroVideo={true}
                isVideoHub={true}
                publicUrl={config.publicUrl}
                onPlayNextVideo={
                    isVideoWatchPage &&
                    item.topicMeta?.id.includes('entertainment')
                        ? onPlayNextVideo
                        : undefined
                }
                adElement={adElement}
            />
        </StyledVideoPlayer>
    )
}
