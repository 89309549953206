import { amphtml } from './react_amp'

export interface AmpAnalyticsProps {
    id?: string
    type?: string
    config?: string
    'data-credentials'?: 'include'
    dangerouslySetInnerHTML?: { __html: string }
}

export const AmpAnalytics = amphtml<AmpAnalyticsProps>('analytics')
