import { addUrlParams, ShareOption } from '@news-mono/web-common'
import { getClipboardOnClick } from '../../../buttons/Sharing/utils/get-clipboard-on-click'
import { getEmailOnClick } from '../../../buttons/Sharing/utils/get-email-on-click'
import { getFacebookOnClick } from '../../../buttons/Sharing/utils/get-facebook-on-click'
import { getTwitterOnClick } from '../../../buttons/Sharing/utils/get-twitter-on-click'
import { getLinkedInOnClick } from './get-linkedin-on-click'
import { getNativeShareOnClick } from './get-native-share-on-click'

const extractUrlHash = (url: string) => {
    const urlParts = new URL(url)
    const hash = urlParts.hash
    urlParts.hash = ''
    return [hash, urlParts.toString()]
}

// @TODO: WhatsApp and Reddit functionality needs to be added
export const getButtonOnClick = (
    shareOption: ShareOption,
    url: string,
    text: string,
    fbAppId: string,
    customCampaign?: string,
) => {
    // hash fragment is required to be after the query params
    const [hash, urlWithoutHash] = extractUrlHash(url)
    let shareUrl = addUrlParams(urlWithoutHash, [
        ['utm_campaign', customCampaign || 'share-icons'],
        ['utm_source', shareOption],
        [
            'utm_medium',
            ['facebook', 'twitter'].indexOf(shareOption) !== -1
                ? 'social'
                : shareOption,
        ],
        ['tid', `${Date.now()}`],
    ])
    if (hash) {
        shareUrl = `${shareUrl}${hash}`
    }

    switch (shareOption) {
        case 'facebook':
        case 'facebookRounded':
            return getFacebookOnClick(shareUrl, text, fbAppId)
        case 'x':
        case 'twitter':
            return getTwitterOnClick(shareUrl, text)
        // case 'whatsapp': return
        case 'email':
        case 'email_tn':
            return getEmailOnClick(shareUrl, text)
        // case 'reddit': return
        case 'clipboard':
            return getClipboardOnClick(shareUrl)
        case 'linkedin':
            return getLinkedInOnClick(shareUrl)
        case 'system':
            return getNativeShareOnClick(shareUrl, text)
        default:
            return () => undefined
    }
}
