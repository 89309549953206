import React from 'react'

/**
 *  Returns a function that can be run to determine if the component is mounted or not
 **/
export function useMounted() {
    const mounted = React.useRef(false)
    const isMounted = () => mounted.current
    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])
    return isMounted
}
