import styled from '@emotion/styled'
import { breakpoint } from '../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { StyledBylineClassName } from '../../CardByline/CardByline.styled'
import { CardText } from '../../CardText/CardText'
import { StyledHeadlineText } from '../../CardText/CardText.styled'

interface SmallCardProps {
    cardNumber: number
}

export const StyledSmallCard = styled('div')<SmallCardProps>(
    ({ theme, cardNumber }) => ({
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        [breakpoint('sm')]: {
            flexDirection: 'column',
        },
        [`& ${StyledBylineClassName}`]: {
            display: 'none',
        },
    }),
)

export const StyledContentWrap = styled('div')(({ theme }) => ({
    paddingLeft: theme.margins.sm,
    [breakpoint('sm')]: {
        padding: `${theme.margins.sm}px 0px 0px ${theme.margins.md}px`,
    },
    [breakpoint('md')]: {
        padding: `0px ${theme.margins.sm}px 0px ${theme.margins.sm}px`,
    },
}))

export const StyledSmallCardContent = styled(CardText)(({ theme }) => ({
    [`& ${StyledHeadlineText}`]: {
        fontSize: calcRem(14),

        [breakpoint('sm')]: {
            fontSize: calcRem(16),
        },
    },
}))
