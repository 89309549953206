import styled from '@emotion/styled'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledSocialLinks = styled('div')(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'center',
        marginTop: calcRem(9),

        '& > a + a': {
            marginLeft: calcRem(8),
        },
    },
    theme.kind === 'perthnow' && {
        marginTop: calcRem(0),
        flexWrap: 'wrap',

        '& > a + a': {
            marginLeft: calcRem(0),
        },

        '& > a': {
            marginTop: calcRem(9),
            marginRight: calcRem(8),
        },
    },

    theme.kind === 'sevennews' && {
        marginTop: 0,
        '& > a': {
            width: calcRem(39),
            height: calcRem(39),
        },

        [breakpoint('sm')]: {
            '& > a': {
                width: calcRem(28),
                height: calcRem(28),
            },
        },
    },
])
