import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AdUnitWrapper } from '../../../advertising/AdUnit/AdUnitWrapper'
import { AdUnitInformation } from '../../../advertising/advertising.routing'
import { ThemeMargins } from '../../../__styling/settings/metrics'
import { LazyTheWestLiveBillboard } from './TheWestLiveBillboard.lazy'

export interface TheWestLiveBillboardRoutingProps {
    showSponsorLogo?: boolean
    verticalSpacing?: [keyof ThemeMargins, keyof ThemeMargins] | undefined
    twlLogoAdUnit?: AdUnitInformation['data']
}

export const TheWestLiveBillboardRegistration = createRegisterableComponent(
    'the-west-live-billboard',
    (props: TheWestLiveBillboardRoutingProps, services) => {
        const twlLogoAdUnit = props.twlLogoAdUnit && (
            <AdUnitWrapper
                {...props.twlLogoAdUnit}
                adState={services.adState}
                unitId={props.twlLogoAdUnit.slot.id}
                onEvent={services.onEvent}
                adType={'inline'}
            />
        )

        return (
            <LazyTheWestLiveBillboard
                {...props}
                twlLogoAdUnit={twlLogoAdUnit}
            />
        )
    },
)
