import queryString from 'query-string'
import { sanitiseVideoId } from '../../../content/Video/utils/sanitise-video-id'

const cleanPath = (path: string) =>
    path.replace(/\.amp$/, '').replace(/[^a-z0-9A-Z]/g, '')

export interface GetImaOptionsProps {
    videoId: string
    adUnitPath?: string
    correlator?: string
    requestMode?: BrightcovePlayer.AdRequestMode
    topics?: string[]
    publicationPathname?: string
}

export interface IMAOptions extends BrightcovePlayer.Ima3Options {
    custPrams: string
}

export const getCustParams = (options: {
    noCustParamsLabel?: boolean
    adUnitPath?: string
    publicationPathname?: string
    camelCase?: boolean
}) => {
    // TODO this will be getting replaced with keywords which should be generated
    // based on all topics once ad ops are ready
    let custParams = 'device=web'

    const { noCustParamsLabel, adUnitPath, publicationPathname, camelCase } =
        options

    if (adUnitPath) {
        const adUnitPathParts = adUnitPath.split('/')

        // adUnitPaths have the following structure
        // /60035833/WAN/thewest/News/home
        // /60035833/WAN/thewest/News/home
        // /60035833/WAN/thewest/News/WANews/home
        // /60035833/WAN/thewest/News/WANews/article

        // if it's an article we want to grab the slug from the url to pass to dfp
        const pathname = resolvePublicationPathname(publicationPathname)
        const pathnameParts = pathname.split('/')
        if (
            adUnitPathParts[adUnitPathParts.length - 1] === 'article' &&
            pathnameParts.length
        ) {
            const articlePathPart = pathnameParts[pathnameParts.length - 1]
            const articleAdSlug = cleanPath(articlePathPart)

            custParams = custParams + `&pagetitle=${articleAdSlug}`
        }

        // if it's under a section on the site we want to pass those in as levels to dfp
        const sections = adUnitPathParts.slice(4, adUnitPathParts.length - 1)

        if (sections[0]) {
            custParams = custParams + `&Level2=${sections[0]}`
        }

        if (sections[1]) {
            custParams = custParams + `&Level3=${sections[1]}`
        }
    }

    const gootest = getGooTestParam()

    if (gootest) {
        custParams = custParams + `&gootest=${getGooTestParam()}`
    }

    const params = encodeURIComponent(custParams)
    const paramName = camelCase ? 'custParams' : 'cust_params'
    return !noCustParamsLabel ? `${paramName}=${params}` : params
}

// CMS_ID
const CMS_ID = 3993
// Brightcove CMS ID, feeds metadata from Brightcove to DFP
const getCmsId = (options?: { camelCase?: boolean }) =>
    options && options.camelCase ? `cmsId=${CMS_ID}` : `cmsid=${CMS_ID}`

// timestamp to keep requests unique and bust caching
const getScor = () => {
    const randomInt = new Date().getTime().toString()

    return `scor=${randomInt}`
}

const getVid = (videoId: string) => `vid=${sanitiseVideoId(videoId)}`

// report full url including querystring
const getPageUrl = () => {
    if (typeof window === 'undefined') {
        return ''
    }

    const pageUrl = encodeURIComponent(
        `${window.location.protocol}//${window.location.host}/${window.location.pathname}` +
            `${window.location.search}${window.location.hash}`,
    )

    return `url=${pageUrl}`
}

const getCorrelator = (correlator: string | undefined) => {
    if (!correlator) {
        return ''
    }

    return `correlator=${correlator}`
}

const getIu = (adUnitPath: string | undefined) => {
    // test iu path for google
    // return 'iu=/124319096/external/single_ad_samples'
    if (!adUnitPath) {
        return ''
    }

    return `iu=${encodeURIComponent(adUnitPath)}`
}

export const getImaOptions = (props: GetImaOptionsProps) => {
    const { adUnitPath, publicationPathname, videoId, correlator } = props

    // Generate IMA ad Url
    const queryParams = [
        getIu(adUnitPath),
        'sz=400x300',
        'ciu_szs',
        'gdfp_req=1',
        'env=vp',
        'output=vast',
        'ad_rule=1',
        'vpos=preroll',
        getCmsId(),
        getVid(videoId),
        'vad_type=linear',
        getScor(),
        getPageUrl(), // doesnt happen on SSR
        getCustParams({ adUnitPath, publicationPathname }),
        getCorrelator(correlator),
    ]
        .filter((q) => !!q)
        .join('&')
    const serverUrl =
        'https://pubads.g.doubleclick.net/gampad/ads?' + queryParams

    // One of the ad networks used for video ads penalises us if we make initial ad requests
    // without serving an the ad
    // We are trialling only making the ad request on autoplays which have a high rate of plays
    const requestMode = props.requestMode || 'onplay'

    const ima3Options: IMAOptions = {
        adTechOrder: ['html5'],
        debug: false,
        requestMode,
        serverUrl,
        custPrams: getCustParams({}),
        timeout: 10000,
        vpaidMode: 'ENABLED',
        loadingSpinner: true,
        showVpaidControls: true,
    }

    return ima3Options
}

function getGooTestParam() {
    if (typeof location === 'undefined') {
        return undefined
    }

    const parsed: {
        gootest?: string
    } = queryString.parse(location.search)
    const gootest = parsed.gootest

    return gootest
}

/**
 * We want to resolve path from `document.location`, but we can't do this during SSR,
 * so we fallback to the provided `pathname` which should be passed in during SSR.
 * This is mainly for AMP ads.
 * @param pathname The pathname of the publication, if available
 */
function resolvePublicationPathname(pathname?: string) {
    const isTestEnv = process.env.NODE_ENV === 'test'
    const hasDocument = typeof document !== 'undefined' && !isTestEnv
    if (!hasDocument || !document.location) {
        return pathname || ''
    }

    return document.location.pathname
}
