import React, { useEffect, useRef, useState } from 'react'
import {
    CardsContainer,
    CardImage,
    CardTitle,
    CardLink,
} from './PuzzlesListingCards.styled'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const crosswordFrame = require('./assets/crossword-frame.png')
const sudokuFrame = require('./assets/sudoku-frame.png')
const targetFrame = require('./assets/target-frame.png')
const wordFrame = require('./assets/word-frame.png')

export interface ListingCardsProps {
    disabled: boolean
}
export interface CardProps {
    title: string
    image: string
    linkPath: string
    disabled: boolean
}

export const PuzzlesListingCards = ({ disabled }: ListingCardsProps) => {
    const [customClass, setCustomClass] = useState('')
    const container = useRef<any>(null)
    const containerSize = container.current ? container.current.offsetWidth : 0

    // Resizing to cater for Sidebar ad slots layouts if its used for large tablet size only
    // Works fine if no sidebar in the layout
    useEffect(() => {
        window.addEventListener('resize', function () {
            if (
                window.innerWidth > 1019 &&
                window.innerWidth < 1200 &&
                containerSize < 900
            ) {
                setCustomClass('container-with-sidebar')
            } else {
                setCustomClass('')
            }
        })
    })

    return (
        <>
            <CardsContainer ref={container} className={customClass}>
                <ListingCards
                    title={'Crosswords'}
                    image={crosswordFrame}
                    linkPath={'/puzzles/crossword'}
                    disabled={disabled}
                />
                <ListingCards
                    title={'Sudoku'}
                    image={sudokuFrame}
                    linkPath={'/puzzles/sudoku'}
                    disabled={disabled}
                />
                <ListingCards
                    title={'Target'}
                    image={targetFrame}
                    linkPath={'/puzzles/target'}
                    disabled={disabled}
                />
                <ListingCards
                    title={'Word Search'}
                    image={wordFrame}
                    linkPath={'/puzzles/word-search'}
                    disabled={disabled}
                />
            </CardsContainer>
        </>
    )
}

export const ListingCards = ({
    title,
    image,
    linkPath,
    disabled,
}: CardProps) => {
    const disabledClass = disabled ? 'disabled' : ''
    const link = !disabled ? linkPath : '#'
    return (
        <CardLink href={link} className={`puzzles-card ${disabledClass}`}>
            <CardImage src={image} alt={title} />
            <CardTitle>{title}</CardTitle>
        </CardLink>
    )
}
