import React, { useEffect } from 'react'

/**
 * useInterval Hook
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @usage useInterval(() => { // Callback function}, 1000 // Interval length in ms)
 */
export function useInterval(
    callback: () => void,
    interval: number | null = 300,
) {
    const savedCallback = React.useRef<() => void>()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current()
            }
        }

        if (interval !== null) {
            const timer = window.setInterval(tick, interval)
            return () => window.clearInterval(timer)
        }

        return () => {}
    }, [interval])
}
