import { ColorsType } from '../types'

const Palette = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    greyLight: '#fafafa',
    red: '#E6001E',
    darkRed: '#b60920',
    stormGrey: '#737480',
    tundora: '#4d4d4d',
    charade: '#292A33',
    midGray: '#696A70',
    charcoal: '#4a4a4a',
    nero: '#272626',
    nero50: '#939393',
    alto: '#dfdfdf',
    turbo: '#FBE400',
    greyMarble: '#ccc',
    boxShadow: '#0000001',
    seashell: '#f1f1f1',
    toreaBay: '#162EA6',
    resolutionBlue: '#002482',
    blueRibbon: '#2e49fe',
    piper: '#CF5226',
    piperDark: '#A5421E',
    blazeOrange: '#FF670A',
    chillOrange: '#e7a893',
    endeavour: '#0058AD',
    endeavourDark: '#003E79',
    azureRadiance: '#0777E5',
    chillEndeavour: '#80abd6',
    kashmirBlue: '#48639A',
    pickledBluewood: '#262F4B',
    pickledBluewoodDark: '#1B2135',
    chillPickledBluewood: '#9397a5',
    happeningBlue: '#0a3ac1',
    lifestylePink: '#B04AE3',
    entertainmentPurple: '#A8328B',
    entertainmentPerfume: '#C0A9F6',
    latestRed: '#FF0505',
    defaultRed: '#BF001F',
    snentpurple: '#A583F1',
    sentTitleGrey: '#616161',
    entertainmentOrange: '#EB8733',
    platinum: '#E7E7E7',
    platinumLight: '#e7e5e5',

    //bauhaus
    bauhausWorld: '#BE001E',
    bauhausLifestyle: '#BA0057',
    bauhausEntertainment: '#EB8733',
    bauhausSport: '#0E5F0A',
    bauhausTech: '#3F8438',
    bauhausPolitics: '#FA2D00',
    bauhausBusiness: '#51B8C4',
    bauhausBlack: '#333333',
    bauhausBestBuys: '#0050D6',
    commonwealthGames: {
        slateBlue: '#724DD6',
        crayola: '#FF9D1A',
        blueBolt: '#00B6F8',
        hotMagenta: '#EA27C2',
        bitterLemon: '#CEDF00',
    },
    matchCentre: {
        darkred: '#B6001E',
        darkredWithOpacity: '#B6001E80',
        lightRed: '#F6A3AE',
        black005: '#f2f2f2',
        lighterRed: '#fbe9e9',
        bettingBlue: '#2368AD',
    },
    AFL: {
        blue: '#032B80',
        lightRed: '#FACCD2',
        red20: 'rgba(230, 0, 30, 0.2)',
        red40: 'rgba(230, 0, 30, 0.4)',
        black005: '#f2f2f2',
    },
    NRL: {
        black: '#000000',
        red: '#FF1000',
        orange: '#FF8C00',
        blue: '#032B80',
        yellow: '#EAD80A',
        grey: '#676767',
    },
    soccer: {
        green: '#008000',
        maroon: '#880000',
    },
    articlesV2: {
        buttonBackground: '#F1EFEF',
        contextualBackground: '#FAF8F7',
        cardsBorder: '#E9E9E9',
        horizontalLine: 'rgba(0, 0, 0, 0.15)',
    },
    flyoutV2: {
        flyoutLinkRed: '#B80018',
        flyoutLinkGrey: '#1F351B',
        flyoutGrey: '#FAF8F7',
        flyoutText: '#4A4A4A',
    },
    newsletters: {
        lightBlack50: '#939393',
        lightBlackAlternative: '#626262',
        white20: 'rgba(255, 255, 255, 0.2)',
        borderBlack: 'rgba(41, 42, 51, 0.04)',
        borderBlackAlternative: '#b1b1b1',
        darkWhite: '#f9f9f9',
    },
    gradialRed:
        'radial-gradient(50% 50% at 50% 50%, rgba(230, 0, 30, 0.06) 0%, rgba(230, 0, 30, 0.06) 100%)',
    gradialGrey: 'rgba(39, 38, 38, 0.5)',
} as const

export type SevenNewsPalette = typeof Palette

export type SevenNewsColourMap = ColorsType<SevenNewsPalette>

export const colors: SevenNewsColourMap = {
    brand: Palette.red,
    section: Palette.red,
    background: {
        primary: Palette.white,
        secondary: Palette.black,
    },
    palette: Palette,
    text: {
        primary: Palette.charade,
        secondary: Palette.charade,
        tertiary: Palette.sentTitleGrey,
        heading: Palette.charade,
    },
    actions: {
        link: {
            default: Palette.charade,
            hover: Palette.red,
            focus: Palette.darkRed,
            active: Palette.darkRed,
        },
        button: {
            primary: {
                text: {
                    default: Palette.white,
                    hover: Palette.white,
                    focus: Palette.white,
                    active: Palette.white,
                },
                background: {
                    default: Palette.red,
                    hover: Palette.black,
                    focus: Palette.black,
                    active: Palette.black,
                },
                border: {
                    default: Palette.red,
                    hover: Palette.black,
                    focus: Palette.black,
                    active: Palette.black,
                },
            },
        },
    },
    borders: {
        primary: Palette.alto,
        secondary: Palette.greyMarble,
        tertiary: Palette.entertainmentPerfume,
    },
    cards: {
        primary: Palette.snentpurple,
    },
}
