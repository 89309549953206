/**
 * Gets the numbers between `start` and `end`. i.e. given 1 and 5 will return `[1,2,3,4,5]`
 * @param start The first page
 * @param end The last page
 * @returns An array of numbers from `start` to `end`
 */
export const range = (start: number, end: number) => {
    return [...Array(end - start).keys()].map((el) => el + start)
}

/**
 * Gets the first and last page that we want to render, relative to the currentPage. The results are then passed into the `range` function to get the pages to render
 * @param pageCount Total number of pages
 * @param currentPage  The current page
 * @param pagesToDisplay The number of pages to display
 * @returns `{start:number, end:number}`
 */
export const getNearbyPages = (
    pageCount: number,
    currentPage: number,
    pagesToDisplay: number,
) => {
    const ceiling = Math.ceil(pagesToDisplay / 2)
    const floor = Math.floor(pagesToDisplay / 2)

    if (pageCount < pagesToDisplay) {
        return { start: 1, end: pageCount + 1 }
    } else if (currentPage >= 1 && currentPage <= ceiling) {
        return { start: 1, end: pagesToDisplay + 1 }
    } else if (currentPage + floor >= pageCount) {
        return { start: pageCount - pagesToDisplay + 1, end: pageCount + 1 }
    } else {
        return {
            start: currentPage - ceiling + 1,
            end: currentPage + floor + 1,
        }
    }
}
