import { breakpoint, Breakpoints } from '../../..'

export function isMobileViewport(size: keyof Breakpoints = 'sm') {
    if (
        // fail conditional if SSR is triggered or a VR test
        typeof window !== 'undefined' &&
        window &&
        typeof window.matchMedia === 'function'
    ) {
        const smBreakpoint = window.matchMedia(
            breakpoint(size).replace('@media ', ''),
        )
        // if viewport size is below defined breakpoint (default sm if no parameters) return true
        if (!smBreakpoint.matches) {
            return true
        }
    }
    return false
}
