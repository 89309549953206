import React, { ErrorInfo } from 'react'
import { Logger } from 'typescript-log'

interface State {
    hasError: boolean
}

export type ErrorRenderer = (retry: () => void) => React.ReactElement<any>

export interface ErrorBoundaryProps {
    log: Logger
    renderError: ErrorRenderer
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    state: State = {
        hasError: false,
    }

    componentDidCatch(err: Error, info: ErrorInfo) {
        const { log } = this.props
        const message = `ErrorBoundary caught an error`
        log.error({ err, info }, message)

        this.setState({ hasError: true })
    }

    retry = () => {
        this.setState({ hasError: false })
    }

    render() {
        if (this.state.hasError) {
            return this.props.renderError(this.retry)
        }
        return this.props.children
    }
}
