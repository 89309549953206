import styled from '@emotion/styled'
import { FillProperty } from 'csstype'
import { IconComment } from '../../icons/IconComment/IconComment'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { ArrowStyle } from './MessageBanner'

interface StyledMessageBannerProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    connector?: ArrowStyle
}

const arrowSize = 22
const bannerWidth = 768

export const StyledMessageBanner = styled('div')<StyledMessageBannerProps>(
    ({ theme, verticalSpacing }) => ({
        position: 'relative',
        padding: calcRem(theme.margins.md, theme.margins.lg),
        background: themedValue(theme, {
            thewest: colors.thewest.greyErmine,
            perthnow: colors.perthnow.greyBasalt90,
            sevennews: colors.sevennews.charade,
            fallback: undefined,
        }),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
    }),
    ({ connector, theme }) => [
        connector === 'solid-arrow' && {
            '&::after': {
                border: 'solid transparent',
                content: '""',
                height: 0,
                width: 0,
                bottom: -(arrowSize * 2),
                left: `calc(50% - ${arrowSize}px)`,
                position: 'absolute',
                pointerEvents: 'none',
                borderColor: 'transparent',
                borderTopColor: themedValue(theme, {
                    thewest: colors.thewest.greyErmine,
                    perthnow: colors.perthnow.greyBasalt90,
                    sevennews: colors.sevennews.charade,
                    fallback: undefined,
                }),
                borderWidth: arrowSize,
                zIndex: zIndex.common.messageBannerArrow,

                [breakpoint('md')]: {
                    left: `calc(60% - ${calcRem(
                        bannerWidth / 2,
                    )} + ${arrowSize}px)`,
                },
            },
        },
    ],
)

export const StyledMessageBannerWrapper = styled('div')(({ theme }) => ({
    maxWidth: calcRem(bannerWidth),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    fontFamily: theme.fonts.sansSerif,
    overflowY: 'hidden',
    padding: calcRem(theme.margins.md, 0),

    '> *:first-child': {
        marginRight: theme.margins.md,
    },
}))

export const StyledIconComment = styled(IconComment)(({ theme }) => ({
    width: 53,
    height: 47,
    fill: 'none',
    strokeWidth: 1.5,
    stroke: themedValue(theme, {
        thewest: colors.thewest.westblue,
        fallback: colors.black,
    }) as FillProperty,
}))

export const StyledIconPremium = styled(IconPremium)(() => ({
    width: 27,
    height: 27,
}))

export const StyledMessageCTA = styled('div')(({ theme }) => ({
    flexBasis: 350,
    flexGrow: 1,
    justifySelf: 'flex-start',
    padding: calcRem(theme.margins.md, 0),
    paddingRight: theme.margins.xl,

    p: {
        margin: 0,
        fontSize: calcRem(14),
    },
}))

export const StyledMessageBannerGroup = styled('div')({
    display: 'flex',
    flex: 'nowrap',
    flexBasis: 290,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
})

export const StyledSubscriberMessage = styled('p')(({ theme }) => ({
    flexBasis: 160,
    flexGrow: 1,
    position: 'relative',
    paddingLeft: theme.margins.xl / 2,
    margin: 0,
    transform: `translate(${-theme.margins.xl / 2}px, 0)`,
    lineHeight: 1.2,
    fontWeight: 700,
    fontSize: calcRem(18),

    '&::before': {
        content: '""',
        height: '100%',
        width: 1,
        background: colors.thewest.westblue,
        top: 0,
        left: 0,
        position: 'absolute',
    },
}))

export const StyledButtonWrapper = styled('div')({
    flexGrow: 3,
})
