import { differenceInYears, format } from 'date-fns'
import React, { useState } from 'react'
import { TeamSide } from '../AflPlayerStatsWidget/AflPlayerStatsWidget'
import { TeamControls } from '../AflPlayerStatsWidget/TeamControls'
import { StyledWidgetErrorContainer } from '../MatchTimelineWidget/MatchTimelineWidget.styled'
import { AflSquadsWidgetProps } from './AflSquadsWidget.routing'
import {
    StyeldAflSquadsTeamWrapper,
    StyledAflPlayerData,
    StyledAflPlayerDataItem,
    StyledAflPlayerDataWrapper,
    StyledAflPlayerImage,
    StyledAflPlayerName,
    StyledAflPlayerWrapper,
    StyledAflSquadsTeamName,
    StyledAflSquadsWidgetWrapper,
    StyledTeamControlsWrapper,
} from './AflSquadsWidget.styled'
import { PlayerDataInterface, useFetchPlayerData } from './useFetchPlayerData'

const silhouetteImage = require('./assets/silhouette.jpg')

export const AflSquadsWidget = ({
    apiData,
    competition,
    season,
    isAflw,
}: AflSquadsWidgetProps) => {
    const [selectedTeam, setSelectedTeam] = useState<TeamSide>('both')
    const homeId =
        apiData.report.aflMatchStats.matchInfo['@attributes'].homeSquadId
    const homeName =
        apiData.report.aflMatchStats.matchInfo['@attributes'].homeSquadName
    const awayId =
        apiData.report.aflMatchStats.matchInfo['@attributes'].awaySquadId
    const awayName =
        apiData.report.aflMatchStats.matchInfo['@attributes'].awaySquadName

    const [homePlayers, awayPlayers] = useFetchPlayerData(
        season,
        competition,
        homeId,
        awayId,
    )

    if (!homePlayers || !awayPlayers) {
        return (
            <StyledWidgetErrorContainer>
                No data found
            </StyledWidgetErrorContainer>
        )
    }
    const handleTeamChange = (side: TeamSide) => {
        setSelectedTeam(side)
    }
    return (
        <>
            <StyledTeamControlsWrapper>
                <TeamControls
                    teamSide={selectedTeam}
                    handleTeamChange={handleTeamChange}
                />
            </StyledTeamControlsWrapper>
            <StyledAflSquadsWidgetWrapper>
                <StyeldAflSquadsTeamWrapper isShown={selectedTeam !== 'away'}>
                    <StyledAflSquadsTeamName>
                        {homeName}
                    </StyledAflSquadsTeamName>
                    {homePlayers.map((player: PlayerDataInterface, i) => {
                        return (
                            <PlayerCard
                                player={player}
                                isAflw={isAflw}
                                key={i}
                            />
                        )
                    })}
                </StyeldAflSquadsTeamWrapper>
                <StyeldAflSquadsTeamWrapper isShown={selectedTeam !== 'home'}>
                    <StyledAflSquadsTeamName>
                        {awayName}
                    </StyledAflSquadsTeamName>
                    {awayPlayers.map((player: PlayerDataInterface, i) => {
                        return (
                            <PlayerCard
                                player={player}
                                isAflw={isAflw}
                                key={i}
                            />
                        )
                    })}
                </StyeldAflSquadsTeamWrapper>
            </StyledAflSquadsWidgetWrapper>
        </>
    )
}
interface PlayerCardProps {
    player: PlayerDataInterface
    isAflw?: boolean
}
const PlayerCard: React.FC<PlayerCardProps> = ({ player, isAflw }) => {
    const getImage = (playerId: number, isAflw?: boolean) => {
        try {
            if (isAflw) {
                return require(`./assets/aflwPlayers/${playerId}.jpg`)
            }
            return require(`./assets/players/${playerId}.png`)
        } catch (error) {
            return require('./assets/silhouette.jpg')
        }
    }
    return (
        <StyledAflPlayerWrapper>
            <StyledAflPlayerName>{player.name}</StyledAflPlayerName>
            <StyledAflPlayerDataWrapper>
                <StyledAflPlayerImage
                    src={getImage(Number(player.playerId), isAflw)}
                    alt={player.name}
                />
                <StyledAflPlayerData>
                    <StyledAflPlayerDataItem>
                        <span>Born</span>
                        {format(new Date(player.dateOfBirth), 'dd MMM yyy')} (
                        {differenceInYears(
                            new Date(),
                            new Date(player.dateOfBirth),
                        )}{' '}
                        yo)
                    </StyledAflPlayerDataItem>
                    <StyledAflPlayerDataItem>
                        <span>Position</span>
                        {player.profilePosition}
                    </StyledAflPlayerDataItem>
                    <StyledAflPlayerDataItem>
                        <span>Number</span>
                        {player.jumperNumber}
                    </StyledAflPlayerDataItem>
                    <StyledAflPlayerDataItem>
                        <span>Height</span>
                        {player.height} cm
                    </StyledAflPlayerDataItem>
                    {!isAflw && (
                        <StyledAflPlayerDataItem>
                            <span>Weight</span>
                            {player.weight} kg
                        </StyledAflPlayerDataItem>
                    )}
                </StyledAflPlayerData>
            </StyledAflPlayerDataWrapper>
        </StyledAflPlayerWrapper>
    )
}
