import React from 'react'
import {
    SponsoredTopic,
    SponsoredTopicProps,
} from '../../advertising/SponsoredTopic/SponsoredTopic'
import {
    StyledBreadcrumb,
    StyledBreadcrumbContainer,
    StyledChevron,
    StyledCNBCLogo,
    StyledLink,
    StyledPageTitle,
    StyledSevenChevron,
    StyledSpan,
} from '../../navigation/Breadcrumb/Breadcrumb.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { ThemedRender } from '../../__styling/themed-render'

export type LinkProps = {
    text: string
    href?: string
    sponsorLink?: string //Note that this replaces the text/href.
}

export interface BreadcrumbProps {
    items: LinkProps[]
    enableHeaderTag?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    horizontalGutters?: keyof ThemeMargins | undefined
}

export const Breadcrumb: React.FC<BreadcrumbProps & SponsoredTopicProps> = ({
    items,
    sponsor,
    enableHeaderTag,
    verticalSpacing,
    horizontalGutters,
}) => {
    if (!items || items.length === 0) {
        return null
    }
    const numberOfLevels = items.length

    function displayItemText(item: LinkProps) {
        const itemText = item.text.toLowerCase()

        if (itemText === 'sunrise') {
            return 'entertainment - sunrise'
        }

        if (itemText === 'the morning show') {
            return 'entertainment - the morning show'
        }

        return item.text
    }

    function getSponsorLogo(sponsor: string | undefined) {
        if (sponsor === 'cnbc') {
            const cnbcSrc = require('../../logos/CNBCLogos/CNBC_Logo_Black.svg')
            return <StyledCNBCLogo src={cnbcSrc}></StyledCNBCLogo>
        }

        return null
    }

    const breadcrumbs = () =>
        items.map((item, index) => {
            const isLast = index === numberOfLevels - 1

            //DPT3349 - Add topic breadcrumbs to Sunrise and Morning Show pages
            if (item.text.toLowerCase() === 'spotlight') {
                return null
            }

            const chevron = (
                <ThemedRender
                    thewest={() => <StyledChevron />}
                    perthnow={() => <StyledChevron />}
                    sevennews={() => <StyledSevenChevron />}
                />
            )

            const sponsorLinkLogo = item.sponsorLink
                ? getSponsorLogo(item.sponsorLink)
                : null

            const itemLink = item.href ? (
                <React.Fragment key={index}>
                    <StyledLink
                        enableHeaderTag={enableHeaderTag}
                        to={item.href}
                        isLast={isLast}
                    >
                        {sponsorLinkLogo
                            ? sponsorLinkLogo
                            : displayItemText(item)}
                    </StyledLink>
                    {!isLast && chevron}
                </React.Fragment>
            ) : (
                <React.Fragment key={index}>
                    <StyledSpan isLink={false} isLast={isLast}>
                        {sponsorLinkLogo
                            ? sponsorLinkLogo
                            : displayItemText(item)}
                    </StyledSpan>
                    {!isLast && chevron}
                </React.Fragment>
            )

            if (enableHeaderTag && isLast) {
                return <StyledPageTitle key={index}>{itemLink}</StyledPageTitle>
            } else {
                return <React.Fragment key={index}>{itemLink}</React.Fragment>
            }
        })
    return (
        <StyledBreadcrumbContainer
            verticalSpacing={verticalSpacing}
            horizontalGutters={horizontalGutters}
        >
            <StyledBreadcrumb>{breadcrumbs()}</StyledBreadcrumb>
            {sponsor && <SponsoredTopic sponsor={sponsor} />}
        </StyledBreadcrumbContainer>
    )
}
Breadcrumb.displayName = 'Breadcrumb'
