import React from 'react'
import {
    ActiveState,
    StyledLabel,
    TopicFilterContainer,
    TopicLink,
    TopicLinkList,
    TopicLinkListItem,
    TopicLinkWrapper,
} from './TopicFilter.styled'
import { useHistory } from 'react-router'
import { useQueryParams } from 'web-common/src/hooks/useQueryParams'
import { articleTopics, defaultOption, TOPIC_FILTER_PARAM } from './util'
import { URLSearchParams } from 'url'
import { TopicCount } from '@west-australian-newspapers/publication-types'
import { FilterEventProps, getEventName } from '../filter-events-util'

interface TopicFilterProps extends FilterEventProps {
    topicCounts?: TopicCount[]
}

const getTopicFilterString = (
    queryParams: URLSearchParams,
    topic: string,
): string | undefined => {
    const topicFilter = queryParams.get(TOPIC_FILTER_PARAM)
    const topicArray = topicFilter ? topicFilter.split(',') : []

    // add or remove the topic
    const topicIndex = topicArray.indexOf(topic.toLowerCase())
    topicIndex !== -1
        ? topicArray.splice(topicIndex, 1)
        : topicArray.push(topic.toLowerCase())

    return topicArray.length > 0 ? topicArray.join() : undefined
}

const getTopicDisplayString = (
    topic: string,
    topicCounts: TopicCount[] | undefined,
): string => {
    const count = topicCounts
        ? topicCounts.find(
              (topicCount) => topicCount.topic === topic.toLowerCase(),
          )?.count
        : undefined
    const countString = count !== undefined ? ` (${count})` : ''
    return `${topic}${countString}`
}

export const TopicFilter = ({
    topicCounts,
    onEvent,
    dataLayerEventName,
}: TopicFilterProps) => {
    const history = useHistory()
    const { queryParams, pathname } = useQueryParams()

    const onTopicLinkClicked = (topic: string) => {
        // remove the page parameter
        queryParams.delete('page')

        if (topic === 'All') {
            // delete the parameter all together
            queryParams.delete(TOPIC_FILTER_PARAM)
        } else {
            const topicStr = getTopicFilterString(queryParams, topic)
            topicStr
                ? queryParams.set(TOPIC_FILTER_PARAM, topicStr)
                : queryParams.delete(TOPIC_FILTER_PARAM)
        }

        history.push({
            pathname: pathname,
            search: queryParams.toString(),
        })

        const eventName = getEventName(TOPIC_FILTER_PARAM)

        if (eventName) {
            onEvent({
                type: dataLayerEventName,
                originator: 'TopicFilter',
                payload: {
                    name: eventName,
                    value: queryParams.get(TOPIC_FILTER_PARAM) ?? defaultOption,
                },
            })
        }
    }

    const getSelectedState = (topic: string): ActiveState => {
        if (topic === 'All') {
            return queryParams.get(TOPIC_FILTER_PARAM) ? 'default' : 'active'
        } else {
            const topicFilter = queryParams.get(TOPIC_FILTER_PARAM)
            const topicArray = topicFilter ? topicFilter.split(',') : []
            return topicArray.indexOf(topic.toLowerCase()) !== -1
                ? 'active'
                : 'default'
        }
    }

    return (
        <TopicFilterContainer>
            <StyledLabel>Topic</StyledLabel>
            <TopicLinkWrapper>
                <TopicLinkList>
                    {articleTopics.map((topic, i) => (
                        <TopicLinkListItem key={i}>
                            <TopicLink
                                state={getSelectedState(topic)}
                                onClick={() => onTopicLinkClicked(topic)}
                            >
                                {getTopicDisplayString(topic, topicCounts)}
                            </TopicLink>
                        </TopicLinkListItem>
                    ))}
                </TopicLinkList>
            </TopicLinkWrapper>
        </TopicFilterContainer>
    )
}
