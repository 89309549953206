import React from 'react'
import {
    CardEvents,
    CollectionEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { cx } from '@emotion/css'
import { StyledInlineContentCardsContainer } from './InlineContentCards.styled'
import { InlineContentCard } from '../../cards/InlineContentCard/InlineContentCard'

interface InlineRelatedContentCardsProps {
    items: MaybeLoaded<PublicationCardItem[]>
    expectedCards: number
    onEvent: (event: CardEvents | CollectionEvent) => void
}

export const InlineRelatedContentCards: React.FC<InlineRelatedContentCardsProps> =
    (props) => {
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)

        return (
            <StyledInlineContentCardsContainer
                className={cx('hide-print')}
                numberOfCards={items.length}
            >
                {items.map((item, index) => {
                    const key = (item.loaded && item.result.id) || index

                    if (item.loaded) {
                        return (
                            <InlineContentCard
                                key={key}
                                item={item}
                                onEvent={props.onEvent}
                                cardNumber={index}
                                numberOfCards={items.length}
                            />
                        )
                    }
                })}
            </StyledInlineContentCardsContainer>
        )
    }
