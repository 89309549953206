import { getAdSize } from '@news-mono/web-common'
import { VisiblityBreakpoint } from '../../advertising/AdUnit/AdUnit.styled'
import { AdSlotProps } from './AdUnitWrapper'

export function getUnitVisibilityBreakpoints(
    adSlotProps: AdSlotProps,
    includePlaceholders: boolean,
): VisiblityBreakpoint[] {
    const visibilityBreakpoints =
        adSlotProps.adState.breakpoints.map<VisiblityBreakpoint>(
            (breakpoint) => {
                const unit = adSlotProps.adState.unitLookup(
                    adSlotProps.unitId,
                    breakpoint,
                )
                if (!unit) {
                    return {
                        breakpoint,
                        visible: false,
                        placeholderHeight: undefined,
                    }
                }
                const adSize = getAdSize(unit.sizeMapping, breakpoint)
                const visible = !!adSize

                return {
                    breakpoint,
                    visible,
                    placeholderHeight:
                        unit && includePlaceholders
                            ? unit.placeholderHeight
                            : undefined,
                }
            },
        )

    return visibilityBreakpoints
}

export function calculateMargin(
    desiredMargin: number,
    containerWidth: number,
    containerBreakpoint: number,
    viewportWidth: number,
) {
    const pctTotalWidth = containerWidth + desiredMargin * 2
    if (
        viewportWidth < containerBreakpoint ||
        desiredMargin * 2 > viewportWidth - containerBreakpoint
    ) {
        return -Math.floor((viewportWidth - containerWidth) / 2)
    }
    if (pctTotalWidth > viewportWidth) {
        return -Math.floor((viewportWidth - pctTotalWidth) / 2)
    }
    return -desiredMargin
}

export function shouldShowBackground() {
    if (typeof window === 'undefined' || typeof URL === 'undefined') {
        return
    }
    // Below will match on ads_container being present on the href either after a ? or &
    const showBackground = /[&?]ads_container/.test(window.location.href)
    return showBackground
}
