import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getYourMoneyRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        // Personal Finance
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    [
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                    ],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Personal Finance',
                    headingUrl: '/lifestyle/personal-finance',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'personal-finance',
                    topics: ['lifestyle/personal-finance'],
                    includeSubTopics: false,
                    offset: 0,
                    pageSize: 6,
                },
            },
        }),
        // Pensions
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Pensions',
                    headingUrl: '/lifestyle/pensions',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/pensions'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        // Superannuation
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Superannuation',
                    headingUrl: '/lifestyle/superannuation',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/superannuation'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.nestedComposition({
            type: 'janus',
            props: {
                stretchColumns: true,
                verticalSpacing: 'lg',
                hasBorder: true,
            },
            contentAreas: {
                // Retirement Living
                left: [
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: [
                                    'lifestyle/retirement-villages-downsizing',
                                ],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            sectionHeader: {
                                heading: 'Retirement Living',
                                headingUrl:
                                    '/lifestyle/retirement-villages-downsizing',
                            },
                            hasBorder: false,
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                            },
                        },
                    }),
                ],
                right: [
                    // Aged Care
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['lifestyle/aged-care'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            sectionHeader: {
                                heading: 'Aged Care',
                                headingUrl: '/lifestyle/aged-care',
                            },
                            hasBorder: false,
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: { type: 'Landscape-Portrait' },
                        },
                    }),
                ],
            },
        }),

        ...billboardLeaderboardMrec('two'),

        layout.nestedComposition({
            type: 'janus',
            props: {
                stretchColumns: true,
                verticalSpacing: 'lg',
                hasBorder: true,
            },
            contentAreas: {
                // Retirement Investments
                left: [
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['lifestyle/retirement-investments'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            sectionHeader: {
                                heading: 'Retirement Investments',
                                headingUrl: '/lifestyle/retirement-investments',
                            },
                            hasBorder: false,
                            verticalSpacing: 'sm',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: { type: 'Landscape-Portrait' },
                        },
                    }),
                ],
                right: [
                    // Insurance & Estate Planning
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['lifestyle/insurance-estate-planning'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            sectionHeader: {
                                heading: 'Insurance & Estate Planning',
                                headingUrl:
                                    '/lifestyle/insurance-estate-planning',
                            },
                            verticalSpacing: 'sm',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            hasBorder: false,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: { type: 'Landscape-Portrait' },
                        },
                    }),
                ],
            },
        }),

        ...billboardLeaderboardMrec('three'),

        // Latest
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                loadMoreEnabled: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },

                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Latest',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/your-money'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 8,
                    },
                },
            },
        }),
    ]
}
