import React from 'react'
/**
 * Taken from https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * It will return the value from the previous render pass, useful for migrating away from componentDidReceiveProps
 * */
export function usePrevious<T>(value: T) {
    const ref = React.useRef<T>(value)
    React.useEffect(() => {
        ref.current = value
    })
    return ref.current
}
