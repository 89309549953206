import styled, { CSSObject } from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { StyledAflPlayerStatsWidgetTableWrapper } from '../../afl-match-centre/AflPlayerStatsWidget/AflPlayerStatsWidget.styled'
import { breakpoint, calcRem, themedValue } from '../../__styling'
import { Breakpoints } from '../../__styling/settings/breakpoints'
import { ContainerWidth, metrics } from '../../__styling/settings/metrics'
import { DoubletBreakPointProps, DoubletSpacingProps } from './Doublet'

export interface StyledDoubletProps extends DoubletSpacingProps {
    containerWidth?: ContainerWidth
    breakpoints?: DoubletBreakPointProps
    rowBreakpoint?: keyof Breakpoints
}

const getStylesFromProps = (
    props: StyledDoubletProps,
    kind: Product,
): Array<CSSObject | false | undefined> => {
    const margins = metrics[kind].margins

    const horizontalSpacing =
        props.horizontalSpacing && calcRem(margins[props.horizontalSpacing])
    const verticalSpacing =
        props.verticalSpacing && calcRem(margins[props.verticalSpacing])
    const horizontalGutters =
        props.horizontalGutters && calcRem(margins[props.horizontalGutters])
    const topVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[0]])
    const bottomVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[1]])

    return [
        {
            marginLeft: horizontalSpacing,
            marginRight: horizontalSpacing,
            marginBottom: verticalSpacing,
            marginTop: verticalSpacing,
            paddingLeft: horizontalGutters,
            paddingRight: horizontalGutters,
            paddingTop: topVerticalGutters,
            paddingBottom: bottomVerticalGutters,
        },
    ]
}

type BreakpointKey = keyof Breakpoints

export const StyledDoublet = styled('div')<StyledDoubletProps>(
    ({ theme, rowBreakpoint }) => ({
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: themedValue(theme, {
            thewest: calcRem(24),
            fallback: undefined,
        }),
        [breakpoint(rowBreakpoint ? rowBreakpoint : 'md')]: {
            flexDirection: 'row',
            columnGap: themedValue(theme, {
                sevennews: calcRem(50),
                perthnow: calcRem(16),
                fallback: calcRem(24),
            }),
        },
    }),
    ({ theme, breakpoints }) =>
        ({
            theme,
            verticalSpacing,
            horizontalGutters,
            verticalGutters,
            containerWidth,
            horizontalSpacing,
        }) => {
            const breakpointStyles = Object.keys(
                breakpoints || {},
            ).reduce<CSSObject>((styles, key) => {
                const passedBreakpoints = breakpoints as DoubletBreakPointProps
                const props = passedBreakpoints[key as BreakpointKey]
                if (props) {
                    styles[breakpoint(key as BreakpointKey)] =
                        getStylesFromProps(props, theme.kind)
                }
                return styles
            }, {})

            return [
                ...getStylesFromProps(
                    {
                        horizontalGutters,
                        verticalGutters,
                        verticalSpacing,
                        horizontalSpacing,
                    },
                    theme.kind,
                ),
                {
                    maxWidth: containerWidth,
                },
                breakpointStyles,
            ]
        },
)

export interface StyledDoubletColumnProps {
    rowBreakpoint?: keyof Breakpoints
}

export const StyledDoubletColumn = styled('div')<StyledDoubletColumnProps>(
    ({ rowBreakpoint }) => ({
        flexBasis: '100%',
        [breakpoint(rowBreakpoint ? rowBreakpoint : 'md')]: {
            width: '48%',
        },
        [`${StyledAflPlayerStatsWidgetTableWrapper}`]: {
            [breakpoint('md')]: {
                overflowX: 'scroll',
                maxHeight: calcRem(680),
            },
        },
    }),
)
