import React, { useEffect } from 'react'
import { getShouldLoadProps } from './getShouldLoadProps'
import { addListener, removeListener } from './global-dom-events'
import { ShouldLoadProps } from './is-viewable'

interface ImpressionAvailableProps {
    available: () => void
    /** True when collection is still loading */
    loading: boolean
    /** 50 for element to be 50% visible in viewport */
    percentageVisibleThreshold?: number
    debug?: debug.Debugger
}

/**
 * ImpressionAvailable now utilises extensive logging for debugging purposes when implementing into new
 * components. In order to utilise this simply just pass through a debugger object. These can be created
 * to create a private channel for each debugging fields, so that you're not spammed by other components.
 *
 * In order to set your console to view this debug values just simply run `window.localStorage.debug = '{customId}'`
 * in your web console.
 *
 * If you have multiple components within the same page, you can create a parent debug object, and then 'extend' the
 * object for each different component. In order to view all logs for this you can run `window.localStorage.debug = '{parentId}.*'`
 * or to view each individual component debugging, run `window.localStorage.debug = '{parentId}:{childId}'`.
 *
 * NOTE: You need to refresh your page in order to view console logs with the custom debug filter on.
 */
export const useImpressionAvailable = ({
    available,
    loading,
    percentageVisibleThreshold = 50,
    debug,
}: ImpressionAvailableProps) => {
    const lazyLoadRef = React.useRef<HTMLDivElement | null>(null)
    const impressionTriggered = React.useRef<boolean>()

    function shouldTrigger(shouldLoadProps: ShouldLoadProps) {
        return (
            !impressionTriggered.current &&
            !loading &&
            shouldLoadProps.percentVisible > percentageVisibleThreshold
        )
    }

    debugImpression(
        'rendering ImpressionAvailable element with loading at:',
        debug,
        loading,
        lazyLoadRef.current?.getBoundingClientRect(),
    )

    React.useEffect(() => {
        debugImpression(
            're-rendering ImpressionAvailable element with loading at:',
            debug,
            loading,
        )

        if (!lazyLoadRef.current) {
            debugImpression(
                `innerRef passed by 'useLazyLoading' has not been added to an element`,
                debug,
            )
            return
        }

        if (shouldTrigger(getShouldLoadProps(lazyLoadRef.current))) {
            debugImpression(
                'within the viewport on the initial loading state!',
                debug,
            )

            impressionTriggered.current = true
            setTimeout(() => {
                available()
            })
        }

        function detectInViewport() {
            if (
                !lazyLoadRef.current ||
                !lazyLoadRef.current.getBoundingClientRect
            ) {
                debugImpression(
                    'current is false, or current bounding client rect is undefined',
                    debug,
                )
                return
            }

            const shouldLoadProps = getShouldLoadProps(lazyLoadRef.current)
            const shouldTriggerImpression = shouldTrigger(shouldLoadProps)

            debugImpression(
                'checking...',
                debug,
                shouldLoadProps,
                shouldTriggerImpression,
            )

            if (shouldTriggerImpression) {
                debugImpression(
                    'detected within the viewport! Triggering!',
                    debug,
                )
                impressionTriggered.current = true
                setTimeout(() => {
                    available()
                })
            }
        }

        addListener('scroll', detectInViewport)
        addListener('resize', detectInViewport)

        return () => {
            debugImpression('useEffect ending, removing listeners', debug)
            removeListener('scroll', detectInViewport)
            removeListener('resize', detectInViewport)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    return lazyLoadRef
}

export const ImpressionAvailable: React.FC<
    ImpressionAvailableProps & {
        children: (ref: React.RefObject<any>) => React.ReactNode
    }
> = (props) => {
    const lazyLoadRef = useImpressionAvailable(props)
    return props.children(lazyLoadRef) as any
}
ImpressionAvailable.displayName = 'ImpressionAvailable'

const debugImpression = (
    message: string,
    debug?: debug.Debugger,
    ...args: any[]
) => {
    if (debug !== undefined) {
        debug(message, args)
    }
}
