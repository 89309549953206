import React from 'react'

export function filterComponent<Props extends object, K extends string>(
    Component: React.ComponentClass<Props> | React.FC<Props> | string,
    filteredProps: K[],
) {
    const FilterPropsComponent: React.FC<Props & { [key in K]: any }> = (
        props: Props,
    ): React.ReactElement<any> => {
        const newProps: { [key: string]: any } = { ...(props as object) }

        filteredProps.forEach((propName) => {
            delete newProps[propName]
        })

        return <Component {...(newProps as Props)} />
    }
    FilterPropsComponent.displayName = `FilterPropsComponent(${filteredProps.join(
        ',',
    )})`
    return FilterPropsComponent
}
