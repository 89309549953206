import { AppState, isPuzzlePage } from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
    StyledMain,
    StyledSidebar,
    StyledZeus,
} from '../../compositions/Zeus/Zeus.styled'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'

export interface ZeusProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarContent: React.ReactElement<any>
    /** Stacks sidebar content underneath the main content, also modifies the breakpoint at which the sidebar collapses */
    stackSidebarContent?: boolean
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | keyof ThemeMargins
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
}

export const Zeus: React.FC<ZeusProps> = ({
    mainContent,
    sidebarContent,
    hasBackgroundFill,
    horizontalGutters,
    verticalGutters,
    verticalSpacing,
    containerWidth,
    stackSidebarContent = false,
}) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )
    const location = useLocation()

    const isPuzzlePageAppRendition =
        renditionType === 'app' && isPuzzlePage({ location })

    return (
        <StyledZeus
            stackSidebarContent={stackSidebarContent}
            hasBackgroundFill={hasBackgroundFill}
            horizontalGutters={horizontalGutters}
            verticalGutters={verticalGutters}
            verticalSpacing={verticalSpacing}
            containerWidth={containerWidth}
            hideSidebar={isPuzzlePageAppRendition}
        >
            <StyledMain hasBackgroundFill={hasBackgroundFill}>
                {mainContent}
            </StyledMain>
            <StyledSidebar stackSidebarContent={stackSidebarContent}>
                {sidebarContent}
            </StyledSidebar>
        </StyledZeus>
    )
}
Zeus.displayName = 'Zeus'
