import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'

/** The props utilised to configure the olympics collection layout */
interface OlympicsCollectionHeaderProps {
    /** Default's to a value of true */
    removeTopBottomMargin?: boolean
}
export const StyledOlympicsCollectionParent = styled(
    'div',
)<OlympicsCollectionHeaderProps>(({ theme, removeTopBottomMargin }) => [
    {
        backgroundColor: colors.olympics.background,
        display: 'flex',
        position: 'relative',

        // Mobile styling
        width: '100%',
        left: '0%',
        paddingLeft: '0%',
        paddingRight: '0%',

        marginTop: removeTopBottomMargin ? 0 : calcRem(36),
        marginBottom: removeTopBottomMargin ? 0 : calcRem(68),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '102%',
            left: '-1%',
            paddingLeft: '1%',
            paddingRight: '1%',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        backgroundColor: 'inherit',

        [breakpoint('sm')]: {
            width: '100%',
            left: '0%',
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
])

export const StyledOlympicsCollectionContent = styled('div')(({ theme }) => [
    {
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',

        // Mobile styling
        padding: calcRem(
            0,
            theme.margins.outerMargin,
            0,
            theme.margins.outerMargin,
        ),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            padding: calcRem(
                0,
                theme.margins.outerMargin,
                theme.margins.outerMargin,
                theme.margins.outerMargin,
            ),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        padding: 0,
        gap: calcRem(8),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            gap: calcRem(16),
            padding: 0,
        },
    },
])

export const StyledOlympicsCollectionTopRow = styled('div')(({ theme }) => [
    {
        display: 'inline-flex',
        position: 'relative',
        width: '100%',

        // Mobile styling
        flexDirection: 'column',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            flexDirection: 'row',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        gap: calcRem(8),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            gap: calcRem(16),
        },
    },
])

export const StyledOlympicsCollectionTopRowSub = styled('div')(({ theme }) => [
    {
        display: 'inline-flex',
        position: 'relative',
        width: '100%',

        // Mobile styling
        flexDirection: 'column',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '25%',
            flexDirection: 'row',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        gap: calcRem(8),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'auto',
            maxHeight: 'none',
            width: '50%',
            flexDirection: 'column',
        },

        // Laptop or bigger styling
        [breakpoint('md')]: {
            height: calcRem(420),
            maxHeight: calcRem(420),
            width: '50%',
            flexDirection: 'column',
        },
    },
])

export const StyledOlympicsCollectionBottomRow = styled('div')(({ theme }) => [
    {
        display: 'inline-flex',
        position: 'relative',
        width: '100%',

        // Mobile styling
        height: 'fit-content',
        flexDirection: 'column',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
            flexDirection: 'row',
        },
        // Laptop or bigger styling
        [breakpoint('md')]: {
            height: calcRem(400),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        gap: calcRem(16),

        // Laptop or bigger styling
        [breakpoint('md')]: {
            height: calcRem(420),
        },
    },
])

export const StyledOlympicsTallyContainer = styled('div')(({ theme }) => [
    {
        display: 'flex',

        // Mobile styling
        margin: calcRem(12, 0, 12, 0),
        borderLeft: 'none',
        paddingLeft: 0,
        width: '100%',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '25%',
            margin: calcRem(18, 0, 12, 0),
            borderLeft: `${calcRem(0.5)} solid ${colors.olympics.border}`,
            paddingLeft: calcRem(8),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        backgroundColor: colors.olympics.background,

        // Mobile styling
        margin: 0,
        padding: calcRem(5),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '50%',
            margin: 0,
            borderLeft: 'none',
            height: calcRem(420),
        },
    },
])
