import loadable from '@loadable/component'
import {
    PlayerEvent,
    VideoDetails,
    VideoQueueItemMeta,
} from '@news-mono/web-common'
import React from 'react'
import { MediaMode } from '../../cards/CardMedia/CardMedia'
import {
    AutoplayOptions,
    GetVideoQueue,
    VideoOptions,
} from '../../content/Video/PlayerInterface'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'
import {
    ArticleImage,
    FigcaptionRenderOptions,
} from '../../publication/ArticleImage/ArticleImage'
import { createRenderTarget } from '../../render-target'
import AmpArticleVideo from './ArticleVideo.amp'
import { RssArticleVideo } from './ArticleVideo.rss'

export const LazyAmpArticleVideo = loadable(() => import('./ArticleVideo.amp'))

export const LazyWebArticleVideo = loadable(() => import('./ArticleVideo.web'))

export interface VideoArticle {
    isHeroVideo?: boolean
    isVideoStickyEnabled?: boolean
    showPlaylist?: boolean
    publicationPathname?: string
}

export interface ArticleVideoProps extends VideoArticle {
    showTitles: boolean
    videoDetails: VideoDetails
    videoOptions: VideoOptions
    autoplayOptions: AutoplayOptions | false
    autoplayNextOptions: AutoplayOptions | false
    pauseOverlay: boolean
    getVideoQueue: GetVideoQueue
    byline?: string
    captionText?: string
    credit?: string
    kind?: string
    figcaptionRenderOptions?: FigcaptionRenderOptions
    onEvent: (event: PlayerEvent) => void
    playButtonText?: string
    smallPlayButton?: boolean
    mediaMode?: MediaMode
    isVideoHub?: boolean
    publicUrl?: string
    onPlayNextVideo?: (nextVideo: VideoQueueItemMeta) => void
    adElement?: JSX.Element
    setVideoRendered?: () => void
}

const ArticleVideoRenderTarget = createRenderTarget<ArticleVideoProps>(
    'ArticleVideo',
    {
        web: LazyWebArticleVideo,
        amp: AmpArticleVideo,
        rss: RssArticleVideo,
        preview: LazyWebArticleVideo,
        app: LazyWebArticleVideo,
    },
)
export const ArticleVideo = (props: ArticleVideoProps) => {
    return (
        <FeatureToggle
            feature={'brightcove-player'}
            on={() => <ArticleVideoRenderTarget {...props} />}
            off={() => {
                if (!props.videoDetails.posterImage) {
                    return null
                }
                return (
                    <ArticleImage
                        image={props.videoDetails.posterImage}
                        figCaption={{
                            byline: props.byline,
                            captionText: props.captionText,
                            credit: props.credit,
                            kind: 'image',
                        }}
                        figcaptionRenderOptions={props.figcaptionRenderOptions}
                        isHeroImage={true}
                    />
                )
            }}
        />
    )
}

ArticleVideo.displayName = 'ArticleVideo'
