import styled from '@emotion/styled'
import { TheWestPalette, tokens } from '@news-mono/design-tokens'
import { rgba } from 'polished'
import { calcRem } from '../../../../__styling'
import { zIndex } from '../../../../__styling/settings/z-index'
import { StyledSubscriptionPackageButton2022 } from './SubscriptionPackage.styled'

export const StyledSubscriptionPrompt = styled.div(
    {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        backdropFilter: 'blur(3px)',
        overflowY: 'scroll',
        overscrollBehaviorY: 'contain',
        zIndex: zIndex.thewest.flyoutNav,
        textAlign: 'center',

        '& > div': {  // Affects external of the div
            margin: '2rem auto auto auto',
            borderRadius: calcRem(8),
            padding: '1.5rem',
            width: 650,
            maxWidth: '90vw',
        },
    },
    ({ theme }) => ({
        backgroundColor: rgba(
            (theme?.colors.palette as TheWestPalette)?.silver ||
                tokens.thewest.colors.palette.silver,
            0.9,
        ),
        fontFamily: theme.fonts.sansSerif,
        '& > div': {
            backgroundColor: theme.colors.background.primary,
        },
    }),
)

export const StyledSubscriptionPromptMenuBar = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
})

export const StyledSubscriptionPromptCloseButton = styled.div({
    cursor: 'pointer',
})

export const StyledSubscriptionPromptButtonWrapper = styled.div({
    maxWidth: 300,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    overflowY: 'hidden',

    '& > div': {
        padding: '0',
    },
})

export const StyledSubscriptionPromptSecondaryButton = styled(
    StyledSubscriptionPackageButton2022
)({
    backgroundColor: tokens.thewest.colors.palette.transparent,
    color: tokens.thewest.colors.palette.westblue,
    border: 'solid',
    marginTop: calcRem(10),

    borderColor: tokens.thewest.colors.palette.westblue,

    '&:hover': {
        backgroundColor: tokens.thewest.colors.palette.westblue
    },
})
