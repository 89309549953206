import { useUniqueIdOnReRender } from '@news-mono/web-common'
import React from 'react'

export interface IconPremiumProps {
    title?: string
    className?: string
    hasContainer?: boolean
}

// Due to a safari re-rendering bug when logging into the site, after visiting it, svg's are rendered incorrectly
// This is why we need to re-generate id's on re-renders, getUniqueIdAfterReRender will only generate ids' on following
// client side re-renders and not on the initial ssr->client hydration render
// this is only required on this svg due to the linearGradients applied to the paths via fill={`url(#${id})`}
export const IconPremium: React.FC<IconPremiumProps> = ({
    title,
    className,
    hasContainer,
}) => {
    const baseId = useUniqueIdOnReRender('ico-prem')
    const fillId1 = `f1-${baseId}`
    const fillId2 = `f2-${baseId}`

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 260 260"
            className={className}
            role="img"
        >
            <title>{title || 'Premium'}</title>
            {hasContainer && <circle cx="127" cy="127" r="126.4" fill="#FFF" />}
            <defs>
                <linearGradient
                    id={fillId1}
                    x1="83.6%"
                    x2="15.9%"
                    y1="16.4%"
                    y2="84.1%"
                >
                    <stop offset="0%" stopColor="#043DE2" />
                    <stop offset="100%" stopColor="#1257E0" />
                </linearGradient>
                <linearGradient
                    id={fillId2}
                    x1="104.4%"
                    x2="3.3%"
                    y1="4.3%"
                    y2="104.9%"
                >
                    <stop offset="0%" stopColor="#F400AB" />
                    <stop offset="100%" stopColor="#AA01E1" />
                </linearGradient>
            </defs>
            <path
                fill={`url(#${fillId1})`}
                d="M216.6 124.8c-.7-15.5-7.4-20.4-12.4-23.4s-11-5.2-31.8-5.2c-12.4 0-16-12.3-16-22.2 0-9.9 1.3-17.9-6-26.4A33 33 0 0 0 127.8 37l-1-.1-1 .1s-14.1.2-22.7 10.5C96 56 97.1 64 97.1 74c0 9.9-3.5 22.2-16 22.2-20.7 0-26.8 2.2-31.8 5.2s-11.6 8-12.3 23.4v3.8c.7 15.4 7.3 20.3 12.3 23.3 5 3 11.1 5.2 31.8 5.2 12.5 0 16 12.3 16 22.2 0 10-1.2 18 6 26.5a33 33 0 0 0 23.7 10.5h1s14-.3 22.6-10.5c7.3-8.6 6-16.6 6-26.5 0-9.9 3.6-22.2 16-22.2 20.7 0 26.8-2.2 31.8-5.2s11.7-7.9 12.4-23.3v-2-1.8z"
            />
            <path
                fill={`url(#${fillId2})`}
                d="M156.4 74c0-9.9 1.3-17.9-6-26.4A33 33 0 0 0 127.8 37l-1-.1-1 .1s-14.1.2-22.7 10.5C96 56 97.1 64 97.1 74c0 9.9-3.5 22.2-16 22.2-20.7 0-26.8 2.2-31.8 5.2s-11.6 8-12.3 23.4v3.8c.7 15.4 7.2 20.2 12.3 23.3 5.8 3.5 14.4 5.4 31.8 5.2 29.5-.3 75.3-48.4 75.3-83.1z"
            />
        </svg>
    )
}
IconPremium.displayName = 'IconPremium'
