import { Product } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const telethonCountdown = layout.nestedComposition({
    type: 'box',
    props: {
        verticalSpacing: 'lg',
    },
    contentAreas: {
        main: [
            layout.component({
                type: 'telethon-countdown',
                feature: 'telethon-countdown',
                props: {
                    product: Product.TheWest,
                    targetDate: new Date('Sat Oct 19 2024 19:00:00 GMT+0800'),
                },
            }),
        ],
    },
})
