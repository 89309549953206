import React from 'react'
import { addListener, removeListener } from '../__helpers/global-dom-events'
import { getShouldLoadProps } from './getShouldLoadProps'

export interface ShouldLoadProps {
    percentVisible: number // 0-100
    distanceFromViewport: number
    viewportHeight: number
}

interface State {
    viewable: boolean
}

export interface IsViewableProps {
    // The % between 1-100 which has to be visible to be considered viewable
    viewableThresholdPercent: number
    children: (
        ref: React.RefObject<any>,
        isViewable: boolean,
        threshold: number,
    ) => React.ReactNode
}

export class IsViewable extends React.Component<IsViewableProps, State> {
    mounted = false
    innerRef = React.createRef<HTMLElement>()
    displayName = `IsViewable`
    state: State = { viewable: false }
    timer: any | undefined

    componentDidMount() {
        this.mounted = true

        if (!this.innerRef.current) {
            return
        }

        const viewable =
            getShouldLoadProps(this.innerRef.current).percentVisible >
            this.props.viewableThresholdPercent

        this.setState({
            viewable,
        })

        this.detectInViewport()

        addListener('scroll', this.detectInViewport)
        addListener('resize', this.detectInViewport)
    }

    componentWillUnmount() {
        this.mounted = false
        removeListener('scroll', this.detectInViewport)
        removeListener('resize', this.detectInViewport)
    }

    // This code can likely all be removed once all browsers support IntersectionObserver
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Browser_compatibility
    // https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/431/overview
    detectInViewport = () => {
        const refClientHeight = this.innerRef.current
            ? this.innerRef.current.clientHeight
            : 0

        if (
            !this.innerRef.current ||
            !this.innerRef.current.getBoundingClientRect ||
            this.state.viewable ||
            refClientHeight <= 0
        ) {
            return
        }
        const percentViewable = getShouldLoadProps(
            this.innerRef.current,
        ).percentVisible

        if (
            percentViewable >= this.props.viewableThresholdPercent &&
            !this.state.viewable
        ) {
            if (this.timer) {
                return
            }
            // Schedule state change for after all components have determined if they are on screen
            // See the comment about getBoundingClientRect()
            this.timer = setTimeout(() => {
                this.timer = undefined
                // Only update state if we haven't been unmounted while waiting for the timeout
                if (this.mounted) {
                    this.setState({ viewable: true })
                }
            }, 1000)
        }

        if (
            percentViewable < this.props.viewableThresholdPercent &&
            this.timer
        ) {
            clearTimeout(this.timer)
        }
    }

    render() {
        return this.props.children(
            this.innerRef,
            this.state.viewable,
            this.props.viewableThresholdPercent,
        )
    }
}
