import React from 'react'

export const RSSIcon: React.FC<{}> = () => (
    <svg
        viewBox="0 0 24 32"
        width="24"
        height="32"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon--rss"
    >
        <title>rss</title>
        <path d="M4 20c-2.208 0-4 1.793-4 4s1.792 4 4 4 4-1.793 4-4-1.792-4-4-4zm0-8s-2 .063-2 2 2 2 2 2c4.418 0 8 3.582 8 8 0 0 0 2 2 2s2-2 2-2c0-6.625-5.374-12-12-12zm0-8S2 4 2 6s2 2 2 2c8.836 0 16 7.164 16 16 0 0 0 2 2 2s2-2 2-2C24 12.956 15.047 4 4 4z" />
    </svg>
)
