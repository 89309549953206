import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { breakpoints } from '../../..'
/**
 *  Returns current breakpoint at current viewport size eg. 'xs'
 */
export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState<string>('xxs')

    useEffect(() => {
        /* Build breakpoint map from shared breakpoints object */
        const breakpointsMap = new Map(
            Object.entries(breakpoints).sort((a, b) => a[1] - b[1]),
        )
        const handleResize = () => {
            const windowWidth = window.innerWidth
            let lastBreakpointValue = 'xxs'

            for (const [size, value] of breakpointsMap) {
                if (windowWidth > value) {
                    lastBreakpointValue = size
                }
                setBreakpoint(lastBreakpointValue)
            }
        }
        /* Calculate on Mount */
        handleResize()
        window.addEventListener('resize', debounce(handleResize, 200))

        return () =>
            window.removeEventListener('resize', debounce(handleResize, 200))
    }, [])

    return breakpoint
}

/**
 * Returns breakpoint max size at current viewport size in px.
 */
export const useBreakpointSize = () => {
    const [breakpoint, setBreakpoint] = useState<number>(480)

    useEffect(() => {
        /* Build breakpoint map from shared breakpoints object */
        const breakpointsMap = new Map(
            Object.entries(breakpoints).sort((a, b) => a[1] - b[1]),
        )
        const handleResize = () => {
            const windowWidth = window.innerWidth
            let lastBreakpointValue = 480

            for (const [size, value] of breakpointsMap) {
                if (windowWidth > value) {
                    lastBreakpointValue = value
                }
                setBreakpoint(lastBreakpointValue)
            }
        }
        /* Calculate on Mount */
        handleResize()
        window.addEventListener('resize', debounce(handleResize, 400))

        return () =>
            window.removeEventListener('resize', debounce(handleResize, 400))
    }, [])

    return breakpoint
}
