import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { ResponsivePictureLayout } from '@news-mono/component-library'
import { Store } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const businessSegment = (store: Store) => {
    const isCNBCEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'the-west-cnbc',
        false,
    )
    const isTradingUpHomeEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'the-west-cnbc',
        false,
    )

    return [
        layout.component({
            type: 'sierra',
            props: {
                verticalSpacing: 'gridGap',
                fixedRatios: ['16:9'],
                heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
                cardOrientation: {
                    type: 'Landscape-Portrait',
                    maxBreakpoint: 'md',
                },
                cardLayout: ['the-west-hero', 'hero-support'],
                sectionHeader: {
                    heading: 'Business',
                    headingUrl: '/business',
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'business',
                    offset: 0,
                    pageSize: 2,
                },
            },
        }),
        layout.component({
            type: 'uniform',
            props: {
                verticalSpacing: 'xl',
                section: 'default',
                hasBackground: true,
                cardLayout: [
                    ['large-card'],
                    ['large-card-mdNoImage', 'large-card-mdNoImage'],
                    ['large-card'],
                    { type: 'november', listLength: 5, minimumCards: 5 },
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'business',
                    offset: 2,
                    pageSize: 9,
                },
                hasBorder: isTradingUpHomeEnabled ? true : !isCNBCEnabled,
            },
        }),
    ]
}
