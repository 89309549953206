import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import {
    Breakpoints,
    metrics,
    ResponsivePictureLayout,
} from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { get as getCookie, set as setCookie } from 'js-cookie'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'

export const desktopLandingBanner1x = require('./2024/assets/bannerDesktop@1x.jpg')
export const desktopLandingBanner2x = require('./2024/assets/bannerDesktop@2x.jpg')
export const desktopLandingBannerTop14_1x = require('./2024/assets/top14/bannerDesktop@1x.jpg')
export const desktopLandingBannerTop14_2x = require('./2024/assets/top14/bannerDesktop@2x.jpg')
export const desktopLandingBannerTop50_1x = require('./2024/assets/top50/bannerDesktop@1x.jpg')
export const desktopLandingBannerTop50_2x = require('./2024/assets/top50/bannerDesktop@2x.jpg')

export const mobileLandingBanner1x = require('./2024/assets/bannerMobile@1x.jpg')
export const mobileLandingBanner2x = require('./2024/assets/bannerMobile@2x.jpg')
export const mobileLandingBannerTop14_1x = require('./2024/assets/top14/bannerMobile@1x.jpg')
export const mobileLandingBannerTop14_2x = require('./2024/assets/top14/bannerMobile@2x.jpg')
export const mobileLandingBannerTop50_1x = require('./2024/assets/top50/bannerMobile@1x.jpg')
export const mobileLandingBannerTop50_2x = require('./2024/assets/top50/bannerMobile@2x.jpg')

export const tabletLandingBanner1x = require('./2024/assets/bannerTablet@1x.jpg')
export const tabletLandingBanner2x = require('./2024/assets/bannerTablet@2x.jpg')
export const tabletLandingBannerTop14_1x = require('./2024/assets/top14/bannerTablet@1x.jpg')
export const tabletLandingBannerTop14_2x = require('./2024/assets/top14/bannerTablet@2x.jpg')
export const tabletLandingBannerTop50_1x = require('./2024/assets/top50/bannerTablet@1x.jpg')
export const tabletLandingBannerTop50_2x = require('./2024/assets/top50/bannerTablet@2x.jpg')

export const socialShareImage = require('./2024/assets/socialImage@1x.png')

export const bestAustralianYarnRoute: StaticRoutes<TheWestSection> = {
    '/lifestyle/best-australian-yarn': ({
        getAdTargeting,
        store,
        config,
        resolution,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/lifestyle/best-australian-yarn'

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'lifestyle/best-australian-yarn',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                'Best Australian Yarn 2024',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        const getSocialImageMeta = [
            {
                property: 'og:image',
                content: socialShareImage,
            },
            { name: 'twitter:image', content: socialShareImage },
            {
                property: 'og:image:height',
                content: '643',
            },
            {
                property: 'og:image:width',
                content: '1224',
            },
            {
                property: 'og:description',
                content: topic.seoDescription,
            },
            {
                property: 'twitter:description',
                content: topic.seoDescription,
            },
        ]

        const isEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'best-australian-yarn-route',
            true,
        )

        const baySeedVal = store.getState().misc.baySeed

        if (
            typeof window !== 'undefined' &&
            baySeedVal &&
            !getCookie('bayseed')
        ) {
            setCookie('bayseed', baySeedVal)
        }

        if (!isEnabled) {
            return null
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'topic',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: desktopLandingBanner2x,
                                    verticalGutters: ['unset', 'unset'],
                                    altText: 'Best Australian Yarn 2024',
                                    sources: getImageSources(
                                        store.getState().toggles,
                                    ),
                                },
                            }),
                            layout.nestedComposition({
                                type: 'thor',
                                props: {
                                    hasBackgroundFill: true,
                                    containerWidth: '100%',
                                    verticalGutters: true,
                                    horizontalGutters: true,
                                    verticalSpacing: 'lg',
                                    themeOverride: 'best-australian-yarn',
                                    hideFeatureHeader: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                verticalSpacing: 'gridGap',
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },
                                                cardLayout: [
                                                    'the-west-hero-lgNoBorder',
                                                    'hero-support',
                                                    'hero-support',
                                                ],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'best-australian-yarn-feature-page',
                                                    offset: 0,
                                                    pageSize: 3,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                feature: 'BAY-top-14',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 0.75,
                                    fixedRatios: ['16:9'],
                                    hasBackground: true,
                                    kickerMode: 'hidden',
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },
                                    hideByline: true,
                                    hideBottomBorder: true,
                                    sectionHeader: {
                                        heading: "The Winner's Circle",
                                    },
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'best-australian-yarn-feature-page',
                                        offset: 3,
                                        pageSize: 15,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                feature: 'BAY-top-50',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 0.75,
                                    fixedRatios: ['16:9'],
                                    hasBackground: true,
                                    kickerMode: 'hidden',
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                    },
                                    hideByline: true,
                                    hideBottomBorder: true,
                                    sectionHeader: {
                                        heading: 'The Top 50',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: [
                                            'lifestyle/best-australian-yarn/2024',
                                        ],
                                        includeSubTopics: false,
                                        paging: {
                                            page: 1,
                                            pageSize: 50,
                                        },
                                        random: true,
                                        random_offset: 0,
                                        random_size: 50,
                                        seed: baySeedVal,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: 'Latest News',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: [
                                            'lifestyle/best-australian-yarn',
                                        ],
                                        includeSubTopics: true,
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                            }),
                        ],
                        sidebar: [],
                    },
                }),
            ],
        }
    },
}

interface Source {
    src:
        | string
        | {
              src: string
              density: '2x' | '1x'
          }[]
    breakpoint?: keyof Breakpoints
}
const getImageSources = (toggles: TogglesReduxState): Source[] => {
    // Top 14 on
    const isTop14 = isFeatureEnabled(
        toFeatureState(toggles),
        'BAY-top-14',
        false,
    )
    // Top 50 on
    const isTop50 = isFeatureEnabled(
        toFeatureState(toggles),
        'BAY-top-50',
        false,
    )
    if (isTop14) {
        return [
            {
                breakpoint: 'md',
                src: [
                    {
                        src: desktopLandingBannerTop14_1x,
                        density: '1x',
                    },
                    {
                        src: desktopLandingBannerTop14_2x,
                        density: '2x',
                    },
                ],
            },
            {
                breakpoint: 'sm',
                src: [
                    {
                        src: tabletLandingBannerTop14_1x,
                        density: '1x',
                    },
                    {
                        src: tabletLandingBannerTop14_2x,
                        density: '2x',
                    },
                ],
            },
            {
                src: [
                    {
                        src: mobileLandingBannerTop14_1x,
                        density: '1x',
                    },
                    {
                        src: mobileLandingBannerTop14_2x,
                        density: '2x',
                    },
                ],
            },
        ]
    } else if (isTop50) {
        return [
            {
                breakpoint: 'md',
                src: [
                    {
                        src: desktopLandingBannerTop50_1x,
                        density: '1x',
                    },
                    {
                        src: desktopLandingBannerTop50_2x,
                        density: '2x',
                    },
                ],
            },
            {
                breakpoint: 'sm',
                src: [
                    {
                        src: tabletLandingBannerTop50_1x,
                        density: '1x',
                    },
                    {
                        src: tabletLandingBannerTop50_2x,
                        density: '2x',
                    },
                ],
            },
            {
                src: [
                    {
                        src: mobileLandingBannerTop50_1x,
                        density: '1x',
                    },
                    {
                        src: mobileLandingBannerTop50_2x,
                        density: '2x',
                    },
                ],
            },
        ]
    } else {
        // Default (No toggles on)
        return [
            {
                breakpoint: 'md',
                src: [
                    {
                        src: desktopLandingBanner1x,
                        density: '1x',
                    },
                    {
                        src: desktopLandingBanner2x,
                        density: '2x',
                    },
                ],
            },
            {
                breakpoint: 'sm',
                src: [
                    {
                        src: tabletLandingBanner1x,
                        density: '1x',
                    },
                    {
                        src: tabletLandingBanner2x,
                        density: '2x',
                    },
                ],
            },
            {
                src: [
                    {
                        src: mobileLandingBanner1x,
                        density: '1x',
                    },
                    {
                        src: mobileLandingBanner2x,
                        density: '2x',
                    },
                ],
            },
        ]
    }
}
