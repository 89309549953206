import { layout } from '../../App.routing'

export const developingNewsSegment = () => {
    return layout.composition({
        type: 'box',
        props: {},
        contentAreas: {
            main: [
                layout.component({
                    type: 'developing-news',
                    props: {
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'developing-news',
                            offset: 0,
                            pageSize: 8,
                        },
                    },
                }),
            ],
        },
    })
}
