import React from 'react'
import { colors } from '../../__styling/settings/colors'

export interface TheWestMarkProps {
    className?: string
    fill?: string
    width?: number
    height?: number
}

export const TheWestMark: React.FC<TheWestMarkProps> = (props) => (
    <svg
        viewBox="0 0 74 65"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        width={props.width}
        height={props.height}
    >
        <title>The West Australian</title>
        <path
            fill={props.fill ? props.fill : colors.black}
            d="M36.28 52.4c-4.83-6.57-4.28-16.44 5.86-25.37l-.05 29.82 8.8-7.9V26.7l2-1.9 3.7 3.72v26.95c-6.5 4.2-15.7 3.35-20.4-3.02zm36.9 1.57l-2.73 2.73-4.65-4.67.1-26.42 3.43-3.4-.68-.6-2.78 2.8-7-7.1-7.9 8.1v-7.9c-1.15 1.16-3.55 2.95-6.02 2.7-3.7-.34-6.84-2.05-10.9-2.05-4.73-.05-8.05 2.55-8.05 6.4 0 3.5 1.76 5.13 5.7 5.14-3.04-1.1-3.88-1.5-3.88-3.1 0-1.5 1.55-2.2 3.62-2.2 3.24 0 5.47 1.6 10.64 1.6-15.1 12.3-19.65 22.1-13.45 30.4-6.26 2.82-14.1 1.6-18.32-4.1-4.8-6.5-4.2-16.4 5.9-25.3l-.04 29.8L25 48.9l.03-31.5c-1.15 1.16-3.54 2.95-6 2.7-3.7-.34-6.83-2.06-10.9-2.06-4.72 0-8.06 2.6-8.06 6.45C0 28 1.8 29.6 5.74 29.6c-3.04-1.08-3.87-1.45-3.87-3.1 0-1.5 1.55-2.14 3.6-2.14 3.25 0 5.47 1.6 10.65 1.6C1.07 38.2-3.52 48 2.68 56.3l1.1 1.43c6.2 7.34 19.4 8.3 26 .02 6.5 7.76 20.8 8.4 26.98-1.33l7.55 7.64 9.6-9.57-.7-.6zM0 5.75h66V0H0v5.75z"
        />
    </svg>
)
TheWestMark.displayName = 'TheWestMark'
