import { Topic } from '../client-data-types/topic'
export const isLiveSportsTopic = (topic: string): boolean => {
    const validTopics = liveSportTopics
    return validTopics.indexOf(topic) > -1
}

export const liveSportTopics: string[] = [
    'sport/psa-footy',
    'sport/wafl',
    'sport/waflw',
    'sport/wafl-colts',
    'sport/swfl',
    'sport/field-hockey',
    'sport/basketball',
    'sport/netball',
]

export function isSportTopic(topic?: Topic) {
    if (!topic) {
        return false
    }

    if (topic.id === 'sport' || topic.parent?.id === 'sport') {
        return true
    }

    return false
}
