import styled from '@emotion/styled'
import { calcRem } from '../../__styling'
import { zIndex } from '../../__styling/settings/z-index'
import { tokens } from '@news-mono/design-tokens'

export const TNLiveComponentContainer = styled('div')<{
    backgroundColor?: string
    padding?: string
}>(({ backgroundColor = '#FFF', padding }) => ({
    display: 'inline-flex',
    gap: calcRem(4),

    height: 'fit-content',
    width: 'fit-content',

    padding: padding,

    '&.with-background': {
        padding: calcRem(4, 8),
        borderRadius: calcRem(15),
        backgroundColor: backgroundColor,
    },
    '&.image-overlay': {
        // Handle the image offset
        left: calcRem(16),
        bottom: calcRem(16),
        position: 'absolute',
        zIndex: zIndex.thenightly.cardPlayButton,
    },
}))

export const TNLiveComponentText = styled('span')<{
    textColor?: string
}>(
    ({
        textColor = tokens.thenightly.colors.palette.utility.error.default,
    }) => ({
        display: 'flex',
        alignSelf: 'center',
        margin: 0,

        color: textColor,
        fontWeight: 700,
        textDecoration: 'uppercase',
    }),
)
