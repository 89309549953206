import React from 'react'
import {
    StyledCerberus,
    StyledGridItem,
} from '../../compositions/Cerberus/Cerberus.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface CerberusProps {
    content:
        | [
              React.ReactElement<any>,
              React.ReactElement<any>,
              React.ReactElement<any>,
              React.ReactElement<any>,
          ]
        | [
              React.ReactElement<any>,
              React.ReactElement<any>,
              React.ReactElement<any>,
          ]
    hasBackgroundFill?: boolean
    verticalSpacing?: keyof ThemeMargins
}

export const Cerberus: React.FC<CerberusProps> = ({
    content,
    hasBackgroundFill,
    verticalSpacing,
}) => {
    const numberOfColumns = content.length === 4 ? 4 : 3

    return (
        <StyledCerberus
            hasBackgroundFill={hasBackgroundFill}
            verticalSpacing={verticalSpacing}
            numberOfColumns={numberOfColumns}
        >
            <StyledGridItem>{content[0]}</StyledGridItem>
            <StyledGridItem>{content[1]}</StyledGridItem>
            <StyledGridItem>{content[2]}</StyledGridItem>

            {numberOfColumns === 4 && (
                <StyledGridItem>{content[3]}</StyledGridItem>
            )}
        </StyledCerberus>
    )
}
Cerberus.displayName = 'Cerberus'
