import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, TheNightlyTheme } from '../../../__styling'

export const PuzzleLinkContainer = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(40),
    [breakpoint('sm')]: {
        flexDirection: 'row',
    },
})

export const Title = styled.h2(({ theme }) => [
    {
        margin: 0,
    },
    textMixin(theme as TheNightlyTheme, 'section-header'),
])

export const TitleDescription = styled.div(({ theme }) => [
    {
        color: theme.colors.text.tertiary,
    },
    textMixin(theme as TheNightlyTheme, 'body-alt'),
])

export const PuzzleLink = styled(WebLink)<{ entitled?: boolean }>(
    ({ entitled }) => [
        {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: calcRem(8),

            // Remove default link styles.
            color: 'inherit',
            textDecoration: 'inherit',
        },
        // disable links if not entitled
        !entitled && {
            pointerEvents: 'none',
            cursor: 'not-allowed',
            opacity: 0.5,
            textDecoration: 'none',
        },
    ],
)

export const PuzzleTitle = styled.div(({ theme }) =>
    textMixin(theme as TheNightlyTheme, 'subheading'),
)
export const PuzzleDate = styled.div(({ theme }) => [
    {
        color: theme.colors.text.tertiary,
    },
    textMixin(theme as TheNightlyTheme, 'body-alt'),
])

export const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
    alignSelf: 'flex-start',
})
