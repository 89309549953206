import { Theme, ThemeProvider } from '@emotion/react'
import { PartialTheme, ThemeConfig } from '../../__styling'
import React, { PropsWithChildren } from 'react'
import merge from 'deepmerge'

type PartialScheme<T extends ThemeConfig> = Omit<PartialTheme<T>, 'components'>

export type SchemeOverriderProps<T extends ThemeConfig> = {
    override: PartialScheme<T>
}

/**
 * Overrides scheme tokens for broad color/structure changes.
 * No component specific code is EVER to be introduced to this file.
 *
 * Definitions & Documentation:
 * https://wanews.atlassian.net/wiki/spaces/REF/pages/153714860/Theming+Restructure
 */
export const SchemeOverrider = <T extends ThemeConfig>({
    children,
    override,
}: PropsWithChildren<SchemeOverriderProps<T>>) => {
    return (
        <ThemeProvider
            theme={(outerTheme) => {
                return merge(outerTheme, override) as Theme
            }}
        >
            {children}
        </ThemeProvider>
    )
}
