import styled from '@emotion/styled'
import { metrics } from '../../__styling'

export const StyledAside = styled('aside')(({ theme }) => ({
    marginBottom:
        theme.kind === 'thenightly'
            ? metrics.thenightly.articleBlockMargin
            : theme.margins.lg,
    marginTop:
        theme.kind === 'thenightly'
            ? metrics.thenightly.articleBlockMargin
            : undefined,
}))
