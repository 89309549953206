import React from 'react'
import { StyledSubhead } from '../../typography/Subhead/Subhead.styled'
import { TextItemProps } from '../../typography/TextItem/TextItem'

export type SubHeadingElements = 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type SubheadProps = TextItemProps & { tag: SubHeadingElements }

export const Subhead: React.FC<SubheadProps> = (props) => {
    return <StyledSubhead {...props} />
}
Subhead.displayName = 'Subhead'
