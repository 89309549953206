// Common create slug helper for cue / brightcove / newsgate and text
export function createSlug(props: CreateSlug) {
    switch (props.type) {
        case 'cue':
            return sanitiseSlug(`${props.headline}-${props.id}`)
        case 'newsgate':
            return sanitiseSlug(`${props.headline}-ng-${props.id}`)
        case 'brightcove':
            return sanitiseSlug(
                `${trimBrightcoveTitle(props.title)}-bc-${props.id}`,
            )
        case 'text':
            return sanitiseSlug(props.text)
    }
}

/**
 * Be very careful if changing this as some migrations seem to be using it
 * they will need to be updated so that no issues occur
 */
function sanitiseSlug(text: string): string {
    return text
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/\s/g, '-')
        .replace('---', '-')
}

/**
 * As slug column limited to 255char, in edge cases when title is to long after adding '-bc-{bcId}' slug become longer then 255.
 * As sufix are always same (17char long) need to limit title by triming if it longer then 238chars.
 * @param title Title string for triming
 * @returns
 */
function trimBrightcoveTitle(title: string): string {
    return title.length > 238 ? title.substring(0, 238) : title
}

export function capitalizeFirstLetter(s: string) {
    return s[0].toUpperCase() + s.slice(1)
}

type CreateSlug =
    | {
          type: 'cue' | 'newsgate'
          headline: string
          id: string
      }
    | {
          type: 'brightcove'
          title: string
          id: string
      }
    | {
          type: 'text'
          text: string
      }
