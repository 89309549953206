import { cx } from '@emotion/css'
import { useTheme } from '@emotion/react'
import { isCorporateAccount } from '@news-mono/component-library'
import {
    AppState,
    EntitledToView,
    RenderTargetContext,
    useFeature,
} from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { CricketAnimation } from '../../content/TheWestHeader/components/CricketAnimation'
import { InHouseLinks } from '../../content/TheWestHeader/components/InHouseLinks'
import {
    StyledChevronRight,
    StyledHeaderTimestamp,
    StyledLeftWrapper,
    StyledRegionalSwgPromoMastheads,
    StyledSubscribeButton,
    StyledSubscribeIcon,
    StyledTheWestHeader,
    StyledTheWestHeaderWrapper,
    StyledTheWestLogo,
    StyledTimestampWrap,
    StyledWestRewardsIcon,
    StyledWestRewardsLink,
} from '../../content/TheWestHeader/TheWestHeader.styled'
import {
    FeatureToggle,
    useFeatureForSection,
} from '../../feature-toggling/Toggle/Toggle'
import { featurePublicationPageType } from '../../templates/Publication/TheWestPublication/TheWestFeature'
import { useAuthActions } from '../../user-registration/authentication/AuthenticationProvider'
import { createSubscribeLinkClickEvent } from '../../user-registration/subscribe/createSubscribeClickLinkHandler'
import { MastHeadLogo } from './logos/MastheadLogo'
import { TheWestHeaderProps } from './TheWestHeader'

const SubscribeButton: React.FC<{
    onEvent: (event: any) => void
    cricketballEnabled: boolean
}> = ({ onEvent, cricketballEnabled }) => {
    const renderTarget = useContext(RenderTargetContext)
    const { onSubscribeClick } = useAuthActions()

    return renderTarget.renderTarget === 'static-error-page' ? null : (
        <StyledSubscribeButton
            onClick={() => {
                createSubscribeLinkClickEvent('header', onEvent)()
                onSubscribeClick({
                    callToAction: 'header',
                })
            }}
            shouldShrink={cricketballEnabled}
        >
            <StyledSubscribeIcon />
            Subscribe <StyledChevronRight />
        </StyledSubscribeButton>
    )
}

export const TheWestHeaderWeb: React.FC<TheWestHeaderProps> = (props) => {
    const renderTarget = useContext(RenderTargetContext)
    const { section } = useTheme()
    const { subscriptionType } = useSelector(
        (state: AppState) => state.authentication,
    )
    const grandFinalThemeEnabled = useFeature('the-west-grand-final')
    const newSubscribeButtonDesignEnabled = useFeatureForSection({
        feature: 'tp-subscription-subscribe-button-design',
        sections: TheWestSections.default,
    })

    const { className, inHouseLinks, onEvent, isRegionalSwgCampaignPage } =
        props

    const cricketballEnabled =
        useFeature('cricketball') && section === 'default'

    const showWestRewardsOrSubscribe =
        !isCorporateAccount(subscriptionType) && section !== 'genwest'

    const isRegionalSite = section !== 'default'

    const isGenWestSection = section === 'genwest'

    const showDivider =
        (!cricketballEnabled &&
            props.pageType !== featurePublicationPageType) ||
        isRegionalSite

    if (isRegionalSwgCampaignPage) {
        return (
            <StyledTheWestHeaderWrapper>
                <StyledTheWestHeader
                    showDivider={showDivider}
                    className={cx([className, 'regionalSwgCampaignPage'])}
                    featureEnabled={false}
                >
                    <StyledRegionalSwgPromoMastheads>
                        <MastHeadLogo width={275} />

                        <StyledTheWestLogo logoWidth={200} />
                    </StyledRegionalSwgPromoMastheads>
                </StyledTheWestHeader>
            </StyledTheWestHeaderWrapper>
        )
    }

    return (
        <StyledTheWestHeaderWrapper>
            <StyledTheWestHeader
                showDivider={showDivider}
                featureEnabled={grandFinalThemeEnabled}
                className={className}
            >
                {!isGenWestSection &&
                    (newSubscribeButtonDesignEnabled ? (
                        <StyledLeftWrapper />
                    ) : (
                        <StyledLeftWrapper>
                            {showWestRewardsOrSubscribe && (
                                <FeatureToggle
                                    feature="the-west-rewards"
                                    on={() => (
                                        <EntitledToView
                                            requiredAccess={{
                                                level: 'subscriber',
                                            }}
                                            entitled={() => (
                                                <StyledWestRewardsLink to="https://rewards.thewest.com.au/?utm_source=TheWest&utm_medium=Referral&utm_campaign=WestRewards&utm_term=icon&utm_content=header-nav">
                                                    <StyledWestRewardsIcon />
                                                </StyledWestRewardsLink>
                                            )}
                                            notEntitled={() => (
                                                <SubscribeButton
                                                    onEvent={onEvent}
                                                    cricketballEnabled={
                                                        cricketballEnabled
                                                    }
                                                />
                                            )}
                                        ></EntitledToView>
                                    )}
                                    off={() => (
                                        <SubscribeButton
                                            onEvent={onEvent}
                                            cricketballEnabled={
                                                cricketballEnabled
                                            }
                                        />
                                    )}
                                />
                            )}
                        </StyledLeftWrapper>
                    ))}

                <MastHeadLogo />

                {!isGenWestSection && (
                    <StyledTimestampWrap>
                        {inHouseLinks && <InHouseLinks items={inHouseLinks} />}
                        {renderTarget.renderTarget === 'static-error-page' ||
                        renderTarget.renderTarget === 'amp' ? null : (
                            <StyledHeaderTimestamp
                                className={className}
                                timestamp={Date.now()}
                                timestampFormat="dateOnly"
                                id="timestamp-header"
                            />
                        )}
                    </StyledTimestampWrap>
                )}
                {cricketballEnabled && <CricketAnimation />}
            </StyledTheWestHeader>
        </StyledTheWestHeaderWrapper>
    )
}
TheWestHeaderWeb.displayName = 'TheWestHeader'
