import { Topic, toTopicRouteHint } from '@news-mono/web-common'
import React from 'react'
import { StyledTopicLabel } from '../../typography/TopicLabel/TopicLabel.styled'

export interface TopicLabelProps {
    text: string
    topic: Topic
    className?: string
}

export const TopicLabel: React.FC<TopicLabelProps> = (props) => (
    <StyledTopicLabel
        to={toTopicRouteHint(props.topic)}
        className={props.className}
    >
        {props.text}
    </StyledTopicLabel>
)
TopicLabel.displayName = 'TopicLabel'
