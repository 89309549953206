import React from 'react'

export interface IconXProps {
    title?: string
    className?: string
}

export const IconX: React.FC<IconXProps> = ({ className, title }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g clip-path="url(#clip0_5077_5880)">
            <path d="M10.7042 7.62186L17.3999 0H15.8138L9.99743 6.6166L5.35538 0H0L7.02122 10.0064L0 17.9981H1.58611L7.72436 11.0093L12.6278 17.9981H17.9832M2.15858 1.1713H4.59529L15.8126 16.8844H13.3753" />
        </g>
        <defs>
            <title>{title || 'Share to X'}</title>
            <clipPath id="clip0_5077_5880">
                <rect width="17.9832" height="17.9982" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
IconX.displayName = 'IconX'
