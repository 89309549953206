import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'

export function getCommonLinkStyles(theme: Theme): CSSObject {
    return {
        textDecoration: 'underline',
        color: themedValue(theme, {
            thewest: (section) => section.primaryColor,
            perthnow: colors.perthnow.blueKyanite,
            sevennews: (section) => section.link.primaryColor,
            thenightly: tokens.thenightly.colors.actions.link.default,
            fallback: undefined,
        }),

        '&:hover': {
            backgroundColor: themedValue(theme, {
                fallback: undefined,
                perthnow: colors.perthnow.blueKyanite,
            }),
            color: themedValue(theme, {
                thewest: colors.thewest.greyKoala,
                perthnow: colors.white,
                sevennews: (section) => section.link.primaryColor,
                fallback: undefined,
            }),
            textDecoration: themedValue(theme, {
                thewest: 'underline',
                thenightly: 'none',
                fallback: 'none',
            }),
        },
        '&:focus': {
            backgroundColor: themedValue(theme, {
                fallback: undefined,
                perthnow: colors.perthnow.blueKyanite,
            }),
            color: themedValue(theme, {
                thewest: colors.black,
                perthnow: colors.white,
                sevennews: (section) => section.link.primaryColor,
                fallback: undefined,
            }),
            border: undefined,
        },
        '&:active': {
            color: themedValue(theme, {
                thenightly: 'unset',
                fallback: undefined,
            }),
        },
    }
}

export const StyledTextLink = styled(WebLink)(({ theme, type }) => [
    (!type || type === 'default') && getCommonLinkStyles(theme),
    type === 'button-ghost' && {
        display: 'inline-block',
        border: `1px solid ${colors.white}`,
        padding: calcRem(theme.margins.sm, theme.margins.md),
        color: colors.white,
        borderRadius: calcRem(2),
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 700,
        textDecoration: 'none',
        textTransform: 'uppercase',
        cursor: 'pointer',
        ...transition({ properties: ['background', 'color'] }),

        '&:hover': {
            backgroundColor: colors.white,
            color: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.blueKyanite,
                sevennews: colors.sevennews.red,
                fallback: undefined,
            }),
        },

        '&:focus': {
            backgroundColor: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.pinkThulite,
                sevennews: colors.sevennews.red,
                fallback: undefined,
            }),
            borderColor: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.pinkThulite,
                sevennews: colors.sevennews.red,
                fallback: undefined,
            }),
            color: 'currentColor',
            outline: 'none',
            textDecoration: 'underline',
        },
    },
])
