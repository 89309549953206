import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Omny, OmnyProps } from '../../../publication/Embed/web/Omny'

export interface OmnyRouteProps extends OmnyProps {}

export const OmnyRegistration = createRegisterableComponent(
    'omny-web',
    (props: OmnyRouteProps) => {
        return (
            <Omny
                height={props.height}
                link={props.link}
                width={props.width}
                verticalSpacing={props.verticalSpacing}
                smallPlaylist={props.smallPlaylist}
            />
        )
    },
)
