export type ValidationError =
    | {
          type: 'first-name'
          message: string
      }
    | {
          type: 'last-name'
          message: string
      }

export const validateNames = (
    firstName: string,
    lastName: string,
): ValidationError[] => {
    const errors: ValidationError[] = []

    if (firstName === '') {
        errors.push({
            type: 'first-name',
            message: 'Please enter a first name.',
        })
    } else if (firstName.length > 50) {
        errors.push({
            type: 'first-name',
            message: 'First name should be less than 50 characters.',
        })
    }

    if (lastName === '') {
        errors.push({
            type: 'last-name',
            message: 'Please enter a last name.',
        })
    } else if (lastName.length > 50) {
        errors.push({
            type: 'last-name',
            message: 'Last name should be less than 50 characters.',
        })
    }

    return errors
}
