import React from 'react'
import { ImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { ThemeMargins } from '../../../__styling'
import { useProduct } from '../../../__product'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import {
    StyledFiveCardCollection,
    StyledHorizontalLine,
} from './FiveCardCollection.styled'
import { ContentCard } from '../../cards/ContentCard/ContentCard'
import { useViewport } from '../../../__helpers/use-viewport'

interface FiveCardProps {
    items: MaybeLoaded<CardItem[]>
    onEvent: (event: CollectionEvent) => void
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
}

export const FiveCardCollection = (props: FiveCardProps) => {
    const { items, verticalGutters, verticalSpacing } = props
    const product = useProduct()
    const { isTabletOrMobile } = useViewport()

    if (!items.loaded) {
        return null
    }
    const loadedItems = invertMaybeLoadedItems(items, 5)

    const gridAreasNames: {
        [key: number]: string
    } = {
        0: 'one',
        1: 'two',
        2: 'three',
        3: 'four',
        4: 'five',
    }
    return (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'FiveCardCollection',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledFiveCardCollection
                    ref={ref}
                    verticalSpacing={verticalSpacing}
                    verticalGutters={verticalGutters}
                    itemCount={loadedItems.length}
                >
                    <StyledHorizontalLine itemCount={loadedItems.length} />
                    {loadedItems.map((item, itemIndex) => {
                        const cardType =
                            itemIndex === 0 ||
                            itemIndex === 1 ||
                            (itemIndex === 2 && isTabletOrMobile)
                                ? 'large'
                                : 'small'
                        const gridAreaName = gridAreasNames[itemIndex]

                        return (
                            <ContentCard
                                item={item}
                                cardNumber={itemIndex}
                                cardType={cardType}
                                onEvent={props.onEvent}
                                gridAreaName={gridAreaName}
                                collectionType={'five-card'}
                            />
                        )
                    })}
                </StyledFiveCardCollection>
            )}
        </ImpressionAvailable>
    )
}
