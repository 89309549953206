import loadable from '@loadable/component'
import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { PollieRaterBannerProps } from './PollieRaterBanner'

export const LazyPollieRaterBanner = loadable(
    () => import('./PollieRaterBanner'),
)

export interface PollieRaterBannerRouteProps extends PollieRaterBannerProps {
    /** Sets banner maxWidth to 900px  */
    isFixedWidth?: boolean
}

export const PollieRaterBannerRegistration = createRegisterableComponent(
    'pollie-rater-banner',
    (props: PollieRaterBannerRouteProps) => (
        <LazyPollieRaterBanner {...props} />
    ),
)
