import { GetMainSectionFn } from '../custom-topic-routes'
import { layout } from '../../../App.routing'
import { topicParallax } from '../topic-parallax'
import { billboardLeaderboardMrec } from '../../ad-units'

export const getIndigenousAustraliansRouteInfo: GetMainSectionFn = (
    sectionBuilder,
) => {
    return [
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fixedRatios: ['16:9'],
                cardOrientation: {
                    type: 'Landscape-Portrait',
                },
                finalColumns: 4,
                intermediateColumns: 2,
                hasBackground: true,

                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'indigenous-affairs',
                    offset: 0,
                    pageSize: 8,
                },
                parent: {},
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/indigenous-australians'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/indigenous-australians'],
                    includeSubTopics: true,
                    paging: {
                        page: 2,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/indigenous-australians'],
                    includeSubTopics: true,
                    paging: {
                        page: 3,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('three'),
    ]
}
