import { breakpoint } from '../__styling'

export function isTabletOrMobileViewport() {
    if (
        // fail conditional if SSR is triggered or a VR test
        typeof window !== 'undefined' &&
        window &&
        typeof window.matchMedia === 'function'
    ) {
        const mdBreakpoint = window.matchMedia(
            breakpoint('md').replace('@media ', ''),
        )
        // if viewport size is below md breakpoint return true
        if (!mdBreakpoint.matches) {
            return true
        }
    }
    return false
}

export function isMobileViewport() {
    if (
        // fail conditional if SSR is triggered or a VR test
        typeof window !== 'undefined' &&
        window &&
        typeof window.matchMedia === 'function'
    ) {
        const mdBreakpoint = window.matchMedia(
            breakpoint('sm').replace('@media ', ''),
        )
        // if viewport size is below md breakpoint return true
        if (!mdBreakpoint.matches) {
            return true
        }
    }
    return false
}
