import styled, { CSSObject } from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { IconFacebook } from '../../icons/IconFacebook/IconFacebook'
import { IconInstagram } from '../../icons/IconInstagram/IconInstagram'
import { IconTwitter } from '../../icons/IconTwitter/IconTwitter'
import { colors } from '../../__styling/settings/colors'
import {
    breakpoint,
    breakpointMax,
    breakpointSwitch,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { getComponentTheme } from '../../__styling'

export const StyledBioBlock = styled.div(({ theme }) => {
    const bioBlockTheme = getComponentTheme(theme, 'bioBlock')
    return [
        {
            display: 'flex',
            flexWrap: 'wrap',
            background: bioBlockTheme.background,
            margin: bioBlockTheme.margin,

            transition: 'padding 0.25s, margin 0.25s, gap 0.25s',
        },
        bioBlockTheme.breakpointState &&
            breakpointSwitch(bioBlockTheme.breakpointState, (styles) => styles),
        bioBlockTheme.gap &&
            breakpointSwitch(bioBlockTheme.gap, (gap) => ({
                gap,
            })),
    ]
})

export const StyledText = styled.div(({ theme }) => {
    const textTheme = getComponentTheme(theme, 'bioBlock').text
    return [
        {
            borderBottom: textTheme.borderBottom,
            textAlign: textTheme.textAlign,
            padding: textTheme.padding,
            width: textTheme.width,
        },
        textTheme.breakpointState &&
            breakpointSwitch(textTheme.breakpointState, (styles) => styles),
    ]
})

export const StyledAuthor = styled.h1(({ theme }) => {
    const authorTheme = getComponentTheme(theme, 'bioBlock').author
    return [
        {
            margin: authorTheme.margin || 0,
            position: 'relative',
            width: '100%',
            textTransform: authorTheme.textTransform,
            lineHeight: authorTheme.lineHeight,
            display: authorTheme.display,
            flexDirection: authorTheme.flexDirection,
            fontFamily: authorTheme.fontFamily,
            color: authorTheme.color,
            borderBottom: authorTheme.borderBottom,
            paddingBottom: authorTheme.paddingBottom,
            flexWrap: authorTheme.flexWrap,
            justifyContent: authorTheme.justifyContent,
            transform: authorTheme.transform,
            textAlign: authorTheme.textAlign,
        },
        authorTheme.fontSize &&
            breakpointSwitch(authorTheme.fontSize, (fontSize) => ({
                fontSize,
            })),
        authorTheme.after && { ...authorTheme.after },
    ]
})

export const StyledName = styled.span(({ theme }) => {
    const nameTheme = getComponentTheme(theme, 'bioBlock').name
    return [
        {
            display: 'block',
            flexBasis: '100%',
            fontSize: nameTheme.fontSize,
            fontFamily: nameTheme.fontFamily,
            flex: nameTheme.flex,
            fontWeight: nameTheme.fontWeight,
            lineHeight: nameTheme.lineHeight,
        },
        nameTheme.textCrop && { ...nameTheme.textCrop },
        nameTheme.marginBottom &&
            breakpointSwitch(nameTheme.marginBottom, (marginBottom) => ({
                marginBottom,
            })),
    ]
})

export const StyledPosition = styled.span(({ theme }) => {
    const positionTheme = getComponentTheme(theme, 'bioBlock').position
    return {
        display: 'block',
        textTransform: positionTheme?.textTransform,
        fontWeight: positionTheme?.fontWeight,
        color: positionTheme?.color,
        fontFamily: positionTheme?.fontFamily,
        fontSize: positionTheme?.fontSize,
        lineHeight: positionTheme?.lineHeight,
    }
})

// Only used in PerthNow
export const StyledFlexRow = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [breakpointMax('xxs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

// Only used in PerthNow
export const StyledButtons = styled('span')({
    display: 'flex',
    [breakpointMax('xxs')]: {
        marginTop: 10,
    },
})

interface StyledTextProps {
    hasSocial?: boolean
}
export const StyledBioText = styled.p<StyledTextProps>(
    ({ theme, hasSocial }) => {
        const bioTextTheme = getComponentTheme(theme, 'bioBlock').bioText
        return {
            lineHeight: bioTextTheme.lineHeight,
            margin: bioTextTheme.margin,
            marginTop: hasSocial ? 0 : bioTextTheme.marginTop,
            color: bioTextTheme.color,
            fontWeight: bioTextTheme.fontWeight,
            fontFamily: bioTextTheme.fontFamily,
            fontSize: bioTextTheme.fontSize,
        }
    },
)

export const StyledImageContainer = styled.div(({ theme }) => {
    const imageContainerTheme = getComponentTheme(
        theme,
        'bioBlock',
    ).imageContainer
    return [
        {
            position: 'relative',

            flex: imageContainerTheme.flex,
            padding: imageContainerTheme.padding,
            alignSelf: imageContainerTheme.alignSelf,
        },
        breakpointSwitch(
            imageContainerTheme.breakpointState,
            (styles) => styles,
        ),
    ]
})

export const StyledImage = styled.img(({ theme }) => {
    const imageTheme = getComponentTheme(theme, 'bioBlock').image
    return [
        {
            display: 'block',
            filter: imageTheme.filter,
            objectFit: imageTheme.objectFit,
        },
        imageTheme.breakpointState &&
            breakpointSwitch(imageTheme.breakpointState, (styles) => styles),
    ]
})

export const StyledSocialLink = styled(WebLink)({
    display: 'block',
    color: colors.sevennews.blueRibbon,
    fontSize: calcRem(14),
    fontWeight: 400,
    textDecoration: 'none',
    padding: 0,

    '&:hover': {
        textDecoration: 'underline',
    },
    '&:focus': {
        textDecoration: 'none',
    },
})

const SocialIconStyles: CSSObject = {
    width: calcRem(30),
    height: calcRem(30),
    padding: calcRem(6),
    margin: calcRem(5, 0),
    verticalAlign: 'middle',
    [breakpoint('sm')]: {
        margin: calcRem(8, 4, 8, 0),
    },
}

export const StyledFacebookIcon = styled(IconFacebook)(SocialIconStyles, {
    fill: colors.social.facebook,
})
export const StyledTwitterIcon = styled(IconTwitter)(SocialIconStyles, {
    fill: colors.sevennewsSocial.twitter,
})
export const StyledInstagramIcon = styled(IconInstagram)(SocialIconStyles, {
    fill: colors.social.instagram,
})

// Only used in TheNightly
export const StyledDivider = styled.span(({ theme }) => {
    const dividerTheme = getComponentTheme(theme, 'bioBlock').divider
    return [
        {
            width: dividerTheme?.width,
            height: dividerTheme?.height,
            backgroundColor: dividerTheme?.backgroundColor,
        },
        dividerTheme &&
            breakpointSwitch(dividerTheme.margin, (margin) => ({
                margin,
            })),
    ]
})

export const StyledHeader = styled.h3(({ theme }) => {
    const headerTheme = getComponentTheme(theme, 'bioBlock').header
    return [
        {
            fontWeight: headerTheme?.fontWeight,
            margin: headerTheme?.margin,
            width: headerTheme?.width,
        },
        headerTheme &&
            breakpointSwitch(headerTheme.breakpointState, (styles) => styles),
    ]
})
