import React from 'react'
import { IconButton } from '../../buttons/IconButton/IconButton'
import { IconDigitalEdition } from '../../icons/IconDigitalEdition/IconDigitalEdition'
import { IconEmail } from '../../icons/IconEmail/IconEmail'
import { IconFacebook } from '../../icons/IconFacebook/IconFacebook'
import { IconInstagram } from '../../icons/IconInstagram/IconInstagram'
import { IconLink } from '../../icons/IconLink/IconLink'
import { IconLinkedIn } from '../../icons/IconLinkedIn/IconLinkedIn'
import { IconReddit } from '../../icons/IconReddit/IconReddit'
import { IconRSS } from '../../icons/IconRSS/IconRSS'
import { IconTwitter } from '../../icons/IconTwitter/IconTwitter'
import { IconYoutube } from '../../icons/IconYoutube/IconYoutube'
import { colors } from '../../__styling/settings/colors'
import { PixelOffset } from './IconButton.styled'
import {
    IconEmailSevennews,
    IconFacebookRounded,
    IconInstagramSevennews,
    IconTikTok,
    IconX,
    IconYoutubeSevennews,
} from '../../icons'

interface IconButtonProps {
    title?: string
    link: string
    className?: string
    iconScale?: number
    baseColor?: string
    onClick?: () => void
    offset?: PixelOffset
    forceInternalSSR?: boolean
    is7NewsIcon?: boolean
}

export const FacebookIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.facebook}
        icon={
            props.is7NewsIcon ? (
                <IconFacebookRounded title={props.title} />
            ) : (
                <IconFacebook title={props.title} />
            )
        }
        iconScale={props.is7NewsIcon ? 1 : 0.75}
        noBorder={true}
        offset={{ top: 65, left: 50 }}
        {...props}
    />
)

export const TwitterIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.twitter}
        icon={
            props.is7NewsIcon ? (
                <IconX title={props.title} />
            ) : (
                <IconTwitter title={props.title} />
            )
        }
        iconScale={props.is7NewsIcon ? 0.8 : 0.62}
        {...props}
    />
)

export const InstagramIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.instagram}
        icon={
            props.is7NewsIcon ? (
                <IconInstagramSevennews title={props.title} />
            ) : (
                <IconInstagram title={props.title} />
            )
        }
        iconScale={0.63}
        {...props}
    />
)

export const YoutubeIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.youtube}
        icon={
            props.is7NewsIcon ? (
                <IconYoutubeSevennews title={props.title} />
            ) : (
                <IconYoutube title={props.title} />
            )
        }
        iconScale={props.is7NewsIcon ? 1 : 0.63}
        {...props}
    />
)

export const RSSIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        useAnchorTag={true}
        baseColor={colors.social.rss}
        icon={<IconRSS title={props.title} />}
        iconScale={0.6}
        {...props}
    />
)

export const DigitalEditionIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.rss}
        icon={<IconDigitalEdition title={props.title} />}
        iconScale={0.6}
        onClick={props.onClick}
        forceInternalSSR={props.forceInternalSSR}
        {...props}
    />
)

export const RedditIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.reddit}
        icon={<IconReddit title={props.title} />}
        {...props}
    />
)

export const LinkIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.clipboard}
        icon={<IconLink title={props.title} />}
        iconScale={0.6}
        {...props}
    />
)

export const EmailIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.email}
        icon={
            props.is7NewsIcon ? (
                <IconEmailSevennews title={props.title} />
            ) : (
                <IconEmail title={props.title} />
            )
        }
        iconScale={props.is7NewsIcon ? 1 : 0.61}
        {...props}
    />
)

export const LinkedInIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.linkedIn}
        icon={<IconLinkedIn title={props.title} />}
        iconScale={0.61}
        {...props}
    />
)
export const TikTokIconButton: React.FC<IconButtonProps> = (props) => (
    <IconButton
        baseColor={colors.social.email}
        icon={<IconTikTok title={props.title} />}
        iconScale={1}
        {...props}
    />
)
