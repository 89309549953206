import { toFeatureState } from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { isCorporateAccount } from '@news-mono/component-library'
import {
    AppState,
    DataLayerEventName,
    isComponentEnabled,
    isCurrent,
    LogoutEvent,
    NavEvent,
    NavEventArgs,
    NavItem,
    Product,
    RenderTargetContext,
    SubscribeEvent,
    usePageEvents,
    useToggleState,
} from '@news-mono/web-common'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { FeatureToggle } from '../../feature-toggling'
import {
    StyledButtonText,
    StyledCloseButton,
    StyledIconCross,
    StyledNavList,
    StyledNavWrapper,
    StyledNavWrapperClass,
    StyledSearchAction,
} from '../../navigation/FlyoutNav/FlyoutNav.styled'
import { TheWestNavigation } from '../../routing/route-nav-types'
import { useAuthActions } from '../../user-registration/authentication/AuthenticationProvider'
import { createSubscribeLinkClickEvent } from '../../user-registration/subscribe/createSubscribeClickLinkHandler'
import { KeyCode } from '../../__helpers/key-code'
import { useOnClickOutside } from '../../__helpers/on-click-outside'
import { useProduct } from '../../__product/useProduct'
import { ThemedRender } from '../../__styling/themed-render'
import { SearchAction } from '../SearchAction/SearchAction'
import { TheWestReturn } from '../TheWestReturn/TheWestReturn'
import { FlyoutNavActions } from './components/FlyoutNavActions/FlyoutNavActions'
import {
    StyledActionButton,
    StyledActionLinkOutlineButton,
} from './components/FlyoutNavActions/FlyoutNavActions.styled'
import { FlyoutNavItem } from './components/FlyoutNavItem/FlyoutNavItem'

export function getNavName(product: Product) {
    switch (product) {
        case 'thewest': {
            return 'Flyout.TheWest'
        }
        case 'sevennews': {
            return 'Watch.SevenNews'
        }
        default: {
            return
        }
    }
}

export interface FlyoutNavCommonProps {
    includeSubNav?: boolean
    navLinks: TheWestNavigation
    section: TheWestSection
    onEvent: (event: NavEvent | SubscribeEvent | LogoutEvent) => void
    mainNavOpen: boolean
    closeNavigation: () => void
    /** Used for tests */
    startOpen?: boolean
}

export function FlyoutNav(props: FlyoutNavCommonProps) {
    const {
        navLinks,
        mainNavOpen,
        section,
        closeNavigation,
        onEvent,
        startOpen,
    } = props
    const location = useLocation()
    const product = useProduct()
    const navSection = section !== undefined ? section : 'default'
    const [impression, setImpression] = useState(false)
    const ref = useRef<HTMLElement | null>(null)
    const wrapperRef = useRef<HTMLElement | null>(null)
    const featureState = useToggleState()

    const { subscriptionType } = useSelector(
        (state: AppState) => state.authentication,
    )
    const handleClose = () => {
        if (mainNavOpen) {
            closeNavigation()
        }
    }

    const handleClick = (args: NavEventArgs) => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'FlyoutNav',
            payload: {
                navName: getNavName(product),
                navLocation: 'Header',
                navPos: args.navPos,
                navText: args.navText,
                navLink: args.navLink,
                navPath: args.navParent
                    ? `${args.navParent} > ${args.navText}`
                    : `${args.navText}`,
            },
        })

        props.closeNavigation()
    }

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.which === KeyCode.ESCAP) {
            handleClose()
        }
    }

    useOnClickOutside(ref, handleClose, {
        enabled: mainNavOpen,
        filterClasses: [StyledNavWrapperClass],
    })

    // FlyoutNav should only trigger impression once per page load.
    usePageEvents(() => {
        setImpression(true)
    }, ['page-load-complete'])

    useEffect(() => {
        const wrapperCurrent = wrapperRef.current

        if (!wrapperCurrent) {
            return
        }

        const handleTransitionEnd = () => {
            if (!mainNavOpen && wrapperRef.current) {
                wrapperRef.current.classList.remove('isOpen')
            }
        }

        if (mainNavOpen) {
            wrapperCurrent.classList.add('isOpen')
            requestAnimationFrame(() => {
                wrapperCurrent &&
                    wrapperCurrent.classList.add('isTransitioning')
            })
        } else {
            wrapperCurrent.classList.remove('isTransitioning')
        }

        wrapperCurrent.addEventListener('transitionend', handleTransitionEnd)

        return function flyoutTransitionCleanup() {
            if (wrapperCurrent) {
                wrapperCurrent.removeEventListener(
                    'transitionend',
                    handleTransitionEnd,
                )
            }
        }
    }, [mainNavOpen])

    useEffect(() => {
        if (mainNavOpen && ref && ref.current) {
            ref.current.focus()
            if (impression) {
                onEvent({
                    type: DataLayerEventName.navAvailable,
                    originator: getNavName(product) || '',
                    payload: {
                        navName: getNavName(product),
                    },
                })

                setImpression(false)
            }
        }
    }, [mainNavOpen, impression, onEvent, product])

    const navItems = navLinks[section]
    const renderTargetContext = React.useContext(RenderTargetContext)
    const { onLoginClick, onLogoutClick, onSubscribeClick } = useAuthActions()

    return (
        <nav
            onKeyDown={handleKeyPress}
            tabIndex={-1}
            role="navigation"
            ref={ref}
        >
            <ThemedRender
                thewest={() => (
                    <StyledNavWrapper ref={wrapperRef}>
                        <StyledCloseButton type="button" onClick={handleClose}>
                            <StyledButtonText>
                                Close navigation menu
                            </StyledButtonText>
                            <StyledIconCross />
                        </StyledCloseButton>
                        {renderTargetContext.renderTarget !== 'app' && (
                            <FlyoutNavActions
                                LogoutAction={() => (
                                    <StyledActionLinkOutlineButton
                                        type="button"
                                        onClick={() => onLogoutClick(onEvent)}
                                    >
                                        Log Out
                                    </StyledActionLinkOutlineButton>
                                )}
                                LoginAction={() => (
                                    <StyledActionLinkOutlineButton
                                        onClick={onLoginClick}
                                    >
                                        Log In
                                    </StyledActionLinkOutlineButton>
                                )}
                                SubscribeAction={() => (
                                    <StyledActionButton
                                        onClick={() => {
                                            createSubscribeLinkClickEvent(
                                                'flyout-nav',
                                                onEvent,
                                            )()
                                            onSubscribeClick({
                                                callToAction: 'flyout-nav',
                                            })
                                        }}
                                    >
                                        Subscribe
                                    </StyledActionButton>
                                )}
                            />
                        )}
                        <FeatureToggle
                            feature={'search'}
                            on={() => (
                                <StyledSearchAction>
                                    <SearchAction
                                        isLarge
                                        onSubmit={() => {
                                            props.closeNavigation()
                                        }}
                                    />
                                </StyledSearchAction>
                            )}
                        />
                        <StyledNavList>
                            <FlyoutNavItem
                                navPos={0}
                                section={navSection}
                                navItem={{
                                    name: 'Home',
                                    link: '/',
                                }}
                                isCurrent={isCurrent(
                                    location,
                                    section !== 'default'
                                        ? `/${navSection}`
                                        : '/',
                                    { exact: true },
                                )}
                                onClick={handleClick}
                                onEvent={onEvent}
                                startOpen={startOpen}
                            />
                            {navItems &&
                                navItems
                                    .filter((navItem: NavItem) => {
                                        if (navItem.feature) {
                                            const isFeatureEnabled =
                                                isComponentEnabled(
                                                    toFeatureState(
                                                        featureState,
                                                    ),
                                                    {
                                                        feature:
                                                            navItem.feature,
                                                        invertFeatureToggle:
                                                            navItem.invertFeatureToggle,
                                                    },
                                                )
                                            if (!isFeatureEnabled) return false
                                        }

                                        // Items hidden for school corporate site license users only
                                        if (
                                            subscriptionType ===
                                            'corporate-school'
                                        ) {
                                            return ![
                                                'Up Late',
                                                'Trading Up',
                                                'Timespool',
                                                'West Rewards',
                                                'Puzzles',
                                                'The Game',
                                                'Place an Ad',
                                            ].includes(navItem.name)
                                        } else if (
                                            isCorporateAccount(subscriptionType)
                                        ) {
                                            // Items hidden for both standard and school corporate site license users
                                            return ![
                                                'Timespool',
                                                'West Rewards',
                                                'Puzzles',
                                                'The Game',
                                                'Place an Ad',
                                            ].includes(navItem.name)
                                        }

                                        return true
                                    })
                                    .map((navItem: NavItem, index: number) => {
                                        return (
                                            <FlyoutNavItem
                                                key={navItem.name}
                                                // +1 because of home
                                                navPos={index + 1}
                                                section={navSection}
                                                navItem={navItem}
                                                showToggleButton={true}
                                                onClick={handleClick}
                                                onEvent={onEvent}
                                                startOpen={startOpen}
                                                isCurrent={isCurrent(
                                                    location,
                                                    navItem.link,
                                                )}
                                            />
                                        )
                                    })}
                            {section !== 'default' && (
                                <FlyoutNavItem
                                    linkStyle={'standard'}
                                    navPos={navItems ? navItems.length + 1 : 1}
                                    section={navSection}
                                    parentMarker={() => (
                                        <TheWestReturn
                                            hideText={true}
                                            darkTheme={true}
                                        />
                                    )}
                                    navItem={{
                                        name: 'thewest.com.au',
                                        link: 'https://thewest.com.au',
                                        newWindow: false, // explicit
                                    }}
                                    isCurrent={false}
                                    onClick={handleClick}
                                    onEvent={onEvent}
                                    startOpen={startOpen}
                                />
                            )}
                        </StyledNavList>
                    </StyledNavWrapper>
                )}
            />
        </nav>
    )
}

FlyoutNav.displayName = 'FlyoutNav'

export interface ProviderChildProps {
    mainNavOpen: boolean
    origin?: HTMLElement
    closeNavigation: () => void
    openNavigation: () => void
}

interface ProviderProps {
    children: (childProps: ProviderChildProps) => React.ReactElement<any> | null
}

export const FlyoutNavStateProvider: React.FC<ProviderProps> = (props) => {
    const [open, setOpen] = useState(false)
    const [origin, setOrigin] = useState<HTMLElement | null>(null)

    function isHTMLElement(element: Element | null): element is HTMLElement {
        return element && 'focus' in element ? true : false
    }

    return props.children({
        mainNavOpen: open,
        closeNavigation: () => {
            if (origin) {
                origin.focus()
                setOrigin(null)
            }
            setOpen(false)
        },
        openNavigation: () => {
            setOrigin(
                isHTMLElement(document.activeElement)
                    ? document.activeElement
                    : null,
            )
            setOpen(true)
        },
    })
}
