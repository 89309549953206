import React from 'react'

export const IconBlockQuoteNGN: React.FC = () => (
    <svg
        width="65"
        height="76"
        viewBox="0 0 65 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M42.336 30.992C38.016 30.992 33.888 27.632 33.888 20.528C33.888 14.288 37.056 6.608 51.072 2L52.512 5.456C42.72 10.16 40.32 14.768 40.032 20.72C40.896 19.856 41.76 19.376 43.968 19.376C46.944 19.376 49.344 21.392 49.344 25.04C49.344 28.784 46.272 30.992 42.336 30.992ZM20.448 30.992C16.128 30.992 12 27.632 12 20.528C12 14.288 15.168 6.608 29.184 2L30.624 5.456C20.832 10.16 18.432 14.768 18.144 20.72C19.008 19.856 19.872 19.376 22.08 19.376C25.056 19.376 27.456 21.392 27.456 25.04C27.456 28.784 24.384 30.992 20.448 30.992Z"
            fill="#DD1466"
        />
    </svg>
)
IconBlockQuoteNGN.displayName = 'IconBlockQuoteNGN'
