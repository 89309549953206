import React from 'react'

export interface IconDigitalEditionProps {
    title?: string
    className?: string
}

export const IconDigitalEdition: React.FC<IconDigitalEditionProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 17"
        className={className}
    >
        <title>{title || 'Get Digital Edition'}</title>
        <path d="M3.78942 3.37506L5.83576 4.51191C6.08737 4.6517 6.33535 4.32295 6.13182 4.11942L4.08548 2.07308C3.92911 1.91671 3.66175 2.02745 3.66175 2.24859V3.15808C3.66175 3.24822 3.71062 3.33128 3.78942 3.37506Z" />
        <path d="M10.0903 4.45748L11.819 3.37704C11.8916 3.33168 11.9357 3.25214 11.9357 3.16655V2.30221C11.9357 2.07396 11.6536 1.96671 11.5019 2.1373L9.77324 4.08208C9.58272 4.29643 9.84713 4.60947 10.0903 4.45748Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.38502 6.45879L0.948144 3.29953C0.673266 3.16462 0.352173 3.36471 0.352173 3.67091V12.1195C0.352173 12.5565 0.612632 12.9515 1.01434 13.1237L4.59576 14.4633C5.3505 14.7456 6.07788 15.0962 6.76886 15.5108L7.38502 15.8805V6.45879ZM1.76445 5.22475L5.48772 6.91715C5.63541 6.98428 5.73023 7.13153 5.73023 7.29376V8.03969C5.73023 8.34057 5.41925 8.54081 5.14534 8.41631L1.42207 6.72391C1.27439 6.65678 1.17957 6.50952 1.17957 6.3473V5.60137C1.17957 5.30049 1.49054 5.10025 1.76445 5.22475Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.62611 6.45327V15.9219L10.6946 14.8876L14.9856 13.1237C15.3873 12.9516 15.6478 12.5565 15.6478 12.1195V3.6709C15.6478 3.3647 15.3267 3.16462 15.0518 3.29953L8.62611 6.45327ZM10.1097 7.33084L13.833 5.63845C14.1069 5.51394 14.4179 5.71419 14.4179 6.01506V6.76099C14.4179 6.92322 14.323 7.07048 14.1754 7.13761L10.4521 8.83C10.1782 8.95451 9.8672 8.75426 9.8672 8.45339V7.70746C9.8672 7.54523 9.96202 7.39797 10.1097 7.33084ZM11.7507 8.98152L10.0959 9.80891C9.95573 9.87899 9.8672 10.0222 9.8672 10.1789V12.5635C9.8672 12.871 10.1908 13.071 10.4659 12.9335L12.1207 12.1061C12.2608 12.036 12.3494 11.8928 12.3494 11.7361V9.35154C12.3494 9.044 12.0257 8.84398 11.7507 8.98152Z"
        />
    </svg>
)
IconDigitalEdition.displayName = 'IconDigitalEdition'
