import React from 'react'
import { RSSIcon } from '../RSSIcon'
import {
    StyledRSSLink,
    StyledRSSList,
    StyledRSSListItem,
} from './RSSList.styled'

export interface Props {
    items: Array<{
        title: string
        link: string
    }>
}

// type is missing on the typing of React.AnchorHTMLAttributes
const type: object = { type: 'application/rss+xml' }

export const RSSList: React.FC<Props> = ({ items }) => (
    <StyledRSSList>
        {items.map(({ link, title }, index) => (
            <StyledRSSListItem key={index}>
                <StyledRSSLink href={link} rel="alternate" {...type}>
                    <RSSIcon />
                    <h2>{title}</h2>
                </StyledRSSLink>
            </StyledRSSListItem>
        ))}
    </StyledRSSList>
)
