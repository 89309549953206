import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import React from 'react'
import { LabelledToggle } from '../buttons/LabelledToggle/LabelledToggle'
import { ToggleButton } from '../buttons/ToggleButton/ToggleButton'
import { useProduct } from '../__product'
import { fonts, themedValue } from '../__styling'

export function HideOddsControl({
    value,
    onToggle,
}: {
    value: boolean
    onToggle: () => void
}) {
    const product = useProduct()

    return (
        <Container>
            <Label>Show betting odds</Label>
            {product === 'sevennews' ? (
                <ToggleButtonContainer>
                    <ToggleButton
                        name={'afl_betting_odds_toggle'}
                        onToggle={onToggle}
                        checked={value}
                    />
                </ToggleButtonContainer>
            ) : (
                <LabelledToggle
                    on="Yes"
                    off="No"
                    onToggle={onToggle}
                    checked={value}
                ></LabelledToggle>
            )}
        </Container>
    )
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
})

const Label = styled('p')(({ theme }) => ({
    marginRight: 12,
    fontFamily: themedValue(theme, {
        thewest: fonts.thewest.serif,
        perthnow: fonts.perthnow.sansSerif,
        sevennews: fonts.sevennews.sansSerif,
        fallback: undefined,
    }),
    color: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.bauhausBlack,
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        sevennews: 400,
        fallback: undefined,
    }),
}))

const ToggleButtonContainer = styled('div')({
    '& > div': { margin: 0 },
})
