import React from 'react'
import { StyledHomeIcon, StyledHomeLink } from './TheWestHeaderActions.styled'

export const HomeAction: React.FC = () => {
    return (
        <StyledHomeLink to="/" aria-label="Home">
            <StyledHomeIcon />
        </StyledHomeLink>
    )
}
HomeAction.displayName = 'HomeAction'
