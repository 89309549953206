import React from 'react'

export interface IconChevronRightAltProps {
    title?: string
    className?: string
}

export const IconChevronRightAlt: React.FC<IconChevronRightAltProps> = ({
    className,
    title,
}) => (
    <svg
        viewBox="0 0 16 29"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title || 'Chevron Right Icon'}</title>
        <path d="M.79.236H4.29l8.12 13.727L4.3 28.031H.79l8.149-14.049z" />
    </svg>
)
