import { ArticleV4DTO } from '@west-australian-newspapers/publication-types'
import { RedirectPublication } from '../../../../client-data-types/publication'
import { getArticleAndRedirectCommonProps } from '../../../../__api-mapping/v4/publication/map/common'

export function mapRedirect(publication: ArticleV4DTO): RedirectPublication {
    const commonProps = getArticleAndRedirectCommonProps(publication)
    const redirectUrl = publication.redirectUrl || ''
    const requiredAccess = publication.requiredAccess ?? {
        level: 'anonymous',
    }

    return {
        ...commonProps,
        requiredAccess,

        kind: 'redirect',

        redirectUrl,
    }
}
