import {
    createRegisterableComponent,
    PublicationType,
} from '@news-mono/web-common'
import React from 'react'
import { useSectionMetaInfo } from '../../__helpers'
import { RegionalsSwgPromoPageProps } from './RegionalsSwgPromoPage'
import { LazyRegionalsSwgPromoPage } from './RegionalsSwgPromoPage.lazy'

type RegionalsSwgPromoPage = Omit<
    RegionalsSwgPromoPageProps,
    'onEvent' | 'publication' | 'sectionMeta'
>
export const RegionalsSwgPromoPageRegistration = createRegisterableComponent(
    'regionals-swg-promo-page',
    (props: RegionalsSwgPromoPage, services) => {
        const sectionMeta = useSectionMetaInfo()

        const publication: PublicationType = {
            kind: 'article',
            byline: 'Campaign',
            source: 'Subscribe with Google',
            slug: 'subscribewithgoogle',
            heading: `Subscribe with Google Campaign ${sectionMeta.Title}`,
            content: [],
            canonicalTitle: `Subscribe with Google Campaign ${sectionMeta.Title}`,
            canonicalUrl: '',
            layoutView: 'default',
            requiredAccess: {
                level: 'subscriber',
            },
            excludeFeaturedVideo: false,
            allowCommenting: false,
            _self: '',
            id: '',
            homepageHead: undefined,
            socialHead: undefined,
            teaser: undefined,
            socialTeaser: undefined,
            publicationDate: '',
            isSponsored: false,
            hasOovvuuRecommendations: false,
            homepageTeaser: '',
            primaryTopic: {
                id: '',
                title: '',
                seoTitle: '',
                metadata: {},
            },
            secondaryTopics: [],
        }

        return (
            <LazyRegionalsSwgPromoPage
                title={props.title}
                description={props.description}
                image={props.image}
                productId={props.productId}
                onEvent={services.onEvent}
                publication={publication}
                sectionMeta={sectionMeta}
                options={props.options}
            />
        )
    },
)
