/* eslint-disable @typescript-eslint/no-var-requires */
import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

const featureCurationId = '2019-cars-of-the-year'

const bannerDesktopX2 = require<string>('./assets/coty-2019/cotyBanner-desktop-1220x150@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/coty-2019/cotyBanner-desktop-1220x150@1x.jpg')

const bannerTabletX2 = require<string>('./assets/coty-2019/cotyBanner-tablet-738x57@2x.jpg')

const bannerTabletX1 = require<string>('./assets/coty-2019/cotyBanner-tablet-738x57@1x.jpg')

const bannerMobileX2 = require<string>('./assets/coty-2019/cotyBanner-tablet-411x93@2x.jpg')

const bannerMobileX1 = require<string>('./assets/coty-2019/cotyBanner-tablet-411x93@1x.jpg')

export const getCarOfTheYear2019RouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: bannerDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText: 'Cars of the Year - The West Australian',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: bannerDesktopX2, density: '2x' },
                            { src: bannerDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: bannerTabletX2, density: '2x' },
                            { src: bannerTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: bannerMobileX2, density: '2x' },
                            { src: bannerMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.nestedComposition({
            type: 'janus',
            props: {
                layoutRatio: '3:1',
                stretchColumns: true,
                verticalSpacing: 'xl',
            },
            contentAreas: {
                left: [
                    layout.component({
                        type: 'sierra',
                        props: {
                            fixedRatios: ['16:9'],
                            heroImageLayout:
                                ResponsivePictureLayout.ObjectFitContain,
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                                maxBreakpoint: 'md',
                            },
                            cardLayout: ['hero'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: featureCurationId,
                                pageSize: 1,
                                offset: 0,
                            },
                        },
                    }),
                ],
                right: [
                    layout.component({
                        type: 'tw-subscribe-promo-card',
                        props: {},
                    }),
                ],
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                sectionHeader: {
                    heading: '2019 Cars of the Year',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: featureCurationId,
                    pageSize: 11,
                    offset: 1,
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,
                sectionHeader: {
                    heading: 'Motoring',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/motoring'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                        pageOffset: 0,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('three'),
    ]
}
