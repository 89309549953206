import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import React from 'react'

export interface IconLiveTNProps {
    fillColor?: string
    className?: string
    useAnimation?: boolean
}

// stage 1 is a dot
// stage 2 is inner show
// stage 3 is outer show
// stage 4 is hide inner
// stage 5 is hide outer
const innerAnimation = keyframes({
    '0%': {
        opacity: 0,
    },
    '15%, 30%': {
        opacity: 1,
    },
    '55%, 100%': {
        opacity: 0,
    },
})
const outerAnimation = keyframes({
    '0%, 15%': {
        opacity: 0,
    },
    '30%, 45%': {
        opacity: 1,
    },
    '70%, 100%': {
        opacity: 0,
    },
})

const AnimatedSvg = styled('svg')<IconLiveTNProps>(
    ({ useAnimation = true }) => [
        {
            width: 24,
            height: 24,
            fill: 'none',
        },
        useAnimation && {
            '.inner': {
                animation: `${innerAnimation} 1.3s ease-in-out 0s infinite normal forwards`,
            },
            '.outer': {
                animation: `${outerAnimation} 1.3s ease-in-out 0s infinite normal forwards`,
            },

            '@media (prefers-reduced-motion: reduce)': {
                animation: 'none !important',
            },
        },
    ],
)

export const IconLiveTN: React.FC<IconLiveTNProps> = ({
    fillColor = '#9D0009',
    className,
    useAnimation = true,
}) => (
    <AnimatedSvg
        useAnimation={useAnimation}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={className}
    >
        <circle cx="12" cy="12" r="3" fill={fillColor} />
        <path
            className="outer"
            d="M4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 10.6868 2.25866 9.38642 2.76121 8.17316C3.26375 6.95991 4.00035 5.85752 4.92893 4.92893"
            stroke={fillColor}
            strokeWidth={2}
            strokeLinecap={'round'}
        />
        <path
            className="outer"
            d="M19.0711 4.92991C19.9997 5.85849 20.7362 6.96089 21.2388 8.17414C21.7413 9.3874 22 10.6878 22 12.001C22 13.3142 21.7413 14.6146 21.2388 15.8278C20.7362 17.0411 19.9997 18.1435 19.0711 19.072"
            stroke={fillColor}
            strokeWidth={2}
            strokeLinecap={'round'}
        />
        <path
            className="inner"
            d="M7.75736 16.2436C7.20021 15.6865 6.75825 15.025 6.45672 14.2971C6.15519 13.5691 6 12.7889 6 12.001C6 11.213 6.15519 10.4328 6.45672 9.70488C6.75825 8.97692 7.20021 8.31549 7.75736 7.75834"
            stroke={fillColor}
            strokeWidth={2}
            strokeLinecap={'round'}
        />
        <path
            className="inner"
            d="M16.2426 7.75736C16.7998 8.31451 17.2417 8.97595 17.5433 9.7039C17.8448 10.4319 18 11.2121 18 12C18 12.7879 17.8448 13.5681 17.5433 14.2961C17.2417 15.0241 16.7998 15.6855 16.2426 16.2426"
            stroke={fillColor}
            strokeWidth={2}
            strokeLinecap={'round'}
        />
    </AnimatedSvg>
)
IconLiveTN.displayName = 'IconLiveTN'
