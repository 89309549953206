/**
 * Specify the % of viewport the responsive image takes up at that viewport using vw units
 * or use px to specify a fixed width at that breakpoint
 * @example
 * { mobile: '100vw', tablet: '90vw', desktop: '640px' }
 **/
export interface ResponsivePictureSizes {
    /** width of image at sm breakpoint */
    mobile: string
    /** width of image at md breakpoint */
    tablet: string
    /** maximum rendered width of the image */
    desktop: string

    /** The width for the non-responsive src */
    fallbackWidth: number

    /**
     * Defines if px values will be scaled by the container ratio
     * @default scaled
     */
    mode?: 'fixed' | 'scaled'
}

export interface ContainerWidthRatios {
    mobile: number
    tablet: number
    desktop: number
}

export interface ResponsiveContainer {
    /**
     * The proportion of the total collection width this card will take up
     * @example containerWidthRatio: 0.5 if card takes up 50% of the collection width
     **/
    containerWidthRatios?: ContainerWidthRatios
}

export function applyContainerRatio(
    containerWidthRatios: ContainerWidthRatios | undefined,
    responsiveSize: ResponsivePictureSizes,
): ResponsivePictureSizes {
    const mode = responsiveSize.mode || 'scaled'
    return containerWidthRatios
        ? {
              mobile: applyContainerRatioValue(
                  containerWidthRatios.mobile,
                  responsiveSize.mobile,
                  mode,
              ),
              tablet: applyContainerRatioValue(
                  containerWidthRatios.tablet,
                  responsiveSize.tablet,
                  mode,
              ),
              desktop: applyContainerRatioValue(
                  containerWidthRatios.desktop,
                  responsiveSize.desktop,
                  mode,
              ),
              fallbackWidth:
                  mode === 'fixed'
                      ? Math.ceil(responsiveSize.fallbackWidth)
                      : Math.ceil(
                            responsiveSize.fallbackWidth *
                                containerWidthRatios.desktop,
                        ),
          }
        : responsiveSize
}

// Applies the containers ratio to the value if it's a VW value
function applyContainerRatioValue(
    containerWidthRatio: number,
    responsiveValue: string,
    mode: 'fixed' | 'scaled',
): string {
    if (responsiveValue.indexOf('vw') !== -1) {
        return `${Math.ceil(
            Number(responsiveValue.slice(0, -2)) * containerWidthRatio,
        )}vw`
    }

    if (mode === 'scaled') {
        return `${Math.ceil(
            Number(responsiveValue.slice(0, -2)) * containerWidthRatio,
        )}px`
    }

    return responsiveValue
}
