import { flatMap, insertSortedAsc } from '../helpers/utils'
import { getAdSize } from './get-ad-size'
import { SizeMapping } from './size-mapping'

interface WithSizeMapping {
    sizeMapping: SizeMapping[]
}

export function getAdBreakpoints(ads: WithSizeMapping[]) {
    const ranges: number[] = []

    ads.forEach((ad) => {
        ad.sizeMapping.forEach((sizeMapping) => {
            const width = sizeMapping.viewport[0]
            insertSortedAsc(ranges, width, (item) => item)
        })
    })

    return ranges
}

export interface BreakpointAds<T extends WithSizeMapping> {
    start: number
    end?: number
    ads: T[]
}
export function getAdByBreakpoints<T extends WithSizeMapping>(
    ads: T[],
): {
    breakpoints: number[]
    ads: BreakpointAds<T>[]
} {
    const ranges = getAdBreakpoints(ads)

    // Then we break overlaps into their own ranges
    // (ie an ad which spans 3 breakpoints will get copied into all 3)
    return {
        breakpoints: ranges,
        ads: ranges.map<BreakpointAds<T>>((breakpoint, index) => {
            const breakpointEnd = ranges[index + 1]
            // If the ad displays at the start breakpoint, it will for that whole range
            const adsWhichWillDisplay = flatMap(ads, (ad) => {
                const adSize = getAdSize(ad.sizeMapping, breakpoint)

                if (!adSize || adSize.slot.length === 0) {
                    return []
                }

                return [
                    {
                        ...ad,
                        sizeMapping: breakpointEnd
                            ? [
                                  { viewport: [breakpointEnd, 0], slot: [] },
                                  {
                                      viewport: [breakpoint, 0],
                                      slot: adSize.slot,
                                  },
                              ]
                            : [
                                  {
                                      viewport: [breakpoint, 0],
                                      slot: adSize.slot,
                                  },
                              ],
                    },
                ]
            })

            return {
                start: breakpoint,
                end: breakpointEnd,
                ads: adsWhichWillDisplay,
            }
        }),
    }
}
