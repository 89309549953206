import { EntitledToView, WebLink } from '@news-mono/web-common'
import React from 'react'
import {
    StyledPromoImg,
    StyledRetireReadyPromoCard,
} from './RetireReadyPromoCard.styled'

export const EntitledView = () => {
    const promo = require('./images/2024-Retire-Ready-Box-Logged-In.jpg')

    return (
        <StyledRetireReadyPromoCard>
            <WebLink to="#your-money">
                <StyledPromoImg
                    src={promo}
                    alt="Personal finance news for you - Read now"
                />
            </WebLink>
        </StyledRetireReadyPromoCard>
    )
}
export const OpportunityView: React.FC = () => {
    const promo = require('./images/2024-Retire-Ready-Box-Logged-Out.jpg')

    return (
        <StyledRetireReadyPromoCard>
            <WebLink to="https://subscriber.thewest.com.au/retireready">
                <StyledPromoImg
                    src={promo}
                    alt="Get access to the episodes now - Find out how"
                />
            </WebLink>
        </StyledRetireReadyPromoCard>
    )
}

export const RetireReadyPromoCard: React.FC = () => {
    return (
        <EntitledToView
            requiredAccess={{ level: 'subscriber' }}
            entitled={() => {
                return <EntitledView />
            }}
            notEntitled={() => {
                return <OpportunityView />
            }}
        />
    )
}
