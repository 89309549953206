import { createRegisterableComponentWithData } from '@news-mono/web-common'
import React from 'react'

export const FailLoadContentRegistration = createRegisterableComponentWithData(
    'fail-load-content',
    {
        loadData: () => Promise.reject(new Error('Failed to load')),
    },
    (_: {}, data, _services) => {
        if (data.loaded) {
            return <p>Data Load Success!</p>
        }
        return <p>Data load epic fail!</p>
    },
)

export const FailLoadContent404Registration =
    createRegisterableComponentWithData(
        'fail-load-content-404',
        {
            loadData: () => Promise.reject(new Error('404')),
        },
        (_, data, _services) => {
            if (data.loaded) {
                return <p>Data Load Success!</p>
            }
            return <p>Data load epic fail!</p>
        },
    )
