import { CSSObject } from '@emotion/css'
import { ResponsivePictureLayout } from '../ResponsivePictureLayouts'
import { pictureLayoutNone } from './none'
import { pictureLayoutObjectFitContain } from './object-fit-contain'
import { pictureLayoutObjectFitCover } from './object-fit-cover'

export interface PictureLayout {
    pictureStyles: () => CSSObject[]
}

export const pictureLayouts: Record<ResponsivePictureLayout, PictureLayout> = {
    [ResponsivePictureLayout.None]: pictureLayoutNone,
    [ResponsivePictureLayout.ObjectFitContain]: pictureLayoutObjectFitContain,
    [ResponsivePictureLayout.ObjectFitCover]: pictureLayoutObjectFitCover,
}
