import { AllEvents, CardItem } from '@news-mono/web-common'
import * as React from 'react'
import { Sierra } from '../../collections/Sierra/Sierra'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import {
    SierraClassName,
    StyledArticleContainer,
    StyledBackground,
    StyledContainer,
    StyledDevelopingNews,
    StyledHeaderContainer,
    StyledHeadline,
    StyledSierraModifier,
} from './ComingSoon.styled'

export interface ComingSoonProps {
    headLine: string
    item: CardItem
    onEvent: (event: AllEvents) => void
}

export const ComingSoon: React.FC<ComingSoonProps> = ({ headLine, item }) => {
    return (
        <ThemeOverrider override={'developing-news'}>
            <StyledBackground>
                <StyledDevelopingNews numUniformStories={1}>
                    <StyledContainer>
                        <StyledSierraModifier>
                            <StyledHeaderContainer>
                                <StyledHeadline>{headLine}</StyledHeadline>
                            </StyledHeaderContainer>
                            <StyledArticleContainer>
                                {
                                    <Sierra
                                        expectedCards={1}
                                        items={{
                                            loaded: true,
                                            result: [item],
                                        }}
                                        cardLayout={['hero']}
                                        className={SierraClassName}
                                        onEvent={() => {}}
                                        hasBorder={false}
                                    ></Sierra>
                                }
                            </StyledArticleContainer>
                        </StyledSierraModifier>
                    </StyledContainer>
                </StyledDevelopingNews>
            </StyledBackground>
        </ThemeOverrider>
    )
}
