import styled, { CSSObject } from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import {
    AlignItemsProperty,
    FlexDirectionProperty,
    JustifyContentProperty,
} from 'csstype'
import { ThemeConfig } from '../../__styling'
import {
    BreakpointKeys,
    Breakpoints,
} from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import {
    ContainerWidth,
    metrics,
    ThemeMargins,
} from '../../__styling/settings/metrics'
import {
    breakpoint,
    BreakpointState,
    breakpointSwitch,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export type FlexProps = BreakpointState<
    ThemeConfig,
    {
        gap?: string // use calcRem
        flexDirection?: FlexDirectionProperty
        alignItems?: AlignItemsProperty
        justifyContent?: JustifyContentProperty
        hasBeforeAfterMargins?: boolean
        flexGrow?: number
    }
>

export interface RawProps {
    hasTopSpacing?: boolean
    hasBackgroundFill?: boolean
    backgroundColor?: string
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
    horizontalGutters?: keyof ThemeMargins
    horizontalGuttersOverride?: number
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    containerWidth?: ContainerWidth
    fillContainer?: boolean
    stretchColumns?: boolean
    nightlyArticleSidebar?: boolean
    flex?: FlexProps
    horizontalSpacingBreakpoint?: BreakpointKey
    positionOffset?: {
        direction: 'top' | 'bottom'
        value: number
    }
    containerHeight?: number
    stickyOffset?: number | string
    fullWidth?: boolean // Introduced to allow static pages to take full containerWidth when flex grow isn't enough
    hideBottomGap?: boolean
}

export type BreakPointProps = { [key in BreakpointKeys]?: RawProps }

export interface BoxProps extends RawProps {
    breakpoints?: BreakPointProps
}

const getStylesFromProps = (
    props: RawProps,
    kind: Product,
): Array<CSSObject | false | undefined> => {
    const margins = metrics[kind].margins

    const horizontalSpacing =
        props.horizontalSpacing && calcRem(margins[props.horizontalSpacing])
    const verticalSpacing =
        props.verticalSpacing && calcRem(margins[props.verticalSpacing])
    const horizontalGutters =
        props.horizontalGutters && calcRem(margins[props.horizontalGutters])
    const horizontalGuttersOverride =
        props.horizontalGuttersOverride &&
        calcRem(props.horizontalGuttersOverride)
    const topVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[0]])
    const bottomVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[1]])

    const positionOffsetDirection =
        props.positionOffset && props.positionOffset.direction
    const positionOffsetValue =
        props.positionOffset && props.positionOffset.value

    return [
        {
            marginLeft: horizontalSpacing,
            marginRight: horizontalSpacing,
            marginBottom: verticalSpacing,
            paddingLeft: horizontalGuttersOverride || horizontalGutters,
            paddingRight: horizontalGuttersOverride || horizontalGutters,
            paddingTop: topVerticalGutters,
            paddingBottom: bottomVerticalGutters,

            flexGrow: props.fillContainer ? 1 : undefined,
            marginTop: props.hasTopSpacing ? verticalSpacing : undefined,
            height: props.containerHeight
                ? `${props.containerHeight}px`
                : undefined,
            width: props.fullWidth ? '100%' : undefined,
        },
        props.stretchColumns && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        props.nightlyArticleSidebar && {
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
            backgroundColor: 'red',
        },
        props.flex &&
            breakpointSwitch(
                props.flex,
                ({ hasBeforeAfterMargins, ...styles }) => {
                    let margin
                    if (hasBeforeAfterMargins) {
                        switch (styles.flexDirection) {
                            case 'column':
                            case 'column-reverse':
                                margin = `${styles.gap} 0`
                                break
                            default:
                                margin = `0 ${styles.gap}`
                        }
                    }

                    return {
                        display: 'flex',
                        flexGrow: 1,
                        ...styles,
                        margin,
                    }
                },
            ),
        props.horizontalSpacingBreakpoint && {
            [breakpoint(props.horizontalSpacingBreakpoint)]: {
                marginRight: 0,
                marginLeft: 0,
            },
        },
        props.stickyOffset !== undefined && {
            position: 'sticky',
            top: props.stickyOffset,

            transition: 'top 0.25s',
        },
        props.containerWidth !== undefined && {
            maxWidth: props.containerWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        props.positionOffset !== undefined && {
            position: 'relative',
            marginTop:
                positionOffsetDirection === 'top'
                    ? positionOffsetValue && calcRem(-positionOffsetValue)
                    : undefined,
        },
        props.hideBottomGap && {
            [breakpoint('sm')]: {
                marginBottom: calcRem(-64),
            },

            [breakpoint('lg')]: {
                marginBottom: calcRem(-80),
            },
        },
    ]
}
type BreakpointKey = keyof Breakpoints
export const Box = styled('div')<BoxProps>(
    /**
     *  The following takes an object in the shape of breakpoints and reduces that into media queries and styles.
     *  The purpose here is to apply different props at certain breakpoints.
     */
    ({
        theme,
        breakpoints,
        backgroundColor,
        hasBackgroundFill,
        ...props
    }): Array<CSSObject | false | undefined> => {
        const breakpointStyles = Object.keys(
            breakpoints || {},
        ).reduce<CSSObject>((styles, key) => {
            const passedBreakpoints = breakpoints as BreakPointProps
            // cast the breakpoint vars - we know they'll equal something because keys will exist
            const breakpointProps = passedBreakpoints[key as BreakpointKey]
            if (breakpointProps) {
                styles[breakpoint(key as BreakpointKey)] = getStylesFromProps(
                    breakpointProps,
                    theme.kind,
                )
            }
            return styles
        }, {})

        return [
            ...getStylesFromProps(
                {
                    backgroundColor,
                    hasBackgroundFill,
                    ...props,
                },
                theme.kind,
            ),
            breakpointStyles,
            {
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : hasBackgroundFill
                    ? colors.white
                    : 'transparent',
            },
        ]
    },
)
Box.displayName = 'Box'
