import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { LiveBlogEvents } from 'web-common/src/events'
import { DataLayerEventName } from 'web-common/src/helpers'
import { useCallback, useState } from 'react'
import {
    aggressiveScrollTo,
    clearDeepLink,
    DebugEventAdder,
    deleteFromPostList,
    isPublishedMilestone,
    isPublishedPinnedPost,
    scrollToElement,
    updatePostList,
    useFetchEventPosts,
} from '../helpers'
import { PostState } from './PostState'
import { useLiveEventPosts } from './useLiveEventPosts'

/** How many posts to load on loadMore **/
const DEFAULT_LOAD_MORE_COUNT = 5
/** The number of posts loaded before a deep-linked post. */
const DEEP_LINK_BEFORE_FOCUSED = 5
/** The number of posts loaded after a deep-linked post. */
const DEEP_LINK_AFTER_FOCUSED = 5

type UseLiveEventLoadMoreControllerParams = {
    /** The publication ID of the live event being controlled. */
    publicationId: string
    /** The initial state of posts on the page. */
    initialPostState: PostState
    /** A callback fired whenever a new post is recieved via websocket. */
    onLiveEventUpdate?: (post: KilledEventPostV4DTO | EventPostV4DTO) => void
    /** Method to fire analytics events. */
    onEvent: (event: LiveBlogEvents) => void
    /** Debug menu controls. */
    addDebugEvent: DebugEventAdder
}

type UseLiveEventLoadMoreControls = {
    /** If the controller is fetching new posts. */
    isFetching: boolean
    /** The various live event posts. */
    postState: PostState
    /** A function which accepts the posts recieved via websocket since the page was last loaded. */
    loadUpdates: () => void
    /** Deep links to the respective post. */
    handleDeepLink: (postId: string) => void
    /** Loads more posts. */
    loadMore: (amount?: number) => void
    /** If therer are more posts to load. */
    canLoadMore: boolean
}

export const useLiveEventLoadMoreController = ({
    publicationId,
    initialPostState,
    onLiveEventUpdate,
    onEvent,
    addDebugEvent,
}: UseLiveEventLoadMoreControllerParams): UseLiveEventLoadMoreControls => {
    const { fetchEventPosts, isFetching } = useFetchEventPosts()

    const [activePosts, setActivePosts] = useState(initialPostState.activePosts)
    const [postCount, setPostCount] = useState(initialPostState.postCount)
    const [stickyPosts, setStickyPosts] = useState(initialPostState.stickyPosts)
    const [milestonePosts, setMilestonePosts] = useState(
        initialPostState.milestonePosts,
    )
    const [queuedPosts, setQueuedPosts] = useState(initialPostState.queuedPosts)

    const [isDeepLinkState, setIsDeepLinkState] = useState(false)
    const [canLoadMore, setCanLoadMore] = useState(
        initialPostState.activePosts.length < initialPostState.postCount,
    )

    // A ref to hold this reference so as to not trigger re-renders unnecessarily.
    const oldestActivePost =
        activePosts.length > 0 ? activePosts[activePosts.length - 1] : undefined

    const loadMore = useCallback(async () => {
        if (isFetching) return
        addDebugEvent('Loading more posts...')

        const result = await fetchEventPosts({
            publicationId,
            reference: oldestActivePost?.id,
            after: 0,
            before: DEFAULT_LOAD_MORE_COUNT,
        })

        onEvent({
            type: DataLayerEventName.loadMoreOlderPosts,
            originator: `LiveEvent`,
            payload: {
                publicationId,
                lastPostPrior: oldestActivePost?.id ?? 'first post',
            },
        })

        // Update total post count.
        const newPostCount = result.totalAfter + result.totalBefore + 1
        setPostCount(newPostCount)

        const [_, ...newPosts] = result.documents
        setActivePosts((oldActivePosts) => [...oldActivePosts].concat(newPosts))

        setCanLoadMore(result.totalBefore >= DEFAULT_LOAD_MORE_COUNT)

        addDebugEvent(`${result.documents.length} new posts loaded.`)
    }, [
        addDebugEvent,
        fetchEventPosts,
        isFetching,
        oldestActivePost,
        onEvent,
        publicationId,
    ])

    const loadUpdates = useCallback(() => {
        setQueuedPosts((queuedPosts) => {
            setIsDeepLinkState((isDeepLinkState) => {
                if (isDeepLinkState) {
                    addDebugEvent(
                        'Exiting deep-link state and restoring original content.',
                    )
                    // Clear active posts as they will not be in the correct order if merged.
                    setActivePosts(queuedPosts)
                    // If it was in a deep link state, more must be able to be loaded.
                    setCanLoadMore(true)
                } else {
                    addDebugEvent('Updating list with new posts.')
                    setActivePosts((oldPosts) =>
                        updatePostList(oldPosts, ...queuedPosts),
                    )

                    setPostCount((oldCount) => oldCount + queuedPosts.length)
                }
                // Was either not in a deep link state, or just exited it.
                return false
            })

            // Clear queued posts.
            return []
        })
    }, [addDebugEvent])

    // Handle websocket events.
    const onPostCreate = useCallback(
        (post: EventPostV4DTO) =>
            setQueuedPosts((oldPosts) => {
                if (
                    oldPosts.find(
                        (oldPost) =>
                            oldPost.id === post.id &&
                            oldPost.lastUpdated === post.lastUpdated,
                    )
                ) {
                    // Duplicate update event.
                    return oldPosts
                }

                addDebugEvent(
                    `Post ${post.id} recieved as update. Incorporating and adding to update queue.`,
                )
                // Update milestone/sticky posts if applicable.
                if (isPublishedPinnedPost(post)) {
                    setStickyPosts((oldPosts) => updatePostList(oldPosts, post))
                }

                if (isPublishedMilestone(post)) {
                    setMilestonePosts((oldPosts) =>
                        updatePostList(oldPosts, post),
                    )
                }

                onLiveEventUpdate?.(post)
                return [...oldPosts, post]
            }),
        [addDebugEvent, onLiveEventUpdate],
    )

    const onPostDelete = useCallback(
        (deletedPost: KilledEventPostV4DTO) => {
            addDebugEvent(
                `Post ${deletedPost.id} recieved deletion update. Deleting post.`,
            )
            // Remove from milestones/sticky lists.
            setStickyPosts((oldPosts) =>
                deleteFromPostList(deletedPost, oldPosts),
            )
            setMilestonePosts((oldPosts) =>
                deleteFromPostList(deletedPost, oldPosts),
            )
            setActivePosts((oldPosts) =>
                deleteFromPostList(deletedPost, oldPosts),
            )

            // It is possible for the deletion request to be invalid, so this is an assumption at best.
            // State will be recovered once another set of posts is loaded if it gets into a bad state.
            setPostCount((oldCount) => oldCount - 1)

            onLiveEventUpdate?.(deletedPost)
        },
        [addDebugEvent, onLiveEventUpdate],
    )

    useLiveEventPosts({
        publicationId,
        onPostCreate,
        onPostDelete,
    })

    const handleDeepLink = useCallback(
        async (postId: string) => {
            // Only enter a deep link state if the requested post isn't already loaded.
            if (activePosts.findIndex((post) => post.id === postId) === -1) {
                addDebugEvent(
                    `Post ${postId} not found on page! Entering deep link state.`,
                )
                const result = await fetchEventPosts({
                    publicationId,
                    reference: postId,
                    before: DEEP_LINK_BEFORE_FOCUSED,
                    after: DEEP_LINK_AFTER_FOCUSED,
                })

                if (!isDeepLinkState) {
                    //Shift existing values into queued list.
                    setActivePosts((oldActivePosts) => {
                        setQueuedPosts(oldActivePosts)

                        // Set active posts to those including deep linked post.
                        return result.documents
                    })
                }
                setCanLoadMore(result.totalBefore >= DEFAULT_LOAD_MORE_COUNT)
                setIsDeepLinkState(true)
            }

            addDebugEvent(`Post ${postId} loaded. Scrolling to post.`)
            // On the next frame (after render) scroll to post.
            await aggressiveScrollTo(postId)
            clearDeepLink()
        },
        [
            activePosts,
            addDebugEvent,
            fetchEventPosts,
            isDeepLinkState,
            publicationId,
        ],
    )

    return {
        isFetching,
        postState: {
            activePosts,
            stickyPosts,
            milestonePosts,
            queuedPosts,
            postCount,
        },
        loadUpdates,
        handleDeepLink,
        loadMore,
        canLoadMore,
    }
}
