import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '@news-mono/component-library'

export const StyledRSSList = styled('ul')({
    display: 'flex',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    listStyle: 'none',
    flexWrap: 'wrap',
    justifyContent: 'space-around',

    [breakpoint('sm')]: {
        justifyContent: 'space-between',
    },
})

export const StyledRSSListItem = styled('li')({
    padding: `${calcRem(25)} ${calcRem(12)}`,
    width: '100%',
    textAlign: 'center',

    [breakpoint('xs')]: {
        maxWidth: calcRem(280),
        width: '50%',
    },

    ['& .icon']: {
        width: calcRem(40),
        height: calcRem(40),
        color: 'inherit',
        fill: colors.thewest.greyGorilla,
    },
})

export const StyledRSSLink = styled('a')(({ theme }) => ({
    display: 'block',
    borderBottom: `3px solid ${colors.thewest.greyMouse}`,
    color: colors.black,
    textDecoration: 'none',

    '&:hover, &:focus': {
        borderColor: theme.sectionValues.primaryColor,
        background: 'none',
        color: theme.sectionValues.primaryColor,
    },
}))
