import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { ComingSoon } from './ComingSoon'

export const ComingSoonRegistration = createRegisterableComponentWithData(
    'coming-soon',
    CurationAndCurationMetadataLoader,
    (props: {}, data, services) => {
        if (data.loaded && data.result.articles.length > 0) {
            return (
                <ComingSoon
                    item={data.loaded && data.result.articles[0]}
                    headLine={'Coming Soon'}
                    onEvent={services.onEvent}
                />
            )
        }
        return null
    },
)
