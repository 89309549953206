import { TheWestRegionalSections } from '@news-mono/common'
import { FeatureConfig } from '@news-mono/web-common'
import { createRenderTarget } from '../../render-target'
import { TheWestFooterNetworkLinksWeb } from './TheWestFooterNetworkLinks.web'

type Link = {
    name: string
    link: string
} & FeatureConfig

export interface NetworkLink {
    name: string
    links: Link[]
}

export interface BaseSocial {
    facebookMessenger: string
    facebook: string
    email: string
}

export interface Address {
    street: string
    city: string
    state: 'WA'
    postcode: number
    phone: string
}

// Regional Social links just have email, facebook & messenger, hence the BaseSocial type
// Regionals also include the Address interface
export type SectionSocialLinks = {
    [section in TheWestRegionalSections]: BaseSocial & Address
}

export type SectionedSocialLinks = SectionSocialLinks & {
    default: BaseSocial & {
        twitter: string
        instagram: string
        rss: string
        youtube?: string
    }
}

export interface TheWestFooterNetworkLinksProps {
    networkLinks: {
        regional: NetworkLink[]
        thewest: NetworkLink[]
    }
    socialLinks: SectionedSocialLinks
}

export const TheWestFooterNetworkLinks = createRenderTarget(
    'TheWestFooterNetworkLinks',
    {
        web: TheWestFooterNetworkLinksWeb,
        amp: TheWestFooterNetworkLinksWeb,
        rss: TheWestFooterNetworkLinksWeb,
        preview: TheWestFooterNetworkLinksWeb,
        app: null,
    },
)
TheWestFooterNetworkLinks.displayName = 'TheWestFooterNetworkLinks'
