import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export const ShareIcon = () => {
    return (
        <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.7"
                d="M10.2308 4V1L17 6L10.2308 11V7.5C6.81538 7.5 3.67692 8.1 1 12C1.35692 9.68421 1.71385 4.57895 10.2308 4Z"
                stroke={colors.sevennews.red}
                strokeWidth="1.2"
                strokeMiterlimit="16"
                strokeLinejoin="round"
            />
        </svg>
    )
}
