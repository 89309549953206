export const enum DataLayerEventName {
    adClick = 'ad.click',
    adEnd = 'ad.end',
    adError = 'ad.error',
    adLoad = 'ad.load',
    adMilestone25 = 'ad.milestone.25',
    adMilestone50 = 'ad.milestone.50',
    adMilestone75 = 'ad.milestone.75',
    adPause = 'ad.pause',
    adPlay = 'ad.play',
    adPodComplete = 'ads-allpods-completed',
    adRequest = 'ad.request',
    adStart = 'ad.start',
    adunitLoaded = 'adunit.loaded',
    adunitViewable = 'adunit.viewable',
    adVolumeChange = 'ad.volumechange',
    authLogin = 'auth.login',
    authLoginRefresh = 'auth.loginRefresh',
    authLogout = 'auth.logout',
    authNetworkError = 'auth.networkError',
    authSessionExpired = 'auth.sessionExpired',
    bandViewable = 'band-viewable',
    breachScreenAvailable = 'breachscreen.available',
    breachScreenClick = 'breachscreen.click',
    breachScreenComplete = 'breachscreen.complete',
    cardClicked = 'card.clicked',
    collectionAvailable = 'collection.available',
    commentsAvailable = 'comments.available',
    commentPost = 'comment.post',
    commentReplied = 'comment.replied',
    commentEdit = 'comment.edit',
    commentReact = 'comment.react',
    commentReport = 'comment.report',
    commentLoadMore = 'comment.loadmore',
    commentIgnore = 'comment.ignore',
    commentError = 'comment.error',
    cookieConsent = 'cookie.consent',
    countdownInactivityFinished = 'countdowninactivity.finished',
    galleryLoadMore = 'gallery.load-more',
    galleryThumbnailClick = 'gallery.thumbnail.click',
    galleryThumbnailsHide = 'gallery.thumbnails.hide',
    galleryThumbnailsShow = 'gallery.thumbnails.show',
    linkClicked = 'link.clicked',
    navAvailable = 'nav.available',
    navHidden = 'nav.hidden',
    navClicked = 'nav.clicked',
    navSubNavClosed = 'nav.subNav.closed',
    navSubNavOpened = 'nav.subNav.opened',
    navFocused = 'nav.focused',
    newsletterSignupAvailable = 'newsletterSignup.available',
    newsletterSignupFail = 'newsletterSignup.fail',
    newsletterSignupInteract = 'newsletterSignup.interact',
    newsletterSignupSuccess = 'newsletterSignup.success',
    playerError = 'player.error',
    playbackError = 'player.playback.error',
    playToPauseError = 'player.playToPause.error',
    playerFullscreenEnter = 'player.fullscreen.enter',
    playerFullscreenExit = 'player.fullscreen.exit',
    playerReady = 'player.ready',
    playerUserActive = 'player.user.active',
    playerUserInactive = 'player.user.inactive',
    playlistScroll = 'playlist.scroll',
    playlistSelect = 'playlist.select',
    radioAvailable = 'radio.available',
    radioPlay = 'radio.play',
    radioStop = 'radio.stop',
    radio30 = 'radio.30',
    radioEnd = 'radio.end',
    shareButtonClick = 'shareButton.click',
    spaInitialised = 'spa.initialised',
    subscribeClick = 'subscribe.click',
    subscribeComplete = 'subscribe.complete',
    subscriberDropdown = 'subscriberDropdown.click',
    syntheticPageCompleted = 'syntheticPage.completed',
    syntheticPageInitiated = 'syntheticPage.initiated',
    syntheticPageError = 'syntheticPage.error',
    videoAvailable = 'video.available',
    videoEnd = 'video.end',
    videoMilestone25 = 'video.milestone.25',
    videoMilestone50 = 'video.milestone.50',
    videoMilestone75 = 'video.milestone.75',
    videoPlay = 'video.play',
    videoStart = 'video.start',
    videoPause = 'video.pause',
    videoStickyOff = 'video.sticky.off',
    videoStickyOn = 'video.sticky.on',
    videoVolumeChange = 'video.volumechange',
    viewabilityBandViewed = 'viewability.band.viewed',
    extendedAccess = 'extended-access.event',
    subscribeWithGoogle = 'subscribe-with-google.event',
    pollieRatingSubmit = 'pollie-rater.submit',
    inlineArticleBannerAvailable = 'inlineArticleBanner.available',
    loadMoreOlderPosts = 'live-blog-load-more-older-posts.submit',
    milestoneClick = 'live-blog-milestone.click',
    webStoryCarouselViewed = 'web-story-carousel.viewed',
    webStoryClicked = 'web-story.click',
    genericArticleBannerViewed = 'generic-article-banner.viewed',
    viewItemListEvent = 'view_item_list',
    selectItemEvent = 'select_item',
    addToCart = 'add_to_cart',
    removeFromCart = 'remove_from_cart',
    viewSubscriptionItemListEvent = 'view_subscription_item_list',
    articleAvailable = 'view_item',
    viewItemList = 'view_item_list',
    oddsLinkPressed = 'odds_link_pressed',
    viewCart = 'view_cart',
    subscriptionPromptCheckout = 'subscription_prompt_checkout',
    ga4VideoComplete = 'video_complete',
    ga4VideoProgress = 'video_progress',
    ga4VideoPlay = 'video_play',
    ga4VideoPause = 'video_pause',
    ga4AdPlay = 'ad_play',
    ga4AdComplete = 'ad_complete',
    search = 'search',
    searchFilter = 'search_filter',
    searchResults = 'search_results',
    savedArticlesResults = 'saved_articles_results',
    savedArticlesSave = 'saved_articles_save',
    savedArticlesUnsave = 'saved_articles_unsave',
    savedArticlesFilter = 'saved_articles_filter',
    moreButtonClick = 'more_button',
    scrollToTop = 'scroll.to_top',
    surveyBannerViewed = 'survey_banner.viewed',
    surveyBannerLinkClick = 'survey_banner.link_clicked',
    surveyBannerDismissed = 'survey_banner.dismissed',
    notificationCentreOpened = 'notification_centre.opened',
    notificationCentreClosed = 'notification_centre.closed',
}
