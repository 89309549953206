import * as React from 'react'

export interface XIconProps {
    xFillColor?: string
    backgroundFillColor?: string
}

export const XIcon: React.FC<XIconProps> = ({
    xFillColor = 'none',
    backgroundFillColor = 'white',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={xFillColor}
    >
        <title>X</title>
        <g clipPath="url(#clip0_11769_61715)">
            <path
                d="M14.2336 10.4272L22.9715 0.27002H20.9009L13.3138 9.08932L7.25395 0.27002H0.264648L9.42831 13.6064L0.264648 24.2577H2.33538L10.3476 14.9442L16.7472 24.2577H23.7365L14.2331 10.4272H14.2336ZM11.3975 13.7239L10.469 12.3959L3.08149 1.82883H6.26201L12.2238 10.3568L13.1523 11.6848L20.9019 22.7698H17.7214L11.3975 13.7244V13.7239Z"
                fill={backgroundFillColor}
            />
        </g>
        <defs>
            <clipPath id="clip0_11769_61715">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
