import styled from '@emotion/styled'
import { FigCaption } from '../../publication/FigCaption/FigCaption'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const stickyVideoClassName = 'is-sticky'
export const articleVideoClassName = 'article-video'

export interface StyledArticleVideoProps {
    isHeroVideo?: boolean
    mediaMode?: string
}

const pnMarginSml = metrics.perthnow.margins.sm
const pnMarginMd = metrics.perthnow.margins.md
const pnMarginLrg = metrics.perthnow.margins.lg

export const StyledArticleVideo = styled('figure')<StyledArticleVideoProps>(
    {
        flexGrow: 1,
        marginTop: 0,
        clear: 'both',
    },
    ({ theme, isHeroVideo }) => ({
        marginTop: themedValue(theme, {
            perthnow: isHeroVideo ? 0 : calcRem(pnMarginLrg),
            fallback: undefined,
        }),
        marginBottom: themedValue(theme, {
            perthnow: isHeroVideo ? 0 : calcRem(pnMarginLrg),
            thewest: isHeroVideo
                ? undefined
                : calcRem(metrics.thewest.margins.md),
            sevennews: isHeroVideo
                ? undefined
                : calcRem(metrics.sevennews.margins.lg),
            thenightly: isHeroVideo
                ? undefined
                : calcRem(metrics.thenightly.margins.lg),
            fallback: undefined,
        }),
        marginLeft: themedValue(theme, {
            perthnow: calcRem(-pnMarginMd),
            fallback: 'auto',
        }),
        marginRight: themedValue(theme, {
            perthnow: calcRem(-pnMarginMd),
            fallback: 'auto',
        }),
        width: themedValue(theme, {
            sevennews: '100%',
            fallback: undefined,
        }),
        pointerEvents: 'auto',
        [breakpoint('md')]: {
            marginLeft: themedValue(theme, { perthnow: 'auto', fallback: 0 }),
            marginRight: themedValue(theme, { perthnow: 'auto', fallback: 0 }),
        },
    }),
)

export const StyledArticleVideoContainer = styled(
    'div',
)<StyledArticleVideoProps>(({ theme, isHeroVideo, mediaMode }) => ({
    marginBottom: themedValue(theme, {
        thewest: calcRem(10),
        perthnow: isHeroVideo ? 0 : calcRem(pnMarginSml),
        sevennews: calcRem(metrics.sevennews.margins.sm),
        fallback: undefined,
    }),
    margin:
        mediaMode === 'edgeToEdge' || mediaMode === 'inlineVideo'
            ? calcRem(
                  -theme.cards.outerMargin,
                  -theme.cards.outerMargin,
                  theme.margins.sm,
                  -theme.cards.outerMargin,
              )
            : undefined,
    [breakpointMax('sm')]: {
        backgroundColor: themedValue(theme, {
            sevennews: colors.black,
            fallback: undefined,
        }),
        margin: themedValue(theme, {
            sevennews: `0 ${calcRem(-theme.margins.md)} 0 ${calcRem(
                -theme.margins.md,
            )}`,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            sevennews: theme.margins.md,
            fallback: undefined,
        }),
        paddingBottom: themedValue(theme, {
            sevennews: theme.margins.sm,
            fallback: undefined,
        }),
    },
}))

// Figcaption for Article Video's
export const StyledFigcaption = styled(FigCaption)<StyledArticleVideoProps>(
    ({ theme, isHeroVideo, sticky }) => ({
        backgroundColor: themedValue(theme, {
            perthnow: isHeroVideo ? colors.black : undefined,
            fallback: undefined,
        }),
        display: themedValue(theme, {
            sevennews: sticky ? 'none' : undefined,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            thewest: calcRem(0, 0, metrics.thewest.margins.sm),
            perthnow: isHeroVideo
                ? `${calcRem(pnMarginSml)}`
                : `${calcRem(0, pnMarginSml, pnMarginSml)}`,
            sevennews: calcRem(0, 0, metrics.sevennews.margins.sm),
            fallback: undefined,
        }),
        borderBottom: themedValue(theme, {
            thewest: `2px solid ${colors.thewest.greySeal}`,
            perthnow: isHeroVideo
                ? undefined
                : `2px solid ${colors.perthnow.greyPyrite}`,
            sevennews: isHeroVideo
                ? undefined
                : `1px solid ${colors.sevennews.alto}`, //prop forwarded from EntHeroCard to remove border when figcaptionRenderOptions==='hidden'
            fallback: undefined,
        }),

        // Fix dark fig caption on PN
        color: themedValue(theme, {
            perthnow: isHeroVideo ? colors.perthnow.greyMarble : undefined,
            fallback: undefined,
        }),
        svg: {
            path: {
                fill: themedValue(theme, {
                    perthnow: isHeroVideo
                        ? colors.perthnow.greyMarble
                        : undefined,
                    fallback: undefined,
                }) as string,
            },
        },

        [breakpoint('xs')]: {
            padding: themedValue(theme, {
                perthnow: isHeroVideo
                    ? `${calcRem(pnMarginSml)} ${calcRem(pnMarginMd)}`
                    : undefined,
                fallback: undefined,
            }),
        },
        [breakpointMax('sm')]: {
            marginTop: themedValue(theme, {
                sevennews: calcRem(theme.margins.md + theme.margins.sm),
                fallback: undefined,
            }),
        },
    }),
)
