import { layout } from '../../App.routing'

export const travelSegment = [
    layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'md',
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },

            cardLayout: [
                { type: 'hero', disableImageLazyLoad: false },
                'smallTitle-teaser-mdTeaserHidden-Image-EdgeToEdge',
            ],
            dataDefinitionArgs: {
                type: 'curation',
                name: 'travel',
                offset: 0,
                pageSize: 2,
            },
            sectionHeader: {
                heading: 'Travel',
                headingUrl: '/travel',
            },
        },
    }),
    layout.component({
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fixedRatios: ['16:9'],
            intermediateColumns: 2,
            finalColumns: 4,
            hideByline: false,
            hasBackground: true,
            cardOrientation: { type: 'Landscape-Portrait' },

            cardLayout: [
                ['teaserEdgeToEdge'],
                ['teaserEdgeToEdge'],
                ['teaserEdgeToEdge'],
                ['teaserEdgeToEdge'],
            ],
            dataDefinitionArgs: {
                type: 'curation',
                name: 'travel',
                offset: 2,
                pageSize: 4,
            },
        },
    }),
]
