import {
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import InlineTaboolaAmp from '../../../advertising/Taboola/InlineTaboola/InlineTaboola.amp'
import { InlinePositionedContent } from '../Publication.props'
import {
    InlineContentTypes,
    InlineTaboolaAmpComponent,
} from '../SharedPublication.routing'

export interface GetInlinePositionedTaboolaAmpProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}
export function isTaboolaAmpComponent(
    content: InlineContentTypes,
): content is InlineTaboolaAmpComponent {
    return content.kind === 'inline-taboola-amp'
}

/** This is a point where we can switch if we want Taboola to be rendered in all articles */
const renderInAllArticles = true

/** In case that render all articles is set to false we can specify here in which articles
    Taboola should be rendered. Two topics have been left here as an example */
const sevennewsTargetTopics = ['travel', 'entertainment']

export const getInlinePositionedTaboolaAmp = (
    props: GetInlinePositionedTaboolaAmpProps,
): InlinePositionedContent[] => {
    const { publication, inlinePublicationContent } = props

    if (!isArticleLikeType(publication)) {
        return []
    }

    /** Don't insert if there are less than 8 content blocks in the story */
    if (publication.content.length <= 8) {
        return []
    }

    const sevennewsShouldRender =
        sevennewsTargetTopics.some((topic) =>
            publication.primaryTopic?.id.includes(topic),
        ) || renderInAllArticles

    return inlinePublicationContent
        .filter(isTaboolaAmpComponent)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: (
                    <InlineTaboolaAmp
                        sevennewsShouldRender={sevennewsShouldRender}
                    />
                ),
            }
            return mappedContent
        })
}
