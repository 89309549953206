import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../__styling'

export const FiltersContainer = styled.div({
    margin: calcRem(8, 0, 16, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),

    [breakpoint('lg')]: {
        margin: calcRem(0),
    },
})

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'block',
    width: '100%',
}))
