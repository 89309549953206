import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import {
    ContainerWidth,
    metrics,
    ThemeMargins,
} from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

type Gutters = boolean | keyof ThemeMargins

export interface StyledZeusProps {
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: Gutters
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: Gutters
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent: boolean
    hideSidebar?: boolean
}

interface ThemedMetrics {
    margins: ThemeMargins
}

let sidebarWidth = 300

const getGutterSize = (
    themedMetrics: ThemedMetrics,
    gutter?: Gutters,
): string | number => {
    return gutter === false
        ? 0
        : calcRem(
              typeof gutter === 'string'
                  ? themedMetrics.margins[gutter]
                  : themedMetrics.margins.outerMargin,
          )
}

let gridGapSize = calcRem(21)

export const StyledZeus = styled('div')<StyledZeusProps>(
    ({ stackSidebarContent, hideSidebar }) => {
        //DTEC-426 - remove sidebar on app rendition of puzzles
        if (hideSidebar) {
            sidebarWidth = 0
            gridGapSize = '0'
        } else {
            sidebarWidth = 300
            gridGapSize = calcRem(21)
        }

        return {
            margin: '0 auto',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '100%',
            gridGap: stackSidebarContent ? 0 : gridGapSize,

            '@media print': {
                display: 'block',
            },

            [breakpoint(stackSidebarContent ? 'sm' : 'md')]: {
                // prettier-ignore
                gridTemplateColumns: `minmax(0,1fr) ${calcRem(sidebarWidth)}`,
                gridGap: gridGapSize,
            },
        }
    },
    ({ theme, stackSidebarContent }) =>
        // required to make tablet breakpoint 50/50 width between 768 and 800
        // whens stack sidebar content is true, this functionality is notrequired for IE
        // fallbacks as we wont be seeing alot of tablet sizes on IE
        theme.kind === 'sevennews' &&
        stackSidebarContent === true && {
            //prettier-ignore
            [`@media (min-width: ${calcEm(breakpoints['sm'],)}) and (max-width: ${calcEm(800)})`]: {
                    gridTemplateColumns: '1fr 1fr',
                },
        },
    ({
        theme,
        verticalSpacing,
        horizontalGutters,
        verticalGutters,
        containerWidth,
        hasBackgroundFill,
    }) => {
        const themedMetrics = metrics[theme.kind]

        return {
            marginBottom:
                verticalSpacing &&
                calcRem(themedMetrics.margins[verticalSpacing]),
            paddingLeft: getGutterSize(themedMetrics, verticalGutters),
            paddingRight: getGutterSize(themedMetrics, verticalGutters),
            paddingTop: getGutterSize(themedMetrics, verticalGutters),
            paddingBottom: getGutterSize(themedMetrics, verticalGutters),
            maxWidth: containerWidth,
            backgroundColor: themedValue(theme, {
                thewest: undefined,
                fallback: hasBackgroundFill ? colors.white : undefined,
            }),
            ...(theme.kind === 'perthnow' && {
                [breakpoint('md')]: {
                    paddingLeft: calcRem(metrics.perthnow.margins.sm),
                    paddingRight: calcRem(metrics.perthnow.margins.sm),
                    paddingTop: calcRem(metrics.perthnow.margins.sm),
                    paddingBottom: calcRem(metrics.perthnow.margins.sm),
                },
            }),
        }
    },
)
StyledZeus.displayName = 'StyledZeus'

export const StyledMain = styled('div')<{ hasBackgroundFill?: boolean }>(
    ({ theme, hasBackgroundFill }) => ({
        backgroundColor: themedValue(theme, {
            thewest: hasBackgroundFill ? colors.white : undefined,
            fallback: undefined,
        }),
    }),
)
StyledMain.displayName = 'StyledMain'

export const StyledSidebar = withClass('hide-print')(
    styled('div')<StyledZeusProps>(({ stackSidebarContent, theme }) => {
        return {
            display: stackSidebarContent ? 'block' : 'none',
            transform: 'translateZ(0)',

            [breakpoint(stackSidebarContent ? 'sm' : 'md')]: {
                display: 'block',
                boxSizing: 'content-box',
                position: 'relative',
                zIndex: zIndex.perthnow.sidebar,
            },
        }
    }),
)
StyledSidebar.displayName = 'StyledSidebar'
