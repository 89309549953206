import {
    isArticleLikeList,
    ListPublicationV4DTO,
    ListPublicationWithEntriesV4DTO,
    PublicationListingV4ResponseDTO,
} from '@west-australian-newspapers/publication-types'
import {
    PublicationCardItem,
    PublicationCardItemWithoutVideo,
} from '../../../client-data-types/card-types'
import { getExtraProps } from '../../../__api-mapping/v4/list-publication/getExtraProps'
import {
    imageDtoToImageCrop,
    mergeImageDtosToImageSource,
} from '../lib/to-image'

export interface MapListPublicationOptions {
    additionalMap?: ListPublicationAdditionalMap
    useOptimisticImage?: boolean
}

export function mapListPublication(
    document: ListPublicationWithEntriesV4DTO,
    options: MapListPublicationOptions = {},
): PublicationCardItem {
    const image =
        mergeImageDtosToImageSource(...document.cardImages) ||
        mergeImageDtosToImageSource(...document.mainImages) ||
        (isArticleLikeList(document) &&
        document.mainVideo &&
        document.mainVideo.kind === 'video' &&
        document.mainVideo.posterImage
            ? imageDtoToImageCrop(
                  document.mainVideo.posterImage,
                  document.mainVideo.videoDescription,
              )
            : undefined)

    // AAP content will not have headKicker.
    // Neither will content from The Nightly, usually
    const kicker = document.headKicker || document.topics.primary.title
    const source = document.source

    // redirect publications use the redirectUrls for their links
    const link =
        document.kind === 'redirect'
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              document.redirectUrl!
            : `/${document.topics.primary.id}/${document.slug}`

    const { video, isLive, teaser, requiredAccess } = getExtraProps(document)

    const promotionType = 'promotionType' in document && document.promotionType

    const allowCommenting =
        document.kind !== 'gallery' && document.allowCommenting

    const publicationItem: PublicationCardItemWithoutVideo = {
        id: document.id,
        cardType: 'publication',
        isSponsored: document.isSponsored,
        lastUpdated: document.lastUpdated,
        publicationDate: document.publicationDate,
        primaryTopic: document.topics.primary,
        secondaryTopics: document.topics.secondary,
        publicationKind: document.kind,
        byline: document.byline ? document.byline.text : '',
        imageCount:
            document.kind === 'gallery' ? document.imageCount : undefined,

        headline: document.heading,
        shortHeadline: document.homepageHead || document.heading,
        teaser: teaser || '',
        kicker,
        image,
        link,
        source,
        isLive,
        requiredAccess,
        _self: document._self,
        promotionType: promotionType || undefined,
        allowCommenting: allowCommenting,
        readTimeMinutes:
            document.kind === 'article' ? document.readTimeMinutes : null,
        canonicalUrl: document.canonicalUrl,
        headingWithSearchHighlight:
            document.kind === 'article'
                ? document.headingWithSearchHighlight
                : undefined,
        teaserWithSearchHighlight:
            document.kind === 'article'
                ? document.teaserWithSearchHighlight
                : undefined,
        ...(document.kind === 'event' &&
            document.entries && { entries: document.entries }),
        ...(document.userInteractionId && {
            userInteractionId: document.userInteractionId,
        }),
    }

    if (isArticleLikeList(document)) {
        publicationItem.profiles = document.profiles
        // layoutView should always be defined here as all publications of kind "article" in the DTO should have a layoutView.
        // We default to "default" so that we avoid type errors given it will be undefined for galleries and redirects
        publicationItem.layoutView = document.layoutView || 'default'
        publicationItem.classification = document.classification
    }

    if (video) {
        if (options.additionalMap) {
            return options.additionalMap({ ...publicationItem, video })
        }

        return {
            ...publicationItem,
            video,
        }
    }

    if (options.additionalMap) {
        return options.additionalMap(publicationItem)
    }

    return publicationItem
}

export function mapListPublications(
    listResponse: PublicationListingV4ResponseDTO,
    options: MapListPublicationOptions = {},
): PublicationCardItem[] {
    return listResponse.documents.map((document) =>
        mapListPublication(document, options),
    )
}

export type ListPublicationAdditionalMap = (
    item: PublicationCardItem,
) => PublicationCardItem
