import { useEffect, useState } from 'react'

export interface PlayerDataInterface {
    name: string
    dateOfBirth: string
    height: string
    profilePosition: string
    jumperNumber: string
    weight: string
    playerId: string
}

export const useFetchPlayerData = (
    season: string,
    competition: string,
    homeId: string,
    awayId: string,
) => {
    const [homePlayersData, setHomePlayersData] = useState<
        PlayerDataInterface[]
    >([])
    const [awayPlayersData, setAwayPlayersData] = useState<
        PlayerDataInterface[]
    >([])
    useEffect(() => {
        fetch(`/afl/players?season=${season}&competition=${competition}`)
            .then((res) => res.json())
            .then((rawData) => {
                const [homePlayers, awayPlayers] = filterPlayersData(
                    rawData,
                    homeId,
                    awayId,
                )

                setHomePlayersData(homePlayers)
                setAwayPlayersData(awayPlayers)
            })
    }, [awayId, homeId, competition, season])

    return [homePlayersData, awayPlayersData]
}

const filterPlayersData = (data: any, homeId: string, awayId: string) => {
    const allPlayers = data.report?.aflCompetitionPlayers?.player
    if (!allPlayers) {
        return [null, null]
    }
    const homePlayers = allPlayers
        .filter((player: any) => player['@attributes'].squadId === homeId)
        .map((player: any) => formatPlayerData(player['@attributes']))
    const awayPlayers = allPlayers
        .filter((player: any) => player['@attributes'].squadId === awayId)
        .map((player: any) => formatPlayerData(player['@attributes']))
    return [homePlayers, awayPlayers]
}

const formatPlayerData = (player: any): PlayerDataInterface => {
    return {
        name: `${player.firstname} ${player.surname}`,
        dateOfBirth: player.dateOfBirth,
        height: player.height,
        profilePosition: player.profilePosition,
        jumperNumber: player.jumperNumber,
        weight: player.weight,
        playerId: player.playerId,
    }
}
