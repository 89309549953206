/**
 * Returns the ID of the post from the # part of the URL.
 */
export const getPostIdFromUrl = () => {
    if (typeof window !== 'undefined' && window.location.hash) {
        return window.location.hash.split('?')[0].replace('#', '')
    }
}

/**
 * Wipes the # part of the URL.
 */
export const clearDeepLink = () =>
    history.replaceState('', document.title, document.location.pathname)
