import styled from '@emotion/styled'
import { StyledLiveBubble } from '../../typography/EditorialLabel/EditorialLabel.styled'
import { breakpoint, calcRem, colors } from '../../__styling'

export const StyledContainer = styled('div')({
    marginBottom: '16px',
    position: 'relative',
})

export const StyledJumpButtonContainer = styled('div')({
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: -40,
    left: 0,
    right: 0,
    pointerEvents: 'none',
})

export const StyledButtonContainer = styled('div')(({ theme }) => [
    {
        height: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    // Styled for 7N
    theme.kind === 'sevennews' && {
        justifyContent: 'center',
    },
])

interface StyledReportingLiveContainerProps {
    isActive: boolean
}

export const StyledReportingLiveContainer = styled(
    'div',
)<StyledReportingLiveContainerProps>(({ theme, isActive }) => [
    {
        background: theme.colors.brand,
        height: isActive ? calcRem(48) : calcRem(4),
        padding: isActive ? calcRem(16) : 0,
        overflow: 'auto',
        marginLeft: theme.margins.md * -1,
        marginRight: theme.margins.md * -1,
        marginTop: 'unset',
        display: 'flex',
        alignItems: 'center',

        [breakpoint('md')]: {
            marginLeft: theme.margins.md * -1,
            marginRight: theme.margins.md * -1,
        },
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        height: isActive ? calcRem(30) : calcRem(4),
        padding: isActive ? calcRem(8) : 0,
        marginTop: calcRem(15),
        display: isActive ? 'flex' : 'none',
        overflow: 'hidden',

        [breakpoint('md')]: {
            marginLeft: (theme.margins.md - 8) * -1,
            marginRight: (theme.margins.md - 8) * -1,
        },
    },
])

export const StyledReportingLiveText = styled('span')(({ theme }) => [
    {
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(15),
        fontWeight: 700,
        color: theme.colors.palette.white,
        lineHeight: calcRem(16),
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        fontWeight: 400,

        '& > span': {
            fontWeight: 600,
        },
    },
])

export const StyledReportingLiveDivider = styled('hr')(({ theme }) => [
    {
        overflow: 'auto',
        border: 'none',
        margin: 0,
        height: 0,
        background: colors.thewest.greyQuokka,

        [breakpoint('md')]: {
            height: calcRem(1),
            marginLeft: theme.margins.md * -1,
            marginRight: theme.margins.md * -1,
            marginTop: calcRem(8),
        },
    },
    theme.kind === 'sevennews' && {
        background: 'inherit',
    },
])

export const StyledReportingLiveBubble = styled(StyledLiveBubble)(
    ({ theme }) => [
        {
            backgroundColor: theme.colors.palette.white,
            marginLeft: 0,
            marginRight: calcRem(8),
            width: '0.6em',
            height: '0.6em',
        },
        // Styling for 7N
        theme.kind === 'sevennews' && {
            marginLeft: calcRem(22),
            marginRight: calcRem(6.4),
        },
    ],
)
