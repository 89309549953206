import React from 'react'
import { IframeProps } from '../../content/Iframe/Iframe'
import { StyledIframe } from '../../content/Iframe/Iframe.styled'
import { StyledContainer } from './FlourishIframe.styled'

export interface FlourishIframeProps extends IframeProps {
    imgHeight: number
    imgWidth?: number
}

export const FlourishIframe: React.FC<FlourishIframeProps> = (props) => {
    const {
        imgHeight,
        imgWidth,
        link,
        height,
        marginBottom,
        innerRef,
        width,
        frameBorder,
        allow,
        allowFullscreen,
        dataHjAllowIframe,
        id,
        className,
        ariaLabel,
    } = props

    return (
        <StyledContainer>
            <StyledIframe
                src={link}
                height={height}
                width={width}
                marginBottom={marginBottom}
                ref={innerRef}
                sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
                frameBorder={frameBorder}
                allow={allow}
                allowFullScreen={allowFullscreen}
                data-hj-allow-iframe={dataHjAllowIframe}
                id={id}
                className={className}
                aria-label={ariaLabel}
            >
                Sorry, we can't show this content as your browser does not
                support iframes.
            </StyledIframe>
            <img
                src="https://public.flourish.studio/resources/made_with_flourish.svg"
                alt="Made with Flourish"
                width={imgWidth}
                height={imgHeight}
            />
        </StyledContainer>
    )
}
FlourishIframe.displayName = 'FlourishIframe'
