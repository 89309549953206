export const TOPIC_FILTER_PARAM = 'topics'

// This list loosely reflects The Nightly quickLinks found in appsthenightlysrcappnavigation.ts
// It currently excludes "The Front Dore" link
export const articleTopics: string[] = [
    'All',
    'Politics',
    'Australia',
    'Business',
    'Opinion',
    'Culture',
    'Society',
    'Lifestyle',
    'Sport',
    'World',
]

export const defaultOption = articleTopics[0]
