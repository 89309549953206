import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getLifestyleRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Food',
                    headingUrl: '/lifestyle/food',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/food'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },

                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Drink',
                    headingUrl: '/lifestyle/drink',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/drink'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Health & Wellbeing',
                    headingUrl: '/lifestyle/health-wellbeing',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/health-wellbeing'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Parenting',
                    headingUrl: '/lifestyle/parenting',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/parenting'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'STM',
                    headingUrl: '/lifestyle/stm',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/stm'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Home',
                    headingUrl: '/lifestyle/home-decorating',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/home-decorating'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Garden',
                    headingUrl: '/lifestyle/garden',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/garden'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Retirement',
                    headingUrl: '/lifestyle/retirement',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/retirement'],
                    includeSubTopics: false,

                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Fashion',
                    headingUrl: '/lifestyle/fashion',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/fashion'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Motoring',
                    headingUrl: '/lifestyle/motoring',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/motoring'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'New Homes',
                    headingUrl: '/lifestyle/new-homes',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/new-homes'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Real Estate',
                    headingUrl: '/lifestyle/real-estate',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/real-estate'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
