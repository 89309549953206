import {
    ConsentLevel,
    Product,
    scriptExists,
    useConsentGiven,
    useFeature,
} from '@news-mono/web-common'
import React, { useCallback, useEffect } from 'react'
import { eventQueue, getIndexExchangeScriptUri, Section } from '..'

interface IndexExchangeProps {
    section: Section
    product: Product
}

export const IndexExchange: React.FC<IndexExchangeProps> = ({
    section,
    product,
}) => {
    const isToggleEnabled = useFeature('ix-header-tag-wrapper')
    const consentRequiredLevel = ConsentLevel.Advertising
    const consentGiven = useConsentGiven({ consentRequiredLevel })
    //For some reason there are extra spaces appearing.
    const scriptSrc = getIndexExchangeScriptUri(product, section)
    const isLocalEnv =
        typeof window !== 'undefined' &&
        window.location.href.includes('localhost')
    const identifier = isLocalEnv ? 'http' : 'https'

    const addScript = useCallback(() => {
        //IX adds http: to the script source.
        if (
            scriptExists(`${identifier}:${scriptSrc}`) ||
            !consentGiven ||
            !isToggleEnabled
        ) {
            return
        }

        const script = document.createElement('script')
        script.src = scriptSrc
        script.type = 'text/javascript'
        document.head.appendChild(script)
    }, [isToggleEnabled, consentGiven, identifier, scriptSrc])

    useEffect(() => {
        // Given that this runs both on the server(in the initial skeleton SSR and on the client during the CSR)
        // we need to check if the window object is defined first.
        // We also want the script to immediately load if liveRamp is already loaded.
        // SevenNews doesn't use liveRamp, so just initiate the Index Exchange script normally.
        if (
            typeof window !== 'undefined' &&
            (window.liveRampError ||
                window.ats ||
                product === Product.SevenNews)
        ) {
            addScript()
        } else {
            eventQueue.addEvent({
                eventName: 'load-index-exchange-script',
                callbackFunctions: [addScript],
            })
        }
    }, [isToggleEnabled, consentGiven, addScript, product])

    return <></>
}
