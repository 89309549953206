import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { CardItem } from '../client-data-types/card-types'
import { PublicationType } from '../client-data-types/publication'
import { Topic } from '../client-data-types/topic'
import {
    isAnchorLink,
    isExternalLink,
    isNonHttpLink,
} from '../helpers/link-utils'

export interface PublicationRouteHint {
    routeKind: 'publication'
    publicationKind: PublicationType['kind']
    heading: string
    homepageHead: string | undefined
    homepageTeaser: string | undefined
    primaryTopic: Topic
    secondaryTopics: Topic[]
    imageCount?: number
}

export interface TopicRouteHint {
    routeKind: 'topic'
    topic: Topic
    publicationKind: PublicationType['kind']
}

export type RouteHints = PublicationRouteHint | TopicRouteHint
export interface LocationDescriptorWithRouteHint {
    pathname: string
    state: {
        routeHint: RouteHints
    }
}

export function toTopicRouteHint(
    topic: Topic,
): LocationDescriptorWithRouteHint {
    const routeHint: TopicRouteHint = {
        routeKind: 'topic',
        topic: topic,
        publicationKind: 'article',
    }

    return {
        pathname: `/${topic.id}`,
        state: {
            routeHint,
        },
    }
}

export function toLinkWithHint(
    props: MaybeLoaded<CardItem>,
): LocationDescriptorWithRouteHint | string {
    if (!props.loaded) {
        return '/'
    }

    if (props.result.cardType === 'marketing-redirect-tile') {
        return props.result.url
    }

    const { link } = props.result
    const isExternal =
        isExternalLink(link) || isAnchorLink(link) || isNonHttpLink(link)
    if (props.result.publicationKind === 'redirect' && isExternal) {
        return link
    }

    const routeHint: PublicationRouteHint = {
        routeKind: 'publication',
        heading: props.result.headline,
        homepageHead: props.result.shortHeadline,
        homepageTeaser: props.result.teaser,
        primaryTopic: props.result.primaryTopic,
        secondaryTopics: props.result.secondaryTopics,
        publicationKind: props.result.publicationKind,
        imageCount: props.result.imageCount,
    }
    return {
        pathname: props.result.link,
        state: {
            routeHint,
        },
    }
}
