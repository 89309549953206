import { DataDefinition } from 'json-react-layouts-data-loader'
import { ComponentServices } from '../../../routing'
import {
    getLatestIssuesFromMultiplePublications,
    getLatestIssuesFromPublication,
    Issue,
} from './helpers'

export type ePaperLatestEditionDataTypes = {
    numberOfIssues: number
    publication: EpaperPublicationType
}

export type ePaperLatestEditionDataLoaded = {
    latestIssues: Issue[]
}

export type ePaperLatestEditionDataDefinition = DataDefinition<
    ePaperLatestEditionDataTypes,
    ePaperLatestEditionDataLoaded,
    ComponentServices
>

export const ePaperLatestEditionDataDefinitionLoader: ePaperLatestEditionDataDefinition =
    {
        loadData: async (dataDefinitionArgs, services) => {
            const data = await getLatestIssuesFromPublication({
                logger: services.log,
                caller: services.config.apiCallerHeader,
                numberOfIssues: dataDefinitionArgs.numberOfIssues,
                publication: dataDefinitionArgs.publication,
            })

            return data
        },
    }

export const allPublications = [
    'albany-advertiser',
    'the-extra',
    'great-southern-herald',
    'augusta-margaret-river-times',
    'busselton-dunsborough-times',
    'broome-advertiser',
    'the-kimberley-echo',
    'geraldton-guardian',
    'midwest-times',
    'bunbury-herald',
    'harvey-waroona-reporter',
    'manjimup-bridgetown-times',
    'south-western-times',
    'kalgoorlie-miner',
    'narrogin-observer',
    'north-west-telegraph',
    'pilbara-news',
    'sound-telegraph',
    'countryman',
    'default',
    'perthnow',
    'joondalup',
    'western-suburbs',
    'canning',
    'mandurah-times',
    'wanneroo',
    'stirling',
    'central',
    'southern',
    'cockburn',
    'fremantle',
    'melville',
    'sunday-times',
    'the-west-australian',
    'stm',
    'play',
    'the-extra',
    'the-nightly',
] as const

type PublicationTuple = typeof allPublications
export type EpaperPublicationType = PublicationTuple[number]

export type ePaperReadersAlsoLoveDataTypes = {
    publicationsToFetch: EpaperPublicationType[]
}

export type ePaperReadersAlsoLoveDataLoaded = {
    issues: Issue[]
}

export type ePaperReadersAlsoLoveDataDefinition = DataDefinition<
    ePaperReadersAlsoLoveDataTypes,
    ePaperReadersAlsoLoveDataLoaded,
    ComponentServices
>

export const ePaperReadersAlsoLoveDataDefinitionLoader: ePaperReadersAlsoLoveDataDefinition =
    {
        loadData: async (dataDefinitionArgs, services, content) => {
            const data = await getLatestIssuesFromMultiplePublications({
                logger: services.log,
                caller: services.config.apiCallerHeader,
                publications: dataDefinitionArgs.publicationsToFetch,
            })

            return {
                issues: data,
            }
        },
    }
