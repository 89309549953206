import styled from '@emotion/styled'
import React, { Ref, forwardRef } from 'react'
import { useProduct } from '../../__product/useProduct'

type ContainerProps = {
    paddingBottom?: number
    paddingTop?: number
    marginBottom?: number
    marginTop?: number
}

const StyledContainer = styled('div')<ContainerProps>(
    {
        display: 'flex',
        justifyContent: 'center',
    },
    (props) => ({
        paddingBottom: props.paddingBottom,
        paddingTop: props.paddingTop,
        marginBottom: props.marginBottom,
        marginTop: props.marginTop,
    }),
)
interface StyledButtonContainerProps
    extends React.HTMLAttributes<HTMLDivElement> {}
export const StyledButtonContainer = forwardRef(
    (props: StyledButtonContainerProps, ref: Ref<HTMLDivElement>) => {
        const product = useProduct()
        return product === 'thewest' ? (
            <StyledContainer
                paddingBottom={10}
                paddingTop={10}
                marginBottom={18}
            >
                {props.children}
            </StyledContainer>
        ) : product === 'sevennews' ? (
            <StyledContainer paddingBottom={0} paddingTop={0} ref={ref}>
                {props.children}
            </StyledContainer>
        ) : (
            <StyledContainer paddingBottom={20} paddingTop={20}>
                {props.children}
            </StyledContainer>
        )
    },
)
StyledButtonContainer.displayName = 'StyledButtonContainer'
