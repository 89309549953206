import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import { Oscar } from '../../collections/Oscar/Oscar'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { Section } from '../../__styling/settings/sections'

export interface OscarRouteProps
    extends CollectionRouteProps,
        ResponsiveContainer {
    collectionSection: Section
    disableImageLazyLoad?: boolean
    heroCardType?: 'portrait' | 'landscape-stacked'
    hasSeparators?: boolean
    truncatedListCards?: boolean
    relatedLinks?: {
        title: string
        links: Array<{ text: string; link: string }>
    }
}

export const OscarRegistration = createRegisterableComponentWithData(
    'oscar',
    ContentDataDefinitionLoader,
    (props: OscarRouteProps, data, services) => {
        return (
            <Oscar
                items={
                    data.loaded
                        ? { loaded: true, result: data.result.publications }
                        : { loaded: false }
                }
                sectionHeader={props.sectionHeader}
                verticalSpacing={props.verticalSpacing}
                disableImageLazyLoad={props.disableImageLazyLoad}
                hasSeparators={props.hasSeparators}
                onEvent={services.onEvent}
                expectedCards={calculateExpectedCardCountFromContentData(
                    data.dataDefinitionArgs,
                )}
                heroCardType={props.heroCardType || 'landscape-stacked'}
                truncateListCards={props.truncatedListCards}
                relatedLinks={props.relatedLinks}
                containerWidthRatios={props.containerWidthRatios}
            />
        )
    },
)
