import styled from '@emotion/styled'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface StyledIframeProps {
    height: string | number
    marginBottom: keyof ThemeMargins | undefined
}

export const StyledIframe = styled('iframe')<StyledIframeProps>(
    {
        width: '100%',
        border: 0,
        display: 'block',
    },
    ({ theme, marginBottom, height }) => ({
        marginBottom: marginBottom && calcRem(theme.margins[marginBottom]),
        height: height,
    }),
)
