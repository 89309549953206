import {
    ArticleLikePublication,
    PublicationCardItem,
} from '@news-mono/web-common'

export const enum FundingType {
    Sponsored = 'sponsored',
    Supported = 'supported',
    Advertisement = 'advertisement',
}

function isCard(
    item: PublicationCardItem | ArticleLikePublication,
): item is PublicationCardItem {
    // eslint-disable-next-line no-prototype-builtins
    return item.hasOwnProperty('publicationKind')
}

export function getFundingType(
    item: PublicationCardItem | ArticleLikePublication,
    storyClassificationEnabled: boolean,
): FundingType | undefined {
    if (
        !storyClassificationEnabled ||
        (isCard(item) && item.publicationKind === 'redirect')
    ) {
        return item.isSponsored ? FundingType.Sponsored : undefined
    }

    // If no classification is set, fallback to the isSponsored flag.
    if (!item.classification) {
        return item.isSponsored ? FundingType.Sponsored : undefined
    }

    /**
     * If another kind of classification is set, don't fallback to the isSponsored flag,
     * a publication cannot be funded and breaking for instance.
     */
    if (item.classification.kind !== 'sales') {
        return undefined
    }

    switch (item.classification.label) {
        case 'sponsored':
            return FundingType.Sponsored
        case 'supported':
            return FundingType.Supported
        case 'advertisement':
            return FundingType.Advertisement
        default:
            return undefined
    }
}

export function getFundingLabel(type: FundingType) {
    switch (type) {
        case FundingType.Supported:
            return 'Supported'
        case FundingType.Advertisement:
            return 'Advertiser Content'
        case FundingType.Sponsored:
            return 'Sponsored'
        default:
            assertNever(type)
    }
}

function assertNever(type: never): never {
    throw new Error(`Received unexpected funding type (${type})`)
}
