import { cx } from '@emotion/css'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import React from 'react'
import {
    DigitalEditionIconButton,
    EmailIconButton,
    FacebookIconButton,
    InstagramIconButton,
    LinkedInIconButton,
    RSSIconButton,
    TikTokIconButton,
    TwitterIconButton,
    YoutubeIconButton,
} from '../../buttons/IconButton/CommonIconButtons'
import { colors } from '../../__styling/settings/colors'
import { ThemedRender } from '../../__styling/themed-render'
import { StyledSocialLinks } from './SocialLinks.styled'

export interface SocialLinkValues {
    facebook?: string
    twitter?: string
    instagram?: string
    youtube?: string
    tiktok?: string
    rss?: string
    email?: string
    linkedin?: string
    digitalEdition?: string
    onEvent?: (event: AllEvents) => void
}

export interface SocialLinksProps extends SocialLinkValues {
    /* Used by sevennews */
    sectionTitle?: string
    className?: string
}

export const SocialLinks: React.FC<SocialLinksProps> = ({
    facebook,
    twitter,
    instagram,
    youtube,
    tiktok,
    rss,
    digitalEdition,
    email,
    sectionTitle,
    linkedin,
    className,
    onEvent,
}) => {
    const onDigitalEditionClick = () => {
        onEvent &&
            digitalEdition &&
            onEvent({
                type: DataLayerEventName.linkClicked,
                originator: 'SocialLinks',
                payload: {
                    text: 'Perth Now Digital Edition',
                    linkType: 'Digital-Edition-Driver-FollowUs',
                    opensInNewWindow: false,
                    url: digitalEdition,
                },
            })
    }

    return (
        <ThemedRender
            thewest={() => (
                <StyledSocialLinks className={className}>
                    {facebook && <FacebookIconButton link={facebook} />}
                    {twitter && <TwitterIconButton link={twitter} />}
                    {instagram && <InstagramIconButton link={instagram} />}
                    {youtube && <YoutubeIconButton link={youtube} />}
                    {rss && <RSSIconButton link={rss} />}
                    {email && <EmailIconButton link={email} />}
                </StyledSocialLinks>
            )}
            sevennews={() => {
                return (
                    <StyledSocialLinks
                        className={cx(['hide-print', className])}
                    >
                        {facebook && (
                            <FacebookIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                link={facebook}
                                title={getIconTitle('facebook', sectionTitle)}
                                offset={{ top: 50, left: 48 }}
                                is7NewsIcon={true}
                            />
                        )}
                        {instagram && (
                            <InstagramIconButton
                                link={instagram}
                                title={getIconTitle('instagram', sectionTitle)}
                                iconScale={1}
                                is7NewsIcon={true}
                            />
                        )}
                        {email && (
                            <EmailIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                link={email}
                                title={getIconTitle('email', sectionTitle)}
                                is7NewsIcon={true}
                            />
                        )}
                        {youtube && (
                            <YoutubeIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                link={youtube}
                                title={getIconTitle('youtube', sectionTitle)}
                                is7NewsIcon={true}
                            />
                        )}
                        {tiktok && (
                            <TikTokIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                link={tiktok}
                                title={getIconTitle('tiktok', sectionTitle)}
                            />
                        )}
                        {rss && (
                            <RSSIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                iconScale={0.5}
                                link={rss}
                                title={getIconTitle('rss', sectionTitle)}
                            />
                        )}
                        {twitter && (
                            <TwitterIconButton
                                baseColor={colors.sevennews.bauhausBlack}
                                link={twitter}
                                title={getIconTitle('twitter', sectionTitle)}
                                is7NewsIcon={true}
                            />
                        )}
                    </StyledSocialLinks>
                )
            }}
            perthnow={() => (
                <StyledSocialLinks className={className}>
                    {facebook && <FacebookIconButton link={facebook} />}
                    {twitter && <TwitterIconButton link={twitter} />}
                    {instagram && <InstagramIconButton link={instagram} />}
                    {youtube && <YoutubeIconButton link={youtube} />}
                    {linkedin && <LinkedInIconButton link={linkedin} />}
                    {rss && <RSSIconButton link={rss} />}
                    {digitalEdition && (
                        <DigitalEditionIconButton
                            link={digitalEdition}
                            onClick={onDigitalEditionClick}
                            forceInternalSSR
                        />
                    )}
                    {email && <EmailIconButton link={email} />}
                </StyledSocialLinks>
            )}
        />
    )
}

export type IconType =
    | 'youtube'
    | 'instagram'
    | 'facebook'
    | 'twitter'
    | 'rss'
    | 'email'
    | 'digital-edition'
    | 'tiktok'

export const getIconTitle = (iconType: IconType, title?: string) => {
    if (title) {
        switch (iconType) {
            case 'youtube':
                return `Watch ${title} on YouTube`
            case 'instagram':
                return `Follow ${title} on Instagram`
            case 'facebook':
                return `Follow ${title} on Facebook`
            case 'twitter':
                return `Follow ${title} on Twitter`
            case 'tiktok':
                return `Follow ${title} on TikTok`
            case 'rss':
                return `${title} RSS Feed`
            case 'email':
                return `Email ${title}`
            case 'digital-edition':
                return `Get ${title} Digital Edition`
            default:
                return undefined
        }
    }
    return undefined
}
