import { useTheme } from '@emotion/react'
import { TheWestSection } from '@news-mono/common'
import { Product, PageError } from '@news-mono/web-common'
import React from 'react'
import { Box } from '../compositions/Box/Box'
import {
    Attribution,
    Chevron,
    Icon,
    StyledFooter,
    StyledLine,
    StyledParagraph,
    StyledPullquote,
} from '../publication/Pullquote/Pullquote.styled'
import {
    SevenNewsSection,
    sevenNewsSectionDefinitions,
} from '../__styling/settings/sections/sevennews'
import { theWestSectionDefinitions } from '../__styling/settings/sections/thewest'
import { themedValue } from '../__styling/themed-value'
import { ErrorTypeText } from './ErrorPage.styled'

interface ErrorPageProps {
    text?: (product: Product) => JSX.Element | string
    error?: PageError
    attribution?: boolean
}

const StyledPageNotFound = StyledPullquote.withComponent('div')
StyledPageNotFound.displayName = 'StyledPageNotFound'

const StyledAttribution = Attribution.withComponent('div')
StyledAttribution.displayName = 'StyledAttribution'

export function getAttribution(product: Product, section: string) {
    switch (product) {
        case Product.TheWest: {
            const westSection = section as TheWestSection
            return westSection
                ? theWestSectionDefinitions[westSection].title
                : theWestSectionDefinitions.default.title
        }
        case Product.PerthNow:
            return 'The PerthNow Team'
        case Product.SevenNews: {
            const sevenSection = section as SevenNewsSection
            return sevenSection
                ? sevenNewsSectionDefinitions[sevenSection].title
                : sevenNewsSectionDefinitions.default.title
        }
        default:
            return undefined
    }
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
    text,
    error,
    attribution,
}) => {
    const theme = useTheme()
    const symbol = themedValue(theme, {
        perthnow: <Icon />,
        sevennews: <Chevron />,
        thewest: <StyledLine />,
        fallback: undefined,
    })

    const attributionText = attribution
        ? getAttribution(theme.kind, theme.section)
        : undefined

    return (
        <Box verticalSpacing="xxl" hasTopSpacing={true}>
            <StyledPageNotFound attribution={attributionText}>
                {symbol}
                <StyledParagraph>{text && text(theme.kind)}</StyledParagraph>

                {attribution && (
                    <StyledFooter>
                        <StyledAttribution className="attribution">
                            {attributionText}
                        </StyledAttribution>
                    </StyledFooter>
                )}
                {error && (
                    <ErrorTypeText>
                        Error: {error.errorType} <br />
                        {error.errorId}
                    </ErrorTypeText>
                )}
            </StyledPageNotFound>
        </Box>
    )
}
