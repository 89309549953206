import { MatchCenterEvents } from '@news-mono/web-common'
import React, { useContext } from 'react'
import { Skeleton } from '../../content'
import { ScoreBoard } from '../../ScoreBoard/ScoreBoard'
import { OptaAflApiContext } from '../OptaAflApiProvider'
import { AflScoreBoardProps } from './AflScoreBoard.routing'
import {
    StyledHiddenMainHeader,
    StyledHiddenSecondaryHeader,
    StyledScoreboardSkeletonWrapper,
} from './AflScoreBoard.styled'
import { AflFixtureData, TabTouchOdds } from './types'

const getAflFixtureDataFromFallbackApi = (
    fallbackData: any,
    tabTouchOdds?: TabTouchOdds,
): AflFixtureData => {
    return {
        date: fallbackData?.utcStartTime || fallbackData?.startTimeUTC,
        match_status: fallbackData?.matchStatus,
        venue: {
            name: fallbackData?.venueName,
            place: '',
        },
        round: fallbackData?.roundNumber,
        period: '',
        periodCompleted: '',
        periodSeconds: '',
        homeTeam: {
            id: fallbackData?.homeSquadId,
            name: fallbackData?.homeSquadName,
            score: {
                score: '-',
                goals: '-',
                behinds: '-',
            },
        },
        awayTeam: {
            id: fallbackData?.awaySquadId,
            name: fallbackData?.awaySquadName,
            score: {
                score: '-',
                goals: '-',
                behinds: '-',
            },
        },
        rawApiData: undefined,
        tabTouchOdds,
    }
}

interface Props extends AflScoreBoardProps {
    onEvent: (event: MatchCenterEvents) => void
    hideOddsCookieOverride?: boolean
}

export const AflScoreBoard = ({
    gameId,
    apiData,
    verticalGutters,
    verticalSpacing,
    onEvent,
    hideOddsCookieOverride,
    isAflw,
}: Props) => {
    const { data, error, loading } = useContext(OptaAflApiContext)

    if (loading) {
        return (
            <StyledScoreboardSkeletonWrapper>
                <Skeleton count={1} dark={false} />
            </StyledScoreboardSkeletonWrapper>
        )
    }
    if (!data) {
        if (!apiData) {
            return null
        }
        const fallbackData = getAflFixtureDataFromFallbackApi(
            apiData?.report?.aflMatchStats?.matchInfo['@attributes'],
            apiData?.tabTouchOdds,
        )

        const hiddenTitle = `${fallbackData.homeTeam.name} vs ${fallbackData.awayTeam.name}`

        if (
            fallbackData.match_status === 'scheduled' ||
            fallbackData.match_status === 'prematch'
        ) {
            return (
                <>
                    <StyledHiddenMainHeader>
                        {hiddenTitle}
                    </StyledHiddenMainHeader>
                    <ScoreBoard
                        response={{
                            type: 'afl',
                            data: fallbackData,
                            isAflw,
                        }}
                        verticalGutters={verticalGutters}
                        verticalSpacing={verticalSpacing}
                        onEvent={onEvent}
                        hideOddsCookieOverride={hideOddsCookieOverride}
                        gameId={gameId}
                    />
                </>
            )
        } else {
            return null
        }
    }

    if (!data.tabTouchOdds) {
        data.tabTouchOdds = apiData?.tabTouchOdds
    }

    const hiddenTitle = `${data.homeTeam.name} vs ${data.awayTeam.name} Round ${data.round}`
    const hiddenTitleSecondary = `AFL Premiership`

    return (
        <div>
            <StyledHiddenMainHeader>{hiddenTitle}</StyledHiddenMainHeader>
            <StyledHiddenSecondaryHeader>
                {hiddenTitleSecondary}
            </StyledHiddenSecondaryHeader>
            <ScoreBoard
                response={{ type: 'afl', data: data, isAflw }}
                verticalGutters={verticalGutters}
                verticalSpacing={verticalSpacing}
                onEvent={onEvent}
                hideOddsCookieOverride={hideOddsCookieOverride}
                gameId={gameId}
            />
        </div>
    )
}
