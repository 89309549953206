import { TextBlock } from '../client-data-types/blocks'

export const createDiagExample = (
    url: string,
    description: string,
): TextBlock => {
    const text = `${url}: ${description}`
    return {
        text,
        kind: 'text',
        intentions: [
            {
                index: text.indexOf(url),
                length: url.length,
                kind: 'link',
                href: url,
            },
        ],
    }
}
