import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'

export const publicationAdPageType: Record<
    ArticleLikeV4DTO['kind'],
    'article' | 'liveblog' | 'gallery'
> = {
    article: 'article',
    event: 'liveblog',
    redirect: 'article',
    gallery: 'gallery',
}

export const publicationAdKind: Record<
    ArticleLikeV4DTO['kind'],
    'article' | 'liveblog' | 'gallery'
> = {
    article: 'article',
    event: 'liveblog',
    redirect: 'article',
    gallery: 'gallery',
}
