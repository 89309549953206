import React from 'react'
import {
    PlayerDataInterface,
    useFetchPlayerData,
} from '../AflSquadsWidget/useFetchPlayerData'
import {
    teamBrandData,
    TeamBrandData,
} from '../MatchTimelineWidget/helpers/teamBrandData'
import {
    StyledAwayPlayersContainer,
    StyledHomePlayersContainer,
    StyledInIndicator,
    StyledOutIndicator,
    StyledWidgetChangesInfo,
    StyledWidgetContainer,
    StyledWidgetInnerContainer,
    StyledWidgetInRowContainer,
    StyledWidgetLegendContainer,
    StyledWidgetRowContainer,
    StyledWidgetSquadButton,
    StyledWidgetTeamLogo,
    StyledWidgetTeamLogoContainer,
    StyledWidgetTeamLogoContainerMobile,
    StyledWidgetTopRowContainer,
} from './AflInjuriesSuspensionsWidget.styled'

/**
 * Makes sure expected property is an array as API can return empty string, array or object
 * @param data API data with expected property
 * @param property expected property name
 * @returns
 */
const cleanUpApiValue = (data: any, property: string) => {
    return data === ''
        ? []
        : Array.isArray(data[property])
        ? data[property]
        : [data[property]]
}

const clickSquadsTab = () => {
    const buttons = document.body.getElementsByTagName('button')
    for (let i = 0; i < buttons.length; ++i) {
        if (buttons[i].textContent === 'Squads') {
            buttons[i].click()
            buttons[i].scrollIntoView()
        }
    }
}

const mapNamesOfInOutPlayers = (
    players: APIInOutPlayer[],
    allPlayersDetails: PlayerDataInterface[],
) => {
    const playersMapped = players.flatMap((player: APIInOutPlayer) => {
        const matchingPlayerData = allPlayersDetails.find(
            (playerDetails) =>
                playerDetails.playerId === player['@attributes'].playerId,
        )
        if (matchingPlayerData) {
            return matchingPlayerData.name
        } else {
            return []
        }
    })
    return playersMapped
}

const mapNamesOfInjuredSuspendedPlayers = (
    players: APIInjuredSuspendedPlayer[],
    allPlayersDetails: PlayerDataInterface[],
) => {
    const playersMapped = players.flatMap(
        (player: APIInjuredSuspendedPlayer) => {
            const matchingPlayerData = allPlayersDetails.find(
                (playerDetails) =>
                    playerDetails.playerId === player['@attributes'].playerId,
            )
            if (matchingPlayerData) {
                return (
                    matchingPlayerData.name +
                    ` (${player['@attributes'].injSuspStatus})`
                )
            } else {
                return []
            }
        },
    )
    return playersMapped
}

const getInOutPlayersNotInInjuredSuspended = (
    inOutPlayers: APIInOutPlayer[],
    injuredSuspendedPlayers: APIInjuredSuspendedPlayer[],
    teamId: string,
) => {
    const filteredInOutPlayers = inOutPlayers.filter(
        (playerOut: APIInOutPlayer) => {
            const isPlayerInInjuredSuspendedData = injuredSuspendedPlayers.find(
                (playerInjured: APIInjuredSuspendedPlayer) =>
                    playerInjured['@attributes'].playerId ===
                    playerOut['@attributes'].playerId,
            )
            return (
                playerOut['@attributes'].squadId === teamId &&
                playerOut['@attributes'].inOutType === 'OUT' &&
                !isPlayerInInjuredSuspendedData
            )
        },
    )
    return filteredInOutPlayers
}

const processOutPlayers = (
    injurySuspensionData: APIInjuredSuspendedPlayer[],
    doInsOutsData: APIInOutPlayer[],
    players: PlayerDataInterface[],
    teamId: string,
) => {
    const playersInjured = injurySuspensionData.filter(
        (player: APIInjuredSuspendedPlayer) =>
            player['@attributes'].squadId === teamId,
    )
    const playersInjuredMapped = mapNamesOfInjuredSuspendedPlayers(
        playersInjured,
        players,
    )
    const playersOutAndNotInjured = getInOutPlayersNotInInjuredSuspended(
        doInsOutsData,
        injurySuspensionData,
        teamId,
    )
    const playersOutAndNotInjuredMapped = mapNamesOfInOutPlayers(
        playersOutAndNotInjured,
        players,
    )
    const allPlayersOut = playersInjuredMapped.concat(
        playersOutAndNotInjuredMapped,
    )

    return allPlayersOut
}

const processInPlayers = (
    doInsOutsData: APIInOutPlayer[],
    players: PlayerDataInterface[],
    teamId: string,
) => {
    const playersIn = doInsOutsData.filter(
        (player: APIInOutPlayer) =>
            player['@attributes'].squadId === teamId &&
            player['@attributes'].inOutType === 'IN',
    )
    const playersInMapped = mapNamesOfInOutPlayers(playersIn, players)

    return playersInMapped
}

interface APIInjuredSuspendedPlayer {
    '@attributes': {
        squadId: string
        playerId: string
        injSuspStatus: string
    }
}

interface APIInOutPlayer {
    '@attributes': {
        squadId: string
        playerId: string
        inOutType: string
    }
}

export interface AflInjuriesSuspensionsWidgetProps {
    apiData: any
    competition: string
    season: string
}
export const AflInjuriesSuspensionsWidget = ({
    apiData,
    competition,
    season,
}: AflInjuriesSuspensionsWidgetProps) => {
    const homeTeamId =
        apiData?.report?.aflMatchStats?.matchInfo['@attributes']?.homeSquadId
    const awayTeamId =
        apiData?.report?.aflMatchStats?.matchInfo['@attributes']?.awaySquadId

    const [homePlayers, awayPlayers] = useFetchPlayerData(
        season,
        competition,
        homeTeamId,
        awayTeamId,
    )

    if (
        !homePlayers ||
        !awayPlayers ||
        homePlayers.length === 0 ||
        awayPlayers.length === 0 ||
        !apiData?.report?.aflMatchStats?.injuriesSuspensions ||
        apiData?.report?.aflMatchStats?.injuriesSuspensions === ''
    ) {
        return <StyledWidgetContainer>No data found</StyledWidgetContainer>
    }

    const injurySuspensionData: APIInjuredSuspendedPlayer[] = cleanUpApiValue(
        apiData?.report?.aflMatchStats?.injuriesSuspensions,
        'player',
    )

    const doInsOutsData: APIInOutPlayer[] = cleanUpApiValue(
        apiData?.report?.aflMatchStats?.doInsOuts,
        'player',
    )

    // Out players
    const allHomePlayersOut = processOutPlayers(
        injurySuspensionData,
        doInsOutsData,
        homePlayers,
        homeTeamId,
    )

    const allAwayPlayersOut = processOutPlayers(
        injurySuspensionData,
        doInsOutsData,
        awayPlayers,
        awayTeamId,
    )

    // In players
    const homePlayersInMapped = processInPlayers(
        doInsOutsData,
        homePlayers,
        homeTeamId,
    )

    const awayPlayersInMapped = processInPlayers(
        doInsOutsData,
        awayPlayers,
        awayTeamId,
    )

    // Logos
    const homeTeamBrandData = teamBrandData.find(
        (teamData: TeamBrandData) => teamData.teamId === homeTeamId,
    )
    const awayTeamBrandData = teamBrandData.find(
        (teamData: TeamBrandData) => teamData.teamId === awayTeamId,
    )

    return (
        <StyledWidgetContainer>
            <StyledWidgetTeamLogoContainer>
                <StyledWidgetTeamLogo src={homeTeamBrandData?.logo} />
            </StyledWidgetTeamLogoContainer>
            <StyledWidgetInnerContainer>
                <StyledWidgetTopRowContainer>
                    <StyledWidgetTeamLogoContainerMobile>
                        <StyledWidgetTeamLogo src={homeTeamBrandData?.logo} />
                    </StyledWidgetTeamLogoContainerMobile>
                    <StyledWidgetChangesInfo>
                        No late changes
                    </StyledWidgetChangesInfo>
                    <StyledWidgetTeamLogoContainerMobile>
                        <StyledWidgetTeamLogo src={awayTeamBrandData?.logo} />
                    </StyledWidgetTeamLogoContainerMobile>
                </StyledWidgetTopRowContainer>
                {((homePlayersInMapped && homePlayersInMapped.length > 0) ||
                    (awayPlayersInMapped &&
                        awayPlayersInMapped.length > 0)) && (
                    <StyledWidgetInRowContainer>
                        <StyledHomePlayersContainer>
                            {homePlayersInMapped.join(', ')}
                        </StyledHomePlayersContainer>
                        <StyledInIndicator>IN</StyledInIndicator>
                        <StyledAwayPlayersContainer>
                            {awayPlayersInMapped.join(', ')}
                        </StyledAwayPlayersContainer>
                    </StyledWidgetInRowContainer>
                )}
                <StyledWidgetRowContainer>
                    <StyledHomePlayersContainer>
                        {allHomePlayersOut.join(', ')}
                    </StyledHomePlayersContainer>
                    <StyledOutIndicator>OUT</StyledOutIndicator>
                    <StyledAwayPlayersContainer>
                        {allAwayPlayersOut.join(', ')}
                    </StyledAwayPlayersContainer>
                </StyledWidgetRowContainer>
                <StyledWidgetLegendContainer>
                    *Legend: Suspended - Not playing this Round, Injury-Season -
                    Player out for Season, Injury-TBA - Injured player not yet
                    assessed, Injury-Test - Injured player requires Test to
                    confirm availability this round, Injury-Indefinite - Injured
                    until further notice
                </StyledWidgetLegendContainer>
                <StyledWidgetSquadButton onClick={clickSquadsTab}>
                    See Full Squad
                </StyledWidgetSquadButton>
            </StyledWidgetInnerContainer>
            <StyledWidgetTeamLogoContainer>
                <StyledWidgetTeamLogo src={awayTeamBrandData?.logo} />
            </StyledWidgetTeamLogoContainer>
        </StyledWidgetContainer>
    )
}
