import React from 'react'
import { ReadMoreStyled } from './ReadMore.styled'
import { getReadTimeForDisplay } from '../../../../__helpers/get-read-time'

type ReadMoreProps = {
    readTime: number | null
    postCount?: number
    className?: string
}

export const ReadMore = ({ readTime, postCount, className }: ReadMoreProps) => {
    const countLabel = postCount
        ? postCount
        : readTime !== null
        ? getReadTimeForDisplay(readTime)
        : null

    const trailingText = postCount ? 'posts' : readTime !== null ? 'min' : null

    return (
        <ReadMoreStyled className={className}>{`Read more${
            countLabel !== null ? ` (${countLabel} ${trailingText})` : ''
        } →`}</ReadMoreStyled>
    )
}
