import {
    createRegisterableComponentWithData,
    VideoDataDefinitionLoader,
} from '@news-mono/web-common'
import React from 'react'
import { useLocation } from 'react-router'
import { VideoHub } from './VideoHub'
import { VideoHubRouteProps } from './VideoHub.routing'

export const TheWestVideoHubRegistration = createRegisterableComponentWithData(
    'thewest-video-hub',
    VideoDataDefinitionLoader,
    (props: VideoHubRouteProps, data, services) => {
        const location = useLocation()
        if (data.loaded) {
            return (
                <VideoHub
                    item={data.result.documents[0]}
                    onEvent={services.onEvent}
                    config={services.config}
                    adUnitPath={props.adUnitPath ?? ''}
                    currentPath={location.pathname}
                    verticalGutters={props.verticalGutters}
                    verticalSpacing={props.verticalSpacing}
                    getVideoQueue={() => {
                        return new Promise((resolve) =>
                            resolve({
                                items: data.result.documents,
                                type: 'topic-related',
                            }),
                        )
                    }}
                />
            )
        }
        return null
    },
)
