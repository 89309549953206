import React, { useEffect, useState } from 'react'
import {
    StyledCloseButton,
    StyledTooltipContainer,
    StyledTooltipHeading,
    StyledTooltipInfo,
} from './TheWestNewFeatureTooltip.styled'

interface TheWestNewFeatureTooltipProps {
    isOpen: boolean
    displayTooltipOverride?: boolean
}

export function TheWestNewFeatureTooltip({
    isOpen,
    displayTooltipOverride = false,
}: TheWestNewFeatureTooltipProps) {
    const [displayTooltip, setDisplayTooltip] = useState(displayTooltipOverride)

    useEffect(() => {
        if (isOpen) {
            closeButtonHandler()
        }
        if (
            window &&
            window.localStorage.getItem(
                'has-clicked-feature-awareness-tooltip',
            ) !== 'true'
        ) {
            setDisplayTooltip(true)
        }
    }, [isOpen])

    const closeButtonHandler = () => {
        if (window) {
            window.localStorage.setItem(
                'has-clicked-feature-awareness-tooltip',
                'true',
            )
        }
        setDisplayTooltip(false)
    }

    return (
        <StyledTooltipContainer displayTooltip={displayTooltip}>
            <StyledTooltipHeading>
                Your Account and Premium Features
            </StyledTooltipHeading>
            <StyledTooltipInfo>
                From now on, you’ll be able to access Subscriber Features
                directly from your <b>Account menu.</b>
            </StyledTooltipInfo>
            <StyledCloseButton onClick={closeButtonHandler}>
                Close
            </StyledCloseButton>
        </StyledTooltipContainer>
    )
}
