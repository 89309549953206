import { cx } from '@emotion/css'
import {
    BreakpointRatios,
    CropBreakpointSet,
    FixedRatio,
    ImageCrop,
    ImageRatio,
    ImageSource,
} from '@news-mono/web-common'
import React from 'react'
import {
    ShouldLoadProps,
    useLazyLoading,
} from '../../__helpers/with-lazy-loading'
import { BreakpointState, ThemeConfig } from '../../__styling'
import { StyledNoScriptImage } from './Image.noscript'
import { Image } from './Image.web'
import { PictureWithIntrinsicRatio } from './Picture.styled'
import { ResponsivePictureSizes } from './responsive'
import { getPictureSources, isLazyLoadGloballyDisabled } from './utils'
import { FetchPriority, NativeLazyLoading } from './Image.web.types'

export interface ImageSrcInfo {
    srcCallback: (src: string, width: number) => string
    initialWidth: number
}
export interface ImageDimensions {
    width: string
    height: string
}

interface LazyLoadingPictureProps {
    disableLazyLoad?: boolean
    useNativeLazyLoading?: boolean

    imageFetchPriority?: FetchPriority

    /** Additional picture styles */
    pictureClassName?: string

    /** Additional img styles */
    imageClassName?: string

    image?: ImageSource
    srcInfo: ImageSrcInfo
    imageSizes: ResponsivePictureSizes

    fixedRatio: FixedRatio | BreakpointRatios
    breakpointCrops: CropBreakpointSet<ImageCrop>
    imageDimensions?: ImageDimensions
    CSSCrop?: BreakpointState<ThemeConfig, ImageRatio>

    doubleSizedImages?: boolean
    displayNoScriptImage?: boolean
}

/**
 * This component renders an entire picture component including source sets and noscript fallbacks
 */
export const LazyLoadingPicture: React.FC<LazyLoadingPictureProps> = ({
    pictureClassName,
    imageClassName,
    image,
    srcInfo,
    imageSizes,
    fixedRatio,
    breakpointCrops,
    imageFetchPriority,
    imageDimensions,
    CSSCrop,
    doubleSizedImages = false,
    disableLazyLoad = false,
    useNativeLazyLoading = false,
    displayNoScriptImage,
}) => {
    const { lazyLoadRef, lazyLoadHasTriggered, wasLazyLoaded } =
        useLazyLoading<HTMLImageElement>(shouldLoad)
    const show =
        lazyLoadHasTriggered ||
        isLazyLoadGloballyDisabled() ||
        disableLazyLoad ||
        useNativeLazyLoading ||
        displayNoScriptImage ||
        false

    return (
        <PictureWithIntrinsicRatio
            ref={lazyLoadRef}
            image={image}
            fixedRatio={fixedRatio}
            className={cx(pictureClassName, 'image')}
            CSSCrop={CSSCrop}
        >
            {show && (
                <LazyImageWithSources
                    image={image}
                    breakpointCrops={breakpointCrops}
                    imageSizes={imageSizes}
                    srcInfo={srcInfo}
                    disableLazyLoad={disableLazyLoad}
                    imageFetchPriority={imageFetchPriority}
                    wasLazyLoaded={wasLazyLoaded}
                    className={imageClassName}
                    imageDimensions={imageDimensions}
                    doubleSizedImages={doubleSizedImages}
                    displayNoScriptImage={displayNoScriptImage}
                />
            )}
        </PictureWithIntrinsicRatio>
    )
}

interface LazyImageWithSourcesProps {
    disableLazyLoad: boolean
    imageFetchPriority?: FetchPriority
    breakpointCrops: CropBreakpointSet<ImageCrop>
    imageSizes: ResponsivePictureSizes
    srcInfo: ImageSrcInfo
    image?: ImageSource
    wasLazyLoaded: boolean
    className?: string
    imageDimensions?: ImageDimensions
    doubleSizedImages?: boolean
    displayNoScriptImage?: boolean
}

const LazyImageWithSources: React.FC<LazyImageWithSourcesProps> = ({
    disableLazyLoad,
    imageFetchPriority,
    breakpointCrops,
    imageSizes,
    srcInfo,
    image,
    className,
    wasLazyLoaded,
    imageDimensions,
    doubleSizedImages,
    displayNoScriptImage,
}) => {
    const pictureSources = getPictureSources(
        breakpointCrops,
        imageSizes,
        srcInfo,
        doubleSizedImages,
    )
    const defaultSrc = srcInfo.srcCallback(
        breakpointCrops.default.reference,
        srcInfo.initialWidth,
    )

    let loading: NativeLazyLoading | undefined = 'lazy'
    if (disableLazyLoad) {
        loading = undefined
    }

    return (
        <React.Fragment>
            {pictureSources}

            <Image
                enableFadeIn={wasLazyLoaded}
                src={defaultSrc}
                alt={image?.altText || ''}
                className={className}
                // Leverage in browser lazy loading to reduce the number of images
                // loaded on mobile viewports in browsers supporting it
                loading={loading}
                height={imageDimensions?.height}
                width={imageDimensions?.width}
                fetchpriority={imageFetchPriority}
            />

            {(!disableLazyLoad || displayNoScriptImage) && (
                <StyledNoScriptImage
                    alt={image?.altText || ''}
                    src={defaultSrc}
                />
            )}
        </React.Fragment>
    )
}

function shouldLoad(props: ShouldLoadProps) {
    if (props.percentVisible > 0) return true
    return (
        !!props.distanceFromViewport &&
        !!props.viewportHeight &&
        // Is within one screen away
        props.distanceFromViewport / props.viewportHeight < 1
    )
}
