import { ComponentInformation } from 'json-react-layouts'
import { AdUnitRouteProps } from '../../advertising/AdUnit/AdUnit.routing'
import { AdUnitInformation } from '../../advertising/advertising.routing'
import { AmpStickyAdUnitRouteProps } from '../../google-amp/components/AmpStickyAd.routing'
import { PublicationRouteProps } from '../../templates/Publication/SharedPublication.routing'

export const isAdUnitComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, AdUnitRouteProps> => {
    return component.type === 'ad-unit'
}

export const isAmpAdUnitComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, AmpStickyAdUnitRouteProps> => {
    return component.type === 'amp-sticky-ad-unit'
}

export const isPublicationComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, PublicationRouteProps> => {
    return component.type === 'publication'
}

export const isArticleComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, PublicationRouteProps> => {
    return component.type === 'article'
}

export const isFeatureComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, PublicationRouteProps> => {
    return component.type === 'article-feature'
}

export const isGalleryComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, PublicationRouteProps> => {
    return component.type === 'gallery'
}

export const isEventComponent = (
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, PublicationRouteProps> => {
    return component.type === 'event'
}

interface HasInlineContentAds {
    inlineContentAds: AdUnitInformation['data'][]
}

export function isComponentWithInlineContentAds<T extends HasInlineContentAds>(
    component: ComponentInformation<any, any>,
): component is ComponentInformation<any, T> {
    return !!component.props.inlineContentAds
}
