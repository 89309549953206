import styled from '@emotion/styled'
import { breakpoint, calcRem, themedValue } from '../../__styling'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'

interface OlympicWidgetStyleProps {
    size: OlympicsWidgetSize
}

export const StyledFlexRow = styled('section')<OlympicWidgetStyleProps>(
    ({ theme, size }) => [
        {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            justifyContent: size === 'full' ? 'flex-end' : 'flex-start',
            [breakpoint('sm')]: {
                width: 'unset',
            },
        },
        theme.kind === 'perthnow' && {
            width: size === 'embed' ? 'unset' : '100%',

            marginLeft: size === 'embed' ? calcRem(8) : undefined,
            marginRight: size === 'embed' ? calcRem(8) : undefined,
        },
    ],
)

export const StyledHeadingFlexRow = styled(
    StyledFlexRow,
)<OlympicWidgetStyleProps>(({ theme, size }) => ({
    flexDirection: themedValue(theme, {
        thenightly: 'row',
        fallback: size === 'full' ? 'column' : 'row',
    }),
    alignItems: size === 'full' ? 'flex-start' : 'center',
    justifyContent: 'space-between',
    marginBottom: themedValue(theme, {
        thenightly: size === 'full' ? calcRem(16) : 0,
        fallback: undefined,
    }),

    [breakpoint('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))

export const StyledTitle = styled('h5')<OlympicWidgetStyleProps>(
    ({ theme, size }) => ({
        fontSize: themedValue(theme, {
            thenightly: calcRem(20),
            fallback: size === 'full' ? calcRem(32) : calcRem(15),
        }),
        fontFamily: theme.fonts.sansSerif,
        textTransform: themedValue(theme, {
            thenightly: 'capitalize',
            fallback: 'uppercase',
        }),
        color: themedValue(theme, {
            thenightly: size === 'in-article' ? '#FFF' : '#000',
            fallback: '#FFF',
        }),
        margin: '0',

        [breakpoint('sm')]: {
            fontSize: themedValue(theme, {
                thenightly: size === 'full' ? calcRem(40) : calcRem(25),
                thewest: size === 'embed' ? calcRem(16) : calcRem(40),
                fallback: size === 'full' ? calcRem(40) : calcRem(25),
            }),
        },
    }),
)
