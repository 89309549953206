import { EpaperPublicationType, Issue } from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../../../../__product'
import { EpaperCollection } from '../EpaperCollection'
import { StyledReadersAlsoLoveHeading } from './ReadersAlsoLove.styled'

export interface ReadersAlsoLoveProps {
    allEditions: Issue[]
    section: EpaperPublicationType
    border?: boolean
    onEvent: (event: any) => void
}

export const ReadersAlsoLove: React.FC<ReadersAlsoLoveProps> = (props) => {
    const product = useProduct()

    return (
        <div>
            <StyledReadersAlsoLoveHeading border={props.border}>
                Readers also love
            </StyledReadersAlsoLoveHeading>
            <EpaperCollection
                openEReaderOnCardClick={false}
                showMastheadName={true}
                issues={props.allEditions}
                onCardClick={(link) => {
                    window.open(link, '_blank')
                }}
                readersAlsoLove={true}
                sectionToExclude={
                    product === 'thewest'
                        ? (props.section as EpaperPublicationType)
                        : props.section === 'default'
                        ? undefined
                        : 'perthnow'
                }
                onEvent={props.onEvent}
            />
        </div>
    )
}
