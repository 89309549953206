import React from 'react'
import {
    SearchCallLocation,
    SearchForm,
} from '../../search/SearchForm/SearchForm'
import { StyledSearchIcon } from '../../search/SearchForm/SearchForm.styled'
import { StyledSearchLink } from './SearchAction.styled'
import { SearchEvent } from '@news-mono/web-common'

export const SearchAction: React.FC<{
    isLarge?: boolean
    onSubmit?: () => void
    onEvent?: (event: SearchEvent) => void
    callLocation?: SearchCallLocation
}> = ({ isLarge = false, onSubmit, onEvent, callLocation }) => {
    return (
        <React.Fragment>
            {!isLarge && (
                <StyledSearchLink isLarge={isLarge} to="/search">
                    <StyledSearchIcon />
                </StyledSearchLink>
            )}
            <SearchForm
                isLarge={isLarge}
                inMainNavigation={true}
                hasPlaceholder={true}
                resetFormAfterSubmit={true}
                onSubmit={onSubmit}
                onEvent={onEvent}
                callLocation={callLocation}
            />
        </React.Fragment>
    )
}
SearchAction.displayName = 'SearchAction'
