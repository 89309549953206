import {
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../__styling'

export type PullquoteTheme<T extends ThemeConfig> = {
    icon: {
        fill: string
    }
}

export const theNightlyPullquoteTheme = (scheme: TheNightlyTheme) => {
    const theme: PullquoteTheme<TheNightlyThemeConfig> = {
        icon: {
            fill: scheme.sectionValues.primaryColor,
        },
    }
    return theme
}
