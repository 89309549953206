import { SanitisedWebStory } from './types'

export const sanitiseArrayForAmpPlayer = (
    stories: any[],
): Array<SanitisedWebStory> => {
    const sanitiseArray = stories.map((story) => {
        return {
            title: story.title,
            href: story.url,
            posterImage:
                story.posters.portrait || story.posters.default_portrait,
            tags: story.tags,
        }
    })
    return sanitiseArray
}
