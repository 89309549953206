import {
    AdEvent,
    AdState,
    LiveBlogEvents,
    SharingEvent,
} from '@news-mono/web-common'
import React, { FC, Fragment } from 'react'
import { Logger } from 'typescript-log'
import { AdUnitWrapper, useProduct } from '../../..'
import { useIsComponentOnScreen } from '../helpers'
import { LiveEventPinnedPosts } from './LiveEventPinnedPosts'
import { LiveEventPost } from './LiveEventPost'
import { StyledLiveEventTimeline } from './LiveEventTimeline.styled'
import { LiveEventPostV2 } from '../../../SevenNewsV2/LiveEventPostV2/LiveEventPostV2'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'
import { AdUnitMap } from '../hooks/useInterlacedAdUnits'

export interface LiveEventTimelineProps {
    log: Logger
    posts: EventPostV4DTO[]
    adUnits: AdUnitMap
    pinnedPosts: EventPostV4DTO[]
    eventTitle: string
    onEvent: (event: LiveBlogEvents | SharingEvent | AdEvent) => void
    triggerLazyLoad?: () => void
    divRef?: React.RefObject<HTMLDivElement>
    adState: AdState
    deepLinkedPostID?: string
    entryType?: string
}

export const LiveEventTimeline: FC<LiveEventTimelineProps> = ({
    posts,
    adUnits,
    pinnedPosts,
    eventTitle,
    log,
    triggerLazyLoad,
    onEvent,
    divRef,
    adState,
    deepLinkedPostID,
    entryType,
}) => {
    const { componentRef } = useIsComponentOnScreen('0px', triggerLazyLoad)

    const hasPinnedPosts = pinnedPosts.length > 0
    const product = useProduct()
    return (
        <StyledLiveEventTimeline ref={divRef}>
            {hasPinnedPosts && (
                <LiveEventPinnedPosts
                    pinned={pinnedPosts}
                    eventTitle={eventTitle}
                    log={log}
                    adUnitPath={adState.targeting.adUnitPath}
                    onEvent={onEvent}
                />
            )}
            {posts.map((post, index) => (
                <Fragment key={post.id}>
                    {product === 'sevennews' ? (
                        <LiveEventPostV2
                            post={post}
                            log={log}
                            eventTitle={eventTitle}
                            adUnitPath={adState.targeting.adUnitPath}
                            lazyLoadRef={
                                index === posts.length - 2
                                    ? componentRef
                                    : undefined
                            }
                            onEvent={onEvent}
                            isActivePost={deepLinkedPostID === post.id}
                            entryType={entryType}
                        />
                    ) : (
                        <LiveEventPost
                            post={post}
                            log={log}
                            eventTitle={eventTitle}
                            adUnitPath={adState.targeting.adUnitPath}
                            lazyLoadRef={
                                index === posts.length - 2
                                    ? componentRef
                                    : undefined
                            }
                            onEvent={onEvent}
                            isActivePost={deepLinkedPostID === post.id}
                            entryType={entryType}
                        />
                    )}
                    {index in adUnits && (
                        // Add adunit if expected here.
                        <AdUnitWrapper
                            noticePosition="above-center"
                            padding={[4, 0, 4, 0]}
                            adState={adState}
                            unitId={adUnits[index].id}
                            onEvent={onEvent}
                            adType={'inline'}
                        />
                    )}
                </Fragment>
            ))}
        </StyledLiveEventTimeline>
    )
}
