import styled from '@emotion/styled'
import {
    click_origin_breach_screen,
    EntitledToView,
} from '@news-mono/web-common'
import React from 'react'
import { useAuthActions } from '../..'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import {
    StyledBundleImage,
    StyledEntitledContainer,
    StyledEntitledText,
    StyledOpportunityContainer,
    StyledOpportunityText,
    StyledSubscribeButton,
    StyledTextEmphasis,
    StyledWestMark,
} from '../../cards/TWStaticSubscribeCard/TWStaticSubscribeCard.styled'
import { TheWestMark } from '../../logos/TheWestMark/TheWestMark'
import { colors } from '../../__styling/settings/colors'

interface CardOptions {
    options?: {
        btnOptions?: SubscriberButtonProps
    }
}

interface SubscriberButtonProps {
    link: string
    offerCode: string
}

export const EntitledView = () => {
    return (
        <StyledEntitledContainer>
            <StyledLink to={'/features'}>
                <StyledWestMark>
                    <TheWestMark fill={colors.white} height={30} width={38} />
                </StyledWestMark>
                <StyledEntitledText>
                    Explore other
                    <StyledTextEmphasis> exclusive</StyledTextEmphasis> video
                    content.
                </StyledEntitledText>
            </StyledLink>
        </StyledEntitledContainer>
    )
}
export const OpportunityView: React.FC<CardOptions> = ({ options }) => {
    const { onSubscribeClick } = useAuthActions()
    const { btnOptions } = options || {}
    const subscribeUrl = 'https://subscriber.thewest.com.au'
    const subscribeUtm =
        '/?utm_source=Thewest&utm_medium=Subscriber-tile&utm_campaign=gerardross&utm_term=&utm_content=Subscribe-now'
    const bundle = require('../../user-registration/breach/components/SubscriptionPackage/images/updated2022/everyday_digital_190_year.png')
    const hasBikiePromo = false

    function buttonClickHandler(e: React.MouseEvent<HTMLElement>) {
        if (!(btnOptions?.link && hasBikiePromo)) return
        e.preventDefault()
        onSubscribeClick({
            packagePath: btnOptions.link,
            offerCode: btnOptions.offerCode,
            callToAction: click_origin_breach_screen,
        })
    }

    return (
        <StyledOpportunityContainer>
            <StyledOpportunityText>
                Subscribe and enjoy exclusive content
            </StyledOpportunityText>
            <StyledSubscribeButton
                onClick={buttonClickHandler}
                to={subscribeUrl + subscribeUtm}
            >
                Subscribe Now
            </StyledSubscribeButton>

            <StyledBundleImage>
                <img src={bundle} alt="" role="presentation" />
            </StyledBundleImage>
        </StyledOpportunityContainer>
    )
}

export const TWStaticSubscribeCard: React.FC<CardOptions> = ({ options }) => {
    return (
        <EntitledToView
            requiredAccess={{ level: 'subscriber' }}
            entitled={() => {
                return <EntitledView />
            }}
            notEntitled={() => {
                return <OpportunityView options={options} />
            }}
        />
    )
}

export const StyledLink = styled(CardTextInteractions)({
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
})
StyledLink.displayName = 'StyledLink'
