import React from 'react'
import {
    StyledImage,
    StyledImageProps,
} from '../../content/Picture/Image.web.styled'
import { safeAttr } from '../../content/Picture/utils'

type NoScriptImageProps = Exclude<
    StyledImageProps & React.HTMLProps<HTMLImageElement>,
    'show'
>

const NoScriptImage: React.FC<NoScriptImageProps> = (props) => {
    const alt = props.alt ? ` alt="${safeAttr(props.alt)}"` : ''
    const src = props.src ? `src="${encodeURI(props.src)}"` : ''
    const srcset = props.srcSet ? ` srcset="${encodeURI(props.srcSet)}"` : ''

    const imgMarkup = `<img ${src} ${alt} ${srcset} class="${props.className}" />`

    return <noscript dangerouslySetInnerHTML={{ __html: imgMarkup }} />
}

NoScriptImage.displayName = 'NoScriptImage'

export const StyledNoScriptImage = StyledImage.withComponent(NoScriptImage)
StyledNoScriptImage.displayName = 'StyledNoScriptImage'
