import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { OlympicsBylineType } from '..'

export interface OlympicsBylineProps {
    type: OlympicsBylineType
    color?: string
}

/** Parent component for building a custom div */
export const StyledOlympicsByline = styled('div')<{
    type: OlympicsBylineType
}>(({ theme, type }) => [
    {
        display: 'flex',
        marginRight: 'auto',

        // Mobile styling
        marginBottom: calcRem(6),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            marginBottom: type === 'feature-article' ? calcRem(16) : calcRem(0),
        },
    },
    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        display: 'none',
    },
])

/** Style the author's profile thumbnail (if exists) */
export const StyledOlympicsBylineProfileThumbnail = styled('div')(
    ({ theme }) => [
        {
            display: 'flex',
            marginBottom: 'auto',
            marginRight: calcRem(20),

            height: calcRem(32),
            width: calcRem(32),
        },
    ],
)

/** Style the author's name */
export const StyledOlympicsAuthor = styled('p')<OlympicsBylineProps>(
    ({ theme, type, color = colors.olympics.text }) => [
        {
            fontFamily: theme.fonts.serif,
            fontSize: calcRem(12),
            lineHeight: calcRem(16),
            color: color,

            display: 'flex',
            margin: 0,

            // Mobile styling
            fontWeight: 700,

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                fontWeight: type === 'feature-article' ? 400 : 700,
            },
        },
    ],
)
