import React from 'react'

export interface IconChevronDownWideProps {
    className?: string
}

export const IconChevronDownWide: React.FC<IconChevronDownWideProps> = ({
    className,
}) => (
    <svg
        className={className}
        viewBox="0 0 24 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Chevron</title>
        <path d="M2.57196 0.929688L0.214935 3.28671L12 15.0719L23.7851 3.28676L21.4281 0.929736L12.0001 10.3578L2.57196 0.929688Z" />
    </svg>
)
IconChevronDownWide.displayName = 'IconChevronDownWide'
