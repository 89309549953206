import { layout } from '../../App.routing'

export const inCaseYouMissedItSegment = (isRegional = false) => {
    function returnPromoCard(isRegional: boolean) {
        if (isRegional) {
            return layout.component({
                type: 'curated-promotional-card',
                props: {
                    cardOrientation: {
                        defaultLayout: 'landscape',
                        alternativeLayoutOptions: {
                            breakpoint: 'md',
                            layout: 'portrait',
                        },
                    },
                    fixedRatio: '4:3',
                    hasBackground: true,
                    hasPadding: true,
                    mediaMode: 'edgeToEdge',
                    fontScale: 0.88,
                    showCTA: true,
                    verticalSpacing: 'md',
                    dataDefinitionArgs: {
                        type: 'active-promotion',
                        promotionSlotId: 'regional',
                    },
                },
            })
        }

        return layout.component({
            type: 'curated-promotional-card',
            props: {
                fixedRatio: '4:3',
                hasBackground: true,
                hasPadding: true,
                mediaMode: 'edgeToEdge',
                fontScale: 1,
                showCTA: true,
                dataDefinitionArgs: {
                    type: 'active-promotion',
                    promotionSlotId: 'homepage-position-1',
                },
                cardOrientation: {
                    defaultLayout: 'landscape',
                    alternativeLayoutOptions: {
                        breakpoint: 'md',
                        layout: 'portrait',
                    },
                },
            },
        })
    }

    return layout.nestedComposition({
        type: 'janus',
        props: {
            layoutRatio: '1:3',
            stretchColumns: true,
            verticalSpacing: 'xl',
        },
        contentAreas: {
            left: [
                layout.component({
                    type: 'section-header',
                    props: {
                        heading: 'Promotional',
                        hasBottomMargin: true,
                        invisibleHeaderBreakpoint: 'md',
                    },
                }),

                returnPromoCard(isRegional),
            ],
            right: [
                layout.component({
                    type: 'lima',
                    props: {
                        fixedRatios: ['16:9'],
                        intermediateColumns: 3,
                        finalColumns: 3,
                        hideByline: false,
                        hasBackground: true,

                        cardLayout: [
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            [
                                {
                                    type: 'landscape',
                                    format: 'landscape-xsTeaser-smNoTeaser',
                                },
                                {
                                    type: 'landscape',
                                    format: 'landscape-xsTeaser-smNoTeaser',
                                },
                                {
                                    type: 'landscape',
                                    format: 'landscape-xsTeaser-smNoTeaser',
                                },
                            ],
                        ],
                        cardOrientation: { type: 'Landscape-Portrait' },
                        sectionHeader: {
                            heading: 'In Case You Missed It',
                        },
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'featured',
                            offset: 0,
                            pageSize: 5,
                        },
                    },
                }),
            ],
        },
    })
}
