import { createRenderTarget } from '../../render-target'
import { TheWestHeaderActionsApp } from './TheWestHeaderActions.app'
import { TheWestHeaderActionsWeb } from './TheWestHeaderActions.web'

export const TheWestHeaderActions = createRenderTarget('TheWestHeaderActions', {
    web: TheWestHeaderActionsWeb,
    amp: null,
    rss: null,
    preview: TheWestHeaderActionsWeb,
    app: TheWestHeaderActionsApp,
})
TheWestHeaderActions.displayName = 'TheWestHeaderActions'
