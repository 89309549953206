import styled from '@emotion/styled'
import { linearGradient } from 'polished'
import { colors } from '../../__styling/settings/colors'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'

interface StyledBlockContentProps {
    hasRegistrationWall: boolean
}

// This is needed to fix an IE10 issue with overflowing content.
// TODO: Remove when we upgrade to React16 and remove extra uncessary wrapping div
export const StyledBlockContent = styled('div')<StyledBlockContentProps>(
    {
        position: 'relative',
        width: '100%',
    },
    ({ hasRegistrationWall }) =>
        hasRegistrationWall && {
            '&:before': {
                content: `''`,
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: zIndex.common.regWall.fade,

                ...linearGradient({
                    // Need to use rgba(255, 255, 255, 0) for the linear gradient otherwise it won't look good in Safari
                    // https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
                    colorStops: [
                        'rgba(255, 255, 255, 0)',
                        'rgba(255, 255, 255, 0.8)',
                        colors.white,
                    ],
                    fallback: 'transparent',
                    toDirection: '180deg',
                }),
            },
        },
)
StyledBlockContent.displayName = 'StyledBlockContent'

export const TwinImageWrapper = styled('div')({
    clear: 'both',

    [breakpoint('sm')]: {
        lineHeight: '0px',

        '&::after': {
            content: `''`,
            clear: 'both',
            width: '100%',
            display: 'inline-block',
        },
    },
})
TwinImageWrapper.displayName = 'TwinImageWrapper'
