import React from 'react'
import { IconLiveTN } from '../../icons'
import {
    TNLiveComponentContainer,
    TNLiveComponentText,
} from './TNLiveComponent.styled'

export interface TNLiveComponentProps {
    showBackground?: boolean
    backgroundColor?: string
    textColor?: string
    overlayOnImage?: boolean
    showIconAnimation?: boolean
    padding?: string
}

export const TNLiveComponent: React.FC<TNLiveComponentProps> = ({
    showBackground = false,
    backgroundColor,
    textColor,
    overlayOnImage = false,
    showIconAnimation = true,
    padding,
}) => (
    // We want to add the with-background & image-overlay tags here so that it can overlay accordingly
    <TNLiveComponentContainer
        padding={padding}
        backgroundColor={backgroundColor}
        className={`${showBackground ? 'with-background' : ''} ${
            overlayOnImage ? 'image-overlay' : ''
        }`}
    >
        <IconLiveTN useAnimation={showIconAnimation} fillColor={textColor} />
        <TNLiveComponentText textColor={textColor}>LIVE</TNLiveComponentText>
    </TNLiveComponentContainer>
)
