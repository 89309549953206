import {
    ArticleLikePublication,
    CardItem,
    Classification,
    PublicationCardItem,
} from '@news-mono/web-common'

export const enum EditorialType {
    Analysis = 'analysis',
    Breaking = 'breaking',
    Exclusive = 'exclusive',
    Investigation = 'investigation',
    Live = 'live',
    Replay = 'replay',
    Upcoming = 'upcoming',
    Updated = 'updated',
    Opinion = 'opinion',
    Review = 'review',
}

function labelToEditorialType(label: string) {
    switch (label) {
        case 'update':
            return EditorialType.Updated
        case 'upcoming':
            return EditorialType.Upcoming
        case 'replay':
            return EditorialType.Replay
        case 'analysis':
            return EditorialType.Analysis
        case 'breaking':
            return EditorialType.Breaking
        case 'exclusive':
            return EditorialType.Exclusive
        case 'investigation':
            return EditorialType.Investigation
        case 'live':
            return EditorialType.Live
        case 'opinion':
            return EditorialType.Opinion
        case 'review':
            return EditorialType.Review
        default:
            return undefined
    }
}

export function getEditorialType(
    item: PublicationCardItem | ArticleLikePublication | CardItem,
    storyClassificationEnabled: boolean,
) {
    return getFeatureEditorialType(
        item.classification,
        storyClassificationEnabled,
    )
}

export function getFeatureEditorialType(
    classification: Classification | undefined,
    storyClassificationEnabled: boolean,
) {
    if (
        !storyClassificationEnabled ||
        !classification ||
        classification.kind !== 'editorial'
    ) {
        return undefined
    }

    return labelToEditorialType(classification.label)
}
