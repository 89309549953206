import React from 'react'
import {
    PixelOffset,
    StyledIconButton,
    StyledIconButtonAnchorTag,
} from '../../buttons/IconButton/IconButton.styled'

export interface IconButtonProps {
    link: string
    type?: React.ButtonHTMLAttributes<any>['type']
    baseColor: string
    className?: string
    iconScale?: number
    icon: React.ReactElement<any>
    useAnchorTag?: boolean
    noBorder?: boolean // does not draw a border if set
    offset?: PixelOffset // position override for when Icon is not to be centered automatically
    forceInternalSSR?: boolean
    onClick?: () => void
}

export const IconButton: React.FC<IconButtonProps> = ({
    link,
    baseColor,
    icon,
    iconScale,
    className,
    useAnchorTag,
    noBorder,
    offset,
    forceInternalSSR,
    onClick,
}) => {
    return useAnchorTag ? (
        <StyledIconButtonAnchorTag
            href={link}
            baseColor={baseColor}
            iconScale={iconScale}
            className={className}
            noBorder={noBorder}
            offset={offset}
            onClick={onClick}
        >
            {icon}
        </StyledIconButtonAnchorTag>
    ) : (
        <StyledIconButton
            to={link}
            baseColor={baseColor}
            iconScale={iconScale}
            className={className}
            noBorder={noBorder}
            offset={offset}
            forceInternalSSR={forceInternalSSR}
        >
            {icon}
        </StyledIconButton>
    )
}
IconButton.displayName = 'IconButton'
