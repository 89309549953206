import * as CryptoJS from 'crypto-js'
import { ClientAuthConfigSection } from '@news-mono/web-common'

export interface ManageAccountRequestBody {
    email: string
    userId: string
    given_name?: string
    family_name?: string
    current_password?: string // Encrypted string
    new_password?: string // Encrypted string
    new_email?: string
    plain_password?: string
    plain_new_password?: string
    newsletterSignup?: boolean
    newsletterOptIn?: boolean
}

/* The Nightly Myaccount Action Paths */
export const getAuth0EmailVerifyActionPath =
    '/connect/manage-account/verify-email'
export const getAuth0UpdateEmailActionPath =
    '/connect/manage-account/update-email'
export const getAuth0UpdateNameActionPath =
    '/connect/manage-account/update-username'
export const getAuth0UpdatePasswordActionPath =
    '/connect/manage-account/update-password'
export const getAuth0VerifyPasswordActionPath =
    '/connect/manage-account/verify-password'
export const getAuth0DeleteAccountActionPath =
    '/connect/manage-account/delete-account'
export const getAuth0ForgotPasswordActionPath =
    '/connect/manage-account/forgot-password'
export const getAuth0UpdateAppMetaActionPath =
    '/connect/manage-account/update-app-meta'

export async function encryptPass(
    passToEncrypt: string,
    passEncryptionKey: ClientAuthConfigSection['passEncryptionKey'],
) {
    if (!passEncryptionKey || !passToEncrypt) {
        console.log('No key or password found')
        return
    }

    if (passEncryptionKey && passToEncrypt) {
        try {
            const encryptedPass = CryptoJS.AES.encrypt(
                passToEncrypt,
                passEncryptionKey,
            ).toString()

            console.log('successfully encrypted')
            return encryptedPass
        } catch (e) {
            console.log('error encrypting password', { err: e })
            return
        }
    }
}
