import * as React from 'react'
import {
    StyledDriverImageContainer,
    StyledFederalElectionDriverContainer,
    StyledLiveCoverageContainer,
    StyledLiveCoverageTextContainer,
    StyledTriangle,
    StyledTriangleContainer,
    StyledTriangleRight,
} from './FederalElectionDriver.styled'

const asset = require('./assets/federal-election-driver.png')

export const FederalElectionDriver = () => {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        document.location.href = '/politics/federal-election'
    }

    return (
        <StyledFederalElectionDriverContainer onClick={(e) => handleClick(e)}>
            <StyledLiveCoverageContainer>
                <StyledTriangleContainer>
                    <StyledTriangle translateHeight={[-31, 0]} rotate={-90} />
                </StyledTriangleContainer>
                <StyledLiveCoverageTextContainer>
                    <span>LIVE</span>
                    <br />
                    <span>COVERAGE</span>
                </StyledLiveCoverageTextContainer>
                <StyledTriangleRight />
            </StyledLiveCoverageContainer>
            <StyledDriverImageContainer url={asset} />
        </StyledFederalElectionDriverContainer>
    )
}
