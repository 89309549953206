import styled from '@emotion/styled'
import { IconActionComment } from '../../icons/IconActionComment/IconActionComment'
import { IconComment } from '../../icons/IconComment/IconComment'
import { TextLink } from '../../typography/TextLink/TextLink'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledCommentsWrapper = styled('div')(({ theme }) => [
    theme.kind === 'thewest' && {
        marginRight: calcRem(11),
        '&::before': {
            content: `''`,
            display: 'inline-block',
            width: 1,
            height: calcRem(13),
            transform: 'translate(-6px, 2px)',
            background: themedValue(theme, {
                sevennews: colors.sevennews.charade,
                perthnow: colors.perthnow.greyCoal,
                thewest: colors.thewest.greyGorilla,
                fallback: undefined,
            }),
        },
    },
    theme.kind === 'perthnow' && {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'end',
        marginLeft: 'auto',
    },
])

// The West Comment Icon
export const StyledCommentsIcon = styled(IconComment)({
    width: 14,
    height: 12,
    fill: colors.thewest.greyHippo,
    marginRight: calcRem(6),
    transform: 'translate(0, 2px)',
    strokeWidth: 0,
})

interface CommentButtonColorOverrideProps {
    isNeutralGrey?: boolean
}

// PerthNow Comment Icon
export const StyledPNCommentsIcon = styled(IconActionComment)(
    ({ isNeutralGrey }) => ({
        width: 14,
        height: 14,
        marginRight: calcRem(6),
        fill: isNeutralGrey
            ? colors.perthnow.greyMarble
            : colors.perthnow.pinkThulite,
    }),
)

export const StyledTextLink = styled(TextLink)<CommentButtonColorOverrideProps>(
    ({ theme, isNeutralGrey }) => [
        theme.kind === 'perthnow' && {
            fontFamily: theme.fonts.sansSerif,
            fontSize: calcRem(14),
            color: isNeutralGrey
                ? colors.perthnow.greyMarble
                : colors.perthnow.pinkThulite,
            textDecoration: 'none',
            ':hover': {
                background: 'none',
                color: isNeutralGrey
                    ? colors.perthnow.greyMarble
                    : colors.perthnow.pinkThulite,
            },
        },
    ],
)
