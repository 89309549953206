import { DataLoaderGlobalParams, getVideos } from '@news-mono/web-common'
import { VideoQueue } from './get-video-queue'

/**
 * Gets the video queue for a publication based on it's topics
 */
export async function getVideoSeriesQueue(
    services: DataLoaderGlobalParams,
    series: string,
    currentEpisode: number,
): Promise<VideoQueue> {
    const apiVideoListing = await getVideos(services, {
        series,
    })

    const withoutTrailerAndEarlierEpisodes = apiVideoListing.documents.filter(
        (videos) =>
            videos.seriesInfo?.episode !== 'trailer' &&
            (videos.seriesInfo?.episode ?? 0) > currentEpisode,
    )

    return {
        items: withoutTrailerAndEarlierEpisodes,
        type: 'video-series',
    }
}
