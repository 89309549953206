import loadable from '@loadable/component'
import React from 'react'
import { TheWestLivePlayerPageProps } from './TheWestLivePlayerPage'

const LazyTheWestLivePlayerComponent = loadable(
    () => import('./TheWestLivePlayerPage'),
)

// Standalone lazy load component
export const LazyTheWestLivePlayerPage = (
    props: TheWestLivePlayerPageProps,
) => {
    return <LazyTheWestLivePlayerComponent {...props} />
}
