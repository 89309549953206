import styled from '@emotion/styled'
import {
    BreakpointKeys,
    breakpoint,
    breakpointSwitch,
    calcRem,
    getComponentTheme,
} from '../../__styling'

export const ArticleCardListContainer = styled.div(({ theme }) => {
    const articleCardListTheme = getComponentTheme(theme, 'articleCardList')
    return [
        {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',

            transition: 'gap 0.25s',
        },
        breakpointSwitch(articleCardListTheme.gap, (gap) => ({ gap })),
    ]
})

export const Divider = styled.span(({ theme }) => {
    const dividerTheme = getComponentTheme(theme, 'articleCardList').divider
    return {
        display: 'block',
        height: calcRem(1),
        width: '100%',
        maxWidth: calcRem(770),
        background: dividerTheme.color,
    }
})

interface ContainerProps {
    margin: {
        default: string
        sm?: string
        lg?: string
    }
}
export const AdContainer = styled.div<ContainerProps>(({ margin }) => {
    return {
        margin: margin.default,
        width: '100%',

        [breakpoint('sm')]: {
            margin: margin.sm,
        },

        [breakpoint('lg')]: {
            margin: margin.lg,
        },
    }
})

export const MedalTallyWidgetWrapper = styled.div(({ theme }) => {
    return {
        display: 'block',
        width: '100%',
        height: calcRem(320),

        [breakpoint('sm')]: {
            display: 'none',
        },
    }
})
