import { withTheme } from '@emotion/react'
import { handleUnknownError } from '@news-mono/common'
import React from 'react'
import {
    StyledButton,
    StyledContainer,
    StyledContentContainer,
    StyledForm,
    StyledFormContainer,
    StyledIcon,
    StyledInput,
    StyledInputWrapper,
    StyledLabel,
    StyledSubText,
    StyledTermsText,
    StyledText,
    StyledTextLink,
} from '../../banners/NewsletterSignup/NewsletterSignup.styled'
import { LogoSevenMark } from '../../logos/LogoSevenMark/LogoSevenMark'
import { PerthNowMark } from '../../logos/PerthNowMark/PerthNowMark'
import { TheWestMark } from '../../logos/TheWestMark/TheWestMark'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'
import { Product } from '@news-mono/web-common'
import { StyledNightlyIconEmail } from '../../buttons/ProfileEmailButton/ProfileEmailButton.styled'
import { NewsletterVariants } from './NewsletterSignup.routing'

export interface InternalNewsletterSignupProps {
    text: string
    buttonLabel: string
    onEvent: (event: any) => void
    api: string
    verticalSpacing?: keyof ThemeMargins
    subText?: string
    mode: NewsletterVariants
}

interface State {
    email: string
    honeypot: string
    error: boolean
    success: boolean
    isSubmitting: boolean
}

class InternalNewsletterSignup extends React.Component<
    InternalNewsletterSignupProps & { theme: Theme },
    State
> {
    static displayName = 'InternalNewsletterSignup'

    state = {
        email: '',
        honeypot: '',
        error: false,
        success: false,
        isSubmitting: false,
    }

    handleSubmit = async (e: React.FormEvent<any>) => {
        e.preventDefault()
        this.setState({ isSubmitting: true })
        try {
            const api = this.props.api

            const response = await fetch(
                `${api}/subscribe?email=${this.state.email}&address=${this.state.honeypot}`,
                { method: 'POST' },
            )

            if (response.ok) {
                this.setState({ success: true, isSubmitting: false }, () => {
                    this.props.onEvent({
                        type: 'newsletterSignup.success',
                        originator: 'NewsletterSignupBanner',
                        timeStamp: Date.now(),
                        payload: {
                            creativeName: 'Default',
                            buttonText: this.props.buttonLabel,
                            newsletterDatabase: 'daily_news',
                        },
                    })
                })
            } else {
                this.setState({ isSubmitting: false, error: true }, () => {
                    this.props.onEvent({
                        type: 'newsletterSignup.fail',
                        originator: 'NewsletterSignupBanner',
                        timeStamp: Date.now(),
                        payload: {
                            creativeName: 'Default',
                            buttonText: this.props.buttonLabel,
                            newsletterDatabase: 'daily_news',
                        },
                    })
                })

                throw new Error(
                    `NewsletterSignupBanner submission error: ${response.status} ${response.statusText}`,
                )
            }
        } catch (error) {
            const err = handleUnknownError(error)
            console.error(err)
        }
    }

    handleFocus = () => {
        this.props.onEvent({
            type: 'newsletterSignup.interact',
            originator: 'NewsletterSignupBanner',
            timeStamp: Date.now(),
            payload: {
                creativeName: 'Default',
                buttonText: this.props.buttonLabel,
                newsletterDatabase: 'daily_news',
            },
        })
    }

    renderMarkIcon(theme: Theme) {
        switch (theme.kind) {
            case 'perthnow':
                return <PerthNowMark />
            case 'thewest':
                return <TheWestMark fill={colors.white} />
            case 'sevennews':
                return <LogoSevenMark />
            default:
                return <TheWestMark />
        }
    }

    componentDidMount = () => {
        this.props.onEvent({
            type: 'newsletterSignup.available',
            originator: 'NewsletterSignupBanner',
            timeStamp: Date.now(),
            payload: {
                creativeName: 'Default',
                buttonText: this.props.buttonLabel,
                newsletterDatabase: 'daily_news',
            },
        })
    }

    render() {
        const { isSubmitting, success, email, error, honeypot } = this.state
        const theme = this.props.theme

        const emailSubscribeLink = '/manage-email-preferences'

        const subscribeUrl = themedValue(theme, {
            perthnow: `https://www.perthnow.com.au${emailSubscribeLink}`,
            fallback: emailSubscribeLink,
        })

        const contactUrl = themedValue(theme, {
            perthnow: 'https://www.perthnow.com.au/contact',
            thenightly: 'https://thenightly.com.au/contact',
            fallback: 'https://thewest.com.au/contact',
        })

        return (
            <StyledContainer verticalSpacing={this.props.verticalSpacing}>
                {theme.kind !== Product.TheNightly && (
                    <StyledIcon>{this.renderMarkIcon(theme)}</StyledIcon>
                )}
                <StyledContentContainer>
                    <StyledFormContainer mode={this.props.mode}>
                        {success && !isSubmitting && (
                            <StyledText>Thank you for subscribing!</StyledText>
                        )}

                        {isSubmitting && <StyledText>Submitting...</StyledText>}

                        {!success && error && (
                            <StyledText>
                                Something went wrong, please{' '}
                                <StyledTextLink href={subscribeUrl}>
                                    try again
                                </StyledTextLink>{' '}
                                or{' '}
                                <StyledTextLink href={contactUrl}>
                                    contact us
                                </StyledTextLink>
                            </StyledText>
                        )}

                        {!success && !error && !isSubmitting && (
                            <React.Fragment>
                                <StyledText>{this.props.text}</StyledText>
                                {this.props.subText && (
                                    <StyledSubText>
                                        {this.props.subText}
                                    </StyledSubText>
                                )}
                                <StyledForm
                                    method="POST"
                                    onSubmit={this.handleSubmit}
                                    mode={this.props.mode}
                                >
                                    <StyledLabel htmlFor="newsletterSignup-emailAddress">
                                        Your email address:
                                    </StyledLabel>
                                    {theme.kind === Product.TheNightly ? (
                                        <StyledInputWrapper
                                            mode={this.props.mode}
                                        >
                                            <StyledNightlyIconEmail />
                                            <StyledInput
                                                name="email"
                                                id="newsletterSignup-email"
                                                value={email}
                                                type="email"
                                                required
                                                placeholder="Email address"
                                                onFocus={this.handleFocus}
                                                onChange={(e) =>
                                                    this.setState({
                                                        email: e.currentTarget
                                                            .value,
                                                    })
                                                }
                                            />
                                        </StyledInputWrapper>
                                    ) : (
                                        <StyledInput
                                            name="email"
                                            id="newsletterSignup-email"
                                            value={email}
                                            type="email"
                                            required
                                            placeholder="Your email address"
                                            onFocus={this.handleFocus}
                                            onChange={(e) =>
                                                this.setState({
                                                    email: e.currentTarget
                                                        .value,
                                                })
                                            }
                                        />
                                    )}

                                    {/* Honeypot field, will be rejected by API if a value is entered */}
                                    <StyledInput
                                        hidden
                                        name="address"
                                        id="newsletterSignup-address"
                                        value={honeypot}
                                        type="text"
                                        onChange={(e) =>
                                            this.setState({
                                                honeypot: e.currentTarget.value,
                                            })
                                        }
                                    />

                                    <StyledButton
                                        type="submit"
                                        disabled={isSubmitting}
                                        mode={this.props.mode}
                                    >
                                        {this.props.buttonLabel}
                                    </StyledButton>
                                </StyledForm>
                                {theme.kind === Product.TheNightly && (
                                    <StyledTermsText>
                                        By continuing you agree to our{' '}
                                        <a href="/subscription-terms">Terms</a>{' '}
                                        and{' '}
                                        <a href="https://www.sevenwestmedia.com.au/privacy-policies/privacy">
                                            Privacy Policy
                                        </a>
                                        .
                                    </StyledTermsText>
                                )}
                            </React.Fragment>
                        )}
                    </StyledFormContainer>
                </StyledContentContainer>
            </StyledContainer>
        )
    }
}

export const NewsletterSignup = withTheme(InternalNewsletterSignup)
