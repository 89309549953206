import { EditorialType } from '../templates/Publication/lib/get-editorial-type'

export const appendToHeadline = (headline: string, type?: EditorialType) => {
    if (!type || type === undefined) return headline

    const headlineDoesntInclude = (tagline: string) =>
        !headline.toLowerCase().includes(tagline)

    switch (type) {
        case EditorialType.Replay:
            if (headlineDoesntInclude('on demand')) {
                return `On demand: ${headline}`
            }
            return headline
        case EditorialType.Live:
            if (headlineDoesntInclude('watch live')) {
                return `Watch LIVE: ${headline}`
            }
            return headline
        default:
            return headline
    }
}
