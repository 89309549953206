import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { Action } from 'redux'
import { isIE } from '../../client/helpers'
import { RadioEvent, toRadioEvent } from '../../events/radio-events'
import { DataLayerEventName } from '../../helpers/DataLayerEventName'
import { isBetweenUTC, isWeekdayAWST } from '../../helpers/time'
import { SWMAsyncThunkAction } from '../../store'

export type TheWestLivePlayerStates = 'playing' | 'loading' | 'stopped'
export type TheWestLiveBroadcast = 'live'

type RadioCoStatus = 'online' | 'offline'
type RadioCoSourceType = 'live' | 'automated'

interface Collaborator {
    id: string
    name: string
    status: string
}

interface RadioCoStatusResponse {
    status: RadioCoStatus
    source: {
        type: RadioCoSourceType
        collaborator?: Collaborator | null
        relay?: any
    }
    collaborators: Collaborator[]
    relays: any[]
    current_track: {
        title: string
        start_time: Date
        artwork_url: string
        artwork_url_large: string
    } | null
    history: Array<{ title: string }>
    logo_url: string
    streaming_hostname: string
    outputs: {
        name: string
        format: string
        bitrate: number
    }[]
}

/**
 * status: The status of the radio stream, can be online or offline, does not indicate if audio is streaming or a collaborator is connected
 * onAir: A collaborator is actively streaming to the radio
 * streamingHostname: The prefix hostname to the streaming endpoint, runtime config
 */
export interface TheWestLiveState {
    isLive: boolean
    showPlayer: boolean
    streamingHostname?: string
    broadcast: TheWestLiveBroadcast
}

export const WEST_LIVE_ON = 'WEST_LIVE_ON'
export const WEST_LIVE_OFF = 'WEST_LIVE_OFF'
export const HIDE_WEST_LIVE_PLAYER = 'HIDE_WEST_LIVE_PLAYER'

export interface WEST_LIVE_ON extends Action {
    type: typeof WEST_LIVE_ON
    payload: { streamingHostname: string; broadcast: TheWestLiveBroadcast }
}

export interface WEST_LIVE_OFF extends Action {
    type: typeof WEST_LIVE_OFF
}

export interface HIDE_WEST_LIVE_PLAYER extends Action {
    type: typeof HIDE_WEST_LIVE_PLAYER
}

export interface HoursWindow {
    startTime: [number, number]
    endTime: [number, number]
}

export function checkInScheduleWindow(
    schedule: HoursWindow[],
    now: Date = new Date(),
    onEvent: (event: RadioEvent) => void,
    options?: {
        // Testing property to set live
        force?: boolean
        // Delay to factor in streaming difference
        delay?: number
        playerState?: TheWestLivePlayerStates
    },
): SWMAsyncThunkAction<WEST_LIVE_ON | WEST_LIVE_OFF | HIDE_WEST_LIVE_PLAYER> {
    return async (dispatch, getState, { config }) => {
        if (!config.theWestLiveStationId) {
            return
        }

        const isWithinSchedule = isWithinScheduledWindow(schedule, now)
        const { theWestLive, toggles } = getState()
        const featureState = toFeatureState(toggles)
        const { force = false, delay = 30_000, playerState } = options ?? {}
        const isPlaying = playerState === 'playing'

        // Do not render the west live if it is toggled off or opened in IE <= 11
        if (
            !isFeatureEnabled(featureState, 'the-west-live') ||
            isIE(navigator)
        ) {
            shutDownWestLive()
            return
        }

        if (
            isWithinSchedule ||
            isFeatureEnabled(featureState, 'force-west-live-activation') ||
            force
        ) {
            const streamStatus = await getStreamStatus(
                config.theWestLiveStationId,
                force,
            )
            if (!theWestLive.isLive) {
                if (streamStatus.onAir) {
                    onEvent(toRadioEvent(DataLayerEventName.radioAvailable))
                    dispatch({
                        type: WEST_LIVE_ON,
                        payload: {
                            streamingHostname: streamStatus.streamingHostname,
                            broadcast: streamStatus.broadcast,
                        },
                    })
                }
            } else {
                if (!streamStatus.onAir) {
                    shutDownWestLive()
                } else if (isPlaying) {
                    onEvent(toRadioEvent(DataLayerEventName.radio30))
                }
            }
        } else {
            if (
                theWestLive.isLive &&
                !(await getStreamStatus(config.theWestLiveStationId)).onAir
            ) {
                shutDownWestLive()
            }
        }

        function shutDownWestLive() {
            if (theWestLive.isLive) {
                dispatch({ type: WEST_LIVE_OFF })
            }
            if (isPlaying) {
                onEvent(toRadioEvent(DataLayerEventName.radioEnd))
            }

            setTimeout(() => {
                if (theWestLive.showPlayer) {
                    dispatch({ type: HIDE_WEST_LIVE_PLAYER })
                }
            }, delay)
        }
    }
}

async function getStreamStatus(
    stationId: string,
    force = false,
): Promise<
    | { onAir: false }
    | {
          onAir: true
          streamingHostname: string
          broadcast: TheWestLiveBroadcast
      }
> {
    const radioStatusResponse = await fetch(
        `https://public.radio.co/stations/${stationId}/status`,
    )

    if (radioStatusResponse.status !== 200) {
        return { onAir: false }
    }

    const parsedBody: RadioCoStatusResponse = await radioStatusResponse.json()
    const {
        status,
        source,
        streaming_hostname: streamingHostname,
        current_track,
    } = parsedBody

    if (
        force ||
        source.type === 'live' ||
        (source.type === 'automated' &&
            status === 'online' &&
            current_track?.title
                .toLowerCase()
                .indexOf('west live broadcast') !== -1)
    ) {
        return { onAir: true, streamingHostname, broadcast: 'live' }
    }

    return { onAir: false }
}

export type TheWestLiveActions =
    | WEST_LIVE_ON
    | WEST_LIVE_OFF
    | HIDE_WEST_LIVE_PLAYER
    | { type: '@@INIT' }

const defaultState: TheWestLiveState = {
    isLive: false,
    showPlayer: false,
    streamingHostname: undefined,
    broadcast: 'live',
}

export const theWestLiveReducer = (
    state: TheWestLiveState = defaultState,
    action: TheWestLiveActions,
): TheWestLiveState => {
    switch (action.type) {
        case WEST_LIVE_ON: {
            return {
                ...state,
                isLive: true,
                showPlayer: true,
                streamingHostname: action.payload.streamingHostname,
                broadcast: action.payload.broadcast,
            }
        }

        case WEST_LIVE_OFF: {
            return {
                ...state,
                isLive: false,
            }
        }

        case HIDE_WEST_LIVE_PLAYER: {
            return {
                ...state,
                showPlayer: false,
            }
        }

        default:
            return state
    }
}

/**
 * Check west live schedule override
 * ?the_west_live query will enable the west live schedule and set window.theWestLive=true to persist the state for navigation
 * settting window.theWestLive directly requires a rerender / navigation
 */
function scheduleOverride() {
    const maybeWindow =
        typeof window !== 'undefined' ? (window as any) : undefined
    if (
        maybeWindow &&
        maybeWindow.location.search.indexOf('the_west_live') !== -1
    ) {
        maybeWindow.theWestLive = true
    }
    return maybeWindow && maybeWindow.theWestLive
}

export function isWithinScheduledWindow(
    blocks: HoursWindow[],
    now: Date = new Date(),
): boolean {
    if (scheduleOverride()) {
        return true
    }

    if (!isWeekdayAWST(now)) {
        return false
    }

    const withinSchedule = blocks.some((block) =>
        isBetweenUTC(now, block.startTime, block.endTime),
    )

    if (withinSchedule) {
        return true
    }

    return false
}
