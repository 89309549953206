import styled from '@emotion/styled'
import { createPropFilter } from '@news-mono/web-common'
import { breakpoint, calcRem, metrics } from '../../../__styling'

interface Layout {
    className?: string
    numberOfCards: number
}

export const StyledInlineContentCardsContainer = styled('div', {
    shouldForwardProp: createPropFilter<Layout>()(['numberOfCards']),
})<Layout>(({ numberOfCards }) => [
    {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: `${calcRem(
            metrics.sevennews.margins.sm,
            metrics.sevennews.margins.gridGap,
        )}`,

        [breakpoint('xxs')]: {
            gridTemplateColumns: numberOfCards > 1 ? '1fr 1fr' : '1fr',
        },
    },
])
