import React from 'react'
import { StyledHorizontalDivider } from './HorizontalDivider.styled'
import { ThemeMargins } from '../../__styling'

export interface HorizontalDividerProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
}

export const HorizontalDivider = (props: HorizontalDividerProps) => {
    return <StyledHorizontalDivider {...props} />
}
