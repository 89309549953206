import { cx } from '@emotion/css'
import React from 'react'

export interface IconAlertCircleTNProps {
    className?: string
    strokeColor?: string
}

export const IconAlertCircleTN: React.FC<IconAlertCircleTNProps> = ({
    className,
    strokeColor = '#420000',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        className={cx(className, 'icon-alert-circle-tn')}
    >
        <path
            d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconAlertCircleTN.displayName = 'IconAlertCircleTN'
