import { CropDTO } from './dto'

export interface FallbackImages {
    altText: string
    crops: {
        '4:3': CropDTO
        '16:9': CropDTO
        '161:229': CropDTO
        '316:421': CropDTO
    }
}

// Assets have only been updated to the production S3 bucket
export const theWestFallbackImages: FallbackImages = {
    altText: 'The West logo',
    crops: {
        '4:3': {
            reference:
                'https://thewest.com.au/static/fallback-images/thewest-4x3.png',
            dimensions: '322x242',
            width: 322,
            height: 242,
            ratio: { width: 4, height: 3 },
        },
        '16:9': {
            reference:
                'https://thewest.com.au/static/fallback-images/thewest-16x9.png',
            dimensions: '664x374',
            width: 664,
            height: 374,
            ratio: { width: 16, height: 9 },
        },
        '161:229': {
            reference:
                'https://thewest.com.au/static/fallback-images/thewest-161x229.png',
            dimensions: '322x458',
            width: 322,
            height: 458,
            ratio: { width: 161, height: 229 },
        },
        '316:421': {
            reference:
                'https://thewest.com.au/static/fallback-images/thewest-316x421.png',
            dimensions: '316x421',
            width: 316,
            height: 421,
            ratio: { width: 316, height: 421 },
        },
    },
}

// Assets have only been updated to the production S3 bucket
export const perthNowFallbackImages: FallbackImages = {
    altText: 'PerthNow logo',
    crops: {
        '4:3': {
            reference:
                'https://perthnow.com.au/static/fallback-images/perthnow-4x3.png',
            dimensions: '322x242',
            width: 322,
            height: 242,
            ratio: { width: 4, height: 3 },
        },
        '16:9': {
            reference:
                'https://perthnow.com.au/static/fallback-images/perthnow-16x9.png',
            dimensions: '664x374',
            width: 664,
            height: 374,
            ratio: { width: 16, height: 9 },
        },
        '161:229': {
            reference:
                'https://perthnow.com.au/static/fallback-images/perthnow-161x229.png',
            dimensions: '322x458',
            width: 322,
            height: 458,
            ratio: { width: 161, height: 229 },
        },
        '316:421': {
            reference:
                'https://perthnow.com.au/static/fallback-images/perthnow-316x421.png',
            dimensions: '316x421',
            width: 316,
            height: 421,
            ratio: { width: 316, height: 421 },
        },
    },
}

// Assets have only been updated to the production S3 bucket
export const sevenNewsFallbackImages: FallbackImages = {
    altText: '7NEWS logo',
    crops: {
        '4:3': {
            reference:
                'https://7news.com.au/static/fallback-images/7news-4x3.png',
            dimensions: '322x242',
            width: 322,
            height: 242,
            ratio: { width: 4, height: 3 },
        },
        '16:9': {
            reference:
                'https://7news.com.au/static/fallback-images/7news-16x9.png',
            dimensions: '664x374',
            width: 664,
            height: 374,
            ratio: { width: 16, height: 9 },
        },
        '161:229': {
            reference:
                'https://7news.com.au/static/fallback-images/7news-161x229.png',
            dimensions: '322x458',
            width: 322,
            height: 458,
            ratio: { width: 161, height: 229 },
        },
        '316:421': {
            reference:
                'https://7news.com.au/static/fallback-images/7news-316x421.png',
            dimensions: '316x421',
            width: 316,
            height: 421,
            ratio: { width: 316, height: 421 },
        },
    },
}

// TODO, replace with the Nightly assets when available
export const theNightlyFallbackImages: FallbackImages = {
    altText: 'The Nightly logo',
    crops: {
        '4:3': {
            reference:
                'https://thenightly.com.au/static/fallback-images/thenightly-4x3.png',
            dimensions: '322x242',
            width: 322,
            height: 242,
            ratio: { width: 4, height: 3 },
        },
        '16:9': {
            reference:
                'https://thenightly.com.au/static/fallback-images/thenightly-16x9.png',
            dimensions: '664x374',
            width: 664,
            height: 374,
            ratio: { width: 16, height: 9 },
        },
        '161:229': {
            reference:
                'https://thenightly.com.au/static/fallback-images/thenightly-161x229.png',
            dimensions: '322x458',
            width: 322,
            height: 458,
            ratio: { width: 161, height: 229 },
        },
        '316:421': {
            reference:
                'https://thenightly.com.au/static/fallback-images/thenightly-316x421.png',
            dimensions: '316x421',
            width: 316,
            height: 421,
            ratio: { width: 316, height: 421 },
        },
    },
}
