import {
    ArticleLikePublication,
    isArticleLikeType,
    Topic,
} from '@news-mono/web-common'
import React from 'react'
import { Pointer } from '../../../content/Pointer/Pointer'
import { InlinePositionedContent } from '../../../templates/Publication/Publication.props'
import {
    InlineContentTypes,
    InlinePointer,
} from '../../../templates/Publication/SharedPublication.routing'

export interface GetInlinePositionedContentProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlinePointer(
    content: InlineContentTypes,
): content is InlinePointer {
    return content.kind === 'pointer'
}

// We need to match the article topic to either "business/n" or "news/wa".
const matchArticleTopics = (
    primaryTopic: Topic,
    secondaryTopics: Topic[],
): boolean => {
    const primary = /business+(?=\/)|^news\/wa/i.test(primaryTopic.id)
    const secondary =
        secondaryTopics &&
        secondaryTopics.some((topic) =>
            /business+(?=\/)|^news\/wa/i.test(topic.id),
        )
    return primary ? primary : secondary
}

export const getInlinePositionedPointer = (
    props: GetInlinePositionedContentProps,
) => {
    const { publication, inlinePublicationContent } = props

    // Should be an article and either tagged with "Business" or "WA News".
    if (
        !isArticleLikeType(publication) ||
        !matchArticleTopics(
            publication.primaryTopic,
            publication.secondaryTopics,
        )
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlinePointer)
        .reduce((acc: InlinePositionedContent[], content: InlinePointer) => {
            const position = content.insertAfter
            const mappedAdContent = {
                position,
                element: (
                    <Pointer
                        pointerLinkUrl={content.props.pointerLinkUrl}
                        pointerImageSrc={content.props.pointerImageSrc}
                        pointerImageAlt={content.props.pointerImageAlt}
                    />
                ),
            }
            acc.push(mappedAdContent)
            return acc
        }, [])
}
