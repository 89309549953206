import { layout } from '../../App.routing'

export const yourMoneySegment = layout.component({
    type: 'uniform',
    props: {
        sectionHeader: {
            heading: 'Your Money',
            headingUrl: '/business/your-money',
        },
        verticalSpacing: 'xl',
        section: 'default',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            ['teaserWithBackground'],
            [
                'teaser-xsVisible-mdHidden-Image',
                'teaser-xsVisible-mdHidden-noImage',
            ],
            [
                'teaser-xsVisible-mdHidden-Image',
                'teaser-xsVisible-mdHidden-noImage',
            ],
            ['teaserWithBackground'],
        ],

        dataDefinitionArgs: {
            type: 'curation',
            name: 'your-money',
            offset: 0,
            pageSize: 6,
        },
    },
})
