import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ThemeConfig } from '../../__styling'
import { SchemeOverrider, SchemeOverriderProps } from './SchemeOverrider'

export const getSchemeOverriderRegistration = <T extends ThemeConfig>() =>
    createRegisterableComposition<'children'>()(
        'scheme-overrider',
        (contentAreas, { override }: SchemeOverriderProps<T>) => (
            <SchemeOverrider<T> override={override}>
                {contentAreas.children}
            </SchemeOverrider>
        ),
    )
