import {
    ArticleLikeV4DTO,
    BlockV4DTO,
} from '@west-australian-newspapers/publication-types'
import { ArticleLikePublication } from '../../../../client-data-types/publication'
import { Topic } from '../../../../client-data-types/topic'
import { TogglesReduxState } from '../../../../feature-togglings/reducer'
import { isArticleLikeKind } from '../../../../helpers'
import {
    getArticleAndRedirectCommonProps,
    getHeroVideo,
    getProfiles,
} from '../../../../__api-mapping/v4/publication/map/common'
import { getContentBlocks } from '../../../../__api-mapping/v4/publication/map/content-blocks'
import { mergeImageDtosToImageSource } from '../../lib/to-image'

export function mapArticle(
    publication: ArticleLikeV4DTO,
    toggles: TogglesReduxState,
): ArticleLikePublication {
    const blogIsPresent =
        publication.kind === 'article' && publication.blogState !== null
    const content = getContentBlocks(publication)
    const commonProps = getArticleAndRedirectCommonProps(publication)

    const mainVideo = publication.mainVideo
    const heroImage = mergeImageDtosToImageSource(...publication.mainImages)
    const heroVideo = getHeroVideo(heroImage, mainVideo, toggles)
    const profiles = publication.profiles
        ? publication.profiles.map(getProfiles)
        : undefined

    // layoutView should always be defined here as `mapArticle` should only be used where kind === article.
    // We default to "default" so that we avoid type errors given it will be undefined for galleries and redirects
    const layoutView = publication.layoutView || 'default'
    // classification is on articles but not on redirects or galleries
    const classification = isArticleLikeKind(publication.kind)
        ? publication.classification
        : undefined

    const requiredAccess = publication.requiredAccess ?? {
        level: 'anonymous',
    }

    const readTimeMinutes =
        publication.kind == 'article' ? publication.readTimeMinutes : null

    const articleLikeProps = {
        ...commonProps,
        profile: profiles,
        heroImage,
        heroVideo,
        teaser: publication.homepageTeaser,

        /**
         * Present on content that originates from non newsgate sources such as NewsCorp
         */
        canonicalUrl:
            publication.canonicalUrl === null
                ? undefined
                : publication.canonicalUrl,
        canonicalTitle:
            publication.canonicalTitle === null
                ? undefined
                : publication.canonicalTitle,

        blogIsPresent,
        content,
        requiredAccess,
        layoutView,
        classification,
        allowCommenting: publication.allowCommenting,
        excludeFeaturedVideo:
            (publication.kind === 'article' &&
                publication.excludeFeaturedVideo) ||
            shouldExcludeFeaturedVideo(publication),
        readTimeMinutes,
    }

    switch (publication.kind) {
        case 'redirect':
        case 'article':
            return {
                ...articleLikeProps,
                kind: 'article',
                hasOovvuuRecommendations: publication.hasOovvuuRecommendations,
            }
        case 'event':
            return {
                ...articleLikeProps,
                kind: 'event',
                entries: publication.entries,
                entryType: publication.entryTypes,
            }
        default:
            throw new Error('unsupported publication')
    }
}

const featuredVideoExcludedTopics = [
    'news/crime',
    'news/crime-stoppers',
    'news/disaster-and-emergency',
    'news/telethon',
    'news/natural-disasters',
    'news/child-protection',
    'news/claremont-serial-killings',
    'business/public-companies',
    'business/bulls-n-bears',
    'business/west-business-events',
    'business/rich-list',
    'lifestyle/kids',
    'lifestyle/have-you-heard',
    'lifestyle/stm',
    'entertainment/games',
    'entertainment/timespool',
    'entertainment/competitions',
]

const blockedKeywords = [
    'Rape',
    'Murder',
    'Suicide',
    'Self harm',
    'Mental health',
    'Sexual Assault',
    'Sex Assault',
    'WA Police',
    'Magistrate’s Court',
    'Supreme Court',
    'District Court',
    'Australian Federal Police',
    'Claremont serial killing',
    'Claremont serial killer',
    'Paedophile',
    'Paedophilia',
    'Magistrate',
    'Court of Criminal Appeal',
    'Court of Appeal',
    'Indecent Assault',
    'Sexual penetration',
    'Incest',
    'Lloyd Rayney',
    'Ben Cousins',
    'Gina Rinehart',
    'John Kizon',
    'Bikies',
    'Bikie',
]

const matchesExcludedTopic = (topic: Topic) =>
    featuredVideoExcludedTopics.indexOf(topic.id) !== -1
const blockContainsBannedKeyword = (block: BlockV4DTO) => {
    if (block.kind !== 'text') {
        return false
    }

    return blockedKeywords.some(
        (keyword) =>
            block.text.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
    )
}

function shouldExcludeFeaturedVideo(publication: ArticleLikeV4DTO): boolean {
    if (
        [publication.topics.primary, ...publication.topics.secondary].some(
            matchesExcludedTopic,
        )
    ) {
        return true
    }

    if (publication.content.blocks.some(blockContainsBannedKeyword)) {
        return true
    }

    return false
}
