import {
    AllEvents,
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import {
    InlineContentTypes,
    InlineOlympicsMedalWidget,
} from '../SharedPublication.routing'
import { MedalTally } from '../../../olympics'

export interface InlineOlympicsMedalWidgetProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineOlympicsMedalWidget(
    content: InlineContentTypes,
): content is InlineOlympicsMedalWidget {
    return content.kind === 'inline-olympics-medal-widget'
}

export function getInlinePositionedOlympicsMedalWidget({
    publication,
    inlinePublicationContent,
}: InlineOlympicsMedalWidgetProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineOlympicsMedalWidget)
        .filter((content) => content.toggle)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: (
                    <MedalTally size="in-article" widgetTitle={'medal-tally'} />
                ),
            }
            return mappedContent
        })
}
