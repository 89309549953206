import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, PerthNowThemeConfig } from '../../../../__styling'
import { Container } from '../Container/Container'
import { Text, TextContainer, Title } from '../Text/Text'

export const StyledContainer = styled(Container)({
    [breakpoint('sm')]: {
        flexDirection: 'row',
    },
})

export const StyledTextContainer = styled(TextContainer)({
    flex: '1 1 0',

    minWidth: 0,
})

export const StyledText = styled(Text)({
    textAlign: 'left',
})

export const AccountLink = styled(WebLink)(({ theme }) => [
    textMixin<PerthNowThemeConfig>(theme, 'Paragraph/M/Sans/Regular'),
    {
        color: 'inherit',
        textDecoration: 'underline',
    },
])

export const StyledTitle = styled(Title)({
    textAlign: 'left',
})

export const SaveNameForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),
    flex: '1 1 0',
})

export const FormInputWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
})

export const FormErrorText = styled.p(({ theme }) => [
    textMixin<PerthNowThemeConfig>(theme, 'Label/M/Regular'),
    {
        margin: 0,
        color: '#C12323',
    },
])
