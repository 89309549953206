import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'
import { colors, themedValue } from '../../__styling'

interface OlympicWidgetStyleProps {
    size: OlympicsWidgetSize
}

export const StyledFlexRow = styled('section')<OlympicWidgetStyleProps>(
    ({ theme, size }) => [
        {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            justifyContent: size === 'full' ? 'flex-end' : 'flex-start',
            [breakpoint('sm')]: {
                width: size === 'full' ? '50%' : '100%',
            },
        },
        theme.kind === 'perthnow' && {
            marginLeft: size === 'embed' ? calcRem(8) : undefined,
            marginRight: size === 'embed' ? calcRem(8) : undefined,
        },
    ],
)

export const StyledWebLink = styled(WebLink)<OlympicWidgetStyleProps>(
    ({ theme, size }) => ({
        backgroundColor: themedValue(theme, {
            thenightly: colors.white,
            fallback: colors.olympics.link,
        }),
        color: themedValue(theme, {
            thenightly: theme.colors.actions.button.primary.background.default,
            fallback: colors.olympics.background,
        }),
        border: themedValue(theme, {
            thenightly:
                size === 'in-article' ? 'none' : `1px solid ${colors.black}`,
            fallback: undefined,
        }),
        alignItems: 'center',
        textDecoration: 'none',
        padding: calcRem(8, 7),
        width: '100%',
        fontSize: calcRem(13),
        fontWeight: 700,
        borderRadius: '3px',
        textAlign: 'center',
        fontFamily: theme.fonts.sansSerif,
        ...transition({ properties: ['background-color', 'color', 'opacity'] }),
        [breakpoint('sm')]: {
            width: '50%',
        },
        '&:not(:first-child)': {
            marginLeft: calcRem(10),
        },

        '&:hover': {
            opacity: 0.8,
        },

        '&:focus': {
            opacity: 0.8,
        },

        '&.isActive': {
            backgroundColor: themedValue(theme, {
                thenightly: colors.black,
                fallback: colors.white,
            }),
            border: themedValue(theme, {
                thenightly: `1px solid ${colors.black}`,
                fallback: `1px solid ${colors.olympics.background}`,
            }),
            color: themedValue(theme, {
                thenightly: colors.white,
                fallback: colors.olympics.background,
            }),
        },
    }),
)
