import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    CommentData,
    createRegisterableComponentWithData,
    isArticleLikeType,
    useToggleState,
} from '@news-mono/web-common'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import {
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../../templates/Publication/SharedPublication.routing'
import { TheWestPublication } from '../../../templates/Publication/TheWestPublication/TheWestPublication'
import { ArticlePublicationDataDefinitionLoader } from '../ArticlePublicationDataLoader'
import { getVideoQueue, VideoQueue } from '../lib/get-video-queue'
import { getVideoSeriesQueue } from '../lib/get-video-series'
import { getPublicationPageProperties } from '../lib/publication-page-properties'
import { shouldExcludeVideoSeries } from '../lib/should-include-video-series'
import { getWestInlinePositionedContent } from './get-west-inline-positioned-content'

export const TheWestArticleRegistration = createRegisterableComponentWithData(
    'article',
    ArticlePublicationDataDefinitionLoader,
    (props: PublicationRouteProps, data, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)

        if (!isArticleLikeType(publicationInfo.publication)) {
            throw new Error(`Article component rendered for non-article.`)
        }
        const toggles = useToggleState()

        if (data.loaded) {
            const inlinePositionedContent = getWestInlinePositionedContent({
                adState: services.adState,
                publication: publicationInfo.publication,
                meta: props.meta,
                log: services.log,
                onEvent: services.onEvent,
                toggles,
                westLiveState: services.store.getState().theWestLive,
            })

            const commentData: CommentData = {
                'comment.status': publicationInfo.publication.allowCommenting
                    ? 'available'
                    : 'notavailable',
                'comment.viewableUserType': 'loggedin',
                'comment.participationUserType': 'loggedin',
                'comments.featureVisible': isFeatureEnabled(
                    toFeatureState(toggles),
                    'comments',
                ),
                'comments.componentName': 'launch',
            }

            return (
                <PageProps
                    pageProperties={getPublicationPageProperties(
                        publicationInfo.publication,
                        commentData,
                    )}
                >
                    <TheWestPublication
                        data={publicationInfo.publication}
                        inlinePositionedContent={inlinePositionedContent}
                        onEvent={services.onEvent}
                        log={services.log}
                        getVideoQueue={() => {
                            if (
                                publicationInfo.publication.heroVideo
                                    ?.seriesInfo
                            ) {
                                if (
                                    publicationInfo.publication.heroVideo
                                        .seriesInfo.episode === 'trailer'
                                ) {
                                    return Promise.resolve<VideoQueue>({
                                        type: 'video-series',
                                        items: [],
                                    })
                                }

                                return getVideoSeriesQueue(
                                    services,
                                    publicationInfo.publication.heroVideo
                                        .seriesInfo.series,
                                    publicationInfo.publication.heroVideo
                                        .seriesInfo.episode,
                                )
                            }
                            return getVideoQueue(
                                services,
                                publicationInfo.publication.id,
                                shouldExcludeVideoSeries(
                                    publicationInfo.publication,
                                    services.store.getState().toggles,
                                ),
                            )
                        }}
                        heroOptions={{
                            isVideoStickyEnabled: props.isVideoStickyEnabled,
                        }}
                        relatedPublications={data.result.relatedPublications}
                        location={services.location}
                        adState={services.adState}
                    />
                </PageProps>
            )
        }
        return null
    },
)
