import React from 'react'

export interface IconPinProps {
    className?: string
}

export const IconPin: React.FC<IconPinProps> = ({ className }) => {
    return (
        <svg
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Pin Icon</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66 3.642a.643.643 0 0 0 .732.187l4.26-1.699a.643.643 0 0 0 .404-.64L1.66 3.642ZM.558 2.698a.643.643 0 0 0-.359.835l.165.413a1.928 1.928 0 0 0 2.504 1.077l4.26-1.7A1.928 1.928 0 0 0 8.205.82L8.04.406a.643.643 0 0 0-.835-.359L.558 2.698ZM6.895 10.557l1.12-.447 2 5.473H8.827l-1.932-5.026Z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m8.562 6.647-1.787-4.48-4.48 1.786 1.787 4.48-.258.375a3.213 3.213 0 0 0-.339 3.013l.019.046c.131.33.505.49.835.359l6.651-2.653a.643.643 0 0 0 .36-.83l-.01-.028a3.213 3.213 0 0 0-2.342-1.978l-.436-.09ZM3.965 4.67 5.53 8.596l-.648.941c-.25.363-.365.795-.336 1.223l5.285-2.108a1.927 1.927 0 0 0-1.093-.657l-1.113-.23-1.567-3.93-2.093.836Z"
                fill="#fff"
            />
        </svg>
    )
}
