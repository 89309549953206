import { useMemo } from 'react'

/**
 * Info for placing an adunit in the list.
 */
export interface LiveEventAd {
    id: string
    adPos: number
}

/**
 * A map of indeces to adunits.
 * The key corresponds to the index after which the adunit should be inserted.
 * The value corresponds to information about the adunit.
 */
export type AdUnitMap = Record<number, LiveEventAd>

/**
 * A hook to fetch the locations and information about where adunits should be injected.
 */
export const useInterlacedAdUnits = (params: GetInterlacedAdUnitsParams) => {
    return useMemo(() => getInterlacedAdUnits(params), [params])
}

type GetInterlacedAdUnitsParams = {
    /** The number of elements to interlace adunits in. */
    listSize: number
    /** After how many elements should an adunit be inserted */
    offset: number
    /** How many elements between each adunit. */
    spacing: number
}

/**
 * Returns a map of indeces and adunit information to mark where adunits should be inserted.
 */
const getInterlacedAdUnits = ({
    listSize,
    offset,
    spacing,
}: GetInterlacedAdUnitsParams) => {
    const adUnits: AdUnitMap = {}

    if (offset <= 1) {
        // If you want adunits before the first element,
        // you can adjust this check, but be aware this will appear
        // as an adunit with the index '-1'.
        console.warn(
            'Adunit offset must be greater than 0 as they are to be inserted after a post.',
        )
        return {}
    }

    if (spacing < 1) {
        console.warn('Adunits cannot have a spacing less than 1.')
        return {}
    }

    // The number of adunits to show.
    const adUnitCount = Math.ceil((listSize - offset) / spacing)

    for (let adPos = 0; adPos < adUnitCount; adPos++) {
        const adIndex = adPos * spacing + offset - 1

        adUnits[adIndex] = {
            id: `event-mrec-${adPos}`,
            adPos,
        }
    }

    return adUnits
}
