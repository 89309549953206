import H from 'history'
import React from 'react'
import { AflFixtureData } from './ScoreBoard/types'
import { useFetchAflFixtureData } from './ScoreBoard/useAflFetchFixture'

interface OptaAflProviderProps {
    location: H.Location
}

export interface OptaAflApiContextProps {
    data: AflFixtureData | null
    error: boolean
    loading: boolean
}

export const OptaAflApiContext = React.createContext<OptaAflApiContextProps>({
    data: null,
    error: false,
    loading: false,
})

interface OptaAflProviderProps {
    location: H.Location
}

export const OptaAflApiProvider: React.FC<OptaAflProviderProps> = ({
    children,
    location,
}) => {
    let matchId: string | undefined = undefined

    if (
        location.pathname.includes('/sport/afl/match-centre') ||
        location.pathname.includes('/sport/aflw/match-centre')
    ) {
        const params = new URLSearchParams(location.search)
        const match = params.get('match')

        if (match) {
            matchId = match
        }
    }

    const { data, error, loading } = useFetchAflFixtureData(matchId, undefined)

    return (
        <OptaAflApiContext.Provider value={{ data, error, loading }}>
            {children}
        </OptaAflApiContext.Provider>
    )
}
