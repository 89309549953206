import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../../../../__styling'

const { neutral } = tokens.thenightly.colors.palette

export const Bullet = styled.span(({ theme }) => {
    return {
        width: 2,
        height: 2,
        margin: `0 ${calcRem(theme.margins.sm)} 0 ${calcRem(theme.margins.sm)}`,
        borderRadius: 1,
        backgroundColor: neutral[60],
    }
})
