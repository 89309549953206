import { Logger } from 'pino'

/**
 * Handle unknown error type - always returns an Error or descendant type
 *
 * At runtime type info is lost so you can't use instanceof to disambiguate a typescript type/interface (you can if it's a class)
 *
 * However, you can disambiguate the expected type by checking for a property that doesn't exist on Error (something other that name,message)
 *
 * ```ts
 * // Example error interface
 * interface ApiError extends Error {
 *   meta: any
 * }
 *
 * // err is of type ApiError | Error
 * try {
 *  // something that throws an error
 * } catch (error) {
 *   const err = handleUnknownError<ApiError>(error)
 *   if (meta in err) {
 *     // err is ApiError because .meta prop exists
 *   }
 * }
 * ```
 */
export function handleUnknownError<Expected extends Error>(
    err: unknown,
): Error | Expected {
    if (err instanceof Error) {
        return err
    }
    if (typeof err === 'string') {
        return new Error(err)
    }

    if (err && typeof err === 'object') {
        return new Error(err.toString())
    }

    return new Error('Unknown error: ' + err)
}

/**
 * Register global event listeners that ensure unhandled promise rejections and
 * uncaught exceptions are properly logged into sumo
 *
 * @see https://nodejs.org/api/process.html#event-unhandledrejection
 * @param log Logger configured for sumo
 */
export function registerUnhandledLogger(log: Logger) {
    process.on(
        'unhandledRejection',
        (reason: unknown, promise: Promise<unknown>) => {
            if (reason instanceof Error) {
                log.error(
                    { err: reason, promise },
                    'Unhandled promise rejection',
                )
            } else {
                log.error({ reason, promise }, 'Unhandled promise rejection')
            }
        },
    )
    process.on(
        'uncaughtException',
        (
            error: unknown,
            origin: 'uncaughtException' | 'unhandledRejection',
        ) => {
            // Unhandled rejections logged above with the promise info
            if (origin === 'unhandledRejection') {
                return
            }
            if (error instanceof Error) {
                log.error({ err: error }, 'Uncaught exception')
            } else log.error({ error }, 'Uncaught exception')
        },
    )
}
