import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
    isPublicationCardItem,
} from '@news-mono/web-common'
import React from 'react'
import { RelatedContent } from './RelatedContent'

export interface RelatedContentRegistrationProps {
    title?: string
    displayMode?: 'list' | 'juliet'
    numberOfItems?: number
}

export const RelatedContentRegistration = createRegisterableComponentWithData(
    'related-content',
    ContentDataDefinitionLoader,
    (
        { title, displayMode, numberOfItems }: RelatedContentRegistrationProps,
        data,
        services,
    ) => {
        if (data.loaded) {
            const publications = data.result.publications.filter(
                isPublicationCardItem,
            )

            if (publications.length) {
                return (
                    <RelatedContent
                        items={publications}
                        title={title}
                        onEvent={services.onEvent}
                        displayMode={displayMode}
                        numberOfItems={numberOfItems}
                    />
                )
            }
        }

        return null
    },
)
