import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    regionalSiteConfig,
    TheWestRegionalSections,
    TheWestSection,
} from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    RouteInformation,
    RouteResolution,
    RouteServices,
    StaticRoutes,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'

const ePaperSocialImage = ''

function getSocialImageMeta(src: string, height: string, width: string) {
    return [
        {
            property: 'og:image',
            content: src,
        },
        { name: 'twitter:image', content: src },
        {
            property: 'og:image:height',
            content: height,
        },
        {
            property: 'og:image:width',
            content: width,
        },
        {
            property: 'og:image:alt',
            content: '',
        },
        {
            property: 'twitter:image:alt',
            content: '',
        },
    ]
}

export const ePaper: StaticRoutes<TheWestSection> = {
    '/editions': (services) => {
        return createEPaperPage(services, 'default', 'The West Australian')
    },
}

export function createEPaperPage(
    services: RouteServices<RouteResolution>,
    section: 'default' | 'the-extra' | TheWestRegionalSections,
    title: string,
): RouteInformation<TheWestSection> {
    const canonicalUrl =
        section === 'default'
            ? services.config.publicUrl + '/editions'
            : section === 'the-extra'
            ? regionalSiteConfig['albany-advertiser'].publicUrl +
              '/editions/the-extra'
            : regionalSiteConfig[section].publicUrl + '/editions'

    return {
        kind: 'page',
        heading: 'Digital Edition',
        pageType: 'static',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: `${title} Digital Edition`,
            description: `${title} digital edition: Read the latest edition of ${title} – exactly as it appears in print – here online using your mobile device or desktop browser.`,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            meta: [
                {
                    property: 'robots',
                    content: 'noindex',
                },
            ],
            //meta: getSocialImageMeta(ePaperSocialImage, '630', '1200'),
        },
        socialMeta: {
            title: `${title} Digital Edition`,
            description: `${title} digital edition: Read the latest edition of ${title} – exactly as it appears in print – here online using your mobile device or desktop browser.`,
        },
        adTargeting: services.getAdTargeting('home', section, 'default'),
        section: section === 'the-extra' ? 'albany-advertiser' : section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    hasBackgroundFill: true,
                    stretchSelf: true,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'epaper-page',
                                        props: {
                                            dataDefinitionArgs: {
                                                numberOfIssues: 1,
                                                publication: section,
                                            },
                                            title,
                                        },
                                    }),
                                    layout.component({
                                        type: 'epaper-page-latest-edition',
                                        props: {
                                            dataDefinitionArgs: {
                                                numberOfIssues: 14,
                                                publication: section,
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'epaper-page-readers-also-love',
                                        props: {
                                            dataDefinitionArgs: {
                                                publicationsToFetch: [
                                                    'default',
                                                    'perthnow',
                                                    'albany-advertiser',
                                                    'the-extra',
                                                    'augusta-margaret-river-times',
                                                    'broome-advertiser',
                                                    'bunbury-herald',
                                                    'busselton-dunsborough-times',
                                                    'countryman',
                                                    'geraldton-guardian',
                                                    'great-southern-herald',
                                                    'harvey-waroona-reporter',
                                                    'kalgoorlie-miner',
                                                    'the-kimberley-echo',
                                                    'manjimup-bridgetown-times',
                                                    'midwest-times',
                                                    'narrogin-observer',
                                                    'north-west-telegraph',
                                                    'pilbara-news',
                                                    'sound-telegraph',
                                                    'south-western-times',
                                                ],
                                            },
                                            border: true,
                                            section,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const ePaperSubscribe: StaticRoutes<TheWestSection> = {
    '/editions/subscribe': (services) => {
        return createEPaperSubscribePage(
            services,
            'default',
            'The West Australian',
        )
    },
}

export function createEPaperSubscribePage(
    services: RouteServices<RouteResolution>,
    section: 'default' | TheWestRegionalSections,
    title: string,
): RouteInformation<TheWestSection> {
    const canonicalUrl =
        (section === 'default'
            ? services.config.publicUrl
            : regionalSiteConfig[section].publicUrl) + '/editions/subscribe'
    return {
        kind: 'page',
        heading: 'ePaper',
        pageType: 'breach',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: `Subscribe to ${title}`,
            description: `Subscribe to ${title}: Subscription packages for ${title} including print delivery and digital bundles.`,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            //meta: getSocialImageMeta(ePaperSocialImage, '630', '1200'),
        },
        adTargeting: services.getAdTargeting('home', 'default', 'default'),
        socialMeta: {
            title: `Subscribe to ${title}`,
            description: `Subscribe to ${title}: Subscription packages for ${title} including print delivery and digital bundles.`,
        },
        section: section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    stretchSelf: true,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    // Redirect to /editions if already a subscriber.
                                    layout.component({
                                        type: 'redirector',
                                        props: {
                                            getRedirectPathFromState: () =>
                                                '/editions',
                                            isStaticRedirect: true,
                                        },
                                        requiresAccess: {
                                            level: 'subscriber',
                                        },
                                    }),
                                    layout.component({
                                        type: 'breachscreen',
                                        props: {
                                            bannerMessage:
                                                'Subscribers with digital access can view this Digital Edition.',
                                            title: `Subscribe to ${title}`,
                                            breachDeal:
                                                section === 'default'
                                                    ? 'epaper'
                                                    : 'epaper-regional',
                                            breachType: 'ePaper',
                                            breachCreativeName: 'ePaper',
                                            terms: '',
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
