import {
    SectionMetaDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'

export function getSectionMetaInfo(
    sectionMeta: SectionMetaDTO,
    section: string,
) {
    const sectionInfo: SectionMetaInfoDTO = {
        ...sectionMeta.defaultSectionMeta,
        ...(sectionMeta.sectionOverrides as any)[section],
    }

    return sectionInfo
}
