import styled, { Interpolation } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { zIndex } from '../../__styling/settings/z-index'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { hexToRGBA } from '../../__styling/style-mixins'
import { PlayIconProps } from './PlayButtonV2'
import { breakpoint } from '../../__styling'

export const sevennewsPlayArrow = require('./assets/7news-play-arrow.svg')

export const playButtonIconClass = 'video_play_button_icon'

const getPlayIconStyles = (isSmall?: boolean) => {
    return {
        left: calcRem(12),
        transition: `background ${easing.easeOut.fast}`,
        background: `url(${sevennewsPlayArrow})`,
        backgroundRepeat: 'no-repeat',
        width: isSmall ? 32 : 60,
        height: isSmall ? calcRem(14) : calcRem(29),
        backgroundSize: isSmall
            ? `${calcRem(12)} ${calcRem(14)}`
            : `${calcRem(25)} ${calcRem(29)}`,
    }
}
const getNonCenteredPlayBackgroundStyles = (isSmall: boolean) => {
    return {
        top: 'auto',
        bottom: calcRem(16),
        left: calcRem(16),

        [breakpoint('sm')]: {
            bottom: calcRem(20),
            left: calcRem(23),
            width: calcRem(64),
            height: calcRem(64),
        },

        '&::before': {
            [breakpoint('sm')]: {
                left: isSmall ? 12 : 27,
            },
        },
    }
}
interface LeftSize {
    left: number
}

type BorderWidthAndLeftSize = {
    borderWidth?: string
} & LeftSize

interface BreakpointSizeIcon {
    sm: BorderWidthAndLeftSize
    default: BorderWidthAndLeftSize
}

interface BreakpointSize {
    normal: {
        sm: number
        default: number
    }
    small: number
}
interface PlayButtonSize {
    container: BreakpointSize
    iconCentered: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
        withBorder?: BorderWidthAndLeftSize
    }
    icon: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
    }
}
const sizes: PlayButtonSize = {
    container: {
        normal: { sm: 70, default: 60 },
        small: 32,
    },
    iconCentered: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 12 },
        withBorder: { left: 10 },
    },
    icon: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 5 },
    },
}

export const StyledPlayElementStyles: Interpolation<PlayIconProps>[] = [
    () => ({
        position: 'absolute',
        zIndex: zIndex.common.videoPlayButton,
        margin: 0,
        padding: 0,
        width: calcRem(44),
        height: calcRem(44),
        borderRadius: '100%',
        fontSize: 0,
        lineHeight: 1,
        transition: `opacity ${easing.easeOut.med}, background-color ${easing.easeOut.fast}`,
        cursor: 'pointer',
        '&::before': {
            position: 'absolute',
            top: '50%',
            display: 'block',
            content: `''`,
            transform: 'translateY(-50%)',
        },

        '& > span': {
            display: 'hidden',
        },
    }),
    ({ isSmall }) => ({
        backgroundColor: hexToRGBA(colors.black, 0.05),
        opacity: 1,
        backdropFilter: 'blur(13.5914px)',

        '&::before': getPlayIconStyles(isSmall),
    }),
    ({ isCentered, isSmall }) => {
        if (!isCentered) {
            return getNonCenteredPlayBackgroundStyles(isSmall || false)
        }

        const defaultSize = isSmall
            ? sizes.container.small
            : sizes.container.normal.default
        const defaultMargin = defaultSize / 2

        const smSize = isSmall
            ? sizes.container.small
            : sizes.container.normal.sm
        const smMargin = smSize / 2

        const beforeSizing = isSmall
            ? {
                  left: sizes.iconCentered.small.left,
              }
            : {
                  left: calcRem(sizes.iconCentered.normal.default.left),

                  [breakpoint('sm')]: {
                      left: sizes.iconCentered.normal.sm.left,
                  },
              }

        return {
            top: '50%',
            left: '50%',
            marginLeft: `-${calcRem(defaultMargin)}`,
            marginTop: `-${calcRem(defaultMargin)}`,
            width: calcRem(defaultSize),
            height: calcRem(defaultSize),

            [breakpoint('sm')]: {
                top: '50%',
                left: '50%',
                marginLeft: `-${calcRem(smMargin)}`,
                marginTop: `-${calcRem(smMargin)}`,
                width: calcRem(smSize),
                height: calcRem(smSize),
            },

            '&::before': beforeSizing,
        }
    },
]
export const StyledPlayIcon = withClass(playButtonIconClass)(
    styled('div')<PlayIconProps>(...StyledPlayElementStyles),
)
