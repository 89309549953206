import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { ResolveSlugV4DTO } from '@west-australian-newspapers/publication-types'
import { RouteInformation, RouteServices } from '../routing/page-definition'
import {
    getRegisteredRoutes,
    ServerResolution,
} from '../routing/route-resolver'
import { applyRedirects } from './apply-redirects'

export function getRouteForResolution(
    routeServices: RouteServices,
): RouteInformation<string> {
    // There are multiple redirects which may happen, rather than trying
    // to do in one shot we can just apply different redirects as we go
    let routeInfo: RouteInformation<string>
    // Error route info's should always be a 302 just in-case
    let isErrorRouteInfo = false

    if (routeServices.resolution.type === 'error') {
        if (routeServices.resolution.error.errorType === '404') {
            const { notFoundRouteInfo } = getRegisteredRoutes()
            routeInfo = notFoundRouteInfo(routeServices)
        } else {
            const { errorRouteInfo } = getRegisteredRoutes()
            routeInfo = errorRouteInfo(routeServices)
        }
        isErrorRouteInfo = true
    } else if (routeServices.resolution.type === 'static') {
        const { staticRoutes } = getRegisteredRoutes()
        const staticRouteInfo =
            staticRoutes[routeServices.resolution.key](routeServices)

        if (staticRouteInfo === null) {
            const { notFoundRouteInfo } = getRegisteredRoutes()
            routeInfo = notFoundRouteInfo(routeServices)
            isErrorRouteInfo = true
        } else {
            routeInfo = staticRouteInfo
        }
    } else if (routeServices.resolution.type === 'match') {
        const { matchRoutes } = getRegisteredRoutes()
        const matchRouteInfo =
            matchRoutes[routeServices.resolution.key](routeServices)

        if (matchRouteInfo === null) {
            const { notFoundRouteInfo } = getRegisteredRoutes()
            routeInfo = notFoundRouteInfo(routeServices)
            isErrorRouteInfo = true
        } else {
            routeInfo = matchRouteInfo
        }
    } else if (routeServices.resolution.type === 'server') {
        const serverRouteInfo = getServerRouteInfo(routeServices as any)
        if (serverRouteInfo === null) {
            const { notFoundRouteInfo } = getRegisteredRoutes()
            routeInfo = notFoundRouteInfo(routeServices)
            isErrorRouteInfo = true
        } else {
            routeInfo = serverRouteInfo
        }
    } else if (routeServices.resolution.type === 'loading') {
        routeInfo = {
            kind: 'loading',
            section: routeServices.resolution.section, // Section required for loading to avoid jank
        }
    } else {
        routeServices.log.error(
            { resolutiom: routeServices.resolution },
            'Cannot resolve route for resolution',
        )
        const { errorRouteInfo } = getRegisteredRoutes()
        routeInfo = errorRouteInfo(routeServices)
        isErrorRouteInfo = true
    }

    routeInfo = applyRedirects(routeServices, routeInfo, isErrorRouteInfo)
    return routeInfo
}

/** Raw route resolution without additional section redirection logic */
function getServerRouteInfo(
    routeServices: RouteServices<ServerResolution<ResolveSlugV4DTO>>,
): RouteInformation<string> | null {
    if (routeServices.resolution.resolution.type === 'publication') {
        const publication = routeServices.resolution.resolution.publication
        // If we don't have a hostname, it was a programatic call, let's just redirect them
        if (!routeServices.hostname) {
            routeServices.log.info(
                'Redirecting publication due to missing hostname header',
            )

            return {
                kind: 'redirect',
                redirectTo: {
                    targetUrl: publication._self,
                    httpStatusCode: 302,
                },
            }
        }

        const _selfPathParts = publication._self.split('/')
        const _selfPath = `/${_selfPathParts
            .splice(3, _selfPathParts.length)
            .join('/')}`

        const isAmp = routeServices.location.pathname.includes('.amp')

        const path = stripUndesirableExtensions(
            getPath(routeServices.location.pathname),
        )

        if (
            routeServices.location.search.indexOf(
                'disable_path_mismatch_redirect',
            ) === -1 &&
            _selfPath !== path
        ) {
            // For client side, we need to redirect to a relative URL (requirement for React Router)
            // on server side we want to redirect to an absolute URL
            const isClientSideRender = typeof window !== 'undefined'

            const redirectTo =
                (isClientSideRender
                    ? `/${publication.topics.primary.id}/${publication.slug}`
                    : _selfPath) + (isAmp ? '.amp' : '')

            // I mean, the easiest way to fix this would just be to append .amp here..., but is that what we really want to do?
            // 1. Why was Jake stripping .amp?
            // 2. Is this really the best place to do this??

            routeServices.log.info(
                { expectedPath: _selfPath, path, redirectTo },
                'Redirecting publication due to path mismatch',
            )

            const is7NewsRedirectLogicEnabled = isFeatureEnabled(
                toFeatureState(routeServices.store.getState().toggles),
                '7-news-redirect-logic',
            )

            const httpStatusCode =
                routeServices.config.apiCallerHeader === 'sevennews' &&
                is7NewsRedirectLogicEnabled
                    ? 301
                    : 302

            return {
                kind: 'redirect',
                redirectTo: {
                    targetUrl: redirectTo,
                    httpStatusCode,
                },
            }
        }
    }

    if (routeServices.resolution.resolution.type === 'video') {
        const video = routeServices.resolution.resolution.videoMeta
        // If we don't have a hostname, it was a programatic call, let's just redirect them
        if (!routeServices.hostname) {
            routeServices.log.info(
                'Redirecting publication due to missing hostname header',
            )

            return {
                kind: 'redirect',
                redirectTo: {
                    targetUrl: routeServices.resolution.resolution._self,
                    httpStatusCode: 302,
                },
            }
        }

        const _selfPathParts =
            routeServices.resolution.resolution._self.split('/')
        const _selfPath = `/${_selfPathParts
            .splice(3, _selfPathParts.length)
            .join('/')}`

        const path = stripUndesirableExtensions(
            getPath(routeServices.location.pathname),
        )

        if (
            routeServices.location.search.indexOf(
                'disable_path_mismatch_redirect',
            ) === -1 &&
            _selfPath !== path
        ) {
            // For client side, we need to redirect to a relative URL (requirement for React Router)
            // on server side we want to redirect to an absolute URL
            const isClientSideRender = typeof window !== 'undefined'

            const redirectTo = isClientSideRender
                ? `/video/${video.secondaryTopics[0]}/${routeServices.resolution.resolution.slug}`
                : _selfPath

            routeServices.log.info(
                { expectedPath: _selfPath, path, redirectTo },
                'Redirecting publication due to path mismatch',
            )

            return {
                kind: 'redirect',
                redirectTo: {
                    targetUrl: redirectTo,
                    httpStatusCode: 302,
                },
            }
        }
    }

    if (routeServices.resolution.resolution.type === 'redirect') {
        return {
            kind: 'redirect',
            redirectTo: routeServices.resolution.resolution.redirectTo,
        }
    }

    const { serverRoutes } = getRegisteredRoutes()
    const serverRoute = serverRoutes[routeServices.resolution.resolution.type]
    if (!serverRoute) {
        throw new Error(
            `Route resolution not setup for server route ${routeServices.resolution.resolution.type}`,
        )
    }
    // I Can't get type narrowing to work here currently
    const serverRouteInfo = serverRoute(routeServices as any)

    return serverRouteInfo
}

function getPath(url: string) {
    const noQueryPath = decodeURIComponent(url).split('?')[0].trim()
    const noTrailingSlashPath =
        noQueryPath.charAt(noQueryPath.length - 1) === '/'
            ? noQueryPath.slice(0, -1)
            : noQueryPath

    return noTrailingSlashPath
}

export const stripUndesirableExtensions = (url: string) =>
    url.replace('.amp', '')
