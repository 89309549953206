import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { TheNightlyTheme, metrics } from '../../../../__styling'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { StyledWardenButton } from '../..'

export const StyledAccountModifyHeading = styled.p(({ theme }) => [
    {
        textAlign: 'center',
        margin: 0,
    },
    textMixin(theme as TheNightlyTheme, 'subheading'),
])

export const StyledAccountModifyBodyText = styled.p<{
    marginBottom?: number
    marginTop?: number
}>(({ theme }) => [
    {
        margin: 0,
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    textMixin(theme as TheNightlyTheme, 'body-alt'),
])

export const StyledAccountModifyWardenButton = styled(StyledWardenButton)({
    margin: `0 auto`,
})

export const StyledAccountModifyTextLink = styled.a({
    textDecoration: 'underline',
    fontSize: metrics.thenightly.baseFontSize,
    lineHeight: 1.5,
    fontStyle: 'normal',
    fontWeight: 400,
    paddingLeft: calcRem(8),
    '&:hover': {
        cursor: 'pointer',
    },
})

export const StyledAccountModifyTextWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',

    gap: calcRem(8),
})
