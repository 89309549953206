import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import React from 'react'
import { calcRem } from '../../__styling'

const StyledContainer = styled('div')({
    marginBottom: calcRem(16),
})

const StyledHeading = styled('h2')({
    fontSize: calcRem(18),
    position: 'relative',

    ['&::after']: {
        background: tokens.thewest.colors.palette.queensLegacy.maroonLight,
        content: "''",
        position: 'absolute',
        width: 40,
        height: 4,
        bottom: '-8px',
        left: 0,
    },
})

const StyledButton = styled(WebLink)(
    {
        textTransform: 'uppercase',
        fontSize: calcRem(12),
        borderRadius: '2px',
        borderWidth: 1,
        borderStyle: 'solid',
        fontWeight: 700,
        transition: 'background 250ms ease-out',
        textDecoration: 'none',
        flexShrink: 0,
        display: 'inline-block',
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.sansSerif,
        padding: calcRem(theme.margins.sm),
        margin: `${calcRem(theme.margins.sm)} 0`,
        color: theme.colors.actions.button.primary.text.default,
        backgroundColor: theme.colors.actions.button.primary.background.default,
        border: `1px solid ${theme.colors.actions.button.primary.border.default}`,

        ['&:hover, &:focus']: {
            color: theme.colors.actions.button.primary.text.hover,
            backgroundColor:
                theme.colors.actions.button.primary.background.hover,
            border: `1px solid ${theme.colors.actions.button.primary.border.default}`,
        },

        ['&:active']: {
            color: theme.colors.actions.button.primary.text.active,
            backgroundColor:
                theme.colors.actions.button.primary.background.active,
            border: `1px solid ${theme.colors.actions.button.primary.border.active}`,
        },
    }),
)

export const QueenElizabethTributes: React.FC = () => {
    return (
        <StyledContainer>
            <StyledHeading>Reader Tributes</StyledHeading>
            <p>
                We invite our readers to join us in remembering the life and
                reign of Queen Elizabeth II. Submit your tribute below for
                review by our team.
            </p>
            <StyledButton to="/sendtribute">Submit your tribute</StyledButton>
        </StyledContainer>
    )
}
