/* eslint-disable @typescript-eslint/no-var-requires */
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { StaticRoute, StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/Landing_page_Banner_Desktop@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/Landing_page_Banner_Desktop.jpg')

const bannerTabletX2 = require<string>('./assets/Landing_page_Banner_Tablet@2x.jpg')

const bannerTabletX1 = require<string>('./assets/Landing_page_Banner_Tablet.jpg')

const bannerMobileX2 = require<string>('./assets/Landing_page_Banner_Mobile@2x.jpg')

const bannerMobileX1 = require<string>('./assets/Landing_page_Banner_Mobile.jpg')

const socialImage = require('./assets/social.jpg')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '628',
    },
    {
        property: 'og:image:width',
        content: '1200',
    },
]

const topic: Topic = {
    id: 'news/the-west-tonight',
    metadata: {},
    seoTitle: `The West Tonight - With Ben Harvey`,
    title: `The West Tonight`,
    seoDescription:
        "Streaming nightly to thewest.com.au, The West Tonight with Ben Harvey delivers off-beat and bold commentary on news, politics and current events. Irreverent and shrewd, The West Tonight will provoke and entertain you in ways you don't expect. You didn't ask, but ... you'll want to watch. ",
}

const westTonightRouteInfo: StaticRoute<TheWestSection> = ({
    getAdTargeting,
    config,
    store,
}) => {
    const enableRoute = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'route-west-tonight',
    )

    if (!enableRoute) {
        return null
    }

    const canonicalUrl = config.publicUrl + '/news/the-west-tonight'

    return {
        kind: 'page',
        heading: topic.title,
        pageType: 'topic',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: topic.title,
            description: topic.seoDescription,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            meta: getSocialImageMeta,
        },
        socialMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
        },
        adTargeting: getAdTargeting('home', 'default', topic.id),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: bannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText: 'The West Tonight - With Ben Harvey',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: bannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerDesktopX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: bannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerTabletX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: bannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerMobileX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'janus',
                            props: {
                                layoutRatio: '3:1',
                                stretchColumns: true,
                                verticalSpacing: 'xl',
                            },
                            contentAreas: {
                                left: [
                                    layout.component({
                                        type: 'sierra',
                                        props: {
                                            fixedRatios: ['16:9'],
                                            heroImageLayout:
                                                ResponsivePictureLayout.ObjectFitContain,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                                maxBreakpoint: 'md',
                                            },
                                            cardLayout: ['hero-video'],
                                            sectionHeader: {
                                                heading: 'Latest Episode',
                                            },
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'news/the-west-tonight',
                                                ],
                                                paging: {
                                                    page: 1,
                                                    pageSize: 1,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                right: [
                                    layout.component({
                                        type: 'tw-subscribe-promo-card',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                sectionHeader: {
                                    heading: 'Watch the episodes',
                                },

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['news/the-west-tonight'],
                                    paging: {
                                        pageSize: 4,
                                        page: 1,
                                        offset: 1,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'md',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['news/the-west-tonight'],
                                    paging: {
                                        pageSize: 4,
                                        page: 2,
                                        offset: 0,
                                    },
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const getWestTonightRouteInfo: StaticRoutes<TheWestSection> = {
    '/news/the-west-tonight': westTonightRouteInfo,
}
