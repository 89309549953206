import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Image } from '../../content/Picture/Image.web'
import { colors } from '../../__styling/settings/colors'
import {
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords, transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const StyledPaperPromoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: themedValue(theme, {
        thewest: calcRem(13),
        fallback: calcRem(14),
    }),
    ...breakWords,

    [breakpointMax('sm')]: {
        margin: calcRem(theme.margins.sm, 0),
    },

    [breakpointBetween('sm', 'md')]: {
        display: 'none',
    },
}))

export const StyledLink = styled(WebLink)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexGrow: 1,
    alignItems: 'center',
    width: '100%',
    background: `${colors.thewest.greyMouse} linear-gradient(10deg, ${colors.thewest.westblue} 0%, ${colors.thewest.westblue} 42%, ${colors.thewest.greyMouse} 42.1%, ${colors.thewest.greyMouse} 100%);`,
    boxShadow: `${colors.black20} 0 0 8px 0`,
    color: themedValue(theme, {
        perthnow: colors.white,
        fallback: colors.black,
    }),
    textDecoration: 'none',

    '&: focus': {
        [`& ${StyledCTA} span`]: {
            backgroundColor: colors.thewest.greyKoala,
        },
    },

    '&:hover': {
        outline: 'none',

        [`& ${StyledCTA} span`]: {
            backgroundColor: colors.black,
        },
    },
}))

export const StyledImageFrame = styled('div')({
    position: 'relative',
    width: '74%',
    maxWidth: 320,
    marginTop: calcRem(16),
    overflow: 'hidden',
})

export const StyledNewspaperImage = styled(Image)({
    display: 'none',
    width: '100%',
    height: 'auto',

    [`@supports (mix-blend-mode: multiply)`]: {
        display: 'block',
    },
})

export const StyledImage = styled(Image)(({ theme }) => ({
    width: '100%',
    boxShadow: themedValue(theme, {
        thewest: `0 0 8px 0 ${colors.black20}`,
        fallback: undefined,
    }),

    [`@supports (mix-blend-mode: multiply)`]: {
        position: 'absolute',
        top: 16,
        left: 16,
        width: `calc(100% - 35px)`,
        mixBlendMode: 'multiply',
        boxShadow: 'unset',
    },
}))

export const StyledFallbackImage = styled(Image)({
    width: '70%',
    height: 'auto',
})

export const StyledCTA = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: calcRem(theme.margins.lg),
    marginTop: calcRem(-56),
    backgroundColor: colors.white,
    borderTop: `1px solid ${colors.thewest.westblue}`,
    color: colors.thewest.westblue,
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    zIndex: 1,

    '& span': {
        background: colors.thewest.westblue,
        color: colors.white,
        borderRadius: 20,
        padding: calcRem(4, 20, 6),
        boxShadow: `0 6px 13px 0 rgba(0,29,86,0.60)`,
        ...transition({ properties: ['background', 'color'] }),
    },

    '&::before': {
        position: 'absolute',
        display: 'block',
        top: calcRem(-16),
        left: '50%',
        transform: 'translateX(-50%)',
        padding: themedValue(theme, {
            thewest: calcRem(5, 10),
            fallback: undefined,
        }),
        marginBottom: themedValue(theme, {
            thewest: calcRem(theme.margins.md),
            fallback: undefined,
        }),
        backgroundColor: colors.thewest.westblue,
        color: themedValue(theme, {
            thewest: colors.white,
            fallback: undefined,
        }),
        content: `"Today's Paper"`,
        fontSize: themedValue(theme, {
            thewest: calcRem(11),
            fallback: calcRem(12),
        }),
        fontWeight: themedValue(theme, {
            perthnow: 600,
            thewest: 600,
            sevennews: 700,
            fallback: undefined,
        }),
        textTransform: themedValue(theme, {
            perthnow: undefined,
            fallback: 'uppercase',
        }),
    },
}))
