import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../..'

export const StyledLiveEventTimeline = styled('div')(({ theme }) => [
    // Common values used between different themes
    {
        overflow: 'auto',
        minHeight: '100vh',
        marginLeft: theme.margins.md * -1,
        marginRight: theme.margins.md * -1,
        background: colors.thewest.greySeal,

        '& > div:first-of-type': {
            marginTop: theme.margins.md,
        },

        [breakpoint('md')]: {
            '& > div:first-of-type': {
                marginTop: undefined,
            },
        },
    },
    // Styling for PN & TW
    theme.kind === 'perthnow' ||
        (theme.kind === 'thewest' && {
            [breakpoint('md')]: {
                '& > div:first-of-type': {
                    marginTop: 0,
                },
            },
        }),
    // Styling for 7N
    theme.kind === 'sevennews' && {
        background: theme.colors.palette.white,

        [breakpoint('md')]: {
            '& > div:first-of-type': {
                marginTop: theme.margins.md,
            },
        },
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        background: theme.colors.palette.white,
        marginLeft: 0,
        marginRight: 0,

        '& > div:first-of-type': {
            marginTop: calcRem(24),
        },
        '& > div:last-of-type': {
            borderBottom: 'none',
        },
    },
])
