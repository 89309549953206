import styled from '@emotion/styled'
import { breakpoint, calcRem, colors, themedValue } from '../../__styling'
import { OlympicsWidgetSize } from './OlympicsWidgetWrap'

interface OlympicWidgetWrapStyleProps {
    size: OlympicsWidgetSize
}

export const StyledOlympicWidgetWrap = styled(
    'div',
)<OlympicWidgetWrapStyleProps>(({ theme, size }) => [
    {
        fontFamily: 'Guardian Sans Web',
        height: 'fit-content',
        width: '100%',
        padding: themedValue(theme, {
            thenightly: size === 'small' ? calcRem(10) : calcRem(10),
            perthnow:
                size === 'embed' ? undefined : `${calcRem(10)} !important`, // Padding is being overwritten unless !important is added
            fallback: size === 'embed' ? undefined : calcRem(10),
        }),
        marginBottom: themedValue(theme, {
            thenightly:
                size === 'small' || size === 'in-article' ? calcRem(24) : 0,
            thewest: size === 'in-article' ? calcRem(24) : 0,
            perthnow: size === 'in-article' ? calcRem(24) : 0,
            fallback: undefined,
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        a: {
            textDecoration: 'none',
        },
        '.medalTally__link-row': {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        background:
            size === 'in-article' ? colors.olympics.background : undefined,

        [breakpoint('md')]: {
            padding: themedValue(theme, {
                thenightly:
                    size === 'embed'
                        ? 0
                        : size === 'full'
                        ? calcRem(20)
                        : calcRem(10, 0),
                fallback:
                    size === 'embed'
                        ? 0
                        : size === 'full'
                        ? calcRem(20)
                        : calcRem(10),
            }),
        },
    },
])

export const StyledWidgetRow = styled('section')<OlympicWidgetWrapStyleProps>(
    ({ size }) => ({
        margin: size === 'embed' ? calcRem(0, 0, 12, 0) : calcRem(10, 0),
        height:
            size === 'embed'
                ? 'fit-content'
                : size === 'full'
                ? calcRem(475)
                : calcRem(200),

        [breakpoint('sm')]: {
            margin: size === 'embed' ? calcRem(4, 0, 16, 0) : calcRem(10, 0),
            height:
                size === 'embed'
                    ? 'fit-content'
                    : size === 'full'
                    ? calcRem(850)
                    : calcRem(375),
        },
    }),
)

export const StyledWidgetContainer = styled('div')<OlympicWidgetWrapStyleProps>(
    ({ theme, size }) => ({
        height: size === 'embed' ? 'fit-content' : '100%',
        width: '100%',
        border: 'none',
        borderRadius: 5,
        background: 'white',
        overflowY: 'scroll',

        table: {
            width: '100%',
            position: 'relative',
            clear: 'both',
            borderCollapse: 'collapse',
            color: '#000',
            fontFamily: theme.fonts.sansSerif,
        },
        tbody: {
            lineHeight: '14px',
            fontSize: '14px',

            '.odd': {
                backgroundColor: '#fafafa',
            },
            '.even': {
                backgroundColor: '#ffffff',
            },
            textAlign: 'center',
        },
        th: {
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
            fontSize: '14px',
        },
        tr: {
            borderBottom: '1px solid #d6d6d6',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        '.hs-widget-head': {
            display: 'none',
        },
        '.hs-widget-foot': {
            display: 'none',
        },
        '.module-ranking': {
            width: '100%',
            tableLayout: 'auto',
        },
        '.country-name': {
            textAlign: 'left',
        },
        '.hs-widget-body': {
            display: 'flex',
            overflow: 'scroll',
            justifyContent: 'stretch',
        },
        '.country-flag': {
            display: 'none',

            img: {
                width: size === 'embed' ? '50%' : 'auto',
            },
        },
        [breakpoint('sm')]: {
            '.country-flag': {
                display: size === 'embed' ? 'none' : 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'end',

                img: {
                    width: 'auto',
                    height: '30px',
                    padding: '0 2px',
                },
            },
        },
        [breakpoint('md')]: {
            '.country-flag': {
                display: 'table-cell',
            },
        },
    }),
)
