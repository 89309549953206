import { LinkClickedEvent } from '@news-mono/web-common'
import { createRenderTarget } from '../../render-target'
import { RssTextLink } from '../../typography/TextLink/TextLink.rss'
import { WebTextLink } from '../../typography/TextLink/TextLink.web'

export interface TextLinkProps {
    href: string
    className?: string
    dataLinkType?: string
    openInNewWindow?: boolean
    isNoFollow?: boolean

    // HTMl Microdata
    itemScope?: boolean
    itemType?: string

    type?: 'default' | 'button-ghost'

    onEvent?: (event: LinkClickedEvent) => void
}

export const TextLink = createRenderTarget<TextLinkProps>('TextLink', {
    amp: WebTextLink,
    web: WebTextLink,
    rss: RssTextLink,
    preview: WebTextLink,
    app: WebTextLink,
})
