import styled from '@emotion/styled'
import { getCommonLinkStyles } from '../../typography/TextLink/TextLink.styled'

export const StyledLinkButton = styled('button')(({ theme }) => ({
    ...getCommonLinkStyles(theme),
    padding: 0,
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'transparent',
}))
