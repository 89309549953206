import styled from '@emotion/styled'
import { IconClock } from '../../../icons'
import { calcRem } from '../../../__styling'

const clockColor = '#535556'

export const StyledLabelWrapper = styled('div')(({ theme }) => ({
    background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
    paddingBottom: '2px',
    fontFamily: theme.fonts.sansSerif,
}))
export const StyledLabelContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.palette.white,
    paddingBottom: calcRem(6),
}))
export const StyledLabelHeadingMain = styled('span')(({ theme }) => ({
    fontWeight: 700,
    marginRight: theme.margins.xs,
}))

export const StyledLabelTimeContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}))

export const UpdatedClockIcon = styled(IconClock)({
    width: 15,
    height: 15,
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    marginRight: calcRem(4),
    stroke: clockColor,
})
