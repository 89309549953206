import { RenderTargetContext } from '@news-mono/web-common'
import React, { useContext } from 'react'
import { useOnAirState } from '../../content/TheWestLive/TheWestLiveProvider'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'
import { SearchAction } from '../SearchAction/SearchAction'
import { AuthSiteLoginActionLinks } from '../TheWestHeaderLogin/TheWestAuthSiteLogin'
import { HomeAction } from './HomeAction'
import { MenuAction, MenuActionProps } from './MenuAction'
import {
    StyledActionGroup,
    // StyledActionsContainer,
    StyledActionsContent,
    StyledWestHeaderItem,
} from './TheWestHeaderActions.styled'
import { WestLiveAction } from './WestLiveAction'

export interface TheWestHeaderActionsWebProps extends MenuActionProps {
    onEvent: (event: any) => void
    hideBurger?: Boolean
}

export const TheWestActionsStructure: React.FC<{
    left: React.ReactElement
    right: React.ReactElement | false
}> = ({ left, right }) => {
    return (
        <StyledActionsContent>
            {left}
            {<StyledActionGroup>{right && right}</StyledActionGroup>}
        </StyledActionsContent>
    )
}

export const TheWestHeaderActionsWeb: React.FC<TheWestHeaderActionsWebProps> =
    ({ onEvent, ...menuActionProps }) => {
        const renderTarget = useContext(RenderTargetContext)
        const { onAir } = useOnAirState()

        return (
            <TheWestActionsStructure
                left={
                    <StyledWestHeaderItem>
                        <FeatureToggle
                            feature="the-west-live"
                            on={() => {
                                return (
                                    <React.Fragment>
                                        {renderTarget.renderTarget !==
                                            'static-error-page' && (
                                            <MenuAction {...menuActionProps} />
                                        )}
                                        <WestLiveAction showEqualizer={onAir} />
                                    </React.Fragment>
                                )
                            }}
                            off={() => {
                                return (
                                    <React.Fragment>
                                        {renderTarget.renderTarget !==
                                            'static-error-page' && (
                                            <MenuAction {...menuActionProps} />
                                        )}
                                        <HomeAction />
                                    </React.Fragment>
                                )
                            }}
                        />
                    </StyledWestHeaderItem>
                }
                right={
                    renderTarget.renderTarget !== 'static-error-page' && (
                        <React.Fragment>
                            <FeatureToggle
                                feature={'search'}
                                on={() => <SearchAction />}
                            />
                            <AuthSiteLoginActionLinks onEvent={onEvent} />
                        </React.Fragment>
                    )
                }
            />
        )
    }
TheWestHeaderActionsWeb.displayName = 'TheWestHeaderActionsWeb'
