import {
    DEFAULT_DATE_FORMAT,
    GetReadableTimePredicateHierarchy,
} from './constants'

interface GetReadableTimeSinceProps {
    publicationDate: string | number | Date
    comparisonDate: string | number | Date
    hierarchy?: keyof typeof GetReadableTimePredicateHierarchy
    dateFormat?: string
}

export const getReadableTimeSince = ({
    publicationDate,
    comparisonDate,
    hierarchy = 'default',
    dateFormat = DEFAULT_DATE_FORMAT,
}: GetReadableTimeSinceProps): string => {
    const publicationDateInternal = new Date(publicationDate)
    const comparisonDateInternal = new Date(comparisonDate)

    const readableTimeHierarchy = GetReadableTimePredicateHierarchy[hierarchy]

    for (const readableTimeFunction of readableTimeHierarchy) {
        const readableTime = readableTimeFunction(
            publicationDateInternal,
            comparisonDateInternal,
            dateFormat,
        )

        if (readableTime.predicate) {
            return readableTime.toString
        }
    }

    return ''
}
