import {
    CollectionDataLoaded,
    CollectionEvent,
    SearchEvent,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { Lima, LimaLayouts } from '../../collections/Lima/Lima'
import { Box } from '../../compositions/Box/Box'
import { SearchForm } from '../SearchForm/SearchForm'
import {
    StyledArticleMessage,
    StyledHeading,
    StyledMessage,
    StyledSearchResults,
    StyledSearchTerm,
} from './SearchResults.styled'
import { useTheme } from '@emotion/react'
import { Pagination } from '../../data-controllers/Pagination/Pagination'
import { Paging } from '../../data-controllers/Pagination/usePagination'
import { useQueryParams } from '../../../../web-common/src/hooks/useQueryParams'
import { SearchFiltersAccordion } from '../TNSearchFilters/SearchFiltersAccordion/SearchFiltersAccordion'
import { FilterEvent } from '../../result-filters/filter-events-util'

export interface SearchResultsProps {
    results: CollectionDataLoaded
    searchTerm: string
    paging: Paging
    setPage: (page: number) => void
    onEvent: (event: CollectionEvent | SearchEvent | FilterEvent) => void
}

export const SearchResults = ({
    results,
    searchTerm,
    paging,
    setPage,
    onEvent,
}: SearchResultsProps) => {
    const theme = useTheme()
    const { queryParams } = useQueryParams()

    const hasSearchResults =
        results.publications.length >= 1 && results.kind === 'listing'

    const hasPlaceholder = theme.kind === 'thenightly'

    const displayPublicationDate = theme.kind === 'thenightly'
    const cardLayout: LimaLayouts | undefined =
        theme.kind === 'thenightly'
            ? undefined
            : [
                  [
                      {
                          type: 'landscape',
                          format: 'landscape-full-timestamp',
                      },
                  ],
              ]

    const usePagination = useFeature('search-pagination') && paging
    const showSearchFilters =
        useFeature('show-search-filter') && theme.kind === 'thenightly'

    // Removing the page param as it affects navigation when beyond the first page of pagination
    const pageParam = queryParams.get('page')
    if (pageParam) {
        queryParams.delete('page')
    }

    if (hasSearchResults) {
        const totalResults = usePagination
            ? results.total
            : results.publications.length

        return (
            <Box verticalSpacing="lg" hasTopSpacing={true}>
                {theme.kind === 'thenightly' && (
                    <>
                        <StyledArticleMessage>
                            {totalResults} results for{" '"}
                            {searchTerm}
                            {"'."}
                        </StyledArticleMessage>
                    </>
                )}

                <SearchForm
                    hasPlaceholder={hasPlaceholder}
                    mountsWithValue={searchTerm}
                    isLarge={true}
                    onEvent={onEvent}
                    callLocation="Search Page"
                />

                {showSearchFilters && (
                    <SearchFiltersAccordion onEvent={onEvent} />
                )}

                <StyledSearchResults>
                    {theme.kind !== 'thenightly' && (
                        <StyledMessage>
                            Results for{' '}
                            <StyledSearchTerm>{searchTerm}</StyledSearchTerm>
                        </StyledMessage>
                    )}

                    <Lima
                        displayPublicationDate={displayPublicationDate}
                        verticalSpacing="md"
                        fixedRatios={['16:9']}
                        expectedCards={results.publications.length}
                        items={{
                            loaded: true,
                            result: results.publications,
                        }}
                        intermediateColumns={1}
                        initialColumns={1}
                        finalColumns={1}
                        fontScale={0.88}
                        cardLayout={cardLayout}
                        remainingCardLayout={{
                            type: 'landscape',
                            format: 'landscape-full-timestamp',
                        }}
                        containerWidthRatios={{
                            mobile: 1,
                            tablet: 1,
                            desktop: 1,
                        }}
                        onEvent={onEvent}
                    />
                </StyledSearchResults>

                {usePagination && (
                    <Pagination
                        setPage={setPage}
                        itemCount={results.total ?? 0}
                        maxPages={
                            results.total
                                ? Math.ceil(results.total / paging.pageSize)
                                : 0
                        }
                        paging={paging}
                        params={queryParams.toString()}
                        onEvent={onEvent}
                    />
                )}
            </Box>
        )
    }

    if (searchTerm) {
        const StyleMessageWrapper =
            theme.kind === 'thenightly' ? StyledArticleMessage : StyledMessage

        return (
            <Box verticalSpacing="lg" hasTopSpacing={true}>
                <SearchForm
                    hasPlaceholder={hasPlaceholder}
                    mountsWithValue={searchTerm}
                    isLarge={true}
                    onEvent={onEvent}
                    callLocation="Search Page"
                />

                {showSearchFilters && (
                    <SearchFiltersAccordion onEvent={onEvent} />
                )}

                <StyleMessageWrapper>
                    Sorry, we couldn't find any search results for
                    <StyledSearchTerm> {searchTerm}</StyledSearchTerm>
                </StyleMessageWrapper>
            </Box>
        )
    } else {
        const StyleHeadingWrapper =
            theme.kind === 'thenightly' ? StyledArticleMessage : StyledHeading

        return (
            <Box verticalSpacing="lg" hasTopSpacing={true}>
                <StyleHeadingWrapper>Search</StyleHeadingWrapper>
                <SearchForm
                    hasPlaceholder={hasPlaceholder}
                    mountsWithValue={searchTerm}
                    isLarge={true}
                    onEvent={onEvent}
                    callLocation="Search Page"
                />
                {showSearchFilters && (
                    <SearchFiltersAccordion onEvent={onEvent} />
                )}
            </Box>
        )
    }
}
SearchResults.displayName = 'SearchResults'
