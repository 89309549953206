import { amphtml } from '../../google-amp/react_amp'

export interface AmpImgProps {
    width: number | string
    height: number
    src: string
    alt?: string
    srcSet?: string
    layout?: 'fill' | 'responsive' | 'fixed'
    placeholder?: boolean
    sizes?: string
}

export const AmpImg = amphtml<AmpImgProps>('img')
