import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { JanusLayoutRatio } from './Janus'

/* eslint-disable no-redeclare */
function layoutRatioToValue(ratio: JanusLayoutRatio): [string, string]
function layoutRatioToValue(ratio: JanusLayoutRatio): any {
    switch (ratio) {
        case '3:1':
            return '3fr 1fr'
        case '1:3':
            return '1fr 3fr'
        case '2:3':
            return '2fr 3fr'
        default:
            return 'repeat(2, 1fr)'
    }
}

export interface JanusBreakpointOptions {
    // Used to specify the layout ratios breakpoint for thewest
    layoutRatiosBreakpoint: 'xs' | 'md'
}

export interface StyledJanusProps {
    hasBackgroundFill?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    layoutRatio?: JanusLayoutRatio
    breakpoints?: JanusBreakpointOptions
    hasBorder?: boolean
}

export interface StyledGridItemProps {
    hasSidebar?: boolean
}

export const StyledJanus = styled('div')<StyledJanusProps>(
    ({
        theme,
        verticalSpacing,
        hasBackgroundFill,
        layoutRatio = '1:1',
        breakpoints,
        hasBorder,
    }) => ({
        margin: '0 auto',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridGap: calcRem(theme.margins.gridGap),
        backgroundColor: hasBackgroundFill ? colors.white : undefined,
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),

        [breakpoint('xs')]: {
            gridTemplateColumns: themedValue(theme, {
                thewest: undefined,
                fallback: layoutRatioToValue(layoutRatio),
            }),
        },

        [breakpoint(
            (breakpoints && breakpoints.layoutRatiosBreakpoint) || 'md',
        )]: {
            gridTemplateColumns: themedValue(theme, {
                thewest: layoutRatioToValue(layoutRatio),
                fallback: undefined,
            }),
            borderBottom: hasBorder
                ? themedValue(theme, {
                      thewest: `4px double ${theme.colors.borders.secondary}`,
                      fallback: undefined,
                  })
                : 'none',
        },
    }),
)
export const StyledGridItem = styled('div')<StyledGridItemProps>(
    ({ theme, hasSidebar }) => ({
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: hasSidebar ? calcRem(0, 10) : 0,
        backgroundColor: hasSidebar
            ? theme.colors.background.secondary
            : 'transparent',
        [breakpoint('md')]: {
            padding: hasSidebar ? '0 20px 0 0' : 'none',
        },
    }),
)
