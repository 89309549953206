import {
    PagePosition,
    PlayerEvent,
    PlaylistType,
    Product,
    Standard,
    VideoConfig,
    VideoDetails,
} from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import { Logger } from 'typescript-log'
import { AutoplayOptions } from '../../../content/Video/PlayerInterface'
import { RenderPlayerOptions } from '../player-render-params'
import { VideoOptions } from '../PlayerInterface'

export const brightcovePlayerWebId = 'g0iOzsBYC'
// Player used for AMP / IA
export const standaloneSwmPlayerBrightcovePlayerID = 'P5PBiNMA1'

export interface PlayerOptions extends RenderPlayerOptions, VideoOptions {
    brightcovePlayerId: string
}

export interface RuntimeVideoConfig {
    nielsenApid: string
}

export interface PlayerProps {
    logger: Logger
    playerOptions: PlayerOptions

    variant: Standard['videoPlayerVariant']
    onEvent: (event: PlayerEvent) => void

    play: boolean
    pause: boolean

    currentVideo: VideoDetails
    currentVideoMetadata: VideoMetaDTO
    currentVideoPosition: number
    playlistType: PlaylistType
    pagePosition: PagePosition

    autoplayOptions: AutoplayOptions | false
    autoplayHasCompleted: boolean
    isMuted: boolean

    isFullscreen: boolean

    adPlaying: boolean

    config: VideoConfig & RuntimeVideoConfig
    product: Product
    pathname: string
    isVideoHub?: boolean
}
