import React from 'react'
import { StyledButton } from '../../buttons/Button/Button.styled'

export interface ButtonProps {
    type?: React.ButtonHTMLAttributes<any>['type']
    className?: string
    disabled?: boolean
    buttonWidth?: 'wide'
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onEvent?: (event: any) => void
}

export const Button: React.FC<ButtonProps> = ({
    type,
    className,
    disabled,
    onClick,
    children,
    buttonWidth,
}) => (
    <StyledButton
        disabled={disabled}
        type={type}
        className={className}
        onClick={onClick}
        buttonWidth={buttonWidth}
    >
        {children}
    </StyledButton>
)
Button.displayName = 'Button'
