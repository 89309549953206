import { Logger } from 'typescript-log'
import { debugSwg } from '../../authentication/debug'
import { getLoginUrl, stopGoogleAutoLogin } from '../../authentication/helpers'
import { BaseClientConfig } from '../../client'
import { storeCookie } from '../cookies/cookies'
import {
    Entitlements,
    entitlementsResponseType,
    SubscriptionResponse,
    Subscriptions,
    SwgEntitlementPublisher,
} from './swg-api'

export interface SwgMetaInfo {
    teacherRegNumber?: string
    universitySubscriber?: {
        universityName: string
        email: string
    }
}

interface SwGPurchaseAccountCreationDTO {
    userToken: string
    entitlementsToken: string
    swgMetaInfo?: SwgMetaInfo
}

export interface DeferredAccountCreationReturnDTO {
    sub: string
}

export function productIdFromLocation(
    publicationProduct = 'everyday_digital',
    hostUrl?: string,
) {
    const hostname = hostUrl
        ? hostUrl.replace('https://', '')
        : window.location.hostname
    const publicationId = hostname.match(/localhost/)
        ? 'thewest.dev.swmdigital.io'
        : hostname.replace(/www\.|local\./, '')
    return `${publicationId}:${publicationProduct}`
}

/**
 * Function is used to handle the onClick of a SwG button.
 * It does a POST to the /google/deferred-account-creation route on the Auth Site, creating/updating the user account of the person who purchased the SKU.
 * It then does a redirect to the Auth Site to log the user in, calling the relevant SwG functions to update the front-end.
 */
export async function handleSwGPurchase(
    config: BaseClientConfig,
    subscriptionsResponse: SubscriptionResponse,
    swgMetaInfo?: SwgMetaInfo,
): Promise<DeferredAccountCreationReturnDTO | void> {
    // We only really need the purchaseToken and the userData
    const { entitlements, userData } = subscriptionsResponse

    const data: SwGPurchaseAccountCreationDTO = {
        entitlementsToken: entitlements.raw,
        userToken: userData.idToken,
        swgMetaInfo,
    }

    // THere is a chance that we could get 201 instead of a 200 if a new account is created, but in terms of handling it on the client-side, there isn't a difference.
    const response = await fetch(
        `${config.auth?.issuer}/google/deferred-account-creation`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        },
    )

    if (response.ok) {
        debugSwg('Account creation for SwG Purchase was successful')
        const responseData =
            (await response.json()) as DeferredAccountCreationReturnDTO
        return responseData
    } else {
        debugSwg('Account creation for SwG Purchase failed')
    }

    return
}

export interface SwGSubscriptionInfo {
    wanUserId: string
    pageUrl: string
    /** unique hash of SKU purchase */
    purchaseToken: string
    /** Google Play Console orderId */
    orderId: string
    /** User's googleId  */
    googleId: string
    pageTitle: string
    pageSlug: string
    pageByline: string
    masthead: string
    name: string
    email: string
    requiredAccessLevel: string
    callToAction: string
    pageSource: string
    breachScreenVersion: string
    rateCode: 'Google_Everyday_Digi'
}

/*
 *
 * Function to send a fetch request to the /message/swg-newstart route, which fires a message to the appropriate firehose topic.
 * @param {SwGSubscriptionInfo} messageInformation
 * Message contents to be published to the firehose SNS topic.
 */
export async function sendSwGNewStartMessage(
    subscriptionInfo: SwGSubscriptionInfo,
) {
    try {
        const response = await fetch('/firehose/swg-newstart', {
            method: 'POST',
            body: JSON.stringify(subscriptionInfo),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return response.status
    } catch (e) {
        throw new Error(JSON.stringify(e))
    }
}

export function buildRedirectUrl(currentLocation: Location): string {
    const fragments = currentLocation.hash

    const query = currentLocation.search

    const expiredQueryStringUrl =
        query + (query ? '&' : '?') + 'force_access_token_expiry=1'

    let pureUrl = currentLocation.href

    if (!fragments && pureUrl.includes('#')) {
        pureUrl = pureUrl.replace('#', '')
    } else {
        pureUrl = pureUrl.replace(fragments, '')
    }

    if (!query && pureUrl.includes('?')) {
        pureUrl = pureUrl.replace('?', '')
    } else {
        pureUrl = pureUrl.replace(query, '')
    }

    // If the URL has hash fragments, append the fragments AFTER the new queryString in the newURL
    const newUrl = fragments
        ? pureUrl + expiredQueryStringUrl + fragments
        : pureUrl + expiredQueryStringUrl

    return newUrl
}

export async function googleLoginRedirect(
    subscriptions: Subscriptions,
    loginHint: string,
) {
    subscriptions.showLoginNotification().then(() => {
        window.location.href = getLoginUrl({
            prompt: 'google',
            login_hint: loginHint,
        })
    })
}

/**
 * This calls the relevant SwG functions to initate the Auto Login process.
 * Returns true if auto-login if successful and the user successfully logs in, returns false otherwise.
 */
export async function initiateAutoLogin(
    subscriptions: Subscriptions,
    accounts: SwgEntitlementPublisher | entitlementsResponseType,
    log: Logger,
) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const autoLogin = async () => {
            try {
                // Offer auto login
                // Option 2 - get user permission to login with Google.
                await subscriptions.showLoginPrompt().then(
                    async () => {
                        // User clicked 'Yes'.
                        // Notify the user that they're being logged in with Google.
                        // We need to do a login in the auth site here...

                        debugSwg('Reached Auto Login handler')

                        let login_hint = ''

                        // Makeshift typeguard
                        if ('subscriptionToken' in accounts) {
                            login_hint = JSON.parse(accounts.subscriptionToken)[
                                'sub'
                            ]
                        } else {
                            login_hint = accounts.identifier
                        }

                        // Redirect to AuthSite with login_hint and log the user in.
                        await googleLoginRedirect(subscriptions, login_hint)
                        return true
                    },
                    (reason) => {
                        storeCookie(
                            stopGoogleAutoLogin,
                            {},
                            {
                                // This is in days, not millieseconds
                                expires: 0.5,
                            },
                        )
                        throw new Error('User declined auto login')
                    },
                )
            } catch (e) {
                log.error({ e }, 'Error in Auto Login Handler')
                return false
            }
            return true
        }
        resolve(await autoLogin())
    })
}

/**
 * Generates a promise that is passed to the waitForSubscriptonLookup SwG function.
 * Depending on if the user has a WAN entitlement, it generates a promise that resolves to an array of the user's WAN entitlement
 * Or it generates a promise that fetches the user's WAN sub from the Auth Site via fetch (if the user has a non WAN)
 */
export async function generateSwGPromise(
    hasWanEntitlements: boolean,
    authSiteUrl: string | undefined,
    entitlements: Entitlements,
    log: Logger,
): Promise<entitlementsResponseType | SwgEntitlementPublisher | undefined> {
    if (hasWanEntitlements) {
        return new Promise((resolve) => {
            const WANEntitlements = entitlements.entitlements.filter(
                (entitlement): entitlement is SwgEntitlementPublisher => {
                    return entitlement.source !== 'google'
                },
            )

            if (WANEntitlements.length > 1) {
                throw new Error(
                    'WAN Entitlements array is greater than one - something has occured.',
                )
            } else {
                resolve(WANEntitlements[0])
            }
        })
    } else {
        if (!authSiteUrl) {
            log.warn({}, 'Auth Site URL is not defined inside config file.')
            return new Promise(() => {})
        }

        const verifyEntitlements = async (): Promise<
            entitlementsResponseType | undefined
        > => {
            const response = await fetch(
                `${authSiteUrl}/google/entitlement-search`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(entitlements),
                },
            )

            if (
                response &&
                response.body &&
                response.ok &&
                response.status !== 204
            ) {
                debugSwg('Response was successful')
                return await response.json()
            }

            return undefined
        }

        return new Promise((resolve) => {
            resolve(verifyEntitlements())
        })
    }
}
