import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    AflMatchSummaryWidget,
    AflMatchSummaryWidgetProps,
} from './AflMatchSummaryWidget'

export const AflMatchSummaryWidgetRegistration = createRegisterableComponent(
    'afl-match-summary-widget',
    (props: AflMatchSummaryWidgetProps, services) => {
        return <AflMatchSummaryWidget {...props} />
    },
)
