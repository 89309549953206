import { RenderTargetContext } from '@news-mono/web-common'
import React from 'react'

interface RenderTargets<Props> {
    amp: React.ComponentType<Props> | null
    app: React.ComponentType<Props> | null
    rss: React.ComponentType<Props> | null
    web: React.ComponentType<Props> | null
    preview: React.ComponentType<Props> | null
}

// Component to help with rendering to the correct target based on renderMode context
export function createRenderTarget<Props>(
    name: string,
    renderTargets: RenderTargets<Props>,
) {
    const renderTarget: React.FC<Props> = (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const context = React.useContext(RenderTargetContext)

        switch (context.renderTarget) {
            case 'amp':
                return renderTargets.amp ? (
                    <renderTargets.amp {...props} />
                ) : null
            case 'app':
                return renderTargets.app ? (
                    <renderTargets.app {...props} />
                ) : null
            case 'rss':
                return renderTargets.rss ? (
                    <renderTargets.rss {...props} />
                ) : null
            case 'preview':
                return renderTargets.preview ? (
                    <renderTargets.preview {...props} />
                ) : null
            case 'web':
            default:
                return renderTargets.web ? (
                    <renderTargets.web {...props} />
                ) : null
        }
    }
    renderTarget.displayName = `RenderTarget(${name})`
    return renderTarget
}
