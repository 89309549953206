import styled from '@emotion/styled'
import { fonts, calcRem, breakpoint } from '../../../__styling'
import { StyledLink } from '../../TheWestHeader/TheWestHeader.styled'
import { StyledEpaperCollectionPapersStyledImg } from './EpaperCollection.styled'
import { StyledModalMain } from './LatestEdition.styled'

export const StyledEditionGroupPaperContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: calcRem(24),
    flexDirection: 'column',
    [breakpoint('md')]: {
        flexDirection: 'row',
        gap: calcRem(63),
    },
}))

export const StyledEditionGroupContainer = styled('div')(({ theme }) => ({
    outline: '1px solid #D6D6D6',
    borderRadius: calcRem(8),
    padding: calcRem(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: calcRem(12),
}))

export const StyledEditionGroupHeader = styled('div')(({ theme }) => ({
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(24),
    fontWeight: 600,
    lineHeight: calcRem(56),
    paddingBottom: calcRem(20),
    paddingTop: calcRem(10),
    [breakpoint('md')]: {
        fontSize: calcRem(34),
        paddingBottom: calcRem(40),
        paddingTop: 0,
    },
}))

export const StyledEditionGroupTitle = styled('div')(({ theme }) => ({
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(18),
    fontWeight: 600,
    paddingBottom: calcRem(10),
    alignSelf: 'baseline',
    lineHeight: calcRem(6),
    [breakpoint('md')]: {
        fontSize: calcRem(24),
        paddingBottom: calcRem(12),
        lineHeight: calcRem(18),
    },
}))

export const StyledEditionGroupButton = styled(StyledLink)(({ theme }) => ({
    display: 'flex',
    fontFamily: theme.fonts.sansSerif,
    color: theme.colors.actions.button.primary.text.active,
    backgroundColor: theme.sectionValues.primaryColor,
    width: calcRem(98),
    height: calcRem(44),
    fontSize: calcRem(14),
    textTransform: 'none',
    borderRadius: calcRem(2),
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    '&:hover': {
        color: theme.colors.palette.white,
        filter: 'brightness(75%)',
        textDecoration: 'none',
    },
    '&:focus': {
        color: theme.colors.palette.white,
        filter: 'brightness(75%)',
        textDecoration: 'none',
    },
}))

export const StyledEditionGroupImage = styled('img')(({ theme }) => ({
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'right',
    [breakpoint('md')]: {
        height: calcRem(400),
    },
}))

export const StyledEditionGroupModalMain = styled(StyledModalMain)(
    ({ theme }) => ({
        width: calcRem(343),
        padding: calcRem(16),
        overflowY: 'auto',
        maxHeight: '100%',
        height: '88vh',
        [breakpoint('xs')]: {
            width: calcRem(343),
            padding: calcRem(16),
            overflowY: 'auto',
            maxHeight: '100%',
            height: '88vh',
        },
        [breakpoint('md')]: {
            width: calcRem(905),
            padding: calcRem(70),
            height: 'auto',
        },
    }),
)
