import {
    CardItem,
    CollectionEvent,
    ConfigurationContext,
    createCardClickedEvent,
    isPublicationCardItem,
    toLinkWithHint,
    useFeature,
    useToggleState,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useContext, useRef } from 'react'
import {
    StyledCTAButton,
    StyledCardImage,
    StyledCardImageContainer,
    StyledContentCard,
    StyledDotLogo,
    StyledLinkWrapper,
    StyledLiveStatus,
    StyledText,
    StyledTextContainer,
    StyledTimeToRead,
    StyledTimeToReadWrapper,
    StyledLiveLabel,
    StyledBreakingLabel,
} from './ContentCard.styled'
import {
    EditorialType,
    getEditorialType,
} from '../../../templates/Publication/lib/get-editorial-type'
import { useProduct } from '../../../__product/useProduct'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { useLocation } from 'react-router'

export type CardType = 'large' | 'medium' | 'medium-video-hub' | 'small'
export type CollectionType = 'six-card' | 'five-card' | 'four-card' | 'hero'

export interface CTAProps {
    title: string
    link?: string
}

export interface ContentCardProps {
    item: MaybeLoaded<CardItem>
    cardNumber: number
    cardType: CardType
    onEvent: (event: CollectionEvent) => void
    gridAreaName?: string
    displayRightSeparator?: boolean
    cta?: CTAProps
    shouldHideTeaser?: boolean
    collectionType?: CollectionType
}

const getImageWidth = (cardType: CardType) => {
    switch (cardType) {
        case 'small':
            return '350px'
        case 'medium':
            return '740px'
        case 'medium-video-hub':
            return '740px'
        case 'large':
            return '740px'
    }
}

export const ContentCard: React.FC<ContentCardProps> = ({
    item,
    cardNumber,
    cardType,
    onEvent,
    gridAreaName,
    displayRightSeparator,
    cta,
    shouldHideTeaser,
    collectionType,
}) => {
    const product = useProduct()
    const isCarExpert = item.loaded && item.result.source === 'CarExpert'
    const location = useLocation()
    const config = useContext(ConfigurationContext)
    const linkOutIcon = require('../../assets/link-out.svg')
    function linkClicked(
        e: React.MouseEvent<HTMLElement>,
        item: MaybeLoaded<CardItem>,
        cardNumber: number,
    ) {
        if (!item.loaded) {
            e.preventDefault()
            return
        }
        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'InternalPortrait',
                'Portrait',
                cardNumber,
                'InlineRelatedCollection',
            ),
        )
    }
    const toggles = useToggleState()
    const cardRef = useRef<HTMLDivElement>(null)
    const storyClassification = true
    const editorialType = item.loaded
        ? getEditorialType(item.result, storyClassification)
        : undefined

    if (item.loaded && isPublicationCardItem(item.result)) {
        const timeToRead = item?.result?.readTimeMinutes
            ? `${Math.ceil(item.result.readTimeMinutes)} min read`
            : ''
        const isCarExpertCanonicalEnabled = isFeatureEnabled(
            toFeatureState(toggles),
            'car-expert-canonical',
        )

        const currentUrl = `${config.publicUrl}${location.pathname}`

        const overrideUrl =
            isCarExpert &&
            product === 'sevennews' &&
            isCarExpertCanonicalEnabled
                ? `${item.result.canonicalUrl}?referrer=7news&referrerurl=${currentUrl}`
                : null
        return (
            <StyledLinkWrapper
                to={overrideUrl ?? toLinkWithHint(item)}
                onClick={(event) => {
                    linkClicked(event, item, cardNumber)
                }}
                gridAreaName={gridAreaName}
                collectionType={collectionType}
            >
                <StyledContentCard
                    ref={cardRef}
                    cardType={cardType}
                    displayRightSeparator={displayRightSeparator}
                >
                    <StyledTextContainer cardType={cardType}>
                        <StyledText
                            item={{
                                loaded: true,
                                result: item.result,
                            }}
                            fontScale={1}
                            onEvent={onEvent}
                            teaserMode={
                                cardType === 'small' || shouldHideTeaser
                                    ? 'hidden'
                                    : 'visible'
                            }
                            kickerMode={'hidden'}
                        />
                        {editorialType &&
                        editorialType === EditorialType.Live ? (
                            <StyledLiveStatus>
                                <StyledDotLogo />
                                <StyledLiveLabel>Live</StyledLiveLabel>
                            </StyledLiveStatus>
                        ) : editorialType === EditorialType.Breaking ? (
                            <StyledBreakingLabel>Breaking</StyledBreakingLabel>
                        ) : (
                            timeToRead &&
                            !cta && (
                                <StyledTimeToReadWrapper>
                                    <StyledTimeToRead>
                                        {timeToRead}
                                    </StyledTimeToRead>
                                    {overrideUrl && (
                                        <img
                                            src={linkOutIcon}
                                            alt={'link out icon'}
                                        />
                                    )}
                                </StyledTimeToReadWrapper>
                            )
                        )}

                        {cta && <StyledCTAButton>{cta.title}</StyledCTAButton>}
                    </StyledTextContainer>
                    <StyledCardImageContainer cardType={cardType}>
                        <StyledCardImage
                            item={{
                                loaded: true,
                                result: item.result,
                            }}
                            imageWidths={{
                                mobile: getImageWidth(cardType),
                                tablet: getImageWidth(cardType),
                                desktop: getImageWidth(cardType),
                                fallbackWidth: 740,
                            }}
                            hasBackground={true}
                            onEvent={onEvent}
                            mediaMode={'default'}
                            fixedRatio={'16:9'}
                            disableImageLazyLoad={false}
                            displayNoScriptImage={true}
                            isVideoPage={cardType === 'medium-video-hub'}
                        />
                    </StyledCardImageContainer>
                </StyledContentCard>
            </StyledLinkWrapper>
        )
    } else {
        return null
    }
}
