import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

const MOBILE_WIDGET_HEIGHT = 260
const TABLET_WIDGET_HEIGHT = 246
const DESKTOP_WIDGET_HEIGHT = 360

export const StyledScoreboardSkeletonWrapper = styled('div')({
    width: '100%',
    minHeight: calcRem(MOBILE_WIDGET_HEIGHT),
    [breakpoint('sm')]: {
        height: calcRem(TABLET_WIDGET_HEIGHT),
    },
    [breakpoint('lg')]: {
        height: calcRem(DESKTOP_WIDGET_HEIGHT),
    },
    '.skeleton': {
        height: '100%',
    },
})

export const StyledHiddenMainHeader = styled('h1')({
    visibility: 'hidden',
    height: '0px',
    margin: 0,
    padding: 0,
})

export const StyledHiddenSecondaryHeader = styled('h2')({
    visibility: 'hidden',
    height: '0px',
    margin: 0,
    padding: 0,
})
