import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const StyledHeadline = styled('h1')(({ theme }) => ({
    ...breakWords,
    transition: `font-size ${easing.easeOut.fast}`,
    margin: 0,
    fontFamily: theme.fonts.publication.headline,
    fontSize: themedValue(theme, {
        thewest: calcRem(24),
        sevennews: calcRem(36),
        fallback: calcRem(32),
    }),
    fontWeight: themedValue(theme, {
        thewest: 400,
        thenightly: 600,
        fallback: 700,
    }),
    letterSpacing: theme.kind === 'thenightly' ? '-0.48px' : undefined,
    color: themedValue(theme, {
        thewest: colors.thewest.greyGorilla,
        perthnow: colors.perthnow.greyCoal,
        sevennews: colors.sevennews.charade,
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        thewest: 1.2,
        thenightly: '120%',
        fallback: 1,
    }),
    transform: themedValue(theme, {
        perthnow: 'translateY(-5px)',
        fallback: undefined,
    }),

    [breakpoint('sm')]: {
        fontSize: themedValue(theme, {
            thewest: calcRem(38),
            sevennews: calcRem(36),
            thenightly: calcRem(40),
            fallback: calcRem(42),
        }),
        lineHeight: themedValue(theme, {
            thewest: 1.167,
            perthnow: 1.095,
            sevennews: 1,
            fallback: undefined,
        }),
    },
}))
