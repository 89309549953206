import { useTheme } from '@emotion/react'
import {
    FeatureState,
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    CardEvents,
    CollectionEvent,
    createCollectionAvailableEvent,
    Features,
    PublicationCardItem,
    RenderTargetContext,
    useToggleState,
} from '@news-mono/web-common'
import React from 'react'
import { LandscapeLayout } from '../../cards/Landscape/Landscape.layouts'
import { Lima } from '../../collections/Lima/Lima'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { ThemedRender } from '../../__styling/themed-render'
import { themedValue } from '../../__styling/themed-value'
import { ResponsivePictureSizes } from '../Picture/responsive'
import { InlineRelatedContentCard } from './components/InlineRelatedContentCard'
import { PerthNowInlineRelatedContentCards } from './components/PerthNowInlineRelatedContentCards'
import { SevenNewsInlineRelatedContentCards } from './components/SevenNewsInlineRelatedContentCards'
import { SevenNewsInlineRelatedContentTextCard } from './components/SevenNewsInlineRelatedContentTextCards'
import { TheWestInlineRelatedContentCards } from './components/TheWestInlineRelatedContentCards'
import { StyledAside } from './InlineRelatedContent.styled'
import { InlineRelatedContentCards } from '../../SevenNewsV2/content/InlineContent/InlineContentCards'
import { TheNightlyInlineRelatedContentCards } from './components/TheNightlyInlineRelatedContentCards'

export interface InlinerelatedContentProps {
    items: PublicationCardItem[]
    onEvent: (event: CardEvents | CollectionEvent) => void
    publicationPath?: string
    publicationKind?: string
    isV2ArticlePageEnabled?: boolean
}

export const InlineRelatedContent: React.FC<InlinerelatedContentProps> = ({
    items,
    onEvent,
    publicationPath,
    publicationKind,
    isV2ArticlePageEnabled,
}) => {
    const renderTarget = React.useContext(RenderTargetContext)
    const togglesState = useToggleState()
    const toggles = toFeatureState(togglesState)
    const product = useProduct()

    const theme = useTheme()

    const isTheWestEventPost =
        theme.kind === 'thewest' && publicationKind === 'event'

    if (items.length === 0 || renderTarget.renderTarget === 'rss') {
        return null
    }

    const inlineContentSection =
        theme.section === 'community-news'
            ? ('inline-content-cng' as const)
            : ('inline-content' as const)

    const sectionHeadingPerthNow =
        theme.section === 'community-news'
            ? 'More from Community News'
            : 'More from PerthNow'

    const sectionHeader = themedValue(theme, {
        sevennews: undefined,
        thewest: undefined,
        perthnow: {
            heading: sectionHeadingPerthNow,
            fontScale: 1 as const,
            section: inlineContentSection,
        },
        fallback: undefined,
    })

    const landscapeCardLayout: LandscapeLayout = themedValue(theme, {
        thewest: {
            type: 'landscape',
            format: 'landscape-xsNoTeaser-mdTeaser-5050',
        },
        sevennews: {
            type: 'landscape',
            format: 'landscape-xsNoTeaser-mdTeaser-5050-simple',
        },
        perthnow: {
            type: 'landscape',
            format: 'landscape-inline-content-perthnow',
        },
        fallback: {
            type: 'landscape',
            format: 'landscape-xsNoTeaser-mdTeaser-5050',
        },
    })

    const imageWidths = themedValue(theme, {
        thewest: {
            mobile: '110px',
            tablet: '369px',
            desktop: '315px',
            fallbackWidth: 315,
            mode: 'fixed',
        },
        perthnow: {
            mobile: '218px',
            tablet: '475px',
            desktop: '263px',
            fallbackWidth: 263,
            mode: 'fixed',
        },
        sevennews: {
            mobile: '215px',
            tablet: '345px',
            desktop: '275px',
            fallbackWidth: 275,
            mode: 'fixed',
        },
        fallback: undefined,
    }) as ResponsivePictureSizes

    const sevenEntTextCard = (pathName: any) => {
        const isTextCard = isSevenInlineRelatedTextCard(toggles, pathName)

        if (isV2ArticlePageEnabled) {
            return (
                <InlineRelatedContentCards
                    onEvent={onEvent}
                    expectedCards={items.length}
                    items={{ loaded: true, result: items }}
                />
            )
        }

        if (isTextCard) {
            return (
                <SevenNewsInlineRelatedContentTextCard
                    onEvent={onEvent}
                    expectedCards={items.length}
                    items={{ loaded: true, result: items }}
                />
            )
        }
        return (
            <SevenNewsInlineRelatedContentCards
                onEvent={onEvent}
                expectedCards={items.length}
                items={{ loaded: true, result: items }}
            />
        )
    }

    if (items.length === 1) {
        return (
            <ImpressionAvailable
                loading={false}
                available={() => {
                    onEvent(
                        createCollectionAvailableEvent(
                            items,
                            'InlineRelatedContent',
                            product,
                            onEvent,
                            {
                                componentType: 'inlinerelatedcontent',
                            },
                        ),
                    )
                }}
            >
                {(ref) => (
                    <StyledAside ref={ref}>
                        <ThemedRender
                            sevennews={() => sevenEntTextCard(publicationPath)}
                            thewest={() => (
                                <TheWestInlineRelatedContentCards
                                    onEvent={onEvent}
                                    expectedCards={items.length}
                                    items={{ loaded: true, result: items }}
                                    openInternalInNewWindow={isTheWestEventPost}
                                />
                            )}
                            perthnow={() => (
                                <PerthNowInlineRelatedContentCards
                                    onEvent={onEvent}
                                    expectedCards={items.length}
                                    items={{ loaded: true, result: items }}
                                />
                            )}
                            thenightly={() => (
                                <TheNightlyInlineRelatedContentCards
                                    onEvent={onEvent}
                                    expectedCards={items.length}
                                    items={{ loaded: true, result: items }}
                                />
                            )}
                            fallback={() => (
                                <Lima
                                    onEvent={onEvent}
                                    expectedCards={1}
                                    initialColumns={1}
                                    intermediateColumns={1}
                                    finalColumns={1}
                                    hideByline={true}
                                    hasBorder={false}
                                    verticalSpacing={'lg'}
                                    fixedRatios={['16:9']}
                                    sectionHeader={sectionHeader}
                                    cardLayout={[[landscapeCardLayout]]}
                                    items={{ loaded: true, result: items }}
                                    imageWidths={imageWidths}
                                    isInlineRelatedCollection={true}
                                />
                            )}
                        />
                    </StyledAside>
                )}
            </ImpressionAvailable>
        )
    }

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent(
                    createCollectionAvailableEvent(
                        items,
                        'InlineRelatedContent',
                        product,
                        onEvent,
                        {
                            componentType: 'inlinerelatedcontent',
                        },
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledAside ref={ref}>
                    <ThemedRender
                        sevennews={() => sevenEntTextCard(publicationPath)}
                        thewest={() => (
                            <TheWestInlineRelatedContentCards
                                onEvent={onEvent}
                                expectedCards={items.length}
                                items={{ loaded: true, result: items }}
                            />
                        )}
                        perthnow={() => (
                            <PerthNowInlineRelatedContentCards
                                onEvent={onEvent}
                                expectedCards={items.length}
                                items={{ loaded: true, result: items }}
                            />
                        )}
                        thenightly={() => (
                            <TheNightlyInlineRelatedContentCards
                                onEvent={onEvent}
                                expectedCards={items.length}
                                items={{ loaded: true, result: items }}
                            />
                        )}
                        fallback={() => (
                            <InlineRelatedContentCard
                                sectionHeader={sectionHeader}
                                onEvent={onEvent}
                                items={items}
                            />
                        )}
                    />
                </StyledAside>
            )}
        </ImpressionAvailable>
    )
}
InlineRelatedContent.displayName = 'InlineRelatedContent'

interface InlineRelatedTextCardTopic {
    id: string
    feature?: Features
}
function isSevenInlineRelatedTextCard(toggles: FeatureState, path?: string) {
    const includedTopics: InlineRelatedTextCardTopic[] = [
        {
            id: '/entertainment/big-brother-australia/',
            feature: '7ent-article-related',
        },
        {
            id: '/lifestyle',
            feature: '7-news-lifestyle-article-related-card',
        },
        {
            id: '/travel',
            feature: '7-news-lifestyle-article-related-card',
        },
        {
            id: '/entertainment/sas-australia',
            feature: '7-news-sas-australia-article-related-card',
        },
        {
            id: '/sport',
        },
        {
            id: '/politics',
            feature: '7-news-politics-full-page-article-display',
        },
        {
            id: '/sunrise',
        },
        {
            id: '/entertainment',
        },
        {
            id: '/the-morning-show',
        },
        {
            id: '/technology',
        },
        {
            id: '/news',
        },
        {
            id: '/world',
            feature: '7-news-world-full-article-page-display',
        },
        {
            id: '/business',
        },
        {
            id: '/weather',
        },
        {
            id: '/spotlight',
            feature: '7-news-spotlight-full-article-display',
        },
        {
            id: '/heartbreak-island',
        },
        {
            id: '/sunrise/whats-for-dinner',
        },
        {
            id: '/farmer-wants-a-wife',
        },
        {
            id: '/kitchen-nightmares-australia',
        },
        {
            id: '/agt',
        },
        {
            id: '/million-dollar-island',
        },

        { id: '/australian-idol' },
    ]
    if (!path) {
        return false
    }
    const isTextCard = includedTopics.find((item) => path.includes(item.id))
    if (!isTextCard) {
        return false
    }
    //if topic exist in array but don't have feature toggle like sport
    if (!isTextCard.feature) {
        return true
    }
    const isTextCardEnabled = isFeatureEnabled(toggles, isTextCard.feature)
    return isTextCardEnabled
}
