import { TextTransformProperty } from 'csstype'
import {
    BreakpointState,
    PerthNowTheme,
    PerthNowThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    ThemeConfig,
    calcRem,
    TheWestTheme,
    TheWestThemeConfig,
    SevenNewsTheme,
    SevenNewsThemeConfig,
} from '../../__styling'

export type PaginationTheme<T extends ThemeConfig> = {
    gap: BreakpointState<T, string>
    padding: string
    width: string
    margin: string
    fontFamily: string

    paginationItem: {
        gap: string
        padding: BreakpointState<T, string>
        textDecoration: string
        backgroundColor: string
        textTransform: TextTransformProperty
        borderRadius: string
        color: string
        fontSize: string
        fontWeight: number
        lineHeight: string
    }
}

export const theNightlyPaginationTheme = (scheme: TheNightlyTheme) => {
    const theme: PaginationTheme<TheNightlyThemeConfig> = {
        gap: {
            default: calcRem(0),
            sm: calcRem(8),
        },
        width: '100%',
        padding: '0',
        margin: '0',
        fontFamily: scheme.fonts.sansSerif,

        paginationItem: {
            gap: calcRem(4),
            textDecoration: 'none',
            backgroundColor: '#fff',
            textTransform: 'uppercase',
            borderRadius: calcRem(8),
            color: '#161616',
            fontSize: calcRem(16),
            fontWeight: 600,
            lineHeight: calcRem(24),
            padding: {
                default: calcRem(12, 16),
                sm: calcRem(12, 24),
            },
        },
    }
    return theme
}

export const perthNowPaginationTheme = (
    scheme: PerthNowTheme,
): PaginationTheme<PerthNowThemeConfig> => {
    const theme: PaginationTheme<TheNightlyThemeConfig> = {
        gap: {
            default: calcRem(0),
            sm: calcRem(8),
        },
        width: '100%',
        padding: '0',
        margin: '0',
        fontFamily: scheme.fonts.sansSerif,

        paginationItem: {
            gap: calcRem(4),
            textDecoration: 'none',
            backgroundColor: '#fff',
            textTransform: 'uppercase',
            borderRadius: calcRem(8),
            color: '#161616',
            fontSize: calcRem(16),
            fontWeight: 600,
            lineHeight: calcRem(24),
            padding: {
                default: calcRem(12, 16),
                sm: calcRem(12, 24),
            },
        },
    }
    return theme
}

export const theWestPaginationTheme = (
    scheme: TheWestTheme,
): PaginationTheme<TheWestThemeConfig> => {
    const theme: PaginationTheme<TheNightlyThemeConfig> = {
        gap: {
            default: calcRem(0),
            sm: calcRem(8),
        },
        width: '100%',
        padding: '0',
        margin: '0',
        fontFamily: scheme.fonts.sansSerif,

        paginationItem: {
            gap: calcRem(4),
            textDecoration: 'none',
            backgroundColor: '#fff',
            textTransform: 'uppercase',
            borderRadius: calcRem(8),
            color: '#161616',
            fontSize: calcRem(16),
            fontWeight: 600,
            lineHeight: calcRem(24),
            padding: {
                default: calcRem(12, 16),
                sm: calcRem(12, 24),
            },
        },
    }
    return theme
}

export const sevenNewsPaginationTheme = (
    scheme: SevenNewsTheme,
): PaginationTheme<SevenNewsThemeConfig> => {
    const theme: PaginationTheme<TheNightlyThemeConfig> = {
        gap: {
            default: calcRem(0),
            sm: calcRem(8),
        },
        width: '100%',
        padding: '0',
        margin: '0',
        fontFamily: scheme.fonts.sansSerif,

        paginationItem: {
            gap: calcRem(4),
            textDecoration: 'none',
            backgroundColor: '#fff',
            textTransform: 'uppercase',
            borderRadius: calcRem(8),
            color: '#161616',
            fontSize: calcRem(16),
            fontWeight: 600,
            lineHeight: calcRem(24),
            padding: {
                default: calcRem(12, 16),
                sm: calcRem(12, 24),
            },
        },
    }
    return theme
}
