import React from 'react'

export interface IconPlayProps {
    className?: string
}

export const IconPlay: React.FC<IconPlayProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 19 23"
            version="1.1"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.9987095,11.589995 L1.21275235,21.7000917 C0.834271897,21.9280486 0.342657325,21.8060251 0.114700469,21.4275446 C0.0396553666,21.302946 -1.64752168e-15,21.1602452 0,21.0147922 L0,0.794598739 C-1.65130599e-16,0.352770965 0.358172179,-0.00540121345 0.799999952,-0.00540121345 C0.945452962,-0.00540121345 1.08815375,0.0342541532 1.21275235,0.109299256 L17.9987095,10.219396 C18.3771899,10.4473529 18.4992134,10.9389674 18.2712566,11.3174479 C18.2039779,11.4291518 18.1104134,11.5227163 17.9987095,11.589995 Z"></path>
        </svg>
    )
}
IconPlay.displayName = 'IconPlay'
