export const isServerEnvironment = () => {
    return typeof document === 'undefined'
}

export const isTestEnvironment = () => {
    if (isServerEnvironment()) {
        return false
    }

    return (
        navigator.userAgent.toLowerCase().indexOf('node.js') !== -1 ||
        navigator.userAgent.indexOf('jsdom') !== -1
    )
}
