import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { StyledSubscriptionPackageBenefit } from './SubscriptionPackage.styled'

export const StyledMiniSubscriptionPackage = styled.div(
    {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        padding: '1.125rem',
        paddingBottom: '0.5rem',
        width: '100%',
        maxWidth: 252,

        border: '2px solid',
        borderTop: `${calcRem(16)} solid`,
        borderRadius: calcRem(5),
    },
    ({ theme }) => ({
        borderColor: theme.colors.borders.primary,
        borderTopColor: theme.colors.brand,
    }),
)

export const StyledMiniPackageHeader = styled.header(
    {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        textAlign: 'start',
        fontWeight: 500,
        fontSize: calcRem(16),
        h4: {
            flex: '1 1 8rem',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            margin: 0,
        },
        span: {
            flex: '0 1 ' + calcRem(35),
            fontSize: calcRem(12),
            fontWeight: 700,
            marginLeft: '0.5rem',
            textAlign: 'end',
            overflowWrap: 'break-word',
            lineHeight: 1,
            span: {
                fontSize: calcRem(12),
            },

            '&.original-price': {
                flex: '1 0 100%',
                fontSize: '0.75em',
                marginLeft: 0,
                textDecoration: 'line-through',
                lineHeight: 2,
            },
        },
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.serif,
        color: theme.colors.text.primary,
        span: {
            color: theme.colors.brand,
        },
    }),
)

export const StyledOriginalPrice = styled.div({
    textAlign: 'end',
    textDecoration: 'line-through',
    marginBottom: '0.5rem',
})

export const StyledDashedRule = styled.hr(
    {
        borderWidth: 0,
        borderTop: '2px dashed',
        margin: '0.5rem 0',
    },
    ({ theme }) => ({
        borderTopColor: theme.colors.borders.primary,
    }),
)

export const StyledMiniSubscriptionPackageBenefit = styled(
    StyledSubscriptionPackageBenefit,
)(
    {
        fontWeight: 400,
        fontSize: calcRem(12),
    },
    ({ theme }) => ({
        color:
            theme.colors.components?.emailPreferenceCentre?.text?.default ||
            'inherit',
    }),
)
