import React from 'react'
import { DataLayerEventName, PublicationCardItem } from '@news-mono/web-common'
import { IconBookmark } from '../../icons/IconBookmark/IconBookmark'
import { tokens } from '@news-mono/design-tokens'
import { Sharing } from '../../buttons/TheNightlySharing/Sharing'
import {
    StyledButton,
    StyledButtonLabel,
    StyledContainer,
    StyledContextMenuContainer,
    StyledIconDotsVerticalContainer,
    StyledLinkIcon,
    StyledSharingButtonContainer,
} from './SavedArticleContextMenu.styled'
import { IconDotsVertical } from '../../icons/IconDotsVertical/IconDotsVertical'

export interface SavedArticlesContextMenuModalProps {
    publication: PublicationCardItem
    hide: () => void
    onUnsaveClick: (interactionId?: number) => void
    onCopyLinkClick: (url: string) => void
    onEvent: (event: any) => void
    savedArticleContextMenuContainer: React.MutableRefObject<HTMLElement | null> | null
}

export const SavedArticleContextMenuModal: React.FC<SavedArticlesContextMenuModalProps> =
    ({
        publication,
        hide,
        onEvent,
        onCopyLinkClick,
        onUnsaveClick,
        savedArticleContextMenuContainer,
    }) => {
        return (
            <StyledContextMenuContainer
                ref={(el) =>
                    savedArticleContextMenuContainer !== null
                        ? (savedArticleContextMenuContainer.current = el)
                        : null
                }
            >
                <StyledContainer>
                    <StyledIconDotsVerticalContainer onClick={hide}>
                        <IconDotsVertical fill="#FFFFFF" />
                    </StyledIconDotsVerticalContainer>
                    <StyledButton
                        id="saved-articles-unsave-button"
                        marginBottom={12}
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.savedArticlesUnsave,
                                    originator: 'SavedArticleUnsave',
                                    payload: {
                                        publicationId: publication.id,
                                    },
                                })
                            }
                            onUnsaveClick(publication.userInteractionId)
                        }}
                    >
                        <IconBookmark
                            dimension={24}
                            filled
                            strokeColor={
                                tokens.thenightly.colors.palette.neutral[0]
                            }
                        />
                        <StyledButtonLabel>Unsave</StyledButtonLabel>
                    </StyledButton>
                    <StyledButton
                        id="saved-articles-copy-link-to-clipboard-button"
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.shareButtonClick,
                                    originator: 'SavedArticleShare',
                                    payload: {
                                        shareType: 'clipboard',
                                        publicationId: publication.id,
                                    },
                                })
                            }
                            onCopyLinkClick(publication._self)
                        }}
                    >
                        <StyledLinkIcon />
                        <StyledButtonLabel>Copy link</StyledButtonLabel>
                    </StyledButton>
                </StyledContainer>
                <StyledSharingButtonContainer>
                    <Sharing
                        publicationId={publication.id}
                        url={publication._self}
                        text={publication.headline}
                        onEvent={onEvent}
                        shareOptions={['facebook', 'linkedin', 'x', 'email_tn']}
                        originator="SavedArticleShare"
                    />
                </StyledSharingButtonContainer>
            </StyledContextMenuContainer>
        )
    }
