import styled, { StyledComponent } from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { IconHome } from '../../icons/IconHome/IconHome'
import {
    IconWestLive,
    IconWestLiveProps,
} from '../../icons/IconWestLive/IconWestLive'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../__styling/style-mixins'

interface ActionContentsProps {
    rendition?: string
}

export const StyledActionsContent: StyledComponent<ActionContentsProps> =
    styled('div')((props) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: metrics.thewest.siteMetrics.mainContentWidth,
        height: props.rendition === 'app' ? calcRem(72) : calcRem(40),
        padding:
            props.rendition === 'app'
                ? `${calcRem(16)} ${calcRem(15)}`
                : `0 ${calcRem(15)}`,
        margin: '0 auto',
        fontFamily: fonts.thewest.sansSerif,
    }))

export const StyledWestHeaderItem = styled('div')({
    flex: 1,
    flexWrap: 'nowrap',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
})

const linkVertPadding = 13

export const StyledNavTrigger = styled('button')(({ theme }) => ({
    marginRight: calcRem(8),
    padding: calcRem(linkVertPadding / 2, 0),
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: 'transparent',
    color: theme.sectionValues.primaryColor,
    cursor: 'pointer',
    transition: `color ${easing.easeOut.fast}, background ${easing.easeOut.fast}, boxShadow ${easing.easeOut.fast}`,

    '&:hover': {
        color: colors.thewest.greyGorilla,
    },

    '&:focus, &.is-active': {
        outline: 'none',
    },
}))

export interface StyledSubnavTriggerIconProps {
    isActive?: boolean
}

export const StyledSubnavTriggerIcon = styled(
    'div',
)<StyledSubnavTriggerIconProps>(
    {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: calcRem(18),
        height: calcRem(2),
        background: 'currentColor',
        transition: `background ${easing.easeOut.fast}`,
        transform: `translateY(-1px)`,

        '&::before, &::after': {
            content: `''`,
            position: 'absolute',
            transformOrigin: 'center',
            right: 0,
            left: 0,
            height: calcRem(2),
            background: 'currentColor',
            transition: `transform ${easing.easeOut.med}, top ${easing.easeOut.fast}, bottom ${easing.easeOut.fast}`,
        },

        '&::before': {
            top: calcRem(5),
        },

        '&::after': {
            bottom: calcRem(5),
        },
    },
    (props) =>
        props.isActive === true && {
            background: 'transparent',

            '&::before': {
                transform: 'rotate(45deg)',
                top: 0,
            },

            '&::after': {
                transform: 'rotate(-45deg)',
                bottom: 0,
            },
        },
)

export const StyledHomeLink = styled(WebLink)(({ theme }) => ({
    display: 'block',
    position: 'relative',
    padding: calcRem(0, 8),

    '&::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: 0,
        height: calcRem(20),
        transform: 'translateY(-50%)',
        borderLeft: `1px solid ${colors.thewest.greyDolphin}`,
    },

    svg: {
        fill: theme.sectionValues.primaryColor,
    },

    '&:hover svg': {
        fill: colors.black,
    },

    '&:focus': {
        outline: 'none',

        svg: {
            fill: colors.thewest.greyKoala,
        },
    },
}))

export const StyledHomeIcon = styled(IconHome)({
    display: 'inline-block',
    width: calcRem(13),
    height: calcRem(13),
})

export const StyledWestLiveIcon = styled(IconWestLive)<IconWestLiveProps>(
    ({ showEqualizer }) => ({
        display: 'inline-block',
        width: showEqualizer ? calcRem(58) : calcRem(38),
        height: calcRem(13),
    }),
)

export const StyledActionGroup = styled('div')({
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    height: '100%',
})
StyledActionGroup.displayName = 'StyledActionGroup'

export const StyledButtonLabelHidden = styled('span')({
    ...visuallyhidden,
})
