import { keyframes } from '@emotion/css'

export type SharingAnimationType = 'fade-down-n-in' | 'fade-up-n-out'

// ANIMATION: Fade In And Down
const fadeInAndDownAnimation = keyframes`
        0% {
            opacity: 0;
            transform: translateY(16px);
        }
        25% {
            opacity: 0.2;
        }
        75% {
            transform: translateY(0px);
        }
        85% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    `
// ANIMATION: Fade Out And Up
const fadeOutAndUpAnimation = keyframes`
        0% {
            opacity: 1;
            transform: translateY(0px);
        }
        15% {
            opacity: 1;
            transform: translateY(0px);
        }
        25% {
            transform: translateY(0px);
        }
        75% {
            opacity: 0.2;
        }
        100% {
            transform: translateY(-16px);
            opacity: 0;
            display: none;
        }
    `
// ANIMATION: Disappear
export const disappear = keyframes`
        0% {
            opacity: 1;
        }
        20% {
            opacity: 0;
        }
    `
// ANIMATION: Appear
export const appear = keyframes`
        0% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    `

const getAnimation = (type: SharingAnimationType) => {
    switch (type) {
        case 'fade-down-n-in':
            return fadeInAndDownAnimation
        case 'fade-up-n-out':
            return fadeOutAndUpAnimation
        default:
            return ''
    }
}
const getOutAnimation = (type: SharingAnimationType) => {
    // We want to do the opposite for these
    switch (type) {
        case 'fade-down-n-in':
            return fadeOutAndUpAnimation
        case 'fade-up-n-out':
            return fadeInAndDownAnimation
        default:
            return ''
    }
}
const getAnimationDelay = (
    type: SharingAnimationType | undefined,
    index: number,
    total: number,
): number => {
    switch (type) {
        case 'fade-down-n-in': {
            const indexPercentage = index / ((total - 1) / 10)
            const multiplication =
                indexPercentage >= 0 && indexPercentage < 4.5
                    ? 0.1
                    : indexPercentage >= 4.5 && indexPercentage < 8.5
                    ? 0.09
                    : indexPercentage >= 8.5
                    ? 0.08
                    : 0

            // If it's the 0%-45% number, we want to fade in at index * 0.10
            // If it's the 45-85% number, we want to fade in at index * 0.09
            // If it's the 85-100% number, we want to fade in at index * 0.08
            return index * multiplication
        }
        case 'fade-up-n-out': {
            const fakeIndex = total - 1 - index
            const indexPercentage = fakeIndex / ((total - 1) / 10)
            const multiplication =
                indexPercentage == 0
                    ? 0
                    : indexPercentage > 0 && indexPercentage < 4.5
                    ? 0.1
                    : indexPercentage >= 4.5 && indexPercentage < 8.5
                    ? 0.09
                    : indexPercentage >= 8.5
                    ? 0.08
                    : 0

            return fakeIndex * multiplication
        }
        default:
            return 0
    }
}
const getOutAnimationDelay = (
    type: SharingAnimationType | undefined,
    index: number,
    total: number,
): number => {
    // We want to do the opposite for these
    switch (type) {
        case 'fade-down-n-in':
            return getAnimationDelay('fade-up-n-out', index, total)
        case 'fade-up-n-out':
            return getAnimationDelay('fade-down-n-in', index, total)
        default:
            return 0
    }
}

export const getAnimationCss = (
    type: SharingAnimationType | undefined,
    index: number,
    total: number,
) => {
    const showAnimation = type !== undefined
    const anim = showAnimation ? getAnimation(type) : ``
    const animDelay = showAnimation ? getAnimationDelay(type, index, total) : 0
    const outAnim = showAnimation ? getOutAnimation(type) : ``
    const outAnimDelay = showAnimation
        ? getOutAnimationDelay(type, index, total)
        : 0

    return showAnimation
        ? {
              opacity: 0,
              display: 'none',

              '&.visible': {
                  animation: showAnimation
                      ? `${anim} 0.25s ease-out ${animDelay}s`
                      : '',
                  display: 'flex',
                  animationFillMode: 'both',
                  opacity: 1,
              },
              '&.invisible': {
                  animation: showAnimation
                      ? `${outAnim} 0.25s ease-out ${outAnimDelay}s`
                      : '',
                  display: 'flex',
                  animationFillMode: 'both',
              },

              // If the client prefers reduced motion, we don't want animations to show
              '@media (prefers-reduced-motion: reduce)': {
                  opacity: '1 !important',
                  animation: 'none !important',
              },
          }
        : {
              transition: 'background 0.2s',
          }
}
