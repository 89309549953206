import React from 'react'
import { ColumnProps, TableDataProps } from './AflPlayerStatsWidget'
import {
    StyledAflPlayerStatsWidgetTableBody,
    StyledAflPlayerStatsWidgetTableCell,
} from './AflPlayerStatsWidget.styled'

interface PlayerStatsTableBodyProps {
    columns: ColumnProps[]
    tableData: TableDataProps[]
    sortedColumnIndex: number
}

export const PlayerStatsTableBody = ({
    columns,
    tableData,
    sortedColumnIndex,
}: PlayerStatsTableBodyProps) => {
    return (
        <StyledAflPlayerStatsWidgetTableBody>
            {tableData.map((playerData) => {
                return (
                    <tr key={playerData.playerName}>
                        {columns.map(({ accessor }) => {
                            const cellValue = playerData[
                                accessor as keyof TableDataProps
                            ]
                                ? playerData[accessor as keyof TableDataProps]
                                : '-'
                            return (
                                <StyledAflPlayerStatsWidgetTableCell
                                    sortedColumnIndex={sortedColumnIndex}
                                    key={`${playerData.playerName}-${accessor}`}
                                >
                                    {cellValue}
                                </StyledAflPlayerStatsWidgetTableCell>
                            )
                        })}
                    </tr>
                )
            })}
        </StyledAflPlayerStatsWidgetTableBody>
    )
}
