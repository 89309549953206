import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import React from 'react'
import { LazyGeneralInArticleBanner } from '../../../banners/GenericInArticleBanner'
import {
    InlineContentTypes,
    InlineQueenTributeBanner,
} from '../SharedPublication.routing'

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineQueenTributeBanner(
    content: InlineContentTypes,
): content is InlineQueenTributeBanner {
    return content.kind === 'inline-queen-tribute-banner'
}

export function getInlinedPositionedQueenTributeBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    const validTopics = [
        'news/royal-family',
        'news/queen-elizabeth-ii',
        'news/king-charles-iii',
    ]

    if (
        (!validTopics.includes(publication.primaryTopic.id) &&
            !publication.secondaryTopics.find((topic) =>
                validTopics.includes(topic.id),
            )) ||
        publication.primaryTopic.id === 'news/queen-elizabeth-ii/tributes'
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineQueenTributeBanner)
        .map((content: InlineQueenTributeBanner) => {
            return {
                position: content.insertAfter,
                element: (
                    <LazyGeneralInArticleBanner
                        onEvent={onEvent}
                        heading={content.props.heading}
                        subText={content.props.subText}
                        buttonText={content.props.buttonText}
                        driverLink={content.props.driverLink}
                        color={content.props.color}
                        feature={content.props.feature}
                    />
                ),
            }
        })
}
