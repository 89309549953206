import {
    BreakpointState,
    calcRem,
    colors,
    PartialComponentTheme,
    PerthNowTheme,
    PerthNowThemeConfig,
    SevenNewsThemeConfig,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
} from '../../__styling'
import { TextTransformProperty, FlexDirectionProperty } from 'csstype'

export type SectionHeaderTheme<T extends ThemeConfig> = {
    background?: string
    breakpointState?: BreakpointState<
        T,
        {
            padding?: string
            margin?: string
        }
    >
    minHeight?: string
    heading?: {
        flexDirection?: FlexDirectionProperty
        gap?: number
        textType?: BreakpointState<T, T['textType']>
        textTransform?: TextTransformProperty
        textColor?: string
        padding?: string
        verticalGap?: string
        active?: {
            background: string
        }
        hover?: {
            background: string
        }
        section?: {
            fontSize: string
            lineHeight: string
            fontWeight: number
            textTransform: TextTransformProperty
            display: BreakpointState<T, string>
        }
    }
    chevron?: {
        color: string
    }
    /** Divider underneath the heading. */
    divider?: {
        width: string
        height: string
        color: string
    }
    readMore?: {
        textType: T['textType']
        textColor: string
        gap: string
    }
    nav?: {
        padding?: string
        link: {
            textType: T['textType']
            textColor: string
            padding?: string
            active?: {
                background: string
            }
            hover?: {
                background: string
            }
        }
        divider: {
            height: string
            color: string
        }
    }
}

export const perthNowSectionHeaderTheme = (scheme: PerthNowTheme) => {
    const theme: SectionHeaderTheme<PerthNowThemeConfig> = {
        background: scheme.sectionValues.primaryColor,
        breakpointState: {
            default: {
                padding: calcRem(0, 2),
            },
        },
        minHeight: calcRem(36),
        heading: {
            textType: { default: 'Heading/H6' },
            textTransform: 'uppercase',
            textColor: scheme.colors.palette.white,
            padding: calcRem(4, 8),
            hover: {
                background: 'rgba(0,0,0,0.5)',
            },
            active: {
                background: 'rgba(243, 147, 147, 0.2)',
            },
        },
        chevron: {
            color: scheme.colors.palette.white,
        },
        nav: {
            padding: calcRem(0, 3, 0, 0),
            link: {
                textType: 'Label/M/Semibold',
                textColor: scheme.colors.palette.white,
                padding: calcRem(4),
                hover: {
                    background: 'rgba(0,0,0,0.5)',
                },
                active: {
                    background: 'rgba(255,255,255,0.2)',
                },
            },
            divider: {
                color: scheme.colors.palette.white,
                height: calcRem(12),
            },
        },
    }
    return theme
}

export const perthNowSponsoredSectionHeaderOverride =
    (border: boolean) =>
    (scheme: PerthNowTheme): PartialComponentTheme<PerthNowThemeConfig> => {
        const theme: PartialComponentTheme<PerthNowThemeConfig> = {
            sectionHeader: {
                background: 'transparent',
                breakpointState: {
                    default: {
                        padding: calcRem(0),
                    },
                },
                minHeight: calcRem(33),
                heading: {
                    textType: { default: 'Heading/H5' },
                    textColor: scheme.colors.text.heading,
                    verticalGap: calcRem(4),
                    padding: 'unset',
                },
                divider: border
                    ? {
                          width: '100%',
                          height: calcRem(4),
                          color: scheme.colors.text.heading,
                      }
                    : undefined,
            },
        }
        return theme
    }

export const sevenNewsSectionHeaderTheme: SectionHeaderTheme<SevenNewsThemeConfig> =
    {
        heading: {
            textType: { default: 'header' },
            textTransform: 'none',
        },
    }

export const theWestSectionHeaderTheme = (scheme: TheWestTheme) => {
    const theme: SectionHeaderTheme<TheWestThemeConfig> = {
        heading: {
            gap: 8,
            textType: { default: 'head-5' },
            textTransform: 'uppercase',
            textColor: scheme.colors.text.secondary,
            verticalGap: calcRem(4),
        },
        divider: {
            width: calcRem(31),
            height: calcRem(3),
            color: scheme.colors.palette.black,
        },
        chevron: {
            color: scheme.colors.text.secondary,
        },
        nav: {
            link: {
                textType: 'medium/14',
                textColor: scheme.colors.text.tertiary ?? 'unset',
            },
            divider: {
                color: scheme.colors.borders.primary,
                height: calcRem(16),
            },
        },
    }
    return theme
}

export const theNightlySectionHeaderTheme = (scheme: TheNightlyTheme) => {
    const theme: SectionHeaderTheme<TheNightlyThemeConfig> = {
        background: scheme.sectionValues.backgroundColor,
        breakpointState: {
            default: {
                padding: calcRem(32, scheme.margins.sm, scheme.margins.sm),
                margin: calcRem(0, 24),
            },
            sm: {
                padding: calcRem(48, scheme.margins.md, scheme.margins.md),
                margin: calcRem(0, 32),
            },
            lg: {
                padding: calcRem(64, scheme.margins.md, scheme.margins.md),
                margin: 'unset',
            },
        },
        readMore: {
            textType: 'body-alt',
            textColor: scheme.colors.text.primary,
            gap: calcRem(scheme.margins.sm),
        },
        heading: {
            flexDirection: 'column',

            textType: { default: 'section-header' },
            textTransform: 'capitalize',
            textColor: scheme.sectionValues.primaryColor,
            verticalGap: calcRem(scheme.margins.xs),

            section: {
                fontSize: calcRem(16),
                fontWeight: 500,
                lineHeight: calcRem(24),
                textTransform: 'uppercase',

                display: {
                    default: 'flex',
                    lg: 'none',
                },
            },
        },
    }
    return theme
}

export const theNightlyTextOnlySectionHeaderOverride: PartialComponentTheme<TheNightlyThemeConfig> =
    {
        sectionHeader: {
            background: 'unset',
            breakpointState: undefined,
            readMore: undefined,
            heading: {
                textType: {
                    default: 'section-header',
                },
                textColor: colors.thenightly.neutral[100],
            },
        },
    }

export const theNightlySmallSectionHeaderOverride: PartialComponentTheme<TheNightlyThemeConfig> =
    {
        sectionHeader: {
            background: 'unset',
            breakpointState: undefined,
            readMore: undefined,
            heading: {
                textType: {
                    default: 'section-header',
                    lg: 'subheading',
                },
            },
        },
    }

export const theNightlySearchFilterHeaderOverride: PartialComponentTheme<TheNightlyThemeConfig> =
    {
        sectionHeader: {
            background: 'unset',
            breakpointState: undefined,
            readMore: undefined,
            heading: {
                textType: {
                    default: 'section-header',
                    lg: 'subheading',
                },
                textColor: colors.thenightly.neutral[100],
                textTransform: 'none',
            },
        },
    }

export const aflMatchCentreSectionHeaderOverride: PartialComponentTheme<TheWestThemeConfig> =
    {
        sectionHeader: {
            divider: {
                width: '100%',
            },
        },
    }

export const sponsoredSectionHeaderOverride: PartialComponentTheme<TheWestThemeConfig> =
    {
        sectionHeader: {
            heading: {
                textType: {
                    default: 'medium/23',
                },
                textTransform: 'none',
            },
        },
    }
