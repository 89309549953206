import React from 'react'
import { IconCrossCircled, IconTickCircled } from '../../../../icons'
import { SubscriptionPackageFeatureValue } from './MiniSubscriptionPackage'
import { StyledMiniSubscriptionPackageBenefit } from './MiniSubscriptionPackage.styled'
import { SubscriptionPackageBenefitValue } from './SubscriptionPackage'
import { StyledSubscriptionPackageBenefit } from './SubscriptionPackage.styled'

export interface SubscriptionPackageBenefitProps {
    benefit: SubscriptionPackageBenefitValue | SubscriptionPackageFeatureValue
    containerComponent?:
        | typeof StyledSubscriptionPackageBenefit
        | typeof StyledMiniSubscriptionPackageBenefit
    color?: string
}

export const SubscriptionPackageBenefit = ({
    benefit,
    containerComponent = StyledSubscriptionPackageBenefit,
    color,
}: SubscriptionPackageBenefitProps) => {
    const Container = containerComponent
    return (
        <Container>
            {benefit.isAvailable ? (
                <IconTickCircled
                    width={20}
                    height={20}
                    idSuffix={
                        benefit.name.replace(/\W/g, '-') +
                        Math.random().toFixed(5).replace('0.', '')
                    }
                    color={color}
                />
            ) : (
                <IconCrossCircled width={20} height={20} />
            )}
            <span>{benefit.name}</span>
        </Container>
    )
}
