import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    isPublicationCardItem,
} from '@news-mono/web-common'
import React from 'react'
import { useLocation } from 'react-router'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../data-controllers/LoadMore/LoadMore'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import { November } from '../../collections/November/November'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { useProduct } from '../../__product/useProduct'
import { Section } from '../../__styling/settings/sections'
import TVGuideSideBarWidgetTwo from '../../tv-guide-widgets/TVGuideSideBarWidgets/TVGuideSideBarWidgetTwo'
import TVGuideSideBarWidgetThree from '../../tv-guide-widgets/TVGuideSideBarWidgets/TVGuideSideBarWidgetThree'
import TVGuideSideBarWidgetOne from '../../tv-guide-widgets/TVGuideSideBarWidgets/TVGuideSideBarWidgetOne'
import { useViewport } from '../../__helpers/use-viewport'

export interface NovemberRouteProps
    extends CollectionRouteProps,
        LoadMoreRouteProps {
    collectionSection?: Section
    timestamp?: TimestampType
    truncateListCards?: boolean
    hasBackground?: boolean
    contentIsTangential?: boolean
}

export const NovemberRegistration = createRegisterableComponentWithData(
    'november',
    ContentDataDefinitionLoader,
    (props: NovemberRouteProps, data, services) => {
        const location = useLocation()
        const product = useProduct()
        const { isTabletOrMobile } = useViewport()

        const renderTVGuideSideBarWidgets = {
            'tvguidetonight-widget-now': <TVGuideSideBarWidgetOne />,
            'tvguidetonight-widget-shows': <TVGuideSideBarWidgetTwo />,
            'tvguidetonight-widget-combined': <TVGuideSideBarWidgetThree />,
        }

        const isTVGuideEnabled = isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            '7-news-tv-guide-widgets',
        )

        const isEntertainmentTv =
            location.pathname.includes('/entertainment/tv')

        return isTVGuideEnabled && !isTabletOrMobile && isEntertainmentTv ? (
            renderTVGuideSideBarWidgets['tvguidetonight-widget-combined']
        ) : (
            <LoadMore
                dataDefinition={data.dataDefinitionArgs}
                services={services}
                loadMoreEnabled={props.loadMoreEnabled === true}
                data={data}
                onLoadMore={(loadMoreData) => {
                    services.onEvent(
                        createCollectionAvailableEvent(
                            loadMoreData.result.publications,
                            'november',
                            product,
                            services.onEvent,
                        ),
                    )
                }}
                render={(loadMoreData) => {
                    return (
                        <November
                            items={
                                loadMoreData.loaded
                                    ? {
                                          loaded: true,
                                          result: loadMoreData.result.publications.filter(
                                              isPublicationCardItem,
                                          ),
                                      }
                                    : { loaded: false }
                            }
                            expectedCards={calculateExpectedCardCountFromContentData(
                                data.dataDefinitionArgs,
                            )}
                            sectionHeader={props.sectionHeader}
                            section={props.collectionSection}
                            timestamp={props.timestamp}
                            onEvent={services.onEvent}
                            verticalSpacing={props.verticalSpacing}
                            truncateListCards={props.truncateListCards}
                            hasBackground={props.hasBackground}
                            contentIsTangential={props.contentIsTangential}
                            currentPath={location.pathname}
                            toggles={toFeatureState(
                                services.store.getState().toggles,
                            )}
                        />
                    )
                }}
            />
        )
    },
)
