import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Coral, CoralProps } from './Coral'

type CoralRegProps = Omit<Omit<CoralProps, 'onCommentEvent'>, 'userSettings'>

export const CoralRegistration = createRegisterableComponent(
    'coral',
    (props: CoralRegProps, services) => (
        <Coral
            storyID={props.storyID}
            storyURL={props.storyURL}
            articleCommentsEnabled={props.articleCommentsEnabled}
            horizontalSpacing={props.horizontalSpacing}
            onCommentEvent={services.onEvent}
            userSettings={{
                onEvent: () => {},
                config: services.config,
                log: services.log,
            }}
        />
    ),
)
