import styled from '@emotion/styled'
import { calcRem, metrics, ThemeMargins } from '..'

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledVideoHub = styled('div')<GridProps>(
    ({ verticalGutters, verticalSpacing, theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
        minHeight: calcRem(600),
    }),
)
