import seedrandom from 'seedrandom'
/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export function shuffleArray<T>(array: Array<T>, seed?: string) {
    const rngSeeder = seed ? seedrandom(seed) : undefined
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(
            (rngSeeder ? rngSeeder() : Math.random()) * (i + 1),
        )
        swapItems(array, i, j)
    }
    return array
}

// This does the swap in place
export const swapItems = <T>(
    arr: Array<T>,
    firstIndex: number,
    secondIndex: number,
) => {
    const temp = arr[firstIndex]
    arr[firstIndex] = arr[secondIndex]
    arr[secondIndex] = temp

    return arr
}
