import React from 'react'
import {
    StyledBannerMessage,
    StyledCTA,
    StyledLink,
} from '../../banners/LinkBanner/LinkBanner.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface LinkBannerProps {
    url: string
    message: string
    cta: string
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const LinkBanner: React.FC<LinkBannerProps> = ({
    url,
    message,
    cta,
    verticalGutters,
}) => (
    <StyledLink
        to={url}
        openExternalInNewWindow={true}
        verticalGutters={verticalGutters}
    >
        <StyledBannerMessage>{message}</StyledBannerMessage>
        <StyledCTA>{cta}</StyledCTA>
    </StyledLink>
)
LinkBanner.displayName = 'LinkBanner'
