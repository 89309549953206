import { AdState, IsHydrating } from '@news-mono/web-common'
import React from 'react'
import { AdsByBreakpoint } from './DebugAdsByBreakpoint'
import {
    adUnitListClassName,
    StyledDebugAdUnitListContainer,
    StyledOverviewButton,
} from './DebugAdUnitsList.styled'

interface DebugAdUnitsListProps {
    pathname: string
    ads: AdState | undefined
}

const scrollToOverview = () => {
    const item = document.getElementsByClassName(adUnitListClassName)
    if (item) {
        item[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
}

export const DebugAdUnitsList: React.FC<DebugAdUnitsListProps> = ({
    pathname,
    ads,
}) => {
    const pageAds = `page: ${pathname} ads`
    const isHydrating = React.useContext(IsHydrating)

    if (!ads || isHydrating) {
        return null
    }

    return (
        <StyledDebugAdUnitListContainer>
            <StyledOverviewButton onClick={() => scrollToOverview()}>
                Ads Overview
            </StyledOverviewButton>
            <AdsByBreakpoint ads={ads} pageAds={pageAds} />
        </StyledDebugAdUnitListContainer>
    )
}
DebugAdUnitsList.displayName = 'DebugAdUnitsList'
