import { Product } from '@news-mono/web-common'
import React from 'react'
import { ErrorPage } from '../ErrorPage'

// Following format for PageNotFound
export const generateText = (product: Product) => {
    switch (product) {
        default:
            return "Sorry, but the page you've requested is unavailable in your region."
    }
}

export const PageIsUnavailable: React.FC = () => {
    return (
        <React.Fragment>
            <ErrorPage text={generateText} attribution={true} />
        </React.Fragment>
    )
}
