import {
    retrieveAccessToken,
    BaseClientConfig,
    ListingCollectionDataLoaded,
    mapListPublication,
} from '@news-mono/web-common'
import { Paging } from '../data-controllers/Pagination/usePagination'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import qs from 'qs'

export const errorToastText = 'Couldn’t save article'
export const errorToastSubText = 'Please refresh page or try again.'

export const emptyGetSavedArticlesResult = {
    loadMorePossible: false,
    kind: 'listing',
    publications: [],
    total: 0,
} as ListingCollectionDataLoaded

type InteractionType = 'read' | 'saved' | 'viewed'

interface SavedDocument {
    id: number
    userId: string
    publicationId: string
    interactionType: InteractionType
    lastUpdated: Date
}

export interface GetSavedDocumentResult {
    status: number
    statusText?: string
    data?: {
        total: number
        documents: SavedDocument[]
    }
}

interface CustomRequestInit {
    method: string
    body?: any
}

interface GetSavedArticlesOptions {
    topics?: string[]
    paging?: Paging
    publishedSince?: string
    sort?: string
}

export const getSavedPublications = async (
    config: BaseClientConfig,
    userId: string,
) => {
    try {
        if (!userId)
            return {
                total: 0,
            }

        const url = `${config.profileApi}/user-profile/user-interaction/user/${userId}/type/saved`
        const requestConfig = getRequestConfig()

        return fetch(url, requestConfig).then((response) => response.json())
    } catch (e) {
        return { total: 0 }
    }
}

export const getSavedPublicationsByUserId = async (
    config: BaseClientConfig,
    userId: string,
    options: GetSavedArticlesOptions,
): Promise<ListingCollectionDataLoaded> => {
    try {
        if (!userId) return emptyGetSavedArticlesResult

        const { paging = {} as Paging } = options
        const query = {
            page: paging.page || 1,
            page_size: paging.pageSize || 8,
            page_offset: paging.pageOffset || 0,
            topics: options.topics,
            publishedSince: options.publishedSince,
            sort: options.sort || 'desc',
        }

        // construct the query string
        const queryString = query ? qs.stringify(query) : ''
        const prefixedQueryString = queryString === '' ? '' : `?${queryString}`

        const url = `${config.profileApi}/user-profile/saved-articles/${userId}${prefixedQueryString}`
        const requestConfig = getRequestConfig()

        const savedArticles = await fetch(url, requestConfig).then((response) =>
            response.json(),
        )

        const mappedListing = savedArticles.documents
            ? savedArticles.documents.map(
                  (listPublication: ListPublicationV4DTO) =>
                      mapListPublication(listPublication),
              )
            : []

        return {
            loadMorePossible: savedArticles.morePublicationsAvailable,
            kind: 'listing',
            publications: mappedListing,
            total: savedArticles.total,
            topicCounts: savedArticles.topicCounts,
        } as ListingCollectionDataLoaded
    } catch (e) {
        return emptyGetSavedArticlesResult
    }
}

export const saveArticleToUserProfile = async (
    config: BaseClientConfig,
    userId: string,
    publicationId?: string,
) => {
    if (!publicationId)
        return {
            status: 400,
            statusText: errorToastText,
            data: undefined,
        }

    if (!userId)
        return {
            status: 400,
            statusText: errorToastText,
            data: undefined,
        }

    try {
        const url = `${config.profileApi}/user-profile/user-interaction`
        const customInit = {
            method: 'POST',
            body: JSON.stringify({
                userId,
                publicationId,
                interactionType: 'saved',
            }),
        }

        const requestConfig = getRequestConfig(customInit)
        const myRequest = new Request(url, requestConfig)

        return fetch(myRequest)
            .then((response) => response.json())
            .catch(() => {
                return {
                    status: 500,
                    statusText: errorToastText,
                    data: undefined,
                }
            })
    } catch (e) {
        return {
            status: 500,
            statusText: errorToastText,
            data: undefined,
        }
    }
}

export const unsaveArticleFromUserProfile = async (
    config: BaseClientConfig,
    id?: string,
) => {
    if (!id)
        return {
            status: 400,
            statusText: errorToastText,
            data: undefined,
        }

    try {
        const url = `${config.profileApi}/user-profile/user-interaction/${id}`
        const requestConfig = getRequestConfig({ method: 'DELETE' })

        return fetch(url, requestConfig)
            .then((response) => response.json())
            .catch(() => {
                return {
                    status: 500,
                    statusText: errorToastText,
                    data: undefined,
                }
            })
    } catch (e) {
        return {
            status: 500,
            statusText: errorToastText,
            data: undefined,
        }
    }
}

export const isPublicationSaved = (
    publicationId: string,
    savedDocuments: SavedDocument[],
) => {
    return savedDocuments.find(
        (pub) =>
            pub.publicationId.toUpperCase() === publicationId.toUpperCase(),
    )
}

const getRequestConfig = (customRequestInit?: CustomRequestInit) => {
    const accessTokenState = retrieveAccessToken()
    return {
        ...customRequestInit,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenState?.accessToken}`,
        },
    }
}
