import H from 'history'

interface isCurrentOptions {
    exact?: boolean
}

export function isExternalLink(to: string, rel?: string) {
    return (
        to.indexOf('//') === 0 || to.indexOf('http') === 0 || rel === 'external'
    )
}

export function isNonHttpLink(to: string) {
    return to.indexOf('mailto:') === 0 || to.indexOf('tel:') === 0
}

export function isAnchorLink(to: string) {
    return to.charAt(0) === '#'
}

export function isCurrent(
    location: H.Location,
    link: string,
    opts?: isCurrentOptions,
) {
    if (opts && opts.exact) {
        return location.pathname === link
    }

    return location.pathname.indexOf(link) === 0
}

const whitelistForInternalDomains = [
    'thenightly.com.au',
    'thewest.com.au',
    'perthnow.com.au',
    '7news.com.au',
    '7plus.com.au',
    'albanyadvertiser.com.au',
    'amrtimes.com.au',
    'broomead.com.au',
    'bunburyherald.com.au',
    'bdtimes.com.au',
    'countryman.com.au',
    'geraldtonguardian.com.au',
    'gsherald.com.au',
    'harveyreporter.com.au',
    'kalminer.com.au',
    'kimberleyecho.com.au',
    'mbtimes.com.au',
    'midwesttimes.com.au',
    'narroginobserver.com.au',
    'northwesttelegraph.com.au',
    'pilbaranews.com.au',
    'soundtelegraph.com.au',
    'swtimes.com.au',
    'westtravelclub.com.au',
    'westannouncements.com.au',
    'thewestclassifieds.com.au',
    'events.thewest.com.au',
    'thegame.com.au',
    'streamer.com.au',
    'tvguidetonight.com.au',
    'placeanad.thewest.com.au',
    'advertising.sevenwestmedia.com.au',
    'sevenwestmedia.com.au',
    'telethon7.com',
    'www.carexpert.com.au',
]
/**
 * Checks  a url against a list of internal domains to see if it includes a whitelisted domain
 * @param url the url to check
 * @returns true if whitelisted
 */
export function searchCrawlerMastheadWhitelist(url: string) {
    for (const masthhead in whitelistForInternalDomains) {
        if (url.includes(whitelistForInternalDomains[masthhead])) {
            return true
        }
    }
    return false
}
