import React from 'react'
import { StyledHeadline } from '../../cards/CardText/CardText.styled'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { useProduct } from '../../__product/useProduct'
import { FontScales } from '../../__styling/settings/fontScale'

export interface CardHeadlineSkeletonProps {
    hasBackground?: boolean
    className?: string
    isLarge?: boolean
    fontScale: FontScales
    count?: number // optional count for skeleton
}

export const CardHeadlineSkeleton: React.FC<CardHeadlineSkeletonProps> = (
    props,
) => {
    const product = useProduct()
    const perthNowTheme = product === 'perthnow'
    const sevennewsTheme = product === 'sevennews'
    const hasBackgroundColor =
        (perthNowTheme || sevennewsTheme) && props.hasBackground

    return (
        <StyledHeadline fontScale={props.fontScale} className={props.className}>
            <Skeleton dark={hasBackgroundColor || false} count={props.count} />
        </StyledHeadline>
    )
}
CardHeadlineSkeleton.displayName = 'CardHeadlineSkeleton'
