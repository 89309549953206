import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const StyledHeading = styled('h1')(({ theme }) => ({
    ...breakWords,
    fontFamily: theme.fonts.heading,
    fontWeight: themedValue(theme, {
        thewest: 600,
        fallback: 700,
    }),
    fontSize: themedValue(theme, {
        thewest: calcRem(26),
        thenightly: calcRem(40),
        fallback: calcRem(24),
    }),
    lineHeight: themedValue(theme, {
        thewest: 1.158,
        fallback: 1,
    }),
    textTransform: themedValue(theme, {
        thewest: undefined,
        thenightly: undefined,
        fallback: 'uppercase' as const,
    }),
    color: themedValue(theme, {
        thewest: colors.black,
        perthnow: colors.perthnow.greyCoal,
        sevennews: colors.sevennews.charade,
        thenightly: tokens.thenightly.colors.text.heading,
        fallback: undefined,
    }),
    marginTop: themedValue(theme, {
        thewest: calcRem(14),
        fallback: 0,
    }),
    marginBottom: themedValue(theme, {
        thewest: calcRem(14),
        thenightly: calcRem(metrics.thenightly.margins.m2l),
        fallback: 0,
    }),

    [breakpoint('md')]: {
        marginBottom: themedValue(theme, {
            thewest: calcRem(14),
            thenightly: calcRem(metrics.thenightly.margins.xxxl),
            fallback: 0,
        }),
    },
}))
StyledHeading.displayName = 'StyledHeading'
