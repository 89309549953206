import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const ReturnArrow = () => (
    <svg width="11" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.414 0L0 4.53l4.414 4.572V6.367S8.277 5.38 10.937 10c0 0 .663-3.527-2.307-6.082 0-.002-1.315-1.273-4.216-1.183V0z"
            fillRule="evenodd"
        />
    </svg>
)

export const StyledReturnLink = styled('span')<{ darkTheme?: boolean }>(
    ({ darkTheme }) => [
        {
            svg: {
                marginRight: calcRem(6),
                path: {
                    fill: darkTheme ? colors.white : colors.thewest.greyGorilla,
                },
            },
            color: darkTheme ? colors.white : colors.thewest.greyGorilla,
        },
        darkTheme !== true && {
            '&:hover': {
                svg: {
                    path: {
                        fill: colors.thewest.westblue,
                    },
                },
                color: colors.thewest.westblue,
            },

            '&:focus': {
                outline: 'none',
                svg: {
                    path: {
                        fill: colors.thewest.greyHippo,
                    },
                },
                color: colors.thewest.greyHippo,
                textDecorationColor: colors.thewest.greyHippo,
                textDecoration: 'underline',
            },
        },
    ],
)

export const TheWestReturn = (props: {
    darkTheme?: boolean
    hideText?: boolean
}) => {
    return (
        <StyledReturnLink darkTheme={props.darkTheme}>
            <ReturnArrow />
            {props.hideText !== true && 'thewest.com.au'}
        </StyledReturnLink>
    )
}
