import styled from '@emotion/styled'
import { ShareOption } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'

interface StyledSharingProps {
    isSticky?: boolean
    layout?: 'stacked-md-desktop'
}

export const StyledSharing = styled('div')<StyledSharingProps>(
    ({ theme }) => ({
        margin: themedValue(theme, {
            perthnow: calcRem(0, -metrics.perthnow.margins.sm / 2),
            fallback: calcRem(0, -4),
        }),
    }),
    ({ isSticky, theme }) =>
        isSticky && {
            position: 'sticky',
            top: theme.margins.md,
        },

    ({ layout }) =>
        layout === 'stacked-md-desktop' && {
            [breakpoint('md')]: {
                width: calcRem(48),
            },
        },
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            [breakpointMax('md')]: {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                marginTop: calcRem(metrics.sevennews.margins.sm),
                marginBottom: calcRem(
                    metrics.sevennews.margins.md + metrics.sevennews.margins.sm,
                ),

                '&::before': {
                    content: `'Share: '`,
                    marginleft: calcRem(6),
                    marginRight: calcRem(8),
                    display: 'inline-block',
                },
            },
        },
    {
        // amp specific styles
        [`.amp-sharing-button--rounded`]: {
            marginLeft: 0,
            marginRight: metrics.sevennews.margins.lg,
        },
        [`.amp-sharing-button--rounded:last-child`]: {
            marginRight: 0,
        },
    },
)

const getSocialColor = (theme: Theme, shareOption: ShareOption) => {
    switch (shareOption) {
        case 'facebook':
            return colors.social.facebook
        case 'twitter':
            return themedValue(theme, {
                sevennews: colors.sevennewsSocial.twitter,
                fallback: colors.social.twitter,
            })
        case 'whatsapp':
            return colors.social.whatsapp
        case 'email':
            return themedValue(theme, {
                sevennews: colors.sevennewsSocial.email,
                fallback: colors.social.email,
            })
        case 'reddit':
            return colors.social.reddit
        case 'clipboard':
            return themedValue(theme, {
                sevennews: colors.sevennewsSocial.clipboard,
                fallback: colors.social.clipboard,
            })
        default:
            return
    }
}

export interface StyledButtonProps {
    shareOption: ShareOption
    isGhostStyle?: boolean
}

export const StyledButton = styled('button')<StyledButtonProps>(
    {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        width: calcRem(32),
        height: calcRem(32),
        borderRadius: calcRem(32),
        ...transition({
            properties: [
                'background-color',
                'border-color',
                'transform',
                'color',
            ],
        }),
    },
    ({ theme, shareOption, isGhostStyle }) => ({
        color: isGhostStyle ? getSocialColor(theme, shareOption) : colors.white,
        border: isGhostStyle
            ? `1px solid ${colors.perthnow.greyPyrite}`
            : `1px solid ${getSocialColor(theme, shareOption)}`,
        backgroundColor: isGhostStyle
            ? 'transparent'
            : getSocialColor(theme, shareOption),
        margin: themedValue(theme, {
            perthnow: calcRem(metrics.perthnow.margins.sm / 2),
            fallback: calcRem(0, 2),
        }),
    }),

    ({ theme, isGhostStyle, shareOption }) => ({
        '&:hover': {
            borderColor: themedValue(theme, {
                thewest: (section) => section.primaryColor,
                fallback: getSocialColor(theme, shareOption),
            }),
            backgroundColor: themedValue(theme, {
                thewest: (section) => section.primaryColor,
                fallback: isGhostStyle
                    ? getSocialColor(theme, shareOption)
                    : colors.white,
            }),
            color: themedValue(theme, {
                thewest: colors.white,
                fallback: isGhostStyle
                    ? colors.white
                    : getSocialColor(theme, shareOption),
            }),
        },

        '&:focus, &:active': {
            outline: 'none',
            borderColor: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.white,
                sevennews: colors.sevennews.charade,
                fallback: undefined,
            }),
            backgroundColor: themedValue(theme, {
                perthnow: colors.perthnow.greyBasalt,
                fallback: colors.white,
            }),
            color: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.white,
                sevennews: colors.white,
                fallback: undefined,
            }),
        },

        '&:active': {
            transform: themedValue(theme, {
                perthnow: undefined,
                fallback: 'scale(0.9)',
            }),
        },
    }),
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            margin: calcRem(0, 8),
            width: calcRem(32),
            height: calcRem(32),
            backgroundColor: colors.sevennews.bauhausBlack50,
            borderColor: 'transparent',
            '&:hover, &:focus, &:active': {
                backgroundColor: theme.sectionValues.primaryColor,
                borderColor: 'transparent',
                color: colors.sevennews.white,
            },
            [breakpoint('md')]: {
                width: calcRem(32),
                height: calcRem(32),
                margin: calcRem(
                    metrics.sevennews.margins.md / 2,
                    metrics.sevennews.margins.sm,
                    metrics.sevennews.margins.md / 2,
                    0,
                ),
            },
        },
)

export const StyledIcon = styled('div')({
    fill: 'currentColor',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%', // relative to button size
    height: '60%',
    pointerEvents: 'none',
})

export const FacebookStyledIcon = styled(StyledIcon)({
    top: `65%`,
    width: '75%', // relative to button size
    height: '75%',
})
