import { ConfigurationContext } from '@news-mono/web-common'
import React from 'react'
import { RSSList } from './components/RSSList/RSSList'
import { rssItems } from './data'

export const RSS: React.FC = () => (
    <ConfigurationContext.Consumer>
        {(config) => <RSSList items={rssItems(config.publicUrl)} />}
    </ConfigurationContext.Consumer>
)
