import { layout } from '../../App.routing'

export const videoHubSegment = (adUnitPath: string) => {
    return [
        layout.component({
            type: 'thewest-video-hub',
            feature: 'video-hub',
            props: {
                verticalGutters: ['unset', 'xl'],
                adUnitPath: adUnitPath,
                dataDefinitionArgs: {
                    topicTag: 'news/top-video',
                },
            },
        }),
    ]
}
