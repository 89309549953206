/* eslint-disable @typescript-eslint/no-var-requires */
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { StaticRoute, StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/Landing Page Banner_Desktop 2x.jpg')

const bannerDesktopX1 = require<string>('./assets/Landing Page Banner_Desktop 1x.jpg')

const bannerTabletX2 = require<string>('./assets/Landing Page Banner_Tablet 2x.jpg')

const bannerTabletX1 = require<string>('./assets/Landing Page Banner_Tablet 1x.jpg')

const bannerMobileX2 = require<string>('./assets/Landing Page Banner_Mobile 2x.jpg')

const bannerMobileX1 = require<string>('./assets/Landing Page Banner_Mobile 1x.jpg')

const socialImage = require('./assets/social.jpg')

export const getYourStoryOurStorySocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '627',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

const topic: Topic = {
    id: 'features/catching-lisa-govans-killer',
    metadata: {},
    seoTitle: `Catching Lisa's Killer - Fear and murder in Kalgoorlie`,
    title: `Catching Lisa's Killer`,
    seoDescription:
        'Lisa Govan was young, pretty and blonde. Her sudden disappearance from a busy street in Kalgoorlie 1999 should have sparked national attention. It didn’t. This is a story of murder, the underworld and a small town’s secrets. Subscribe and watch it now at thewest.com.au',
    parent: {
        id: 'features',
        metadata: {},
        seoTitle: 'Features',
        title: 'Features',
    },
}

const lisaGovanRouteInfo: StaticRoute<TheWestSection> = ({
    getAdTargeting,
    config,
}) => {
    const canonicalUrl =
        config.publicUrl + '/features/catching-lisa-govans-killer'

    return {
        kind: 'page',
        heading: topic.title,
        pageType: 'video-series',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: topic.title,
            description: topic.seoDescription,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            meta: getYourStoryOurStorySocialImageMeta,
        },
        socialMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
        },
        adTargeting: getAdTargeting('home', 'default', topic.id),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: bannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText:
                                    'Catching Lisa Killer - Fear and murder in Kalgoorlie',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: bannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerDesktopX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: bannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerTabletX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: bannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerMobileX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'janus',
                            props: {
                                layoutRatio: '3:1',
                                stretchColumns: true,
                                verticalSpacing: 'xl',
                            },
                            contentAreas: {
                                left: [
                                    layout.component({
                                        type: 'sierra',
                                        props: {
                                            fixedRatios: ['16:9'],
                                            heroImageLayout:
                                                ResponsivePictureLayout.ObjectFitContain,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                                maxBreakpoint: 'md',
                                            },

                                            cardLayout: ['hero-video'],
                                            dataDefinitionArgs: {
                                                type: 'curation',
                                                name: 'lisa-govan',
                                                offset: 0,
                                                pageSize: 1,
                                            },
                                        },
                                    }),
                                ],
                                right: [
                                    layout.component({
                                        type: 'tw-subscribe-promo-card',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                sectionHeader: {
                                    heading: 'Watch the episodes',
                                },

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'lisa-govan',
                                    offset: 1,
                                    pageSize: 8,
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'md',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                loadMoreEnabled: true,
                                sectionHeader: {
                                    heading: 'Latest Lisa Govan News',
                                },

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['news/lisa-govan'],
                                    includeSubTopics: true,
                                    paging: {
                                        page: 1,
                                        pageSize: 8,
                                        pageOffset: 0,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('two'),
                    ],
                },
            }),
        ],
    }
}

export const getLisaGovanRouteInfo: StaticRoutes<TheWestSection> = {
    '/features/catching-lisa-govans-killer': lisaGovanRouteInfo,
    '/kalgoorlie-miner/features/catching-lisa-govans-killer':
        lisaGovanRouteInfo,
}
