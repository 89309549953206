import React from 'react'
import { CameraProps, StyledCameraIcon } from './IconCamera.styled'

export interface IconCameraProps {
    title?: string
}

export const IconCamera: React.FC<IconCameraProps & CameraProps> = ({
    className,
    title,
    width,
    height,
    horizontalSpacing,
}) => (
    <StyledCameraIcon
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 14"
        fill="#000"
        horizontalSpacing={horizontalSpacing}
    >
        <title>{title || 'Camera Icon'}</title>
        <path d="M10.27 11.623c-2.02 0-3.666-1.644-3.666-3.666 0-2.022 1.645-3.667 3.667-3.667s3.668 1.645 3.668 3.667-1.646 3.666-3.667 3.666zM2.834 4.56c.7 0 1.27.572 1.27 1.272 0 .7-.57 1.27-1.27 1.27-.7 0-1.27-.57-1.27-1.27 0-.7.57-1.27 1.27-1.27zM15.19 3h-1.16c-.366-.735-.82-2.125-1.988-2.125H8.5c-.856 0-1.2.656-2.302 2.125H4.25v-.354c0-.566-.515-1.062-1.102-1.062h-.67c-.554 0-1.042.496-1.042 1.052l-.01.384C.26 3.145 0 3.916 0 4.573v7.358c0 1.077.66 1.693 1.81 1.693h13.38c1.15 0 1.81-.616 1.81-1.692V4.574c0-.718-.314-1.575-1.81-1.575" />
    </StyledCameraIcon>
)
IconCamera.displayName = 'IconCamera'
