import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { VideoTrailerCard } from '../../cards/VideoTrailerCard/VideoTrailerCard'

export interface VideoTrailerCardRouteProps {
    layoutMode: 'single' | 'double'
}
export const VideoTrailerCardRegistration = createRegisterableComponent(
    'video-trailer-card',
    (props: VideoTrailerCardRouteProps, services) => (
        <VideoTrailerCard
            layoutMode={props.layoutMode}
            onEvent={services.onEvent}
        />
    ),
)
