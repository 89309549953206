import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { IconCheckmark } from '../icons/IconCheckmark/IconCheckmark'
import { calcRem, visuallyhidden } from '../__styling'

export interface StyledFilterOptionProps {
    selected: boolean
}

export const StyledFilterOption = styled('div')<StyledFilterOptionProps>(
    ({ theme, selected }) => ({
        display: 'inline-block',
        borderRadius: calcRem(8),
        fontFamily: theme.fonts.sansSerif,
        background: selected
            ? `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`
            : 'transparent',
        color: selected
            ? theme.colors.palette.white
            : theme.colors.text.primary,
        border: `1px solid ${theme.colors.text.primary}`,
        borderColor: selected
            ? theme.colors.palette.white
            : theme.colors.text.primary,
        padding: calcRem(8),
        lineHeight: selected ? 1.1 : 1,
        marginRight: calcRem(8),
        cursor: 'pointer',
        marginBottom: calcRem(8),

        '&:hover, &:focus': {
            background: selected
                ? `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`
                : tokens.thewest.colors.palette.greyDolphin,
            color: selected
                ? theme.colors.palette.white
                : theme.colors.text.primary,
            borderColor: selected
                ? theme.colors.palette.white
                : theme.colors.text.primary,
        },
    }),
)

export const StyledCheckmark = styled(IconCheckmark)({
    marginRight: calcRem(6),
})

export const StyledFilterOptionInput = styled('input')({
    ...visuallyhidden,
})

export const StyledPollieRaterHeader = styled('div')(({ theme }) => ({
    marginTop: theme.margins.xxl,
    marginBottom: calcRem(24),
}))

export const StyledTextContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.margins.xl,
    fontSize: calcRem(17),
    lineHeight: calcRem(24),
    fontFamily: theme.fonts.serif,

    '&::after': {
        content: "''",
        position: 'absolute',
        display: 'block',
        borderBottom: `3px solid ${theme.colors.brand}`,
        width: calcRem(37),
        bottom: calcRem(-8),
    },
}))

export const StyledPollieRaterTextBlock = styled('div')(({ theme }) => ({
    position: 'relative',

    '& > h3': {
        fontSize: calcRem(22),
        lineHeight: calcRem(24),
        fontFamily: theme.fonts.serif,
        fontWeight: 700,
    },
}))
