/* eslint-disable @typescript-eslint/no-var-requires */
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

const insideCoverDesktopX2 = require<string>('./assets/inside-cover/TW-inside-cover-desktop-1220x150@2x.jpg')

const insideCoverDesktopX1 = require<string>('./assets/inside-cover/TW-inside-cover-desktop-1220x150.jpg')

const insideCoverTabletX2 = require<string>('./assets/inside-cover/TW-inside-cover-tablet-738x57@2x.jpg')

const insideCoverTabletX1 = require<string>('./assets/inside-cover/TW-inside-cover-tablet-738x57.jpg')

const insideCoverMobileX2 = require<string>('./assets/inside-cover/TW-inside-cover-mobile-411x93@2x.jpg')

const insideCoverMobileX1 = require<string>('./assets/inside-cover/TW-inside-cover-mobile-411x93.jpg')

export const getInsideCoverRouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: insideCoverDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText:
                    "Inside Cover with Ben O'Shea. Email Ben O'Shea at inside.cover@wanews.com.au",
                url: "mailto:inside.cover@wanews.com.au?subject=Website Enquiry for Inside Cover&body=For Ben O'Shea, In reference to your website column at https://thewest.com.au/opinion/inside-cover. Please type your message here...",
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: insideCoverDesktopX2, density: '2x' },
                            { src: insideCoverDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: insideCoverTabletX2, density: '2x' },
                            { src: insideCoverTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: insideCoverMobileX2, density: '2x' },
                            { src: insideCoverMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['opinion/inside-cover'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['opinion/inside-cover'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['opinion/inside-cover'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
