/* eslint-disable @typescript-eslint/no-var-requires */
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const banner = require<string>('./assets/fact-checker-banner.png')

const socialImage = require('./assets/social.jpg')

const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '643',
    },
    {
        property: 'og:image:width',
        content: '1224',
    },
]

const topic: Topic = {
    id: 'politics/election-fact-checker',
    metadata: {},
    seoTitle:
        'A daily column to cut through the spin, lies and half-truths in the Federal election campaign.',
    title: 'Election Fact Checker',
    seoDescription:
        'John Flint will use the Fact-checker column to drill down into hotly contested Federal election issues.',
    parent: {
        id: 'politics',
        metadata: {},
        seoTitle: 'Politics',
        title: 'Politics',
    },
}

export const getElectionFactCheckerRouteInfo: StaticRoutes<TheWestSection> = {
    '/politics/election-fact-checker': ({ getAdTargeting, config, store }) => {
        const canonicalUrl =
            config.publicUrl + '/politics/election-fact-checker'

        return {
            kind: 'page',
            heading: 'Election Fact Checker',
            pageType: 'homepage',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting: getAdTargeting(
                'home',
                'default',
                'politics/election-fact-checker',
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: banner,
                                    verticalGutters: ['unset', 'md'],
                                    altText: '',
                                    sources: [
                                        {
                                            src: banner,
                                        },
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'sierra',
                                props: {
                                    fixedRatios: ['16:9'],
                                    heroImageLayout:
                                        ResponsivePictureLayout.ObjectFitContain,
                                    cardOrientation: {
                                        type: 'Landscape-Portrait',
                                        maxBreakpoint: 'md',
                                    },

                                    cardLayout: [
                                        'the-west-hero',
                                        'hero-support',
                                    ],

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['politics/federal-election'],
                                        authors: ['john-flint'],
                                        paging: {
                                            page: 1,
                                            pageSize: 2,
                                        },
                                    },
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['politics/federal-election'],
                                        authors: ['john-flint'],
                                        paging: {
                                            page: 1,
                                            pageOffset: 2,
                                            pageSize: 4,
                                        },
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    hasBorder: false,

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['politics/federal-election'],
                                        authors: ['john-flint'],
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 6,
                                        },
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
