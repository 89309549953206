import {
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { ProfileCard } from './ProfileCard'

export const ProfileCardRegistration = createRegisterableComponentWithData(
    'profile-card',
    ContentDataDefinitionLoader,
    (_props: {}, data, services) => {
        const product = useProduct()
        const results = data.loaded && data.result.publications[0]
        if (results && results.cardType !== 'publication') {
            return null
        }

        return (
            <ImpressionAvailable
                loading={!data.loaded}
                available={() => {
                    if (!data.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }

                    services.onEvent(
                        createCollectionAvailableEvent(
                            data.result.publications,
                            'LandscapeStacked',
                            product,
                            services.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <ProfileCard
                        innerRef={ref}
                        item={
                            results
                                ? { loaded: true, result: results }
                                : { loaded: false }
                        }
                        onEvent={services.onEvent}
                        cardNumber={1}
                    />
                )}
            </ImpressionAvailable>
        )
    },
)
