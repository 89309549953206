import {
    createRegisterableComposition,
    mapArticle,
    RequiredAccess,
    useToggleState,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import { getPublicationPageProperties } from '../../templates/Publication/lib/publication-page-properties'
import { getPublicationInfoFromRouteResolution } from '../../templates/Publication/SharedPublication.routing'
import { FeatureArticleBreach } from './FeatureArticleBreach'

export interface FeatureArticleBreachCompositionRegistrationProps {
    requiredAccess: RequiredAccess
    article: ArticleLikeV4DTO
}

export const FeatureArticleBreachCompositionRegistration =
    createRegisterableComposition<'main' | 'common' | 'comments'>()(
        'article-breach-composition',
        (
            contentAreas,
            props: FeatureArticleBreachCompositionRegistrationProps,
            services,
        ) => {
            const publicationInfo =
                getPublicationInfoFromRouteResolution(services)
            const toggleState = useToggleState()
            const article = mapArticle(props.article, toggleState)

            // DPT-1856 - PageAdditionalProps required here at top most of featured article template or
            // events were firing twice, moved from TheWestFeature.routing
            return (
                <PageProps
                    pageProperties={getPublicationPageProperties(
                        publicationInfo.publication,
                    )}
                >
                    <FeatureArticleBreach
                        requiredAccess={props.requiredAccess}
                        location={services.location}
                        onEvent={services.onEvent}
                        commonElements={contentAreas.common}
                        comments={contentAreas.comments}
                        article={article}
                        log={services.log}
                    >
                        {contentAreas.main}
                    </FeatureArticleBreach>
                </PageProps>
            )
        },
    )
