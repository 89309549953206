import { useTheme } from '@emotion/react'
import { Topic, toTopicRouteHint } from '@news-mono/web-common'
import React from 'react'
import { StyledTag } from '../../buttons/Tag/Tag.styled'

export interface TagProps {
    topic: Topic
}

export const Tag: React.FC<TagProps> = ({ topic }) => {
    const { section } = useTheme()
    return (
        // data-section is whitelisted under amp for a tags, needed to use it here as StyledTag wraps a Link
        <StyledTag data-section={section} to={toTopicRouteHint(topic)}>
            {topic.title}
        </StyledTag>
    )
}

Tag.displayName = 'Tag'
