import React from 'react'
import {
    StyledButton,
    StyledTNPromoBannerContainer,
    StyledHeading,
    StyledTextContainer,
    StyledTheNightlyLogo,
} from './TNPromoBanner.styled'
import { themedValue } from '../../__styling'
import { useTheme } from '@emotion/react'

export const TNPromoBanner: React.FC = () => {
    const theme = useTheme()

    const url = themedValue(theme, {
        perthnow:
            'https://thenightly.com.au?utm_medium=in-article_link&utm_source=perthnow&utm_campaign=nightly_launch_promo',
        thewest:
            'https://thenightly.com.au?utm_medium=in-article_link&utm_source=thewest&utm_campaign=nightly_launch_promo',
        sevennews:
            'https://thenightly.com.au/?utm_medium=in-article_link&utm_source=7NEWS&utm_campaign=nightly_launch_promo',
        fallback: 'https://thenightly.com.au',
    })
    return (
        <StyledTNPromoBannerContainer>
            <StyledTheNightlyLogo logoWidth={208} logoHeight={24} />
            <StyledHeading>
                Get in front of tomorrow's news for FREE
            </StyledHeading>
            <StyledTextContainer>
                <p>
                    Journalism for the curious Australian across politics,
                    business, culture and opinion.
                </p>
                <StyledButton to={url}>
                    {theme.kind === 'sevennews' ? 'Read now' : 'READ NOW'}
                </StyledButton>
            </StyledTextContainer>
        </StyledTNPromoBannerContainer>
    )
}
