import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { useWebsocketServiceClient } from '../../../websocket-service-client/use-websocket-service'
import { useCallback, useRef } from 'react'
import { toEpoch } from '../helpers'

type UseLiveEventPostsParams = {
    publicationId: string
    onPostCreate: (post: EventPostV4DTO) => void
    onPostDelete: (post: KilledEventPostV4DTO) => void
}

/**
 * A hook which handles the recieved Live Event post events from websockets.
 * These are then passed to the respective create/delete callback.
 */
export const useLiveEventPosts = ({
    publicationId,
    onPostCreate,
    onPostDelete,
}: UseLiveEventPostsParams) => {
    // Maintain a map of deleted post ids + their deletion date.
    // It is assumed the majority of events are recieved in order,
    // however this should at least prevent deleted posts from reappearing if
    // the updatedDate is older than the deleted state.
    const deletedPosts = useRef(new Map<string, string>)    // Handle websocket messages.

    const messageHandler = useCallback(
        (post: EventPostV4DTO | KilledEventPostV4DTO) => {
            if (post.state === 'published') {
                // Discard if the post is deleted in a chronologically later update.
                if (isPostCreateOutdated(deletedPosts.current, post)) return

                onPostCreate(post)
            } else if (post.state === 'deleted') {
                deletedPosts.current.set(post.id, post.lastUpdated)

                onPostDelete({
                    id: post.id,
                    state: 'deleted',
                    eventId: post.eventId,
                    lastUpdated: post.lastUpdated,
                    publishedDate: post.publishedDate,
                })

            }
        },
        [onPostCreate, onPostDelete],
    )

    useWebsocketServiceClient<EventPostV4DTO>({
        entityId: publicationId,
        onSubscribe: (messages) =>
            messages.map(({ payload: post }) => messageHandler(post)),
        onMessage: ({ payload: post }) => messageHandler(post),
    })
}

const isPostCreateOutdated = (deletedPostMap: Map<string, string>, post: EventPostV4DTO) => {
    const deletionDate = deletedPostMap.get(post.id)

    if (deletionDate) {
        return toEpoch(deletionDate) > toEpoch(
            post.lastUpdated
        )
    }

    return false
}

