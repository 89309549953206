import { layout } from '../../App.routing'

export const opinionSegment = layout.component({
    type: 'lima',
    props: {
        verticalSpacing: 'xl',
        fixedRatios: ['16:9'],
        intermediateColumns: 2,
        finalColumns: 4,
        hideByline: false,
        hasBackground: true,

        cardLayout: [
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            [
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
            ],
        ],
        cardOrientation: { type: 'Landscape-Portrait' },
        sectionHeader: {
            heading: 'Opinion',
            headingUrl: '/opinion',
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'opinion',
            offset: 0,
            pageSize: 6,
        },
    },
})
