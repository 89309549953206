import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface StyledBackgroundProps {
    backgroundUrl: string
}

export const StyledPromoCard = styled(WebLink)({
    display: 'block',
    borderRadius: calcRem(16),
    width: '290px',
    height: '71px',
    boxShadow: '0px 24px 13px -24px rgba(0, 0, 0, 0.4)',
    transform: 'translateY(0)',
    transition: 'all .3s ease',
    position: 'relative',
    '&:hover': {
        boxShadow: '0px 24px 13px -24px rgba(0, 0, 0, 0.0)',
        transform: 'translateY(2px)',
    },
    [breakpoint('xxs')]: {
        width: '334px',
        height: '81px',
    },
    [breakpoint('lg')]: {
        width: '432px',
        height: '104px',
    },
})

export const StyledBackground = styled('div')<StyledBackgroundProps>(
    ({ backgroundUrl }) => ({
        backgroundImage: `url("${backgroundUrl}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
    }),
)

export const StyledYoutubeLogo = styled('div')({
    position: 'absolute',
    right: '-1px',
    bottom: '-1px',
    borderRadius: '21px 0 15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    height: calcRem(40),
    width: calcRem(40),
    [breakpoint('sm')]: {
        right: 0,
    },
})
