import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const messengerBlue = '#0084FF'

export const StyledMessengerButton = styled(WebLink)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: 4,
    padding:
        theme.kind === 'thewest' && theme.section !== 'default'
            ? calcRem(4, 8)
            : calcRem(8, 10),
    border: `1px solid ${messengerBlue}`,
    fontFamily: fonts.thewest.sansSerif,
    fontSize: calcRem(14),
    fontWeight: 500,
    outline: 0,
    textDecoration: 'none',
    color: messengerBlue,

    '& > svg': {
        marginRight: calcRem(8),
        width: calcRem(19),
        height: calcRem(20),
    },

    '& > svg path:first-child': {
        fill: messengerBlue,
    },

    '&:hover': {
        backgroundColor: messengerBlue,
        color: 'white',

        '& > svg path:first-child': {
            fill: 'white',
        },
        '& > svg path:nth-child(2)': {
            fill: messengerBlue,
        },
    },

    '&:focus': {
        borderColor: colors.thewest.greyGorilla,
        backgroundColor: colors.thewest.greyGorilla,
        color: 'white',

        '& > svg path:first-child': {
            fill: 'white',
        },
        '& > svg path:nth-child(2)': {
            fill: colors.thewest.greyGorilla,
        },
    },
}))
