import React, { useState } from 'react'
import { getAuth0EmailVerifyActionPath } from '@news-mono/web-common'
import {
    onClickEmailVerify,
    StyledSaveNameContentSpinner,
} from '../../../warden/account-modify'
import { Container } from '../Container/Container'
import { Title, Text, TextContainer } from '../Text/Text'
import { VerifyEmailButton } from './VerifyEmailContent.styled'

type VerifyEmailContentProps = {
    userId: string
}

export const VerifyEmailContent = ({ userId }: VerifyEmailContentProps) => {
    const [isSending, setIsSending] = useState(false)
    const [hasSent, setHasSent] = useState(false)
    const [hasError, setHasError] = useState(false)

    return (
        <Container>
            <TextContainer>
                <Title>Join the conversation</Title>
                <Text>Please verify your account to make comments</Text>
            </TextContainer>
            <VerifyEmailButton
                disabled={isSending}
                onClick={async () => {
                    setIsSending(true)
                    await onClickEmailVerify({
                        verifyEmailHttpPath: getAuth0EmailVerifyActionPath,
                        userId,
                        onVerifySendError: () => setHasError(true),
                        onVerifySendSuccessful: () => {
                            setHasError(false)
                            setHasSent(true)
                        },
                    })
                    setIsSending(false)
                }}
            >
                {isSending ? (
                    <StyledSaveNameContentSpinner />
                ) : hasSent ? (
                    'Resend verification email'
                ) : (
                    'Send verification email'
                )}
            </VerifyEmailButton>
        </Container>
    )
}
