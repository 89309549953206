import styled, { CSSObject } from '@emotion/styled'
import { IconCamera } from '../../icons/IconCamera/IconCamera'
import { IconVideo } from '../../icons/IconVideo/IconVideo'
import { IconVideo2 } from '../../icons/IconVideo2/IconVideo2'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'
import { FigcaptionLayout } from './FigCaption'

// Using pixel widths to ensure correct calcs in older browsers and IE
const iconWidth = 17
const iconMargin = 10

export interface StyledFigCaptionProps {
    isHero?: boolean
    figcaptionLayout?: FigcaptionLayout
    // "as" this is available on emotion 10 but we are currently overloading with emotion 9 to save build time
    // https://emotion.sh/docs/styled
    as?: string
}

export const StyledFigCaption = styled('figcaption')<StyledFigCaptionProps>(
    ({ theme, isHero, figcaptionLayout }) => [
        {
            breakWords,
            fontWeight: theme.kind === 'thenightly' ? 400 : 500,
            fontFamily: themedValue(theme, {
                sevennews: theme.fonts.sansSerif,
                fallback: theme.fonts.sansSerif,
            }),
            lineHeight: themedValue(theme, {
                thewest: 1.15,
                perthnow: 1.231,
                sevennews: 1,
                thenightly: '150%',
                fallback: undefined,
            }),
            color: themedValue(theme, {
                thewest: colors.thewest.greyElephant,
                perthnow: isHero
                    ? colors.perthnow.greyStone
                    : colors.perthnow.greyMoonstone,
                sevennews: colors.sevennews.stormGrey,
                thenightly: theme.colors.text.secondary,
                fallback: undefined,
            }),
            paddingTop: themedValue(theme, {
                sevennews: calcEm(metrics.sevennews.margins.sm),
                thenightly: calcEm(theme.margins.md),
                fallback: undefined,
            }),
            paddingBottom: themedValue(theme, {
                thewest: undefined,
                thenightly: undefined,
                fallback: calcEm(theme.margins.sm),
            }),

            '&::after': {
                content: `''`,
                display: 'table',
                clear: 'both',
            },
        },
        // Figcaption position used for The West Article Feature Page - this positions
        // the figcaption as a block element on mobile, and on desktop it sits up next
        //  to the article headline
        figcaptionLayout === 'feature-article-position' && {
            margin: `${calcRem(theme.margins.md)} auto`,
            display: 'block',
            width: `calc(100% - ${calcRem(
                metrics.thewest.margins.outerMargin * 2,
            )})`,
            zIndex: zIndex.common.featureFigCaption,

            [breakpoint('md')]: {
                maxWidth: calcRem(719),
            },

            '@media screen and (min-width: 1180px)': {
                position: 'absolute',
                top: calcRem(126),
                right: 0,
                maxWidth: calcRem(225),
            },
        },
    ],
)
StyledFigCaption.displayName = 'Figcaption'

export const StyledFigCaptionText = styled('span')(({ theme }) => ({
    ...breakWords,
    display: 'block',
    float: 'left',
    maxWidth: '100%', // needed to break long words in IE and Edge
    padding: themedValue(theme, {
        thenightly: calcRem(
            0,
            metrics.thenightly.margins.md,
            0,
            metrics.thenightly.margins.md,
        ),
        fallback: undefined,
    }),
    width: themedValue(theme, {
        sevennews: undefined,
        fallback: `calc(100% - (${iconWidth}px + ${iconMargin}px))`,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(12),
        thenightly: calcRem(16),
        fallback: calcRem(14),
    }),

    [breakpoint('sm')]: {
        padding: themedValue(theme, {
            thenightly: calcRem(0),
            fallback: undefined,
        }),
    },
}))

export const Caption = styled('span')({
    display: 'inline',
})
Caption.displayName = 'Caption'

const iconStyles: CSSObject = {
    width: iconWidth,
    height: calcRem(15),
    display: 'block',
    verticalAlign: 'middle',
    float: 'left',
    marginRight: iconMargin,
    marginTop: 2, // offset to align with font
}

const iconColorStyling = (theme: Theme, isHero?: boolean) => {
    return {
        fill: themedValue(theme, {
            thewest: theme.colors.icons?.default,
            perthnow: isHero
                ? colors.perthnow.greyStone
                : colors.perthnow.greyMoonstone,
            sevennews: colors.sevennews.stormGrey,
            fallback: undefined,
        }),
    }
}

export const StyledIconImage = styled(IconCamera)<StyledFigCaptionProps>(
    iconStyles,
    ({ theme, isHero }) => iconColorStyling(theme, isHero),
)

export const StyledIconVideo = styled(IconVideo)<StyledFigCaptionProps>(
    iconStyles,
    ({ theme, isHero }) => iconColorStyling(theme, isHero),
)

export const StyledIconVideo2 = styled(IconVideo2)(({ theme }) => ({
    display: themedValue(theme, {
        thenightly: 'none',
        fallback: 'block',
    }),
    verticalAlign: 'middle',
    float: 'left',
    marginTop: 2, // offset to align with font
    marginRight: 10,
}))
