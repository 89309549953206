import styled from '@emotion/styled'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { calcRem, PerthNowThemeConfig } from '../../../../__styling'

export const Text = styled.p(({ theme }) => [
    {
        margin: 0,

        textAlign: 'center',
    },
    textMixin<PerthNowThemeConfig>(theme, 'Paragraph/M/Sans/Regular'),
])

export const Title = styled.p(({ theme }) => [
    {
        margin: 0,

        textAlign: 'center',
    },
    textMixin<PerthNowThemeConfig>(theme, 'Heading/H3'),
])

export const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: calcRem(12),
})
