import { cx } from '@emotion/css'
import React from 'react'

export interface IconInfoProps {
    dimension?: number
    className?: string
    strokeColor?: string
    filled?: boolean
}

export const IconInfo: React.FC<IconInfoProps> = ({
    className,
    strokeColor = '#000000',
    dimension = 24,
    filled = false,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={dimension}
        height={dimension}
        viewBox={`0 0 ${dimension} ${dimension}`}
        fill={filled ? strokeColor : 'none'}
        className={cx(className, 'icon-info')}
    >
        <path
            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconInfo.displayName = 'IconInfo'
