import React from 'react'

export interface IconLinkedInProps {
    title?: string
    className?: string
}

export const IconLinkedIn: React.FC<IconLinkedInProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        className={className}
    >
        <title>{title || 'Share to LinkedIn'}</title>
        <path
            d="M3.7778906 16.8440625H.2775V5.593125h3.5003906v11.2509375zM2.02875 4.0558594C.905625 4.0558594 0 3.1474219 0 2.0278125 0 .90867187.905625 0 2.02875 0c1.1182031 0 2.0264063.9084375 2.0264063 2.0278125.0002343 1.1196094-.9079688 2.0280469-2.0264063 2.0280469zM16.875 16.8440625h-3.4966406v-5.47125c0-1.3045313-.0227344-2.983125-1.816875-2.983125-1.8199219 0-2.0971875 1.4214844-2.0971875 2.8898437v5.5645313H5.9716406V5.593125h3.3517969v1.5379687H9.37125c.4664062-.8845312 1.6071094-1.8171093 3.3086719-1.8171093 3.5402344 0 4.1946093 2.3294531 4.1946093 5.3596875L16.875 16.8440625z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
)
IconLinkedIn.displayName = 'IconLinkedIn'
