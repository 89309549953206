import {
    NielsenDCRMetaData,
    NielsenRenderType,
    RegionCookieState,
    retrieveCookie,
    userLocationKey,
} from '@news-mono/web-common'

const SEVENNEWS_NIELSEN_PREFIX = '7news'

export function getSectionFromPath(pathname: string) {
    // Should not occur but if it does return unknown section
    if (pathname.indexOf('/') === -1) {
        return 'unknown'
    }

    const item = pathname.split('/')[1]

    return item === '' ? undefined : item
}

export function getNielsonTopLevelSection(section = 'home') {
    // DPT-3104 return finance instead of business all business sections
    if (section.includes('business')) {
        section = 'finance'
    }

    return `${SEVENNEWS_NIELSEN_PREFIX} - ${removeHyphens(section)}`
}

export function getNielsonSubBrand(section: string | undefined) {
    switch (section) {
        case 'entertainment':
        case 'sunrise':
        case 'the-morning-show':
            return 'c28'

        case 'sport':
            return 'c29'

        case 'lifestyle':
            return 'c30'

        case 'technology':
            return 'c31'

        case 'travel':
            return 'c32'

        case 'business':
            return 'c33'

        default:
            return undefined
    }
}

/**
 * Expected output for the following Dcr metadata is
 *
 * for SevenNews (P19-135):
 *  section: pathname sanitised without slug/query params e.g '/news/crime'
 *  segA: '7News' for / or first level topic (e.g 'news' if /news)
 *  segB: region or 'not set'
 */
export function get7NewsDcrMeta(
    siteName: string,
    pathName: string,
    renderType: NielsenRenderType,
): NielsenDCRMetaData {
    const section = getSectionFromPath(pathName)

    // generate correct segC based on renderType
    let segC = undefined
    if (renderType === 'google-amp')
        switch (section?.toLowerCase()) {
            case 'entertainment':
                segC = `${siteName} - Entertainment - Google AMP`
                break
            case 'sport':
                segC = `${siteName} - Sport - Google AMP`
                break
            case 'lifestyle':
                segC = `${siteName} - Lifestyle - Google AMP`
                break
            case 'technology':
                segC = `${siteName} - Technology - Google AMP`
                break
            case 'travel':
                segC = `${siteName} - Travel - Google AMP`
                break
            case 'finance':
                segC = `${siteName} - Finance - Google AMP`
                break
            default:
                segC = `${siteName} - Google AMP`
                break
        }

    const cookie = retrieveCookie<RegionCookieState>(userLocationKey)

    const nielsonSection = getNielsonTopLevelSection(section)
    const segB = cookie?.region ?? undefined

    const subbrand = getNielsonSubBrand(section)

    return {
        section: nielsonSection,
        ...(segB && { segB }),
        ...(segC && { segC }),
        subbrand,
    }
}

const removeHyphens = (str: string) => `${str.replace(/-/g, ' ')}`

/**
 * Used by 7NEWS only to just return the pathname excluding any slugs or query params
 */
export function pathNoSlug(path: string) {
    const withOutQuery = path.split('?')[0]
    // we dont want items in the following split that are the cue slug e.g dogs/doggos-are-the-best-c-13808
    // seven just uses cue so those should be fine
    const split = withOutQuery.split('/').filter((item) => {
        const regex = new RegExp(/-c-[0-9]+/)
        const match = regex.exec(item)
        return match === null
    })

    if (split.length < 4) {
        const filtered = split.filter((i) => i !== '/' && i !== '')
        return filtered.length > 0
            ? `${SEVENNEWS_NIELSEN_PREFIX} - ${filtered
                  .map(removeHyphens)
                  .join(' - ')}`
            : `${SEVENNEWS_NIELSEN_PREFIX} - home`
    } else {
        return `${SEVENNEWS_NIELSEN_PREFIX} - ${[split[1], split[2]]
            .map(removeHyphens)
            .join(' - ')}`
    }
}
