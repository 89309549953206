import { useTheme } from '@emotion/react'
import { Product } from '@news-mono/web-common'
import cuid from 'cuid'
import React, { useRef } from 'react'
import { CardTeaser } from '../../cards/CardText/CardTeaser'
import { FundingType } from '../../templates/Publication/lib/get-funding-type'
import {
    StyledCardHeadline,
    StyledFundingLabel,
    StyledImage,
    StyledPicture,
    StyledRippleCard,
    StyledText,
} from './Ripple.styled'

declare let apntag: any

export interface RippleProps {
    pageSection: string
}

export interface RippleAdAvailableEventArgs {
    tagId: number
    auctionId: string
    adType: 'native'
    buyerMemberId: number
    source: 'rtb'
    creativeId: number
    mediaSubtypeId: number
    mediaTypeId: number
    brandCategoryId: number
    native: {
        title: string
        body: string
        icon: {
            url: string
            width: number
            height: number
            prevent_crop: boolean
        }
        image: {
            url: string
            width: number
            height: number
            prevent_crop: boolean
        }
        cta: string
        sponsoredBy: string
        impressionTrackers: string[]
        clickTrackers: string[]
        clickUrl: string
    }
}

function getTagId(product: Product, section: string) {
    switch (product) {
        case Product.PerthNow:
            return 18037201
        case Product.TheWest:
            // Thewest : regionals
            return section === 'default' ? 17298420 : 18037200
        default:
            return undefined
    }
}

export const RippleAd: React.FC<RippleProps> = ({ pageSection }) => {
    const unmounted = useRef(false)
    // Generates a id when it mounts
    const [id] = React.useState(() => cuid())
    const { section, kind } = useTheme()

    const [adState, setAdState] =
        React.useState<RippleAdAvailableEventArgs | undefined>(undefined)
    const [noBid, setNoBid] = React.useState(false)

    // use a sample ID from taboola in local dev otherwise no cards will be available
    const tagId =
        process.env.NODE_ENV === 'development'
            ? 13232354
            : getTagId(kind, section)

    React.useEffect(() => {
        if (typeof apntag === 'undefined') {
            return
        }

        // On mount it calls the ripple API
        apntag.anq.push(function () {
            // Not using setPageOpts in this example, member set in defineTag
            apntag.defineTag({
                targetId: id,
                tagId,
                allowedFormats: ['native'],
                // Below native specs are optional; For details see: https://wiki.appnexus.com/display/sdk/DefineTag
                native: {
                    title: { required: false },
                    body: { required: false, max_length: 300 },
                    image: {
                        required: true,
                        sizes: [{ width: 480, height: 270 }],
                    },
                    icon: { required: false },
                    sponsoredBy: { required: false },
                    cta: { required: false },
                    clickUrl: { required: false },
                },
            })
            apntag.setKeywords(`${id}`, { section: pageSection })
            /**
             * adAvailable will not be fired if checking on localhost
             * use your computer name instead
             **/
            apntag.onEvent(
                'adAvailable',
                id,
                function (ad: RippleAdAvailableEventArgs) {
                    if (unmounted.current) {
                        return
                    }
                    // Once the ad has loaded, we set the `ad` which contains all the info
                    setAdState(ad)
                    // Fire imp trackers, attach click tracker to div
                    apntag.fireImpressionTrackers(ad)
                    apntag.attachClickTrackers(ad, id)
                },
            )
            apntag.onEvent('adNoBid', 'nativediv', function () {
                if (unmounted.current) {
                    return
                }
                setNoBid(true)
            })
            apntag.loadTags()
        })

        return () => {
            unmounted.current = true
        }
    }, [id, kind, section, tagId, pageSection])

    if (typeof apntag === 'undefined') {
        return null
    }

    if (noBid) {
        return null
    }

    // The wrapping div is needed so the ripple SDK has an element to attach it's click handlers to
    return <div id={id}>{adState ? <RippleCard ad={adState} /> : null}</div>
}
RippleAd.displayName = 'RippleAd'

export interface RippleCardProps {
    ad: RippleAdAvailableEventArgs
}
export const RippleCard: React.FC<RippleCardProps> = ({ ad }) => {
    return (
        <StyledRippleCard to={ad.native.clickUrl} data-js="ripple-ad">
            <StyledPicture>
                <StyledImage src={ad.native.image.url} alt="" />
            </StyledPicture>
            <StyledText>
                <StyledCardHeadline
                    headlineText={ad.native.title}
                    kickerMode="hidden"
                    fontScale={1}
                    publicationKind="unknown"
                />
                <CardTeaser
                    teaserOrSkeletonLines={ad.native.body}
                    teaserMode="visible"
                />
                <StyledFundingLabel
                    source={ad.native.sponsoredBy}
                    fundingType={FundingType.Sponsored}
                />
            </StyledText>
        </StyledRippleCard>
    )
}

export const RippleSkeleton: React.FC<RippleCardProps> = () => {
    return <p>Loading...</p>
}
