import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts, themedValue } from '../../__styling'

export const AflMatchStatsWidgetContainer = styled('div')(({ theme }) => ({
    display: 'block',
    maxWidth: themedValue(theme, {
        sevennews: calcRem(890),
        perthnow: 'unset',
        fallback: undefined,
    }),
    margin: '0 auto',
    '& .Opta': {
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            perthnow: fonts.perthnow.sansSerif,
            thewest: fonts.thewest.sansSerif,
            fallback: undefined,
        }),
        '.Opta_W': {
            marginTop: themedValue(theme, {
                perthnow: calcRem(16),
                fallback: undefined,
            }),
        },
        '.Opta_W, .Opta_W>div': {
            background: themedValue(theme, {
                perthnow: 'transparent',
                fallback: undefined,
            }),
        },
        '& .Opta-Stats-Bars': {
            '& th': {
                fontSize: calcRem(12),
                lineHeight: '15px',
                color: tokens.sevennews.colors.palette.black,
                [breakpoint('sm')]: {
                    fontSize: calcRem(18),
                    lineHeight: '23px',
                },
            },
            '& td': {
                paddingTop: 0,
                '&.Opta-Outer': {
                    color: tokens.sevennews.colors.palette.black,
                    fontSize: calcRem(13),
                    lineHeight: '17px',
                    width: themedValue(theme, {
                        perthnow: calcRem(30),
                        thewest: calcRem(30),
                        fallback: undefined,
                    }),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(18),
                        lineHeight: '23px',
                    },
                },
                '&.Opta-Outer-bigger': {
                    fontWeight: 700,
                },
            },
            '& .Opta-Bars-Full': {
                '.Opta-Percent': {
                    background: themedValue(theme, {
                        sevennews:
                            tokens.sevennews.colors.palette.matchCentre
                                .lightRed,
                        perthnow:
                            tokens.perthnow.colors.palette.AFL.pinkThuliteLight,
                        thewest: tokens.thewest.colors.palette.AFL.westblue40,
                        fallback: undefined,
                    }),
                    '&.Opta-Away': {
                        background: themedValue(theme, {
                            sevennews:
                                tokens.sevennews.colors.palette.matchCentre
                                    .darkred,
                            perthnow:
                                tokens.perthnow.colors.palette.pinkThulite,
                            thewest: tokens.thewest.colors.palette.westblue,
                            fallback: undefined,
                        }),
                    },
                },
            },
        },
        '.Opta-error': {
            marginTop: 0,
            marginBottom: calcRem(24),
            p: {
                fontFamily: themedValue(theme, {
                    perthnow: fonts.perthnow.serif,
                    fallback: undefined,
                }),
                fontSize: themedValue(theme, {
                    sevennews: calcRem(15),
                    fallback: calcRem(16),
                }),
                fontWeight: themedValue(theme, {
                    sevennews: 500,
                    fallback: undefined,
                }),
                lineHeight: 1.5,
                padding: 0,
                color: themedValue(theme, {
                    perthnow: tokens.perthnow.colors.palette.greyCoal,
                    fallback: undefined,
                }),
            },
        },
    },
}))
