import { layout } from '../../App.routing'

export const aflSegment = [
    layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'md',
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },

            cardLayout: [
                {
                    type: 'hero',
                    disableImageLazyLoad: false,
                },
                'teaser-xsVisible-mdHidden-Image',
                'teaser-xsVisible-mdHidden-Image',
            ],
            dataDefinitionArgs: {
                type: 'curation',
                name: 'afl',
                offset: 0,
                pageSize: 3,
            },
            sectionHeader: {
                heading: 'AFL',
                headingUrl: '/sport/afl',
            },
        },
    }),
    layout.nestedComposition({
        type: 'janus',
        props: {
            layoutRatio: '3:1',
            stretchColumns: true,
            verticalSpacing: 'xl',
        },
        contentAreas: {
            left: [
                layout.component({
                    type: 'lima',
                    props: {
                        fixedRatios: ['16:9'],
                        intermediateColumns: 3,
                        finalColumns: 3,
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },

                        cardLayout: [
                            ['teaserWithBackground'],
                            [
                                'teaser-xsVisible-mdHidden-Image',
                                'teaser-xsVisible-mdHidden-noImage',
                            ],
                            { type: 'november', listLength: 4 },
                        ],
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'afl',
                            offset: 3,
                            pageSize: 7,
                        },
                    },
                }),
            ],
            right: [
                layout.component({
                    type: 'curated-promotional-card',
                    props: {
                        fixedRatio: '4:3',
                        hasBackground: true,
                        hasPadding: true,
                        mediaMode: 'edgeToEdge',
                        fontScale: 0.88,
                        showCTA: true,
                        cardOrientation: {
                            defaultLayout: 'landscape',
                            alternativeLayoutOptions: {
                                breakpoint: 'md',
                                layout: 'portrait',
                            },
                        },
                        verticalSpacing: 'md',
                        dataDefinitionArgs: {
                            type: 'active-promotion',
                            promotionSlotId: 'homepage-position-2',
                        },
                    },
                }),
            ],
        },
    }),
]
