import loadable from '@loadable/component'
import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'

const LazyOurPublications = loadable(() => import('./our-publication'))

export const OurPublicationRegistration = createRegisterableComponent(
    'our-publications',
    () => <LazyOurPublications />,
)
