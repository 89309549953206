import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TimelineFeedWidget } from './TimelineFeedWidget'

export interface TimelineFeedRouteProps {
    matchId: string
    apiData: any
}

export const TimelineFeedWidgetRegistration = createRegisterableComponent(
    'afl-timeline-feed-widget',
    (props: TimelineFeedRouteProps, services) => {
        return <TimelineFeedWidget {...props} />
    },
)
