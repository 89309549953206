import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledPollieRaterSignupBanner = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.margins.md,
    backgroundColor: theme.colors.palette.white,
    border: 'solid black 1px',
    borderRadius: 4,
    width: '100%',

    [breakpoint('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: calcRem(0, theme.margins.md),
    },

    [breakpoint('md')]: {
        flexDirection: 'column',
        padding: calcRem(theme.margins.md),
    },

    [breakpoint('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: calcRem(0, theme.margins.md),
    },
}))

export const StyledPollieRaterSignupTextContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.margins.sm,

        '& > svg': {
            marginRight: theme.margins.sm,
        },
        [breakpoint('sm')]: {
            width: calcRem(200),
            marginBottom: 0,
            '& > svg': {
                marginRight: theme.margins.md,
                overflow: 'visible',
            },
        },
    }),
)

export const StyledPollieRaterSignupText = styled('p')(({ theme }) => ({
    margin: calcRem(10, 0),
    fontFamily: theme.fonts.serif,
    fontSize: calcRem(16),
    fontWeight: 500,

    '& > span': {
        color: theme.colors.brand,
    },
}))

export const StyledPollieRaterCTAButtonsContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > span': {
            margin: calcRem(0, 8),
            fontWeight: 500,
        },
    }),
)

export const StyledPollieRaterCTAButton = styled('button')(({ theme }) => ({
    height: calcRem(32),
    padding: theme.margins.sm,
    whiteSpace: 'nowrap',
    fontFamily: theme.fonts.sansSerif,
    backgroundColor: theme.colors.brand,
    border: 0,
    borderRadius: 2,
    fontSize: calcRem(13),
    fontWeight: 700,
    color: theme.colors.palette.white,
    cursor: 'pointer',

    // No alt state design for these buttons so have used same styling as submit
    '&:hover': {
        background: `linear-gradient(90.01deg, #1b008d 0.01%, #721f13 99.72%)`,
    },

    '&:focus': {
        outline: 'none',

        '&::after': {
            display: 'block',
        },
    },

    '&:active': {
        background: theme.colors.actions.button.primary.background.active,
        borderColor: theme.colors.actions.button.primary.border.active,
        color: theme.colors.actions.button.primary.text.active,
    },
}))
