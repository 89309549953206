import React from 'react'
import { StyledVideoIcon } from '../../icons/IconVideo2/IconVideo2.styled'

export interface IconVideo2Props {
    className?: string
    width: number
    height: number
    horizontalSpacing?: number
}

export const IconVideo2: React.FC<IconVideo2Props> = ({
    width,
    height,
    horizontalSpacing,
    className,
}) => (
    <StyledVideoIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 9"
        width={width}
        height={height}
        horizontalSpacing={horizontalSpacing}
        fill="#000"
        className={className}
    >
        <g fillRule="evenodd">
            <title>Video</title>
            <rect width="11" height="9" rx="2" />
            <path d="M16.48.04L12.2 2.29c-.12.06-.2.2-.2.33v3.75c0 .15.08.28.2.34l4.28 2.25c.24.13.52-.06.52-.34V.38c0-.28-.28-.47-.52-.34" />
        </g>
    </StyledVideoIcon>
)
