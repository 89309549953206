import React from 'react'
import {
    StyledToggle,
    StyledToggleBackground,
    StyledToggleButton,
    StyledToggleCheckbox,
    StyledToggleLabel,
    StyledToggleSwitch,
} from '../../buttons/ToggleButton/ToggleButton.styled'

export interface ToggleButtonProps {
    checked?: boolean
    onToggle: (value: boolean) => void
    name: string
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    onToggle,
    checked = false,
    name,
}) => (
    <StyledToggle>
        <StyledToggleCheckbox
            type="checkbox"
            checked={checked}
            onChange={() => onToggle(!checked)}
        />
        <StyledToggleLabel htmlFor={`${name}_${checked ? 'off' : 'on'}`}>
            {`Toggle ${name} ${checked ? 'off' : 'on'}`}
        </StyledToggleLabel>

        <StyledToggleButton onClick={() => onToggle(!checked)}>
            <StyledToggleBackground active={checked}>
                <StyledToggleSwitch />
            </StyledToggleBackground>
        </StyledToggleButton>
    </StyledToggle>
)

ToggleButton.displayName = 'ToggleButton'
