import React from 'react'
import { ErrorPage } from '../ErrorPage'
import { PageError } from '@news-mono/web-common'

type SomethingWentWrongProps = {
    error: PageError
    attribution: boolean
}

export const SomethingWentWrong = ({
    error,
    attribution,
}: SomethingWentWrongProps) => (
    <ErrorPage
        text={() => 'Oops! Something went wrong.'}
        error={error}
        attribution={attribution}
    />
)
