import { cx } from '@emotion/css'
import isPropValid from '@emotion/is-prop-valid'
import { StyledComponent } from '@emotion/styled'
import React from 'react'

type WrappedComponentBase =
    | StyledComponent<any, any>
    | React.ComponentType<any>
    | React.Component<any>
    | React.ForwardRefExoticComponent<any>

type GetComponentProps<T> = T extends
    | StyledComponent<infer P, infer I>
    | React.ComponentType<infer P>
    | React.Component<infer P>
    ? P & I
    : never

/**
 * Use `withClass` to add a class to resulting styled component
 */

export function withClass(customClassName?: string) {
    return <C extends WrappedComponentBase>(Component: C) => {
        type Props = GetComponentProps<C>
        type WithClassNames = { className?: string }

        const ComponentWithCustomClass = React.forwardRef<
            any,
            Omit<Props, 'theme'>
        >((props, ref) => {
            const newProps = {
                ...props,
                className: cx(props.className, customClassName),
                ref,
            }
            const ToRender = Component as React.FC<
                Omit<Props, 'theme'> & WithClassNames
            >
            return <ToRender {...newProps} />
        })

        ComponentWithCustomClass.displayName = 'ComponentWithCustomClass'
        return ComponentWithCustomClass
    }
}

/**
 * Use `createPropFilter` with shouldForwardProp to filter out props. Will automatically filter out `theme`
 * since this prop is passed via emotion's context.
 */

export function createPropFilter<ComponentProps extends {}>() {
    return function <Omitted extends PropertyKey>(
        propsToOmit: Omitted[],
        stripNonHTML?: boolean,
    ) {
        return function (
            prop: PropertyKey,
        ): prop is Exclude<keyof ComponentProps, Omitted> {
            return stripNonHTML
                ? isPropValid(prop) && propsToOmit.indexOf(prop as any) === -1
                : propsToOmit.indexOf(prop as any) === -1
        }
    }
}
