import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { PublicationCardItemVideo } from '../../../client-data-types/card-types'
import { RequiredAccess } from '../../../client-data-types/publication'

export function getExtraProps(document: ListPublicationV4DTO): {
    video: PublicationCardItemVideo | undefined
    teaser: string | null
    isLive: boolean
    requiredAccess: RequiredAccess
    blogIsPresent: boolean
} {
    const requiredAccess = document.requiredAccess ?? {
        level: 'anonymous',
    }

    if (
        document.kind === 'article' ||
        document.kind === 'event' ||
        document.kind === 'redirect'
    ) {
        const video: PublicationCardItemVideo | undefined =
            document.mainVideo && document.mainVideo.kind !== 'missing-video'
                ? {
                      id: document.mainVideo.videoId,
                      accountId: document.mainVideo.accountId,
                      heading: document.mainVideo.heading,
                      source: document.source,
                      duration: null,
                      videoType: document.mainVideo.videoType,
                      videoSeries: document.mainVideo.seriesInfo,
                  }
                : undefined
        const isLive =
            (document.kind === 'article' || document.kind === 'redirect') &&
            document.isLiveContent
        const blogIsPresent =
            (document.kind === 'article' || document.kind === 'redirect') &&
            !!document.blogState

        return {
            video,
            isLive,
            requiredAccess,
            blogIsPresent,
            teaser: document.homepageTeaser,
        }
    }

    if (document.kind === 'gallery') {
        return {
            video: undefined,
            teaser: document.homepageTeaser,
            isLive: false,
            requiredAccess,
            blogIsPresent: false,
        }
    }

    throw new Error('Unknown document kind')
}
