import { ConsentLevel } from './consent.redux'

/**
 * To check ConsentLevel use a bitwise test
 *
 * consentLevel & ConsentLevel.Analytics === true if set
 * (consentLevel & ConsentLevel.Analytics) === ConsentLevel.Analytics if set
 */
export function hasConsentLevel(
    consent: ConsentLevel,
    requiredLevel: ConsentLevel,
) {
    return (consent & requiredLevel) === requiredLevel
}
