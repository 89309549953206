import React, { useEffect, useState } from 'react'
import { IconCommentTN } from '../../../../../icons/IconCommentTN/IconComment'
import {
    Comments,
    CommentsSpan,
    StyledIconWrapper,
} from './CommentsCount.styled'

export type CommentsProps = {
    articleUrl?: string
    size?: number
}

export const CommentsCount: React.FC<CommentsProps> = ({
    ...props
}: CommentsProps) => {
    const [showLoadedComments, setShowLoadedComments] = useState(false)
    useEffect(() => {
        if (!showLoadedComments) {
            setShowLoadedComments(true)
        } else {
            window.CoralCount && window.CoralCount.reload()
        }
    }, [showLoadedComments])

    return (
        <Comments>
            <StyledIconWrapper size={props.size}>
                <IconCommentTN />
            </StyledIconWrapper>

            {props.articleUrl && (
                <CommentsSpan
                    className="coral-count"
                    data-coral-url={props.articleUrl}
                    data-coral-notext={true}
                    dangerouslySetInnerHTML={{ __html: '' }} // Bypass hydration overwriting the span
                    suppressHydrationWarning
                ></CommentsSpan>
            )}
        </Comments>
    )
}

export default CommentsCount
