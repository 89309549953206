import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { GetMainSectionFn } from '../custom-topic-routes'

const desktopBanner = require<string>('./assets/food/Desktop_-_Food-and-Wine-Banner-2440x300@2x.jpg')
const tabletBanner = require<string>('./assets/food/Tablet_-_Food-and-Wine-Banner-1476x114@2x.jpg')
const mobileBanner = require<string>('./assets/food/Mobile_-_Food-and-Wine-Banner-822x186@2x.jpg')

export const getFoodRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: desktopBanner,
                verticalGutters: ['unset', 'md'],
                altText: 'Food Reviews Banner',
                hiddenHeading: 'Food',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            {
                                src: desktopBanner,
                                density: '2x',
                            },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            {
                                src: tabletBanner,
                                density: '2x',
                            },
                        ],
                    },
                    {
                        src: [
                            {
                                src: mobileBanner,
                                density: '2x',
                            },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'sierra',
            props: {
                verticalSpacing: 'gridGap',
                fixedRatios: ['16:9'],
                heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
                cardOrientation: {
                    type: 'Landscape-Portrait',
                    maxBreakpoint: 'md',
                },
                cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'food-hub',
                    offset: 0,
                    pageSize: 2,
                },
            },
        }),

        layout.component({
            type: 'flourish-iframe',
            props: {
                link: 'https://flo.uri.sh/visualisation/10284374/embed',
                height: 800,
                marginBottom: 'lg',
                ariaLabel: 'a scrollable list of food reviews',
                imgHeight: 15,
            },
        }),

        layout.component({
            type: 'sierra',
            props: {
                verticalSpacing: 'gridGap',
                fixedRatios: ['16:9'],
                heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
                cardOrientation: {
                    type: 'Landscape-Portrait',
                    maxBreakpoint: 'md',
                },
                cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'food',
                    offset: 0,
                    pageSize: 2,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                teaserMode: 'visible',
                cardOrientation: { type: 'Landscape-Portrait' },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'food',
                    offset: 2,
                    pageSize: 4,
                },
            },
        }),

        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Wine',
                    headingUrl: 'wine',
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'wine',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),

        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Beer',
                    headingUrl: 'beer',
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'beer',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
    ]
}
