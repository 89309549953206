import React from 'react'
import { StyledPlayIcon } from './PlayButtonV2.styled'

export interface PlayIconProps {
    isSmall?: boolean
    isCentered?: boolean
}

export const PlayButtonV2: React.FC<PlayIconProps> = ({
    isSmall,
    isCentered,
}) => {
    return (
        <StyledPlayIcon
            title="Video"
            aria-live={undefined}
            aria-disabled={undefined}
            isSmall={isSmall}
            isCentered={isCentered}
        />
    )
}
PlayButtonV2.displayName = 'PlayButtonV2'
