import { DataLayerEventName, MatchCenterEvents } from '@news-mono/web-common'
import React from 'react'
import { teamBrandData } from '../afl-match-centre/MatchTimelineWidget/helpers/teamBrandData'
import { AflTeam, TabTouchOdds } from '../afl-match-centre/ScoreBoard/types'
import { Team } from '../cricket-match-centre/ScoreBoard/types'
import { ScoreBoardDataProps } from './ScoreBoard'
import {
    StyledAflGoalsBehinds,
    StyledAflScore,
    StyledFirstInningScore,
    StyledNoScoreDash,
    StyledScore,
    StyledScoreOvers,
    StyledTeamLogo,
    StyledTeamName,
    StyledTeamNameWrapper,
} from './ScoreBoard.styled'
import { SingleOddsComponent } from './SponsorRow'

export interface TeamScoreProps {
    teamData: ScoreBoardDataProps
    teamSide: 'home' | 'away'
    onEvent: (event: MatchCenterEvents) => void
    odds?: TabTouchOdds
}
export const TeamScore = ({
    teamData,
    teamSide,
    odds,
    onEvent,
}: TeamScoreProps) => {
    const side = teamSide === 'home' ? 'homeTeam' : 'awayTeam'
    switch (teamData.type) {
        case 'cricket':
            return (
                <TeamScoreCricket
                    status={teamData.data.gameStatus}
                    team={teamData.data[side]}
                    oponentNumberOfInnings={
                        teamData.data.awayTeam.innings.length
                    }
                />
            )
        case 'afl':
            return (
                <TeamScoreAfl
                    team={teamData.data[side]}
                    side={teamSide}
                    odds={odds}
                    onEvent={onEvent}
                />
            )
        default:
            return null
    }
}

interface TeamScoreCricketProps {
    team: Team
    status: string
    oponentNumberOfInnings: number //for adding space at top if one team have one inning and other have two
}
const TeamScoreCricket = ({
    team: { name, innings, id },
    status,
    oponentNumberOfInnings,
}: TeamScoreCricketProps) => {
    const [firstInning, secondInning] = innings

    const mainInning = secondInning || firstInning
    const leastInning = secondInning ? firstInning : null

    if (status === '0' || firstInning.runsScored === null) {
        return (
            <>
                <StyledTeamNameWrapper>
                    <StyledTeamName>{name}</StyledTeamName>
                    <StyledTeamLogo
                        src={`https://omo.akamai.opta.net/image.php?h=omo.akamai.opta.net&sport=cricket&entity=team&description=badges&dimensions=65&id=${id}`}
                        alt={`${name} logo`}
                    />
                </StyledTeamNameWrapper>
                <StyledNoScoreDash>-</StyledNoScoreDash>
            </>
        )
    }

    return (
        <>
            <StyledTeamNameWrapper>
                <StyledTeamName>{name}</StyledTeamName>
                <StyledTeamLogo
                    src={`https://omo.akamai.opta.net/image.php?h=omo.akamai.opta.net&sport=cricket&entity=team&description=badges&dimensions=65&id=${id}`}
                    alt={`${name} logo`}
                />
            </StyledTeamNameWrapper>
            <StyledFirstInningScore>
                {leastInning?.runsScored ? `${leastInning.runsScored} &` : null}
            </StyledFirstInningScore>

            <StyledScore
                hasFirstInning={oponentNumberOfInnings > 1 && !leastInning}
            >
                {mainInning.wickets !== '10'
                    ? `${mainInning.wickets}-${mainInning.runsScored}`
                    : mainInning.runsScored}
            </StyledScore>
            <StyledScoreOvers>({mainInning.overs})</StyledScoreOvers>
        </>
    )
}

interface TeamScoreAflProps {
    team: AflTeam
    side: 'home' | 'away'
    odds?: TabTouchOdds
    onEvent: (event: MatchCenterEvents) => void
}

const TeamScoreAfl = ({ team, side, odds, onEvent }: TeamScoreAflProps) => {
    const onClick = (team?: string) => {
        onEvent({
            type: DataLayerEventName.oddsLinkPressed,
            originator: 'MatchCenter',
            payload: {
                type: team ? 'single' : 'logo',
                team,
            },
        })
    }

    const currentTeamBrandData = teamBrandData.find(
        (teamData) => teamData.teamId === team.id,
    )

    return (
        <>
            <StyledTeamNameWrapper isReverse={side === 'away'} isAfl={true}>
                <StyledTeamName>{team.name}</StyledTeamName>
                <StyledTeamLogo
                    src={
                        currentTeamBrandData
                            ? currentTeamBrandData.darkBgLogo
                            : `http://omo.akamai.opta.net/image.php?h=omo.akamai.opta.net&sport=AFL&entity=team&description=badges&dimensions=65&id=${team.id}`
                    }
                    alt={`${team.name} logo`}
                    type={'afl'}
                />
            </StyledTeamNameWrapper>

            {team.score.score ? (
                <StyledAflScore>{team.score.score}</StyledAflScore>
            ) : (
                <StyledNoScoreDash>-</StyledNoScoreDash>
            )}
            {team.score.goals && team.score.behinds && (
                <StyledAflGoalsBehinds>
                    {team.score.goals}.{team.score.behinds}
                </StyledAflGoalsBehinds>
            )}
            {odds && (
                <SingleOddsComponent
                    onClick={() =>
                        onClick(side === 'home' ? odds.home : odds.away)
                    }
                    value={side === 'home' ? odds.homeOdds : odds.awayOdds}
                    link={side === 'home' ? odds.homeBetLink : odds.awayBetLink}
                />
            )}
        </>
    )
}
