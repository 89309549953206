import styled from '@emotion/styled'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface StyledPictureProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledPicture = styled('picture')<StyledPictureProps>(
    {
        display: 'block',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
    ({ theme, verticalGutters }) => ({
        marginTop: calcRem(
            verticalGutters ? theme.margins[verticalGutters[0]] : 0,
        ),
        marginBottom: calcRem(
            verticalGutters ? theme.margins[verticalGutters[1]] : 0,
        ),
    }),
)
