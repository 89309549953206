import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const adUnitListClassName = 'debug-ad-unit-list'

export const StyledDebugAdUnitListContainer = styled('div')({
    overflowX: 'auto',
})
StyledDebugAdUnitListContainer.displayName = 'StyledDebugAdUnitListContainer'

export const StyledDebugAdUnitList = withClass(adUnitListClassName)(
    styled('div')(({ theme }) => ({
        margin: calcRem(metrics[theme.kind].margins.md),
    })),
)
StyledDebugAdUnitList.displayName = 'StyledDebugAdUnitList'

export const StyledOverviewButton = styled('button')({
    position: 'absolute',
    top: calcRem(10),
    right: calcRem(10),
    fontSize: calcRem(12),
    color: '#000',
    backgroundColor: '#fff',
    cursor: 'pointer',
    zIndex: 100,

    '&:hover': {
        color: '#fff',
        backgroundColor: 'grey',
        boxShadow: '2px 2px #000',
    },
    '&:focus': {
        outline: 'none',
    },
})
StyledOverviewButton.displayName = 'StyledOverviewButton'
