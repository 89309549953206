import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import React from 'react'
import { LazyGeneralInArticleBanner } from '../../../banners/GenericInArticleBanner'
import {
    InlineContentTypes,
    InlineGoMatildasBanner,
} from '../SharedPublication.routing'

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineGoMatildasBanner(
    content: InlineContentTypes,
): content is InlineGoMatildasBanner {
    return content.kind === 'inline-go-matildas-banner'
}

export function getInlinedPositionedGoMatildasBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    const validTopics = [
        'sport/fifa-women-s-world-cup',
        'sport/world-cup-soccer',
        'news/wa',
        'sport/soccer',
    ]

    if (
        !validTopics.includes(publication.primaryTopic.id) &&
        !publication.secondaryTopics.find((topic) =>
            validTopics.includes(topic.id),
        )
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineGoMatildasBanner)
        .map((content: InlineGoMatildasBanner) => {
            return {
                position: content.insertAfter,
                element: (
                    <LazyGeneralInArticleBanner
                        onEvent={onEvent}
                        {...content.props}
                    />
                ),
            }
        })
}
