import queryString from 'query-string'
import { useSelector } from 'react-redux'
import {
    CountryCode,
    euMembers,
} from '../../data/location-detection/country-codes'
import { AppState } from '../../store'
import { LocationState, RegionState } from './reducer'

export interface LocationStateProps {
    geoLocation: LocationState
}

export interface LocationStateProp {
    geoLocation: LocationState
}

export type AustralianMarketCodes =
    | 'NSW'
    | 'VIC'
    | 'QLD'
    | 'QLDREG'
    | 'SA'
    | 'WA'
    | 'TAS'
    | 'NT'
const validLocations = ['NSW', 'VIC', 'QLD', 'QLDREG', 'SA', 'WA', 'TAS', 'NT']

export function isValidMarketCode(code = ''): code is AustralianMarketCodes {
    return validLocations.includes(code.toUpperCase())
}

export interface LocationResponse {
    ip: string
    postcode?: string
    state?: string
    city?: string
    country?: string
}

export const retrieveLocationQueryString = (
    location: Location,
): RegionState => {
    const query: { location?: string } = queryString.parse(location.search)
    const locationQS =
        typeof query.location === 'string'
            ? query.location.toUpperCase()
            : undefined

    return {
        userSelectedRegion: isValidMarketCode(locationQS) ? locationQS : 'NSW',
    }
}

export type MarketCodeTypes = AustralianMarketCodes | false

export type AustralianMarketCodeLabels = {
    [key in AustralianMarketCodes]: string
}

export const marketCodeLabels: AustralianMarketCodeLabels = {
    NSW: 'Sydney, NSW',
    VIC: 'Melbourne, VIC',
    QLD: 'Brisbane, QLD',
    QLDREG: 'Queensland',
    WA: 'Perth, WA',
    SA: 'Adelaide, SA',
    TAS: 'Hobart, TAS',
    NT: 'Darwin, NT',
}

export const useLocationState = () => {
    const { countryCode, region, userSelectedRegion } = useSelector<
        AppState,
        LocationState
    >((state) => state.geoLocation)

    return { countryCode, region, userSelectedRegion }
}

export function isEuMember(countryCode: CountryCode | undefined) {
    if (!countryCode) return true
    return euMembers.includes(countryCode)
}

export function useIsEuMemberState() {
    return isEuMember(useLocationState().countryCode)
}
