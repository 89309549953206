import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { ComponentEvent } from './component-event'

interface RadioEventProps {}

export interface RadioAvailableEvent extends ComponentEvent<RadioEventProps> {
    type: DataLayerEventName.radioAvailable
}

export interface RadioPlayEvent extends ComponentEvent<RadioEventProps> {
    type: DataLayerEventName.radioPlay
}

export interface RadioStopEvent extends ComponentEvent<RadioEventProps> {
    type: DataLayerEventName.radioStop
}

export interface Radio30Event extends ComponentEvent<RadioEventProps> {
    type: DataLayerEventName.radio30
}

export interface RadioEndEvent extends ComponentEvent<RadioEventProps> {
    type: DataLayerEventName.radioEnd
}

export type RadioEvent =
    | RadioAvailableEvent
    | RadioPlayEvent
    | RadioStopEvent
    | Radio30Event
    | RadioEndEvent

export type RadioEventNames =
    | DataLayerEventName.radio30
    | DataLayerEventName.radioAvailable
    | DataLayerEventName.radioEnd
    | DataLayerEventName.radioPlay
    | DataLayerEventName.radioStop

export function toRadioEvent(
    type: RadioEventNames,
    source?: string,
): RadioEvent {
    const originator = 'West Live - Launch - Morning'

    return {
        type,
        originator,
        payload: {
            source: source,
        },
    }
}
