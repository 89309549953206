import React, { useState } from 'react'
import { TabHeader } from '../../cricket-match-centre/TabHeader/TabHeader'
import { BreakpointKeys, ContainerWidth, ThemeMargins } from '../../__styling'
import { BaseCompositionProps } from '../types'
import { StyledTab, StyledTabContent } from './Tab.styled'

export type HeaderDataProps = {
    position: string
    title: string
}
export interface TabRawProps extends BaseCompositionProps {
    one?: React.ReactElement<any>
    two?: React.ReactElement<any>
    three?: React.ReactElement<any>
    four?: React.ReactElement<any>
    five?: React.ReactElement<any>
    six?: React.ReactElement<any>
    seven?: React.ReactElement<any>
    eight?: React.ReactElement<any>
    nine?: React.ReactElement<any>
    headerData: Array<HeaderDataProps>
    containerWidth?: ContainerWidth
}

export interface TabSpacingProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    horizontalGutters?: keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
}

export type TabBreakPointProps = {
    [key in BreakpointKeys]?: TabSpacingProps
}

export interface TabProps extends TabRawProps, TabSpacingProps {
    breakpoints?: TabBreakPointProps
}

export const Tab = ({
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    headerData,
    containerWidth,
    verticalGutters,
    horizontalGutters,
    verticalSpacing,
    horizontalSpacing,
}: TabProps) => {
    const [activeTab, setActiveTab] = useState(headerData[0])

    const returnContent = () => {
        switch (activeTab.position) {
            case 'two':
                return two
                break
            case 'three':
                return three
                break
            case 'four':
                return four
                break
            case 'five':
                return five
                break
            case 'six':
                return six
                break
            case 'seven':
                return seven
                break
            case 'eight':
                return eight
                break
            case 'nine':
                return nine
                break
            default:
                return one
        }
    }

    return (
        <StyledTab
            containerWidth={containerWidth}
            verticalGutters={verticalGutters}
            horizontalGutters={horizontalGutters}
            verticalSpacing={verticalSpacing}
            horizontalSpacing={horizontalSpacing}
        >
            <TabHeader
                data={headerData}
                active={activeTab}
                setActive={(item: HeaderDataProps) => setActiveTab(item)}
            />
            <StyledTabContent>{returnContent()}</StyledTabContent>
        </StyledTab>
    )
}
