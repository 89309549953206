import { TableDataProps, TeamSide } from '../AflPlayerStatsWidget'

export function formatPlayerData(
    rawMatchData: any,
    selectedSide: TeamSide,
): TableDataProps[] {
    const homeId = rawMatchData.matchInfo['@attributes'].homeSquadId
    const awayId = rawMatchData.matchInfo['@attributes'].awaySquadId
    const playersStats = rawMatchData.playerStats?.player
    const formatedData: TableDataProps[] = !playersStats
        ? []
        : playersStats.map((playerData: any) => {
              return {
                  jumperNumber: playerData['@attributes'].jumperNumber,
                  playerName: `${playerData['@attributes'].playerFirstname} ${playerData['@attributes'].playerSurname}`,
                  goals: playerData['@attributes'].goals,
                  behinds: playerData['@attributes'].behinds,
                  disposals: playerData['@attributes'].disposals,
                  kicks: playerData['@attributes'].kicks,
                  handballs: playerData['@attributes'].handballs,
                  marks: playerData['@attributes'].marks,
                  tackles: playerData['@attributes'].tackles,
                  hitouts: playerData['@attributes'].hitouts,
                  teamId: playerData['@attributes'].squadId,
                  freesFor: playerData['@attributes'].freesFor,
                  freesAgainst: playerData['@attributes'].freesAgainst,
              }
          })

    if (selectedSide === 'home') {
        return formatedData.filter(
            (item: TableDataProps) => item.teamId === homeId,
        )
    } else if (selectedSide === 'away') {
        return formatedData.filter(
            (item: TableDataProps) => item.teamId === awayId,
        )
    }
    return formatedData
}
