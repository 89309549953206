import { useTheme } from '@emotion/react'
import { Product } from '@news-mono/web-common'
import React from 'react'
import { LoggerContext } from '../diagnostics/LoggerContext'

export type ReactRenderValue = () => React.ReactElement<any> | null

export interface ThemedRenderProps {
    perthnow?: ReactRenderValue
    thewest?: ReactRenderValue
    sevennews?: ReactRenderValue
    thenightly?: ReactRenderValue
    fallback?: ReactRenderValue
}

export const ThemedRender: React.FC<ThemedRenderProps> = ({
    perthnow,
    thewest,
    sevennews,
    thenightly,
    fallback,
}) => {
    const theme = useTheme()
    const logger = React.useContext(LoggerContext)

    switch (theme.kind) {
        case Product.PerthNow:
            return renderOrReturnValue(perthnow || fallback)
        case Product.TheWest:
            return renderOrReturnValue(thewest || fallback)
        case Product.SevenNews:
            return renderOrReturnValue(sevennews || fallback)
        case Product.TheNightly:
            return renderOrReturnValue(thenightly || fallback)
        default: {
            logger.warn(
                'Unable to render component as no matching theme was found',
            )
            return null
        }
    }
}

function renderOrReturnValue(renderFn?: ReactRenderValue) {
    return renderFn ? renderFn() : null
}
