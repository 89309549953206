import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { TheNightlyTheme, breakpoint, calcRem, metrics } from '../../__styling'
import { IconCross } from '../../navigation/TNFlyoutNav/assets/IconCross'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

// TheNightly Colors
const tnColors = tokens.thenightly.colors
const tnPalette = tnColors.palette
// TheNightly Metrics
const tnMetrics = metrics.thenightly
const tnMargins = tnMetrics.margins

// PerthNow Colors
const pnColors = tokens.perthnow.colors
const pnPalette = pnColors.palette
// PerthNow Metrics
const pnMetrics = metrics.perthnow
const pnMargins = pnMetrics.margins

interface StyledUserSettingsSlideToastProps {
    type?: 'success' | 'error' | 'information'
    show?: boolean
    forceRender?: boolean
    fixedAtBottom?: boolean
}

export const StyledUserSettingsSlideToast = styled(
    'div',
)<StyledUserSettingsSlideToastProps>(
    ({
        theme,
        show = false,
        type = 'success',
        forceRender = false,
        fixedAtBottom = false,
    }) => [
        {
            position: forceRender ? undefined : 'relative',
            zIndex: 99,

            width: '100%',
            bottom: show ? calcRem(tnMargins.xxxl) : calcRem(-tnMargins.xxxl),
            padding: calcRem(0, tnMargins.m2l),
            transition: forceRender ? undefined : 'all 0.3s',
            opacity: show ? 1 : 0,

            div: {
                display: 'flex',
                alignItems: 'center',
                background:
                    type === 'success'
                        ? tnPalette.utility.success.dark
                        : type === 'information'
                        ? tnPalette.neutral[100]
                        : tnPalette.utility.error.default,
                borderRadius: '8px',
                padding: calcRem(tnMargins.sm2l),
                color: tnPalette.white,
                fontWeight: 600,
                span: {
                    marginLeft: calcRem(tnMargins.sm),
                },
            },

            [breakpoint('sm')]: {
                width: calcRem(400),
                padding: 0,
                left: '50%',
                transform: 'translateX(-50%)',
            },
        },

        fixedAtBottom === true && {
            position: 'fixed',
            bottom: show ? calcRem(tnMargins.xxxxl) : calcRem(-tnMargins.xxxxl),
            left: '50%',
            transform: 'translateX(-50%)',

            [breakpoint('md')]: {
                left: 'auto',
                transform: 'none',
                position: 'fixed',
                marginRight: calcRem(40),
                marginBottom: calcRem(40),
                bottom: 0,
                right: 40,
            },
        },

        // PerthNow Styling
        theme.kind === 'perthnow' && {
            bottom: show ? calcRem(0) : calcRem(-pnMargins.xxl),

            div: {
                padding: calcRem(12, 16),
                gap: calcRem(12),
                background:
                    type === 'success'
                        ? pnPalette.greenJadeShade[50]
                        : pnPalette.redCrisis[50],
                // Color the text!
                color:
                    type === 'success'
                        ? pnPalette.greenJadeShade[600]
                        : pnPalette.redCrisis[600],

                span: {
                    fontFamily: theme.fonts.sansSerif,
                    marginLeft: 0,
                },
            },

            // Color the SVG Icons
            path: {
                stroke:
                    type === 'success'
                        ? pnPalette.greenJadeShade[600]
                        : pnPalette.redCrisis[600],
            },

            // Large Mobile or bigger styling
            [breakpoint('sm')]: {
                transform: 'translateX(-60%)',
            },
        },
    ],
)

export const StyledIconCross = styled(IconCross)<{ onClick: () => void }>({
    height: calcRem(24),
    width: calcRem(24),
    fill: tnPalette.white,
    '&:hover': {
        cursor: 'pointer',
    },
    marginLeft: 'auto',
})

export const StyledTextContainer = styled('span')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(4),
})

export const StyledTextTitle = styled('span')(({ theme }) => [
    theme.kind === 'thenightly' && {
        ...textMixin(theme as TheNightlyTheme, 'item-small'),
    },
])

export const StyledTextDescription = styled('span')(({ theme }) => [
    theme.kind === 'thenightly' && {
        ...textMixin(theme as TheNightlyTheme, 'byline-secondary'),
    },
])
