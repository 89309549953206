import {
    CardEvents,
    EntitledToView,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CardMedia } from '../CardMedia/CardMedia'
import { VideoTrailerCardRouteProps } from './VideoTrailerCard.routing'
import {
    StyledAngle,
    StyledEntitledContainer,
    StyledEntitledText,
    StyledLiveSportsLogo,
    StyledOpportunityContainer,
    StyledOpportunityContentWrap,
    StyledOpportunityHeader,
    StyledOpportunityHeaderWrap,
    StyledOpportunityLink,
    StyledOpportunityText,
    StyledOpportunityVideoWrap,
    StyledSubscribeButton,
} from './VideoTrailerCard.styled'

/*
 *
 * Layout Mode Props - required
 * Single: styled view for 1 tile in a 3:1 ratio
 * Double: styled view for Card in a 1:1 ratio ( this card spans 2 columns )
 *
 * Size Props
 * sm, md, lg - different font sizes, not affected by screen size
 * - defaults to sm
 *
 * affects the following styled components
 * - StyledOpportunityHeader
 * - StyledOpportunityLink
 *
 */

export interface VideoTrailerCardProps extends VideoTrailerCardRouteProps {
    size?: string
    onEvent: (event: CardEvents) => void
    cardNumber?: number
}

export interface EntitledViewProps {
    layoutMode: 'single' | 'double'
}

export const EntitledView = (props: EntitledViewProps) => {
    const { layoutMode } = props
    const subscribeUrl = 'https://thewest.com.au/manage-email-preferences'

    return (
        <StyledEntitledContainer>
            <StyledEntitledText>
                Get the latest sports news from 'The West' to your inbox
            </StyledEntitledText>
            <StyledSubscribeButton to={subscribeUrl}>
                Sign up for email
            </StyledSubscribeButton>
            <StyledAngle layoutMode={layoutMode} />
        </StyledEntitledContainer>
    )
}

interface OpportunityViewProps extends VideoTrailerCardProps {
    item: MaybeLoaded<PublicationCardItem>
}
export const OpportunityView = (props: OpportunityViewProps) => {
    const { layoutMode, size, onEvent, cardNumber } = props

    const subscribeUtm =
        '?utm_source=Thewest&utm_medium=Live-sport-trailer-card&utm_term=&utm_content=Get_access_today'

    return (
        <StyledOpportunityContainer layoutMode={layoutMode} size={size}>
            <StyledOpportunityText layoutMode={layoutMode}>
                Watch for just $1 a day
            </StyledOpportunityText>

            <StyledOpportunityVideoWrap layoutMode={layoutMode}>
                <CardMedia
                    imageWidths={{
                        mobile: '110px',
                        tablet: '258px',
                        desktop: '289px',
                        fallbackWidth: 289,
                        mode: 'fixed',
                    }}
                    mediaMode={'fullHeight'}
                    onEvent={onEvent}
                    fixedRatio={'16:9'}
                    item={item}
                    willPlayVideoInline={true}
                    hasBackground={true}
                    isLarge={false}
                    displayNoScriptImage={true}
                />
            </StyledOpportunityVideoWrap>

            <StyledOpportunityContentWrap layoutMode={layoutMode}>
                {layoutMode === 'double' && (
                    <StyledOpportunityHeaderWrap>
                        <StyledOpportunityHeader size={size}>
                            Live sport from Armadale to Optus Stadium. All from
                            $1 a day.
                        </StyledOpportunityHeader>
                        <StyledOpportunityLink
                            to={`https://subscriber.thewest.com.au/${subscribeUtm}`}
                            size={size}
                        >
                            Get access today
                        </StyledOpportunityLink>
                    </StyledOpportunityHeaderWrap>
                )}
                <StyledLiveSportsLogo />
            </StyledOpportunityContentWrap>
            <StyledAngle layoutMode={layoutMode} />
        </StyledOpportunityContainer>
    )
}

export const VideoTrailerCard = (props: VideoTrailerCardProps) => {
    const { size, layoutMode, onEvent, cardNumber } = props

    return (
        <EntitledToView
            requiredAccess={{ level: 'subscriber' }}
            entitled={() => {
                return <EntitledView layoutMode={layoutMode} />
            }}
            notEntitled={() => {
                return (
                    <OpportunityView
                        cardNumber={cardNumber}
                        layoutMode={layoutMode}
                        size={size}
                        item={item}
                        onEvent={onEvent}
                    />
                )
            }}
        />
    )
}

const item: MaybeLoaded<PublicationCardItem> = {
    loaded: true,
    result: {
        id: 'B881844003Z',
        headline: 'string',
        shortHeadline: 'string',
        link: '/sport/live',
        primaryTopic: {
            id: 'sports/live',
            title: '',
            seoTitle: '',
            metadata: {},
        },
        secondaryTopics: [
            {
                id: 'sports/live',
                title: '',
                seoTitle: '',
                metadata: {},
            },
        ],
        kicker: '',
        byline: 'string',
        source: 'string',
        allowCommenting: false,
        teaser: 'teaser',
        isLive: true,
        isSponsored: false,
        cardType: 'publication',
        publicationKind: 'article',
        requiredAccess: { level: 'anonymous' },
        _self: 'string',
        image: {
            altText: 'Live streams and replays - cover image',
            crops: {
                '16:9': {
                    reference: '/', // set cover image url here
                    width: 10,
                    height: 10,
                    ratio: {
                        width: 16,
                        height: 9,
                    },
                },
            },
            userSelectedCrop: '16:9',
            captionText: 'Live sports',
        },
        video: {
            id: '6248934362001',
            accountId: '4761797480001',
            heading: 'Live Stream',
            source: 'The West Australian',
            duration: '100',
            videoType: 'vod',
        },
        readTimeMinutes: null,
    },
}
