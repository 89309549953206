import { cx } from '@emotion/css'
import {
    CardEvents,
    CollectionEvent,
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import {
    StyledCard,
    StyledCardContainer,
    StyledCardMedia,
    StyledCardText,
    StyledImageContainer,
    StyledInlineContainer,
    StyledTextContainer,
} from './PerthNowInlineRelatedContentCards.styled'

interface TheWestInlineRelatedContentCardsProps {
    items: MaybeLoaded<PublicationCardItem[]>
    expectedCards: number
    onEvent: (event: CardEvents | CollectionEvent) => void
}
export const PerthNowInlineRelatedContentCards: React.FC<TheWestInlineRelatedContentCardsProps> =
    (props) => {
        //Transforms props.items into an array of MaybeLoaded items.
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)
        const storyClassification = true

        function linkClicked(
            e: React.MouseEvent<HTMLElement>,
            item: MaybeLoaded<PublicationCardItem>,
            cardNumber: number,
        ) {
            if (!item.loaded) {
                e.preventDefault()
                return
            }
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    item.result,
                    'PerthNowInlineRelatedContentCards',
                    'InlineRelatedContent',
                    cardNumber,
                    getFundingType(item.result, storyClassification),
                    'InlineRelatedCollection',
                ),
            )
        }

        return (
            <StyledCardContainer className={cx('hide-print')}>
                {items.map((item, index) => {
                    const key = (item.loaded && item.result.id) || index

                    return (
                        <StyledCard
                            to={toLinkWithHint(item)}
                            onClick={(event) => {
                                linkClicked(event, item, index)
                            }}
                            key={key}
                        >
                            <StyledInlineContainer>
                                <StyledImageContainer>
                                    <StyledCardMedia
                                        item={item}
                                        imageWidths={{
                                            mobile: '100vw',
                                            tablet: '100vw',
                                            desktop: '227px',
                                            fallbackWidth: 227,
                                        }}
                                        onEvent={props.onEvent}
                                        mediaMode={'default'}
                                        willPlayVideoInline={false}
                                        fixedRatio={{
                                            sm: '16:9',
                                            lg: '16:9',
                                            default: '16:9',
                                        }}
                                    />
                                </StyledImageContainer>
                                <StyledTextContainer>
                                    <StyledCardText
                                        item={item}
                                        teaserMode={'hidden'}
                                        onEvent={props.onEvent}
                                        innerRef={undefined}
                                        fontScale={1}
                                    />
                                </StyledTextContainer>
                            </StyledInlineContainer>
                        </StyledCard>
                    )
                })}
            </StyledCardContainer>
        )
    }
