import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { CollectionRouteProps } from '../../collections'
import { ThemeMargins } from '../../__styling'
import { AflScoreBoard } from './AflScoreBoard'

export interface AflScoreBoardProps extends CollectionRouteProps {
    gameId: string
    apiData?: any
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isAflw?: boolean
}

export const AflScoreBoardRegistration = createRegisterableComponent(
    'afl-match-centre-scoreboard',
    (props: AflScoreBoardProps, services) => {
        return <AflScoreBoard {...props} onEvent={services.onEvent} />
    },
)
