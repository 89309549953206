import { Profile as ProfileData } from '@news-mono/web-common'
import React, { Fragment } from 'react'
import { Box } from '../../compositions/Box/Box'
import { BioBlock } from '../../content/BioBlock/BioBlock'

export interface TheNightlyProfileProps {
    profile: ProfileData
    profileUrl: string
}

export const TheNightlyProfile: React.FC<TheNightlyProfileProps> = (props) => {
    return (
        <BioBlock
            profile={props.profile}
            url={props.profileUrl}
            title={props.profile.name}
        />
    )
}
