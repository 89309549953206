import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, colors } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledBuyNowV2Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    marginTop: calcRem(-18),
    marginBottom: calcRem(16),
}))

export const StyledBuyNowV2Link = styled(WebLink)(({ theme }) => ({
    width: '100%',
    background: tokens.sevennews.colors.palette.red,
    lineHeight: 'normal',
    textAlign: 'center',
    padding: calcRem(10, 10),
    fontSize: calcRem(17),
    color: colors.white,
    borderRadius: calcRem(4),
    textDecoration: 'none',
    letterSpacing: calcRem(2),
}))
