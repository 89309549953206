import { useEffect } from 'react'

interface MetadataProps {
    canonicalUrl: string
    prevLink: string | null
    nextLink: string | null
}
export const useMetadata = ({
    canonicalUrl,
    prevLink,
    nextLink,
}: MetadataProps) => {
    useEffect(() => {
        const updateMetadata = () => {
            const head = document.head

            head.querySelectorAll('link[rel="prev"]').forEach((el) =>
                el.remove(),
            )
            head.querySelectorAll('link[rel="next"]').forEach((el) =>
                el.remove(),
            )
            head.querySelectorAll('link[rel="canonical"]').forEach((el) =>
                el.remove(),
            )

            if (prevLink) {
                const prevLinkElement = document.createElement('link')
                prevLinkElement.rel = 'prev'
                prevLinkElement.href = prevLink
                head.appendChild(prevLinkElement)
            }

            if (nextLink) {
                const nextLinkElement = document.createElement('link')
                nextLinkElement.rel = 'next'
                nextLinkElement.href = nextLink
                head.appendChild(nextLinkElement)
            }

            if (canonicalUrl) {
                const canonicalLinkElement = document.createElement('link')
                canonicalLinkElement.rel = 'canonical'
                canonicalLinkElement.href = canonicalUrl
                head.appendChild(canonicalLinkElement)
            }
        }

        updateMetadata()

        return () => {
            const head = document.head

            head.querySelectorAll('link[rel="prev"]').forEach((el) =>
                el.remove(),
            )
            head.querySelectorAll('link[rel="next"]').forEach((el) =>
                el.remove(),
            )
            head.querySelectorAll('link[rel="canonical"]').forEach((el) =>
                el.remove(),
            )
        }
    }, [canonicalUrl, prevLink, nextLink])
}
