import { AppState } from '@news-mono/web-common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Paragraph } from '../../publication/Paragraph/Paragraph'
import { Subhead } from '../../typography/Subhead/Subhead'
import { smoothScrollTo } from '../../__helpers/smoothscroll'
import {
    StyledPodcastLogoLink,
    StyledPodcastLogos,
} from '../TheWestLive/TheWestLivePlayerPage/TheWestLivePlayerPage.styled'
import {
    StyledPlayIcon,
    StyledPodcastBodyContainer,
    StyledPodcastButton,
    StyledPodcastDescription,
    StyledPodcastHeader,
    StyledPodcastImage,
    StyledPodcastInfo,
    StyledPodcastSegment,
    StyledPodcastSharing,
    StyledPodcastShowMore,
    StyledPodcastSubHeading,
    StyledPodcastTitle,
} from './CourtInTheActPage.styled'

const applePodcastBadge = require('../TheWestLive/TheWestLivePlayerPage/assets/applepodcast.png')
const googlePodcastBadge = require('../TheWestLive/TheWestLivePlayerPage/assets/googlepodcast.png')
const spotifyPodcastBadge = require('../TheWestLive/TheWestLivePlayerPage/assets/spotifypodcast.png')

export interface CourtInTheActPageProps {
    shareUrl: string
    onEvent: (event: any) => void
}

export const CourtInTheActPage: React.FC<CourtInTheActPageProps> = (props) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const [showMore, setShowMore] = useState(false)

    return (
        <>
            {renditionType !== 'app' && (
                <StyledPodcastSegment>
                    <StyledPodcastImage
                        src={require('./assets/court-in-the-act-tile.jpg')}
                        width={'180px'}
                        height={'180px'}
                        alt={'Logo for Court in the Act podcast'}
                    />
                    <StyledPodcastInfo>
                        <StyledPodcastHeader>
                            <StyledPodcastTitle>
                                Court in the Act
                            </StyledPodcastTitle>
                            <StyledPodcastSharing
                                url={props.shareUrl}
                                text={
                                    'Check out the latest episode for The West Live'
                                }
                                onEvent={props.onEvent}
                                shareOptions={[
                                    'facebook',
                                    'twitter',
                                    'email',
                                    'clipboard',
                                ]}
                            />
                        </StyledPodcastHeader>
                        <StyledPodcastBodyContainer>
                            <StyledPodcastSubHeading>
                                friday
                            </StyledPodcastSubHeading>
                            <StyledPodcastDescription showMore={showMore}>
                                Do you live and breathe true crime? Want the
                                inside story? All the juicy details? You’re in
                                luck. Court in the Act with Tim Clarke goes
                                inside the courtroom with an in-depth look at
                                the cases happening right now. Join Clarke and
                                Australia’s leading legal minds for all the
                                twists and turns of the nation’s most
                                interesting cases.
                            </StyledPodcastDescription>
                            <StyledPodcastShowMore
                                onClick={() => {
                                    setShowMore(!showMore)
                                }}
                            >
                                {showMore ? 'Show less ...' : 'Show more ...'}
                            </StyledPodcastShowMore>
                        </StyledPodcastBodyContainer>
                        <StyledPodcastButton
                            onClick={() => {
                                const targetElement =
                                    document.getElementById('anchor')
                                if (!targetElement) {
                                    return
                                }
                                smoothScrollTo(targetElement)
                            }}
                        >
                            <StyledPlayIcon />
                            <div>listen</div>
                        </StyledPodcastButton>
                    </StyledPodcastInfo>
                </StyledPodcastSegment>
            )}

            <Subhead
                idAttribute="podcast"
                tag="h2"
                text="Subscribe to Court in the Act Podcast"
                onEvent={props.onEvent}
            />
            <Paragraph
                text="Never miss an episode by subscribing to the podcast - released each every Friday arvo."
                onEvent={props.onEvent}
            />
            <StyledPodcastLogoLink
                to="https://podcasts.apple.com/us/podcast/court-in-the-act/id1706274438"
                rel="noopener"
            >
                <StyledPodcastLogos
                    src={applePodcastBadge}
                    alt="Listen to The West Live on Apple Podcasts"
                    width="136"
                    height="33"
                />
            </StyledPodcastLogoLink>
            <StyledPodcastLogoLink
                to="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cub21ueWNvbnRlbnQuY29tL2QvcGxheWxpc3QvMTg4NGYyNTgtMTdhZi00OTJkLWE4OTEtYTczZTAwNzIxMzIyL2Y3ZDBhZTAxLTU1NTItNDNkMS1hZDQ2LWIwNzYwMDc4NDlkNC83ZDFkYjYzYy0wM2I1LTRhZDItOWVhMy1iMDc2MDA3OWU1MWQvcG9kY2FzdC5yc3M"
                rel="noopener"
            >
                <StyledPodcastLogos
                    src={googlePodcastBadge}
                    alt="Listen to The West Live on Google Podcasts"
                    width="134"
                    height="34"
                />
            </StyledPodcastLogoLink>
            <StyledPodcastLogoLink
                to="https://open.spotify.com/show/08t90tLG4t8GRUYsTVDrN5"
                rel="noopener"
            >
                <StyledPodcastLogos
                    src={spotifyPodcastBadge}
                    alt="Listen to The West Live on Spotify"
                    width="136"
                    height="33"
                />
            </StyledPodcastLogoLink>
            <div id="anchor"></div>
        </>
    )
}

CourtInTheActPage.displayName = 'TheWestLivePlayerPage'

export default CourtInTheActPage
