import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    LinkBanner,
    LinkBannerProps,
} from '../../banners/LinkBanner/LinkBanner'

export interface LinkBannerRouteProps extends LinkBannerProps {}

export const LinkBannerRegistration = createRegisterableComponent(
    'link-banner',
    (props: LinkBannerRouteProps) => <LinkBanner {...props} />,
)
