import styled from '@emotion/styled'

export const StyledIframe = styled.iframe<{ height: string }>(({ height }) => ({
    display: 'block',
    width: '100%',
    height,
    border: 0,
}))

export const StyledTNIframe = styled.iframe<{
    height: string
    entitled?: boolean
}>(({ height, entitled }) => [
    {
        display: 'block',
        width: '100%',
        height,
        border: 0,
        flexGrow: 1,
    },
    // disable iframe if not entitled
    !entitled && {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: 0.5,
    },
])
