import { shuffleArray } from '@news-mono/common'
import { Topic } from '@news-mono/web-common'
import React from 'react'
import { Tag } from '../../buttons/Tag/Tag'
import {
    StyledHeader,
    StyledNightlyText,
    StyledTopicListContainer,
    StyledTopicNav,
} from '../../content/TopicList/TopicList.styled'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { ThemeOverrideTypes } from '../../__styling'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { ThemedRender } from '../../__styling/themed-render'
import { useTheme } from '@emotion/react'

export interface TopicListProps {
    primaryTopic?: Topic
    secondaryTopics?: Topic[]
    verticalSpacing?: keyof ThemeMargins
    themeOverride?: ThemeOverrideTypes | undefined
}

export const TopicList: React.FC<TopicListProps> = ({
    primaryTopic,
    secondaryTopics = [],
    verticalSpacing,
    themeOverride,
}) => {
    const theme = useTheme()

    if (!primaryTopic && secondaryTopics.length === 0) {
        return null
    }

    let secondaryTopicsList = secondaryTopics.filter((topic) => {
        if (primaryTopic) {
            return topic.id !== primaryTopic.id
        }
        return true
    })

    // DPT-2180 - reduce the number of topics if there are a large amount of topics, cap at 6,
    // they will be shuffled to ensure distribution of potential topic listing click throughs
    if (secondaryTopics.length > 6) {
        secondaryTopicsList = shuffleArray(secondaryTopicsList)
    }
    return (
        <StyledTopicListContainer
            className="topic-list hide-print"
            verticalSpacing={verticalSpacing}
        >
            <ThemeOverrider override={themeOverride}>
                <ThemedRender
                    perthnow={() => <StyledHeader>Topics</StyledHeader>}
                    thewest={() => null}
                    sevennews={() => null}
                />
                {theme.kind === 'thenightly' ? (
                    <StyledTopicNav>
                        {primaryTopic && (
                            <>
                                <StyledNightlyText>
                                    Tags: &nbsp;
                                </StyledNightlyText>
                                <Tag topic={primaryTopic} />
                                <StyledNightlyText>
                                    {secondaryTopicsList.length !== 0
                                        ? ','
                                        : '.'}
                                    &nbsp;
                                </StyledNightlyText>
                            </>
                        )}

                        {secondaryTopicsList.map((topic, i) => {
                            return (
                                <div key={topic.id}>
                                    <Tag key={topic.id} topic={topic} />
                                    <StyledNightlyText>
                                        {i + 1 === secondaryTopicsList.length
                                            ? '.'
                                            : ','}
                                        &nbsp;
                                    </StyledNightlyText>
                                </div>
                            )
                        })}
                    </StyledTopicNav>
                ) : (
                    <StyledTopicNav>
                        {primaryTopic ? (
                            <Tag topic={primaryTopic} />
                        ) : undefined}

                        {secondaryTopicsList.map((topic) => (
                            <Tag key={topic.id} topic={topic} />
                        ))}
                    </StyledTopicNav>
                )}
            </ThemeOverrider>
        </StyledTopicListContainer>
    )
}
TopicList.displayName = 'TopicList'
