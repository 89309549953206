import {
    AppState,
    CountdownInactivityEvent,
    DataLayerEventName,
} from '@news-mono/web-common'
import debug from 'debug'
import React from 'react'
import { connect } from 'react-redux'
import { addListener, removeListener } from '../../__helpers/global-dom-events'

const countdownInteractivityDebug = debug('countdown-interactivity')


// same values for both timeToInactive and timeRequiredInactive results in instant tracking
export interface CountdownInactivityProps {
    /**
     * timeToInactive - timeRequiredInactive calculates when we start tracking inactivity  
     * Measured in seconds.
     */
    timeToInactive: number
    /**
     * Time period until inactivity reload  
     * Measured in seconds.
     */
    timeRequiredInactive: number

    onEvent: (event: CountdownInactivityEvent) => void
    westLiveActive?: boolean
}

const checkIfVideoPlayingOnPage = () => {
    const videoElements = document.getElementsByTagName('video')

    for (let i = 0; i < videoElements.length; ++i) {
        const videoElement = videoElements[i]

        const videoPlaying = !!(
            videoElement.currentTime > 0 &&
            !videoElement.paused &&
            !videoElement.ended &&
            videoElement.readyState > 2
        )

        const isTaboolaOrigin =
            videoElement.src && videoElement.src.includes('taboola')

        if (videoPlaying && !isTaboolaOrigin) {
            return true
        }
    }

    return false
}

// Used instead of window.setInterval because we
// need to hook into these functions and override them in tests.
// We can't use jests mock timers functionality because it causes other side effects
// like having to do jest.runAllTimers() to get fetchMock to work properly.
export const countdownTimer = {
    clearTimeout(handle: number) {
        window.clearTimeout(handle)
    },

    setTimeout(handler: (...args: any[]) => void, timeout: number) {
        return window.setTimeout(handler, timeout)
    },
}

class CountdownInactivity extends React.Component<
    CountdownInactivityProps,
    object
> {
    static displayName = 'CountdownInactivity'

    timer!: number
    startTime = Date.now()

    componentDidMount() {
        const initialCountdownTime =
            this.props.timeToInactive * 1000 -
            this.props.timeRequiredInactive * 1000

        this.timer = countdownTimer.setTimeout(
            this.startInactiveTimer,
            initialCountdownTime,
        )

        countdownInteractivityDebug('initial countdown started %o', [
            initialCountdownTime,
        ])
    }

    componentDidUpdate(prevProps: CountdownInactivityProps) {
        // We only want to do this if there is a change in the prop value of westLiveActive
        if (prevProps.westLiveActive !== this.props.westLiveActive) {
            countdownInteractivityDebug('state of westlive', [
                this.props.westLiveActive,
            ])
            this.clearTimerAndEvents()

            if (!this.props.westLiveActive) {
                this.startInactiveTimer()
            }
        }
    }

    resetInactiveTimer = () => {
        countdownInteractivityDebug('Inactive timer resetting')
        this.clearTimerAndEvents()
        this.startInactiveTimer()
    }
    startInactiveTimer = () => {
        this.timer = countdownTimer.setTimeout(
            this.finishCountdown,
            this.props.timeRequiredInactive * 1000,
        )

        countdownInteractivityDebug('Inactive timer starting')

        addListener('click', this.resetInactiveTimer)
        addListener('mousemove', this.resetInactiveTimer)
        addListener('keypress', this.resetInactiveTimer)
        addListener('scroll', this.resetInactiveTimer)
        addListener('touchstart', this.resetInactiveTimer)
    }

    checkRefreshCount = (startTime: number) => {
        try {
            const setRefreshCount = (
                count: number,
                initialStartTime: number,
            ) => {
                sessionStorage.setItem(
                    'currentRefreshData',
                    JSON.stringify({
                        count,
                        page: window.location.href,
                        initialStartTime,
                    }),
                )

                return { refreshCount: count, initialStartTime }
            }

            countdownInteractivityDebug('Checking refresh count')

            const storedRefreshData =
                sessionStorage.getItem('currentRefreshData')

            if (storedRefreshData === null) {
                return setRefreshCount(1, startTime)
            }

            const refreshData = JSON.parse(storedRefreshData)

            if (refreshData.page !== window.location.href) {
                return setRefreshCount(1, startTime)
            }

            return setRefreshCount(
                refreshData.count + 1,
                refreshData.initialStartTime,
            )
        } catch (_er) {
            return { refreshCount: null, initialStartTime: null }
        }
    }

    finishCountdown = () => {
        // We also do naive checking to make sure a video is not currently being played on the page.
        // This will not detect if a video ad is playing
        if (checkIfVideoPlayingOnPage()) {
            this.resetInactiveTimer()

            return
        }

        this.clearTimerAndEvents()

        const { refreshCount, initialStartTime } = this.checkRefreshCount(
            this.startTime,
        )

        countdownInteractivityDebug('Refreshing...')

        this.props.onEvent({
            type: DataLayerEventName.countdownInactivityFinished,
            originator: 'CountdownInactivity',
            payload: {
                timeSinceInitial:
                    initialStartTime && Date.now() - initialStartTime,
                refreshCount,
                timeTillInactive: Date.now() - this.startTime,
            },
        })
    }

    clearTimerAndEvents = () => {
        countdownInteractivityDebug('Clearing listeners...')
        removeListener('click', this.resetInactiveTimer)
        removeListener('mousemove', this.resetInactiveTimer)
        removeListener('keypress', this.resetInactiveTimer)
        removeListener('scroll', this.resetInactiveTimer)
        removeListener('touchstart', this.resetInactiveTimer)

        countdownTimer.clearTimeout(this.timer)
    }

    componentWillUnmount() {
        this.clearTimerAndEvents()
    }

    render() {
        return null
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        westLiveActive: state.theWestLive.isLive,
    }
}

export default connect(mapStateToProps, null)(CountdownInactivity)
