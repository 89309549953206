import React from 'react'
import { YoutubeLogo } from './YoutubeLogo'
import {
    StyledBackground,
    StyledPromoCard,
    StyledYoutubeLogo,
} from './YoutubePromoCard.styled'

export type YTPromoDataType = {
    [key: string]: { backgroundUrl: string; youtubeLink: string }
}

export function YoutubePromoCard({ profileType }: { profileType: string }) {
    const identity = profileType.replace(/-/g, '')

    const YTPromoData: YTPromoDataType = {
        sarahison: {
            backgroundUrl: require('./assets/sarah.png'),
            youtubeLink: 'https://www.youtube.com/c/politicsmadeokay',
        },
        emmagarlett: {
            backgroundUrl: require('./assets/emma.png'),
            youtubeLink: 'https://www.youtube.com/c/Paintitblakthewest',
        },
    }

    return (
        <StyledPromoCard to={YTPromoData[identity].youtubeLink}>
            <StyledBackground
                backgroundUrl={YTPromoData[identity].backgroundUrl}
            />
            <StyledYoutubeLogo>
                <YoutubeLogo />
            </StyledYoutubeLogo>
        </StyledPromoCard>
    )
}
