import React from 'react'
import {
    StyledOlympicsAuthor,
    StyledOlympicsByline,
    StyledOlympicsBylineProfileThumbnail,
} from './OlympicsCollectionByline.styled'
import { Profile } from '@news-mono/web-common'
import { BylineProfile } from '../../publication/Byline/components/BylineProfile'
import { colors } from '../../__styling'

export type OlympicsBylineType = 'feature-article' | 'article'

export interface OlympicsCollectionBylineProps {
    profiles?: Profile[]
    showProfile?: boolean
    type: OlympicsBylineType
    text: string
}

/**
 * Build a custom byline utilising the author's name and thumbnail, if
 * appropriate.
 */
export const OlympicsCollectionByline: React.FC<OlympicsCollectionBylineProps> =
    ({ profiles, showProfile = false, type, text }) => {
        const displayProfile = showProfile && profiles !== undefined

        return (
            <StyledOlympicsByline type={type}>
                {displayProfile && (
                    <StyledOlympicsBylineProfileThumbnail>
                        <BylineProfile
                            profile={profiles}
                            profilePageLink={true}
                        />
                    </StyledOlympicsBylineProfileThumbnail>
                )}
                <StyledOlympicsAuthor type={type} color={colors.olympics.text}>
                    {text}
                </StyledOlympicsAuthor>
            </StyledOlympicsByline>
        )
    }
