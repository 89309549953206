import styled from '@emotion/styled'
import { calcRem } from '../../../__styling'
import { createPropFilter } from '@news-mono/web-common'

interface StyledSkeletonCardProps {
    gridAreaName?: string
    overrideWidth?: number
    overrideHeight?: number
    isVerticalCard?: boolean
    isHomeHeroCard?: boolean
}

export const StyledSkeletonCard = styled('div', {
    shouldForwardProp: createPropFilter()(['gridAreaName']),
})<StyledSkeletonCardProps>(
    ({
        gridAreaName,
        overrideWidth,
        overrideHeight,
        isVerticalCard,
        isHomeHeroCard,
    }) => [
        {
            width: overrideWidth ? calcRem(overrideWidth) : '100%',
            minHeight: calcRem(100),
            height: overrideHeight ? calcRem(overrideHeight) : undefined,
            display: 'grid',
            gridTemplateColumns: '3fr 2fr',
            columnGap: calcRem(13),
            '& [class*="StyledSkeleton"]': {
                borderRadius: calcRem(4),
            },
        },
        gridAreaName && {
            gridArea: gridAreaName,
        },
        isVerticalCard && {
            gridTemplateColumns: 'unset',
        },
        isHomeHeroCard && {
            gridTemplateColumns: '1fr 2fr',
        },
    ],
)

interface StyledSkeletonTextProps {
    isVerticalCard?: boolean
}
export const StyledSkeletonText = styled('div')<StyledSkeletonTextProps>(
    ({ isVerticalCard }) => ({
        position: 'relative',
        width: '100%',
        height: isVerticalCard ? calcRem(170) : undefined,
        gridRow: isVerticalCard ? '2' : undefined,
        '& [class*="StyledSkeleton"]': {
            height: calcRem(20),
        },
    }),
)

interface StyledSkeletonMediaProps {
    isVerticalCard?: boolean
}
export const StyledSkeletonMedia = styled('div')<StyledSkeletonMediaProps>(
    ({ isVerticalCard }) => ({
        width: '100%',
        gridRow: isVerticalCard ? '1' : undefined,
        height: isVerticalCard ? calcRem(210) : '100%',
        '& [class*="StyledSkeleton"]': {
            height: '95%',
        },
    }),
)

export const StyledSkeletonTimeToRead = styled('div')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    '& [class*="StyledSkeleton"]': {
        height: calcRem(15),
        width: calcRem(55),
    },
})
