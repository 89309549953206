import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface StyledVideoIconProps {
    className?: string
    width: number
    height: number
    horizontalSpacing?: number | undefined
}

export const StyledVideoIcon = styled('svg')<StyledVideoIconProps>((props) => ({
    width: calcRem(props.width),
    height: calcRem(props.height),
    marginLeft: props.horizontalSpacing && calcRem(props.horizontalSpacing),
    fill: props.theme.colors.icons?.default,
}))
