import styled from '@emotion/styled'
import { calcRem } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledArticleSummaryWrapper = styled('div')(({ theme }) => ({
    background: theme.colors.components?.summary?.background,
    padding: calcRem(theme.margins.md),
    margin: calcRem(0, 0, theme.margins.lg, 0),
    'border-radius': calcRem(10),
    h2: {
        marginTop: 0,
        fontFamily: theme.fonts.sansSerif,
    },
    ul: {
        marginBottom: 0,
    },
    'ul li': {
        fontSize: calcRem(18),
        lineHeight: calcRem(24),
        fontFamily: theme.fonts.sansSerif,
        color: theme.colors.components?.summary?.text,
    },
    'a': {
        color: theme.colors.components?.summary?.text,
    },
    'li:last-child': {
        marginBottom: 0,
    },
}))
