import React from 'react'
import { StyledFlexRow, StyledWebLink } from './OlympicsWidgetLinks.styled'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'
import { useLocation } from 'react-router'

export interface OlympicWidgetLinksProps {
    size: OlympicsWidgetSize
    pageType: string
}

export function OlympicWidgetLinks(props: OlympicWidgetLinksProps) {
    const { size, pageType } = props
    const location = useLocation()

    return (
        <StyledFlexRow size={size}>
            <StyledWebLink
                size={size}
                to="/sport/olympics/medal-tally"
                className={pageType === 'medal-tally' ? 'isActive' : undefined}
            >
                Full Medal Tally
            </StyledWebLink>
            <StyledWebLink
                size={size}
                to="/sport/olympics"
                className={
                    location.pathname.endsWith('olympics') &&
                    !location.pathname.includes('medal-tally')
                        ? 'isActive'
                        : undefined
                }
            >
                Olympics Coverage
            </StyledWebLink>
        </StyledFlexRow>
    )
}
