import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { StyledButton } from '../../../../buttons/Button/Button.styled'
import { colors } from '../../../../__styling/settings/colors'
import {
    breakpoint,
    breakpointMax,
} from '../../../../__styling/style-functions'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { fluidTypography } from '../../../../__styling/style-mixins'
import { themedValue } from '../../../../__styling/themed-value'
import { BreachWallStyle } from '../../helpers/BreachWallTypes'
import { PackageType } from '../../common'

const SubscribeWithGoogle = require('./SubscribeWithGoogle.svg')

export type PackageTheme =
    | 'default'
    | 'red'
    | 'yellow'
    | '190year'
    | 'abtest'
    | 'abtest2'
    | 'vanishing-cousins'
    | 'west-blue'

export interface PackageOptions {
    theme: PackageTheme
    type?: PackageType
}
export interface BreachOptions {
    style: BreachWallStyle | 'afl50-style'
    useBreachDealStyle?: boolean
    isRegional?: boolean
}

export interface PackageThemeProps {
    packageOptions: PackageOptions
    breachOptions: BreachOptions
}

export const StyledSubscriptionPackage = styled('div')<PackageThemeProps>(
    ({ theme, packageOptions, breachOptions }) => ({
        flexBasis: calcRem(278),
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        padding: calcRem(theme.margins.md, 24),
        margin: calcRem(theme.margins.md),
        borderRadius: 6,
        fontFamily: theme.fonts.subscriber.text,
        background:
            packageOptions.theme === '190year'
                ? tokens.thewest.colors.palette.white
                : 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(191, 205, 216, 0.5) 100%), #FFF;',
        color:
            packageOptions.theme === '190year'
                ? tokens.thewest.colors.palette.westblue
                : themedValue(theme, {
                      thewest: colors.thewest.westblue,
                      perthnow: colors.perthnow.pinkThulite,
                      sevennews: colors.sevennews.red,
                      fallback: undefined,
                  }),
        boxShadow:
            breachOptions.isRegional
                ? '0 2px 21px 0 rgba(47, 48, 104, 0.4)'
                : '0 2px 21px 0 rgba(0,0,0,0.5)',
        [breakpointMax('xxs')]: {
            margin: calcRem(theme.margins.md, 0),
            flexBasis: calcRem(250),
        },
    }),
)

export const StyledSubscriptionPackage2022 = styled(
    StyledSubscriptionPackage,
)<PackageThemeProps>(({ theme, packageOptions, breachOptions }) => ({
    flexBasis: calcRem(312),
    margin:
        breachOptions.style === 'afl50-style'
            ? calcRem(0, 0, 18, 0)
            : calcRem(0, 12, 18, 0),
    padding:
        breachOptions.style === 'afl50-style' ||
        breachOptions.style === 'eofy'
            ? calcRem(16)
            : calcRem(24),
    background: theme.colors.background.primary || 'white',
    boxShadow: undefined,
    minHeight: 'auto',
    height: breachOptions.style === 'afl50-style' ||
            breachOptions.style === 'eofy'
            ? calcRem(790)
            : 'auto',

    '&:nth-child(2n)': {
        marginRight: breachOptions.style === 'afl50-style'
            ? calcRem(28)
            : calcRem(12),

        h4: {
            margin: undefined,
        },

        button: {
            background:
                packageOptions.theme === 'vanishing-cousins'
                    ? colors.white
                    : undefined,
            color:
                packageOptions.theme === 'vanishing-cousins'
                    ? colors.thewest.westblue
                    : undefined,
            border:
                packageOptions.theme === 'vanishing-cousins'
                    ? `2px solid ${colors.thewest.westblue}`
                    : undefined,

            '&:focus': {
                background:
                    packageOptions.theme === 'vanishing-cousins'
                        ? colors.thewest.greyKoala
                        : undefined,
                color:
                    packageOptions.theme === 'vanishing-cousins'
                        ? colors.white
                        : undefined,
                border:
                    packageOptions.theme === 'vanishing-cousins'
                        ? 'none'
                        : undefined,
            },

            '&:hover': {
                background:
                    packageOptions.theme === 'vanishing-cousins'
                        ? colors.thewest.greyGorilla
                        : undefined,
                color:
                    packageOptions.theme === 'vanishing-cousins'
                        ? colors.white
                        : undefined,
                border:
                    packageOptions.theme === 'vanishing-cousins'
                        ? 'none'
                        : undefined,
            },
        },
    },

    [breakpointMax('md')]: {
        flexBasis: calcRem(312),
    },
    [breakpointMax('xs')]: {
        height: 'auto',
        '&:nth-child(2n)': {
            marginRight: 0,
        },
        marginRight: 0,
    },
    [breakpointMax('xxs')]: {
        marginRight: 0,
        flexGrow: 1,
    },
}))

interface StyledPackageImageProps {
    isRegional?: boolean
    isEditionsModal?: boolean
    packageTheme?: PackageTheme
    packageType?: PackageType
    breachStyle?: BreachWallStyle
    customScale?: number
}

export const StyledPackageImage = styled('img')<StyledPackageImageProps>(
    ({
        isRegional,
        isEditionsModal,
        breachStyle,
        packageTheme,
        packageType,
        customScale,
    }) => ({
        width: isEditionsModal ? '75%' : '95%',
        display: 'block',
        margin: '0 auto',
        marginTop:
            isRegional && !isEditionsModal
                ? calcRem(25)
                : (breachStyle === 'black-friday' || breachStyle === 'eofy') && packageType === 'digital'
                ? calcRem(6.4)
                : 0,
        transform: customScale ? `scale(${customScale})` : 'scale(1.1)',
        height: '180px',
        objectFit: 'contain',
    }),
)

export const StyledMiniPackageImage = styled(StyledPackageImage)({
    height: 'auto',
    transform: 'unset',
})

export const StyledSubscriptionPackageHeading = styled('h4')<PackageThemeProps>(
    ({ theme }) => ({
        fontSize: calcRem(24),
        fontWeight: 700,
        lineHeight: 1.167,
        margin: calcRem(theme.margins.md, 0, theme.margins.sm, 0),
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.greyBasalt90,
            sevennews: colors.sevennews.charade,
            fallback: undefined,
        }),

        flex: '0 0 auto', // fixes ie11 column wrap bug
    }),
)

export const StyledSubscriptionPackageHeading2022 = styled(
    'h4',
)<PackageThemeProps>(({ theme, breachOptions, packageOptions }) => ({
    fontFamily: theme.fonts.serif,
    fontSize: calcRem(22),
    lineHeight: 1,
    fontWeight: undefined,
    margin:
        breachOptions.style === 'afl50-style' &&
              packageOptions.type === 'digital'
            ? calcRem(39.4, 0, theme.margins.sm, 0)
            : calcRem(theme.margins.md, 0, theme.margins.sm, 0),
    color: themedValue(theme, {
        thewest: colors.black,
        perthnow: colors.perthnow.greyBasalt90,
        sevennews: colors.sevennews.charade,
        fallback: undefined,
    }),

    flex: '0 0 auto', // fixes ie11 column wrap bug
}))

export const StyledSubscriptionPackageOriginalPrice = styled(
    'h4',
)<PackageThemeProps>(({ theme, packageOptions, breachOptions }) => ({
    fontSize:
        packageOptions.theme === 'abtest2' ||
        breachOptions.style === 'black-friday'
            ? calcRem(22)
            : calcRem(16),
    fontWeight: packageOptions.theme === 'abtest2' ? 400 : 700,
    lineHeight: 1.167,
    textDecoration: 'line-through',
    textTransform:
        packageOptions.theme === 'abtest2' ||
        breachOptions.style === 'black-friday'
            ? 'none'
            : 'uppercase',
    margin: 0,
    color: themedValue(theme, {
        thewest: colors.thewest.greyGorilla,
        perthnow: colors.perthnow.greyBasalt90,
        sevennews: colors.sevennews.charade,
        fallback: undefined,
    }),

    flex: '0 0 auto', // fixes ie11 column wrap bug

    [breakpointMax('xs')]: {
        fontSize: calcRem(16),
    },
}))

export const StyledSubscriptionPackageOriginalPrice2022 = styled(
    StyledSubscriptionPackageOriginalPrice,
)(({ theme, packageOptions, breachOptions }) => ({
    fontFamily:
        packageOptions.theme === 'abtest2'
            ? theme.fonts.serif
            : theme.fonts.sansSerifCond,
    color:
        packageOptions.theme === '190year' && breachOptions.isRegional
            ? tokens.thewest.colors.palette.westblue
            : breachOptions.isRegional && breachOptions.useBreachDealStyle
            ? tokens.thewest.colors.palette.westblue
            : theme.sectionValues.primaryColor,
    fontFeatureSettings: "'pnum' on, 'lnum' on",
}))

const themedPriceFluid = (theme: Theme) =>
    themedValue(theme, {
        sevennews: fluidTypography(375, 1000, 24, 28),
        perthnow: fluidTypography(375, 1020, 24, 30),
        thewest: fluidTypography(375, 1220, 24, 24),
        fallback: undefined,
    })

export const StyledSubscriptionPackagePrice = styled('p')<PackageThemeProps>(
    ({ theme }) => ({
        ...themedPriceFluid(theme),
        flex: '0 0 auto', // fixes ie11 column wrap bug
        fontWeight: 500,
        lineHeight: 1.167,
        margin: 0,
        color: theme.sectionValues.primaryColor,
    }),
)

export const StyledSubscriptionPackagePrice2022 = styled(
    StyledSubscriptionPackagePrice,
)(({ theme, packageOptions, breachOptions }) => ({
    flex: '0 0 auto', // fixes ie11 column wrap bug
    fontSize:
        breachOptions.style === 'black-friday' ? calcRem(40) : calcRem(32),
    fontWeight: 700,
    fontFamily: theme.fonts.sansSerifCond,
    fontFeatureSettings: "'pnum' on, 'lnum' on",
    textTransform:
        breachOptions.style === 'black-friday' ? 'none' : 'uppercase',
    color:
        packageOptions.theme === '190year' && breachOptions.isRegional
            ? tokens.thewest.colors.palette.westblue
            : breachOptions.isRegional && breachOptions.useBreachDealStyle
            ? tokens.thewest.colors.palette.westblue
            : theme.sectionValues.primaryColor,
}))

export const StyledSubscriptionPackagePriceQualifier = styled(
    'p',
)<PackageThemeProps>(({ packageOptions, breachOptions }) => ({
    fontSize:
        packageOptions.theme === 'abtest2'
            ? calcRem(19)
            : breachOptions.style === 'black-friday'
            ? calcRem(16)
            : calcRem(14),
    flex: '0 0 auto', // fixes ie11 column wrap bug
    fontWeight: 700,
    lineHeight: 1.167,
    margin: 0,
    color:
        breachOptions.style === 'afl50-style'
            ? colors.black
            : packageOptions.theme === 'red'
            ? colors.thewest.ladybird
            : packageOptions.theme === 'yellow'
            ? colors.thewest.sunshineYellow
            : packageOptions.theme == '190year' ||
              packageOptions.theme == 'abtest' ||
              packageOptions.theme == 'abtest2'
            ? tokens.thewest.colors.palette.anniversary190YearPrimary
            : packageOptions.theme === 'vanishing-cousins' ||
              breachOptions.style === 'black-friday' ||
              packageOptions.theme === 'west-blue'
            ? colors.black
            : undefined,
}))

export const StyledSubscriptionPackagePriceDivider2022 = styled(
    'hr',
)<PackageThemeProps>(({ theme, packageOptions, breachOptions }) => ({
    width: '100%',
    maxWidth:
        breachOptions.style === 'black-friday'
            ? calcRem(112)
            : breachOptions.style === 'afl50-style'
            ? calcRem(80)
            : calcRem(53),
    height: calcRem(4),
    backgroundColor:
        packageOptions.theme == '190year' || packageOptions.theme == 'abtest2'
            ? tokens.thewest.colors.palette.anniversary190YearPrimary
            : breachOptions.isRegional && breachOptions.useBreachDealStyle
            ? tokens.thewest.colors.palette.westblue
            : tokens.thewest.colors.palette.westblue,
    marginLeft: 0,
    borderRadius: calcRem(4),
    border: 'none',
    marginTop: calcRem(6),
    marginBottom: calcRem(16),
}))

export const StyledSubscriptionPackageActions = styled('div')<{paddingBot?: number}>(({ paddingBot }) => ([{
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
    lineHeight: 'normal',
},
paddingBot && {
    padding: calcRem(0, 0, paddingBot ?? 0)
}
]))

export const StyledSubscriptionPackageButton = styled(
    StyledButton, //target styledbutton
)<PackageThemeProps>(({ theme, packageOptions, breachOptions }) => ({
    margin: calcRem(theme.margins.md, 0, 0),
    width: calcRem(200),
    height: calcRem(40),
    fontSize: calcRem(16),

    [breakpoint('xxs')]: {
        fontSize: calcRem(18),
        width: calcRem(230),
    },

    color:
        packageOptions.theme === '190year'
            ? tokens.thewest.colors.palette.anniversary190YearPrimary
            : undefined,

    backgroundColor:
        packageOptions.theme === 'red'
            ? colors.thewest.ladybird
            : packageOptions.theme === 'yellow'
            ? colors.thewest.sunshineYellow
            : packageOptions.theme === '190year'
            ? tokens.thewest.colors.palette.anniversary190YearTeal
            : breachOptions.isRegional && breachOptions.useBreachDealStyle
            ? tokens.thewest.colors.palette.westblue
            : packageOptions.theme === 'west-blue'
            ? colors.thewest.westblue
            : undefined,

    background:
        breachOptions.useBreachDealStyle && packageOptions.theme === 'abtest'
            ? tokens.thewest.colors.palette.newWestGradient.purpleBlue2
            : packageOptions.theme === 'abtest2'
            ? tokens.thewest.colors.palette.newWestGradient.purpleBlue3
            : packageOptions.theme === 'red'
            ? colors.thewest.ladybird
            : packageOptions.theme === 'yellow'
            ? colors.thewest.sunshineYellow
            : packageOptions.theme === '190year'
            ? tokens.thewest.colors.palette.anniversary190YearTeal
            : breachOptions.isRegional && breachOptions.useBreachDealStyle
            ? tokens.thewest.colors.palette.westblue
            : packageOptions.theme === 'west-blue'
            ? colors.thewest.westblue
            : undefined,
}))

export const StyledSubscriptionPackageButton2022 = styled(
    StyledSubscriptionPackageButton,
)<PackageThemeProps>({
    fontWeight: 500,
    width: '100%',
    textTransform: 'none',
    transition: '0s background ease',
    [breakpoint('xxs')]: { width: '100%' },
})

export const StyledSubscriptionCancellationMessage = styled(
    'p',
)<PackageThemeProps>(({ theme, packageOptions }) => ({
    fontSize: calcRem(12),
    flex: '0 0 auto', // fixes ie11 column wrap bug
    fontWeight: 400,
    lineHeight: packageOptions.theme === 'abtest2' ? 1 : 1.167,
    margin: 'auto 0 0',
    color: themedValue(theme, {
        thewest: colors.thewest.greyRhino,
        perthnow: colors.perthnow.greyBasalt90,
        sevennews: colors.sevennews.charade,
        fallback: undefined,
    }),
    paddingTop: calcRem(theme.margins.sm),
}))

export const Ribbon = styled('p')<PackageThemeProps>(
    ({ theme, packageOptions, breachOptions }) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        background:
            packageOptions.theme === 'red'
                ? colors.thewest.ladybird
                : packageOptions.theme === 'yellow'
                ? colors.thewest.sunshineYellow
                : packageOptions.theme === 'west-blue'
                ? colors.thewest.westblue
                : breachOptions.style === 'black-friday'
                ? tokens.thewest.colors.palette.aqua
                : 'linear-gradient(45deg, #aa01e1 0%,#f400ab 100%)',
        transform:
            packageOptions.theme === 'abtest2'
                ? 'rotate(45deg) translate(16%,-100%)'
                : packageOptions.theme === 'vanishing-cousins'
                ? 'rotate(45deg) translate(11%, -105%)'
                : breachOptions.style === 'black-friday'
                ? 'rotate(45deg) translate(13%, -105%)'
                : 'rotate(45deg) translate(15%,-100%)',
        height: 80,
        width: 160,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontSize:
            packageOptions.theme === 'vanishing-cousins' ||
            breachOptions.style === 'black-friday'
                ? calcRem(32)
                : calcRem(10),
        fontWeight: 700,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.5)',

        span: {
            color:
                packageOptions.theme === '190year'
                    ? tokens.thewest.colors.palette.anniversary190YearPrimary
                    : breachOptions.style === 'black-friday'
                    ? tokens.thewest.colors.palette.black
                    : colors.white,
            display: 'block',
            width: 50,
            textAlign: 'center',
            lineHeight: 1,
            marginBottom:
                packageOptions.theme === 'vanishing-cousins' ||
                breachOptions.style === 'black-friday'
                    ? 0
                    : theme.margins.xs,
        },

        svg: {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, calc(-100% - 5px))',
        },

        '&.cornerDesignTag': {
            height: packageOptions.theme === 'abtest2' ? 100 : 94,
            width: packageOptions.theme === 'abtest2' ? 280 : 234,
            fontSize:
                packageOptions.theme === '190year'
                    ? 20
                    : packageOptions.theme === 'vanishing-cousins'
                    ? calcRem(32)
                    : breachOptions.style === 'black-friday'
                    ? calcRem(28)
                    : 24,
            flexDirection: 'column',
            alignItems: 'center',
            lineHeight: '91%',

            span: {
                width:
                    packageOptions.theme === '190year'
                        ? 78
                        : packageOptions.theme === 'abtest2'
                        ? '75%'
                        : packageOptions.theme === 'vanishing-cousins' ||
                          breachOptions.style === 'black-friday'
                        ? '100%'
                        : 50,

                '&:first-child': {
                    paddingTop:
                        packageOptions.theme === 'abtest2'
                            ? calcRem(35)
                            : calcRem(20),
                    fontSize:
                        packageOptions.theme === 'abtest2' ||
                        packageOptions.theme === 'vanishing-cousins' ||
                        breachOptions.style === 'black-friday'
                            ? calcRem(28)
                            : 'inherit',
                    marginTop:
                        packageOptions.theme === 'vanishing-cousins' ||
                        breachOptions.style === 'black-friday'
                            ? 'auto'
                            : undefined,
                    marginBottom:
                        packageOptions.theme === 'abtest2'
                            ? 0
                            : packageOptions.theme === 'vanishing-cousins' ||
                              breachOptions.style === 'black-friday'
                            ? undefined
                            : 'inherit',
                    lineHeight:
                        packageOptions.theme === 'abtest2'
                            ? 0.7
                            : packageOptions.theme === 'vanishing-cousins' ||
                              breachOptions.style === 'black-friday'
                            ? 0.6
                            : 'inherit',
                },

                '&:last-child': {
                    fontSize:
                        packageOptions.theme === 'abtest2'
                            ? calcRem(40)
                            : 'inherit',
                    marginBottom:
                        packageOptions.theme === 'abtest2'
                            ? calcRem(15)
                            : packageOptions.theme === 'vanishing-cousins'
                            ? calcRem(7.2)
                            : breachOptions.style === 'black-friday'
                            ? calcRem(12)
                            : 0,
                },
            },
            svg: {
                display: 'none',
            },
        },

        '&.newDesignTag': {
            position: 'unset',
            transform: 'none',
            height: 'auto',
            width: '90px',
            padding: '10px',
            paddingRight: '25px',
            marginTop: '20px',
            marginBottom: '-5px',
            borderRadius: '30px',

            span: {
                position: 'relative',
                margin: 0,
                fontSize: '0.65rem',

                svg: {
                    left: 'unset',
                    right: '-16px',
                    width: '12px',
                    transform: 'translateY(-3px)',
                },
            },
        },
    }),
)

export const StyledPromoTagline = styled('p')<{ hasAsset: boolean }>(
    ({ hasAsset }) => ({
        fontSize: calcRem(16),
        fontWeight: 600,
        color: tokens.thewest.colors.palette.black,
        paddingLeft: hasAsset ? '20px' : undefined,
        marginTop: 0,
        position: 'relative',

        img: {
            position: 'absolute',
            left: '0px',
            top: calcRem(4),
            transform: 'rotate(-15deg)',
            width: '16px',
            height: '16px',
            objectFit: 'cover',
        },
    }),
)

export const StyledSubscriptionPackageBenefit = styled.div(({ theme }) => ({
    fontFamily: theme.fonts.serif,
    fontSize: calcRem(16),
    color: 'black',
    display: 'flex',
    margin: calcRem(6, 0),
    alignItems: 'center',
    textAlign: 'start',

    '& > :first-child': {
        flex: '0 0 ' + calcRem(20),
    },
    '& > :last-child': {
        flex: '1 1 auto',
        marginLeft: calcRem(8),
    },
}))

export const StyledSubscribeWithGoogleButton = styled('button')(
    ({ theme }) => ({
        marginTop: theme.margins.sm,
        cursor: 'pointer',
        maxWidth: '100%',
        borderRadius: '4px',
    }),
)

export const StyledSubscribeWithGoogleButton2022 = styled(
    StyledSubscribeWithGoogleButton,
)({
    width: '100%',
    padding: 0,
    borderRadius: calcRem(2),
    border: `1px solid ${colors.thewest.greyKoala}`,
    boxShadow: 'none',
    '&::after': {
        content: "''",
        position: 'relative',
        display: 'inline-block',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('${SubscribeWithGoogle}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    '&:hover': {
        backgroundColor: '#f8f8f0',
    },
})

// News Breach V2 2024
export const StyledSubscriptionButton2024 = styled(StyledSubscriptionPackageButton2022)<PackageThemeProps>(({breachOptions}) => ({
    background: 'linear-gradient(270deg, rgb(220, 1, 190) 0%, rgb(170, 1, 225) 100%)',
    // background: breachOptions.style === 'black-friday' ? tokens.thewest.colors.palette.aqua : 'linear-gradient(270deg, rgb(220, 1, 190) 0%, rgb(170, 1, 225) 100%)',
    fontSize: '20px',
    height: '2.2em',
    // color: breachOptions.style !== 'black-friday'  ? tokens.thewest.colors.palette.black : undefined
}))

export const StyledSwgButton2024 = styled(StyledSubscribeWithGoogleButton)(() => ({
    width: '100%',
    padding: 0,
    border: 'none',
    borderRadius: calcRem(2),
    '&::after': {
        content: "''",
        position: 'relative',
        display: 'inline-block',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('${SubscribeWithGoogle}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    '&:hover': {
        backgroundColor: '#f8f8f0',
    }
}))