import styled from '@emotion/styled'
import { IconCross } from '../../icons/IconCross/IconCross'
import { IconSearch } from '../../icons/IconSearch/IconSearch'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { metrics } from '../../__styling'

interface StyledSearchContentProps {
    searchInMainNav?: boolean
    searchHasValue: boolean
    isLarge?: boolean
}

interface StyledSearchSubmitButtonProps {
    disabled: boolean
    isLarge: boolean
}

interface StyledClearButtonProps {
    searchHasValue: boolean
    isLarge: boolean
}

export const StyledSearchContainer = styled('div')<StyledSearchContentProps>(
    ({ searchInMainNav, isLarge, theme }) => [
        {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        theme.kind === 'thenightly' &&
            !searchInMainNav &&
            isLarge && {
                margin: `${calcRem(metrics.thenightly.margins.m2l)} 0`,

                [breakpoint('lg')]: {
                    margin: `${calcRem(metrics.thenightly.margins.xxxl)} 0`,
                },
            },
        searchInMainNav &&
            !isLarge && {
                display: 'none',

                [breakpoint('sm')]: {
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                },
            },
    ],
)

export const StyledSearchIcon = styled(IconSearch)(({ theme }) => ({
    width: calcRem(14),
    height: calcRem(14),
    fill: theme.sectionValues.primaryColor,
    display: 'flex',
}))
StyledSearchIcon.displayName = 'SearchIcon'

export const StyledCrossIcon = styled(IconCross)({
    height: calcRem(10),
    width: calcRem(10),
    fill: colors.thewest.greyGorilla,
})
StyledCrossIcon.displayName = 'IconCross'

interface StyledSearchFormProps {
    searchHasFocus: boolean
    searchHasValue: boolean
    isLarge: boolean
    searchInMainNav?: boolean
}

export const StyledSearchForm = styled('form')<StyledSearchFormProps>(
    ({ isLarge, searchHasFocus, searchHasValue, searchInMainNav, theme }) => ({
        display: 'flex',
        flexDirection:
            theme.kind === 'thenightly' && isLarge && !searchInMainNav
                ? 'row-reverse'
                : 'row',
        justifyContent:
            theme.kind === 'thenightly' && isLarge ? 'start' : undefined,
        padding:
            theme.kind === 'thenightly'
                ? calcRem(8, 12)
                : isLarge
                ? calcRem(6, 4, 6, 10)
                : calcRem(1, 1, 1, 10),
        fontSize: isLarge ? calcRem(16) : calcRem(13),
        margin: theme.kind === 'thenightly' ? calcRem(0, 24) : 0,
        borderRadius: theme.kind === 'thenightly' ? calcRem(8) : '1rem',
        overflow: 'hidden',
        width: '100%',
        background:
            theme.kind === 'thenightly'
                ? isLarge
                    ? tokens.thenightly.colors.palette.neutral[0]
                    : tokens.thenightly.colors.palette.neutral[10]
                : colors.thewest.greyErmine,
        border:
            theme.kind === 'thenightly' && isLarge
                ? `1px solid ${tokens.thenightly.colors.palette.neutral[100]}`
                : searchHasFocus || searchHasValue
                ? `1px solid ${colors.thewest.greyDolphin}`
                : '1px solid transparent',
        color: searchHasFocus ? colors.black : colors.thewest.greyElephant,

        [breakpoint('lg')]: {
            margin:
                theme.kind === 'thenightly' && !isLarge ? calcRem(0, 24) : 0,
        },
    }),
)

StyledSearchForm.displayName = 'SearchForm'

export const StyledSearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    border: 'none',
    fontFamily:
        theme.kind === 'thenightly'
            ? fonts.thenightly.publication.headline
            : fonts.thewest.sansSerif,
    fontWeight: theme.kind === 'thenightly' ? 400 : 600,
    background: 'transparent',
    fontSize: theme.kind === 'thenightly' ? calcRem(16) : undefined,

    '&:focus': {
        outline: 'none',
    },

    '&::placeholder': {
        color: colors.thewest.greyElephant,
        opacity: 1,
    },

    // We have our own cancel, so we end up with 2 in chrome and edge.
    '&::-webkit-search-cancel-button': {
        display: 'none',
    },
}))

StyledSearchInput.displayName = 'SearchInput'

export const StyledClearButton = styled('button')<StyledClearButtonProps>(
    ({ searchHasValue, theme, isLarge }) => [
        {
            padding: isLarge ? calcRem(5, 10) : calcRem(2, 6),
            background: 'transparent',
            cursor: 'pointer',
            border: 'none',
            lineHeight: 0,
            marginRight: calcRem(5),

            '&:hover path, &:focus path': {
                fill: theme.sectionValues.primaryColor,
            },

            [breakpoint('md')]: {
                marginRight: 0,
            },
        },
        !searchHasValue && {
            visibility: 'hidden',
        },
    ],
)
StyledClearButton.displayName = 'ClearButton'

export const StyledSearchSubmitButton = styled(
    'button',
)<StyledSearchSubmitButtonProps>(({ theme, isLarge }) => ({
    padding: isLarge ? calcRem(5, 10) : calcRem(2, 6),
    border: 'none',
    borderRadius: calcRem(2),
    cursor: 'pointer',
    margin: calcRem(1),
    outline: 'none',
    backgroundColor: 'transparent',

    [`${StyledSearchIcon}`]: {
        fill: colors.thewest.greyElephant,
    },

    '&:disabled': {
        cursor: 'not-allowed',

        [`${StyledSearchIcon}`]: {
            fill: colors.thewest.greyElephant,
        },
    },

    [`&:hover:disabled ${StyledSearchIcon}`]: {
        fill: colors.thewest.greyElephant,
    },

    [`&:hover ${StyledSearchIcon}, &:focus ${StyledSearchIcon}`]: {
        fill: theme.sectionValues.primaryColor,
    },
}))
StyledSearchSubmitButton.displayName = 'SubmitButton'

export const StyledSearchComponents = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
}))
StyledSearchComponents.displayName = 'SearchTerm'
