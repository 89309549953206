import React from 'react'
import { getChipIcon } from '../helpers/getChipIcon'
import { getChipType } from '../helpers/getChipType'
import { StyledChip } from './Chip.styled'
import { useTheme } from '@emotion/react'

export const ChipVariantOptions = ['label', 'pinned', 'happening'] as const
export type ChipVariant = typeof ChipVariantOptions[number]

export interface ChipProps {
    text?: string
    variant: ChipVariant
    iconSide?: 'left' | 'right'
    entryType?: string
}

export const Chip: React.FC<ChipProps> = ({
    text,
    variant,
    iconSide = 'left',
    entryType,
}) => {
    const theme = useTheme()

    return (
        <StyledChip
            buttonType={getChipType(theme, text, entryType)}
            variant={variant}
            iconSide={iconSide}
        >
            {getChipIcon(variant, theme, text, entryType)}
            {text && <span>{text}</span>}
        </StyledChip>
    )
}
