import {
    BreakpointState,
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../__styling'

export type TrendingArticlesTheme<T extends ThemeConfig> = {
    border: string

    container: {
        breakpointState: BreakpointState<
            T,
            {
                gap: string
                maxWidth?: string
                margin: string
            }
        >
    }
    gridContainer: {
        breakpointState: BreakpointState<
            T,
            {
                gridTemplateColumns: string
                columnGap: string
            }
        >
    }
    header: {
        breakpointState: BreakpointState<
            T,
            {
                gap: string
            }
        >
    }
    headerText: {
        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                letterSpacing: string
                lineHeight?: string
            }
        >
    }
    svg: {
        breakpointState: BreakpointState<
            T,
            {
                height: string
                width: string
            }
        >
    }
}

export const theNightlyTrendingArticlesTheme = (scheme: TheNightlyTheme) => {
    const theme: TrendingArticlesTheme<TheNightlyThemeConfig> = {
        border: `0.5px solid ${scheme.colors.borders.secondary}`,

        container: {
            breakpointState: {
                default: {
                    margin: calcRem(0, 24),
                    gap: calcRem(8),
                },
                sm: {
                    margin: calcRem(0, 32),
                    gap: calcRem(16),
                },
                lg: {
                    margin: `${calcRem(0)} auto`,
                    maxWidth: calcRem(scheme.siteMetrics.mainContentWidth),
                    gap: calcRem(32),
                },
            },
        },
        gridContainer: {
            breakpointState: {
                default: {
                    gridTemplateColumns: '1fr',
                    columnGap: calcRem(0),
                },
                sm: {
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: calcRem(scheme.margins.md),
                },
            },
        },
        header: {
            breakpointState: {
                default: {
                    gap: calcRem(scheme.margins.sm),
                },
                sm: {
                    gap: calcRem(scheme.margins.gridGap),
                },
            },
        },
        headerText: {
            breakpointState: {
                default: {
                    fontSize: calcRem(25),
                    lineHeight: calcRem(32),
                    letterSpacing: '-0.8px',
                },
                sm: {
                    fontSize: calcRem(45),
                    lineHeight: calcRem(48),
                    letterSpacing: '-1.8px',
                },
                lg: {
                    fontSize: calcRem(51),
                    lineHeight: calcRem(56),
                    letterSpacing: '-2.04px',
                },
            },
        },
        svg: {
            breakpointState: {
                default: {
                    height: calcRem(32),
                    width: calcRem(32),
                },
                xs: {
                    height: calcRem(48),
                    width: calcRem(48),
                },
                lg: {
                    height: calcRem(56),
                    width: calcRem(56),
                },
            },
        },
    }
    return theme
}
