import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    Product,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { ListCard } from '../../cards/ListCard/ListCard'
import { HeroCard } from '../../collections/Oscar/components/HeroCard'
import {
    StyledGridList,
    StyledHeader,
    StyledListItem,
    StyledOscar,
    StyledRelatedLink,
    StyledRelatedLinks,
    StyledRelatedLinksHeading,
} from '../../collections/Oscar/Oscar.styled'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { OverrideThemeSection } from '../../__helpers/override-theme-section'
import { useProduct } from '../../__product/useProduct'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { Section } from '../../__styling/settings/sections'
import { invertMaybeLoadedItems } from '../helpers/loading'

export interface OscarProps extends ResponsiveContainer {
    className?: string
    sectionHeader?: SectionHeaderProps
    section?: Section
    verticalSpacing?: keyof ThemeMargins
    heroCardType: 'landscape-stacked' | 'portrait'
    disableImageLazyLoad?: boolean
    truncateListCards?: boolean
    hasSeparators?: boolean
    onEvent: (event: CollectionEvent) => void
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    relatedLinks?: {
        title: string
        links: Array<{ text: string; link: string }>
    }
}

export const Oscar: React.FC<OscarProps> = (props) => {
    const {
        className,
        sectionHeader,
        onEvent,
        verticalSpacing,
        section,
        truncateListCards,
        disableImageLazyLoad,
        hasSeparators,
        expectedCards,
        relatedLinks,
    } = props

    const items = invertMaybeLoadedItems(props.items, expectedCards)
    const product = useProduct()

    const removeMargins = product !== 'perthnow'

    const heroCardPadding = product === Product.PerthNow ? true : false

    const renderOscar = () => (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Oscar',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledOscar
                    ref={ref}
                    className={className}
                    verticalSpacing={verticalSpacing}
                >
                    {sectionHeader && items && (
                        <StyledHeader>
                            <SectionHeader
                                heading={sectionHeader.heading}
                                hasBottomMargin={sectionHeader.hasBottomMargin}
                                headingUrl={sectionHeader.headingUrl}
                                navLinks={sectionHeader.navLinks}
                                onClick={sectionHeader.onClick}
                            />
                        </StyledHeader>
                    )}
                    {items &&
                        items
                            .slice(0, 1)
                            .map((item, index) => (
                                <CardSwitcher
                                    onEvent={onEvent}
                                    key={index}
                                    item={item}
                                    cardContext="oscar-hero"
                                    cardNumber={1}
                                    publicationCard={(publicationItem) => (
                                        <HeroCard
                                            key={index}
                                            item={publicationItem}
                                            heroCardType={
                                                props.heroCardType ||
                                                'landscape-stacked'
                                            }
                                            mediaMode={'default'}
                                            hasPadding={heroCardPadding}
                                            onEvent={props.onEvent}
                                            disableImageLazyLoad={
                                                disableImageLazyLoad
                                            }
                                            cardNumber={1}
                                            containerWidthRatios={
                                                props.containerWidthRatios
                                            }
                                        />
                                    )}
                                />
                            ))}
                    {items.length > 1 && (
                        <StyledGridList grow={!relatedLinks}>
                            {items.slice(1, items.length).map((item, index) => {
                                const cardNumber = index + 2
                                return (
                                    <CardSwitcher
                                        key={index}
                                        onEvent={onEvent}
                                        item={item}
                                        cardContext="oscar-list"
                                        cardNumber={cardNumber}
                                        publicationCard={(publicationItem) => (
                                            <ListCard
                                                hideTopic
                                                item={publicationItem}
                                                onEvent={onEvent}
                                                simpleList={true}
                                                noStretch={true}
                                                removeHorizontalMargin={
                                                    removeMargins
                                                }
                                                disableImageLazyLoad={
                                                    disableImageLazyLoad
                                                }
                                                cardNumber={cardNumber}
                                                isTruncated={truncateListCards}
                                                hasSeparators={hasSeparators}
                                            />
                                        )}
                                    />
                                )
                            })}
                        </StyledGridList>
                    )}
                    {relatedLinks && (
                        <StyledRelatedLinks>
                            <StyledRelatedLinksHeading>
                                {relatedLinks.title}
                            </StyledRelatedLinksHeading>
                            {relatedLinks.links &&
                                relatedLinks.links.map((item) => {
                                    return (
                                        <StyledListItem key={item.link}>
                                            <StyledRelatedLink to={item.link}>
                                                {item.text}
                                            </StyledRelatedLink>
                                        </StyledListItem>
                                    )
                                })}
                        </StyledRelatedLinks>
                    )}
                </StyledOscar>
            )}
        </ImpressionAvailable>
    )

    return section ? (
        <OverrideThemeSection section={section}>
            {renderOscar()}
        </OverrideThemeSection>
    ) : (
        renderOscar()
    )
}
Oscar.displayName = 'Oscar'
