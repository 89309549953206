import {
    ConsentLevel,
    mapSophiBreadcrumb,
    useConsentGiven,
} from '@news-mono/web-common'
import React from 'react'
import { AmpAnalytics } from '../analytics'
import { AmpSophiProps, SophiConfig } from './AmpSophiProps'

export const AmpSophi: React.FC<AmpSophiProps> = ({
    primaryTopicId,
    publicationDate,
    sectionName,
    kind,
    publicationId,
}) => {
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Analytics,
    })
    const appId = 'wan:thewest_com_au:amp'

    if (!consentGiven) {
        return null
    }

    const sophiCustomContexts = getSophiCustomContexts({
        primaryTopicId,
        sectionName,
        publicationDate,
        kind,
        publicationId,
    })

    const sophiConfig: SophiConfig = {
        vars: {
            collectorHost: 'collector.sophi.io',
            appId: appId,
            customContexts: sophiCustomContexts.replace(/\n/g, ''),
        },
        linkers: {
            enabled: true,
            proxyOnly: false,
            destinationDomains: ['thewest.com.au'],
        },
        triggers: {
            defaultPageview: { on: 'visible', request: 'pageView' },
            trackFirstPagePing: {
                on: 'timer',
                request: 'pagePing',
                timerSpec: {
                    interval: 5,
                    maxTimerLength: 4.99,
                    immediate: false,
                    startSpec: { on: 'visible', selector: ':root' },
                    stopSpec: { on: 'hidden', selector: ':root' },
                },
            },
            trackPagePings: {
                on: 'timer',
                request: 'pagePing',
                timerSpec: {
                    interval: 20,
                    maxTimerLength: 1800,
                    immediate: false,
                    startSpec: { on: 'visible', selector: ':root' },
                    stopSpec: { on: 'hidden', selector: ':root' },
                },
            },
        },
    }

    return (
        <AmpAnalytics
            type="snowplow_v2"
            id="sophi"
            data-credentials="include"
            dangerouslySetInnerHTML={{
                __html: `
                <script type="application/json">
                    ${JSON.stringify(sophiConfig)}
                </script>`,
            }}
        ></AmpAnalytics>
    )
}

// munge the content into the shape #ext-sophi want - they explicitly do not want
// their anonymous nodes in an array, this is invalid json ¯\_(ツ)_/¯
export function getSophiCustomContexts(props: AmpSophiProps): string {
    return ''.concat(
        JSON.stringify({
            schema: 'iglu:com.globeandmail/environment/jsonschema/1-0-9',
            data: {
                client: 'wan',
                environment: 'prod',
            },
        }),
        ',',
        JSON.stringify({
            schema: 'iglu:com.globeandmail/page/jsonschema/1-0-10',
            data: {
                type: 'article',
                breadcrumb: `${mapSophiBreadcrumb(props.primaryTopicId)}`,
                sectionName: props.sectionName,
                datePublished: props.publicationDate,
            },
        }),
        ',',
        JSON.stringify({
            schema: 'iglu:com.globeandmail/content/jsonschema/1-0-12',
            data: {
                type: props.kind,
                contentId: props.publicationId,
            },
        }),
    )
}
