/**
 * Chunks array items into sub arrays
 * @param array
 * @param chunkSize
 * @param returnRemaining returns remaining items as last chunk
 * @returns
 */
export function chunkArray<T>(
    array: T[],
    chunkSize: number,
    returnRemaining?: boolean,
): T[][] {
    const chunks: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize)

        if (chunk.length === chunkSize || returnRemaining) {
            chunks.push(chunk)
        }
    }
    return chunks
}
