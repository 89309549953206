import { FlexDirectionProperty, PositionProperty } from 'csstype'
import {
    BreakpointState,
    calcRem,
    colors,
    PerthNowTheme,
    PerthNowThemeConfig,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../../../__styling'
import { zIndex } from '../../../../__styling/settings/z-index'
import { tokens } from '@news-mono/design-tokens'

export type StickyAccountBannerTheme<T extends ThemeConfig> = {
    background?: string
    paddingHorizontal: string
    paddingVertical: string
    display: string
    alignItems: string
    gap: string
    position?: PositionProperty
    zIndex: number
    width?: string

    rowContainer: {
        margin: string
        position: PositionProperty
        maxWidth: number
        display: string
        alignItems: string
        gap: string
        background?: string
        borderTop?: string
        paddingHorizontal?: string
        paddingVertical?: string
    }
    innerRowContainer: {
        position: PositionProperty
        display: string
        alignItems: string
        flexDirection: BreakpointState<T, FlexDirectionProperty>
        gap: BreakpointState<T, string>
    }
    closeButton: {
        flexShrink: number
        display: string
        background: string
        border: string
        margin: string
        padding: string
        textUnderlinePosition: string
        iconFocusFill?: string
    }
    icon: {
        width: string
        height: string
        fill?: string

        hover: {
            fill?: string
        }
    }
    textContainer: {
        fontSize: string
        lineHeight: number
        fontStyle: string
        fontWeight: number
        color: string
        fontFamily?: string
    }
    messagesContainer: {
        display: string
        flexDirection: BreakpointState<T, FlexDirectionProperty>
        gap: BreakpointState<T, string>
    }
    textLink: {
        textDecoration: string
        color: string
        fontSize: string
        lineHeight: number
        fontStyle: string
        fontWeight: number
        fontFamily?: string
        padding?: string

        hover: {
            color: string
        }
    }
}

export const theNightlyStickyAccountBannerTheme = (scheme: TheNightlyTheme) => {
    const theme: StickyAccountBannerTheme<TheNightlyThemeConfig> = {
        background: scheme.colors.background.primary,
        paddingHorizontal: calcRem(scheme.margins.outerMargin),
        paddingVertical: calcRem(scheme.margins.md),
        display: 'block',
        alignItems: 'center',
        gap: calcRem(scheme.margins.md),
        position: 'sticky',
        zIndex: zIndex.thenightly.stickyCreateAccountBanner,

        rowContainer: {
            margin: '0 auto',
            position: 'relative',
            maxWidth: scheme.siteMetrics.mainContentWidth,
            display: 'flex',
            alignItems: 'center',
            gap: calcRem(scheme.margins.md),
        },
        innerRowContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: {
                default: 'column',
                sm: 'row',
            },
            gap: {
                default: calcRem(0),
                sm: calcRem(scheme.margins.md),
            },
        },
        closeButton: {
            flexShrink: 0,
            display: 'flex',
            background: 'none',
            border: 'none',
            margin: '0',
            padding: '0',
            textUnderlinePosition: 'under',
            iconFocusFill: scheme.colors.borders.secondary,
        },
        messagesContainer: {
            display: 'flex',
            flexDirection: {
                default: 'column',
                sm: 'row',
            },
            gap: {
                default: calcRem(0),
                sm: calcRem(scheme.margins.mobSm),
            },
        },
        icon: {
            height: calcRem(24),
            width: calcRem(24),
            fill: scheme.colors.palette.white,

            hover: {
                fill: scheme.colors.borders.secondary,
            },
        },
        textContainer: {
            fontSize: calcRem(16),
            lineHeight: 1.5,
            fontStyle: 'normal',
            fontWeight: 600,
            color: scheme.colors.palette.white,
        },
        textLink: {
            textDecoration: 'underline',
            color: scheme.colors.section,
            fontSize: calcRem(scheme.baseFontSize),
            lineHeight: 1.5,
            fontStyle: 'normal',
            fontWeight: 400,
            padding: '0',

            hover: {
                color: tokens.thenightly.colors.palette.starlight.light,
            },
        },
    }
    return theme
}

export const perthNowStickyAccountBannerTheme = (
    scheme: PerthNowTheme,
): StickyAccountBannerTheme<PerthNowThemeConfig> => {
    const theme: StickyAccountBannerTheme<TheNightlyThemeConfig> = {
        paddingHorizontal: '0',
        paddingVertical: '0',
        display: 'block',
        alignItems: 'center',
        gap: calcRem(scheme.margins.md),
        position: 'sticky',
        zIndex: zIndex.perthnow.stickyCreateAccountBanner,
        width: '100%',

        rowContainer: {
            margin: '0 auto',
            position: 'relative',
            maxWidth: scheme.siteMetrics.mainContentWidth,
            display: 'flex',
            alignItems: 'center',
            gap: calcRem(12),
            background: scheme.colors.palette.white,
            borderTop: `1px solid ${scheme.colors.background.tertiary}`,
            paddingHorizontal: calcRem(16),
            paddingVertical: calcRem(12),
        },
        innerRowContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: {
                sm: 'row',
            },
            gap: {
                default: calcRem(2),
            },
        },
        closeButton: {
            flexShrink: 0,
            display: 'flex',
            background: 'none',
            border: 'none',
            margin: calcRem(0),
            padding: calcRem(0),
            textUnderlinePosition: 'under',
            iconFocusFill: tokens.perthnow.colors.palette.greySlate,
        },
        icon: {
            height: calcRem(24),
            width: calcRem(24),
            fill: scheme.colors.text.weak,

            hover: {
                fill: tokens.perthnow.colors.palette.greySlate,
            },
        },
        messagesContainer: {
            display: 'flex',
            flexDirection: {
                default: 'column',
                sm: 'row',
            },
            gap: {
                default: calcRem(0),
                sm: calcRem(scheme.margins.mobSm),
            },
        },
        textContainer: {
            fontSize: calcRem(scheme.baseFontSize),
            lineHeight: 1.5,
            fontStyle: 'normal',
            fontWeight: 500,
            color: scheme.colors.text.primary,
            fontFamily: scheme.fonts.sansSerif,
        },
        textLink: {
            textDecoration: 'none',
            color: colors.perthnow.pinkThulite,
            fontSize: calcRem(scheme.baseFontSize),
            lineHeight: 1.5,
            fontStyle: 'normal',
            fontWeight: 500,
            fontFamily: scheme.fonts.sansSerif,
            padding: '0 0 0 2px',

            hover: {
                color: tokens.perthnow.colors.palette.pinkGarnet,
            },
        },
    }
    return theme
}
