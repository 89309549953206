import { ErrorState, ScriptProps, useScript } from 'use-script'
import { ConsentLevel } from '../data/consent/consent.redux'
import { useConsentGiven } from './useConsentGiven'

export const enum ScriptLoadResult {
    Loading = 1,
    Success = 2,
    Failed = 3,
    /** Will be returned when consent is not given and no fallback script is specified */
    NotConsented = 4,
}

function getConsentNotMetScript(options: UseScriptWithConsentOptions) {
    if (!options.consentNotMet) {
        return
    }
    const result = options.consentNotMet()

    if (result === 'safe-to-load') {
        return options.consentMet
    }
    return result
}

export interface UseScriptWithConsentOptions {
    consentRequiredLevel: ConsentLevel | 'unknown'
    consentMet: ScriptProps
    consentNotMet?: () => ScriptProps | 'safe-to-load' | null
}

export function useScriptWithConsent(
    options: UseScriptWithConsentOptions,
): [ScriptLoadResult, ErrorState] {
    const consentGiven = useConsentGiven(options)
    const script = consentGiven
        ? options.consentMet
        : getConsentNotMetScript(options)

    if (!script) {
        return [ScriptLoadResult.NotConsented, null]
    }

    // It is ok to disable this warning. `consentLevel` and consentRequiredLevel will both
    // not change witin a page session. A change in consent will trigger a page reload, so
    // in practise this conditional will never change

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, error } = useScript(script)

    if (loading) {
        return [ScriptLoadResult.Loading, null]
    }
    if (error) {
        return [ScriptLoadResult.Failed, error]
    }

    return [ScriptLoadResult.Success, null]
}
