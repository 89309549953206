import {
    AllEvents,
    ConfigurationContext,
    DataLayerEventName,
    EntitledToView,
    NavEvent,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import {
    StyledContainer,
    StyledEntitledText,
    StyledOpportunityText,
    StyledButton,
    StyledWestMark,
    StyledHeaderText,
    StyledSubText,
    StyledSlimWestMark,
} from './TWStaticVCSubscribeCard.styled'
import { TheWestMark } from '../../logos/TheWestMark/TheWestMark'
import { colors } from '../../__styling/settings/colors'
import { handlePageEventsInDatalayer, toDataLayerEvent } from '../../tracking'

interface CardOptions {
    options?: {
        navName?: string
        navLocation?: string
        navLink?: string
    }
}

export const EntitledView = () => {
    return (
        <StyledContainer>
            <StyledWestMark>
                <TheWestMark fill={colors.white} height={35} width={43} />
            </StyledWestMark>
            <StyledEntitledText>
                Discover More
                <b>Homegrown</b>
                Series
            </StyledEntitledText>
            <StyledButton onClick={() => {}} to={'#other-exclusive-series'}>
                Watch Now
            </StyledButton>
        </StyledContainer>
    )
}
export const OpportunityView: React.FC<CardOptions> = ({ options }) => {
    const config = useContext(ConfigurationContext)
    const { navLink, navName, navLocation } = options || {}

    const publicUrl = config.publicUrl
    const redirectUrl = publicUrl + navLink

    function buttonClickHandler(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault()
        window.location.href = redirectUrl

        if (navName && navLocation && navLink) {
            const event: NavEvent = {
                type: DataLayerEventName.navClicked,
                originator: navName,
                payload: {
                    navName: navName,
                    navLocation: navLocation,
                    navPos: 1,
                    navText: 'SUBSCRIBE NOW',
                    navLink: navLink,
                },
            }

            dataLayerEventHandler(event, {})
        }
    }

    return (
        <StyledContainer>
            <StyledSlimWestMark>
                <TheWestMark fill={colors.white} height={35} width={43} />
            </StyledSlimWestMark>
            <StyledHeaderText>40% OFF</StyledHeaderText>
            <StyledSubText>a digital subscription</StyledSubText>
            <StyledOpportunityText>
                Discover More
                <br />
                <b>Homegrown</b> Series
            </StyledOpportunityText>
            <StyledButton onClick={buttonClickHandler} to={redirectUrl}>
                Subscribe to Watch
            </StyledButton>
        </StyledContainer>
    )
}

export const TWStaticVCSubscribeCard: React.FC<CardOptions> = ({ options }) => {
    return (
        <EntitledView />
    )
}

// We need to make sure to not send the route-inf-resolver & page-render events
// down through this funnel.
//
// Copied from page-event-handler.ts
const dataLayerEventHandler = handlePageEventsInDatalayer<AllEvents>(
    (event: AllEvents) => {
        if (
            event.type === 'route-info-resolver.failed' ||
            event.type === 'page-render.failed'
        ) {
            return
        }
        return toDataLayerEvent(event, {})
    },
)
