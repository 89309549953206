import React from 'react'

export interface IconLightningProps {
    className?: string
    fill?: string
}

export const IconLightning: React.FC<IconLightningProps> = ({
    className,
    fill = 'currentcolor',
}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 28"
    >
        <g fill={fill} fillRule="evenodd">
            <path d="M3.86 0h8.003L10.46 6.705 0 14.118z" />
            <path d="M2.218 6h14.817l-3.673 8.14H0z" />
            <path d="M7 10.951L17.03 6 8.452 27.558z" />
        </g>
    </svg>
)
IconLightning.displayName = 'IconLightning'
