import {
    ClassificationV4DTO,
    EditionListV4ResponseDTO,
    EditionV4DTO,
    ListArticleV4DTO,
    TopicCount,
    TopicV4DTO,
    VideoListV4ResponseDTO,
} from '@west-australian-newspapers/publication-types'
import { DataDefinition } from 'json-react-layouts-data-loader'
import { useSelector } from 'react-redux'
import {
    CardItem,
    MarketingRedirectTileCard,
    PublicationCardItem,
} from '../client-data-types/card-types'
import { getCurationFromRegion } from '../data/location-detection/helpers'
import { httpGet } from '../helpers'
import { scaleImage } from '../helpers/scaleImage'
import {
    ActivePromotionData,
    AggregatedData,
    ComponentServices,
    ContextData,
    CurationData,
    GeolocationCurationData,
    ListingData,
    PromotionalData,
    VideoListingData,
    EditionData,
    NewsTickerData,
    NotificationCentreData,
} from '../routing/page-definition'
import { AppState } from '../store'
import { mapListPublication } from '../__api-mapping/v4/list-publication'
import { mapListVideo } from '../__api-mapping/v4/list-video'
import { getTopicVideos } from '../__content-api/v4'
import { getActivePromotion } from '../__content-api/v4/get-active-promotion'
import { getCuration } from '../__content-api/v4/get-curation'
import { getCurationMetadata } from '../__content-api/v4/get-curation-metadata'
import { getTopicListing } from '../__content-api/v4/get-topic-listing'
import { LocationState, SelectedRegion } from './location-detection/reducer'
import { DataLoaderGlobalParams } from './resources'
import { getEditionsList } from '../__content-api/v4/get-edition'

export type ContentDataTypes =
    | AggregatedData
    | CurationData
    | ListingData
    | GeolocationCurationData
    | PromotionalData
    | ActivePromotionData
    | VideoListingData
    | ContextData
    | EditionData
    | NewsTickerData
    | NotificationCentreData

export type ContentData = ContentDataTypes | ContentDataTypes[]

export type ListingCollectionDataLoaded = {
    loadMorePossible: boolean
    kind: 'listing'
    publications: CardItem[]
    classification?: ClassificationV4DTO
    total?: number
    videos?: CardItem[]
    topicCounts?: TopicCount[]
}

export type GeolocationCollectionDataLoaded = {
    loadMorePossible: false
    kind: 'geolocation'
    location: LocationState
    publications: CardItem[]
    videos?: CardItem[]
}

export type CurationCollectionDataLoaded = {
    loadMorePossible: false
    kind: 'curation'
    metadata: { [key: string]: any }
    publications: CardItem[]
    videos?: CardItem[]
}

export type AggregatedCollectionDataLoaded = {
    loadMorePossible: false
    /** When multiple other collection sources are merged */
    kind: 'aggregated'
    publications: CardItem[]
    sourceCollections: CollectionDataLoaded[]
    videos?: CardItem[]
}

export type PromotionalCollectionDataLoaded = {
    loadMorePossible: false
    kind: 'promotional'
    publications: CardItem[]
    videos?: CardItem[]
}

export type ActivePromotionalCollectionDataLoaded = {
    loadMorePossible: false
    kind: 'active-promotion'
    publications: CardItem[]
    videos?: CardItem[]
}

export type VideoListingCollectionDataLoaded = {
    loadMorePossible: false
    kind: 'video-listing'
    publications: CardItem[]
    videos?: CardItem[]
    topic?: TopicV4DTO
}

export type ContextCollectionDataLoaded = {
    // Here so as to not break everything else that expects these.
    loadMorePossible: false
    kind: 'context'
    publications: CardItem[] // Nothing here, as it should be fetched from CollectionDataContext.
    videos?: CardItem[]
}

export type EditionCollectionDataLoaded = {
    loadMorePossible: boolean
    kind: 'edition'
    editions: EditionV4DTO[]
    publications: CardItem[]
    videos?: CardItem[]
    total?: number
}

export type CollectionDataLoaded =
    | ListingCollectionDataLoaded
    | GeolocationCollectionDataLoaded
    | CurationCollectionDataLoaded
    | AggregatedCollectionDataLoaded
    | PromotionalCollectionDataLoaded
    | ActivePromotionalCollectionDataLoaded
    | VideoListingCollectionDataLoaded
    | ContextCollectionDataLoaded
    | EditionCollectionDataLoaded

type RuntimeData = {
    region?: SelectedRegion
}

export type ContentDataDefinition = DataDefinition<
    ContentDataTypes,
    CollectionDataLoaded,
    ComponentServices,
    RuntimeData
>

export const loadCollectionData = async (
    services: DataLoaderGlobalParams,
    data: Exclude<ContentDataTypes, AggregatedData | ContextData>,
): Promise<CollectionDataLoaded> => {
    if (data.type === 'listing') {
        // If fetching both gallery and article than we don't set a "kind" value so we get all article types back,
        // then after fetching, we filter the results to get the correct values
        const publicationKind =
            data.publicationKind === 'galleryAndArticle'
                ? undefined
                : data.publicationKind

        const topicListing = await getTopicListing(services, {
            /** We need to include both parentTopics to get all the children,
             * and topics to get the top level
             */
            parentTopics: data.topics.filter((t) => t.indexOf('/') === -1),
            topics: data.topics,
            excludeTopics: data.excludeTopics,
            paging: data.paging,
            isSponsored: data.isSponsored,
            publicationKind,
            source: data.source,
            authors: data.authors,
            profiles: !data.hideProfiles,
            classification: data.classification,
            excludeSubTopics: data.excludeSubTopics,
            randomisation: data.random
                ? {
                      random_offset: data.random_offset,
                      random_size: data.random_size,
                      seed: data.seed,
                  }
                : undefined,
        })

        let mappedListing = topicListing.documents
            .filter((listPublication) => {
                if (data.publicationKind === 'galleryAndArticle') {
                    return (
                        ['gallery', 'article'].indexOf(listPublication.kind) >=
                        0
                    )
                }
                return true
            })
            .map((listPublication) => mapListPublication(listPublication))

        // Orders the results requested via reverse chron paging, in chronological order
        if (data.sortResultsInChornologicalOrder === true) {
            mappedListing = mappedListing.sort((a, b) => {
                if (a.publicationDate && b.publicationDate) {
                    return (
                        new Date(a.publicationDate).getTime() -
                        new Date(b.publicationDate).getTime()
                    )
                }
                return 0
            })
        }
        return {
            loadMorePossible: topicListing.morePublicationsAvailable,
            kind: 'listing',
            publications: mappedListing,
            total: topicListing.total,
            videos: [],
        }
    }

    if (data.type === 'curation') {
        const curation = await getCuration(services, data.name, {
            profiles: !data.hideProfiles,
            offset: data.offset,
            pageSize: data.pageSize,
            excludedCurations: data.excludeCurations,
        })

        const mappedCollectionItems: CardItem[] = curation.articles.map(
            (listPublication) => mapListPublication(listPublication),
        )

        if (data.injectMetadataStory && curation.metadata) {
            const metadataStory =
                curation.metadata[data.injectMetadataStory.metadataKey]

            if (metadataStory && isArticleMetadata(metadataStory)) {
                if (
                    data.injectMetadataStory.kind ===
                        'marketing-redirect-tile' &&
                    metadataStory.kind === 'redirect' &&
                    metadataStory.mainImages.length &&
                    metadataStory.redirectUrl
                ) {
                    const imageData = metadataStory.mainImages[0]
                    const scaledSrc = scaleImage(imageData.src, 500, false)

                    const promoTile: MarketingRedirectTileCard = {
                        cardType: 'marketing-redirect-tile',
                        image: scaledSrc,
                        url: metadataStory.redirectUrl,
                        altText: imageData.captionText || '',
                        id: metadataStory.id,
                        headline: metadataStory.heading,
                        primaryTopic: metadataStory.topics.primary,
                        source: metadataStory.source,
                        classification: metadataStory.classification,
                        promotionType: metadataStory.promotionType || undefined,
                    }

                    // Inject the promo tile in
                    mappedCollectionItems.splice(
                        data.injectMetadataStory.position,
                        0,
                        promoTile,
                    )
                    // Then trim the collection
                    mappedCollectionItems.splice(data.pageSize)
                }
            }
        }

        return {
            loadMorePossible: false,
            kind: 'curation',
            metadata: curation.metadata,
            publications: mappedCollectionItems,
        }
    }

    if (data.type === 'geolocation') {
        const location = services.store.getState().geoLocation
        const name = getCurationFromRegion(location)
        const curation = await getCuration(services, name, {
            offset: data.offset,
            pageSize: data.pageSize,
            profiles: true,
        })

        const mappedCollectionItems = curation.articles.map((listPublication) =>
            mapListPublication(listPublication),
        )

        return {
            loadMorePossible: false,
            kind: 'geolocation',
            location,
            publications: mappedCollectionItems,
        }
    }

    if (data.type === 'promotional') {
        let publications: PublicationCardItem[] = []
        try {
            const publicationDTO = await getCurationMetadata(services, {
                curationId: data.curationId || 'promotional-cards',
                metadataKey: data.metadataKey,
            })

            publications = [mapListPublication(publicationDTO)]
        } catch (err) {
            // Catching 404 errors. Don't need to display something went wrong in this case
        }

        return {
            loadMorePossible: false,
            kind: 'promotional',
            publications,
        }
    }

    if (data.type === 'active-promotion') {
        let activePromotion: PublicationCardItem[] = []
        try {
            const activePromotionDTO = await getActivePromotion(services, {
                promotionSlotId: data.promotionSlotId,
                metadataKey: data.metadataKey,
            })
            activePromotion = [mapListPublication(activePromotionDTO)]
        } catch (err) {
            // Catching 404 errors. Don't need to display something went wrong in this case
        }

        return {
            loadMorePossible: false,
            kind: 'active-promotion',
            publications: activePromotion,
        }
    }

    if (data.type === 'video-listing') {
        const topicVideos = await getTopicVideos(services, {
            topics: data.topics,
            paging: data.paging,
        })

        const mappedListing = topicVideos.documents.map((videoMeta) =>
            mapListVideo(videoMeta, {
                publicUrl: services.config.publicUrl,
            }),
        )

        return {
            loadMorePossible: false,
            kind: 'video-listing',
            publications: mappedListing,
        }
    }

    if (data.type === 'edition') {
        const editions = await getEditionsList(services, {
            paging: {
                page: data.page,
                pageSize: data.pageSize,
            },
        })
        if (
            editions.documents.length < 1 &&
            data.throw404OnNoResults &&
            editions.page > 1
        )
            throw new Error('404')

        return {
            loadMorePossible: editions.moreEditionsAvailable,
            kind: 'edition',
            editions: editions.documents,
            publications: [], // An offering to the TypeScript gods...
            total: editions.total,
        }
    }

    // Need the as any here, because contentAreaComponent.data has been narrowed to never
    // But the switch statement cannot compile if we don't have this.
    // ugh
    throw new Error(`Unknown content area type of ${(data as any).type}`)
}

export const loadAggregatedData = async (
    services: DataLoaderGlobalParams,
    { sources, offset, maxPageSize, keepDuplicates = false }: AggregatedData,
): Promise<CollectionDataLoaded> => {
    const rawData = await Promise.all(
        sources.map((datum) => loadCollectionData(services, datum)),
    )

    const rawPublications = rawData.flatMap((v) => v.publications)

    let publications: CardItem[]
    // Deduplicate results
    if (keepDuplicates) {
        publications = rawPublications
    } else {
        publications = []

        const seenIds = new Set()
        for (const publication of rawPublications) {
            // Skip if seen before.
            if (seenIds.has(publication.id)) continue

            publications.push(publication)
            seenIds.add(publication.id)
        }
    }

    // Trim results as per options.
    publications = publications.splice(
        offset ?? 0, // Default to start of array.
        maxPageSize ?? publications.length, // Default to all values in the array.
    )

    // multiple collection loading wont have any morePublicationsAvailable
    return {
        loadMorePossible: false,
        kind: 'aggregated',
        publications: publications,
        sourceCollections: rawData,
    }
}

export const loadContentData = (
    data: ContentDataTypes & RuntimeData,
    services: DataLoaderGlobalParams,
): Promise<CollectionDataLoaded> => {
    if (data.type === 'context') {
        // Fallback to nothing.
        // 'Context' should be intercepted in the component routing file.
        return Promise.resolve({
            kind: 'context',
            loadMorePossible: false,
            publications: [],
        })
    } else if (data.type === 'aggregated') {
        return loadAggregatedData(services, data)
    } else {
        return loadCollectionData(services, data)
    }
}

export type CurationWithMetadataDefinition = DataDefinition<
    CurationData,
    Curation_WithMetadata,
    ComponentServices
>

export interface Curation_WithMetadata {
    metadata: { [key: string]: any }
    articles: PublicationCardItem[]
}

export const CurationAndCurationMetadataLoader: CurationWithMetadataDefinition =
    {
        loadData: (props, services) =>
            loadCurationAndCurationMetadata(services, props),
    }

async function loadCurationAndCurationMetadata(
    services: DataLoaderGlobalParams,
    data: CurationData,
): Promise<Curation_WithMetadata> {
    const curation = await getCuration(services, data.name, {
        offset: data.offset,
        pageSize: data.pageSize,
        excludedCurations: data.excludeCurations,
        excludedSources: data.excludeSources,
        applyTrendingStoriesExclusion: data.applyTrendingStoriesExclusion,
    })

    const mappedCollectionItems: PublicationCardItem[] = curation.articles.map(
        (listPublication) => mapListPublication(listPublication),
    )

    return {
        metadata: curation.metadata,
        articles: mappedCollectionItems,
    }
}

/**
 * Applies to cards, collections anything that requires the ContentDataDefinition data loading
 */
export const ContentDataDefinitionLoader: ContentDataDefinition = {
    useRuntimeParams: (props, services) => {
        // Check to see if any data definitions are of type geolocation
        const geoLocationPresent = Array.isArray(props)
            ? props.some((def) => def.type === 'geolocation')
            : props.type === 'geolocation'

        const region = useSelector(
            (state: AppState) => state.geoLocation?.userSelectedRegion,
        )
        if (geoLocationPresent) {
            return { region }
        }
        // invalidate the whole content area if geoLocationPresent = true with a new cacheKey
        return {}
    },
    loadData: loadContentData,
}

function isArticleMetadata(metadata: any): metadata is ListArticleV4DTO {
    return (
        metadata &&
        (metadata.kind === 'article' ||
            metadata.kind === 'redirect' ||
            metadata.kind === 'event')
    )
}

export type VideoDataDefinition = DataDefinition<
    {
        topicTag: string
    },
    VideoListV4ResponseDTO,
    ComponentServices
>

export const VideoDataDefinitionLoader: VideoDataDefinition = {
    loadData: async (props, services) => {
        const data = await httpGet<VideoListV4ResponseDTO>({
            baseUrl: services.config.contentApi,
            log: services.log,
            path: 'v4/video/topic',
            query: { topics: props.topicTag ? props.topicTag : '' },
            validate: (data) => {
                return data as VideoListV4ResponseDTO
            },
            customHeaders: false,
        })

        return data
    },
}

export type EditionDataDefinition = DataDefinition<
    EditionData,
    EditionListV4ResponseDTO,
    ComponentServices
>

export const EditionLoader: EditionDataDefinition = {
    loadData: (props, services) =>
        getEditionsList(services, {
            paging: {
                page: props.page,
                pageSize: props.pageSize,
            },
        }),
}
