import {
    AppState,
    AuthenticationState,
    THEWEST_ALL,
} from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../buttons/Button/Button'
import { useAuthActions } from '../../user-registration/authentication/AuthenticationProvider'
import { ThemeMargins } from '../../__styling/settings/metrics'
import {
    StyledButtonWrapper,
    StyledIconComment,
    StyledIconPremium,
    StyledMessageBanner,
    StyledMessageBannerGroup,
    StyledMessageBannerWrapper,
    StyledMessageCTA,
    StyledSubscriberMessage,
} from './MessageBanner.styled'

export type ArrowStyle = 'solid-arrow'

export interface MessageBannerProps {
    icon?: 'comment' | 'subscriber'
    id?: string
    verticalSpacing?: keyof ThemeMargins | undefined
    onEvent: (event: any) => void
    connector?: ArrowStyle
}

export const MessageBanner: React.FC<MessageBannerProps> = ({
    icon,
    id,
    children,
    verticalSpacing,
    onEvent,
    connector,
}) => {
    const { onLoginClick } = useAuthActions()
    const { entitlements, isLoggedIn } = useSelector<
        AppState,
        AuthenticationState
    >((state) => state.authentication)

    const returningUser = isLoggedIn && !entitlements?.includes(THEWEST_ALL)

    return (
        <StyledMessageBanner
            id={id}
            verticalSpacing={verticalSpacing}
            connector={connector}
        >
            <StyledMessageBannerWrapper>
                {icon === 'comment' && <StyledIconComment />}
                {icon === 'subscriber' && <StyledIconPremium />}
                <StyledMessageCTA>{children}</StyledMessageCTA>
                {!returningUser && (
                    <StyledMessageBannerGroup>
                        <StyledSubscriberMessage>
                            Are you already a subscriber?
                        </StyledSubscriberMessage>
                        <StyledButtonWrapper>
                            <Button
                                onClick={onLoginClick}
                                onEvent={onEvent}
                                buttonWidth="wide"
                            >
                                Login
                            </Button>
                        </StyledButtonWrapper>
                    </StyledMessageBannerGroup>
                )}
            </StyledMessageBannerWrapper>
        </StyledMessageBanner>
    )
}

MessageBanner.displayName = 'MessageBanner'
