import styled from '@emotion/styled'
import { IconArrow } from '../../../icons/IconArrow/IconArrow'
import { theme } from '../../../themes/perthnow/theme'
import { colors } from '../../../__styling/settings/colors'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { themedValue } from '../../../__styling/themed-value'

export const StyledButton = styled('button', theme)(
    {
        width: '100%',
        padding: calcRem(10),
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        color: colors.white,
        border: 'none',
    },
    ({ theme }) => ({
        background: themedValue(theme, {
            thewest: (section) => section.primaryColor,
            perthnow: colors.perthnow.redRuby,
            fallback: colors.sevennews.red,
        }),
        '&:hover, &:focus': {
            background: themedValue(theme, {
                thewest: colors.thewest.greyGorilla,
                perthnow: colors.perthnow.blueKyanite,
                fallback: colors.sevennews.toreaBay,
            }),
        },
    }),
)

export const StyledIconArrow = styled(IconArrow)({
    transform: 'rotate(90deg)',
    width: calcRem(10),
    height: calcRem(10),
    marginLeft: calcRem(5),

    '& path': {
        fill: colors.white,
    },
})
