import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

export interface StyledFundingLabelProps {
    hasBackground?: boolean
}

export const StyledTitle = styled('span')(({ theme }) => ({
    verticalAlign: 'middle',
    display: theme.kind === 'thenightly' ? undefined : 'inline-block',
    color:
        theme.kind === 'thenightly'
            ? tokens.thenightly.colors.palette.uplate.accent
            : 'inherit',
    marginTop: themedValue(theme, {
        perthnow: calcRem(2),
        fallback: undefined,
    }),
}))

export const StyledLinkTitle = styled(WebLink)(({ theme }) => ({
    textDecoration: theme.kind === 'thenightly' ? 'underline' : 'none',
    verticalAlign: 'middle',
    display: theme.kind === 'thenightly' ? undefined : 'inline-block',
    color:
        theme.kind === 'thenightly'
            ? tokens.thenightly.colors.palette.uplate.accent
            : 'inherit',
    marginTop: themedValue(theme, {
        perthnow: calcRem(2),
        fallback: undefined,
    }),
    transition: `color ${easing.easeOut.fast}`,

    '&:hover, &:focus': {
        color:
            theme.kind === 'thenightly'
                ? undefined
                : theme.sectionValues.primaryColor,
        textDecoration: 'underline',
    },

    '&:focus': {
        outline: 'none',
    },
}))

export const StyledFundingLabel = styled('span')<StyledFundingLabelProps>(
    ({ theme, hasBackground }) => ({
        display: theme.kind === 'thenightly' ? undefined : 'inline-block',
        verticalAlign: 'middle',
        lineHeight: theme.kind === 'thenightly' ? undefined : 1,
        margin: 0,
        textOverflow: 'ellipsis',
        fontFamily: theme.fonts.sponsored.label,
        overflow: themedValue(theme, {
            sevennews: 'hidden',
            fallback: undefined,
        }),
        whiteSpace: themedValue(theme, {
            sevennews: undefined,
            fallback: 'nowrap',
        }),
        color: themedValue(theme, {
            perthnow: hasBackground ? colors.white : theme.colors.text.primary,
            fallback: theme.colors.text.secondary,
        }),
        fontSize: themedValue(theme, {
            perthnow: calcRem(12),
            thewest: calcRem(12),
            sevennews: calcRem(11),
            thenightly: calcRem(12),
            fallback: undefined,
        }),
        fontWeight: themedValue(theme, {
            perthnow: 700,
            thewest: 400,
            sevennews: 500,
            fallback: undefined,
        }),
        textTransform: themedValue(theme, {
            sevennews: undefined,
            fallback: 'uppercase',
        }),
        marginBottom: themedValue(theme, {
            sevennews: calcRem(7),
            fallback: undefined,
        }),
        width: themedValue(theme, {
            sevennews: '100%',
            fallback: undefined,
        }),

        [`& > ${StyledLinkTitle} + ${StyledLinkTitle}, & > ${StyledTitle} + ${StyledTitle}`]:
            themedValue(theme, {
                thewest: {
                    marginTop: calcRem(2),
                },
                sevennews: {
                    whiteSpace: 'normal',
                },
                fallback: undefined,
            }),
    }),
)

export const StyledSource = styled('span')(
    {
        whiteSpace: 'normal',
    },
    ({ theme }) => ({
        display: themedValue(theme, {
            sevennews: 'inline-block',
            fallback: 'block',
        }),
        color: themedValue(theme, {
            sevennews: colors.sevennews.charade,
            thewest: colors.thewest.greyGorilla,
            fallback: undefined,
        }),
        fontWeight: themedValue(theme, {
            thewest: 500,
            perthnow: 400,
            sevennews: 600,
            thenightly: undefined,
        }),
    }),
    ({ theme }) =>
        theme.kind === 'perthnow' && {
            textTransform: 'none',
            marginTop: calcRem(5),
            transform: `translateY(${calcRem(-2)})`,
        },
)
