import {
    DataLayerEventName,
    MatchCenterEvents,
    WebLink,
} from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../__product'
import {
    StyledBTYBCopy,
    StyledBTYBImage,
    StyledBTYBWrapper,
} from './ScoreBoard.styled'

export interface SponsorRowProps {
    sponsorLogo: string
}

export const SponsorRowBTYB = ({ sponsorLogo }: SponsorRowProps) => {
    return (
        <StyledBTYBWrapper>
            <StyledBTYBCopy>Presented by</StyledBTYBCopy>
            <StyledBTYBImage src={sponsorLogo} />
        </StyledBTYBWrapper>
    )
}
