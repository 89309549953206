import React from 'react'
import { StyledFootnote } from '../../publication/Footnote/Footnote.styled'
import { TextLink } from '../../typography/TextLink/TextLink'

export interface Link {
    text: string
    href: string
    className?: string
}

export interface FootnoteProps {
    text: string
    link?: Link
}

export const Footnote: React.FC<FootnoteProps> = ({ text, link }) => {
    return (
        <StyledFootnote>
            {text}
            {link && (
                <TextLink href={link.href} className={link.className}>
                    {link.text}
                </TextLink>
            )}
        </StyledFootnote>
    )
}
Footnote.displayName = 'Footnote'

export interface SevenFootnoteProps {
    text: string[]
    link?: Link
    source: string
}
export const SevenFootnote: React.FC<SevenFootnoteProps> = ({
    text,
    link,
    source,
}) => {
    link && getMastheadSourceFromUrl(link.href)
    return (
        <StyledFootnote>
            {link && (
                <TextLink href={link.href} className={link.className}>
                    {text[0]}
                    {source}
                    {text[1]}
                </TextLink>
            )}
        </StyledFootnote>
    )
}
SevenFootnote.displayName = 'SevenFootnote'

/**
 * Parse masthead source to get Masthead Source name
 * @param url
 * @returns string
 */
function getMastheadSourceFromUrl(url: string): string {
    return url.split('.')[1]
}
