import React from 'react'
export const SevenPlusLogo = () => (
    <svg
        height="17"
        viewBox="0 0 35 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.172 22.3485L21.2008 8.22726L16.5757 0.472656L3.8681 22.3485H13.172Z"
            fill="white"
        />
        <path
            d="M0.425781 0.473327L4.95775 8.22788H11.4607L15.955 0.473327H0.425781Z"
            fill="white"
        />
        <path
            d="M25.6162 3.67199V8.22787H22.4419L20.1337 12.285H25.6162V16.8427H29.7482V12.285H34.2981V8.22787H29.7482V3.67199H25.6162Z"
            fill="white"
        />
    </svg>
)
