import React from 'react'
import { AmpAd } from '../../google-amp/components/AmpAd'
import { InnerAdProps } from '../../google-amp/components/AmpAdProps'
import { amphtml } from '../../google-amp/react_amp'

interface InnerProps {
    layout: 'nodisplay'
}

const AmpStickyAdElement = amphtml<InnerProps>('sticky-ad')

export const AmpStickyAd: React.FC<InnerAdProps> = (props: InnerAdProps) => (
    <AmpStickyAdElement layout={'nodisplay'}>
        <AmpAd {...props} />
    </AmpStickyAdElement>
)
