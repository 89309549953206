import {
    adsDebug,
    GptAdSlotDefinition,
    GptApi,
    isServerEnvironment,
    SlotRenderEndedEvent,
} from '@news-mono/web-common'
import { AdRefreshConfigSection } from 'libs/web-common/src/advertising/AdRefreshConfig'
import { GptAdSlotRegistration } from './gpt-ad-provider'

const gptMgrDebug = adsDebug.extend('gpt-mgr')
const gptMgrDiffDebug = adsDebug.extend('gpt-mgr-diff')

/**
 * This class tracks registered ad slots, and ensures that ads are created and destroyed at the correct time.
 *
 * It is basically the bridge between the declaritive world of React and the imperitive world of the GPT library
 */
export class GptSlotManager {
    private pollDocumentVisibilityDuration = 4000
    private pollingInterval: any = null
    private renderedAdSlots: GptAdSlotRegistration[] = []
    private definedSlots: {
        [renderKey: string]: GptAdSlotDefinition[]
    } = {}
    private queuedCleanupAndDisplaySlotsWhenIdleCall: undefined | any
    private renderKeyChanging: Promise<void> | undefined
    private currentRenderKey: string | undefined

    constructor(
        private gptApi: GptApi,
        private shouldDisplaySlot: (slot: GptAdSlotRegistration) => boolean,
        renderKey: string | null,
        private getAdRefreshEnabled: () => boolean,
        private adRefreshValues: AdRefreshConfigSection,

        private options?: {
            requestIdleCallback?: typeof window.requestIdleCallback
        },
    ) {
        if (!isServerEnvironment()) {
            if (renderKey) {
                this.renderKeyChanged(renderKey)
            }
            gptApi.registerOnSlotRenderEnded(this.onSlotRenderEnded.bind(this))
        }
    }

    private onSlotRenderEnded(event: SlotRenderEndedEvent) {
        const slotId = event.slot.getSlotElementId()

        this.renderedAdSlots
            .filter((slot) => slot.id === slotId)
            .forEach((slot) => slot.onSlotRenderEnded(event))
    }

    defineSlots(renderKey: string, slots: GptAdSlotDefinition[]) {
        gptMgrDebug('Slots defined: %o', {
            renderKey,
            slots: slots.map((slot) => slot.id),
        })
        this.definedSlots[renderKey] = slots
        this.cleanupAndDisplaySlotsWhenIdle('Slots defined')
    }

    registerSlot(slot: GptAdSlotRegistration) {
        if (!this.currentRenderKey) {
            console.error(
                { slotId: slot.id },
                'GPT Slot Manager: Slot rendered without currentRenderKey',
            )
            return
        }
        gptMgrDebug('Slot rendered: %o', { slotId: slot.id })

        const registered = this.renderedAdSlots.filter(
            (currentSlot) => currentSlot.id === slot.id,
        )

        if (registered.length) {
            console.error(
                { registered, slotId: slot.id },
                'GPT Slot Manager: GPT slot with the same ID registered multiple times!',
            )
            return
        }

        this.renderedAdSlots.push(slot)

        this.cleanupAndDisplaySlotsWhenIdle('slot registered')
    }

    unregisterSlot(slotId: string) {
        gptMgrDebug('Slot unmounted: %o', { slotId })
        const slotIndex = this.renderedAdSlots.findIndex(
            (slotRefference) => slotRefference.id === slotId,
        )

        if (slotIndex !== -1) {
            this.renderedAdSlots.splice(slotIndex, 1)

            const slotDef = this.getSlotDefinition(slotId)
            const displayedSlot = this.gptApi.displayedSlots[slotId]

            if (slotDef && displayedSlot) {
                this.gptApi.destroySlots([slotDef])
            }
        }
    }

    /** Called once per page */
    renderKeyChanged(renderKey: string) {
        const currentRenderKey = this.currentRenderKey

        if (!currentRenderKey) {
            this.currentRenderKey = renderKey
            gptMgrDebug('Initial Render key set: %o', {
                currentRenderKey: this.currentRenderKey,
            })
        } else {
            gptMgrDebug('Render key changed: %o', {
                renderKey,
                currentRenderKey,
            })
            this.gptApi.destroySlots(this.definedSlots[currentRenderKey])
            this.currentRenderKey = renderKey
        }

        const isDifferentRender = () => this.currentRenderKey !== renderKey

        if (isDifferentRender()) {
            return
        }

        gptMgrDebug('Page targeting set, displaying slots: %o', {
            renderKey,
            currentRenderKey,
        })

        // Clear as part of this microtask, then cleanupAndDisplaySlots will run
        this.renderKeyChanging = undefined
        this.cleanupAndDisplaySlotsWhenIdle('render key changed', true)
    }

    /**
     * This function is called multiple times during the page lifecycle
     **/
    async cleanupAndDisplaySlotsWhenIdle(
        reason: string,
        updateCorrelator = false,
    ) {
        if (
            this.queuedCleanupAndDisplaySlotsWhenIdleCall ||
            this.renderKeyChanging
        ) {
            return
        }

        gptMgrDiffDebug('Queued cleanupAndDisplaySlots when idle: %o', {
            reason,
            updateCorrelator,
        })

        const ric =
            this.options?.requestIdleCallback || window.requestIdleCallback
        this.queuedCleanupAndDisplaySlotsWhenIdleCall = ric(
            () => this.cleanupAndDisplaySlots(updateCorrelator),
            { timeout: 100 },
        )
    }

    private async cleanupAndDisplaySlots(updateCorrelator: boolean) {
        this.queuedCleanupAndDisplaySlotsWhenIdleCall = undefined
        // Some browsers do not support cancelling requestIdleCallback, so we need
        // to not run this function if we are setting up a page, this will be run once
        // it completes
        if (this.renderKeyChanging) {
            return
        }

        gptMgrDiffDebug('cleanupAndDisplaySlots: %o', { updateCorrelator })
        this.cleanUpSlots()

        await this.pollVisibilityState(() =>
            this.displayViewableSlots(updateCorrelator),
        )
    }

    /**
     * Helper function to poll the documentVisibility state
     */
    private async pollVisibilityState(cb: () => void) {
        return new Promise<void>((resolve) => {
            const intervalFunction = () => {
                if (!isServerEnvironment()) {
                    if (window.document.visibilityState === 'visible') {
                        clearInterval(this.pollingInterval)
                        cb()

                        resolve()
                        return true
                    }
                    // continue polling if the document is hidden.
                }
                // Resolve immediately if we are in a server environment
                else {
                    clearInterval(this.pollingInterval)
                    cb()
                    resolve()
                    return true
                }

                return false
            }

            // Run the function immediately once
            if (intervalFunction()) {
                return
            }

            this.pollingInterval = setInterval(
                intervalFunction,
                this.pollDocumentVisibilityDuration,
            )
        })
    }

    private async displayViewableSlots(updateCorrelator: boolean) {
        const slotsToLoad: GptAdSlotDefinition[] = this.renderedAdSlots
            .filter((slot) => {
                const shouldDisplay = this.shouldDisplaySlot(slot)
                return shouldDisplay
            })
            .map((slot) => this.getSlotDefinition(slot.id))
            .filter((slot): slot is GptAdSlotDefinition => slot !== undefined)

        if (slotsToLoad.length === 0) {
            return
        }

        await this.gptApi.displaySlots(
            slotsToLoad,
            updateCorrelator,
            this.getAdRefreshEnabled(),
            this.adRefreshValues,
        )
    }

    private cleanUpSlots() {
        const displayedSlots = Object.keys(this.gptApi.displayedSlots)
        const missingSlots = displayedSlots.filter(
            (displayedSlot) =>
                !this.definedSlots[this.currentRenderKey!].some(
                    (slot) => displayedSlot === slot.id,
                ),
        )
        const slotsWithMissingConfiguration = displayedSlots.filter(
            (displayedSlot) =>
                !this.definedSlots[this.currentRenderKey!].some(
                    (slot) => displayedSlot === slot.id,
                ),
        )
        const slotsToCleanup = missingSlots.concat(
            slotsWithMissingConfiguration,
        )
        if (slotsToCleanup.length) {
            this.gptApi.destroySlots(
                slotsToCleanup.map(
                    (missingSlot) =>
                        this.gptApi.displayedSlots[missingSlot].definition,
                ),
            )
        }
    }

    getSlotDefinition = (slotId: string) => {
        if (!this.currentRenderKey) {
            return
        }

        if (!this.definedSlots[this.currentRenderKey]) {
            return
        }

        return this.definedSlots[this.currentRenderKey].find(
            (slotDefinition) => slotDefinition.id === slotId,
        )
    }
}
