import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheWestPageIsUnavailable } from './TheWestPageIsUnavailable'

export const TheWestPageIsUnavailableRegistration = createRegisterableComponent(
    'west-page-is-unavailable',
    () => (
        <RouteStatus statusCode={451}>
            <TheWestPageIsUnavailable />
        </RouteStatus>
    ),
)
