/* eslint-disable react/jsx-no-useless-fragment */
import { Product, Profile } from '@news-mono/web-common'
import React from 'react'
import { FundingType } from '../../../templates/Publication/lib/get-funding-type'
import {
    TimestampFormat,
    TimestampLabel,
} from '../../../typography/Timestamp/Timestamp'
import { useProduct } from '../../../__product/useProduct'
import {
    StyledBylineAuthor,
    StyledBylinePosition,
    StyledBylineTextBlock,
    StyledBylineTextWrap,
    StyledFundingLabel,
    StyledMultipleTimestampWrapper,
} from '../Byline.styled'
import { BylineTimestamp } from './BylineTimestamp'
import { BylineTimestampUpdated } from './BylineTimestampUpdated'
import { SourceLogo } from './SourceLogo'

interface BylineContentProps {
    inlineTimestamp: boolean
    profiles: Profile[] | undefined
    fundingType?: FundingType
    source: string | undefined
    timestamp: string | undefined
    timestampUpdated: string | undefined
    timestampFormat: TimestampFormat
    timestampUpdatedFormat: TimestampFormat
    timestampUpdatedLabel?: TimestampLabel
    text: string | undefined
}

export const BylineContent: React.FC<BylineContentProps> = ({
    profiles,
    inlineTimestamp,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    timestampFormat,
    timestampUpdatedFormat,
    text,
    timestampUpdatedLabel,
}) => {
    const product = useProduct()
    const updateLabel = timestampUpdatedLabel || 'Updated'
    const timestampLabel =
        product === Product.SevenNews ? 'Published:' : undefined

    if (profiles && profiles.length > 0 && !fundingType) {
        const profileBlock = profiles.map((profile, index) => {
            const profileUrl = `/profile/${profile.slug}`

            if (source) {
                return (
                    <StyledBylineTextWrap key={index}>
                        <StyledBylineAuthor
                            href={profileUrl}
                            itemScope
                            itemType="http://schema.org/Person"
                        >
                            <span itemProp="name">{profile.name}</span>
                        </StyledBylineAuthor>
                        <SourceLogo source={source} />
                        {/* TODO remove when seven has moved to Bauhaus */}
                        {inlineTimestamp && (
                            <StyledMultipleTimestampWrapper>
                                {timestamp && (
                                    <BylineTimestamp
                                        timestamp={timestamp}
                                        timestampLabel={timestampLabel}
                                        timestampFormat={timestampFormat}
                                    />
                                )}
                                {timestampUpdated && (
                                    <BylineTimestampUpdated
                                        timestamp={timestampUpdated}
                                        timestampLabel={updateLabel}
                                        timestampUpdatedFormat={
                                            timestampUpdatedFormat
                                        }
                                    />
                                )}
                            </StyledMultipleTimestampWrapper>
                        )}
                    </StyledBylineTextWrap>
                )
            } else if (profile.position) {
                return (
                    <StyledBylineTextWrap key={index}>
                        <StyledBylineAuthor
                            href={profileUrl}
                            itemScope
                            itemType="http://schema.org/Person"
                        >
                            <span itemProp="name">{profile.name}</span>
                        </StyledBylineAuthor>
                        <StyledBylinePosition itemProp="jobTitle">
                            {profile.position}
                        </StyledBylinePosition>
                    </StyledBylineTextWrap>
                )
            } else {
                return (
                    <StyledBylineAuthor
                        key={index}
                        href={profileUrl}
                        itemScope
                        itemType="http://schema.org/Person"
                    >
                        <span itemProp="name">{profile.name}</span>
                    </StyledBylineAuthor>
                )
            }
        })

        return <React.Fragment>{profileBlock}</React.Fragment>
    }

    // Sevennews doenst have designs for the funding label yet
    if (fundingType && !(product === Product.SevenNews)) {
        if (profiles && profiles.length > 0) {
            const profileBlock = profiles.map((profile, index) => {
                const profileUrl = `/profile/${profile.slug}`

                return (
                    <StyledBylineTextWrap key={index}>
                        {profile.name && (
                            <StyledBylineAuthor
                                href={profileUrl}
                                itemScope
                                itemType="http://schema.org/Person"
                            >
                                <span itemProp="name">{profile.name}</span>
                            </StyledBylineAuthor>
                        )}
                        <StyledFundingLabel fundingType={fundingType} />
                    </StyledBylineTextWrap>
                )
            })

            return <React.Fragment>{profileBlock}</React.Fragment>
        }
        return (
            <StyledBylineTextWrap>
                {text && (
                    <StyledBylineTextBlock
                        itemScope
                        itemType="http://schema.org/Person"
                    >
                        <span itemProp="name">{text}</span>
                    </StyledBylineTextBlock>
                )}
                <StyledFundingLabel
                    fundingType={fundingType}
                    linkToDisclaimer
                />
            </StyledBylineTextWrap>
        )
    }

    if (text && source) {
        return (
            <StyledBylineTextWrap>
                <StyledBylineTextBlock
                    itemScope
                    itemType="http://schema.org/Person"
                >
                    <span itemProp="name">{text}</span>
                </StyledBylineTextBlock>
                <SourceLogo source={source} />
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampLabel={timestampLabel}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextWrap>
        )
    } else if (text) {
        return (
            <StyledBylineTextBlock>
                <StyledBylineTextBlock
                    itemScope
                    itemType="http://schema.org/Person"
                >
                    <span itemProp="name">{text}</span>
                </StyledBylineTextBlock>
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampLabel={timestampLabel}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextBlock>
        )
    } else if (source) {
        return (
            <StyledBylineTextBlock>
                <SourceLogo source={source} />
                {inlineTimestamp && (
                    <StyledMultipleTimestampWrapper>
                        <BylineTimestamp
                            timestamp={timestamp}
                            timestampLabel={timestampLabel}
                            timestampFormat={timestampFormat}
                        />
                        {timestampUpdated && (
                            <BylineTimestampUpdated
                                timestamp={timestampUpdated}
                                timestampUpdatedFormat={timestampUpdatedFormat}
                            />
                        )}
                    </StyledMultipleTimestampWrapper>
                )}
            </StyledBylineTextBlock>
        )
    }

    return null
}
