import React from 'react'
import {
    StyledContent,
    StyledLatestLiveBlogContainer,
    StyledPostTime,
    StyledTitled,
} from './LatestLiveBlog.styled'
import { format } from 'date-fns'
import { getEventPostTimeAgo } from '@news-mono/web-common'

type LatestLiveBlogProps = {
    title: string
    content: string
    showAsLive?: boolean
    publishedDate: string
}

export const LatestLiveBlog = ({
    title,
    content,
    showAsLive = false,
    publishedDate,
}: LatestLiveBlogProps) => {
    const formattedPublishedDate = showAsLive
        ? 'Latest post'
        : format(new Date(publishedDate), 'dd MMM yyyy').toUpperCase()

    const formattedPublishedTime = getEventPostTimeAgo(publishedDate)

    return (
        <StyledLatestLiveBlogContainer>
            <StyledPostTime>
                {formattedPublishedDate} - {formattedPublishedTime}
            </StyledPostTime>
            <StyledTitled>{title}</StyledTitled>
            <StyledContent>{content}</StyledContent>
        </StyledLatestLiveBlogContainer>
    )
}
