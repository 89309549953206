import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { CardText } from '../CardText/CardText'
import { StyledHeadlineText } from '../CardText/CardText.styled'
import { StyledKicker } from '../Kicker/Kicker.styled'
import { styledLinkClassName } from '../ListCard/ListCard.styled'

export const StyledLinkClassName = 'StyledLink'
export const StyledProfileCard = styled('div')({
    ...breakWords,
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    backgroundColor: colors.white,
})

export const StyledCardTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'center',
    marginTop: calcRem(-theme.margins.md),
    position: 'relative',
}))

export const StyledLink = withClass(styledLinkClassName)(
    styled(WebLink, {
        shouldForwardProp: createPropFilter<WebLinkProps>()([
            'theme',
            'hasPadding',
            'isLoading',
            'cardOrientation',
        ]),
    })(({ theme }) => ({
        textDecoration: 'none',
        width: '100%',
        textDecorationSkipInk: 'auto',

        color: colors.black,
        padding: calcRem(theme.cards.outerMargin),

        '&:focus': {
            outline: 'none',

            [`& ${StyledHeadlineText}`]: {
                textDecoration: 'underline',
            },

            [`& ${StyledKicker}`]: {
                background: colors.black,
            },
        },

        '&:hover': {
            [`& ${StyledHeadlineText}`]: {
                color: colors.thewest.westblue,
            },
        },
    })),
)

export const StyledPremiumIcon = styled(IconPremium)({
    verticalAlign: 'text-bottom',
    marginRight: calcRem(1),
    position: 'absolute',
    zIndex: 1,
    width: calcRem(17),
    height: calcRem(17),
    backgroundColor: colors.white,
    borderRadius: '50%',
})

export const StyledCardText = styled(CardText)(({ theme }) => ({
    [`& ${StyledHeadlineText}`]: {
        display: 'inline',
        color: colors.black,
    },
    [`& ${StyledKicker}`]: {
        background: colors.thewest.westblue,
        padding: calcRem(5, 10),
        color: colors.white,
        marginBottom: calcRem(theme.margins.md),
    },
}))
