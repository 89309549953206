import React from 'react'

export interface TheWestLiveLogoProps {
    className?: string
}

export const WEST_LIVE_LOGO_PRIMARY_FILL_CLASS = 'west-live-fill-primary' // 'The West'
export const WEST_LIVE_LOGO_SECONDARY_FILL_CLASS = 'west-live-fill-secondary' // Logo mark and 'Live'

export const TheWestLiveLogo: React.FC<TheWestLiveLogoProps> = ({
    className,
}) => (
    <svg
        viewBox="0 0 746 259"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        focusable="false"
        role="img"
    >
        <title>The West Live</title>
        <defs>
            <path
                id="thewestlivelogo-path-a"
                d="M.313.098h483.355V79.79H.313z"
            />
            <path id="thewestlivelogo-path-c" d="M0 .08h258.92V259H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(262 40)">
                <mask id="thewestlivelogo-mask-b" fill="#fff">
                    <use xlinkHref="#thewestlivelogo-path-a" />
                </mask>
                <path
                    d="M305.935.098l-9.688 44.89h-.454L285.078.099h-21.2l-10.716 44.89h-.456L243.93.099h-26.443l18.35 78.559h26.9l10.487-43.758h.342l9.803 43.758h26.785L328.732.097h-22.797zm43.104 41.604c.795-8.05 3.873-10.203 7.862-10.203 4.673 0 6.839 2.607 6.839 10.203h-14.701zm7.634-25.28c-19.945 0-31.459 12.696-31.459 30.834v1.36c0 20.632 10.944 31.174 33.624 31.174 11.743 0 19.605-2.608 24.506-5.328v-14.17c-4.445 1.587-11.055 3.287-18.121 3.287-9.689 0-14.59-1.927-15.959-9.861h36.93v-4.761c0-20.406-7.637-32.536-29.521-32.536zm67.373 23.465l-5.583-.793c-4.448-.68-6.27-1.247-6.27-3.627 0-2.608 2.278-3.4 7.636-3.4 7.523 0 12.88 1.472 17.097 2.605v-15.19c-5.129-1.926-11.397-3.06-20.517-3.06-17.894 0-26.33 7.368-26.33 20.178 0 11.676 5.7 17.57 16.642 19.272l5.7.792c4.216.681 5.7 1.134 5.7 3.514 0 2.608-2.394 3.742-9.232 3.742-8.094 0-13.566-1.7-18.353-3.288v15.304c4.901 2.153 13.451 3.854 21.884 3.854 19.948 0 28.495-7.255 28.495-21.198 0-10.43-4.217-16.778-16.87-18.705zm59.622-6.462V17.556h-11.511V.433h-23.708v17.123h-7.18v15.87h7.18v27.772c0 14.399 7.41 18.479 20.743 18.479 6.725 0 12.082-1.36 14.476-2.608V61.993c-1.367.339-3.532.68-5.358.68-4.217 0-6.153-1.02-6.153-4.761V33.425h11.51zM112.94 16.421c-7.865 0-13.676 2.155-18.352 6.01h-.568V.098H70.425v78.559h23.596V35.806c1.937-.68 4.787-1.36 7.295-1.36 4.217 0 6.268 1.36 6.268 5.328v38.883h23.593V33.2c0-12.243-7.067-16.779-18.236-16.779zM.313 18.35h19.72v60.308H46.59V18.349h19.833V.098H.313v18.251zM160.37 41.702c.796-8.05 3.874-10.203 7.863-10.203 4.673 0 6.838 2.607 6.838 10.203H160.37zm7.634-25.28c-19.944 0-31.458 12.696-31.458 30.834v1.36c0 20.632 10.943 31.174 33.624 31.174 11.742 0 19.605-2.608 24.506-5.328v-14.17c-4.445 1.587-11.055 3.287-18.121 3.287-9.689 0-14.59-1.927-15.96-9.861h36.931v-4.761c0-20.406-7.637-32.536-29.522-32.536z"
                    fill="#333"
                    mask="url(#thewestlivelogo-mask-b)"
                    className={WEST_LIVE_LOGO_PRIMARY_FILL_CLASS}
                />
            </g>
            <path
                d="M473.556 169.266h-20.194l-1.72 7.434h15.903l-2.692 11.63h-15.904l-1.908 8.244h21.157l-2.744 11.85h-38.17l11.808-51.009h37.207l-2.743 11.85zm-88.641 39.158l-5.429-51.009h17.458l2.216 31.651h.37l16.647-31.65h15.682l-29.042 51.008h-17.902zm-24.76 0h-17.162l11.807-51.009h17.16l-11.806 51.01zm-25.949 0h-35.505l11.807-51.009h17.235l-9.064 39.159h18.27l-2.743 11.85zM277.75 142L259 223h238.25L516 142H277.749z"
                fill="#043DE2"
                className={WEST_LIVE_LOGO_SECONDARY_FILL_CLASS}
            />
            <mask id="thewestlivelogo-mask-d" fill="#fff">
                <use xlinkHref="#thewestlivelogo-path-c" />
            </mask>
            <path
                d="M194.35 195.304l-15.612-15.644c-12.737 19.922-42.332 18.645-55.74 2.714-13.631 16.952-40.927 15.005-53.696-.095l-2.3-2.938c-12.801-17.047-3.32-37.064 27.839-62.253-10.695 0-15.292-3.287-21.996-3.287-4.246 0-7.47 1.308-7.47 4.405 0 3.352 1.724 4.118 8.013 6.321-8.141-.032-11.844-3.352-11.812-10.535 0-7.917 6.896-13.249 16.664-13.217 8.396 0 14.845 3.512 22.475 4.246 5.108.479 10.056-3.192 12.418-5.555l-.095 64.583-18.229 16.218.096-61.167c-20.943 18.325-22.092 38.597-12.1 52.037 8.748 11.748 24.933 14.238 37.863 8.396-12.802-17.08-3.384-37.16 27.774-62.348-10.694 0-15.292-3.289-21.996-3.289-4.278-.032-7.47 1.309-7.47 4.406 0 3.352 1.724 4.118 8.013 6.32-8.141-.031-11.812-3.351-11.812-10.534 0-7.918 6.864-13.25 16.664-13.217 8.364 0 14.877 3.512 22.507 4.246 5.108.479 10.057-3.192 12.419-5.555v16.122l16.313-16.537 14.494 14.526 5.746-5.619 1.405 1.213-7.087 7.023-.224 54.176 9.61 9.578 5.619-5.587 1.404 1.213-19.697 19.634zM61.347 75.566h136.235v-11.79H61.347v11.79zm145.067-50.123c-57.491-42.5-138.55-30.348-181.05 27.144-42.5 57.49-30.348 138.549 27.143 181.05 57.491 42.499 138.55 30.347 181.05-27.144s30.347-138.55-27.143-181.05zm-35.529 89.06l-4.118 4.054-.064 45.589-18.26 16.217.095-61.167c-20.943 18.324-22.092 38.565-12.1 52.036 9.738 13.058 28.765 14.782 42.141 6.194l-.032-55.261-7.662-7.662z"
                fill="#043DE2"
                mask="url(#thewestlivelogo-mask-d)"
                className={WEST_LIVE_LOGO_SECONDARY_FILL_CLASS}
            />
        </g>
    </svg>
)
TheWestLiveLogo.displayName = 'TheWestLiveLogo'
