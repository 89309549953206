import { Classification, useFeature } from '@news-mono/web-common'
import React from 'react'
import { getFeatureEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { EditorialLabel } from '../../typography/EditorialLabel/EditorialLabel'
import {
    StyledFeatureLabel,
    StyledHeadline,
    StyledHeadlineText,
} from './FeatureHeadline.styled'

export interface FeatureHeadlineProps {
    text: string
    classification?: Classification
}

export const FeatureHeadline: React.FC<FeatureHeadlineProps> = ({
    text,
    classification,
}) => {
    const storyClassification = true
    const editorialType = getFeatureEditorialType(
        classification,
        storyClassification,
    )

    return (
        <StyledHeadline itemProp="headline" hasEditorialType={!!editorialType}>
            {editorialType && (
                <StyledFeatureLabel>
                    <EditorialLabel type={editorialType} isLarge />
                </StyledFeatureLabel>
            )}
            <StyledHeadlineText>{text}</StyledHeadlineText>
        </StyledHeadline>
    )
}
