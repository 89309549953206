export const validateKeys = (
    validationItem: object,
    expectedKeys: string[],
): false | Error[] => {
    const errors: Error[] = []
    const actualKeys = Object.keys(validationItem)

    expectedKeys.forEach((expectedKey) => {
        if (actualKeys.indexOf(expectedKey) === -1) {
            const error = new Error(`Missing expected Key: ${expectedKey}`)
            errors.push(error)
        }
    })

    actualKeys.forEach((actualKey) => {
        if (expectedKeys.indexOf(actualKey) === -1) {
            const error = new Error(`Missing expected Key: ${actualKey}`)
            errors.push(error)
        }
    })

    if (errors.length) {
        return errors
    }

    return false
}

export type KeyType =
    | 'number'
    | 'string'
    | ObjectShape
    | [ObjectShape]
    | 'boolean'
    | ['string']
    | ['number']

export interface ObjectShape {
    [key: string]: KeyType
}

export const validateKey = (
    itemType: ObjectShape | 'string' | 'number',
    item: any,
    objectType: string,
    expectedKey: string,
    itemIndex: number,
) => {
    if (itemType === 'string') {
        if (typeof item !== 'string') {
            return [
                `Expected ${objectType}.${expectedKey}[${itemIndex}] to be type: string`,
            ]
        }

        return false
    }

    if (itemType === 'number') {
        if (typeof item !== 'number') {
            return [
                `Expected ${objectType}.${expectedKey}[${itemIndex}] to be type: number`,
            ]
        }

        return false
    }

    return validateKeyTypes(
        `${objectType}.${expectedKey}[${itemIndex}]`,
        item,
        itemType,
    )
}

export const validateKeyTypes = (
    objectType: string,
    validationItem: any,
    expectedObjectShape: ObjectShape,
): false | string[] => {
    const errors: string[] = []
    try {
        const validationItemType = typeof validationItem
        if (validationItemType !== 'object') {
            return [
                `${objectType} was expected to be an object, but was ${validationItemType}`,
            ]
        }
        const actualKeys = Object.keys(validationItem)

        Object.keys(expectedObjectShape).forEach((expectedKey) => {
            try {
                if (actualKeys.indexOf(expectedKey) === -1) {
                    errors.push(`Missing expected Key: ${expectedKey}`)
                    return
                }
                const actualValue = validationItem[expectedKey]
                const expectedType = expectedObjectShape[expectedKey]

                if (expectedType === 'number') {
                    if (typeof actualValue !== 'number') {
                        errors.push(
                            `Expected Key [${expectedKey}] to be type: number`,
                        )
                    }
                    return
                }

                if (expectedType === 'string') {
                    if (typeof actualValue !== 'string') {
                        errors.push(
                            `Expected Key [${expectedKey}] to be type: string`,
                        )
                    }
                    return
                }

                if (expectedType === 'boolean') {
                    if (typeof actualValue !== 'boolean') {
                        errors.push(
                            `Expected Key [${expectedKey}] to be type: boolean`,
                        )
                    }
                    return
                }

                if (expectedType instanceof Array) {
                    if (actualValue instanceof Array) {
                        actualValue.forEach((item, itemIndex) => {
                            const nestedErrors = validateKey(
                                expectedType[0],
                                item,
                                objectType,
                                expectedKey,
                                itemIndex,
                            )
                            if (nestedErrors) {
                                errors.push(
                                    `Key [${expectedKey}@${itemIndex}] had the following errors:\n` +
                                        `${nestedErrors.join('    \n')}`,
                                )
                            }
                        })
                    } else {
                        errors.push(
                            `Expected Key [${expectedKey}] to be type: Array`,
                        )
                    }
                    return
                }

                if (expectedType instanceof Object) {
                    const nestedErrors = validateKeyTypes(
                        `${objectType}.${expectedKey}`,
                        expectedType,
                        expectedType,
                    )
                    if (nestedErrors) {
                        errors.push(
                            `Key [${expectedKey}] had the following errors:\n` +
                                `${nestedErrors.join('    \n')}`,
                        )
                    }
                    return
                }
            } catch (err) {
                console.error(
                    `Unexpected error when validating ${objectType}.${expectedKey}`,
                    err,
                )
            }
        })
    } catch (err) {
        console.error(`Unexpected error when validating ${objectType}`, err)
        errors.push(`Unexpected error when validating ${objectType}`)
    }

    if (errors.length) {
        return errors
    }

    return false
}
