import { useState, useEffect, RefObject } from 'react'

export const useOnScreen = (
    ref: RefObject<HTMLElement>,
    initialState?: boolean,
) => {
    const [isVisible, setisVisible] = useState(initialState ?? false)

    useEffect(() => {
        if (!supportIntersection) {
            return
        }
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setisVisible(true)
            } else {
                setisVisible(false)
            }
        })

        if (ref.current) {
            observer.observe(ref.current)
            return () => observer.disconnect()
        }
    }, [ref])

    return isVisible
}

const supportIntersection =
    typeof window !== 'undefined' &&
    ('IntersectionObserver' in window ||
        ('IntersectionObserverEntry' in window &&
            'intersectionRatio' in
                (window as any).IntersectionObserverEntry?.prototype))
