import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { LazyPuzzlesListing } from './PuzzleListing.lazy'

export const PuzzlesListingRegistration = createRegisterableComponent(
    'puzzles-listing',
    () => {
        return <LazyPuzzlesListing />
    },
)
