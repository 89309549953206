export interface FilterQueryParam {
    displayName: string
    paramName: string
}

export const getParameterValue = (
    param: string | null,
    options: FilterQueryParam[],
    defaultOption: FilterQueryParam,
): FilterQueryParam => {
    if (param === null) {
        return defaultOption
    }

    const timeFilterOption = options.find(
        (option) => option.paramName === param,
    )

    return timeFilterOption ?? defaultOption
}
