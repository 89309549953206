import { PublicationType } from '@news-mono/web-common'
import { RequiredAccessLevel } from '@west-australian-newspapers/publication-types'
import React, { useState } from 'react'

export interface BreachContext {
    publication?: PublicationType
    packagePath?: string
    requiredAccessLevel?: RequiredAccessLevel
    breachCreativeName?: string
    breachDeal?: string
    offerCode?: string
    leadDepth?: number
    returningUser?: boolean
}
export interface BreachScreenContextProps {
    setContext: (context: BreachContext) => void
    clearContext: () => void
    context: BreachContext | undefined
}

export const BreachScreenContext =
    React.createContext<BreachScreenContextProps>({
        setContext: () => null,
        clearContext: () => null,
        context: undefined,
    })

export const BreachScreenContextProvider: React.FC = ({ children }) => {
    const [context, setbreachContext] = useState<BreachContext | undefined>(
        undefined,
    )

    const setContext = (newContext: BreachContext | undefined) => {
        setbreachContext(newContext)
    }

    const clearContext = () => {
        setbreachContext(undefined)
    }

    return (
        <BreachScreenContext.Provider
            value={{ setContext, clearContext, context }}
        >
            {children}
        </BreachScreenContext.Provider>
    )
}
