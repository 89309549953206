import { layout } from '../../../App.routing'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getAFLWRouteInfo: GetMainSectionFn = (sectionBuilder, store) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                loadMoreEnabled: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Latest',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/aflw'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 12,
                    },
                },
            },
        }),
    ]
}
