import styled from '@emotion/styled'
import { calcRem, colors, fonts, metrics } from '../../__styling'

export const StyledBreakoutBoxWrapper = styled('div')({
    background: colors.sevennews.seashell,
    padding: calcRem(
        metrics.sevennews.textMargin,
        metrics.sevennews.margins.lg,
    ),
    margin: calcRem(0, 0, metrics.sevennews.textMargin, 0),
    //prevent default h2 maring at the top of breakout box
    h2: {
        marginTop: 0,
        fontFamily: fonts.sevennews.sansSerif,
    },
    'ul li': {
        fontSize: calcRem(18),
        fontWeight: 300,
        lineHeight: '25px',
    },
})
