import styled from '@emotion/styled'
import { colors } from '../../..'
import { Timestamp } from '../../../content/CardTimestamp/CardTimestamp'
import { calcRem } from '../../../__styling'
import { rgba } from 'polished'

export const StyledMilestonesContainer = styled('div')(({ theme }) => [
    // Common values used between different themes
    {
        backgroundColor: colors.thewest.greyErmine,
        padding: theme.margins.sm,
        margin: 0,
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        backgroundColor: colors.white,
        margin: calcRem(theme.margins.lg, -7, 0),
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        backgroundColor: colors.white,
        padding: calcRem(0, 0, 8),
    },
])

export const StyledMilestonesTimeline = styled('div')(({ theme }) => ({
    '& > a': {
        textDecoration: 'none',
    },
}))

export const StyledMilestone = styled('div')<{ dimmed: boolean }>(
    ({ theme, dimmed }) => [
        // Common values used between different themes
        {
            position: 'relative',
            margin: calcRem(0, 20),
            padding: calcRem(0, 14, theme.margins.md),
            borderLeft: `1px solid ${colors.thewest.greySeal}`,
            opacity: dimmed ? 0.2 : 1,
            '&:hover': {
                opacity: 1,
            },
        },
        // Apply the dot point to styling that is NOT on TN, as TN uses a 'display: list-item'.
        theme.kind !== 'thenightly' && {
            '&::after': {
                display: 'block',
                position: 'absolute',
                borderRadius: '50%',
                content: `''`,
                left: -5,
                top: 3,
                width: 10,
                height: 10,
                backgroundColor: theme.colors.brand,
                zIndex: 11,
            },
        },
        // Styling for TN
        theme.kind === 'thenightly' && {
            padding: calcRem(0, 14, 0),
            margin: calcRem(0, 8),
            borderLeft: '0px solid',
        },
    ],
)

export const StyledMilestoneTimestamp = styled(Timestamp)(({ theme }) => [
    {
        marginLeft: 0,
        backgroundColor: 'transparent',

        color: theme.colors.palette.black,
        fontWeight: 700,
        fontSize: calcRem(15),
        lineHeight: calcRem(16),
        fontFamily: theme.fonts.sansSerif,
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        color: rgba(39, 38, 38, 0.5),
        fontWeight: 600,
        fontSize: calcRem(12),
        fontFamily: theme.fonts.sansSerif,
    },
])

export const StyledMilestoneTitle = styled('span')(({ theme }) => [
    // Common values used between different themes
    {
        textDecoration: 'none',
        color: theme.colors.palette.black,
        fontWeight: 'inherit',
        fontFamily: theme.fonts.serif,
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 400,
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        display: 'list-item',
        color: theme.colors.text.tertiary,
        textDecorationLine: 'underline',
        lineHeight: calcRem(24),
        letterSpacing: calcRem(-0.32),
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 400,
    },
])

export const StyledMilestonesTitle = styled('h3')(({ theme }) => [
    {
        fontSize: calcRem(22),
        padding: calcRem(0, theme.margins.md, theme.margins.md),
        margin: 0,
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        padding: calcRem(0, 0, 8),
    },
])
