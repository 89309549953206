import { tokens } from '@news-mono/design-tokens'
import { TheNightlyThemeConfig, ThemeConfig, calcRem } from '../../__styling'

export type SharingTheme<T extends ThemeConfig> = {
    gap: string
    compactGap: string
    button: {
        backgroundColor: string
        color: string
        gap: string
        hover: {
            backgroundColor: string
        }
    }
    icon: {
        fill: string
    }
}

export const theNightlySharingTheme: SharingTheme<TheNightlyThemeConfig> = {
    gap: calcRem(16),
    compactGap: calcRem(8),
    button: {
        backgroundColor: tokens.thenightly.colors.palette.neutral[10],
        color: tokens.thenightly.colors.background.primary,
        gap: calcRem(12),
        hover: {
            backgroundColor: tokens.thenightly.colors.palette.neutral[30],
        },
    },
    icon: {
        fill: tokens.thenightly.colors.background.primary,
    },
}
