import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    RouteInformation,
    RouteResolution,
    RouteServices,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'
import { rampAdUnit } from '../home'
import { cnbcSegment } from '../home/cnbc-segment'
import { inCaseYouMissedItSegment } from '../home/in-case-you-missed-it-segment'
import { subscriberExclusive } from '../home/subscriber-exclusive'
import { webStoriesSegment } from '../home/web-stories-segment'
import { customTopicRoutes } from '../topic/custom-topic-routes'
import {
    getCountrymanCuratedSection,
    getCountrymanRouteInfo,
} from '../topic/custom/get-countryman-route-info'
import { getCuratedRegionalHomepage } from './get-curated-regional-homepage'

export interface RegionalHomepageOptions {
    section: TheWestSection
    mastheadTopic: string
    regionalTopic: string
    title: string
    description: string
    socialDescription: string
}

export const getRegionalHomepage = (
    services: RouteServices<RouteResolution>,
    options: RegionalHomepageOptions,
): RouteInformation<TheWestSection> | null => {
    if (
        !isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'regionals',
        )
    ) {
        // DPT-1409: if the section is countryman, we need to return the existing page for it
        if (options.section === 'countryman') {
            return customTopicRoutes['/countryman'](services)
        }

        return null
    }

    /**
     * DPT-3647: Enable this toggle after the affected curations have been curated
     */
    const southWestCurationsEnabled = isFeatureEnabled(
        toFeatureState(services.store.getState().toggles),
        'south-west-curations',
    )

    if (southWestCurationsEnabled) {
        switch (options.section) {
            case 'augusta-margaret-river-times':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'augusta-margaret-river-times',
                    topicId: 'news/augusta-margaret-river-times',
                })
            case 'busselton-dunsborough-times':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'busselton-dunsborough-times',
                    topicId: 'news/busselton-dunsborough-times',
                })
            case 'harvey-waroona-reporter':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'harvey-waroona-reporter',
                    topicId: 'news/harvey-waroona-reporter',
                })
            case 'manjimup-bridgetown-times':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'manjimup-bridgetown-times',
                    topicId: 'news/manjimup-bridgetown-times',
                })
            case 'bunbury-herald':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'south-west',
                    topicId: 'news/bunbury-herald',
                })
            case 'south-western-times':
                return getCuratedRegionalHomepage(services, {
                    ...options,
                    curationId: 'south-west',
                    topicId: 'news/south-western-times',
                })
            default:
                break
        }
    }

    switch (options.section) {
        case 'kalgoorlie-miner':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'goldfields',
                topicId: 'news/goldfields',
            })
        case 'albany-advertiser':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'albany-advertiser',
                topicId: 'news/albany-advertiser',
            })
        case 'broome-advertiser':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'broome-advertiser',
                topicId: 'news/broome-advertiser',
            })
        case 'north-west-telegraph':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'north-west-telegraph',
                topicId: 'news/north-west-telegraph',
            })
        case 'pilbara-news':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'pilbara-news',
                topicId: 'news/pilbara-news',
            })
        case 'geraldton-guardian':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'geraldton-guardian',
                topicId: 'news/geraldton-guardian',
            })
        case 'the-kimberley-echo':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'the-kimberley-echo',
                topicId: 'news/the-kimberley-echo',
            })
        case 'midwest-times':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'mid-west-times',
                topicId: 'news/mid-west',
            })
        case 'great-southern-herald':
            return getCuratedRegionalHomepage(services, {
                ...options,
                curationId: 'great-southern',
                topicId: 'news/great-southern-herald',
            })
        default:
            break
    }

    // South West is the only region with more than one publication
    const displayRegionSection = options.regionalTopic === 'news/south-west'
    const adTargeting = services.getAdTargeting('home', options.section, 'home')

    const sections =
        options.section === 'countryman'
            ? getCountrymanRouteInfo({
                  getCuratedSection: getCountrymanCuratedSection,
              })
            : [
                  homeSegment(options),
                  rampAdUnit,
                  subscriberExclusive(),
                  inCaseYouMissedItSegment(true),
                  ...billboardLeaderboardMrec('two'),
                  regionalNewsSegment(),
                  webStoriesSegment(),
                  cnbcSegment(),
                  waNewsSegment(options, displayRegionSection),
                  ...billboardLeaderboardMrec('three'),
                  layout.component({
                      type: 'taboola',
                      feature: 'taboola-regionals',
                      props: {
                          taboolaContainerId: 'taboola-below-home-thumbnails',
                          locationType: 'home',
                      },
                  }),
              ]

    return {
        kind: 'page',
        heading: options.title,
        hideHeading: true,
        pageType: 'homepage',
        pageMeta: {
            title: options.title,
            description: options.description,
        },
        socialMeta: {
            description: options.socialDescription,
        },
        noMetaTitleTemplate: true,
        adTargeting,
        section: options.section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    verticalGutters: true,
                },
                contentAreas: {
                    main: sections,
                },
            }),
        ],
    }
}

const homepageHeroSegment = (mastheadTopic: string) =>
    layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'gridGap',
            fixedRatios: ['16:9'],
            heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },

            cardLayout: ['hero', 'commonWithBackground'],
            dataDefinitionArgs: {
                type: 'listing',
                topics: [mastheadTopic],
                includeSubTopics: false,
                paging: {
                    page: 1,
                    pageSize: 2,
                },
            },
        },
    })

const homepageSubHeroSegment1 = (mastheadTopic: string) =>
    layout.component({
        type: 'uniform',
        props: {
            section: 'default',
            verticalSpacing: 'xl',
            cardOrientation: { type: 'Landscape-Portrait' },
            cardLayout: [
                { type: 'november', listLength: 5 },
                ['teaserWithBackground'],
                ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
                ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
            ],

            dataDefinitionArgs: {
                type: 'listing',
                topics: [mastheadTopic],
                paging: {
                    page: 1,
                    pageOffset: 2,
                    pageSize: 10,
                },
            },
        },
    })

const homepageSubHeroSegment2 = (mastheadTopic: string) =>
    layout.component({
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fixedRatios: ['16:9'],
            cardOrientation: {
                type: 'Landscape-Portrait',
            },
            finalColumns: 4,
            intermediateColumns: 2,
            hasBackground: true,

            cardLayout: [
                ['teaserWithBackground'],
                [
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                ],
                ['teaserWithBackground'],
                ['teaserWithBackground'],
            ],
            dataDefinitionArgs: {
                type: 'listing',
                topics: [mastheadTopic],
                paging: {
                    page: 1,
                    pageOffset: 12,
                    pageSize: 6,
                },
            },
        },
    })

const homeSegment = (options: RegionalHomepageOptions) => {
    const sections = [
        homepageHeroSegment(options.mastheadTopic),
        homepageSubHeroSegment1(options.mastheadTopic),
        ...billboardLeaderboardMrec('one'),
        homepageSubHeroSegment2(options.mastheadTopic),
    ]

    return layout.nestedComposition({
        type: 'thor',
        props: {
            hasBackgroundFill: false,
            containerWidth: metrics.thewest.siteMetrics.mainContentWidth,
            verticalGutters: false,
            horizontalGutters: false,
        },
        contentAreas: {
            main: sections,
        },
    })
}

export const regionalNewsSegment = () =>
    layout.component({
        type: 'lima',
        props: {
            fixedRatios: ['16:9'],
            verticalSpacing: 'xl',
            fontScale: 1.2,
            hasBackground: true,
            initialColumns: 1,
            intermediateColumns: 2,
            finalColumns: 4,

            cardLayout: [
                ['teaserWithBackground'],
                ['teaserWithBackground'],
                ['teaserWithBackground'],
                [
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                ],
            ],
            sectionHeader: {
                heading: 'Regional News',
                headingUrl: '',
            },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'regional',
                offset: 0,
                pageSize: 6,
            },
        },
    })

export const waNewsSegment = (
    options: RegionalHomepageOptions,
    displayRegionSection: boolean,
) => {
    const excludeTopics = [options.mastheadTopic]
    if (displayRegionSection) {
        excludeTopics.push(options.regionalTopic)
    }
    return layout.component({
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fixedRatios: ['16:9'],
            cardOrientation: {
                type: 'Landscape-Portrait',
            },
            finalColumns: 4,
            intermediateColumns: 2,
            hasBackground: true,

            cardLayout: [
                ['teaserWithBackground'],
                ['teaserWithBackground'],
                [
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                ],
                ['teaserWithBackground'],
            ],
            sectionHeader: {
                heading: 'WA News',
                headingUrl: '',
                section: options.section,
            },
            dataDefinitionArgs: {
                type: 'listing',
                topics: ['news/wa'],
                excludeTopics: excludeTopics,
                includeSubTopics: false,
                paging: {
                    page: 1,
                    pageSize: 6,
                },
            },
        },
    })
}
