import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ArticleLikeV4DTO,
    Item_MainVideoV4DTO,
    MissingVideoV4DTO,
    ProfileV4DTO,
    PublicationV4DTO,
} from '@west-australian-newspapers/publication-types'
import { HeroVideo } from '../../../../client-data-types/HeroVideo'
import {
    ImageCrop,
    ImageRatio,
    ImageSourceWithCaptions,
} from '../../../../client-data-types/image-item'
import { TogglesReduxState } from '../../../../feature-togglings/reducer'
import { pickImage } from '../../lib/to-image'

export function getHeroVideo(
    heroImage: ImageSourceWithCaptions | undefined,
    mainVideo: Item_MainVideoV4DTO | MissingVideoV4DTO | null,
    toggles: TogglesReduxState,
) {
    if (mainVideo === null || mainVideo.kind === 'missing-video') {
        return
    }

    // We only want the heroImage if there is a 16:9 crop as thats what the getPosterImage helper uses,
    // otherwise fallback to the poster image of the mainVideo which might have a 16:9
    const posterImage =
        heroImage &&
        heroImage.crops['16:9'] &&
        isFeatureEnabled(toFeatureState(toggles), 'hero-image-as-video-poster')
            ? heroImage
            : getPosterImageFromMainVideo(mainVideo)

    const heroVideo: HeroVideo = {
        videoId: mainVideo.videoId,
        accountId: mainVideo.accountId,
        captionText: mainVideo.videoDescription,
        heading: mainVideo.heading,
        posterImage,
        videoType: mainVideo.videoType,
        seriesInfo: mainVideo.seriesInfo,
    }

    return heroVideo
}

export function getPosterImageFromMainVideo(
    mainVideo: Item_MainVideoV4DTO | MissingVideoV4DTO | null,
): ImageSourceWithCaptions | undefined {
    if (
        mainVideo === null ||
        mainVideo.kind === 'missing-video' ||
        !mainVideo.posterImage
    ) {
        return
    }

    const ratio: ImageRatio = { width: 16, height: 9 }
    const posterImage16by9 = pickImage(['16:9'], [mainVideo.posterImage])

    if (!posterImage16by9) {
        return
    }

    const crop: ImageCrop = {
        reference: mainVideo.posterImage.reference,
        width: mainVideo.posterImage.width,
        height: mainVideo.posterImage.height,
        ratio,
    }

    return {
        altText: mainVideo.posterImage.altText || undefined,
        crops: {
            ['16:9']: crop,
        },
        captionText: mainVideo.posterImage.captionText || '',
        byline: mainVideo.posterImage.byline || '',
        credit: mainVideo.posterImage.credit || undefined,
    }
}
export function getProfiles(profile: ProfileV4DTO) {
    return {
        name: profile.name,
        slug: profile.slug,
        email: profile.email,
        position: profile.position,
        biography: profile.biography,
        mainPhoto: profile.mainPhoto,
        thumbnailPhoto: profile.thumbnailPhoto,
        twitter: profile.twitter,
        facebook: profile.facebook,
        instagram: profile.instagram,
        canonicalSite: profile.canonicalSite,
    }
}

export function getArticleAndRedirectCommonProps(
    publication: ArticleLikeV4DTO,
) {
    const commonProps = getCommonProps(publication)

    return {
        ...commonProps,
        heading: publication.heading,
        headKicker: publication.headKicker,
        homepageHead: publication.homepageHead,
        homepageTeaser: publication.homepageTeaser,
        byline: publication.byline ? publication.byline.text : '',
        isLive: publication.kind === 'article' && publication.isLiveContent,
        // missing fields on vnext currently
        socialHead: publication.socialHead || undefined,
        socialTeaser: publication.socialTeaser || undefined,
        requiredAccess: publication.requiredAccess,
        allowCommenting: publication.allowCommenting,
    }
}

export function getCommonProps({
    _self,
    id,
    isSponsored,
    slug,
    source,
    lastUpdated,
    publicationDate,
    topics: { primary: primaryTopic, secondary: secondaryTopics },
}: PublicationV4DTO) {
    return {
        _self,
        id,
        isSponsored,
        slug,
        source,
        lastUpdated,
        publicationDate,
        primaryTopic,
        secondaryTopics,
    }
}
