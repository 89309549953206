import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts, themedValue } from '../../__styling'
import { metrics } from '../../__styling/settings/metrics'

export const StyledTabHeader = styled('div')(({ theme }) => ({
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: calcRem(metrics.sevennews.margins.lg, 0),
    marginTop: themedValue(theme, {
        sevennews: 0,
        fallback: undefined,
    }),
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    [breakpoint('sm')]: {
        justifyContent: themedValue(theme, {
            thewest: 'center',
            fallback: undefined,
        }),
    },
    [breakpoint('md')]: {
        justifyContent: 'space-around',
        overflowX: 'hidden',
    },
}))

export interface StyledTabHeaderItemProps {
    active: boolean
}

export const StyledTabHeaderItem = styled('button')<StyledTabHeaderItemProps>(
    ({ active, theme }) => ({
        border: 'none',
        borderBottom: themedValue(theme, {
            sevennews: active
                ? `solid 5px ${tokens.sevennews.colors.palette.bauhausSport}`
                : 'solid 5px transparent',
            perthnow: active
                ? `solid 5px ${tokens.perthnow.colors.palette.pinkThulite}`
                : 'solid 5px transparent',
            thewest: active
                ? `solid 5px ${tokens.thewest.colors.palette.westblue}`
                : 'solid 5px transparent',
            fallback: undefined,
        }),
        background: 'none',
        padding: calcRem(metrics.sevennews.margins.md),
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: calcRem(16),
        lineHeight: calcRem(20),
        boxSizing: 'border-box',
        display: 'inline-block',
        color: '#292A33',
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            thewest: fonts.thewest.sansSerif,
            perthnow: fonts.perthnow.sansSerif,
            fallback: undefined,
        }),
        [breakpoint('md')]: {
            display: 'flex',
        },
        '&:hover': { cursor: 'pointer' },
        '&:focus, &:active': { outline: 'none' },
    }),
)
