import React from 'react'

export interface IconClockProps {
    className?: string
}

export const IconClock: React.FC<IconClockProps> = ({ className }) => (
    <svg
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M7 12.75c-3.18 0-5.75-2.57-5.75-5.75S3.82 1.25 7 1.25 12.75 3.82 12.75 7 10.18 12.75 7 12.75z" />
        <path d="M6.75 4.25V7l3 2.75" />
    </svg>
)
IconClock.displayName = 'IconClock'
