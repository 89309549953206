import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateKeyTypes } from '../../../helpers/validation'

export interface Options {
    curationId: string
    metadataKey?: string
    includePremium?: boolean
}

export function validateGetCurationMetadata(value: any): ListPublicationV4DTO {
    const toValidate = value as ListPublicationV4DTO

    if (!toValidate) {
        throw new Error('ListPublication data missing')
    }

    if (typeof toValidate.kind !== 'string') {
        throw new Error(
            `ListPublication response invalid: Has no 'kind' property`,
        )
    }

    if (!['article', 'gallery', 'redirect'].includes(toValidate.kind)) {
        throw new Error(
            `ListPublication response invalid: kind '${toValidate.kind}' is unknown`,
        )
    }

    const errors = validateKeyTypes('listpublication', value, {
        _self: 'string',
        id: 'string',
        slug: 'string',

        topics: {
            primary: {
                id: 'string',
                title: 'string',
                metadata: {},
            },
            secondary: [
                {
                    id: 'string',
                    title: 'string',
                    metadata: {},
                },
            ],
        },
        status: 'string',
        source: 'string',
        publicationDate: 'string',
        created: 'string',
        lastUpdated: 'string',
    })

    if (errors) {
        throw new Error(
            'ListPublication response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}

export const getCurationMetadata = (
    services: DataLoaderGlobalParams,
    { includePremium, curationId, metadataKey }: Options,
) => {
    return httpGet({
        log: services.log,
        baseUrl: services.config.contentApi,
        path: `v4/curation/${curationId}/metadata/${
            metadataKey ? metadataKey : ''
        }`,
        query: { include_premium: includePremium },
        validate: validateGetCurationMetadata,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}
