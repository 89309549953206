import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MatildasMessages } from './components/MatlidasMessages'
import { QueenElizabethTributes } from './components/QueenElizabethTribute'
import { GuestBook, Props } from './GuestBook'

const queryClient = new QueryClient()
export interface GuestBookRegistrationProps extends Props {}

export const GuestBookRegistration = createRegisterableComponent(
    'guestbook',
    (props: GuestBookRegistrationProps, services) => {
        return (
            <QueryClientProvider client={queryClient}>
                <GuestBook
                    onEvent={services.onEvent}
                    logger={services.log}
                    campaign={props.campaign}
                    introContent={getIntroContent(props.campaign)}
                />
            </QueryClientProvider>
        )
    },
)

function getIntroContent(campaign: string): JSX.Element | null | undefined {
    if (campaign === `Queen's Tribute`) {
        return <QueenElizabethTributes />
    }
    if (campaign === 'Go Matildas') {
        return <MatildasMessages />
    }
    return null
}
