import React from 'react'

export interface IconEmailProps {
    title?: string
    className?: string
}

export const IconEmail: React.FC<IconEmailProps> = ({ className, title }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 13"
        className={className}
    >
        <title>{title || 'Email Us'}</title>
        <path d="M9 6.84L17.26.27C17 .1 16.7 0 16.36 0H1.64c-.32 0-.6.1-.86.25L9 6.85z" />
        <path d="M17.8.88l-8.56 6.8c-.14.1-.34.1-.48 0L.2.85c-.12.23-.2.5-.2.8v9c0 .9.73 1.62 1.64 1.62h14.72c.9 0 1.64-.73 1.64-1.63v-9c0-.28-.07-.53-.2-.76" />
    </svg>
)
IconEmail.displayName = 'IconEmail'
