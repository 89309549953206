import { layout } from '../../App.routing'

export const miscCollectionsSegment = layout.nestedComposition({
    type: 'cerberus-four',
    props: {
        stretchColumns: true,
    },
    contentAreas: {
        content1: [
            layout.component({
                type: 'oscar',
                props: {
                    sectionHeader: {
                        heading: 'Motoring',
                        headingUrl: '/lifestyle/motoring',
                    },
                    collectionSection: 'lifestyle',
                    heroCardType: 'portrait',
                    hasSeparators: true,
                    dataDefinitionArgs: {
                        type: 'listing',
                        topics: ['lifestyle/motoring'],
                        includeSubTopics: false,
                        paging: {
                            page: 1,
                            pageSize: 4,
                        },
                    },
                },
            }),
        ],
        content2: [
            layout.component({
                type: 'oscar',
                props: {
                    sectionHeader: {
                        heading: 'Technology',
                        headingUrl: '/technology',
                    },
                    collectionSection: 'technology',
                    heroCardType: 'portrait',
                    hasSeparators: true,
                    dataDefinitionArgs: {
                        type: 'curation',
                        name: 'technology',
                        offset: 0,
                        pageSize: 4,
                    },
                },
            }),
        ],
        content3: [
            layout.component({
                type: 'oscar',
                props: {
                    sectionHeader: {
                        heading: 'Competitions',
                        headingUrl: '/entertainment/competitions',
                    },
                    collectionSection: 'technology',
                    heroCardType: 'portrait',
                    hasSeparators: true,

                    dataDefinitionArgs: {
                        type: 'listing',
                        topics: ['entertainment/competitions'],
                        includeSubTopics: false,
                        paging: {
                            page: 1,
                            pageSize: 4,
                        },
                    },
                },
            }),
        ],
        content4: [
            layout.component({
                type: 'section-header',
                props: {
                    heading: 'Promotional',
                    hasBottomMargin: true,
                    invisibleHeaderBreakpoint: 'sm',
                },
            }),
            layout.component({
                type: 'curated-promotional-card',
                props: {
                    fixedRatio: '4:3',
                    hasBackground: true,
                    hasPadding: true,
                    mediaMode: 'edgeToEdge',
                    fontScale: 0.88,
                    showCTA: true,
                    cardOrientation: {
                        defaultLayout: 'landscape',
                        alternativeLayoutOptions: {
                            breakpoint: 'sm',
                            layout: 'portrait',
                        },
                    },
                    dataDefinitionArgs: {
                        type: 'active-promotion',
                        promotionSlotId: 'homepage-position-4',
                    },
                },
            }),
        ],
    },
})
