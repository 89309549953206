import { Product, WebLink } from '@news-mono/web-common'
import React from 'react'
import { ErrorPage } from '../ErrorPage'

export const text = (product: Product) => {
    switch (product) {
        case Product.SevenNews:
            return 'Sorry, the page you are looking for is not available'
        case Product.PerthNow:
            return (
                <React.Fragment>
                    Oops! Something went wrong but have no fear, the{' '}
                    <WebLink to="/">PerthNow homepage is here</WebLink>
                </React.Fragment>
            )
        default:
            return 'Uh oh, that page does not exist'
    }
}

export const PageNotFound = (props: { attribution: boolean }) => (
    <ErrorPage text={text} attribution={props.attribution} />
)
