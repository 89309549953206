import React, { FC } from 'react'
import {
    StyledArrow,
    StyledButton,
    StyledButtonInnerContainer,
    StyledSpinner,
} from './LiveEventJumpToTopButton.styled'
import { useTheme } from '@emotion/react'
import { Product } from '@news-mono/web-common'

export interface JumptToTopButtonProps {
    onClick: () => void
    inViewport: boolean
    isLoading: boolean
    newPostCount: number
    defaultLabel?: string
}

export const JumpToTopButton: FC<JumptToTopButtonProps> = ({
    onClick,
    inViewport,
    isLoading,
    newPostCount,
    defaultLabel = 'BACK TO TOP',
}) => {
    const showButton = inViewport || newPostCount > 0
    const theme = useTheme()

    const buttonLabel = () => {
        if (theme.kind === Product.TheNightly) {
            if (newPostCount === 0) {
                return defaultLabel
            } else {
                return 'NEW POSTS'
            }
        } else {
            switch (newPostCount) {
                case 0:
                    return 'Jump to top'
                case 1:
                    return '1 New Update'
                default:
                    return `${newPostCount} New Updates`
            }
        }
    }

    const Icon: FC = () =>
        newPostCount > 0 ? (
            <StyledSpinner rotate={isLoading} />
        ) : (
            <StyledArrow />
        )

    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.currentTarget.blur()
        onClick()
    }

    return (
        <StyledButton onClick={handleOnClick} show={showButton}>
            <StyledButtonInnerContainer>
                <Icon />
                <span>{buttonLabel()}</span>
            </StyledButtonInnerContainer>
        </StyledButton>
    )
}
