import {
    PublicationType,
    RequiredAccess,
    requiredAccessValue,
    useToggleState,
} from '@news-mono/web-common'
import React from 'react'

import {
    StyledCommentsIcon,
    StyledEditorialLabel,
    StyledHeadline,
    StyledHeadlineDiv,
    StyledHeadlineEditorialLabel,
    StyledHeadlineText,
    StyledKickerEditorialLabel,
    StyledPremiumIcon,
} from '../../cards/CardText/CardText.styled'
import { IconTypes, Kicker, KickerMode } from '../../cards/Kicker/Kicker'
import { EditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { appendToHeadline } from '../../__helpers/append-to-headline'
import { useProduct } from '../../__product/useProduct'
import { FontScales } from '../../__styling/settings/fontScale'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { StyledLiveStatus } from '../../SevenNewsV2/cards/ContentCard/ContentCard.styled'
import { StyledPulsatingDotLogo } from '../../SevenNewsV2/HeroCard/ArticleHeroCard/ArticleHeroCard.styled'

export interface CardHeadline {
    hasBackground?: boolean
    kickerMode?: KickerMode
    className?: string
    isLarge?: boolean
    fontScale: FontScales
    headlineText: string
    kickerText?: string
    publicationKind: PublicationType['kind']
    isSponsored?: boolean
    insideSponsoredCollection?: boolean
    editorialType?: EditorialType
    showVideoIcon?: boolean
    requiredAccess?: RequiredAccess
    showCommentsIcon?: boolean
    isLiveSportsTopic?: boolean
    headlineAsDiv?: boolean
    headlineAsHTML?: boolean
}
// eslint-disable-next-line no-redeclare
export const CardHeadline: React.FC<CardHeadline> = function CardHeadline(
    props,
) {
    const iconWidth = 16
    const iconPremiumHeight = 16
    const product = useProduct()
    const {
        fontScale,
        hasBackground,
        kickerText,
        kickerMode,
        headlineText,
        className,
        isLarge,
        publicationKind,
        editorialType,
        showVideoIcon,
        showCommentsIcon,
        requiredAccess,
        insideSponsoredCollection,
        isLiveSportsTopic,
        headlineAsDiv,
        headlineAsHTML,
    } = props

    const perthNowTheme = product === 'perthnow'

    const showKicker = perthNowTheme
        ? kickerMode !== 'hidden' &&
          !props.isSponsored &&
          publicationKind !== 'gallery'
        : (kickerMode !== 'hidden' && !props.isSponsored) ||
          (kickerMode !== 'hidden' &&
              product === 'thewest' &&
              insideSponsoredCollection === true) // show kicker in sponsored collection

    const showPremiumIcon =
        requiredAccess?.level === 'subscriber' ||
        requiredAccess?.level === 'registered'

    // Set Icon Types for Kicker
    const iconTypes: Array<IconTypes> = []
    showVideoIcon && iconTypes.push('video')
    showPremiumIcon && iconTypes.push('premium')
    publicationKind === 'gallery' && iconTypes.push('gallery')
    showCommentsIcon && iconTypes.push('comments')

    const toggles = useToggleState()

    return (
        <>
            {headlineAsDiv ? (
                <StyledHeadlineDiv fontScale={fontScale} className={className}>
                    {/* Editorial Label shown above the kicker mostly on PerthNow */}
                    {/* For sevennews badge need to positioned over card image  */}
                    {editorialType &&
                        product !== 'sevennews' &&
                        product !== 'thenightly' && (
                            <StyledEditorialLabel
                                type={editorialType}
                                cardHasBackground={hasBackground}
                                hasKicker={
                                    showKicker && kickerMode !== 'hidden'
                                }
                            />
                        )}
                    {showKicker &&
                        kickerMode !== 'hidden' &&
                        kickerMode?.kickerVisibility !== 'hidden' && (
                            <Kicker
                                size={
                                    kickerMode?.size || isLarge
                                        ? 'large'
                                        : 'default'
                                }
                                kickerPosition={kickerMode?.kickerPosition}
                                icons={kickerMode?.icons ?? iconTypes}
                                kickerVisibility={kickerMode?.kickerVisibility}
                                kickerType={kickerMode?.kickerType}
                                requiredAccess={requiredAccess}
                                kickerBackgroundColorOverrideForPN={
                                    kickerMode?.kickerBackgroundColorOverrideForPN
                                }
                            >
                                {/* Editorial Label shown on The West inline with kickers */}
                                {editorialType &&
                                    product !== 'sevennews' &&
                                    product !== 'thenightly' && (
                                        <StyledKickerEditorialLabel
                                            type={editorialType}
                                        />
                                    )}
                                {/* Sevennews Editorial Label left from headlinetext */}
                                {editorialType &&
                                    editorialType === EditorialType.Live &&
                                    product === 'sevennews' && (
                                        <StyledLiveStatus>
                                            <StyledPulsatingDotLogo />
                                            Live
                                        </StyledLiveStatus>
                                    )}
                                {kickerText}
                            </Kicker>
                        )}
                    <StyledHeadlineText
                        fontScale={fontScale}
                        className={className}
                        cardHasBackground={hasBackground}
                        isLarge={isLarge}
                    >
                        {/* Editorial Label shown on The West next to the title only on some places like the latest news page */}
                        {editorialType &&
                            product !== 'sevennews' &&
                            product !== 'thenightly' && (
                                <StyledHeadlineEditorialLabel
                                    type={editorialType}
                                />
                            )}
                        {showCommentsIcon &&
                            (kickerMode === 'hidden' ||
                                kickerMode?.kickerVisibility) && (
                                <StyledCommentsIcon kickerMode={kickerMode} />
                            )}
                        {showPremiumIcon &&
                            product !== 'thenightly' &&
                            (kickerMode === 'hidden' ||
                                kickerMode?.kickerVisibility) &&
                            requiredAccessValue(requiredAccess, {
                                subscriber: (
                                    <StyledPremiumIcon
                                        width={iconWidth}
                                        height={iconPremiumHeight}
                                        kickerMode={kickerMode}
                                    />
                                ),
                                anonymous: undefined,
                            })}

                        <span>
                            {isLiveSportsTopic
                                ? appendToHeadline(headlineText, editorialType)
                                : headlineText}
                        </span>
                    </StyledHeadlineText>
                </StyledHeadlineDiv>
            ) : (
                <StyledHeadline fontScale={fontScale} className={className}>
                    {/* Editorial Label shown above the kicker mostly on PerthNow */}
                    {/* For sevennews badge need to positioned over card image  */}
                    {editorialType &&
                        product !== 'sevennews' &&
                        product !== 'thenightly' && (
                            <StyledEditorialLabel
                                type={editorialType}
                                cardHasBackground={hasBackground}
                                hasKicker={
                                    showKicker && kickerMode !== 'hidden'
                                }
                            />
                        )}
                    {showKicker &&
                        kickerMode !== 'hidden' &&
                        kickerMode?.kickerVisibility !== 'hidden' && (
                            <Kicker
                                size={
                                    kickerMode?.size || isLarge
                                        ? 'large'
                                        : 'default'
                                }
                                kickerPosition={kickerMode?.kickerPosition}
                                icons={kickerMode?.icons ?? iconTypes}
                                kickerVisibility={kickerMode?.kickerVisibility}
                                kickerType={kickerMode?.kickerType}
                                requiredAccess={requiredAccess}
                                kickerBackgroundColorOverrideForPN={
                                    kickerMode?.kickerBackgroundColorOverrideForPN
                                }
                            >
                                {/* Editorial Label shown on The West inline with kickers */}
                                {editorialType &&
                                    product !== 'sevennews' &&
                                    product !== 'thenightly' && (
                                        <StyledKickerEditorialLabel
                                            type={editorialType}
                                        />
                                    )}
                                {kickerText}
                            </Kicker>
                        )}
                    <StyledHeadlineText
                        fontScale={fontScale}
                        className={className}
                        cardHasBackground={hasBackground}
                        isLarge={isLarge}
                    >
                        {/* Editorial Label shown on The West next to the title only on some places like the latest news page */}
                        {editorialType &&
                            product !== 'sevennews' &&
                            product !== 'thenightly' && (
                                <StyledHeadlineEditorialLabel
                                    type={editorialType}
                                />
                            )}
                        {showCommentsIcon &&
                            (kickerMode === 'hidden' ||
                                kickerMode?.kickerVisibility) && (
                                <StyledCommentsIcon kickerMode={kickerMode} />
                            )}
                        {showPremiumIcon &&
                            product !== 'thenightly' &&
                            (kickerMode === 'hidden' ||
                                kickerMode?.kickerVisibility) &&
                            requiredAccessValue(requiredAccess, {
                                subscriber: (
                                    <StyledPremiumIcon
                                        width={iconWidth}
                                        height={iconPremiumHeight}
                                        kickerMode={kickerMode}
                                    />
                                ),
                                anonymous: undefined,
                            })}

                        <span>
                            {isLiveSportsTopic ? (
                                appendToHeadline(headlineText, editorialType)
                            ) : headlineAsHTML ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: headlineText,
                                    }}
                                />
                            ) : (
                                headlineText
                            )}
                        </span>
                    </StyledHeadlineText>
                </StyledHeadline>
            )}
        </>
    )
}
CardHeadline.displayName = 'CardHeadline'
