import { useEffect, useState } from 'react'
import { webStoriesDebug } from '..'

export const useFetchStories = (url: string) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setIsLoading(true)
        fetch(url)
            .then((res) => res.json())
            .then((resData) => {
                setIsLoading(false)
                webStoriesDebug('fetching stories...', resData)
                setData(resData.stories)
            })
            .catch((err) => {
                throw new Error('Unable to fetch stories')
            })
    }, [url])

    return { data, isLoading }
}
