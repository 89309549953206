import loadable from '@loadable/component'
import { ConsentLevel, useConsentGiven } from '@news-mono/web-common'
import * as React from 'react'
import { createRenderTarget } from '../../../render-target'
import { ConsentFallback } from '../../../tracking/ConsentFallback'

export const LazyWebHorizontalCricketWidget = loadable(
    () => import('./HorizontalCricketWidget.web'),
)

const HorizontalCricketWidgetRenderTarget = createRenderTarget(
    'HorizontalCricketWidget',
    {
        web: LazyWebHorizontalCricketWidget,
        amp: null,
        rss: null,
        preview: null,
        app: null,
    },
)

export const HorizontalCricketWidget: React.FC = () => {
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.All,
    })

    if (!consentGiven) {
        return <ConsentFallback />
    }

    return <HorizontalCricketWidgetRenderTarget />
}
