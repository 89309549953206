import { Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getThemedSegment } from '../../home/get-themed-segment'
import { GetMainSectionFn } from '../custom-topic-routes'
import { getTopicSponsor } from '../get-topic-sponsor'

export const getSportRouteInfo: GetMainSectionFn = (sectionBuilder, store) => {
    const sportTopic: Topic = {
        id: 'sport',
        title: 'Sport',
        seoTitle: 'Sport',
        metadata: {},
    }

    const themeOverride = store?.getState().toggles[
        'the-west-grand-final-freo-collection-theme'
    ]
        ? 'grand-final-freo'
        : 'grand-final'

    return [
        getThemedSegment({
            featureToggle: 'the-west-grand-final-collection',
            layoutVersion: '8-card',
            curation: 'afl-grand-final',
            themeOverride,
            cardExtensionProps: {
                eightCardExtension: {
                    kind: '8-card',
                    thorProps: {
                        unsetFeatureContainerBackground: true,
                        layouts: '8-card',
                    },
                },
            },
        }),
        layout.component({
            type: 'breadcrumb',
            props: {
                topic: sportTopic,
                sponsor: getTopicSponsor(sportTopic),
                enableHeaderTag: true,
                verticalSpacing: 'sm',
            },
        }),
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'AFL',
                    headingUrl: '/sport/afl',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/afl'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Cricket',
                    headingUrl: '/sport/cricket',
                    navLinks: [
                        {
                            text: 'Perth Scorchers',
                            link: '/sport/perth-scorchers',
                        },
                        {
                            text: 'Live Scores',
                            link: '/sport/cricket/live-scores',
                        },
                    ],
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/cricket'],
                    publicationKind: 'article',
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'WAFL',
                    headingUrl: '/sport/wafl',
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'wafl',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Basketball',
                    headingUrl: '/sport/basketball',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/basketball'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Soccer',
                    headingUrl: '/sport/soccer',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/soccer'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'NRL',
                    headingUrl: '/sport/rugby-league',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/rugby-league'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Rugby Union',
                    headingUrl: '/sport/rugby-union',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/rugby-union'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Horse Racing',
                    headingUrl: '/sport/horse-racing',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/horse-racing'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Netball',
                    headingUrl: '/sport/netball',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/netball'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Tennis',
                    headingUrl: '/sport/tennis',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/tennis'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Motorsport',
                    headingUrl: '/sport/motorsport',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/motorsport'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Golf',
                    headingUrl: '/sport/golf',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/golf'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
