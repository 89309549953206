import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
    FederalElectionWidgetCollection,
    FederalElectionWidgetCollectionProps,
} from './FederalElectionWidgetCollection'

const queryClient = new QueryClient()

export const FederalElectionWidgetCollectionRegistration =
    createRegisterableComponent(
        'federal-election-widget-collection',
        (props: FederalElectionWidgetCollectionProps) => {
            return (
                <QueryClientProvider client={queryClient}>
                    <FederalElectionWidgetCollection layout={props.layout} />
                </QueryClientProvider>
            )
        },
    )
