import React from 'react'

interface IconLockProps {
    title?: string
    className?: string
}

export const IconLock: React.FC<IconLockProps> = ({ title, className }) => {
    return (
        <svg
            width="18"
            height="22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <title>{title} || Lock</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 9.5a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3v-3a6 6 0 1 1 12 0v3Zm-6-7a4 4 0 0 1 4 4v3H5v-3a4 4 0 0 1 4-4Zm6 9H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Z"
                fill="currentColor"
            />
        </svg>
    )
}
