import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import React from 'react'
import { IconChevronLeftTN } from '../../../icons/IconChevronLeftTN/IconChevronLeftTN'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, TheNightlyTheme } from '../../../__styling'

type ContextualBackProps = {
    to: string
    children: string
    onClick?: () => void
}

export const ContextualBack = ({
    to,
    children,
    onClick,
}: ContextualBackProps) => {
    return (
        <div onClick={onClick}>
            <ContextualBackContainer to={to}>
                <IconChevronLeftTN />
                {children}
            </ContextualBackContainer>
        </div>
    )
}

export const ContextualBackContainer = styled(WebLink)(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(4),

        textDecoration: 'inherit',
        color: 'inherit',
    },
    textMixin(theme as TheNightlyTheme, 'body-alt'),
])
