import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../App.routing'

const westLiveSocialImage = require('./assets/TWL-LandingPage-Social-Image.jpg')

function getSocialImageMeta(src: string, height: string, width: string) {
    return [
        {
            property: 'og:image',
            content: src,
        },
        { name: 'twitter:image', content: src },
        {
            property: 'og:image:height',
            content: height,
        },
        {
            property: 'og:image:width',
            content: width,
        },
        {
            property: 'og:image:alt',
            content:
                'A picture of Ben O’Shea and Matt Tinney wearing headphones. Join them for the West Live, on air from 7am weekdays.',
        },
        {
            property: 'twitter:image:alt',
            content:
                'A picture of Ben O’Shea and Matt Tinney wearing headphones. Join them for the West Live, on air from 7am weekdays',
        },
    ]
}

export const westlive: StaticRoutes<TheWestSection> = {
    '/the-west-live': ({ getAdTargeting, config }) => {
        const canonicalUrl = config.publicUrl + '/the-west-live'

        return {
            kind: 'page',
            heading: 'The West Live',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: 'Perth news, The West Live podcast',
                description:
                    'Perth news that is live, local and a little loose. These are the stories peppered with a distinctive West Australian flavour, made especially for WA.',
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta(westLiveSocialImage, '630', '1200'),
            },
            socialMeta: {
                title: `The West Live podcast with Ben O'Shea`,
                description:
                    'It’s live, it’s local, it’s Perth news as you’ve never heard it before.',
            },
            adTargeting: getAdTargeting(
                'the-west-live',
                'default',
                'the-west-live',
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'zeus',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                        hasBackgroundFill: true,
                        stretchSelf: true,
                    },
                    contentAreas: {
                        main: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    verticalSpacing: 'md',
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'the-west-live-player-page',
                                            props: {
                                                adUnit: {
                                                    hiddenUntilLoaded: false,
                                                    noticePosition:
                                                        'below-center',
                                                    padding: [
                                                        metrics.thewest.margins
                                                            .md,
                                                        0,
                                                        0,
                                                        0,
                                                    ],
                                                    slot: {
                                                        id: 'leaderboard-one',
                                                        size: 'leaderboard768Above',
                                                    },
                                                    adType: 'inline',
                                                },
                                                adUnitMobile: {
                                                    hiddenUntilLoaded: false,
                                                    noticePosition:
                                                        'below-center',
                                                    padding: [
                                                        metrics.thewest.margins
                                                            .md,
                                                        0,
                                                        0,
                                                        0,
                                                    ],
                                                    slot: {
                                                        id: 'mrec-one',
                                                        size: 'mrec768below',
                                                    },
                                                    adType: 'inline',
                                                },
                                                twlLogoAdUnit: {
                                                    hiddenUntilLoaded: false,
                                                    noticePosition: 'none',
                                                    padding: [
                                                        metrics.thewest.margins
                                                            .md,
                                                        0,
                                                        0,
                                                        0,
                                                    ],
                                                    slot: {
                                                        id: 'twl-landing',
                                                        size: 'theWestLiveLogo',
                                                    },
                                                    adType: 'inline',
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'iframe',
                                props: {
                                    link: 'https://omny.fm/shows/the-west-live/playlists/podcast/embed?style=cover&image=1&share=1&download=0&description=1&subscribe=1&playlistimages=1&playlistshare=1&foreground=2b2c2d&background=f4f5f5&highlight=043de2',
                                    height: 800,
                                    marginBottom: 'lg',
                                },
                            }),
                            layout.component({
                                type: 'link-banner',
                                props: {
                                    url: '/manage-email-preferences',
                                    message:
                                        'Get the latest news from thewest.com.au in your inbox.',
                                    cta: 'Sign up for our emails',
                                    verticalGutters: ['md', 'lg'],
                                },
                            }),
                            layout.component({
                                type: 'related-content',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'home',
                                        offset: 0,
                                        pageSize: 3,
                                    },
                                },
                            }),
                        ],
                        sidebar: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'sidebar-top',
                                        size: 'desktopMrecHalfPage',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset: 350,
                                    fillContainer: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                padding: [
                                                    0,
                                                    0,
                                                    metrics.thewest.margins.md,
                                                    0,
                                                ],
                                                slot: {
                                                    id: 'sidebar-middle',
                                                    size: 'desktopMrec',
                                                    pageType: 'static',
                                                },
                                                adType: 'inline',
                                            },
                                        }),

                                        layout.component({
                                            type: 'juliet',
                                            props: {
                                                cardType: {
                                                    type: 'landscape',
                                                    format: 'landscape-smallImage',
                                                },
                                                removeHorizontalGutters: true,
                                                kickerMode: 'hidden',
                                                contentIsTangential: true,
                                                sectionHeader: {
                                                    heading: 'Top Stories',
                                                },
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'home',
                                                    offset: 0,
                                                    pageSize: 3,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
