import { Product } from '@news-mono/web-common'
import React from 'react'
import {
    TimestampFormat,
    TimestampLabel,
} from '../../../typography/Timestamp/Timestamp'
import { useProduct } from '../../../__product/useProduct'
import { StyledTimestamp } from '../Byline.styled'

interface BylineTimestampProps {
    timestamp: string | undefined
    timestampLabel?: TimestampLabel
    timestampFormat?: TimestampFormat
}

export const BylineTimestamp: React.FC<BylineTimestampProps> = ({
    timestamp,
    timestampLabel,
    timestampFormat,
}) => {
    const product = useProduct()

    if (!timestamp) {
        return null
    }

    const isSevenNews = product === Product.SevenNews

    return (
        <StyledTimestamp
            iconVisible={isSevenNews}
            timestamp={timestamp}
            timestampFormat={timestampFormat}
            timestampLabel={timestampLabel}
        />
    )
}
