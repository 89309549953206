import React from 'react'
import { StyledPlayButton, StyledPlayIcon } from './PlayButton.styled'

export interface PlayButtonProps {
    /** When true, play button will be big and centered */
    isCentered: boolean
    /* onclick wont be set when isIcon is true */
    onClick?: React.MouseEventHandler<any>
    /* is small is not to be used when isCentered is false, results in a small icon */
    isSmall?: boolean
    /* renders a button if omitted or a div if true */
    isIcon?: boolean
    /* currently affects The West and PerthNow (DPT-378) */
    isInversed?: boolean
    isBauhaus?: boolean
}

export const PlayButton: React.FC<PlayButtonProps> = ({
    isIcon,
    isSmall,
    onClick,
    isCentered,
    isInversed,
    isBauhaus,
}) => {
    const PlayButtonComponent = isIcon ? StyledPlayIcon : StyledPlayButton
    const title = isIcon ? 'Video' : 'Play Video'
    return (
        <PlayButtonComponent
            isIcon={isIcon}
            title={title}
            isSmall={isSmall}
            isCentered={isCentered}
            aria-live={isIcon ? undefined : 'polite'}
            aria-disabled={isIcon ? undefined : 'false'}
            onClick={isIcon ? undefined : onClick}
            isInversed={isInversed}
            isBauhaus={isBauhaus}
        >
            <span>{title}</span>
        </PlayButtonComponent>
    )
}
PlayButton.displayName = 'PlayButton'
