import React from 'react'
import { StyledMessengerButton } from '../../buttons/MessengerButton/MessengerButton.styled'
import { IconMessenger } from '../../icons/IconMessenger/IconMessenger'

export interface MessengerButtonProps {
    link: string
}

export const MessengerButton: React.FC<MessengerButtonProps> = ({ link }) => {
    return (
        <StyledMessengerButton to={link}>
            <IconMessenger />
            Send us a message
        </StyledMessengerButton>
    )
}
MessengerButton.displayName = 'MessengerButton'
