import {
    AdDefinition,
    createRegisterableComponent,
} from '@news-mono/web-common'
import React from 'react'
import { AmpStickyAd } from './AmpStickyAd'

export type AmpStickyAdUnitRouteProps = { slot: AdDefinition }

export const AmpStickyAdUnitRegistration = createRegisterableComponent(
    'amp-sticky-ad-unit',
    (props: AmpStickyAdUnitRouteProps, services) => {
        return <AmpStickyAd adState={services.adState} unitId={props.slot.id} />
    },
)
