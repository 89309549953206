import styled from '@emotion/styled'
import { breakpoint } from '../../__styling'
import { themedValue } from '../../__styling/themed-value'
import {
    CardTextContainer,
    StyledCell,
    StyledHeroCardMedia,
} from '../HeroCard/HeroCard.styled'
import { VideoPositionLayouts } from './HeroVideoCard'

interface VideoPositionProps {
    videoPosition?: VideoPositionLayouts
}

export const StyledHeroVideoCardCell = styled(StyledCell)<VideoPositionProps>(
    (props) => ({
        [breakpoint('sm')]: {
            ':nth-child(1)': {
                display: 'flex',
                width: props.videoPosition === 'video-on-left' ? '55%' : '45%',

                [`${StyledHeroCardTextContainer}, ${StyledHeroCardMedia}`]: {
                    width: '100%',
                },
            },
            ':nth-child(2)': {
                width: props.videoPosition === 'video-on-left' ? '45%' : '55%',

                [`${StyledHeroCardMedia}, ${StyledHeroCardTextContainer}`]: {
                    width: '100%',
                },
            },
        },
    }),
)

export const StyledHeroCardTextContainer = styled(
    CardTextContainer,
)<VideoPositionProps>((props) => ({
    paddingLeft: themedValue(props.theme, {
        thewest:
            props.videoPosition === 'video-on-left'
                ? props.theme.margins.md
                : 0,
        fallback: props.theme.margins.md,
    }),
}))
