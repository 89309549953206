import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { ThemeMarginKeys, ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

type Gutters = boolean | ThemeMarginKeys

export interface StyledFeatureCompositionProps {
    hasTopSpacing?: boolean
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: Gutters
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: Gutters
    verticalSpacing?: ThemeMarginKeys
    horizontalGap?: ThemeMarginKeys
}

interface ThemedMetrics {
    margins: ThemeMargins
}

const sidebarWidth = 300

const getGutterSize = (
    themedMetrics: ThemedMetrics,
    gutter?: Gutters,
): string | number => {
    return gutter === false
        ? 0
        : calcRem(
              typeof gutter === 'string'
                  ? themedMetrics.margins[gutter]
                  : themedMetrics.margins.outerMargin,
          )
}

export const StyledFeatureComposition = styled(
    'div',
)<StyledFeatureCompositionProps>(
    ({
        theme,
        verticalSpacing,
        horizontalGutters,
        verticalGutters,
        hasBackgroundFill,
        hasTopSpacing,
        horizontalGap,
    }) => ({
        margin: '0 auto',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '100%',
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingLeft: getGutterSize(theme, horizontalGutters),
        paddingRight: getGutterSize(theme, horizontalGutters),
        paddingTop: hasTopSpacing ? getGutterSize(theme, verticalGutters) : 0,
        paddingBottom: getGutterSize(theme, verticalGutters),
        maxWidth: calcRem(1220),
        backgroundColor: themedValue(theme, {
            thewest: undefined,
            fallback: hasBackgroundFill ? colors.white : undefined,
        }),

        [breakpoint('md')]: {
            gridColumnGap: horizontalGap
                ? calcRem(theme.margins[horizontalGap])
                : 0,
            // prettier-ignore
            gridTemplateColumns: `minmax(40px, 1fr) minmax(300px, 600px) minmax(${calcRem(sidebarWidth,)}, 1fr)}`,
        },
    }),
)
StyledFeatureComposition.displayName = 'StyledFeatureComposition'

export const StyledMain = styled('div')<{ hasBackgroundFill?: boolean }>(
    ({ theme, hasBackgroundFill }) => ({
        display: 'block',
        width: '100%',
        position: 'relative',
        backgroundColor: themedValue(theme, {
            thewest: hasBackgroundFill ? colors.white : undefined,
            fallback: undefined,
        }),
    }),
)
StyledMain.displayName = 'StyledMain'

export const StyledSidebar = styled('aside')({
    display: 'none',
    transform: 'translateZ(0)',

    [breakpoint('md')]: {
        display: 'block',
        boxSizing: 'content-box',
    },
})
StyledSidebar.displayName = 'StyledSidebar'

export const StyledAside = styled('aside')({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',

    [breakpoint('md')]: {
        display: 'block',
        justifySelf: 'flex-end',
        justifyContent: 'flex-start',
        marginBottom: 0,
    },
})
StyledAside.displayName = 'StyledAside'
