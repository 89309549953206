import { BreachScreenType, CartEvent, SswPackage, isSswPackage } from "@news-mono/web-common";
import { getAttribution } from "../../errors/ErrorPage";
import { Theme } from "@emotion/react";
import { ISubscriptionPackage, isSubscriptionPackage } from "../../user-registration/breach/common";

export const TEACHERS_OFFER = 'teachers-offer'
export const UNIVERSITY_OFFER = 'university-offer'
export type CampaignModifier = typeof TEACHERS_OFFER | typeof UNIVERSITY_OFFER

//The default quantity used for all GA4 events
export const DEFAULT_CART_QUANTITY = 4;

export type SwgPackageOptions = {
    quantity?: number
    promoPrice?: string
    originalPrice?: string
    modifier?: string
    discount?: number
    coupon?: string
    varient?: 'swg' | 'native'
}

/**
 * This will generate a new cart event payload, which is used to send to the GTM layer.
 *
 * @param packageInfo - the package to remove from cart, this can be obtained from a getSswPackageByName(string)
 * @param theme - the theme to apply, used for brand identification
 * @param options - any page price options, including quantity
 * @param breachScreenType - only when passed through is this provided
 */
export function getCartPayload(
    packageInfo: ISubscriptionPackage | SswPackage | undefined,
    mastheadTheme: Theme | string,
    options?: SwgPackageOptions,
    breachScreenType?: BreachScreenType

): CartEvent['payload'] | undefined {
    return convertPackageToPayload(packageInfo, mastheadTheme, options, breachScreenType)
}


// Internal function to convert from variables to a payload
function convertPackageToPayload(
    packageInfo: ISubscriptionPackage | SswPackage | undefined,
    mastheadTheme: Theme | string,
    options?: SwgPackageOptions,
    breachScreenType?: BreachScreenType
): CartEvent['payload'] | undefined {
    const item_brand = typeof(mastheadTheme) === 'string' ? mastheadTheme :
        getAttribution(mastheadTheme.kind, mastheadTheme.section) || 'The West Australian'
    const quantity = options?.quantity || DEFAULT_CART_QUANTITY
    let item_id = undefined
    let item_name = undefined
    let price = undefined
    let promoPrice = undefined
    let discount = undefined
    let coupon = undefined

    if(isSswPackage(packageInfo)) { // Check if it's an SSW package, if so set variables
        const sswPackageInfo = packageInfo as SswPackage

        item_id = sswPackageInfo.stdRateCode
        item_name = sswPackageInfo.title
        price = sswPackageInfo.weeklyPrice
        promoPrice = options?.promoPrice === undefined? 0 : getWeeklyPrice(options?.modifier);
        discount = options?.discount || 0
        coupon = options?.coupon || ""
    } else if (isSubscriptionPackage(packageInfo)) { // else check if it's a subscription package, if so set variables
        const subscriptionPackage = packageInfo as ISubscriptionPackage

        item_id = subscriptionPackage.rateCode
        item_name = subscriptionPackage.heading
        price = subscriptionPackage.weeklyPrice
        promoPrice = subscriptionPackage.promoPrice || 0
        discount = subscriptionPackage.discount || 0
        coupon = subscriptionPackage.offerCode || ""
    } else {
        return undefined // else type not recognised, return undefined
    }

    // now using the set values, create a payload
    return {
        currency: 'AUD',
        value: (promoPrice > 0? promoPrice : price) * quantity,
        items: [{
            item_id: item_id,
            item_brand: item_brand,
            item_name: item_name,
            price: price,
            promo_price: promoPrice,
            discount: discount,
            coupon: coupon,
            quantity: quantity,
            item_variant: options?.varient || 'native'
        }],
        breachScreenType: breachScreenType || undefined
    }
}


// This function only relates to SwG products that have a landing page on news-mono
function getWeeklyPrice(modifier?: string): number {
    switch (modifier) {
        case UNIVERSITY_OFFER:
            return 0.25
        case TEACHERS_OFFER:
            return 0
        default:
            break
    }
    return 0
}
