import { useTheme } from '@emotion/react'
import {
    addUrlParams,
    Product,
    ShareOption,
    SharingEvent,
} from '@news-mono/web-common'
import React from 'react'
import { AmpSharing } from '../../buttons/Sharing/Sharing.amp'
import {
    FacebookStyledIcon,
    StyledButton,
    StyledIcon,
    StyledSharing,
} from '../../buttons/Sharing/Sharing.styled'
import { getButtonOnClick } from '../../buttons/Sharing/utils/get-button-on-click'
import { getIconComponent } from '../../buttons/Sharing/utils/get-icon-component'
import { createRenderTarget } from '../../render-target'
import { useSectionMetaInfo } from '../../__helpers/use-section-meta'

export interface SharingProps {
    /** When the loading flag is passed, the sharing icons will be
     * rendered but not be responsive, even if an invalid url is passed
     */
    loading?: boolean
    url: string
    text: string
    className?: string
    shareOptions: ShareOption[]
    isGhostStyle?: boolean
    isSticky?: boolean
    layout?: 'stacked-md-desktop'
    onEvent: (event: SharingEvent) => void
    isBauhaus?: boolean
    customCampaign?: string
    publicationId?: string
    postId?: string
}

// Render the AMP Sharing component
const AmpSharingTarget: React.FC<SharingProps> = (props) => {
    const sectionMeta = useSectionMetaInfo()
    const theme = useTheme()

    if (!props.loading && !props.url) {
        return null
    }

    if (theme.kind === Product.SevenNews && props.isBauhaus) {
        return WebSharing(props)
    }

    // Define custom AMP Sharing options
    const shareOptions =
        theme.kind === Product.SevenNews
            ? ['facebook', 'twitter', 'email']
            : ['facebook', 'twitter', 'email', 'whatsapp', 'linkedin', 'sms']

    return (
        <StyledSharing className={props.className}>
            {shareOptions.map((shareOption, index) => {
                const shareUrl = addUrlParams(props.url, [
                    ['utm_campaign', 'share-icons'],
                    ['utm_source', shareOption],
                    [
                        'utm_medium',
                        ['facebook', 'twitter'].indexOf(shareOption) !== -1
                            ? 'social'
                            : shareOption,
                    ],
                    ['tid', `${Date.now()}`],
                ])

                const combinedShareMsg = props.text + ' ' + shareUrl

                if (shareOption !== 'clipboard') {
                    return (
                        <AmpSharing
                            key={index}
                            class="amp-sharing-button--rounded" // does not convert className to class, so needs to use class
                            type={shareOption}
                            data-param-text={
                                shareOption === 'whatsapp'
                                    ? combinedShareMsg
                                    : props.text // whatsapp requires the text and URL in data-param-text
                            }
                            data-param-url={shareUrl}
                            data-param-app_id={
                                shareOption === 'facebook'
                                    ? sectionMeta.FacebookAppId
                                    : undefined
                            }
                            width={30}
                            height={30}
                        />
                    )
                }

                return null
            })}
        </StyledSharing>
    )
}

// Render the web sharing component
const WebSharing: React.FC<SharingProps> = (props) => {
    const sectionMeta = useSectionMetaInfo()
    if (!props.loading && !props.url) {
        return null
    }

    return (
        <StyledSharing
            className={props.className}
            isSticky={props.isSticky}
            layout={props.layout}
        >
            {props.shareOptions.map((shareOption, index) => {
                const iconComponent = getIconComponent(shareOption)

                const Icon = iconComponent
                    ? shareOption !== 'facebook'
                        ? StyledIcon.withComponent(iconComponent)
                        : FacebookStyledIcon.withComponent(iconComponent)
                    : undefined
                const gtmHook = `gtm-${shareOption}-share-button`

                if (!Icon) {
                    return null
                }
                return (
                    <StyledButton
                        key={shareOption + index}
                        shareOption={shareOption}
                        isGhostStyle={props.isGhostStyle}
                        className={gtmHook}
                        onClick={() => {
                            if (props.loading) {
                                return
                            }

                            props.onEvent({
                                type: 'shareButton.click',
                                originator: 'Sharing',
                                payload: {
                                    shareType: shareOption,
                                    publicationId: props?.publicationId,
                                    postId: props?.postId,
                                },
                            })
                            const onClick = getButtonOnClick(
                                shareOption,
                                props.url,
                                props.text,
                                sectionMeta.FacebookAppId,
                                props.customCampaign,
                            )

                            onClick()
                        }}
                    >
                        <Icon />
                    </StyledButton>
                )
            })}
        </StyledSharing>
    )
}
WebSharing.displayName = 'WebSharing'

export const Sharing = createRenderTarget<SharingProps>('Sharing', {
    web: WebSharing,
    amp: AmpSharingTarget,
    rss: null,
    preview: WebSharing,
    app: null,
})
Sharing.displayName = 'Sharing'
