import { useTheme } from '@emotion/react'
import {
    ConsentLevel,
    isServerEnvironment,
    Product,
    ScriptLoadResult,
    useFeature,
    useScriptWithConsent,
} from '@news-mono/web-common'
import React, { useCallback, useRef, useState } from 'react'
import { useProduct } from '../../__product/useProduct'
import debug from 'debug'

let hasTaboolaLoaded = false

export const TaboolaContext = React.createContext<null | {
    result: ScriptLoadResult
    flushQueue: (pageId: string) => void
    newPage: (pageId: string, command: TaboolaLocationCommand) => void
    addPlacement: (pageId: string, command: TaboolaPlacementCommand) => void
}>(null)

const taboolaProductName = {
    sevennews: '7news',
    perthnow: 'perthnow',
    thewest: 'thewest',
    thenightly: 'thenightly',
}

export function getTaboolaPublisher(product: Product, section: string) {
    if (product === 'thewest' && section !== 'default') {
        return 'sevenwestmedia-network'
    } else if (section === 'community-news' || section === 'local-news') {
        return 'sevenwestmedia-cng'
    } else {
        return `sevenwestmedia-${taboolaProductName[product]}`
    }
}

export interface TaboolaPropsScript {
    ampPosition?: number
}

export const TaboolaScriptProvider: React.FC<TaboolaPropsScript> = ({
    children,
}) => {
    const product = useProduct()
    const { section } = useTheme()

    // Metadata setup

    let publisher: string
    if (isServerEnvironment() || !window.taboolaPublisher) {
        // Assumption: all of the sections on the west besides default are regionals
        publisher = getTaboolaPublisher(product, section)

        if (!isServerEnvironment()) {
            window.taboolaPublisher = publisher
        }
    } else {
        publisher = window.taboolaPublisher
    }

    // Script loading
    const [result, error] = useScriptWithConsent({
        consentRequiredLevel: ConsentLevel.Analytics | ConsentLevel.Advertising,
        consentMet: {
            src: `//cdn.taboola.com/libtrc/${publisher}/loader.js`,
        },
    })

    const logger = debug('_taboola:script-provider')
    const flushNeeded = useRef(false)
    const lastNotifiedPage = useRef<string | null>(null)

    React.useEffect(() => {
        if (window._taboola === undefined) {
            window._taboola = []
            // window.performance.mark is not a function in tests and unsupported in older browsers
            if (
                process.env.NODE_ENV !== 'test' &&
                typeof window.performance?.mark === 'function'
            ) {
                window.performance.mark('tbl_ic')
            }
        }
    }, [])

    const context = {
        result,
        newPage: useCallback(
            (page: string, command: TaboolaLocationCommand) => {
                if (result !== ScriptLoadResult.Success) {
                    return
                }
                const isInitialPage = lastNotifiedPage.current === null

                const isNewPage = lastNotifiedPage.current !== page

                const shouldSendLocationCommand = isNewPage || isInitialPage

                const shouldSendNewPageCommand =
                    shouldSendLocationCommand && !isInitialPage

                if (shouldSendNewPageCommand) {
                    const command = { notify: 'newPageLoad' }
                    window._taboola.push(command)
                    logger?.('[sent] TaboolaNotifyNewPageCommand', command, {
                        page,
                    })
                } else {
                    logger?.(
                        '[skipped] TaboolaNotifyNewPageCommand',
                        {
                            reason: isInitialPage
                                ? 'not needed for initial pageload'
                                : 'not needed at this time',
                        },
                        { page },
                    )
                }

                if (shouldSendLocationCommand) {
                    window._taboola.push(command)
                    logger?.('[sent] TaboolaLocationCommand', command, { page })
                    lastNotifiedPage.current = page
                } else {
                    logger?.(
                        '[skipped] TaboolaLocationCommand',
                        { reason: 'already sent for this page' },
                        { page },
                    )
                }
            },
            [result, logger],
        ),
        addPlacement: useCallback(
            (page: string, command: TaboolaPlacementCommand) => {
                if (result !== ScriptLoadResult.Success) {
                    return
                }
                logger?.('[sent] TaboolaPlacementCommand', command, { page })
                window._taboola.push(command)
                flushNeeded.current = true
            },
            [result, logger],
        ),
        flushQueue: useCallback(
            (page: string) => {
                if (result !== ScriptLoadResult.Success) {
                    return
                }
                setTimeout(() => {
                    if (flushNeeded.current) {
                        const command = { flush: true }
                        window._taboola.push(command)
                        flushNeeded.current = false
                        logger?.('[sent] TaboolaFlushCommand', command, {
                            page,
                        })
                    } else {
                        logger?.(
                            '[skipped] TaboolaFlushCommand',
                            { reason: 'not needed' },
                            { page },
                        )
                    }
                }, (Math.random() + 0.5) * 10)
            },
            [result, logger],
        ),
    }

    React.useEffect(() => {
        if (result === ScriptLoadResult.Success) {
            if (!hasTaboolaLoaded) {
                hasTaboolaLoaded = true
            }
        }
    }, [result, error])

    return (
        <TaboolaContext.Provider value={context}>
            {children}
        </TaboolaContext.Provider>
    )
}
TaboolaScriptProvider.displayName = 'Taboola'

export default TaboolaScriptProvider
