import React from 'react'
import { StyledTradingUpCard } from './TWStaticTradingUpCard.styled'

export const TWStaticTradingUpCard: React.FC<{
    isStakeBannerActive: boolean
}> = (props) => {
    const promo = require('./images/promo.jpg')
    const stakePromo = require('./images/stakePromo.jpg')

    return (
        <StyledTradingUpCard>
            <img
                src={props.isStakeBannerActive ? stakePromo : promo}
                alt="Trading Up - Episodes drop afternoons Monday to Friday"
                role=""
            />
        </StyledTradingUpCard>
    )
}
