import {
    CardEvents,
    CollectionEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { Juliet } from '../../../collections/Juliet/Juliet'

interface TheNightlyInlineRelatedContentCardsProps {
    items: MaybeLoaded<PublicationCardItem[]>
    expectedCards: number
    onEvent: (event: CardEvents | CollectionEvent) => void
    openInternalInNewWindow?: boolean
}
export const TheNightlyInlineRelatedContentCards: React.FC<TheNightlyInlineRelatedContentCardsProps> =
    (props) => {
        return (
            <Juliet
                onEvent={props.onEvent}
                expectedCards={props.expectedCards}
                hideByline={false}
                noHorizontalDividers={true}
                hideLastDivider={true}
                fixedRatios={['16:9']}
                cardType={{
                    type: 'landscape',
                    format: 'landscape-common',
                }}
                items={props.items}
                removeHorizontalGutters={true}
            />
        )
    }
