import H from 'history'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { DataLoaderState } from 'react-ssr-data-loader'
import { Logger, LogObject } from 'typescript-log'

export interface RouteState {
    [key: string]: any
}

export interface IRouteCache {
    getOrLoad: <T>(
        resourceType: string,
        key: string,
        /** Included as log context, useful when cache debug logs are enabled */
        context: Record<string, unknown>,
        load: () => Promise<T> | T,
    ) => Promise<T> | T

    clear: () => void
}

export const NoRouteCache: IRouteCache = {
    getOrLoad: (_, __, ___, load) => load(),
    clear: () => {},
}

export interface PAGE_DATA {
    [cacheKey: string]: {
        data: {
            hasData: boolean
            result?: any
        }
    }
}

declare const PAGE_DATA: PAGE_DATA

function getLocationKey(location: H.Location): string {
    return (
        location.key ||
        `${location.pathname}?${location.search}#${location.hash}`
    )
}

export const RouteCacheContext = React.createContext<IRouteCache>(NoRouteCache)

export class RouteCachePerRoute implements IRouteCache {
    constructor(private log: Logger, private maxCacheSize = 5) {}
    /** Stores the location key */
    backStack = new Set<string>()
    /** Stores the location key */
    forwardStack = new Set<string>()
    states: Map<string, RouteState> = new Map()
    currentLocationKey?: string

    // Little helper to help with logging in a consistent manner
    message(logObj: LogObject, msg: string) {
        this.log.debug({ logObj }, `route-cache: ${msg}`)
    }

    getOrLoad<T>(
        resourceType: string,
        cacheKey: string,
        context: Record<string, unknown>,
        load: () => Promise<T> | T,
    ) {
        const routeState =
            this.currentLocationKey && this.states.get(this.currentLocationKey)

        if (this.currentLocationKey && routeState && routeState[cacheKey]) {
            this.message(
                {
                    locationKey: this.currentLocationKey,
                    resourceType,
                    cacheKey,
                    context,
                },
                'cache hit',
            )
            return routeState[cacheKey]
        }

        this.message(
            {
                locationKey: this.currentLocationKey,
                resourceType,
                cacheKey,
                context,
            },
            'cache miss, loading',
        )

        const promiseOrValue = load()

        if (this.currentLocationKey) {
            const locationKey = this.currentLocationKey
            if (Promise.resolve(promiseOrValue) === promiseOrValue) {
                return Promise.resolve(promiseOrValue).then((loaded) => {
                    this.persistValue(locationKey, cacheKey, loaded)
                    return loaded
                })
            }

            this.persistValue(this.currentLocationKey, cacheKey, promiseOrValue)
        }

        return promiseOrValue
    }

    persistValue(locationKey: string, key: string, value: any) {
        this.message({ locationKey, key }, 'loaded data, persisting')

        this.states.set(locationKey, {
            ...this.states.get(locationKey),
            [key]: value,
        })

        if (this.states.size >= this.maxCacheSize) {
            const oldestStateKey = Array.from(this.states.keys())[0]
            this.states.delete(oldestStateKey)
        }
    }

    clear() {
        this.states.clear()
    }
}

export const UpdateRouteCachePerLocation: React.FC<{
    pageData: DataLoaderState | undefined
}> = ({ pageData }) => {
    const routeCache = React.useContext(RouteCacheContext)
    const location = useLocation()
    const locationKey = getLocationKey(location)
    const history = useHistory()
    const previousLocationKey = React.useRef<string | undefined>(undefined)

    if (routeCache instanceof RouteCachePerRoute) {
        previousLocationKey.current = routeCache.currentLocationKey
        routeCache.currentLocationKey = locationKey
    }

    React.useEffect(() => {
        if (typeof pageData === 'undefined') {
            return
        }

        for (const cacheKey in pageData) {
            const dataLoaderState = pageData[cacheKey]

            if (!dataLoaderState.data || !dataLoaderState.data.hasData) {
                return
            }

            if (routeCache instanceof RouteCachePerRoute) {
                routeCache.persistValue(
                    locationKey,
                    cacheKey,
                    dataLoaderState.data.result,
                )
            }
        }

        // On mount is the desired effect here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (
            routeCache instanceof RouteCachePerRoute &&
            previousLocationKey.current !== locationKey
        ) {
            if (history.action === 'PUSH') {
                if (previousLocationKey.current) {
                    routeCache.backStack.add(previousLocationKey.current)
                }
                routeCache.forwardStack.clear()
            }

            // Pop covers forward and back history
            if (history.action === 'POP') {
                // Navigated back
                if (routeCache.backStack.has(locationKey)) {
                    routeCache.backStack.delete(locationKey)

                    if (previousLocationKey.current) {
                        routeCache.forwardStack.add(previousLocationKey.current)
                    }
                }

                // Navigated forward
                if (routeCache.forwardStack.has(locationKey)) {
                    routeCache.forwardStack.delete(locationKey)

                    if (previousLocationKey.current) {
                        routeCache.backStack.add(previousLocationKey.current)
                    }
                }
            }
        }
    }, [routeCache, locationKey, history, previousLocationKey])

    return null
}
