import React, { useEffect } from 'react'
import { useLocalStorage } from '../../../web-common/src/hooks/useLocalStorage'

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed'
        platform: string
    }>
    prompt(): Promise<void>
}

/**
 * Triggers native Android app install prompt
 * https://developer.chrome.com/blog/app-install-banners-native
 */
export const GoogleNativeAppPrompt: React.FC = () => {
    const [hasViewedPrompt, setHasViewedPrompt] = useLocalStorage({
        key: 'SWMGoogleNativeAppPrompt',
        initialValue: 'false',
    })

    const onBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
        if (typeof window !== undefined) {
            e.preventDefault()
            const deferredPrompt = e
            deferredPrompt.prompt()

            deferredPrompt.userChoice.then(() => {
                setHasViewedPrompt('true')
            })
        }
    }

    if (typeof window !== 'undefined' && hasViewedPrompt !== 'true') {
        window.addEventListener(
            'beforeinstallprompt',
            onBeforeInstallPrompt as EventListener,
            { once: true },
        )
    }

    return <></>
}
