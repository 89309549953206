import { cx } from '@emotion/css'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AdEvent,
    AdState,
    AppState,
    AuthenticationState,
    SlotRenderEndedEvent,
    adsDebug,
    useToggleState,
} from '@news-mono/web-common'
import React from 'react'
import {
    AdNoticePosition,
    StyledAdUnitWrapper,
    StyledCenter,
    StyledNotice,
} from '../../advertising/AdUnit/AdUnit.styled'
import {
    calculateMargin,
    getUnitVisibilityBreakpoints,
} from '../../advertising/AdUnit/util'
import { addListener, removeListener } from '../../__helpers/global-dom-events'
import { isRichMediaAd } from './isRichMediaAd'
import { ViewabilityAdUnit } from './ViewabilityAdUnit'
import { teadsAdSlotID } from '../../__helpers'
import { useSelector } from 'react-redux'

const teadsAdDebug = adsDebug.extend('teads')

export interface Breakout {
    kind: 'absolute' | 'percentage'
    value: number
    breakpoint: number
}

/**
 * TODO investigate moving placeholder height from ../ad-helpers/
 */
export interface AdUnitWrapperProps {
    /**
     * Position of the notice that this unit is an ad should be displayed
     */
    noticePosition: AdNoticePosition

    /**
     * Padding to be applied to the ad unit
     * not applied if ad unit is hidden
     */
    padding?: [number] | [number, number] | [number, number, number, number]
    /**
     * Ignores the placeholder height
     */
    hiddenUntilLoaded?: boolean
    /**
     * Prevents the ad slot from collapsing if no ad served
     */
    preventCollapse?: boolean
    /**
     * override lazyloading behaviour with a fixed distance from viewport, in pixels
     */
    lazyLoadingDistance?: number

    breakout?: Breakout
    removeAdContainerStyles?: boolean

    /** Called once the ad unit has rendered */
    unitRendered?: () => void
    stickyOffset?: number | string
    adType: 'event' | 'inline'
}

export interface AdSlotProps {
    unitId: string
    adState: AdState
}

export interface AdUnitWrapperInternalProps extends AdUnitWrapperProps {
    onEvent: (event: AdEvent) => void
    disableBreachAds?: boolean
}

/**
 * This component is the outer most ad unit component
 *
 * It is responsible for collapsing the ad unit if an ad is not rendered and rendering the ad notice
 */

export const AdUnitWrapper: React.FC<AdUnitWrapperInternalProps & AdSlotProps> =
    ({
        hiddenUntilLoaded,
        adState,
        unitId,
        breakout,
        unitRendered,
        onEvent,
        noticePosition,
        padding,
        stickyOffset,
        preventCollapse,
        disableBreachAds
    }) => {
        const breakoutContainerRef = React.useRef<HTMLDivElement | null>(null)

        //As part of the DTEC-412 testing for the ad bug we are adding this toggle temporarily so that we can validate the behaviour in prod
        const toggles = useToggleState()
        const isOverwriteHiddenUntilLoaded = isFeatureEnabled(
            toFeatureState(toggles),
            'force-ads-hidden-until-loaded-false',
        )

        const [shouldHide, setShouldHide] = React.useState(
            isOverwriteHiddenUntilLoaded ? false : hiddenUntilLoaded || false,
        )
        const [forcePadding, setForcePadding] = React.useState(false)
        const [breakoutMargins, setBreakoutMargins] = React.useState({
            left: 0,
            right: 0,
        })
        const [removeAdContainerStyles, setRemoveAdContainerStyles] =
            React.useState(false)
        const [recievedAdSlot, setRecievedAdSlot] = React.useState<
            boolean | undefined
        >(undefined)
        const [previousAdPageKey, setPreviousAdPageKey] = React.useState(
            adState.pageKey,
        )

        // If we recieve updated props where the adPageKey does not match the next adPageKey:
        // TODO this won't work for th 5minute page reload behaviour
        // unless we are actually refreshing the page

        if (previousAdPageKey !== adState.pageKey) {
            setPreviousAdPageKey(adState.pageKey)
            setShouldHide(
                isOverwriteHiddenUntilLoaded
                    ? false
                    : hiddenUntilLoaded || false,
            )
            setRecievedAdSlot(undefined)
        }

        const onSlotMutation = React.useCallback(
            (id: string) =>
                setRemoveAdContainerStyles(
                    isRichMediaAd(document.getElementById(id)),
                ),
            [],
        )

        const calculateBreakoutMargins = React.useCallback(() => {
            if (
                !breakout ||
                !breakoutContainerRef.current ||
                !document.documentElement
            ) {
                return
            }

            const viewportWidth = document.documentElement.clientWidth
            const containerWidth =
                breakoutContainerRef.current.getBoundingClientRect().width
            const { kind, value, breakpoint } = breakout

            let finalMargin = 0
            switch (kind) {
                case 'percentage': {
                    const absMargin = Math.ceil(containerWidth * (value / 100))
                    finalMargin = calculateMargin(
                        absMargin,
                        containerWidth,
                        breakpoint,
                        viewportWidth,
                    )
                    break
                }
                default:
                case 'absolute':
                    finalMargin = calculateMargin(
                        value,
                        containerWidth,
                        breakpoint,
                        viewportWidth,
                    )
                    break
            }

            setBreakoutMargins({
                left: finalMargin,
                right: finalMargin,
            })

            /* eslint-disable react-hooks/exhaustive-deps */
            // eslint complains that we can't use expressions but we need to as otherwise
            // js would always interperate `breakout` as a new object
        }, [
            breakout && breakout.breakpoint,
            breakout && breakout.kind,
            breakout && breakout.value,
        ])
        /* eslint-enable react-hooks/exhaustive-deps */

        React.useEffect(() => {
            if (!breakoutContainerRef.current) return
            calculateBreakoutMargins()
            addListener('resize', calculateBreakoutMargins)
            return () => removeListener('resize', calculateBreakoutMargins)
            // only re-compute if calculateBreakoutMargins changes, this is important as calculateBreakoutMargins sets state,
            // and otherwise may introduce infinite re-rendering
        }, [calculateBreakoutMargins])

        /**
         * We have a secondary useEffect because the TEADs ad slot is filled from
         * an external source, so we have no on-board management to determine when it's
         * being filled or not. So instead we'll go off the breakoutContainerRef height,
         * and listen to whenever that changes and do a check to see if it's above the
         * minimum height.
         */
        React.useEffect(() => {
            if (unitId !== teadsAdSlotID || !breakoutContainerRef.current) {
                return
            }

            teadsAdDebug(
                `${teadsAdSlotID} ad slot has been registered and is now monitoring for filling...`,
            )

            // Observe the teads breakout container ref to force the notice and padding
            // below the slot in order to find when to add the padding
            const resizeObserver = new ResizeObserver(() => {
                const boundingClient =
                    breakoutContainerRef.current?.getBoundingClientRect()

                if (boundingClient && boundingClient?.height > 4) {
                    setForcePadding(true)
                    setRecievedAdSlot(true)

                    teadsAdDebug(
                        `${teadsAdSlotID} has been observed of a resize, to size ${boundingClient?.height}px`,
                    )
                }
            })

            resizeObserver.observe(breakoutContainerRef.current)
            return () => resizeObserver.disconnect()
        }, [])

        const onSlotRenderEnded = React.useCallback(
            (event: SlotRenderEndedEvent) => {
                const hasNoPlaceholderHeight =
                    event.unit && !event.unit.placeholderHeight
                const canCollapse =
                    hasNoPlaceholderHeight || hiddenUntilLoaded !== undefined
                const shouldHide = preventCollapse
                    ? false
                    : canCollapse && event.isEmpty

                // If it's the teads ad slot, we don't want to hide it ever, otherwise
                // the ad slot won't be able to be filled, else fall back to whatever
                // value was just set
                setShouldHide(unitId === teadsAdSlotID ? false : shouldHide)
                setRecievedAdSlot(!event.isEmpty)
                setRemoveAdContainerStyles(
                    isRichMediaAd(document.getElementById(event.unit.id)),
                )

                if (unitRendered) {
                    unitRendered()
                }
            },
            [unitRendered, hiddenUntilLoaded, preventCollapse],
        )

        const visibilityBreakpoints = getUnitVisibilityBreakpoints(
            { unitId, adState },
            recievedAdSlot !== true,
        )

        const adUnitWithViewability = (
            <ViewabilityAdUnit
                unitId={unitId}
                adState={adState}
                removeAdContainerStyles={removeAdContainerStyles}
                onSlotMutation={onSlotMutation}
                onSlotRenderEnded={onSlotRenderEnded}
                breakoutContainerRef={breakoutContainerRef}
                breakoutMargins={breakoutMargins}
                onEvent={onEvent}
                recievedAdSlot={recievedAdSlot}
                shouldHide={shouldHide}
                visibilityBreakpoints={visibilityBreakpoints}
            />
        )

        const adWithNotice = (
            <StyledNotice
                noticePosition={noticePosition}
                noticeMessage={recievedAdSlot === true ? 'Advertisement' : ' '}
                removeAdContainerStyles={removeAdContainerStyles}
            >
                {adUnitWithViewability}
            </StyledNotice>
        )

        // determine which react component to render, if it's the
        // teads ad slot we don't want to render the notice position
        // until it's been filled with an ad, otherwise we're left with
        // weird padding issues and large gaps.
        const renderAd =
            noticePosition === 'none' ||
            (unitId === teadsAdSlotID && !forcePadding)
                ? adUnitWithViewability
                : adWithNotice

        /** Disable/Hide Ad wrapper for breachwall pages */
        const authentication = useSelector<AppState, AuthenticationState>(
            ({ authentication }) => authentication,
        )
        const isEntitled = authentication.isEntitled
        if(disableBreachAds && !isEntitled) return null

        return (
            <StyledAdUnitWrapper
                className={cx('hide-print', 'ad-no-notice')}
                stickyOffset={stickyOffset}
                ref={breakoutContainerRef}
            >
                <StyledCenter
                    visibilityBreakpoints={visibilityBreakpoints}
                    hidden={shouldHide}
                    forcePadding={forcePadding}
                    padding={padding}
                    unitId={unitId}
                    breakoutRef={breakoutContainerRef}
                >
                    {renderAd}
                </StyledCenter>
            </StyledAdUnitWrapper>
        )
    }
