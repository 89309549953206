import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../../feature-toggling/Toggle/Toggle'
import { TheWestLiveStickyBannerProps } from './TheWestLiveStickyBanner'

const LazyTheWestLiveStickyBannerComponent = loadable(
    () => import('./TheWestLiveStickyBanner'),
)

// Standalone lazy load component
export const LazyTheWestLiveStickyBanner = (
    props: TheWestLiveStickyBannerProps,
) => {
    return (
        <FeatureToggle
            feature="the-west-live"
            on={() => <LazyTheWestLiveStickyBannerComponent {...props} />}
        />
    )
}
