import { ComponentEvent } from '@news-mono/web-common'
import { Properties } from '../../tracking/gtm/gtm'

/** When A/B testing is enabled page events need to be buffered until the
 * spa.initialised event has been raised.
 * This is so GA events include the correct toggles
 */

export function createEventBuffer<Event extends ComponentEvent<Properties>>(
    handlePageEvents: (event: Event) => void,
) {
    let featuresInitialised = false
    let isBufferingPageEvents = false
    let events: ComponentEvent<Properties>[] = []

    return function raiseEvent(event: Event) {
        // We need to buffer events until features are initialised,
        // otherwise we will report to GA incorrectly
        if (event.type === 'spa.initialised') {
            featuresInitialised = true

            handlePageEvents(event)
            const eventsCopy = [...events]
            events = []
            eventsCopy.forEach((e) => {
                // We need a cast here because the events are boxed
                raiseEvent(e as any)
            })
            return
        }

        if (featuresInitialised) {
            if (event.type === 'page-load-started') {
                isBufferingPageEvents = true
                handlePageEvents(event)
                return
            }

            if (event.type === 'page-load-complete') {
                handlePageEvents(event)
                events.forEach((e) => {
                    // We need a cast here because the events are boxed
                    handlePageEvents(e as any)
                })
                events = []
                isBufferingPageEvents = false
                return
            }

            if (event.type === 'page-load-failed') {
                handlePageEvents(event)
                events.forEach((e) => {
                    // We need a cast here because the events are boxed
                    handlePageEvents(e as any)
                })
                events = []
                isBufferingPageEvents = false
                return
            }

            if (!isBufferingPageEvents) {
                handlePageEvents(event)
                return
            }
        }
        events.push(event)
    }
}
