import { toFeatureState } from '@etrigan/feature-toggles-client'
import { TheWestRegionalSections, TheWestSection } from '@news-mono/common'
import { isCorporateAccount } from '@news-mono/component-library'
import {
    AppState,
    isComponentEnabled,
    useToggleState,
} from '@news-mono/web-common'
import { isWestRegionalSection } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { MessengerButton } from '../../buttons/MessengerButton/MessengerButton'
import { SocialLinks } from '../../navigation/SocialLinks/SocialLinks'
import {
    StyledBlock,
    StyledBlockHeader,
    StyledFooterContent,
    StyledLink,
    StyledList,
    StyledText,
    StyledTitle,
} from '../../navigation/TheWestFooterNetworkLinks/TheWestFooterNetworkLinks.styled'
import { useSection } from '../../__helpers'
import { theWestSectionDefinitions } from '../../__styling/settings/sections/thewest'
import {
    Address,
    BaseSocial,
    TheWestFooterNetworkLinksProps,
} from './TheWestFooterNetworkLinks'

export const TheWestFooterNetworkLinksWeb: React.FC<TheWestFooterNetworkLinksProps> =
    ({ networkLinks, socialLinks }) => {
        const featureState = useToggleState()
        const section = useSection()

        const { subscriptionType } = useSelector(
            (state: AppState) => state.authentication,
        )

        const isRegionalSection = isWestRegionalSection(section)
        const isGenwestSection = section === 'genwest'
        const hiddenLinksForCorporateUsers = [
            'West Rewards',
            'West Classifieds',
            'The Game',
            'The West Email Newsletters',
        ]
        const linksToDisplay = isRegionalSection
            ? networkLinks.regional
            : networkLinks.thewest

        const renderSocialLinks = () => {
            if (isRegionalSection) {
                if (!socialLinks[section as TheWestRegionalSections]) {
                    return
                }

                const sectionedSocialLinks = socialLinks[
                    section as TheWestRegionalSections
                ] as BaseSocial & Address

                const { street, city, state, postcode, phone } =
                    sectionedSocialLinks
                const { title } =
                    theWestSectionDefinitions[section as TheWestSection]

                return (
                    <StyledBlock>
                        <StyledBlockHeader>Contact Us</StyledBlockHeader>
                        <StyledTitle>{title}</StyledTitle>
                        <StyledText>
                            {street}
                            <br />
                            {`${city} ${state} ${postcode}`}
                            <br />
                            {`Tel ${phone}`}
                        </StyledText>
                        <MessengerButton
                            link={sectionedSocialLinks.facebookMessenger}
                        />
                        <SocialLinks {...sectionedSocialLinks} />
                    </StyledBlock>
                )
            }

            return (
                <StyledBlock>
                    <StyledBlockHeader>Connect with us</StyledBlockHeader>
                    <MessengerButton
                        link={socialLinks.default.facebookMessenger}
                    />
                    <SocialLinks {...socialLinks.default} />
                </StyledBlock>
            )
        }

        return isGenwestSection ? (
            <></>
        ) : (
            <StyledFooterContent id="footer">
                {linksToDisplay.map((item) => {
                    return (
                        <StyledBlock key={item.name}>
                            <StyledBlockHeader>{item.name}</StyledBlockHeader>
                            <StyledList>
                                {item.links
                                    .filter(
                                        ({
                                            feature,
                                            invertFeatureToggle,
                                            name,
                                        }) => {
                                            const shouldHideForCorporateUsers =
                                                isCorporateAccount(
                                                    subscriptionType,
                                                ) &&
                                                hiddenLinksForCorporateUsers.includes(
                                                    name,
                                                )
                                            const isEnabled =
                                                isComponentEnabled(
                                                    toFeatureState(
                                                        featureState,
                                                    ),
                                                    {
                                                        feature,
                                                        invertFeatureToggle,
                                                    },
                                                )
                                            return (
                                                !shouldHideForCorporateUsers &&
                                                isEnabled
                                            )
                                        },
                                    )
                                    .map(({ name, link }) => {
                                        return (
                                            <li key={name}>
                                                <StyledLink to={link}>
                                                    {name}
                                                </StyledLink>
                                            </li>
                                        )
                                    })}
                            </StyledList>
                        </StyledBlock>
                    )
                })}
                {renderSocialLinks()}
            </StyledFooterContent>
        )
    }
TheWestFooterNetworkLinksWeb.displayName = 'TheWestFooterNetworkLinks'
