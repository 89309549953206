import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { SSWTransaction } from '../ssw'
import { AllEvents } from './all-events'
import { ComponentEvent } from './component-event'
import {
    AddToCartEvent,
    RemoveFromCartEvent,
    SubscriptionPromptCheckoutEvent,
    ViewCartEvent,
    ViewSubscriptionItemListEvent,
} from './subscribeEventTypes'

export type BreachScreenType =
    | 'Premium - Inline'
    | 'Registration - Inline'
    | 'Premium - Inline - Promo'

export interface BreachEventProps {
    /* What paywall method was implemented ie freemium vs metered */
    paywallType: 'freemium' | 'metered'
    /* lead in depth - how many paragraphs were shown in the lead in */
    leadDepth: number
    /* breach screen creative name */
    creative: string
    breachScreenType?: BreachScreenType
    /* breach screen pop up method (inline inferred from type) */
    popupMethod: 'auto'
    /* The deal in the breach screen, deal code	 */
    deal: string
    /* Upcoming breach notification - ie you have two stories left */
    upcomingNotify?: number
    /* Subscription price point */
    pricepoint: number[]
}

export interface BreachCompleteEventPublicationInfo {
    /* should be document.title at the time of event creation */
    currentPageTitle: string
}

export type RegwallBreachCompleteEventProps = BreachEventProps &
    BreachCompleteEventPublicationInfo & {
        breachScreenType: 'Registration - Inline' // used to narrow the type
    }
type SubsBreachCompleteEventProps = BreachEventProps &
    BreachCompleteEventPublicationInfo &
    SSWTransaction & { breachScreenType: 'Premium - Inline' } // used to narrow the type

type InlinePromoCompleteEventProps = BreachEventProps &
    BreachCompleteEventPublicationInfo &
    SSWTransaction & { breachScreenType: 'Premium - Inline - Promo' }

export type BreachCompleteEventProps =
    | RegwallBreachCompleteEventProps
    | SubsBreachCompleteEventProps
    | InlinePromoCompleteEventProps

export interface BreachAvailableEvent extends ComponentEvent<BreachEventProps> {
    type: DataLayerEventName.breachScreenAvailable
}

export interface BreachClickEvent extends ComponentEvent<BreachEventProps> {
    type: DataLayerEventName.breachScreenClick
}

export interface BreachCompleteEvent
    extends ComponentEvent<BreachCompleteEventProps> {
    type: DataLayerEventName.breachScreenComplete
}

interface BreachBaseGTMProps extends Omit<BreachEventProps, 'pricepoint'> {
    transactionAffiliation: 'The West'
    pricepoint: string
}

interface Transaction {
    sku: string
    name: string
    price: number
    quantity: 1
}

interface SubsBreachCompleteGTMProps
    extends BreachBaseGTMProps,
        Omit<SSWTransaction, 'productSku' | 'productName'> {
    transactionProducts: Transaction[]
    transactionShipping: 0
}

interface RegwallBreachCompleteGTMProps extends BreachBaseGTMProps {}

export type BreachScreenEvent =
    | BreachAvailableEvent
    | BreachClickEvent

export type BreachEvent =
    | BreachScreenEvent
    | AddToCartEvent
    | RemoveFromCartEvent
    | ViewCartEvent
    | ViewSubscriptionItemListEvent
    | SubscriptionPromptCheckoutEvent

export const breachScreenEventNames = [
    DataLayerEventName.breachScreenAvailable,
    DataLayerEventName.breachScreenClick
] as const

export const breachEventNames = [
    DataLayerEventName.breachScreenAvailable,
    DataLayerEventName.breachScreenClick,
    DataLayerEventName.addToCart,
    DataLayerEventName.removeFromCart,
    DataLayerEventName.viewSubscriptionItemListEvent,
    DataLayerEventName.viewCart,
    DataLayerEventName.subscriptionPromptCheckout,
] as const

export type BreachEventNameType = typeof breachEventNames[number]

export function isBreachEvent(event: AllEvents): event is BreachScreenEvent {
    return (breachScreenEventNames as readonly DataLayerEventName[]).includes(
        event.type as DataLayerEventName,
    )
}

export function toBreachBaseEventShape(
    props: BreachEventProps,
): BreachBaseGTMProps {
    return {
        ...props,
        transactionAffiliation: 'The West',
        pricepoint: props.pricepoint.join(','),
    }
}

export function toBreachCompleteEventShape(
    props: BreachCompleteEventProps,
): SubsBreachCompleteGTMProps | RegwallBreachCompleteGTMProps {
    if (props.breachScreenType === 'Premium - Inline') {
        const {
            transactionId,
            transactionTotal,
            transactionTax,
            productSku,
            productName,
            pricepoint,
            ...breachProps
        } = props

        return {
            ...breachProps,
            transactionId,
            pricepoint: pricepoint.join(','),
            transactionAffiliation: 'The West',
            transactionTotal,
            transactionTax,
            transactionShipping: 0,
            transactionProducts: [
                {
                    sku: productSku,
                    name: productName,
                    price: transactionTotal,
                    quantity: 1,
                },
            ],
        }
    }

    // regwall breach complete
    const { pricepoint, ...breachProps } = props
    return {
        ...breachProps,
        pricepoint: pricepoint.join(','),
        transactionAffiliation: 'The West',
    }
}
