import { IsHydrating, useMounted } from '@news-mono/web-common'
import React from 'react'
import { addListener, removeListener } from '../__helpers/global-dom-events'
import { getShouldLoadProps } from './getShouldLoadProps'

export interface ShouldLoadProps {
    percentVisible: number // 0-100
    distanceFromViewport: number
    viewportHeight: number
}

export interface LazyLoadingResult<T extends HTMLElement> {
    lazyLoadHasTriggered: boolean
    wasLazyLoaded: boolean
    lazyLoadRef: React.RefObject<T>
}

export function useLazyLoading<T extends HTMLElement>(
    shouldLoad: (props: ShouldLoadProps) => boolean,
): LazyLoadingResult<T> {
    const isHydrating = React.useContext(IsHydrating)
    const [isLoaded, setLoaded] = React.useState(false)
    const [wasLazyLoaded, setWasLazyLoaded] = React.useState(false)
    const isMounted = useMounted()
    const lazyLoadRef = React.useRef<T | null>(null)

    React.useEffect(() => {
        if (!lazyLoadRef.current) {
            console.debug(
                `innerRef passed by 'useLazyLoading' has not been added to an element`,
            )
            return
        }

        const loaded = shouldLoad(getShouldLoadProps(lazyLoadRef.current))
        setLoaded(loaded)
        // Component was lazy loaded if not initially loaded
        setWasLazyLoaded(!loaded)

        function detectInViewport() {
            if (
                !lazyLoadRef.current ||
                !lazyLoadRef.current.getBoundingClientRect
            )
                return
            // Once loaded, we stay loaded
            if (loaded) return
            if (shouldLoad(getShouldLoadProps(lazyLoadRef.current))) {
                // Schedule state change for after all components have determined if they are on screen
                // See the comment about getBoundingClientRect()
                setTimeout(() => {
                    // Only update state if we haven't been unmounted while waiting for the timeout
                    if (isMounted()) {
                        setLoaded(true)
                    }
                })
            }
        }

        addListener('scroll', detectInViewport)
        addListener('resize', detectInViewport)

        return () => {
            removeListener('scroll', detectInViewport)
            removeListener('resize', detectInViewport)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        lazyLoadHasTriggered: !isHydrating && isLoaded,
        wasLazyLoaded,
        lazyLoadRef,
    }
}
