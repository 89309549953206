import styled from '@emotion/styled'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const promo = require('./images/promo.jpg')

export const StyledTradingUpCard = styled('div')({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    padding: calcRem(8),
    marginTop: calcRem(28),
    display: 'none',
    [breakpoint('md')]: {
        display: 'block',
    },
})
