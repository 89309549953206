// TODO if something from this file is imported into the west code base it will add the perthnow
// images to the webpack assets.

import { ImageLinkProps } from '../../buttons/ImageLink'

const cngSendStoryTips = require('./images/cng-send-your-story-tips.png')
const rsBanner = require('./images/reader-survey-win-2k-banner-min.png')
const pnlnSendStoryTips = require('./images/pnln-send-your-story-tips.png')
const sendStoryTips = require('./images/send-your-story-tips.png')
const sendYourPhotos = require('./images/send-your-photos.png')
const timespool = require('./images/timespool.png')
const waBushfireAlerts = require('./images/wa-bushfire-alerts.png')
const perthTraffic = require('./images/perth-traffic.png')
const subscribeCard = require('./images/subscribe-card.jpg')
const horoscopeCard = require('./images/horoscope-card.jpg')
const cngNewsletterCard = require('./images/cng-newsletter.png')
const cngNewsletterArticleCard = require('./images/cng-newsletter-article.png')
const pnlnNewsletterCard = require('./images/pnln-newsletter.png')
const cngEPapersCard = require('./images/cng-epapers.png')
const pnlnEPapersCard = require('./images/pnln-epapers.png')
const blindDateBanner = require('./images/blind-date-banner.jpg')
const sendPhotosSubject = encodeURIComponent('Picture from website reader')
const sendPhotosMessage = encodeURIComponent(
    'Dear reader: Please attach your pictures, then delete this text and replace it with as much information as you can about the incident.',
)

export const sendPhotosLink = `mailto:news@perthnow.com.au?subject=${sendPhotosSubject}&body=${sendPhotosMessage}`

export const PNDigitalEditionLink = '/editions'

export const sendStoryLink = buildTipsLink(
    'news@perthnow.com.au',
    'Submit a story tip',
    'Think we should be covering a story that’s not on our site or in the newspaper? Please let us know. Please provide a contact number if you would like us to contact you.',
)

export const SendYourPhotos: ImageLinkProps = {
    src: sendYourPhotos,
    link: sendPhotosLink,
    altText: 'Send us your photos',
    title: '',
    width: 322,
    height: 70,
}

export const SendStoryTips: ImageLinkProps = {
    src: sendStoryTips,
    link: sendStoryLink,
    altText: 'Send us your story tips',
    title: '',
    width: 322,
    height: 70,
}

export const Timespool: ImageLinkProps = {
    src: timespool,
    link: '/entertainment/timespool/perthnow-timespool-c-657990',
    altText: 'Win cash with the Sunday Times - Get your timespool codeword',
    title: '',
    width: 322,
    height: 70,
}

// TODO use: traffic image,
export const Traffic: ImageLinkProps = {
    src: perthTraffic,
    link: '/news/perth-traffic',
    altText: 'Main Roads WA brings you Perth Traffic',
    title: '',
    width: 322,
    height: 70,
}

// TODO use: bushfire image,
export const Bushfires: ImageLinkProps = {
    src: waBushfireAlerts,
    link: '/news/bushfire-alerts',
    altText: 'Up to the minute WA bushfire alerts',
    title: '',
    width: 322,
    height: 70,
}

export const Subscribe: ImageLinkProps = {
    src: subscribeCard,
    link: 'https://subscriber.thewest.com.au/?utm_source=perthnow&utm_medium=referral&utm_campaign=subscribe&utm_content=homebanner#packages',
    altText: 'Get your newspaper delivered, subscribe now',
    title: '',
    width: 300,
    height: 210,
}
export const HoroscopeImageCard: ImageLinkProps = {
    src: horoscopeCard,
    link: '/lifestyle/horoscopes',
    altText: `Oscar Cainer's Daily Horoscopes`,
    title: '',
    width: 322,
    height: 70,
}

export const CngNewsletter: ImageLinkProps = {
    src: cngNewsletterCard,
    link: '/community-news/subscribe',
    altText: `Subscribe to our newsletter`,
    title: '',
    width: 310,
    height: 70,
}

export const PnlnNewsletter: ImageLinkProps = {
    src: pnlnNewsletterCard,
    link: '/manage-email-preferences',
    altText: `Subscribe to our newsletter`,
    title: '',
    width: 310,
    height: 70,
}

export const CngNewsletterArticle: ImageLinkProps = {
    src: cngNewsletterArticleCard,
    link: '/community-news/subscribe',
    altText: `Subscribe to our newsletter`,
    title: '',
    width: 549,
    height: 54,
}

export const RegionalReaderSurvey: ImageLinkProps = {
    src: rsBanner,
    link: 'https://regionalreader.com.au/',
    altText: `Reader Survey`,
    title: '',
    width: '100%',
    height: 70,
}

export const CngEPapers: ImageLinkProps = {
    src: cngEPapersCard,
    link: PNDigitalEditionLink,
    altText: `Subscribe to our newsletter`,
    title: '',
    width: 310,
    height: 70,
    forceInternalSSR: true,
}

export const PnlnEPapers: ImageLinkProps = {
    src: pnlnEPapersCard,
    link: PNDigitalEditionLink,
    altText: `Exclusive PerthNow Editions at your fingertips`,
    title: '',
    width: 310,
    height: 70,
    forceInternalSSR: true,
}

export const CngSendStoryTips: ImageLinkProps = {
    src: cngSendStoryTips,
    link: buildTipsLink(
        'newstips@communitynews.com.au',
        'Submit a story tip',
        'Think we should be covering a story that’s not on our site or in the newspaper? Please let us know. Please provide a contact number if you would like us to contact you.',
    ),
    altText: 'Send your Story Tips to Community News',
    title: '',
    width: 322,
    height: 70,
}

export const PnlnSendStoryTips: ImageLinkProps = {
    src: pnlnSendStoryTips,
    link: buildTipsLink(
        'news@perthnow.com.au',
        'Submit a story tip',
        'Think we should be covering a story that’s not on our site or in the newspaper? Please let us know. Please provide a contact number if you would like us to contact you.',
    ),
    altText: 'Send your Story Tips to Local News',
    title: '',
    width: 322,
    height: 70,
}
const blindDateArticleLink = '/lifestyle/blind-date'

export const PlayBlindDate: ImageLinkProps = {
    src: blindDateBanner,
    link: blindDateArticleLink,
    altText: `Blind Date banner`,
    title: '',
    width: 310,
    height: 70,
}

export const marketingLinks = {
    Bushfires,
    SendStoryTips,
    SendYourPhotos,
    Timespool,
    Traffic,
    Subscribe,
    Horoscope: HoroscopeImageCard,
    CngSendStoryTips,
    PnlnSendStoryTips,
    CngNewsletter,
    CngNewsletterArticle,
    PnlnNewsletter,
    CngEPapers,
    PnlnEPapers,
    PlayBlindDate,
}

export type MarketingLink = keyof typeof marketingLinks

export function buildTipsLink(
    mailto: string,
    subject: string,
    message: string,
) {
    const encodedSubject = encodeURIComponent(subject)
    const encodedMessage = encodeURIComponent(message)
    return `mailto:${mailto}?subject=${encodedSubject}&body=${encodedMessage}`
}
