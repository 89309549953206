import { VideoDetails } from '@news-mono/web-common'

/**
 * Gets the poster image for the video, this is the 16:9 crop if it's https
 */
export function getPosterImage(currentVideo: VideoDetails) {
    return currentVideo.posterImage &&
        currentVideo.posterImage.crops['16:9'] &&
        currentVideo.posterImage.crops['16:9'].reference.indexOf('https') >= 0
        ? currentVideo.posterImage.crops['16:9']
        : undefined
}
