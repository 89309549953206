import styled from '@emotion/styled'
import { TextItem } from '../../typography/TextItem/TextItem'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export type StyledParagraphProps = {
    isIncreasedFont: boolean
}

export const StyledParagraph = styled(TextItem)<StyledParagraphProps>(
    ({ theme, isIncreasedFont }) => ({
        ...breakWords,
        margin: themedValue(theme, {
            thewest: `0 0 ${calcRem(metrics.thewest.textMargin / 2)} 0`,
            perthnow: `0 0 ${calcRem(metrics.perthnow.textMargin)} 0`,
            sevennews: `0 0 ${calcRem(metrics.sevennews.textMargin)} 0`,
            thenightly: `0 0 ${calcRem(metrics.thenightly.textMargin)} 0`,
            fallback: undefined,
        }),
        lineHeight: themedValue(theme, {
            thewest: 1.65,
            perthnow: 1.5,
            sevennews: '25px',
            fallback: undefined,
        }),
        fontSize: themedValue(theme, {
            sevennews: calcRem(18),
            thenightly: calcRem(20),
            fallback: calcRem(16),
        }),
        ...(theme.kind === 'sevennews' && {
            fontFamily: fonts.sevennews.sansSerif,
            fontWeight: 300,
            strong: {
                fontWeight: 700,
            },
        }),
        ...(theme.kind === 'thenightly' && {
            fontFamily: fonts.thenightly.body,
            fontWeight: 400,
            color: theme.colors.text.tertiary,
        }),
        ...(isIncreasedFont && {
            [`@media (max-width: 425px)`]: {
                fontSize: calcRem(18),
                margin: `${calcRem(metrics.thewest.textMargin / 2)} 0 ${calcRem(
                    metrics.thewest.textMargin / 2,
                )} 0`,
                lineHeight: '1.4',
                letterSpacing: '0.025rem',
            },
        }),
    }),
)
