import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardItem,
    LiveBlogEntry,
    PublicationCardItem,
    PublicationCardItemWithoutVideo,
} from 'web-common'
import {
    Block_TextV4DTO,
    EventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { getEventPostTimeAgo } from '@news-mono/web-common'
import { format, subHours } from 'date-fns'

const mastHeads = [
    'The New York Times',
    'The Economist',
    'The Washington Post',
    'CNBC',
]

export function getByline(item: MaybeLoaded<PublicationCardItem>): {
    link?: string
    text: string
} {
    if (!item.loaded) {
        return { link: undefined, text: '' }
    }

    const card = item.result

    // If the source is one of the special mastheads, use that as the byline text
    if (card.source && mastHeads.includes(card.source)) {
        return { link: undefined, text: card.source }
    }

    // If the card has a profile, use that for the byline
    if (
        isPublicationCardItemWithoutVideo(card) &&
        card.profiles &&
        card.profiles.length > 0
    ) {
        const profile = card.profiles[0]
        return { link: `/profile/${profile.slug}`, text: profile.name }
    }

    // Fallback to the default card byline
    return { link: undefined, text: card.byline }
}

export function getTopicLink(item: MaybeLoaded<CardItem>): string {
    if (!item.loaded) {
        return ''
    }

    return `/${item.result.primaryTopic.id}` ?? ''
}

function isPublicationCardItemWithoutVideo(
    card: CardItem,
): card is PublicationCardItemWithoutVideo {
    return (<PublicationCardItemWithoutVideo>card).profiles !== undefined
}

export const getLatestLiveBlog = (
    liveBlogEntry?: LiveBlogEntry,
    expectedTextLength?: number,
) => {
    const textLength = expectedTextLength ?? 250
    const latestPostHoursLimit = 4

    if (!liveBlogEntry) return undefined

    const latestContent = selectLatestContent(
        liveBlogEntry.milestones,
        liveBlogEntry.stickies,
        liveBlogEntry.documents,
        textLength,
        latestPostHoursLimit,
    )

    if (!latestContent) return undefined

    // take the first text block
    const blogText = (latestContent.content.blocks[0] as Block_TextV4DTO).text

    return {
        title: latestContent.title,
        text: blogText,
        publishedDate: latestContent.publishedDate,
    }
}

const selectLatestContent = (
    milestones: EventPostV4DTO[],
    stickies: EventPostV4DTO[],
    posts: EventPostV4DTO[],
    textLength: number,
    latestPostHoursLimit: number,
) => {
    const hasValidTextBlock = (item: EventPostV4DTO): boolean => {
        return item.content.blocks.some(
            (block) => block.kind === 'text' && block.text.trim() !== '',
        )
    }

    // Filter items to only include those with kind = 'text' and non-empty text
    const filterValidItems = (items: EventPostV4DTO[]) =>
        items.filter(hasValidTextBlock)

    const filteredMilestones = filterValidItems(milestones)
    const filteredStickies = filterValidItems(stickies)
    const filteredPosts = filterValidItems(posts)

    // Select the latest milestone post
    if (filteredMilestones.length > 0) {
        const selectedItem = filteredMilestones[0]
        selectedItem.content.blocks = selectedItem.content.blocks.map(
            (block) => {
                if (block.kind === 'text') {
                    block.text = truncateText(block.text, textLength)
                }
                return block
            },
        )
        return selectedItem
    } else {
        // If none, Select the recent post, posted within the last latestPostHoursLimit hours
        const now = new Date()
        const latestPostTimeThreshold = subHours(now, latestPostHoursLimit)
        const recentPosts = filteredPosts.filter(
            (post) => new Date(post.publishedDate) > latestPostTimeThreshold,
        )

        if (recentPosts.length > 0) {
            const selectedItem = recentPosts[0]
            selectedItem.content.blocks = selectedItem.content.blocks.map(
                (block) => {
                    if (block.kind === 'text') {
                        block.text = truncateText(block.text, textLength)
                    }
                    return block
                },
            )
            return selectedItem
        } else {
            // If no recent posts, select the latest sticky post
            if (filteredStickies.length > 0) {
                const selectedItem = filteredStickies[0]
                selectedItem.content.blocks = selectedItem.content.blocks.map(
                    (block) => {
                        if (block.kind === 'text') {
                            block.text = truncateText(block.text, textLength)
                        }
                        return block
                    },
                )
                return selectedItem
            } else {
                // fallback to select the latest post
                if (filteredPosts.length > 0) {
                    const selectedItem = filteredPosts[0]
                    selectedItem.content.blocks =
                        selectedItem.content.blocks.map((block) => {
                            if (block.kind === 'text') {
                                block.text = truncateText(
                                    block.text,
                                    textLength,
                                )
                            }
                            return block
                        })
                    return selectedItem
                }
            }
        }
    }
    return null
}

const truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}\u2026` // horizontal ellipsis unicode
    }
    return text
}
