import { createRegisterableComponent, Product } from '@news-mono/web-common'
import { TopicV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { TopicList } from './TopicList'

export interface TopicListRouteProps {
    topics: { primary: TopicV4DTO; secondary: TopicV4DTO[] }
    verticalSpacing?: keyof ThemeMargins
}

export const TopicListRegistration = createRegisterableComponent(
    'topic-list',
    (props: TopicListRouteProps) => {
        const { topics, verticalSpacing } = props

        return (
            <TopicList
                primaryTopic={topics.primary}
                secondaryTopics={topics.secondary}
                verticalSpacing={verticalSpacing}
            />
        )
    },
)
