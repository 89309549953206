import { useEffect, useState } from 'react'
import { DebugEventAdder, getPostIdFromUrl } from '../helpers'

type UseDeepLinkHandlerProps = {
    handleDeepLink: (postId: string) => void
    addDebugEvent: DebugEventAdder
}

/**
 * A hook to detect deep links (Hash-based) and execute a provided handler.
 */
export const useDeepLinkHandler = ({
    handleDeepLink,
    addDebugEvent,
}: UseDeepLinkHandlerProps) => {
    const [deepLinkPostId, setDeepLinkPostId] = useState(getPostIdFromUrl())
    const [hasNavigated, setHasNavigated] = useState(false)
    // Handle initial deep link.
    useEffect(() => {
        if (!deepLinkPostId || hasNavigated) return

        addDebugEvent(
            `Deep link to post ${deepLinkPostId} detected! Handling...`,
        )

        handleDeepLink(deepLinkPostId)
        setHasNavigated(true)
    }, [addDebugEvent, deepLinkPostId, handleDeepLink, hasNavigated])

    // Handle deep links from internal nav.
    useEffect(() => {
        const onHashChange = () => {
            setHasNavigated(false)
            setDeepLinkPostId(getPostIdFromUrl())
        }

        window.addEventListener('hashchange', onHashChange)

        return () => window.removeEventListener('hashchange', onHashChange)
    }, [])

    return deepLinkPostId
}
