import { layout } from '../../App.routing'

export const healthSegment = layout.component({
    type: 'uniform',
    props: {
        section: 'news',
        verticalSpacing: 'xl',
        cardOrientation: { type: 'Landscape-Portrait' },

        cardLayout: [
            ['large-card'],
            ['large-card'],
            ['large-card'],
            {
                type: 'november',
                listLength: 5,
                hasTimestamp: true,
            },
        ],
        sectionHeader: {
            heading: 'Health',
            headingUrl: '/news/health',
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'health',
            offset: 0,
            pageSize: 8,
        },
    },
})
