import { ConsentLevel, useConsentGiven } from '@news-mono/web-common'
import React from 'react'
import { StyledIframe } from '../../content/Iframe/Iframe.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface IframeProps {
    link: string
    height: number | string
    width?: number | string
    marginBottom?: keyof ThemeMargins
    innerRef?:
        | ((element: HTMLIFrameElement) => void)
        | React.RefObject<HTMLIFrameElement>
        | null
    sandbox?: string
    frameBorder?: number
    consentRequiredLevel?: ConsentLevel
    allow?: string
    allowFullscreen?: boolean
    dataHjAllowIframe?: boolean
    id?: string
    className?: string
    ariaLabel?: string
}

export const Iframe: React.FC<IframeProps> = ({
    link,
    height,
    marginBottom,
    innerRef,
    sandbox,
    width,
    frameBorder,
    consentRequiredLevel = 1,
    allow,
    allowFullscreen,
    dataHjAllowIframe,
    id,
    className,
    ariaLabel,
}) => {
    const consentGiven = useConsentGiven({
        consentRequiredLevel,
    })

    if (!consentGiven) {
        return null
    }

    return (
        <StyledIframe
            src={link}
            height={height}
            width={width}
            marginBottom={marginBottom}
            ref={innerRef}
            sandbox={sandbox}
            frameBorder={frameBorder}
            allow={allow}
            allowFullScreen={allowFullscreen}
            data-hj-allow-iframe={dataHjAllowIframe}
            id={id}
            className={className}
            aria-label={ariaLabel}
        >
            Sorry, we can't show this content as your browser does not support
            iframes.
        </StyledIframe>
    )
}
Iframe.displayName = 'Iframe'
