import styled from '@emotion/styled'
import { colors } from '../../../../design-tokens/src/thenightly/colors'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { TheNightlyTheme } from '../../__styling'

export const StyledToggleContainer = styled('div')(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',

        cursor: 'pointer',
    },
])

export const StyledToggleLabel = styled('div')(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'center',

        color: colors.palette.neutral[100],
        textTransform: 'uppercase',
    },
    textMixin(theme as TheNightlyTheme, 'button-label'),
])
