import React from 'react'
import { StyledImage } from './Image.web.styled'
import { NativeLazyLoading, FetchPriority } from './Image.web.types'

export interface ImageProps {
    src: string
    srcSet?: string
    sizes?: string
    alt?: string
    enableFadeIn: boolean
    className?: string
    loading?: NativeLazyLoading
    onError?: () => void
    height?: string
    width?: string
    fetchpriority?: FetchPriority
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    (
        {
            enableFadeIn,
            src,
            srcSet,
            sizes,
            alt,
            className,
            loading,
            onError,
            height,
            width,
            fetchpriority,
        },
        ref,
    ) => {
        const [loaded, setLoaded] = React.useState(enableFadeIn === false)
        function onLoad() {
            setLoaded(true)
        }

        return (
            <StyledImage
                ref={ref}
                show={loaded}
                onLoad={onLoad}
                onError={onError}
                src={src}
                srcSet={srcSet}
                sizes={sizes}
                alt={alt}
                className={className}
                loading={loading}
                height={height}
                width={width}
                fetchpriority={fetchpriority}
            />
        )
    },
)

Image.displayName = 'Image'
