import { breakpoints } from '../../..'
/**
 * Helper function to determine current WAN breakpoint at given viewport size
 * @param width current view port width
 * @returns applicable breakpoint eg. 'xs'
 */
export function getBreakpoint(width: number) {
    if (width <= breakpoints['xs']) {
        return 'xs'
    } else if (width <= breakpoints['sm']) {
        return 'sm'
    } else {
        return 'md'
    }
}
