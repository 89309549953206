import {
    CardEvents,
    CollectionEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import React from 'react'
import { Lima } from '../../../collections/Lima/Lima'
import { SectionHeaderProps } from '../../../section-header/SectionHeader/SectionHeader'

interface InlinerelatedContentCardProps {
    items: PublicationCardItem[]
    sectionHeader?: SectionHeaderProps

    onEvent: (event: CardEvents | CollectionEvent) => void
}
export const InlineRelatedContentCard: React.FC<InlinerelatedContentCardProps> =
    ({ items, onEvent, sectionHeader }) => {
        return (
            <Lima
                onEvent={onEvent}
                expectedCards={2}
                initialColumns={2}
                intermediateColumns={2}
                finalColumns={2}
                kickerMode="hidden"
                verticalSpacing={'lg'}
                fixedRatios={['16:9']}
                sectionHeader={sectionHeader}
                cardLayout={[
                    ['noTeaserNoPadding1xFont'],
                    ['noTeaserNoPadding1xFont'],
                ]}
                items={{ loaded: true, result: items.slice(0, 2) }}
                isInlineRelatedCollection={true}
            />
        )
    }
