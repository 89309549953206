import { PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { PortraitProps } from '../../cards/Portrait/Portrait'
import { CommonCardProps } from '../CardItem.Props'

type CommonLayoutProps = Omit<PortraitProps, 'cardNumber' | 'item' | 'onEvent'>
export interface LandscapeStackedLayout {
    type: 'landscape-stacked'
    format:
        | CommonLayoutProps
        | 'landscape-stacked-common'
        | 'landscape-stacked-noteaser'
}

export const commonLandscapeStackedProps: CommonLayoutProps = {
    teaserMode: 'visible',
}

export const noTeaserLandscapeStackedProps: CommonLayoutProps = {
    teaserMode: 'hidden',
}

const layoutProps: { [formatName: string]: CommonLayoutProps } = {
    'landscape-stacked-common': commonLandscapeStackedProps,
    'landscape-stacked-noteaser': noTeaserLandscapeStackedProps,
}

function getPropsForLayout(layout: LandscapeStackedLayout): CommonLayoutProps {
    if (typeof layout.format === 'object') {
        return layout.format
    }

    return layoutProps[layout.format]
}

export const getLandscapeStackedCardItemProps = (
    item: MaybeLoaded<PublicationCardItem>,
    layout: LandscapeStackedLayout,
    props: CommonCardProps,
    cardNumber: number,
) => {
    const commonProps: CommonLayoutProps = getPropsForLayout(layout)

    const loadedProps: PortraitProps = {
        ...commonProps,
        ...props,
        item,
        cardNumber,
    }

    return loadedProps
}
