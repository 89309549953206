import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { MatchTimelineWidget } from './MatchTimelineWidget'

export interface MatchTimelineRouteProps {
    matchId: string
    apiData: any
}

export const MatchTimelineWidgetRegistration = createRegisterableComponent(
    'afl-match-centre-timeline',
    (props: MatchTimelineRouteProps, services) => {
        return <MatchTimelineWidget {...props} />
    },
)
