/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useCallback, useEffect, useState } from 'react'
import { StyledButton } from '../../../buttons/Button/Button.styled'
import { breakpoints } from '../../../__styling'
import {
    StyledThumbnailPaginationButtonsContainer,
    StyledThumbnailPaginationContainer,
} from '../Gallery.styled'
import { GalleryThumbnails, GalleryThumbnailsProps } from './GalleryThumbnails'

export const GalleryThumbnailsPagination: React.FC<GalleryThumbnailsProps> = ({
    images,
    onEvent,
    scrollTo,
    toggleThumbnails,
}) => {
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(images.length)
    const indexOfLastItem = page * sizePerPage
    const indexOfFirstItem = indexOfLastItem - sizePerPage

    const updateSize = useCallback(() => {
        const numOfThumbnails =
            window.innerWidth < breakpoints.xs
                ? 6
                : window.innerWidth < breakpoints.md
                ? 12
                : images.length
        setSizePerPage(numOfThumbnails)
    }, [images.length])

    useEffect(() => {
        updateSize()
    }, [updateSize])

    useEffect(() => {
        window.addEventListener('resize', updateSize)
        return () => window.removeEventListener('resize', updateSize)
    })

    const img = images.slice(indexOfFirstItem, indexOfLastItem)

    return (
        <StyledThumbnailPaginationContainer>
            <GalleryThumbnails
                images={img}
                onEvent={onEvent}
                scrollTo={scrollTo}
                toggleThumbnails={toggleThumbnails}
                paginationIndex={(page - 1) * sizePerPage}
            />
            {sizePerPage !== images.length && (
                <StyledThumbnailPaginationButtonsContainer>
                    <StyledButton
                        onClick={() => setPage(page - 1)}
                        type="button"
                        disabled={page === 1}
                    >
                        Previous
                    </StyledButton>
                    <StyledButton
                        onClick={() => setPage(page + 1)}
                        type="button"
                        disabled={indexOfLastItem >= images.length}
                    >
                        Next
                    </StyledButton>
                </StyledThumbnailPaginationButtonsContainer>
            )}
        </StyledThumbnailPaginationContainer>
    )
}
