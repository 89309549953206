import { EditorialType, IconLiveTN } from '@news-mono/component-library'
import React, { FC } from 'react'
import { Classification } from './Classification'
import { Bullet } from './Bullet'
import { TopicLink } from './Topic'
import { Author, AuthorLink } from './Author'
import { TopicBylineWrapper } from './TopicBylineWrapper'
import CommentsCount from './CommentsCount'
import { StyledLiveClassificationContainer } from './Byline.styled'

export interface BylineProps {
    editorialType?: EditorialType
    topicLink: string
    topicTitle: string
    bylineText: string
    bylineLink?: string
    allowCommenting: boolean
    articleUrl: string
}

export const Byline: FC<BylineProps> = ({
    editorialType,
    topicLink,
    topicTitle,
    bylineText,
    bylineLink,
    allowCommenting,
    articleUrl,
}) => {
    return (
        <TopicBylineWrapper>
            {editorialType && (
                <>
                    <Classification type={editorialType}>
                        {editorialType === EditorialType.Live && (
                            <StyledLiveClassificationContainer>
                                <IconLiveTN />
                            </StyledLiveClassificationContainer>
                        )}
                        {editorialType}
                    </Classification>
                    <Bullet />
                </>
            )}

            <TopicLink to={topicLink}>{topicTitle}</TopicLink>
            {!!bylineText && (
                <>
                    <Bullet />
                    {!bylineLink ? (
                        <Author>{bylineText}</Author>
                    ) : (
                        <AuthorLink to={bylineLink}>{bylineText}</AuthorLink>
                    )}
                </>
            )}
            {allowCommenting && (
                <>
                    <Bullet />
                    <CommentsCount articleUrl={articleUrl} />
                </>
            )}
        </TopicBylineWrapper>
    )
}
