import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

function getOpinionCollection(
    heading: string,
    topic: string,
): Array<CheckedComponentInformation> {
    return [
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'visible',
                    md: 'visible',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading,
                    headingUrl: `/${topic}`,
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: [`${topic}`],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}

export const getOpinionRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        ...getOpinionCollection('Editorials', 'opinion/editorials'),
        ...getOpinionCollection('Ben Harvey', 'opinion/ben-harvey'),
        ...getOpinionCollection('Jessica Page', 'opinion/jessica-page'),
        ...getOpinionCollection('Kate Emery', 'opinion/kate-emery'),
        ...billboardLeaderboardMrec('one'),
        ...getOpinionCollection('Katina Curtis', 'opinion/katina-curtis'),
        ...getOpinionCollection('Basil Zempilas', 'opinion/basil-zempilas'),
        ...billboardLeaderboardMrec('two'),
        ...getOpinionCollection('Emma Garlett', 'opinion/emma-garlett'),
        ...getOpinionCollection('Kim MacDonald', 'opinion/kim-macdonald'),
        ...getOpinionCollection('Paul Murray', 'opinion/paul-murray'),
        ...getOpinionCollection('Justin Langer', 'sport/justin-langer'),
        ...getOpinionCollection('Belle Taylor', 'opinion/belle-taylor'),
        ...getOpinionCollection('Laura Newell', 'opinion/laura-newell'),
        ...getOpinionCollection('Anxiety Aunt', 'opinion/anxiety-aunt'),
        ...getOpinionCollection('Andrew Miller', 'opinion/andrew-miller'),
        ...getOpinionCollection('Dean Alston', 'opinion/dean-alston'),
    ]
}
