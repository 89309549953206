import styled from '@emotion/styled'
import { calcRem, TheNightlyThemeConfig, breakpoint } from '../../../__styling'
import { CardContainer } from '../components/CardContainer'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { Headline } from '../components'
import { WebLink } from '@news-mono/web-common'

export const StyledHeadline = styled(Headline)(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'article-main'),
).withComponent('h2')

export const LeadCardContainer = styled(CardContainer)({
    alignSelf: 'center',
    marginTop: calcRem(-40),

    [breakpoint('sm')]: {
        marginTop: 0,
    },

    transition: 'margin 0.25s',
})

export const CardLinkWrapper = styled(WebLink)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    // Remove default anchor styling.
    color: 'unset',
    textDecoration: 'none',

    // Underline Headline when hovered or active.
    [`&:is(:hover, :focus) ${StyledHeadline}`]: {
        textDecoration: 'underline',
    },
}))

export const StyledIconWrapper = styled('div')(({ theme }) => ({
    color: theme.colors.text.secondary,
    height: calcRem(16),
    width: calcRem(16),
    padding: calcRem(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))
