import styled from '@emotion/styled'
import {
    CardItem,
    CollectionEvent,
    ComponentServices,
    EntitledToView,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CardOrientationOptions } from '../../cards/Portrait/Portrait'
import { Lima } from '../../collections/Lima/Lima'
import { Sierra } from '../../collections/Sierra/Sierra'
import { StyledHeader } from '../../collections/Sierra/Sierra.styled'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { calcRem, colors, themedValue, ThemeMargins } from '../../__styling'

interface HeraProps {
    expectedCards: number
    onEvent: (event: CollectionEvent) => void
    items: MaybeLoaded<CardItem[]>
    sectionHeader?: SectionHeaderProps
    hasBorder?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    cardServices?: ComponentServices
    cardOrientation?: CardOrientationOptions
}

interface StyledHeraProps {
    hasBorder?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
}

/**
 * DPT-3259:
 * This composition dynamically renders different collections
 * based on the amount of cards available.
 *
 * - with 1 card available, render Sierra with Hero Video & a call-to-action card
 * - with 2 cards available, render Sierra with Hero Video & 1 portrait cards
 * - with 3 cards available, render Sierra with Hero Video & 2 portrait cards
 * - with 4 or more cards available, render Lima
 */
export const HeraBody: React.FC<HeraProps> = (props: HeraProps) => {
    const {
        expectedCards,
        items,
        onEvent,
        hasBorder,
        cardServices,
        cardOrientation,
    } = props
    if (!items.loaded) {
        return (
            <Sierra
                expectedCards={expectedCards}
                items={{ loaded: false }}
                onEvent={onEvent}
                cardLayout={[
                    'hero-video-left',
                    'teaser-xsVisible-mdHidden-Image',
                    'teaser-xsVisible-mdHidden-Image',
                ]}
                cardServices={cardServices}
            />
        )
    }

    switch (items.result.length) {
        case 1:
            return (
                <Sierra
                    expectedCards={expectedCards}
                    items={items}
                    onEvent={onEvent}
                    cardLayout={[
                        'hero-video-left',
                        'video-trailer-card-single',
                    ]}
                    restrictedVideoPlaybackMode={'entitled'}
                    heroVideoOptions={{ largeVideoPlayButton: false }}
                    cardServices={cardServices}
                />
            )
        case 2:
            return (
                <EntitledToView
                    requiredAccess={{ level: 'subscriber' }}
                    entitled={() => {
                        return (
                            <Sierra
                                expectedCards={expectedCards}
                                items={items}
                                onEvent={onEvent}
                                cardLayout={[
                                    'hero-video-left',
                                    'teaser-xsVisible-mdHidden-Image',
                                ]}
                                heroVideoOptions={{
                                    largeVideoPlayButton: false,
                                }}
                                hasBorder={false}
                                hasHeroBorder={false}
                                cardServices={cardServices}
                                cardOrientation={cardOrientation}
                            />
                        )
                    }}
                    notEntitled={() => {
                        return (
                            <Sierra
                                expectedCards={3}
                                items={items}
                                onEvent={onEvent}
                                cardLayout={[
                                    'teaser-xsVisible-mdHidden-Image',
                                    'teaser-xsVisible-mdHidden-Image',
                                    'video-trailer-card-double',
                                ]}
                                hasBorder={false}
                                hasHeroBorder={false}
                                cardServices={cardServices}
                                cardOrientation={cardOrientation}
                            />
                        )
                    }}
                />
            )
        case 3:
            return (
                <EntitledToView
                    requiredAccess={{ level: 'subscriber' }}
                    entitled={() => {
                        return (
                            <Sierra
                                expectedCards={expectedCards}
                                items={items}
                                onEvent={onEvent}
                                cardLayout={[
                                    'hero-video-left',
                                    'teaser-xsVisible-mdHidden-Image',
                                    'teaser-xsVisible-mdHidden-Image',
                                ]}
                                heroVideoOptions={{
                                    largeVideoPlayButton: false,
                                }}
                                cardOrientation={cardOrientation}
                                cardServices={cardServices}
                            />
                        )
                    }}
                    notEntitled={() => {
                        return (
                            <Lima
                                expectedCards={4}
                                items={items}
                                onEvent={onEvent}
                                cardLayout={[
                                    ['teaser-xsVisible-mdHidden-Image'],
                                    ['teaser-xsVisible-mdHidden-Image'],
                                    ['teaser-xsVisible-mdHidden-Image'],
                                    ['video-trailer-card-single'],
                                ]}
                                hasBorder={false}
                                cardOrientation={cardOrientation}
                            />
                        )
                    }}
                />
            )
        default:
            return (
                <Lima
                    expectedCards={expectedCards}
                    items={items}
                    onEvent={onEvent}
                    cardLayout={[['teaser-xsVisible-mdHidden-Image']]}
                    hasBorder={false}
                    cardOrientation={cardOrientation}
                />
            )
    }
}

const StyledHera = styled('div')<StyledHeraProps>(
    ({ theme, hasBorder, verticalSpacing }) => ({
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        borderBottom: hasBorder
            ? themedValue(theme, {
                  thewest: `4px double ${colors.thewest.greyGorilla}`,
                  fallback: undefined,
              })
            : undefined,
    }),
)

export const Hera: React.FC<HeraProps> = (props: HeraProps) => {
    const hasItems = props.items
    return (
        <StyledHera
            verticalSpacing={props.verticalSpacing}
            hasBorder={props.hasBorder}
        >
            {props.sectionHeader && hasItems && (
                <StyledHeader>
                    <SectionHeader {...props.sectionHeader} />
                </StyledHeader>
            )}
            <HeraBody {...props} />
        </StyledHera>
    )
}
