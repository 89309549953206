import { VideoListV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../data/resources'
import { httpGet } from '../../helpers/http'
import { Paging } from './paging'

export function validateGetVideosResponse(value: any): VideoListV4ResponseDTO {
    return value
}

export interface GetVideosOptions {
    series: string
}

export function getVideos(
    services: DataLoaderGlobalParams,
    options: GetVideosOptions,
) {
    return httpGet({
        log: services.log,
        validate: validateGetVideosResponse,
        baseUrl: services.config.contentApi,
        path: `v4/video`,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
        query: {
            series: options.series,
        },
    })
}

export interface GetTopicVideosOptions {
    topics: string[]
    paging?: Paging
}

export function getTopicVideos(
    services: DataLoaderGlobalParams,
    options: GetTopicVideosOptions,
) {
    const { paging = {} } = options
    return httpGet({
        log: services.log,
        validate: validateGetVideosResponse,
        baseUrl: services.config.contentApi,
        path: `v4/video/topic`,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
        query: {
            topics: options.topics,
            page: paging.page || 1,
            page_size: paging.pageSize || 3,
            page_offset: paging.pageOffset || 0,
        },
    })
}
