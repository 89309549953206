import styled from '@emotion/styled'
import { SectionHeaderContainer } from '../../../section-header/SectionHeader/SectionHeader.styled'
import { styledListClassName } from '../../../typography/List/List.styled'
import { metrics } from '../../../__styling/settings/metrics'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

const gridGapSize = calcRem(metrics.thewest.margins.md)
const insetMargin = calcRem(metrics.thewest.margins.xl)
const insetMarginMob = 0

export const StyledGrid = styled('article')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: gridGapSize,
    marginBottom: metrics.thewest.margins.md,
})

export const StyledInsetCell = styled('div')({
    [breakpoint('md')]: {
        padding: `0 ${insetMargin}`,
    },
})

export const StyledArticleContentFooter = styled('div')({
    marginLeft: insetMarginMob,
    marginRight: insetMarginMob,

    [breakpoint('md')]: {
        marginLeft: insetMargin,
        marginRight: insetMargin,
    },
})
StyledArticleContentFooter.displayName = 'ArticleContentFooter'

export const StyledArticleContent = styled('div')({
    /*
            - Adds the horizontal padding to the article content
            - Excludes the figure component so we can center images & video of unknown width
              but also have the image asset full bleed to the edge on desktop.
        */
    width: '100%',

    '&>div > *:not(figure)': {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: insetMarginMob,
        paddingRight: insetMarginMob,

        [breakpoint('md')]: {
            paddingLeft: insetMargin,
            paddingRight: insetMargin,
        },
    },

    [`${SectionHeaderContainer}`]: {
        marginTop: calcRem(metrics.thewest.margins.xl),
    },

    [`& .${styledListClassName}`]: {
        paddingLeft: insetMargin,

        [breakpoint('md')]: {
            paddingLeft: calcRem(
                metrics.thewest.margins.xl + metrics.thewest.margins.lg,
            ),
        },
    },

    '& > *:last-child': {
        marginBottom: 0,
    },

    '& figure, & blockquote': {
        marginBottom: calcRem(metrics.thewest.margins.xxl),
    },

    '& h2': {
        fontSize: calcRem(30),
        lineHeight: 1.167,
    },

    // If the last item in an article is an image then remove the border on the figcaption so it doesn't double up with topics
    '& > figure:last-child figcaption': {
        borderBottom: 'none',
    },

    '& .twin-image-wrapper': {
        marginBottom: calcRem(metrics.thewest.margins.md),

        [breakpoint('sm')]: {
            marginBottom: 0,
        },
    },
})
