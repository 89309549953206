import React from 'react'

// prettier-ignore
export const circles = [
    <circle cx="8" cy="8" r="8" />,
    <circle cx="40" cy="8" r="8" />,
    <circle cx="72" cy="8" r="8" />,
    <circle cx="104" cy="8" r="8" />,
    <circle cx="136" cy="8" r="8" />,

    <circle cx="8" cy="40" r="8" />,
    <circle cx="40" cy="40" r="8" />,
    <circle cx="72" cy="40" r="8" />,
    <circle cx="104" cy="40" r="8" />,
    <circle cx="136" cy="40" r="8" />,

    <circle cx="8" cy="72" r="8" />,
    <circle cx="40" cy="72" r="8" />,
    <circle cx="72" cy="72" r="8" />,
    <circle cx="104" cy="72" r="8" />,
    <circle cx="136" cy="72" r="8" />,

    <circle cx="8" cy="104" r="8" />,
    <circle cx="40" cy="104" r="8" />,
    <circle cx="72" cy="104" r="8" />,
    <circle cx="104" cy="104" r="8" />,
    <circle cx="136" cy="104" r="8" />,

    <circle cx="8" cy="136" r="8" />,
    <circle cx="40" cy="136" r="8" />,
    <circle cx="72" cy="136" r="8" />,
    <circle cx="104" cy="136" r="8" />,
    <circle cx="136" cy="136" r="8" />,

    <circle cx="8" cy="168" r="8" />,
    <circle cx="40" cy="168" r="8" />,
    <circle cx="72" cy="168" r="8" />,
    <circle cx="104" cy="168" r="8" />,
    <circle cx="136" cy="168" r="8" />,

    <circle cx="8" cy="200" r="8" />,
    <circle cx="40" cy="200" r="8" />,
    <circle cx="72" cy="200" r="8" />,
    <circle cx="104" cy="200" r="8" />,
    <circle cx="136" cy="200" r="8" />,

    <circle cx="8" cy="232" r="8" />,
    <circle cx="40" cy="232" r="8" />,
    <circle cx="72" cy="232" r="8" />,
    <circle cx="104" cy="232" r="8" />,
    <circle cx="136" cy="232" r="8" />,

    <circle cx="12.9824" cy="279.404" r="8" transform="rotate(-12 12.9824 279.404)" />,
    <circle cx="44.2834" cy="272.75"  r="8" transform="rotate(-12 44.2834 272.75)" />,
    <circle cx="75.5842" cy="266.097" r="8" transform="rotate(-12 75.5842 266.097)" />,
    <circle cx="106.884" cy="259.444" r="8" transform="rotate(-12 106.884 259.444)" />,
    <circle cx="138.185" cy="252.791" r="8" transform="rotate(-12 138.185 252.791)" />,

    <circle cx="27.7117" cy="324.736" r="8" transform="rotate(-24 27.7117 324.736)" />,
    <circle cx="56.9451" cy="311.72" r="8" transform="rotate(-24 56.9451 311.72)" />,
    <circle cx="86.1785" cy="298.705" r="8" transform="rotate(-24 86.1785 298.705)" />,
    <circle cx="115.412" cy="285.689" r="8" transform="rotate(-24 115.412 285.689)" />,
    <circle cx="144.645" cy="272.674" r="8" transform="rotate(-24 144.645 272.674)" />,

    <circle cx="51.544" cy="366.015" r="8" transform="rotate(-36 51.544 366.015)" />,
    <circle cx="77.4327" cy="347.206" r="8" transform="rotate(-36 77.4327 347.206)" />,
    <circle cx="103.321" cy="328.397" r="8" transform="rotate(-36 103.321 328.397)" />,
    <circle cx="129.21" cy="309.587" r="8" transform="rotate(-36 129.21 309.587)" />,
    <circle cx="155.098" cy="290.778" r="8" transform="rotate(-36 155.098 290.778)" />,

    <circle cx="83.4383" cy="401.437" r="8" transform="rotate(-48 83.4383 401.437)" />,
    <circle cx="104.85" cy="377.656" r="8" transform="rotate(-48 104.85 377.656)" />,
    <circle cx="126.263" cy="353.875" r="8" transform="rotate(-48 126.263 353.875)" />,
    <circle cx="147.675" cy="330.095" r="8" transform="rotate(-48 147.675 330.095)" />,
    <circle cx="169.087" cy="306.314" r="8" transform="rotate(-48 169.087 306.314)" />,

    <circle cx="122" cy="429.454" r="8" transform="rotate(-60 122 429.454)" />,
    <circle cx="138" cy="401.741" r="8" transform="rotate(-60 138 401.741)" />,
    <circle cx="154" cy="374.028" r="8" transform="rotate(-60 154 374.028)" />,
    <circle cx="170" cy="346.315" r="8" transform="rotate(-60 170 346.315)" />,
    <circle cx="186" cy="318.602" r="8" transform="rotate(-60 186 318.602)" />,

    <circle cx="165.544" cy="448.841" r="8" transform="rotate(-72 165.544 448.841)" />,
    <circle cx="175.433" cy="418.407" r="8" transform="rotate(-72 175.433 418.407)" />,
    <circle cx="185.321" cy="387.973" r="8" transform="rotate(-72 185.321 387.973)" />,
    <circle cx="195.209" cy="357.539" r="8" transform="rotate(-72 195.209 357.539)" />,
    <circle cx="205.098" cy="327.106" r="8" transform="rotate(-72 205.098 327.106)" />,

    <circle cx="212.167" cy="458.751" r="8" transform="rotate(-84 212.167 458.751)" />,
    <circle cx="215.513" cy="426.926" r="8" transform="rotate(-84 215.513 426.926)" />,
    <circle cx="218.857" cy="395.102" r="8" transform="rotate(-84 218.857 395.102)" />,
    <circle cx="222.203" cy="363.277" r="8" transform="rotate(-84 222.203 363.277)" />,
    <circle cx="225.547" cy="331.452" r="8" transform="rotate(-84 225.547 331.452)" />,

    // last dot
    <circle cx="236" cy="288" r="8" />,

    <circle cx="259.833" cy="458.751" r="8" transform="rotate(-96 259.833 458.751)" />,
    <circle cx="256.488" cy="426.926" r="8" transform="rotate(-96 256.488 426.926)" />,
    <circle cx="253.142" cy="395.102" r="8" transform="rotate(-96 253.142 395.102)" />,
    <circle cx="249.798" cy="363.277" r="8" transform="rotate(-96 249.798 363.277)" />,
    <circle cx="246.453" cy="331.452" r="8" transform="rotate(-96 246.453 331.452)" />,
    
    <circle cx="306.456" cy="448.841" r="8" transform="rotate(-108 306.456 448.841)" />,
    <circle cx="296.567" cy="418.407" r="8" transform="rotate(-108 296.567 418.407)" />,
    <circle cx="286.679" cy="387.973" r="8" transform="rotate(-108 286.679 387.973)" />,
    <circle cx="276.791" cy="357.54" r="8" transform="rotate(-108 276.791 357.54)" />,
    <circle cx="266.902" cy="327.106" r="8" transform="rotate(-108 266.902 327.106)" />,

    <circle cx="350" cy="429.454" r="8" transform="rotate(-120 350 429.454)" />,
    <circle cx="334" cy="401.741" r="8" transform="rotate(-120 334 401.741)" />,
    <circle cx="318" cy="374.029" r="8" transform="rotate(-120 318 374.029)" />,
    <circle cx="302" cy="346.316" r="8" transform="rotate(-120 302 346.316)" />,
    <circle cx="286" cy="318.603" r="8" transform="rotate(-120 286 318.603)" />,

    <circle cx="388.562" cy="401.437" r="8" transform="rotate(-132 388.562 401.437)" />,
    <circle cx="367.15" cy="377.657" r="8" transform="rotate(-132 367.15 377.657)" />,
    <circle cx="345.738" cy="353.876" r="8" transform="rotate(-132 345.738 353.876)" />,
    <circle cx="324.326" cy="330.095" r="8" transform="rotate(-132 324.326 330.095)" />,
    <circle cx="302.913" cy="306.315" r="8" transform="rotate(-132 302.913 306.315)" />,

    <circle cx="420.456" cy="366.016" r="8" transform="rotate(-144 420.456 366.016)" />,
    <circle cx="394.567" cy="347.206" r="8" transform="rotate(-144 394.567 347.206)" />,
    <circle cx="368.678" cy="328.397" r="8" transform="rotate(-144 368.678 328.397)" />,
    <circle cx="342.79" cy="309.588" r="8" transform="rotate(-144 342.79 309.588)" />,
    <circle cx="316.902" cy="290.779" r="8" transform="rotate(-144 316.902 290.779)" />,

    <circle cx="444.288" cy="324.736" r="8" transform="rotate(-156 444.288 324.736)" />,
    <circle cx="415.055" cy="311.721" r="8" transform="rotate(-156 415.055 311.721)" />,
    <circle cx="385.822" cy="298.705" r="8" transform="rotate(-156 385.822 298.705)" />,
    <circle cx="356.588" cy="285.69" r="8" transform="rotate(-156 356.588 285.69)" />,
    <circle cx="327.355" cy="272.674" r="8" transform="rotate(-156 327.355 272.674)" />,

    <circle cx="459.017" cy="279.404" r="8" transform="rotate(-168 459.017 279.404)" />,
    <circle cx="427.717" cy="272.751" r="8" transform="rotate(-168 427.717 272.751)" />,
    <circle cx="396.416" cy="266.098" r="8" transform="rotate(-168 396.416 266.098)" />,
    <circle cx="365.115" cy="259.445" r="8" transform="rotate(-168 365.115 259.445)" />,
    <circle cx="333.815" cy="252.791" r="8" transform="rotate(-168 333.815 252.791)" />,
 
    <circle r="8" transform="matrix(-1 0 0 1 464 232)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 232)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 232)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 232)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 232)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 200)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 200)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 200)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 200)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 200)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 168)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 168)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 168)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 168)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 168)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 136)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 136)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 136)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 136)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 136)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 104)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 104)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 104)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 104)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 104)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 72)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 72)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 72)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 72)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 72)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 40)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 40)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 40)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 40)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 40)" />,

    <circle r="8" transform="matrix(-1 0 0 1 464 8)" />,
    <circle r="8" transform="matrix(-1 0 0 1 432 8)" />,
    <circle r="8" transform="matrix(-1 0 0 1 400 8)" />,
    <circle r="8" transform="matrix(-1 0 0 1 368 8)" />,
    <circle r="8" transform="matrix(-1 0 0 1 336 8)" />,

]
