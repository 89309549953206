import { ConfigurationContext, VideoDetails } from '@news-mono/web-common'
import React from 'react'
import { VideoOptions } from '../../content/Video/PlayerInterface'
import { getImaOptions } from '../../content/Video/utils/get-ima-options'
import { AmpGA } from '../../google-amp/components/AmpGoogleAnalytics'
import { amphtml } from '../../google-amp/react_amp'
import { useNielsenApid } from '../../__helpers/nielsen-apid'
import { standaloneSwmPlayerBrightcovePlayerID } from './providers/PlayerProps'
import { encodePlayerConfigToJSON } from './utils/encode-player-config-to-json'
import { getPosterImage } from './utils/poster'

export type ContainerLayout =
    | 'fill'
    | 'fixed'
    | 'fixed-height'
    | 'flex-item'
    | 'nodisplay'
    | 'responsive'

interface InnerProps {
    'data-account': string
    'data-player': string
    'data-embed': 'default'
    'data-video-id'?: string
    'data-param-video-id-amp'?: string
    'data-param-swm-player-config'?: string
    layout: ContainerLayout
    width: number
    height: number
    class: string
}

const AmpBrightcoveElement = amphtml<InnerProps>('brightcove')
export const AmpBrightcoveElementClassName = 'amp-brightcove'

/**
 * This is an empty, hidden element created to satisfy the AMP analytics trigger definitions
 * We can't know if there's a brightcove video on the page at this stage but the triggers will throw an error
 * if an element matching their selector is not found, this places a fallback below the real video element
 */
export const AmpGtmVideoFallback: React.FC = () => (
    <div
        className={AmpBrightcoveElementClassName}
        style={{ display: 'none' }}
    />
)

export interface AmpVideoProps {
    playerId?: string
    width: number
    height: number
    videoDetails: VideoDetails
    videoOptions: VideoOptions
    publicationPathname?: string
}

const ampBrightcovePlayerID = 'ByNMd1kAM'

/**
 * AMP Brightcove settings can only be configured through Brightcove Studio or API.
 * Please see documentation at:
 * https://confluence.swmdigital.io/display/DPT/AMP+Brightcove+Player+Configuration
 */
export const AmpVideo: React.FC<AmpVideoProps> = (props) => {
    const {
        videoDetails,
        videoOptions,
        width,
        height,
        playerId,
        publicationPathname,
    } = props
    const config = React.useContext(ConfigurationContext)
    const videoId = videoDetails.id
    const accountId = videoDetails.accountId

    const imaOptions = getImaOptions({
        videoId,
        adUnitPath: videoOptions.adUnitPath,
        requestMode: videoOptions.adRequestMode,
        publicationPathname,
    })

    const sanitisedHeadline = JSON.stringify(videoDetails.heading)

    const serverUrlData = serverUrlToJson(imaOptions.serverUrl)

    const ampDataParams = convertJsonToAmpDataParams({
        ...serverUrlData,
        heading: sanitisedHeadline,
    })

    const crop = getPosterImage(videoDetails)
    const posterFrameImageSrc = crop ? crop.reference : undefined

    const nielsenApid = useNielsenApid()

    const unitPath = videoOptions.adUnitPath.split('/')
    const [, , ...applicationIdPaths] = unitPath

    return (
        <>
            <AmpBrightcoveElement
                data-account={accountId}
                data-player={standaloneSwmPlayerBrightcovePlayerID}
                data-embed="default"
                data-param-video-id-amp={videoId}
                data-param-swm-player-config={encodePlayerConfigToJSON({
                    posterFrameImageSrc,
                    videoOptions,
                    config: { ...config, nielsenApid },
                })}
                data-param-app-id={
                    applicationIdPaths.length
                        ? applicationIdPaths.join('/')
                        : 'WAN'
                }
                layout="responsive"
                width={width}
                height={height}
                class={AmpBrightcoveElementClassName}
                {...ampDataParams}
            />
            <AmpGA gtmTrackingId={config.gtmTrackingId} />
        </>
    )
}

export interface QueryKeyValues {
    [key: string]: string
}

/**
 * Converts a URI's query parameters into JSON.
 * Ref: https://www.ampproject.org/docs/reference/components/amp-brightcove#data-param-*
 * @param serverUrl a fully qualified ad call URI containing ad query params
 */
export function serverUrlToJson(serverUrl: string) {
    const json: Record<string, string> = {}

    const [, queryString] = serverUrl.split('?')
    const queries = queryString.split('&')

    for (const query of queries) {
        const [key, value] = query.split('=')
        json[key] = decodeURIComponent(value)
    }

    return json
}

/**
 * Converts JSON into AMP's `data-param-*` attributes.
 * Ref: https://www.ampproject.org/docs/reference/components/amp-brightcove#data-param-*
 * @param data a Record type object
 */
export function convertJsonToAmpDataParams(data: Record<string, string>) {
    const ampDataParamProps: QueryKeyValues = {}

    for (const key of Object.keys(data)) {
        const cleanKey = camelCaseToKebabCase(replaceUnderscoresWithDashes(key))
        const ampKey = `data-param-${camelCaseToKebabCase(cleanKey)}`
        ampDataParamProps[ampKey] = data[key]
    }

    return ampDataParamProps
}

function replaceUnderscoresWithDashes(key: string) {
    return key.replace(/_/g, '-')
}

export function camelCaseToKebabCase(string: string) {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}
