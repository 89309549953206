import React from 'react'

export default function TVGuideSideBarWidgetOne() {
    return (
        <iframe
            id="tvguidetonight-widget-now"
            src="https://tvguidetonight.com.au/widgets/now.html?region=vic"
            style={{ height: '612px', border: 'none' }}
            title=""
        ></iframe>
    )
}
