import {
    socialLinks,
    TermsFooterProps,
    TheWestFooterNetworkLinksProps,
} from '@news-mono/component-library'
import { BaseClientConfig } from '@news-mono/web-common'
import { SectionMetaInfoDTO } from '@west-australian-newspapers/publication-types'
import { regionalLinks } from './navigation'

interface FooterLinks
    extends TheWestFooterNetworkLinksProps,
        TermsFooterProps {}

const getUTMTag = (hostname?: string) =>
    `?utm_source=${
        hostname || 'TheWest'
    }&utm_medium=Referral&utm_campaign=footer&utm_content=footerlink`

export const getFooterLinks = (
    config: BaseClientConfig,
    sectionMeta: SectionMetaInfoDTO,
): FooterLinks => {
    const { publicUrl } = config

    const utmTag = getUTMTag(sectionMeta.Hostname)

    return {
        networkLinks: {
            regional: [
                {
                    name: 'Regional Network',
                    links: regionalLinks,
                },
            ],
            thewest: [
                {
                    name: 'Our Network',
                    links: [
                        {
                            name: 'West Benefits',
                            link: `https://benefits.thewest.com.au/${utmTag}`,
                            feature: 'the-west-rewards',
                            invertFeatureToggle: true,
                        },
                        {
                            name: 'West Rewards',
                            link: `https://rewards.thewest.com.au/${utmTag}`,
                            feature: 'the-west-rewards',
                        },
                        {
                            name: 'West Regional Network',
                            link: `https://thewest.com.au/our-papers/${utmTag}`,
                        },
                        {
                            name: 'West Announcements ',
                            link: `https://www.westannouncements.com.au/${utmTag}`,
                        },
                        {
                            name: 'West Classifieds',
                            link: `https://www.thewestclassifieds.com.au/${utmTag}`,
                        },
                        {
                            name: 'WestBusiness Events ',
                            link: `https://events.thewest.com.au/${utmTag}`,
                        },
                        {
                            name: 'West Travel Club',
                            link: `https://westtravelclub.com.au/${utmTag}`,
                        },
                        {
                            name: 'The Game',
                            link: `https://www.thegame.com.au/${utmTag}`,
                        },
                        {
                            name: 'Media Education',
                            link: `https://mediaeducation.com.au/${utmTag}`,
                        },
                        {
                            name: 'The Nightly',
                            link: `https://www.thenightly.com.au/${utmTag}`,
                        },
                        {
                            name: 'PerthNow',
                            link: `https://www.perthnow.com.au/${utmTag}`,
                        },
                        {
                            name: '7plus',
                            link: `https://7plus.com.au/${utmTag}`,
                        },
                        {
                            name: '7NEWS',
                            link: `https://7news.com.au/${utmTag}`,
                        },
                        {
                            name: 'TV Guide',
                            link: `https://tvguidetonight.com.au/${utmTag}`,
                            feature: '7news-tv-guide-navigation',
                        },
                        {
                            name: 'Streamer',
                            link: `https://streamer.com.au/${utmTag}`,
                        },
                    ],
                },
                {
                    name: 'Our Partners',
                    links: [
                        {
                            name: 'Real Estate View',
                            link: `https://view.com.au/${utmTag}`,
                        },
                        {
                            name: 'Healthengine',
                            link: `https://healthengine.com.au/${utmTag}`,
                        },
                        {
                            name: 'Huddle Insurance',
                            link: `https://huddle.com.au/${utmTag}`,
                        },
                        {
                            name: 'Carbar',
                            link: `https://carbar.com.au/${utmTag}`,
                        },

                        {
                            name: 'CarExpert',
                            link: `https://carexpert.com.au/${utmTag}`,
                        },
                        {
                            name: 'Raiz Invest',
                            link: `https://raizinvest.com.au/${utmTag}`,
                        },
                        {
                            name: 'Institchu',
                            link: `https://institchu.com/${utmTag}`,
                        },
                        {
                            name: 'Starts at 60',
                            link: `https://startsat60.com/${utmTag}`,
                        },
                        {
                            name: 'MoneyMe',
                            link: `https://moneyme.com.au/${utmTag}`,
                        },
                        {
                            name: 'Iseekplant',
                            link: `https://iseekplant.com.au/${utmTag}`,
                        },
                    ],
                },
                {
                    name: 'Subscribe To',
                    links: [
                        {
                            name: 'The West Australian Newspaper',
                            link: `https://subscriber.thewest.com.au/subscription-packages${utmTag}`,
                        },
                        {
                            name: 'The West Email Newsletters',
                            link: '/manage-email-preferences',
                        },
                    ],
                },
            ],
        },

        socialLinks,

        footerCompanyLinks: [
            { name: 'Contact Us', link: '/contact' },
            { name: 'Frequently Asked Questions', link: `${publicUrl}/faq` },
            {
                name: 'Editorial Policy',
                link: 'https://content.thewest.com.au/uploads/the-west/1580711938051editorial_policy_1-156ajrk.pdf',
            },
            {
                name: 'Editorial Complaints',
                link: `${publicUrl}/editorial-complaints`,
            },
            // Place an ad in thewest/Classifieds is injected dynamically in the terms footer, in the lastlength-2 spot
            // Advertise in thewest/regional is injected dynamically in the terms footer, in the lastlength-1 spot
            { name: 'Corporate', link: 'https://www.sevenwestmedia.com.au/' },
        ],

        footerTermsLinks: [
            {
                name: 'Privacy Policy',
                link: 'https://www.sevenwestmedia.com.au/privacy-policies/privacy',
            },
            { name: 'Terms of Use', link: 'https://thewest.com.au/terms' },
        ],
    }
}
