import React from 'react'

export interface IconRSSProps {
    title?: string
    className?: string
}

export const IconRSS: React.FC<IconRSSProps> = ({ className, title }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
        className={className}
    >
        <title>{title || 'RSS Feed'}</title>
        <path d="M2 10c.56 0 1.03.2 1.42.58.4.4.58.86.58 1.42 0 .56-.2 1.03-.58 1.42-.4.4-.86.58-1.42.58-.56 0-1.03-.2-1.42-.58-.4-.4-.58-.86-.58-1.42 0-.56.2-1.03.58-1.42.4-.4.86-.58 1.42-.58zm4.37-2.37c.76.76 1.37 1.63 1.82 2.6.44.98.7 2 .8 3.08 0 .2-.05.36-.17.5-.12.13-.28.2-.48.2H7c-.17 0-.3-.06-.43-.17-.12-.1-.2-.25-.2-.4-.15-1.55-.77-2.86-1.86-3.95C3.44 8.4 2.13 7.78.6 7.63c-.16 0-.3-.08-.4-.2C.05 7.3 0 7.17 0 7V5.64c0-.2.07-.35.2-.47.12-.1.27-.17.44-.17H.7c1.06.1 2.1.36 3.07.8.97.46 1.84 1.07 2.6 1.83zm6.4.73c.7 1.58 1.1 3.24 1.2 4.98 0 .17-.05.33-.18.46s-.28.2-.47.2H11.9c-.16 0-.3-.06-.43-.17-.12-.12-.2-.26-.2-.43-.07-1.42-.4-2.77-1-4.05-.58-1.28-1.35-2.4-2.3-3.33-.93-.94-2.04-1.7-3.32-2.3-1.28-.58-2.63-.92-4.06-1-.17 0-.3-.08-.43-.2C.07 2.4 0 2.25 0 2.1V.66C0 .47.07.33.2.2.32.1.46.04.63.04h.03c1.74.1 3.4.5 4.98 1.2 1.58.7 3 1.67 4.22 2.9 1.24 1.24 2.2 2.65 2.9 4.23z" />
    </svg>
)
