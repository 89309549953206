import React from 'react'
import { ThemeMargins } from '../../../__styling'
import { StyledFourCardCollection } from './FourCardCollection.styled'
import { SkeletonCard } from '../../cards'

interface FourCardCollectionSkeletonProps {
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}
export const FourCardCollectionSkeleton: React.FC<FourCardCollectionSkeletonProps> =
    ({}) => {
        return (
            <StyledFourCardCollection verticalGutters={['unset', 'lg']}>
                {[...new Array(4)].map(() => {
                    return (
                        <SkeletonCard
                            showTimeToRead={true}
                            textLines={4}
                            isVerticalCard={true}
                            overrideHeight={400}
                        />
                    )
                })}
            </StyledFourCardCollection>
        )
    }
