import React, { useState } from 'react'
import {
    StyledLiveEventShareButtonContainer,
    StyledShareButtonContainer,
    StyledShareButtonText,
} from './TNShareButton.styled'
import { IconShareTN } from '../../icons'
import {
    Sharing,
    TNSharingProps,
} from '../../buttons/TheNightlySharing/Sharing'

export interface TNShareButtonProps {
    buttonPosition?: 'left' | 'right'
    forceOpen?: boolean
}

export const TNShareButton: React.FC<TNShareButtonProps & TNSharingProps> = (
    props,
) => {
    const [shareButtonsVisible, setButtonsVisible] = useState(
        props.forceOpen ? true : false,
    )
    const [visibilityState, setVisibilityState] = useState(
        props.forceOpen ? 'visible' : '',
    )

    return (
        <StyledLiveEventShareButtonContainer
            buttonPosition={props.buttonPosition}
        >
            <StyledShareButtonContainer
                shareButtonsVisible={shareButtonsVisible}
                compactTextPaddingOnMobile={true}
                onClick={() => {
                    if (shareButtonsVisible) {
                        setButtonsVisible(false)
                        setVisibilityState('invisible')
                    } else {
                        setButtonsVisible(true)
                        setVisibilityState('visible')
                    }
                }}
            >
                {!shareButtonsVisible && <IconShareTN />}
                <StyledShareButtonText
                    shareButtonsVisible={shareButtonsVisible}
                    hideTextOnMobile={true}
                >
                    {shareButtonsVisible ? 'CLOSE' : 'SHARE'}
                </StyledShareButtonText>
            </StyledShareButtonContainer>

            {/* Buttons are visible, so we want to display them all here! */}
            <Sharing
                visible={visibilityState}
                onEvent={props.onEvent}
                shareOptions={props.shareOptions}
                text={props.text}
                url={props.url}
                animationType={'fade-down-n-in'}
                hideClipboardTextOnMobile={true}
            />
        </StyledLiveEventShareButtonContainer>
    )
}
