import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'

export interface StyledContentWrapperProps {
    stretchColumns?: boolean
    stretchSelf?: boolean
    fillContainer?: boolean
    hasBackgroundFill?: boolean
}

export const StyledContentWrapper = styled('div')<StyledContentWrapperProps>(
    (props) => [
        {
            width: '100%',
            backgroundColor: props.hasBackgroundFill ? colors.white : undefined,
            flexGrow: props.fillContainer ? 1 : undefined,
            height: props.stretchSelf ? '100%' : undefined,
        },
        props.stretchColumns && {
            display: 'flex',
            flexDirection: 'column',
        },
    ],
)
