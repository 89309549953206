import React from 'react'

export interface IconTikTokProps {
    title?: string
    className?: string
}

export const IconTikTok: React.FC<IconTikTokProps> = ({ className, title }) => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title || 'Share to TikTok'}</title>
        <path
            d="M11.9577 4.32703C12.7123 4.85217 13.6368 5.1612 14.6351 5.1612V3.29059C14.4461 3.29066 14.2577 3.27146 14.0729 3.23328V4.70575C13.0746 4.70575 12.1502 4.39672 11.3954 3.87154V7.68882C11.3954 9.59851 9.80539 11.1465 7.84417 11.1465C7.11242 11.1465 6.43222 10.9311 5.86719 10.5616C6.51206 11.2036 7.41136 11.6019 8.40632 11.6019C10.3677 11.6019 11.9578 10.054 11.9578 8.14422V4.32703H11.9577ZM12.6514 2.43982C12.2657 2.02963 12.0125 1.49951 11.9577 0.91342V0.672852H11.4248C11.559 1.4177 12.0164 2.05408 12.6514 2.43982ZM7.10766 9.09637C6.89223 8.82123 6.77576 8.48481 6.77629 8.13882C6.77629 7.2654 7.50358 6.55731 8.40081 6.55731C8.56799 6.55721 8.7342 6.58217 8.89358 6.63137V4.71899C8.70737 4.69415 8.51944 4.68363 8.3315 4.6875V6.17602C8.17207 6.12681 8.00577 6.10181 7.83852 6.10195C6.94127 6.10195 6.21407 6.81008 6.21407 7.68358C6.21407 8.30115 6.57756 8.83585 7.10766 9.09637Z"
            fill="#292A33"
        />
        <path
            opacity="0.8"
            d="M11.3959 3.87156C12.1507 4.39673 13.0751 4.70576 14.0734 4.70576V3.2333C13.5161 3.11779 13.0228 2.83421 12.6518 2.43983C12.0169 2.05408 11.5594 1.4177 11.4253 0.672852H10.0257V8.14424C10.0225 9.01517 9.29651 9.72039 8.40121 9.72039C7.87361 9.72039 7.40489 9.47563 7.10808 9.09638C6.57797 8.83586 6.21446 8.30115 6.21446 7.68356C6.21446 6.81006 6.9417 6.10194 7.83891 6.10194C8.0108 6.10194 8.1765 6.12799 8.33192 6.176V4.68748C6.40513 4.72626 4.85547 6.25908 4.85547 8.14424C4.85547 9.08528 5.24139 9.93849 5.86767 10.5618C6.43268 10.9312 7.1129 11.1466 7.84465 11.1466C9.80587 11.1466 11.3959 9.59852 11.3959 7.68895V3.87156Z"
            fill="#292A33"
        />
        <path
            opacity="0.8"
            d="M14.0731 3.23458V2.83644C13.5705 2.83713 13.0778 2.70014 12.6516 2.44103C13.029 2.84334 13.526 3.12078 14.0731 3.23458ZM11.425 0.674132C11.4122 0.602946 11.4024 0.53131 11.3956 0.459334V0.21875H9.46308V7.69012C9.46 8.56107 8.73401 9.26627 7.83863 9.26627C7.57575 9.26627 7.32758 9.20561 7.10779 9.09765C7.4046 9.47691 7.87331 9.72165 8.40091 9.72165C9.29618 9.72165 10.0222 9.01643 10.0254 8.1455V0.674132H11.425ZM8.33169 4.68876V4.26488C8.17021 4.24338 8.00741 4.23262 7.84444 4.23273C5.88296 4.23273 4.29297 5.78073 4.29297 7.69012C4.29297 8.88732 4.91786 9.94235 5.86743 10.5629C5.24117 9.93965 4.85526 9.08655 4.85526 8.1455C4.85525 6.26036 6.40484 4.72754 8.33169 4.68876Z"
            fill="#292A33"
        />
        <path
            d="M13.3626 17.8296C14.2906 17.8296 15.043 17.1025 15.043 16.2059C15.043 15.3091 14.2906 14.582 13.3626 14.582H13.1094C14.0374 14.582 14.7898 15.3091 14.7898 16.2059C14.7898 17.1025 14.0374 17.8296 13.1094 17.8296H13.3626Z"
            fill="#292A33"
        />
        <path
            d="M13.0861 14.582H12.8329C11.9049 14.582 11.1523 15.3091 11.1523 16.2059C11.1523 17.1025 11.9049 17.8296 12.8329 17.8296H13.0861C12.1579 17.8296 11.4055 17.1025 11.4055 16.2059C11.4056 15.3091 12.158 14.582 13.0861 14.582Z"
            fill="#292A33"
        />
        <path
            opacity="0.8"
            d="M0.3125 13.8487V14.6717H1.30222V17.8078H2.29229V14.6939H3.09787L3.37409 13.8487H0.3125ZM8.41546 13.8487V14.6717H9.4052V17.8078H10.3953V14.6939H11.2008L11.477 13.8487H8.41546ZM3.55845 14.3157C3.55845 14.0579 3.77449 13.8487 4.04149 13.8487C4.30866 13.8487 4.52498 14.0579 4.52498 14.3157C4.52498 14.5737 4.30877 14.7827 4.04149 14.7827C3.77449 14.7827 3.55845 14.5737 3.55845 14.3157ZM3.55845 15.1165H4.52498V17.8078H3.55845V15.1165ZM4.93943 13.8487V17.8078H5.90631V16.7848L6.20562 16.5176L7.14927 17.8301H8.1851L6.82683 15.9171L8.04688 14.7605H6.87294L5.90629 15.6945V13.8486H4.93943V13.8487ZM15.3672 13.8487V17.8078H16.3341V16.7848L16.6333 16.5176L17.5773 17.8301H18.6132L17.255 15.9171L18.4749 14.7605H17.3011L16.3342 15.6945V13.8486H15.3672V13.8487Z"
            fill="#292A33"
        />
        <path
            opacity="0.8"
            d="M13.1096 17.8296C14.0378 17.8296 14.7902 17.1025 14.7902 16.2059C14.7902 15.3091 14.0378 14.582 13.1096 14.582H13.0867C12.1587 14.582 11.4062 15.3091 11.4062 16.2059C11.4062 17.1025 12.1587 17.8296 13.0867 17.8296H13.1096ZM12.2811 16.2059C12.2811 15.7697 12.647 15.4164 13.0985 15.4164C13.5495 15.4164 13.9155 15.7697 13.9155 16.2059C13.9155 16.6421 13.5495 16.9956 13.0985 16.9956C12.647 16.9956 12.2811 16.6421 12.2811 16.2059Z"
            fill="#292A33"
        />
    </svg>
)
