import { SelectItemAvailableEvent } from '../client-data-types/card-types'
import { DataLayerEventName } from '../helpers/DataLayerEventName'
import { Product } from '../__product'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import {
    ArticleAvailableEvent,
    EpaperPublicationType,
    Issue,
    ViewItemListAvailableEvent,
} from '..'

interface EditionViewItemListProps {
    item_brand: string
    index: number
    item_category: string
    item_category2: string
    item_id: string
    item_name: string
    item_heading: string
    item_variant: boolean
    price: 0.0
}
interface EditionSelectItemProps extends EditionViewItemListProps {
    link_text: string
    link_url: string
}
export type EditionEvent =
    | SelectItemAvailableEvent
    | ViewItemListAvailableEvent
    | ArticleAvailableEvent

type ValidCategories =
    | 'thenightly_specific_edition_page'
    | 'thenightly_editions_page'
    | 'thenightly_editions_page_latest_editions'
    | 'editions_page_readers_also_love_carousel'
    | 'editions_page_latest_editions_carousel'
    | 'editions_page_readers_also_love'
    | 'editions_page_latest_editions'
    | 'perthnow_editions_page'
    | `perthnow_${EpaperPublicationType}_editions_page`
    | `thewest_${EpaperPublicationType}_editions_page`

interface EditionViewItemListEventProps {
    editions?: EditionV4DTO[]
    issues?: Issue[]
    collectionName: string
    itemCategory: ValidCategories
}

/**
 * Returns an Edition payload in the shape required by the `view_item_list` data layer event
 */
export function createEditionViewItemListEvent({
    editions,
    issues,
    collectionName,
    itemCategory,
}: EditionViewItemListEventProps): EditionEvent | undefined {
    let cardInformation: Array<Partial<EditionViewItemListProps>>

    if (editions) {
        cardInformation = editions.map((edition, index) => {
            return {
                index,
                item_brand: formattedBrandNames.get('the-nightly'),
                item_category: itemCategory,
                item_category2: 'edition',
                item_id: edition.id,
                item_name: edition.title,
                item_heading: edition.heading,
                item_variant: false,
                price: 0,
            }
        })
    } else if (issues) {
        cardInformation = issues.map((issue, index) => {
            const category =
                issue.publicationName === 'default'
                    ? 'the-west-australian'
                    : issue.publicationName
            return {
                index,
                item_brand: formattedBrandNames.get(issue.publicationName),
                item_category: `${category}_${itemCategory}`,
                item_category2: 'edition',
                item_id: issue.url,
                item_name: issue.date.toLocaleString(),
                item_variant: false,
                price: 0,
            }
        })
    } else {
        return undefined
    }

    return {
        type: DataLayerEventName.viewItemList,
        originator: collectionName,
        payload: {
            cardInformation,
        },
    }
}
interface ItemProps {
    category: ValidCategories
    linkText: string
    linkUrl: string
    itemName?: string
}
interface EditionSelectItemEventProps {
    edition?: EditionV4DTO
    issue?: Issue
    collectionName: string
    product: Product
    itemDetails: ItemProps
}
/**
 * Returns an Edition payload in the shape required by the `select_item` data layer event
 */
export function createEditionSelectItemEvent({
    edition,
    issue,
    collectionName,
    product,
    itemDetails,
}: EditionSelectItemEventProps): EditionEvent {
    let payload: Partial<EditionSelectItemProps>

    if (edition) {
        payload = {
            index: 0,
            item_brand: formattedBrandNames.get('the-nightly'),
            item_category: itemDetails.category,
            item_category2: 'edition',
            item_id: edition.id,
            item_name: itemDetails.itemName || edition.heading,
            item_variant: false,
            price: 0,
            link_text: itemDetails.linkText,
            link_url: itemDetails.linkUrl,
        }
    } else if (issue) {
        payload = {
            index: 0,
            item_brand: formattedBrandNames.get(issue.publicationName),
            item_category: itemDetails.category,
            item_category2: 'edition',
            item_name: itemDetails.itemName || issue.publicationName,
            item_variant: false,
            price: 0,
            link_text: itemDetails.linkText,
            link_url: itemDetails.linkUrl,
        }
    } else {
        payload = {
            index: 0,
            item_brand: product,
            item_category: itemDetails.category,
            item_category2: 'edition',
            item_name: itemDetails.itemName,
            item_variant: false,
            price: 0,
            link_text: itemDetails.linkText,
            link_url: itemDetails.linkUrl,
        }
    }

    return {
        type: DataLayerEventName.selectItemEvent,
        originator: collectionName,
        payload: {
            ...payload,
        },
    }
}

const formattedBrandNames = new Map<EpaperPublicationType, string>([
    ['albany-advertiser', 'Albany Advertiser'],
    ['the-extra', 'The Extra'],
    ['great-southern-herald', 'Great Southern Herald'],
    ['augusta-margaret-river-times', 'Augusta Margaret River Times'],
    ['busselton-dunsborough-times', 'Busselton Dunsborough Times'],
    ['broome-advertiser', 'Broome Advertiser'],
    ['the-kimberley-echo', 'The Kimberley Echo'],
    ['geraldton-guardian', 'Geraldton Guardian'],
    ['midwest-times', 'Midwest Times'],
    ['bunbury-herald', 'Bunbury Herald'],
    ['harvey-waroona-reporter', 'Harvey-Waroona Reporter'],
    ['manjimup-bridgetown-times', 'Manjimup-Bridgetown Times'],
    ['south-western-times', 'South Western Times'],
    ['kalgoorlie-miner', 'Kalgoorlie Miner'],
    ['narrogin-observer', 'Narrogin Observer'],
    ['north-west-telegraph', 'North West Telegraph'],
    ['pilbara-news', 'Pilbara News'],
    ['sound-telegraph', 'Sound Telegraph'],
    ['countryman', 'Countryman'],
    ['default', 'The West Australian'],
    ['perthnow', 'PerthNow'],
    ['joondalup', 'PerthNow Joondalup'],
    ['wanneroo', 'PerthNow Wanneroo'],
    ['stirling', 'PerthNow Stirling'],
    ['western-suburbs', 'PerthNow Western Suburbs'],
    ['central', 'PerthNow Central'],
    ['southern', 'PerthNow Southern'],
    ['canning', 'PerthNow Canning'],
    ['cockburn', 'PerthNow Cockburn'],
    ['fremantle', 'PerthNow Fremantle'],
    ['melville', 'PerthNow Melville'],
    ['mandurah-times', 'PerthNow Mandurah'],
    ['the-nightly', 'The Nightly'],
    ['sunday-times', 'The Sunday Times'],
    ['the-west-australian', 'The West Australian'],
    ['stm', 'STM'],
    ['play', 'Play'],
])
