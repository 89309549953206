import { Topic } from '@news-mono/web-common'

export const getTopicSponsor = (topic: Topic) => {
    if (
        topic.id === 'business/public-companies' ||
        topic.id === 'business/bulls-n-bears'
    ) {
        return 'bullsnbears'
    }
    return undefined
}
