/* eslint-disable @typescript-eslint/no-var-requires */
import styled from '@emotion/styled'
import { calcRem } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { GetMainSectionFn } from '../custom-topic-routes'

const desktopAppBanner = require<string>('./assets/subscriber-exclusive/The-West-App_Desktop-Banner-906x212px.png')
const mobileAppBanner = require<string>('./assets/subscriber-exclusive/The-West-App_Mobile-Banner-343x344px.png')
// subscriber-exclusive-subscriber-series
export const getSubscriberFeaturesRouteInfo: GetMainSectionFn = ({}) => {
    return [
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `VANISHING COUSINS`,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'sub-exclusive-vanishing-cousins',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            feature: 'subscriber-exclusive-subscriber-service-curation',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `VIDEO CHANNELS AND PODCASTS`,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'video-channels-and-podcasts',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `PUZZLES, COMPS, REWARDS, NEWSLETTERS`,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'features-puzzles-comps-rewards-newsletters',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        // App Banner
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: mobileAppBanner,
                verticalGutters: ['unset', 'md'],
                altText:
                    'Download the West App for 24/7 access to breaking news direct to your device',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            {
                                src: desktopAppBanner,
                                density: '2x',
                            },
                            {
                                src: desktopAppBanner,
                                density: '1x',
                            },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            {
                                src: desktopAppBanner,
                                density: '2x',
                            },
                            {
                                src: desktopAppBanner,
                                density: '1x',
                            },
                        ],
                    },
                    {
                        src: [
                            {
                                src: mobileAppBanner,
                                density: '2x',
                            },
                            {
                                src: mobileAppBanner,
                                density: '1x',
                            },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `FOOD HUB`,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'features-food-hub',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `Sports`,
                    headingUrl: '/sport',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport'],
                    publicationKind: 'article',
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `BUSINESS`,
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/economy'],
                    publicationKind: 'article',
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: `COURTS AND CRIME`,
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/crime'],
                    publicationKind: 'article',
                    paging: {
                        page: 1,
                        pageOffset: 0,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}

export const StyledComingSoonBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#001D56',
    marginBottom: calcRem(theme.margins.md),
}))
