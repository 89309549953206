import { AmpPicture } from '../../content/Picture/Picture.amp'
import {
    ResponsivePictureProps,
    WebPicture,
} from '../../content/Picture/Picture.web'
import { createRenderTarget } from '../../render-target'
import { SimpleImg } from './SimpleImg'

export const ResponsivePicture = createRenderTarget<ResponsivePictureProps>(
    'Picture',
    {
        amp: AmpPicture,
        web: WebPicture,
        rss: SimpleImg,
        preview: WebPicture,
        app: WebPicture,
    },
)
