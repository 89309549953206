import React from 'react'
import { TheWestReturn } from '../TheWestReturn/TheWestReturn'
import { StyledReturnLink } from './TheWestHeaderLogin.styled'

export const RegionalActionLink: React.FC = () => {
    return (
        <StyledReturnLink to="https://thewest.com.au" isPrimary={true}>
            <TheWestReturn />
        </StyledReturnLink>
    )
}

RegionalActionLink.displayName = 'RegionalActionLink'
