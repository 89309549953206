import {
    AppState,
    ConsentLevel,
    Product,
    scriptExists,
    useConsentGiven,
    useFeature,
} from '@news-mono/web-common'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventQueue } from '..'

interface IndexExchangeProps {
    product: Product
}

export const MagniteWrapper: React.FC<IndexExchangeProps> = ({ product }) => {
    const isEnvironmentToggleEnabled = useFeature('magnite-custom-environment')

    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const isToggleEnabled = useFeature('magnite-header-tag-wrapper')
    const consentRequiredLevel = ConsentLevel.Advertising
    const consentGiven = useConsentGiven({ consentRequiredLevel })

    let scriptSrc = '//micro.rubiconproject.com/prebid/dynamic/16378.js'

    // If the rendition type is app, add app environment content, else
    // if it's amp add amp, otherwise treat it all the same.
    if (isEnvironmentToggleEnabled) {
        if (renditionType === 'app') {
            scriptSrc += '?environment=app'
        } else if (renditionType === 'amp') {
            scriptSrc += '?environment=amp'
        }
    }

    const isLocalEnv =
        typeof window !== 'undefined' &&
        window.location.href.includes('localhost')
    const identifier = isLocalEnv ? 'http' : 'https'

    const addScript = useCallback(() => {
        if (
            scriptExists(`${identifier}:${scriptSrc}`) ||
            !consentGiven ||
            !isToggleEnabled
        ) {
            return
        }

        const script = document.createElement('script')
        script.src = scriptSrc
        script.type = 'text/javascript'
        script.async = true
        document.head.appendChild(script)
    }, [isToggleEnabled, consentGiven, identifier, scriptSrc])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            addScript()
        } else {
            eventQueue.addEvent({
                eventName: 'load-magnite-script',
                callbackFunctions: [addScript],
            })
        }
    }, [isToggleEnabled, consentGiven, addScript, product])

    return <></>
}
