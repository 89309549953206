import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { PaperPromo } from './PaperPromo'

export const PaperPromoRegistration = createRegisterableComponent(
    'paper-promo',
    (_props, services) => {
        return <PaperPromo onEvent={services.onEvent} />
    },
)
