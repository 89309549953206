import React from 'react'

export const IconTickCircled = ({
    width,
    height,
    color,
    idSuffix,
}: {
    width: number
    height: number
    color?: string
    idSuffix: string
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 9L7 11L11 5"
            stroke={color || `url(#gradientTick${idSuffix || ''})`}
            strokeWidth="2"
            strokeLinecap="round"
        />
        <circle
            cx="8"
            cy="8"
            r="7.5"
            stroke={color || `url(#gradientCircle${idSuffix || ''})`}
        />
        <defs>
            <linearGradient
                id={`gradientTick${idSuffix || ''}`}
                x1="11"
                y1="8"
                x2="5"
                y2="8"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#DC01BE" />
                <stop offset="1" stopColor="#AA01E1" />
            </linearGradient>
            <linearGradient
                id={`gradientCircle${idSuffix || ''}`}
                x1="16.1"
                y1="8"
                x2="0"
                y2="8"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#DC01BE" />
                <stop offset="1" stopColor="#AA01E1" />
            </linearGradient>
        </defs>
    </svg>
)
