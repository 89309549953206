import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../__styling'
import { IconCheckmarkTN } from '../../icons'
import { IconAlertCircleTN } from '../../icons/IconAlertCircleTN/IconAlertCircleTN'
import React from 'react'
import { TNToastProps } from './TNToastNotification.routing'

export const StyledToastIconContainer = styled('div')({
    display: 'flex',
    maxWidth: calcRem(24),
    maxHeight: calcRem(24),
    width: '100%',
    margin: `auto ${calcRem(8)} auto 0`,
})

const ToastCheckmarkIcon = () => (
    <IconCheckmarkTN
        strokeColor={tokens.thenightly.colors.palette.utility.success.dark}
    />
)
export const StyledToastCheckmarkIcon = styled(ToastCheckmarkIcon)({
    width: calcRem(24),
    height: calcRem(24),
})

export const StyledToastInfoIcon: React.FC<TNToastProps> = (props) => (
    <IconAlertCircleTN
        strokeColor={
            props.type === 'warning'
                ? tokens.thenightly.colors.palette.utility.warning.dark
                : props.type === 'error'
                ? tokens.thenightly.colors.palette.utility.error.dark
                : tokens.thenightly.colors.palette.neutral[100]
        }
    />
)
