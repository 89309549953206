import {
    NavEventArgs,
    NavItem,
    searchCrawlerMastheadWhitelist,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledButtonText,
    StyledChevronDown,
    StyledLink,
    StyledLinkContainer,
    StyledRollUp,
    StyledToggleButton,
    StyledWrapper,
} from '../../../../navigation/FlyoutNav/components/FlyoutParentItem/FlyoutParentItem.styled'
import { KeyCode } from '../../../../__helpers/key-code'

export interface FlyoutParentItemProps {
    navItem: NavItem
    onClick?: (args: NavEventArgs) => void
    showToggle?: boolean
    isOpen?: boolean
    marker?: () => React.ReactNode
    showPseudoElement?: boolean
    toggleAction?: any
    navPos: number
    subNavId?: string
    isCurrent?: boolean
    linkStyle: 'heading' | 'standard'
}

export const FlyoutParentItem: React.FC<FlyoutParentItemProps> = ({
    marker,
    navItem,
    onClick,
    showToggle,
    toggleAction,
    navPos,
    isOpen,
    subNavId,
    isCurrent,
    linkStyle,
}) => {
    const keyDownHandler = (evt: React.KeyboardEvent) => {
        const isClosedAndDown = !isOpen && evt.which === KeyCode.DOWN_ARROW
        const isOpenAndUp = isOpen && evt.which === KeyCode.UP_ARROW
        if (isClosedAndDown || isOpenAndUp) {
            toggleAction()
        }
    }

    const isCrawlerWhitelisted = searchCrawlerMastheadWhitelist(navItem.link)

    return (
        <StyledWrapper>
            <StyledLinkContainer linkStyle={linkStyle}>
                <StyledLink
                    linkStyle={linkStyle}
                    to={navItem.link}
                    target={navItem.newWindow ? '_blank' : undefined}
                    rel={
                        navItem.newWindow && !isCrawlerWhitelisted
                            ? 'noopener'
                            : undefined
                    }
                    openExternalInNewWindow={navItem.newWindow}
                    onClick={() => {
                        onClick &&
                            onClick({
                                navPos,
                                navText: navItem.name,
                                navLink: navItem.link,
                                navParent: parent.name,
                            })
                    }}
                    isCurrent={!!isCurrent}
                    forceInternalSSR={navItem.forceInternalSSR}
                >
                    {marker && marker()}

                    {navItem.name}
                </StyledLink>
            </StyledLinkContainer>
            {showToggle && (
                <StyledToggleButton
                    type="button"
                    onClick={toggleAction}
                    onKeyDown={keyDownHandler}
                    aria-controls={subNavId}
                    aria-expanded={isOpen}
                    aria-haspopup
                >
                    {!isOpen ? (
                        <React.Fragment>
                            <StyledButtonText>
                                Reveal sub navigation
                            </StyledButtonText>
                            <StyledChevronDown />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <StyledButtonText>
                                Hide sub navigation
                            </StyledButtonText>
                            <StyledRollUp />
                        </React.Fragment>
                    )}
                </StyledToggleButton>
            )}
        </StyledWrapper>
    )
}
