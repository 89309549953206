import React from 'react'
import styled from '@emotion/styled'
import { textMixin } from '../../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '../../../../../__styling'
import { WebLink, WebLinkProps } from '@news-mono/web-common'

type TopicProps = WebLinkProps & {}

export const TopicLink = ({ ...props }: TopicProps) => (
    <Topic>
        <StyledTopicLink {...props} />
    </Topic>
)

const Topic = styled.div(({ theme }) => [
    {
        color: theme.colors.text.secondary,
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-primary'),
])

const StyledTopicLink = styled(WebLink)({
    // Remove default anchor styling.
    color: 'unset',
    textDecoration: 'none',

    // Underline Headline when hovered or active.
    [`&:is(:hover, :focus)`]: {
        textDecoration: 'underline',
    },
})
