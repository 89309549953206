import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { TheWestSection } from '@news-mono/common'

export const getOlympicMedalTallyRouteInfo: StaticRoutes<TheWestSection> = {
    '/sport/olympics/medal-tally': ({ getAdTargeting, config, store }) => {
        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'paris-olympics-widgets',
        )

        if (!enableRoute) {
            return null
        }

        const canonicalUrl = config.publicUrl + '/sport/olympics/medal-tally'

        return {
            kind: 'page',
            heading: 'Medal Tally',
            pageType: 'topic',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: 'Medal Tally',
                description:
                    'Shows the medal tally for nations competing in the Paris Olympics',
                link: [{ rel: 'canonical', href: canonicalUrl }],
            },
            socialMeta: {
                title: 'Medal Tally',
                description:
                    'Shows the medal tally for nations competing in the Paris Olympics',
            },
            adTargeting: getAdTargeting(
                'home',
                'default',
                'sport/olympics/medal-tally',
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: 'outerMargin',
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    items: [
                                        { text: 'sport', href: '/sport' },
                                        {
                                            text: 'olympics',
                                            href: '/sport/olympics',
                                        },
                                        {
                                            text: 'medal tally',
                                            href: '/sport/olympics/medal-tally',
                                        },
                                    ],
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    layoutRatio: '3:1',
                                    stretchColumns: true,
                                    verticalSpacing: 'xl',
                                    hasSidebar: true,
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'olympics-medal-tally',
                                            props: {
                                                size: 'full',
                                                listSize: 50,
                                                svgColourOverride: '#06671e',
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                hiddenUntilLoaded: true,
                                                padding: [0, 0, 13, 0],
                                                slot: {
                                                    id: 'sidebar-one',
                                                    size: 'desktopMrecHalfPage',
                                                },
                                                adType: 'inline',
                                            },
                                        }),
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                hiddenUntilLoaded: true,
                                                padding: [0, 0, 13, 0],
                                                slot: {
                                                    id: 'sidebar-two',
                                                    size: 'above768Mrec',
                                                },
                                                adType: 'inline',
                                            },
                                        }),
                                    ],
                                },
                            }),
                            ...billboardLeaderboardMrec('three'),
                        ],
                    },
                }),
            ],
        }
    },
}
