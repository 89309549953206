import { MaybeLoaded } from 'json-react-layouts-data-loader'

/**
 * Converts from a collection level MaybeLoaded<Array<Card>> to
 * Array<MaybeLoaded<Card>> so the items can be passed down into cards
 */
export function invertMaybeLoadedItems<T>(
    maybeLoadedItems: MaybeLoaded<T[]>,
    expectedCards: number,
) {
    const items: MaybeLoaded<T>[] = maybeLoadedItems.loaded
        ? maybeLoadedItems.result.map((item) => ({
              loaded: true,
              result: item,
          }))
        : Array.from<undefined>(Array(expectedCards)).map(() => ({
              loaded: false,
          }))

    return items
}
