import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { zIndex } from '../../__styling/settings/z-index'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { HeartIcon } from './assets/HeartIcon'

export const telethonRedColor = '#DE1529'
export const telethonBlue = '#2873F2'
export const telethonBlueDark = '#0B4499'

export const heartIconClassname = 'heart-icon'

export const pulsingHeart = keyframes({
    '0%': {
        transform: 'scale( 1 )',
    },
    '20%': {
        transform: 'scale( .75 )',
    },
    '40%': {
        transform: 'scale( 1 )',
    },
    '60%': {
        transform: 'scale( .75 )',
    },
    '80%': {
        transform: 'scale( 1 )',
    },
    '100%': {
        transform: 'scale( 1 )',
    },
})

export const StyledHeart = styled(HeartIcon)({
    animation: `${pulsingHeart} 1.5s infinite`,
})

export const StyledTelethonCountdown = styled('div')(({ theme }) => ({
    background:
        'linear-gradient(110.54deg, #2873F2 0%, #4BA0EE 59%, #5CADFC 59.63%, #3795F4 60.13%, #0B55D1 94.94%)',
    position: 'relative',
    display: 'flex',
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(20),
    lineHeight: themedValue(theme, { fallback: 1.05, thewest: 1.154 }),
    overflow: 'hidden',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    maxWidth: calcRem(1220),
    borderTopRightRadius: 0,
    borderBottomRightRadius: calcRem(50),
    borderBottomLeftRadius: calcRem(50),
    zIndex: 1, // zIndex fixes issue with overflow not being hidden over a border radius
    paddingBottom: calcRem(60),

    [breakpoint('sm')]: {
        background:
            'linear-gradient(125deg, #2873F2 -2.16%, #4BA0EE 66.06%, #5CADFC 66.73%, #3795F4 67.21%, #0B55D1 102.89%)',
        paddingBottom: 0,
    },

    [breakpoint('md')]: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        background:
            'linear-gradient(114deg, #2873F2 3.68%, #4BA0EE 46.67%, #5CADFC 46.81%, #3795F4 46.94%, #0B55D1 91.38%)',
        height: theme.kind === 'perthnow' ? calcRem(180) : calcRem(150),
    },
}))

export const StyledTelethonTrianglesLeft = styled('img')(({ theme }) => ({
    width: 110,
    height: 188,
    position: 'absolute',
    transform: 'translate(-34px, 0px)',

    [breakpoint('sm')]: {
        position: 'relative',
        transform: 'translate(-64px, 0px)',
    },

    [breakpoint('md')]: {
        transform: 'translate(0, -50px)',
    },
}))

interface StyledTelethonShapesRightProps {
    hiddenCountdown: boolean
}

export const StyledTelethonShapesRight = styled(
    'img',
)<StyledTelethonShapesRightProps>(({ theme, hiddenCountdown }) => ({
    display: 'none',

    [breakpoint('sm')]: {
        display:
            theme.kind === 'perthnow' || hiddenCountdown === false
                ? 'none'
                : 'block',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 'auto',
        height: 127,
    },

    [breakpoint('md')]: {
        height: 97,
    },
}))

export const StyledTelethonKids = styled('div')(({ theme }) => ({
    display: 'none',

    [breakpoint('sm')]: {
        display: 'block',
        position: 'absolute',
        overflow: 'hidden',
        zIndex: zIndex.common.telethonKids,

        img: {
            width: 275,
            transform: 'translateX(-20px)',
        },
    },
    [breakpoint('md')]: {
        img: {
            width: theme.kind === 'perthnow' ? 250 : 208,
            transform: theme.kind === 'perthnow' ? 'none' : 'translateX(-7px)',
        },
    },
}))

export const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    transform: 'translate(0, 36px)',
    margin: '0 auto',
    textAlign: 'center',
    alignSelf: 'center',
    padding: calcRem(0, 16),
    flexDirection: theme.kind === 'perthnow' ? 'column' : 'row',

    [breakpoint('sm')]: {
        transform: 'translate(140px, 0)',
        margin: '0',
        textAlign: 'left',
        padding: 0,
    },

    [breakpointMax('md')]: {
        marginBottom: 27,
        flexDirection: 'column',
    },

    [breakpointBetween('sm', 'md')]: {
        marginRight: themedValue(theme, {
            perthnow: '200px',
            fallback: undefined,
        }),
    },

    [breakpointBetween('md', 'lg')]: {
        alignItems: 'center',
    },

    [breakpoint('md')]: {
        transform: themedValue(theme, {
            perthnow: 'translate(100px,0)',
            thewest: 'translate(80px,0)',
            fallback: undefined,
        }),
    },

    [breakpoint('lg')]: {
        transform: themedValue(theme, {
            perthnow: 'translate(120px,0)',
            thewest: 'translate(100px,0)',
            fallback: undefined,
        }),
    },
}))

export const LogoAndInfoContent = styled('div')<{ hiddenCountdown: boolean }>(
    ({ theme }) => ({
        color: theme.colors.palette.white,
        width: '100%',
        maxWidth: '330px',

        p: {
            fontWeight: 400,
            margin: themedValue(theme, {
                fallback: calcRem(10, 0),
                thewest: calcRem(15, 0),
            }),

            [`@media (max-width: 659px)`]: {
                margin: calcRem(15, 0),
            },
        },

        [breakpoint('xs')]: {
            padding: calcRem(15, 0, 0),
            width: 'auto',
            maxWidth: 'none',
        },
    }),
    ({ theme }) =>
        theme.kind !== 'thewest' && {
            [`@media (min-width: 1019px)`]: {
                width: calcRem(370),

                p: {
                    fontSize: calcRem(19),
                },
            },
        },
)

export const StyledTelethonLogo = styled('img')({
    width: calcRem(205),
    height: calcRem(31),

    [breakpoint('sm')]: {
        width: calcRem(256),
        height: calcRem(40),
        marginLeft: calcRem(-7),
    },
})

export const DonateContent = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    padding: theme.kind !== 'thewest' ? 0 : calcRem(20),

    [`@media (max-width: 1019px)`]: {
        padding: 0,
    },

    [`@media (max-width: 659px)`]: {
        flexDirection: 'column',
        marginRight: 0,

        a: {
            marginBottom: calcRem(15),
        },
    },

    [breakpointBetween('md', 'lg')]: {
        flexDirection: themedValue(theme, {
            perthnow: 'row',
            fallback: 'column',
        }),

        paddingTop: theme.kind === 'perthnow' ? '7px' : undefined,
        marginBottom: theme.kind === 'perthnow' ? '4px' : undefined,

        [`& ${DonateNow}`]: {
            marginBottom: calcRem(15),
        },
    },
    [breakpoint('lg')]: {
        paddingTop: theme.kind === 'perthnow' ? '7px' : undefined,
        marginBottom: theme.kind === 'perthnow' ? '14px' : undefined,
    },
}))

export const DonateNow = styled(WebLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: calcRem(10, 20),
    backgroundColor: colors.white,
    color: telethonBlue,
    width: calcRem(170),
    height: calcRem(40),
    fontWeight: 400,
    borderRadius: themedValue(theme, {
        fallback: calcRem(20),
        thewest: calcRem(24),
    }),
    textDecoration: 'none',
    outline: 'none',
    zIndex: 2, // needs to be higher than the other element thats zIndex 1 to fix an ios issue
    fontSize: themedValue(theme, {
        fallback: calcRem(20),
        sevennews: calcRem(16),
    }),
    ...transition({ properties: ['background-color'], speed: 'fast' }),

    [`@media (min-width: 660px)`]: {
        marginRight: themedValue(theme, {
            perthnow: calcRem(5),
            thewest: calcRem(5),
            sevennews: calcRem(15),
            fallback: undefined,
        }),
    },

    [`@media (min-width: 769px)`]: {
        marginRight: themedValue(theme, {
            perthnow: calcRem(5),
            thewest: calcRem(30),
            sevennews: calcRem(20),
            fallback: undefined,
        }),
    },

    svg: {
        marginRight: calcRem(10),
        path: {
            fill: telethonBlue,
        },
    },

    '&:hover': {
        backgroundColor: telethonBlueDark,
        color: colors.white,
    },

    '&:focus': {
        color: telethonBlueDark,
        backgroundColor: colors.white,
        border: `solid 1px ${telethonBlueDark}`,
        svg: {
            path: {
                fill: telethonBlueDark,
            },
        },
    },
}))
