import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { FundingLabel } from '../../advertising/FundingLabel/FundingLabel'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledBylineClassName = 'CardByline'
export const StyledBylineTextClassName = 'CardByline-Text'
export const StyledHeadshotWrapperClassName = 'CardByline-HeadshotWrapper'

export interface StyledBylineProps {
    hasPadding: boolean
}

export const StyledByline = withClass(StyledBylineClassName)(
    styled('div')<StyledBylineProps>(({ theme, hasPadding }) => ({
        fontWeight: 400,
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.kind === 'thenightly' ? undefined : calcRem(8),
        marginRight: calcRem(theme.margins.xs),
        padding: hasPadding
            ? themedValue(theme, {
                  thewest: calcRem(
                      0,
                      theme.cards.outerMargin,
                      theme.cards.outerMargin,
                      0,
                  ),
                  sevennews: calcRem(
                      0,
                      metrics.sevennews.cards.outerMargin,
                      metrics.sevennews.cards.outerMargin,
                      metrics.sevennews.cards.outerMargin,
                  ),
                  fallback: undefined,
              })
            : undefined,
    })),
)

export const StyledBylineText = withClass(StyledBylineTextClassName)(
    styled('p')(({ theme }) => ({
        fontStyle: 'normal',
        marginTop: calcRem(16),
        margin: 0,
        alignSelf: 'center',
        color:
            theme.kind === 'thenightly'
                ? theme.colors.text.secondary
                : undefined,
        textTransform: theme.kind === 'thenightly' ? 'uppercase' : undefined,
        fontSize: themedValue(theme, {
            thewest: calcRem(12),
            sevennews: calcRem(14),
            perthnow: calcRem(14),
            thenightly: calcRem(12),
            fallback: undefined,
        }),
        fontFamily: themedValue(theme, {
            thewest: fonts.thewest.serif,
            sevennews: fonts.sevennews.sansSerif,
            fallback: undefined,
        }),
        fontWeight: themedValue(theme, {
            thewest: 500,
            thenightly: 400,
            fallback: 700,
        }),
    })),
)

export const StyledBylineTextWithHeadshot = styled(StyledBylineText)({
    width: `calc(100% - ${calcRem(53)} - ${calcRem(10)})`,
})

export const StyledBylineName = styled('span')(
    {
        fontWeight: 700, // Temporary while we work out getting proper font
        display: 'block',
        lineHeight: 1.2,
    },
    (props) => ({
        marginBottom: themedValue(props.theme, {
            thewest: 0,
            sevennews: metrics.sevennews.cards.textOffset,
            fallback: undefined,
        }),
    }),
)

export const StyledBylinePosition = styled('span')(
    {
        fontWeight: 400,
        display: 'block',
        lineHeight: 1.2,
    },
    (props) => ({
        color: props.theme.colors.text.secondary,
    }),
)

export const StyledHeadshotWrapper = withClass(StyledHeadshotWrapperClassName)(
    styled('div')({
        display: 'block',
        borderRadius: '50%',
        marginRight: calcRem(10),
        overflow: 'hidden',
        width: calcRem(53),
        height: calcRem(53),
    }),
)

export const StyledHeadshot = styled('img')({
    width: calcRem(53),
    height: calcRem(53),
    filter: 'grayscale(100%)',
})

export const StyledFundingLabel = styled(FundingLabel)({}, (props) => ({
    display: 'block',
    width: themedValue(props.theme, {
        thewest: '100%',
        fallback: undefined,
    }),
}))

export const StyledBylinePublicationDate = styled('span')({
    fontWeight: 400,
})
