import styled from '@emotion/styled'
import { IconClock } from '../../icons/IconClock/IconClock'
import { IconClockAlt } from '../../icons/IconClockAlt/IconClockAlt'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'

const iconColor = (theme: Theme) =>
    themedValue(theme, {
        perthnow: colors.perthnow.greyStone,
        thewest: colors.thewest.greyHippo,
        sevennews: colors.sevennews.stormGrey,
        fallback: undefined,
    })

export const StyledTimestamp = styled('time')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.fonts.sansSerif,
    fontSize: themedValue(theme, {
        thewest: calcRem(15),
        perthnow: calcRem(14),
        sevennews: calcRem(11),
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, { sevennews: 600, fallback: 400 }),
    color: themedValue(theme, {
        thewest: colors.thewest.greyGorilla,
        perthnow: colors.perthnow.greyCoal,
        sevennews: colors.sevennews.stormGrey,
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        perthnow: 1.429,
        thewest: 1.25,
        fallback: undefined,
    }),

    '&::after': {
        display: 'block',
    },
}))

export const ClockIcon = styled(IconClock)(
    {
        width: 14,
        height: 14,
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        marginRight: calcRem(5),
    },
    ({ theme }) => ({
        stroke: iconColor(theme),
    }),
)

export const ClockIconAlt = styled(IconClockAlt)(
    {
        position: 'relative',
        marginRight: calcRem(6),
        width: 12,
        height: 12,
    },
    ({ theme }) => ({ fill: iconColor(theme) }),
)
