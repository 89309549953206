import { supportsMutationObserver } from '@news-mono/web-common'
import throttle from 'lodash.throttle'
import React from 'react'
import { addListener, removeListener } from '../__helpers/global-dom-events'

export interface DimensionProps {
    width: number
    height: number
    innerRef: (ref: any) => void
    triggerMeasure: () => void
}

interface State {
    width: number
    height: number
}

export interface WithInnerRef {
    innerRef?: (ref: any) => void
}

/**
 * Calculates dimensions of element with a ref={innerRef} attribute
 * Use innerRef={innerRef} when applying to emotion components
 */
export function withDimensions<P>(
    WrappedComponent:
        | React.ComponentClass<P & DimensionProps>
        | React.FC<P & DimensionProps>,
): React.ComponentClass<P & WithInnerRef> {
    return class ComponentWithDimensions extends React.Component<
        P & WithInnerRef,
        State
    > {
        innerRef!: HTMLElement
        observer: MutationObserver | undefined
        constructor(props: P & WithInnerRef) {
            super(props)
            this.measureDimensions = throttle(this.measureDimensions, 32) // ~ 30 times a second ( 1000 / 30 )
            this.state = {
                width: 0,
                height: 0,
            }
        }

        componentDidMount() {
            this.measureDimensions()
            if (supportsMutationObserver()) {
                this.observer = new MutationObserver(this.measureDimensions)
                // innerRef is sometimes an object? Checking for .getBoundingClientRect ensures DOM node
                if (
                    this.innerRef &&
                    (this.innerRef as any).getBoundingClientRect
                ) {
                    this.observer.observe(this.innerRef, {
                        childList: true,
                        subtree: true,
                    })
                }
            }
            addListener('resize', this.measureDimensions)
        }

        componentWillUnmount() {
            removeListener('resize', this.measureDimensions)
            if (this.observer) {
                this.observer.disconnect()
            }
        }

        componentDidUpdate() {
            this.measureDimensions()
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    width={this.state.width}
                    height={this.state.height}
                    innerRef={this.setInnerRef}
                    triggerMeasure={this.measureDimensions}
                />
            )
        }

        setInnerRef = (innerRef: any) => {
            this.innerRef = innerRef
            if (this.props.innerRef) {
                // TypeScript can't infer function type correctly
                ;(this.props.innerRef as any)(innerRef)
            }
        }

        measureDimensions = () => {
            if (!this.innerRef || !this.innerRef.getBoundingClientRect) {
                return
            }

            const { width, height } = this.innerRef.getBoundingClientRect()

            const widthRounded = Math.round(width)
            const heightRounded = Math.round(height)

            if (
                heightRounded === this.state.height &&
                widthRounded === this.state.width
            ) {
                return
            }

            this.setState({ width: widthRounded, height: heightRounded })
        }
    }
}
