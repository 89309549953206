import styled from '@emotion/styled'
import { breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledDebugContainer = styled('div')<{
    width: number
    height: number
}>(
    {
        minHeight: calcRem(50),
        minWidth: calcRem(250),
        fontSize: calcRem(12),
        border: 'solid 2px #2bb210',
        overflow: 'scroll',
        textAlign: 'left',
        display: 'inline-block',
        wordBreak: 'break-word',
        padding: calcRem(2, 4),
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    ({ width, height }) => ({ width, height }),
)

export const StyledText = styled('span')<{ color: string }>(
    { display: 'inline-block', fontSize: calcRem(13) },
    ({ color }) => ({
        color,
    }),
)

export const StyledDebugItem = styled('span')({
    display: 'block',

    [breakpointMax('xxs')]: {
        display: 'inline-block',
        '::after': {
            whiteSpace: 'pre',
            content: `', '`,
        },
    },
})
