import { toArray } from '@news-mono/web-common'
import React from 'react'
import { StyledSkeleton } from '../../content/Skeleton/Skeleton.styled'

export interface SkeletonProps {
    count?: number
    dark?: boolean
    round?: boolean
    height?: number
    width?: number
    className?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({
    count = 1,
    dark,
    round,
    height,
    width,
    className,
}) => {
    const array = toArray(count)

    return (
        <span className={`${className} js-skeleton`}>
            {array.map((_: any, i: number) => (
                <StyledSkeleton
                    key={i}
                    dark={dark}
                    round={round}
                    customHeight={height}
                    customWidth={width}
                >
                    &zwnj;
                </StyledSkeleton>
            ))}
        </span>
    )
}
Skeleton.displayName = 'Skeleton'
