/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    ConsentLevel,
    Product,
    ScriptLoadResult,
    useScriptWithConsent,
} from '@news-mono/web-common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ContentSeparator } from '../content/ContentSeparator/ContentSeparator.styled'
import { metrics } from '../__styling/settings/metrics'
import { calcRem } from '../__styling/style-functions/calc-rem'
import { CoralProps } from './Coral'
import { CoralEventStream, handleCoralEvents } from './coral-event-stream'

/**
 * --------------------------------------
 * Coral Documentation can be found here:
 * https://docs.coralproject.net/cms
 * --------------------------------------
 */
declare const Coral: CoralScript

interface CoralStreamEmbedProps {
    storyID?: string
    storyURL?: string
    commentID?: string
    rootURL?: string
    id?: string
    autoRender?: boolean
    accessToken?: string
    enableDeprecatedEvents?: boolean
    customCSSURL?: string

    /** Allow setting className of body tag inside iframe */
    containerClassName?: string
    events?: (eventStream: CoralEventStream) => void
}

/**
 * Controls returned by the coral script.
 */
type CoralEmbed = {
    login: (accessToken: string) => void
    logout: () => void
}

interface CoralScript {
    createStreamEmbed: (props: CoralStreamEmbedProps) => CoralEmbed
}

function checkCoral() {
    return typeof Coral !== 'undefined' && Coral && Coral.createStreamEmbed
}

export interface CoralCommentsProps extends Omit<CoralProps, 'userSettings'> {
    coralScriptUrl: string
    hostName: string
    useContentSeperator: boolean
    canComment?: boolean
}

export const CoralComments: React.FC<CoralCommentsProps> = ({
    coralScriptUrl,
    storyID,
    storyURL,
    horizontalSpacing,
    onCommentEvent: onEvent,
    hostName,
    useContentSeperator,
    canComment = true,
}) => {
    const { kind } = useTheme()
    const [result] = useScriptWithConsent({
        consentRequiredLevel: ConsentLevel.Essential | ConsentLevel.Analytics,
        consentMet: { src: `${coralScriptUrl}/assets/js/embed.js` },
    })

    const [streamLoaded, updateStreamLoaded] = useState(false)

    const authState = useSelector((state: AppState) => state.authentication)

    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    // A workaround for a Safari bug which does not calculate the correct size on initial draw.
    const [display, setDisplay] = useState('block')

    const { coralToken, hasAttemptedValidation, checkingTokenState } = authState

    React.useEffect(() => {
        const loadStream =
            result === ScriptLoadResult.Success &&
            checkCoral() &&
            ((coralToken && kind === Product.TheWest) ||
                kind === Product.PerthNow ||
                kind === Product.TheNightly) &&
            hasAttemptedValidation &&
            !checkingTokenState

        // This allows us to insert some extra styles into the styleSheet that is used to style the Coral embed.
        const viewOnly =
            (!coralToken &&
                (kind === Product.PerthNow || kind === Product.TheNightly)) ||
            !canComment

        if (renditionType === 'app') {
            const redactedAuthState: AuthenticationState = {
                ...authState,
                userEmail: 'redacted',
                userName: 'redacted',
                wanUserId:
                    authState.wanUserId !== 'app-bad-token'
                        ? 'redacted'
                        : authState.wanUserId,
                auth0UserId: authState.auth0UserId ? 'redacted' : undefined,
            }

            console.info('INC-251 hasDuplicates', {
                auth0UserId: redactedAuthState.auth0UserId,
                wanUserId: redactedAuthState.wanUserId,
                coralToken: redactedAuthState.coralToken,
                canLoadStream: loadStream,
            })
        }

        if (loadStream) {
            Coral.createStreamEmbed({
                id: 'coral_thread',
                autoRender: true,
                rootURL: coralScriptUrl,
                accessToken: coralToken,
                containerClassName: `${kind}-comments`,
                customCSSURL: `${hostName}/coral.css${
                    viewOnly ? '?viewOnly=true' : ''
                }`,
                storyID,
                storyURL,
                events: function (eventStream) {
                    eventStream.onAny((event) => {
                        // Force safari to redraw.
                        if (event === 'ready') {
                            setDisplay('none')
                            setTimeout(() => {
                                setDisplay('block')
                            })
                        }
                    })
                    handleCoralEvents(eventStream, onEvent)
                },
            })
            updateStreamLoaded(true)
        }
    }, [
        result,
        coralScriptUrl,
        kind,
        storyID,
        storyURL,
        onEvent,
        coralToken,
        hasAttemptedValidation,
        checkingTokenState,
        canComment,
        renditionType,
        authState,
        hostName,
    ])

    return (
        <React.Fragment>
            <div
                id="coral_thread"
                className="hide-print"
                css={{
                    display: display,
                    margin: `0 ${
                        horizontalSpacing &&
                        calcRem(metrics.thewest.margins[horizontalSpacing])
                    }`,
                }}
            />
            {useContentSeperator && streamLoaded && (
                <ContentSeparator verticalBottomSpacing="lg" />
            )}
        </React.Fragment>
    )
}

export default CoralComments
