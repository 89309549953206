import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { metrics, breakpoint } from '../../../__styling'

export interface StyledContainerProps {
    showAsDivider?: boolean
    blockStyle?: 'comments' | 'default'
}

export const StyledPagesCTAContainer = styled('div')<StyledContainerProps>(
    ({ blockStyle }) => [
        {
            margin: `0`,
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            gap: calcRem(24),
            width: '100%',
        },
        blockStyle === 'default' && {
            padding: `${metrics.thenightly.margins.m2l}px 0`,
            borderTop: `1px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
            borderBottom: `1px solid ${tokens.thenightly.colors.palette.neutral[20]}`,

            a: {
                color: tokens.thenightly.colors.palette.section.opinion.default,
            },

            [breakpoint('sm')]: {
                padding: calcRem(24),
            },
        },
        blockStyle === 'comments' && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            padding: `${metrics.thenightly.margins.m2l}px`,
            background: tokens.thenightly.colors.palette.neutral[100],
            color: tokens.thenightly.colors.palette.white,

            p: {
                '&[type]': {
                    color: tokens.thenightly.colors.palette.black,
                },
            },

            a: {
                color: tokens.thenightly.colors.palette.starlight.default,
            },

            [breakpoint('sm')]: {
                padding: `${metrics.thenightly.margins.m2l}px 0`,
            },
        },
    ],
)
