import { FilterQueryParam } from '../FilterQueryParam'

export const latestSavedSortOption: FilterQueryParam = {
    displayName: 'Latest saved',
    paramName: 'desc',
}

export const oldestSavedSortOption: FilterQueryParam = {
    displayName: 'Oldest saved',
    paramName: 'asc',
}

export const relevanceSortOption: FilterQueryParam = {
    displayName: 'Relevance',
    paramName: 'relevance',
}

export const latestFirstSortOption: FilterQueryParam = {
    displayName: 'Latest first',
    paramName: 'desc',
}

export const oldestFirstSortOption: FilterQueryParam = {
    displayName: 'Oldest first',
    paramName: 'asc',
}

export const RESULT_SORT_PARAM = 'sort'

export const getSortOrder = (sortBy?: string) => {
    switch (sortBy) {
        case 'desc':
            return 'desc'
        case 'asc':
            return 'asc'
        default:
            return 'relevance'
    }
}
