import {
    CardEvents,
    CardItem,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { ReactElement } from 'react'
import { CardTakeover } from '../cards/CardTakeover/CardTakeover'

export interface CardSwitcherProps {
    item: MaybeLoaded<CardItem>
    /** Explains where the card is */
    cardContext: string
    cardNumber: number
    publicationCard: (
        item: MaybeLoaded<PublicationCardItem>,
    ) => ReactElement<any> | null
    onEvent: (event: CardEvents) => void
}
export const CardSwitcher: React.FC<CardSwitcherProps> = ({
    item,
    cardContext,
    publicationCard,
    cardNumber,
    onEvent,
}) => {
    // If a collection is accessing an item through an index we do not
    // get type safety, so just support accidently getting undefined
    if (!item) {
        console.warn(
            `CardSwitcher rendered with undefined item from ${cardContext}`,
        )
        return null
    }
    if (item.loaded) {
        switch (item.result.cardType) {
            case 'marketing-redirect-tile':
                return (
                    <CardTakeover
                        item={item.result}
                        onEvent={onEvent}
                        imageSrc={item.result.image}
                        imageAlt={item.result.altText}
                        linkUrl={item.result.url}
                        cardNumber={cardNumber}
                    />
                )
        }

        return publicationCard({ loaded: true, result: item.result })
    }

    return publicationCard(item)
}
CardSwitcher.displayName = 'CardSwitcher'
