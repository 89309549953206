import Cookies from 'js-cookie'
import { Dispatch } from 'react'
import {
    BeginLoadingEvent,
    EndLoadingEvent,
} from 'react-ssr-data-loader/dist/cjs/events'
import { debugAuth, RenderTarget } from '../..'
import {
    AccessTokenState,
    authRefreshPath,
    AuthUserInfoResponse,
    getAuthStateFromUserInfoToken,
} from '../../authentication/helpers'
import { UserAuthEvents } from '../../events/user-event-types'
import { TogglesReduxState } from '../../feature-togglings'
import { SwGState } from '../google'
import {
    AuthenticationState,
    authEntitlementsChecked,
    authGrantEntitlements,
    authIsLoadingState,
    authLogout,
    authNetworkError,
    authRefreshLogin,
    authLogin,
    authSessionExpired,
    GoogleUserState,
} from './reducer'

let hasDoneInitialRefresh = false

export interface AuthRefreshResponse {
    googleUserState: GoogleUserState
    userInfo: AuthUserInfoResponse
    coralToken?: string
    hashedSophiUserID?: string
    hashedLiveRampEmail?: string
    hashedUserEmail?: string
}

export interface AuthTokenExchangeResponse extends AuthRefreshResponse {
    loginProvider: string
}

export async function checkAccessTokenAndRefresh(params: {
    renditionType: RenderTarget
    accessTokenState: AccessTokenState | undefined
    swgState: SwGState
    dispatch: Dispatch<any>
    authState: AuthenticationState
    toggleState: TogglesReduxState
    onEvent: (authEvent: UserAuthEvents) => void
    options: {
        invocation: 'manual' | 'auto'
        autoRefreshToken: boolean
    }
    retryCount?: number
    forceRefresh?: boolean
    onInvocationEvent?: (
        loadingEvent: BeginLoadingEvent | EndLoadingEvent,
    ) => void
}) {
    const {
        accessTokenState,
        dispatch,
        authState,
        onEvent,
        options,
        retryCount = 2,
        swgState,
        toggleState,
        forceRefresh,
        onInvocationEvent,
    } = params
    const { invocation, autoRefreshToken } = options

    try {
        if (invocation === 'auto' && onInvocationEvent) {
            onInvocationEvent({
                type: 'begin-loading-event',
                data: {
                    resourceType: 'auth-check-refresh-token',
                    resourceLoadParamsHash: '',
                },
            })
        }

        const { isLoggedIn } = authState

        if (!accessTokenState) {
            debugAuth('No Access token cookie found, logging out: %o', {
                invocation,
            })
            dispatch(authEntitlementsChecked())
            isLoggedIn && dispatch(authLogout({ onEvent }))

            // Used primary in TheNightly for auth loading state
            dispatch(authIsLoadingState(false))

            // We need to allow the google crawler to gain access here.
            if (
                toggleState['subscribe-with-google'] &&
                swgState.userSWGState &&
                swgState.userSWGState.isCrawler
            ) {
                // Need to give entitlements to crawler. (We need to keep this as the crawler is not logged in nor would it have entitlements.)
                dispatch(
                    authGrantEntitlements({
                        entitlements: [
                            'thewest:all',
                            'westbenefits',
                            'westrewards',
                        ],
                    }),
                )
            }

            return
        }

        const forcedExpiry =
            typeof location !== 'undefined' &&
            location.search.indexOf('force_access_token_expiry') !== -1

        const isTokenExpired =
            accessTokenState.expiryDate < new Date().getTime() / 1000 ||
            forcedExpiry

        let googleUserState = authState.googleUserState
        let userInfo: AuthUserInfoResponse | undefined
        let coralToken: string | undefined = authState.coralToken
        let hashedSophiUserID: string | undefined
        let hashedLiveRampEmail: string | undefined
        let hashedUserEmail: string | undefined

        try {
            if (
                (autoRefreshToken && isTokenExpired) ||
                !hasDoneInitialRefresh ||
                forceRefresh
            ) {
                debugAuth('Refreshing token: %o', { invocation, authState })
                hasDoneInitialRefresh = true

                const { status, data } = await new Promise<{
                    status: number
                    data: AuthRefreshResponse | undefined
                }>((resolve) => {
                    const xhr = new XMLHttpRequest()
                    xhr.open('POST', authRefreshPath, true)

                    //Send the proper header information along with the request
                    xhr.setRequestHeader('Content-Type', 'application/json')
                    xhr.setRequestHeader('Accept', 'application/json')
                    xhr.withCredentials = true

                    xhr.onreadystatechange = function () {
                        // Call a function when the state changes.
                        if (this.readyState === XMLHttpRequest.DONE) {
                            resolve({
                                status: this.status,
                                data:
                                    xhr.response &&
                                    this.status === 200 &&
                                    JSON.parse(xhr.response),
                            })
                        }
                    }

                    xhr.send()
                })

                if (status === 200 && data) {
                    googleUserState = data.googleUserState
                    userInfo = data.userInfo
                    coralToken = data.coralToken
                    hashedSophiUserID = data.hashedSophiUserID
                    hashedLiveRampEmail = data.hashedLiveRampEmail
                    hashedUserEmail = data.hashedUserEmail
                } else {
                    // If the authentication server responds with a non-200 error
                    // we've now entered a state we can't recover from. The users session will be expired
                    // and the stale cookies cleared.
                    dispatch(authSessionExpired({ onEvent }))

                    // Used primary in TheNightly for auth loading state
                    dispatch(authIsLoadingState(false))

                    // Lets retry if required
                    // A 401 Unauthorized means their refresh token is missing or invalid so retrying won't help
                    if (status !== 401 && retryCount > 0) {
                        setTimeout(
                            () =>
                                checkAccessTokenAndRefresh({
                                    ...params,
                                    retryCount: retryCount - 1,
                                }),
                            5000,
                        )
                    }
                    return
                }

                debugAuth('Token refreshed: %o', { invocation })
            }

            dispatch(authEntitlementsChecked())

            debugAuth('Logging user in: %o', {
                userInfo,
                invocation,
            })

            if (userInfo) {
                const userTokenAuthState = getAuthStateFromUserInfoToken(
                    userInfo,
                    onEvent,
                    hashedSophiUserID,
                    hashedLiveRampEmail,
                    hashedUserEmail,
                )

                debugAuth('userToken / authTokens refreshed successfully: %o', {
                    userInfo,
                    invocation,
                })

                const cookieId = 'notOriginallyLoggedIn'
                const noPreviousLoginCookie = Cookies.get(cookieId)

                // Cookie found to declare first login, fire off an auth login
                if (noPreviousLoginCookie) {
                    // Remove cookie first to stop double-up
                    Cookies.remove(cookieId)
                    // Dispatch authLogin redux update

                    dispatch(
                        authLogin({
                            ...userTokenAuthState,
                            onEvent,
                            googleUserState,
                            coralToken,
                        }),
                    )
                } else {
                    // No cookie found, so fire off a refresh login
                    dispatch(
                        authRefreshLogin({
                            ...userTokenAuthState,
                            onEvent,
                            googleUserState,
                            coralToken,
                        }),
                    )
                }
            }
        } catch (error) {
            dispatch(authEntitlementsChecked())
            // Used primary in TheNightly for auth loading state
            dispatch(authIsLoadingState(false))
            // Recovery should be possible when a network error occurs.
            // Flag a network error and on the next refresh attempt try again.
            dispatch(authNetworkError({ onEvent }))
            return
        }
    } finally {
        if (invocation === 'auto' && onInvocationEvent) {
            onInvocationEvent({
                type: 'end-loading-event',
                data: {
                    resourceType: 'auth-check-refresh-token',
                    resourceLoadParamsHash: '',
                },
            })
        }
    }
}
