/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import {
    AdDefinition,
    AdState,
    getAdSize,
    sizeProps,
} from '@news-mono/web-common'
import React from 'react'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { formatAdSlot } from './ViewSizeMappings'

const StyledRow = styled('td')({
    border: '1px solid #e6e6e6',
    padding: calcRem(5),
})

export const DebugAdUnitRow: React.FC<{
    adState: AdState
    ad: AdDefinition
    forBreakpoint: number
}> = ({ adState, ad, forBreakpoint }) => {
    const unit = adState.unitLookup(ad.id, forBreakpoint)
    if (!unit) {
        // Unit not for this breakpoint
        return null
    }

    const sizeMapping = getAdSize(unit.sizeMapping, forBreakpoint)
    if (!sizeMapping) {
        return null
    }
    const sizeProp = sizeProps[ad.size]

    return (
        <tr
            css={{
                backgroundAttachment: 'fixed',
            }}
        >
            <StyledRow>{ad.size}</StyledRow>
            <StyledRow>{unit.targeting && unit.targeting.pos}</StyledRow>
            <StyledRow>{unit.id}</StyledRow>
            <StyledRow>{formatAdSlot(sizeMapping.slot)}</StyledRow>
            <StyledRow>{sizeProp.adPosCategory}</StyledRow>
            <StyledRow>{unit.exclusionLabels}</StyledRow>
        </tr>
    )
}
