import React from 'react'

export interface IconClockAltProps {
    className?: string
}

export const IconClockAlt: React.FC<IconClockAltProps> = ({ className }) => (
    <svg
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g>
            <path d="M4.5 9a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z" />
            <path
                d="M6.94 3.831a.56.56 0 1 1 0 1.119H4.05V2.06a.56.56 0 1 1 1.119 0V3.83H6.94z"
                fill="#FFF"
            />
        </g>
    </svg>
)
