import { AdState } from '../advertising/ad-state'
import {
    AdDefinition,
    adSlotsAsArray,
    AdTargeting,
    getAds,
    getAdSlotId,
} from '../advertising/add-ads'
import { GptAdSlotDefinition } from '../advertising/gpt-ad-slot-definintion'
import { RenderTarget } from '../context-providers'

export function getAdState(
    routeAdTargeting: AdTargeting,
    pageAds: AdDefinition[],
    pathname: string,
    renderTarget: RenderTarget,
) {
    const { slotMap, breakpoints } = getAds(
        routeAdTargeting,
        pageAds,
        renderTarget,
    )
    const unitsArray: GptAdSlotDefinition[] = slotMap
        ? adSlotsAsArray(slotMap)
        : []
    const pageKey = pathname
    const safePageKey = pageKey === '' ? 'home' : pageKey

    const adState: AdState = {
        pageKey: safePageKey,
        definition: pageAds,
        targeting: routeAdTargeting,
        unitLookup: (adId, windowWidth) => {
            let breakpoint = 0
            for (let index = breakpoints.length - 1; index >= 0; index--) {
                breakpoint = breakpoints[index]
                if (breakpoints[index] <= windowWidth) {
                    break
                }
            }

            const slotId = getAdSlotId(breakpoint, adId)
            const foundSlot = slotMap[slotId]
            return foundSlot
        },
        breakpoints,
    }

    return { safePageKey, unitsArray, adState }
}
