import { useTheme } from '@emotion/react'
import { ConfigurationContext } from '@news-mono/web-common'
import { ElectionDayData } from '@west-australian-newspapers/contentful-retrieval-utilities'
import * as React from 'react'
import { useElectionDayData } from '../FederalElectionWidgetCollection/helpers/useElectionDayData'
import { calcRem, metrics } from '../__styling'
import { AccordionRouteProps } from './FederalElectionAccordion.routing'
import { FederalElectionAccordionBody } from './FederalElectionAccordionBody/FederalElectionAccordionBody'
import { FederalElectionAccordionPanel } from './FederalElectionAccordionPanel/FederalElectionAccordionPanel'
export interface AccordionProps {
    isFullWidth: boolean
    isOpen: boolean
    electionDayData: ElectionDayData
    hidePadding?: boolean
    hideHoverAndFocusBackground?: boolean
}

export const FederalElectionAccordion: React.FC<AccordionRouteProps> = (
    props,
) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const config = React.useContext(ConfigurationContext)

    const result = useElectionDayData(config.contentfulStaticSiteEndpoint)
    const theme = useTheme()

    const {
        isLoading,
        isError,
        data,
    }: {
        isLoading: boolean
        isError: boolean
        data: ElectionDayData | undefined
    } = result

    return (
        <div>
            {!isLoading && !isError && data && (
                <div
                    style={{
                        marginBottom: props.verticalSpacing
                            ? calcRem(
                                  metrics[theme.kind].margins[
                                      props.verticalSpacing
                                  ],
                              )
                            : undefined,
                    }}
                >
                    <div onClick={() => setIsOpen(!isOpen)}>
                        <FederalElectionAccordionPanel
                            isFullWidth={props.isFullWidth}
                            isOpen={isOpen}
                            electionDayData={data}
                            hideHoverAndFocusBackground={
                                props.hideHoverAndFocusBackground
                            }
                        />
                    </div>
                    <FederalElectionAccordionBody
                        isOpen={isOpen}
                        isFullWidth={props.isFullWidth}
                        electionDayData={data}
                        hidePadding={props.hidePadding}
                    />
                </div>
            )}
        </div>
    )
}
