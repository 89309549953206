import {
    FeatureState,
    isFeatureEnabled,
    RawFeatureValues,
} from '@etrigan/feature-toggles-client'
import {
    AllEvents,
    AuthenticationState,
    ConsentLevel,
    ConsentState,
    DataLayerEventName,
    Features,
    GetDcrMeta,
    getDefaultConsentLevel,
    handleChartbeatTracking,
    hasConsentLevel,
    HttpError,
    isEuMember,
    LocationState,
    sophiDebug,
    SubscriptionType,
} from '@news-mono/web-common'
import debug from 'debug'
import { PageLifecycleProviderRenderProps } from 'page-lifecycle-provider'
import queryString from 'query-string'
import { DataProviderEvents } from 'react-ssr-data-loader'
import { Logger } from 'typescript-log'
import { purgeTruskin } from '../advertising/ad-helpers/truskin'
import { debugIpsos, injectIpsos, injectParsely } from '../tracking'
import {
    handlePageEventsInDatalayer,
    toDataLayerEvent,
} from '../tracking/gtm/gtm'
import { nielsenSoftPageLoad } from '../tracking/nielsen/nielsen'
import { eventQueue } from './client'
import { initRealEstateViewWidgetWithRetry } from '../advertising/RealEstateView'

export const pageEventsDebug = debug('page-events')

let externalEventsDisabled = false

declare const _satellite: undefined | { track(url: string): void }

export const disableExternalEvents = () => (externalEventsDisabled = true)

export function handleDataProviderEvent(
    pageProps: PageLifecycleProviderRenderProps,
    // We want to pass the logger in here as it could
    logger: Logger,
): (event: DataProviderEvents) => void {
    return (event) => {
        if (event.type === 'state-changed') {
            return
        }

        const { data } = event
        const dataResourceInfo = `${data.resourceType} - ${data.resourceLoadParamsHash}`

        if (event.type === 'begin-loading-event') {
            pageEventsDebug(
                `client - Signal start loading data: ${dataResourceInfo}`,
            )
            pageProps.beginLoadingData()
        } else if (event.type === 'end-loading-event') {
            pageEventsDebug(
                `client - Signal end loading data: ${dataResourceInfo}`,
            )
            pageProps.endLoadingData()
        } else if (event.type === 'load-error') {
            if (event.data.error.message === 'failed-data-load') {
                return
            }

            pageEventsDebug(
                `client - Signal data load failed: ${dataResourceInfo}`,
            )
            if (
                event.data.error instanceof HttpError ||
                event.data.error.message === '404'
            ) {
                return
            }

            logger.error(
                { err: event.data.error },
                'client - Data load failed with unknown error',
            )
        }
    }
}

/** Ensures Bonzai units are cleared on loading a new page as Bonzai ad units don't do this themselves. */
const handleBonzaiAds = (event: AllEvents): void => {
    if (event.type !== 'page-load-started') {
        return
    }

    const bonzaiAds = document.querySelectorAll('iframe[adcontainer]')

    for (let index = 0; index < bonzaiAds.length; ++index) {
        const bonzaiNode = bonzaiAds[index]
        if (!bonzaiNode) {
            continue
        }
        const bonzaiParent = bonzaiNode.parentNode
        if (!bonzaiParent) {
            continue
        }

        bonzaiParent.removeChild(bonzaiNode)
    }
}

const handleTruskinAds = (event: AllEvents): void => {
    if (event.type === 'page-load-started') {
        purgeTruskin()
    }
}

const dataLayerEventHandler = handlePageEventsInDatalayer<AllEvents>(
    (event: AllEvents, actualPageVariables: any) => {
        if (
            event.type === 'route-info-resolver.failed' ||
            event.type === 'page-render.failed'
        ) {
            return
        }
        return toDataLayerEvent(event, actualPageVariables)
    },
)

export interface HandlePageEventProps {
    event: AllEvents
    toggleState: FeatureState
    actualPageVariables: any
    nielsenSiteName: string
    getDcrMeta: GetDcrMeta
    consentState: ConsentState
    publicHostname: string
    chartbeatId?: number
    locationState: LocationState
    authState: AuthenticationState
    aBTestingFeatureVariables?: RawFeatureValues
}

export function handlePageEvents({
    event,
    toggleState,
    actualPageVariables,
    nielsenSiteName,
    getDcrMeta,
    consentState,
    publicHostname,
    chartbeatId,
    locationState,
    authState,
    aBTestingFeatureVariables,
}: HandlePageEventProps) {
    const isEuMemberState = isEuMember(locationState.countryCode)

    const hasAnalyticsConsent = isEuMemberState
        ? hasConsentLevel(
              consentState.consentLevel ?? ConsentLevel.Essential,
              ConsentLevel.Analytics,
          )
        : true

    const nielsenEnabled = false

    const adobeAudienceManagerEnabled =
        isFeatureEnabled<Features>(toggleState, 'adobe-audience-manager') &&
        hasAnalyticsConsent

    const sophiEnabled =
        isFeatureEnabled<Features>(toggleState, 'sophi') && hasAnalyticsConsent

    const activeConsentLevel =
        consentState.consentLevel || getDefaultConsentLevel(locationState)

    const isNativeChartBeatEnabled = false
    const chartbeatEnabled =
        chartbeatId && isNativeChartBeatEnabled && activeConsentLevel >= 2

    pageEventsDebug('Page event raised: %o', event)

    const isIpsosEnabled =
        isFeatureEnabled(toggleState, 'ipsos-tracking') && hasAnalyticsConsent

    const isParselyEnabled =
        isFeatureEnabled(toggleState, '7-news-parsely-script') &&
        hasAnalyticsConsent

    dataLayerEventHandler(event, {
        ...actualPageVariables,
        'a-b-testing-features': aBTestingFeatureVariables,
    })
    handleBonzaiAds(event)
    handleTruskinAds(event)

    if (chartbeatEnabled) {
        handleChartbeatTracking(event, chartbeatId!, publicHostname)
    }

    if (event.type === DataLayerEventName.subscribeComplete) {
        // DPT-942: Prevent multiple breachscreen.complete events triggering
        try {
            if (typeof window !== 'undefined') {
                const {
                    productName,
                    productSku,
                    transactionTax,
                    transactionTotal,
                    clickOrigin,
                    ...search
                } = queryString.parse(location.search)

                // Remove the ga query string added if a regwall event happened
                if (search) {
                    const searchQueryItems = Object.keys(search)
                    let gaKey: string | null = null
                    for (const key of searchQueryItems) {
                        if (key.match(/ga\d+.\d+.\d+.\d+/)) {
                            gaKey = key as string
                        }
                    }

                    // Remove the GA query param e.g ga1.1.2066668464.1598930365
                    if (gaKey) {
                        delete search[gaKey]
                    }
                }

                // We want to retain search params except all the breach screen params except transactionId, which
                // is needed by gtm, by not having the clickOrigin present the breachscreen.complete and subscribe.complete won't fire.
                const searchExcludingBreachParams =
                    queryString.stringify(search)

                // Only add the search params on if there are some
                const url = `${window.location.pathname}${
                    window.location.hash
                }${
                    searchExcludingBreachParams.length > 0
                        ? `?${searchExcludingBreachParams}`
                        : ''
                }`

                window.history.replaceState({}, document.title, url)
            }
        } catch (err) {
            // not much we can do
        }
    }

    if (event.type === 'page-load-complete') {
        const isSophiLoaded = !!(window.sophi && window.sophi.data)
        const sendSophiEvent = () => {
            try {
                const sophiContent = event.payload.sophiContent
                // Ensure sophiContent doesn't appear on our default data layer for page
                if (sophiContent) {
                    delete event.payload.sophiContent
                }

                const canSendSophiEvent =
                    sophiEnabled && window.sophi && window.sophi.sendEvent

                sophiDebug('Can send sophi event: %b', canSendSophiEvent)
                window.sophi.data = {
                    ...sophiContent.data,
                    visitor: {
                        isLoggedIn: authState.isLoggedIn,
                        type: mapVisitorType(
                            authState.isLoggedIn,
                            authState.subscriptionType,
                        ),
                        uid: authState.hashedSophiUserID,
                    },
                }
                sophiDebug('Setting sophi.data: %O', window.sophi.data)

                sophiDebug('Sending event page_view')
                window.sophi.sendEvent({
                    type: 'page_view',
                    config: { overrideStoredContext: true },
                })
                //Trigger the wall-hit event, if there is any in the eventQueue.
                eventQueue.resolveEvent('sophi-wall-hit')
            } catch (err) {
                // not much we can do
            }
        }
        if (isSophiLoaded) {
            sendSophiEvent() //If the library has already been initialised, we want to call the function instead of adding it to a queue.
        } else {
            eventQueue.addEvent({
                eventName: 'sophi-page-view',
                callbackFunctions: [sendSophiEvent],
            })
        }
        if (isIpsosEnabled) {
            debugIpsos('Calling Ajax Call for Ipsos')

            injectIpsos(
                window.location.hostname,
                window.location.pathname,
                'ajax',
            )
        }
        if (isParselyEnabled) {
            injectParsely('ajax')
        }
        if (
            isFeatureEnabled(toggleState, '7news-real-estate-view-widget') &&
            (window.location.pathname === '/lifestyle/real-estate' ||
                window.location.pathname === '/business/property')
        ) {
            initRealEstateViewWidgetWithRetry()
        }
    }

    if (event.type === 'page-load-complete' && !externalEventsDisabled) {
        if (nielsenEnabled) {
            nielsenSoftPageLoad(getDcrMeta, nielsenSiteName)
        }

        if (
            adobeAudienceManagerEnabled &&
            typeof _satellite !== 'undefined' &&
            typeof _satellite.track !== 'undefined'
        ) {
            _satellite.track('spa_link')
        }
    }
}

export type VisitorType = 'Anonymous' | 'Registered' | 'Subscribed'

export function mapVisitorType(
    isLoggedIn: boolean,
    subscriptionType: SubscriptionType,
): VisitorType {
    return !isLoggedIn
        ? 'Anonymous'
        : subscriptionType === 'none'
        ? 'Registered'
        : 'Subscribed'
}

export function isCorporateAccount(subscriptionType: string) {
    // Standard site license users and school site license users
    return ['corporate-site', 'corporate-school'].includes(subscriptionType)
}
