import { PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardOrientationOptions,
    PortraitProps,
} from '../../cards/Portrait/Portrait'
import { CommonCardProps } from '../CardItem.Props'

export type CommonLayoutProps = Omit<
    PortraitProps,
    'cardNumber' | 'item' | 'onEvent' | 'containerWidthRatios'
>
export type LayoutName =
    | 'titleOnly'
    | 'common'
    | 'commonWithBackground'
    | 'noImageWithBackground'
    | 'large-card'
    | 'teaserEdgeToEdge' // the west & perthnow

    //The West
    | 'teaserWithBackground'
    | 'xsImage-mdNoImage-withBG'
    | 'teaser-xsVisible-mdHidden-noImage'
    | 'teaser-xsVisible-mdHidden-Image'
    | 'smallTitle-teaser-mdTeaserHidden-Image-EdgeToEdge' // travel
    | 'teaser-xsVisible-mdHidden-noKicker-Image' // brand content
    | 'teaser-xsVisible-smHidden-noImage'
    | 'video-trailer-card-single' // live sports hub
    | 'the-west-hero'
    | 'largeCard'
    | 'large-card-lgNoText'
    | 'small-card'
    | 'noImage-noBackground'

    // PerthNow
    | 'teaserLargeTitle'
    | 'noTeaserEdgeToEdge'
    | 'smallTitleNoKickerEdgeToEdge'
    | 'teaserSmallTitle'
    | 'tallPortrait'

    // Sevennews
    | 'noTeaserNoPadding'
    | 'bbPromo'

    // Inline content
    | 'noTeaserNoPadding1xFont'
    | 'teaser-xsVisible-mdHidden-mdInlineVideo' // DPT-3921: playable video card

export interface NamedLayout {
    type: 'named-layout'
    name: LayoutName
}

export const commonPortraitProps: CommonLayoutProps = {
    hasPadding: true,
    fontScale: 0.88,
    teaserMode: 'hidden',
}

export const noPaddingPortraitProps: CommonLayoutProps = {
    fontScale: 1,
    teaserMode: 'visible',
    hasPadding: false,
}

export const commonWithBackgroundPortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    hasBackground: true,
}

export const tallPortraitProps: CommonLayoutProps = {
    hasPadding: true,
    fontScale: 1.4,
    mediaMode: 'edgeToEdge',
    teaserMode: 'visible',
}

export const titleOnlyPortraitProps: CommonLayoutProps = {
    hideByline: true,
    fontScale: 0.88,
    hasBackground: true,
    mediaMode: 'hidden',
    hasPadding: true,
    teaserMode: 'hidden',
}

export const xsTeaserVisibleMdTeaserHiddenNoImageProps: CommonLayoutProps = {
    fontScale: 0.88,
    hasBackground: true,
    hasPadding: true,
    mediaMode: {
        initial: 'default',
        xs: 'default',
        sm: 'hidden',
        md: 'hidden',
    },
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'visible',
        md: 'hidden',
    },
}

export const xsTeaserVisibleSmTeaserHiddenNoImageProps: CommonLayoutProps = {
    fontScale: 0.88,
    hasBackground: true,
    hasPadding: true,
    mediaMode: {
        initial: 'default',
        xs: 'default',
        sm: 'hidden',
        md: 'hidden',
    },
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'hidden',
        md: 'hidden',
    },
}

export const xsTeaserVisibleMdTeaserHiddenImageProps: CommonLayoutProps = {
    fontScale: 0.88,
    hasBackground: true,
    mediaMode: 'edgeToEdge',
    hasPadding: true,
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'visible',
        md: 'hidden',
    },
}

export const teaserXsVisibleMdHiddenMdInlineVideo: CommonLayoutProps = {
    fontScale: 0.88,
    mediaMode: 'inlineVideo',
    hasPadding: true,
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'visible',
        md: 'hidden',
    },
}

export const teaserPortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    teaserMode: 'visible',
    fontScale: 0.88,
}

export const teaserLargeTitlePortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    teaserMode: 'visible',
    fontScale: 2,
    mediaMode: 'edgeToEdge',
}

export const smallTitleTeaserPortraitProps: CommonLayoutProps = {
    hasPadding: true,
    fontScale: 0.75,
    hasBackground: false,
    teaserMode: 'visible',
    mediaMode: 'edgeToEdge',
}

export const smallTitleMdTeaserHiddenEdgeToEdgePortraitProps: CommonLayoutProps =
    {
        ...commonPortraitProps,
        teaserMode: {
            initial: 'visible',
            xs: 'visible',
            sm: 'visible',
            md: 'hidden',
        },
        fontScale: 0.75,
        mediaMode: 'edgeToEdge',
    }

export const xsTeaserVisibleMdTeaserHiddenNoKickerImage: CommonLayoutProps = {
    hasPadding: true,
    fontScale: 0.75,
    hasBackground: false,
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'visible',
        md: 'hidden',
    },
    kickerMode: 'hidden',
    mediaMode: 'edgeToEdge',
}

export const smallTitleNoKickerEdgeToEdgePortraitProps: CommonLayoutProps = {
    hasPadding: true,
    fontScale: 1,
    hasBackground: false,
    teaserMode: 'visible',
    kickerMode: 'hidden',
    mediaMode: 'edgeToEdge',
}

export const teaserWithBackgroundPortraitProps: CommonLayoutProps = {
    ...teaserPortraitProps,
    hasBackground: true,
}

export const teaserWithBackgroundEdgeToEdgeMediaPortraitProps: CommonLayoutProps =
    {
        ...teaserPortraitProps,
        hasBackground: true,
        mediaMode: 'edgeToEdge',
    }

export const noImageWithBackgroundPortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    disableImageLazyLoad: true,
    teaserMode: 'visible',
    fontScale: 0.88,
    mediaMode: 'hidden',
    hasBackground: true,
}

export const xsImageMdNoImageWithBackgroundPortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    teaserMode: 'visible',
    fontScale: 0.88,
    mediaMode: {
        initial: 'default',
        xs: 'default',
        sm: 'hidden',
        md: 'hidden',
    },
    hasBackground: true,
}

export const noTeaserNoPaddingPortraitProps: CommonLayoutProps = {
    teaserMode: 'hidden',
    fontScale: 1.2,
    hasPadding: false,
}

export const noTeaserNoPadding1xFontPortraitProps: CommonLayoutProps = {
    teaserMode: 'hidden',
    fontScale: 1,
    hasPadding: false,
    mediaMode: 'default',
}

export const noTeaserEdgeToEdgePortraitProps: CommonLayoutProps = {
    ...commonPortraitProps,
    teaserMode: 'hidden',
    mediaMode: 'edgeToEdge',
}

function getNamedLayout(layout: LayoutName): CommonLayoutProps {
    switch (layout) {
        case 'titleOnly':
            return titleOnlyPortraitProps
        case 'noImageWithBackground':
            return noImageWithBackgroundPortraitProps
        case 'tallPortrait':
            return tallPortraitProps
        case 'teaserWithBackground':
            return teaserWithBackgroundPortraitProps
        case 'teaserSmallTitle':
            return smallTitleTeaserPortraitProps
        case 'noTeaserEdgeToEdge':
        case 'video-trailer-card-single':
            return noTeaserEdgeToEdgePortraitProps
        case 'smallTitleNoKickerEdgeToEdge':
            return smallTitleNoKickerEdgeToEdgePortraitProps
        case 'teaserLargeTitle':
            return teaserLargeTitlePortraitProps
        case 'commonWithBackground':
            return commonWithBackgroundPortraitProps
        case 'noTeaserNoPadding':
            return noTeaserNoPaddingPortraitProps
        case 'noTeaserNoPadding1xFont':
            return noTeaserNoPadding1xFontPortraitProps
        case 'teaserEdgeToEdge':
            return teaserWithBackgroundEdgeToEdgeMediaPortraitProps
        case 'teaser-xsVisible-mdHidden-noImage':
            return xsTeaserVisibleMdTeaserHiddenNoImageProps
        case 'teaser-xsVisible-smHidden-noImage':
            return xsTeaserVisibleSmTeaserHiddenNoImageProps
        case 'teaser-xsVisible-mdHidden-Image':
            return xsTeaserVisibleMdTeaserHiddenImageProps
        case 'teaser-xsVisible-mdHidden-noKicker-Image':
            return xsTeaserVisibleMdTeaserHiddenNoKickerImage
        case 'smallTitle-teaser-mdTeaserHidden-Image-EdgeToEdge':
            return smallTitleMdTeaserHiddenEdgeToEdgePortraitProps
        case 'xsImage-mdNoImage-withBG':
            return xsImageMdNoImageWithBackgroundPortraitProps
        case 'teaser-xsVisible-mdHidden-mdInlineVideo':
            return teaserXsVisibleMdHiddenMdInlineVideo
        case 'noImage-noBackground':
            return {
                ...commonPortraitProps,
                teaserMode: 'visible',
                hasBackground: false,
                mediaMode: 'hidden',
            }
        default:
            return commonPortraitProps
    }
}

export const getCardItemProps = (
    item: MaybeLoaded<PublicationCardItem>,
    layout: LayoutName,
    props: CommonCardProps,
    cardNumber: number,
    cardOrientation?: CardOrientationOptions,
): PortraitProps => {
    const loadedProps = {
        ...getNamedLayout(layout),
        ...props,
        item,
        cardNumber,
        cardOrientation,
    }

    return loadedProps
}
