import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { MedalTally } from './MedalTally'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'

export interface MedalTallyRouteProps {
    size: OlympicsWidgetSize
    trimToFit?: boolean
    svgColourOverride?: string
    listSize?: number
    widgetTitle?: string
    hideLinks?: boolean
}

export const MedalTallyRegistration = createRegisterableComponent(
    'olympics-medal-tally',
    (props: MedalTallyRouteProps) => {
        return (
            <MedalTally
                size={props.size}
                svgColourOverride={props.svgColourOverride}
                listSize={props.listSize}
                widgetTitle={props.widgetTitle}
                hideLinks={props.hideLinks}
            />
        )
    },
)
