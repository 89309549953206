import { useTheme } from '@emotion/react'
import {
    createCardClickedEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { FC } from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledHeadline,
    StyledListCard,
} from '../../cards/ListCard/ListCard.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { ListCardContent } from './components/ListCardContent'

export interface ListCardProps extends CommonCardProps {
    timestamp?: TimestampType
    simpleList?: boolean
    noStretch?: boolean
    removeHorizontalMargin?: boolean
    hideTopic?: boolean
    isTruncated?: boolean
    hasSeparators?: boolean
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem>
}

export const ListCard: FC<ListCardProps> = (props) => {
    const { className, simpleList, noStretch } = props

    const theme = useTheme()

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'ListCard',
                'List',
                props.cardNumber,
            ),
        )
    }

    return (
        <StyledListCard
            className={className}
            data-topic={
                !props.item.loaded
                    ? undefined
                    : props.item.result.primaryTopic.id
            }
            simpleList={simpleList}
            noStretch={noStretch}
        >
            {theme.kind === 'perthnow' ? (
                <StyledHeadline>
                    <ListCardContent
                        item={props.item}
                        simpleList={props.simpleList}
                        timestamp={props.timestamp}
                        linkClicked={linkClicked}
                        hasSeparators={props.hasSeparators}
                        hideTopic={props.hideTopic}
                        isTruncated={props.isTruncated}
                        removeHorizontalMargin={props.removeHorizontalMargin}
                    />
                </StyledHeadline>
            ) : (
                <ListCardContent
                    item={props.item}
                    simpleList={props.simpleList}
                    timestamp={props.timestamp}
                    linkClicked={linkClicked}
                    hasSeparators={props.hasSeparators}
                    hideTopic={props.hideTopic}
                    isTruncated={props.isTruncated}
                    removeHorizontalMargin={props.removeHorizontalMargin}
                />
            )}
        </StyledListCard>
    )
}
ListCard.displayName = 'ListCard'
