import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { RealEstateView, RealEstateViewProps } from './RealEstateView'

export const RealEstateViewRegistration = createRegisterableComponent(
    'real-estate-view-widget',
    (props: RealEstateViewProps, services) => {
        return <RealEstateView {...props} />
    },
)
