import {
    AllEvents,
    AppState,
    ArticleLikePublication,
    isArticleLikeType,
    Topic,
} from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { LazySportBreachCallToAction } from '../../../user-registration/breach/components/SportBreachCallToAction/SportBreachCallToAction.lazy'
import {
    InlineContentTypes,
    InlineSportCallToAction,
} from '../SharedPublication.routing'

export interface GetInlinePositionedSportCallToActionProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export interface GetInlinePositionedCallToAction {}

export function isInlineCallToAction(
    content: InlineContentTypes,
): content is InlineSportCallToAction {
    return content.kind === 'sport-call-to-action'
}

function matchSportsTopic(primaryTopic: Topic) {
    return /^sport/.test(primaryTopic.id)
}

interface CallToActionWrapperProps {
    onEvent: (event: AllEvents) => void
    articleId: string
    leadDepth: number
}

const CallToActionWrapper: React.FC<CallToActionWrapperProps> = ({
    onEvent,
    articleId,
    leadDepth,
}) => {
    const authState = useSelector((state: AppState) => state.authentication)

    return (
        <LazySportBreachCallToAction
            isLoggedIn={authState.isLoggedIn}
            onEvent={onEvent}
            articleId={articleId}
            breachCreativeName={'TW_Article_Breach_Article_SPORT_PACKAGE'}
            breachDeal={'sportspack'}
            leadDepth={leadDepth}
        />
    )
}
CallToActionWrapper.displayName = 'CallToActionWrapper'

export function getInlinePositionedSportCallToAction(
    props: GetInlinePositionedSportCallToActionProps,
) {
    const { publication, inlinePublicationContent, onEvent } = props

    if (
        !isArticleLikeType(publication) ||
        !matchSportsTopic(publication.primaryTopic)
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineCallToAction)
        .map((content: InlineSportCallToAction) => {
            const leadDepth = (content.insertAfter ?? 0) + 1

            return {
                position: content.insertAfter,
                element: (
                    <CallToActionWrapper
                        onEvent={onEvent}
                        articleId={publication.id}
                        leadDepth={leadDepth}
                    />
                ),
            }
        })
}
