import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getTravelRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    [
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                    ],
                ],
                sectionHeader: {
                    heading: 'Travel Places',
                    navLinks: [
                        {
                            text: 'Europe',
                            link: '/travel/europe',
                        },
                        { text: 'Bali', link: '/travel/bali' },
                        {
                            text: 'Japan',
                            link: '/travel/japan',
                        },
                        {
                            text: 'Western Australia',
                            link: '/travel/wa',
                        },
                    ],
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'travel-places',
                    offset: 0,
                    pageSize: 6,
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    [
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                    ],
                    ['teaserWithBackground'],
                ],
                sectionHeader: {
                    heading: 'Travel Experiences',
                    navLinks: [
                        {
                            text: 'Hotels & Stays',
                            link: '/travel/hotels-accommodation',
                        },
                        { text: 'Food & Drink', link: '/travel/food-wine' },
                        {
                            text: 'Ocean Cruising',
                            link: '/travel/cruising',
                        },
                        {
                            text: 'River Cruising',
                            link: '/travel/river-cruising',
                        },
                    ],
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'travel-experiences',
                    offset: 0,
                    pageSize: 6,
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    [
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                        {
                            type: 'landscape',
                            format: 'landscape-smTeaser-mdNoTeaser',
                        },
                    ],
                ],
                sectionHeader: {
                    heading: 'Travel Essentials',
                    navLinks: [
                        {
                            text: 'Photography',
                            link: '/travel/photography',
                        },
                        { text: 'Travel Tips', link: '/travel/travel-tips' },
                        {
                            text: 'Travel Tech',
                            link: '/travel/travel-tech',
                        },
                        {
                            text: 'Travel Gear',
                            link: '/travel/travel-gear',
                        },
                    ],
                },

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'travel-essentials',
                    offset: 0,
                    pageSize: 6,
                },
            },
        }),
        layout.nestedComposition({
            type: 'janus',
            props: {
                stretchColumns: true,
                verticalSpacing: 'lg',
                hasBorder: true,
            },
            contentAreas: {
                // Travel Guides
                left: [
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['travel/guides'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            sectionHeader: {
                                heading: 'Travel Guides',
                                headingUrl: '/travel/guides',
                            },
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            hasBorder: false,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: { type: 'Landscape-Portrait' },
                        },
                    }),
                ],
                right: [
                    // Travel to Western Australia
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['travel/travel-to-western-australia'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },

                            sectionHeader: {
                                heading: 'Travel to Western Australia',
                                headingUrl:
                                    '/travel/travel-to-western-australia',
                            },
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            hasBorder: false,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                            },
                        },
                    }),
                ],
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.nestedComposition({
            type: 'janus',
            props: {
                stretchColumns: true,
                verticalSpacing: 'lg',
                hasBorder: true,
            },
            contentAreas: {
                // West Travel Club
                left: [
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'west-travel-club',
                                offset: 0,
                                pageSize: 4,
                            },
                            sectionHeader: {
                                heading: 'West Travel Club',
                                headingUrl: 'https://westtravelclub.com.au/',
                            },
                            hasBorder: false,
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                            },
                        },
                    }),
                ],
                right: [
                    // Travel Videos
                    layout.component({
                        type: 'lima',
                        props: {
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: [
                                    'travel/travel-club-show',
                                    'travel/on-the-road',
                                ],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            hasBorder: false,
                            sectionHeader: {
                                heading: 'Travel Videos',
                                navLinks: [
                                    {
                                        text: 'Travel Club Show',
                                        link: '/travel/travel-club-show',
                                    },
                                    {
                                        text: 'On the Road',
                                        link: '/travel/on-the-road',
                                    },
                                ],
                            },
                            verticalSpacing: 'xs',
                            fixedRatios: ['16:9'],
                            finalColumns: 2,
                            stretchSelf: true,
                            cardLayout: [
                                ['teaserWithBackground'],
                                [
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                    {
                                        type: 'landscape',
                                        format: 'landscape-smTeaser-mdNoTeaser',
                                    },
                                ],
                            ],
                            cardOrientation: { type: 'Landscape-Portrait' },
                        },
                    }),
                ],
            },
        }),
    ]
}
