import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateTopicListingResponse } from '../get-topic-listing'
import { Paging } from '../paging'

export interface GetRelationPublicationOptions {
    paging?: Paging
    includePremium?: boolean
}

export const getRelatedPublications = (
    services: DataLoaderGlobalParams,
    publicationId: string,
    options: GetRelationPublicationOptions,
) => {
    const include_premium =
        // option defaults to true, which is then confirmed/denied by the feature toggle
        // see commments in https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/1501/overview
        options.includePremium !== false &&
        isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'digital-subscriptions',
            false,
        )

    const { paging = {} as Paging } = options
    const query = {
        page: paging.page || 1,
        page_size: paging.pageSize || 4,
        page_offset: paging.pageOffset || 0,
        include_premium: include_premium || undefined,
    }

    return httpGet({
        log: services.log,
        validate: validateTopicListingResponse,
        baseUrl: services.config.contentApi,
        path: `v4/publication/${publicationId}/related`,
        query,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}
