import { ArticlePublication, isArticleLikeType } from '@news-mono/web-common'
import React from 'react'
import { ImageLink } from '../../../buttons/ImageLink'
import {
    InlineContentTypes,
    InlineImageLink,
} from '../SharedPublication.routing'
import { GetInlinePositionedContentProps } from './get-inline-positioned-pointer'

export interface getInlinePositionedImageLinkProps {
    publication: ArticlePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlineImageLink(
    content: InlineContentTypes,
): content is InlineImageLink {
    return content.kind === 'image-link'
}

export function getInlinePositionedImageLink({
    publication,
    inlinePublicationContent,
}: GetInlinePositionedContentProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineImageLink)
        .filter(
            (inlinImageContent) =>
                inlinImageContent.props.displayCondition ?? true,
        )
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: (
                    <ImageLink
                        width={content.props.width}
                        height={content.props.height}
                        title={content.props.title}
                        altText={content.props.altText}
                        src={content.props.src}
                        link={content.props.link}
                        visibilityMode={content.props.visiblility}
                        verticalSpacing="md"
                    />
                ),
            }
            return mappedContent
        })
}
