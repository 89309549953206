import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    GenericInArticleBanner,
    GenericInArticleBannerProps,
} from './GenericInArticleBanner'

export const GenericInArticleBannerRegistration = createRegisterableComponent(
    'generic-in-article-banner',
    (props: GenericInArticleBannerProps) => {
        return <GenericInArticleBanner {...props} />
    },
)
