import styled from '@emotion/styled'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface StyledTopicListContainerProps {
    verticalSpacing?: keyof ThemeMargins
}

export const StyledTopicListContainer = styled(
    'div',
)<StyledTopicListContainerProps>(({ theme, verticalSpacing }) => [
    theme.kind === 'perthnow' && {
        alignItems: 'center',
        display: 'flex',
    },
    {
        marginBottom: verticalSpacing && theme.margins[verticalSpacing],
    },
])

export const StyledTopicNav = styled('nav')({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    overflowX: 'auto',
})

export const StyledHeader = styled('h2')({
    alignSelf: 'flex-start',
    color: colors.black,
    fontFamily: fonts.perthnow.sansSerifCond,
    fontSize: calcRem(16),
    fontWeight: 700,
    lineHeight: 1,
    margin: `0 ${calcRem(15)} 0 0`,
    textTransform: 'uppercase',
})

export const StyledNightlyText = styled('span')(({ theme }) => ({
    fontSize: calcRem(16),
    fontWeight: 400,
    color: theme.colors.text.secondary,
}))
