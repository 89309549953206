import { NavEvent, NavItem } from '@news-mono/web-common'
import { createRenderTarget } from '../../render-target'
import { QuickLinksWeb } from './QuickLinks.web'

export interface QuickLinksProps {
    links: NavItem[]
    onEvent: (event: NavEvent) => void
    hasBackgroundFill?: boolean
}

export const QuickLinks = createRenderTarget('QuickLinks', {
    web: QuickLinksWeb,
    amp: QuickLinksWeb,
    rss: QuickLinksWeb,
    preview: QuickLinksWeb,
    app: null,
})
QuickLinks.displayName = 'QuickLinks'
