import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { IconCommentTN } from '../../icons/IconCommentTN/IconComment'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { WebLink } from '@news-mono/web-common'

export const StyledCommentsWrapper = styled('div')(({ theme }) => [
    {
        minHeight: calcRem(24),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        color: tokens.thenightly.colors.actions.link.default,
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(16),
        fontWeight: 700,
        lineHeight: calcRem(24),
        gap: calcRem(4),
    },
])

export const StyledCommentsIcon = styled(IconCommentTN)({
    width: calcRem(18),
    height: calcRem(18),
    fill: colors.thenightly.neutral[100],
    strokeWidth: 0,
})

export const StyledIconWrapper = styled('div')({
    width: calcRem(24),
    height: calcRem(24),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: calcRem(4),
})

export const StyledWebLink = styled(WebLink)([
    {
        display: 'flex',
        gap: calcRem(4),
        textDecoration: 'none',
        color: colors.thenightly.neutral[100],

        ':hover': {
            background: 'none',
            color: colors.thenightly.neutral[100],
        },
    },
])

export const StyledText = styled.span(({ theme }) => [
    {
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(16),
        fontWeight: 600,
        lineHeight: calcRem(24),
        color: colors.thenightly.neutral[100],
        textTransform: 'uppercase',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
    },
])
