import React from 'react'
import {
    StyledInHouseLinks,
    StyledLink,
} from '../../../content/TheWestHeader/TheWestHeader.styled'
import { InHouseLink } from '../../../navigation/TheWestMainNavigation/components/in_house_links/in_house_links'

export interface InHouseLinksProps {
    items: InHouseLink[]
}

export const InHouseLinks: React.FC<InHouseLinksProps> = ({ items }) => (
    <StyledInHouseLinks className="in-house-links">
        {items.map(
            ({
                text,
                link,
                target,
                rel,
                forceInternalSSR,
                dataAttributeId,
            }) => (
                <StyledLink
                    key={link}
                    to={link}
                    rel={rel}
                    target={target}
                    id={dataAttributeId}
                    forceInternalSSR={forceInternalSSR}
                >
                    {text}
                </StyledLink>
            ),
        )}
    </StyledInHouseLinks>
)
InHouseLinks.displayName = 'InHouseLinks'
