import loadable from '@loadable/component'
import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling'
import { PollieRaterDriverBannerProps } from './PollieRaterDriverBanner'

export const LazyPollieRaterDriverBanner = loadable(
    () => import('./PollieRaterDriverBanner'),
)

export interface PollieRaterDriverBannerRouteProps
    extends PollieRaterDriverBannerProps {
    /** Sets banner maxWidth to 900px  */
    isFixedWidth?: boolean
}

export const PollieRaterDriverBannerRegistration = createRegisterableComponent(
    'pollie-rater-driver-banner',
    (props: PollieRaterDriverBannerRouteProps) => (
        <FeatureToggle
            feature="pollie-rater-drivers"
            on={() => <LazyPollieRaterDriverBanner {...props} />}
        />
    ),
)
