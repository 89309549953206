import { PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { FC } from 'react'
import { TimestampType } from '../../../content/CardTimestamp/CardTimestamp'
import { StyledTimestamp } from '../ListCard.styled'

interface ListCardTimestampProps {
    item: MaybeLoaded<PublicationCardItem>
    timestamp?: TimestampType
}

export const ListCardTimestamp: FC<ListCardTimestampProps> = (props) => {
    const { item, timestamp } = props

    if (!item.loaded) {
        if (timestamp !== undefined) {
            return <StyledTimestamp loading={true} />
        }
        return null
    }

    if (!item) {
        return null
    }

    const time = item.result.publicationDate
    return <StyledTimestamp time={time} />
}
ListCardTimestamp.displayName = 'ListCardTimestamp'
