import React from 'react'
import { StyledActionButton } from './TheWestHeaderLogin.styled'

export const LogoutActionLink: React.FC<{ onLogoutClick: () => void }> = ({
    onLogoutClick,
}) => {
    return (
        <StyledActionButton type="button" isPrimary onClick={onLogoutClick}>
            Log Out
        </StyledActionButton>
    )
}
LogoutActionLink.displayName = 'LogoutActionLink'
