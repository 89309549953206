import {
    BreakpointCrops,
    BreakpointRatios,
    CropBreakpoint,
    CropBreakpointSet,
    FixedRatio,
    ImageCrops,
    ImageRatio,
    ProcessedBreakpointCrops,
} from '@news-mono/web-common'
import { KnownRatioToRatio } from '@west-australian-newspapers/publication-types'
import React, { Fragment } from 'react'
import { breakpoints } from '../../__styling/settings/breakpoints'
import { ImageSrcInfo } from './LazyLoadingPicture'
import { ResponsivePictureSizes } from './responsive'

let globalDisableLazyLoad = false
export const disableImageLazyLoad = () => (globalDisableLazyLoad = true)
export const isLazyLoadGloballyDisabled = () => globalDisableLazyLoad

// Returns interable breakpoints
export function getIterableBreakpoints<T = any>(
    breakpointSet: CropBreakpointSet<T>,
): (keyof CropBreakpointSet<T>)[] {
    return Object.keys(breakpointSet) as (CropBreakpoint | 'default')[]
}

// Returns the relevant crop ratio from image crops
export function getPlaceholderRatio(
    crops: ImageCrops,
    fixedRatio: BreakpointRatios | undefined,
): BreakpointCrops<ImageRatio | undefined> | undefined {
    if (fixedRatio) {
        return mapCropBreakpoint(fixedRatio, (ratio) => {
            if (ratio === 'original') {
                return crops.original ? crops.original.ratio : undefined
            }
            const crop = crops[ratio]
            return crop ? crop.ratio : KnownRatioToRatio[ratio]
        })
    }

    return undefined
}

// Maps over the crop breakpoints returning an object that is the result of the cropFn only if its not null
// similar logic to the forEachBreakpoint
export function mapCropBreakpoint<T = any, R = any>(
    breakpointSet: CropBreakpointSet<T>,
    cropFn: (crop: T) => R | null | undefined,
): CropBreakpointSet<R> | undefined {
    // eslint-disable-next-line
    const mapResult = {} as CropBreakpointSet<R>
    for (const breakpointString in breakpointSet) {
        if (!breakpointSet.hasOwnProperty(breakpointString)) continue
        const breakpoint = breakpointString as CropBreakpoint | 'default'
        // its guaranteed to be specified because of hasOwnProperty
        const crop = breakpointSet[breakpoint] as T
        const cropFnResult = cropFn(crop)
        if (cropFnResult !== null && cropFnResult !== undefined) {
            ;(mapResult as CropBreakpointSet<R>)[breakpoint] = cropFnResult
        }
    }
    // there is a chance its still {} here depending on the function invoking this and the cropFn logic, hence returning undefined
    return Object.keys(mapResult).length > 0 ? mapResult : undefined
}

// This iterates over a CropBreakpointSet<T> and returns a function that represents
// the given crop breakpoint and its crop value
export function forEachCropBreakpoint<T = any>(
    breakpointSet: CropBreakpointSet<T>,
    cropFn: (key: CropBreakpoint | 'default', crop: T) => void,
) {
    for (const breakpointString in breakpointSet) {
        if (!breakpointSet.hasOwnProperty(breakpointString)) continue
        const breakpoint = breakpointString as CropBreakpoint | 'default'
        // its guaranteed to be specified because of hasOwnProperty
        const crop = breakpointSet[breakpoint] as T
        cropFn(breakpoint, crop)
    }
}

// Turns an array of FixedRatio's into a ImageBreakpointRatio
export function fixedRatioToBreakpointRatios(
    fixedRatios: FixedRatio | BreakpointRatios,
): BreakpointRatios {
    if (typeof fixedRatios === 'string') {
        return {
            default: fixedRatios,
        }
    }
    return fixedRatios
}

/**
 * Given an aspect ratio, we can calculate the fixed height of a square
 * to ensure the box is that ratio. We use this for our placeholders
 * @param dimensions { width: number, height: number}
 */
export function calculateIntrinsicRatio(dimensions: ImageRatio) {
    // Calculate the padding/height
    return `${
        Math.round((dimensions.height / dimensions.width) * 10000) / 100
    }%`
}

export function safeAttr(str: string) {
    return ('' + str) /* Forces the conversion to string. */
        .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/`/g, '&#96;')
}

// This returns the image crops based on the breakpoint ratios supplied
export function getCrops(
    imageCrops: ImageCrops | undefined,
    breakpointRatio: BreakpointRatios | undefined,
    useOriginalCrop: boolean,
): ProcessedBreakpointCrops | undefined {
    if (!imageCrops) {
        return undefined
    }

    // return the original crop, and assign a ratio equal to the first breakpoint ratio
    // this is required in cases where object-fit-contain and object-fit-cover are used
    if (useOriginalCrop && imageCrops['original']) {
        const crop = imageCrops['original']
        if (breakpointRatio) {
            const firstRatio = breakpointRatio.default
            if (firstRatio !== 'original') {
                // dont want to mutate
                return {
                    default: { ...crop, ratio: KnownRatioToRatio[firstRatio] },
                }
            }
        }
        return { default: crop }
    }

    if (breakpointRatio) {
        // return the default fixedRatio crop, we only want to return the first image crop
        // as that would be the most relevant.
        const crops = mapCropBreakpoint(breakpointRatio, (fixedRatios) => {
            return imageCrops[fixedRatios] || null
        })

        return crops
    }

    return undefined
}

// Returns a collection of source elements, each with its own ratio, srcSet & sizes
export function getPictureSources(
    crops: ProcessedBreakpointCrops,
    imageSizes: ResponsivePictureSizes,
    srcInfo: ImageSrcInfo,
    doubleSize = false,
): JSX.Element[] {
    const cropBreakpoints = getIterableBreakpoints(crops)
    const largestCropBreakpoint = getLargestCropBreakpoint(cropBreakpoints)
    const mobileMax = breakpoints.sm - 1
    const tabletMax = breakpoints.md
    const sizes =
        `(max-width: ${mobileMax}px) ${imageSizes.mobile},` +
        `(min-width: ${mobileMax + 1}px) and (max-width: ${tabletMax}px) ${
            imageSizes.tablet
        },` +
        `${imageSizes.desktop}`

    return cropBreakpoints.map((breakpoint, index) => {
        const mediaQuery =
            breakpoint !== 'default'
                ? `(max-width: ${breakpoints[breakpoint] - 1}px)`
                : `(min-width: ${largestCropBreakpoint}px)`

        const crop = crops[breakpoint]

        if (!crop) {
            return <Fragment />
        }

        const srcSetValue = [100, 150, 250, 320, 414, 500, 640, 828, 1024]
            .map(
                (size) =>
                    `${srcInfo.srcCallback(
                        crop.reference,
                        doubleSize ? size * 2 : size,
                    )} ${size}w`,
            )
            .join(',')
        const ratio = `${crop.ratio.width}:${crop.ratio.height}`

        return (
            <source
                data-ratio={ratio}
                key={index}
                media={mediaQuery}
                srcSet={srcSetValue}
                sizes={sizes}
            />
        )
    })
}

// Determines the highest breakpoint from a collection of crop breakpoints
function getLargestCropBreakpoint(
    cropBreakpoints: (keyof CropBreakpointSet<any>)[],
): number {
    return cropBreakpoints.reduce(
        (currentHighest, breakpoint) =>
            breakpoint !== 'default' && breakpoints[breakpoint] > currentHighest
                ? breakpoints[breakpoint]
                : currentHighest,
        0,
    )
}
