import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { AdTargeting, StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'
import { billboardLeaderboardMrec } from '../../ad-units'

// TODO: Update icons

const bannerDesktopX2 = require<string>('./assets/2023-VC-Feature-Banner-Desktop@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/2023-VC-Feature-Banner-Desktop@1x.jpg')

const bannerTabletX2 = require<string>('./assets/2023-VC-Feature-Banner-Tablet@2x.jpg')

const bannerTabletX1 = require<string>('./assets/2023-VC-Feature-Banner-Tablet@1x.jpg')

const bannerMobileX2 = require<string>('./assets/2023-VC-Feature-Banner-Mobile@2x.jpg')

const bannerMobileX1 = require<string>('./assets/2023-VC-Feature-Banner-Mobile@1x.jpg')

const socialImage = require('./assets/2023-VC-social-image.jpg')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '627',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

const topic: Topic = {
    id: 'features/vanishing-cousins',
    metadata: {},
    seoTitle: `Vanishing Cousins`,
    title: `Vanishing Cousins`,
    seoDescription: `Vanishing Cousins`,
}

export const getVanishingCousinsRouteInfo: StaticRoutes<TheWestSection> = {
    '/features/vanishing-cousins': ({
        getAdTargeting,
        config,
        store,
        resolution,
    }) => {
        const canonicalUrl = config.publicUrl + '/features/vanishing-cousins'

        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'vanishing-cousins-feature-route',
        )

        if (!enableRoute) {
            return null
        }

        const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
            'video-series',
            'default',
            topic,
        )

        // Added custom adtargeting path resolutions for this features.
        // Might remove if it causes issues
        let newAdUnitPath: string = adUnitPath

        if (ssAdUnits && adUnitPath) {
            ssAdUnits.splice(2, 0, 'features')

            const adUnitsArray = adUnitPath.split('/')
            adUnitsArray.splice(4, 0, 'features')
            newAdUnitPath = adUnitsArray.join('/')
        }

        const adTargeting: AdTargeting = {
            pageId: topic.title,
            adUnitPath: newAdUnitPath,
            ssAdUnits,
            topics,
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'video-series',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting,
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                // Image Header
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: bannerDesktopX2,
                                    verticalGutters: ['unset', 'md'],
                                    altText: topic.seoTitle,
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: bannerDesktopX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerDesktopX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: bannerTabletX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerTabletX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: bannerMobileX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerMobileX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            // Hero Image & Subscribe Layout
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    layoutRatio: '3:1',
                                    stretchColumns: true,
                                    verticalSpacing: 'xl',
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },

                                                cardLayout: ['hero-video'],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'vanishing-cousins',
                                                    offset: 0,
                                                    pageSize: 1,
                                                },
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'tw-subscribe-vc-promo-card',
                                            props: {
                                                navName: 'VanishingCousins',
                                                navLocation: 'MREC',
                                                navLink: '/vanishing-mrec',
                                            },
                                        }),
                                    ],
                                },
                            }),
                            // Episde Curations
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'Watch the episodes',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'vanishing-cousins',
                                        offset: 1,
                                        pageSize: 8,
                                    },
                                },
                            }),
                            // Banner Ad #1
                            ...billboardLeaderboardMrec('one'),
                            // Other Exclusive Video Series
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 1,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'Other Exclusive Video Series',
                                        idTagName: 'other-exclusive-series',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'vanishing-cousins-exclusives',
                                        offset: 0,
                                        pageSize: 4,
                                    },
                                },
                            }),
                            // More On Vanishing Cousins
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 1,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: false,
                                    sectionHeader: {
                                        heading: 'More on Vanishing Cousins',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'vanishing-cousins-more',
                                        offset: 0,
                                        pageSize: 4,
                                    },
                                },
                            }),
                            // Related Courts & Crime Content
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 1,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: 'Courts and Crime',
                                    },
                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['news/crime'],
                                        includeSubTopics: true,
                                        paging: {
                                            page: 1,
                                            pageSize: 4,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                                link: '/news/crime',
                            }),
                            // Ad slot #2
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
