import { ThemeProvider } from '@emotion/react'
import { PartialComponentTheme, Theme, ThemeConfig } from '../../__styling'
import React, { PropsWithChildren } from 'react'
import merge from 'deepmerge'

export type ComponentOverriderProps<T extends ThemeConfig> = {
    override: (scheme: Theme<T>) => PartialComponentTheme<T>
}

/**
 * Overrides component theme tokens for scoped, semantic color/structure changes.
 * No broad theme values are EVER to be introduced to this file.
 *
 * Definitions & Documentation:
 * https://wanews.atlassian.net/wiki/spaces/REF/pages/153714860/Theming+Restructure
 */
export const ComponentOverrider = <T extends ThemeConfig>({
    children,
    override,
}: PropsWithChildren<ComponentOverriderProps<T>>) => {
    return (
        <ThemeProvider
            theme={(outerTheme) => {
                return {
                    ...outerTheme, // Inherit scheme tokens
                    ...{
                        // Replace 'components()'
                        components() {
                            return merge(
                                outerTheme.components(),
                                // Pass outerTheme into override's 'this' value.
                                override(outerTheme as Theme<T>),
                            )
                        },
                    },
                }
            }}
        >
            {children}
        </ThemeProvider>
    )
}
