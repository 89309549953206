import styled, { CSSObject } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { StyledVideo } from '../../content/Video/Video.styled'
import { IconCamera } from '../../icons/IconCamera/IconCamera'
import { ArticleVideo } from '../../publication/ArticleVideo/ArticleVideo'
import { StyledArticleVideoContainer } from '../../publication/ArticleVideo/ArticleVideo.styled'
import { breakpoints, Breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import {
    breakpoint,
    breakpointMax,
    getSmallestBreakpointValue,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { MediaMode } from './CardMedia'

export const styledMediaClassName = 'Card-Media'
export const styledMediaContentClassName = 'Card-Media-Content'
export const styledPlayButtonClassName = 'Card-PlayButton'
export const styledGalleryButtonClassName = 'Card-GalleryButton'
export const styledGalleryIconClassName = 'Card-GalleryIcon'

const cardSpacing = calcRem(metrics.perthnow.cards.spacing)

interface StyledMediaProps {
    mediaMode?: MediaMode
    borderShadingOnPN?: boolean
    isVideoRendered?: boolean
}

export const StyledMediaContent = withClass(styledMediaContentClassName)(
    styled('div')<StyledMediaProps>(({ theme, isVideoRendered }) => [
        {
            position: 'relative',
            display: isVideoRendered ? 'none' : 'block',
        },
        theme.kind === 'thenightly' && {
            width: '100%',
            maxHeight: '100%',
        },
    ]),
)
export const StyledVideoCardNowPlaying = styled.span(({ theme }) => [
    // Shared
    {
        display: 'none',
    },
    // Sevennews
    theme.kind === 'sevennews' && {
        display: 'block',
        width: calcRem(88),
        height: calcRem(22),
        padding: 0,
        background: colors.sevennews.red,
        borderRadius: calcRem(2),
        textAlign: 'center',
        lineHeight: calcRem(22),
        fontSize: calcRem(10),
        color: 'white',

        [breakpoint('sm')]: {
            fontSize: calcRem(14),
        },
    },
])

type BreakpointKey = keyof Breakpoints

function getMediaBreakpointStyles({ mediaMode }: StyledMediaProps): CSSObject {
    if (!mediaMode) {
        return {
            display: 'block',
        }
    }

    if (typeof mediaMode === 'string') {
        return { display: mediaMode === 'hidden' ? 'none' : 'block' }
    }

    const result: CSSObject = {}

    for (const [breakpointValue, mediaState] of Object.entries(mediaMode)) {
        if (!(breakpointValue in breakpoints) || breakpointValue === 'initial')
            continue

        result[breakpoint(breakpointValue as BreakpointKey)] = {
            display: mediaState === 'hidden' ? 'none' : 'block',
        }
    }

    return result
}

export const StyledMedia = withClass(styledMediaClassName)(
    styled('div')<StyledMediaProps>(
        ({ theme, mediaMode, borderShadingOnPN }) => {
            const smallestBreakpointValue =
                getSmallestBreakpointValue(mediaMode)

            return {
                [breakpointMax('xs')]: {
                    display:
                        smallestBreakpointValue === 'hidden' ? 'none' : 'block',
                },

                overflow: 'hidden',
                backfaceVisibility: 'hidden',
                position: 'relative',
                flex: 'none',
                margin:
                    mediaMode === 'edgeToEdge' || mediaMode === 'inlineVideo'
                        ? calcRem(
                              -theme.cards.outerMargin,
                              -theme.cards.outerMargin,
                              theme.margins.sm,
                              -theme.cards.outerMargin,
                          )
                        : undefined,
                marginBottom:
                    mediaMode === 'fullHeight' ? 0 : calcRem(theme.margins.sm),
                width:
                    mediaMode === 'edgeToEdge'
                        ? `calc(100% + ${theme.cards.outerMargin * 2}px)`
                        : undefined,

                '&::after': {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    content: `''`,
                    background: 'transparent',
                    borderWidth: '4px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    opacity: borderShadingOnPN ? '0.5' : 'inherit',
                    ...transition({ properties: ['height', 'border-color'] }),
                },
            }
        },
        // Fix for 7News hero card for Video
        ({ theme, mediaMode }) =>
            theme.kind === 'sevennews' &&
            mediaMode === 'edgeToEdge' && {
                margin: calcRem(
                    -theme.cards.outerMarginLarge,
                    -theme.cards.outerMarginLarge,
                    theme.margins.sm,
                    -theme.cards.outerMarginLarge,
                ),
                width: `calc(100% + ${theme.cards.outerMarginLarge * 2}px)`,
            },
        getMediaBreakpointStyles,
    ),
)

export const StyledArticleVideo = styled(ArticleVideo)({
    width: '100%',
})

interface StyledMediaContainerProps {
    mediaMode?: MediaMode
    isVideoRendered: boolean
}

export const StyledMediaContainer = styled.div<StyledMediaContainerProps>(
    ({ theme, mediaMode, isVideoRendered }) => [
        {
            display: isVideoRendered ? undefined : 'none',
            marginBottom: 0,
            width: '100%',
            minWidth: calcRem(110),

            // To remove the pop out that happens on article pages. Don't want it on cards
            [`& ${StyledArticleVideoContainer}`]: {
                [breakpointMax('sm')]: {
                    margin: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingBottom: 0,
                },
            },
        },
        theme.kind === 'thewest' && [
            mediaMode === 'inlineVideo' && {
                width: '110px',
                marginRight: '0.5rem',
                [`& ${StyledVideo}`]: {
                    minHeight: '80px',
                },
                [breakpoint('sm')]: {
                    width: '210px',
                },
                [breakpoint('md')]: {
                    width: 'unset',
                    margin: calcRem(
                        -theme.cards.outerMargin,
                        -theme.cards.outerMargin,
                        0,
                        -theme.cards.outerMargin,
                    ),
                },
            },
            mediaMode === 'edgeToEdge' && {
                width: `100%`,
            },
        ],
        // Fix for 7News hero card for Video
        theme.kind === 'sevennews' &&
            mediaMode === 'edgeToEdge' && {
                margin: calcRem(
                    -theme.cards.outerMarginLarge,
                    -theme.cards.outerMarginLarge,
                    theme.margins.sm,
                    -theme.cards.outerMarginLarge,
                ),
                width: `calc(100% + ${theme.cards.outerMarginLarge * 2}px)`,
            },

        /**
         * Counter the negative margin applied for child StyledArticleVideo elements of this component
         */
        theme.kind === 'perthnow' && [
            mediaMode === 'inlineVideo' && {
                padding: calcRem(0, metrics.perthnow.margins.md),
            },
            mediaMode === 'edgeToEdge' && {
                width: `calc(100% + ${theme.cards.outerMargin * 2}px)`,
                margin: calcRem(
                    -theme.cards.outerMargin,
                    -theme.cards.outerMargin,
                    theme.margins.sm,
                    -theme.cards.outerMargin,
                ),
            },
        ],
    ],
)

export const StyledCardPlayButton = withClass(styledPlayButtonClassName)(
    styled('span')(({ theme }) => ({
        position: 'absolute',
        bottom: calcRem(10),
        left: calcRem(10),
        width: calcRem(32),
        height: calcRem(32),
        borderRadius: '100%',
        lineHeight: 1,
        zIndex: themedValue(theme, {
            perthnow: zIndex.perthnow.cardIcon,
            thewest: zIndex.thewest.cardPlayButton,
            fallback: 20,
        }),
        backgroundColor: themedValue(theme, {
            perthnow: colors.perthnow.greyBasalt,
            fallback: colors.white,
        }),

        ...transition({ properties: ['background-color'] }),

        '&::before': {
            position: 'absolute',
            top: '50%',
            left: cardSpacing,
            display: 'block',
            width: 0,
            height: 0,
            borderWidth: `${calcRem(8)} 0 ${calcRem(8)} ${calcRem(12)}`,
            borderStyle: 'solid',
            borderColor: 'transparent',
            borderLeftColor: themedValue(theme, {
                thewest: (section) => section.primaryColor,
                fallback: colors.white,
            }),
            content: `''`,
            transform: 'translateY(-50%)',
            ...transition({ properties: ['border-color'] }),
        },
    })),
)

export const StyledProviderLogo = styled('img')({
    position: 'absolute',
    top: calcRem(10),
    right: calcRem(10),
    width: calcRem(34),
    height: calcRem(34),
    zIndex: zIndex.thewest.cardPlayButton,
})

export const StyledGalleryButton = withClass(styledGalleryButtonClassName)(
    styled('div')({
        backgroundColor: colors.perthnow.greyBasalt,
        color: colors.white,
        width: calcRem(46),
        height: calcRem(46),
        borderRadius: calcRem(46),
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: calcRem(26),
        margin: metrics.perthnow.margins.sm,
        zIndex: zIndex.perthnow.cardIcon,
        ...transition({ properties: ['color', 'background-color'] }),
    }),
)

export const StyledGalleryIcon = withClass(styledGalleryIconClassName)(
    styled(IconCamera)({
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }),
)
