import React from 'react'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface GameOnParisLogoProps {
    className?: string
    height?: number
    width?: number
}

export function GameOnParisLogo({
    className,
    width,
    height,
}: GameOnParisLogoProps) {
    return (
        <svg
            width={width ? calcRem(width) : '1300'}
            height={height ? calcRem(height) : '271'}
            viewBox="0 0 1300.16 270.87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className ? className : undefined}
        >
            <title>Game On Paris</title>
            <g>
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M1.5,135.7c3.71-4.75,15.04-45.93,41.26-64.39,2.29-1.45.99-4.08,3.47-6.35,12.25-10.54,19.34-14.64,38.27-15.53,13.54,3.94,10.99,15.63,20.15,22.2.06,3.76-1.79,6.27-3.85,9.06,1.91.72,3.41,2.8,4.28,4.37-.19,1.35-1.01,2.74-1.85,3.05-1.93-2.33-6.42-6.43-11.05-6.21-23.14,1.09-72.62,51.61-68.42,89.63,14.99,2.52,37.61-4.2,42.82-7.68,2.71-1.74,5.58-6.19,7.21-9.76,5.29-11.28,13.07-24.3,19.66-33.49.86,1.03,1.92,1.79,2.99,2.55-1.64,2.5-3.87,7.72-3.42,9.58l5.13-8.32c4.46,2.48,8.91,4.16,9.93,14.87,5.26-.25-.85,12.69,1.1,15.56-14.62,20.07-19.8,25.16-22.89,28.8-1.77,7.35-4.14,16.88-7.14,26.71l.87,1.84c3.23-8.23,5.2-16.66,7.39-24.3l2.29-1.45c-5.19,17.47-10.09,39.5-16.98,55.98-1.82,4.39.94,6.15-.87,11.08-1.61,4.65-3.38,12-7.59,12.2l-3.58.17-.06-3.5c-9,2.85-13.74-3.66-15.55-11.11l5.06-12.08c-3.35,1.24-4.76,4.8-5.75,8.88l-3.58.17c5.23-15.05,9.03-27.07,12.84-38.56,3.21-9.57,5.64-2.96,5.39-17.75l-3.76,1.79c-3.02-4.43-10.12-1.14-15.09,4.21-17.87,1.11-27.2-3.02-35.3-22.02l-2.52.12c-4.22-13.26-2.09-24.93.87-36.91l-1.74-3.42ZM21.48,160.33c-1.64,2.5-2.45,4.96-2.41,6.84.03,1.61.89,2.92,2.16,3.4l.25-10.24ZM97.8,129.54l-4.09,7.46c2.94-.68,5.41-3.75,7.27-5.73-1.1-2.64-2.16-3.67-3.19-1.73Z"
                />
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M203.84,151.71c-1.61,4.65-.78,16.46-5.11,22.04,2.98,2.01,1.41,8.82-1.27,12.17,1.33,3.97,2.22,6.89-2.08,14.63-.19,1.09,1.89-.36,2.3-.92-.27,9.16-1.83,16.51-3.81,24.67-6.52.31-12.54-7.48-15.88-5.71.12-5.39-.54-7.51-3.49-7.37l.15-3.78c1.05-.05,1.88-.9,2.06-2.79-1.05.05-1.91-.72-1.94-2.6.32-6.21,2.72-14.12,3.85-22.25,1.47-.07,1.53,3.43,1.39,7.47,1.47-.07,3.61-11.21,3.92-17.41l-2.55-1.23c-.14,4.31-.04,10.23-2.59,9,.57-3.8,1.05-13.24,1.62-16.5l3.67,5.48c-.26-3.22.78-4.07.29-8.09-12,.03-43.4,23.31-52.73,32.1l-.99,3.55.89,3.19c-3.42,9.58-6.4,20.76-9.67,26.56l-13.37-6.64c-.77-8.31-9.87-11.38-7.67-18.48l-3.37.16c3.96-40.83,38.37-86.06,57.59-120.88,9.61-17.14,19.31-28.9,27.38-37.63,1.45-1.68,7.38.73,7.72-4.4,2.75.95,8.69,3.9,11.9,6.97l-3.76,1.79c-.14,4.58,5.04-.78,6.11.25,7.92,8.24,3.74,22.97,7.87,30.58.23,1.34.19,24.48-2.93,27.05.43.52.64.51,1.28,1.02-1.16,5.98-2.72,13.86-3.02,21.41-.16,3.24,7.98,12,7.55,23.87-3.78.72-7.76,1.98-11.32,2.69ZM137.51,176.38c-1.02,1.93-1.42,3.03-2.23,4.95l15.81-11.51c-2.78-2.56-9.62,3.68-13.57,6.56ZM146.37,164.65c-1.67.89-2.93,1.21-4.39,2.09l-2.45,4.69c.83-.58,5.63-2.96,6.84-6.78ZM196.19,70.25c-3.99.46-13.42,15.98-22.21,31.73l-1.07-1.29c.6-2.18,1.2-3.83.34-4.86-3.29,5-4.72,7.49-2.74,12.51-3.07,5.53-5.93,10.51-8.6,14.94-3.68,6.36-9.17,18.2-13.87,27.3,4.15-3.43,7.22-9.22,15.56-14.19,7.3-4.38,11.7-5.67,19.22-8.71.52-7.02,2.54-12.23,3.32-16.04l3.64,3.87c2.39-8.46,4.98-30.38,7.09-42.59l-.68-2.66ZM160.87,162.89v-.27c-4.63.49-6.51,1.12-7.28,5.46,1.87-1.43,5-3.2,7.28-5.19ZM179.82,189.18l-.78,3.54,1.26-.06.77-4.34-1.25.87ZM185.28,124.06c-.79,3.27.68,3.2,2.15,2.86l.16-2.97-2.31.11ZM187.1,119.67l-.85-.23-.79,3,1.89-.09-.25-2.68ZM192.66,86.3l-1.89.09,1.56-7.34,1.88-1.17-1.55,8.42Z"
                />
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M385.05,99.01c-4.66-1.66-6.55,11.08-7.56,14.08l-3.37.16c.05,3.23,3.01,3.63,4.91,4.34-1,3.01-3.3,3.93-5.83,3.78l-5.5,23.94,2.5-1.73,4.14-17.42,1.33,4.24-2.72,13.59c-.97,4.89-2.8,8.75-4.91,8.84-2.94.14-2.24,4.95-3.02,8.49l-1.51-2.35-1.24,1.67c1.1,2.64,3.26,6.04,3.31,9.26.1,6.19-4.97,18-8.37,28.66l-3.64-3.87c-.41.56-1.04.86-1.66,1.16-.63.3-1.26.33-2.1.37-7.99.38-17.53-16.4-17.69-26.08-.18-11.03,16.42-51.38,23.64-72.98l-.67-2.12c-6.02,5.13-20.85,12.02-21.35,20.39l-36.57,42.37c-3.68-6.02-6.13-1.33-10.54-1.12-5.47.26-8.84-12.77-11.09-21.55,2.94-13.33,7.93-29.98,12.73-45.28l-2.19-5.28c-8.27,9.27-15.83,23.36-21.52,35.47-8.54,18.71-20.06,47.51-28.76,68.92-2.12-.98-.51-5.63.9-9.46-2.33-.7-3.93,3.95-5.32,9.13-1.38,5.45-.87,11.34.46,15.05-1.03,1.12-2.08,1.71-3.34,1.77-8.62.41-20.93-18.66-21.03-24.57-.11-6.45,16.98-43.06,26.34-62.88,10.57-22.84,20.96-44.05,35.04-58.45,3.87-7.99,12.24-23.99,19.81-24.35,11.78-.56,16.29,18.07,21.38,32.9-.94,7.04-.14,17.23,2.07,23.32,6.63-6.23,14.9-15.78,21.55-20.93,1.45-1.15,3.37.38,4.46,2.48,1.66-1.69,2.87-4.71,1.77-7.62,11-9.4,24.95-19.21,29.57-19.43,4-.19,9.12,4.15,13.61,8.24,3.75,10.59,7.89,19.28,8.04,28.15.13,7.8-2.23,18.14-6.04,16.7ZM238.66,170.25l-2.23,5.22,1.29,1.55,2.22-5.76-1.28-1.02ZM239.66,167.24l1.28,1.29c1.01-2.74,2.04-3.87.55-5.14l-1.83,3.86ZM362.26,146.92c.02,1.35.25,2.68.26,3.22,1.66-1.69,2.62-6.85.71-7.84-.62.84-.8,2.73-.98,4.62ZM372.29,116.57h-.21c-2.86,5.53-6.74,12.71-6.6,21.05.03,1.61.26,2.95.5,4.55h.21s6.11-25.6,6.11-25.6ZM375.41,101.35l-2.18,8.45c2.28-2.26,4.13-5.04,3.47-6.35l-1.3-2.09ZM378.11,99.34l2.39-8.46c-2.94.14-3.1,3.64-4.7,8.57l2.31-.11ZM381.49,112.9l-2.31.11c.81-2.19,2.87-4.98,3.91-5.57l-1.59,5.46Z"
                />
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M495.8,62.56c-18.96,11.93-38.51,13.66-56.91,21.26-2.23,4.95-5.08,11.27-8.53,18.7,8.4-1.47,25.7-11.44,31.8-11.73,6.1-.29,8.04,2.31,12.61,11.79-.79,3,.3,5.64,2.43,6.88l-1.38,5.99c-12.79,3.29-44.02,11.23-54.44,17.64-5.42,3.22-9.77,33.03-9.88,39.22,6.62,5.88,16.46,2.72,22.53.55l1.31,2.63c1.47-.07,3.34-1.77,3.31-3.39,3.15-.69,4.3,5.72,7.23,4.5,13.59-5.49,29.67-13.24,40.12-18.04.63-.3,1.46-.61,1.69.19.44,1.33-2.68,3.36-4.35,4.51l-14.19,7.4c1.28,1.02,3.62,2.79,5.11,3.8.64.24.22.8-.4,1.09-10.44,5.07-23.41,10.26-35.12,14.58,3.19,1.73,7.81,1.52,11.79-.02,8.37-3.35,19.85-9.01,30.08-14.07.63-.3,2.09-1.18,1.08,1.56-1.22,2.75-2.87,4.71-5.79,6.2-13.56,7.37-31.1,16.27-43.93,16.88-24.61,1.16-42.3-12.27-42.1-51.57.09-19.92,12.52-45,27.21-86.87-1.16-6.67-1.06-13.14,2.61-20.58,10.54,1.12,16.3-19.88,23.67-20.23,4-.19,15.21,3.32,17.96,4.27.31,5.91-.64,12.14-3.47,19.54,3.59.91,13.71,1.78,18.34,1.56l1.98,5.02c1.05-.05,2.92-1.75,5.23-1.86,2.31-.11,7.41,2.34,10.36,2.74.42-.02.85.5.44,1.05-8.26,10.08-14.24,4.44-12.64,12.44,6.26-3.53,11.48-5.66,17.32-9.43.83-.58,2.06-2.52,2.3-.92.46,2.67-3.29,4.46-5.36,6.71ZM408.37,160.62c-.76,4.88-.91,8.39.79,9.92l.93-7.31-1.73-2.61ZM411.48,145.13l-2.15,10.06,1.51,2.35,2.74-12.78-2.1.37ZM411.87,143.23h.21c1.23-2.22,2.87-4.45,3.67-7.18-2.74-.14-3.55,1.51-3.93,4.22l.05,2.96ZM423.22,103.66l2.73-.67,2-6.29c-1.47.07-4.15,3.96-4.73,6.95ZM432.6,85.19l-3.45,7.7,2.72-.94,3.45-7.7-2.72.94ZM461.27,164.58c-5.8,5.93-13.1,10.04-15.71,5.05,9.63-3.15,19.24-7.64,26.96-12.04.83-.58,1.06.22.65,1.04-2.46,3.89-7.9,6.03-11.9,5.95ZM479.26,54.99l.68,3.2,3.75-2.33c-.23-1.34-3.18-1.47-4.43-.87Z"
                />
            </g>
            <g>
                <path
                    className="cls-1"
                    fill="#e6a601"
                    d="M730.82,56.47c1.85-1.62,5.2-2.79,8.73-3.05,6.05-.27,10.76.84,13.62,3.57l-3.36,3.24.34.92c1.18,0,4.37-1.41,5.37-2.33l4.72,6.85c-8.73,5.57-24.33,21.51-32.86,37.63-1,1.84-1.68-.9-2.69.02-.32,8.26-3.84,12.64-7.85,21.39-.84,2.07-.49,5.05-1.5,8.27,0,1.38-2.52,2.77-3.02,4.84-4.16,17.69-9.84,36.09-15.18,54.93l-7.57-4.76-1,3.22c-10.77-3.82-11.79-9.55-13.33-24.91-.52-5.96.81-16.29-.38-21.33-4.07-16.03-.22-22.94-1.25-34.63l-.86-8.94c-5.21,2.33-11.07,9.25-15.42,20.07l.51,3.67c-8.36,21.62-15.86,51.5-23.39,65.32l-4.71.03c-4.87.04-7.6-15.31-9.62-16.68l-1.03-10.09c-.86-7.11.65-9.64,1.82-14.01,11.85-39.54,30.96-63.53,44.2-82.21,5.05,2.49,18.86,14.09,21.23,22.56,2.03,6.87,4.08,21.3,5.78,30.7,11.37-27.15,28.11-60.99,31.8-62.85l3.53-.71c-10.39,14.07-17.75,30.64-21.77,38.69-1.51,3.45-2.18,4.83-1.84,5.06l1.01.91c4.85-10.36,16.07-32.23,25.96-45.38ZM664.29,73l-3.69,5.3c2.02,2.28,3.86-2.32,5.03-3.93l-1.35-1.37ZM714.54,145.59l-1.52-1.37c.16-3.21.32-7.57,2.34-6.44l-.82,7.81ZM727.7,92.27c-1.34,2.76-4.02,7.6-2.33,8.73l3.51-7.37c.33-.69-.84-2.06-1.18-1.37ZM726.81,67.96l2.85-5.07-1.35-.91c-.84.92-1.84,2.54-2.85,4.84.17.46.68,1.37,1.35,1.14ZM732.06,84.21l-3.35,5.99c1.01-.24,1.35.22,1.52,1.14l3.01-5.99c.34-.46-.34-2.52-1.18-1.14ZM744.79,65.08l.34,2.06c1.68-1.62,3.19-1.86,2.85-5.3l-3.19,3.23Z"
                />
                <path
                    className="cls-1"
                    fill="#e6a601"
                    d="M613.52,136.09c.96,2.56-.39,5.38-2.5,5.39l-1.35-3.33c-1.54.52-2.69,1.55-1.54,4.36-2.11,0-5.57,2.32-5.57,5.14,0,3.84-3.27,2.57-5.57,3.35-2.11,2.82-3.84,6.16-6.15,10.26l-2.11-2.55c-5,4.11-2.31,6.92-7.49,10.27-3.07,2.06-6.72,1.82-10.18,2.85-.58.77,1.73,1.79,1.54,2.81l-3.46,1.55-5.38-4.33c-3.08,2.57-.77,8.45-4.61,10.26-4.61,2.06-8.26,3.87-13.64,6.19-3.27-4.34-7.11-2.79-9.8.03-6.73-5.61-14.99-14.03-19.98-19.65-4.61-4.85-6.15-17.39-6.15-29.68,0-62.22,58.22-102.62,72.63-102.67,5.57-.02,8.26,4.58,14.22,10.7,0,11.78,9.42,21.22,17.29,28.36,7.49,6.63,9.99,19.43,9.99,33.25,0,8.45-3.27,19.98-10.18,27.43ZM592.76,83.67c-.58-2.3-.96-3.84-1.73-2.81-.77,1.03-1.54,1.54-2.11,1.54-1.35,0-2.11-2.04-2.31-2.04-10.18.03-27.86,15.71-35.93,32.64-9.22,5.92-15.56,20.54-19.79,38.22,4.42-.53,10.18-1.06,13.64-2.86l34.59-19.32c3.84-2.06,12.11-7.47,15.76-13.63l8.65-15.14-10.76-16.61ZM545.11,112.51c-2.88-2.29-6.34,1.3-6.73,4.12,0,1.54.58,2.82,2.31,3.07l4.42-7.19ZM545.88,183.69c-.77-1.53-2.11-2.81-3.46-2.8-.77.26-1.54.52-2.31,1.54l5.76,1.26ZM571.05,165.68c0,.77-.77,2.56-.39,3.07.77.77,2.31,0,3.27-.52,0-1.79-.96-2.56-2.88-2.55ZM581.43,158.22c-3.46,2.32-4.8,3.6-4.61,6.42l5.57-3.86-.96-2.56ZM586.81,152.06l-3.08,4.36.77,2.81,3.84-3.09c-.58-.77-.96-3.58-1.54-4.09Z"
                />
            </g>

            <g>
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M946.83,147.28c-1.82,4.57-1.6,16.59-6.16,21.89,2.85,2.28.95,9.04-1.87,12.21,1.12,4.13,1.85,7.16-2.79,14.64-.25,1.08,1.89-.21,2.32-.74-.73,9.25-2.64,16.55-5,24.66-6.47-.23-12.05-8.61-15.45-7.09.39-5.44-.16-7.65-3.09-7.75l.34-3.81c1.04.04,1.91-.75,2.18-2.65-1.04-.04-1.85-.88-1.79-2.79.63-6.25,3.39-14.07,4.92-22.2,1.46.05,1.34,3.59,1,7.68,1.46.05,4.13-11.04,4.76-17.3l-2.46-1.45c-.35,4.35-.55,10.35-3.01,8.9.75-3.79,1.7-13.31,2.43-16.56l3.36,5.85c-.1-3.28.97-4.06.69-8.16-11.89-.96-44.16,20-53.84,28.12l-1.16,3.51.73,3.3c-3.87,9.41-7.37,20.48-10.9,26.08l-12.91-7.82c-.35-8.47-9.21-12.33-6.68-19.34l-3.34-.12c5.96-40.99,42.31-83.92,63.08-117.57,10.38-16.55,20.57-27.65,29-35.82,1.52-1.58,7.27,1.35,7.86-3.82,2.68,1.19,8.42,4.66,11.44,8.04l-3.81,1.5c-.36,4.63,5.03-.37,6.04.76,7.44,8.99,2.56,23.56,6.27,31.6.16,1.37-1.03,24.8-4.25,27.14.4.56.61.57,1.22,1.13-1.45,5.95-3.39,13.8-4.06,21.41-.32,3.26,7.31,12.81,6.29,24.78-3.78.41-7.78,1.36-11.35,1.78ZM879.9,166.75c-1.11,1.87-1.56,2.95-2.46,4.83l16.24-10.34c-2.62-2.82-9.72,2.93-13.77,5.52ZM889.26,155.62c-1.7.76-2.96.99-4.45,1.75l-2.66,4.55c.85-.52,5.73-2.53,7.11-6.3ZM943.32,64.21c-3.98.13-14.09,15.06-23.59,30.28l-1-1.4c.7-2.16,1.38-3.77.58-4.89-3.5,4.79-5.05,7.19-3.34,12.43-3.31,5.34-6.4,10.14-9.26,14.41-3.97,6.14-10,17.66-15.1,26.48,4.29-3.12,7.61-8.74,16.12-13.08,7.45-3.83,11.87-4.77,19.48-7.23.86-7.06,3.12-12.17,4.08-15.96l3.41,4.21c2.79-8.36,6.44-30.33,9.15-42.52l-.54-2.75ZM903.71,155.04v-.27c-4.59.11-6.49.59-7.47,4.92,1.92-1.3,5.11-2.82,7.47-4.65ZM921.17,183.22l-.95,3.51,1.25.04.98-4.33-1.28.77ZM929.83,117.76c-.94,3.24.52,3.29,1.99,3.07l.31-2.99-2.3-.08ZM931.85,113.47l-.83-.3-.94,2.97,1.88.07-.12-2.73ZM939.02,80.16l-1.88-.07,1.92-7.3,1.92-1.02-1.95,8.39Z"
                />
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M1155.12,59.23l-2.51-.02c-6.24,9.19-10.05,26.18-20.6,32.16l.89,2.93-8.95,5.27c-9.02,13.54-13.05,30.28-18.2,44.1-.7,1.94-3.7,5.8-.75,6.8.68.25,2.33-4.84,2.56-5.57,5.36-12.6,9.41-31.53,17.72-41.43,3.21-1.19.9,1.71.43,2.44-8.12,14.28-12.14,30.78-18.92,47.74-4.67,11.39-6.36,21.83-5.26,26.71-4.09-1.5-7.73-3.23-11.36-5.46l.07-7.78c-1.61,1.44-2.31,3.14-2.56,5.81-5.21-4.18-9.49-9.82-12.38-18.36.4-43.78,43.38-103.22,60.1-135.17,1.39-1.93,4.37-3.36,6.88-3.34,2.28.02,4.55,1.02,7.06,1.77l-.05,5.84c4.55,1.99,8.15,7.62,8.1,12.97-.06,6.57-1.05,15.31-2.26,22.6ZM1100.85,155.78l-2.57,6.54c-.23.73.43,3.17,1.82.75l3.27-8.48c-3.16-3.43-2.29.47-2.52,1.19Z"
                />
                <path
                    className="cls-2"
                    fill="#fff"
                    d="M1262.71,31.7c9.17,2.53,13.12,11.97,11.47,23.29,1.21,1.17,1.62,2.65,1.5,4.14-.48,6.25-9.97,14.73-20.05,17.27-4.85-7.95,3.77-8.38,1.41-11.61-26.3.43-40.03,6.6-64.11,25.42l-.34,4.46,10.25,8.75c10.51,8.74,21.12,12.73,28.18,22.43l3.02-2.13c6.84,9.1,10.4,20.34,9.28,28.08l-3.4,23.83-4.33-1.12c-8.47,12.03-24.39,22.99-40.35,30.98-7.77-3.74-12.31-2.18-12.67,5.85-12.91,5.56-24.31,8.42-29,8.5-3.65.06-9.53-4.6-15.25-11.34-10.23-2.21-12.03-9.31-10.31-14.69-2.97-5.3-4.93-10.32-4.67-13.6.57-7.44,5.34-11.98,9.78-15.62,4.11,4.09,10.07,14.4,14.76,14.32,12.24-.2,37.07-8.63,55.02-25.57l.23-2.98c-5.96-7.04-10.16-20.05-15.12-16.4-6.21-10.6-8.99-15.01-13.97-11.07-3.37-3.51-9.85-10.54-10.51-18.85-.83-9.5-3.11-20.46-2.36-26.71,3.44-24.43,47.5-49.52,80.56-50.06,6.51-.11,15.08.05,21.52.84l-.54,3.58ZM1179.86,206.04l-9.46,4.61,1.14,2.06,8.04-3.1.27-3.57ZM1213.58,72.03c-9.62,3.43-19.6,8.05-20.23,12.81l20.21-12.52.02-.3ZM1263.98,75.97l6.9-5.17c-.27,3.57-1.08,3.88-4.72,7.21l-2.18-2.05Z"
                />
                <g>
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M853.53,91.76c-.97.98,1.02,3.03,3.44.74,4.85-3.95,13.61-9.65,11.6-12.94l-15.04,12.19Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M835.79,103.78l-3.18.17c-3.18.17-5.82,4.62-6.26,8.96,5.85-2.47,12.66-7.14,17.75-11.11-2.99-3.85-4.39,1.77-8.31,1.98Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M843.07,98.47c1.96-.1,2.24,2.96,4.18,1.32l4.13-3.3c-1.24-1.47-6.39-1.82-8.31,1.98Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M872.93,75.33c-1.21.99-2.43,1.36-2.9,3.23-.7,2.5,5.4.33,5.35-3.06-.01-.92-1.24-1.17-2.45-.18Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M801.63,156.42c-1.46.69-4.06,8.53-4.74,12.88.75.88,2.19-1.04,3.17-1.09,1.18-4.07,4.98-13.82,1.57-11.79Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M869.37,24.38c-8.84-1.38-16.43-1.29-24.03-1.19-1.06-6.72-3.58-12.13-5.61-18.19-4.17-.4-7.38-2.69-11.08-4.03-1.72-.52-2.95-1.08-5.4-.95-9.31.49-6.42,36.38-29.7,35.77l.06,4.93c-1.94,1.64-4.85,3.95-6.53,7.12l6.14,1.52c-.44,3.72-1.86,8.11-3.54,10.97,2,2.97,5.72,7.09,9.44,10.28-12.79,36.41-30.5,91.26-37.96,122.15.08,6.16,1.43,16.87,5.44,24.05,3.5,5.98,17.05,11.42,19.25,11.3.2-4.02,1.33-11.16,2.03-14.28l10.53-40.91c.47-1.87-3.41,1.41-3.44-.43-.6-8.9,4.88-21.51,7.5-27.81,2.62-5.99,1.99,2.05,3.21,1.99,1.22-.06,6.63-18.53,9.5-24.84,7.67,5.76,25.59-11.83,42.09-25.02l1.99,2.05c18.65-17.62,31.52-27.85,31.25-49.4-.21-17.55-8.13-23.29-21.14-25.07ZM840.05,45.96c4.75-4.8,29.47-2.88,24.72,14.4s-44.68,33.6-44.68,33.6l19.96-47.99ZM826.34,112.91c.44-4.34,3.08-8.79,6.26-8.96l3.18-.17c3.92-.21,5.32-5.83,8.31-1.98-5.1,3.97-11.9,8.64-17.75,11.11ZM847.25,99.79c-1.94,1.64-2.22-1.42-4.18-1.32,1.91-3.8,7.06-3.45,8.31-1.98l-4.13,3.3ZM856.97,92.5c-2.42,2.28-4.41.23-3.44-.74l15.04-12.19c2,3.28-6.75,8.98-11.6,12.94ZM870.03,78.56c.47-1.87,1.69-2.25,2.9-3.23,1.21-.99,2.44-.75,2.45.18.04,3.39-6.06,5.56-5.35,3.06Z"
                    />
                </g>
                <g>
                    <polygon
                        className="cls-2"
                        fill="#fff"
                        points="980.73 173.21 981.83 176.62 989.06 160.06 987.94 157.8 980.73 173.21"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M1071.48,45.83c-2.57-1.07-7.06-.37-8.74.83l7.52,1.22,1.23-2.04Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M1103.35,211.52l-10.69,3.18c-3.28-1.05-5.7,1.89-.33,3.74,4.5-.99,9.98-3.16,13.31-4.69v-.29c-1.17.03-1.61-1.39-2.3-1.94Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M1035.47,122.01l1.35,2.54,6.46-3.92c-1.11-2.84-5.16-1-7.81,1.37Z"
                    />
                    <path
                        className="cls-2"
                        fill="#fff"
                        d="M1135.39,192.81c-7.46,6.24-17.09,14.83-26.86,19.13-1.19.32-3.98-1.03-2.08-1.95,8.82-3.98,17.93-10.27,20.34-12.63,1.44-1.19-3.26-1.63-6.33-1.54l-.46-.56c4.53-2.14,8.81-3.7,13.33-5.26-1.82-2.82-5.51-6.15-8.09-6.93-5.23,1.87-17.12,6.23-28.21,6.55-22.88.66-46.68-39.37-68.5-63.68,7.27-8.24,14.23-13.32,17.62-7.39,12.24-8.96,8.55-12.58,18.88-20.62l2.52,3.08c8.95-9.44,15.75-17.66,22.28-24.73,1.94-2.06,3.23-6.69,3.31-10.13.16-6.6-1.63-10.56-6.22-15.88-.55,3.17-2.67,3.23-4.52,1.56.73-.88,1.71-2.63,1.98-4.07l-4.43-2.17c-.09,3.73-3.86,3.84-7.14,2.79l1.76-4.64c-4.21-1.31-8.21-1.77-13.4-1.62-8.26.24-17.8,5.1-25.21,9.05-3.33-8.79,3.52-29.07-15.82-28.5-24.13,42.85-65.29,147.28-65.65,162.2-.13,5.45,7.72,2.64,1.94,7.4l3.26,11.66c1.74,5.97,7.77,10.38,12.25,10.25,5.34-6.46,11.82-11.24,13.39-17.59,1.31-5.49-2.99-12.82-1.49-16.59,3.57-11.29,13.79-34.53,20.17-45.03,13.94,8.49,44.25,62.38,55.48,75.82,5.96,7.28,15.98,12.15,24.85,15.62,17.69-.51,40.45-15.51,53.29-29.94-.44-1.13-1.35-2.54-2.27-3.66ZM1071.48,45.83l-1.23,2.04-7.52-1.22c1.68-1.2,6.17-1.9,8.74-.83ZM981.83,176.62l-1.1-3.41,7.21-15.41,1.12,2.26-7.23,16.56ZM1026.97,68.77c1.61-4.99,10.15-7.38,13.03-7.32,2.88.06,19.1,5.23,13.06,19.65-6.04,14.42-42.78,27.26-42.78,27.26l16.69-39.59ZM1105.66,213.75c-3.34,1.53-8.81,3.7-13.31,4.69-5.38-1.85-2.95-4.79.33-3.74l10.69-3.18c.69.55,1.13,1.97,2.31,1.94v.29Z"
                    />
                </g>
            </g>
        </svg>
    )
}
