import styled, { CSSObject } from '@emotion/styled'
import { BreakpointKeys } from '../../__styling/settings/breakpoints'
import { ThemeMarginKeys } from '../../__styling/settings/metrics'
import {
    breakpoint,
    getSmallestBreakpointValue,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

type ImageLinkVisibility = 'hidden' | 'visible'
export type ImageLinkVisibilityMode =
    | ImageLinkVisibility
    | Partial<Record<'initial' | BreakpointKeys, ImageLinkVisibility>>

interface StyledImageLinkContainerProps {
    visibilityMode?: ImageLinkVisibilityMode
    verticalSpacing?: ThemeMarginKeys
}

function getVisibilityStyles({
    visibilityMode,
}: StyledImageLinkContainerProps): CSSObject {
    if (!visibilityMode) {
        return {}
    }

    if (typeof visibilityMode === 'string') {
        return { display: visibilityMode === 'hidden' ? 'none' : 'block' }
    }

    return (
        visibilityMode &&
        Object.entries(visibilityMode)
            .slice(1)
            .reduce<CSSObject>(
                (styles, [breakpointValue, visibilityModeForBreakpoint]) => {
                    styles[breakpoint(breakpointValue as BreakpointKeys)] = {
                        display:
                            visibilityModeForBreakpoint === 'hidden'
                                ? 'none'
                                : 'block',
                    }

                    return styles
                },
                {},
            )
    )
}

export const StyledImageLinkContainer = styled(
    'div',
)<StyledImageLinkContainerProps>(
    {
        textAlign: 'center',

        '& img': {
            height: 'auto',
            maxWidth: '100%',
        },
    },
    getVisibilityStyles,
    ({ visibilityMode }) => {
        const smallestBreakpointValue =
            getSmallestBreakpointValue(visibilityMode)

        return {
            display: smallestBreakpointValue === 'hidden' ? 'none' : 'block',
        }
    },
    ({ theme, verticalSpacing }) => ({
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
    }),
)
