import { cx } from '@emotion/css'
import React from 'react'

export interface IconChevronDownTNProps {
    className?: string
    strokeColor?: string
}

export const IconChevronDownTN: React.FC<IconChevronDownTNProps> = ({
    className,
    strokeColor = '#6F6F6F',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={cx(className, 'icon-chevron-down-tn')}
    >
        <path
            d="M6 9L12 15L18 9"
            stroke="currentColor"
            fill="transparent"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconChevronDownTN.displayName = 'IconChevronDownTN'
