import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'

/** The parent container for the mini collection, used to build the 2x2 or 1x2 grid. */
export const StyledOlympicsMiniCollection = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'inline-flex',

        // Mobile styling
        flexDirection: 'column',
        width: '100%',

        padding: 0,
        marginTop: 0,
        marginBottom: 0,

        borderLeft: 'none',
        borderRight: 'none',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            flexDirection: 'row',
            width: '50%',

            padding: calcRem(0, 10, 0, 10),
            marginTop: calcRem(12),
            marginBottom: calcRem(12),

            borderLeft: `${calcRem(0.5)} solid ${colors.olympics.border}`,
            borderRight: `${calcRem(0.5)} solid ${colors.olympics.border}`,
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Tablet or bigger styling
        [breakpoint('sm')]: {
            padding: 0,
            marginTop: 0,
            marginBottom: 0,

            borderLeft: 'none',
            borderRight: 'none',
        },
    },
])

/** Styles out each column, on TheWest we have a 'first' & 'second' column, on PerthNow we only have a 'first' column. */
export const StyledOlympicsMiniColumn = styled('div')<{
    column: 'first' | 'second'
}>(({ theme, column }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'inline-flex',
        flexDirection: 'column',

        // Mobile styling
        width: '100%',

        paddingLeft: 0,
        paddingRight: 0,

        borderLeft: 'none',
        borderRight: 'none',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '50%',

            paddingLeft: column === 'second' ? calcRem(6) : undefined,
            paddingRight: column === 'first' ? calcRem(6) : undefined,

            borderLeft:
                column === 'second'
                    ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                    : undefined,
            borderRight:
                column === 'first'
                    ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                    : undefined,
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        gap: calcRem(8),

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            width: '100%',

            paddingLeft: 0,
            paddingRight: 0,

            borderLeft: 'none',
            borderRight: 'none',
        },
    },
])

/** Style out the mini cell. The use of this is mainly to confirm height of 50% of the column and store the article. */
export const StyledOlympicsMiniCell = styled('div')<{
    row: 'first' | 'second'
}>(({ theme, row }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'flex',
        position: 'relative',

        // Mobile styling
        height: 'fit-content',

        paddingBottom: 0,
        paddingTop: 0,

        borderBottom: 'none',
        borderTop: 'none',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: '50%',

            paddingBottom: row === 'first' ? calcRem(6) : undefined,
            paddingTop: row === 'second' ? calcRem(6) : undefined,

            borderBottom:
                row === 'first'
                    ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                    : undefined,
            borderTop:
                row === 'second'
                    ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                    : undefined,
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Tablet or bigger styling
        [breakpoint('sm')]: {
            paddingBottom: 0,
            paddingTop: 0,

            borderBottom: 'none',
            borderTop: 'none',
        },
    },
])
