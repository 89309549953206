import { useTheme } from '@emotion/react'
import {
    isPublicationCardItemWithVideo,
    Product,
    PublicationCardItem,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import { CardHeadline } from '../../cards/CardText/CardHeadline'
import { CardTeaser, TeaserMode } from '../../cards/CardText/CardTeaser'
import {
    StyledText,
    StyledTimestamp,
} from '../../cards/CardText/CardText.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { getEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { getLatestTime } from '../../__helpers/get-latest-time'
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { KickerMode } from '../Kicker/Kicker'
import { CardHeadlineSkeleton } from './CardHeadlineSkeleton'

export interface CardTextProps extends CommonCardProps {
    hasBackground?: boolean
    kickerMode?: KickerMode
    hasPadding?: boolean
    timestamp?: TimestampType
    verticalSpacing?: keyof ThemeMargins
    isLarge?: boolean
    fontScale: FontScales
    teaserMode: TeaserMode
    innerRef?: (ref: any) => void
    insideSponsoredCollection?: boolean
    item: MaybeLoaded<PublicationCardItem>
    kickerOverrideText?: string | false
    isLiveSportsTopic?: boolean
    hideEditorialType?: boolean
    headlineAsDiv?: boolean
}

export function CardText(props: CardTextProps) {
    const {
        fontScale,
        verticalSpacing,
        hasBackground,
        hasPadding,
        className,
        timestamp,
        isLarge,
        item,
        innerRef,
        teaserMode,
        insideSponsoredCollection,
        kickerMode,
        isLiveSportsTopic,
        kickerOverrideText,
        hideEditorialType,
        headlineAsDiv,
    } = props

    const theme = useTheme()

    const storyClassification = true
    const isFunded =
        item.loaded && !!getFundingType(item.result, storyClassification)
    const editorialType =
        item.loaded && !hideEditorialType
            ? getEditorialType(item.result, storyClassification)
            : undefined

    const allowCommentingOnTheWest =
        useFeature('comments') && theme.kind === Product.TheWest

    return (
        <StyledText
            hasPadding={hasPadding}
            cardLoading={false}
            className={className}
            verticalSpacing={verticalSpacing}
            ref={innerRef}
            isLarge={isLarge}
        >
            <React.Fragment>
                {item.loaded ? (
                    <CardHeadline
                        kickerText={kickerOverrideText || item.result.kicker}
                        kickerMode={kickerMode}
                        headlineText={item.result.shortHeadline}
                        hasBackground={hasBackground}
                        fontScale={fontScale}
                        showVideoIcon={isPublicationCardItemWithVideo(
                            item.result,
                        )}
                        showCommentsIcon={
                            allowCommentingOnTheWest &&
                            item.result.allowCommenting
                        }
                        isSponsored={isFunded}
                        insideSponsoredCollection={insideSponsoredCollection}
                        publicationKind={item.result.publicationKind}
                        isLarge={isLarge}
                        requiredAccess={item.result.requiredAccess}
                        editorialType={editorialType}
                        isLiveSportsTopic={isLiveSportsTopic}
                        headlineAsDiv={headlineAsDiv}
                    />
                ) : (
                    <CardHeadlineSkeleton
                        hasBackground={hasBackground}
                        fontScale={fontScale}
                        isLarge={isLarge}
                    />
                )}

                {teaserMode !== 'hidden' && (
                    <CardTeaser
                        teaserOrSkeletonLines={
                            item.loaded ? item.result.teaser : 1
                        }
                        teaserMode={teaserMode}
                        hasBackground={hasBackground}
                    />
                )}

                {timestamp !== undefined &&
                    item.loaded &&
                    item.result.publicationDate && (
                        <StyledTimestamp
                            isLarge={isLarge}
                            iconVisible
                            hasBackground={hasBackground}
                            timestampFormat="relative"
                            timestamp={getLatestTime(
                                theme,
                                item.result.publicationDate,
                                item.result.lastUpdated,
                            )}
                        />
                    )}
            </React.Fragment>
        </StyledText>
    )
}
CardText.displayName = 'CardText'
