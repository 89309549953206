import styled from '@emotion/styled'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '../../../../__styling'

export const Teaser = styled.p(({ theme }) => {
    return [
        {
            width: '100%',
            color: theme.colors.text.tertiary,
            wordBreak: 'break-word',
        },
        textMixin<TheNightlyThemeConfig>(theme, 'body-main'),
    ]
})
