import { SectionMetaInfoDTO } from '@west-australian-newspapers/publication-types'
import { isServerEnvironment, isTestEnvironment } from '../environment'
import { RouteInformation, RouteServices } from '../routing/page-definition'
import { parseURL, stripTrailingSlash } from './helpers'
import { RedirectTo } from './route-resolver'

export interface RouteLocation {
    protocol: string | undefined
    hostname: string | undefined
    pathname: string
    search: string
    hash: string
    httpStatusCode: RedirectTo['httpStatusCode'] | undefined
}

export function getInitialRouteLocation(
    routeServices: RouteServices,
    routeInfo: RouteInformation<string>,
): RouteLocation {
    if (routeInfo.kind === 'redirect') {
        const redirectUrl = parseURL(
            routeInfo.redirectTo.targetUrl,
            `${routeServices.protocol || 'https:'}//${routeServices.hostname}`,
        )
        return {
            protocol: routeServices.protocol,
            hostname: redirectUrl.hostname,
            pathname: redirectUrl.pathname,
            search: redirectUrl.search,
            hash: redirectUrl.hash,
            httpStatusCode: routeInfo.redirectTo.httpStatusCode,
        }
    }
    return {
        protocol: routeServices.protocol,
        hostname: routeServices.hostname,
        pathname: routeServices.location.pathname,
        search: decodeURIComponent(routeServices.location.search),
        hash: decodeURIComponent(routeServices.location.hash),
        httpStatusCode: undefined,
    }
}

export function applyRedirects(
    routeServices: RouteServices,
    routeInfo: RouteInformation<string>,
    isErrorRouteInfo: boolean,
) {
    const routeLocation = getInitialRouteLocation(routeServices, routeInfo)
    const routeLocationWithAppliedRedirects = [
        redirectHostname,
        redirectForSectionDomains,
        redirectForTrailingSlash,
        redirectForInvalidProtocol,
    ].reduce(
        (currentRouteLocation, redirectFn) =>
            redirectFn(currentRouteLocation, routeServices, routeInfo),
        routeLocation,
    )

    if (
        routeLocationsAreDifferent(
            routeLocation,
            routeLocationWithAppliedRedirects,
        )
    ) {
        const { hostname, pathname, search, httpStatusCode } =
            routeLocationWithAppliedRedirects
        const routeInfoRedirect: RouteInformation<string> = {
            kind: 'redirect',
            redirectTo: {
                // At this point we always assume a redirect is https
                targetUrl: `https://${hostname}${pathname}${search}`,
                httpStatusCode: isErrorRouteInfo
                    ? 302
                    : httpStatusCode
                    ? httpStatusCode
                    : 301,
            },
        }
        return routeInfoRedirect
    }

    return routeInfo
}

// Lets allow redirects between localhost hosts with different ports, otherwise
// strip the ports
function stripPort(host: string, includeLocalhost = false) {
    const hostWithoutPort = host.split(':')[0]

    if (includeLocalhost) {
        return hostWithoutPort
    }

    return host.indexOf('localhost') === -1 ? hostWithoutPort : host
}

// Fix the hostname if incorrect
// E.g. for amp urls, we need to change from amp.thewest ->  thewest.com.au/article.amp
export function redirectHostname(
    routeLocation: RouteLocation,
    routeServices: RouteServices,
): RouteLocation {
    if (!routeLocation.hostname) return routeLocation

    const appState = routeServices.store.getState()
    let expectedPublicHostname = routeServices.config.publicHostname

    // If the section domains feature is enabled and the domain matches a resolved section domain
    // then set the expectedPublichostname to the hostname
    // Just need to strip amp. subdomain from the hostname if it exists
    const hostname = routeLocation.hostname.replace('amp.', '')
    if (appState.meta.hostnameToSectionLookup[hostname]) {
        expectedPublicHostname = hostname
        // Only trace if its actually being overwriten
        if (hostname !== expectedPublicHostname) {
            routeServices.log.debug(
                {
                    originalHostname: hostname,
                    publicHostname: expectedPublicHostname,
                },
                'Overwriting public hostname due to section domain match',
            )
        }
    }

    if (
        routeLocation.hostname &&
        routeServices.config.hostnameRedirectsEnabled &&
        routeLocation.search.indexOf('disable_host_redirect') === -1 &&
        stripPort(routeLocation.hostname) !== stripPort(expectedPublicHostname)
    ) {
        const urlSuffix =
            routeLocation.hostname.indexOf('amp.') === 0 ? '.amp' : ''
        // Do not append .amp if it was a resolution failure
        const targetPath =
            routeServices.resolution.type === 'error'
                ? routeLocation.pathname
                : `${routeLocation.pathname}${urlSuffix}`

        routeServices.log.debug(
            {
                hostname: routeLocation.hostname,
                publicHostname: expectedPublicHostname,
                targetPath,
                hostnameRedirectsEnabled:
                    routeServices.config.hostnameRedirectsEnabled,
                protocol: routeLocation.protocol ?? 'not set',
                expected: routeServices.config.publicHostname,
                pubUrl: routeServices.config.publicUrl,
            },
            'Updating redirect target due to hostname mismatch',
        )

        return {
            ...routeLocation,
            hostname: expectedPublicHostname,
            pathname: targetPath,
            httpStatusCode: 301,
        }
    }

    return routeLocation
}

export function redirectForSectionDomains(
    routeLocation: RouteLocation,
    routeServices: RouteServices,
    routeInfo: RouteInformation<string>,
): RouteLocation {
    const appState = routeServices.store.getState()

    if (
        !routeServices.config.sectionDomainsRedirect ||
        routeInfo.kind !== 'page'
    ) {
        return routeLocation
    }

    const metaState = appState.meta
    const sectionOverrides = metaState.sectionMeta.sectionOverrides as Record<
        string,
        Partial<SectionMetaInfoDTO>
    >

    const sectionMeta = sectionOverrides[routeInfo.section]

    if (sectionMeta) {
        routeServices.log.debug(
            sectionMeta,
            `Section=${routeInfo.section} Meta`,
        )
    }

    // If the section name is a domain, remove the section name from the path
    // e.g thewest.com.au/albany-advertiser/hello -> albany-advertiser.com.au/hello
    if (sectionMeta && sectionMeta.Hostname && routeLocation.hostname) {
        const sectionHostname = sectionMeta.Hostname
        const expectedPublicHostname = stripPort(
            routeServices.config.publicHostname,
            true,
        )
        const doesSectionMatchHostname =
            routeLocation.hostname.indexOf(sectionHostname) !== -1

        if (!doesSectionMatchHostname && sectionMeta.DedicatedDomain) {
            /**
                Some section names also match a section domain. In cases where
                the section metadata includes the hasMatchingFirstLevelTopic,
                we dont want to remove the section name from the path.
                e.g thewest.com.au/countryman/livestock -> countryman.com.au/countryman/livestock
            */
            if (sectionMeta.hasMatchingFirstLevelTopic) {
                /**
                    Need to check if the pathname is the root of the section. For sections where
                    they have matching first level topics the resolved section needs to go to
                    the root e.g. "/countryman" should go to "/"
                    We do not want redirection on app rendition - DTEC-391
                */
                if (routeServices.renderTarget === 'app') {
                    return routeLocation
                } else {
                    const isPathIsRootOfSection = pathIsRootOfSection(
                        routeLocation.pathname,
                        routeInfo.section,
                    )
                    const pathname = isPathIsRootOfSection
                        ? '/'
                        : routeLocation.pathname

                    routeServices.log.info(
                        { hostname: sectionHostname },
                        `Redirect to section dedicated domain`,
                    )
                    return {
                        ...routeLocation,
                        pathname,
                        httpStatusCode: 301,
                        hostname: sectionHostname,
                    }
                }
            }

            const pathname = routeLocation.pathname.replace(
                `/${routeInfo.section}`,
                '',
            )
            routeServices.log.info(
                { hostname: sectionHostname, pathname },
                `Redirect to section dedicated domain`,
            )
            return {
                ...routeLocation,
                httpStatusCode: 301,
                hostname: sectionHostname,
                pathname,
            }
        } else if (doesSectionMatchHostname && !sectionMeta.DedicatedDomain) {
            return {
                ...routeLocation,
                hostname: expectedPublicHostname,
                pathname: `/${routeInfo.section}${routeLocation.pathname}`,
                httpStatusCode: 301,
            }
        }
    }
    return routeLocation
}

export function redirectForInvalidProtocol(
    routeLocation: RouteLocation,
    routeServices: RouteServices,
): RouteLocation {
    // We have to check for test environment also so that this reduction can be run by tests
    if (!isServerEnvironment() && !isTestEnvironment()) return routeLocation

    const invalidXForwardedProtoHeader =
        // in development this header does not exist
        routeLocation.protocol === undefined
            ? false
            : ['http:', 'https:'].indexOf(routeLocation.protocol) === -1

    const incorrectXForwardedProtoHeader =
        // in development this header does not exist
        routeLocation.protocol === undefined
            ? false
            : routeLocation.protocol !== 'https:'

    if (invalidXForwardedProtoHeader) {
        routeServices.log.error(
            {
                err: new Error('Invalid x-forwarded-proto header received'),
                xForwardedProtoHeader: routeLocation.protocol,
            },
            'Invalid x-forwarded-proto header received',
        )
    }

    if (!invalidXForwardedProtoHeader && !incorrectXForwardedProtoHeader)
        return routeLocation

    routeServices.log.debug(
        {
            protocol: routeLocation.protocol,
            hostname: routeLocation.hostname,
            publicHostname: routeServices.config.publicHostname,
        },
        'Redirect due to to protocol/host',
    )

    return {
        ...routeLocation,
        httpStatusCode: 301,
        protocol: 'https:',
    }
}

export function redirectForTrailingSlash(
    routeLocation: RouteLocation,
    routeServices: RouteServices,
): RouteLocation {
    const isMatching =
        routeLocation.pathname.length > 1 &&
        routeLocation.pathname.charAt(routeLocation.pathname.length - 1) === '/'

    if (!isMatching) return routeLocation

    routeServices.log.debug(
        {
            pathname: routeLocation.pathname,
            hostname: routeLocation.hostname,
            publicHostname: routeServices.config.publicHostname,
        },
        'Updating redirect target due to trailing /',
    )

    return {
        ...routeLocation,
        httpStatusCode: 301,
        pathname: stripTrailingSlash(routeLocation.pathname),
    }
}

export function pathIsRootOfSection(pathname: string, section: string) {
    return (
        pathname.split('/').filter((seg) => !!seg).length === 1 &&
        pathname.indexOf(section) >= 0
    )
}

export function routeLocationsAreDifferent(
    origional: RouteLocation,
    toCompare: RouteLocation,
) {
    return Object.keys(origional).some(
        (key) =>
            origional[key as keyof RouteLocation] !==
            toCompare[key as keyof RouteLocation],
    )
}
