import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { Sharing } from '../../buttons/Sharing/Sharing'
import { CardInteractionHoverStylesPerthNow } from '../../cards/CardText/CardTextInteractions.styled'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'

const styledLandscapeWideClassName = 'LandscapeWideCard'
export const styledHeadlineClassName = 'Card-Headline'

export const StyledLandscapeWide = withClass(styledLandscapeWideClassName)(
    styled('div')({
        ...breakWords,
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        overflow: 'hidden',
        padding: calcRem(
            metrics.perthnow.margins.md,
            metrics.perthnow.margins.sm,
        ),
        ...CardInteractionHoverStylesPerthNow,
        background: colors.white,
    }),
)

export const LandscapeStyledSharing = styled(Sharing)({
    display: 'none',

    [breakpoint('xs')]: {
        display: 'block',
        flex: `0 0 ${calcRem(110)}`,
        alignSelf: 'center',
        paddingLeft: calcRem(metrics.perthnow.margins.sm),
        borderLeft: `1px solid ${colors.perthnow.greyMarble}`,
        marginLeft: calcRem(metrics.perthnow.margins.sm),
        marginRight: calcRem(metrics.perthnow.margins.sm),
    },
})
LandscapeStyledSharing.displayName = 'StyledSharing'
