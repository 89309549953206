import React from 'react'

export const ArrowIcon = () => {
    return (
        <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.24074 0.730305L12.5 3.98943M12.5 3.98943L9.24074 7.24882M12.5 3.98943H1.5"
                stroke="white"
                strokeWidth="1.03629"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
