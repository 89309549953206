import * as React from 'react'
import { useEffect, useState } from 'react'
import url from 'url'
import { loadCricketWidgetScripts } from '../../common'
import {
    OptaIsAvailable,
    OptaScript,
    StyledCricketWidgetContainer,
} from '../HorizontalCricketWidget/HorizontalCricketWidget.web'

declare const Opta: OptaScript

interface LiveScoreParams {
    competition: string
    season: string
    match: string
}

export interface LiveScoresProps {
    statsPerformToken: string | undefined
}

export const LiveScores: React.FC<LiveScoresProps> = ({
    statsPerformToken,
}) => {
    //This triggers a re-render when the script loads
    const [scriptLoaded, updateScriptLoaded] = useState(false)
    // We only want to render this component once.
    useEffect(() => {
        const scriptElements = loadCricketWidgetScripts(
            statsPerformToken,
            updateScriptLoaded,
        )
        // This removes the scripts from the DOM once the cricket widget unmounts
        return function scriptRemoval() {
            if (scriptElements) {
                document.head.removeChild(scriptElements.cssScript)
                document.body.removeChild(scriptElements.jsScript)
                document.body.removeChild(scriptElements.settingsScript)
            }
        }
    }, [statsPerformToken])

    React.useEffect(() => {
        if (OptaIsAvailable() && scriptLoaded) {
            console.log('Opta is available')
            Opta.start()
        }
    })

    // As of implementation, we don't have access to the query string in SSR, so we render a fragment
    // until it becomes available after re-hydrate.
    if (typeof window === 'undefined' || window.location === undefined) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <React.Fragment />
    }

    const parsedUrl = url.parse(window.location.href, true)

    const params: LiveScoreParams = {
        match: parsedUrl.query.match as string,
        season: (parsedUrl.query.season || '0') as string,
        competition: parsedUrl.query.competition as string,
    }

    return (
        <StyledCricketWidgetContainer>
            <opta-widget
                widget="score_card"
                competition={`${params.competition}`}
                season={`${params.season}`}
                match={`${params.match}`}
                template="normal"
                live="true"
                show_match_header="true"
                show_crests="false"
                show_competition_name="true"
                show_match_description="true"
                show_date="true"
                date_format="LLLL"
                show_venue="true"
                show_officials="on_field"
                show_toss="true"
                show_innings_breakdown="true"
                show_current_batting="true"
                show_best_batting="1"
                show_best_bowling="1"
                show_state_of_play="true"
                navigation="tabs"
                default_nav="1"
                show_batting="true"
                show_mins_batted="true"
                show_fours="false"
                show_sixes="false"
                show_strike_rate="false"
                show_bowling="true"
                show_economy="false"
                show_dot_balls="false"
                show_bowling_extras="false"
                show_fow="true"
                show_partnerships="true"
                show_unfinished_partnerships="true"
                team_naming="full"
                player_naming="initial"
                show_logo="false"
                show_title="true"
                breakpoints="400"
                sport="cricket"
            />
        </StyledCricketWidgetContainer>
    )
}
export default LiveScores
