import React from 'react'
import {
    TimestampFormat,
    TimestampLabel,
} from '../../../typography/Timestamp/Timestamp'
import { StyledTimestampUpdated } from '../Byline.styled'

interface BylineTimestampUpdatedProps {
    timestamp: string | undefined
    timestampLabel?: TimestampLabel | undefined
    timestampUpdatedFormat: TimestampFormat
}

export const BylineTimestampUpdated: React.FC<BylineTimestampUpdatedProps> = ({
    timestamp,
    timestampLabel = 'Updated:',
    timestampUpdatedFormat,
}) => {
    if (!timestamp) {
        return null
    }

    return (
        <StyledTimestampUpdated
            timestamp={timestamp}
            timestampLabel={timestampLabel}
            timestampFormat={timestampUpdatedFormat}
        />
    )
}
