import styled from '@emotion/styled'
import { calcRem } from '../../../../../__styling'

export const Comments = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'inherit',
}))

export const CommentsSpan = styled('span')(({ theme }) => [
    {
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
    },
])

interface IconWrapperProps {
    size?: number
}

export const StyledIconWrapper = styled('div')<IconWrapperProps>(
    ({ size }) => ({
        height: (size && calcRem(size)) || calcRem(16),
        width: (size && calcRem(size)) || calcRem(16),
        padding: calcRem(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: calcRem(4),
    }),
)
