import { ConfigurationContext } from '@news-mono/web-common'
import { Section } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useSection } from './use-section-meta'

export const useNielsenApid = () => {
    const section = useSection() as Section
    const config = React.useContext(ConfigurationContext)

    return config.nielsenApids[section] || config.nielsenApids['default']
}
