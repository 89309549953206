import {
    AppState,
    checkInScheduleWindow,
    DataLayerEventName,
    HoursWindow,
    isWithinScheduledWindow,
    RadioEvent,
    RenderTargetContext,
    TheWestLiveBroadcast,
    TheWestLivePlayerStates,
    TheWestLiveState,
    toRadioEvent,
    useFeature,
    useInterval,
} from '@news-mono/web-common'
import React, { createContext, FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { TheWestLiveAudio } from './TheWestLiveAudio/TheWestLiveAudio'

interface TheWestLiveProviderProps {
    stationId?: string
    onEvent: (event: RadioEvent) => void
}

export interface TheWestLiveContextProps {
    broadcastSchedules: HoursWindow[]
    playerState: TheWestLivePlayerState
    setPlayerState: (state: TheWestLivePlayerState) => void
    playerActions: {
        onControlClick: () => void
        onVolumeChange: (volume: number) => void
    }
    onEvent: (event: RadioEvent) => void
}

export interface TheWestLivePlayerState {
    state: TheWestLivePlayerStates
    volume: number
}

export const TheWestLiveContext = createContext<TheWestLiveContextProps>({
    broadcastSchedules: [{ startTime: [0, 0], endTime: [0, 0] }],
    playerState: {
        state: 'stopped',
        volume: 0.5,
    },
    setPlayerState: () => null,
    playerActions: {
        onControlClick: () => null,
        onVolumeChange: () => null,
    },
    onEvent: () => null,
})

export const TheWestLiveProvider: FC<TheWestLiveProviderProps> = ({
    stationId,
    children,
    onEvent,
}) => {
    const dispatch = useDispatch()
    const { search } = useLocation()
    const { renderTarget } = React.useContext(RenderTargetContext)
    const { showPlayer, streamingHostname } = useSelector<
        AppState,
        TheWestLiveState
    >((state) => state.theWestLive)
    const hasWestLiveQuery = search.indexOf('the_west_live') !== -1

    const broadcastWindow: HoursWindow[] = [
        { startTime: [22, 59], endTime: [23, 45] }, // 07:00 WST - 07:45 WST window
    ]

    function getPlayerState() {
        return playerState.state
    }

    const [playerState, setPlayerState] = useState<TheWestLivePlayerState>({
        state: 'stopped',
        volume: 0.5,
    })

    // Initial check
    React.useEffect(() => {
        dispatch(
            checkInScheduleWindow(broadcastWindow, undefined, onEvent, {
                force: hasWestLiveQuery,
            }),
        )
        // Reason: Props are required, we want onComponentMount functionality
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Polling
    useInterval(() => {
        dispatch(
            checkInScheduleWindow(broadcastWindow, undefined, onEvent, {
                force: hasWestLiveQuery,
                // Counteracting the closure
                playerState: getPlayerState(),
            }),
        )
    }, 30_000)

    const onControlClickHandler = () => {
        setPlayerState({
            ...playerState,
            state: playerState.state === 'stopped' ? 'loading' : 'stopped',
        })

        onEvent(
            toRadioEvent(
                playerState.state === 'stopped'
                    ? DataLayerEventName.radioPlay
                    : DataLayerEventName.radioStop,
            ),
        )
    }

    const volumeChangeHandler = (volume: number) => {
        setPlayerState({ ...playerState, volume })
    }

    function toggleStatusHandler(incomingState: TheWestLivePlayerStates) {
        if (playerState.state !== incomingState) {
            setPlayerState({ ...playerState, state: incomingState })
        }
    }

    const context: TheWestLiveContextProps = {
        broadcastSchedules: broadcastWindow,
        playerState,
        setPlayerState,
        playerActions: {
            onControlClick: onControlClickHandler,
            onVolumeChange: volumeChangeHandler,
        },
        onEvent,
    }

    return (
        <TheWestLiveContext.Provider value={context}>
            {(renderTarget === 'web' || 'app') && (
                <TheWestLiveAudio
                    streamingHostname={streamingHostname}
                    stationId={stationId}
                    volume={playerState.volume}
                    playerState={playerState.state}
                    showPlayer={showPlayer}
                    toggleState={toggleStatusHandler}
                />
            )}
            {children}
        </TheWestLiveContext.Provider>
    )
}

export const queryOverrideOffAir =
    typeof window !== 'undefined' &&
    window.location.search.indexOf('the_west_live=offair') !== -1

export function useInProgrammedScheduleWindow(): boolean {
    const { broadcastSchedules } = useContext(TheWestLiveContext)
    const forceLive = useFeature('force-west-live-activation')
    return forceLive || isWithinScheduledWindow(broadcastSchedules)
}

interface onAirState {
    onAir: boolean
    broadcast: TheWestLiveBroadcast
}

export function useOnAirState(): onAirState {
    const { isLive, broadcast } = useSelector<AppState, TheWestLiveState>(
        (state) => state.theWestLive,
    )

    return {
        onAir: isLive && !queryOverrideOffAir,
        broadcast,
    }
}
