import styled from '@emotion/styled'
import { StyledButton } from '../../buttons/Button/Button.styled'
import { Sharing } from '../../buttons/Sharing/Sharing'
import { IconCamera } from '../../icons/IconCamera/IconCamera'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords, textCrop, transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

const westMargins = metrics.thewest.margins
const perthnowMargins = metrics.perthnow.margins

export const StyledGallery = styled('div')(({ theme }) => ({
    paddingBottom: themedValue(theme, {
        thewest: calcRem(westMargins.xxl),
        fallback: undefined,
    }),
}))

export const StyledStaticHr = styled('hr')({
    width: '100%',
    height: calcRem(2),
    border: 'none',
    background: tokens.sevennews.colors.palette.alto,
    margin: 0,
    padding: 0,
})

export const StyledHeroWrap = styled('div')(({ theme }) => ({
    position: 'relative',
    margin: themedValue(theme, {
        thewest: calcRem(0, -westMargins.mobSm, westMargins.md),
        fallback: undefined,
    }),

    [breakpoint('sm')]: {
        margin: themedValue(theme, {
            thewest: calcRem(0, -westMargins.md, westMargins.md),
            fallback: undefined,
        }),
    },
}))
StyledHeroWrap.displayName = 'HeroWrapper'

interface StyledTitleBarProps {
    entitled: boolean
    overlayImage: boolean
}

export const StyledTitleBar = styled('div')<StyledTitleBarProps>(
    ({ theme, overlayImage, entitled }) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: entitled ? 'space-between' : 'flex-end',
        width: '100%',
        flexWrap: 'wrap',
        background: themedValue(theme, {
            perthnow: colors.perthnow.greyBasalt,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            thewest: calcRem(westMargins.md, 13), // smaller margin so content fits on smaller mobiles
            perthnow: calcRem(15, perthnowMargins.md),
            fallback: undefined,
        }),

        [breakpoint('sm')]: [
            {
                background: themedValue(theme, {
                    perthnow: colors.perthnow.greyBasalt90,
                    fallback: undefined,
                }),
                padding: themedValue(theme, {
                    thewest: calcRem(
                        westMargins.md,
                        westMargins.lg + westMargins.md,
                    ),
                    perthnow: calcRem(perthnowMargins.md, perthnowMargins.lg),
                    fallback: undefined,
                }),
            },
            // If there is an image have the title bar sit over the top of it. If not, it should stay within the flow of the page
            overlayImage && {
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
            },
        ],
    }),
)
StyledTitleBar.displayName = 'GalleryTitleBar'

// This Icon is used on PerthNow only
export const StyledIcon = styled(IconCamera)(({ theme }) => ({
    fontSize: calcRem(22),
    width: '1em',
    height: '1em',
    fill: colors.white,
    marginRight: calcRem(theme.margins.sm),
    transform: 'translateY(2px)',

    [breakpoint('sm')]: {
        fontSize: calcRem(30),
        marginRight: calcRem(theme.margins.md),
        transform: 'translateY(4px)',
    },
}))
StyledIcon.displayName = 'CameraIcon'

export const StyledTitle = styled('h1')(({ theme }) => ({
    ...breakWords,
    fontWeight: 700,
    display: 'flex',
    alignSelf: 'flex-start',
    color: themedValue(theme, {
        thewest: colors.thewest.greyGorilla,
        perthnow: tokens.perthnow.colors.palette.blackShade[5],
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        thewest: fonts.thewest.sansSerifCond,
        fallback: theme.fonts.heading,
    }),
    lineHeight: themedValue(theme, {
        thewest: 1.083,
        perthnow: 1.15,
        fallback: undefined,
    }),
    margin: themedValue(theme, {
        thewest: calcRem(0, 0, westMargins.md, 0),
        perthnow: calcRem(0, 0, perthnowMargins.sm),
        fallback: calcRem(0, 0, theme.margins.md),
    }),
    paddingRight: themedValue(theme, {
        perthnow: metrics.perthnow.margins.md,
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        thewest: calcRem(36),
        perthnow: calcRem(24),
        fallback: calcRem(36),
    }),

    [breakpoint('sm')]: {
        flex: '1 1 auto',
        margin: themedValue(theme, {
            perthnow: 0,
            fallback: undefined,
        }),
        fontSize: themedValue(theme, {
            thewest: calcRem(42),
            perthnow: calcRem(36),
            fallback: calcRem(42),
        }),
    },
}))
StyledTitle.displayName = 'GalleryTitle'

export const GalleryStyledSharing = styled(Sharing)(({ theme }) => ({
    marginLeft: themedValue(theme, {
        perthnow: calcRem(perthnowMargins.sm + perthnowMargins.md),
        fallback: undefined,
    }),
    [breakpoint('sm')]: {
        marginLeft: themedValue(theme, {
            perthnow: 0,
            fallback: undefined,
        }),
        flex: themedValue(theme, {
            perthnow: `0 0 ${calcRem(164)}`,
            fallback: '0 0 auto',
        }),
    },
}))
GalleryStyledSharing.displayName = 'SharingIcons'

// The Hero Caption Container is only needed on PerthNow for layout purposes. The West does not use it.
export const StyledHeroCaptionContainer = styled('div')({
    ...breakWords,
    fontSize: calcRem(14),
    fontFamily: fonts.perthnow.sansSerif,

    [breakpoint('xs')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: calcRem(metrics.perthnow.margins.sm),
    },
})
StyledHeroCaptionContainer.displayName = 'HeroCaptionContainer'

export const StyledHeroCaption = styled('p')(({ theme }) => [
    {
        display: 'inline-block',
        fontFamily: themedValue(theme, {
            thewest: fonts.thewest.serif,
            perthnow: fonts.perthnow.sansSerif,
            fallback: theme.fonts.body,
        }),
        fontSize: themedValue(theme, {
            thewest: calcRem(17),
            perthnow: calcRem(14),
            fallback: undefined,
        }),
        margin: themedValue(theme, {
            thewest: `0 auto ${calcRem(westMargins.md)}`,
            perthnow: calcRem(0, perthnowMargins.md, perthnowMargins.xs, 0),
            fallback: undefined,
        }),
        lineHeight: themedValue(theme, {
            perthnow: 1.5,
            fallback: undefined,
        }),
    },
    theme.kind === 'thewest' && {
        ...textCrop({
            fontSize: 42,
            desiredLineHeight: 1.765,
            cropLineHeight: 1.2,
        }),
    },
])
StyledHeroCaption.displayName = 'HeroCaption'

export const StyledGalleryContentWrapper = styled('div')(({ theme }) => ({
    padding: themedValue(theme, {
        thewest: calcRem(0, 0, westMargins.md, 0),
        fallback: calcRem(theme.margins.md),
    }),

    [breakpoint('xs')]: {
        padding: themedValue(theme, {
            thewest: calcRem(0, westMargins.lg, westMargins.md),
            fallback: calcRem(theme.margins.md, theme.margins.lg),
        }),
    },
}))
StyledGalleryContentWrapper.displayName = 'Wrapper'

export const StyledShowMoreButton = styled(StyledButton)(({ theme }) => ({
    width: calcRem(120),
    padding: calcRem(theme.margins.sm),

    [breakpoint('xs')]: {
        margin: '0 auto',
    },
}))
StyledShowMoreButton.displayName = 'MoreButton'

export const StyledThumbnailPaginationContainer = styled('div')(
    ({ theme }) => ({}),
)

export const StyledThumbnailPaginationButtonsContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            width: calcRem(120),
            margin: `0 ${calcRem(10)} 0`,
        },
        '& button:nth-of-type(odd)': {
            color: colors.sevennews.red,
            backgroundColor: colors.white,
            border: `1px solid ${colors.sevennews.red}`,
        },
        '& button:nth-of-type(odd):disabled': {
            color: colors.sevennews.bauhausBlack50,
            border: `1px solid ${colors.sevennews.bauhausBlack50}`,
        },
        '& button:nth-of-type(even):disabled': {
            backgroundColor: colors.sevennews.bauhausBlack50,
        },
    }),
)

export const StyledThumbnailContainer = styled('div')(({ theme }) => ({
    padding: themedValue(theme, {
        perthnow: calcRem(0, 0, perthnowMargins.md, 0),
        fallback: calcRem(theme.margins.md, 0),
    }),

    [breakpoint('xs')]: {
        padding: themedValue(theme, {
            thewest: calcRem(westMargins.md, westMargins.xl),
            fallback: undefined,
        }),
    },
}))
StyledThumbnailContainer.displayName = 'ThumbnailContainer'

export const StyledButtonCenterContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}))

export const StyledThumbnailSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: themedValue(theme, {
        perthnow: calcRem(0, perthnowMargins.sm, perthnowMargins.sm),
        fallback: undefined,
    }),

    [breakpoint('xs')]: {
        padding: themedValue(theme, {
            perthnow: 0,
            fallback: undefined,
        }),
        margin: themedValue(theme, {
            perthnow: calcRem(0, -perthnowMargins.sm),
            fallback: undefined,
        }),
    },
}))
StyledThumbnailSection.displayName = 'Thumbnails'

interface StyledThumbnailLinkProps {
    isSevenNewsV2?: boolean
}

export const StyledThumbnailLink = styled('button')<StyledThumbnailLinkProps>(
    ({ theme, isSevenNewsV2 }) => ({
        display: 'block',
        padding: calcRem(theme.margins.sm),
        width: 'calc(100% / 2)',
        cursor: 'pointer',
        position: 'relative',
        background: 'none',
        border: 0,

        '&::after': {
            position: 'absolute',
            content: `''`,
            top: theme.margins.sm,
            right: theme.margins.sm,
            bottom: theme.margins.sm,
            left: theme.margins.sm,
            border: '4px solid transparent',
            ...transition({ properties: ['border-color'] }),
        },

        '&:hover': {
            '&::after': {
                borderColor: colors.white50,
            },
        },

        '&:focus': {
            outline: 'none',

            '&::after': {
                borderColor: themedValue(theme, {
                    perthnow: colors.perthnow.greyCoal,
                    thewest: colors.thewest.greyGorilla,
                    fallback: colors.black,
                }),
            },
        },

        [breakpoint('xs')]: {
            width: 'calc(100% / 4)',
        },

        [breakpoint('sm')]: {
            width: themedValue(theme, {
                sevennews: 'calc(100% / 4)',
                fallback: 'calc(100% / 6)',
            }),
        },

        [breakpoint('md')]: {
            width: themedValue(theme, {
                sevennews: `calc(100% / ${isSevenNewsV2 ? 5 : 4})`,
                fallback: 'calc(100% / 8)',
            }),
        },
    }),
)
StyledThumbnailLink.displayName = 'ThumbnailLinks'
