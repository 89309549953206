import React from 'react'
import { PaginationItem } from './PaginationItem'
import { StyledPageNumberWrapper } from '../Pagination.styled'

interface NumberedPageProps {
    pages: number[]
    onPageChange: (e: number) => void
    path: string
    currentPage: number
    params?: string
    onEvent: (event: any) => void
}
export const NumberedPages = ({
    pages,
    onPageChange,
    path,
    currentPage,
    params,
    onEvent,
}: NumberedPageProps) => (
    <StyledPageNumberWrapper>
        {pages.map((page) => (
            <PaginationItem
                isDisabled={false}
                layout={{
                    showText: true,
                    display: {
                        default: {
                            display: 'flex',
                        },
                    },
                }}
                onClick={onPageChange}
                routePath={path}
                params={params}
                page={{ currentPage, nextPage: page }}
                key={page}
                flexGrow
                onEvent={onEvent}
            />
        ))}
    </StyledPageNumberWrapper>
)
