import { CSSObject, keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { IconLightning } from '../../icons/IconLightning/IconLightning'
import { PerthNowIcon } from '../../logos/PerthNowIcon/PerthNowIcon'
import { TheWestMark } from '../../logos/TheWestMark/TheWestMark'
import { colors } from '../../__styling/settings/colors'
import { perthNowSectionDefinitions } from '../../__styling/settings/sections/perthnow'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface StyledEditorialLabelProps {
    isBreaking: boolean
    cardHasBackground?: boolean
    isLarge?: boolean
}

export const StyledEditorialLabelClassName = 'StyledEditorialLabelClass'

export const StyledEditorialLabel = withClass(StyledEditorialLabelClassName)(
    styled('div')<StyledEditorialLabelProps>(
        {
            textTransform: 'uppercase',
        },
        ({ theme, isBreaking, cardHasBackground, isLarge }) => [
            {
                color: cardHasBackground ? colors.white : undefined,
                letterSpacing: '0.05em',
            },
            // Styling for TW
            theme.kind === 'thewest' && {
                fontWeight: 700,
                fontSize: isLarge ? calcRem(15) : calcRem(11),
                fontFamily: theme.fonts.sansSerif,
                color: isBreaking
                    ? theme.colors.components?.card?.label?.breaking
                    : theme.colors.components?.card?.label?.default ??
                      colors.thewest.westblue,
                letterSpacing: 'normal',
            },
            // Styling for PN
            theme.kind === 'perthnow' && {
                fontWeight: 700,
                fontSize: isLarge ? calcRem(20) : calcRem(11),
                fontFamily: theme.fonts.sansSerifCond,
                color: cardHasBackground
                    ? colors.white
                    : theme.colors.text.primary,
            },
            // Styling for 7N
            theme.kind === 'sevennews' && {
                fontWeight: 400,
                fontSize: isLarge ? calcRem(18) : calcRem(16),
                fontFamily: theme.fonts.sansSerif,
                color: colors.white,
                letterSpacing: 'normal',
            },
        ],
    ),
)

export const StyledEditorialLabelText = styled('span')<{
    isOlympics?: boolean
}>(({ isOlympics }) => ({
    marginTop: isOlympics ? 'auto' : undefined,
    marginBottom: isOlympics ? 'auto' : undefined,
    height: isOlympics ? 'fit-content' : undefined,
    display: isOlympics ? 'block' : undefined,
    color: isOlympics ? colors.olympics.orange : undefined,

    marginLeft: calcRem(0),
}))

interface Keyframes {
    [key: string]: CSSObject
}

const liveBlinkAnimation: Keyframes = {
    from: {
        opacity: 1,
    },
    to: {
        opacity: 0.1,
    },
}

export const StyledLiveBubble = styled('div')<{ isOlympics?: boolean }>(
    {
        display: 'inline-block',
        borderRadius: '100%',
        width: '0.7em',
        height: '0.7em',
        animation: `${keyframes(liveBlinkAnimation)} 1s infinite ease`,

        '@media (prefers-reduced-motion: reduce)': {
            animation: 'none',
        },
    },
    ({ theme, isOlympics = false }) => [
        {
            backgroundColor: 'currentColor',
        },
        // Styling for PN
        theme.kind === 'perthnow' && {
            backgroundColor: perthNowSectionDefinitions.default.primaryColor,
        },
        // Styling for TW
        theme.kind === 'thewest' && {
            display: isOlympics ? 'flex' : undefined,
            marginTop: isOlympics ? 'auto' : undefined,
            marginBottom: isOlympics ? 'auto' : undefined,

            backgroundColor: isOlympics
                ? colors.olympics.orange
                : theme.colors.brand,
        },
    ],
)

interface StyledPerthNowBlockProps {
    isLarge?: boolean
}

export const StyledPerthNowBlock = styled('div')<StyledPerthNowBlockProps>(
    {
        display: 'inline-block',
        backgroundColor: perthNowSectionDefinitions.default.primaryColor,
    },
    ({ isLarge }) => [
        {
            width: isLarge ? calcRem(15) : calcRem(8),
            height: isLarge ? calcRem(15) : calcRem(8),
            marginTop: isLarge ? calcRem(2) : calcRem(1),
        },
    ],
)

interface StyledIconProps {
    isLarge?: boolean
}

export const StyledTheWestMark = styled(TheWestMark)<StyledIconProps>(
    {
        width: calcRem(12),
        height: calcRem(10),
    },
    ({ isLarge }) => [
        isLarge && {
            width: calcRem(15),
            height: calcRem(13),
        },
    ],
)

export const StyledPerthNowIcon = styled(PerthNowIcon)<StyledIconProps>(
    {
        width: calcRem(9),
        height: calcRem(9),
        transform: `translateY(1px)`,
    },
    ({ isLarge }) => [
        isLarge && {
            width: calcRem(16),
            height: calcRem(16),
            transform: `translateY(2px)`,
        },
    ],
)

export const StyledIconLightning = styled(IconLightning)<StyledIconProps>(
    {
        width: calcRem(7),
        height: calcRem(10),
        transform: `translateY(1px)`,
    },
    ({ theme, isLarge }) => [
        // Styling for PN
        isLarge &&
            theme.kind === 'perthnow' && {
                width: calcRem(12),
                height: calcRem(16),
                transform: `translateY(2px)`,
            },
        // Styling for TW
        isLarge &&
            theme.kind === 'thewest' && {
                width: calcRem(10),
                height: calcRem(14),
                transform: 'translateY(2px)',
            },
    ],
)
