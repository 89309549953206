import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts, themedValue } from '../../__styling'

export const StyledAflPlayerStatsWidgetWrapper = styled('div')({})

export const StyledAflPlayerStatsWidgetTeamControls = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: calcRem(16),
        marginTop: themedValue(theme, {
            perthnow: calcRem(16),
            thewest: calcRem(24),
            fallback: undefined,
        }),
        fontFamily: themedValue(theme, {
            thewest: fonts.thewest.sansSerif,
            fallback: undefined,
        }),
    }),
)

interface AflPlayerStatsWidgetTeamButtonProps {
    isActive: boolean
}

export const StyledAflPlayerStatsWidgetTeamButton = styled(
    'button',
)<AflPlayerStatsWidgetTeamButtonProps>(({ isActive, theme }) => ({
    padding: calcRem(8, 20),
    textTransform: 'uppercase',
    fontSize: calcRem(12),
    fontWeight: themedValue(theme, {
        sevennews: 500,
        perthnow: 600,
        thewest: 600,
        fallback: undefined,
    }),
    lineHeight: '20px',
    letterSpacing: '0.7px',
    background: themedValue(theme, {
        sevennews: isActive
            ? tokens.sevennews.colors.palette.red
            : tokens.sevennews.colors.palette.AFL.red40,
        perthnow: isActive
            ? tokens.perthnow.colors.palette.pinkThulite
            : tokens.perthnow.colors.palette.AFL.pinkThuliteLight,
        thewest: isActive
            ? tokens.thewest.colors.palette.westblue
            : tokens.thewest.colors.palette.AFL.westblue40,
        fallback: undefined,
    }),
    color: tokens.sevennews.colors.palette.white,
    border: 'none',
    cursor: 'pointer',
    ':nth-of-type(2)': {
        margin: calcRem(0, 2.2),
    },
}))

export const StyledAflPlayerStatsWidgetTableWrapper = styled('div')({
    overflowX: 'scroll',
    [breakpoint('md')]: {
        overflowX: 'hidden',
    },
})

export const StyledAflPlayerStatsWidgetTable = styled('table')(({ theme }) => ({
    width: '100%',
    minWidth: calcRem(640),
    tableLayout: 'fixed',
    [breakpoint('sm')]: {
        minWidth: themedValue(theme, {
            sevennews: calcRem(1020),
            perthnow: calcRem(970),
            fallback: undefined,
        }),
    },
}))
export const StyledAflPlayerStatsWidgetTableHead = styled('thead')(
    ({ theme }) => ({
        color: tokens.sevennews.colors.palette.white,
        fontSize: themedValue(theme, {
            thewest: calcRem(14),
            fallback: undefined,
        }),

        '& th': {
            background: themedValue(theme, {
                sevennews: tokens.sevennews.colors.palette.matchCentre.darkred,
                perthnow: tokens.perthnow.colors.palette.pinkThulite,
                thewest: tokens.thewest.colors.palette.westblue,
                fallback: undefined,
            }),
            textAlign: themedValue(theme, {
                perthnow: 'center',
                fallback: undefined,
            }),
            padding: calcRem(10, 0),
            cursor: 'pointer',
            ':nth-of-type(1)': {
                width: calcRem(45),
                position: 'sticky',
                left: 0,
                zIndex: 1,
                [breakpoint('sm')]: {
                    width: calcRem(75),
                },
            },
            ':nth-of-type(2)': {
                width: calcRem(160),
                position: 'sticky',
                left: calcRem(45),
                zIndex: 1,
                [breakpoint('sm')]: {
                    width: '23%',
                    left: calcRem(75),
                },
            },
        },
    }),
)
export const StyledAflPlayerStatsWidgetTableBody = styled('tbody')(
    ({ theme }) => ({
        color: tokens.sevennews.colors.palette.bauhausBlack,
        fontSize: calcRem(16),
        lineHeight: '20px',
        fontWeight: 300,

        '& tr': {
            ':nth-child(even)': {
                '& td': {
                    background: themedValue(theme, {
                        thewest: tokens.thewest.colors.palette.greyErmine,
                        fallback: tokens.sevennews.colors.palette.AFL.black005,
                    }),
                },
            },
        },
    }),
)
interface StyledAflPlayerStatsWidgetTableCellProps {
    sortedColumnIndex: number
}

export const StyledAflPlayerStatsWidgetTableCell = styled(
    'td',
)<StyledAflPlayerStatsWidgetTableCellProps>(({ sortedColumnIndex, theme }) => ({
    background: tokens.sevennews.colors.palette.white,
    textAlign: 'center',
    padding: calcRem(10, 0),
    fontSize: themedValue(theme, {
        thewest: calcRem(14),
        fallback: undefined,
    }),
    [`:nth-of-type(${sortedColumnIndex})`]: {
        fontWeight: 'bold',
    },
    ':nth-of-type(1)': {
        position: 'sticky',
        left: 0,
        fontWeight: sortedColumnIndex === 1 ? 'bold' : 'normal',
    },
    ':nth-of-type(2)': {
        position: 'sticky',
        left: calcRem(45),
        fontWeight: sortedColumnIndex === 2 ? 'bold' : 'normal',
        [breakpoint('sm')]: {
            left: calcRem(75),
        },
    },
}))

interface StyledArrowIndicatorProps {
    isAsc: boolean | false
}

export const StyledArrowIndicator = styled('img')<StyledArrowIndicatorProps>(
    ({ isAsc }) => ({
        userSelect: 'none',
        marginLeft: calcRem(5),
        transform: isAsc ? 'rotate(180deg)' : '',
    }),
)

export const StyledLegend = styled('p')(({ theme }) => ({
    fontSize: calcRem(13),
    lineHeight: '14px',
    fontWeight: 300,
    textAlign: themedValue(theme, {
        thewest: 'center',
        fallback: 'left',
    }),
    color: tokens.sevennews.colors.palette.bauhausBlack,
    strong: {
        fontWeight: 600,
    },
    [breakpoint('sm')]: {
        textAlign: 'center',
    },
}))

export const StyledSwipeIndicator = styled('p')({
    fontSize: calcRem(12),
    lineHeight: '18px',
    fontWeight: 400,
    textAlign: 'right',
    margin: 0,
})
