import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import {
    StyledErrorBlock,
    StyledErrorButton,
    StyledPoliRaterErrorContainer,
} from '../PollieRaterListing/PollieRaterListing.styled'
import { LiveEventLoadButton } from '../publication/LiveEvent/Components'
import { calcRem } from '../__styling'

export const StyledGuestBookLoadMoreButton = styled(LiveEventLoadButton)({})

export const StyledContainer = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: calcRem(theme.margins.lg),
}))

export const StyledGuestbookErrorContainer = styled(
    StyledPoliRaterErrorContainer,
)({})

export const StyledGuestBookErrorBlock = styled(StyledErrorBlock)({
    gap: calcRem(48),
})

export const StyledGuestBookErrorButton = styled(StyledErrorButton)({
    wordBreak: 'break-word',
    maxWidth: calcRem(150),
})

export const StyledWebLink = styled(WebLink)({})

export const StyledSpinnerContainer = styled('div')(({ theme }) => ({
    margin: '0 auto',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(theme.margins.md),
    alignItems: 'center',
}))

export const StyledSpinner = styled('div')(({ theme }) => ({
    display: 'block',
    width: calcRem(70),
    height: calcRem(70),
    borderLeft: `2px solid ${theme.colors.borders.primary}`,
    borderRight: `2px solid ${theme.colors.borders.primary}`,
    borderTop: `2px solid ${theme.colors.borders.primary}`,
    borderRadius: '50%',
    animation: `${spin} 0.6s infinite`,
    WebkitAnimation: `${spin} 0.6s infinite`,
}))

const spin = keyframes({
    to: {
        transform: 'rotate(360deg)',
    },
})
