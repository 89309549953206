/* eslint-disable react-hooks/exhaustive-deps */
import { ConfigurationContext } from '@news-mono/web-common'
import {
    SubscribeToEntity,
    SubscriptionErrorNotification,
    SubscriptionSuccessNotification,
    WebsocketServiceMessage,
    WebsocketServiceMessageWithPayload,
} from '@west-australian-newspapers/websocket-message-types'
import debug from 'debug'
import { useContext, useEffect, useMemo } from 'react'
import useWebSocket, { Options, ReadyState } from 'react-use-websocket'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types'

export const debugWSS = debug('wss')

type Props<T> = {
    entityId: string
    onMessage: (message: WebsocketServiceMessageWithPayload<T>) => void
    onSubscribe?: (message: WebsocketServiceMessageWithPayload<T>[]) => void
    optionOverrides?: Omit<Options, 'filter'>
}
export type WebsocketServiceClientHook<T> = Omit<
    WebSocketHook,
    'lastMessage' | 'lastJsonMessage' | 'sendJsonMessage' | 'sendMessage'
> & {
    sendMessage: (message: WebsocketServiceMessage) => void
    lastMessage: WebsocketServiceMessageWithPayload<T>
}

export function useWebsocketServiceClient<T>({
    entityId,
    onMessage,
    onSubscribe,
    optionOverrides,
}: Props<T>): WebsocketServiceClientHook<T> {
    const config = useContext(ConfigurationContext)

    type PacketType =
        | WebsocketServiceMessageWithPayload<T>
        | SubscriptionErrorNotification
        | SubscriptionSuccessNotification<T>

    const parseWebsocketMessage = (
        message: WebSocketEventMap['message'],
    ): PacketType | undefined => {
        try {
            const packet = JSON.parse(message.data) as PacketType

            if (!packet.hasOwnProperty('kind')) {
                debugWSS('Packet appears malformed: %o', { packet })
                return
            }

            debugWSS('Message parsed: %o', { packet })

            return packet
        } catch (err) {
            debugWSS(
                'Something went wrong while parsing the websocket message: %o',
                { message, err },
            )
        }
    }

    const isSystemPacket = (
        packet: PacketType,
    ): packet is
        | SubscriptionErrorNotification
        | SubscriptionSuccessNotification<T> => {
        return packet.kind.startsWith('subscription')
    }

    const handleSystemPacket = (
        packet:
            | SubscriptionErrorNotification
            | SubscriptionSuccessNotification<T>,
    ) => {
        switch (packet.kind) {
            case 'subscription-success': {
                debugWSS('Successfully subscribed to entity: %s', entityId)
                if (onSubscribe && packet.buffer) {
                    onSubscribe(packet.buffer)
                }
                break
            }

            default:
                break
        }
    }

    const defaultOnOpen = (event: WebSocketEventMap['open']) => {
        debugWSS('Connection open: %o', { event })
    }

    const filter = (packet: PacketType) => {
        debugWSS('Filtering packet: %o', { packet })

        const allowMessage = packet.entityId === entityId

        debugWSS(`Message ${allowMessage ? 'passed' : 'failed'} filter: %o`, {
            packet,
        })

        return allowMessage
    }

    const defaultOnMessage = (message: WebSocketEventMap['message']) => {
        debugWSS('Message received: %o', { message })

        const packet = parseWebsocketMessage(message)

        if (!packet) {
            return false
        }

        if (!filter(packet)) {
            return
        }

        if (isSystemPacket(packet)) {
            return handleSystemPacket(packet)
        }

        debugWSS('Sending packet to listener')
        onMessage(packet)
    }

    const defaultOnError = (event: WebSocketEventMap['error']) =>
        debugWSS('Connection error: %o', { event })
    const defaultOnClose = (event: WebSocketEventMap['close']) =>
        debugWSS('Connection closed: %o', { event })

    const options = useMemo<Options>(() => {
        return {
            filter: undefined,
            shouldReconnect: () => true,
            reconnectAttempts: 10,
            reconnectInterval: 3000,
            retryOnError: true,
            fromSocketIO: false,
            queryParams: undefined,
            share: false,
            onOpen: defaultOnOpen,
            onClose: defaultOnClose,
            onMessage: defaultOnMessage,
            onError: defaultOnError,
            enforceStaticOptions: false,
            ...optionOverrides,
        }
    }, [optionOverrides, onMessage, entityId])

    if (!config.websocketServiceEndpoint) {
        debugWSS('No websocketServiceEndpoint provided')
        throw new Error('No websocketServiceEndpoint provided')
    }

    const { lastJsonMessage, lastMessage, sendJsonMessage, ...websocket } =
        useWebSocket(config.websocketServiceEndpoint, options)

    useEffect(() => {
        debugWSS('Websocket status: %s', ReadyState[websocket.readyState])

        /**
         * When the websocket is ready, send a subscription message
         */
        if (websocket.readyState === ReadyState.OPEN) {
            debugWSS('Subscribing to entity: %s', entityId)

            const subscriptionMessage: SubscribeToEntity = {
                kind: 'subscribe',
                entityId,
                includeBuffer: !!onSubscribe,
            }

            sendJsonMessage(subscriptionMessage)
        }
    }, [websocket.readyState, entityId])

    const sendMessage = (message: WebsocketServiceMessage) =>
        sendJsonMessage(message)

    return { ...websocket, sendMessage, lastMessage: lastJsonMessage }
}

export const useWSS = useWebsocketServiceClient
