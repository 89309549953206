import { useTheme } from '@emotion/react'
import {
    PublicationCardItem,
    requiredAccessValue,
    toLinkWithHint,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { FC } from 'react'
import {
    StyledIconPremium,
    StyledLink,
    StyledLinkText,
} from '../../../cards/ListCard/ListCard.styled'
import { TimestampType } from '../../../content/CardTimestamp/CardTimestamp'
import { Skeleton } from '../../../content/Skeleton/Skeleton'
import { ListCardTimestamp } from './ListCardTimestamp'
import { ListCardTopicTag } from './ListCardTopicTag'

export interface ListCardContentProps {
    timestamp?: TimestampType
    simpleList?: boolean | undefined
    item: MaybeLoaded<PublicationCardItem>
    removeHorizontalMargin?: boolean
    hasSeparators?: boolean
    isTruncated?: boolean
    linkClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
    hideTopic?: boolean
}

export const ListCardContent: FC<ListCardContentProps> = (props) => {
    const {
        simpleList,
        item,
        timestamp,
        hideTopic,
        removeHorizontalMargin,
        linkClicked,
        hasSeparators,
        isTruncated,
    } = props
    const theme = useTheme()

    const requiredAccess = !item.loaded ? undefined : item.result.requiredAccess

    const showTimestamp =
        (timestamp !== undefined && !item.loaded) ||
        (timestamp !== undefined &&
            item.loaded &&
            !!item.result.publicationDate)

    const shouldRenderTopic = hideTopic || showTimestamp ? false : true

    return (
        <React.Fragment>
            {theme.kind === 'perthnow' && (
                <React.Fragment>
                    {showTimestamp && (
                        <ListCardTimestamp item={item} timestamp={timestamp} />
                    )}
                    {shouldRenderTopic && <ListCardTopicTag item={item} />}
                </React.Fragment>
            )}

            <StyledLink
                to={toLinkWithHint(item)}
                onClick={linkClicked}
                timestamp={timestamp}
                removeHorizontalMargin={
                    removeHorizontalMargin || (!item.loaded && !timestamp)
                }
                simpleList={simpleList}
                hasSeparators={hasSeparators}
            >
                {theme.kind === 'sevennews' && (
                    <React.Fragment>
                        {showTimestamp && (
                            <ListCardTimestamp
                                item={item}
                                timestamp={timestamp}
                            />
                        )}
                        {shouldRenderTopic && <ListCardTopicTag item={item} />}
                    </React.Fragment>
                )}

                <StyledLinkText isTruncated={isTruncated}>
                    {requiredAccessValue(requiredAccess, {
                        subscriber: (
                            <StyledIconPremium width={17} height={17} />
                        ),
                        anonymous: undefined,
                    })}
                    {!item.loaded ? <Skeleton /> : item.result.shortHeadline}
                </StyledLinkText>
            </StyledLink>
        </React.Fragment>
    )
}
ListCardContent.displayName = 'ListCardContent'
