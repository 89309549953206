import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PollieRater } from './PollieRater'

const queryClient = new QueryClient()

export const PollieRaterRegistration = createRegisterableComponent(
    'pollie-rater',
    ({}, services) => {
        return (
            <QueryClientProvider client={queryClient}>
                <PollieRater onEvent={services.onEvent} />
            </QueryClientProvider>
        )
    },
)
