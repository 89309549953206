import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledTopicLabel = styled(Link)(
    {
        fontWeight: 600 as const,
        fontSize: calcRem(14),
        textDecoration: 'none',
        transition: `background-color ${easing.easeOut.fast}`,
        minHeight: 31,
        alignItems: 'center',
        padding: calcEm(0, 7),

        '&:hover': {
            backgroundColor: colors.perthnow.blueKyanite,
        },

        '&:focus': {
            backgroundColor: 'transparent',
            outline: `2px solid ${colors.perthnow.greyCoal}`,
            outlineOffset: -2,
            color: colors.perthnow.greyCoal,
        },
    },
    ({ theme }) => ({
        color:
            theme.kind === 'thenightly'
                ? theme.colors.text.primary
                : colors.white,
        fontFamily: theme.fonts.sansSerif,
        backgroundColor: themedValue(theme, {
            perthnow: (section) => section.primaryColor,
            fallback: undefined,
        }),
    }),
)
