import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import {
    Timestamp,
    TimestampType,
} from '../../content/CardTimestamp/CardTimestamp'
import { styledCardTimestampTextLabel } from '../../content/CardTimestamp/CardTimestamp.styled'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { TopicLabel } from '../../typography/TopicLabel/TopicLabel'
import { breakpointMax } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords, transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Kicker } from '../Kicker/Kicker'
import { StyledKicker } from '../Kicker/Kicker.styled'

export const styledListCardClassName = 'ListCard'
export const styledLinkClassName = 'ListCard-Link'
export const styledHeadlineClassName = 'ListCard-Headline'

export interface StyledListCardProps {
    simpleList?: boolean
    noStretch?: boolean
}

export const StyledTimestamp = styled(Timestamp)((props) => ({
    textTransform: 'uppercase' as const,
    marginBottom: themedValue(props.theme, {
        perthnow: 0,
        fallback: calcRem(6),
    }),
}))

export const StyledListCardKicker = styled(Kicker)({
    marginBottom: calcRem(6),
})

export const StyledListCard = withClass(styledListCardClassName)(
    styled('li')<StyledListCardProps>(
        {
            ...breakWords,
            width: '100%',
            listStyle: 'none',
        },
        (props) => ({
            flexGrow: props.noStretch ? 0 : 1,
            backgroundColor: themedValue(props.theme, {
                perthnow: colors.white,
                fallback: 'transparent',
            }),
        }),

        ({ theme }) =>
            theme.kind === 'perthnow' && {
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                margin: 0,
            },

        ({ theme }) =>
            theme.kind === 'sevennews' && {
                '&:hover': {
                    [`& .${styledLinkClassName}`]: {
                        color: themedValue(theme, {
                            sevennews: (section) =>
                                section.cards.list.hoverColor,
                            fallback: undefined,
                        }),
                    },
                    [`& ${StyledKicker}`]: {
                        color: themedValue(theme, {
                            sevennews: (section) =>
                                section.cards.kicker.hoverColor,
                            fallback: undefined,
                        }),
                    },
                    [`& .${styledCardTimestampTextLabel}`]: {
                        color: themedValue(theme, {
                            sevennews: (section) =>
                                section.cards.timestamp.hoverColor,
                            fallback: undefined,
                        }),
                    },
                },
            },
    ),
)

export const StyledHeadline = withClass(styledHeadlineClassName)(
    styled('span')(
        ({ theme }) =>
            (theme.kind === 'perthnow' || theme.kind === 'thewest') && {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                flex: '1 1 auto',
                alignSelf: 'stretch',
                overflow: 'hidden',
                color: colors.black,
                fontSize: calcRem(14),
                margin: 0,
            },
    ),
)

export const StyledCommentsLink = styled(WebLink)(({ theme }) => {
    return {
        color: theme.colors.text.secondary,
        textDecoration: 'none',

        [`&:is(:hover, :focus)`]: {
            textDecoration: 'underline',
        },
    }
})
export const StyledTopicLabel = styled(TopicLabel)(
    ({ theme }) =>
        theme.kind === 'perthnow' && {
            flex: '0 0 auto',
            display: 'flex',
            alignSelf: 'stretch',
        },
)

export const StyledIconWrapper = styled('div')(() => ({
    height: calcRem(12),
    width: calcRem(12),
    marginLeft: calcRem(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export interface StyledLinkProps {
    simpleList?: boolean
    removeHorizontalMargin?: boolean
    hasSeparators?: boolean
    timestamp?: TimestampType
}

export const StyledLink = withClass(styledLinkClassName)(
    styled(WebLink, {
        shouldForwardProp: createPropFilter<WebLinkProps>()([
            'simpleList',
            'removeHorizontalMargin',
            'hasSeparators',
            'section',
        ]),
    })<StyledLinkProps>(
        {
            textDecoration: 'none',
        },
        (props) => ({
            lineHeight: themedValue(props.theme, {
                thewest: 1.286,
                fallback: 1.154,
            }),
            display: themedValue(props.theme, {
                perthnow: 'flex',
                fallback: 'block',
            }),
            padding: themedValue(props.theme, {
                thewest: calcRem(metrics.thewest.margins.sm, 0),
                perthnow:
                    props.timestamp !== undefined
                        ? calcRem(21, metrics.perthnow.margins.sm)
                        : 0,
                sevennews: calcRem(8, 0),
                fallback: undefined,
            }),
            fontWeight: themedValue(props.theme, {
                perthnow:
                    props.timestamp !== undefined || props.simpleList
                        ? 700
                        : 400,
                sevennews: 500,
                thewest: 400,
                fallback: undefined,
            }),
            margin: props.removeHorizontalMargin
                ? 0
                : themedValue(props.theme, {
                      perthnow: `0 ${calcRem(metrics.perthnow.margins.sm)}`,
                      fallback: undefined,
                  }),
            color: themedValue(props.theme, {
                perthnow:
                    props.timestamp !== undefined
                        ? colors.black
                        : colors.perthnow.greyCoal,
                thewest: colors.black,
                sevennews: (section) => section.cards.list.primaryColor,
                fallback: undefined,
            }),
            fontFamily: themedValue(props.theme, {
                perthnow:
                    props.timestamp !== undefined
                        ? props.theme.fonts.sansSerifCond
                        : props.theme.fonts.sansSerif,
                sevennews: props.theme.fonts.sansSerif,
                fallback: props.theme.fonts.cards.headline,
            }),
            fontSize: themedValue(props.theme, {
                sevennews: calcRem(14),
                thewest: calcRem(14),
                fallback: undefined,
            }),
            borderBottom: props.hasSeparators
                ? themedValue(props.theme, {
                      thewest: `1px solid ${colors.thewest.greyQuokka}`,
                      perthnow: '1px solid black',
                      fallback: 'currentColor',
                  })
                : undefined,

            '&:first-child': {
                borderTop: props.hasSeparators
                    ? themedValue(props.theme, {
                          thewest: `1px solid ${colors.thewest.greyQuokka}`,
                          perthnow: '1px solid black',
                          fallback: 'currentColor',
                      })
                    : undefined,
            },

            '&:last-child': {
                borderBottom: 'none',
            },

            '&:hover': {
                color: themedValue(props.theme, {
                    perthnow: colors.perthnow.blueKyanite,
                    thewest: (section) => section.primaryColor,
                    fallback: undefined,
                }),
                textDecoration: themedValue(props.theme, {
                    sevennews: 'underline',
                    fallback: undefined,
                }),
            },

            '&:focus': {
                outline: themedValue(props.theme, {
                    thewest: 'none',
                    perthnow: 'none',
                    sevennews: 'solid 2px',
                    fallback: undefined,
                }),
                textDecoration: themedValue(props.theme, {
                    sevennews: undefined,
                    fallback: 'underline',
                }),
                color: colors.black,
                outlineColor: themedValue(props.theme, {
                    sevennews: (section) => section.focusColor,
                    fallback: undefined,
                }),
            },

            // to prevent flash on desktop when clicking through
            '&:active': {
                outline: themedValue(props.theme, {
                    sevennews: 'none',
                    fallback: undefined,
                }),
            },

            [breakpointMax('xxs')]: {
                fontSize:
                    props.theme.kind === 'thewest'
                        ? calcRem(metrics.thewest.cards.headingFontSize)
                        : undefined,
            },
        }),

        ({ theme }) =>
            theme.kind === 'perthnow' && {
                flex: '1 1 auto',
                alignSelf: 'center',
                alignItems: 'center',
                height: calcRem(31),
                // Hack to stop long text overflowing container
                width: 0,
                overflow: 'hidden',
                ...transition({ properties: ['color'] }),
            },
    ),
)

export interface StyledLinkTextProps {
    isTruncated?: boolean
}

export const StyledLinkText = styled('span')<StyledLinkTextProps>(
    {
        display: 'block',
        width: '100%',
    },
    ({ isTruncated }) =>
        isTruncated && {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
)

export interface StyledIconPremiumProps {
    width: number
    height: number
}

export const StyledIconPremium = styled(IconPremium)<StyledIconPremiumProps>(
    ({ width, height }) => ({
        width,
        height,
        verticalAlign: 'text-bottom',
        marginRight: calcRem(1),
    }),
)
