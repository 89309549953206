import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CardOrientationOptions } from '../../cards/Portrait/Portrait'
import { calculateExpectedCardCountFromContentData } from '../../collections'
import { SectionHeaderProps } from '../../section-header/SectionHeader/SectionHeader'
import { ThemeMargins } from '../../__styling'
import { Hera } from './Hera'

export interface HeraRoutingProps {
    sectionHeader?: SectionHeaderProps
    hasBorder?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    cardOrientation?: CardOrientationOptions
}

export const HeraRegistration = createRegisterableComponentWithData(
    'hera',
    ContentDataDefinitionLoader,
    (props: HeraRoutingProps, data, services) => {
        const expectedCards = calculateExpectedCardCountFromContentData(
            data.dataDefinitionArgs,
        )

        if (
            !data.loaded ||
            (data.loaded && data.result.publications.length <= 0)
        ) {
            return null
        }
        return (
            <Hera
                expectedCards={expectedCards}
                items={
                    data.loaded
                        ? { loaded: true, result: data.result.publications }
                        : { loaded: false }
                }
                onEvent={services.onEvent}
                sectionHeader={props.sectionHeader}
                hasBorder={props.hasBorder}
                verticalSpacing={props.verticalSpacing}
                cardOrientation={props.cardOrientation}
                cardServices={services}
            />
        )
    },
)
