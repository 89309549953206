import React from 'react'
import { AmpVideo } from '../../content/Video/Video.amp'
import { ArticleVideoProps } from './ArticleVideo'
import {
    articleVideoClassName,
    StyledArticleVideo,
    StyledArticleVideoContainer,
    StyledFigcaption,
} from './ArticleVideo.styled'

export const AmpArticleVideo: React.FC<ArticleVideoProps> = ({
    byline,
    captionText,
    credit,
    kind,
    videoDetails,
    isHeroVideo,
    publicationPathname,
    videoOptions,
}) => {
    if (videoDetails.id) {
        return (
            <StyledArticleVideo
                className={articleVideoClassName}
                isHeroVideo={isHeroVideo}
            >
                <StyledArticleVideoContainer isHeroVideo={isHeroVideo}>
                    <AmpVideo
                        videoDetails={videoDetails}
                        width={480}
                        height={270}
                        videoOptions={videoOptions}
                        publicationPathname={publicationPathname}
                    />
                </StyledArticleVideoContainer>

                {(captionText || credit || byline) && (
                    <StyledFigcaption
                        byline={byline}
                        captionText={captionText}
                        credit={isHeroVideo ? undefined : credit}
                        isHeroVideo={isHeroVideo}
                        kind={kind || 'video'}
                    />
                )}
            </StyledArticleVideo>
        )
    }

    return null
}
export default AmpArticleVideo
