import React, { FC, useEffect, useState } from 'react'
import {
    StyledIconCross,
    StyledTextContainer,
    StyledTextDescription,
    StyledTextTitle,
    StyledUserSettingsSlideToast,
} from './UserSettingsSlideToast.styled'
import { IconAlertCircleTN, IconCheckmarkTN } from '../../icons'
import { useProduct } from '../../__product'
import { Product } from '@news-mono/web-common'
import { IconInfo } from '../../icons/IconInfo/IconInfo'

export type ToastType = 'success' | 'error' | 'information'

export interface ToastMessage {
    text: string
    subText?: string
    type: ToastType
    show: boolean
}

export interface TNSlideToastProps extends ToastMessage {
    onClose: () => void
    forceRender?: boolean
    fixedAtBottom?: boolean
}

export const UserSettingsSlideToast: FC<TNSlideToastProps> = ({
    text,
    subText = undefined,
    show = false,
    type,
    onClose,
    forceRender = false,
    fixedAtBottom = false,
}) => {
    const [showToast, setShowToast] = useState<boolean>(forceRender)
    const product = useProduct()
    const isTn = product === Product.TheNightly
    const timeout = isTn ? 3000 : 5000

    useEffect(() => {
        setShowToast(show)
        if (show) {
            const autoDismissTimer = setTimeout(() => {
                setShowToast(false)
                onClose()
            }, timeout)
            return () => autoDismissTimer && clearTimeout(autoDismissTimer)
        }
    }, [show, onClose, timeout])

    const toastIcon =
        type === 'success' ? (
            <IconCheckmarkTN strokeColor="#fff" />
        ) : type === 'information' ? (
            <IconInfo strokeColor="#fff" />
        ) : (
            <IconAlertCircleTN strokeColor="#fff" />
        )

    return (
        <StyledUserSettingsSlideToast
            show={showToast}
            type={type}
            forceRender={forceRender}
            fixedAtBottom={fixedAtBottom}
        >
            <div>
                {toastIcon}
                <StyledTextContainer>
                    <StyledTextTitle>{text}</StyledTextTitle>
                    {subText && (
                        <StyledTextDescription>{subText}</StyledTextDescription>
                    )}
                </StyledTextContainer>
                {/* Only add the Cross Icon for TheNightly */}
                {isTn && <StyledIconCross onClick={onClose} />}
            </div>
        </StyledUserSettingsSlideToast>
    )
}
