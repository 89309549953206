import { createRegisterableComponent, NavItem } from '@news-mono/web-common'
import React from 'react'
import { QuickLinks } from '../../navigation/QuickLinks/QuickLinks'

export interface QuickLinksRouteProps {
    links: NavItem[]
    hasBackgroundFill?: boolean
}

export const QuickLinksRegistration = createRegisterableComponent(
    'quick-links',
    (props: QuickLinksRouteProps, services) => (
        <QuickLinks {...props} onEvent={services.onEvent} />
    ),
)
