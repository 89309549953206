import React from 'react'

export function YoutubeLogo() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3766 4.04067C22.4093 4.31845 23.2225 5.1369 23.4985 6.17626C24 8.06003 24 11.9904 24 11.9904C24 11.9904 24 15.9207 23.4985 17.8047C23.2225 18.8439 22.4093 19.6623 21.3766 19.9402C19.505 20.4449 12 20.4449 12 20.4449C12 20.4449 4.49499 20.4449 2.62335 19.9402C1.59076 19.6623 0.777485 18.8439 0.501509 17.8047C0 15.9207 0 11.9904 0 11.9904C0 11.9904 0 8.06003 0.501509 6.17626C0.777485 5.1369 1.59076 4.31845 2.62335 4.04067C4.49499 3.53585 12 3.53585 12 3.53585C12 3.53585 19.505 3.53585 21.3766 4.04067ZM15.8181 11.9906L9.54539 15.5589V8.42192L15.8181 11.9906Z"
                fill="#FF0000"
            />
        </svg>
    )
}
