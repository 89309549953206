import { FeatureState, isFeatureEnabled } from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import {
    PageMetaItemDTO,
    allowedSources,
} from '@west-australian-newspapers/publication-types'

export const allowList = allowedSources.thewest

/** [TNI-272](https://wanews.atlassian.net/jira/software/c/projects/TNI/boards/38?assignee=712020%3A6745acdd-b636-4a2b-b9b4-9caae1c77ec1&selectedIssue=TNI-272): This function is used to add the meta tag `noindex, nofollow` to any third party content */
export const getNoIndexMeta = (
    source: string,
    section: TheWestSection,
    featureState: FeatureState | undefined,
): PageMetaItemDTO[] | undefined => {
    const formattedSection = section.split('-').join(' ')
    const isAllowed = allowList.includes(source)
    const noIndexFeatureEnabled = featureState
        ? isFeatureEnabled(
              featureState,
              'third-party-noindex-nofollow-meta-tags',
              true,
          )
        : true

    if (!noIndexFeatureEnabled) {
        return undefined
    }

    if (source.toLowerCase() === formattedSection) {
        return
    } else if (section === 'default') {
        if (!isAllowed) {
            return [{ name: 'robots', content: 'noindex, nofollow' }]
        }
    } else if (section === 'manjimup-bridgetown-times') {
        if (source !== 'Manjimup-Bridgetown Times') {
            return [{ name: 'robots', content: 'noindex, nofollow' }]
        }
    } else if (section === 'harvey-waroona-reporter') {
        if (source !== 'Harvey-Waroona Reporter') {
            return [{ name: 'robots', content: 'noindex, nofollow' }]
        }
    } else {
        return [{ name: 'robots', content: 'noindex, nofollow' }]
    }
}
