import React from 'react'
import styled from '@emotion/styled'
import { textMixin } from '../../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '../../../../../__styling'
import { WebLink, WebLinkProps } from '@news-mono/web-common'

type AuthorProps = WebLinkProps & {}

export const AuthorLink = ({ ...props }: AuthorProps) => (
    <Author>
        <StyledAuthorLink {...props} />
    </Author>
)

export const Author = styled.div(({ theme }) => [
    {
        color: theme.colors.text.secondary,
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-secondary'),
])

const StyledAuthorLink = styled(WebLink)({
    // Remove default anchor styling.
    color: 'unset',
    textDecoration: 'none',

    // Underline Headline when hovered or active.
    [`&:is(:hover, :focus)`]: {
        textDecoration: 'underline',
    },
})
