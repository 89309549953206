import React from 'react'
import { IconBlockQuoteNGN } from '@news-mono/component-library'
import { StyledPNPullQuote, StyledQuoteText } from './PNPullQuote.styled'

export interface PNPullQuoteProps {
    text: string
}

export const PNPullQuote: React.FC<PNPullQuoteProps> = ({ text }) => {
    const quote = text.replace(/[“”]/g, '')
    return (
        <StyledPNPullQuote>
            <IconBlockQuoteNGN />
            <StyledQuoteText>{quote}</StyledQuoteText>
        </StyledPNPullQuote>
    )
}
export default PNPullQuote
