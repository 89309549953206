import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { breakpoint, calcRem } from '..'
import {
    PERTHNOW_CARD_HEIGHT,
    THEWEST_CARD_HEIGHT,
} from './WebStoriesCard/WebStoriesCard.styled'

interface CarouselWrapperProps {
    isBeforeActive?: boolean
    isAfterActive?: boolean
}

export const StyledCarouselWrapper = styled('div')<CarouselWrapperProps>(
    ({ theme, isAfterActive, isBeforeActive }) => [
        {
            position: 'relative',
            width: '100%',

            '&::after': {
                display: isAfterActive ? 'block' : 'none',
                position: 'absolute',
                pointerEvents: 'none',
                content: `''`,
                width: calcRem(240),
                bottom: 0,
                right: 0,
                background:
                    'linear-gradient(270deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0) 89.96%)',
                backgroundAttachment: 'local local scroll scroll',
            },
            '&::before': {
                zIndex: 1,
                display: isBeforeActive ? 'block' : 'none',
                position: 'absolute',
                pointerEvents: 'none',
                content: `''`,
                width: calcRem(240),
                bottom: 0,
                left: 0,
                background: `linear-gradient(90deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0) 89.96%)`,
            },
        },
    ],
)

export const StyledCarouselCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    overflowX: 'visible',
    alignItems: 'center',

    '&::after': {
        display: 'block',
    },
    '& .swiper-slide': {
        width: 'auto',
    },
}))

export const StyledCarouselEntryPoints = styled('div')(({ theme }) => ({
    display: 'flex',
    overflowY: 'visible',
    scrollbarWidth: 'none',
}))

interface StyledEntryPointArrowProps {
    // Turns on hover/focus effects.
    hasEffects?: boolean
}

export const StyledEntryPointArrow = styled(
    'button',
)<StyledEntryPointArrowProps>(({ theme, hasEffects }) => [
    {
        display: 'none',

        [breakpoint('lg')]: {
            display: 'block',
            top: `calc(50% - ${calcRem(18)})`,
            zIndex: 3,
            cursor: 'pointer',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%',
            backgroundSize: `${calcRem(9)} ${calcRem(16)}`,
            border: 0,
            backgroundColor:
                theme.kind === Product.SevenNews
                    ? '#EAEAEA'
                    : theme.colors.palette.black,
            outline: 'transparent',
            height: calcRem(37),
            width: calcRem(37),
            position: 'absolute',
            borderRadius: '50%',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
            backgroundImage: `url('data:image/svg+xml;charset=utf-8,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14.1787L7 8.17871L2 2.17871" stroke="${
                theme.kind === Product.SevenNews ? 'black' : 'white'
            }" stroke-width="2.76738" stroke-linecap="round"/></svg>')`,
            ':disabled': {
                display: 'none',
            },
        },
    },
    hasEffects && {
        '&:hover': {
            opacity: '0.8',
        },
        '&:focus': {
            opacity: '0.76',
        },
    },
])

export const StyledEntryPointArrowLeft = styled(StyledEntryPointArrow)(
    ({}) => ({
        left: calcRem(-18),
        transform: 'rotate(-180deg)',
    }),
)
export const StyledEntryPointArrowRight = styled(StyledEntryPointArrow)(
    ({}) => ({
        right: calcRem(-18),
    }),
)

export const StyledGradientFadeRight = styled('div')(({ theme }) => ({
    maskImage:
        'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    background:
        'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 2,
    width: calcRem(70),
    height:
        theme.kind === Product.PerthNow
            ? calcRem(PERTHNOW_CARD_HEIGHT)
            : calcRem(THEWEST_CARD_HEIGHT),
    right: 0,
}))

export const StyledGradientFadeLeft = styled('div')(({ theme }) => ({
    maskImage:
        'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    background:
        'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 2,
    width: calcRem(70),
    height:
        theme.kind === Product.PerthNow
            ? calcRem(PERTHNOW_CARD_HEIGHT)
            : calcRem(THEWEST_CARD_HEIGHT),
    left: 0,
}))
