import { GalleryV4DTO } from '@west-australian-newspapers/publication-types'
import { GalleryPublication } from '../../../../client-data-types/publication'
import { getCommonProps } from '../../../../__api-mapping/v4/publication/map/common'
import {
    imageWithCropsDtoToImageCrop,
    mergeImageDtosToImageSource,
} from '../../lib/to-image'
import { getContentBlocks } from './content-blocks'

export function mapGallery(publication: GalleryV4DTO): GalleryPublication {
    const commonProps = getCommonProps(publication)
    const images = publication.photoGallery.images.map(
        imageWithCropsDtoToImageCrop,
    )
    const heroImage = mergeImageDtosToImageSource(...publication.mainImages)
    const content = getContentBlocks(publication)

    return {
        ...commonProps,
        kind: 'gallery',
        heroImage,
        socialTeaser: publication.socialTeaser || undefined,
        socialHead: publication.socialHead || undefined,
        homepageHead: publication.homepageHead,
        teaser: publication.homepageTeaser,
        heading: publication.heading,
        images,
        byline: publication.byline?.text,
        profile: publication.profiles,
        shareUrl: publication._self,
        requiredAccess: publication.requiredAccess,
        content,
        allowCommenting: publication.allowCommenting,
        canonicalUrl:
            publication.canonicalUrl === null
                ? undefined
                : publication.canonicalUrl,
        canonicalTitle:
            publication.canonicalTitle === null
                ? undefined
                : publication.canonicalTitle,
    }
}
