import { TheWestSection } from '@news-mono/common'
import { horizontalDividers, metrics } from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Topic,
} from '@news-mono/web-common'
import { ClassificationV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'
import { getTopicSponsor } from './get-topic-sponsor'
import { topicParallax } from './topic-parallax'

export interface Props {
    topic: Topic
    publicationKind?: 'article' | 'gallery'
    topicSource?: string
    config: BaseClientConfig
    section: TheWestSection
    getAdTargeting: GetRouteAdTargeting
    classification?: ClassificationV4DTO
}

export const getTopicPageRouteInfo = ({
    topic,
    publicationKind,
    topicSource,
    section,
    getAdTargeting,
    classification,
}: Props): PageType<TheWestSection> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const sponsor = getTopicSponsor(topic)
    const isSponsoredCollection = !!sponsor

    const shouldDisplayBreakingNews = () => section === 'default'

    const isGenWestArticle = section === 'genwest'

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        includeFromSectionInMetaDescription: !topic.seoDescription,
        pageMeta: {
            description:
                topic.seoDescription || `The Latest in ${topic.seoTitle}`,
            title: topic.seoTitle || topic.title,
        },
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        ...(shouldDisplayBreakingNews()
                            ? [
                                  layout.component({
                                      type: 'breaking-news',
                                      props: {
                                          dataDefinitionArgs: {
                                              type: 'curation',
                                              name: 'breaking-news',
                                              offset: 0,
                                              pageSize: 1,
                                          },
                                      },
                                  }),
                                  layout.component({
                                      type: 'breaking-news-promo-strap',
                                      props: {
                                          dataDefinitionArgs: {
                                              type: 'curation',
                                              name: 'breaking-news-promo-strap',
                                              offset: 0,
                                              pageSize: 1,
                                          },
                                      },
                                  }),
                              ]
                            : []),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacing: 'sm',
                                breakpoints: {
                                    sm: {
                                        horizontalSpacing: 'outerMargin',
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'breadcrumb',
                                        props: {
                                            topic,
                                            sponsor,
                                            enableHeaderTag: true,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        ...renderRealEstateViewWidget(topic),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                isSponsoredCollection,

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [topic.id],
                                    includeSubTopics: true,
                                    publicationKind,
                                    source: topicSource,
                                    classification,
                                    paging: {
                                        page: 1,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        ...(isGenWestArticle ? [] : topicParallax()),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                hasBackground: true,
                                isSponsoredCollection,
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [topic.id],
                                    includeSubTopics: true,
                                    publicationKind,
                                    source: topicSource,
                                    paging: {
                                        page: 1,
                                        pageOffset: 8,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        ...(isGenWestArticle
                            ? []
                            : billboardLeaderboardMrec('one')),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'md',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                hasBackground: true,
                                isSponsoredCollection,
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,

                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [topic.id],
                                    includeSubTopics: true,
                                    publicationKind,
                                    source: topicSource,
                                    paging: {
                                        page: 1,
                                        pageOffset: 16,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        ...(isGenWestArticle
                            ? []
                            : billboardLeaderboardMrec('two')),
                    ],
                },
            }),
        ],
    }
}

function renderRealEstateViewWidget(topic: Topic) {
    if (
        topic.id.includes('lifestyle/real-estate') ||
        topic.id.includes('business/property')
    ) {
        return [
            layout.component({
                type: 'real-estate-view-widget',
                props: {
                    verticalSpacing: 'unset',
                    vertiicalGutters: ['lg', 'lg'],
                },
            }),
        ]
    }
    return []
}
