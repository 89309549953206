import { Profile as ProfileData } from '@news-mono/web-common'
import React from 'react'
import { YoutubePromoCard } from '../../cards/YoutubePromoCard/YoutubePromoCard'
import {
    StyledHeading,
    StyledHeadingWrap,
    StyledPromoBlock,
    StyledSubHeading,
} from './PromoBlock.styled'

export interface PromoBlockProps {
    profile: ProfileData
}

export function PromoBlock({ profile }: PromoBlockProps) {
    const profilesWithYoutube = ['sarah-ison', 'emma-garlett']

    const tagline = (slug: string) => {
        switch (slug) {
            case 'sarah-ison':
                return 'Politics Made Okay with Sarah Ison'
            case 'emma-garlett':
                return 'Paint It Blak with Emma Garlett'
        }
    }

    if (profilesWithYoutube.indexOf(profile.slug) === -1) return null

    return (
        <StyledPromoBlock>
            <StyledHeadingWrap>
                <h2>
                    <StyledHeading>Watch on Youtube</StyledHeading>
                    <StyledSubHeading>{tagline(profile.slug)}</StyledSubHeading>
                </h2>
            </StyledHeadingWrap>

            <YoutubePromoCard profileType={profile.slug} />
        </StyledPromoBlock>
    )
}
