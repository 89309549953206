import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'

/**
 * A hook which returns information on if a specified component is on the screen.
 */
export function useIsComponentOnScreen(
    offset = '-100%',
    callback?: () => void,
) {
    const componentRef = useRef<HTMLDivElement>(null)
    const [isVisible, setisVisible] = useState(false)

    const { current } = componentRef

    useEffect(() => {
        if (!supportsIntersection) {
            return
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setisVisible(true)
                    callback && throttle(() => callback(), 500)()
                } else {
                    setisVisible(false)
                }
            },
            { rootMargin: `0px 0px ${offset} 0px` },
        )

        if (current) {
            observer.observe(current)
        }

        return () => {
            observer.disconnect()
        }
    }, [current, callback, offset])

    return { componentRef, isVisible }
}

// This is supported in 98 of all browsers.
// Can probably be dropped.
export const supportsIntersection =
    typeof window !== 'undefined' &&
    ('IntersectionObserver' in window ||
        ('IntersectionObserverEntry' in window &&
            'intersectionRatio' in
                (window as any).IntersectionObserverEntry?.prototype))
