import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { IconUpArrow } from '../../icons'
import { IconChevronRight } from '../../icons/IconChevronRight/IconChevronRight'
import { StyledActionLink } from '../../navigation/TheWestHeaderLogin/TheWestHeaderLogin.styled'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    themedValue,
    transition,
} from '../../__styling'
import { StyledLink } from '../TheWestHeader/TheWestHeader.styled'

//top heading stuff
export const StyledEPaperHeading = styled('h1')(({ theme }) => ({
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.serif,
        perthnow: theme.fonts.sansSerifCond,
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        thewest: 400,
        perthnow: 700,
        fallback: undefined,
    }),
    marginRight: calcRem(12),
    marginBottom:
        theme.kind === Product.PerthNow ? theme.margins.sm : theme.margins.md,
    marginTop: theme.kind === Product.PerthNow ? 0 : undefined,
    lineHeight: calcRem(30),
}))

//main banner stuff
export const StyledEPaperBanner = styled('div')<{
    bannerUrls: {
        mobile: any
        tablet: any
        desktop: any
    }
    backgroundGradient?: any
}>(({ theme, bannerUrls, backgroundGradient }) => ({
    height: '135vw',
    background: `url(${bannerUrls.mobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    overflow: 'clip',
    marginBottom: theme.margins.lg,
    paddingTop: '8vw',
    [breakpoint('xxs')]: {
        height: theme.kind === Product.TheWest ? '325px' : '280px',
        background: `${
            backgroundGradient ? `${backgroundGradient}, ` : ''
        }url('${bannerUrls.tablet}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: 0,
        paddingRight:
            theme.kind === Product.PerthNow ? calcRem(160) : undefined,
        paddingLeft: calcRem(30),
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
    [breakpoint('xs')]: {
        paddingRight: 0,
    },
}))

export const StyledEpaperUnsubscribedBanner = styled('div')<{
    bannerUrls: {
        mobile: any
        tablet: any
        desktop: any
    }
    backgroundGradient?: any
}>(({ theme, bannerUrls, backgroundGradient }) => ({
    height: '135vw',
    background: `${backgroundGradient ? `${backgroundGradient}, ` : ''}url('${
        bannerUrls.mobile
    }')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [breakpoint('xxs')]: {
        height: '384px',
        background: `${
            backgroundGradient ? `${backgroundGradient}, ` : ''
        }url('${bannerUrls.tablet}')`,
        alignItems: 'normal',
    },
    [breakpoint('sm')]: {
        background: `${
            backgroundGradient ? `${backgroundGradient}, ` : ''
        }url('${bannerUrls.desktop}')`,
        alignItems: 'normal',
    },
}))

export const StyledEPaperBannerWrapper = styled('div')(({ theme }) => ({
    maxHeight: '325px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: themedValue(theme, {
        thewest: 'black',
        perthnow: 'white',
        fallback: undefined,
    }),
    [breakpoint('xxs')]: {
        display: 'block',
    },
}))
export const StyledEPaperDateTime = styled('h2')(({ theme }) => ({
    fontSize: themedValue(theme, {
        thewest: '9vw',
        perthnow: '11vw',
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        perthnow: theme.fonts.sansSerifCond,
        fallback: undefined,
    }),
    textAlign: 'center',
    filter:
        theme.kind === Product.PerthNow
            ? 'drop-shadow(0.5px 0.5px 1px rgba(27, 38, 46, 0.6))'
            : undefined,
    margin: '0',
    lineHeight: theme.kind === Product.PerthNow ? '10vw' : undefined,
    [breakpoint('xxs')]: {
        textAlign: 'left',
        lineHeight: theme.kind === Product.PerthNow ? calcRem(40) : undefined,
        fontSize: calcRem(40),
        marginTop: calcRem(20),
    },
}))
export const StyledEPaperSubheading = styled('div')(({ theme }) => ({
    fontSize: themedValue(theme, {
        thewest: '7vw',
        perthnow: '6vw',
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        thewest: 'normal',
        perthnow: 500,
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        perthnow: theme.fonts.sansSerif,
        fallback: undefined,
    }),
    filter:
        theme.kind === Product.PerthNow
            ? 'drop-shadow(0.5px 0.5px 1px rgba(27, 38, 46, 0.6))'
            : undefined,
    margin: '0',
    marginTop: themedValue(theme, {
        thewest: calcRem(4),
        perthnow: 0,
        fallback: undefined,
    }),
    marginBottom: '50px',
    [breakpoint('xxs')]: {
        marginTop: themedValue(theme, {
            thewest: calcRem(-5),
            perthnow: calcRem(6),
            fallback: undefined,
        }),
        marginBottom: 0,
        fontSize: themedValue(theme, {
            thewest: calcRem(28),
            perthnow: calcRem(23),
            fallback: undefined,
        }),
    },
}))
export const StyledEPaperButton = styled(StyledLink)(({ theme }) => ({
    textTransform: 'none',
    width: '85%',
    height: '16vw',
    fontSize: '8vw',
    margin: 0,
    marginTop: calcRem(300),
    paddingTop: '3.5vw',
    position: 'absolute',
    bottom: '20px',
    fontFamily: theme.fonts.sansSerif,
    color: theme.colors.actions.button.primary.text.active,
    backgroundColor: theme.sectionValues.primaryColor,
    borderRadius: calcRem(2),
    textDecoration: 'none',
    fontWeight: 700,
    textAlign: 'center',
    filter:
        theme.kind === Product.PerthNow
            ? 'drop-shadow(0.5px 0.5px 1px rgba(27, 38, 46, 0.6))'
            : undefined,
    '&:hover': {
        textDecoration: 'none',
        filter: 'brightness(85%)',
        color: 'white',
        border: 'none',
    },
    '&:focus': {
        backgroundColor: theme.colors.actions.button.primary.background.focus,
        textDecoration: 'none',
        color: 'white',
        border: 'none',
    },
    [breakpoint('xxs')]: {
        display: 'block',
        position: 'relative',
        marginTop: themedValue(theme, {
            thewest: calcRem(50),
            perthnow: calcRem(35),
            fallback: undefined,
        }),
        fontSize: themedValue(theme, {
            thewest: calcRem(28),
            perthnow: calcRem(21),
            fallback: undefined,
        }),
        width: themedValue(theme, {
            thewest: calcRem(230),
            perthnow: calcRem(180),
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '70px',
            perthnow: calcRem(55),
            fallback: undefined,
        }),
        paddingTop: themedValue(theme, {
            thewest: calcRem(20),
            perthnow: calcRem(15),
            fallback: undefined,
        }),
    },

    [breakpoint('xs')]: {
        marginTop: themedValue(theme, {
            perthnow: calcRem(50),
            fallback: undefined,
        }),
    },
}))

export const StyledEpaperUnsubscriberContainer = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}))

export const StyledEpaperSubscribeButton = styled(StyledLink)(({ theme }) => ({
    textTransform: 'none',
    fontSize: calcRem(18),
    width: '85%',
    alignItems: 'center',
    padding: calcRem(20),
    marginTop: 'auto',
    marginBottom: '7.5%',
    lineHeight: calcRem(20),
    fontWeight: 700,

    background: theme.sectionValues.primaryColor,
    color: colors.white,
    fontFamily: fonts.thewest.sansSerif,
    borderRadius: '2px',
    border: 'none',
    flex: '0 0 auto',
    ...transition({ properties: ['background', 'color'] }),

    '&:focus': {
        background: colors.thewest.greyKoala,
        textDecoration: 'none',
    },

    '&:hover': {
        background: colors.thewest.greyGorilla,
        color: colors.white,
        textDecoration: 'none',
    },

    display: 'flex',
    justifyContent: 'center',

    [breakpoint('xxs')]: {
        fontSize: calcRem(14),
    },
    [breakpoint('xxs')]: {
        textTransform: 'none',
        fontSize: calcRem(24),
        width: calcRem(230),
        marginLeft: calcRem(48),
        marginTop: calcRem(48),
        marginBottom: 0,
    },
}))

export const StyledEpaperSubscribeText = styled('text')<{}>(({ theme }) => ({
    display: 'block',
    fontSize: calcRem(26),
    fontFamily: theme.fonts.serif,
    fontWeight: 400,
    color: theme.colors.palette.white,
    textAlign: 'center',
    marginTop: calcRem(32),

    [breakpoint('xxs')]: {
        marginLeft: calcRem(48),
        marginTop: calcRem(48),
        textAlign: 'left',
    },
}))

export const StyledEpaperSubscribeUnderlineText = styled(
    StyledEpaperSubscribeText,
)(() => ({
    fontSize: calcRem(48),
    fontWeight: 700,
    marginTop: calcRem(8),

    '&:after': {
        content: '""',
        display: 'block',
        borderRadius: calcRem(5),
        borderTop: '4px solid white',
        width: calcRem(90),
        [breakpoint('xs')]: {
            width: calcRem(140),
        },
    },

    [breakpoint('xxs')]: {
        marginTop: calcRem(8),
    },
}))

export const StyledEPaperPNButton = styled(StyledEPaperButton)<{
    color: string
}>(({ color }) => ({
    backgroundColor: color,
    color: 'white',
    border: 'none',
    borderRadius: calcRem(2),
    '&:hover': {
        backgroundColor: color,
        filter: 'brightness(85%)',
        color: 'white',
        border: 'none',
    },
    '&:focus': {
        backgroundColor: color,
        filter: 'brightness(85%)',
        color: 'white',
        border: 'none',
    },
}))

export const StyledEPaperChevon = styled(IconChevronRight)({
    marginLeft: calcRem(17),
    width: calcRem(15),
    height: calcRem(20),
    color: 'inherit',
    fill: 'currentColor',
    transform: 'translateY(2px)',
    display: 'inline',
})
//paper images for banner
export const StyledEPaperCoverLeft = styled('img')(({ theme }) => ({
    right: '50%',
    top: '45vw',
    height: '64vw',
    position: 'absolute',
    zIndex: 0,
    transform: 'rotate(-10deg) translate(50%, 0)',
    userSelect: 'none',
    borderRadius: calcRem(5),
    border: `3vw solid #2B2D2E`,
    outline: 'solid #74787A',
    objectPosition: 'right',
    [breakpoint('xxs')]: {
        border: `${calcRem(11)} solid #2B2D2E`,
        right: themedValue(theme, {
            thewest: '-115px',
            perthnow: '-65px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '105px',
            perthnow: '70px',
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '300px',
            perthnow: '265px',
            fallback: undefined,
        }),
        transform: 'rotate(-20deg)',
    },
    [breakpoint('xs')]: {
        right: themedValue(theme, {
            thewest: '70px',
            perthnow: '-15px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '108px',
            perthnow: '130px',
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '300px',
            perthnow: '340px',
            fallback: undefined,
        }),
        transform: 'rotate(-20deg)',
    },
    [breakpoint('md')]: {
        right: themedValue(theme, {
            thewest: '150px',
            perthnow: '85px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '50px',
            perthnow: '55px',
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '400px',
            perthnow: '320px',
            fallback: undefined,
        }),
        borderRadius: calcRem(5),
    },
}))

export const StyledEPaperCoverRight = styled('img')(({ theme }) => ({
    right: '37%',
    top: '57vw',
    height: '48vw',
    position: 'absolute',
    transform: 'rotate(5deg) translate(50%, 0)',
    userSelect: 'none',
    borderRadius: calcRem(5),
    border: `2.5vw solid #2B2D2E`,
    borderBottom: `5vw solid #2B2D2E`,
    outline: 'solid #74787A',
    objectPosition: 'right',
    [breakpoint('xxs')]: {
        border: `${calcRem(11)} solid #2B2D2E`,
        borderBottom: `${calcRem(23)} solid #2B2D2E`,
        right: themedValue(theme, {
            thewest: '-100px',
            perthnow: '-80px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '175px',
            perthnow: '130px',
            fallback: undefined,
        }),
        height: '215px',
        transform: themedValue(theme, {
            thewest: 'rotate(-5deg)',
            perthnow: 'rotate(3deg)',
            fallback: undefined,
        }),
    },
    [breakpoint('xs')]: {
        right: themedValue(theme, {
            thewest: '25px',
            perthnow: '-40px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '150px',
            perthnow: '180px',
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '300px',
            perthnow: '300px',
            fallback: undefined,
        }),
        transform: 'rotate(-5deg)',
    },
    [breakpoint('md')]: {
        right: themedValue(theme, {
            thewest: '50px',
            perthnow: '30px',
            fallback: undefined,
        }),
        top: themedValue(theme, {
            thewest: '155px',
            perthnow: '120px',
            fallback: undefined,
        }),
        height: themedValue(theme, {
            thewest: '400px',
            perthnow: '250px',
            fallback: undefined,
        }),
        transform: themedValue(theme, {
            perthnow: 'none',
            fallback: undefined,
        }),
    },
}))

//TW only, extra coffee table images
export const StyledEPaperCoffee = styled('img')(({ theme }) => ({
    right: '13%',
    top: '34vw',
    height: '24vw',
    position: 'absolute',
    transform: 'rotate(13deg) translate(50%, 0)',
    userSelect: 'none',
    [breakpoint('xxs')]: {
        display: 'none',
    },
    [breakpoint('xs')]: {
        display: 'block',
        right: '-10px',
        top: '45px',
        height: '100px',
        transform: 'rotate(-18deg)',
    },
    [breakpoint('md')]: {
        right: '-10px',
        top: 0,
        height: '150px',
    },
}))

//PN only, return to portal page link
export const StyledEPaperLink = styled(StyledActionLink)(({ theme }) => ({
    color: colors.perthnow.greyMoonstone,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(17),
    paddingTop: 0,
    paddingBottom: calcRem(16),
    '&:hover': {
        filter: 'brightness(85%)',
        color: colors.perthnow.greyMoonstone,
        textDecoration: 'none',
    },
    '&:focus': {
        filter: 'brightness(85%)',
        color: colors.perthnow.greyMoonstone,
        textDecoration: 'none',
    },
}))

export const StyledEPaperArrow = styled(IconUpArrow)({
    width: calcRem(15),
    height: calcRem(20),
    marginRight: calcRem(6),
    color: 'inherit',
    fill: 'currentColor',
    transform: 'rotate(-90deg)',
    display: 'inline',
})
