import { ColorsType } from '../types'

const Palette = {
    transparent: 'transparent',
    black: '#000',
    blackShade: {
        100: 'rgba(0,0,0,1)',
        85: '#252525',
        53: '#777777',
        50: 'rgba(0,0,0,0.50)',
        40: '#999999',
        14: '#DCDCDC',
        20: '#CCCCCC',
        10: '#E5E5E5',
        5: '#F3F3F3',
        4: '#F6F6F6',
        1: '#FDFDFD',
    },
    white: '#fff',
    perthNowRed: '#d42626',
    redCrisis: {
        650: '#A81A1A',
        600: '#C12323',
        50: '#FBE9E9',
    },
    greyCoal: '#252525', // -> blackShade[85],
    greyMoonstone: '#777', // -> blackShade[53]
    greyStone: '#999', // -> blackShade[40]
    greyMarble: '#ccc', // -> blackShade[20]
    greyPyrite: '#e5e5e5', // -> blackShade[10]
    greyGlass: '#f3f3f3', // -> blackShade[5]
    greyBasalt: '#1b262e',
    greySlate: '#32373b',
    greyDavy: '#535556',
    greyFlint: '#3c525c',
    greyShale: '#8798a1',
    greyPebble: '#97a5ad',
    greyWeak: '#666869',
    greyDivider: '#DCDCDC',
    blueKyanite: '#066cb6',
    blueSapphire: '#0771be',
    pinkThulite: '#dd1466',
    pinkThuliteShade: {
        600: '#C9125D',
        50: '#FCE8F0',
    },
    pinkGarnet: '#c1125b',
    fadedPinkThulite: 'rgba(221,20,102,0.1)',
    greenJade: '#418702',
    greenJadeShade: {
        600: '#3B7B02',
        50: '#ECF3E6',
    },
    greenPeople: '#3B7B02',
    greenTourmaline: '#38674',
    blueCerulean: '#009FDA',
    orangeCitrine: '#c74705',
    purpleAmethyst: '#a762a6',
    tealTopaz: '#0b8478',
    grandFinal: {
        white: '#fff',
        lightBlue: '#85C3F5',
        lightBlueBorderTransparent: 'rgba(133, 195, 245, 0.3)',
        darkBlue: '#00529B',
        gradientBlue: 'linear-gradient(180deg, #01285E 0%, #004C8E 100%)',
        transparent: 'transparent',
    },
    federalElection: {
        federalElectionBorders: '#D2D5D6',
    },
    queensLegacy: {
        maroon: '#750049',
    },
    streamerScreamer: {
        gradientBrown: 'linear-gradient(135deg, #3D1C19 40%, #252525 80%)',
        orange: '#E24427',
        red: '#C8290C',
    },
    AFL: {
        pinkThuliteLight: '#EB9ABB',
        pinkThulite10: '#F1DDE5',
        neutral: '#888D90',
        darkViolet: '#512D6D',
    },
    BestAustralianShortFilm: {
        electricGreen: '#D7F24B',
        coalBlack: '#302E26',
    },
} as const

export type PerthNowPalette = typeof Palette

export type PerthNowColourMap = ColorsType<PerthNowPalette>

export const colors: PerthNowColourMap = {
    brand: Palette.perthNowRed,
    section: Palette.white,
    background: {
        primary: Palette.white,
        secondary: Palette.greyBasalt,
        tertiary: Palette.greyGlass,
    },
    palette: Palette,
    text: {
        primary: Palette.greyCoal,
        secondary: Palette.greyMoonstone,
        tertiary: Palette.white,
        heading: Palette.greyCoal,
        weak: Palette.greyWeak,
    },
    actions: {
        link: {
            default: Palette.greyCoal,
            hover: Palette.blueSapphire,
            focus: Palette.blueKyanite,
            active: Palette.blueKyanite,
        },
        button: {
            primary: {
                text: {
                    default: Palette.white,
                    hover: Palette.white,
                    focus: Palette.white,
                    active: Palette.white,
                },
                background: {
                    default: Palette.pinkThulite,
                    hover: Palette.blueKyanite,
                    focus: Palette.blueKyanite,
                    active: Palette.blueKyanite,
                },
                border: {
                    default: Palette.pinkThulite,
                    hover: Palette.blueKyanite,
                    focus: Palette.blueKyanite,
                    active: Palette.blueKyanite,
                },
            },
            secondary: {
                text: {
                    default: Palette.white,
                    hover: Palette.white,
                    focus: Palette.white,
                    active: Palette.white,
                },
                background: {
                    default: Palette.pinkThulite,
                    hover: Palette.blueKyanite,
                    focus: Palette.blueKyanite,
                    active: Palette.blueKyanite,
                },
                border: {
                    default: Palette.pinkThulite,
                    hover: Palette.blueKyanite,
                    focus: Palette.blueKyanite,
                    active: Palette.blueKyanite,
                },
            },
        },
    },
    borders: {
        primary: Palette.greyPyrite,
        secondary: Palette.greyMarble,
    },
    components: {
        playOfTheDay: {
            background: Palette.pinkThulite,
            text: Palette.white,
            banner: {
                background: Palette.pinkThulite,
            },
        },
        videoHub: {
            borderBottom: Palette.greyMarble,
        },
        emailPreferenceCentre: {
            background: {
                mute: Palette.white,
            },
            text: {
                default: Palette.greyCoal,
                secondary: Palette.greyCoal,
                mute: Palette.greyMoonstone,
                danger: Palette.pinkThulite,
            },
            link: {
                default: Palette.blueKyanite,
            },
            button: {
                primary: {
                    text: {
                        default: Palette.white,
                        hover: Palette.pinkThulite,
                        focus: Palette.pinkThulite,
                        active: Palette.pinkThulite,
                        disabled: Palette.pinkThulite,
                    },
                    background: {
                        default: Palette.perthNowRed,
                        hover: Palette.pinkThulite,
                        focus: Palette.pinkThulite,
                        active: Palette.pinkThulite,
                        disabled: Palette.greyMarble,
                    },
                    border: {
                        default: Palette.pinkThulite,
                        hover: Palette.white,
                        focus: Palette.greyMarble,
                        active: Palette.greyMarble,
                        disabled: Palette.greyMarble,
                    },
                },
                secondary: {
                    text: {
                        default: Palette.pinkThulite,
                        hover: Palette.pinkThulite,
                        focus: Palette.pinkThulite,
                        active: Palette.pinkThulite,
                        disabled: Palette.pinkThulite,
                    },
                    background: {
                        default: Palette.white,
                        hover: Palette.pinkThulite,
                        focus: Palette.pinkThulite,
                        active: Palette.pinkThulite,
                        disabled: Palette.greyMarble,
                    },
                    border: {
                        default: Palette.pinkThulite,
                        hover: Palette.white,
                        focus: Palette.greyMarble,
                        active: Palette.greyMarble,
                        disabled: Palette.greyMarble,
                    },
                },
            },
            borders: {
                primary: Palette.greyPebble,
                divider: Palette.greyPebble,
                mute: Palette.greyPyrite,
            },
        },
    },
}
