import styled from '@emotion/styled'
import { OlympicsCollectionArticleType } from './OlympicsCollectionArticle'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { CardMedia } from '../../cards/CardMedia/CardMedia'
import { styledMediaClassName } from '../../cards/CardMedia/StyledMedia.styled'
import { withClass } from '@news-mono/web-common'
import { StyledKicker } from '../../cards/Kicker/Kicker.styled'

const HeadlineClassName = 'OlympicsArticleHeadline'

export interface OlympicsCollectionArticleProps {
    articleType: OlympicsCollectionArticleType
    isMiniCollection?: boolean
}

/** Extra styling for the 'overall' component wrapper for the article link */
export const StyledLink = styled(CardTextInteractions)(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        textDecoration: 'none',
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',

        // Style the hover & focus of the card
        ['&:is(:hover, :focus)']: {
            // Style the image hovering
            [`& .${styledMediaClassName}`]: {
                '&::after': {
                    borderColor: colors.olympics.orange,
                },
            },
            [`& .${HeadlineClassName}`]: {
                color: colors.olympics.orange,
            },
            [`& ${StyledKicker} svg`]: {
                fill: colors.olympics.orange,
            },
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        height: '100%',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
        },

        // Make sure the ::after is styled to 50% opacity
        [`& .${styledMediaClassName}`]: {
            '&::after': {
                opacity: '50%',
            },
        },
    },

    // THE WEST STYLING
    theme.kind === 'thewest' && {
        // Style the kicker inside the links
        span: {
            color: colors.olympics.kicker.thewest,
            textDecoration: 'none !important',
        },
    },
])
StyledLink.displayName = 'StyledLink'

/** Highest level article div, used as a 'parent' to store widths, heights, and backgrounds */
export const StyledOlympicsArticle = styled(
    'div',
)<OlympicsCollectionArticleProps>(
    ({ theme, articleType, isMiniCollection = false }) => [
        // THE WEST & SHARED STYLING
        {
            display: 'flex',

            // Mobile styling
            width: '100%',
            height: articleType === 'article' ? '100%' : 'fit-content',
            borderRight: 'none',
            borderLeft: 'none',

            paddingLeft: 0,
            paddingRight: 0,
            margin: 0,

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                width:
                    articleType === 'articleWithTeaserAndImageLeft' ||
                    articleType === 'articleWithTeaserAndImageRight'
                        ? '25%'
                        : undefined,
                height:
                    articleType === 'articleWithNoTeaser' ||
                    articleType === 'articleWithTeaserAndImageLeft' ||
                    articleType === 'articleWithTeaserAndImageRight'
                        ? 'auto'
                        : undefined,

                // ---- Collection Borders ----

                borderRight:
                    articleType === 'articleWithTeaserAndImageLeft'
                        ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                        : undefined,
                borderLeft:
                    articleType === 'articleWithTeaserAndImageRight' ||
                    articleType === 'articleWithNoTeaser'
                        ? `${calcRem(0.5)} solid ${colors.olympics.border}`
                        : undefined,

                // ---- Side Padding / Margins ----

                paddingLeft:
                    articleType === 'articleWithNoTeaser' ||
                    articleType === 'articleWithTeaserAndImageRight'
                        ? calcRem(8)
                        : undefined,
                paddingRight:
                    articleType === 'articleWithTeaserAndImageLeft'
                        ? calcRem(12)
                        : undefined,
                margin:
                    articleType === 'articleWithTeaserAndImageLeft' ||
                    articleType === 'articleWithTeaserAndImageRight'
                        ? calcRem(12, 0, 12, 0)
                        : undefined,
            },
            // Laptop or bigger styling
            [breakpoint('md')]: {
                height:
                    articleType === 'articleWithNoTeaser'
                        ? calcRem(282)
                        : articleType === 'articleWithTeaserAndImageLeft' ||
                          articleType === 'articleWithTeaserAndImageRight'
                        ? 'auto'
                        : undefined,
            },
        },

        // PERTH NOW STYLING
        theme.kind === 'perthnow' && {
            backgroundColor: colors.olympics.background,

            // Mobile styling
            height: 'fit-content',

            // Large mobile styling
            [breakpoint('xxs')]: {
                height: calcRem(184),
            },

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                height: isMiniCollection ? '100%' : '50%',

                borderRight: 'none',
                borderLeft: 'none',

                paddingLeft: 0,
                paddingRight: 0,
            },
            // Laptop or bigger styling
            [breakpoint('md')]: {
                height:
                    articleType === 'article'
                        ? isMiniCollection
                            ? '100%'
                            : '50%'
                        : undefined,
            },
        },
    ],
)

/** Used as an internal container, underneath the Parent -> StyledLink, used to sort the actual content */
export const StyledOlympicsInnerArticle = styled(
    'div',
)<OlympicsCollectionArticleProps>(({ theme, articleType }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'inline-flex',
        width: '100%',

        // Mobile styling
        flexDirection: 'row',
        padding: calcRem(12, 0),
        borderBottom: `${calcRem(1)} solid ${colors.olympics.border}`,

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            flexDirection: 'column',
            padding: calcRem(0),

            borderBottom:
                articleType === 'articleWithNoTeaser'
                    ? `${calcRem(1)} solid ${colors.olympics.border}`
                    : 'none',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        height: 'fit-content',
        borderBottom: 'none',
        padding: calcRem(12, 12, 0, 12),
        gap: calcRem(12),

        // Large mobile styling
        [breakpoint('xxs')]: {
            height: '100%',
            padding: calcRem(12),
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
            flexDirection: 'row',
            padding: calcRem(8, 8, 0, 8),
            gap: 0,

            borderBottom: 'none',
        },
    },
])

/** Styling for the article image, needed to make sure it wraps appropriately. */
export const StyledOlympicsArticleImage = styled(
    CardMedia,
)<OlympicsCollectionArticleProps>(({ theme, articleType }) => [
    // THE WEST & SHARED STYLING
    {
        height: 'auto',

        // Mobile styling
        width: '40%',
        marginRight: calcRem(8),
        marginBottom: 'auto',

        display: 'block',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            display: articleType !== 'article' ? 'block' : 'none',
            width: '100%',
            margin: 0,
        },

        '& [class*="image"]': {
            aspectRatio: 'auto',

            '& img': {
                width: '100%',
                height: 'auto',
            },
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        // Mobile styling
        height: 'fit-content',
        marginRight: 0,
        marginBottom: 0,

        // Large mobile styling
        [breakpoint('xxs')]: {
            marginBottom: 'auto',
            height: '100%',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            display: 'block',
            width: '40%',
            height: 'fit-content',
        },
    },
])

/** Used to as a wrapper container for all the text aspects of the article, to maintain appropriate content split width rules. */
export const StyledOlympicsArticleText = styled(
    'div',
)<OlympicsCollectionArticleProps>(({ theme, articleType }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'inline-flex',
        flexDirection: 'column',
        cursor: 'pointer',

        height: '100%',
        width: '100%',

        // Mobile styling
        marginTop: 0,

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            marginTop:
                articleType === 'articleWithNoTeaser'
                    ? calcRem(16)
                    : articleType === 'article'
                    ? undefined
                    : calcRem(7),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        height: 'fit-content',

        // Large mobile devices
        [breakpoint('xxs')]: {
            height: '100%',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
            marginTop: 0,
            marginLeft: calcRem(8),
        },
    },
])

/** Styling for the article kicker, this is mainly just the parent topic */
export const StyledOlympicsArticleKicker = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',

        width: '100%',
        alignSelf: 'stretch',

        // Mobile styling
        height: 'fit-content',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: calcRem(16),
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        height: 'fit-content',

        // Mobile styling
        marginRight: 'auto',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            height: 'fit-content',
            marginRight: 0,
        },
    },
])

/** Styling for the article headline, this uses the `article.shortHeadline` as the headline, rather than the `article.headline`. */
export const StyledOlympicsArticleHeadline = withClass(HeadlineClassName)(
    styled('h2')(({ theme }) => [
        // THE WEST & SHARED STYLING
        {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            padding: calcRem(8, 0, 0),
            width: '100%',
            margin: 0,

            color: colors.olympics.text,
            fontFamily: theme.fonts.serif,
            fontSize: calcRem(16),
            fontWeight: 500,
            lineHeight: calcRem(18),
        },

        // PERTH NOW STYLING
        theme.kind === 'perthnow' && {
            fontFamily: theme.fonts.sansSerifCond,
            fontWeight: 700,
            fontSize: calcRem(22),
            lineHeight: calcRem(24),
            padding: calcRem(0),

            // Mobile styling
            marginRight: 'auto',

            // Tablet or bigger styling
            [breakpoint('sm')]: {
                marginRight: 0,
            },
        },
    ]),
)

/** Styling for the article teaser, a short summary of what the article is about. PerthNow consists of two teasers in order
 *  to wrap nicely with different widths, that's why we have a `wrappedTeaser` parameter.
 */
export const StyledOlympicsArticleTeaser = styled('p')<
    {
        wrappedTeaser?: boolean
    } & OlympicsCollectionArticleProps
>(({ theme, wrappedTeaser = true, articleType }) => [
    // THE WEST & SHARED STYLING
    {
        position: 'relative',

        padding: calcRem(8, 0, 0, 0),
        width: '100%',
        margin: 0,

        color: colors.olympics.text,
        fontFamily: theme.fonts.serif,
        fontSize: calcRem(12),
        fontWeight: 400,
        lineHeight: calcRem(16),

        // Mobile styling
        display: 'flex',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            display: articleType === 'articleWithNoTeaser' ? 'none' : 'flex',
        },
    },

    // PERTH NOW STYLING
    theme.kind === 'perthnow' && {
        height: '100%',
        marginBottom: 'auto',

        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(14),

        // Mobile styling
        padding: calcRem(4, 12, 12, 12),
        display: wrappedTeaser ? 'none' : 'flex',

        // Large mobile styling
        [breakpoint('xxs')]: {
            padding: calcRem(4, 0, 0, 0),
            display: wrappedTeaser ? 'flex' : 'none',
        },

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            display: wrappedTeaser ? 'none' : 'flex',
            padding: calcRem(4, 8, 8, 8),
        },
    },
])

/** Styling for the article byline, only utilised on TheWest, as PerthNow doesn't show bylines */
export const StyledOlympicsArticleByline = styled('div')(({ theme }) => [
    // THE WEST & SHARED STYLING
    {
        position: 'relative',
        padding: calcRem(16, 0, 2, 0),
        marginTop: 'auto',

        // Mobile styling
        display: 'none',

        // Tablet or bigger styling
        [breakpoint('sm')]: {
            display: 'flex',
        },
    },
])
