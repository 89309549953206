import { CSSObject } from '@emotion/css'
import { ThemeConfig } from '..'
import { BreakpointKeys } from '../settings'
import { breakpoint } from './breakpoint'

export type BreakpointState<T extends ThemeConfig, P> =
    | Partial<Record<T['breakpoints'], P>>
    | { default: P }

/**
 * When provided a BreakpointState, and a builder function which takes the
 * BreakpointState's inner object that returns CSS styles, it will generate
 * all the relevant media-queries for the states.
 *
 * Do not destructure this object into other CSS styles, as the media-queries
 * will override other ones in the same place. Instead use the array syntax
 * to return multiple styles, including this one to correctly merge them.
 */
export const breakpointSwitch = <P>(
    states: BreakpointState<ThemeConfig, P>,
    builder: (values: P, breakpoint: BreakpointKeys) => CSSObject,
) => {
    const breakpointStates = Object.entries(states)

    let cssResult: CSSObject = {}
    for (const [breakpointKey, value] of breakpointStates) {
        if (breakpointKey === 'default') {
            // Blend default values into base-level CSS.
            cssResult = {
                ...cssResult,
                ...builder(value, breakpointKey as BreakpointKeys),
            }
        } else {
            // Set breakpoint values at their respective breakpoints.
            cssResult[breakpoint(breakpointKey as BreakpointKeys)] = builder(
                value,
                breakpointKey as BreakpointKeys,
            )
        }
    }
    return cssResult
}
