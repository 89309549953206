import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export interface ContentSeparatorProps {
    verticalBottomSpacing: keyof ThemeMargins
    verticalTopSpacing?: keyof ThemeMargins
}
export const ContentSeparator = styled('hr')<ContentSeparatorProps>(
    ({ verticalTopSpacing, verticalBottomSpacing, theme }) => ({
        border: 0, // needed to reset some hr global styling
        margin: 0,
        borderTop: themedValue(theme, {
            thewest: `1px solid ${colors.thewest.greyDolphin}`,
            fallback: `1px solid ${colors.black}`,
        }),
        marginBottom:
            verticalBottomSpacing &&
            calcRem(theme.margins[verticalBottomSpacing]),
        marginTop:
            verticalTopSpacing && calcRem(theme.margins[verticalTopSpacing]),
    }),
)
