import React from 'react'

export interface IconCrossProps {
    className?: string
}

export const IconCross: React.FC<IconCrossProps> = ({ className, ...rest }) => (
    <svg
        {...rest}
        className={className}
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.133 7l5.091 5.091h-.001a.8.8 0 0 1-1.132 1.132L7 8.132l-5.091 5.091A.8.8 0 0 1 .777 12.09L5.867 7 .778 1.909A.8.8 0 1 1 1.91.777L7 5.867l5.091-5.09a.8.8 0 1 1 1.132 1.132l-5.09 5.09z" />
    </svg>
)
IconCross.displayName = 'IconCross'
