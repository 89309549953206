import { useTheme } from '@emotion/react'
import {
    createCardClickedEvent,
    PublicationCardItem,
    requiredAccessValue,
    toLinkWithHint,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../content/Picture/responsive'
import { CommonCardProps } from '../CardItem.Props'
import { CardMedia } from '../CardMedia/CardMedia'
import { IconTypes, KickerMode } from '../Kicker/Kicker'
import {
    StyledCardText,
    StyledCardTextContainer,
    StyledLink,
    StyledPremiumIcon,
    StyledProfileCard,
} from './ProfileCard.styled'

export interface ProfileCardProps extends CommonCardProps, ResponsiveContainer {
    cardNumber: number
    innerRef?: React.RefObject<any>
    item: MaybeLoaded<PublicationCardItem>
    kickerMode?: KickerMode
    kickerIcons?: Array<IconTypes>
}

export const ProfileCard: React.FC<ProfileCardProps> = (props) => {
    const theme = useTheme()

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalProfileCard',
                'ProfileCard',
                props.cardNumber,
            ),
        )
    }

    const { item, onEvent } = props
    const requiredAccess = !item.loaded ? undefined : item.result.requiredAccess

    return (
        <StyledProfileCard>
            <StyledLink to={toLinkWithHint(item)} onClick={linkClicked}>
                {requiredAccessValue(requiredAccess, {
                    subscriber: <StyledPremiumIcon />,
                    anonymous: undefined,
                })}
                <CardMedia
                    item={item}
                    onEvent={onEvent}
                    mediaMode={'edgeToEdge'}
                    imageWidths={applyContainerRatio(
                        props.containerWidthRatios,
                        {
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: `${theme.siteMetrics.mainContentWidth}px`,
                            fallbackWidth: theme.siteMetrics.mainContentWidth,
                        },
                    )}
                    displayNoScriptImage={true}
                />
                <StyledCardTextContainer>
                    <StyledCardText
                        fontScale={1}
                        item={item}
                        onEvent={onEvent}
                        teaserMode="hidden"
                        kickerMode={{
                            kickerType: 'block',
                            icons: props.kickerIcons ? props.kickerIcons : [],
                        }}
                    />
                </StyledCardTextContainer>
            </StyledLink>
        </StyledProfileCard>
    )
}
ProfileCard.displayName = 'ProfileCard'
