import React from 'react'
import { StyledHeadline } from '../../publication/Headline/Headline.styled'

export interface HeadlineProps {
    text: string
    className?: string
}

export const Headline: React.FC<HeadlineProps> = ({ text, className }) => (
    <StyledHeadline itemProp="headline" className={className}>
        {text}
    </StyledHeadline>
)
Headline.displayName = 'Headline'
