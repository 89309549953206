import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AdUnitWrapper } from '../../../advertising/AdUnit/AdUnitWrapper'
import { AdUnitInformation } from '../../../advertising/advertising.routing'
import { LazyTheWestLivePlayerPage } from './TheWestLivePlayerPage.lazy'

export interface TheWestLivePlayerPageRouteProps {
    adUnit?: AdUnitInformation['data']
    adUnitMobile?: AdUnitInformation['data']
    twlLogoAdUnit?: AdUnitInformation['data']
}

export const TheWestLivePlayerPageRegistration = createRegisterableComponent(
    'the-west-live-player-page',
    (props: TheWestLivePlayerPageRouteProps, services) => {
        const shareUrl = services.config.publicUrl + services.location.pathname
        const adUnit = props.adUnit && (
            <AdUnitWrapper
                {...props.adUnit}
                adState={services.adState}
                unitId={props.adUnit.slot.id}
                onEvent={services.onEvent}
                adType={'inline'}
            />
        )
        const adUnitMobile = props.adUnitMobile && (
            <AdUnitWrapper
                {...props.adUnitMobile}
                adState={services.adState}
                unitId={props.adUnitMobile.slot.id}
                onEvent={services.onEvent}
                adType={'inline'}
            />
        )
        const twlLogoAdUnit = props.twlLogoAdUnit && (
            <AdUnitWrapper
                {...props.twlLogoAdUnit}
                adState={services.adState}
                unitId={props.twlLogoAdUnit.slot.id}
                onEvent={services.onEvent}
                padding={[0, 0]}
                adType={'inline'}
            />
        )
        return (
            <LazyTheWestLivePlayerPage
                shareUrl={shareUrl}
                onEvent={services.onEvent}
                showSponsorLogo={true} // Sponsor logo for west live player page
                adUnit={adUnit}
                adUnitMobile={adUnitMobile}
                twlLogoAdUnit={twlLogoAdUnit}
            />
        )
    },
)
