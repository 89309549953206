import { KnownRatio } from '@west-australian-newspapers/publication-types'

export const cropBreakpoints = ['xxs', 'sm', 'lg'] as const
export type CropBreakpoint = typeof cropBreakpoints[number]

export const isCropBreakpoint = (
    breakpoint: string,
): breakpoint is CropBreakpoint =>
    cropBreakpoints.includes(breakpoint as CropBreakpoint)

/**
 * This takes the crop breakpoints and makes them optional keys all returning T as the type
 *
 * e.g
 * {
 *   sm?: T,
 *   lg?: T,
 *   default: T
 * }
 */
export type CropBreakpointSet<T> = Partial<Record<CropBreakpoint, T>> & {
    default: T
}

export interface ImageRatio {
    width: number
    height: number
}

export interface ImageCrop {
    reference: string
    width: number
    height: number
    ratio: ImageRatio
}

export interface ImageCrops {
    original?: ImageCrop
    '4:3'?: ImageCrop
    '16:9'?: ImageCrop
    '161:229'?: ImageCrop
    '316:421'?: ImageCrop
}

export interface ImageSource {
    altText: string | undefined
    crops: ImageCrops
    userSelectedCrop?: KnownRatio
}

export interface ImageSourceWithCaptions extends ImageSource {
    captionText: string
    byline?: string
    credit?: string
}

export type FixedRatio = keyof ImageCrops

// These are used in the route definition and allow you to specify an array of fixed ratio's per breakpoint
export type BreakpointCrops<T = ImageCrop[]> = CropBreakpointSet<T>

// Breakpoint ratio per breakpoint for an image
export type BreakpointRatios = BreakpointCrops<FixedRatio>

// Used by card media to support fallbacks
export type CardBreakpointRatios = BreakpointCrops<FixedRatio | FixedRatio[]>

// This type is used after the getCrops function is called, narrowing down a fixed set of ratios per breakpoint to a single image crop
export type ProcessedBreakpointCrops = BreakpointCrops<ImageCrop>
