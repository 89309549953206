import { Product } from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../../__product'
import {
    StyledCommentsIcon,
    StyledCommentsWrapper,
    StyledPNCommentsIcon,
    StyledTextLink,
} from './CommentButton.styled'

interface CommentButtonProps {
    isDarkBackground?: boolean
    customLink?: string
}

export const CommentButton: React.FC<CommentButtonProps> = ({
    isDarkBackground,
    customLink,
}) => {
    const product = useProduct()
    const isPerthNow = product === Product.PerthNow

    return (
        <StyledCommentsWrapper>
            {isPerthNow ? (
                <StyledPNCommentsIcon isNeutralGrey={isDarkBackground} />
            ) : (
                <StyledCommentsIcon />
            )}

            <StyledTextLink
                href={customLink ? customLink : '#coral_thread'}
                isNeutralGrey={isDarkBackground}
            >
                Comments
            </StyledTextLink>
        </StyledCommentsWrapper>
    )
}
