import {
    ConfigurationContext,
    getBaseUrl,
    useFeature,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { LinkBanner } from '../../../banners/LinkBanner/LinkBanner'
import { RelatedContent } from '../../../content/RelatedContent/RelatedContent'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'
import { Byline } from '../../../publication/Byline/Byline'
import { Footnote } from '../../../publication/Footnote/Footnote'
import { getCanonicalLink } from '../../../templates/Publication/lib/get-canonical-link'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import { PublicationProps } from '../../../templates/Publication/Publication.props'
import { useSectionMetaInfo } from '../../../__helpers/use-section-meta'
import {
    StyledArticleContent,
    StyledArticleContentFooter,
    StyledGrid,
    StyledInsetCell,
} from './TheWestFeature.styled'

interface CellProps {
    offset?: {
        position: 'top'
        value: '50%'
    }
}

export const featurePublicationPageType = 'feature-publication'

export function TheWestFeature(props: PublicationProps & CellProps) {
    const publication = props.data
    const content = publication.content
    const canonicalLink = getCanonicalLink(publication)
    const { publicUrl } = useContext(ConfigurationContext)
    const sectionMeta = useSectionMetaInfo()
    const articleUrl = `${getBaseUrl(publicUrl, sectionMeta)}/${
        publication.primaryTopic.id
    }/${publication.slug}`

    const relatedItems =
        props.relatedPublications && props.relatedPublications.items

    const useStoryClassification = true
    const fundingType = getFundingType(publication, useStoryClassification)
    return (
        <StyledGrid itemProp="articleBody">
            <StyledInsetCell>
                <Byline
                    profile={publication.profile}
                    text={publication.byline}
                    timestamp={publication.publicationDate}
                    fundingType={fundingType}
                    source={publication.source}
                    isFullWidth={true}
                    url={articleUrl}
                    title={publication.heading}
                    requiredAccess={publication.requiredAccess}
                    allowCommenting={publication.allowCommenting}
                />
            </StyledInsetCell>

            <div>
                {content && (
                    <StyledArticleContent>
                        <ArticleBlockContent
                            blocks={content}
                            targetedContentElement={
                                props.targetedContent &&
                                props.targetedContent.position === 'inline'
                                    ? props.targetedContent.element
                                    : undefined
                            }
                            inlinePositionedContent={
                                props.inlinePositionedContent
                            }
                            onEvent={props.onEvent}
                            log={props.log}
                            publicationKind={publication.kind}
                            publicationPathname={props.location.pathname}
                            adUnitPath={props.adState.targeting.adUnitPath}
                            requiredAccess={publication.requiredAccess}
                        />
                        {canonicalLink && (
                            <Footnote
                                text="Originally published as "
                                link={canonicalLink}
                            />
                        )}
                    </StyledArticleContent>
                )}

                <StyledArticleContentFooter>
                    <LinkBanner
                        url={`/manage-email-preferences`}
                        message="Get the latest news delivered to your inbox."
                        cta="Sign up for our emails"
                        verticalGutters={['md', 'md']}
                    />

                    {relatedItems && (
                        <div>
                            <RelatedContent
                                items={relatedItems}
                                onEvent={props.onEvent}
                            />
                        </div>
                    )}
                </StyledArticleContentFooter>
            </div>
        </StyledGrid>
    )
}
