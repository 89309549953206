import { Features, WebLink } from '@news-mono/web-common'
import React from 'react'
import { AmpImg } from '../../content/Picture/Image.amp'
import { FeatureToggle } from '../../feature-toggling'
import { createRenderTarget } from '../../render-target'
import { ThemeMarginKeys } from '../../__styling/settings/metrics'
import {
    ImageLinkVisibilityMode,
    StyledImageLinkContainer,
} from './ImageLink.styled'

export interface ImageLinkProps {
    link: string
    src: string
    forceInternalSSR?: boolean

    altText: string
    title: string

    width: number | string
    height: number

    className?: string
    visibilityMode?: ImageLinkVisibilityMode
    verticalSpacing?: ThemeMarginKeys

    feature?: Features
}

const SimpleImageLinkImage = (props: ImageLinkProps) => (
    <img
        src={props.src}
        title={props.title}
        alt={props.altText}
        width={props.width}
        height={props.height}
    />
)

const AmpImageLinkImage = (props: ImageLinkProps) => (
    <AmpImg
        src={props.src}
        alt={props.altText}
        width={props.width}
        height={props.height}
    />
)

const ImageLinkImage = createRenderTarget('ImageLinkImage', {
    amp: AmpImageLinkImage,
    web: SimpleImageLinkImage,
    rss: SimpleImageLinkImage,
    preview: SimpleImageLinkImage,
    app: SimpleImageLinkImage,
})

export const ImageLink: React.FC<ImageLinkProps> = (props) => {
    if (props.visibilityMode === 'hidden') {
        return null
    }

    if (props.feature) {
        return (
            <FeatureToggle
                feature={props.feature}
                on={() => (
                    <StyledImageLinkContainer
                        className={props.className}
                        visibilityMode={props.visibilityMode}
                        verticalSpacing={props.verticalSpacing}
                    >
                        <WebLink
                            to={props.link}
                            forceInternalSSR={props.forceInternalSSR}
                        >
                            <ImageLinkImage {...props} />
                        </WebLink>
                    </StyledImageLinkContainer>
                )}
            />
        )
    }

    // Dont apply srcSets to marketing tiles as typically where they are used they dont change size
    // otherwise they end up being pixelated due to further optimization by image manager
    // they should already be optimised
    return (
        <StyledImageLinkContainer
            className={props.className}
            visibilityMode={props.visibilityMode}
            verticalSpacing={props.verticalSpacing}
        >
            <WebLink to={props.link} forceInternalSSR={props.forceInternalSSR}>
                <ImageLinkImage {...props} />
            </WebLink>
        </StyledImageLinkContainer>
    )
}
ImageLink.displayName = 'ImageLink'
