import { ConsentLevel, useConsentGiven } from '@news-mono/web-common'
import * as React from 'react'
import { createRenderTarget } from '../../../render-target'
import { ConsentFallback } from '../../../tracking/ConsentFallback'
import { LiveScores as LiveScoresWeb, LiveScoresProps } from './LiveScores.web'

const LazyLiveScoresRenderTarget = createRenderTarget('LiveScores', {
    web: LiveScoresWeb,
    amp: null,
    rss: null,
    preview: null,
    app: null,
})

export const LiveScores: React.FC<LiveScoresProps> = ({
    statsPerformToken,
}) => {
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.All,
    })

    if (!consentGiven) {
        return <ConsentFallback />
    }

    return <LazyLiveScoresRenderTarget statsPerformToken={statsPerformToken} />
}
