import debug from 'debug'
import pino from 'pino'

let pageLoadCounter = -1

const debugParsely = debug('parsely')
const logger = pino()

type ParselyRenderType = 'web' | 'ajax'

export const injectParsely = (
    callType: ParselyRenderType,
    parselyApikey?: string,
) => {
    try {
        debugParsely(`Performing Parsely ${callType} action %o`, {
            pageLoadCounter,
            callType,
        })

        switch (callType) {
            case 'ajax': {
                pageLoadCounter++
                if (pageLoadCounter === 0) {
                    return
                }
                if (window.PARSELY) {
                    return window.PARSELY.beacon.trackPageView({
                        url: window.location.href,
                        js: 1,
                    })
                }
                break
            }

            default: {
                return returnWebParselyScript(parselyApikey)
            }
        }
    } catch (e) {
        logger.warn(
            { err: e },
            `Unable to return Parsely Script + ${callType}` + ' Error: ' + e,
        )
    }
}

export const returnWebParselyScript = (parselyApikey: string | undefined) =>
    `<script id="parsely-cfg" src="//cdn.parsely.com/keys/${parselyApikey}/p.js"></script>
<script>
function getSectionFromTags(tags) {
    if (tags.length && tags.length > 0) {
        return tags[0].split('/').length > 0 ? tags[0].split('/')[0] : tags[0]
    }
    return undefined
}
if (window.PARSELY && window.PARSELY.video) {
    window.PARSELY.video.onPlay = (playerApi, videoId, metadata) => {
        window.PARSELY &&
        window.PARSELY.video.trackPlay(videoId, {
            ...metadata,
            title: playerApi.swmOptions.video.name,
            section: getSectionFromTags(playerApi.swmOptions.video.tags),
        })
    }
}
</script>
`
