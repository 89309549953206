import React, { useState, useRef, useContext, useEffect } from 'react'
import {
    BreachWallContentType,
    BreachWallStyle,
} from '../../helpers/BreachWallTypes'
import { RequiredAccessLevel } from '@west-australian-newspapers/publication-types'
import { TheWestSection } from '@news-mono/common'
import {
    PublicationType,
    CardEvents,
    CollectionEvent,
    LinkClickedEvent,
    PlayerEvent,
    BreachEvent,
    BreachScreenContext,
    BreachScreenType,
    click_origin_breach_screen,
    DataLayerEventName,
    useFeature,
    useSubscribeWithGoogle,
    ViewSubscriptionItem,
} from '@news-mono/web-common'
import { useTheme } from '@emotion/react'
import { useAuthActions } from '../../../../user-registration/authentication/AuthenticationProvider'
import { useSection, useSectionMetaInfo } from '../../../../__helpers'
import { createLinkClickEvent } from '../../createLinkClickHandler'
import H from 'history'
import {
    BottomTextWrap,
    ButtonWrap,
    CTAHeading,
    CTASubHeading,
    InfoText,
    InfoTextLink,
    PromoRibbon,
    PuzzlesBreachCTAWrap,
    PuzzlesBreachWrap,
    SubscribeButton,
    SubscribeWithGoogleButton,
} from './PuzzlesBreachwall.styled'
import { getCartPayload } from '../../../../tracking/gtm/gtm-event-payloads'
import { packageOfferDetails, useUpdateBreachWallContext } from '../../common'
import { toItemListId, DEFAULT_CART_QUANTITY } from '../../../../tracking'
import { PuzzlesListingCards } from './PuzzlesListingCards'
import { PuzzleListingDescription } from '../../../../puzzles/PuzzleListing/TheWest/PuzzleListing.styled'

const defaultCreative = 'TW_Article_Breach_WestBlue_Design_PhotoPackages'
const defaultStyle: BreachWallStyle = 'default-breachwall-2024'
const defaultType: BreachWallContentType = 'default'
const defaultDeal = ''

const puzzlesBackgroundImage = require('./assets/puzzle-tw-min.png')

export interface puzzlesBreachProps {
    suppliedDeal: string
    suppliedType: BreachWallContentType
    suppliedCreative?: string
    suppliedStyle?: BreachWallStyle
    publication?: PublicationType
    requiredAccess?: RequiredAccessLevel
    location: H.Location
    onEvent: (
        event:
            | BreachEvent
            | PlayerEvent
            | LinkClickedEvent
            | CardEvents
            | CollectionEvent,
    ) => void
    title?: string
    bannerText?: string
    message?: string
    renditionType?: string
    enabledSwgCampaign?: boolean
}

export const PuzzlesBreachWallWrapper = ({
    suppliedDeal,
    suppliedType,
    suppliedCreative,
    suppliedStyle,
    publication,
    requiredAccess,
    onEvent,
    title,
    bannerText,
    message,
    renditionType,
    enabledSwgCampaign,
}: puzzlesBreachProps) => {
    const section = useSection<TheWestSection>()
    const theme = useTheme()
    const buttonRef = useRef<HTMLButtonElement>(null)
    const { onSubscribeClick, onLoginClick } = useAuthActions()

    const sectionMeta = useSectionMetaInfo()
    const masthead = 'The West Australian'
    const breachName = section === 'genwest' ? 'default' : section
    const subscribeWithGoogleEnabled = useFeature('subscribe-with-google')
    const subscribeWithGoogleBreachInterfaceEnabled = useFeature(
        `swg-breach-${breachName}`,
    )

    const [breachDeal, setBreachDeal] = useState<string>(
        suppliedDeal || defaultDeal,
    )
    const [breachType, setBreachType] = useState<BreachWallContentType>(
        suppliedType || defaultType,
    )
    const [breachStyle, setBreachStyle] = useState<BreachWallStyle>(
        suppliedStyle || defaultStyle,
    )
    const breachCreative = suppliedCreative || defaultCreative

    const subscriptionPackages = packageOfferDetails(
        breachType ? breachType : 'Puzzles',
    )

    // Puzzles is now one package type only
    const {
        packagePath,
        promoPrice,
        weeklyPrice,
        discount,
        offerCode,
        googleSKU,
        ribbonTitle,
    } = subscriptionPackages[0]

    const showSwG =
        subscribeWithGoogleEnabled &&
        subscribeWithGoogleBreachInterfaceEnabled &&
        googleSKU

    // fire breach wall context update
    useUpdateBreachWallContext({
        publication: publication,
        requiredAccessLevel: requiredAccess || 'subscriber',
        breachDeal: suppliedDeal,
        breachCreativeName: breachCreative,
    })

    const breachScreenContext = useContext(BreachScreenContext)
    const breachContext = breachScreenContext.context
    const breachScreenType: BreachScreenType = 'Premium - Inline'

    // handle whenever 'subscription options' is clicked.
    const onAllPackagesClicked = React.useCallback(() => {
        createLinkClickEvent(breachCreative, breachDeal, onEvent)()
        onSubscribeClick({
            callToAction: click_origin_breach_screen,
            breachScreenType: 'Premium - Inline',
        })
    }, [breachCreative, breachDeal, onEvent, onSubscribeClick])

    const addToCartPayload = getCartPayload(subscriptionPackages[0], masthead)

    useSubscribeWithGoogle(
        buttonRef.current as HTMLButtonElement,
        googleSKU || 'everyday_digital_4weeks',
        sectionMeta,
        breachContext,
    )

    // when everything is loaded, fire off a view_item_list GA4 event
    useEffect(() => {
        const availableDeal = breachDeal === '' ? 'default' : breachDeal
        const breachAvailablePayload: BreachEvent['payload'] = {
            paywallType: 'freemium',
            leadDepth: 0,
            creative: breachCreative,
            deal: availableDeal,
            breachScreenType: 'Premium - Inline',
            popupMethod: 'auto',
            pricepoint: [7, 8],
        }
        onEvent({
            type: DataLayerEventName.breachScreenAvailable,
            originator: 'breachscreen',
            payload: breachAvailablePayload,
        })

        const viewSubscriptionItems: ViewSubscriptionItem[] =
            subscriptionPackages?.map((pkg, index) => ({
                index,
                deal_name: breachType,
                item_name: pkg.heading,
                item_id: pkg.rateCode,
                item_brand: masthead,
                component_path: pkg.packagePath,
                item_list_category: 'freemium',
                item_list_category2: 'Premium - Inline',
                item_list_name: breachCreative,
                item_list_id: toItemListId(breachCreative),
                price: pkg.weeklyPrice,
                promo_price: pkg.promoPrice || 0,
                discount: pkg.discount || 0,
                coupon: pkg.offerCode || '',
                quantity: DEFAULT_CART_QUANTITY,
                currency: 'AUD',
            })) || []
        onEvent({
            type: DataLayerEventName.viewSubscriptionItemListEvent,
            parentType: DataLayerEventName.viewItemListEvent,
            originator: 'breachscreen',
            payload: { items: viewSubscriptionItems },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PuzzlesBreachWrap theme={theme}>
                {renditionType !== 'app' && bannerText && (
                    <PuzzleListingDescription>
                        {bannerText}
                    </PuzzleListingDescription>
                )}
                <PuzzlesBreachCTAWrap
                    bgImage={puzzlesBackgroundImage}
                    noBanner={!bannerText}
                    enabledSwgCampaign={enabledSwgCampaign}
                >
                    <CTAHeading>{title}</CTAHeading>
                    <CTASubHeading>{message}</CTASubHeading>
                    <ButtonWrap enabledSwgCampaign={enabledSwgCampaign}>
                        {!enabledSwgCampaign && (
                            <SubscribeButton
                                buttonWidth="wide"
                                type="button"
                                title="Subscribe Button"
                                aria-label="Subscribe button"
                                onClick={() => {
                                    createLinkClickEvent(
                                        'puzzles',
                                        breachDeal,
                                        onEvent,
                                        breachScreenType,
                                        1,
                                        addToCartPayload,
                                    )()
                                    onSubscribeClick({
                                        packagePath: packagePath,
                                        offerCode: offerCode,
                                        callToAction:
                                            click_origin_breach_screen,
                                        breachScreenType: breachScreenType,
                                    })
                                }}
                            >
                                Subscribe
                            </SubscribeButton>
                        )}

                        {showSwG && (
                            <SubscribeWithGoogleButton
                                ref={buttonRef}
                                type="button"
                                aria-label="Subscribe with google button"
                                onClick={() =>
                                    onEvent({
                                        type: DataLayerEventName.addToCart,
                                        originator: 'breachscreen',
                                        payload: {
                                            currency: 'AUD',
                                            value:
                                                (promoPrice
                                                    ? promoPrice
                                                    : weeklyPrice) *
                                                DEFAULT_CART_QUANTITY,
                                            items: [
                                                {
                                                    item_id: 'WST_DIGI_DD',
                                                    item_brand: masthead,
                                                    item_name:
                                                        'Everyday Digital',
                                                    price: weeklyPrice,
                                                    promo_price:
                                                        promoPrice || 0,
                                                    quantity:
                                                        DEFAULT_CART_QUANTITY,
                                                    discount: discount || 0,
                                                    coupon: offerCode || '',
                                                    item_variant: 'swg',
                                                },
                                            ],
                                        },
                                    })
                                }
                            />
                        )}

                        {ribbonTitle && (
                            <PromoRibbon>{ribbonTitle}</PromoRibbon>
                        )}
                    </ButtonWrap>
                    <BottomTextWrap>
                        <InfoText>
                            Looking for a different subscription?{' '}
                            <InfoTextLink
                                href={'#'}
                                onEvent={onAllPackagesClicked}
                            >
                                Browse all
                            </InfoTextLink>
                        </InfoText>
                        <InfoText>
                            Already have an account?{' '}
                            <InfoTextLink href={'#'} onEvent={onLoginClick}>
                                Log in here
                            </InfoTextLink>
                        </InfoText>
                    </BottomTextWrap>
                </PuzzlesBreachCTAWrap>

                <PuzzlesListingCards disabled={true} />
            </PuzzlesBreachWrap>
        </>
    )
}
