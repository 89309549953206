import { LinkDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { StyledBuyNowV2Link, StyledBuyNowV2Wrapper } from './BuyNowV2.styled'

export interface BuyNowV2Props {
    text: string
    link: LinkDTO
}
const BuyNowV2: React.FC<BuyNowV2Props> = ({ text, link }) => {
    return (
        <StyledBuyNowV2Wrapper>
            <StyledBuyNowV2Link to={link.href} target={link.target || '_blank'}>
                {text}
            </StyledBuyNowV2Link>
        </StyledBuyNowV2Wrapper>
    )
}

export default BuyNowV2
