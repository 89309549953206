import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { CardMedia } from '../../cards/CardMedia/CardMedia'
import { CardText } from '../../cards/CardText/CardText'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { StyledHeadlineText } from '../CardText/CardText.styled'
import { HeroVideoLayoutRatios } from '../HeroVideoCard/HeroVideoCard'

function layoutRatioToValue(ratio: HeroVideoLayoutRatios): any {
    switch (ratio) {
        case '3:1':
            return ['66.666666%', '33.333333%']
        case '1:3':
            return ['33.333333%', '66.666666%']
        default:
            return ['50%', '50%']
    }
}

export const styledHeroClassName = 'HeroCard'

export interface StyledHeroCardProps {
    className?: string
    isSponsored: boolean | null
    hasBackground?: boolean
    primaryTopic: string | undefined
    hasHeroBorder?: boolean
}

export const StyledHeroCard = withClass(styledHeroClassName)(
    styled('div')<StyledHeroCardProps>(
        {
            ...breakWords,
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            flexGrow: 1,
        },
        ({ theme, hasHeroBorder }) => ({
            borderBottom: themedValue(theme, {
                thewest: `1px solid ${
                    hasHeroBorder ? theme.colors.borders.primary : 'transparent'
                }`,
                perthnow: `1px solid ${colors.perthnow.greyMarble}`,
                sevennews: undefined,
                fallback: undefined,
            }),
        }),
        ({ theme, isSponsored }) =>
            theme.kind === 'perthnow' && {
                borderBottom: isSponsored
                    ? `2px solid ${colors.black}`
                    : `1px solid ${colors.perthnow.greyMarble}`,
            },
        ({ theme, hasBackground }) => ({
            backgroundColor: themedValue(theme, {
                perthnow: hasBackground
                    ? colors.perthnow.greyBasalt
                    : colors.white,
                sevennews: hasBackground ? colors.white : 'transparent',
                fallback: undefined,
            }),
            alignItems: themedValue(theme, {
                perthnow: 'flex-start',
                thewest: 'stretch',
                sevennews: 'stretch',
                fallback: undefined,
            }),
        }),
    ),
)
StyledHeroCard.displayName = 'StyledHeroCard'

export interface StyledCellProps {
    layoutRatio: HeroVideoLayoutRatios
}

export const StyledCell = styled('div')<StyledCellProps>(
    (props: StyledCellProps) => {
        const [col1Dimensions, col2Dimensions] = layoutRatioToValue(
            props.layoutRatio,
        )

        return {
            width: '100%',

            [breakpoint('sm')]: {
                ':nth-child(1)': {
                    display: 'flex',
                    width: props.layoutRatio !== '1:1' ? col1Dimensions : '45%',

                    [`${CardTextContainer}`]: {
                        width: '100%',
                    },
                },
                ':nth-child(2)': {
                    width: props.layoutRatio !== '1:1' ? col2Dimensions : '55%',

                    [`${StyledHeroCardMedia}`]: {
                        width: '100%',
                    },
                },
            },
        }
    },
)

export const CardTextContainer = styled('div')(
    {
        width: '100%',
        flex: 'none',
        fontSize: calcRem(15),

        [breakpoint('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '45%',
        },
    },
    (props) => ({
        padding: props.theme.cards.outerMargin,
        paddingLeft: themedValue(props.theme, {
            thewest: 0,
            fallback: props.theme.margins.md,
        }),
        paddingRight: props.theme.margins.md,
    }),
)
CardTextContainer.displayName = 'CardTextContainer'

export const StyledHeroCardMedia = styled(CardMedia)(
    {
        width: '100%',
        display: 'block',
        alignSelf: 'flex-start',

        [breakpoint('sm')]: {
            width: '55%',
            marginTop: 0,
        },
    },
    (props) => ({
        marginTop: themedValue(props.theme, {
            thewest: calcRem(props.theme.margins.sm),
            sevennews: calcRem(metrics.sevennews.margins.sm),
            fallback: undefined,
        }),
    }),
)
StyledHeroCardMedia.displayName = 'StyledHeroCardMedia'

export const StyledLink = styled(CardTextInteractions)({
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
})
StyledLink.displayName = 'StyledLink'

export const StyledHeroCardText = styled(CardText)(
    {
        paddingTop: 0,
    },
    ({ theme }) => ({
        [`& ${StyledHeadlineText}`]: {
            fontWeight: themedValue(theme, {
                thewest: 400,
                fallback: undefined,
            }),
        },
    }),
)
StyledHeroCardText.displayName = 'StyledHeroCardText'
