import React from 'react'
import { ImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { ThemeMargins } from '../../../__styling'
import { useProduct } from '../../../__product'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { StyledSixCardCollection } from './SixCardCollection.styled'
import { ContentCard } from '../../cards/ContentCard/ContentCard'
import { HorizontalDivider } from '../../HorizontalDivider/HorizontalDivider'

interface SixCardProps {
    items: MaybeLoaded<CardItem[]>
    onEvent: (event: CollectionEvent) => void
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
}

export const SixCardCollection = (props: SixCardProps) => {
    const { items, verticalGutters, verticalSpacing } = props
    const product = useProduct()

    if (!items.loaded) {
        return null
    }
    const loadedItems = invertMaybeLoadedItems(items, 6)

    const gridAreasNames: {
        [key: number]: string
    } = {
        0: 'one',
        1: 'two',
        2: 'three',
        3: 'four',
        4: 'five',
        5: 'six',
    }

    return (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'SixCardCollection',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <>
                    <StyledSixCardCollection
                        ref={ref}
                        verticalSpacing={verticalSpacing}
                        verticalGutters={verticalGutters}
                    >
                        {loadedItems.map((item, itemIndex) => {
                            const cardType =
                                itemIndex === 0 || itemIndex === 1
                                    ? 'large'
                                    : 'small'
                            const gridAreaName = gridAreasNames[itemIndex]

                            return (
                                <ContentCard
                                    item={item}
                                    cardNumber={itemIndex}
                                    cardType={cardType}
                                    onEvent={props.onEvent}
                                    gridAreaName={gridAreaName}
                                    collectionType={'six-card'}
                                />
                            )
                        })}
                    </StyledSixCardCollection>
                    <HorizontalDivider />
                </>
            )}
        </ImpressionAvailable>
    )
}
