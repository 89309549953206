import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    breakpoint,
    calcRem,
    fonts,
    metrics,
    themedValue,
} from '../../__styling'

export const AflFixturesWidgetContainer = styled('div')(({ theme }) => ({
    display: 'block',
    '& .Opta': {
        maxWidth: themedValue(theme, {
            sevennews: '800px',
            perthnow: metrics.perthnow.siteMetrics.mainContentWidth,
            fallback: undefined,
        }),
        overflow: 'hidden',
        margin: '0 auto',
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            perthnow: fonts.perthnow.sansSerifCond,
            thewest: fonts.thewest.sansSerif,
            fallback: undefined,
        }),
        textTransform: 'uppercase',

        '& .Opta_W': {
            background: themedValue(theme, {
                perthnow: 'transparent',
                sevennews: '#fff',
                thewest: 'transparent',
                fallback: undefined,
            }),
            marginTop: themedValue(theme, {
                perthnow: calcRem(16),
                thewest: calcRem(16),
                fallback: undefined,
            }),
            '&>div': {
                background: themedValue(theme, {
                    perthnow: 'transparent',
                    fallback: '#fff',
                }),
            },
            '& .Opta-Tabs': {
                '& .Opta-Nav ': {
                    backgroundColor: 'transparent',
                    marginBottom: themedValue(theme, {
                        perthnow: calcRem(16),
                        thewest: calcRem(16),
                        fallback: undefined,
                    }),
                    '& ul': {
                        display: 'flex',
                        backgroundColor: 'transparent',
                        overflow: 'scroll',
                        '&::-webkit-scrollbar': { display: 'none' },
                    },
                    '& ul li': {
                        width: 'auto',
                        minWidth: '125px',
                        border: 'solid 1px transparent',
                    },
                    '& ul li a': {
                        fontWeight: 500,
                        textTransform: 'uppercase',

                        fontSize: themedValue(theme, {
                            sevennews: calcRem(12),
                            perthnow: calcRem(16),
                            fallback: calcRem(12),
                        }),
                        color: tokens.sevennews.colors.palette.white,
                        backgroundColor: themedValue(theme, {
                            sevennews:
                                tokens.sevennews.colors.palette.AFL.lightRed,
                            perthnow:
                                tokens.perthnow.colors.palette.AFL
                                    .pinkThuliteLight,
                            thewest:
                                tokens.thewest.colors.palette.AFL.westblue40,
                            fallback: undefined,
                        }),

                        width: '100%',
                        textAlign: 'center',
                    },
                    '& ul li.Opta-On a': {
                        backgroundColor: themedValue(theme, {
                            sevennews: tokens.sevennews.colors.palette.red,
                            perthnow:
                                tokens.perthnow.colors.palette.pinkThulite,
                            thewest: tokens.thewest.colors.palette.westblue,
                            fallback: undefined,
                        }),
                    },
                    '& .Opta-Overflowed': {
                        backgroundColor: themedValue(theme, {
                            sevennews:
                                tokens.sevennews.colors.palette.AFL.lightRed,
                            perthnow:
                                tokens.perthnow.colors.palette.AFL
                                    .pinkThuliteLight,
                            thewest:
                                tokens.thewest.colors.palette.AFL.westblue40,
                            fallback: undefined,
                        }),
                        scrollbarWidth: 'none',
                    },
                },
            },
        },

        '& h4': {
            display: themedValue(theme, {
                sevennews: 'block',
                perthnow: 'none',
                thewest: 'none',
                fallback: undefined,
            }),
            background: tokens.sevennews.colors.palette.seashell,
            '&:first-of-type': {
                marginTop: calcRem(8),
            },
        },

        '& .Opta-Even': {
            backgroundColor: tokens.sevennews.colors.palette.white,
        },

        '& .Opta_AFL_F .Opta-fixture': {
            marginBottom: themedValue(theme, {
                sevennews: calcRem(30),
                perthnow: calcRem(4),
                thewest: calcRem(4),
                fallback: undefined,
            }),
            background: themedValue(theme, {
                thewest: tokens.thewest.colors.palette.greyErmine,
                fallback: undefined,
            }),
            padding: themedValue(theme, {
                perthnow: calcRem(16, 0),
                fallback: undefined,
            }),
            '& .Opta-Scoreline .Opta-scores': {
                alignItems: 'center',
            },
            '& .Opta-Scoreline': {
                '>span': {
                    [breakpoint('sm')]: {
                        padding: themedValue(theme, {
                            perthnow: calcRem(5, 30),
                            fallback: undefined,
                        }),
                    },
                },
                '& .Opta-TeamName': {
                    fontSize: calcRem(12),
                    fontWeight: 700,

                    [breakpoint('md')]: {
                        fontSize: calcRem(16),
                        padding: themedValue(theme, {
                            perthnow: calcRem(0, 20),
                            fallback: undefined,
                        }),
                    },
                },
            },
        },

        '& .Opta-Time': {
            fontSize: calcRem(14),
            lineHeight: '20px',
            fontWeight: 700,
            [breakpoint('md')]: {
                fontSize: calcRem(16),
            },
        },
        '& .Opta-Image-Team-Small': {
            maxWidth: calcRem(64),
            maxHeight: calcRem(43),
            width: '100%',
            '& img': {
                width: '100%',
            },
        },
        '& .Opta_AFL_F .Opta-fixture .Opta-MatchLink-container': {
            marginTop: calcRem(10),
            width: 'auto',
            [breakpoint('xxs')]: {
                width: '33%',
            },
            '& .Opta-MatchLink ': {
                background: themedValue(theme, {
                    perthnow: tokens.perthnow.colors.palette.AFL.neutral,
                    thewest: tokens.thewest.colors.palette.greyHippo,
                    sevennews: 'rgba(51, 51, 51, 0.5)',
                    fallback: undefined,
                }),
                fontSize: calcRem(13),
                fontWeight: 600,
                color: ' rgb(255, 255, 255)',
                padding: themedValue(theme, {
                    sevennews: calcRem(5, 20),
                    perthnow: calcRem(6, 40),
                    thewest: calcRem(6, 28),
                    fallback: undefined,
                }),
                textAlign: 'center',

                [breakpoint('md')]: {
                    padding: themedValue(theme, {
                        perthnow: calcRem(6, 60),
                        thewest: calcRem(6, 60),
                        fallback: undefined,
                    }),
                    fontSize: themedValue(theme, {
                        thewest: calcRem(13),
                        fallback: calcRem(15),
                    }),
                },
                '@media screen and (min-width: 430px) and (max-width: 640px)': {
                    display: 'block',
                },
            },
        },
    },
}))
