import { Topic } from '../client-data-types/topic'
import { GtmEvent } from '../events/gtm-event-types'

let chartbeatInitialised = false

type ChartbeatUserType = 'anon' | 'lgdin' | 'paid'

export function handleChartbeatTracking(
    event: GtmEvent,
    chartbeatId: number,
    publicHostname: string,
) {
    if (event.type === 'auth.login' || event.type === 'auth.loginRefresh') {
        const userType = getChartbeatUserType(event.payload.entitlements)
        setChartbeatUserType(userType)
    }

    if (event.type === 'page-load-complete') {
        const {
            primaryTopic,
            secondaryTopics,
            location,
            title,
            byline,
            entitlements,
        } = event.payload

        const path = location.pathname
        const sections = [
            primaryTopic?.id,
            ...(secondaryTopics ? secondaryTopics : []).map(
                (topic: Topic) => topic.id,
            ),
        ].join()

        if (!chartbeatInitialised) {
            initChartbeat(chartbeatId, publicHostname, byline, sections)
        } else {
            const userType = getChartbeatUserType(entitlements)
            setChartbeatUserType(userType)
            trackChartbeatPageview(title, byline, sections, path)
        }
    }
}

export function initChartbeat(
    id: number,
    domain: string,
    author: string,
    sections: string,
) {
    if (domain === '7news.com.au') return null

    const _sf_async_config: ChartbeatAsyncConfig = (window._sf_async_config =
        window._sf_async_config || {})
    _sf_async_config.uid = id
    _sf_async_config.domain = domain
    _sf_async_config.useCanonical = true
    _sf_async_config.useCanonicalDomain = true
    _sf_async_config.sections = sections
    _sf_async_config.authors = author

    addScript()

    chartbeatInitialised = true
}

export function addScript() {
    const e = document.createElement('script')
    const n = document.getElementsByTagName('script')[0]
    e.type = 'text/javascript'
    e.async = true
    e.src = '//static.chartbeat.com/js/chartbeat.js'
    n.parentNode?.insertBefore(e, n)
}

export function trackChartbeatPageview(
    title: string,
    authors: string,
    sections: string,
    path: string,
) {
    if (typeof pSUPERFLY !== 'undefined') {
        pSUPERFLY.virtualPage({
            sections,
            authors,
            path,
            title,
        })
    }
}

export function getChartbeatUserType(
    entitlements: string[] | undefined,
): ChartbeatUserType {
    if (!entitlements?.length) {
        return 'anon'
    }

    return entitlements.indexOf('thewest:all') >= 0 ? 'paid' : 'lgdin'
}

/**
 * Chartbeat looks for a global _cbq variable containing user data
 * @param userType
 */
export function setChartbeatUserType(userType: ChartbeatUserType) {
    const _cbq = (window._cbq = window._cbq || [])

    _cbq.push(['_acct', userType])
}
