import { TheWestSection } from '@news-mono/common'
import { Product } from '@news-mono/web-common'
import { PartialTheme, ThemeConfig } from '../..'
import {
    PerthNowSection,
    perthNowSectionDefinitions,
    PerthNowSectionProps,
} from './perthnow'
import {
    SevenNewsSection,
    sevenNewsSectionDefinitions,
    SevenNewsSectionProps,
} from './sevennews'
import {
    TheNightlySection,
    theNightlySectionDefinitions,
    TheNightlySectionProps,
} from './thenightly'
import { theWestSectionDefinitions, TheWestSectionProps } from './thewest'
export * from './perthnow'
export * from './sevennews'
export * from './thewest'

export type Section =
    | PerthNowSection
    | TheWestSection
    | SevenNewsSection
    | TheNightlySection

/**
 *  Get the sections from the product, e.g. ProductSection<Product.PerthNow> = PerthNowSection
 */

export type ProductSection<P extends Product> = P extends Product.PerthNow
    ? PerthNowSection
    : P extends Product.TheWest
    ? TheWestSection
    : P extends Product.SevenNews
    ? SevenNewsSection
    : P extends Product.TheNightly
    ? TheNightlySection
    : never

type SectionProps = {
    perthnow: PerthNowSectionProps
    sevennews: SevenNewsSectionProps
    thewest: TheWestSectionProps
    thenightly: TheNightlySectionProps
}

export const sections: SectionProps = {
    perthnow: perthNowSectionDefinitions,
    sevennews: sevenNewsSectionDefinitions,
    thewest: theWestSectionDefinitions,
    thenightly: theNightlySectionDefinitions,
}

export interface BaseSectionValues {
    primaryColor: string
}

export const sectionThemeOverrider = <P extends keyof SectionProps>(
    product: P,
    section: keyof SectionProps[P],
): PartialTheme<ThemeConfig> => {
    return {
        sectionValues: sections[product][section] ?? {},
        section: section.toString(),
    }
}
