export interface SizeMapping {
    viewport: [number, number]
    slot: SlotSize
    /** If not specified will pick the height of the first size mapping */
    placeholderHeight?: number
}

export type SlotSize = SlotSizeArray | SingleSlotSize
export type SlotSizeArray = SingleSlotSize[]
export type SingleSlotSize = [number, number]

export function isSlotSizeArray(slot: SlotSize): slot is SlotSizeArray {
    return Array.isArray(slot[0])
}

function equals(a: SingleSlotSize, b: SingleSlotSize) {
    return a[0] === b[0] && a[1] === b[1]
}

export function uniqueSlotSizes(sizeMapping: SizeMapping[]): SlotSizeArray {
    const sizes: SlotSizeArray = []
    const addNonZero = (size: SingleSlotSize) => {
        if (size.length && size[0] > 0 && size[1] > 0) sizes.push(size)
    }
    sizeMapping.forEach((element) => {
        if (isSlotSizeArray(element.slot)) {
            element.slot.forEach((element) => {
                addNonZero(element)
            })
        } else {
            addNonZero(element.slot)
        }
    })
    return sizes.filter(
        (aVal, index, self) =>
            index === self.findIndex((bVal) => equals(aVal, bVal)),
    )
}
