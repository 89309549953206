import { RendererMiddleware } from 'json-react-layouts'
import { ComponentServices } from '../routing/page-definition'

/** This middleware adds the context to the event automatically */
export const componentOnEventMiddleware: RendererMiddleware<
    ComponentServices,
    {}
> = (componentProps, middlewareProps, serviceProps, next) => {
    const modifiedServices = {
        ...serviceProps,
        services: { ...serviceProps.services },
    }

    modifiedServices.services.onEvent = (event: any) => {
        serviceProps.services.onEvent({
            context: {
                timeStamp: Date.now(),
                componentType: componentProps.layoutType,
                componentRenderPath: componentProps.renderPath,
                dataDefinition: componentProps.dataDefinitionArgs,
            },
            ...event,
        })
    }

    return next(componentProps, middlewareProps, modifiedServices)
}
