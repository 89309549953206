import React from 'react'

export interface IconAnnouncementProps {
    className?: string
}

export const IconAnnouncement: React.FC<IconAnnouncementProps> = (props) => (
    <svg
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21"
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2 9.07c0 2.8 1.1 5.41 3.08 7.38a.99.99 0 0 1 0 1.4 1 1 0 0 1-1.42 0A12.3 12.3 0 0 1 0 9.08C0 5.76 1.3 2.64 3.66.3a1 1 0 0 1 1.42 0 .99.99 0 0 1 0 1.4A10.34 10.34 0 0 0 2 9.08zm4 0a6.4 6.4 0 0 0 1.9 4.57c.4.39.4 1.02 0 1.4a1 1 0 0 1-1.41 0A8.37 8.37 0 0 1 4 9.08c0-2.25.88-4.37 2.49-5.97a1 1 0 0 1 1.41 0c.4.39.4 1.02 0 1.4A6.4 6.4 0 0 0 6 9.08zm16 0c0-2.78-1.1-5.4-3.07-7.37a.99.99 0 0 1 0-1.4 1 1 0 0 1 1.4 0A12.3 12.3 0 0 1 24 9.06c0 3.32-1.3 6.44-3.66 8.79a1 1 0 0 1-1.41 0 .99.99 0 0 1 0-1.4A10.34 10.34 0 0 0 22 9.06zm-4 0a6.4 6.4 0 0 0-1.9-4.56.99.99 0 0 1 0-1.4 1 1 0 0 1 1.41 0A8.36 8.36 0 0 1 20 9.06c0 2.26-.88 4.38-2.49 5.98a1 1 0 0 1-1.41 0 .99.99 0 0 1 0-1.4A6.4 6.4 0 0 0 18 9.06zM12 5.6a3.5 3.5 0 0 1 3.5 3.47c0 1.58-1.06 2.9-2.5 3.34V20a1 1 0 0 1-1 .99 1 1 0 0 1-1-1v-7.6a3.49 3.49 0 0 1-2.5-3.33A3.5 3.5 0 0 1 12 5.6z"
        />
    </svg>
)
IconAnnouncement.displayName = 'IconAnnouncement'
