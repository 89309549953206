import { ThemeProvider } from '@emotion/react'
import { TheNightlySection, TheWestSection } from '@news-mono/common'
import {
    AppState,
    getSectionMetaInfo,
    Product,
    TogglesReduxState,
} from '@news-mono/web-common'
import {
    FallbackImages as PublicationTypesFallbackImages,
    SectionMetaDTO,
} from '@west-australian-newspapers/publication-types'
import React from 'react'
import { connect } from 'react-redux'
import { FallbackImages } from '../__fallback-images'
import { borderRadius } from '../__styling/settings/borderRadius'
import { sections } from '../__styling/settings/sections'
import { PerthNowSection } from '../__styling/settings/sections/perthnow'
import { SevenNewsSection } from '../__styling/settings/sections/sevennews'

interface ProvideRouteResolvedDataToThemeProps {
    section: string
    sectionMeta: SectionMetaDTO
    product: Product
    children?: React.ReactNode
    toggles?: TogglesReduxState
}

/**
 * Adds data resolved from the the routing to our theme provider
 */
export const ProvideRouteResolvedDataToTheme = connect(
    (appState: AppState) => ({
        sectionMeta: appState.meta.sectionMeta,
        toggles: appState.toggles,
    }),
)((props: ProvideRouteResolvedDataToThemeProps) => {
    const { sectionMeta, children, section, product, toggles } = props

    const sectionFallbackImages: PublicationTypesFallbackImages =
        getSectionMetaInfo(sectionMeta, section).FallbackImages

    const fallbackImages: FallbackImages = {
        altText: sectionFallbackImages.altText,
        crops: {
            '4:3': {
                ...sectionFallbackImages.crops['4:3'],
                ratio: { width: 4, height: 3 },
            },
            '16:9': {
                ...sectionFallbackImages.crops['16:9'],
                ratio: { width: 16, height: 9 },
            },
            '161:229': {
                ...sectionFallbackImages.crops['161:229'],
                ratio: { width: 161, height: 229 },
            },
            '316:421': {
                ...sectionFallbackImages.crops['316:421'],
                ratio: { width: 316, height: 421 },
            },
        },
    }

    let sectionValues
    let radius

    switch (product) {
        case Product.PerthNow:
            sectionValues =
                sections.perthnow[section as PerthNowSection] ||
                sections.perthnow.default
            radius = borderRadius.perthnow

            break
        case Product.TheWest:
            sectionValues =
                sections.thewest[section as TheWestSection] ||
                sections.thewest.default
            break
        case Product.SevenNews:
            sectionValues =
                sections.sevennews[section as SevenNewsSection] ||
                sections.sevennews.default
            break
        case Product.TheNightly:
            sectionValues =
                sections.thenightly[section as TheNightlySection] ||
                sections.thenightly.default
            break
        default:
            ;((product: never) => {
                throw new Error(`Invalid product provided: ${product} `)
            })(product)
    }

    return (
        <ThemeProvider
            theme={{
                fallbackImages,
                section,
                sectionValues,
                radius,
            }}
        >
            {children}
        </ThemeProvider>
    )
})
