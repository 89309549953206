import styled from '@emotion/styled'
import { StyledButton } from '../../../buttons/Button/Button.styled'
import { IconCross } from '../../../icons'
import { TheWestLogo } from '../../../logos/TheWestLogo/TheWestLogo'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    themedValue,
} from '../../../__styling'
import { zIndex } from '../../../__styling/settings/z-index'

export const StyledLatestEditionHeading = styled('h2')(({ theme }) => ({
    fontFamily:
        theme.kind === 'thenightly'
            ? theme.fonts.heading
            : fonts.thewest.sansSerifCond,
    fontSize: theme.kind === 'thenightly' ? calcRem(40) : calcRem(28),
    fontWeight: theme.kind === 'thenightly' ? 700 : undefined,
    letterSpacing: theme.kind === 'thenightly' ? calcRem(-2) : undefined,
    marginBottom: theme.kind === 'thenightly' ? calcRem(48) : 0,
    [breakpoint('xs')]: {
        fontSize: theme.kind === 'thenightly' ? calcRem(51) : undefined,
    },
}))

export const StyledModal = styled('div')<{ showModal: boolean }>(
    ({ theme, showModal }) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `rgba(0, 0, 0, 0.6)`,
        display: showModal ? 'block' : 'none',
        zIndex: zIndex.thewest.epaperSubscribeModal,
    }),
)

export const StyledModalMain = styled('div')(({ theme }) => ({
    position: 'fixed',
    background: 'white',
    width: '80%',
    height: 'auto',
    top: '50%',
    left: '50%',
    transform: `translate(-50%,-50%)`,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingBottom: calcRem(40),

    [breakpoint('xs')]: {
        width: calcRem(490),
        height: 'auto',
    },
}))

export const StyledIconCross = styled(IconCross)({
    height: calcRem(12),
    width: calcRem(12),
    fill: colors.black,
})

export const StyledIconCrossSpanWrapper = styled('span')({
    position: 'absolute',
    top: calcRem(10),
    right: calcRem(20),

    '&:focus': {
        border: '1px solid blue',
    },

    '&:hover': {
        cursor: 'pointer',
    },
})

export const StyledSubscribeButton = styled(StyledButton)(({ theme }) => ({
    width: '80%',
    height: calcRem(50),
    marginTop: '6vw',
    textTransform: 'none',
    fontSize: '3.7vw',

    [breakpoint('xxs')]: {
        fontSize: calcRem(18),
        marginTop: calcRem(56),
    },
    [breakpoint('xs')]: {
        width: calcRem(395),
        height: calcRem(50),
        marginTop: calcRem(56),
        fontSize: calcRem(18),
    },
    [breakpoint('sm')]: {
        width: calcRem(395),
        height: calcRem(50),
        marginTop: calcRem(56),
    },
    [breakpoint('md')]: {
        width: calcRem(395),
        height: calcRem(50),
        marginTop: calcRem(56),
    },
}))

export const StyledOutlinedButton = styled(StyledSubscribeButton)(
    ({ theme }) => ({
        background: 'transparent',
        border: `1px solid ${theme.sectionValues.primaryColor}`,
        color: theme.sectionValues.primaryColor,
        textTransform: 'none',
        borderRadius: calcRem(4),
        fontSize: '3.5vw',

        '&:focus': {
            borderColor: colors.thewest.greyGorilla,
        },

        '&:hover': {
            backgroundColor: colors.thewest.greyGorilla,
            color: colors.white,
            borderColor: colors.thewest.greyGorilla,
        },

        marginTop: calcRem(16),

        [breakpoint('xxs')]: {
            marginTop: calcRem(16),
            fontSize: calcRem(18),
        },

        [breakpoint('xs')]: {
            marginTop: calcRem(16),
            fontSize: calcRem(18),
        },
    }),
)

export const StyledTheWestLogo = styled(TheWestLogo)({
    marginTop: calcRem(40),
    width: '80%',

    [breakpoint('xs')]: {
        marginTop: calcRem(40),
        width: calcRem(395),
    },
    [breakpoint('sm')]: {
        marginTop: calcRem(40),
    },
    [breakpoint('md')]: {
        marginTop: calcRem(40),
    },
})

export const StyledLogoImg = styled('img')({
    marginTop: calcRem(40),
    width: '80%',

    [breakpoint('xs')]: {
        marginTop: calcRem(40),
        width: calcRem(395),
    },
    [breakpoint('sm')]: {
        marginTop: calcRem(40),
    },
    [breakpoint('md')]: {
        marginTop: calcRem(40),
    },
})

export const StyledSvg = styled('svg')({})

export const StyledText = styled('text')({
    fontSize: '3.7vw',
    fontWeight: 400,
    fontFamily: fonts.thewest.serif,
    marginTop: calcRem(16),
    marginBottom: '6vw',
    [breakpoint('xxs')]: {
        marginBottom: calcRem(56),
    },
    [breakpoint('xs')]: {
        fontSize: calcRem(22),
    },
})

export const StyledFaqText = styled('text')({
    fontSize: '3vw',
    fontWeight: 400,
    fontFamily: fonts.thewest.serif,
    marginTop: calcRem(16),

    [breakpoint('xs')]: {
        fontSize: calcRem(18),
    },
})

export const StyledImage = styled('img')({
    width: '60%',
    height: 'auto',
    marginTop: calcRem(32),

    [breakpoint('xs')]: {
        width: calcRem(268),
        height: 'auto',
    },
})
