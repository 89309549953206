export const enum KeyCode {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    PAUSE_BREAK = 19,
    CAPS_LOCK = 20,
    ESCAP = 27,
    SPACE = 32,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
    INSERT = 45,
    DELETE = 46,
    ZERO = 48,
    CLOSED_PAREN = 48,
    ONE = 49,
    EXCLAMATION_MARK = 49,
    TWO = 50,
    AT_SIGN = 50,
    THREE = 51,
    POUND_SIGN = 51,
    HASH = 51,
    FOUR = 52,
    DOLLAR_SIGN = 52,
    FIVE = 53,
    PERCENT_SIGN = 53,
    SIX = 54,
    CARET = 54,
    HAT = 54,
    SEVEN = 55,
    AMPERSAND = 55,
    EIGHT = 56,
    STAR = 56,
    ASTERISK = 56,
    NINE = 57,
    OPEN_PAREN = 57,
    A = 65,
    B = 66,
    C = 67,
    D = 68,
    E = 69,
    F = 70,
    G = 71,
    H = 72,
    I = 73,
    J = 74,
    K = 75,
    L = 76,
    M = 77,
    N = 78,
    O = 79,
    P = 80,
    Q = 81,
    R = 82,
    S = 83,
    T = 84,
    U = 85,
    V = 86,
    W = 87,
    X = 88,
    Y = 89,
    Z = 90,
    LEFT_WINDOW = 91,
    RIGHT_WINDOW = 92,
    SELECT = 93,
    NUMPAD_0 = 96,
    NUMPAD_1 = 97,
    NUMPAD_2 = 98,
    NUMPAD_3 = 99,
    NUMPAD_4 = 100,
    NUMPAD_5 = 101,
    NUMPAD_6 = 102,
    NUMPAD_7 = 103,
    NUMPAD_8 = 104,
    NUMPAD_9 = 105,
    MULTIPLY = 106,
    ADD = 107,
    SUBTRACT = 109,
    DECIMAL_POINT = 110,
    DIVIDE = 111,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    NUM_LOCK = 144,
    SCROLL_LOCK = 145,
    SEMI_COLON = 186,
    EQUALS = 187,
    COMMA = 188,
    DASH = 189,
    PERIOD = 190,
    UNDER_SCORE = 189,
    PLUS_SIGN = 187,
    FORWARD_SLASH = 191,
    TILDE = 192,
    GRAVE_ACCENT = 192,
    OPEN_BRACKET = 219,
    CLOSED_BRACKET = 221,
    QUOTE = 222,
}
