import { useTheme } from '@emotion/react'
import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    getTimeRoundedMax,
    isPublicationCardItemWithVideo,
    Product,
    PublicationCardItem,
    PublicationCardItemWithoutVideo,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    getLandscapeWrapperTag,
    StyledCardHeadlineSkeleton,
    StyledFundingLabel,
    StyledHeader,
    StyledHeadline,
    StyledInlineWrapper,
    StyledLandscapeLink,
    StyledMedia,
    StyledTeaser,
    StyledTimestamp,
} from '../../cards/Landscape/Landscape.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { ResponsivePictureSizes } from '../../content/Picture/responsive'
import { CommentButton } from '../../coral/CommentButton/CommentButton'
import { getEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { useProduct } from '../../__product/useProduct'
import { FontScales } from '../../__styling/settings/fontScale'
import { themedValue } from '../../__styling/themed-value'
import { CardByline } from '../CardByline/CardByline'
import { MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'
import { KickerMode } from '../Kicker/Kicker'

export type LandscapeImageSize = 'small' | 'normal' | 'large' | 'medium'
export interface LandscapeProps extends CommonCardProps {
    className?: string
    cardNumber: number

    asListElement?: boolean
    teaserMode: TeaserMode
    hasBackground?: boolean
    hasPadding?: boolean
    hideBorder?: boolean
    hideByline?: boolean
    mediaMode?: MediaMode
    kickerMode?: KickerMode
    timestamp?: TimestampType

    // TODO: address multiple font scales.
    fontScale?: FontScales
    teaserFontScale?: FontScales

    // Images
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    imageWidths?: ResponsivePictureSizes
    imagePosition?: 'left' | 'right'
    imageSize?: LandscapeImageSize
    item: MaybeLoaded<PublicationCardItem>
    isInlineRelatedCollection?: boolean
}

export const Landscape: React.FC<LandscapeProps> = (props) => {
    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            e.preventDefault()
            return
        }
        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalLandscape',
                'Landscape',
                props.cardNumber,
                fundingType,
                props.isInlineRelatedCollection
                    ? 'InlineRelatedCollection'
                    : undefined,
            ),
        )
    }

    const theme = useTheme()
    const product = useProduct()
    const cardItem = props.item

    // Sponsored
    const storyClassification = true
    const fundingType = cardItem.loaded
        ? getFundingType(cardItem.result, storyClassification)
        : undefined

    const editorialType = cardItem.loaded
        ? getEditorialType(cardItem.result, storyClassification)
        : undefined

    const showByline = props.hideByline === false

    // Images
    const imageSize = props.imageSize || 'normal'
    const setImagePosition = props.imagePosition || 'left' // Default image position to left so it doesn't break existing cards
    const imageRatio = props.fixedRatio || '4:3'
    const imageRight = props.imagePosition === 'right'
    const imageLeft = props.imagePosition !== 'right'

    const hideBorder = props.hideBorder || false

    const StyledLandscape = getLandscapeWrapperTag(
        props.asListElement ? 'li' : 'div',
    )
    // Comments
    const commentToggle = useFeature('comments')
    const isPerthNow = product === Product.PerthNow

    const showPerthNowComments =
        cardItem.loaded &&
        cardItem.result.allowCommenting &&
        isPerthNow &&
        commentToggle

    const fontScale =
        props.fontScale ||
        (themedValue(theme, {
            thewest: 0.75,
            perthnow: 0.75,
            sevennews: 0.83,
            fallback: 1,
        }) as FontScales)

    const getKickerText = (cardItem: PublicationCardItemWithoutVideo) => {
        let time: string | undefined

        if (
            props.timestamp === 'rounded-in-kicker' &&
            cardItem.publicationDate
        ) {
            time = getTimeRoundedMax(cardItem.publicationDate)
        }

        return time ? time : cardItem.kicker
    }

    const defaultImageWidths = themedValue(theme, {
        thewest: {
            mobile: '110px',
            tablet: '95px',
            desktop: '140px',
            fallbackWidth: 140,
            mode: 'fixed',
        },
        perthnow: {
            mobile: '140px',
            tablet: '140px',
            desktop: '140px',
            fallbackWidth: 140,
            mode: 'fixed',
        },
        sevennews: {
            mobile: '140px',
            tablet: '140px',
            desktop: '140px',
            fallbackWidth: 140,
            mode: 'fixed',
        },
        thenightly: {
            mobile: '80px',
            tablet: '120px',
            desktop: '96px',
            fallbackWidth: 140,
            mode: 'fixed',
        },
        fallback: {
            mobile: '140px',
            tablet: '140px',
            desktop: '140px',
            fallbackWidth: 140,
            mode: 'fixed',
        },
    }) as ResponsivePictureSizes

    const renderImage = (
        <StyledMedia
            item={cardItem}
            imageWidths={
                props.imageWidths ? props.imageWidths : defaultImageWidths
            }
            fixedRatio={imageRatio}
            onEvent={props.onEvent}
            disableImageLazyLoad={props.disableImageLazyLoad}
            imagePosition={setImagePosition}
            imageSize={imageSize}
            mediaMode={props.mediaMode}
        />
    )

    const renderHeadline = cardItem.loaded ? (
        <StyledHeadline
            kickerText={getKickerText(cardItem.result)}
            headlineText={cardItem.result.shortHeadline}
            showVideoIcon={
                props.timestamp
                    ? false
                    : isPublicationCardItemWithVideo(cardItem.result)
            }
            kickerMode={props.kickerMode}
            isSponsored={!!fundingType}
            publicationKind={cardItem.result.publicationKind}
            requiredAccess={cardItem.result.requiredAccess}
            fontScale={fontScale}
            imageSize={imageSize}
            editorialType={editorialType}
            showCommentsIcon={cardItem.result.allowCommenting}
        />
    ) : (
        <StyledCardHeadlineSkeleton fontScale={fontScale} count={3} />
    )

    return (
        <StyledLandscape
            className={props.className}
            data-topic={cardItem.loaded && cardItem.result.primaryTopic.id}
            showSponsoredStyles={!!fundingType}
            hasBackground={props.hasBackground || false}
            hasPadding={props.hasPadding}
            hideBorder={hideBorder}
        >
            <StyledLandscapeLink
                to={toLinkWithHint(cardItem)}
                onClick={linkClicked}
                isLoading={!cardItem.loaded}
                hasBackground={false}
            >
                {/* If not hiding, and we want image on the left! */}
                {props.mediaMode === 'hidden' ? null : imageLeft && renderImage}

                <StyledHeader>
                    {renderHeadline}
                    {cardItem.loaded && (
                        <StyledTeaser
                            teaserMode={props.teaserMode}
                            teaserOrSkeletonLines={
                                cardItem.loaded ? cardItem.result.teaser : 1
                            }
                            teaserFontScale={props.teaserFontScale}
                        />
                    )}
                    {cardItem.loaded && (
                        <StyledInlineWrapper>
                            {showByline && (
                                <CardByline
                                    item={cardItem}
                                    onEvent={props.onEvent}
                                />
                            )}

                            {props.timestamp === 'full-at-bottom' && (
                                <StyledTimestamp
                                    time={cardItem.result.publicationDate}
                                    timestampStyle="full"
                                />
                            )}
                        </StyledInlineWrapper>
                    )}
                    {fundingType && cardItem.loaded && (
                        <StyledFundingLabel
                            source={cardItem.result.byline}
                            fundingType={fundingType}
                        />
                    )}
                    {showPerthNowComments && <CommentButton />}
                </StyledHeader>

                {/* If not hiding  and we want image on the right! */}
                {props.mediaMode === 'hidden'
                    ? null
                    : imageRight && renderImage}
            </StyledLandscapeLink>
        </StyledLandscape>
    )
}
Landscape.displayName = 'Landscape'
