import { CSSObject } from '@emotion/css'
import { colors } from '../__styling/settings/colors'
import { easing } from '../__styling/settings/easing'
import { zIndex } from '../__styling/settings/z-index'
import { calcRem } from './style-functions/calc-rem'
import { Theme } from './themes'

export const visuallyhidden: CSSObject = {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    width: '1px',
    height: '1px',
}

export const visuallyhiddenOff: CSSObject = {
    position: 'static',
    overflow: 'visible',
    clip: 'auto',
    width: 'auto',
    height: 'auto',
}

export const horizontalDividers: CSSObject = {
    content: `''`,
    width: '100%',
    height: 1,
    position: 'absolute',
    left: 0,
    bottom: 0,
    backgroundColor: colors.thewest.greyDolphin,
    zIndex: zIndex.common.collectionDividers,
}

export const verticalDividers: CSSObject = {
    content: `''`,
    width: 1,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateX(-6px)',
    backgroundColor: colors.thewest.greyDolphin,
    zIndex: zIndex.common.collectionDividers,
}

export const miniDivider = (props: {
    theme: Theme
    dividerColor?: string
    spacing?: number
}): CSSObject => {
    return {
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: 49,
            height: 3,
            bottom: props.spacing
                ? calcRem(-props.spacing / 2)
                : calcRem(-props.theme.margins.lg / 2),
            backgroundColor: props.dividerColor,
        },
    }
}

/**
 * Simplified short-hand mixin to make including multiple transition-properties more concise.
 * @param props.properties string[] list of properties to apply the transition to
 * @param props.speed string how fast the transition should be. defaults to fast
 */
export const transition = (props: {
    properties?: string[]
    speed?: 'fast' | 'med' | 'slow'
}): CSSObject => {
    const timing = easing.easeOut.timingFunction
    const properties = props.properties ? props.properties.join(',') : 'all'
    const duration = props.speed
        ? easing.duration[props.speed]
        : easing.duration.fast

    // use shorthand if possible to reduce CSS output
    // we check for comma here instead of props.properties.length because
    // some styles do ['prop1,prop2'] and we want to catch that
    if (properties.indexOf(',') === -1) {
        return {
            transition: `${properties} ${duration} ${timing}`,
        }
    } else {
        return {
            transitionTimingFunction: timing,
            transitionProperty: properties,
            transitionDuration: duration,
        }
    }
}

export const breakWords: CSSObject = {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word' as any, // non-standard for WebKit
}

export const clearFix: CSSObject = {
    '&::after': {
        content: '""',
        display: 'table',
        clear: 'both',
    },
}

/**
 *  This mixin crops the extra spacing below at the baseline and above at the cap height
 * This let's us set correct margins so we don't have to account for the extra spacing.
 * http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=ABeeZee&custom-typeface-name=Guardian&custom-typeface-url=https%3A%2F%2Fthewest.com.au%2Fstatic%2Ffonts%2FGuardianSansCond-Bold-Web.eot&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=regular&size=36&line-height=1.2&top-crop=9&bottom-crop=8
 * DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
 * Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output. You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings
 * @param props.fontSize the size of the font
 * @param props.desiredLineHeight the desired line height
 * @param props.cropLineHeight the crop line height
 * @param props.topAdjustment an optional top adjustment value
 * @param props.bottomAdjustment an optional bottom adjustment value
 */

export const textCrop = (props: {
    fontSize: number
    desiredLineHeight: number
    cropLineHeight: number
    topAdjustment?: number
    bottomAdjustment?: number
    topCrop?: number
    bottomCrop?: number
}): CSSObject => {
    const topCrop = props.topCrop !== undefined ? props.topCrop : 11
    const bottomCrop = props.bottomCrop !== undefined ? props.bottomCrop : 9

    const topAdjustmentValue = props.topAdjustment ? props.topAdjustment : 0
    const bottomAdjustmentValue = props.bottomAdjustment
        ? props.bottomAdjustment
        : 0

    const dynamicTopCrop =
        Math.max(
            topCrop +
                (props.desiredLineHeight - props.cropLineHeight) *
                    (props.fontSize / 2),
            0,
        ) / props.fontSize

    const dynamicBottomCrop =
        Math.max(
            bottomCrop +
                (props.desiredLineHeight - props.cropLineHeight) *
                    (props.fontSize / 2),
            0,
        ) / props.fontSize

    return {
        lineHeight: props.desiredLineHeight,

        '&::before, &::after': {
            content: `''`,
            display: 'block',
            height: 0,
            width: 0,
        },

        '&::before': {
            marginBottom: `calc(-${dynamicTopCrop}em + ${topAdjustmentValue}px)`,
        },

        '&::after': {
            marginTop: `calc(-${dynamicBottomCrop}em + ${bottomAdjustmentValue}px)`,
        },
    }
}

/**
 * A fluid typography mixin which will scale the font with the browser to a set minimum value and a set maximum value.
 * @param minScreen a number setting the minimum screen size
 * @param maxScreen a number setting the maximum screen size
 * @param minValue a number setting the minimum font size
 * @param maxValue a number setting the maximum font size
 */
export const fluidTypography = (
    minScreen: number,
    maxScreen: number,
    minValue: number,
    maxValue: number,
): CSSObject => {
    return {
        fontSize: calcInterpolation(minScreen, minValue, maxScreen, maxValue),
    }
}

export const calcInterpolation = (
    minScreen: number,
    minValue: number,
    maxScreen: number,
    maxValue: number,
): string => {
    const a = (maxValue - minValue) / (maxScreen - minScreen)
    let b = minValue - a * minScreen
    let sign = '+'

    if (b < 0) {
        sign = '-'
        b = Math.abs(b)
    }

    // I had to add in a restriction on the decimal places because IE11 was having a bad time
    // It can be removed when we drop IE11.
    return `calc(${a.toFixed(6)} * 100vw ${sign} ${b.toFixed(6)}px)`
}

export const hexToRGBA = (hexColor: string, opacity = 1) => {
    if (hexColor.charAt(0) === '#') {
        hexColor = hexColor.slice(1)
    }

    if (hexColor.length !== 3 && hexColor.length !== 6) {
        throw new Error('Incorrect hex value supplied')
    }

    if (hexColor.length === 3) {
        hexColor =
            hexColor[0] +
            hexColor[0] +
            hexColor[1] +
            hexColor[1] +
            hexColor[2] +
            hexColor[2]
    }

    const rgbColor = hexColor
        .match(/.{1,2}/g)!
        .map((section) => parseInt(section, 16))
        .join(',')

    return `rgba(${rgbColor},${opacity})`
}
