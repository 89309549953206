import { WebLink } from '@news-mono/web-common'
import React from 'react'
import {
    StyledHeadingFlexRow,
    StyledTitle,
} from './OlympicsWidgetHeading.styled'
import { OlympicWidgetLinks } from '../OlympicsWidgetLinks/OlympicsWidgetLinks'
import { OlympicsWidgetSize } from '../OlympicsWidgetWrap/OlympicsWidgetWrap'
import { SidebarHeader } from '../SidebarHeader/SidebarHeader'
import { useTheme } from '@emotion/react'

export interface OlympicWidgetHeadingProps {
    size: OlympicsWidgetSize
    pageType: string
    svgColourOverride?: string
    widgetTitle?: string
    hideLinks?: boolean
}

function returnWidgetTitle(pageType: string) {
    return pageType.replace(/-/g, ' ')
}

export function OlympicWidgetHeading(props: OlympicWidgetHeadingProps) {
    const { pageType, size, svgColourOverride, widgetTitle, hideLinks } = props
    const theme = useTheme()
    return (
        <StyledHeadingFlexRow size={size}>
            {size === 'full' ? (
                <>
                    {theme.kind === 'thenightly' && (
                        <WebLink
                            to={`/sport/olympics/${widgetTitle ?? pageType}`}
                        >
                            <StyledTitle size={size}>
                                {returnWidgetTitle(widgetTitle ?? pageType)}
                            </StyledTitle>
                        </WebLink>
                    )}
                    <SidebarHeader
                        svgColourOverride={svgColourOverride}
                        size={size}
                    />
                    {!hideLinks && (
                        <OlympicWidgetLinks size={size} pageType={pageType} />
                    )}
                </>
            ) : (
                <>
                    <WebLink to={`/sport/olympics/${widgetTitle ?? pageType}`}>
                        <StyledTitle size={size}>
                            {returnWidgetTitle(widgetTitle ?? pageType)}
                        </StyledTitle>
                    </WebLink>
                    <SidebarHeader
                        svgColourOverride={svgColourOverride}
                        size={size}
                    />
                </>
            )}
        </StyledHeadingFlexRow>
    )
}
