import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import React from 'react'
import { LazyGeneralInArticleBanner } from '../../../banners/GenericInArticleBanner'
import {
    InlineContentTypes, InlineVanishingCousinsBanner,
} from '../SharedPublication.routing'
export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineVanishingCousinsBanner(
    content: InlineContentTypes,
): content is InlineVanishingCousinsBanner {
    return content.kind === 'inline-vanishing-cousins-banner'
}

export function getInlinedPositionedVanishingCousinsBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    const validTopics = [
        'news/crime',
        'news/court-justice',
    ]

    if (
        !validTopics.includes(publication.primaryTopic.id) &&
        !publication.secondaryTopics.find((topic) =>
            validTopics.includes(topic.id),
        )
    ) {
        return []
    }

    // /features/vanishing-cousins/vanishing-cousins-episode-1-the-vanishing-c-12301866
    // TODO: Maybe find a way to force in a PublicationItem ?

    return inlinePublicationContent
        .filter(isInlineVanishingCousinsBanner)
        .map((content: InlineVanishingCousinsBanner) => {
            return {
                position: content.insertAfter,
                element: (
                    <LazyGeneralInArticleBanner
                        onEvent={onEvent}
                        {...content.props}
                    />
                ),
            }
        })
}
