import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { WebLink, WebLinkProps } from '@news-mono/web-common'
import { LinkProps } from 'react-router-dom'
import { filterComponent } from '../../__helpers/filter-component'
import { breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { styledMediaClassName } from '../CardMedia/StyledMedia.styled'
import { CardText } from '../CardText/CardText'
import { StyledHeadlineText } from '../CardText/CardText.styled'
import { StyledKicker } from '../Kicker/Kicker.styled'
import { styledWrapperClassName } from '../LandscapeWide/Styled/StyledText.styled'

export interface StyledPromotionalCardCardProps {
    verticalSpacing?: keyof ThemeMargins
    isLoading?: boolean
    mobileOnly?: true
    cardTheme: string
}

export const StyledPromotionalCard = styled(
    'aside',
)<StyledPromotionalCardCardProps>(
    ({ theme, verticalSpacing, isLoading, mobileOnly, cardTheme }) => [
        {
            ...breakWords,
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: 1,

            fontSize: themedValue(theme, {
                thewest: calcRem(13),
                fallback: calcRem(14),
            }),
            backgroundColor: !isLoading
                ? themedValue(theme, {
                      perthnow: colors.perthnow.greenTourmaline,
                      thewest: colors.white,
                      fallback: 'transparent',
                  })
                : 'transparent',
            alignItems: themedValue(theme, {
                perthnow: 'flex-start',
                fallback: 'stretch',
            }),
            boxShadow: themedValue(theme, {
                thewest: `0 0 8px 0 ${colors.black20}`,
                fallback: undefined,
            }),

            marginBottom:
                verticalSpacing && calcRem(theme.margins[verticalSpacing]),

            [breakpointBetween('sm', 'md')]: {
                maxWidth: calcRem(300),

                display: themedValue(theme, {
                    thewest: 'none',
                    fallback: undefined,
                }),
            },
        },

        mobileOnly && {
            maxWidth: calcRem(breakpoints.xs),
            marginRight: 'auto',
            marginLeft: 'auto',

            [breakpoint('md')]: {
                display: 'none',
            },
        },

        // DPT-2343: 'Key Promo' theme styles
        theme.kind === 'thewest' &&
            cardTheme === 'key' && {
                ...keyPromoStyles,
            },
    ],
)

export const HoverStylesPerthNow: CSSObject = {
    [`& ${StyledHeadlineText}`]: {
        boxShadow: `6px 0 ${colors.perthnow.greyBasalt}, 6px 2px ${colors.perthnow.greyBasalt}, -3px 0 ${colors.perthnow.greyBasalt}, -3px 2px ${colors.perthnow.greyBasalt}`,
        boxDecorationBreak: 'clone',
        background: colors.perthnow.greyBasalt,
    },
}

const FilteredWebLink = filterComponent<WebLinkProps, string>(WebLink, [
    'theme',
    'hasPadding',
    'isLoading',
    'cardTheme',
])

export interface StyledLinkProps extends LinkProps {
    hasPadding?: boolean
    isLoading: boolean
    cardTheme?: string
}
export const StyledLink = styled(FilteredWebLink)<StyledLinkProps>(
    ({ theme, hasPadding, isLoading, cardTheme }) => ({
        textDecoration: 'none',
        display: 'flex',
        width: '100%',
        textDecorationSkipInk: 'auto',
        flexFlow: 'column nowrap',

        color: themedValue(theme, {
            perthnow: colors.white,
            fallback: colors.black,
        }),
        padding: hasPadding ? calcRem(theme.cards.outerMargin) : undefined,
        paddingBottom: themedValue(theme, {
            perthnow: calcRem(theme.cards.outerMargin),
            thewest: calcRem(theme.cards.outerMargin),
            sevennews: undefined,
            fallback: undefined,
        }),

        [`& .${styledMediaClassName}, & + .${styledWrapperClassName} .${styledMediaClassName}`]:
            {
                borderBottom:
                    !isLoading && cardTheme !== 'key'
                        ? themedValue(theme, {
                              thewest: `2px solid ${colors.thewest.westblue}`,
                              fallback: undefined,
                          })
                        : undefined,
            },

        '&:focus': {
            outline: 'none',

            [`& ${StyledHeadlineText}`]: {
                textDecoration: 'underline',
            },

            [`& .${styledMediaClassName}, & + .${styledWrapperClassName} .${styledMediaClassName}`]:
                {
                    '&::after': {
                        borderColor: themedValue(theme, {
                            perthnow: colors.perthnow.greyCoal,
                            fallback: 'transparent',
                        }),
                    },
                },

            [`& ${StyledKicker}`]: {
                backgroundColor: themedValue(theme, {
                    thewest: colors.black,
                    fallback: undefined,
                }),
            },
        },

        '&:hover': {
            [`& ${StyledKicker}`]: {
                backgroundColor: themedValue(theme, {
                    thewest:
                        cardTheme !== 'key'
                            ? (section) => section.primaryColor
                            : undefined,
                    fallback: undefined,
                }),
            },
            [`& .Card-Media`]: {
                borderBottom: themedValue(theme, {
                    thewest:
                        cardTheme !== 'key'
                            ? (section) => `2px solid ${section.primaryColor}`
                            : undefined,
                    fallback: undefined,
                }),
            },
            [`& ${StyledHeadlineText}`]: {
                color: themedValue(theme, {
                    thewest: (section) => section.primaryColor,
                    fallback: colors.white,
                }),
            },
            ...(theme.kind === 'perthnow' && HoverStylesPerthNow),
        },
    }),
)

interface StyledCardTextContainerProps {
    applyNegativeMarginToCardText?: string | false // DPT-2190 HeadlineText overlaps CardMedia if kicker is missing
}

export const StyledCardTextContainer = styled(
    'div',
)<StyledCardTextContainerProps>(({ theme, applyNegativeMarginToCardText }) => ({
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'center',
    marginTop: applyNegativeMarginToCardText
        ? calcRem(-theme.margins.md)
        : undefined,
    width: '100%',
}))

interface StyledCardTextProps {
    isLarge?: boolean
}

export const StyledCardText = styled(CardText)<StyledCardTextProps>(
    ({ theme }) => {
        return {
            [`& ${StyledHeadlineText}`]: {
                display: 'inline',
                fontFamily: themedValue(theme, {
                    thewest: fonts.thewest.sansSerif,
                    fallback: undefined,
                }),
                lineHeight: themedValue(theme, {
                    thewest: 1.2,
                    fallback: undefined,
                }),
                color: themedValue(theme, {
                    perthnow: colors.white,
                    fallback: colors.black,
                }),

                [breakpointMax('md')]: {
                    fontSize:
                        theme.kind === 'thewest' ? calcRem(16) : undefined, // Make all portrait card font sizes the same on smaller screens
                },
            },

            [`& ${StyledKicker}`]: {
                backgroundColor: themedValue(theme, {
                    thewest: colors.thewest.westblue,
                    fallback: undefined,
                }),
                padding: themedValue(theme, {
                    thewest: calcRem(5, 10),
                    fallback: undefined,
                }),
                color: themedValue(theme, {
                    thewest: colors.white,
                    fallback: undefined,
                }),
                marginBottom: themedValue(theme, {
                    thewest: calcRem(theme.margins.md),
                    fallback: undefined,
                }),
                fontWeight: themedValue(theme, {
                    perthnow: 600,
                    thewest: 600,
                    sevennews: 700,
                    fallback: undefined,
                }),
                textTransform: 'capitalize',
            },
        }
    },
)

export const StyledCTA = styled('p')(({ theme }) => ({
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: calcRem(theme.margins.md, 0),
    fontFamily: theme.fonts.sansSerif,
    color: themedValue(theme, {
        thewest: (section) => section.primaryColor,
        fallback: undefined,
    }),
}))

const keyPromoStyles: CSSObject = {
    backgroundColor: 'transparent',
    background: `linear-gradient(180deg, #043DE2 0%, #043DE2 70%, #0A70F3 100%)`,

    [`& ${StyledCardText} ${StyledHeadlineText}`]: {
        color: colors.white,
        fontFamily: fonts.thewest.sansSerif,
    },

    [`& .${styledMediaClassName}`]: {
        width: 'auto',
        margin: 0,
        borderBottom: `1px solid ${colors.white}`,
    },

    [`& ${StyledCardTextContainer}`]: {
        width: 'auto',
        padding: calcRem(metrics.thewest.cards.outerMargin),
        marginTop: calcRem(-16),
        position: 'relative',
    },

    [`& ${StyledCardTextContainer} ${StyledCTA}`]: {
        background: colors.white,
        border: `1px solid ${colors.white}`,
        color: colors.thewest.westblue,
        borderRadius: 20,
        alignSelf: 'center',
        padding: calcRem(4, 20, 6),
        boxShadow: `0 6px 13px 0 rgba(0,29,86,0.60)`,
        margin: calcRem(16),
    },

    [`& ${StyledCardText}`]: {
        textAlign: 'center',
    },

    [`& ${StyledCardText} ${StyledKicker}`]: {
        background: colors.white,
        color: colors.thewest.westblue,
    },

    [`& ${StyledLink}`]: {
        padding: 0,
        display: 'block',
    },

    [`& ${StyledLink}:hover`]: {
        [`& ${StyledHeadlineText}`]: {
            color: colors.white,
            textDecoration: 'underline',
        },
        [`& ${StyledCTA}`]: {
            background: 'transparent',
            boxShadow: 'none',
            color: colors.white,
        },
    },

    [`& ${StyledLink}:focus`]: {
        background: colors.thewest.westblueTint,

        [`& ${StyledHeadlineText}`]: {
            color: colors.black,
        },

        [`& ${StyledKicker}`]: {
            backgroundColor: colors.black,
            color: colors.white,
        },
    },
}
