import { Intention } from '@news-mono/web-common'
import {
    EmphasizedIntention,
    HierarchicalIntent,
    ImportantIntention,
    LineBreakIntention,
    LinkIntention,
    TextIntention,
} from '../../../typography/TextItem/TextItem'

interface IntermediateIntent extends Intention {
    match: string
    matchIndex: number
    endIndex: number
    nestedIntents: IntermediateIntent[]
}

// Pulled out for speed
function iterateNestedIntents(nested: IntermediateIntent[]) {
    const arr: HierarchicalIntent[] = []
    for (let i = 0; i < nested.length; i++) {
        arr.push(toHierachicalIntent(nested[i]))
    }
    return arr
}

function toHierachicalIntent(
    intermediateIntent: IntermediateIntent,
): HierarchicalIntent {
    const textIntent: TextIntention = {
        kind: 'text',
        value: intermediateIntent.match,
        nestedIntents: iterateNestedIntents(intermediateIntent.nestedIntents),
    }

    switch (intermediateIntent.kind) {
        case 'emphasized': {
            const emphasizedIntent: EmphasizedIntention = {
                kind: intermediateIntent.kind,
                nestedIntents: iterateNestedIntents(
                    intermediateIntent.nestedIntents,
                ),
            }

            return emphasizedIntent
        }

        case 'important': {
            const importantIntent: ImportantIntention = {
                kind: intermediateIntent.kind,
                nestedIntents: iterateNestedIntents(
                    intermediateIntent.nestedIntents,
                ),
            }

            return importantIntent
        }

        case 'link': {
            if (intermediateIntent.href) {
                const linkIntent: LinkIntention = {
                    kind: intermediateIntent.kind,
                    href: intermediateIntent.href,
                    nestedIntents: iterateNestedIntents(
                        intermediateIntent.nestedIntents,
                    ),
                }
                return linkIntent
            }

            return textIntent
        }

        case 'line-break': {
            const lineBreakIntention: LineBreakIntention = {
                kind: 'line-break',
                nestedIntents: [],
            }

            return lineBreakIntention
        }

        case 'text':
        default:
            return textIntent
    }
}

/* Initial call
   ------------
    insertText([{
        kind: 'emphasized',
        match: 'text with nested',
        index: 0,
        matchIndex: 5,
        endIndex: 21,
        nestedIntents: [
            { kind: 'important',
                match: 'text',
                index: 0,
                matchIndex: 5,
                endIndex: 9,
                nestedIntents: []
            }
        ]
    }], Some text with nested intentions)

    returns: [Some, <em>...</em>, intentions] (where ... is a recusive call)

    Recursive call
    --------------
    insertText([{
        kind: 'important',
        match: 'text',
        index: 0,
        matchIndex: 5,
        endIndex: 9,
        nestedIntents: []
    }], Some text with nested, 5)

    The fallbackStartIndex is needed to stop nested intentions grabbing too much leading text
*/
function convertToElementHierachy(
    reduced: IntermediateIntent[],
    text: string,
    fallbackStartIndex = 0,
) {
    const withText: HierarchicalIntent[] = []
    for (let i = 0; i < reduced.length; i++) {
        const curr = reduced[i]
        const start = i === 0 ? fallbackStartIndex : reduced[i - 1].endIndex
        const end = curr.matchIndex
        const plainText = text.substring(start, end)
        // Only add leading text if it exists, if an intent is directly nested,
        // plainText will be empty
        if (plainText) {
            withText.push({ value: plainText, kind: 'text', nestedIntents: [] })
        }
        const converted = toHierachicalIntent(curr)
        withText.push(converted)
        // When element has no nested intents then just use the match as the nested intent
        // so the text ends up as a child
        if (converted.nestedIntents.length === 0) {
            converted.nestedIntents.push({
                value: curr.match,
                kind: 'text',
                nestedIntents: [],
            })
        } else {
            const textWithoutEnd = text.substring(0, curr.endIndex)
            converted.nestedIntents = convertToElementHierachy(
                curr.nestedIntents,
                textWithoutEnd,
                end,
            )
        }
    }

    const endIndex = reduced[reduced.length - 1].endIndex

    // Finally add any remaining text not included in an intent
    if (endIndex < text.length) {
        withText.push({
            value: text.substring(endIndex),
            kind: 'text',
            nestedIntents: [],
        })
    }

    return withText
}

function buildHierarchy(
    aggregate: IntermediateIntent[],
    currentIntent: IntermediateIntent,
) {
    if (aggregate.length === 0) {
        aggregate.push(currentIntent)
        return aggregate
    }

    // Find the correct spot in the array for us
    for (let i = 0; i < aggregate.length; i++) {
        const curr = aggregate[i]

        if (curr.matchIndex > currentIntent.matchIndex) {
            // It belongs before current element
            aggregate.splice(i, 0, currentIntent)
            return aggregate
        }
        // Current item in aggregate envelops the currentIntent
        else if (
            curr.matchIndex <= currentIntent.matchIndex &&
            curr.endIndex >= currentIntent.endIndex
        ) {
            // Current reduce item is between the current index item, need to recursively build the hierarchy
            curr.nestedIntents = buildHierarchy(
                curr.nestedIntents,
                currentIntent,
            )
            return aggregate
        }
    }

    // it belongs inline
    aggregate.push(currentIntent)
    return aggregate
}

export function createIntents(
    intentions: Intention[],
    text: string,
): HierarchicalIntent[] {
    if (intentions.length === 0) {
        return [{ value: text, kind: 'text', nestedIntents: [] }]
    }

    const intentHierarchySorted = intentions.sort((intentionA, intentionB) => {
        const intentionAText = text.substr(intentionA.index, intentionA.length)
        const intentionBText = text.substr(intentionB.index, intentionB.length)
        if (intentionAText.length < intentionBText.length) {
            return 1
        }
        if (intentionAText.length > intentionBText.length) {
            return -1
        }
        return 0
    })

    const intentHierarcyMapped = intentHierarchySorted.map<IntermediateIntent>(
        (intention) => {
            const position = intention.index
            const matchIndex = position
            const endIndex = position + intention.length
            return {
                ...intention,
                match: text.substr(intention.index, intention.length),
                matchIndex,
                endIndex,
                nestedIntents: [],
            }
        },
    )

    // reduce the mapped hierarchy
    const intentHierarcy: IntermediateIntent[] = intentHierarcyMapped.reduce(
        buildHierarchy,
        [],
    )

    // Now that we have the intent hierarchy,
    // we need to insert the text elements into it
    return convertToElementHierachy(intentHierarcy, text)
}
