import React from 'react'

export default function TVGuideSideBarWidgetTwo() {
    return (
        <iframe
            id="tvguidetonight-widget-shows"
            src="https://tvguidetonight.com.au/widgets/plus-shows.html"
            style={{ height: '603px', border: 'none' }}
            title=""
        ></iframe>
    )
}
