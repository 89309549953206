import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AmpGoogleAnalyticsFour } from './AmpGoogleAnalyticsFour'
import { AmpGoogleAnalyticsFourProps } from './AmpGoogleAnalyticsFourProps'

export const AmpGoogleAnalyticsFourRegistration = createRegisterableComponent(
    'amp-google-analytics-four',
    (props: AmpGoogleAnalyticsFourProps) => {
        return <AmpGoogleAnalyticsFour {...props} />
    },
)
