import { shuffleArray } from '@news-mono/common'
import {
    DataLoaderGlobalParams,
    getRecommendations,
    PlaylistType,
    VideoQueueItemMeta,
} from '@news-mono/web-common'

export interface VideoQueue {
    items: VideoQueueItemMeta[]
    type: PlaylistType
}

export type VideoQueueItem = VideoQueueItemBasic | VideoQueueItemMeta

export interface VideoQueueItemBasic {
    id: string
    accountId: string
}

export function isVideoItemWithMeta(
    videoItem: VideoQueueItem,
): videoItem is VideoQueueItemMeta {
    return (videoItem as VideoQueueItemMeta).name !== undefined
}

/**
 * Gets the video queue for a publication based on it's topics
 */
export async function getVideoQueue(
    services: DataLoaderGlobalParams,
    publicationId: string,
    excludeVideoSeries?: boolean,
): Promise<VideoQueue> {
    const apiVideoListing = await getRecommendations(services, {
        publicationId,
        excludeVideoSeries,
    })

    /**
     * Comment this out as we are not using this toggle any more
     */
    // if (
    //     apiVideoListing.shortform.length > 0 &&
    //     isFeatureEnabled(
    //         toFeatureState(services.store.getState().toggles),
    //         'use-oovvuu-recommendation',
    //     )
    // ) {
    //     const videoQueuePopulated = await Promise.all(
    //         apiVideoListing.shortform.map<Promise<VideoQueueItemMeta>>((id) => {
    //             return getMetadata(
    //                 id,
    //                 apiVideoListing.recommendationsAccountId,
    //                 services.config,
    //                 services.log,
    //             )
    //         }),
    //     )

    //     return {
    //         items: videoQueuePopulated,
    //         type: 'oovvuu-recommendation',
    //     }
    // }

    return {
        items: shuffleArray(apiVideoListing.relatedVideos),
        type: 'topic-related',
    }
}
