import debounce from 'lodash.debounce'
import React from 'react'

const eventTypes = ['mousedown', 'touchstart'] as ['mousedown', 'touchstart']

function hasClassInHierarchy(element: Element, className: string): boolean {
    if (element.classList.contains(className)) {
        return true
    }
    return element.parentElement &&
        hasClassInHierarchy(element.parentElement, className)
        ? true
        : false
}

export type ClickOutsideEvent = MouseEvent | TouchEvent
export type ClickOutsideEventTrigger = (e: ClickOutsideEvent) => void

export interface ClickOutsideOptions {
    filterClasses?: string[]
    enabled?: boolean
}

export interface OnClickOutsideProps {
    setClickOutsiteTrigger: (newTrigger: ClickOutsideEventTrigger) => void
    clickOutsideRef: React.RefObject<any>
}

export interface WrapperProps {
    innerRef?:
        | ((instance: any) => void)
        | React.RefObject<any>
        | null
        | undefined
    onClickOutsideOptions?: ClickOutsideOptions
}

/**
 * A React hook for triggering a fuction when a user clicks outside of a particular element
 * @param {React.RefObject<HTMLElement | null>} ref - A react ref object, this should be the element you want to track outside clicks
 * @param {(e: MouseEvent | TouchEvent) => void} trigger - A function that is triggered when a click occurs outside
 * @param {Options.filterClasses} filterClasses - An optional array of classNames which will not trigger the outside click
 * @param {Options.enabled} enabled - Set "enabled" to false to not add the click effect to the page
 */
export const useOnClickOutside = (
    ref: React.RefObject<HTMLElement>,
    trigger: ClickOutsideEventTrigger,
    options?: ClickOutsideOptions,
) => {
    React.useEffect(() => {
        if (options && options.enabled === false) {
            return
        }

        // Handler is debounced, that way if both a mouse event and a touch event are called, they will
        // only trigger once
        const handleClickOutside = debounce(
            (e: MouseEvent | TouchEvent) => {
                const isInside =
                    e.target instanceof Element &&
                    ref.current &&
                    ref.current.contains(e.target)

                if (isInside) return

                const inClassfilter =
                    options &&
                    options.filterClasses &&
                    options.filterClasses.some((className) => {
                        if (e.target instanceof Element) {
                            return hasClassInHierarchy(e.target, className)
                        }
                        return false
                    })

                if (inClassfilter) return
                trigger(e)
            },
            0,
            { leading: true, trailing: false },
        )

        for (const eventName of eventTypes) {
            document.addEventListener(eventName, handleClickOutside, false)
        }

        return function cleanupOnClickOutside() {
            for (const eventName of eventTypes) {
                document.removeEventListener(
                    eventName,
                    handleClickOutside,
                    false,
                )
            }
        }
    })
}
