import React from 'react'
import { StyledNavWrapperClass } from '../FlyoutNav/FlyoutNav.styled'
import {
    StyledButtonLabelHidden,
    StyledNavTrigger,
    StyledSubnavTriggerIcon,
} from './TheWestHeaderActions.styled'

export interface MenuActionProps {
    mainNavOpen: boolean
    openNavigation: () => void
    closeNavigation: () => void
}

export const MenuAction: React.FC<MenuActionProps> = ({
    mainNavOpen,
    openNavigation,
    closeNavigation,
}) => {
    const handleNavTrigger = () => {
        if (mainNavOpen) {
            closeNavigation()
        } else {
            openNavigation()
        }
    }

    return (
        <StyledNavTrigger
            type="button"
            onClick={handleNavTrigger}
            className={StyledNavWrapperClass}
        >
            <StyledSubnavTriggerIcon isActive={mainNavOpen} />
            <StyledButtonLabelHidden>Menu</StyledButtonLabelHidden>
        </StyledNavTrigger>
    )
}
