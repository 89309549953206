export interface ClickEmailVerifyArgs {
    verifyEmailHttpPath: string
    userId: string

    onVerifySendError: () => void
    onVerifySendSuccessful: () => void
}

export const onClickEmailVerify = async ({
    verifyEmailHttpPath,
    userId,
    onVerifySendError,
    onVerifySendSuccessful,
}: ClickEmailVerifyArgs) => {
    const request = 'send-verification-email'

    const response = await fetch(verifyEmailHttpPath, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
    })

    // Send return type and handle functions based on the response
    if (!response.ok || response.status !== 201) {
        onVerifySendError()
        console.error(`Failed to send verification email in ${request}`)
    }

    onVerifySendSuccessful()
}
