import { TheWestSection } from '@news-mono/common'
import {
    aflMatchCentreSectionHeaderOverride,
    metrics,
} from '@news-mono/component-library'
import {
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Topic,
} from '@news-mono/web-common'
import { PageMetaDTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../App.routing'
const metaImage = require('./assets/meta.png')

export const aflTopicForAdTargeting: Topic = {
    title: 'Afl',
    id: 'sport/afl',
    seoTitle: 'Afl',
    metadata: {},
    parent: {
        title: 'Sport',
        id: 'sport',
        seoTitle: 'Sport',
        metadata: {},
    },
}

export const createAflMatchCentreRoute = ({
    config,
    getAdTargeting,
    meta,
    match,
    competition,
    season,
    apiData,
}: {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    meta: PageMetaDTO | undefined
    match: string
    competition: string
    season: string
    apiData: any
}): PageType<TheWestSection> => {
    return {
        kind: 'page',
        heading:
            '2023 AFL Match Centre | Follow Every Kick, Mark & Goal | The West Australian',
        pageMeta: {
            title: '2023 AFL Match Centre | Follow Every Kick, Mark & Goal | The West Australian',
            description: ` Get in on the action with The West Australian's 2023 AFL Match Centre. Follow every kick, mark and goal with live scores, stats and match summaries. Stay informed with detailed player stats and a live match timeline. Don't miss a moment of the excitement.`,
            meta: [
                {
                    property: 'og:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:image',
                    content: metaImage,
                },
                {
                    property: 'og:image:height',
                    content: '720',
                },
                {
                    property: 'og:image:width',
                    content: '1280',
                },
            ],
        },
        socialMeta: {
            title: meta?.title ? meta.title : '',
            description: meta?.description ? meta.description : '',
        },
        hideHeading: true,
        noMetaTitleTemplate: true,
        pageType: 'static',
        refreshOnInactive: true,
        adTargeting: getAdTargeting(
            'match-centre',
            'sport',
            aflTopicForAdTargeting,
        ),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'afl-fixtures-scoreboard',
                            props: {},
                        }),
                        layout.component({
                            type: 'afl-match-centre-scoreboard',
                            props: {
                                gameId: match,
                                apiData,
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'component-overrider',
                props: {
                    override: () => aflMatchCentreSectionHeaderOverride,
                },
                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'tab',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                horizontalGutters: 'outerMargin',
                                verticalGutters: ['outerMargin', 'unset'],
                                headerData: [
                                    {
                                        position: 'one',
                                        title: 'Summary',
                                    },
                                    {
                                        position: 'two',
                                        title: 'Stats',
                                    },
                                    {
                                        position: 'three',
                                        title: 'Timeline',
                                    },
                                    {
                                        position: 'four',
                                        title: 'Fixtures',
                                    },
                                    {
                                        position: 'five',
                                        title: 'Squads',
                                    },
                                    {
                                        position: 'six',
                                        title: 'Ladder',
                                    },
                                    {
                                        position: 'seven',
                                        title: 'News',
                                    },
                                ],
                            },
                            contentAreas: {
                                one: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Timeline',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-match-centre-timeline',
                                        props: {
                                            apiData,
                                            matchId: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Match Summary',
                                            isFullWidth: true,
                                            hasTopMargin: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-match-summary-widget',
                                        props: {
                                            competition,
                                            match,
                                            season,
                                        },
                                    }),

                                    layout.nestedComposition({
                                        type: 'doublet',
                                        props: {
                                            verticalGutters: ['unset', 'md'],
                                        },
                                        contentAreas: {
                                            leftContent: [
                                                layout.component({
                                                    type: 'section-header',
                                                    props: {
                                                        heading: 'Player Stats',
                                                        isFullWidth: true,
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'afl-player-stats',
                                                    props: {
                                                        apiData,
                                                    },
                                                }),
                                            ],
                                            rightContent: [
                                                layout.component({
                                                    type: 'section-header',
                                                    props: {
                                                        heading: 'Match Stats',
                                                        isFullWidth: true,
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'afl-match-stats-widget',
                                                    props: {
                                                        competition,
                                                        match,
                                                        season,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),

                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Injuries & Suspensions',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-injuries-suspensions-widget',
                                        props: {
                                            apiData,
                                            competition,
                                            season,
                                        },
                                    }),
                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                two: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Match Stats',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-match-stats-widget',
                                        props: {
                                            competition,
                                            match,
                                            season,
                                        },
                                    }),
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Player Stats',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-player-stats',
                                        props: {
                                            apiData,
                                        },
                                    }),

                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                three: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'timeline',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-match-centre-timeline',
                                        props: {
                                            apiData,
                                            matchId: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Match Feed',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-timeline-feed-widget',
                                        props: {
                                            apiData,
                                            matchId: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                four: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Finals Fixtures',
                                            hasTopMargin: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-fixtures-widget',
                                        props: {
                                            competitionId: '12039',
                                        },
                                    }),
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Fixtures',
                                            hasTopMargin: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-fixtures-widget',
                                        props: {},
                                    }),

                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                five: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Squads',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-squads',
                                        props: {
                                            apiData,
                                            competition,
                                            season,
                                        },
                                    }),

                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                six: [
                                    layout.component({
                                        type: 'section-header',
                                        props: {
                                            heading: 'Ladder',
                                            isFullWidth: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-match-ladder-widget',
                                        props: { competition, season },
                                    }),

                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,
                                            teaserMode: 'hidden',
                                            loadMoreEnabled: false,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                seven: [
                                    layout.component({
                                        type: 'lima',
                                        props: {
                                            verticalSpacing: 'xl',
                                            fontScale: 1,
                                            fixedRatios: ['16:9'],
                                            hideSeparators: true,
                                            hideBottomBorder: true,

                                            teaserMode: {
                                                initial: 'hidden',
                                                xs: 'hidden',
                                                sm: 'visible',
                                                md: 'visible',
                                            },
                                            loadMoreEnabled: true,
                                            initialColumns: 0,
                                            intermediateColumns: 2,
                                            finalColumns: 4,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                            },

                                            sectionHeader: {
                                                heading: 'AFL News',
                                                isFullWidth: true,
                                            },

                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['sport/afl'],
                                                includeSubTopics: false,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 12,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                eight: [],
                                nine: [],
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
