import { AppState, ClickSourceOption, useFeature } from '@news-mono/web-common'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from '../../content/Video/utils/can-autoplay'
import { IconChevronDown } from '../../icons/IconChevronDown/IconChevronDown'
import { useAuthActions } from '../../user-registration/authentication/AuthenticationProvider'
import { createSubscribeLinkClickEvent } from '../../user-registration/subscribe/createSubscribeClickLinkHandler'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { EntitlementDetail } from '../TheWestHeaderLogin/TheWestAuthSiteLogin'
import { TheWestHeaderCloseIcon } from './icons/TheWestHeaderCloseIcon'
import { TheWestHeaderPremiumIcon } from './icons/TheWestHeaderPremiumIcon'
import { TheWestHeaderProfileIcon } from './icons/TheWestHeaderProfileIcon'
import {
    StyledActionSection,
    StyledActionUsername,
    StyledDesktopIcon,
    StyledDropdown,
    StyledFeatureSection,
    StyledFeatureTitleWrap,
    StyledInfo,
    StyledLinkBlockingContainer,
    StyledList,
    StyledLogoutButton,
    StyledMobileCloseIcon,
    StyledMobileIcon,
    StyledPremiumFeatureItem,
    StyledSubscribeCTA,
    StyledTheWestHeaderDropdownWrap,
    StyledUserSection,
    StyledWebLink,
} from './TheWestHeaderDropdown.styled'
import { TheWestNewFeatureTooltip } from './TheWestNewFeatureTooltip/TheWestNewFeatureTooltip'

interface TheWestHeaderDropdownProps {
    userName: string
    isOpenOverride?: boolean
    displayTooltipOverride?: boolean
    vrMobileOverride?: boolean
    entitlements: EntitlementDetail
    onEvent: (event: any) => void
    onLogoutClick: () => void
}

export function TheWestHeaderDropdown({
    userName,
    onLogoutClick,
    isOpenOverride,
    displayTooltipOverride,
    vrMobileOverride,
    entitlements,
    onEvent,
}: TheWestHeaderDropdownProps) {
    const [isOpen, setIsOpen] = useState(
        isOpenOverride ? isOpenOverride : false,
    )
    const [displayFullName, setDisplayFullName] = useState(
        vrMobileOverride ? false : true,
    )
    const authState = useSelector((state: AppState) => state.authentication)
    const isTooltipEnabled = useFeature('subscriber-feature-tooltip')
    const { onSubscribeClick } = useAuthActions()
    const dropdownEl = useRef<HTMLElement | null>(null)
    const userEmail = authState.userEmail ? authState.userEmail : 'not set'
    const subscriptionID = authState.subscriptionId
    const isVRTest = process.env.NODE_ENV === 'vr_test'
    const [userFirstName] = userName.split(' ')

    const { isAllEntitled, isPremiumEntitled, isSiteLicence } = entitlements

    //defaults to apple store on desktop,
    const theWestAppURL =
        isVRTest || isMobile.Android()
            ? 'https://play.google.com/store/apps/details?id=com.thewest&hl=en_AU&gl=US'
            : 'https://apps.apple.com/au/app/the-west-australian/id1198233691'

    const premiumFeatures: {
        text: string
        url: string
        clickSource: ClickSourceOption
        withEntitlement: keyof EntitlementDetail
        availableToCorporateSite: boolean
        forceInternalSSR?: boolean
    }[] = [
        {
            text: "Today's Paper",
            url: '/editions?utm_source=TheWest&utm_medium=Referral&utm_campaign=digital-edition&utm_term=todays-paper&utm_content=subscriber-dropdown',
            clickSource: 'todays-paper',
            withEntitlement: 'isReplicaEntitled',
            availableToCorporateSite: true,
            forceInternalSSR: true,
        },
        {
            text: 'Daily Puzzles',
            url: 'https://thewest.com.au/puzzles',
            clickSource: 'puzzles',
            withEntitlement: 'isAllEntitled',
            availableToCorporateSite: true,
        },
        {
            text: 'West Rewards',
            url: 'https://rewards.thewest.com.au/?utm_source=TheWest&utm_medium=Referral&utm_campaign=WestRewards&utm_term=icon&utm_content=subscriber-dropdown',
            clickSource: 'west-rewards',
            withEntitlement: 'isWestRewardsEntitled',
            availableToCorporateSite: false,
        },
        {
            text: 'The West App',
            url: theWestAppURL,
            clickSource: 'the-west-app',
            withEntitlement: 'isAllEntitled',
            availableToCorporateSite: true,
        },
        {
            text: 'My Account',
            url: 'https://subscriber.thewest.com.au/myaccount',
            clickSource: 'my-account',
            withEntitlement: 'isAllEntitled',
            availableToCorporateSite: false,
        },
    ]

    const fireGaEvent = (clickSource: ClickSourceOption) => {
        onEvent({
            type: 'subscriberDropdown.click',
            originator: 'Subscriber Dropdown',
            payload: { clickSource },
        })
    }

    useEffect(() => {
        if (window && typeof window.matchMedia === 'function') {
            const breakpointKey = window.matchMedia(
                breakpoint('sm').replace('@media ', ''),
            )
            setDisplayFullName(breakpointKey.matches)

            !breakpointKey.matches && isOpen
                ? (document.body.style.overflow = 'hidden')
                : (document.body.style.overflow = 'auto')
        }
    }, [isOpen])

    return (
        <StyledTheWestHeaderDropdownWrap
            ref={(el) => (dropdownEl.current = el)}
        >
            <StyledLinkBlockingContainer
                isOpen={isOpen}
                onClick={() => setIsOpen(false)}
            />
            {isTooltipEnabled && (
                <TheWestNewFeatureTooltip
                    isOpen={isOpen}
                    displayTooltipOverride={displayTooltipOverride}
                />
            )}
            <StyledActionUsername
                onClick={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
            >
                <div className="flex-center">
                    <span className="username">
                        {displayFullName ? userName : userFirstName}
                    </span>
                    <StyledDesktopIcon>
                        <IconChevronDown className="StyledActionUsernameIcon" />
                    </StyledDesktopIcon>

                    <StyledMobileIcon>
                        <TheWestHeaderProfileIcon />
                    </StyledMobileIcon>
                </div>
            </StyledActionUsername>

            <StyledDropdown isOpen={isOpen} isEntitled={isAllEntitled}>
                <StyledMobileCloseIcon onClick={() => setIsOpen(!isOpen)}>
                    <TheWestHeaderCloseIcon />
                </StyledMobileCloseIcon>

                <StyledUserSection>
                    <span className="username heading-font">{userName}</span>
                    <StyledInfo>
                        {(isAllEntitled || isPremiumEntitled) && (
                            <span className="heading-font">
                                Premium Digital Subscriber
                            </span>
                        )}
                        <span>{userEmail}</span>
                        {subscriptionID && (
                            <span>Sub ID: {subscriptionID}</span>
                        )}
                    </StyledInfo>
                </StyledUserSection>

                <StyledFeatureSection
                    isEntitled={isAllEntitled || isPremiumEntitled}
                >
                    {!(isAllEntitled || isPremiumEntitled) && (
                        <StyledSubscribeCTA
                            onClick={() => {
                                fireGaEvent('subscribe-to-access')
                                createSubscribeLinkClickEvent(
                                    'header-dropdown',
                                    onEvent,
                                )()
                                onSubscribeClick({
                                    callToAction: 'header-dropdown',
                                })
                            }}
                        >
                            Subscribe to access
                        </StyledSubscribeCTA>
                    )}

                    <StyledFeatureTitleWrap>
                        <TheWestHeaderPremiumIcon />
                        <span className="block-heading-font">
                            Premium Features
                        </span>
                    </StyledFeatureTitleWrap>

                    <StyledList>
                        {premiumFeatures.map((link, index) => (
                            <StyledPremiumFeatureItem
                                key={index}
                                onClick={() => {
                                    setIsOpen(false)
                                    fireGaEvent(link.clickSource)
                                }}
                                visible={
                                    entitlements.isSiteLicence
                                        ? link.availableToCorporateSite
                                        : true
                                }
                                active={entitlements[link.withEntitlement]}
                            >
                                <StyledWebLink
                                    to={link.url}
                                    forceInternalSSR={link.forceInternalSSR}
                                >
                                    {link.text}
                                </StyledWebLink>
                            </StyledPremiumFeatureItem>
                        ))}
                    </StyledList>
                </StyledFeatureSection>
                {!isSiteLicence && (
                    <StyledActionSection>
                        <StyledList>
                            <li>
                                <StyledWebLink
                                    to="https://thewest.com.au/manage-email-preferences"
                                    onClick={() => {
                                        setIsOpen(false)
                                        fireGaEvent('subscribe-to-newsletter')
                                    }}
                                    openInternalInNewWindow={true}
                                >
                                    Subscribe to our newsletters
                                </StyledWebLink>
                            </li>
                            <li>
                                <StyledLogoutButton
                                    onClick={() => {
                                        onLogoutClick()
                                        fireGaEvent('logout')
                                    }}
                                >
                                    Log out
                                </StyledLogoutButton>
                            </li>
                        </StyledList>
                    </StyledActionSection>
                )}
            </StyledDropdown>
        </StyledTheWestHeaderDropdownWrap>
    )
}
