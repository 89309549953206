import styled from '@emotion/styled'
import { IconChevronUpTN } from '../../icons'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const AccordionContainer = styled.div(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.kind === 'thenightly' ? calcRem(0, 24) : 0,
}))

export const ExpanderButton = styled.div(({ theme }) => ({
    padding: calcRem(16, 0),
    display: 'flex',
    flexDirection: 'row',
}))

export const HeadingWrapper = styled.div({
    flex: 1,
})

export const HeadingLabel = styled.h3(({ theme }) => ({
    margin: 0,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(20),
}))

export interface StyledIconContainerProps {
    selected: boolean
}

export const IconWrapper = styled.div<StyledIconContainerProps>(
    ({ selected }) => ({
        marginLeft: calcRem(0),
        width: calcRem(24),
        height: calcRem(24),
        transform: 'translate(0px, 2px)', // fix alignment issue
        rotate: selected ? 'none' : '180deg',
        transition: 'rotate 0.25s',
    }),
)

export const ExpanderIcon = styled(IconChevronUpTN)({})

export const ContentWrapper = styled.div({})
