import React from 'react'
import { StyledUpLateCard } from './TWStaticUpLateCard.styled'

export const TWStaticUpLateCard = () => {
    const imageSrc = require('./images/Up-Late-Article-Banner@2x.jpg')

    return (
        <StyledUpLateCard>
            <img src={imageSrc} alt="" role="presentation" />
        </StyledUpLateCard>
    )
}
