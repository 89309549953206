import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledText = styled('p')({
    fontWeight: 'bold',
    borderColor: colors.thewest.greyDolphin, // To be used for PerthNow as well
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: calcRem(6, 0, 6, 10),
})
