import { SswPackage, sswPackages } from '@news-mono/web-common'
import React from 'react'
import { packageImages } from './images/updated2022/redesign'
import {
    StyledDashedRule,
    StyledMiniPackageHeader,
    StyledMiniSubscriptionPackage,
    StyledMiniSubscriptionPackageBenefit,
} from './MiniSubscriptionPackage.styled'
import { StyledMiniPackageImage } from './SubscriptionPackage.styled'
import { SubscriptionPackageBenefit } from './SubscriptionPackageBenefit'

export interface MiniSubscriptionPackageProps {
    packageInfo: SswPackage
    offerPrice?: number
}

export const MiniSubscriptionPackage = ({
    packageInfo,
    offerPrice,
}: MiniSubscriptionPackageProps) => {
    const { title: heading, weeklyPrice, features } = packageInfo
    const currentPrice = offerPrice || weeklyPrice

    const cookiePackage = sswPackages.find(
        (pkg) => pkg.name === packageInfo.name,
    )
    const { x1, x2 } = cookiePackage?.imgPaths || sswPackages[0].imgPaths
    const imagePathX1 = packageImages[x1]
    const imagePathX2 = packageImages[x2]
    return (
        <StyledMiniSubscriptionPackage>
            <StyledMiniPackageImage
                srcSet={`${imagePathX1}, ${imagePathX2} 2x`}
                src={imagePathX1}
                alt={packageInfo.title}
            />

            <StyledMiniPackageHeader>
                {!!offerPrice && (
                    <span className="original-price">
                        $
                        {weeklyPrice.toFixed(
                            Number.isInteger(weeklyPrice) ? 0 : 2,
                        )}
                    </span>
                )}
                <h4>{heading}</h4>
                <span>
                    $
                    {currentPrice.toFixed(
                        Number.isInteger(currentPrice) ? 0 : 2,
                    )}
                    / <span>week</span>
                </span>
            </StyledMiniPackageHeader>
            <StyledDashedRule />

            {features.map((feature) => (
                <SubscriptionPackageBenefit
                    benefit={{
                        name: feature.text,
                        isAvailable: !feature.unavailable,
                    }}
                    containerComponent={StyledMiniSubscriptionPackageBenefit}
                    key={feature.text}
                />
            ))}
        </StyledMiniSubscriptionPackage>
    )
}

export type SubscriptionPackageFeatureValue = {
    name: string
    isAvailable: boolean
}
