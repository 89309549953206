import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CardOrientationOptions } from '../../cards/Portrait/Portrait'
import { CollectionRouteProps } from '../../collections/collections-props.routing'
import {
    calculateLayoutsLength,
    Uniform,
    UniformLayouts,
} from '../../collections/Uniform/Uniform'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { Section } from '../../__styling/settings/sections'

export interface UniformRouteProps
    extends CollectionRouteProps,
        ResponsiveContainer {
    disableImageLazyLoad?: boolean
    section: Section
    cardLayout: UniformLayouts
    cardOrientation?: CardOrientationOptions
    hasBorder?: boolean
}

export const UniformRegistration = createRegisterableComponentWithData(
    'uniform',
    ContentDataDefinitionLoader,
    (props: UniformRouteProps, data, services) => {
        return (
            <Uniform
                items={
                    data.loaded
                        ? { loaded: true, result: data.result.publications }
                        : { loaded: false }
                }
                expectedCards={calculateLayoutsLength(props.cardLayout)}
                disableImageLazyLoad={props.disableImageLazyLoad}
                onEvent={services.onEvent}
                section={props.section}
                sectionHeader={props.sectionHeader}
                verticalSpacing={props.verticalSpacing}
                cardLayout={props.cardLayout}
                cardOrientation={props.cardOrientation}
                containerWidthRatios={props.containerWidthRatios}
                hasBorder={props.hasBorder}
            />
        )
    },
)
