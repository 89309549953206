import { Request } from 'express'
// Note: js-cookie 3.0.0 removes automatic json parsing; see
// https://github.com/js-cookie/js-cookie/releases/tag/v3.0.0
import { CookieAttributes, get, remove, set } from 'js-cookie'

/**
 * Type safe cookie store
 */

const expiresDefault = 5 // Days until cookie expires

export function storeCookie<S extends object>(
    key: string,
    data: S | string,
    {
        expires = expiresDefault,
        secure = process.env.NODE_ENV === 'production',
        sameSite = 'lax',
    }: CookieAttributes = {},
): void {
    set(key, JSON.stringify(data), { expires, secure, sameSite })
}

/**
 * Retrieve typed cookies from window or request for ssr
 * @param  {string} key Location of the cookie
 * @param  {Request} req? an Express Request object
 */
export function retrieveCookie<S extends object>(
    key: string,
    req?: Request,
): S | undefined {
    try {
        if (req && req.cookies) {
            return (
                req.cookies[key] ? JSON.parse(req.cookies[key]) : undefined
            ) as S
        } else {
            const value = get(key)
            if (value === undefined) {
                return undefined
            }
            const cookie = JSON.parse(value) as S
            return cookie
        }
    } catch {
        return undefined
    }
}

/**
 * Retrieve typed cookies from window or request for ssr expects a strin
 * @param  {string} key Location of the cookie
 * @param  {Request} req? an Express Request object
 */
export function retrieveCookieValue<S extends string>(
    key: string,
    req?: Request,
): S | undefined {
    try {
        if (req && req.cookies) {
            const cookieValue = req.cookies[key]
            if (cookieValue) {
                if (
                    cookieValue.includes('%7B%22') &&
                    cookieValue.includes('%22%7D')
                ) {
                    JSON.parse(cookieValue) as S
                }
                return cookieValue as S
            }
            return undefined
        } else {
            const value = get(key)
            if (value === undefined) {
                return undefined
            }
            const cookie = JSON.parse(value) as S
            return cookie
        }
    } catch {
        return undefined
    }
}

export function removeCookie(key: string, req?: Request) {
    if (req && req.cookies) {
        delete req.cookies[key]
    } else {
        return remove(key)
    }
}
