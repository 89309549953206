import { AppState, RenderTargetContext } from '@news-mono/web-common'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { MastHeadLogo } from '../../content/TheWestHeader/logos/MastheadLogo'
import { MenuAction, MenuActionProps } from './MenuAction'
import {
    StyledActionGroup,
    StyledActionsContent,
    StyledWestHeaderItem,
} from './TheWestHeaderActions.styled'

export interface TheWestActionsStructureProps extends MenuActionProps {
    onEvent: (event: any) => void
    hideBurger?: Boolean
}

export const TheWestActionsStructure: React.FC<{
    left: React.ReactElement | false
    center: React.ReactElement
    right: React.ReactElement | false
}> = ({ left, right, center }) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    return (
        <StyledActionsContent rendition={renditionType}>
            {<StyledActionGroup>{left && left}</StyledActionGroup>}
            {center}
            {<StyledActionGroup>{right && right}</StyledActionGroup>}
        </StyledActionsContent>
    )
}

export const TheWestHeaderActionsApp: React.FC<TheWestActionsStructureProps> =
    ({ onEvent, hideBurger, ...menuActionProps }) => {
        const renderTarget = useContext(RenderTargetContext)

        return (
            <TheWestActionsStructure
                left={
                    !hideBurger || hideBurger === false ? (
                        <StyledWestHeaderItem>
                            {renderTarget.renderTarget !==
                                'static-error-page' && (
                                <MenuAction {...menuActionProps} />
                            )}
                        </StyledWestHeaderItem>
                    ) : (
                        false
                    )
                }
                center={<MastHeadLogo height={28} />}
                right={false}
            />
        )
    }
TheWestHeaderActionsApp.displayName = 'TheWestHeaderActionsApp'
