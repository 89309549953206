import {
    NielsenDCRMetaData,
    NielsenRenderType,
    RegionCookieState,
    retrieveCookie,
    userLocationKey,
} from '@news-mono/web-common'

const SEVENNEWS_NIELSEN_PREFIX = '7news'

function get7NewsTopLevelSection(location: { pathname: string }) {
    const path = location.pathname
    // Should not occur but if it does return unknown section
    if (path.indexOf('/') === -1) {
        return 'unknown'
    }

    const base = `${SEVENNEWS_NIELSEN_PREFIX} - home`

    const item = path.split('/')[1]
    const resolved = `${SEVENNEWS_NIELSEN_PREFIX} - ${removeHyphens(item)}`

    return path === '/' ? base : resolved
}

/**
 * Expected output for the following Dcr metadata is
 *
 * for SevenNews (P19-135):
 *  section: pathname sanitised without slug/query params e.g '/news/crime'
 *  segA: '7News' for / or first level topic (e.g 'news' if /news)
 *  segB: region or 'not set'
 */
export function getLegacy7NewsDcrMeta(
    siteName: string,
    pathName: string,
    renderType: NielsenRenderType,
): NielsenDCRMetaData {
    // generate correct segC based on renderType
    let segC = undefined
    switch (renderType) {
        case 'google-amp':
            segC = `${siteName} - Google AMP`
            break
        default:
            break
    }

    const cookie = retrieveCookie<RegionCookieState>(userLocationKey)
    const section = get7NewsTopLevelSection({ pathname: pathName })
    const segB = cookie?.region ?? undefined

    return {
        section,
        ...(segB && { segB }),
        ...(segC && { segC }),
    }
}

const removeHyphens = (str: string) => `${str.replace('-', ' ')}`
