import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Box } from '../../../compositions/Box/Box'
import { ThemeMargins } from '../../../__styling/settings/metrics'
import { HorizontalCricketWidget } from './HorizontalCricketWidget'

export interface HorizontalCricketWidgetProps {
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const HorizontalCricketWidgetRegistration = createRegisterableComponent(
    'HorizontalCricketWidget',
    (props: HorizontalCricketWidgetProps, services) => {
        return (
            <Box {...props}>
                <HorizontalCricketWidget />
            </Box>
        )
    },
)
