import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'

interface StyledHeadlineProps {
    hasEditorialType: boolean
}

export const StyledHeadline = styled('h1')<StyledHeadlineProps>(
    ({ hasEditorialType, theme }) => ({
        ...breakWords,
        fontSize: calcRem(25),
        fontWeight: 700,
        fontFamily: theme.fonts.publication.headline,
        lineHeight: 1.28,
        background: `linear-gradient(to bottom, ${colors.thewest.westblueTint} 0%, ${colors.thewest.westblue} 100%)`,
        padding: hasEditorialType ? calcRem(40, 22, 30) : calcRem(35, 22),
        color: colors.white,
        textAlign: 'center',
        width: `calc(100% - ${metrics.thewest.margins.outerMargin * 2}px)`,
        margin: '0 auto',
        boxShadow: `0 15px 34px 0 ${colors.black25}`,
        position: 'relative',
        zIndex: zIndex.thewest.featureHeadline,
        minHeight: calcRem(150),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [breakpoint('md')]: {
            width: '100%',
            minHeight: calcRem(200),
            maxWidth: calcRem(719),
            padding: hasEditorialType ? calcRem(32, 32, 26) : calcRem(29, 32),
            fontSize: calcRem(38),
            lineHeight: 1.158,
        },
    }),
)

export const StyledHeadlineText = styled('span')({
    maxWidth: calcRem(800),
})

export const StyledFeatureLabel = styled('span')({
    background: colors.white,
    padding: calcRem(7, 12),
    boxShadow: `0 8px 18px 0 ${colors.black35}`,
    position: 'absolute',
    top: 0,
    left: '50%',
    borderRadius: calcRem(2),
    transform: 'translate(-50%, -50%)',
})
