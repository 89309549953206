import React from 'react'

export interface IconCartProps {
    title?: string
    className?: string
}

export const IconEmptyCart: React.FC<IconCartProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 22"
        fill="none"
        className={className}
    >
        <title>{title || 'Empty Cart Icon'}</title>
        <path
            d="M7.51953 17.875C6.35938 17.875 5.5 18.8203 5.5 19.9375C5.5 21.0977 6.40234 22 7.51953 22C8.59375 22 9.53906 21.0977 9.53906 19.9375C9.53906 18.8203 8.67969 17.875 7.51953 17.875ZM19.8945 17.875C18.7344 17.875 17.875 18.8203 17.875 19.9375C17.875 21.0977 18.7773 22 19.8945 22C20.9688 22 21.9141 21.0977 21.9141 19.9375C21.9141 18.8203 21.0547 17.875 19.8945 17.875ZM24.4492 1.93359C24.1914 1.58984 23.8047 1.375 23.332 1.375H5.19922L5.11328 0.859375C5.02734 0.386719 4.59766 0 4.125 0H0.988281C0.429688 0 0 0.472656 0 1.03125C0 1.63281 0.429688 2.0625 0.988281 2.0625H3.22266L5.80078 15.6836C5.92969 16.1562 6.35938 16.5 6.875 16.5H20.9688C21.5273 16.5 22 16.0703 22 15.5117C22 14.9102 21.5273 14.4375 20.9688 14.4375H7.69141L7.30469 12.375H21.0117C21.6133 12.375 22.1289 11.9883 22.3008 11.3867L24.6641 3.17969C24.793 2.75 24.707 2.27734 24.4492 1.93359ZM20.4961 10.3125H6.91797L5.62891 3.4375H22.4297L20.4961 10.3125Z"
            fill="#535556"
        />
    </svg>
)

export const IconFullCart: React.FC<IconCartProps> = ({ className, title }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 25"
        fill="none"
        className={className}
    >
        <title>{title || 'Full Cart Icon'}</title>
        <path
            d="M19.8945 20.875C18.7344 20.875 17.875 21.8203 17.875 22.9375C17.875 24.0977 18.7773 25 19.8945 25C20.9688 25 21.9141 24.0977 21.9141 22.9375C21.9141 21.8203 21.0547 20.875 19.8945 20.875ZM7.51953 20.875C6.35938 20.875 5.5 21.8203 5.5 22.9375C5.5 24.0977 6.40234 25 7.51953 25C8.59375 25 9.53906 24.0977 9.53906 22.9375C9.53906 21.8203 8.67969 20.875 7.51953 20.875ZM24.4492 4.93359C24.1914 4.58984 23.8047 4.375 23.332 4.375H5.19922L5.11328 3.85938C5.02734 3.38672 4.59766 3 4.125 3H0.988281C0.429688 3 0 3.47266 0 4.03125C0 4.54688 0.429688 5.0625 0.988281 5.0625H3.22266L5.80078 18.6836C5.92969 19.1562 6.35938 19.5 6.875 19.5H20.9688C21.5273 19.5 22 19.0703 22 18.5117C22 17.9102 21.5273 17.4375 20.9688 17.4375H7.69141L7.30469 15.375H21.0117C21.6133 15.375 22.1289 14.9883 22.3008 14.3867L24.6641 6.17969C24.793 5.75 24.707 5.27734 24.4492 4.93359Z"
            fill="#043DE2"
        />
        <circle cx="20" cy="6" r="5.5" fill="white" stroke="#043DE2" />
        <path
            d="M19.0918 9H21V3H19.8984L18 3.69928V4.64892L19.0918 4.57122V9Z"
            fill="#043DE2"
        />
    </svg>
)
