import {
    AppState,
    ArticleAvailableEvent,
    Block,
    BreachEvent,
    CardEvents,
    CollectionEvent,
    EntitledToView,
    isArticleLikeType,
    LinkClickedEvent,
    PlayerEvent,
    PublicationType,
    RequiredAccess,
    TogglesReduxState,
} from '@news-mono/web-common'
import H from 'history'
import React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Logger } from 'typescript-log'
import { isLiveSportArticle } from '../../templates/Publication/lib/get-inline-positioned-sports-hub-cta'
import {
    InlinePositionedContent,
    TargetedContent,
} from '../../templates/Publication/Publication.props'
import {
    responsiveComponent,
    ResponsiveProps,
} from '../../__helpers/responsive-component'
import { createIsEntitledChangeHandler } from './createIsEntitledChangeHandler'
import { LazyBreachWallContentWrapper } from './components/BreachWallContent/BreachWallContentWrapper.lazy'

interface OwnProps {
    requiredAccess: RequiredAccess
    location: H.Location
    blocks: Block[]
    slug: string
    topicIds: string[]
    // inlinePositionedContent and targetedContent are for placing ads between content
    inlinePositionedContent?: InlinePositionedContent[]
    targetedContent?: TargetedContent
    onEvent: (
        event:
            | BreachEvent
            | ArticleAvailableEvent
            | PlayerEvent
            | LinkClickedEvent
            | CardEvents
            | CollectionEvent,
    ) => void
    children: (content: {
        blocks: Block[]
        inlinePositionedContent: InlinePositionedContent[] | undefined
        targetedContent: TargetedContent | undefined
    }) => React.ReactElement<any>
    publication: PublicationType
    log: Logger
    forceLoad?: boolean
}

export type InternalArticleBreachProps = OwnProps & {
    toggles: TogglesReduxState
} & DispatchProp<any> &
    ResponsiveProps
const mapStateToProps = (state: AppState) => ({ toggles: state.toggles })

export const InternalArticleBreach: React.FC<InternalArticleBreachProps> = (
    props,
) => {
    const {
        blocks,
        inlinePositionedContent,
        targetedContent,
        requiredAccess,
        location,
        onEvent,
        slug,
        publication,
        log,
        topicIds,
        forceLoad,
    } = props
    const classification = isArticleLikeType(publication)
        ? publication.classification
        : undefined

    const showLiveSportHubTrailer =
        classification &&
        isLiveSportArticle(
            classification,
            publication.primaryTopic,
            publication.secondaryTopics,
        )

    return (
        <EntitledToView
            requiredAccess={requiredAccess}
            articleSlug={slug}
            topicIds={topicIds}
            onIsEntitledChange={createIsEntitledChangeHandler(
                location,
                onEvent,
                publication,
            )}
            entitled={() => {
                return props.children({
                    blocks,
                    inlinePositionedContent,
                    targetedContent,
                })
            }}
            notEntitled={() => {
                return (
                    <LazyBreachWallContentWrapper
                        publication={publication}
                        requiredAccess={requiredAccess.level}
                        location={location}
                        log={log}
                        blocks={blocks}
                        onEvent={onEvent}
                        contentOptions={{ showLiveSportHubTrailer }}
                        forceLoad={forceLoad}
                    />
                )
            }}
        />
    )
}

export const ArticleBreach: React.ComponentType<OwnProps> =
    responsiveComponent<OwnProps>(
        connect<{ toggles: TogglesReduxState }, {}, OwnProps, AppState>(
            mapStateToProps,
            null,
            null,
            {},
        )(InternalArticleBreach),
    )

ArticleBreach.displayName = 'ArticleBreach'
