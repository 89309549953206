import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { Sharing } from '../../buttons/Sharing/Sharing'
import { IconPlay } from '../../icons/IconPlay/IconPlay'
import { breakpoint, calcRem, themedValue, transition } from '../../__styling'
import { colors } from '../../__styling/settings/colors'

export const StyledPodcastSegment = styled('div')({
    position: 'relative',
    background: 'linear-gradient(180deg, #111A4D 64.01%, #0A0C20 100.05%)',
    overflow: 'hidden',
    width: '100%',
    padding: calcRem(24),
    boxShadow: `0 -1px 19px 0 ${colors.black20}`,
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(24),
    [breakpoint('xs')]: {
        flexDirection: 'row',
    },
})

export const StyledPodcastImage = styled('img')({
    alignSelf: 'center',
    borderRadius: calcRem(6),
    [breakpoint('xs')]: {
        alignSelf: 'flex-start',
    },
})

export const StyledPodcastInfo = styled('div')({
    display: 'flex',
    color: 'white',
    flexDirection: 'column',
    flexGrow: 1,
    gap: calcRem(16),
})

export const StyledPodcastHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: calcRem(16),
    [breakpoint('xs')]: {
        flexDirection: 'row',
    },
})

export const StyledPodcastTitle = styled('h1')({
    fontSize: calcRem(30),
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1,
    margin: 0,
    paddingTop: calcRem(16),
})

export const StyledPodcastBodyContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
    paddingBottom: calcRem(8),
    [breakpoint('xs')]: {
        paddingBottom: 0,
    },
})

export const StyledPodcastSubHeading = styled('div')(({ theme }) => ({
    opacity: 0.6,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(14),
    fontWeight: 500,
    textTransform: 'uppercase',
}))

export interface StyledPodcastDescriptionProps {
    showMore?: boolean
}

export const StyledPodcastDescription = styled(
    'div',
)<StyledPodcastDescriptionProps>(({ showMore }) => ({
    opacity: 0.8,
    fontSize: calcRem(16),
    overflow: showMore ? undefined : 'hidden',
    display: showMore ? undefined : '-webkit-box',
    lineClamp: showMore ? undefined : 3,
    '-webkit-line-clamp': showMore ? undefined : '3',
    '-webkit-box-orient': showMore ? undefined : 'vertical',
}))

export const StyledPodcastShowMore = styled('button')(({ theme }) => ({
    background: 'transparent',
    color: 'white',
    border: 'none',
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 600,
    textAlign: 'left',
    padding: 0,
    alignSelf: 'start',
    flexGrow: 0,
    '&:hover': { textDecoration: 'underline' },
}))

export const StyledPodcastButton = styled('button')(({ theme }) => ({
    display: 'flex',
    gap: calcRem(8),
    alignSelf: 'start',
    alignItems: 'center',
    padding: calcRem(9, 20),
    outlineStyle: 'solid',
    outlineWidth: calcRem(2),
    color: colors.white,
    backgroundColor: 'transparent',
    borderRadius: themedValue(theme, {
        fallback: calcRem(20),
        thewest: calcRem(24),
    }),
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(15),
    fontWeight: 'bold',
    fill: colors.white,
    ...transition({ properties: ['background-color'], speed: 'fast' }),

    '&:hover': {
        backgroundColor:
            theme.colors.actions.button.secondary?.background.hover,
        color: colors.black,
        outlineStyle: 'solid',
        outlineWidth: calcRem(2),
        outlineColor: 'white',
        fill: colors.black,
    },
}))

export const StyledPlayIcon = styled(IconPlay)({
    width: calcRem(12),
    height: calcRem(14),
})

export const StyledPodcastSharing = styled(Sharing)(({ theme }) => ({
    margin: 0,

    '& > button': {
        color: '#111A4D',
        borderColor: '#111A4D',
        backgroundColor: 'white',
        margin: 0,
        '&:focus': {
            color: 'white',
            borderColor: tokens.thewest.colors.brand,
            backgroundColor: tokens.thewest.colors.brand,
            margin: 0,
        },
    },
    '& > button + button': {
        marginLeft: calcRem(8),
        '&:focus': {
            marginLeft: calcRem(8),
        },
    },
}))
