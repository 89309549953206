const easeOut = 'cubic-bezier(.55, 0, .1, 1)'

export const durationInt: { [key: string]: number } = {
    fast: 250,
    med: 350,
    slow: 450,
}

export const duration: { [key: string]: string } = {
    fast: `${durationInt.fast}ms`,
    med: `${durationInt.med}ms`,
    slow: `${durationInt.slow}ms`,
}

export const easing = {
    duration,
    easeOut: {
        timingFunction: easeOut,
        fast: `${duration.fast} ${easeOut}`,
        med: `${duration.med} ${easeOut}`,
        slow: `${duration.slow} ${easeOut}`,
    },
}
