import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Thor } from '../../compositions/Thor/Thor'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { ThemeOverrideTypes } from '../../__styling/themes'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'

export interface ThorRouteProps extends BaseCompositionProps {
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | keyof ThemeMargins
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | keyof ThemeMargins
    containerWidth?: ContainerWidth
    verticalSpacing?: keyof ThemeMargins
    themeOverride?: ThemeOverrideTypes
    hideHeadingLink?: boolean
    // Pollie Rater / Federal Election specific props
    hidePollieRaterBanner?: boolean
    isHeadingH1?: boolean
    hasBorder?: boolean
    layouts?: '4-card' | '8-card' | '7-card'
    unsetFeatureContainerBackground?: boolean
    extraBottomBorder?: boolean
    hideFeatureHeader?: boolean
    isGenwest?: boolean
}

export const ThorRegistration = createRegisterableComposition<'main'>()(
    'thor',
    (contentAreas, props: ThorRouteProps) => (
        <ContentWrapper
            stretchColumns={props.stretchColumns}
            stretchSelf={props.stretchSelf}
        >
            <Thor {...props} mainContent={contentAreas.main} />
        </ContentWrapper>
    ),
    (
        _contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: ThorRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        return {
            containerWidthRatios: {
                desktop: containerWidthRatios.desktop,
                tablet: containerWidthRatios.tablet,
                mobile: containerWidthRatios.mobile,
            },
        }
    },
)
