import {
    ConsentLevel,
    DataLayerEventName,
    useConsentState,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { AmpAnalytics } from '../analytics'
import {
    AmpGA4Config,
    AmpGoogleAnalyticsFourProps,
} from './AmpGoogleAnalyticsFourProps'
import { AmpBrightcoveElementClassName } from '../../content'
import {
    Item_MainVideoV4DTO,
    MissingVideoV4DTO,
} from '@west-australian-newspapers/publication-types'

const getVideoParams = (
    mainVideo: Item_MainVideoV4DTO | MissingVideoV4DTO | null,
) => {
    if (mainVideo && mainVideo.kind === 'video') {
        return {
            event__str_id: mainVideo.videoId,
            event__str_account_id: mainVideo.accountId,
            event__str_description: mainVideo.videoDescription,
            event__str_name: mainVideo.heading,
            event__str_type: mainVideo.videoType ? mainVideo.videoType : '',
        }
    } else {
        return null
    }
}

export const AmpGoogleAnalyticsFour: React.FC<AmpGoogleAnalyticsFourProps> = ({
    gaFourMeasureId,
    article,
}) => {
    const consent = useConsentState()

    const selector = `.${AmpBrightcoveElementClassName}`

    const videoParams = getVideoParams(article.mainVideo)

    const googleAnalyticsFourConfig: AmpGA4Config = {
        vars: {
            GA4_MEASUREMENT_ID: gaFourMeasureId,
            GA4_ENDPOINT_HOSTNAME: 'www.google-analytics.com',
            GOOGLE_CONSENT_ENABLED:
                consent.consentLevel ?? ConsentLevel.Essential,
            WEBVITALS_TRACKING: false,
            PERFORMANCE_TIMING_TRACKING: false,
            DEFAULT_PAGEVIEW_ENABLED: false,
            SEND_DOUBLECLICK_BEACON: false,
            DISABLE_REGIONAL_DATA_COLLECTION: false,
            ENHANCED_MEASUREMENT_SCROLL: false,
        },
        triggers: {
            custom_pageview: {
                enabled: true,
                on: 'visible',
                request: 'ga4Event',
                vars: {
                    ga4_event_name: 'view_item',
                },
                extraUrlParams: {
                    pa: 'detail',
                    pr1: `id${article.id}~nm${article.heading}~br${article.source}~ca${article.topics.primary.id}~va${article.classification?.label}~qt1~pr0`,
                },
            },
            videoPlayEvent: {
                enabled: true,
                on: 'video-play',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4VideoPlay,
                },
                extraUrlParams: {
                    event__num_video_plays: 1,
                    event__str_autoplay_status: '${autoplay}',
                    ...videoParams,
                },
            },
            videoEndedEvent: {
                enabled: true,
                on: 'video-percentage-played',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4VideoComplete,
                },
                videoSpec: {
                    percentages: [100],
                },
                extraUrlParams: {
                    event__num_video_complete: 1,
                    event__str_autoplay_status: '${autoplay}',
                    ...videoParams,
                },
            },
            video25PercentageEvent: {
                enabled: true,
                on: 'video-percentage-played',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4VideoProgress,
                },
                videoSpec: {
                    percentages: [25],
                },
                extraUrlParams: {
                    event__str_percent: '25%',
                    event__num_video_25played: 1,
                    event__str_autoplay_status: '${autoplay}',
                    ...videoParams,
                },
            },
            video50PercentageEvent: {
                enabled: true,
                on: 'video-percentage-played',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4VideoProgress,
                },
                videoSpec: {
                    percentages: [50],
                },
                extraUrlParams: {
                    event__str_percent: '50%',
                    event__num_video_50played: 1,
                    event__str_autoplay_status: '${autoplay}',
                    ...videoParams,
                },
            },
            video75PercentageEvent: {
                enabled: true,
                on: 'video-percentage-played',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4VideoProgress,
                },
                videoSpec: {
                    percentages: [75],
                },
                extraUrlParams: {
                    event__str_percent: '75%',
                    event__num_video_75played: 1,
                    event__str_autoplay_status: '${autoplay}',
                    ...videoParams,
                },
            },
            videoAdStartEvent: {
                enabled: true,
                on: 'video-ad-start',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4AdPlay,
                },
                extraUrlParams: {
                    event__num_ad_preroll_plays: 1,
                    ...videoParams,
                },
            },
            videoAdEndEvent: {
                enabled: true,
                on: 'video-ad-end',
                selector,
                request: 'ga4Event',
                vars: {
                    ga4_event_name: DataLayerEventName.ga4AdComplete,
                },
                extraUrlParams: {
                    event__num_ad_preroll_complete: 1,
                    ...videoParams,
                },
            },
        },
    }

    return (
        <AmpAnalytics
            type="googleanalytics"
            config={`https://amp.analytics-debugger.com/ga4.json`}
            data-credentials="include"
            dangerouslySetInnerHTML={{
                __html: `
                <script type="application/json">
                    ${JSON.stringify(googleAnalyticsFourConfig)}
                </script>`,
            }}
        ></AmpAnalytics>
    )
}
