import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../__styling'

const FlashingElipsis = keyframes`
0% {
        background-color: ${tokens.sevennews.colors.palette.white};
  }
  50%, 100% {
    background-color: ${tokens.sevennews.colors.palette.newsletters.white20};
  }
`
const FlashingGreyElipsis = keyframes`
0% {
        background-color: ${tokens.sevennews.colors.palette.midGray};
  }
  50%, 100% {
    background-color: ${tokens.sevennews.colors.palette.greyMarble};  
  }
`
interface StyledLoadingEllipsisProps {
    color?: string
}
export const StyledLoadingEllipsis = styled('div')<StyledLoadingEllipsisProps>(
    ({ color }) => [
        {
            position: 'relative',
            width: calcRem(10),
            height: calcRem(10),
            borderRadius: calcRem(5),
            backgroundColor:
                color === 'grey'
                    ? tokens.sevennews.colors.palette.midGray
                    : tokens.sevennews.colors.palette.white,
            color:
                color === 'grey'
                    ? tokens.sevennews.colors.palette.midGray
                    : tokens.sevennews.colors.palette.white,
            animation:
                color === 'grey'
                    ? `${FlashingGreyElipsis} 1s infinite linear alternate`
                    : `${FlashingElipsis} 1s infinite linear alternate`,
            animationDelay: '0.5s',

            '&::before, &::after': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                top: 0,
            },

            '&::before': {
                left: calcRem(-15),
                width: calcRem(10),
                height: calcRem(10),
                borderRadius: calcRem(5),
                backgroundColor:
                    color === 'grey'
                        ? tokens.sevennews.colors.palette.midGray
                        : tokens.sevennews.colors.palette.white,
                color:
                    color === 'grey'
                        ? tokens.sevennews.colors.palette.midGray
                        : tokens.sevennews.colors.palette.white,
                animation:
                    color === 'grey'
                        ? `${FlashingGreyElipsis} 1s infinite linear alternate`
                        : `${FlashingElipsis} 1s infinite alternate`,
                animationDelay: '0s',
            },
            '&::after': {
                left: calcRem(15),
                width: calcRem(10),
                height: calcRem(10),
                borderRadius: calcRem(5),
                backgroundColor:
                    color === 'grey'
                        ? tokens.sevennews.colors.palette.midGray
                        : tokens.sevennews.colors.palette.white,
                color:
                    color === 'grey'
                        ? tokens.sevennews.colors.palette.midGray
                        : tokens.sevennews.colors.palette.white,
                animation:
                    color === 'grey'
                        ? `${FlashingGreyElipsis} 1s infinite linear alternate`
                        : `${FlashingElipsis} 1s infinite alternate`,
                animationDelay: '1s',
            },
        },
    ],
)
