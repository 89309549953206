import styled from '@emotion/styled'
import {
    breakpoint,
    calcRem,
    metrics,
    themedValue,
    ThemeMargins,
} from '../../__styling'

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const RealEstateViewContainer = styled('div')<GridProps>(
    ({ verticalSpacing, verticalGutters, theme }) => ({
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
        '#rev-widget-el > .rev-w__wrapper': {
            paddingTop: themedValue(theme, {
                perthnow: calcRem(16),
                fallback: undefined,
            }),
        },
    }),
)
