import styled from '@emotion/styled'
import { colors } from '../__styling/settings/colors'
import { fonts } from '../__styling/settings/fonts'
import { metrics } from '../__styling/settings/metrics'
import { calcRem } from '../__styling/style-functions/calc-rem'
import { themedValue } from '../__styling/themed-value'

export const StyledGoogleAmp = styled('div')({
    fontFamily: fonts.perthnow.body,
    fontSize: metrics.perthnow.baseFontSize,
    color: colors.perthnow.greyCoal,
    lineHeight: 1.5,
})

export const StyledAmpAdWrapper = styled('div')(
    {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
    },
    ({ theme }) => ({
        marginBottom: themedValue(theme, {
            fallback: undefined,
            perthnow: metrics.perthnow.margins.md,
        }),
    }),
)

// Required on 320px devices on amp, only way is to center ad and display empty underneath it
export const StyledAmpAd = styled('div')({
    display: 'block',
    '@media (max-width: 320px)': {
        margin: 0,
        transform: 'translate(-50%)',
        left: '50%',
        position: 'absolute',
    },
})

// Display an empty block div if at the 320px breakpoint
export const StyledAmpSpace = styled('div')({
    display: 'block',
    '@media (max-width: 320px)': {
        width: '100%',
        minHeight: calcRem(250),
        height: calcRem(250),
        position: 'relative',
    },
})
