import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, Theme, transition } from '../../__styling'

export function commonStyles(theme: Theme): CSSObject {
    return {
        display: 'inline-block',
        borderRadius: calcRem(2),
        fontFamily: theme.fonts.sansSerif,
        background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        padding: calcRem(8),
        color: theme.colors.palette.white,
        fontWeight: 700,
        textDecoration: 'none',
        textTransform: 'uppercase',
        cursor: 'pointer',
        ...transition({ properties: ['color'] }),
        minHeight: calcRem(40),
        position: 'relative',
        textAlign: 'center',

        '&::after': {
            display: 'none',
            border: `1px solid ${theme.colors.palette.black}`,
            content: `''`,
            position: 'absolute',
            top: -3,
            left: -3,
            width: 'calc(100% + 6px)',
            height: 'calc(100% + 6px)',
            borderRadius: calcRem(4),
        },

        '&:hover': {
            background: `linear-gradient(90.01deg, #1b008d 0.01%, #721f13 99.72%)`,
        },

        '&:focus': {
            outline: 'none',

            '&::after': {
                display: 'block',
            },
        },

        '&:active': {
            background: theme.colors.actions.button.primary.background.active,
            borderColor: theme.colors.actions.button.primary.border.active,
            color: theme.colors.actions.button.primary.text.active,
        },
    }
}

export const StyledPollieRaterButtonLink = styled(WebLink)(({ theme }) => ({
    ...commonStyles(theme),
}))

export const StyledPollieRaterActionButton = styled('button')(({ theme }) => ({
    ...commonStyles(theme),
    position: 'relative',
    border: 'none',
    textTransform: 'none',
    margin: calcRem(6, 0, 4, 0),
    minHeight: calcRem(30),
    minWidth: calcRem(96),

    '&:disabled': {
        pointerEvents: 'none',
        background: theme.colors.actions.button.primary.background.disabled,
        color: theme.colors.actions.button.primary.text.disabled,
    },
}))
