import {
    BreakpointState,
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../../__styling'

export type SectionContentTheme<T extends ThemeConfig> = {
    maxWidth: string
    gap: BreakpointState<T, string>
}

export const theNightlySectionContentTheme = (scheme: TheNightlyTheme) => {
    const theme: SectionContentTheme<TheNightlyThemeConfig> = {
        maxWidth: calcRem(scheme.siteMetrics.mainContentWidth),
        gap: {
            default: calcRem(24),
            sm: calcRem(scheme.margins.lg),
            lg: calcRem(48),
        },
    }
    return theme
}
