import { TheWestSection } from '@news-mono/common'
import { StaticRoute, StaticRoutes } from '@news-mono/web-common'
import { getSwgLandingPage } from '../regionals/get-swg-landing-page'

const subscribeWithGoogleLandingPage: StaticRoute<TheWestSection> = (
    services,
) =>
    getSwgLandingPage({
        section: 'default',
        getAdTargeting: services.getAdTargeting,
    })

export const getSwgRouteInfo: StaticRoutes<TheWestSection> = {
    '/google': subscribeWithGoogleLandingPage,
}
