import { ContentDataTypes } from '@news-mono/web-common'
import { SectionHeaderProps } from '../section-header/SectionHeader/SectionHeader'
import { ThemeMargins } from '../__styling/settings/metrics'

export interface SectionHeaderNavLink {
    text: string
    link: string
    isExternalLink?: boolean
    forceInternalSSR?: boolean
}
export interface CollectionRouteProps {
    sectionHeader?: SectionHeaderProps
    verticalSpacing?: keyof ThemeMargins | undefined
}

export const calculateExpectedCardCountFromContentData = (
    data: ContentDataTypes,
): number => {
    if (data === undefined) {
        throw new Error(
            'calculateExpectedCardCountFromContentData received no data',
        )
    }

    switch (data.type) {
        case 'active-promotion':
            return 1
        case 'video-listing':
            return data.paging.pageSize
        case 'aggregated':
            return Math.min(
                data.sources.reduce((acc, val) => {
                    if (val.type === 'listing') {
                        acc += val.paging.pageSize
                    } else if (val.type === 'curation') {
                        acc += val.pageSize
                    }
                    return acc
                }, 0),
                data.maxPageSize ?? Number.POSITIVE_INFINITY, // default to infinity, i.e. no limit.
            )
        case 'listing':
            return data.paging.pageSize
        case 'context':
            //unknown.
            return 0
        default:
            return data.pageSize
    }
}
