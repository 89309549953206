import { DataLayerEventName, SubscribeEvent } from '@news-mono/web-common'

export function createSubscribeLinkClickEvent(
    originator:
        | 'header'
        | 'header-nav'
        | 'footer-nav'
        | 'flyout-nav'
        | 'header-utility'
        | 'subscribe-card'
        | 'header-dropdown',
    onEvent: (event: SubscribeEvent) => void,
): () => void {
    const eventPayload: SubscribeEvent['payload'] = {
        paywallType: 'freemium',
        leadDepth: 0,
        creative: undefined,
        breachScreenType: undefined,
        popupMethod: undefined,
        deal: undefined,
        pricepoint: [7, 8],
    }

    return () => {
        onEvent({
            type: DataLayerEventName.subscribeClick,
            originator,
            payload: eventPayload,
        })
    }
}
