import React from 'react'
import { HeaderDataProps } from '../../compositions'
import { StyledTabHeader, StyledTabHeaderItem } from './TabHeader.styled'

export interface TabHeaderProps {
    data: Array<HeaderDataProps>
    active: HeaderDataProps
    setActive: any
}

export const TabHeader = ({ data, active, setActive }: TabHeaderProps) => {
    const onClick = (item: HeaderDataProps) => {
        setActive(item)
    }

    return (
        <StyledTabHeader>
            {data.map((item: HeaderDataProps, index: number) => {
                return (
                    <StyledTabHeaderItem
                        key={index}
                        onClick={() => onClick(item)}
                        active={active.title === item.title}
                    >
                        {item.title}
                    </StyledTabHeaderItem>
                )
            })}
        </StyledTabHeader>
    )
}
