import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import {
    TheNightlyThemeConfig,
    Theme,
    getComponentTheme,
    metrics,
} from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

export const StyledHeading = styled('h1')(({ theme }) => ({
    width: '100%',
    fontSize: calcRem(22),
    lineHeight: 1.15,
    margin: `0 0 ${calcRem(theme.margins.md)} 0`,
    padding: 0,

    [breakpoint('sm')]: {
        fontSize: calcRem(32),
    },
}))
StyledHeading.displayName = 'SearchHeading'

const getHeadingStyle = (theme: Theme) => [textMixin(theme, 'article')]

export const StyledArticleMessage = styled('p')(({ theme }) => [
    getHeadingStyle(theme),
    {
        margin:
            theme.kind === 'thenightly'
                ? calcRem(
                      metrics.thenightly.margins.md,
                      metrics.thenightly.margins.lg,
                  )
                : calcRem(metrics.thenightly.margins.xxxl, 0),

        [breakpoint('lg')]: {
            margin:
                theme.kind === 'thenightly'
                    ? calcRem(metrics.thenightly.margins.xxxl, 0)
                    : undefined,
        },
    },
])
StyledArticleMessage.displayName = 'StyledArticleMessage'

export const StyledMessage = styled('p')(({ theme }) => ({
    width: '100%',
    fontSize: calcRem(22),
    fontWeight: 700,
    lineHeight: 1.2,
    margin: calcRem(theme.margins.md, 0),

    [breakpoint('sm')]: {
        fontSize: calcRem(32),
    },
}))
StyledMessage.displayName = 'SearchMessage'

export const StyledSearchTerm = styled('span')(({ theme }) => ({
    fontWeight: 400,
    fontStyle: 'italic',
    color: themedValue(theme, {
        thewest: colors.thewest.greyElephant,
        sevennews: colors.sevennews.stormGrey,
        perthnow: colors.perthnow.greyMarble,
        fallback: undefined,
    }),
}))
StyledSearchTerm.displayName = 'SearchTerm'

export const StyledSearchResults = styled('div')(({ theme }) => ({
    margin:
        theme.kind === 'thenightly'
            ? `auto ${calcRem(theme.margins.outerMargin)}`
            : `auto`,

    [breakpoint('lg')]: {
        margin: 'auto',
    },
}))
StyledSearchResults.displayName = 'StyledSearchResults'

export const StyledTitle = styled('p')(
    ({ theme }) => textMixin<TheNightlyThemeConfig>(theme, 'article-main'),
    {
        display: 'block',
        width: '100%',
        margin: calcRem(
            metrics.thenightly.margins.sm,
            metrics.thenightly.margins.lg,
        ),

        [breakpoint('lg')]: {
            display: 'none',
        },
    },
)
StyledTitle.displayName = 'StyledTitle'
