import {
    ArticleAvailableEvent,
    EntitledToView,
    ImageSourceWithCaptions,
    PublicationEvents,
    PublicationType,
    RequiredAccess,
} from '@news-mono/web-common'
import H from 'history'
import React, { Fragment } from 'react'
import { Logger } from 'typescript-log'
import { createIsEntitledChangeHandler } from './createIsEntitledChangeHandler'
import {
    StyledGalleryBreach,
    StyledGalleryBreachNotification,
} from './GalleryBreach.styled'
import { LazyBreachWallContentWrapper } from './components/BreachWallContent/BreachWallContentWrapper.lazy'

interface GalleryProps {
    requiredAccess: RequiredAccess
    location: H.Location
    images: ImageSourceWithCaptions[]
    onEvent: (event: PublicationEvents | ArticleAvailableEvent) => void
    children: (
        content: {
            images: ImageSourceWithCaptions[]
        },
        entitled: boolean,
    ) => React.ReactElement<any>
    gallery: PublicationType
    slug: string
    log: Logger
    forceLoad?: boolean
}

export const GalleryBreach: React.FC<GalleryProps> = (props) => {
    const {
        requiredAccess,
        location,
        images,
        onEvent,
        children,
        gallery,
        slug,
        log,
        forceLoad,
    } = props
    return (
        <EntitledToView
            requiredAccess={requiredAccess}
            articleSlug={slug}
            onIsEntitledChange={createIsEntitledChangeHandler(
                location,
                onEvent,
                gallery,
            )}
            entitled={() => {
                return children({ images }, true)
            }}
            notEntitled={() => {
                const { children } = props
                return (
                    <Fragment>
                        <StyledGalleryBreach>
                            {children({ images: [] }, false)}
                        </StyledGalleryBreach>
                        <StyledGalleryBreachNotification>
                            <LazyBreachWallContentWrapper
                                publication={gallery}
                                requiredAccess={requiredAccess.level}
                                location={location}
                                onEvent={onEvent}
                                log={log}
                                forceLoad={forceLoad}
                            />
                        </StyledGalleryBreachNotification>
                    </Fragment>
                )
            }}
        />
    )
}

GalleryBreach.displayName = 'GalleryBreach'
