import {
    breakpointSwitch,
    getComponentTheme,
    Theme,
    ThemeConfig,
} from '../../__styling'

export const textMixin = <T extends ThemeConfig>(
    theme: Theme<T>,
    textType: T['textType'],
) => {
    const textMixinTheme = getComponentTheme(theme, 'textMixin')

    return breakpointSwitch(textMixinTheme[textType], (fontRules) => fontRules)
}
