import { CSSObject } from '@emotion/styled'
import { pictureLayoutObjectFitContain } from '../../../content/Picture/layouts/object-fit-contain'
import { PictureLayout } from './layouts'

const objectFitCoverStyles: CSSObject = {
    '& img': {
        // Object fit for everything else
        objectFit: 'cover',
    },
}

export const pictureLayoutObjectFitCover: PictureLayout = {
    pictureStyles: () => [
        ...pictureLayoutObjectFitContain.pictureStyles(),
        objectFitCoverStyles,
    ],
}
