import { Product } from '@news-mono/web-common'
import React from 'react'
import { AmpImage } from '../../../content/Picture/Picture.amp'
import { getSourceLogo } from '../../../publication/SourceLogo/getSourceLogo'
import { createRenderTarget } from '../../../render-target'
import { useProduct } from '../../../__product/useProduct'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import {
    StyledBylineTextBlock,
    StyledSourceLogo,
    StyledSourceWrapper,
} from '../Byline.styled'

interface SourceLogoProps {
    source?: string
}

export const SourceLogo: React.FC<SourceLogoProps> = ({ source = '' }) => {
    const product = useProduct()
    const logoSrc = getSourceLogo(source)

    if (product === Product.SevenNews) {
        return source ? (
            <StyledSourceWrapper>
                {logoSrc ? (
                    <SrcLogo source={source} logoSrc={logoSrc} />
                ) : (
                    source
                )}
            </StyledSourceWrapper>
        ) : null
    }

    return <StyledBylineTextBlock isSource>{source}</StyledBylineTextBlock>
}

export interface SrcLogoProps {
    source: string
    logoSrc: {
        src: string
        width: number
        height: number
        maxWidth: number
    }
}

const SrcLogoWeb: React.FC<SrcLogoProps> = ({ source, logoSrc }) => (
    <StyledSourceLogo
        alt={source}
        src={logoSrc.src}
        maxWidth={logoSrc.maxWidth}
    />
)

const SrcLogo = createRenderTarget<SrcLogoProps>('SrcLogo', {
    web: ({ source, logoSrc }) => (
        <SrcLogoWeb source={source} logoSrc={logoSrc} />
    ),
    amp: ({ source, logoSrc }) => (
        <AmpImage
            width={logoSrc.width}
            height={logoSrc.height}
            alt={source}
            src={logoSrc.src}
            sizes={calcRem(logoSrc.maxWidth)}
        />
    ),
    rss: ({ source, logoSrc }) => <img src={logoSrc.src} alt={source} />,
    preview: SrcLogoWeb,
    app: ({ source, logoSrc }) => (
        <SrcLogoWeb source={source} logoSrc={logoSrc} />
    ),
})
