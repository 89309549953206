import React from 'react'
import {
    Product,
    PublicationCardItem,
    isPublicationCardItemWithVideo,
    useFeature,
} from '@news-mono/web-common'
import { IconTypes, Kicker } from '../../cards/Kicker/Kicker'
import { useProduct } from '../../__product'
import {
    StyledHeadlineEditorialLabel,
    StyledKickerEditorialLabel,
} from '../../cards/CardText/CardText.styled'
import { getEditorialType } from '../../templates'

export interface OlympicsCollectionKickerProps {
    item: PublicationCardItem
}

/**
 * Build up a OlympicsCollectionKicker utilising the original style of a 'kicker', and
 * the original components, however styling in a uniform Olympics style.
 *
 * @param item - The article we're building the kicker for.
 */
export const OlympicsCollectionKicker: React.FC<OlympicsCollectionKickerProps> =
    ({ children, item }) => {
        const product = useProduct()
        const showPremiumIcon =
            item.requiredAccess?.level === 'subscriber' ||
            item.requiredAccess?.level === 'registered'
        const allowCommentingOnTheWest =
            useFeature('comments') && product === Product.TheWest
        const showVideoIcon = isPublicationCardItemWithVideo(item)
        const showCommentsIcon =
            allowCommentingOnTheWest && item.allowCommenting

        // Set Icon Types for Kicker
        const iconTypes: Array<IconTypes> = []
        showVideoIcon && iconTypes.push('video')
        showPremiumIcon && iconTypes.push('premium')
        item.publicationKind === 'gallery' && iconTypes.push('gallery')
        showCommentsIcon && iconTypes.push('comments')

        // Get the editorialType and style the 'exclusive' tag for TheWest
        const storyClassification = true
        const editorialType = getEditorialType(item, storyClassification)

        return (
            <>
                {/* Editorial Label shown on The West next to the title only on some places like the latest news page */}
                {editorialType &&
                    product !== 'sevennews' &&
                    product !== 'thenightly' && (
                        <StyledHeadlineEditorialLabel type={editorialType} />
                    )}

                <Kicker
                    size={'large'}
                    kickerPosition={'topleft'}
                    icons={iconTypes}
                    kickerVisibility={'visible'}
                    kickerType={'text'}
                    requiredAccess={item.requiredAccess}
                    kickerStyleGuide={'olympics'}
                >
                    {/* Editorial Label shown on The West inline with kickers */}
                    {editorialType &&
                        product !== 'sevennews' &&
                        product !== 'thenightly' && (
                            <StyledKickerEditorialLabel
                                type={editorialType}
                                isOlympics={true}
                            />
                        )}
                    {children}
                </Kicker>
            </>
        )
    }
