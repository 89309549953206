import { AppState, RouteStatus } from '@news-mono/web-common'
import { default as React } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isServerEnvironment } from '../../../web-common/src/environment'

export type RedirectPathDeterminer = (props: Partial<RedirectorProps>) => string

export interface OwnProps {
    getRedirectPathFromState: RedirectPathDeterminer
    status?: number
    isStaticRedirect?: boolean
}

export type RedirectorProps = OwnProps & AppState & DispatchProp<any>

export const InternalRedirector: React.FC<RedirectorProps> = ({
    getRedirectPathFromState,
    status,
    isStaticRedirect,
    ...props
}) => {
    // <Redirect> does not work for static routes.
    if (isStaticRedirect && !isServerEnvironment()) {
        window.location.pathname = getRedirectPathFromState(props)
        return null
    }

    return (
        <RouteStatus statusCode={status || 302}>
            <Redirect to={getRedirectPathFromState(props)} />
        </RouteStatus>
    )
}

const mapStateToProps = (state: AppState) => state

export const Redirector = connect<AppState, {}, OwnProps, AppState>(
    mapStateToProps,
    null,
    null,
    {},
)(InternalRedirector)
