import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Paragraph } from '../../publication/Paragraph/Paragraph'

export interface ParagraphProps {
    text: string
}

export const ParagraphRegistration = createRegisterableComponent(
    'paragraph',
    (props: ParagraphProps, services) => (
        <Paragraph text={props.text} onEvent={services.onEvent} />
    ),
)
