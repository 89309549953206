import styled, { StyledComponent } from '@emotion/styled'
import {
    createPropFilter,
    useFeature,
    WebLinkProps,
    WebNavLink,
    WebNavLinkProps,
} from '@news-mono/web-common'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { IconEmptyCart, IconFullCart } from '../../icons/IconCart/IconCart'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { IconUser } from '../../icons/IconUser/IconUser'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { fonts } from '../../__styling/settings/fonts'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const subscribeButtonClassname = 'subscribe-button'
export const usernameButtonClassname = 'username-button'

export const StyledActionLinks = styled('div')(({ theme }) => {
    const subscriberDropdownEnabled = useFeature('subscriber-dropdown')

    return [
        {
            display: 'flex',
            fontFamily: fonts.thewest.sansSerif,
            height: calcRem(40),
        },

        subscriberDropdownEnabled && {
            [breakpoint('sm')]: {
                marginLeft: calcRem(16),
            },
            [breakpoint('md')]: {
                marginLeft: calcRem(32),
            },
        },
        !subscriberDropdownEnabled && {
            '> *:not(:last-child)::after': {
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                content: `''`,
                height: calcRem(20),
                borderRight: `1px solid ${colors.thewest.greyDolphin}`,
            },
        },
        {
            '> *:last-child': {
                paddingRight: 0,
            },
        },

        // Need to hide the psuedo element when a regional site is active
        // as the return link gets hidden in StyledReturnLink after the sm breakpoint
        theme.section !== 'default' && {
            [breakpointMax('sm')]: {
                [`.${subscribeButtonClassname}`]: {
                    '::after': {
                        content: 'none',
                    },
                },
            },
        },
    ]
})

export const NewStyledActionLinks = styled(StyledActionLinks)({
    '> *:last-child': {
        paddingRight: 'revert',
    },
})

export interface ActionLinkProps {
    isPrimary: boolean
    strokeHover?: boolean
}

const linkVertPadding = 13

export const StyledReturnLink = styled(WebNavLink, {
    shouldForwardProp: createPropFilter<WebNavLinkProps>()([
        'isPrimary',
        'strokeHover',
    ]),
})<ActionLinkProps>({
    position: 'relative',
    padding: calcRem(linkVertPadding / 2, 8),
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: calcRem(12),
    fontWeight: 600,
    textDecoration: 'none',

    [breakpointMax('sm')]: {
        display: 'none',
    },
})

export const StyledActionLink = styled(WebNavLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'isPrimary',
        'strokeHover',
    ]),
})<ActionLinkProps>(
    {
        position: 'relative',
        padding: calcRem(linkVertPadding / 2, 8),
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        fontSize: calcRem(14),
        fontWeight: 700,
        textDecoration: 'none',
        textTransform: 'uppercase',

        '&:hover, &:focus': {
            outline: 'none',
            textDecoration: 'underline',
            color: colors.thewest.westblue,
        },

        [`&.${usernameButtonClassname}`]: {
            padding: calcRem(0, 0, 0, 2),
            textDecoration: 'underline',
        },
    },
    ({ isPrimary, strokeHover, theme }) => [
        {
            color: isPrimary
                ? theme.sectionValues.primaryColor
                : colors.thewest.greyGorilla,
        },
        strokeHover && {
            '&:hover svg': {
                stroke: colors.thewest.greyGorilla,
            },
        },
    ],
)

export const StyledActionLinkSkeleton = StyledActionLink.withComponent(
    'span',
) as StyledComponent<ActionLinkProps>

export const StyledActionText = styled('p')({
    position: 'relative',
    padding: calcRem(linkVertPadding / 2, 8),
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: calcRem(14),
    fontWeight: 700,
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: colors.thewest.greyGorilla,
    margin: 0,
})

export const StyledActionTextUsername = styled(StyledActionText)({
    display: 'none',
    [breakpoint('xs')]: {
        display: 'flex',
    },
})

export interface ActionButtonProps {
    isPrimary?: boolean
}

export const StyledActionButton = styled('button')<ActionButtonProps>(
    ({ theme, isPrimary }) => ({
        position: 'relative',
        padding: calcRem(linkVertPadding / 2, 8),
        whiteSpace: 'nowrap',
        border: 0,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontSize: calcRem(14),
        fontWeight: 700,
        lineHeight: 1,
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: `color ${easing.easeOut.fast}, background ${easing.easeOut.fast}, boxShadow ${easing.easeOut.fast}`,
        fontFamily: theme.fonts.sansSerif,
        color: isPrimary
            ? theme.sectionValues.primaryColor
            : colors.thewest.greyGorilla,

        '&:hover': {
            textDecoration: 'underline',
        },

        '&:focus, &.is-active': {
            textDecoration: 'underline',
        },
    }),
)

export const NewStyledActionButton = styled(
    StyledActionButton,
)<ActionButtonProps>(({ theme, isPrimary }) => ({
    margin: isPrimary ? 7 : 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: isPrimary ? 2 : 0,
    backgroundColor: isPrimary
        ? theme.sectionValues.primaryColor
        : 'transparent',
    color: isPrimary
        ? theme.colors.background.primary
        : colors.thewest.greyGorilla,
}))

export const StyledPremiumIcon = styled(IconPremium)({
    width: calcRem(17),
    height: calcRem(17),
    verticalAlign: 'text-top',
    marginRight: calcRem(4),
})

export const NewStyledPremiumIcon = styled(StyledPremiumIcon)({
    backgroundColor: colors.white,
    borderRadius: 10,
})

export const StyledIconUser = styled(IconUser)(({ theme }) => ({
    height: calcRem(20),
    width: calcRem(20),
    marginLeft: calcRem(8),
    stroke: theme.sectionValues.primaryColor,
}))

export const StyledCartContainer = styled.div({
    alignSelf: 'center',
    svg: {
        verticalAlign: 'text-bottom',
    },
})

export const StyledEmptyCartIcon = styled(IconEmptyCart)({
    width: calcRem(32),
    paddingLeft: calcRem(10),
})

export const StyledFullCartIcon = styled(IconFullCart)({
    width: calcRem(32),
    paddingLeft: calcRem(10),
})

export const StyledSkeleton = styled(Skeleton)({
    display: 'inline-block',
    width: calcRem(60),
    margin: 8,
})
