import {
    AllEvents,
    AppState,
    AuthenticationState,
    BreakingNewsCard,
    CollectionEvent,
    createCardClickedEvent,
    createCollectionAvailableEvent,
    entitledToAll,
    raiseSelectItemEvent,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import {
    StyledBreakingNews,
    StyledBreakingNewsTitle,
    StyledIconChevronRight,
    StyledPromoStrapMark,
    StyledWrapper,
} from '../BreakingNews/BreakingNews.styled'
import { BlackFridayStrap } from '../BlackFridayStrap/BlackFridayStrap'
import { useAuthActions } from '../../user-registration'

export interface PromoStrapProps {
    titlePrefix: string
    unsubTitlePrefix: string
    link: string | undefined
    unsubLink: string | undefined
    onEvent: (event: AllEvents) => void
}

export interface PromoStrapBanner {
    title: string
    link: string | undefined
    onEvent: (event: AllEvents) => void
}

export const PromoStrap = (props: PromoStrapProps) => {
    const { titlePrefix, unsubTitlePrefix, link, unsubLink, onEvent } = props
    const product = useProduct()
    const hideOnMobileAndApp = useFeature('hide-mobile-breaking-news-banner')
    const blackFridayPromoStrap = useFeature('black-friday-promo-strap-style')
    const { getSubscribeLink } = useAuthActions()
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isEntitled = entitledToAll(authentication)
    const cardFormat = 'BreakingNews'
    let title: string
    let bannerLink: string | undefined

    if (isEntitled) {
        title = titlePrefix
        bannerLink = link
    } else {
        title = unsubTitlePrefix
        bannerLink = unsubLink
    }

    const newItem: BreakingNewsCard = {
        shortHeadline: title,
        link: link ? link : '',
        cardType: 'breaking-news',
        listId: 'promo-strap',
    }
    const linkClicked = () => {
        onEvent(
            createCardClickedEvent(
                onEvent,
                newItem,
                'PromoStrap',
                cardFormat,
                1,
            ),
        )
    }

    if (title && bannerLink && authentication.hasAttemptedValidation) {
        if(blackFridayPromoStrap && !isEntitled) {
            return (
                <ImpressionAvailable
                    loading={false}
                    available={() => {
                        onEvent(
                            createCollectionAvailableEvent(
                                [`Promo Strap: ${title}`],
                                `Promo Strap`,
                                product,
                                onEvent,
                                {
                                    cardFormat: 'PromoStrap',
                                },
                            ),
                        )
                    }}
                >
                    {() => (
                        <BlackFridayStrap bannerLink={getSubscribeLink()} onEvent={onEvent} />
                    )}
                </ImpressionAvailable>
            )
        }

        return (
            <ImpressionAvailable
                loading={false}
                available={() => {
                    onEvent(
                        createCollectionAvailableEvent(
                            [`Promo Strap: ${title}`],
                            `Promo Strap`,
                            product,
                            onEvent,
                            {
                                cardFormat: 'PromoStrap',
                            },
                        ),
                    )
                }}
            >
                {(ref) => (
                    // TODO You can't pass a ref down to a custom component. We should be able
                    // to fix this with emotion 10. Wrapping in a div for now
                    <div ref={ref}>
                        <StyledBreakingNews
                            to={bannerLink ? bannerLink : ''}
                            target={'_blank'}
                            onClick={linkClicked}
                            breakingNewsTheme="promo-strap"
                            hideOnMobileAndApp={hideOnMobileAndApp}
                        >
                            <StyledWrapper>
                                <StyledPromoStrapMark />
                                <StyledBreakingNewsTitle
                                    breakingNewsTheme="promo-strap"
                                    fontScale={1}
                                >
                                    {`${title}`}
                                </StyledBreakingNewsTitle>
                            </StyledWrapper>
                            <StyledIconChevronRight />
                        </StyledBreakingNews>
                    </div>
                )}
            </ImpressionAvailable>
        )
    }
    return null
}
PromoStrap.displayName = 'PromoStrap'
