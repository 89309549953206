import {
    AppState,
    AuthenticationState,
    isEntitledCheck,
    RenderTargetContext,
} from '@news-mono/web-common'
import { RequiredAccessDTO } from '@west-australian-newspapers/publication-types'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SwGState } from '../data'
import { THEWEST_ALL, THEWEST_PREMIUM } from './common'
import { PublicationToEntitlementMap } from './PublicationToEntitlements'

interface EntitledToViewProps {
    requiredAccess: RequiredAccessDTO
    /** slug to check against mmo referrer slug to allow access */
    articleSlug?: string
    topicIds?: string[]
    entitled: () => React.ReactElement<any> | null
    notEntitled?: (
        loginStatusChecked: boolean,
    ) => React.ReactElement<any> | null
    onIsEntitledChange?: (event: {
        entitled: boolean
        loggedInStatusChecked: boolean
    }) => void
}

function checkAgainstTopicsAndEntitlements(
    topics: string[],
    entitlements: string[]
) {
    const entitlementsMap = PublicationToEntitlementMap

    for (let index = 0; index < topics.length; index++) {
        const topicId = topics[index]
        const expectedEntitlements = entitlementsMap.get(topicId)

        // if there was an entitlement value found for the topicId, and the user has entitlements
        if (expectedEntitlements !== undefined && expectedEntitlements?.filter(entitlement => entitlements.includes(entitlement)).length > 0) {
            return true
        }
    }

    return false
}

export function entitledToViewContent(
    requiredAccess: RequiredAccessDTO,
    authentication: AuthenticationState,
    slug?: string,
    topicIds?: string[]
) {
    /**
     * The EntitledToView component hides an article behind a breach screen if the user
     * isn't subscribed to see it. But we also have a registration wall for anonymous
     * users. The registration wall shows the first 3 paragraphs of an article so it
     * isn't handled in the EntitledToView code here but rather in ArticleBlockContent
     */
    if (requiredAccess.level !== 'subscriber') {
        return true
    }

    /**
     * THEWEST_PREMIUM is a sub-entitlement of THEWEST_ALL
     * which indicates user only have premium article access
     */
    if (
        authentication.entitlements.includes(THEWEST_ALL) ||
        authentication.entitlements.includes(THEWEST_PREMIUM)
    ) {
        return true
    }

    if (typeof slug !== 'undefined' && authentication.bypassSlug === slug) {
        return true
    }

    // for topic paywall, we could pass in a list of entitlements and the primary (or secondary) topics.
    // then could look for an entitlement to 'thewest:all' or 'thewest:<topic>', or some other mapping
    if (typeof topicIds !== 'undefined' && topicIds.length > 0) {
        return checkAgainstTopicsAndEntitlements(topicIds, authentication.entitlements)
    }

    return false
}

export const EntitledToView: React.FC<EntitledToViewProps> = ({
    requiredAccess,
    entitled,
    notEntitled,
    onIsEntitledChange,
    articleSlug,
    topicIds,
}) => {
    const renderTarget = useContext(RenderTargetContext)
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )

    const swgState = useSelector<AppState, SwGState>(({ SwG }) => SwG)

    const isEntitled =
        entitledToViewContent(requiredAccess, authentication, articleSlug, topicIds) ||
        renderTarget.renderTarget === 'preview'

    const dispatch = useDispatch()

    useEffect(() => {
        if (onIsEntitledChange) {
            onIsEntitledChange({
                entitled: isEntitled,
                loggedInStatusChecked: authentication.hasAttemptedValidation,
            })
            // Once the entitlement is checked, update the Redux Store
            dispatch(
                isEntitledCheck({
                    isEntitled,
                    requiredAccess,
                    articleSlug,
                }),
            )
        }
        // Ignoring eslint-disable-next-line react-hooks/exhaustive-deps for this effect
        // because it will continue to trigger. We can come back and look at useCallback and
        // resolving the issue. Any news deps should still be added
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEntitled, authentication.hasAttemptedValidation])

    return isEntitled
        ? entitled()
        : notEntitled
        ? notEntitled(authentication.hasAttemptedValidation)
        : null
}
