import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { IconEmail } from '../../icons/IconEmail/IconEmail'
import { getComponentTheme } from '../../__styling'
import { interactionSwitch } from '../../__styling/style-functions/interactionState'
import { IconEmailTN } from '../../icons'

export const StyledProfileEmailButton = styled(WebLink)(({ theme }) => {
    const profileEmailButtonTheme = getComponentTheme(
        theme,
        'profileEmailButton',
    )
    return [
        {
            borderRadius: profileEmailButtonTheme.borderRadius,
            border: profileEmailButtonTheme.border,
            fontSize: profileEmailButtonTheme.fontSize,
            fontWeight: profileEmailButtonTheme.fontWeight,
            fontFamily: profileEmailButtonTheme.fontFamily,
            lineHeight: profileEmailButtonTheme.lineHeight,
            textDecoration: profileEmailButtonTheme.textDecoration,
            display: profileEmailButtonTheme.display,
            alignItems: profileEmailButtonTheme.alignItems,
            position: profileEmailButtonTheme.position,
            transition: profileEmailButtonTheme.transition,
            alignSelf: profileEmailButtonTheme.alignSelf,
            padding: profileEmailButtonTheme.padding,
            gap: profileEmailButtonTheme.gap,
        },
        interactionSwitch(
            profileEmailButtonTheme.interactionState,
            ({ background, textColor, borderColor, outline }) => ({
                background,
                color: textColor,
                borderColor,
                outline,
            }),
        ),
    ]
})

export const StyledIconEmail = styled(IconEmail)(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'profileEmailButton').icon
    return {
        fill: 'currentColor',
        width: iconTheme.width,
        height: iconTheme.height,
        margin: iconTheme.margin,
    }
})
export const StyledNightlyIconEmail = styled(IconEmailTN)(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'profileEmailButton').icon
    return {
        fill: 'none',
        width: iconTheme.width,
        height: iconTheme.height,
        margin: iconTheme.margin,
    }
})
