import styled from '@emotion/styled'
import { ImageRatio, withClass } from '@news-mono/web-common'
import {
    FundingLabel,
    FundingLabelProps,
} from '../../advertising/FundingLabel/FundingLabel'
import { CardMedia } from '../../cards/CardMedia/CardMedia'
import { CardHeadline } from '../../cards/CardText/CardHeadline'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { Timestamp } from '../../content/CardTimestamp/CardTimestamp'
import { colors } from '../../__styling/settings/colors'
import { FontScales } from '../../__styling/settings/fontScale'
import { metrics } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointBetween,
    breakpointMax,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { CardHeadlineSkeleton } from '../CardText/CardHeadlineSkeleton'
import { CardTeaser } from '../CardText/CardTeaser'
import { StyledHeadlineText } from '../CardText/CardText.styled'
import { LandscapeImageSize } from './Landscape'

export const styledLandscapeClassName = 'LandscapeCard'

export interface StyledLandscapeProps {
    showSponsoredStyles?: boolean
    hasBackground?: boolean
    edgeToEdge?: boolean
    hasPadding?: boolean
    hideBorder?: boolean
}

export const getLandscapeWrapperTag = (tag: 'div' | 'li') => {
    return withClass(styledLandscapeClassName)(
        styled(tag || 'div')<StyledLandscapeProps>(
            {
                ...breakWords,
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                flexGrow: 1,
                alignItems: 'flex-start',
            },
            ({
                showSponsoredStyles,
                hasBackground,
                hasPadding,
                hideBorder,
                theme,
            }) => ({
                overflow: theme.radius ? 'hidden' : undefined,
                borderRadius: theme.radius?.lg,
                background: hasBackground ? colors.white : undefined,
                padding: themedValue(theme, {
                    thewest: undefined,
                    fallback: hasPadding
                        ? calcRem(theme.margins.sm)
                        : undefined,
                }),
                // prettier-ignore
                borderBottom: hideBorder ? undefined : themedValue(theme, {
                    thewest: undefined,
                    perthnow: `1px solid ${colors.perthnow.greyMarble}`,
                    sevennews: showSponsoredStyles ? `2px solid ${colors.black}` : `1px solid ${colors.perthnow.greyMarble}`,
                    fallback: undefined
    }),
            }),
            // Remove horizontal padding only
            ({ theme, edgeToEdge }) =>
                edgeToEdge && {
                    padding: edgeToEdge
                        ? `${calcRem(theme.margins.sm)} 0`
                        : calcRem(theme.margins.sm),
                },
        ),
    )
}

// Adds in the width and alignment styles for the wrapping link - hovers, focus, link styles come from CardTextInteractions component
export const StyledLandscapeLink = styled(CardTextInteractions)({
    display: 'flex',
    flex: '1 3 auto',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: 0,
})
StyledLandscapeLink.displayName = 'LandscapeLink'

export const StyledHeader = styled('div')(({ theme }) => ({
    fontSize: themedValue(theme, {
        thewest: calcRem(18),
        fallback: calcRem(16),
    }),
    lineHeight: 1.375,
    flexDirection: 'column',
    justifyContent: theme.kind === 'thenightly' ? 'center' : 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
    flex: `1 2 ${calcRem(120)}`,
    padding: themedValue(theme, {
        thewest: calcRem(0, 8, 16, 0),
        thenightly: calcRem(0, 8, 4, 0),
        fallback: calcRem(8, 8, 4, 0),
    }),

    [breakpoint('sm')]: {
        fontSize: calcRem(16),
    },
}))
StyledHeader.displayName = 'LandscapeHeaderWrapper'

interface StyledCardHeadlineProps {
    imageSize: LandscapeImageSize
}

// CardHeadline component alignment and fontsize overrides
export const StyledHeadline = styled(CardHeadline)<StyledCardHeadlineProps>(
    ({ theme, fontScale, imageSize }) => ({
        margin: 0,
        width: '100%',
        flex: 'none', // overide default headline flexbox from CardText component
        fontSize: themedValue(theme, {
            perthnow:
                imageSize === 'large'
                    ? fontScale && calcRem(Math.ceil(18 * fontScale))
                    : calcRem(14),
            sevennews: fontScale && calcRem(Math.ceil(18 * fontScale)),
            thenightly: calcRem(16),
            fallback: calcRem(14),
        }),
        lineHeight: themedValue(theme, {
            thenightly: calcRem(24),
            fallback: undefined,
        }),

        fontWeight: theme.kind === 'thenightly' ? 600 : undefined,

        [breakpoint('sm')]: {
            fontSize:
                theme.kind === 'thenightly'
                    ? calcRem(18)
                    : fontScale && calcRem(Math.ceil(18 * fontScale)),
        },
        [breakpoint('md')]: {
            lineHeight: themedValue(theme, {
                thenightly: calcRem(28),
                fallback: undefined,
            }),
            fontSize:
                theme.kind === 'thenightly'
                    ? calcRem(20)
                    : fontScale && calcRem(Math.ceil(18 * fontScale)),
        },
        [breakpointMax('xxs')]: {
            fontSize:
                theme.kind === 'thewest'
                    ? calcRem(metrics.thewest.cards.headingFontSize)
                    : undefined,
            margin: theme.kind === 'thewest' ? undefined : 0,
        },
    }),
)

StyledHeadline.displayName = 'LandscapeHeadline'

export const StyledCardHeadlineSkeleton = styled(CardHeadlineSkeleton)({
    margin: 0,
    width: '100%',
    flex: 'none', // overide default headline flexbox from CardText component

    [`& ${StyledHeadlineText}`]: {
        transform: `translateY(-${calcRem(metrics.perthnow.cards.textOffset)})`,
    },
})

export interface StyledMediaProps {
    imagePosition: 'left' | 'right'
    imageSize: LandscapeImageSize
}

const xsImageWidth = 95
const mobileImageWidth = 110

function getImageSize(imageSize: LandscapeImageSize) {
    switch (imageSize) {
        case 'small':
            return calcRem(90)
        case 'medium':
            return '40%'
        case 'large':
            return '50%'
        case 'normal':
            return calcRem(140)
    }
}

// CardMedia component width & margin overrides
export const StyledMedia = styled(CardMedia)<StyledMediaProps>(
    ({ imagePosition, imageSize, theme }) => ({
        flex: `1 3 ${calcRem(106)}`,
        maxWidth: getImageSize(imageSize),
        margin:
            imagePosition === 'right'
                ? `0 0 0 ${calcRem(theme.margins.sm)}`
                : `0 ${calcRem(theme.margins.sm)} 0 0`,
    }),
    ({ theme, imageSize }) =>
        theme.kind === 'thewest' && {
            [breakpointMax('sm')]: {
                maxWidth:
                    imageSize !== 'normal'
                        ? getImageSize(imageSize)
                        : calcRem(mobileImageWidth),
            },

            [breakpointBetween('sm', 'md')]: {
                maxWidth:
                    imageSize !== 'normal'
                        ? getImageSize(imageSize)
                        : calcRem(mobileImageWidth),
            },

            // Custom breakpoint for when landscape cards are in columns on the west
            '@media screen and (min-width: 1020px) and (max-width: 1070px)': {
                maxWidth:
                    imageSize !== 'normal'
                        ? getImageSize(imageSize)
                        : calcRem(xsImageWidth),
            },
        },
)
StyledMedia.displayName = 'LandscapeMedia'

export interface StyledTeaserProps {
    teaserFontScale?: FontScales
}

// CardTeaser component font size overrides
export const StyledTeaser = styled(CardTeaser)<StyledTeaserProps>(
    ({ theme, teaserFontScale }) => ({
        fontSize: themedValue(theme, {
            thewest: calcRem(12),
            fallback: calcRem(14),
        }),

        [breakpoint('sm')]: {
            fontSize: themedValue(theme, {
                thewest: teaserFontScale
                    ? calcRem(Math.ceil(16 * teaserFontScale))
                    : calcRem(12),
                fallback: calcRem(14),
            }),
        },
    }),
)

// FundingLabel component overrides for margins and wrapping
export const StyledFundingLabel = styled(FundingLabel)<FundingLabelProps>(
    ({ theme }) => ({
        marginTop: theme.margins.sm,
        whiteSpace: themedValue(theme, {
            sevennews: undefined,
            fallback: 'unset',
        }),
    }),
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            flex: 'auto',
            textAlign: 'right',
            alignSelf: 'flex-end',
            whiteSpace: 'normal',
        },
)
StyledFundingLabel.displayName = 'LandscapeFundingLabel'

export const StyledTimestamp = styled(Timestamp)(({ theme }) => ({
    color: themedValue(theme, {
        thewest: colors.thewest.greyElephant,
        fallback: colors.black,
    }),
}))

export const StyledInlineWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: theme.kind === 'thenightly' ? 'center' : 'flex-end',
    paddingTop: theme.kind === 'thenightly' ? calcRem(8) : undefined,
    gap: theme.kind === 'thenightly' ? calcRem(8) : undefined,
}))
