import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { rampAdUnit } from '../../home'
import { cnbcSegment } from '../../home/cnbc-segment'
import { inCaseYouMissedItSegment } from '../../home/in-case-you-missed-it-segment'
import { subscriberExclusive } from '../../home/subscriber-exclusive'
import { webStoriesSegment } from '../../home/web-stories-segment'
import { GetMainSectionFn, TopicSectionBuilder } from '../custom-topic-routes'

const heroSegment = layout.component({
    type: 'sierra',
    props: {
        verticalSpacing: 'gridGap',
        fixedRatios: ['16:9'],
        heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
        cardOrientation: {
            type: 'Landscape-Portrait',
            maxBreakpoint: 'md',
        },

        cardLayout: ['hero', 'commonWithBackground'],
        dataDefinitionArgs: {
            type: 'curation',
            name: 'countryman',
            offset: 0,
            pageSize: 2,
        },
    },
})

const subheroSegment = layout.component({
    type: 'uniform',
    props: {
        section: 'default',
        verticalSpacing: 'xl',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            { type: 'november', listLength: 5 },
            ['teaserWithBackground'],
            ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
            ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
        ],

        dataDefinitionArgs: {
            type: 'curation',
            name: 'countryman',
            offset: 2,
            pageSize: 10,
        },
    },
})

const revChronSegment = layout.component({
    type: 'lima',
    props: {
        verticalSpacing: 'xl',
        fontScale: 1.2,
        fixedRatios: ['16:9'],
        hasBackground: true,
        teaserMode: 'hidden',
        initialColumns: 1,
        intermediateColumns: 2,
        finalColumns: 4,
        cardOrientation: { type: 'Landscape-Portrait' },

        cardLayout: [
            ['teaserWithBackground'],
            [
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
                {
                    type: 'landscape',
                    format: 'landscape-smTeaser-mdNoTeaser',
                },
            ],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
        ],
        dataDefinitionArgs: {
            type: 'listing',
            topics: ['countryman'],
            includeSubTopics: false,
            paging: {
                page: 1,
                pageSize: 6,
            },
        },
    },
})

export const getCountrymanCuratedSection: TopicSectionBuilder['getCuratedSection'] =
    () => {
        return [
            heroSegment,
            subheroSegment,
            ...billboardLeaderboardMrec('one'),
            revChronSegment,
        ]
    }

export const getCountrymanRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        rampAdUnit,
        subscriberExclusive(),
        inCaseYouMissedItSegment(true),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'News',
                    headingUrl: '/countryman/news',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/news'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        cnbcSegment(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Grain',
                    headingUrl: '/countryman/grain',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/grain'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Livestock',
                    headingUrl: '/countryman/livestock',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/livestock'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('three'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Wool',
                    headingUrl: '/countryman/wool',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/wool'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Politics',
                    headingUrl: '/countryman/politics',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/politics'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        webStoriesSegment(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Machinery',
                    headingUrl: '/countryman/machinery',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/machinery'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Field Days',
                    headingUrl: '/countryman/field-days',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/field-days'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('four'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Horticulture',
                    headingUrl: '/countryman/horticulture',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/horticulture'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Finance & Markets',
                    headingUrl: '/countryman/finance-markets',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/finance-markets'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Opinion',
                    headingUrl: '/countryman/opinion',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/opinion'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: 'hidden',
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Country Communities',
                    headingUrl: '/countryman/country-communities',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['countryman/country-communities'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'taboola',
            feature: 'taboola-regionals',
            props: {
                taboolaContainerId: 'taboola-below-home-thumbnails',
                locationType: 'home',
            },
        }),
    ]
}
