import H from 'history'
import { BreachWallContentType, BreachWallStyle } from './BreachWallTypes'
import { BASE_50_DEAL_OFFERCODE } from '../common'
import { BreachWallOptions } from '../components/BreachWallContent/BreachWallContentWrapper'

function getAfl50OfferSubTopics() {
    return [
        'afl',
        'wafl',
        'aflw',
        'wafl-colts',
        'psa-footy',
        'west-coast-eagles',
        'fremantle-dockers',
    ]
}

// 'canShowXXBreach' is created to include topics and subpages, whereas 'otherOptions.canShowXX' is a site wide breach screen
export function getBreachWallOptions(location: H.Location, otherOptions: BreachWallOptions): { deal: string, type: BreachWallContentType, style: BreachWallStyle } | undefined {
    if(canShowAflBreach(location, otherOptions.canShowBreachDiscount50afl)) {
        return {
            deal: 'AFL50',
            type: '50-off-afl-breach-screen',
            style: 'default-breachwall-2024',
        }
    }
    if(canShowSchoolBreach(location, otherOptions.canShowBreachDiscount50sch)) {
        return {
            deal: 'SCHOOL30',
            type: 'top-50-schools',
            style: 'default-breachwall-2024',
        }
    }
    if(canShowBusinessBreach(location, otherOptions.canShowBusinessPackDiscount)) {
        return {
            deal: 'BUSN2',
            type: 'business-pack',
            style: 'default-breachwall-2024',
        }
    }
    if(canShowSportPackBreach(location, otherOptions.canShowSportPackDiscount)) {
        return {
            deal: 'SP3022',
            type: 'sport-pack',
            style: 'default-breachwall-2024',
        }
    }
    if(canShowSportBreach(location, otherOptions.canShowSportBreach)) {
        return {
            deal: 'sport',
            type: 'sport',
            style: 'default-breachwall-2024',
        }
    }
    if(otherOptions.canShowBreachDiscountGeneric) {
        return {
            deal: BASE_50_DEAL_OFFERCODE,
            type: '50-off-breach-discount',
            style: 'default-breachwall-2024',
        }
    }
    if(otherOptions.canShowEofyDiscount) {
        return {
            deal: 'EOFY-WINTER-SALE',
            type: 'subs-campaign-eofy-weekend-digital',
            style: 'eofy',
        }
    }
    if(otherOptions.canShowBlackFriday) {
        return {
            deal: 'black-friday',
            type: 'black-friday',
            style: 'black-friday',
        }
    }    if(otherOptions.canShowPriceIncrease) {
        return {
            deal: 'default-price-increase',
            type: 'default-price-increase',
            style: 'default-breachwall-2024',
        }
    }

    return undefined
}

// breach checkers

function canShowSportBreach(location: H.Location, canShow: boolean): boolean {
    return canShow && doesContainTopic(location, 'sport')
}
function canShowAflBreach(location: H.Location, canShow: boolean): boolean {
    return canShow && doesTopicContainSubTopics(location, getAfl50OfferSubTopics())
}
function canShowSchoolBreach(location: H.Location, canShow: boolean): boolean {
    return canShow && doesTopicContainSubTopic(location, 'top-50-schools')
}
function canShowBusinessBreach(location: H.Location, canShow: boolean): boolean {
    return canShow && doesContainTopic(location, 'business')
}
function canShowSportPackBreach(location: H.Location, canShow: boolean): boolean {
    return canShow && doesContainTopic(location, 'sport')
}

// topic checkers

export function doesContainTopic(location: H.Location, topic: string): boolean {
    const pathName = location.pathname
    return pathName.startsWith('/' + topic)
}
export function doesTopicContainSubTopics(location: H.Location, subTopics: string[]): boolean {
    const pathName = location.pathname
    const pathNameSplit = pathName.split('/')
    return pathNameSplit.length > 1 ? subTopics.filter(subTopic => pathName.includes(subTopic)).length > 0 : false
}
export function doesTopicContainSubTopic(location: H.Location, subTopic: string): boolean {
    const pathName = location.pathname
    const pathNameSplit = pathName.split('/')
    return pathNameSplit.length > 1 ? pathName.includes(subTopic) : false
}
