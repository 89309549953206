import { useTheme } from '@emotion/react'
import {
    Product,
    RequiredAccess,
    requiredAccessValue,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledCommentsIcon,
    StyledKicker,
    StyledKickerText,
    StyledPremiumIcon,
} from '../../cards/Kicker/Kicker.styled'
import { IconCamera } from '../../icons/IconCamera/IconCamera'
import { IconVideo2 } from '../../icons/IconVideo2/IconVideo2'
import { useProduct } from '../../__product/useProduct'
import { BreakpointKeys } from '../../__styling/settings/breakpoints'
import { themedValue } from '../../__styling/themed-value'

export interface KickerModeOptions {
    kickerPosition?: KickerPosition
    size?: KickerSizeOptions
    kickerType?: KickerType
    kickerVisibility?: KickerVisibility
    icons?: Array<IconTypes> // included because of Profile Card - otherwise it's defined in CardHeadline
    // {TODO} Remove this and all other mentions of this after Queens Legacy
    kickerBackgroundColorOverrideForPN?: string
}

export type KickerMode = 'hidden' | KickerModeOptions

export type KickerType = 'block' | 'text'
export type IconTypes = 'premium' | 'video' | 'gallery' | 'comments'
export type KickerSizeOptions = 'default' | 'large'
export type KickerBreakpoints = 'initial' | 'xs' | 'sm' | 'md'
export type KickerVisibility =
    | 'hidden'
    | 'visible'
    | Partial<Record<'initial' | BreakpointKeys, 'hidden' | 'visible'>>

export type KickerStyleGuide = 'default' | 'olympics'

export type KickerPosition =
    | 'topleft'
    | 'topleftinset'
    | 'topright'
    | 'bottomleft'
    // Sevennews specific
    | 'bottomleftinset'
    | 'mobilebottomleft-insetdesktop'
    | 'bottomleftinset-hero'
    | 'bottomleftinset-herovideo'
    | undefined

export interface KickerProps {
    className?: string
    icons?: Array<IconTypes>
    kickerVisibility?: KickerVisibility
    size?: KickerSizeOptions
    kickerPosition?: KickerPosition
    kickerType?: KickerType
    requiredAccess?: RequiredAccess
    kickerStyleGuide?: KickerStyleGuide
    // {TODO} Remove this and all other mentions of this after Queens Legacy
    kickerBackgroundColorOverrideForPN?: string
}

const iconWidth = 14
const iconVideoHeight = 8
const iconCameraHeight = 11
const iconPremiumHeight = 14
const iconSpacing = 6

export const Kicker: React.FC<KickerProps> = ({
    className,
    icons,
    children,
    requiredAccess,
    kickerPosition,
    kickerVisibility,
    kickerType,
    kickerStyleGuide = 'default',
    kickerBackgroundColorOverrideForPN,
    size = 'default',
}) => {
    const product = useProduct()
    const theme = useTheme()
    const publicationIcon = icons && getPublicationIcon(icons)

    const themedKickerType = themedValue(theme, {
        thewest: kickerType || 'text',
        perthnow: 'block',
        sevennews: kickerType || 'text',
        fallback: undefined,
    }) as KickerType

    const hasIconsDefined = icons && icons.length >= 1

    return (
        <StyledKicker
            className={className}
            size={size}
            kickerPosition={kickerPosition}
            kickerVisibility={kickerVisibility}
            kickerType={themedKickerType}
            kickerBackgroundColorOverrideForPN={
                kickerBackgroundColorOverrideForPN
            }
            kickerStyleGuide={kickerStyleGuide}
        >
            <StyledKickerText kickerStyleGuide={kickerStyleGuide}>
                {children}
                {product === 'thewest' && publicationIcon}
            </StyledKickerText>
            {hasIconsDefined &&
                icons!.includes('comments') &&
                theme.kind === Product.TheWest && <StyledCommentsIcon />}

            {hasIconsDefined &&
                icons!.includes('premium') &&
                requiredAccessValue(requiredAccess, {
                    subscriber: (
                        <StyledPremiumIcon
                            width={iconWidth}
                            height={iconPremiumHeight}
                        />
                    ),
                    anonymous: undefined,
                })}
        </StyledKicker>
    )
}
Kicker.displayName = 'Kicker'

export const StyledIconCamera = 'StyledIconCamera'
export const StyledIconVideo2 = 'StyledIconVideo2'

function getPublicationIcon(
    icons: Array<IconTypes> | undefined,
): React.ReactNode {
    if (icons && icons.includes('gallery')) {
        return (
            <IconCamera
                width={iconWidth}
                height={iconCameraHeight}
                horizontalSpacing={iconSpacing}
                className={StyledIconCamera}
            />
        )
    }

    if (icons && icons.includes('video')) {
        return (
            <IconVideo2
                width={iconWidth}
                height={iconVideoHeight}
                horizontalSpacing={iconSpacing}
                className={StyledIconVideo2}
            />
        )
    }

    return
}
