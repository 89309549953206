import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { DevelopingNews } from './DevelopingNews'

export const DevelopingNewsRegistration = createRegisterableComponentWithData(
    'developing-news',
    CurationAndCurationMetadataLoader,
    (props: {}, data, services) => {
        const product = useProduct()

        if (
            data.loaded &&
            data.result.articles.length > 0 &&
            data.result.metadata['developing-news-headline']
        ) {
            return (
                <ImpressionAvailable
                    loading={!data.loaded}
                    available={() => {
                        if (!data.loaded) {
                            console.warn(
                                'Available should never be called when loading is true',
                            )
                            return
                        }
                    }}
                >
                    {(ref) => (
                        <DevelopingNews
                            innerRef={ref}
                            items={data.loaded && data.result.articles}
                            headLine={
                                data.result.metadata['developing-news-headline']
                            }
                            themeOverrideType={
                                data.result.metadata['developing-news-theme']
                            }
                            onEvent={services.onEvent}
                        />
                    )}
                </ImpressionAvailable>
            )
        }
        return null
    },
)
