import {
    EpaperPublicationType,
    Issue,
    createEditionSelectItemEvent,
    createEditionViewItemListEvent,
} from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import {
    StyledDate,
    StyledDateSpan,
    StyledEpaperCollectionPaperContainer,
    StyledEpaperCollectionPaperMainContainer,
    StyledEpaperCollectionPaperSpanText,
    StyledEpaperCollectionPapersStyledImg,
    StyledEpaperCollectionPaperWrapper,
    StyledIconPremium,
} from './EpaperCollection.styled'
import { EpaperDualImage } from './EpaperDualImage'
import _ from 'lodash'
import { useProduct } from '../../../__product'
import { useSection } from '../../../__helpers'
import { TheWestSection } from '@news-mono/common'

export interface EpaperCollectionProps {
    issues: Issue[]
    sectionToExclude?: EpaperPublicationType
    onCardClick: (link?: string, editionData?: Issue) => void
    border?: boolean
    showMastheadName: boolean
    openEReaderOnCardClick: boolean
    readersAlsoLove: boolean
    onEvent: (event: any) => void
}

export const EpaperCollection: React.FC<EpaperCollectionProps> = (props) => {
    const [anchorElementTabIndex, setAnchorElementTabIndex] = useState(-1)
    const [labelVisibility, setLabelVisibility] = useState<
        'hidden' | 'visible'
    >('hidden')
    const firstEdition = useRef<HTMLAnchorElement>(null)
    const parentContainer = useRef<HTMLInputElement>(null)
    const product = useProduct()
    const section = useSection<TheWestSection>()

    const handleKeyDown = (event: any) => {
        if (
            event.key === 'Enter' &&
            !parentContainer.current?.matches(':focus-within:not(:focus)')
        ) {
            event.preventDefault()
            setAnchorElementTabIndex(0)
            firstEdition.current?.focus()
        }
        if (event.key === 'Escape') {
            setAnchorElementTabIndex(-1)
            parentContainer.current?.focus()
        }
    }
    const handleFocus = () => {
        setLabelVisibility('visible')
    }
    const handleFocusOut = () => {
        setLabelVisibility('hidden')
    }
    const updateValue = _.debounce(() => {
        props.onEvent(
            createEditionViewItemListEvent({
                issues: props.issues,
                collectionName: 'EditionPageCarousel',
                itemCategory: `editions_page_${
                    props.readersAlsoLove
                        ? 'readers_also_love_carousel'
                        : 'latest_editions_carousel'
                }`,
            }),
        )
    }, 100)
    const handleScroll = () => {
        updateValue()
    }

    let issues = props.issues
    if (props.sectionToExclude)
        issues = issues.filter(
            (issue) => issue.publicationName !== props.sectionToExclude,
        )

    useEffect(() => {
        props.onEvent(
            createEditionViewItemListEvent({
                issues: props.issues,
                collectionName: `${
                    product.charAt(0).toUpperCase() + product.slice(1) // Capitalize first letter - matches other uses :-)
                }EditionPage`,
                itemCategory: `editions_page_${
                    props.readersAlsoLove
                        ? 'readers_also_love'
                        : 'latest_editions'
                }`,
            }),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ position: 'relative' }} onScroll={handleScroll}>
            <label
                style={{
                    visibility: labelVisibility,
                    position: 'absolute',
                    top: '-30px',
                    left: '220px',
                }}
            >
                Press enter to select an edition. Use escape to exit selection.
            </label>
            <StyledEpaperCollectionPaperMainContainer
                aria-label={
                    'Press enter to select an edition, then use the tab key to cycle through the editions. Use escape to exit selection'
                }
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleFocusOut}
                tabIndex={0}
                ref={parentContainer}
            >
                {issues.map((issue, idx) => {
                    //check index, put a ref on the 1st element, then point to that ref on state change
                    const mastheadMetadata = props.showMastheadName
                        ? getMastheadMetadataFromMastheadName(
                              issue.publicationName,
                          )
                        : undefined

                    const ePaperRedirectUrl =
                        props.readersAlsoLove === true
                            ? getMastheadMetadataFromMastheadName(
                                  issue.publicationName,
                              )?.ePaperUrl
                            : issue.url

                    const editionName = `${
                        mastheadMetadata?.mastheadName || issue.publicationName
                    } - ${format(new Date(issue.date), 'd MMM yyyy')}`

                    return (
                        issue && (
                            <StyledEpaperCollectionPaperContainer
                                key={idx}
                                id={
                                    // only add id to 'Latest editions'
                                    !props.readersAlsoLove && idx < 7
                                        ? `${format(
                                              issue.date,
                                              'EEEE',
                                          ).toLowerCase()}-edition`
                                        : undefined
                                }
                            >
                                <a
                                    href={ePaperRedirectUrl}
                                    target="_blank"
                                    tabIndex={anchorElementTabIndex}
                                    ref={idx === 0 ? firstEdition : undefined}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.onEvent(
                                            createEditionSelectItemEvent({
                                                issue,
                                                collectionName: `${product}EditionPage`,
                                                product,
                                                itemDetails: {
                                                    category: `editions_page_${
                                                        props.readersAlsoLove
                                                            ? 'readers_also_love'
                                                            : 'latest_editions'
                                                    }`,
                                                    linkText: 'image',
                                                    linkUrl: issue.url,
                                                    itemName: editionName,
                                                },
                                            }),
                                        )
                                        props.onCardClick(
                                            ePaperRedirectUrl,
                                            issue.magazine ? issue : undefined,
                                        )
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                    }}
                                >
                                    {issue.magazine ? (
                                        <EpaperDualImage
                                            frontIssue={issue}
                                            backIssue={issue.magazine}
                                        />
                                    ) : (
                                        <StyledEpaperCollectionPapersStyledImg
                                            src={issue.coverUrl}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null
                                                currentTarget.src = require('../assets/paper.jpg')
                                            }}
                                            alt={
                                                mastheadMetadata !== undefined
                                                    ? `${mastheadMetadata.mastheadName} digital newspaper landing page`
                                                    : `${
                                                          getMastheadMetadataFromMastheadName(
                                                              issue.publicationName,
                                                          )?.mastheadName
                                                      } - ${format(
                                                          issue.date,
                                                          'EEEE',
                                                      )}, ${format(
                                                          issue.date,
                                                          'dd MMMM yyyy',
                                                      )} edition`
                                            }
                                        />
                                    )}
                                </a>
                                <StyledEpaperCollectionPaperWrapper
                                    aria-hidden={true}
                                >
                                    {mastheadMetadata !== undefined ? (
                                        <>
                                            {issue.isPremium && (
                                                <StyledIconPremium />
                                            )}
                                            <StyledEpaperCollectionPaperSpanText>
                                                {mastheadMetadata.mastheadName}
                                            </StyledEpaperCollectionPaperSpanText>
                                        </>
                                    ) : (
                                        <>
                                            {issue.isPremium && (
                                                <StyledIconPremium />
                                            )}
                                            <StyledEpaperCollectionPaperSpanText>
                                                <FormattedDate
                                                    date={issue.date}
                                                    index={idx}
                                                />
                                            </StyledEpaperCollectionPaperSpanText>
                                        </>
                                    )}
                                </StyledEpaperCollectionPaperWrapper>
                            </StyledEpaperCollectionPaperContainer>
                        )
                    )
                })}
            </StyledEpaperCollectionPaperMainContainer>
        </div>
    )
}

const FormattedDate: React.FC<{ date: Date; index: number }> = (props) => {
    return (
        <>
            <StyledDate>
                <StyledDateSpan>{format(props.date, 'EEEE')}, </StyledDateSpan>
                <StyledDateSpan
                    id={
                        props.index === 0
                            ? 'latest-digital-edition-timestamp'
                            : undefined
                    }
                >
                    {format(props.date, 'dd MMMM yyyy')}
                </StyledDateSpan>
            </StyledDate>
        </>
    )
}

export function getMastheadMetadataFromMastheadName(masthead: string) {
    type MastheadTypes = TheWestSections & {
        readonly perthnow: 'perthnow'
        readonly thewest: 'thewest'
        readonly 'the-west-australian': 'the-west-australian'
        readonly 'sunday-times': 'sunday-times'
        readonly stm: 'stm'
        readonly play: 'play'
        readonly 'the-extra': 'the-extra'
    }
    const mastheadToEPaperUrlMapping: Map<
        keyof MastheadTypes,
        { mastheadName: string; ePaperUrl: string }
    > = new Map([
        [
            'default',
            {
                ePaperUrl: 'https://thewest.com.au/editions',
                mastheadName: 'The West Australian',
            },
        ],
        [
            'the-west-australian',
            {
                ePaperUrl: 'https://thewest.com.au/editions',
                mastheadName: 'The West Australian',
            },
        ],
        [
            'sunday-times',
            {
                ePaperUrl: 'https://thewest.com.au/editions',
                mastheadName: 'Sunday Times',
            },
        ],
        [
            'albany-advertiser',
            {
                ePaperUrl: 'https://www.albanyadvertiser.com.au/editions',
                mastheadName: 'Albany Advertiser',
            },
        ],
        [
            'the-extra',
            {
                ePaperUrl:
                    'https://www.albanyadvertiser.com.au/editions/the-extra',
                mastheadName: 'The Extra',
            },
        ],
        [
            'broome-advertiser',
            {
                ePaperUrl: 'https://www.broomead.com.au/editions',
                mastheadName: 'Broome Advertiser',
            },
        ],
        [
            'bunbury-herald',
            {
                ePaperUrl: 'https://www.bunburyherald.com.au/editions',
                mastheadName: 'Bunbury Herald',
            },
        ],
        [
            'geraldton-guardian',
            {
                ePaperUrl: 'https://www.geraldtonguardian.com.au/editions',
                mastheadName: 'Geraldton Guardian',
            },
        ],
        [
            'great-southern-herald',
            {
                ePaperUrl: 'https://www.gsherald.com.au/editions',
                mastheadName: 'Great Southern Herald',
            },
        ],
        [
            'kalgoorlie-miner',
            {
                ePaperUrl: 'https://www.kalminer.com.au/editions',
                mastheadName: 'Kalgoorlie Miner',
            },
        ],
        [
            'the-kimberley-echo',
            {
                ePaperUrl: 'https://www.kimberleyecho.com.au/editions',
                mastheadName: 'The Kimberley Echo',
            },
        ],
        [
            'manjimup-bridgetown-times',
            {
                ePaperUrl: 'https://www.mbtimes.com.au/editions',
                mastheadName: 'Manjimup-Bridgetown Times',
            },
        ],
        [
            'midwest-times',
            {
                ePaperUrl: 'https://www.midwesttimes.com.au/editions',
                mastheadName: 'Midwest Times',
            },
        ],
        [
            'narrogin-observer',
            {
                ePaperUrl: 'https://www.narroginobserver.com.au/editions',
                mastheadName: 'Narrogin Observer',
            },
        ],
        [
            'pilbara-news',
            {
                ePaperUrl: 'https://www.pilbaranews.com.au/editions',
                mastheadName: 'Pilbara News',
            },
        ],
        [
            'sound-telegraph',
            {
                ePaperUrl: 'https://www.soundtelegraph.com.au/editions',
                mastheadName: 'Sound Telegraph',
            },
        ],
        [
            'south-western-times',
            {
                ePaperUrl: 'https://www.swtimes.com.au/editions',
                mastheadName: 'South Western Times',
            },
        ],
        [
            'busselton-dunsborough-times',
            {
                ePaperUrl: 'https://www.bdtimes.com.au/editions',
                mastheadName: 'Busselton Dunsborough Times',
            },
        ],
        [
            'countryman',
            {
                ePaperUrl: 'https://www.countryman.com.au/editions',
                mastheadName: 'Countryman',
            },
        ],
        [
            'augusta-margaret-river-times',
            {
                ePaperUrl: 'https://www.amrtimes.com.au/editions',
                mastheadName: 'Augusta Margaret River Times',
            },
        ],
        [
            'harvey-waroona-reporter',
            {
                ePaperUrl: 'https://www.harveyreporter.com.au/editions',
                mastheadName: 'Harvey-Waroona Reporter',
            },
        ],
        [
            'north-west-telegraph',
            {
                ePaperUrl: 'https://www.northwesttelegraph.com.au/editions',
                mastheadName: 'North West Telegraph',
            },
        ],
        [
            'perthnow',
            {
                ePaperUrl: 'https://www.perthnow.com.au/editions',
                mastheadName: 'PerthNow',
            },
        ],
        [
            'stm',
            {
                ePaperUrl: 'https://www.thewest.com.au/editions',
                mastheadName: 'STM',
            },
        ],
        [
            'play',
            {
                ePaperUrl: 'https://www.thewest.com.au/editions',
                mastheadName: 'PLAY',
            },
        ],
    ])

    return mastheadToEPaperUrlMapping.get(masthead as keyof MastheadTypes)
}
