import React from 'react'

export interface IconTwitterProps {
    title?: string
    className?: string
}

export const IconTwitter: React.FC<IconTwitterProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 16"
        className={className}
    >
        <title>{title || 'Share to Twitter'}</title>
        <path d="M16.17 4.83c0 4.97-3.72 10.7-10.5 10.7-2.1 0-4.04-.62-5.67-1.68.3.03.58.05.88.05 1.73 0 3.32-.6 4.6-1.6-1.63-.04-3-1.13-3.46-2.62.22.04.45.06.7.06.33 0 .58-.04.9-.13C1.9 9.3.56 7.78.56 5.95V5.9c0 .27 1.14.44 1.75.46C1.3 5.68.7 4.53.7 3.23c0-.7.2-1.34.5-1.9C3.08 3.6 5.8 5.1 8.88 5.27c-.07-.28-.1-.57-.1-.86 0-2.06 1.66-3.74 3.7-3.74 1.06 0 2.02.45 2.7 1.2.84-.18 1.63-.5 2.34-.93-.26.9-.85 1.63-1.6 2.1.74-.1 1.45-.3 2.1-.6-.5.76-1.1 1.42-1.83 1.95v.48z" />
    </svg>
)
