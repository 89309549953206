import styled from '@emotion/styled'
import { CardText } from '../../../cards/CardText/CardText'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { CardType, CollectionType } from './ContentCard'
import { WebLink, WebLinkProps, createPropFilter } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, colors } from '../../../__styling'

export interface StyledContentCardProps {
    cardType: CardType
    displayRightSeparator?: boolean
}

export const StyledContentCard = styled('div')<StyledContentCardProps>(
    ({ cardType, displayRightSeparator }) => [
        cardType === 'small'
            ? {
                  display: 'grid',
                  gridTemplateColumns: '3fr 2fr',
                  columnGap: calcRem(13),
                  [breakpoint('lg')]: {
                      columnGap: calcRem(15),
                  },
              }
            : {},

        cardType === 'medium'
            ? {
                  display: 'grid',
                  [`${StyledCardImageContainer}`]: {
                      gridRow: 1,
                  },
              }
            : {},

        cardType === 'medium-video-hub'
            ? {
                  display: 'grid',
                  gridTemplateColumns: '3fr 2fr',
                  columnGap: calcRem(18),
                  [`${StyledCardImageContainer}`]: {
                      gridRow: 'unset',
                  },
                  [breakpoint('sm')]: {
                      gridTemplateColumns: 'unset',
                      [`${StyledCardImageContainer}`]: {
                          gridRow: 1,
                      },
                  },
              }
            : {},

        cardType === 'large'
            ? {
                  display: 'grid',
                  [`${StyledCardImageContainer}`]: {
                      gridRow: 1,
                  },

                  [breakpoint('sm')]: {
                      gridTemplateColumns: '3fr 2fr',
                      columnGap: calcRem(18),
                      [`${StyledCardImageContainer}`]: {
                          gridRow: 'unset',
                      },
                  },
                  [breakpoint('md')]: {
                      columnGap: calcRem(23),
                  },
              }
            : {},
        displayRightSeparator && {
            '&::after': {
                position: 'absolute',
                content: `''`,
                width: calcRem(1),
                height: calcRem(40),
                background: colors.sevennews.nero15,
                right: calcRem(-27),
                transform: 'translateY(50%)',
            },
        },
    ],
    {
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: '0.7',
        },
    },
)

export const StyledTextContainer = styled('div')<StyledContentCardProps>(
    ({ cardType }) => [
        cardType === 'small' || cardType === 'large'
            ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
              }
            : {},
        cardType === 'small'
            ? {
                  '& [class*="StyledHeadline"]': {
                      fontWeight: 500,
                      fontSize: calcRem(14),
                      lineHeight: 'normal',

                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 4,
                      overflow: 'hidden',

                      [breakpoint('md')]: {
                          fontSize: calcRem(12),
                      },
                      [breakpoint('lg')]: {
                          fontSize: calcRem(15),
                      },
                  },
              }
            : {},
        cardType === 'medium'
            ? {
                  '& [class*="StyledHeadline"],[class*="StyledTeaser"]': {
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                  },
                  '& [class*="StyledHeadline"]': {
                      fontSize: calcRem(22),
                      lineHeight: calcRem(29),
                      marginBottom: 0,
                      WebkitLineClamp: 3,
                      fontWeight: 500,

                      [breakpoint('sm')]: {
                          fontSize: calcRem(18),
                          lineHeight: 'normal',
                          minHeight: calcRem(66),
                      },
                      [breakpoint('lg')]: {
                          fontSize: calcRem(21),
                          lineHeight: calcRem(27),
                          minHeight: calcRem(81),
                      },
                      [breakpoint('xl')]: {
                          fontSize: calcRem(22),
                          lineHeight: calcRem(29),
                          minHeight: calcRem(87),
                      },
                  },
                  '& [class*="StyledTeaser"]': {
                      fontSize: calcRem(17),
                      lineHeight: calcRem(22),
                      marginTop: calcRem(8),
                      WebkitLineClamp: 2,
                      fontWeight: 400,

                      [breakpoint('sm')]: {
                          fontSize: calcRem(13),
                          lineHeight: calcRem(19),
                          minHeight: calcRem(38),
                      },
                      [breakpoint('lg')]: {
                          fontSize: calcRem(15),
                          lineHeight: calcRem(22),
                      },
                      [breakpoint('xl')]: {
                          fontSize: calcRem(17),
                          minHeight: calcRem(44),
                      },
                  },
              }
            : {},
        cardType === 'medium-video-hub'
            ? {
                  '& [class*="StyledHeadline"]': {
                      fontSize: calcRem(14),
                      lineHeight: 'normal',
                      marginBottom: 0,
                      WebkitLineClamp: 3,
                      fontWeight: 500,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',

                      [breakpoint('sm')]: {
                          fontSize: calcRem(18),
                          lineHeight: 'normal',
                          minHeight: calcRem(66),
                      },
                      [breakpoint('lg')]: {
                          fontSize: calcRem(21),
                          lineHeight: calcRem(27),
                          minHeight: calcRem(81),
                      },
                      [breakpoint('xl')]: {
                          fontSize: calcRem(22),
                          lineHeight: calcRem(29),
                          minHeight: calcRem(87),
                      },
                  },
              }
            : {},
        cardType === 'large'
            ? {
                  '& [class*="StyledHeadline"],[class*="StyledTeaser"]': {
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                  },
                  '& [class*="StyledHeadline"]': {
                      fontWeight: 500,
                      fontSize: calcRem(22),
                      lineHeight: calcRem(29),
                      marginBottom: 0,

                      WebkitLineClamp: 3,

                      [breakpoint('sm')]: {
                          fontWeight: 500,
                      },
                      [breakpoint('xl')]: {
                          fontSize: calcRem(22),
                          lineHeight: calcRem(28),
                      },
                  },
                  '& [class*="StyledTeaser"]': {
                      fontWeight: 400,
                      fontSize: calcRem(17),
                      lineHeight: calcRem(22),
                      marginTop: calcRem(8),

                      WebkitLineClamp: 2,

                      [breakpoint('xl')]: {
                          fontSize: calcRem(17),
                          lineHeight: calcRem(25),
                      },
                  },
              }
            : {},
    ],
)

export const StyledCardImageContainer = styled('div')<StyledContentCardProps>(
    ({ cardType }) => [
        cardType === 'medium'
            ? {
                  '& > div': {
                      marginBottom: 0,
                  },
                  marginBottom: calcRem(16),
                  [breakpoint('sm')]: {
                      marginBottom: calcRem(8),
                  },
                  [breakpoint('lg')]: {
                      marginBottom: calcRem(16),
                  },
              }
            : {},
        cardType === 'medium-video-hub'
            ? {
                  '& > div': {
                      marginBottom: 0,
                  },
                  marginBottom: calcRem(16),
                  [breakpoint('sm')]: {
                      marginBottom: calcRem(8),
                  },
                  [breakpoint('lg')]: {
                      marginBottom: calcRem(16),
                  },
              }
            : {},
        cardType === 'large'
            ? {
                  marginBottom: calcRem(16),
                  [breakpoint('sm')]: {
                      marginBottom: 'unset',
                  },
              }
            : {},
    ],
)

export const StyledText = styled(CardText)(({}) => [{}])
interface StyledCardImageProps {
    isVideoPage?: boolean
}
export const StyledCardImage = styled(CardMedia)<StyledCardImageProps>(
    ({ isVideoPage }) => [
        {
            margin: 0,
            '& [class*="image"]': {
                aspectRatio: '3/2',
                '& img': {
                    width: 'auto',
                    height: '100%',
                    translate: isVideoPage ? 'unset' : '-8%',
                },
            },
        },
    ],
)

export const StyledTimeToRead = styled('p')(() => [
    {
        marginBottom: calcRem(6),
        color: colors.sevennews.nero30,
        fontSize: calcRem(12),
    },
])

export const StyledLiveStatus = styled('p')(() => [
    {
        display: 'flex',
        alignItems: 'center',
        marginBottom: calcRem(6),
        fontSize: calcRem(12),
    },
])

export const StyledDotLogo = styled('div')({
    background: tokens.sevennews.colors.palette.red,
    height: calcRem(5),
    width: calcRem(5),
    transform: 'translateX(-0%) translateY(-0%)',
    marginBottom: 0,
    marginRight: calcRem(4),
    borderRadius: '50%',
})
export const StyledLiveLabel = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
        margin: 0,
    },
])

export const StyledLinkWrapper = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([]),
})<{ gridAreaName?: string; collectionType?: CollectionType }>(
    ({ gridAreaName, collectionType }) => [
        gridAreaName
            ? {
                  gridArea: gridAreaName,
              }
            : {},
        {
            textDecoration: 'none',
            ...(collectionType === 'six-card'
                ? {
                      [breakpoint('md')]: {
                          paddingTop: gridAreaName === 'two' ? calcRem(16) : 0,
                      },
                  }
                : {}),
        },
    ],
)

export const StyledCTAButton = styled('button')({
    fontSize: calcRem(12),
    fontWeight: 600,
    lineHeight: calcRem(15),
    color: tokens.sevennews.colors.palette.red,
    padding: calcRem(8, 22),
    border: `${calcRem(2)} solid ${tokens.sevennews.colors.palette.red}`,
    borderRadius: calcRem(25),
    background: 'transparent',
    marginTop: calcRem(12),
    cursor: 'pointer',
    '&:hover': {
        color: tokens.sevennews.colors.palette.white,
        background: tokens.sevennews.colors.palette.red,
    },
})
export const StyledTimeToReadWrapper = styled('div')(() => [
    {
        display: 'flex',
        gap: calcRem(8),
        alignItems: 'center',
        '& img': {
            marginTop: calcRem(4),
        },
    },
])
export const StyledBreakingLabel = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
        marginBottom: calcRem(6),
    },
])
