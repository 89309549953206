import { TermsFooterProps } from '@news-mono/component-library'
import React from 'react'

export interface LinkContext {
    links: TermsFooterProps
}

export const LinkContext = React.createContext<LinkContext>({
    links: { footerTermsLinks: [] },
})
