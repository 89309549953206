import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { fonts } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { calcRem, filterColor } from '../../__styling/style-functions'
import { visuallyhidden } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

interface StyledActiveProps {
    active?: boolean
    primary?: boolean
    loading?: boolean
}

interface StyledLabelledToggleProps {
    loading?: true | undefined
    disabled?: boolean
}

const StyledToggleSwitchClassName = 'StyledToggleSwitch'

export const StyledToggleCheckbox = styled('input')(visuallyhidden)
export const StyledToggleLabel = styled('label')(visuallyhidden)

export const StyledToggle = styled('div')()

const getThemedBackground = (
    loading: boolean,
    primary: boolean,
    primaryColor: string,
    secondaryColor: string,
) => {
    return primary
        ? loading
            ? filterColor(primaryColor, -90)
            : primaryColor
        : loading
        ? filterColor(secondaryColor, -90)
        : secondaryColor
}

export const StyledLabelledToggle = styled('button')<StyledLabelledToggleProps>(
    ({ theme, disabled, loading }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gridTemplateAreas: '. .',
        background: colors.thewest.greyErmine,
        border: `1px solid ${colors.thewest.greyDolphin}`,
        borderRadius: 2,
        cursor: disabled ? 'no-drop' : loading ? 'wait' : 'pointer',
        padding: 5,
        fontFamily: fonts.designSystem.sansSerif,
    }),
)

export const StyledToggleSwitch = withClass(StyledToggleSwitchClassName)(
    styled('span')<StyledActiveProps>(({ theme, active, primary, loading }) => {
        const shouldHaveBackground =
            (active && primary) || (!active && !primary)

        const activeBackground = themedValue(theme, {
            thewest: getThemedBackground(
                !!loading,
                !!primary,
                colors.thewest.westblue,
                colors.thewest.greyHippo,
            ),
            perthnow: getThemedBackground(
                !!loading,
                !!primary,
                colors.perthnow.pinkThulite,
                colors.thewest.greyHippo,
            ),
            sevennews: getThemedBackground(
                !!loading,
                !!primary,
                colors.sevennews.red,
                colors.thewest.greyHippo,
            ),
            fallback: 'transparent',
        })

        return {
            display: 'inline-block',
            backgroundColor: shouldHaveBackground
                ? activeBackground
                : 'transparent',
            color: shouldHaveBackground
                ? colors.white
                : colors.thewest.greyHippo,
            padding: calcRem(3, 6),
            textTransform: 'uppercase',
            borderRadius: 2,
            fontSize: calcRem(12),
            transition: 'all, ease-in-out, .2s',
        }
    }),
)
