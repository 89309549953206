import { keyframes } from '@emotion/css'
import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    themedValue,
} from '../../__styling'

import { Timestamp } from '../../typography/Timestamp/Timestamp'

const gradialRed = tokens['sevennews'].colors.palette.gradialRed
const gradialGrey = tokens['sevennews'].colors.palette.gradialGrey
const greyLight = tokens['sevennews'].colors.palette.greyLight
interface Keyframes {
    [key: string]: CSSObject
}

const shadingAnimation = (
    initialColor: string,
    finalColor: string,
): Keyframes => {
    return {
        '10%': {
            background: initialColor,
        },
        '100%': {
            background: finalColor,
        },
    }
}

export const StyledLiveEventPost = styled('div')<{
    /** Post has no bottom margin or border. Generally due to being placed in truncated container */
    noBotMargin?: boolean
    isActivePost?: boolean
}>(({ theme, noBotMargin, isActivePost }) => [
    {
        padding: theme.margins.md,
        marginBottom: noBotMargin ? undefined : theme.margins.sm,
        background: greyLight,

        [breakpoint('sm')]: {
            margin: theme.margins.md,
            marginBottom: noBotMargin ? 0 : theme.margins.md,
        },
        [breakpoint('md')]: {
            padding: themedValue(theme, {
                fallback: theme.margins.md,
            }),
            margin: calcRem(0, 0, theme.margins.md, 0),
            marginBottom: noBotMargin
                ? 0
                : calcRem(theme.margins.md, theme.margins.sm),
        },
    },
    isActivePost && {
        animation: `${keyframes(
            shadingAnimation(
                gradialRed,

                theme.colors.palette.white,
            ),
        )} 3s ease`,
    },
])

export const StyledLiveEventTitle = styled('h2')(({ theme }) => ({
    margin: calcRem(theme.margins.sm, 0),
    fontSize: calcRem(21),
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    color: theme.colors.palette.black,
    lineHeight: calcRem(31),
}))

export const StyledLiveEventArticleBlockContent = styled('div')(({ theme }) => [
    {
        margin: calcRem(theme.margins.md, 0),
        '& > div > p': {
            fontFamily: theme.fonts.sansSerif,
            fontSize: calcRem(16),
            fontWeight: 400,
            lineHeight: calcRem(27),
        },

        '& > div > blockquote > p': {
            marginLeft: 0,
            fontSize: calcRem(18),
            fontWeight: 700,
            fontFamily: theme.fonts.sansSerif,

            color: theme.colors.brand,
            lineHeight: calcRem(25),
        },
        '& > div > blockquote > div': {
            width: 4,
        },
    },
    theme.kind === 'sevennews' && {
        '& > div > blockquote ': {
            transform: 'translateX(0)',
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
            maxWidth: '100%',
            '& > p': {
                maxWidth: '100%',
            },
        },
    },
])

export const StyledBylineWrap = styled('div')(({}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
}))
interface ShareWrapperProps {
    isOpened: boolean
}

export const StyledShareWrapper = styled('div')<ShareWrapperProps>(
    ({ isOpened }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(8),
        visibility: isOpened ? 'visible' : 'hidden',
        width: isOpened ? calcRem(110) : 0,
        marginTop: calcRem(3),
        [breakpoint('xxs')]: { gap: calcRem(16) },
        [breakpoint('md')]: {
            marginTop: 0,
        },
        '& [class*="StyledButton"]': {
            width: calcRem(26),
            height: calcRem(26),
        },
    }),
)

export const StyledShareIconWrapper = styled('div')<ShareWrapperProps>(
    ({ isOpened }) => ({
        width: calcRem(16),
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
            '& svg path': {
                fill: tokens.sevennews.colors.palette.red,
            },
        },
        '& svg path': {
            fill: isOpened ? tokens.sevennews.colors.palette.red : undefined,
        },
    }),
)

export const StyledLiveEventTimestamp = styled(Timestamp)(() => ({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(12),
    fontWeight: 600,
    color: gradialGrey,
    minWidth: calcRem(80),
    [breakpoint('xxs')]: {
        minWidth: calcRem(204),
    },
}))

export const StyledFooterCardContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))
export const StyledFooterCardMobileWrap = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(8),
}))
export const StyledShareMobileWrapper = styled('div')<ShareWrapperProps>(
    ({ isOpened }) => ({
        width: calcRem(18),
        height: '100%',
        cursor: 'pointer',

        '&:hover': {
            '& svg path': {
                fill: colors.sevennews.red,
            },
        },
        '& svg path': {
            fill: isOpened ? colors.sevennews.red : undefined,
        },
    }),
)
