import { useTheme } from '@emotion/react'
import {
    CardBreakpointRatios,
    CardEvents,
    ConsentLevel,
    FixedRatio,
    ImageRatio,
    ImageSource,
    isPublicationCardItemWithVideo,
    Product,
    PublicationCardItem,
    PublicationCardItemWithVideo,
    useConsentGiven,
    useFeature,
    VideoDetails,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useRef, useState } from 'react'
import inViewport, { ViewportProps } from '../../__helpers/in-viewport'
import { BreakpointState, ThemeConfig } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { themedValue } from '../../__styling/themed-value'
import { PlayButton } from '../../buttons/PlayButton/PlayButton'
import {
    StyledArticleVideo,
    StyledCardPlayButton,
    StyledGalleryButton,
    StyledGalleryIcon,
    StyledMedia,
    StyledMediaContainer,
    StyledMediaContent,
    StyledProviderLogo,
} from '../../cards/CardMedia/StyledMedia.styled'
import { ResponsivePicture } from '../../content/Picture/Picture'
import { PicturePlaceholder } from '../../content/Picture/PicturePlaceholder'
import { ResponsivePictureSizes } from '../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'
import {
    GetVideoQueue,
    VideoOptions,
} from '../../content/Video/PlayerInterface'
import { useGdprMode } from '../../content/Video/utils/gdpr-mode'
import { ArticleVideoProps } from '../../publication/ArticleVideo/ArticleVideo'
import { InlineVideoPlayerMode } from '../Portrait/Portrait'
import {
    doesParticularRatioExist,
    fixedRatiosToCardBreakpoints,
    getCompatibleBreakpointRatios,
} from './image-helpers'

import { FetchPriority } from '../../content/Picture/Image.web.types'
import { PlayButtonV2 } from '../../SevenNewsV2/PlayButtonV2/PlayButtonV2'
import { PNVideoPlayButton } from '../../nextgen-news/perthnow/buttons/PNVideoPlayButton'
import { PNCardOverlayButtonScale } from '../../nextgen-news'

export type MediaState =
    | 'hidden'
    | 'edgeToEdge'
    | 'fullHeight'
    | 'inlineVideo'
    | 'default'
export type MediaMode =
    | MediaState
    | Record<'initial' | 'xs' | 'sm' | 'md', MediaState>

export interface CardMediaProps {
    hasBackground?: boolean
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios

    imageWidths: ResponsivePictureSizes

    imageLayout?: ResponsivePictureLayout
    mediaMode?: MediaMode
    hideMediaIcon?: boolean
    providerLogo?: string
    /**
     * flag to modify card styles if a video will play inline
     */
    willPlayVideoInline?: InlineVideoPlayerMode
    getVideoQueue?: GetVideoQueue
    adUnitPath?: string

    isLarge?: boolean
    item: MaybeLoaded<PublicationCardItem>

    disableImageLazyLoad?: boolean
    imageFetchPriority?: FetchPriority

    // other
    onEvent: (event: CardEvents) => void
    className?: string
    playButtonText?: string
    isBauhaus?: boolean
    borderShadingOnPN?: boolean // This controls the border that appears on hover inside PN (on cards)
    CSSCrop?: BreakpointState<ThemeConfig, ImageRatio>
    displayNoScriptImage?: boolean
    overlayButtonScale?: PNCardOverlayButtonScale
}

export const CardMedia: React.FC<CardMediaProps> = ({
    hasBackground = false,
    isLarge = false,
    hideMediaIcon = false,
    disableImageLazyLoad = false,
    mediaMode,
    imageLayout = ResponsivePictureLayout.ObjectFitContain,
    fixedRatio = '4:3',
    adUnitPath = '',
    className,
    item,
    getVideoQueue,
    onEvent,
    imageWidths,
    playButtonText,
    willPlayVideoInline = false,
    borderShadingOnPN,
    CSSCrop,
    imageFetchPriority,
    displayNoScriptImage,
    overlayButtonScale,
}) => {
    const theme = useTheme()
    // Default all image as Edge to Edge for the west
    const mediaModeWithDefault =
        mediaMode ||
        themedValue(theme, {
            thewest: 'edgeToEdge',
            sevennews: 'default',
            perthnow: 'default',
            thenightly: 'default',
            fallback: 'default',
        })

    const gdprMode = useGdprMode()
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Advertising | ConsentLevel.Analytics,
    })
    const isNgnEnabled = useFeature('perthnow-ngn-redesign-master', false)

    const canLoadVideoPlayer = gdprMode || consentGiven

    const publicationKind = item.loaded
        ? item.result.publicationKind
        : 'unknown'

    const backgroundColor = themedValue(theme, {
        perthnow: hasBackground ? colors.perthnow.greyBasalt : undefined,
        fallback: undefined,
    })

    if (!item.loaded) {
        return (
            <StyledMedia
                className={className}
                mediaMode={mediaMode}
                borderShadingOnPN={borderShadingOnPN}
            >
                <PicturePlaceholder
                    image={undefined}
                    fixedRatio={fixedRatiosToCardBreakpoints(fixedRatio, '4:3')}
                    loadingBackgroundColor={backgroundColor}
                />
            </StyledMedia>
        )
    }

    const cardItem = item.result

    const isVideoPromoCard = item.result.link.match(/-bc-(\d{13})/)

    const breakpointRatio: CardBreakpointRatios = Array.isArray(fixedRatio)
        ? { default: fixedRatio }
        : typeof fixedRatio === 'string'
        ? { default: fixedRatio }
        : fixedRatio

    if (theme.kind === 'perthnow' && publicationKind === 'gallery') {
        return (
            <CardMediaImage
                mediaMode={mediaModeWithDefault}
                imageLayout={imageLayout}
                fixedRatios={breakpointRatio}
                image={cardItem.image || theme.fallbackImages}
                className={className}
                disableImageLazyLoad={disableImageLazyLoad}
                imageFetchPriority={imageFetchPriority}
                imageWidths={imageWidths}
                displayNoScriptImage={displayNoScriptImage}
                iconOverlay={
                    <StyledGalleryButton>
                        <StyledGalleryIcon />
                    </StyledGalleryButton>
                }
            />
        )
    }

    if (
        isInlineVideoCompatible({ willPlayVideoInline, fixedRatio }) &&
        isPublicationCardItemWithVideo(cardItem) &&
        canLoadVideoPlayer
    ) {
        return (
            <CardMediaInlineVideo
                cardItem={cardItem}
                adUnitPath={adUnitPath}
                disableImageLazyLoad={disableImageLazyLoad}
                imageFetchPriority={imageFetchPriority}
                fixedRatios={breakpointRatio}
                getVideoQueue={
                    getVideoQueue ||
                    (() =>
                        Promise.resolve({
                            items: [],
                            type: 'not-playlist-video',
                        }))
                }
                hideMediaIcon={hideMediaIcon}
                imageLayout={imageLayout}
                isLarge={isLarge}
                imageWidths={imageWidths}
                mediaMode={mediaModeWithDefault}
                onEvent={onEvent}
                playButtonText={playButtonText}
                willPlayVideoInline={willPlayVideoInline}
                overlayButtonScale={overlayButtonScale}
            />
        )
    }

    if (
        (isPublicationCardItemWithVideo(cardItem) || isVideoPromoCard) &&
        canLoadVideoPlayer
    ) {
        return (
            <CardMediaImage
                image={cardItem.image || theme.fallbackImages}
                fixedRatios={breakpointRatio}
                mediaMode={mediaModeWithDefault}
                imageLayout={imageLayout}
                className={className}
                disableImageLazyLoad={disableImageLazyLoad}
                displayNoScriptImage={displayNoScriptImage}
                imageFetchPriority={imageFetchPriority}
                imageWidths={imageWidths}
                iconOverlay={
                    <CardMediaPlayButton
                        hideMediaIcon={hideMediaIcon}
                        isLarge={isLarge}
                        willPlayVideoInline={willPlayVideoInline}
                        isNgn={isNgnEnabled}
                        overlayButtonScale={overlayButtonScale}
                    />
                }
                providerIcon={
                    !!cardItem.providerLogo && (
                        <StyledProviderLogo
                            src={cardItem.providerLogo}
                            alt=""
                        />
                    )
                }
            />
        )
    }

    return (
        <CardMediaImage
            image={cardItem.image || theme.fallbackImages}
            imageLayout={imageLayout}
            mediaMode={mediaModeWithDefault}
            fixedRatios={breakpointRatio}
            className={className}
            disableImageLazyLoad={disableImageLazyLoad}
            displayNoScriptImage={displayNoScriptImage}
            imageFetchPriority={imageFetchPriority}
            imageWidths={imageWidths}
            borderShadingOnPN={borderShadingOnPN}
            CSSCrop={CSSCrop}
        />
    )
}
CardMedia.displayName = 'CardMedia'

function isInlineVideoCompatible({
    willPlayVideoInline,
    fixedRatio,
}: {
    willPlayVideoInline: InlineVideoPlayerMode
    fixedRatio: FixedRatio | FixedRatio[] | CardBreakpointRatios
}) {
    if (!willPlayVideoInline) {
        return false
    }
    if (!fixedRatio || !doesParticularRatioExist(fixedRatio, '16:9')) {
        return false
    }

    return true
}
interface CardMediaImageProps {
    image: ImageSource
    fixedRatios: FixedRatio[] | CardBreakpointRatios
    className: string | undefined
    iconOverlay?: false | JSX.Element
    providerIcon?: false | JSX.Element
    disableImageLazyLoad: boolean
    imageFetchPriority?: FetchPriority
    mediaMode: MediaMode
    imageLayout: ResponsivePictureLayout
    imageWidths: ResponsivePictureSizes
    borderShadingOnPN?: boolean
    CSSCrop?: BreakpointState<ThemeConfig, ImageRatio>
    displayNoScriptImage?: boolean
    isVideoRendered?: boolean
}

const CardMediaImage = React.forwardRef<HTMLDivElement, CardMediaImageProps>(
    (
        {
            image,
            fixedRatios,
            className,
            iconOverlay = false,
            providerIcon = false,
            disableImageLazyLoad,
            imageFetchPriority,
            mediaMode,
            imageLayout,
            imageWidths,
            borderShadingOnPN,
            CSSCrop,
            displayNoScriptImage,
            isVideoRendered,
        },
        ref,
    ) => {
        const theme = useTheme()
        const cardMediaRef = useRef<HTMLDivElement | null>(null)
        const breakpointRatio: CardBreakpointRatios = Array.isArray(fixedRatios)
            ? { default: fixedRatios }
            : fixedRatios

        const imageWithFallbacks: ImageSource = {
            ...image,
            crops: {
                original: image.crops.original,
                '161:229':
                    image.crops['161:229'] ||
                    theme.fallbackImages.crops['161:229'],
                '16:9':
                    image.crops['16:9'] || theme.fallbackImages.crops['16:9'],
                '316:421':
                    image.crops['316:421'] ||
                    theme.fallbackImages.crops['316:421'],
                '4:3': image.crops['4:3'] || theme.fallbackImages.crops['4:3'],
            },
        }

        const fixedRatio = getCompatibleBreakpointRatios(
            image.crops,
            breakpointRatio,
        )

        return (
            <StyledMedia
                ref={ref}
                className={className}
                mediaMode={mediaMode}
                borderShadingOnPN={borderShadingOnPN}
            >
                <StyledMediaContent
                    ref={cardMediaRef}
                    isVideoRendered={isVideoRendered}
                >
                    {iconOverlay}
                    {providerIcon}
                    <ResponsivePicture
                        image={imageWithFallbacks}
                        fixedRatio={fixedRatio}
                        imageWidths={imageWidths}
                        // Need to use any here because Picture props are a tagged union based
                        // on this property
                        layout={imageLayout}
                        disableLazyLoad={disableImageLazyLoad}
                        displayNoScriptImage={displayNoScriptImage}
                        imageFetchPriority={imageFetchPriority}
                        imageDimensions={{
                            height: cardMediaRef.current
                                ? cardMediaRef.current.clientHeight.toString()
                                : image.crops['16:9']?.height.toString() || '',

                            width: cardMediaRef.current
                                ? cardMediaRef.current.clientWidth.toString()
                                : image.crops['16:9']?.height.toString() || '',
                        }}
                        CSSCrop={CSSCrop}
                    />
                </StyledMediaContent>
            </StyledMedia>
        )
    },
)

interface CardMediaInlineVideoWithLazyLoadVideoPlayerProps {
    cardItem: PublicationCardItemWithVideo
    adUnitPath: string
    mediaMode: MediaMode
    fixedRatios: FixedRatio[] | CardBreakpointRatios
    imageWidths: ResponsivePictureSizes
    imageFetchPriority?: FetchPriority
    getVideoQueue: GetVideoQueue
    onEvent: (event: CardEvents) => void
    className?: string
    imageLayout: ResponsivePictureLayout
    isLarge: boolean
    hideMediaIcon: boolean
    disableImageLazyLoad: boolean
    playButtonText?: string
    willPlayVideoInline?: InlineVideoPlayerMode
    displayNoScriptImage?: boolean
    overlayButtonScale?: PNCardOverlayButtonScale
}

const CardMediaInlineVideoWithLazyLoadVideoPlayer: React.FC<
    CardMediaInlineVideoWithLazyLoadVideoPlayerProps & ViewportProps
> = ({
    // Props
    cardItem,
    adUnitPath,
    mediaMode,
    fixedRatios,
    imageWidths,
    getVideoQueue,
    onEvent,
    className,
    imageLayout,
    isLarge,
    hideMediaIcon,
    disableImageLazyLoad,
    imageFetchPriority,
    playButtonText,
    overlayButtonScale,
    // Viewport props
    inViewport,
    innerRef,
    displayNoScriptImage,
}) => {
    // this is an inline video
    const theme = useTheme()
    const isTestEnviroment =
        process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'vr_test'
    const isMagniteEnabled = useFeature('magnite-header-tag-wrapper')

    const enablePrebid = useFeature('prebid-video')
    const gdprMode = useGdprMode()
    const [isVideoRendered, setVideoRendered] = useState(isTestEnviroment)

    let inlineVideoComponent: JSX.Element | false = false

    if (isTestEnviroment || inViewport > 10 || isVideoRendered) {
        const { id, heading, accountId, source, videoType } = cardItem.video

        const videoDetails: VideoDetails = {
            id,
            accountId,
            heading,
            posterImage: cardItem.image,
            source,
            videoType,
        }

        const videoOptions: VideoOptions = {
            adRequestMode: 'onload',
            adUnitPath,
            preloadOption: 'none',
            canAutoplayVideos: false,
            compactMode: false, // should be false as its not a sticky video
            isMagniteEnabled,
            enableSnowplow: false,
            enablePrebid,
            playButtonText,
            smallPlayButton: !isLarge,
            gdprMode,
        }

        const videoProps: ArticleVideoProps = {
            showTitles: true,
            videoDetails,
            autoplayOptions: false,
            autoplayNextOptions: {
                delayDuration: 5,
            },
            videoOptions,
            isHeroVideo: true,
            pauseOverlay: true,
            getVideoQueue: getVideoQueue!,
            isVideoStickyEnabled: false,
            onEvent,
            playButtonText,
            mediaMode,
            smallPlayButton: !isLarge,
            setVideoRendered: () => {
                // We don't want to reset it multiple times, or hide it down the track
                if (isVideoRendered) return
                setVideoRendered(true)
            },
        }

        inlineVideoComponent = (
            <StyledMediaContainer
                mediaMode={mediaMode || 'default'}
                isVideoRendered={isVideoRendered}
            >
                <StyledArticleVideo {...videoProps} />
            </StyledMediaContainer>
        )
    }

    return (
        <>
            {/* This is the media image to display for lazy loading and while the video is rendering */}
            {!isVideoRendered && (
                <CardMediaImage
                    ref={innerRef}
                    image={cardItem.image || theme.fallbackImages}
                    fixedRatios={fixedRatios}
                    mediaMode={mediaMode}
                    imageLayout={imageLayout}
                    className={className}
                    disableImageLazyLoad={disableImageLazyLoad}
                    displayNoScriptImage={displayNoScriptImage}
                    imageFetchPriority={imageFetchPriority}
                    imageWidths={imageWidths}
                    iconOverlay={
                        <CardMediaPlayButton
                            hideMediaIcon={hideMediaIcon}
                            willPlayVideoInline={true}
                            isLarge={isLarge}
                            overlayButtonScale={overlayButtonScale}
                        />
                    }
                    providerIcon={
                        !!cardItem.providerLogo && (
                            <StyledProviderLogo
                                src={cardItem.providerLogo}
                                alt=""
                            />
                        )
                    }
                    isVideoRendered={isVideoRendered}
                />
            )}
            {/* Once the video component is loaded, it will be displayed here */}
            {inlineVideoComponent}
        </>
    )
}

const CardMediaInlineVideo =
    inViewport<CardMediaInlineVideoWithLazyLoadVideoPlayerProps>(
        CardMediaInlineVideoWithLazyLoadVideoPlayer,
    )

const CardMediaPlayButton: React.FC<{
    hideMediaIcon: boolean
    willPlayVideoInline: InlineVideoPlayerMode
    isLarge: boolean
    forceMediaIconVisible?: boolean
    isBauhaus?: boolean
    isNgn?: boolean
    overlayButtonScale?: PNCardOverlayButtonScale
}> = ({
    hideMediaIcon,
    willPlayVideoInline,
    isLarge,
    isBauhaus,
    isNgn,
    overlayButtonScale,
}) => {
    const theme = useTheme()

    const showPlayButton =
        !hideMediaIcon &&
        (theme.kind !== Product.TheWest || willPlayVideoInline === true)

    if (!showPlayButton) {
        return null
    }

    if (isBauhaus) {
        return (
            <PlayButton
                isIcon
                isSmall={isLarge !== true}
                isCentered
                isBauhaus
            />
        )
    }

    if (theme.kind === 'sevennews') {
        return <PlayButtonV2 isSmall={isLarge !== true} isCentered />
    }

    if (theme.kind === 'thenightly') {
        return (
            <PlayButton isIcon isSmall={isLarge !== true} isCentered={false} />
        )
    }

    if (theme.kind === 'perthnow' && isNgn) {
        return <PNVideoPlayButton overlayButtonScale={overlayButtonScale} />
    }

    return <StyledCardPlayButton />
}
