import styled from '@emotion/styled'
import { StyledMessageBanner } from '../../banners/MessageBanner/MessageBanner.styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledFeatureArticleBreachNotification = styled('div')({
    width: '100%',

    '@media screen and (min-width: 1180px)': {
        [`${StyledMessageBanner}`]: {
            paddingTop: calcRem(120),
        },
    },
})
