import React from 'react'

export interface IconShareTNProps {
    className?: string
    strokeColor?: string
}

export const IconShareTN: React.FC<IconShareTNProps> = ({
    className,
    strokeColor = '#161616',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <path
            d="M8.59 13.51L15.42 17.49M15.41 6.51L8.59 10.49M21 5C21 6.65685 19.6569 8 18 8C16.3431 8 15 6.65685 15 5C15 3.34315 16.3431 2 18 2C19.6569 2 21 3.34315 21 5ZM9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12ZM21 19C21 20.6569 19.6569 22 18 22C16.3431 22 15 20.6569 15 19C15 17.3431 16.3431 16 18 16C19.6569 16 21 17.3431 21 19Z"
            stroke={strokeColor}
            strokeWidth={2}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
        />
    </svg>
)
