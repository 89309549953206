import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import { TheWestRegionalSections } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { ImageLink } from '../../../buttons/ImageLink'
import {
    InlineContentTypes,
    InlineImageLink,
} from '../SharedPublication.routing'

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
    section?: string
}

export function isInlineReadersSurveyBanner(
    content: InlineContentTypes,
): content is InlineImageLink {
    return content.kind === 'inline-readers-survey-banner'
}

export function isRegional(section: any) {
    return section in TheWestRegionalSections
}

export function getInlinedReadersSurveyBanner({
    publication,
    inlinePublicationContent,
    onEvent,
    section,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    if (!isRegional(section)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineReadersSurveyBanner)
        .map((content: InlineImageLink) => {
            return {
                position: content.insertAfter,
                element: (
                    <ImageLink
                        width={content.props.width}
                        height={content.props.height}
                        title={content.props.title}
                        altText={content.props.altText}
                        src={content.props.src}
                        link={content.props.link}
                        visibilityMode={content.props.visiblility}
                        verticalSpacing="md"
                        feature={content.props.feature}
                    />
                ),
            }
        })
}
