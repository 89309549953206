import { tokens } from '@news-mono/design-tokens'
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    Plugin,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
    TooltipPositionerFunction,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useProduct } from '../../__product/useProduct'
import { DataPointData } from './MatchTimelineWidget'
import { useViewport } from '../../__helpers/use-viewport'

function getLineValue(scale: any, index: any, offsetGridLines: any) {
    let lineValue = scale.getPixelForTick(index)

    if (offsetGridLines) {
        if (index === 0) {
            lineValue -= (scale.getPixelForTick(1) - lineValue) / 2
        } else {
            lineValue -= (lineValue - scale.getPixelForTick(index - 1)) / 2
        }
    }
    return lineValue
}

function hasData(data: any) {
    return data && data.datasets && data.datasets.length > 0
}

function findScale(chart: any, options: any) {
    const scales = Object.keys(chart.scales).map((d) => chart.scales[d])
    if (options.axis === 'category') {
        return scales.find(
            (d) => d.type === 'hierarchical' || d.type === 'category',
        )
    }
    return scales.find((d) => d.id.startsWith(options.axis))
}

/**
 * Dynamically calculate top/bottom chart boundary for Y axis absolute values that exceed 60, default boundary is min/max 70
 * @param dataPoints
 * @returns
 */
const getYAxisMaxValue = (dataPoints: DataPointData[]) => {
    const largeYValues = dataPoints
        .map((dataPoint) => {
            return {
                ...dataPoint,
                y: Math.abs(dataPoint.y),
            }
        })
        .filter((dataPoint) => dataPoint.y > 60)
        .sort((a, b) => a.y - b.y)
    if (largeYValues.length > 0) {
        // round largest number to the next greatest multiple of 10 and add 20
        return Math.ceil((largeYValues[0].y + 1) / 10) * 10 + 20
    } else {
        return 70
    }
}

// custom plugin for background color on quarter
const defaultLineChartBackgroundPluginOptions = {
    color: tokens.sevennews.colors.palette.matchCentre.lighterRed,
    axis: 'xAxis',
    mode: 'odd',
}
const lineChartBackgroundPlugin: Plugin<'line'> = {
    id: 'lineChartBackgroundPlugin',

    beforeDraw(chart: any, _easingValue: any, options: any) {
        options = Object.assign(
            {},
            defaultLineChartBackgroundPluginOptions,
            options,
        )

        const scale = findScale(chart, options)
        if (!hasData(chart.config.data) || !scale) {
            return
        }
        const ticks = scale.getTicks()
        if (!ticks || ticks.length === 0) {
            return
        }

        const chartArea = chart.chartArea

        const soptions = scale.options
        const grid = soptions.grid

        // push the current canvas state onto the stack
        const ctx = chart.ctx
        ctx.save()

        // set background color
        ctx.fillStyle = options.color

        const tickPositions = ticks.map((_: any, index: any) =>
            getLineValue(scale, index, grid.offset && ticks.length > 1),
        )
        const shift = options.mode === 'odd' ? 0 : 1
        if (tickPositions.length % 2 === 1 - shift) {
            // add the right border as artifical one
            tickPositions.push(chartArea.right)
        }

        const chartHeight = chartArea.bottom - chartArea.top
        for (let i = shift; i < tickPositions.length; i += 2) {
            const x = tickPositions[i]
            const x2 = tickPositions[i + 1]
            ctx.fillRect(x, chartArea.top, x2 - x, chartHeight)
        }

        ctx.restore()
    },
}

// custom plugin for vertical line on hover
const verticalLinePlugin: Plugin<'line'> = {
    id: 'verticalLinePlugin',
    afterInit: (chart: any) => {
        chart.verticalLinePlugin = {
            x: 0,
            y: 0,
        }
    },
    afterEvent: (chart: any, evt: any) => {
        const {
            chartArea: { top, bottom, left, right },
        } = chart
        const {
            event: { x, y },
        } = evt
        if (x < left || x > right || y < top || y > bottom) {
            chart.verticalLinePlugin = {
                x,
                y,
                draw: false,
            }
            chart.draw()
            return
        }

        chart.verticalLinePlugin = {
            x,
            y,
            draw: true,
        }

        chart.draw()
    },
    beforeDatasetsDraw: (chart: any, _, opts) => {
        const {
            ctx,
            chartArea: { top, bottom, left, right },
        } = chart
        const { x, y, draw } = chart.verticalLinePlugin

        const activeElements = chart.getActiveElements()
        if (!draw || activeElements.length === 0) {
            return
        }

        ctx.lineWidth = opts.width || 0
        ctx.setLineDash([])
        ctx.strokeStyle = opts.color || 'black'

        ctx.save()
        ctx.beginPath()
        ctx.moveTo(activeElements[0].element.x, bottom)
        ctx.lineTo(activeElements[0].element.x, top)
        ctx.stroke()
        ctx.restore()
    },
}
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    verticalLinePlugin,
    lineChartBackgroundPlugin,
)

// custom positioner for aligning tooltip/popup at top of chart
Tooltip.positioners.customTop = function (chartElements, coordinates) {
    return {
        x: coordinates.x,
        y: 10,
    }
}

export type VerticalLinePluginOptions = {
    dash: any
    color: any
    width: any
}

export type LineChartBackgroundPluginOptions = {
    color: string
    axis: string
    mode: string
}

declare module 'chart.js' {
    interface PluginOptionsByType<TType extends ChartType> {
        verticalLinePlugin?: VerticalLinePluginOptions
        lineChartBackgroundPlugin?: LineChartBackgroundPluginOptions
    }
    interface TooltipPositionerMap {
        customTop: TooltipPositionerFunction<ChartType>
    }
}

interface WormChartProps {
    handleUpdateCurrentHoveredPoint: (
        dataPoint: DataPointData | undefined,
    ) => void
    currentHoveredPointData: DataPointData | undefined
    dataPoints: DataPointData[]
}

export const WormChart = ({
    dataPoints,
    currentHoveredPointData,
    handleUpdateCurrentHoveredPoint,
}: WormChartProps) => {
    const { isMobile } = useViewport()
    const product = useProduct()

    const data: ChartData<'line'> = {
        datasets: [
            {
                data: dataPoints,
                borderColor:
                    product === 'sevennews'
                        ? tokens.sevennews.colors.palette.red
                        : product === 'perthnow'
                        ? tokens.perthnow.colors.palette.pinkThulite
                        : tokens.thewest.colors.palette.westblue,
                fill: false,
                stepped: true,
                pointRadius: 0,
                pointHoverRadius: 6,
                pointBackgroundColor: tokens.sevennews.colors.palette.white,
                pointHoverBackgroundColor:
                    tokens.sevennews.colors.palette.white,
                pointHoverBorderWidth: 3,
                pointStyle: 'circle',
                animation: false,
            },
        ],
    }

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: -10,
                top: -10,
                bottom: -10,
            },
        },
        interaction: {
            intersect: false,
            axis: 'x',
        },
        plugins: {
            lineChartBackgroundPlugin: {
                color:
                    product === 'sevennews'
                        ? tokens.sevennews.colors.palette.matchCentre.lighterRed
                        : product === 'perthnow'
                        ? tokens.perthnow.colors.palette.AFL.pinkThulite10
                        : tokens.thewest.colors.palette.AFL.westblue10,
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            verticalLinePlugin: {
                color:
                    product === 'sevennews'
                        ? tokens.sevennews.colors.palette.red
                        : product === 'perthnow'
                        ? tokens.perthnow.colors.palette.pinkThulite
                        : tokens.thewest.colors.palette.westblue,
                width: 3,
            },
            tooltip: {
                enabled: false,
                position: 'customTop',
                external: (context) => {
                    const { tooltip } = context

                    // Hide if no tooltip
                    if (
                        (tooltip.opacity === 0 ||
                            tooltip.getActiveElements().length === 0) &&
                        currentHoveredPointData
                    ) {
                        handleUpdateCurrentHoveredPoint(undefined)
                        return
                    }

                    const currentlyActiveElements = tooltip.getActiveElements()
                    if (currentlyActiveElements.length > 0) {
                        const dataFromCurrentElement = tooltip.dataPoints[0]
                        const dataSetElement = dataFromCurrentElement.dataset
                            .data[
                            dataFromCurrentElement.dataIndex
                        ] as unknown as DataPointData
                        dataSetElement['x'] =
                            currentlyActiveElements[0].element.x
                        if (
                            JSON.stringify(dataSetElement) !==
                            JSON.stringify(currentHoveredPointData)
                        ) {
                            handleUpdateCurrentHoveredPoint(dataSetElement)
                        }
                    }
                },
            },
        },
        scales: {
            xAxis: {
                type: 'linear',
                grid: {
                    display: false,
                    drawBorder: false,
                },
                min: 0,
                max: 4000,
                ticks: {
                    stepSize: 1000,
                    display: false,
                },
            },
            yAxis: {
                type: 'linear',
                min: -getYAxisMaxValue(dataPoints),
                max: getYAxisMaxValue(dataPoints),
                beginAtZero: true,
                ticks: {
                    display: isMobile ? false : true,
                    // moves tick labels to right side of axis ie. inside chart
                    mirror: true,
                    stepSize: 10,
                    callback: (value) => {
                        if (value != 0) {
                            return Math.abs(parseInt(value as string))
                        } else {
                            return 0
                        }
                    },
                    backdropColor:
                        product === 'sevennews'
                            ? tokens.sevennews.colors.palette.matchCentre
                                  .lighterRed
                            : product === 'perthnow'
                            ? tokens.perthnow.colors.palette.AFL.pinkThulite10
                            : tokens.thewest.colors.palette.AFL.westblue10,
                    showLabelBackdrop: true,
                },
                afterTickToLabelConversion: (scaleInstance: any) => {
                    // remove first and last tick so their labels do not display
                    scaleInstance.ticks.shift()
                    scaleInstance.ticks.pop()
                },
                grid: {
                    tickWidth: 0,
                    drawOnChartArea: true,
                    drawBorder: false,
                    borderDash: (ctx) =>
                        // make Y-axis 0 level solid, all other Y-axis levels as dashed
                        ctx.index === getYAxisMaxValue(dataPoints) / 10 - 1
                            ? []
                            : [6, 5],
                    color:
                        tokens.sevennews.colors.palette.bauhausBlack +
                        (isMobile ? '0A' : '50'),
                },
            },
        },
        events: [
            'mousemove',
            'mouseout',
            'click',
            'touchstart',
            'touchmove',
            'touchend',
        ],
    }

    return <Line options={options} data={data} />
}
