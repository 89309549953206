import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../../../feature-toggling/Toggle/Toggle'
import { RegistrationWallProps } from './RegistrationWall'

const LazyRegistrationWallComponent = loadable(
    () => import('./RegistrationWall'),
)

export const LazyRegistrationWall: React.FC<RegistrationWallProps> = (
    props,
) => {
    return (
        <FeatureToggle
            feature="registration-wall"
            on={() => <LazyRegistrationWallComponent {...props} />}
        />
    )
}
