import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { AdTargeting, PageType, StaticRoutes } from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import { getOurPapersRoute } from '../regionals/get-our-papers'

function createPage(
    pageTitle: string,
    description: string,
    adTargeting: AdTargeting,
    contentComponentType: Array<CheckedComponentInformation>,
    section: TheWestSection = 'default',
    breakingNews = true,
): PageType<TheWestSection> {
    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'static',
        section,
        adTargeting,
        hideHeading: true,
        pageMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        ...(breakingNews
                            ? [
                                  layout.component({
                                      type: 'breaking-news',
                                      props: {
                                          dataDefinitionArgs: {
                                              type: 'curation',
                                              name: 'breaking-news',
                                              offset: 0,
                                              pageSize: 1,
                                          },
                                      },
                                  }),
                                  layout.component({
                                      type: 'breaking-news-promo-strap',
                                      props: {
                                          dataDefinitionArgs: {
                                              type: 'curation',
                                              name: 'breaking-news-promo-strap',
                                              offset: 0,
                                              pageSize: 1,
                                          },
                                      },
                                  }),
                              ]
                            : []),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: pageTitle,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                },
                contentAreas: {
                    main: contentComponentType,
                },
            }),
        ],
    }
}

export const staticContentRoutes: StaticRoutes<TheWestSection> = {
    '/contact': () =>
        createPage(
            'Contact Us',
            'Contact Us | The West Australian',
            {
                pageId: 'contact',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'contact' },
                    },
                }),
            ],
        ),
    '/contact/regional': () =>
        createPage(
            'Regional Office Contacts',
            'Regional Contacts | The West Australian',
            {
                pageId: 'contact/regional',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'contact-regional' },
                    },
                }),
            ],
        ),
    '/contact/countryman': () =>
        createPage(
            'Countryman Contacts',
            'Countryman Contacts | The West Australian',
            {
                pageId: 'contact/regional',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'contact-countryman' },
                    },
                }),
            ],
        ),
    '/editorial-complaints': () =>
        createPage(
            'Editorial Complaints',
            'Editorial Complaints | The West Australian',
            {
                pageId: 'editorial-complaints',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'editorial-complaints',
                        },
                    },
                }),
            ],
        ),
    '/editorial-policy': () =>
        createPage(
            'EDITORIAL POLICY',
            'EDITORIAL POLICY| The West Australian',
            {
                pageId: 'editorial-policy',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'editorial-policy',
                        },
                    },
                }),
            ],
        ),
    '/faq': () =>
        createPage(
            'Frequently Asked Questions',
            'Frequently Asked Questions | The West Australian',
            {
                pageId: 'frequently-asked-questions',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'faq',
                        },
                    },
                }),
            ],
        ),
    '/our-papers': ({ getAdTargeting }) =>
        getOurPapersRoute({ section: 'default', getAdTargeting }),
    '/terms': () =>
        createPage(
            'TERMS OF USE',
            'TERMS OF USE | The West Australian',
            {
                pageId: 'terms',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'the-west-terms',
                    props: {},
                }),
            ],
        ),
    '/privacy-statement': () =>
        createPage(
            'WAN Privacy Statement',
            'Privacy Statement | The West Australian',
            {
                pageId: 'privacy-statement',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'privacy-statement' },
                    },
                }),
            ],
        ),
    '/rss-feeds': () =>
        createPage(
            'RSS Feeds',
            'RSS Feeds | The West Australian',
            {
                pageId: 'rss-feeds',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: { id: 'rss-feeds' },
                    },
                }),
                layout.component({
                    type: 'rss-feeds',
                    props: {},
                }),
                layout.component({
                    type: 'inline-content',
                    props: {
                        blocks: [
                            {
                                kind: 'text',
                                text: 'By using our news feed, you agree to our Terms of Use.',
                                intentions: [
                                    {
                                        index: 41,
                                        kind: 'link',
                                        href: '/terms',
                                        length: 12,
                                    },
                                ],
                            },
                        ],
                    },
                }),
            ],
        ),
    '/content-funding': () =>
        createPage(
            'Commercial content on The West',
            'The West Australian produces a variety of digital content, we aim to make it clear how the content has been commissioned and produced, and who has funded it.',
            {
                pageId: 'content-funding',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'content-funding',
                        },
                    },
                }),
            ],
        ),
    '/community-guidelines': () =>
        createPage(
            'Community Guidelines',
            'The community guidelines for commenting and reader discussions on thewest.com.au when joining the conversation.',
            {
                pageId: 'community-guidelines',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            [
                layout.component({
                    type: 'static-content',
                    props: {
                        dataDefinitionArgs: {
                            id: 'community-guidelines',
                        },
                    },
                }),
            ],
        ),
}
