import { useEffect, useState } from 'react'
import { breakpoint } from '../__styling'

interface IState {
    isMobile: boolean
    isTabletOrMobile: boolean
    isLargeScreen: boolean
}

export function useViewport() {
    const [viewportData, setViewportData] = useState<IState>({
        isMobile: false,
        isTabletOrMobile: false,
        isLargeScreen: false,
    })

    const updateViewport = () => {
        if (
            // fail conditional if SSR is triggered or a VR test
            typeof window !== 'undefined' &&
            window &&
            typeof window.matchMedia === 'function'
        ) {
            const lgBreakpoint = window.matchMedia(
                breakpoint('lg').replace('@media ', ''),
            )
            const mdBreakpoint = window.matchMedia(
                breakpoint('md').replace('@media ', ''),
            )
            const smBreakpoint = window.matchMedia(
                breakpoint('sm').replace('@media ', ''),
            )
            // if viewport size is below md or sm breakpoint return true
            return setViewportData({
                isMobile: !smBreakpoint.matches ? true : false,
                isTabletOrMobile: !mdBreakpoint.matches ? true : false,
                isLargeScreen: lgBreakpoint.matches ? true : false,
            })
        }
    }

    useEffect(() => {
        updateViewport()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', updateViewport)
        return () => window.removeEventListener('resize', updateViewport)
    })

    return viewportData
}
