import {
    CollectionEvent,
    Product,
    PublicationCardItem,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useProduct } from '../../__product'
import {
    StyledOlympicsCollectionBottomRow,
    StyledOlympicsCollectionContent,
    StyledOlympicsCollectionParent,
    StyledOlympicsCollectionTopRow,
    StyledOlympicsCollectionTopRowSub,
    StyledOlympicsTallyContainer,
} from './OlympicsCollection.styled'
import {
    MedalTally,
    OlympicsCollectionArticle,
    OlympicsCollectionFeatureArticle,
    OlympicsCollectionHeader,
    OlympicsCollectionMini,
} from '../../olympics'
import { useImpressionAvailable } from '../../__helpers/impression-available-helper'

/** The props utilised to configure the olympics collection layout */
export interface OlympicsCollectionProps {
    /** onEvent handler passed down from the top level */
    onEvent: (event: CollectionEvent) => void
    /** Cards to show from the curation */
    items: MaybeLoaded<PublicationCardItem[]>
    /** Feature toggle to show the medal tally. If false it'll show the last curation item */
    tallyToggle: boolean
    /** Default's to a value of true */
    isHomepage?: boolean
    /** If true, the OlympicsCollectionParent div will have no margin. */
    removeTopBottomMargin?: boolean
}

export const OlympicsCollection: React.FC<OlympicsCollectionProps> = ({
    onEvent,
    items,
    tallyToggle,
    isHomepage = true,
    removeTopBottomMargin = false,
}) => {
    const product = useProduct()
    const impressionRef = useImpressionAvailable({
        available: () => {
            onEvent(
                createCollectionAvailableEvent(
                    items.loaded ? items.result : [],
                    'OlympicsCollection',
                    product,
                    onEvent,
                ),
            )
        },
        loading: !items.loaded,
    })

    // Wait for the items to be loaded
    if (!items.loaded) {
        return null
    }

    const articles = items.result
    const isPerthNow = product === Product.PerthNow

    // PerthNow is styled in a rather different way, so for simplicity sake we want to
    // utilise different react components
    const CollectionLayout = isPerthNow
        ? getPerthNowCollectionLayout
        : getDefaultCollectionLayout

    return items.loaded ? (
        <CollectionLayout
            articles={articles}
            onEvent={onEvent}
            isHomepage={isHomepage}
            tallyToggle={tallyToggle}
            ref={impressionRef}
            removeTopBottomMargin={removeTopBottomMargin}
        />
    ) : null
}

interface LayoutProps {
    articles: PublicationCardItem[]
    onEvent: (event: any) => void
    isHomepage: boolean
    tallyToggle: boolean
    ref?: React.RefObject<any>
    removeTopBottomMargin?: boolean
}

const getPerthNowCollectionLayout: React.FC<LayoutProps> = ({
    articles,
    onEvent,
    isHomepage,
    tallyToggle,
    ref,
    removeTopBottomMargin,
}) => (
    <StyledOlympicsCollectionParent
        ref={ref}
        removeTopBottomMargin={removeTopBottomMargin}
    >
        <StyledOlympicsCollectionContent>
            <OlympicsCollectionHeader
                isHomepage={isHomepage}
                onEvent={onEvent}
            />
            {/* First row, contains feature article and normal article */}
            <StyledOlympicsCollectionTopRow>
                <OlympicsCollectionFeatureArticle
                    item={articles[0]}
                    onEvent={onEvent}
                />
                <StyledOlympicsCollectionTopRowSub>
                    <OlympicsCollectionArticle
                        item={articles[1]}
                        onEvent={onEvent}
                        articleType={'article'}
                        cardNumber={2}
                    />
                    <OlympicsCollectionArticle
                        item={articles[2]}
                        onEvent={onEvent}
                        articleType={'article'}
                        cardNumber={3}
                    />
                </StyledOlympicsCollectionTopRowSub>
            </StyledOlympicsCollectionTopRow>
            {/* Second row, contains semi-large article and normal articles for TheWest, otherwise 2 more articles and large content */}
            <StyledOlympicsCollectionBottomRow>
                {/* Show the 'mini' collection which is a group of more articles, on TheWest it's a 2x2, on PerthNow it's a 1x2 */}
                <OlympicsCollectionMini
                    articles={articles.slice(3, 5)}
                    onEvent={onEvent}
                />
                {tallyToggle ? (
                    <StyledOlympicsTallyContainer>
                        <MedalTally size={'embed'} listSize={8} />
                    </StyledOlympicsTallyContainer>
                ) : (
                    <OlympicsCollectionFeatureArticle
                        item={articles[5]}
                        onEvent={onEvent}
                        cardNumber={6}
                    />
                )}
            </StyledOlympicsCollectionBottomRow>
        </StyledOlympicsCollectionContent>
    </StyledOlympicsCollectionParent>
)

const getDefaultCollectionLayout: React.FC<LayoutProps> = ({
    articles,
    onEvent,
    isHomepage,
    tallyToggle,
    ref,
    removeTopBottomMargin,
}) => (
    <StyledOlympicsCollectionParent
        ref={ref}
        removeTopBottomMargin={removeTopBottomMargin}
    >
        <StyledOlympicsCollectionContent>
            <OlympicsCollectionHeader
                isHomepage={isHomepage}
                onEvent={onEvent}
            />
            {/* First row, contains feature article and normal article */}
            <StyledOlympicsCollectionTopRow>
                <OlympicsCollectionFeatureArticle
                    item={articles[0]}
                    onEvent={onEvent}
                />
                <StyledOlympicsCollectionTopRowSub>
                    <OlympicsCollectionArticle
                        item={articles[1]}
                        onEvent={onEvent}
                        articleType={'articleWithNoTeaser'}
                        cardNumber={2}
                    />
                </StyledOlympicsCollectionTopRowSub>
            </StyledOlympicsCollectionTopRow>
            {/* Second row, contains semi-large article and normal articles for TheWest, otherwise 2 more articles and large content */}
            <StyledOlympicsCollectionBottomRow>
                {/* Only show the extra article on TheWest */}
                <OlympicsCollectionArticle
                    item={articles[2]}
                    onEvent={onEvent}
                    articleType={'articleWithTeaserAndImageLeft'}
                    cardNumber={3}
                />

                {/* Show the 'mini' collection which is a group of more articles, on TheWest it's a 2x2, on PerthNow it's a 1x2 */}
                <OlympicsCollectionMini
                    articles={articles.slice(3, 7)}
                    onEvent={onEvent}
                />
                {tallyToggle ? (
                    <StyledOlympicsTallyContainer>
                        <MedalTally
                            size={'embed'}
                            listSize={7}
                            widgetTitle="medal-tally"
                        />
                    </StyledOlympicsTallyContainer>
                ) : (
                    <OlympicsCollectionArticle
                        item={articles[7]}
                        onEvent={onEvent}
                        articleType={'articleWithTeaserAndImageRight'}
                        cardNumber={8}
                    />
                )}
            </StyledOlympicsCollectionBottomRow>
        </StyledOlympicsCollectionContent>
    </StyledOlympicsCollectionParent>
)
