import styled from '@emotion/styled'
import React from 'react'
import { calcRem } from '../../__styling'

const StyledGuestMessage = styled('div')(({ theme }) => ({
    background: theme.colors.components?.guestMessage
        ? theme.colors.components.guestMessage.background
        : theme.colors.background.primary,
    padding: calcRem(24),
    marginBottom: calcRem(16),

    '& p': {
        marginTop: 0,
        wordWrap: 'break-word',
    },
}))

const StyledGuestMessageAuthor = styled('span')({
    fontSize: calcRem(16),
    fontWeight: 'bold',
    margin: 0,
})

interface Props {
    message: JSX.Element
    author: string
}

export const GuestMessage: React.FC<Props> = (props) => {
    const { message, author } = props
    return (
        <StyledGuestMessage>
            {message}
            <StyledGuestMessageAuthor>{author}</StyledGuestMessageAuthor>
        </StyledGuestMessage>
    )
}
