import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { PageType, Store } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export function createCookieSettingsPage(
    store: Store,
    section: TheWestSection = 'default',
): PageType<TheWestSection> | null {
    if (
        !isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'cookie-consent',
        )
    ) {
        return null
    }

    return {
        kind: 'page',
        heading: 'Cookie Settings',
        hideHeading: true,
        pageType: 'static',
        adTargeting: {
            pageId: 'cookie-settings',
            adUnitPath: '',
            ssAdUnits: [],
            topics: [],
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                    horizontalGutters: 'outerMargin',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: 'Cookie Settings',
                                enableHeaderTag: true,
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: true,
                    horizontalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'thor',
                            props: {
                                containerWidth: '100%',
                                horizontalGutters: true,
                                hasBackgroundFill: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'cookie-consent-user-settings-page',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
