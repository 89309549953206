import { ColorsType } from '../types'

const Palette = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    aqua: '#00FFFF',
    greyGorilla: '#2b2c2d',
    greyRhino: '#3D3D3D',
    greyElephant: '#535556',
    greyKoala: '#74787a',
    greyHippo: '#888d90',
    greyQuokka: '#b5b9ba',
    greyDolphin: '#d2d5d6',
    greySeal: '#e5e5e5',
    greyMouse: '#eaeaea',
    greyErmine: '#f4f5f5',
    westblue: '#043de2',
    westblueTint: '#0a70f3',
    indigo: '#3c62c3',
    westblueDisabled: '#b4c4f7',
    ladybird: '#ab2b2b',
    engineRed: '#C63400',
    dangerRed: '#740400',
    cricketGreen: '#2c3e02',
    java: '#13b4c3',
    hollywoodCerise: '#F400AB',
    sunshineYellow: '#E4C300',
    canaryYellow: '#FFB506',
    fantaOrange: '#FF8900',
    breachNavy: '#001d56',
    breachLightBlue: '#EBF0FD',
    breachBgLightBlue: '#D7E2FF',
    customBodyTextColor: 'rgb(255, 255, 255, 0.8)',
    customAuthorTextColor: 'rgb(255, 255, 255, 0.65)',
    silver: '#2B2D2E',
    // lightSilver: 'rgba(210,213,214,0.16)',
    lightSilver: 'rgba(255,255,255,0.16)',
    developingNews: {
        grey: {
            background: '#535556',
            kickerFont: '#DDDDDD',
        },
        blue: {
            background: '#001d56',
            kickerFont: '#CCD2DD',
            sectionValue: '#95D2FF',
        },
        red: {
            background: '#850400',
            kickerFont: '#E7CDCC',
            sectionValue: '#FFA8A8',
        },
        australia: {
            background: 'linear-gradient(180deg, #006331 0%, #000000 187.16%)',
            kickerFont: '#FFDA00',
            hover: '#FFF4B3',
            editorialLabel: {
                breaking: '#FFFFFF',
                default: '#FFFFFF',
            },
        },
    },
    newWestGradient: {
        blue: 'linear-gradient(180deg, #043DE2 25%, #000047 90%)',
        purpleBlue: 'linear-gradient(45deg, #043DE2 29%, #871DE3 59%)',
        purpleBlue2: 'linear-gradient(90deg, #043DE2 29%, #871DE3 82%)',
        purpleBlue3: 'linear-gradient(90deg, #043DE2 29%, #871DE3 100%)',
        purpleBlue4: 'linear-gradient(90deg, #043DE2 29%, #871DE3 69%)',
    },
    aussie: {
        green: '#006331',
        bg_gradient:
            'linear-gradient(180deg,#FFDA00 40%, #38691C 77%, #154022 108%)',
    },
    grandFinal: {
        white: '#fff',
        lightBlue: '#92E4FE',
        mediumBlue: '#03307C',
        lightBlueBorderTransparent: 'rgba(133, 195, 245, 0.25)',
        darkBlue: '#002a5f',
        gradientBlue:
            'linear-gradient(179.74deg, #001F54 0.25%, #003897 116.15%)',
        transparent: 'transparent',
    },
    grandFinalFreo: {
        purple: 'linear-gradient(129.35deg, #48008F 15.06%, #1A013A 87.96%)',
        lightPurple: '#BC92FF',
        linePurple: 'rgb(216, 192, 255, 0.4)', // RGB was used instead of hex to apply opacity.
    },
    queensLegacy: {
        white: '#fff',
        maroon: '#750049',
        maroonLight: '#B572A6',
        pink: '#952d4f',
        whiteBorderTransparent: 'rgba(255, 255, 255, 0.3)',
        darkBlue: '#002a5f',
        gradientMaroon: 'linear-gradient(180deg, #952d4f 0%, #750049 100%)',
        transparent: 'transparent',
    },
    cnbc: {
        darkBlue: '#004B89',
    },
    theEconomist: {
        darkGrey: '#383E42',
        lightGrey: '#D7D7D7',
    },
    waPolice: {
        darkBlue: '#21368B',
    },
    commonWealthGames: {
        purple: 'linear-gradient(92.09deg, #021487 -0.69%, #6E259B 48.73%, #BD37AC 103.58%)',
        // Used in the 4-card curation on the home page
        cardBorderTransparent: 'rgba(255, 255, 255, 0.5)',
        // Used for the hover colors on the 4-card curation when hovering over the cards.
        cardTextHover: 'rgba(255, 255, 255, 0.8)',
        transparent: 'rgba(255, 255, 255, 0)',
    },
    kingsCoronation: {
        gradientRed: 'linear-gradient(#8F0202, #531235)',
        brickRed: '#8F0202',
        palePink: '#FFD4D4',
        gold: '#FFE072',
        gold40: '#FFE07240',
        brilliantGold: '#F0A22C',
        shinyGold:
            'radial-gradient(farthest-side at 100px, #FFE5A3 10%, #F0A22C)',
    },
    goMatildas: {
        gradientGreen: 'linear-gradient(#006331 0%, #000 180%)',
        aussieGreen: '#006331',
        aussieGold: '#FFDA00',
        custardYellow: '#FFF4B3',
    },
    universityOffer: {
        campaignLilac: '#a170ff',
    },
    bestAustralianYarn: {
        velvet: '#24243f',
        lilac: '#d2d3d7',
        darkVelvet: '#160F35',
        cyan: '#18E4F9',
        blueCharcoal: '#18184c',
        ochreYellow: '#ffbc21',
    },
    videoHub: {
        dullBlue: '#7892DE',
    },
    AFL: {
        westblue40: '#9BB1F3',
        westblue10: '#E5EBFC',
        aflgold: '#FEC70A',
    },
    gradialBlue:
        'radial-gradient(50% 50% at 50% 50%, rgba(4, 61, 226, 0.06) 0%, rgba(4, 61, 226, 0.06) 100%)',
    regionals: {
        albanyadvertiserBlue: '#000099',
        augustamargaretrivertimesOrange: '#d3420d',
        broomeadvertiserGreen: '#00856f',
        bunburyheraldBlue: '#0071bb',
        busseltondunsboroughtimesTeal: '#0080a3',
        countrymanRed: '#e01f2e',
        geraldtonguardianRed: '#c4161c',
        greatsouthernheraldBlue: '#006ad3',
        harveywaroonareporterGreen: '#008542',
        kalgoorlieminerRed: '#e22822',
        manjimupbridgetowntimesBlue: '#2478cc',
        midwesttimesRed: '#e7131a',
        narroginobserverBlue: '#007bc7',
        northwesttelegraphOcre: '#cc4928',
        pilbaranewsOrange: '#cd470e',
        soundtelegraphBlue: '#007acc',
        southwesterntimesRed: '#da2128',
        thekimberleyechoRed: '#e7131a',
    },
    anniversaryPurple: '#2E3068',
    anniversaryPurpleGradient: 'linear-gradient(#2E3068, #151730)',
    anniversaryPalePurple: '#D6CCE0',
    anniversary190YearGradient:
        'linear-gradient(45deg, #D9D9D9 0%, #EBF3F8 20%, #B6D0E4 100%), #B6D0E4',
    anniversary190YearTeal: '#62CDD9',
    anniversary190YearPrimary: '#2F3068',
    vividMulberry: '#B201DB',
    mediumSpringGreen: '#27FB9E',
}

export type TheWestPalette = typeof Palette

export type TheWestColourMap = ColorsType<TheWestPalette>

export const colors: TheWestColourMap = {
    brand: Palette.westblue,
    section: Palette.westblue,
    palette: Palette,
    background: {
        primary: Palette.white,
        secondary: Palette.white,
    },
    text: {
        primary: Palette.black,
        secondary: Palette.greyElephant,
        tertiary: Palette.greyGorilla,
        heading: Palette.greyGorilla,
    },
    actions: {
        link: {
            default: Palette.greyGorilla,
            hover: Palette.westblue,
            focus: Palette.westblue,
            active: Palette.westblue,
        },
        button: {
            // White text on Blue
            primary: {
                text: {
                    default: Palette.white,
                    hover: Palette.westblue,
                    focus: Palette.white,
                    active: Palette.white,
                    disabled: Palette.greyGorilla,
                },
                background: {
                    default: Palette.westblue,
                    hover: Palette.white,
                    focus: Palette.greyGorilla,
                    active: Palette.greyGorilla,
                    disabled: Palette.greyQuokka,
                },
                border: {
                    default: Palette.westblue,
                    hover: Palette.white,
                    focus: Palette.greyGorilla,
                    active: Palette.greyGorilla,
                    disabled: Palette.greyQuokka,
                },
            },
            // White text on Grey
            secondary: {
                text: {
                    default: Palette.white,
                    hover: Palette.westblue,
                    focus: Palette.white,
                    active: Palette.white,
                    disabled: Palette.greyGorilla,
                },
                background: {
                    default: Palette.greyMouse,
                    hover: Palette.white,
                    focus: Palette.greyGorilla,
                    active: Palette.greyGorilla,
                    disabled: Palette.greyQuokka,
                },
                border: {
                    default: Palette.westblue,
                    hover: Palette.white,
                    focus: Palette.greyGorilla,
                    active: Palette.greyGorilla,
                    disabled: Palette.greyGorilla,
                },
            },
            // Blue text on White
            tertiary: {
                text: {
                    default: Palette.westblue,
                    hover: Palette.greyGorilla,
                    focus: Palette.white,
                    active: Palette.greySeal,
                    disabled: Palette.westblueDisabled,
                },
                background: {
                    default: Palette.white,
                    hover: Palette.white,
                    focus: Palette.greyKoala,
                    active: Palette.greyGorilla,
                    disabled: Palette.white,
                },
                border: {
                    default: Palette.westblue,
                    hover: Palette.white,
                    focus: Palette.greyKoala,
                    active: Palette.greyGorilla,
                    disabled: Palette.westblueDisabled,
                },
            },
        },
    },
    icons: {
        default: Palette.greyHippo,
        hover: Palette.westblue,
        secondary: Palette.breachNavy,
    },
    borders: {
        primary: Palette.greyDolphin,
        secondary: Palette.greyGorilla,
        tertiary: Palette.greySeal,
    },
    components: {
        guestMessage: {
            background: Palette.greyErmine,
            text: Palette.black,
        },
        videoHub: {
            scrollBar: {
                track: Palette.greyDolphin,
                thumb: Palette.videoHub.dullBlue,
            },
            borderBottom: Palette.black,
        },
        emailPreferenceCentre: {
            background: {
                mute: Palette.greyErmine,
            },
            text: {
                default: Palette.silver,
                secondary: Palette.greyElephant,
                mute: Palette.greyKoala,
                danger: Palette.ladybird,
            },
            link: {
                default: Palette.westblue,
            },
            button: {
                primary: {
                    text: {
                        default: Palette.white,
                        hover: Palette.westblue,
                        focus: Palette.westblue,
                        active: Palette.westblue,
                        disabled: Palette.westblue,
                    },
                    background: {
                        default: Palette.ladybird,
                        hover: Palette.westblue,
                        focus: Palette.westblue,
                        active: Palette.westblue,
                        disabled: Palette.greyGorilla,
                    },
                    border: {
                        default: Palette.westblue,
                        hover: Palette.white,
                        focus: Palette.greyGorilla,
                        active: Palette.greyGorilla,
                        disabled: Palette.greyGorilla,
                    },
                },
                secondary: {
                    text: {
                        default: Palette.westblue,
                        hover: Palette.westblue,
                        focus: Palette.westblue,
                        active: Palette.westblue,
                        disabled: Palette.westblue,
                    },
                    background: {
                        default: Palette.white,
                        hover: Palette.westblue,
                        focus: Palette.westblue,
                        active: Palette.westblue,
                        disabled: Palette.westblue,
                    },
                    border: {
                        default: Palette.westblue,
                        hover: Palette.white,
                        focus: Palette.greyGorilla,
                        active: Palette.greyGorilla,
                        disabled: Palette.greyGorilla,
                    },
                },
            },
            borders: {
                primary: Palette.greyGorilla,
                divider: Palette.greyQuokka,
                mute: Palette.greySeal,
            },
        },
        card: {
            label: {
                breaking: Palette.ladybird,
            },
        },
    },
}
