import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface CameraProps {
    className?: string
    width?: number
    height?: number
    horizontalSpacing?: number | undefined
}

export const StyledCameraIcon = styled('svg')<CameraProps>((props) => ({
    width: props.width ? calcRem(props.width) : undefined,
    height: props.height ? calcRem(props.height) : undefined,
    marginLeft: props.horizontalSpacing && calcRem(props.horizontalSpacing),
    fill: props.theme.colors.icons?.default,
}))
