import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { hexToRGBA } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const StyledTermsFooter = styled('nav')(({ theme }) => ({
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        fallback: fonts.thewest.sansSerif,
    }),
    width: '100%',
}))

export const StyledTerms = styled('div')(({ theme }) => ({
    background: themedValue(theme, {
        sevennews: (resolved) => resolved.footerNav.primaryColor,
        fallback: undefined,
    }),
    color: themedValue(theme, {
        sevennews: colors.white,
        fallback: colors.thewest.greyElephant,
    }),
    display: 'flex',
    alignItems: 'center',
    borderTop: themedValue(theme, {
        thewest: `1px solid ${colors.thewest.greyDolphin}`,
        fallback: undefined,
    }),
    flexWrap: 'wrap',
    padding: themedValue(theme, {
        sevennews: 0,
        fallback: calcRem(
            metrics.thewest.margins.md,
            0,
            metrics.thewest.margins.xl,
            0,
        ),
    }),
    fontSize: calcRem(14),

    '> a': {
        display: 'block',
        width: '100%',

        // Custom breakpoint to fit the footer logo on screen for longer
        '@media (min-width: 1044px)': {
            marginLeft: 'auto',
            width: 'auto',
        },
    },

    '& p': {
        padding: 0,
        margin: themedValue(theme, {
            thewest: calcRem(
                metrics.thewest.margins.xs,
                metrics.thewest.margins.md,
                metrics.thewest.margins.sm,
                0,
            ),
            fallback: undefined,
        }),
    },
}))

export const StyledLinkList = styled('ul')(({ theme }) => [
    {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        padding: themedValue(theme, {
            thewest: calcRem(metrics.thewest.margins.sm, 0),
            fallback: undefined,
        }),
        width: '100%',
        maxWidth: '1000px',
        margin: '0 auto',
    },
    theme.kind === 'sevennews' && {
        width: '100%',
        padding: calcRem(8, 2),

        [breakpointMax('sm')]: {
            justifyContent: 'space-between',
            padding: calcRem(
                metrics.sevennews.margins.sm,
                0,
                metrics.sevennews.margins.md,
                0,
            ),
        },
    },
])

export const StyledLinks = styled('li')(({ theme }) => [
    {
        padding: themedValue(theme, {
            fallback: calcRem(
                metrics.thewest.margins.xs,
                metrics.thewest.margins.md,
                metrics.thewest.margins.sm,
                0,
            ),
        }),
    },

    theme.kind === 'sevennews' && {
        display: 'block',
        width: '100%',
        lineHeight: 1,
        textAlign: 'center',
        padding: 0,

        [breakpoint('sm')]: {
            display: 'inline-block',
            padding: 0,
            width: 'auto',

            '&::after': {
                content: "''",
                display: 'inline-block',
                height: calcRem(14),
                borderLeft: `1px solid ${colors.white}`,
                verticalAlign: 'middle',
            },

            '&:last-child::after': {
                borderLeft: `none`,
            },
        },
    },
    {
        '&:first-child': {
            paddingLeft: 0,
        },
        '& a': [
            {
                display: 'inline-block',
                fontSize: themedValue(theme, {
                    thewest: calcRem(14),
                    fallback: calcRem(11),
                }),
                color: themedValue(theme, {
                    thewest: colors.thewest.greyElephant,
                    fallback: colors.white,
                }),
                textDecoration: 'none',

                '&:hover': {
                    textDecoration: 'underline',
                    color: themedValue(theme, {
                        thewest: (section) => section.primaryColor,
                        fallback: colors.white,
                    }),
                },

                '&:focus': {
                    textDecoration: 'underline',
                },
            },

            theme.kind === 'sevennews' && {
                padding: calcRem(14, 15),
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: 0.17,

                [breakpoint('sm')]: {
                    padding: calcRem(0, 15),
                },

                '&:hover': {
                    backgroundColor: hexToRGBA(colors.white, 0.1),
                    color: colors.white,
                },

                '&:focus': {
                    outline: `2px solid ${colors.white}`,
                    color: colors.white,
                },
            },
        ],
    },
])

export const StyledFooterLogo = styled('div')({
    display: 'inline-block',

    [breakpoint('xl')]: {
        marginLeft: 'auto',
    },

    a: {
        img: {
            padding: 0,
            height: calcRem(30),
        },
        svg: {
            padding: 0,
            height: calcRem(30),
        },
    },
})
