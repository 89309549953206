import {
    isServerEnvironment,
    RedirectRouteInformation,
    RouteStatus,
} from '@news-mono/web-common'
import isAbsoluteUrl from 'is-absolute-url'
import React from 'react'
import { Redirect } from 'react-router'
import { Logger } from 'typescript-log'

export interface RedirectRendererProps {
    log: Logger
    routeInfo: RedirectRouteInformation
}

export const RedirectRenderer: React.FC<RedirectRendererProps> = ({
    routeInfo,
    log,
}) => {
    log.debug({ routeInfo }, 'Redirecting')
    // React router does not support absolute redirects
    if (
        isAbsoluteUrl(routeInfo.redirectTo.targetUrl) &&
        !isServerEnvironment()
    ) {
        window.location.href = routeInfo.redirectTo.targetUrl
        return null
    }

    return (
        <RouteStatus statusCode={routeInfo.redirectTo.httpStatusCode || 302}>
            <Redirect to={routeInfo.redirectTo.targetUrl} />
        </RouteStatus>
    )
}
RedirectRenderer.displayName = 'RedirectRenderer'
