import React from 'react'
import { StyledToastComponentContainer } from './components/StyledToastComponentContainer'
import { StyledToastText } from './components/StyledToastText'
import { TNToastComponentProps } from './TNToastNotification.routing'
import {
    StyledToastCheckmarkIcon,
    StyledToastIconContainer,
    StyledToastInfoIcon,
} from './TNToastNotification.styled'

export const TNToastComponent: React.FC<TNToastComponentProps> = (props) => {
    // Change the icon for the 'success' alert banner, otherwise use the info
    // icon.
    const Icon =
        props.type === 'success'
            ? StyledToastCheckmarkIcon
            : StyledToastInfoIcon

    return (
        <StyledToastComponentContainer
            type={props.type}
            useMaxWidth={props.useMaxWidth}
            forceMaxWidth={props.forceMaxWidth}
        >
            {/* We wrap the alert icon in a container so it can always be centered and at 24x24px */}
            <StyledToastIconContainer>
                <Icon type={props.type} />
            </StyledToastIconContainer>
            {/* Style the text with coloring, which is passed through with the type */}
            <StyledToastText type={props.type}>{props.text}</StyledToastText>
        </StyledToastComponentContainer>
    )
}
