import { cx } from '@emotion/css'
import { useTheme } from '@emotion/react'
import React from 'react'
import {
    Attribution,
    Chevron,
    Icon,
    NightlyIcon,
    StyledFooter,
    StyledLine,
    StyledParagraph,
    StyledPullquote,
} from '../../publication/Pullquote/Pullquote.styled'
import { themedValue } from '../../__styling/themed-value'
import { PullquoteProps } from './Pullquote'
import { Product } from '@news-mono/web-common'

export const WebPullQuote: React.FC<PullquoteProps> = ({
    text,
    attribution,
}) => {
    const theme = useTheme()
    const classes = cx('pullquote', {
        'pullquote--no-attrib': !attribution,
    })

    const attributionHack = { attribution } as any

    const quote = theme.kind !== 'sevennews' ? text.replace(/[“”]/g, '') : text

    const symbol = themedValue(theme, {
        perthnow: <Icon />,
        sevennews: <Chevron />,
        thewest: <StyledLine />,
        thenightly: <NightlyIcon product={Product.TheNightly} />,
        fallback: undefined,
    })

    return (
        <StyledPullquote className={classes} {...attributionHack} theme={theme}>
            {theme.kind === 'sevennews' ? (
                <>
                    <StyledParagraph>
                        <span>"</span>
                        {quote}
                        <span>"</span>
                    </StyledParagraph>
                </>
            ) : (
                <>
                    {symbol}
                    <StyledParagraph>{quote}</StyledParagraph>
                </>
            )}
            {attribution && (
                <StyledFooter>
                    <Attribution className="attribution">
                        {attribution}
                    </Attribution>
                </StyledFooter>
            )}
        </StyledPullquote>
    )
}
