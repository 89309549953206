import { IsHydrating } from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import {
    StyledCountDown,
    StyledElement,
    StyledItem,
    StyledLabel,
} from './Coutdown.styled'

interface CountdownProps {
    targetDate: Date
    countdownFinished?: () => void
    hideWhenComplete?: boolean
}

export const Countdown: React.FC<CountdownProps> = (props) => {
    const { targetDate, countdownFinished, hideWhenComplete } = props
    const initialValue = targetDate.getTime() - new Date().getTime()
    const [timeLeftMs, setTimeLeftMs] = useState(Math.max(0, initialValue))

    useEffect(() => {
        if (timeLeftMs <= 0) {
            countdownFinished && countdownFinished()
        }
    }, [timeLeftMs, countdownFinished])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeftMs((value) => value - 1000)
        }, 1000)

        return () => clearInterval(intervalId)
    })

    const component = <CountdownDisplay millisecondsLeft={timeLeftMs} />
    return hideWhenComplete ? (timeLeftMs > 0 ? component : null) : component
}

const CountdownDisplay = (props: { millisecondsLeft: number }) => {
    const countdown = useGetCountDownValues(props.millisecondsLeft)

    const separator = () => (
        <StyledElement separator={true}>
            <StyledItem>{' : '}</StyledItem>
        </StyledElement>
    )

    return (
        <StyledCountDown>
            <StyledElement>
                <StyledItem>{leftPad(countdown.days)}</StyledItem>
                <StyledLabel>{'days'}</StyledLabel>
            </StyledElement>
            {separator()}
            <StyledElement>
                <StyledItem>{leftPad(countdown.hours)}</StyledItem>
                <StyledLabel>{'hours'}</StyledLabel>
            </StyledElement>
            {separator()}
            <StyledElement>
                <StyledItem>{leftPad(countdown.minutes)}</StyledItem>
                <StyledLabel>{'minutes'}</StyledLabel>
            </StyledElement>
            {separator()}
            <StyledElement>
                <StyledItem>{leftPad(countdown.seconds)}</StyledItem>
                <StyledLabel>{'seconds'}</StyledLabel>
            </StyledElement>
        </StyledCountDown>
    )
}

const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24

function useGetCountDownValues(difference: number) {
    const isHydrating = React.useContext(IsHydrating)

    // when hydrating and not a test vr env
    if (isHydrating && process.env.NODE_ENV !== 'vr_test') {
        return {
            days: '--',
            hours: '--',
            minutes: '--',
            seconds: '--',
        }
    }

    const days = Math.floor(difference / day)
    const hours = Math.floor((difference % day) / hour)
    const minutes = Math.floor((difference % hour) / minute)
    const seconds = Math.floor((difference % minute) / second)

    return {
        days,
        hours,
        minutes,
        seconds,
    }
}

// Left pad numbers only
function leftPad(item: number | string) {
    if (typeof item !== 'string') {
        if (Number(item) < 10) {
            return `0${item}`
        }
    }
    return item
}
