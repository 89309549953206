import { useTheme } from '@emotion/react'
import { tokens } from '@news-mono/design-tokens'
import {
    AppState,
    BreachEvent,
    BreachScreenType,
    CartEvent,
    DataLayerEventName,
    RemoveFromCartEvent,
    setDismissedSubscriptionPrompt,
    sswPackages,
    SubscriptionPromptCheckoutEvent,
    SubscriptionPromptCookieData,
    ViewCartEvent,
} from '@news-mono/web-common'
import Cookies from 'js-cookie'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_CART_QUANTITY, getCartPayload } from '../../../../'
import { TheWestMark } from '../../../../logos/TheWestMark/TheWestMark'
import { TheWestHeaderCloseIcon } from '../../../../navigation/TheWestHeaderDropdown/icons/TheWestHeaderCloseIcon'
import CountdownInactivity from '../../../../tools/CountdownInactivity'
import { useAuthActions } from '../../../authentication'
import { createLinkClickEvent } from '../../createLinkClickHandler'
import { MiniSubscriptionPackage } from './MiniSubscriptionPackage'
import { StyledSubscriptionPackageButton2022 } from './SubscriptionPackage.styled'
import {
    StyledSubscriptionPrompt,
    StyledSubscriptionPromptButtonWrapper,
    StyledSubscriptionPromptCloseButton,
    StyledSubscriptionPromptMenuBar,
    StyledSubscriptionPromptSecondaryButton,
} from './SubscriptionPrompt.styled'

const FIVE_MINUTES = 1000 * 60 * 5
const IDLE_TIME_IN_SECONDS = 120
export const IS_SHOWING_LOCAL_STORAGE_KEY = 'SubscriptionPromptIsShowing'

export const SubscriptionPrompt = ({
    promptData,
    checkoutLink,
    onEvent,
}: {
    promptData: SubscriptionPromptCookieData
    checkoutLink: string
    onEvent: (event: BreachEvent) => void
}) => {
    const [isHidden, setIsHidden] = React.useState(
        window?.localStorage?.getItem(IS_SHOWING_LOCAL_STORAGE_KEY) !=
            true.toString(),
    )
    const [hasAlreadyPrompted, setHasAlreadyPrompted] = React.useState(false)
    const { onSubscribeClick } = useAuthActions()
    const theme = useTheme()

    const dismissedPrompt = useSelector(
        (state: AppState) => state.misc.dismissedSubscriptionPrompt,
    )
    const dispatch = useDispatch()
    const startDateTime = promptData.time + FIVE_MINUTES // Don't prompt until at least 5 minutes after the user abandoned their subscription.
    const waitTimeUntilListenForInactivityInSeconds =
        Math.max(0, startDateTime - new Date().valueOf()) / 1000

    // package information
    const cookiePackageInfo = promptData?.package_info
    const packageInfo =
        cookiePackageInfo?.package ||
        sswPackages.find((pkg) => pkg.name === 'digital') ||
        sswPackages[0]
    const isOffer = cookiePackageInfo?.is_offer === true

    let cookieCartPayload = cookiePackageInfo?.cart_payload
    let isValidCart = cookieCartPayload && cookieCartPayload.items
    let updatedTotal = -1

    if (isOffer) {
        const offerId = cookiePackageInfo.item_id
        const offerName = cookiePackageInfo.item_name
        const originalPrice = cookiePackageInfo.price
        const promoPrice = cookiePackageInfo.promo_price
        const discount = cookiePackageInfo.discount
        const coupon = cookiePackageInfo.coupon

        // all values are present
        if (
            offerId &&
            offerName &&
            originalPrice &&
            promoPrice &&
            discount &&
            coupon
        ) {
            // update package rate
            packageInfo['stdRateCode'] = offerId

            // update cookie cart payload
            if (isValidCart) {
                cookieCartPayload = cookieCartPayload as CartEvent['payload']

                const currentItem = cookieCartPayload.items[0]

                cookieCartPayload.items[0] = {
                    ...currentItem,

                    item_id: offerId,
                    item_name: offerName,
                    promo_price: promoPrice,
                    discount: discount,
                    coupon: coupon,
                }

                updatedTotal = promoPrice * currentItem.quantity
            } else {
                const updatedPackage = packageInfo
                updatedPackage['weeklyPrice'] = promoPrice
                updatedPackage['price'] = '$' + promoPrice

                // update cart payload
                cookieCartPayload = getCartPayload(updatedPackage, theme)
                isValidCart = cookieCartPayload && cookieCartPayload.items
                updatedTotal =
                    promoPrice *
                    (cookieCartPayload?.items[0].quantity ||
                        DEFAULT_CART_QUANTITY)
            }
        }
    }

    // if it's not a valid cart, use the updated package info to
    // get an updated cart
    if (!isValidCart) {
        cookieCartPayload = getCartPayload(packageInfo, theme)
    }

    // now make sure it's not unrecognised
    cookieCartPayload = cookieCartPayload as CartEvent['payload']

    // and now make sure to update the total value if the cart was updated
    if (updatedTotal !== -1) {
        cookieCartPayload.value = updatedTotal
    }

    const isAlreadyDismissed = React.useCallback(() => {
        return (
            (dismissedPrompt?.href === promptData.href &&
                dismissedPrompt?.time === promptData.time) ||
            hasAlreadyPrompted
        )
    }, [
        dismissedPrompt?.href,
        dismissedPrompt?.time,
        promptData.href,
        promptData.time,
        hasAlreadyPrompted,
    ])

    const hide = React.useCallback(() => {
        setIsHidden(true)
        window?.localStorage?.setItem(
            IS_SHOWING_LOCAL_STORAGE_KEY,
            false.toString(),
        )
        onViewPageChange(
            onEvent,
            false,
            cookieCartPayload as ViewCartEvent['payload'],
        )
        dispatch(setDismissedSubscriptionPrompt(promptData))
    }, [setIsHidden, dispatch, promptData, cookieCartPayload, onEvent])
    const show = React.useCallback(() => {
        setIsHidden(false)
        setHasAlreadyPrompted(true)
        onViewPageChange(
            onEvent,
            true,
            cookieCartPayload as ViewCartEvent['payload'],
        )
    }, [setIsHidden, setHasAlreadyPrompted, cookieCartPayload, onEvent])
    const hideOnEscape = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                hide()
            }
        },
        [hide],
    )
    const navigateAwayHandler = React.useCallback(
        (e: BeforeUnloadEvent) => {
            if (!isAlreadyDismissed()) {
                setTimeout(show, 10)
                e.preventDefault()
                e.returnValue =
                    'You have an incomplete subscription. Are you sure you wish to leave without completing it?'
                return e.returnValue
            }
        },
        [isAlreadyDismissed, show],
    )

    React.useEffect(() => {
        addAndModifyFakeInput()

        const rootElement = document.getElementById('root')
        rootElement?.setAttribute('disabled', (!isHidden).toString())

        if (isHidden) {
            document.body.style.overflowY = 'auto'
            document.body.removeEventListener('keyup', hideOnEscape)
            window.addEventListener('beforeunload', navigateAwayHandler, {
                capture: true,
            })
            window?.localStorage?.setItem(
                IS_SHOWING_LOCAL_STORAGE_KEY,
                false.toString(),
            )
        } else {
            document.body.style.overflowY = 'hidden'
            document.body.addEventListener('keyup', hideOnEscape)
            window.removeEventListener('beforeunload', navigateAwayHandler, {
                capture: true,
            })
            window?.localStorage?.setItem(
                IS_SHOWING_LOCAL_STORAGE_KEY,
                true.toString(),
            )
        }

        return function cleanUp() {
            rootElement?.removeAttribute('disabled')
            document.body.style.overflowY = 'auto'
            document.body.removeEventListener('keyup', hideOnEscape)
            window.removeEventListener('beforeunload', navigateAwayHandler, {
                capture: true,
            })
        }
    }, [
        isHidden,
        navigateAwayHandler,
        hideOnEscape,
        cookieCartPayload,
        onEvent,
        promptData,
        hasAlreadyPrompted,
    ])

    // Always use a seperate useEffect for GA4 events in order to
    // stop it from spamming the events flow with events each time a
    // dependency is updated.
    React.useEffect(() => {
        onViewPageChange(
            onEvent,
            !isHidden,
            cookieCartPayload as ViewCartEvent['payload'],
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StyledSubscriptionPrompt
            style={{ display: isHidden ? 'none' : undefined }}
            hidden={isHidden}
            aria-hidden={isHidden}
        >
            <CountdownInactivity
                timeToInactive={waitTimeUntilListenForInactivityInSeconds}
                timeRequiredInactive={IDLE_TIME_IN_SECONDS}
                onEvent={() => {
                    if (!isAlreadyDismissed()) {
                        show()
                    }
                }}
            />
            {!isHidden && (
                <div>
                    <StyledSubscriptionPromptMenuBar>
                        <TheWestMark height={36} />
                        <StyledSubscriptionPromptCloseButton onClick={hide}>
                            <TheWestHeaderCloseIcon
                                fill={tokens.thewest.colors.palette.greyQuokka}
                            />
                        </StyledSubscriptionPromptCloseButton>
                    </StyledSubscriptionPromptMenuBar>
                    <h2>Are you still there?</h2>
                    <p>We've saved your subscription progress.</p>
                    <MiniSubscriptionPackage
                        packageInfo={packageInfo}
                        offerPrice={
                            isOffer ? cookiePackageInfo?.promo_price : undefined
                        }
                    />
                    <StyledSubscriptionPromptButtonWrapper>
                        <StyledSubscriptionPackageButton2022
                            packageOptions={{
                                theme: 'default',
                            }}
                            breachOptions={{
                                style: 'default-breachwall-2024',
                            }}
                            style={{ maxWidth: 252 }}
                            onClick={() => {
                                const breachScreenType: BreachScreenType =
                                    'Premium - Inline'

                                // Link click event
                                createLinkClickEvent(
                                    'SubscriptionPrompt_Subscribe_Click',
                                    cookieCartPayload?.items[0].coupon || '',
                                    onEvent,
                                    breachScreenType,
                                    1,
                                )
                                // Send subscription_prompt_checkout event
                                onEvent({
                                    type: DataLayerEventName.subscriptionPromptCheckout,
                                    originator: 'subscriptionprompt',
                                    payload:
                                        cookieCartPayload as SubscriptionPromptCheckoutEvent['payload'],
                                })

                                onSubscribeClick({
                                    packagePath: packageInfo.path,
                                    offerCode: cookiePackageInfo?.coupon,
                                    callToAction: 'shopping_cart',
                                    breachScreenType: breachScreenType,
                                })
                            }}
                        >
                            Checkout
                        </StyledSubscriptionPackageButton2022>
                        <StyledSubscriptionPromptSecondaryButton
                            packageOptions={{
                                theme: 'default',
                            }}
                            breachOptions={{
                                style: 'default-breachwall-2024',
                            }}
                            style={{ maxWidth: 252 }}
                            onClick={() => {
                                // Link click event
                                createLinkClickEvent(
                                    'SubscriptionPrompt_Clear_Cart_Click',
                                    cookieCartPayload?.items[0].coupon || '',
                                    onEvent,
                                    undefined,
                                    1,
                                )()
                                // Send remove from cart event
                                onEvent({
                                    type: DataLayerEventName.removeFromCart,
                                    originator: 'subscriptionprompt',
                                    payload:
                                        cookieCartPayload as RemoveFromCartEvent['payload'],
                                })

                                handleCookieClear()
                                hide()
                            }}
                        >
                            Clear cart
                        </StyledSubscriptionPromptSecondaryButton>
                    </StyledSubscriptionPromptButtonWrapper>
                </div>
            )}
        </StyledSubscriptionPrompt>
    )
}

function onViewPageChange(
    onEvent: (event: BreachEvent) => void,
    viewing: boolean,
    payloadInfo: ViewCartEvent['payload'],
) {
    if (viewing) {
        onEvent({
            type: DataLayerEventName.viewCart,
            originator: 'breachscreen',
            payload: payloadInfo,
        })
    }
}

function addAndModifyFakeInput() {
    // Hack to make the prmompt more likely to show up as some browsers only activate it once the user edits an input.
    const input = document.createElement('input')
    input.value = 'I changed something'
    input.style.display = 'none'
    document.body.appendChild(input)
    input.value = 'Different'
    input.dispatchEvent(
        new InputEvent('input', { view: window, inputType: 'keyboard' }),
    )
}

function handleCookieClear() {
    // Does cookie exist? If so set it's expiry 14 days ago so it's not considered an
    // active cookie.
    // This is done instead of using Cookies.remove() so that we don't run into issues
    // with it not clearing due to domain permission issues.
    const cookie = Cookies.get('subscribeCart')
    if (cookie) {
        const cookieOptions = {
            expires: -14,
            path: '/', // available on all paths.
            domain: getTopCookieDomain(),
        }

        Cookies.set('subscribeCart', cookie, cookieOptions)
    }
}

// Copied from SSW @ 2023-06-30_15-53 AWST
function getTopCookieDomain() {
    const TEMP_COOKIE_NAME = 'check-top-domain'
    const domainSegments = location.hostname.split('.').reverse()
    let domain = ''
    let options = {}
    do {
        domain = domainSegments.splice(0, 1)[0] + domain
        if (domainSegments.length > 0) {
            domain = '.' + domain
        }

        options = { domain: domain }
        Cookies.set(TEMP_COOKIE_NAME, 'ok', options)
    } while (
        domainSegments.length > 0 &&
        Cookies.get(TEMP_COOKIE_NAME) !== 'ok'
    )

    Cookies.remove(TEMP_COOKIE_NAME, options)

    // We want to make sure that on prod the news-mono has access
    // to remove the cart too.
    if (domain.startsWith('.')) {
        domain = domain.substring(1)
    }

    return domain
}
