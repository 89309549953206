import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { fonts } from '../..'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledTag = styled(Link)(
    {
        display: 'inline-block',
        textDecoration: 'none',
        transition: 'background 250ms ease, transform 150ms ease',
        position: 'relative',

        '&:hover, &:focus': {
            WebkitTapHighlightColor: 'transparent',
        },

        '&:focus': {
            outline: 'none',
        },
    },
    (props) => ({
        background: themedValue(props.theme, {
            thewest: undefined,
            perthnow: colors.perthnow.greyMarble,
            sevennews: colors.sevennews.seashell,
            fallback: undefined,
        }),
        color: themedValue(props.theme, {
            thewest: colors.thewest.greyElephant,
            perthnow: colors.black,
            sevennews: colors.sevennews.charade,
            thenightly: props.theme.colors.text.secondary,
            fallback: undefined,
        }),
        fontFamily: props.theme.fonts.sansSerif,
        fontSize: themedValue(props.theme, {
            perthnow: calcRem(13),
            thewest: calcRem(14),
            sevennews: calcRem(12),
            thenightly: calcRem(16),
            fallback: undefined,
        }),
        fontWeight: themedValue(props.theme, {
            perthnow: 700,
            thewest: 400,
            sevennews: 500,
            thenightly: 400,
            fallback: undefined,
        }),
        lineHeight: themedValue(props.theme, {
            perthnow: 1,
            thewest: 1.2,
            sevennews: undefined,
            fallback: undefined,
        }),
        margin: themedValue(props.theme, {
            perthnow: calcRem(0, 9, 9, 0),
            // prettier-ignore
            sevennews: `0 ${calcRem(metrics.globalMargin / 2)} ${calcRem(metrics.globalMargin / 2)} 0`,
            thewest: undefined,
            fallback: undefined,
        }),
        padding: themedValue(props.theme, {
            perthnow: calcRem(1.5, 9),
            thewest: undefined,
            sevennews: calcRem(5, 15),
            fallback: undefined,
        }),
    }),

    ({ theme }) =>
        theme.kind === 'sevennews' && {
            borderRadius: calcRem(18),
            fontFamily: fonts.sevennews.sansSerif,

            '&:hover': {
                color: colors.white,
                background: themedValue(theme, {
                    sevennews: (section) => section.tag.primaryColor,
                    fallback: undefined,
                }),
            },

            '&:focus': {
                color: colors.white,
                background: themedValue(theme, {
                    sevennews: (section) => section.tag.primaryColor,
                    fallback: undefined,
                }),
                textDecoration: 'underline',
            },

            '&:active': {
                color: colors.sevennews.stormGrey,
            },
        },

    ({ theme }) =>
        theme.kind === 'thewest' && {
            '&:hover': {
                color: theme.sectionValues.primaryColor,
            },

            '&:focus': {
                color: theme.sectionValues.primaryColor,
                textDecoration: 'underline',
            },

            '&:active': {
                color: colors.thewest.greyGorilla,
            },

            paddingRight: calcRem(10),
            paddingLeft: calcRem(10),
            transform: `translateX(-10px)`,

            '&:not(:first-child)': {
                '::before': {
                    content: `''`,
                    width: 1,
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    background: colors.thewest.greyQuokka,
                },
            },
        },

    ({ theme }) =>
        theme.kind === 'perthnow' && {
            border: '1px solid transparent',

            '&:hover': {
                borderRadius: calcRem(2),
                background: colors.perthnow.greyMoonstone,
                color: colors.white,
            },

            '&:focus': {
                background: colors.white,
                border: `1px solid ${colors.black}`,
                color: colors.black,
            },

            '&:active': {
                background: colors.perthnow.greyMoonstone,
                color: colors.white,
                transform: 'none',
            },
        },
    ({ theme }) =>
        theme.kind === 'thenightly' && {
            border: '1px solid transparent',
            textDecoration: 'underline',
            textTransform: 'lowercase',

            '&:hover': {
                textDecoration: 'none',
            },

            '&:focus': {
                textDecoration: 'none',
            },

            '&:active': {
                textDecoration: 'none',
            },
        },
)
