import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    ContentSeparator,
    ContentSeparatorProps,
} from './ContentSeparator.styled'

export const ContentSeparatorRegistration = createRegisterableComponent(
    'content-separator',
    (props: ContentSeparatorProps, _services) => (
        <ContentSeparator
            verticalBottomSpacing={props.verticalBottomSpacing}
            verticalTopSpacing={props.verticalTopSpacing}
        />
    ),
)
