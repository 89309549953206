import { ConfigurationContext } from '@news-mono/web-common'
import {
    EventPostsListingV4ResponseWithoutDeletedDTO,
    EventPostsListingPageV4ResponseDTO,
    EventPostByIdListingPageV4ResponseDTO,
} from '@west-australian-newspapers/publication-types'
import { useContext, useState, useCallback } from 'react'
import { liveEventDebug } from '.'

export const useFetchEventPosts = () => {
    const config = useContext(ConfigurationContext)
    const [isFetching, setIsFetching] = useState(false)

    const fetchEventPostPageById = useCallback(
        async (params: {
            publicationId: string
            postId: string
            pageSize: number
        }) => {
            setIsFetching(true)

            const queryParams = new URLSearchParams({
                page_size: `${params.pageSize}`,
            }).toString()

            liveEventDebug('Fetching page of posts given an id: %o', {
                queryParams,
            })

            const url = `${config.contentApi}/v4/publication/${params.publicationId}/entries-page/${params.postId}?${queryParams}`

            const response = await fetch(url, {})

            if (!response.ok) {
                setIsFetching(false)

                liveEventDebug('Error fetching posts: %o', { response })
                throw new Error('Unable to fetch posts')
            }

            const data: EventPostByIdListingPageV4ResponseDTO =
                await response.json()

            setIsFetching(false)

            liveEventDebug('Success fetching posts: %o', data)

            return data
        },
        [config.contentApi],
    )

    const fetchEventPostsPage = useCallback(
        async (params: {
            publicationId: string
            page: number
            pageSize: number
        }) => {
            setIsFetching(true)

            const queryParams = new URLSearchParams({
                page: `${params.page}`,
                page_size: `${params.pageSize}`,
            }).toString()

            liveEventDebug('Fetching page of posts: %o', { queryParams })

            const url = `${config.contentApi}/v4/publication/${params.publicationId}/entries-page?${queryParams}`

            const response = await fetch(url, {})

            if (!response.ok) {
                setIsFetching(false)

                liveEventDebug('Error fetching posts: %o', { response })
                throw new Error('Unable to fetch posts')
            }

            const data: EventPostsListingPageV4ResponseDTO =
                await response.json()

            setIsFetching(false)

            liveEventDebug('Success fetching posts: %o', data)

            return data
        },
        [config.contentApi],
    )

    const fetchEventPosts = useCallback(
        async (params: {
            publicationId: string
            reference?: string
            after?: number
            before?: number
        }) => {
            setIsFetching(true)

            const queryParams = new URLSearchParams({
                before: `${params.before ?? ''}`,
                after: `${params.after ?? ''}`,
                reference: params.reference ?? '',
            }).toString()

            liveEventDebug('Fetching posts: %o', { queryParams })

            const url = `${config.contentApi}/v4/publication/${params.publicationId}/entries?${queryParams}`

            const response = await fetch(url, {})

            if (!response.ok) {
                setIsFetching(false)

                liveEventDebug('Error fetching posts: %o', { response })
                throw new Error('Unable to fetch posts')
            }

            const data: EventPostsListingV4ResponseWithoutDeletedDTO =
                await response.json()

            setIsFetching(false)

            liveEventDebug('Success fetching posts: %o', data)

            return data
        },
        [config.contentApi],
    )

    return {
        fetchEventPostPageById,
        fetchEventPostsPage,
        fetchEventPosts,
        isFetching,
    }
}
