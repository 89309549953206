import React, { FC, PropsWithChildren, ReactElement } from 'react'

/** A helper component that can conditionally wrap another component with a provided wrapper component. */
export const ConditionalWrapper = ({
    condition,
    Wrapper,
    children,
}: PropsWithChildren<{
    condition: boolean
    Wrapper: FC
}>) => (condition ? <Wrapper>{children}</Wrapper> : <>{children}</>)

export const ConditionalOrElseWrapper = ({
    condition,
    Wrapper,
    OrElseWrapper,
    children,
}: PropsWithChildren<{
    condition: boolean
    Wrapper: FC
    OrElseWrapper: FC
}>) =>
    condition ? (
        <Wrapper>{children}</Wrapper>
    ) : (
        <OrElseWrapper>{children}</OrElseWrapper>
    )
