import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { TextItem } from '../TextItem/TextItem'
import { fonts } from '../../__styling'

export const styledListClassName = 'List'

const StyledBaseList = styled('ol')(
    { ...breakWords, clear: 'both' },
    ({ theme }) => ({
        margin: themedValue(theme, {
            fallback: `0 0 ${calcRem(metrics.thewest.textMargin)} 0`,
            perthnow: calcRem(
                0,
                0,
                metrics.perthnow.textMargin,
                metrics.perthnow.margins.md,
            ),
        }),
        padding: themedValue(theme, {
            fallback: calcRem(0, 32),
            perthnow: calcRem(0, 16),
        }),
    }),
)

export const StyledOrderedList = withClass(styledListClassName)(StyledBaseList)
StyledOrderedList.displayName = 'StyledOrderedList'

export const StyledUnorderedList = withClass(styledListClassName)(
    StyledBaseList.withComponent('ul'),
)
StyledUnorderedList.displayName = 'StyledUnorderedList'

export const StyledListItem = styled('li')(({ theme }) => ({
    fontWeight: themedValue(theme, {
        sevennews: 300,
        fallback: 400,
    }),
    margin: themedValue(theme, {
        fallback: calcRem(0, 0, 15, 0),
        perthnow: calcRem(0, 0, metrics.perthnow.margins.sm, 0),
        sevennews: 0,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(18),
        thenightly: calcRem(20),
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        sevennews: calcRem(25),
        fallback: undefined,
    }),
    color: themedValue(theme, {
        thenightly: theme.colors.text.tertiary,
        fallback: undefined,
    }),
    fontFamily: theme.kind === 'thenightly' ? fonts.thenightly.body : undefined,
}))
StyledListItem.displayName = 'StyledListItem'

export const StyledTextListItem = StyledListItem.withComponent(TextItem)
