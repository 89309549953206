import { Profile } from '@news-mono/web-common'

/**
 * Returns array of authors in same order as it written in CUE byline override
 * @param authors
 * @param byline
 * @returns
 */

export function reorderAuthors(profiles: Profile[], byline?: string) {
    const byLineArray = byline && byline.split(/\s*(,|and)\s+/)

    if (byLineArray && byLineArray.length > 0) {
        const sortedProfiles: Profile[] = []
        byLineArray.map((author) => {
            const prof = profiles.find((profile) => profile.name === author)
            if (prof) {
                sortedProfiles.push(prof)
            }
        })
        return sortedProfiles
    }
    return profiles
}
