import styled from '@emotion/styled'

/**
 * A function that returns a styled headline element of a specific level.
 */
export const Headline = styled.h3({
    width: '100%',
    // Remove default margins on h components.
    margin: 0,
})
