import React, { useContext, useState } from 'react'
import { OptaAflApiContext } from '../OptaAflApiProvider'
import { CardData, TimelineCard } from '../TimelineCard/TimelineCard'
import { teamBrandData } from './helpers/teamBrandData'
import {
    StyledMatchTimelineInnerContainer,
    StyledMatchTimelineLabelFour,
    StyledMatchTimelineLabelOne,
    StyledMatchTimelineLabelThree,
    StyledMatchTimelineLabelTwo,
    StyledMatchTimelineTeamLogo,
    StyledMatchTimelineTeamLogoContainer,
    StyledMatchTimelineWidgetContainer,
    StyledMobileQuarterScoreLabel,
    StyledMobileQuarterTotalScoreLabel,
    StyledQuarterScoreContainer,
    StyledQuarterScoreInnerContainer,
    StyledQuarterScoreLabel,
    StyledTimelineChartContainer,
    StyledTimelineTooltipContainer,
    StyledWidgetErrorContainer,
} from './MatchTimelineWidget.styled'
import { WormChart } from './WormChart'

// each quarter on chart is 1000 xAxis units, total seconds in a quarter = maxSecondsInQuarter == ~ 1800 (30 minutes), so (secondsInQuarter/maxSecondsInQuarter) * 1000 == x position for that quarter
const getXAxisPosition = (
    quarter: number,
    secondsInQuarter: number,
    lastScoreTimeInSeconds = 1800,
) => {
    // if last point scored is after 30 minutes, use that as max value for the quarter length else default to 1800 seconds
    const maxSecondsInQuarter =
        lastScoreTimeInSeconds < 1800 ? 1800 : lastScoreTimeInSeconds + 10
    return (
        1000 * (quarter - 1) + (secondsInQuarter / maxSecondsInQuarter) * 1000
    )
}

const getLastScoreTimeInPeriodData = (scoreApiData: any[]) => {
    return Math.max(
        ...scoreApiData.map((scoreDataPoint) =>
            parseInt(scoreDataPoint['@attributes'].periodSeconds),
        ),
    )
}

export const secondsToTime = (seconds: number) => {
    const m = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, '0'),
        s = Math.floor(seconds % 60)
            .toString()
            .padStart(2, '0')

    return m + ':' + s
}

const processScoreFlowToPointData = (
    scoreData: any[],
    playerData: any[],
    homeTeamId: string,
    hasMatchEnded: boolean,
) => {
    // if home team scores add to counter, if away team scores substract from counter
    let scoreCounter = 0
    const dataPoints = scoreData.map((currentDataPoint, index) => {
        // calculate y axis position
        if (currentDataPoint['@attributes'].squadId === homeTeamId) {
            scoreCounter += parseInt(
                currentDataPoint['@attributes'].scorepoints,
            )
        } else {
            scoreCounter -= parseInt(
                currentDataPoint['@attributes'].scorepoints,
            )
        }
        // calculate x axis position
        const xPosition = getXAxisPosition(
            parseInt(currentDataPoint['@attributes'].period),
            parseInt(currentDataPoint['@attributes'].periodSeconds),
            getLastScoreTimeInPeriodData(
                scoreData.filter(
                    (scoreDataPoint) =>
                        scoreDataPoint['@attributes'].period ===
                        currentDataPoint['@attributes'].period,
                ),
            ),
        )

        const timeInQuarter = secondsToTime(
            parseInt(currentDataPoint['@attributes'].periodSeconds),
        )

        const playerWhoScored = playerData.find(
            (playerEl: any) =>
                playerEl['@attributes'].playerId ===
                currentDataPoint['@attributes'].playerId,
        )

        const scoreEventsUpUntilAndIncludingCurrent = scoreData.slice(
            0,
            index + 1,
        )
        const totalPlayerGoals = scoreEventsUpUntilAndIncludingCurrent.filter(
            (historicDataPoint) =>
                historicDataPoint['@attributes'].playerId ===
                    currentDataPoint['@attributes'].playerId &&
                historicDataPoint['@attributes'].scoreName === 'goal',
        ).length
        const totalPlayerBehinds = scoreEventsUpUntilAndIncludingCurrent.filter(
            (historicDataPoint) =>
                historicDataPoint['@attributes'].playerId ===
                    currentDataPoint['@attributes'].playerId &&
                historicDataPoint['@attributes'].scoreName === 'behind',
        ).length

        return {
            x: xPosition,
            y: scoreCounter,
            type: currentDataPoint['@attributes'].scoreName,
            quarter: 'Q' + currentDataPoint['@attributes'].period,
            playerFirstname: playerWhoScored
                ? playerWhoScored['@attributes'].playerFirstname
                : '',
            playerLastname: playerWhoScored
                ? playerWhoScored['@attributes'].playerSurname
                : '',
            timeInQuarter,
            playerGoalsScored: totalPlayerGoals,
            playerBehindScored: totalPlayerBehinds,
            teamId: currentDataPoint['@attributes'].squadId,
        }
    })

    const firstElement = {
        x: -1000,
        y: 0,
        type: '',
        quarter: '',
        playerFirstname: '',
        playerLastname: '',
        timeInQuarter: '',
        playerGoalsScored: 0,
        playerBehindScored: 0,
        teamId: '',
    }
    const result = [firstElement].concat(dataPoints)
    if (hasMatchEnded) {
        const lastElement = {
            x: 5000,
            y: 0,
            type: '',
            quarter: '',
            playerFirstname: '',
            playerLastname: '',
            timeInQuarter: '',
            playerGoalsScored: 0,
            playerBehindScored: 0,
            teamId: '',
        }
        return result.concat([lastElement])
    }
    return result
}

export interface DataPointData extends CardData {
    x: number
    y: number
}

const getEmptyQuarterScoreData = (period: string) => {
    return {
        period,
        awayGoalsQtr: '0',
        awayBehindsQtr: '0',
        awayScoreQtr: '0',
        homeGoalsQtr: '0',
        homeBehindsQtr: '0',
        homeScoreQtr: '0',
    }
}

/**
 * Pads array of QuarterScoreData objects with a default QuarterScoreData object for every quarter missing from API data
 * @param quarterData qtr score data from API
 * @returns
 */
const populateMissingQuarterData = (quarterData: QuarterScoreData[]) => {
    for (let i = quarterData.length + 1; i < 5; i++) {
        quarterData.push(getEmptyQuarterScoreData(i.toString()))
    }
    return quarterData
}

export interface QuarterScoreData {
    period: string
    awayGoalsQtr: string
    awayBehindsQtr: string
    awayScoreQtr: string
    homeGoalsQtr: string
    homeBehindsQtr: string
    homeScoreQtr: string
}

/**
 * Makes sure scoreFlow is an array as API can return empty string, array or object
 * @param scoreFlow API scoreFlow data
 * @returns
 */
export const cleanUpScoreFlowData = (scoreFlow: any) => {
    return scoreFlow === ''
        ? []
        : Array.isArray(scoreFlow.score)
        ? scoreFlow.score
        : [scoreFlow.score]
}

/**
 * Makes sure qtr is an array as API can return empty string, array or object
 * @param quarterScoreData API qtr score data
 * @returns
 */
const cleanUpQuarterScoreData = (matchScoreData: any) => {
    let quarterData: QuarterScoreData[] =
        matchScoreData === ''
            ? []
            : Array.isArray(matchScoreData.qtr)
            ? matchScoreData.qtr.map(
                  (quarterEl: any) => quarterEl['@attributes'],
              )
            : [matchScoreData.qtr].map(
                  (quarterEl: any) => quarterEl['@attributes'],
              )
    if (quarterData) {
        if (quarterData.length < 4) {
            quarterData = populateMissingQuarterData(quarterData)
        }
        quarterData.sort(
            (a: QuarterScoreData, b: QuarterScoreData) =>
                parseInt(a.period) - parseInt(b.period),
        )
    }
    return quarterData
}

export interface MatchTimelineWidgetProps {
    matchId: string
    apiData: any
}

export const MatchTimelineWidget = ({
    matchId,
    apiData,
}: MatchTimelineWidgetProps) => {
    const [currentHoveredPointData, setCurrentHoveredPointData] = useState<
        DataPointData | undefined
    >()
    const { data, error, loading } = useContext(OptaAflApiContext)

    if (!data || loading) {
        return (
            <StyledWidgetErrorContainer>
                No data found
            </StyledWidgetErrorContainer>
        )
    }

    const scoreData = cleanUpScoreFlowData(
        data.rawApiData.report.aflMatchStats.scoreFlow,
    )
    const playerData = data.rawApiData.report.aflMatchStats.playerStats.player
    const homeTeamId = data.homeTeam.id
    const awayTeamId = data.awayTeam.id
    const scoreDataPoints = processScoreFlowToPointData(
        scoreData,
        playerData,
        homeTeamId,
        data.rawApiData.report.aflMatchStats.matchInfo['@attributes']
            .periodCompleted === '4',
    )

    const quarterData = cleanUpQuarterScoreData(
        data.rawApiData.report.aflMatchStats.matchScores,
    )

    const currentTeamBrandData = teamBrandData.find(
        (teamData) => teamData.teamId === currentHoveredPointData?.teamId,
    )
    const homeTeamBrandData = teamBrandData.find(
        (teamData) => teamData.teamId === homeTeamId,
    )
    const awayTeamBrandData = teamBrandData.find(
        (teamData) => teamData.teamId === awayTeamId,
    )

    return (
        <StyledMatchTimelineWidgetContainer>
            {homeTeamBrandData && awayTeamBrandData && (
                <StyledMatchTimelineTeamLogoContainer>
                    <StyledMatchTimelineTeamLogo src={homeTeamBrandData.logo} />
                    <StyledMatchTimelineTeamLogo src={awayTeamBrandData.logo} />
                </StyledMatchTimelineTeamLogoContainer>
            )}
            <StyledMatchTimelineInnerContainer>
                {quarterData && (
                    <StyledQuarterScoreContainer>
                        {quarterData.map(
                            (quarter: QuarterScoreData, i: number) => {
                                return (
                                    <StyledQuarterScoreInnerContainer key={i}>
                                        <StyledQuarterScoreLabel>
                                            {`${quarter.homeGoalsQtr}.${quarter.homeBehindsQtr} (${quarter.homeScoreQtr})`}
                                        </StyledQuarterScoreLabel>
                                        <StyledMobileQuarterTotalScoreLabel>{`${quarter.homeScoreQtr}`}</StyledMobileQuarterTotalScoreLabel>
                                        <StyledMobileQuarterScoreLabel>{`(${quarter.homeGoalsQtr}.${quarter.homeBehindsQtr})`}</StyledMobileQuarterScoreLabel>
                                    </StyledQuarterScoreInnerContainer>
                                )
                            },
                        )}
                    </StyledQuarterScoreContainer>
                )}
                <StyledTimelineChartContainer>
                    <WormChart
                        dataPoints={scoreDataPoints}
                        handleUpdateCurrentHoveredPoint={(
                            dataPoint: DataPointData | undefined,
                        ) => {
                            setCurrentHoveredPointData(dataPoint)
                        }}
                        currentHoveredPointData={currentHoveredPointData}
                    />
                    <StyledMatchTimelineLabelOne>
                        Q1
                    </StyledMatchTimelineLabelOne>
                    <StyledMatchTimelineLabelTwo>
                        Q2
                    </StyledMatchTimelineLabelTwo>
                    <StyledMatchTimelineLabelThree>
                        Q3
                    </StyledMatchTimelineLabelThree>
                    <StyledMatchTimelineLabelFour>
                        Q4
                    </StyledMatchTimelineLabelFour>
                </StyledTimelineChartContainer>

                {quarterData && (
                    <StyledQuarterScoreContainer>
                        {quarterData.map(
                            (quarter: QuarterScoreData, i: number) => {
                                return (
                                    <StyledQuarterScoreInnerContainer key={i}>
                                        <StyledQuarterScoreLabel>
                                            {`${quarter.awayGoalsQtr}.${quarter.awayBehindsQtr} (${quarter.awayScoreQtr})`}
                                        </StyledQuarterScoreLabel>
                                        <StyledMobileQuarterTotalScoreLabel>{`${quarter.awayScoreQtr}`}</StyledMobileQuarterTotalScoreLabel>
                                        <StyledMobileQuarterScoreLabel>{`(${quarter.awayGoalsQtr}.${quarter.awayBehindsQtr})`}</StyledMobileQuarterScoreLabel>
                                    </StyledQuarterScoreInnerContainer>
                                )
                            },
                        )}
                    </StyledQuarterScoreContainer>
                )}
            </StyledMatchTimelineInnerContainer>
            {currentHoveredPointData && currentTeamBrandData && (
                <StyledTimelineTooltipContainer
                    leftPosition={currentHoveredPointData.x - 60}
                    // fix for disabling mobile tooltip on tap
                    onTouchEnd={(e) => {
                        e.preventDefault()
                        if (currentHoveredPointData) {
                            e.currentTarget.parentElement
                                ?.getElementsByTagName('canvas')[0]
                                .dispatchEvent(new PointerEvent('mouseout'))
                        }
                    }}
                >
                    <TimelineCard
                        cardData={currentHoveredPointData}
                        teamBrandData={currentTeamBrandData}
                    />
                </StyledTimelineTooltipContainer>
            )}
            {homeTeamBrandData && awayTeamBrandData && (
                <StyledMatchTimelineTeamLogoContainer>
                    <StyledMatchTimelineTeamLogo src={homeTeamBrandData.logo} />
                    <StyledMatchTimelineTeamLogo src={awayTeamBrandData.logo} />
                </StyledMatchTimelineTeamLogoContainer>
            )}
        </StyledMatchTimelineWidgetContainer>
    )
}
