import styled from '@emotion/styled'
import React from 'react'
import { breakpointMax } from '../../../../../__styling/style-functions'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'

const StyledRoadSafetyLogo = styled('img')({
    position: 'absolute',
    right: 0,
    top: calcRem(-45),
    height: calcRem(40),
    maxWidth: '100%',

    [breakpointMax('sm')]: {
        height: 'auto',
        position: 'relative',
        top: 0,
    },
})

export const RoadSafetyLogo: React.FC<{}> = () => (
    <StyledRoadSafetyLogo
        src={require('./rsc-logo-with-state-govt-crest_nah-not-worth-it.png')}
        alt="Live scores brought to you by The Road Safety Commission"
        height="34"
    />
)
