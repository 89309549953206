import React from 'react'
import {
    StyledAFLDraftHeader,
    StyledAFLDraftHeading,
    StyledAFLDraftText,
    StyledAFLDraftTextWrapper,
} from './AFLDraftHeader.styled'

export interface AFLDraftHeaderProps {
    header: string
    body: string
}

export const AFLDraftHeader: React.FC<AFLDraftHeaderProps> = ({
    header,
    body,
}) => {
    return (
        <StyledAFLDraftHeader>
            <StyledAFLDraftTextWrapper>
                <StyledAFLDraftHeading>{header}</StyledAFLDraftHeading>
                <StyledAFLDraftText>{body}</StyledAFLDraftText>
            </StyledAFLDraftTextWrapper>
        </StyledAFLDraftHeader>
    )
}
