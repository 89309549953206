import { Profile } from '@news-mono/web-common'
import React, { Fragment } from 'react'
import { ProfileEmailButton } from '../../buttons/ProfileEmailButton/ProfileEmailButton'
import { ProfileTwitterButton } from '../../buttons/ProfileTwitterButton/ProfileTwitterButton'
import {
    StyledAuthor,
    StyledBioBlock,
    StyledBioText,
    StyledButtons,
    StyledFacebookIcon,
    StyledFlexRow,
    StyledImage,
    StyledImageContainer,
    StyledInstagramIcon,
    StyledName,
    StyledPosition,
    StyledSocialLink,
    StyledText,
    StyledTwitterIcon,
    StyledDivider,
    StyledHeader,
} from '../../content/BioBlock/BioBlock.styled'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'
import { useProduct } from '../../__product/useProduct'
import { ThemedRender } from '../../__styling/themed-render'

export interface BioBlockProps {
    profile?: Profile
    url: string
    title: string
}
interface EmailProps {
    text?: string
}

export const BioBlock: React.FC<BioBlockProps> = ({ profile, url, title }) => {
    const product = useProduct()
    if (
        product === 'thewest' &&
        (!profile || !profile.mainPhoto || !profile.biography)
    ) {
        return null
    } else if (!profile) {
        return null
    }

    const { name, email, position, mainPhoto, biography, twitter } = profile

    const MainPhoto = () =>
        mainPhoto ? (
            <StyledImageContainer>
                <StyledImage src={mainPhoto} alt={`Photo of ${name}`} />
            </StyledImageContainer>
        ) : null

    const Email = ({ text }: EmailProps) =>
        email ? (
            <FeatureToggle
                feature="email-author"
                on={() => (
                    <ProfileEmailButton
                        text={text || name}
                        email={email || ''}
                        url={url}
                        title={title}
                    />
                )}
            />
        ) : null

    const Twitter = () =>
        twitter ? <ProfileTwitterButton twitter={twitter} /> : null

    const networks: Array<keyof Profile> = ['twitter', 'facebook', 'instagram']

    const getSocialIcon = (network: string) => {
        switch (network) {
            case 'twitter':
                return <StyledTwitterIcon />
            case 'facebook':
                return <StyledFacebookIcon />
            case 'instagram':
                return <StyledInstagramIcon />
            default:
                return null
        }
    }

    const SocialLinks = () => (
        <Fragment>
            {networks.map((network, index) =>
                profile[network] ? (
                    <StyledSocialLink
                        key={index}
                        openExternalInNewWindow
                        to={`http://${network}.com/${profile[network]}`}
                    >
                        {getSocialIcon(network)}@{profile[network]}
                    </StyledSocialLink>
                ) : null,
            )}
        </Fragment>
    )

    const hasSocial = () =>
        networks
            .map((network) => (profile[network] ? true : false))
            .includes(true)

    return (
        <ThemedRender
            thewest={() => (
                <StyledBioBlock>
                    <MainPhoto />
                    <StyledText>
                        <StyledAuthor>
                            <StyledName>{name}</StyledName>
                            <StyledPosition>{position}</StyledPosition>
                        </StyledAuthor>
                        <StyledBioText>{biography}</StyledBioText>
                        <Email />
                        <Twitter />
                    </StyledText>
                </StyledBioBlock>
            )}
            perthnow={() => (
                <StyledBioBlock>
                    <MainPhoto />
                    <StyledText>
                        <StyledAuthor>
                            <StyledName>{name}</StyledName>
                            <StyledFlexRow>
                                <StyledPosition>{position}</StyledPosition>
                                <StyledButtons>
                                    <Email />
                                    <Twitter />
                                </StyledButtons>
                            </StyledFlexRow>
                        </StyledAuthor>
                        <StyledBioText>{biography}</StyledBioText>
                    </StyledText>
                </StyledBioBlock>
            )}
            sevennews={() => (
                <StyledBioBlock>
                    <MainPhoto />
                    <StyledAuthor>
                        <StyledName>{name}</StyledName>
                        <StyledPosition>{position}</StyledPosition>
                        <SocialLinks />
                    </StyledAuthor>
                    <StyledText>
                        <StyledBioText hasSocial={hasSocial()}>
                            {biography}
                        </StyledBioText>
                    </StyledText>
                </StyledBioBlock>
            )}
            thenightly={() => (
                <StyledBioBlock>
                    <MainPhoto />
                    <StyledText>
                        <StyledAuthor>
                            <StyledName>{name}</StyledName>
                        </StyledAuthor>
                        <StyledBioText>{biography}</StyledBioText>
                        <Email text="Contact" />
                    </StyledText>
                    <StyledDivider />
                    <StyledHeader as={'h2'}>
                        Recent articles by {name}
                    </StyledHeader>
                </StyledBioBlock>
            )}
        />
    )
}
BioBlock.displayName = 'BioBlock'
