import { useLocation } from 'react-router'
import { useMemo } from 'react'

export const useQueryParams = () => {
    const { search, pathname } = useLocation()

    return {
        queryParams: useMemo(() => new URLSearchParams(search), [search]),
        pathname,
    }
}
