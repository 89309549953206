import { CSSObject } from '@emotion/css'
import {
    BreakpointKeys,
    Breakpoints,
    breakpoints,
} from '../../__styling/settings/breakpoints'
import { calcEm } from '../../__styling/style-functions/calc-em'

export const breakpoint = (
    point: keyof Breakpoints,
    includeMediaDecleration = true,
) =>
    `${includeMediaDecleration ? '@media ' : ''}(min-width: ${calcEm(
        breakpoints[point],
    )})`

export const breakpointMax = (
    point: keyof Breakpoints,
    includeMediaDecleration = true,
) =>
    `${includeMediaDecleration ? '@media ' : ''}(max-width: ${calcEm(
        breakpoints[point] - 1,
    )})`

export const breakpointBetween = (
    startingPoint: keyof Breakpoints,
    endingPoint: keyof Breakpoints,
    includeMediaDecleration = true,
) =>
    `${includeMediaDecleration ? '@media ' : ''}(min-width: ${calcEm(
        breakpoints[startingPoint],
    )}) and (max-width: ${calcEm(breakpoints[endingPoint] - 1)})`

export function getSmallestBreakpointValue(
    breakpointValues?:
        | Partial<Record<BreakpointKeys | 'initial', any>>
        | string,
): string | undefined {
    if (!breakpointValues || typeof breakpointValues === 'string') {
        return breakpointValues
    }

    const breakpointKeys: Array<BreakpointKeys | 'initial'> = [
        'initial',
        'xxs',
        'xs',
        'xl',
        'sm',
        'md',
        'lg',
        'xxl',
    ]

    const smallestBreakpoint = breakpointKeys.find(
        (size) => breakpointValues[size],
    )
    return smallestBreakpoint ? breakpointValues[smallestBreakpoint] : undefined
}

/**
 * Get a diff between two style objects
 * Example use: Only apply what actually changed for a breakpoint
 */
export const styleDiff = (
    styles: CSSObject,
    baseStyles?: CSSObject,
): CSSObject => {
    if (!baseStyles) {
        return styles
    }

    const result: CSSObject = {}

    Object.keys(styles).forEach((key) => {
        const val = styles[key]
        const baseVal = baseStyles[key]
        // TODO: fix this assertion
        // If an object is passed as a property, we assume it's going to be another style property
        if (typeof val === 'object' && typeof baseVal === 'object') {
            result[key] = styleDiff(val as CSSObject, baseVal as CSSObject)
        } else if (val !== baseVal) {
            result[key] = val
        }
    })

    return result
}

/**
 * Asserts a string is a valid breakpoint.
 */
export const isBreakpoint = (
    maybeBreakpoint: string,
): maybeBreakpoint is BreakpointKeys => maybeBreakpoint in breakpoints
