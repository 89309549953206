import * as React from 'react'

export interface IconArrowCircleProps {
    className?: string
    width?: number
    height?: number
    fillColor?: string
}

export const IconArrowCircle: React.FC<IconArrowCircleProps> = ({
    className,
    width = 26,
    height = 26,
    fillColor = '#fff',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.0044 18.0165L11.6388 16.6221L14.8039 13.4201L5.50775 13.4066L5.50968 11.4392L14.7698 11.4527L11.6581 8.27499L13.03 6.88707L18.4921 12.4648L13.0044 18.0165Z"
                fill={fillColor}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22.6368C17.6249 22.6368 22.1848 18.0769 22.1848 12.452C22.1848 6.82705 17.6249 2.26714 12 2.26714C6.37508 2.26714 1.81518 6.82705 1.81518 12.452C1.81518 18.0769 6.37508 22.6368 12 22.6368ZM12 24.452C18.6274 24.452 24 19.0794 24 12.452C24 5.82455 18.6274 0.451969 12 0.451969C5.37258 0.451969 0 5.82455 0 12.452C0 19.0794 5.37258 24.452 12 24.452Z"
                fill={fillColor}
            />
        </svg>
    )
}
