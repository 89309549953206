import { isServerEnvironment, isTestEnvironment } from '@news-mono/web-common'

let adblockEnabledResult: boolean

function checkAdblockEnabled() {
    if (isServerEnvironment()) {
        return true
    }

    // we need to recognize the test environment because JSDOM returns
    // the wrong values for offsetParent and the dimensions from 9.11.0
    if (isTestEnvironment()) {
        return false
    }

    const bait: HTMLDivElement = document.createElement('div')

    bait.setAttribute(
        'class',
        'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links',
    )
    bait.setAttribute(
        'style',
        'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;',
    )

    const deployedBait = window.document.body.appendChild(bait)
    const adBlockPlusEnabled = window.document.body.getAttribute('abp') !== null

    if (
        adBlockPlusEnabled ||
        deployedBait.offsetParent === null ||
        deployedBait.offsetHeight === 0 ||
        deployedBait.offsetLeft === 0 ||
        deployedBait.offsetTop === 0 ||
        deployedBait.offsetWidth === 0 ||
        deployedBait.clientHeight === 0 ||
        deployedBait.clientWidth === 0
    ) {
        return true
    }
    return false
}

export function adBlockEnabled() {
    if (adblockEnabledResult === undefined) {
        adblockEnabledResult = checkAdblockEnabled()
    }
    return adblockEnabledResult
}
