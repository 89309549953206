import {
    CommentData,
    CommentEvent,
    DataLayerEventName,
} from '@news-mono/web-common'

/**
 * For a full list of possible events see: https://github.com/coralproject/talk/blob/master/CLIENT_EVENTS.md
 * Although not stated there, the ready event is also sent through this handler with no
 * event data attached, which is why the event data is optional, it's also partial just
 * to make sure it doesn't throw an error in case the event data object isn't correct.
 */
export interface CoralEventStream {
    onAny: (
        handleEvent: (eventName: string, eventData?: Partial<any>) => void,
    ) => void
}

const originator = 'Coral Comments'

export function handleCoralEvents(
    coralEventStream: CoralEventStream,
    onEvent: (event: CommentEvent) => void,
) {
    /**
     * Coral doesn't have page numbers for comments, just a button to load more,
     * so we're manually tracking how many times they load more and converting
     * that to an assumed page number.
     */
    let pageNumber = 1

    const baseEventData: CommentData = {
        'comment.status': 'available',
        'comment.viewableUserType': 'loggedin',
        'comment.participationUserType': 'loggedin',
        'comments.featureVisible': true,
        'comments.componentName': 'launch',
    }

    coralEventStream.onAny((eventName, eventData) => {
        switch (eventName) {
            case 'ready':
                onEvent({
                    type: DataLayerEventName.commentsAvailable,
                    originator,
                    payload: { ...baseEventData },
                })
                break
            case 'createComment.error':
            case 'createCommentReply.error':
            case 'editComment.error':
                onEvent({
                    type: DataLayerEventName.commentError,
                    originator,
                    payload: {
                        'comment.errorname': eventData?.error?.code,
                        ...baseEventData,
                    },
                })
                break
            case 'createComment.success':
                onEvent({
                    type: DataLayerEventName.commentPost,
                    originator,
                    payload: {
                        'comment.commentID': eventData?.success?.id,
                        ...baseEventData,
                    },
                })
                break
            case 'createCommentReply.success':
                onEvent({
                    type: DataLayerEventName.commentReplied,
                    originator,
                    payload: {
                        'comment.commentID': eventData?.parentID,
                        ...baseEventData,
                    },
                })
                break
            case 'createCommentReaction.success':
                onEvent({
                    type: DataLayerEventName.commentReact,
                    originator,
                    payload: {
                        'comment.commentID': eventData?.commentID,
                        ...baseEventData,
                    },
                })
                break
            case 'editComment.success':
                onEvent({
                    type: DataLayerEventName.commentEdit,
                    originator,
                    payload: {
                        'comment.commentID': eventData?.commentID,
                        ...baseEventData,
                    },
                })
                break
            case 'reportComment.success':
                onEvent({
                    type: DataLayerEventName.commentReport,
                    originator,
                    payload: {
                        'comment.commentID': eventData?.commentID,
                        'comment.reason': eventData?.reason,
                        ...baseEventData,
                    },
                })
                break
            case 'ignoreUser.success':
                onEvent({
                    type: DataLayerEventName.commentIgnore,
                    originator,
                    payload: {
                        'comment.userID': eventData?.userID,
                        ...baseEventData,
                    },
                })
                break
            case 'loadMoreAllComments.success':
                pageNumber++
                onEvent({
                    type: DataLayerEventName.commentLoadMore,
                    originator,
                    payload: {
                        'comment.storyID': eventData?.storyID,
                        'comment.pageno': pageNumber,
                        ...baseEventData,
                    },
                })
                break
        }
    })
}
