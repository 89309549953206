import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { WeAreForYouLogo } from '../../logos/WeAreForYouLogo/WeAreForYouLogo'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const cardBackgroundEntitled = require('./images/rectangle-mobile-logged-in.svg')
const cardBackgroundEntitledTablet = require('./images/rectangle-tblt-logged-in.svg')
const cardBackgroundEntitledDesktop = require('./images/rectangle-dsktp-logged-in.png')

const cardBackgroundDesktop = require('./images/rectangle-dsktp-logged-out.png')

const StyledContainer = styled('div')({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    padding: calcRem(metrics.thewest.margins.md),
})

export const StyledEntitledContainer = styled(StyledContainer)({
    backgroundImage: `url(${cardBackgroundEntitled})`,
    display: 'block',
    minHeight: 170,
    color: colors.black,
    position: 'relative',
    backgroundPosition: '100% 50%',

    [breakpoint('xxs')]: {
        minHeight: 130,
    },

    [breakpoint('xs')]: {
        minHeight: 'auto',
    },

    [breakpoint('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'normal',
        backgroundColor: colors.white,
        backgroundImage: `url(${cardBackgroundEntitledTablet})`,
        backgroundSize: 'auto',
        backgroundPosition: 'bottom right',
    },

    [breakpoint('md')]: {
        display: 'block',
        height: '100%',
        backgroundColor: 'transparent',
        backgroundImage: `url(${cardBackgroundEntitledDesktop})`,
        backgroundSize: '100%',
        backgroundPosition: 'top center',
    },
})

export const StyledOpportunityContainer = styled(StyledContainer)({
    backgroundColor: colors.thewest.westblue,
    color: colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoint('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'normal',
        minHeight: 'auto',
    },

    [breakpoint('md')]: {
        backgroundSize: '100%',
        backgroundColor: 'transparent',
        backgroundImage: `url(${cardBackgroundDesktop})`,
        display: 'block',
        paddingBottom: 0,
    },
})

const StyledCardText = styled('p')({
    fontFamily: fonts.thewest.serif,
    fontWeight: 'bold',
    fontSize: calcRem(22),
    lineHeight: 1.2,
    margin: 0,
})

export const StyledEntitledText = styled(StyledCardText)({
    marginTop: calcRem(metrics.thewest.margins.xxl),
    marginBottom: calcRem(metrics.thewest.margins.md),

    [breakpoint('sm')]: {
        margin: 0,
        marginLeft: calcRem(metrics.thewest.margins.md),
    },

    [breakpoint('md')]: {
        marginLeft: 0,
    },
})

export const StyledOpportunityText = styled(StyledCardText)({
    flexBasis: '100%',
    flexGrow: 1,
    marginBottom: calcRem(metrics.thewest.margins.sm),
    textAlign: 'center',

    [breakpoint('xs')]: {
        fontSize: calcRem(24),
        lineHeight: calcRem(32),
    },

    [breakpoint('sm')]: {
        textAlign: 'left',
        flexBasis: '0%',
        marginBottom: 0,
    },

    [breakpoint('md')]: {
        flexBasis: '0%',
        marginBottom: calcRem(metrics.thewest.margins.sm),
        fontSize: calcRem(20),
        lineHeight: 1.2,
        flexGrow: 0,
    },

    '@media screen and (min-width: 1120px)': {
        fontSize: calcRem(24),
    },
})

export const StyledTextEmphasis = styled('em')({
    fontStyle: 'normal',
    borderBottom: `3px solid ${colors.thewest.westblue}`,
})

export const StyledSubscribeButton = styled(WebLink)({
    backgroundColor: colors.white,
    border: `1px solid ${colors.white}`,
    padding: calcRem(metrics.thewest.margins.sm, metrics.thewest.margins.md),
    borderRadius: calcRem(2),
    color: colors.thewest.westblue,
    fontFamily: fonts.thewest.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(14),
    textDecoration: 'none',
    textTransform: 'uppercase',

    [breakpoint('sm')]: {
        marginLeft: 'auto',
    },

    [breakpoint('md')]: {
        marginLeft: 0,
        marginBottom: calcRem(metrics.thewest.margins.xs),
        display: 'inline-block',
    },

    '&:hover, &:focus': {
        backgroundImage: 'none',
        outline: 'none',
    },

    '&:hover': {
        backgroundColor: 'transparent',
        color: colors.white,
    },

    '&:focus': {
        backgroundColor: colors.thewest.greyKoala,
        textDecoration: 'underline',
    },
})

export const StyledWestMark = styled('div')({
    background: colors.thewest.westblue,
    display: 'inline-block',
    padding: calcRem(metrics.thewest.margins.sm),
    paddingBottom: calcRem(metrics.thewest.margins.xs),
    position: 'absolute',
    top: 0,
    left: metrics.thewest.margins.md,

    [breakpoint('sm')]: {
        position: 'relative',
        left: 0,
    },

    [breakpoint('md')]: {
        marginTop: calcRem(-metrics.thewest.margins.md),
        marginBottom: calcRem(metrics.thewest.margins.sm),
    },
})

export const StyledWeAreForYouLogo = styled(WeAreForYouLogo)({
    position: 'absolute',
    bottom: '12px',
    right: '12px',
    width: calcRem(120),
    height: calcRem(30),
})

export const StyledBundleImage = styled('picture')({
    display: 'none',
    width: calcRem(140),
    maxWidth: '100%',
    marginLeft: 'auto',

    img: {
        width: '100%',
    },

    [breakpoint('md')]: {
        display: 'block',
    },

    '@media screen and (min-width: 1120px)': {
        width: calcRem(220),
    },
})
