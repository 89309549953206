import {
    CollectionDataLoaded,
    ContentDataTypes,
    DataLoaderGlobalParams,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { Button } from '../../buttons/Button/Button'
import { StyledButtonContainer } from './LoadMore.styled'
import { LoadedData, useLoadMore } from './useLoadMore'

export interface LoadMoreRouteProps {
    loadMoreEnabled?: boolean
}

interface LoadMoreProps {
    dataDefinition: ContentDataTypes
    data: MaybeLoaded<CollectionDataLoaded>
    onLoadMore: (data: LoadedData) => void
    services: DataLoaderGlobalParams
    loadMoreEnabled: boolean
    render: (
        data: MaybeLoaded<CollectionDataLoaded>,
    ) => JSX.Element | JSX.Element[]
}

export const LoadMore = ({
    dataDefinition: initialDataDefinition,
    data: initialData,
    onLoadMore,
    services,
    loadMoreEnabled,
    render,
}: LoadMoreProps) => {
    const { loadMore, isLoading, data, dataDefinition } = useLoadMore(
        initialDataDefinition,
        initialData,
        services,
        onLoadMore,
    )

    function loadMoreButton() {
        if (
            loadMoreEnabled &&
            data.loaded &&
            data.result.loadMorePossible &&
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.paging
        ) {
            return (
                <StyledButtonContainer>
                    <Button
                        className={'gtm-load-more-button'}
                        disabled={isLoading}
                        onClick={() => loadMore(data)}
                    >
                        {isLoading ? 'Loading...' : 'Load More'}
                    </Button>
                </StyledButtonContainer>
            )
        }
        return null
    }

    return (
        <>
            {render(data)}
            {loadMoreButton()}
        </>
    )
}
