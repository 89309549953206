import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AflPlayerStatsWidget } from './AflPlayerStatsWidget'

export interface AflPlayerStatsWidgetProps {
    apiData: any
}

export const AflPlayerStatsWidgetRegistration = createRegisterableComponent(
    'afl-player-stats',
    (props: AflPlayerStatsWidgetProps) => {
        return <AflPlayerStatsWidget {...props} />
    },
)
