const sevenLogo = require('./assets/7news-red.png')
const sevenLogoReversed = require('./assets/7news-reversed.png')
const ttLogo = require('./assets/tt.png')
const aapLogo = require('./assets/aap.png')
const cnnLogo = require('./assets/cnn.png')
const nbcLogo = require('./assets/nbc.png')
const carsGuideLogo = require('./assets/carsguide.png')
const realestateComAuLogo = require('./assets/realestatecomau.png')
const whistleoutLogo = require('./assets/whistleout.png')
const sunriseLogo = require('./assets/sunrise.png')
const reviewsOrgLogo = require('./assets/reviewsorg.png')
const sevenSportLogo = require('./assets/7sport_Black.png')

// Masthead source logos
export function getSourceLogo(
    source: string,
    reversed = false,
): false | { src: string; width: number; height: number; maxWidth: number } {
    switch (source.toLowerCase()) {
        case '7 news perth':
        case '7news':
            return {
                src: reversed ? sevenLogoReversed : sevenLogo,
                width: 368,
                height: 150,
                maxWidth: 70,
            }

        case 'today tonight':
            return { src: ttLogo, width: 324, height: 64, maxWidth: 70 }

        case 'aap':
            return { src: aapLogo, width: 109, height: 40, maxWidth: 50 }

        case 'cnn':
            return { src: cnnLogo, width: 107, height: 50, maxWidth: 30 }

        case 'nbc':
            return { src: nbcLogo, width: 120, height: 33, maxWidth: 80 }

        case 'carsguide':
            return { src: carsGuideLogo, width: 712, height: 154, maxWidth: 70 }

        case 'realestate.com.au':
            return {
                src: realestateComAuLogo,
                maxWidth: 100,
                width: 652,
                height: 150,
            }

        case 'whistleout australia':
            return { src: whistleoutLogo, maxWidth: 70, width: 190, height: 36 }

        case 'reviews.org australia':
            return { src: reviewsOrgLogo, width: 351, height: 68, maxWidth: 70 }

        case 'sunrise':
            return { src: sunriseLogo, width: 382, height: 201, maxWidth: 70 }

        case '7sport':
            return { src: sevenSportLogo, width: 280, height: 80, maxWidth: 70 }

        default:
            return false
    }
}
