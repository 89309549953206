import {
    DataLayerEventName,
    MatchCenterEvents,
    WebLink,
} from '@news-mono/web-common'
import React from 'react'
import { TabTouchOdds } from '../afl-match-centre/ScoreBoard/types'
import { useProduct } from '../__product'
import {
    SponsorRowWrapper,
    StyledBettingOdd,
    StyledBettingOddWrapper,
    StyledSpnsorCopy,
    StyledSpnsorImage,
    StyledSpnsorLink,
    StyledSpnsorWrapper,
} from './ScoreBoard.styled'

const tabTouchLogo = require('./assets/TABtouch.png')
const sportsBetLogo = require('./assets/sportsBet.png')

export interface SponsorRowProps {
    odds: TabTouchOdds
    onEvent: (event: MatchCenterEvents) => void
}

const utm = new URLSearchParams({
    utm_campaign: 'pnmc', // PerthNow match centre
    utm_source: 'SWM',
    utm_medium: 'web',
})
const formatLink = (url: string) => `${url}&${utm}`
const formatCurrency = (value: number) => `$${value.toFixed(2)}`

export const SingleOddsComponent = ({
    value,
    link,
    onClick,
}: {
    value: number
    link: string
    onClick: () => void
}) => {
    return (
        <StyledBettingOddWrapper>
            <WebLink to={formatLink(link)} onClick={onClick}>
                <StyledBettingOdd>{formatCurrency(value)}</StyledBettingOdd>
            </WebLink>
        </StyledBettingOddWrapper>
    )
}

export const SponsorRow = ({ odds, onEvent }: SponsorRowProps) => {
    const product = useProduct()

    const onClick = (team?: string) => {
        onEvent({
            type: DataLayerEventName.oddsLinkPressed,
            originator: 'MatchCenter',
            payload: {
                type: team ? 'single' : 'logo',
                team,
            },
        })
    }

    return (
        <SponsorRowWrapper>
            <SingleOddsComponent
                value={odds.homeOdds}
                link={odds.homeBetLink}
                onClick={() => onClick(odds.home)}
            />
            <StyledSpnsorWrapper>
                <StyledSpnsorCopy>Presented by</StyledSpnsorCopy>
                <StyledSpnsorLink
                    to={formatLink(odds.eventLink)}
                    onClick={() => onClick()}
                >
                    <StyledSpnsorImage
                        src={
                            product === 'sevennews'
                                ? sportsBetLogo
                                : tabTouchLogo
                        }
                    />
                </StyledSpnsorLink>
            </StyledSpnsorWrapper>
            <SingleOddsComponent
                value={odds.awayOdds}
                link={odds.awayBetLink}
                onClick={() => onClick(odds.away)}
            />
        </SponsorRowWrapper>
    )
}
