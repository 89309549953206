import styled from '@emotion/styled'
import { calcRem } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

const { neutral } = tokens.thenightly.colors.palette

export const TopicFilterContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
})

export const TopicLinkWrapper = styled.nav({
    display: 'flex',
})

export const StyledLabel = styled.label(({ theme }) => ({
    color: neutral[100],
    fontSize: calcRem(20),
    fontWeight: 'bold',
    marginBottom: calcRem(8),
}))

export const TopicLinkList = styled.ul({
    // Reset ul styles.
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: calcRem(8),
    width: '100%',
})

export const TopicLinkListItem = styled.li({
    // Reset li styles
    display: 'flex',
    flexWrap: 'wrap',
})

export type ActiveState = 'active' | 'default'

export type TopicLinkProps = {
    state?: ActiveState
}

export const TopicLink = styled.div<TopicLinkProps>(
    ({ theme, state = 'default' }) => {
        return [
            [
                {
                    // reset anchor styles.
                    color: 'unset',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    width: '100%',
                    padding: calcRem(4, 12),
                    borderRadius: calcRem(40),
                    outlineStyle: 'solid',
                    outlineWidth: calcRem(1),
                    outlineOffset: calcRem(-1),
                    outlineColor: neutral[30],

                    ...(state === 'active' && {
                        backgroundColor: neutral[100],
                        outlineColor: neutral[100],
                        color: neutral[0],
                    }),
                },
            ],
        ]
    },
)
