import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { BreachWallContentType } from './helpers/BreachWallTypes'
import { BreachWallContentWrapper } from './components/BreachWallContent/BreachWallContentWrapper'
import { PuzzlesBreachWallWrapper } from './components/PuzzlesBreach/PuzzlesBreachWallWrapper'

export interface BreachScreenRegistrationProps {
    bannerMessage?: string
    breachCreativeName: string
    breachDeal: string
    breachType: BreachWallContentType
    title: string
    message?: string
    terms: string
    breachwall?: 'default' | 'puzzles'
    renditionType?: string
    enableSwgCampaign?: boolean
}

export interface PuzzlesBreachDealProps {
    bannerMessage?: string
    breachCreativeName?: string
    breachDeal: string
    breachType: BreachWallContentType
    title: string
    message?: string
    terms?: string
    enableSwgCampaign?: boolean
}

export const BreachScreenRegistration = createRegisterableComponent(
    'breachscreen',
    (props: BreachScreenRegistrationProps, services) => {
        if (props.breachwall === 'puzzles') {
            const { breachDeal, breachType, bannerMessage, title, message } =
                puzzlesBreachPackage(props)

            return (
                <PuzzlesBreachWallWrapper
                    suppliedDeal={breachDeal}
                    suppliedType={breachType}
                    suppliedCreative={props.breachCreativeName}
                    requiredAccess={'subscriber'}
                    location={services.location}
                    onEvent={services.onEvent}
                    bannerText={bannerMessage}
                    title={title}
                    message={message}
                    renditionType={props.renditionType}
                    enabledSwgCampaign={props.enableSwgCampaign}
                />
            )
        }

        return (
            <BreachWallContentWrapper
                suppliedDeal={props.breachDeal}
                suppliedType={props.breachType}
                suppliedCreative={props.breachCreativeName}
                requiredAccess={'subscriber'}
                location={services.location}
                onEvent={services.onEvent}
                bannerSize={'large'}
                bannerText={props.bannerMessage}
                title={props.title}
                message={props.message}
                terms={props.terms}
                log={services.log}
            />
        )
    },
)

function puzzlesBreachPackage(
    breachOptions: PuzzlesBreachDealProps,
): PuzzlesBreachDealProps {
    const enabledSwgCampaign = breachOptions.enableSwgCampaign
    if (enabledSwgCampaign) {
        return {
            breachDeal: breachOptions.breachDeal,
            breachType: 'puzzles-swg-30-off',
            bannerMessage: breachOptions.bannerMessage,
            title: 'Access puzzles for only 70c a day',
            message: breachOptions.message,
        }
    }
    return {
        breachDeal: breachOptions.breachDeal,
        breachType: breachOptions.breachType,
        bannerMessage: breachOptions.bannerMessage,
        title: breachOptions.title,
        message: breachOptions.message,
    }
}

function breachDealTerms(deal: string) {
    switch (deal) {
        case 'SCHOOL30':
            return 'Offer ends 23 November 2021.'
        case 'BIKEDI30':
            return 'Offer ends 21 November 2021.'
        case 'GOVAN50':
            return 'Offer ends 20 February 2021.'
        case 'BRE1DISC':
            return 'Offer ends 2 May 2021.'
        default:
            return ''
    }
}
