import { useSelector } from 'react-redux'
import { ConsentLevel } from '../data/consent/consent.redux'
import { getDefaultConsentLevel } from '../data/consent/get-default-consent-level'
import { hasConsentLevel } from '../data/consent/has-consent-level'
import { AppState } from '../store'

export function useConsentGiven(options: {
    // TODO We need to remove the unknown level, unknown will always load to maintain compaibility
    // Which if we leave it will violate GDPR
    consentRequiredLevel: ConsentLevel | 'unknown'
}) {
    const currentConsentLevel = useSelector(
        (state: AppState) =>
            state.consent.consentLevel ||
            getDefaultConsentLevel(state.geoLocation),
    )

    const consentGiven =
        options.consentRequiredLevel === 'unknown' ||
        hasConsentLevel(currentConsentLevel, options.consentRequiredLevel)

    return consentGiven
}
