import {
    PageMetaDTO,
    SectionMetaDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'

export function getMetaForSection(
    sectionMeta: SectionMetaDTO,
    sectionInfo: SectionMetaInfoDTO,
): PageMetaDTO {
    const siteTitle = sectionInfo.Hostname
        ? sectionInfo.Title
        : sectionMeta.defaultSectionMeta.Title

    return {
        link: [],
        meta: [
            // OG Metadata
            { property: 'og:type', content: 'website' },
            { property: 'og:locale', content: 'en_GB' },
            { property: 'og:site_name', content: siteTitle },
            {
                property: 'og:image',
                content: sectionInfo.OgImage.image,
            },
            {
                property: 'og:image:width',
                content: sectionInfo.OgImage.width.toString(),
            },
            {
                property: 'og:image:height',
                content: sectionInfo.OgImage.height.toString(),
            },

            // Twitter metadata
            { name: 'twitter:site', content: siteTitle },
            {
                name: 'twitter:image',
                content: sectionInfo.TwitterImage + '?imwidth=1024',
            },
            { name: 'twitter:card', content: 'summary_large_image' },
        ],
    }
}
