import styled from '@emotion/styled'
import React from 'react'

export interface TheWestGenwestLogo {
    className?: string
    logoWidth?: '100%' | number | undefined
    logoHeight?: number | undefined
}

const StyledImg = styled('img')<TheWestGenwestLogo>({}, (props) => ({
    // IE11 and some android browsers requires a height and width to render correctly
    maxHeight: props.logoHeight,
    maxWidth: props.logoWidth,
    width: '100%',
}))

export const TheWestGenwestLogo: React.FC<TheWestGenwestLogo> = ({
    className,
    logoWidth,
    logoHeight,
}) => (
    <StyledImg
        src={require('./genwest-large-banner-2x.jpg')}
        width={logoWidth}
        height={logoHeight}
    />
)
TheWestGenwestLogo.displayName = 'TheWestGenwestLogo'
