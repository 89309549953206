import { useTheme } from '@emotion/react'
import React from 'react'
import { amphtml } from '../../../google-amp/react_amp'
import { useProduct } from '../../../__product/useProduct'
import { StyledInlineTaboolaAMP } from './InlineTaboola.styled'
import { getTaboolaPublisher } from '../TaboolaScriptProvider'
import { Product, useToggleState } from '@news-mono/web-common'
import {
  isFeatureEnabled,
  toFeatureState,
} from '@etrigan/feature-toggles-client'

interface AmpProps {
    width: number
    height: number
    type: string
    layout: string
    'data-publisher': string
    'data-mode': string
    'data-placement': string
    'data-target_type': string
    'data-article': string
    'data-url': string
    style?: Object
}

interface InlineTaboolaAmpProps {
  sevennewsShouldRender?: boolean
}

const AmpEmbed = amphtml<AmpProps>('embed')

const InlineTaboolaAmp: React.FC<InlineTaboolaAmpProps> = ({
  sevennewsShouldRender
}) => {
    const product = useProduct()
    const toggles = useToggleState()
    const { section } = useTheme()

    const publisher = getTaboolaPublisher(product, section)
    const dataMode = getInlineTaboolaDataMode(product)

    const sevennewsIsEnabled = isFeatureEnabled(toFeatureState(toggles), '7news-taboola-midarticle')

    if (product == "sevennews" && !(sevennewsShouldRender && sevennewsIsEnabled)) {
      return <></>
    }

    return (
        <StyledInlineTaboolaAMP>
            <AmpEmbed
                width={100}
                height={100}
                type="taboola"
                layout="responsive"
                data-publisher={publisher}
                data-mode={dataMode}
                data-placement="Mid Article Thumbnails AMP"
                data-target_type="mix"
                data-article="auto"
                data-url="" // TODO: pass canonical URL to improve recommendation
                style={{ height: 315 }}
            >
                <div overflow="" tabIndex={0} role="button" aria-label="">
                    Read more...
                </div>
            </AmpEmbed>
        </StyledInlineTaboolaAMP>
    )
}

const getInlineTaboolaDataMode = (product: Product) => {
    if (product == "sevennews") {
        return "thumbnails-a"
    } else {
        return "alternating-thumbnails-reco"
    }
}

export default InlineTaboolaAmp
