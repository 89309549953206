import { GptAdSlotDefinition, SizeMapping } from '@news-mono/web-common'
import React from 'react'

export const DisplaySizeMapping: React.FC<{ sizeMapping: SizeMapping }> = ({
    sizeMapping,
}) => (
    <li>
        {formatAdSlot(sizeMapping.slot)} @ width &gt;= {sizeMapping.viewport[0]}
    </li>
)

export const ViewSizeMappings: React.FC<{ unit: GptAdSlotDefinition }> = ({
    unit,
}) => (
    <ul>
        {unit.sizeMapping
            ? unit.sizeMapping
                  .sort((a, b) => (a.viewport[0] < b.viewport[0] ? 1 : -1))
                  .map((sm, i) => (
                      <DisplaySizeMapping key={i} sizeMapping={sm} />
                  ))
            : 'unknown'}
    </ul>
)

export function formatAdSlot(adSlot: SizeMapping['slot']) {
    if (typeof adSlot === 'string') {
        return adSlot
    }
    if (adSlot.length === 0) {
        return 'None'
    }

    if (typeof adSlot[0] === 'string') {
        return adSlot.join(', ')
    }
    if (typeof adSlot[0] === 'number') {
        return `${adSlot[0]}x${adSlot[1]}`
    }

    // Need a cast here as type narrowing doesn't work with above checks
    return (adSlot as Array<[number, number]>)
        .map((slot) => `${slot[0]}x${slot[1]}`)
        .join(', ')
}
