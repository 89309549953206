import React, { FC } from 'react'
import { getReadableTimeSince } from '../../../../__helpers/get-readable-time-since/get-readable-time-since'
import {
    StyledPublicationDate,
    StyledPublicationDateWrapper,
    StyledUpdateLabel,
} from './PublishedTime.styled'

interface NightlyPublishedTimeProps {
    shouldDisplay?: boolean
    publicationDate?: string
    isLiveEvent?: boolean
}

export const PublishedTime: FC<NightlyPublishedTimeProps> = ({
    shouldDisplay = true,
    publicationDate,
    isLiveEvent,
}) => {
    if (!shouldDisplay || !publicationDate) return null

    const publicationDateMessage = getReadableTimeSince({
        publicationDate,
        comparisonDate: new Date().toISOString(),
    })

    return (
        <StyledPublicationDateWrapper>
            {isLiveEvent && <StyledUpdateLabel>Updated</StyledUpdateLabel>}
            <StyledPublicationDate dateTime={publicationDate}>
                {publicationDateMessage}
            </StyledPublicationDate>
        </StyledPublicationDateWrapper>
    )
}
