import { Product } from '@news-mono/web-common'
import React from 'react'
import {
    StyledIconImage,
    StyledIconVideo,
    StyledIconVideo2,
} from './FigCaption.styled'

export function getTypeAndIcon(
    showMediaIcon: boolean,
    product: Product,
    kind: string | undefined,
    captionText: string | undefined,
) {
    let mediaType = null
    let mediaIcon = null

    if (showMediaIcon) {
        mediaType = captionText ? ' Credit: ' : 'Credit: '

        if (kind === 'video') {
            if (product === 'perthnow') {
                mediaIcon = <StyledIconVideo />
            } else {
                mediaIcon = <StyledIconVideo2 width={17} height={15} />
            }
        } else if (kind === 'image') {
            mediaIcon = <StyledIconImage />
        } else if (kind === 'hero-image') {
            mediaType = 'Main Image: '
            mediaIcon = <StyledIconImage />
        }
    }

    if (product === 'sevennews' || product === 'thenightly') {
        mediaIcon = null
    }
    return { mediaType, mediaIcon }
}
