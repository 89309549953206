import React from 'react'
import { createRenderTarget } from '../../render-target'
import { FigCaptionWeb } from './FigCaption.web'

export type FigcaptionLayout = 'feature-article-position'

export interface FigCaptionProps {
    byline?: string
    captionText?: string
    credit?: string
    kind?: string
    className?: string
    figcaptionLayout?: FigcaptionLayout
    separatedFromMedia?: boolean
    sticky?: boolean
    showMediaIcon?: boolean
}

export const FigCaption = createRenderTarget('FigCaption', {
    web: FigCaptionWeb,
    amp: FigCaptionWeb,
    rss: (props) => <FigCaptionWeb {...props} showMediaIcon={false} />,
    preview: FigCaptionWeb,
    app: FigCaptionWeb,
})
FigCaption.displayName = 'FigCaption'
