import {
    getDateTime,
    getFullDateTime,
    getTime,
    getTimeAgo,
    getTimeAgoLong,
} from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../../__product/useProduct'
import { ThemedRender } from '../../__styling/themed-render'
import { Skeleton } from '../Skeleton/Skeleton'
import { StyledTimestamp } from './CardTimestamp.styled'

export type TimestampStyle = 'full' | 'time-only' | 'relative'
export type TimestampType =
    | 'rounded-in-kicker'
    | 'full-at-bottom'
    | 'list-style'

export interface TimestampProps {
    loading?: boolean
    time?: string
    timestampStyle?: TimestampStyle
    className?: string
}

const renderTime = (props: TimestampProps) => {
    const { timestampStyle, className } = props
    const time = props.time ? props.time : ''
    const dateTime = getDateTime(time)
    const fullDateTime = getFullDateTime(time)

    return (
        <ThemedRender
            perthnow={() => (
                <StyledTimestamp className={className} dateTime={dateTime}>
                    {timestampStyle === 'relative'
                        ? getTimeAgo(time)
                        : getTime(time)}
                </StyledTimestamp>
            )}
            thewest={() => (
                <StyledTimestamp className={className} dateTime={dateTime}>
                    {timestampStyle === 'relative' && getTimeAgoLong(time)}
                    {timestampStyle === 'full' && fullDateTime}
                    {!timestampStyle && getTime(time, true)}
                </StyledTimestamp>
            )}
            sevennews={() => (
                <StyledTimestamp className={className} dateTime={dateTime}>
                    {timestampStyle === 'relative'
                        ? getTimeAgo(time)
                        : getTime(time, true, true)}
                </StyledTimestamp>
            )}
        />
    )
}

export const Timestamp: React.FC<TimestampProps> = (props) => {
    const { loading, time, className } = props
    const product = useProduct()

    return loading || !time ? (
        <StyledTimestamp className={className}>
            {product !== 'perthnow' ? <Skeleton /> : <time>&zwnj;</time>}
        </StyledTimestamp>
    ) : (
        renderTime(props)
    )
}
Timestamp.displayName = 'Card-Timestamp'
