import { useSelector } from 'react-redux'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../../store'
import { getDefaultConsentLevel } from './get-default-consent-level'

/**
 * To check ConsentLevel use a bitwise test
 *
 * (consentLevel & ConsentLevel.Analytics) === ConsentLevel.Analytics if set
 *
 * Consent types - based on https://gdpr.eu/cookies/
 *
 * Essential:
 * Strictly necessary cookies — These cookies are essential for you to browse the website and use its features, such as accessing secure
 * areas of the site. Cookies that allow web shops to hold your items in your cart while you are shopping online are an example of strictly
 * necessary cookies. These cookies will generally be first-party session cookies. While it is not required to obtain consent for these
 * cookies, what they do and why they are necessary should be explained to the user.
 *
 * No level for this:
 * Preferences cookies — Also known as “functionality cookies,” these cookies allow a website to remember choices you have made in the past,
 * like what language you prefer, what region you would like weather reports for, or what your user name and password are so you can
 * automatically log in.
 *
 * Analytics (anonymous, no tracking):
 * Statistics cookies — Also known as “performance cookies,” these cookies collect information about how you use a website, like which pages
 * you visited and which links you clicked on. None of this information can be used to identify you. It is all aggregated and, therefore,
 * anonymized. Their sole purpose is to improve website functions. This includes cookies from third-party analytics services as long as the
 * cookies are for the exclusive use of the owner of the website visited.
 *
 * Advertising (targeted tracking):
 * Marketing cookies — These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many
 * times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and
 * almost always of third-party provenance.
 */
export const enum ConsentLevel {
    None = 0,
    Essential = 1,
    Analytics = 2,
    Advertising = 4,
    All = 1 | 2 | 4,
}

export type ConsentAction = never

export const LOAD_USER_CONSENT = 'LOAD_USER_CONSENT'
export const SHOW_COOKIE_SETTINGS_DIALOG = 'SHOW_COOKIE_SETTINGS_DIALOG'
export const ACCEPTING_COOKIE_SETTINGS_DIALOG =
    'ACCEPTING_COOKIE_SETTINGS_DIALOG'
export const ACCEPTED_COOKIE_SETTINGS_DIALOG = 'ACCEPT_COOKIE_SETTINGS_DIALOG'

export interface LOAD_USER_CONSENT extends Action {
    type: typeof LOAD_USER_CONSENT
    payload: Pick<ConsentState, 'consentLevel' | 'consentTimestamp'>
}

/**
 * IMPORTANT: This should only be used during SSR
 *
 * This is because we can't reload scripts which are loaded so the value should not be updated client side
 **/
export function loadConsent(
    consentLevel: ConsentLevel,
    consentTimestamp?: string,
): LOAD_USER_CONSENT {
    return {
        type: LOAD_USER_CONSENT,
        payload: { consentLevel, consentTimestamp },
    }
}
export interface SHOW_COOKIE_SETTINGS_DIALOG extends Action {
    type: typeof SHOW_COOKIE_SETTINGS_DIALOG
    payload: boolean
}

export function showCookieSettingsDialog(
    payload = true,
): SHOW_COOKIE_SETTINGS_DIALOG {
    return {
        type: SHOW_COOKIE_SETTINGS_DIALOG,
        payload,
    }
}

export interface ACCEPTING_COOKIE_SETTINGS_DIALOG extends Action {
    type: typeof ACCEPTING_COOKIE_SETTINGS_DIALOG
    payload: { accepting: boolean }
}

export interface ACCEPTED_COOKIE_SETTINGS_DIALOG extends Action {
    type: typeof ACCEPTED_COOKIE_SETTINGS_DIALOG
    payload: Required<Pick<ConsentState, 'consentLevel'>>
}

export function acceptCookieSettingsDialog(
    payload: Required<Pick<ConsentState, 'consentLevel'>>,
): ThunkAction<
    void,
    AppState,
    any,
    ACCEPTING_COOKIE_SETTINGS_DIALOG | ACCEPTED_COOKIE_SETTINGS_DIALOG
> {
    return async (dispatch, getState) => {
        dispatch({
            type: ACCEPTING_COOKIE_SETTINGS_DIALOG,
            payload: { accepting: true },
        })

        const result = await fetch('/set-consent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                consentLevel: payload.consentLevel,
            }),
            // Required to set cookies
            credentials: 'same-origin',
        })

        if (result.status !== 200) {
            dispatch({
                type: ACCEPTING_COOKIE_SETTINGS_DIALOG,
                payload: { accepting: false },
            })
        } else {
            const state = getState()
            if (
                (state.consent.consentLevel ||
                    getDefaultConsentLevel(state.geoLocation)) !==
                payload.consentLevel
            ) {
                window.location.reload()
                // If we do not return here, we will cause a hooks error elsewhere
                // because it may cause a script to now load
                return
            }

            dispatch({
                type: ACCEPTED_COOKIE_SETTINGS_DIALOG,
                payload,
            })
        }
    }
}
/**
 *  Once consentLevel is set, we don't reset it, so that way scripts accessing it don't update when the consent level changes.
 **/
export interface ConsentState {
    /**
     * IMPORTANT: consentLevel should not be updated except during server side render
     *
     * This is because we can't reload scripts which are loaded so the value should not be updated client side
     **/
    consentLevel?: ConsentLevel
    showConsentDialog?: boolean
    savingConsent?: boolean
    consentTimestamp?: string
}

const defaultState: ConsentState = {
    showConsentDialog: true,
    consentTimestamp: undefined,
}

type ConsentActions =
    | SHOW_COOKIE_SETTINGS_DIALOG
    | ACCEPTING_COOKIE_SETTINGS_DIALOG
    | ACCEPTED_COOKIE_SETTINGS_DIALOG
    | LOAD_USER_CONSENT
    | { type: '@@INIT' /** This represents all other actions */ }

export function consentReducer(
    state: ConsentState = defaultState,
    action: ConsentActions,
): ConsentState {
    switch (action.type) {
        case LOAD_USER_CONSENT: {
            const { consentLevel, consentTimestamp } = action.payload

            return {
                ...state,
                consentLevel,
                consentTimestamp,
                showConsentDialog: !consentLevel,
            }
        }
        case SHOW_COOKIE_SETTINGS_DIALOG: {
            return {
                ...state,
                showConsentDialog: action.payload,
            }
        }

        case ACCEPTED_COOKIE_SETTINGS_DIALOG: {
            const { consentLevel } = action.payload

            return {
                ...state,
                consentLevel,
                showConsentDialog: false,
                savingConsent: false,
            }
        }

        case ACCEPTING_COOKIE_SETTINGS_DIALOG: {
            return {
                ...state,
                savingConsent: true,
            }
        }

        default:
            return state
    }
}

export function useConsentState(): ConsentState {
    const consent = useSelector<AppState, ConsentState>(
        (state) => state.consent,
    )
    return consent
}
