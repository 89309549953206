/* eslint-disable @typescript-eslint/no-var-requires */
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/bali-bombings-feature-banner-lg@2x.png')

const bannerDesktopX1 = require<string>('./assets/bali-bombings-feature-banner-lg@1x.png')

const bannerTabletX2 = require<string>('./assets/bali-bombings-feature-banner-md@2x.png')

const bannerTabletX1 = require<string>('./assets/bali-bombings-feature-banner-md@1x.png')

const bannerMobileX2 = require<string>('./assets/bali-bombings-feature-banner-sm@2x.png')

const bannerMobileX1 = require<string>('./assets/bali-bombings-feature-banner-sm@1x.png')

const socialImage = require('./assets/bali-bombings-social-share-@2x.png')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '627',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

export const getBaliBombingsMyStoryRouteInfo: StaticRoutes<TheWestSection> = {
    '/features/bali-bombings-my-story': ({
        getAdTargeting,
        config,
        store,
        resolution,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/features/bali-bombings-my-story'

        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'bali-bombings-feature-route',
        )

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'features/bali-bombings-my-story',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                '',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        if (!enableRoute) {
            return null
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'video-series',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting: getAdTargeting('home', 'default', topic.id),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: bannerDesktopX2,
                                    verticalGutters: ['unset', 'md'],
                                    altText: topic.seoTitle,
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: bannerDesktopX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerDesktopX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: bannerTabletX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerTabletX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: bannerMobileX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerMobileX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    layoutRatio: '3:1',
                                    stretchColumns: true,
                                    verticalSpacing: 'xl',
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },

                                                cardLayout: ['hero-video'],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'bali-bombing-my-story',
                                                    offset: 0,
                                                    pageSize: 1,
                                                },
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'tw-subscribe-promo-card',
                                            props: {},
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'Watch the episodes',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'bali-bombing-my-story',
                                        offset: 1,
                                        pageSize: 8,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: 'Latest Bali Bombings News',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['news/bali-bombings'],
                                        includeSubTopics: true,
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
