import styled from '@emotion/styled'
import { IconRefresh } from '../../../icons/IconRefresh/IconRefresh'
import { IconUpArrow } from '../../../icons/IconUpArrow/IconUpArrow'
import { calcRem, easing } from '../../../__styling'

interface StyledButtonProps {
    show: boolean
}

export const StyledButton = styled('button')<StyledButtonProps>(
    ({ theme, show }) => [
        {
            backgroundColor:
                theme.colors.actions.button.primary.background.default,
            borderRadius: calcRem(32),
            marginTop: theme.margins.md,
            marginBottom: theme.margins.sm,
            height: calcRem(40),
            cursor: 'pointer',
            border: `1px solid ${theme.colors.brand}`,
            position: 'sticky',
            top: 20,
            margin: '0 auto',
            zIndex: 1000,
            display: 'flex',
            color: theme.colors.actions.button.primary.text.default,
            opacity: show ? 1 : 0,
            pointerEvents: show ? 'initial' : 'none',
            transition: `opacity ${easing.easeOut.med}, box-shadow ${easing.easeOut.fast}, transform ${easing.easeOut.fast}`,

            boxShadow: '0px 4px 11px 4px rgb(0 0 0 / 18%)',

            '&:focus, &:hover': {
                backgroundColor:
                    theme.colors.actions.button.primary.background.default,
                borderColor:
                    theme.colors.actions.button.primary.background.default,
            },
            '&:active': {
                backgroundColor:
                    theme.colors.actions.button.primary.background.default,
                borderColor:
                    theme.colors.actions.button.primary.background.default,
            },

            // Remove outline
            '&:focus, &:active': {
                outline: 'none',
            },

            // Add box shadow
            '&:focus': {
                boxShadow: '0px 2px 10px 10px rgba(9, 180, 192, 0.18)',
            },
            '&:hover': {
                boxShadow: '0px 9px 21px 3px rgb(0 0 0 / 30%)',
                transform: `translateY(-4px)`,
            },
        },
        // Styling for TN
        theme.kind === 'thenightly' && {
            top: `calc(20px + var(--stickyHeaderHeight))`,

            transition: 'top 0.2s linear',
        },
        // Styling for PN & TW
        theme.kind === 'perthnow' ||
            (theme.kind === 'thewest' && {
                '&:focus, &:hover': {
                    backgroundColor: '#114CE4',
                    borderColor: '#114CE4',
                },

                '&:active': {
                    backgroundColor: '#0231B5',
                    borderColor: '#0231B5',
                },
            }),
    ],
)

export const StyledButtonInnerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    padding: calcRem(10, 10),
    gap: calcRem(6),

    '& > span': {
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 700,
        fontSize: calcRem(15),
        lineHeight: calcRem(16),
    },
}))

export const StyledArrow = styled(IconUpArrow)(({ theme }) => ({
    width: calcRem(12),
    height: calcRem(14),
    fill: theme.colors.actions.button.primary.text.default,
}))

export const StyledSpinner = styled(IconRefresh)(({ theme }) => ({
    width: calcRem(12),
    height: calcRem(14),
    fill: theme.colors.actions.button.primary.text.default,
}))
