import { TheWestLivePlayerStates } from '@news-mono/web-common'
import React from 'react'
import { LoggerContext } from '../../../diagnostics/LoggerContext'

interface TheWestLiveAudioProps {
    streamingHostname?: string
    stationId?: string
    volume: number
    playerState: TheWestLivePlayerStates
    showPlayer: boolean
    toggleState: (playerState: TheWestLivePlayerStates) => void
}

export const TheWestLiveAudio: React.FC<TheWestLiveAudioProps> = ({
    streamingHostname,
    stationId,
    volume = 0.5,
    playerState,
    toggleState,
    showPlayer = false,
}) => {
    const playerRef = React.useRef<HTMLAudioElement>(null)
    const logger = React.useContext(LoggerContext)

    React.useEffect(() => {
        if (playerRef.current) {
            playerRef.current.volume = volume
        }
    }, [volume])

    React.useEffect(() => {
        if (playerState === 'playing') {
            playerRef.current?.play()
        }
    }, [playerState])

    if (
        !streamingHostname ||
        !stationId ||
        playerState === 'stopped' ||
        !showPlayer
    ) {
        return null
    }

    const onCanPlayHandler = () => {
        logger.debug('Player can play')
        if (playerState === 'loading') {
            toggleState('playing')
        }
    }

    const onPlayingHandler = () => {
        logger.debug('Player playing')
        toggleState('playing')
    }

    const onPauseHandler = () => {
        logger.debug('Player paused')
        toggleState('stopped')
    }

    const onSuspendHandler = () => {
        logger.debug('Player suspended')
    }

    const onStalledHandler = () => {
        logger.debug('Player stalled')
    }

    const onWaitingHandler = () => {
        logger.debug('Player waiting')
    }

    const onLoadedMetaData = () => {
        logger.debug('Player loaded metadata')
        if (playerState === 'loading') {
            toggleState('playing')
        }
    }

    const onErrorHandler = (
        event: React.SyntheticEvent<HTMLAudioElement, Event>,
    ) => {
        const target = playerRef.current ?? (event.target as HTMLAudioElement)

        logger.error(
            { errorCode: target.error?.code, message: target.error?.message },
            `West Live Player error`,
        )
    }

    return (
        <audio
            ref={playerRef}
            onPlaying={onPlayingHandler}
            onPause={onPauseHandler}
            onSuspend={onSuspendHandler}
            onLoadedMetadata={onLoadedMetaData}
            onCanPlay={onCanPlayHandler}
            onStalled={onStalledHandler}
            onWaiting={onWaitingHandler}
            onError={onErrorHandler}
            preload="metadata"
        >
            <source src={`https://${streamingHostname}/${stationId}/listen`} />
        </audio>
    )
}
