import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { breakpoint } from '../../__styling'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    stickyOffset?: number
    // "as" this is available on emotion 10 but we are currently overloading with emotion 9 to save build time
    // https://emotion.sh/docs/styled
    as?: string
}

export const StyledJuliet = styled('div')<GridProps>(
    {
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    ({ stickyOffset, verticalSpacing, theme }) => [
        {
            marginBottom:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
        },
        stickyOffset !== undefined && {
            position: 'sticky',
            top: `${stickyOffset}px`,
        },
    ],
)

export const StyledHeader = styled('div')(
    {
        display: 'block',
        width: '100%',
    },
    ({ theme }) => ({
        marginBottom:
            theme.kind === 'thenightly'
                ? calcRem(metrics[theme.kind].margins.sm)
                : calcRem(metrics[theme.kind].margins.md),

        [breakpoint('lg')]: {
            marginBottom:
                theme.kind === 'thenightly'
                    ? 0
                    : calcRem(metrics[theme.kind].margins.md),
        },
    }),
)

export const StyledGridItem = styled('div')<{
    noHorizontalDividers?: boolean
    hideLastDivider?: boolean
}>(
    {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexShrink: 1,
        width: '100%',
        maxWidth: '100%',
        alignItems: 'stretch',
        flex: '0 0 auto',
        position: 'relative',
    },
    ({ theme, noHorizontalDividers, hideLastDivider }) => ({
        marginBottom:
            theme.kind === 'thenightly'
                ? 0
                : calcRem(metrics[theme.kind].margins.sm),
        paddingTop: theme.kind === 'thenightly' ? calcRem(16) : undefined,
        paddingBottom: theme.kind === 'thenightly' ? calcRem(16) : undefined,

        '&:last-child': {
            marginBottom: '0',
            paddingBottom: theme.kind === 'thenightly' ? 0 : undefined,

            [breakpoint('lg')]: {
                paddingBottom:
                    theme.kind === 'thenightly'
                        ? hideLastDivider
                            ? 0
                            : calcRem(metrics[theme.kind].margins.xxxl)
                        : undefined,
            },
        },

        // Adds horizontal dividers
        '&::before': {
            content: themedValue(theme, {
                thewest: `''`,
                perthnow: `''`,
                thenightly: `''`,
                sevennews: undefined,
                fallback: undefined,
            }),
            backgroundColor: themedValue(theme, {
                thewest: colors.thewest.greyDolphin,
                perthnow: noHorizontalDividers
                    ? undefined
                    : colors.perthnow.greyMarble,
                thenightly: noHorizontalDividers
                    ? undefined
                    : theme.colors.borders.secondary,
                sevennews: undefined,
                fallback: undefined,
            }),
            width: '100%',
            height: noHorizontalDividers
                ? 0
                : theme.kind === 'thenightly'
                ? 0.5
                : 1,
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: zIndex.common.collectionDividers,
        },

        // only have horizontal divider on last card on perthnow & thenightly
        '&:last-child::before': {
            content: themedValue(theme, {
                thewest: 'none',
                perthnow: `''`,
                thenightly: `''`,
                sevennews: undefined,
                fallback: undefined,
            }),
            height: theme.kind === 'thenightly' ? 0 : 1,

            [breakpoint('lg')]: {
                height:
                    theme.kind === 'thenightly'
                        ? hideLastDivider
                            ? 0
                            : 0.5
                        : 1,
            },
        },

        '> .PortraitCard': {
            alignItems: 'flex-start',
        },
    }),
)
