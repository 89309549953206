import {
    AdState,
    BaseClientConfig,
    CollectionEvent,
} from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { GetVideoQueue } from '../content/Video/PlayerInterface'
import { SectionHeaderProps } from '../section-header'
import { ThemeMargins } from '../__styling'
import { StyledVideoHub } from './VideoHub.styled'
import { VideoPlayer } from './VideoPlayer/VideoPlayer'
import { HeaderV2 } from '../SevenNewsV2/HeaderV2/HeaderV2'

export interface VideoHubProps {
    item: VideoMetaDTO
    onEvent: (event: CollectionEvent) => void
    config: BaseClientConfig
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    adUnitPath: string
    currentPath: string
    getVideoQueue: GetVideoQueue
    sectionHeader?: SectionHeaderProps
    isVideoWatchPage?: boolean
    autoplayWhenInView?: boolean
    adElement?: JSX.Element
}
export const VideoHub: React.FC<VideoHubProps> = ({
    onEvent,
    item,
    config,
    adUnitPath,
    currentPath,
    verticalGutters,
    verticalSpacing,
    getVideoQueue,
    sectionHeader,
    isVideoWatchPage,
    autoplayWhenInView,
    adElement,
}) => {
    if (item) {
        return (
            <StyledVideoHub
                verticalGutters={verticalGutters}
                verticalSpacing={verticalSpacing}
            >
                {sectionHeader?.heading && (
                    <HeaderV2 sectionHeader={sectionHeader} />
                )}
                <VideoPlayer
                    onEvent={onEvent}
                    config={config}
                    item={item}
                    adUnitPath={adUnitPath}
                    currentPath={currentPath}
                    getVideoQueue={getVideoQueue}
                    isVideoWatchPage={isVideoWatchPage}
                    autoplayWhenInView={autoplayWhenInView}
                    adElement={adElement}
                />
            </StyledVideoHub>
        )
    }
    return null
}
