import { decode } from 'jsonwebtoken'
import { CredentialToken } from '.'
import { BaseClientConfig } from '../..'

export function decodeToken(credential: string) {
    return decode(credential) as CredentialToken
}

export function getReferrers(config: BaseClientConfig) {
    return config.googleLoginClientIds
        ? Object.keys(config.googleLoginClientIds)
        : []
}

export function getGoogleOAuthClientId(
    config: BaseClientConfig,
    hostname: string,
) {
    const apex = hostname.replace(/^www\./, '')

    if (!config.googleLoginClientIds || !config.googleLoginClientIds[apex]) {
        throw new Error(
            `Google login client id not defined for apex domain ${apex}`,
        )
    }
    return config.googleLoginClientIds[apex]
}
