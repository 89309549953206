import { MarketCodeTypes } from '../../data/location-detection/location-detection'
import { LocationState } from '../../data/location-detection/reducer'

export const getCurationFromRegion = (location?: LocationState) =>
    (location &&
        ((region?: MarketCodeTypes) => {
            switch (region) {
                case 'NSW':
                case 'WA':
                case 'SA':
                case 'VIC':
                case 'QLD':
                    return region.toLowerCase()
                case 'QLDREG':
                    return 'qld'
                case 'NT':
                    return 'sa'
                case 'TAS':
                    return 'vic'
                default:
                    return 'nsw'
            }
        })(location.userSelectedRegion)) ||
    'regional'
