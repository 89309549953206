import { PublicationCardItem, WebLink } from '@news-mono/web-common'
import styled from '@emotion/styled'
import React, { FC } from 'react'
import CommentsCount from '../../TheNightly/components/card-body/byline/CommentsCount'
import { TheNightlyTheme, calcRem } from '../../../__styling'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'

interface NightlyTopicTagProps {
    data: PublicationCardItem
    level?: 'parent'
}
export const NightlyTopicTag: FC<NightlyTopicTagProps> = ({ data, level }) => {
    const topic =
        level === 'parent'
            ? data.primaryTopic.parent ?? data.primaryTopic
            : data.primaryTopic
    return (
        <TopicTagWrapper className="topic-label">
            <TopicLabel to={`/${topic.id}`}>{topic.title}</TopicLabel>
            {data.allowCommenting && (
                <CommentsLink to={`${data.link}#comments`}>
                    <CommentsCount articleUrl={data._self} />
                </CommentsLink>
            )}
        </TopicTagWrapper>
    )
}

const TopicLabel = styled(WebLink)(({ theme }) => [
    {
        color: theme.colors.text.secondary,
        textDecoration: 'unset',

        [`&:is(:hover, :focus)`]: {
            textDecoration: 'underline',
        },
    },
])
const TopicTagWrapper = styled.div(({ theme }) => [
    {
        textTransform: 'uppercase',
        paddingLeft: 0,
        minHeight: 0,
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(8),
    },
    textMixin(theme as TheNightlyTheme, 'byline-primary'),
])
const CommentsLink = styled(WebLink)(({ theme }) => {
    return {
        color: theme.colors.text.secondary,
        textDecoration: 'none',

        [`&:is(:hover, :focus)`]: {
            textDecoration: 'underline',
        },
    }
})
