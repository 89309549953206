import styled from '@emotion/styled'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint } from '../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { transition } from '../../../__styling/style-mixins'

interface StyledTooltipContainerProps {
    displayTooltip: boolean
}

export const StyledTooltipContainer = styled(
    'div',
)<StyledTooltipContainerProps>(({ theme, displayTooltip }) => ({
    opacity: displayTooltip ? 1 : 0,
    pointerEvents: displayTooltip ? 'unset' : 'none',
    position: 'absolute',
    right: '-7%',
    top: '48px',
    zIndex: zIndex.thewest.newFeatureTooltip,
    padding: calcRem(theme.margins.md),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    transition: 'opacity 0.2s ease',
    width: calcRem(288),
    height: calcRem(118),
    backgroundColor: theme.colors.brand,
    boxShadow:
        '0px 2px 38px rgba(0, 0, 0, 0.38), 0px 28px 20px -20px rgba(0, 0, 0, 0.56)',
    borderRadius: 8,

    '&::after': {
        position: 'absolute',
        right: '22px',
        top: '-30px',
        content: `''`,
        borderWidth: calcRem(16),
        borderStyle: 'solid',
        borderColor: `transparent transparent ${theme.colors.brand} transparent `,
        width: calcRem(24),
        height: calcRem(24),
    },

    [breakpoint('sm')]: {
        width: calcRem(448),
        height: calcRem(168),
        padding: theme.margins.md,
        right: '110%',
        top: '-12px',

        '&::after': {
            right: '-7%',
            top: '18px',
            borderColor: `transparent transparent transparent ${theme.colors.brand}`,
        },
    },
}))

export const StyledTooltipHeading = styled('h3')(({ theme }) => ({
    display: 'none',
    margin: theme.margins.sm,
    marginTop: calcRem(20),
    fontFamily: theme.fonts.serif,
    textAlign: 'left',
    fontSize: calcRem(22),
    lineHeight: calcRem(24),
    color: theme.colors.palette.white,

    [breakpoint('sm')]: {
        display: 'block',
    },
}))

export const StyledTooltipInfo = styled('p')(({ theme }) => ({
    margin: calcRem(theme.margins.sm, 0),
    fontFamily: theme.fonts.serif,
    fontWeight: 'normal',
    fontSize: calcRem(14),
    lineHeight: calcRem(18),
    color: theme.colors.palette.white,

    [breakpoint('sm')]: {
        margin: calcRem(theme.margins.sm),
        fontSize: calcRem(17),
        lineHeight: calcRem(24),
        fontWeight: 'normal',
    },
}))

export const StyledCloseButton = styled('button')(({ theme }) => ({
    display: 'inline-block',
    alignSelf: 'flex-end',
    border: `1px solid ${theme.colors.palette.white}`,
    padding: calcRem(theme.margins.sm, theme.margins.xl),
    margin: calcRem(0, 0, theme.margins.md, theme.margins.md),
    background: 'none',
    color: theme.colors.palette.white,
    borderRadius: calcRem(2),
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(13),
    textDecoration: 'none',
    cursor: 'pointer',
    ...transition({ properties: ['background', 'color'] }),

    '&:hover': {
        backgroundColor: theme.colors.palette.white,
        color: theme.colors.brand,
    },

    '&:focus': {
        backgroundColor: theme.colors.palette.black,
        borderColor: theme.colors.palette.black,
        color: theme.colors.palette.white,
        textDecoration: 'underline',
    },
}))
