import { layout } from '../../App.routing'

export function subscriberExclusive() {
    return layout.component({
        type: 'lima',
        props: {
            fixedRatios: ['16:9'],
            intermediateColumns: 4,
            finalColumns: 4,
            hideByline: false,
            hasBackground: true,
            verticalSpacing: 'xl',
            fontScale: 0.88,
            cardOrientation: { type: 'Landscape-Portrait' },

            sectionHeader: {
                heading: 'Subscriber Exclusive',
                headingUrl: '/features',
            },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'subscriber-exclusive',
                offset: 0,
                pageSize: 4,
            },
        },
    })
}
