import { toFeatureState } from '@etrigan/feature-toggles-client'
import {
    isComponentEnabled,
    NavEventArgs,
    NavItem,
    searchCrawlerMastheadWhitelist,
    useToggleState,
} from '@news-mono/web-common'
import React from 'react'
import { KeyCode } from '../../../../__helpers/key-code'
import { Section } from '../../../../__styling/settings/sections'
import { StyledLink, StyledList, StyledListItem } from './FlyoutSubnav.styled'

export interface FlyoutSubnavProps {
    navItems: NavItem[]
    section: Section
    onClick?: (args: NavEventArgs) => void
    onToggle?: () => void
    id: string
    itemShouldBeVisible: boolean
}

export const FlyoutSubnav: React.FC<FlyoutSubnavProps> = ({
    navItems,
    onClick,
    id,
    onToggle = () => {},
    itemShouldBeVisible,
}) => {
    const keyDownHandler = (evt: React.KeyboardEvent) => {
        if (evt.which === KeyCode.ESCAP) {
            evt.stopPropagation()
            onToggle()
        }
    }

    const featureState = useToggleState()

    return (
        <StyledList
            id={id}
            onKeyDown={keyDownHandler}
            shouldBeVisible={itemShouldBeVisible}
        >
            {navItems
                .filter((navItem: NavItem) => {
                    if (navItem.feature) {
                        return isComponentEnabled(
                            toFeatureState(featureState),
                            {
                                feature: navItem.feature,
                                invertFeatureToggle:
                                    navItem.invertFeatureToggle,
                            },
                        )
                    }
                    return true
                })
                .map((navItem, index) => {
                    const isCrawlerWhitelisted = searchCrawlerMastheadWhitelist(
                        navItem.link,
                    )
                    return (
                        <StyledListItem key={navItem.name}>
                            <StyledLink
                                children={navItem.name}
                                openExternalInNewWindow={navItem.newWindow}
                                target={
                                    navItem.newWindow ? '_blank' : undefined
                                }
                                rel={
                                    navItem.newWindow && !isCrawlerWhitelisted
                                        ? 'noopener'
                                        : undefined
                                }
                                to={navItem.link}
                                onClick={() => {
                                    onClick &&
                                        onClick({
                                            navPos: index,
                                            navText: navItem.name,
                                            navLink: navItem.link,
                                            navParent: parent.name,
                                        })
                                }}
                                forceInternalSSR={navItem.forceInternalSSR}
                            />
                        </StyledListItem>
                    )
                })}
        </StyledList>
    )
}
