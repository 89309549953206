import { ThemeProvider } from '@emotion/react'
import merge from 'deepmerge'
import React from 'react'
import { useProduct } from '../../__product/useProduct'
import {
    getThemeOverrideValues,
    ThemeOverrideTypes,
} from '../../__styling/themes'

interface Props {
    override: ThemeOverrideTypes | undefined
}

/** @deprecated Use SchemeOverrider and ComponentThemeOverrider */
export const ThemeOverrider: React.FC<Props> = ({ override, children }) => {
    const product = useProduct()

    if (!override) {
        return <React.Fragment>{children}</React.Fragment>
    }

    return (
        <ThemeProvider
            theme={(theme) => {
                const mergedTheme = merge(
                    theme,
                    getThemeOverrideValues({ override, product }),
                )
                return mergedTheme
            }}
        >
            {children}
        </ThemeProvider>
    )
}
