export const rssItems = (publicUrl: string) => [
    {
        title: 'Latest',
        link: `${publicUrl}/rss`,
    },
    {
        title: 'WA News',
        link: `${publicUrl}/news/wa/rss`,
    },
    {
        title: 'National News',
        link: `${publicUrl}/news/australia/rss`,
    },
    {
        title: 'International News',
        link: `${publicUrl}/news/world/rss`,
    },
    {
        title: 'Sport',
        link: `${publicUrl}/sport/rss`,
    },
    {
        title: 'AFL',
        link: `${publicUrl}/sport/afl/rss`,
    },
    {
        title: 'Business',
        link: `${publicUrl}/business/rss`,
    },
    {
        title: 'Politics',
        link: `${publicUrl}/politics/rss`,
    },
    {
        title: 'Lifestyle',
        link: `${publicUrl}/lifestyle/rss`,
    },
    {
        title: 'Entertainment',
        link: `${publicUrl}/entertainment/rss`,
    },
    {
        title: 'Travel',
        link: `${publicUrl}/travel/rss`,
    },
]
