import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    BaseClientConfig,
    ErrorResolution,
    GetRouteAdTargeting,
    noIndexMeta,
    pageIsUnavailable,
    PageType,
    RouteResolution,
    StaticPageRoute,
    StaticRoutes,
} from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import { layout } from '../App.routing'

// Only for page not found
export const recommended = layout.component({
    type: 'lima',
    props: {
        verticalSpacing: 'lg',
        fontScale: 1.2,
        fixedRatios: ['16:9'],
        initialColumns: 1,
        intermediateColumns: 2,
        finalColumns: 4,
        teaserMode: 'hidden',
        hideByline: false,
        hasBackground: true,

        sectionHeader: {
            heading: 'Recommended',
            headingUrl: '/',
            navLinks: [],
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'home',
            offset: 0,
            pageSize: 4,
        },
    },
})

export const notFoundRoute: StaticPageRoute<TheWestSection> = ({
    getAdTargeting,
    resolution,
}) => {
    const section = ((resolution as ErrorResolution).section ||
        TheWestSections.default) as TheWestSection

    return {
        kind: 'page',
        heading: 'Page Not Found',
        pageMeta: {
            meta: [noIndexMeta],
        },
        // used by `/server/middleware/optimised-ssr` to determine redirect status
        pageType: 'error',
        hideHeading: true,
        adTargeting: getAdTargeting('error', section, 'page-not-found'),
        section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: 'Page not found',
                                verticalSpacing: 'md',
                            },
                        }),
                        layout.component({
                            type: 'west-404-page',
                            props: {},
                        }),
                        recommended,
                    ],
                },
            }),
        ],
    }
}

export const theWestPageIsUnavailableRoute: StaticRoutes<TheWestSection> = {
    [`${pageIsUnavailable}`]: ({ resolution, getAdTargeting }) => {
        const section = ((resolution as ErrorResolution).section ||
            TheWestSections.default) as TheWestSection

        return {
            kind: 'page',
            heading: 'Page is Unavailable',
            pageMeta: {
                // Should fix the crawler problems
                meta: [noIndexMeta],
            },
            // TODO: Look into how optimised SSR affects this
            pageType: 'error',
            hideHeading: true,
            adTargeting: getAdTargeting(
                'error',
                section,
                'page-is-unavailable',
            ),
            section,
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    title: 'Page Unavailable',
                                    verticalSpacing: 'md',
                                },
                            }),
                            layout.component({
                                type: 'west-page-is-unavailable',
                                props: {},
                            }),
                            recommended,
                        ],
                    },
                }),
            ],
        }
    },
}

export const matchedSomethingWentWrongRoute = (
    config: BaseClientConfig,
    getAdTargeting: GetRouteAdTargeting,
    resolution: RouteResolution,
): PageType<TheWestSection> =>
    somethingWentWrongContent(config, getAdTargeting, resolution)

export const staticSomethingWentWrongRoute: StaticPageRoute<TheWestSection> = ({
    config,
    getAdTargeting,
    resolution,
}) => somethingWentWrongContent(config, getAdTargeting, resolution)

const somethingWentWrongContent = (
    _: BaseClientConfig,
    getAdTargeting: GetRouteAdTargeting,
    resolution: RouteResolution,
): PageType<TheWestSection> => {
    let errorResolution: ErrorResolution | undefined = undefined

    if ('error' in resolution) {
        errorResolution = resolution as ErrorResolution
    }

    const section = ((resolution as ErrorResolution).section ||
        TheWestSections.default) as TheWestSection

    return {
        kind: 'page',
        heading: 'Something Went Wrong',
        pageMeta: {
            meta: [noIndexMeta],
        },
        pageType: 'error',
        hideHeading: true,
        adTargeting: getAdTargeting('error', section, 'something-went-wrong'),
        section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                title: 'Something Went Wrong',
                            },
                        }),
                        layout.component({
                            type: 'west-500-page',
                            props: {
                                error: errorResolution?.error ?? {
                                    errorId: process.env.GENERATE_STATIC_ERROR
                                        ? 'cloudfront'
                                        : 'ssr',
                                    errorType: '500',
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
