import React, { useState } from 'react'
import { DataLayerEventName, PublicationCardItem } from '@news-mono/web-common'

import {
    ToastMessage,
    UserSettingsSlideToast,
} from '../../content/UserSettingsSlideToast/UserSettingsSlideToast'
import { useSavedArticleContextMenu } from './useSavedArticleContextMenu'
import { SavedArticleContextMenuModal } from './SavedArticleContextMenuModal'
import {
    StyledIconDotsVerticalContainer,
    StyledModalContainer,
} from './SavedArticleContextMenu.styled'
import { IconDotsVertical } from '../../icons/IconDotsVertical/IconDotsVertical'

export interface SavedArticlesContextMenuProps {
    publication: PublicationCardItem
    onEvent: (event: any) => void
}

export const SavedArticleContextMenu: React.FC<SavedArticlesContextMenuProps> =
    ({ publication, onEvent }) => {
        const savedArticleContextMenuContainer =
            React.useRef<HTMLElement | null>(null)

        const [toast, setToast] = useState<ToastMessage>({
            text: '',
            subText: undefined,
            type: 'success',
            show: false,
        })

        const onDismissToast = () => {
            setToast({ ...toast, show: false })
        }

        const { isHidden, onUnsaveClick, onCopyLinkClick, show, hide } =
            useSavedArticleContextMenu({
                savedArticleContextMenuContainer,
                setToast,
            })

        return (
            <>
                <StyledIconDotsVerticalContainer
                    position="flex-start"
                    onClick={() => {
                        if (onEvent) {
                            onEvent({
                                type: DataLayerEventName.moreButtonClick,
                                originator: 'SavedArticleMore',
                                payload: {
                                    publicationId: publication.id,
                                },
                            })
                        }
                        show()
                    }}
                >
                    <IconDotsVertical fill="#161616" />
                </StyledIconDotsVerticalContainer>
                {!isHidden && (
                    <StyledModalContainer>
                        <SavedArticleContextMenuModal
                            publication={publication}
                            hide={hide}
                            onCopyLinkClick={onCopyLinkClick}
                            onEvent={onEvent}
                            onUnsaveClick={onUnsaveClick}
                            savedArticleContextMenuContainer={
                                savedArticleContextMenuContainer
                            }
                        />
                    </StyledModalContainer>
                )}
                <UserSettingsSlideToast
                    text={toast.text}
                    subText={toast.subText}
                    type={toast.type}
                    show={toast.show}
                    onClose={onDismissToast}
                    fixedAtBottom={true}
                />
            </>
        )
    }
