import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../__styling'

export const PuzzleListingContainer = styled.div<{ inApp?: boolean }>(
    ({ inApp = false }) => ({
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        gap: calcRem(40),
        width: '100%',
        maxWidth: calcRem(770),
        padding: inApp ? 0 : calcRem(0, 24),

        transition: 'padding 0.25s',

        [breakpoint('lg')]: {
            padding: 0,
        },
    }),
)
