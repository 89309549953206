import { layout } from '../../App.routing'

export const regionalSegment = layout.component({
    type: 'uniform',
    props: {
        verticalSpacing: 'xl',
        section: 'default',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            ['teaserWithBackground'],
            ['xsImage-mdNoImage-withBG', 'teaser-xsVisible-mdHidden-noImage'],
            ['teaserWithBackground'],
            ['teaser-xsVisible-mdHidden-noImage', 'xsImage-mdNoImage-withBG'],
        ],
        sectionHeader: {
            heading: 'Regional',
            headingUrl: '/news/regional',
        },

        dataDefinitionArgs: {
            type: 'curation',
            name: 'regional',
            offset: 0,
            pageSize: 7,
        },
    },
})
