import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../../feature-toggling'

const LazyTheWestLiveInArticleBannerComponent = loadable(
    () => import('./TheWestLiveInArticleBanner'),
)

export const LazyTheWestLiveInArticleBanner = () => {
    return (
        <FeatureToggle
            feature="the-west-live"
            on={() => <LazyTheWestLiveInArticleBannerComponent />}
        />
    )
}
