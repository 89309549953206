import React from 'react'

export interface IconCrossProps {
    className?: string
    fill?: string
    title?: string
}

export const IconCross: React.FC<IconCrossProps> = ({
    className,
    fill = 'white',
    title,
    ...rest
}) => (
    <svg
        {...rest}
        className={className}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title || 'Cross Icon'}</title>
        <g id="close">
            <path
                id="close_2"
                d="M6.22468 4.81047C5.83416 4.41995 5.201 4.41995 4.81047 4.81047C4.41995 5.201 4.41995 5.83416 4.81047 6.22468L10.5852 11.9994L4.81053 17.7741C4.42001 18.1646 4.42001 18.7978 4.81053 19.1883C5.20106 19.5788 5.83422 19.5788 6.22474 19.1883L11.9994 13.4136L17.7741 19.1883C18.1646 19.5788 18.7978 19.5788 19.1883 19.1883C19.5788 18.7978 19.5788 18.1646 19.1883 17.7741L13.4136 11.9994L19.1884 6.22468C19.5789 5.83416 19.5789 5.201 19.1884 4.81047C18.7978 4.41995 18.1647 4.41995 17.7742 4.81047L11.9994 10.5852L6.22468 4.81047Z"
                fill={fill}
            />
        </g>
    </svg>
)
IconCross.displayName = 'IconCross'
