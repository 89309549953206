import React from 'react'

export interface IconUserProps {
    className?: string
}

export const IconUser: React.FC<IconUserProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 23"
    >
        <g fill="none" strokeLinejoin="round">
            <path
                strokeLinecap="round"
                d="M10 14v-3m-3.478-.766v2.453l-4.356 1.566a2.625 2.625 0 0 0-1.731 2.472v2.087H10.87"
            />
            <path d="M13.043 5.688c0 2.898-1.946 5.25-4.347 5.25-2.402 0-4.348-2.352-4.348-5.25 0-2.9 1.946-5.25 4.348-5.25 2.4 0 4.347 2.35 4.347 5.25z" />
            <path d="M13.003 5.209c-.146.018-.247.012-.394.041-1.481.287-2.432-.253-3.247-1.689-.49.944-2.02 1.69-3.275 1.69-.618 0-1.15-.129-1.683-.409m14.637 14.51l.87-1.515-.87-.507c.12-.504.12-.907 0-1.41l.869-.505-.87-1.515-.868.504a3.035 3.035 0 0 0-1.215-.7v-1.016h-1.74v1.016c-.46.14-.875.377-1.215.7l-.869-.504-.87 1.515.87.504c-.12.504-.12.907 0 1.411l-.87.507.87 1.516.87-.506c.34.323.755.56 1.215.699v1.017h1.739v-1.017c.46-.14.874-.376 1.215-.7l.87.507z" />
            <path d="M17.391 16.625c0 .724-.584 1.313-1.304 1.313-.72 0-1.304-.588-1.304-1.313s.584-1.313 1.304-1.313c.72 0 1.304.588 1.304 1.313z" />
        </g>
    </svg>
)
