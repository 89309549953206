import { NavEvent, NavEventArgs, NavItem } from '@news-mono/web-common'
import React, { useState } from 'react'
import { StyledNavListItem } from '../../../../navigation/FlyoutNav/components/FlyoutNavItem/FlyoutNavItem.styled'
import { FlyoutSubnav } from '../../../../navigation/FlyoutNav/components/FlyoutSubnav/FlyoutSubnav'
import { Section } from '../../../../__styling/settings/sections'
import { FlyoutParentItem } from '../FlyoutParentItem/FlyoutParentItem'

export interface FlyoutNavItemProps {
    navItem: NavItem
    navPos: number
    section: Section
    onClick?: (args: NavEventArgs) => void
    onEvent: (args: NavEvent) => void
    showToggleButton?: boolean
    parentMarker?: () => React.ReactNode
    linkStyle?: 'heading' | 'standard'
    startOpen?: boolean
    isCurrent?: boolean
}

export const FlyoutNavItem: React.FC<FlyoutNavItemProps> = ({
    navItem,
    section,
    parentMarker,
    linkStyle = 'heading', // the default
    showToggleButton,
    onClick,
    navPos,
    startOpen,
    isCurrent,
}) => {
    const [showSubNav, setShowSubNav] = useState(false)

    const toggleSubNav = () => {
        setShowSubNav(!showSubNav)
    }

    const subNavId = `subnav-${navItem.name.replace(' ', '-').toLowerCase()}`

    return (
        <StyledNavListItem key={navItem.name}>
            <FlyoutParentItem
                linkStyle={linkStyle}
                marker={parentMarker}
                showToggle={navItem.subNavLinks && showToggleButton}
                toggleAction={toggleSubNav}
                navItem={navItem}
                onClick={onClick}
                navPos={navPos}
                isOpen={showSubNav}
                subNavId={navItem.subNavLinks && subNavId}
                isCurrent={isCurrent}
            />
            {navItem.subNavLinks && (
                <FlyoutSubnav
                    itemShouldBeVisible={startOpen || showSubNav}
                    section={section}
                    id={subNavId}
                    navItems={navItem.subNavLinks}
                    onClick={onClick}
                    onToggle={toggleSubNav}
                />
            )}
        </StyledNavListItem>
    )
}

FlyoutNavItem.displayName = 'FlyoutNavItem'
