import styled from '@emotion/styled'
import { Button } from '../../buttons/Button/Button'
import { TextLink } from '../../typography/TextLink/TextLink'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins, metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../__styling/style-mixins'

import { getComponentTheme } from '../../__styling'
import { breakpoint, breakpointSwitch } from '../../__styling/style-functions'
import { IconEmailTN } from '../../icons'
import { NewsletterVariants } from './NewsletterSignup.routing'

interface StyledContainerProps {
    verticalSpacing?: keyof ThemeMargins
}
interface NewsletterVariantProps {
    mode: NewsletterVariants
}

export const StyledContainer = styled.div<StyledContainerProps>(
    ({ theme, verticalSpacing }) => {
        const newsletterTheme = getComponentTheme(theme, 'newsletter')
        return [
            {
                marginBottom:
                    verticalSpacing && calcRem(theme.margins[verticalSpacing]),

                position: newsletterTheme.position,
                backgroundPosition: newsletterTheme.backgroundPosition,
                backgroundRepeat: newsletterTheme.backgroundRepeat,
                width: newsletterTheme.width,
            },
            newsletterTheme.breakpointState &&
                breakpointSwitch(
                    newsletterTheme.breakpointState,
                    (styles) => styles,
                ),
        ]
    },
)

export const StyledIcon = styled.div(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'newsletter').icon
    return (
        iconTheme && [
            {
                '& > svg': {
                    fill: colors.white,
                    width: 21,
                    height: 21,
                },
            },
            breakpointSwitch(iconTheme.breakpointState, (styles) => styles),
        ]
    )
})

export const StyledContentContainer = styled.div(({ theme }) => {
    const contentContainerTheme = getComponentTheme(
        theme,
        'newsletter',
    ).contentContainer
    return [
        {
            height: contentContainerTheme.height,
            marginLeft: contentContainerTheme.marginLeft,
            position: contentContainerTheme.position,
            display: contentContainerTheme.display,
            justifyContent: contentContainerTheme.justifyContent,
        },
        breakpointSwitch(
            contentContainerTheme.breakpointState,
            (styles) => styles,
        ),
    ]
})

export const StyledFormContainer = styled.div<NewsletterVariantProps>(
    ({ theme, mode }) => {
        const formContainerTheme = getComponentTheme(
            theme,
            'newsletter',
        ).formContainer
        const sharedStyles = [
            { maxWidth: formContainerTheme.maxWidth },
            breakpointSwitch(
                formContainerTheme.breakpointState,
                (styles) => styles,
            ),
            formContainerTheme.transition && {
                ...formContainerTheme.transition,
            },
        ]

        switch (mode) {
            case 'inline-article':
                return [
                    ...sharedStyles,
                    formContainerTheme.inArticle &&
                        breakpointSwitch(
                            formContainerTheme.inArticle.padding,
                            (padding) => ({
                                padding,
                            }),
                        ),
                    formContainerTheme.inlineArticle &&
                        breakpointSwitch(
                            formContainerTheme.inlineArticle.display,
                            (display) => ({
                                display,
                            }),
                        ),
                    { overflowWrap: 'break-word', wordBreak: 'break-word' },
                ]
            case 'sidebar-article':
                return [
                    ...sharedStyles,
                    formContainerTheme.inArticle &&
                        breakpointSwitch(
                            formContainerTheme.inArticle.padding,
                            (padding) => ({
                                padding,
                            }),
                        ),
                    formContainerTheme.sidebarArticle &&
                        breakpointSwitch(
                            formContainerTheme.sidebarArticle.display,
                            (display) => ({
                                display,
                            }),
                        ),
                ]
            case 'journo-profile':
                return (
                    formContainerTheme.journoProfile && [
                        ...sharedStyles,
                        {
                            width: formContainerTheme.journoProfile.width,
                        },
                        breakpointSwitch(
                            formContainerTheme.journoProfile.padding,
                            (padding) => ({
                                padding,
                            }),
                        ),
                    ]
                )
            case 'default':
            default:
                return [
                    ...sharedStyles,
                    breakpointSwitch(formContainerTheme.width, (width) => ({
                        width,
                    })),
                ]
        }
    },
)

export const StyledTextLink = styled(TextLink)({
    color: colors.white,
})

export const StyledText = styled.p(({ theme }) => {
    const textTheme = getComponentTheme(theme, 'newsletter').text
    return [
        {
            color: textTheme.color,
            fontWeight: textTheme.fontWeight,
            fontFamily: textTheme.fontFamily,
            margin: textTheme.margin,
            textAlign: textTheme.textAlign,
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
        },
        breakpointSwitch(textTheme.breakpointState, (styles) => styles),
    ]
})

export const StyledSubText = styled.p(({ theme }) => {
    const subTextTheme = getComponentTheme(theme, 'newsletter').subText
    return (
        subTextTheme && {
            color: subTextTheme.color,
            fontFamily: subTextTheme.fontFamily,
            fontSize: subTextTheme.fontSize,
            fontWeight: subTextTheme.fontWeight,
            lineHeight: subTextTheme.lineHeight,
            textAlign: subTextTheme.textAlign,
            margin: subTextTheme.margin,
        }
    )
})

export const StyledForm = styled.form<NewsletterVariantProps>(
    ({ theme, mode }) => {
        const formTheme = getComponentTheme(theme, 'newsletter').form

        const sharedProps = {
            display: formTheme.display,
            width: formTheme.width,
            gap: formTheme.gap,
            height: formTheme.height,
        }

        switch (mode) {
            case 'sidebar-article':
                return [
                    {
                        ...sharedProps,
                        flexDirection: formTheme.sidebarArticle?.flexDirection,
                    },
                ]
            case 'default':
            default:
                return [
                    { ...sharedProps },
                    breakpointSwitch(
                        formTheme.breakpointState,
                        (styles) => styles,
                    ),
                ]
        }
    },
)

export const StyledLabel = styled('label')(visuallyhidden)

export const StyledInputWrapper = styled.div<NewsletterVariantProps>(
    ({ theme, mode }) => {
        const inputWrapperTheme = getComponentTheme(
            theme,
            'newsletter',
        ).inputWrapper

        const sharedProps = inputWrapperTheme && [
            {
                padding: inputWrapperTheme.padding,
                background: inputWrapperTheme.backgroundColor,
                border: inputWrapperTheme.border,
                borderRadius: inputWrapperTheme.borderRadius,
                fontSize: inputWrapperTheme.fontSize,
                display: inputWrapperTheme.display,
                alignItems: inputWrapperTheme.alignItems,
                gap: inputWrapperTheme.gap,
                color: inputWrapperTheme.color,
            },
            inputWrapperTheme.focus && {
                '&:focus-within': {
                    outline: inputWrapperTheme.focus.outline,
                },
            },
        ]

        switch (mode) {
            case 'inline-article':
            case 'sidebar-article':
                return (
                    sharedProps && [
                        ...sharedProps,
                        {
                            width: inputWrapperTheme.inArticle.width,
                        },
                    ]
                )
            case 'default':
            default:
                return (
                    sharedProps && [
                        ...sharedProps,
                        breakpointSwitch(inputWrapperTheme.width, (width) => ({
                            width,
                        })),
                    ]
                )
        }
    },
)

export const StyledEmailIcon = styled(IconEmailTN)(({ theme }) => {
    return {
        fill: 'none',
        width: 24,
        height: 24,
    }
})

export const StyledInput = styled.input(({ theme }) => {
    const inputTheme = getComponentTheme(theme, 'newsletter').input
    return [
        {
            marginRight: inputTheme.marginRight,
            padding: inputTheme.padding,
            height: inputTheme.height,
            border: inputTheme.border,
            borderRadius: inputTheme.borderRadius,
            fontSize: inputTheme.fontSize,
            backgroundColor: inputTheme.backgroundColor,
            lineHeight: inputTheme.lineHeight,
            color: inputTheme.color,
        },
        inputTheme.focus && {
            '&:focus': {
                outline: inputTheme.focus.outline,
            },
        },
        breakpointSwitch(inputTheme.breakpointState, (styles) => styles),
    ]
})

export const StyledButton = styled.button<NewsletterVariantProps>(
    ({ theme, mode }) => {
        const buttonTheme = getComponentTheme(theme, 'newsletter').button
        const sharedProps = [
            {
                margin: buttonTheme.margin,
                padding: buttonTheme.padding,
                height: buttonTheme.height,
                border: buttonTheme.border,
                color: buttonTheme.color,
                maxWidth: buttonTheme.maxWidth,
                borderRadius: buttonTheme.borderRadius,
                backgroundColor: buttonTheme.backgroundColor,
                lineHeight: buttonTheme.lineHeight,
                fontWeight: buttonTheme.fontWeight,
                textTransform: buttonTheme.textTransform,
                flex: buttonTheme.flex,
                cursor: 'pointer',
                fontFamily: buttonTheme.fontFamily,

                ...buttonTheme.transition,
            },
            buttonTheme.fontSize &&
                breakpointSwitch(buttonTheme.fontSize, (fontSize) => ({
                    fontSize,
                })),
            buttonTheme.textCrop && buttonTheme.textCrop,
            {
                '&:hover': {
                    backgroundColor: buttonTheme.hover.backgroundColor,
                    color: buttonTheme.hover.color,
                    textDecoration: buttonTheme.hover.textDecoration,
                    border: buttonTheme.hover.border,
                    opacity: buttonTheme.hover.opacity,
                },
            },
            {
                '&:focus': {
                    backgroundColor: buttonTheme.focus.backgroundColor,
                    color: buttonTheme.focus.color,
                    textDecoration: buttonTheme.focus.textDecoration,
                    border: buttonTheme.focus.border,
                    opacity: buttonTheme.focus.opacity,
                },
            },
            {
                '&:disabled': {
                    pointerEvents: buttonTheme.disabled.pointerEvents,
                    cursor: buttonTheme.disabled.cursor,
                    opacity: buttonTheme.disabled.opacity,
                },
            },
        ]
        switch (mode) {
            case 'sidebar-article':
                return [
                    ...sharedProps,
                    {
                        width: buttonTheme.sidebarArticle?.width,
                    },
                ]
            case 'default':
            default:
                return [
                    ...sharedProps,
                    buttonTheme.width &&
                        breakpointSwitch(buttonTheme.width, (width) => ({
                            width,
                        })),
                ]
        }
    },
)

export const StyledTermsText = styled.span(({ theme }) => {
    const termsTextTheme = getComponentTheme(theme, 'newsletter').termsText
    return (
        termsTextTheme && {
            color: termsTextTheme.color,
            fontSize: termsTextTheme.fontSize,
            fontWeight: termsTextTheme.fontWeight,
            lineHeight: termsTextTheme.lineHeight,
            width: termsTextTheme.width,
            textAlign: termsTextTheme.textAlign,

            a: {
                textDecoration: termsTextTheme.a.textDecoration,
                color: termsTextTheme.a.color,
            },
        }
    )
})

export const StyledInlineContainer = styled.div(({ theme }) => {
    return {
        paddingBottom: metrics.thenightly.articleBlockMargin,
        [breakpoint('lg')]: {
            paddingBottom: 0,
        },
    }
})
