import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { calcRem, colors, fonts } from '../..'
import { StyledSkeleton } from '../../content/Skeleton/Skeleton.styled'
import { breakpoint, themedValue } from '../../__styling'

export const MOBILE_CARD_WIDTH = 278
export const MOBILE_CARD_HEIGHT = 484
export const TABLET_CARD_WIDTH = 267
export const TABLET_CARD_HEIGHT = 465
export const DESKTOP_CARD_WIDTH = 218
export const DESKTOP_CARD_HEIGHT = 380

// PN and TW have standard card dimensions across all viewports.
export const PERTHNOW_CARD_WIDTH = 217
export const PERTHNOW_CARD_HEIGHT = 387

export const THEWEST_CARD_WIDTH = 232
export const THEWEST_CARD_HEIGHT = 411

export const CARD_MARGIN = 15

export const StyledCarouselCard = styled('div')<{}>(({ theme }) => [
    {
        flexShrink: 0,
        cursor: 'pointer',
        position: 'relative',
        height:
            theme.kind === Product.SevenNews
                ? calcRem(MOBILE_CARD_HEIGHT)
                : theme.kind === Product.PerthNow
                ? calcRem(PERTHNOW_CARD_HEIGHT)
                : calcRem(THEWEST_CARD_HEIGHT),
        width:
            theme.kind === Product.SevenNews
                ? calcRem(MOBILE_CARD_WIDTH)
                : theme.kind === Product.PerthNow
                ? calcRem(PERTHNOW_CARD_WIDTH)
                : calcRem(THEWEST_CARD_WIDTH),
        filter: 'drop-shadow(0px 1.94663px 3.89326px rgba(0, 0, 0, 0.25))',
        background:
            'linear-gradient(179.94deg, rgba(0, 0, 0, 0.04) 16.03%, #000000 83.93%, #000000 99.95%)',
        margin: calcRem(20, 0),
    },
    theme.kind === Product.SevenNews && {
        [breakpoint('md')]: {
            height: calcRem(TABLET_CARD_HEIGHT),
            width: calcRem(TABLET_CARD_WIDTH),
        },

        [breakpoint('lg')]: {
            height: calcRem(DESKTOP_CARD_HEIGHT),
            width: calcRem(DESKTOP_CARD_WIDTH),
        },
    },
])

export const StyledCarouselCardImage = styled('img')({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    boxShadow: '0px 2px 8px rgba(0,0,0,0.2)',
    borderRadius: 6,
})

export interface StyledCardHeadWrapperProps {
    fromBottom?: boolean
}

export const StyledCardHeadWrapper = styled('div')<StyledCardHeadWrapperProps>(
    ({ fromBottom }) => ({
        position: 'absolute',
        top: fromBottom ? undefined : `calc(50% + ${calcRem(50)})`,
        bottom: fromBottom ? calcRem(48) : undefined,
        left: 0,
        width: `calc(100% - ${calcRem(13)})`,
        zIndex: 1,
        padding: calcRem(0, 15),
        [breakpoint('lg')]: {
            top: fromBottom ? undefined : `calc(45% + ${calcRem(30)})`,
        },
    }),
)

export const StyledEntryPointCardHeadline = styled('span')(({ theme }) => [
    {
        color: colors.white,
        fontWeight: themedValue(theme, {
            sevennews: 400,
            fallback: 700,
        }),
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            thewest: theme.fonts.serif,
            perthnow: theme.fonts.sansSerifCond,
            fallback: undefined,
        }),
        fontSize: themedValue(theme, {
            sevennews: calcRem(24),
            fallback: calcRem(22),
        }),
        lineHeight: themedValue(theme, {
            sevennews: calcRem(30),
            fallback: calcRem(24),
        }),
    },
    theme.kind === Product.SevenNews && {
        [breakpoint('md')]: {
            fontSize: calcRem(20),
            lineHeight: '23px',
        },
    },
])

export const StyledStoryTagWrapper = styled('div')({
    marginBottom: calcRem(10),
})

export const StyledStoryTag = styled('span')(({ theme }) => ({
    background: theme.colors.brand,
    color: colors.white,
    textTransform: 'uppercase',
    lineHeight: themedValue(theme, {
        thewest: calcRem(16),
        perthnow: calcRem(16),
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.sansSerif,
        perthnow: theme.fonts.sansSerif,
        fallback: undefined,
    }),
    padding: themedValue(theme, {
        sevennews: calcRem(3, 7),
        fallback: calcRem(2, 4),
    }),
    letterSpacing: themedValue(theme, {
        sevennews: '2px',
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        thewest: 400,
        perthnow: 600,
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(12),
        thewest: calcRem(14),
        perthnow: calcRem(13),
        fallback: undefined,
    }),
}))

export const StyledStoryOverlay = styled('img')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
})

export const StyledCardSkeleton = styled(StyledSkeleton)<{
    uniformHeight?: boolean
}>(() => ({
    width: calcRem(MOBILE_CARD_WIDTH),
    height: calcRem(MOBILE_CARD_HEIGHT),
    marginRight: calcRem(CARD_MARGIN),
    borderRadius: '16px',
    [breakpoint('md')]: {
        width: calcRem(TABLET_CARD_WIDTH),
        height: calcRem(TABLET_CARD_HEIGHT),
    },
    [breakpoint('lg')]: {
        width: calcRem(DESKTOP_CARD_WIDTH),
        height: calcRem(DESKTOP_CARD_HEIGHT),
    },
}))
