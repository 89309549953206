import styled from '@emotion/styled'
import { Product, withClass } from '@news-mono/web-common'
import { easing } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const videoClassname = 'video-specificity'
export const pnVideoClassname = 'pn-video-specificity'

interface StyledVideoProps {
    posterImage?: string
}

export const StyledWrapper = styled('div')<{
    compactMode?: boolean
    hide?: boolean
    isShowMore?: boolean
    isVideoHub?: boolean
}>(
    {},
    ({ theme, compactMode, hide }) => ({
        backgroundColor: themedValue(theme, {
            sevennews: compactMode ? colors.black : undefined,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            sevennews: compactMode
                ? `${calcRem(theme.margins.sm)} ${calcRem(
                      theme.margins.sm,
                  )} 0 ${calcRem(theme.margins.sm)}`
                : undefined,
            fallback: undefined,
        }),
        position: 'relative',
        display: hide ? 'none' : 'block',
    }),
    ({ theme, isShowMore, hide, isVideoHub }) =>
        theme.kind !== Product.PerthNow &&
        isVideoHub && {
            display: hide ? 'none' : 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            transition: `height ${easing.easeOut.fast}`,
            [breakpoint('md')]: {
                flexDirection: 'row',
                height: themedValue(theme, {
                    sevennews: 'auto',
                    fallback: undefined,
                }),
                maxHeight: themedValue(theme, {
                    sevennews: isShowMore ? calcRem(720) : calcRem(1500),
                    thewest: calcRem(640),
                    fallback: undefined,
                }),
            },
        },
)

export const StyledStickyCloseButton = styled('button')({
    display: 'none',
    position: 'absolute',
    padding: calcRem(5, 7),
    top: calcRem(4),
    right: calcRem(1),
    height: calcRem(26),
    outline: 'none',
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'unset',

    '&:hover': {
        backgroundColor: colors.black,
        outline: `solid 1px ${colors.white}`,
    },
})

export const StyledVideo = withClass(videoClassname)(
    styled('div')<StyledVideoProps>(
        {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: colors.black,

            padding: '0 0 56.25%',
            minHeight: '80px',

            position: 'relative',
            overflow: 'hidden',
            [breakpoint('sm')]: {
                minHeight: '140px',
            },
            '.collection__card-media > &': {
                padding: 'auto',
                minHeight: 'unset',

                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                overflow: 'hidden',
            },

            // Show sticky close button on hover
            '&:hover': {
                [`& ${StyledStickyCloseButton}`]: {
                    display: 'block',
                },
            },
        },
        (props) => ({
            backgroundImage: props.posterImage && `url(${props.posterImage})`,
        }), // custom big play button
        {
            // the video className is added before the contextual selector to ensure specificity in ie10
            [`&.${videoClassname} .vjs-big-play-button`]: {
                display: 'none',
            },

            [`&.${videoClassname} .vjs-big-play-button .vjs-control-text`]: {
                position: 'relative',
                overflow: 'visible',
                clip: 'auto',
                margin: 0,
                padding: 0,
                border: 0,
                display: 'none',
            },
        },
    ),
)
StyledVideo.displayName = 'StyledVideo'

export const StyledVideoErrorBoundary = styled('div')({
    textAlign: 'center',
    button: {
        display: 'inline-block',
    },
})
StyledVideoErrorBoundary.displayName = 'StyledVideoErrorBoundary'
