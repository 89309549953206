/* eslint-disable no-redeclare */
import { IsHydrating } from '@news-mono/web-common'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import React from 'react'

/**
 * - resize: viewport width
 * - scroll: velocity in px per second
 * - scrollend: position where scroll has ended
 * - visibility: document is visible
 * - click: click occured
 * - mousemove: mouse moved
 * - keypress: key pressed
 * - touchstart: touch started
 */
export type EventListener = (value?: any) => void

export interface ScrollProps {
    velocity: number
    lastScrollPosition: number
}

export type ScrollEventListener = (value: ScrollProps) => void

export type DomEventTarget = 'window' | 'document'

export interface EventDefinition {
    active: boolean
    target: DomEventTarget
    events: string[]
    handler: () => void
    listeners: EventListener[]
}

export interface ScrollEventDefinition extends EventDefinition {
    listeners: ScrollEventListener[]
}

export interface EventDefinitions {
    click: EventDefinition
    mousemove: EventDefinition
    keypress: EventDefinition
    touchstart: EventDefinition
    resize: EventDefinition
    scroll: ScrollEventDefinition
    scrollend: ScrollEventDefinition
    visibility: EventDefinition
    keydown: EventDefinition
}

// We use this to debounce/throttle event listeners that are fired very often.
// Changing this will e.g. influence how often lazy loading for ads is triggered.
// We chose 250ms as a middle ground between fine granularity and performance impact
const eventIntervalPeriod = 250 // ms

let lastScrollPosition = 0 // px

const measureScrollVelocity = () => {
    const scrollPosition = window.pageYOffset
    const velocity =
        ((scrollPosition - lastScrollPosition) * 1000) / eventIntervalPeriod // px/s
    lastScrollPosition = scrollPosition
    return { velocity, lastScrollPosition }
}

const generateEventDefinition = (
    target: DomEventTarget,
    events: string[],
    handler: (evt?: Event) => void,
): EventDefinition => ({
    active: false,
    target,
    events,
    handler,
    listeners: [],
})

export const eventDefinitions: EventDefinitions = {
    click: generateEventDefinition(
        'window',
        ['click'],
        throttle(() => {
            eventDefinitions.click.listeners.forEach((listener) => listener())
        }, eventIntervalPeriod),
    ),
    touchstart: generateEventDefinition(
        'window',
        ['touchstart'],
        throttle(() => {
            eventDefinitions.touchstart.listeners.forEach((listener) =>
                listener(),
            )
        }, eventIntervalPeriod),
    ),
    keypress: generateEventDefinition(
        'window',
        ['keypress'],
        throttle((evt?: Event) => {
            eventDefinitions.keypress.listeners.forEach((listener) =>
                listener(evt as KeyboardEvent),
            )
        }, eventIntervalPeriod),
    ),
    keydown: generateEventDefinition(
        'window',
        ['keydown'],
        throttle((evt?: Event) => {
            eventDefinitions.keydown.listeners.forEach((listener) =>
                listener(evt as KeyboardEvent),
            )
        }, eventIntervalPeriod),
    ),
    mousemove: generateEventDefinition(
        'window',
        ['mousemove'],
        throttle(() => {
            eventDefinitions.mousemove.listeners.forEach((listener) =>
                listener(),
            )
        }, eventIntervalPeriod),
    ),
    resize: generateEventDefinition(
        'window',
        ['resize', 'orientationchange'],
        debounce(() => {
            eventDefinitions.resize.listeners.forEach((listener) =>
                listener(window.innerWidth),
            )
        }, eventIntervalPeriod),
    ),
    scroll: generateEventDefinition(
        'window',
        ['scroll'],
        throttle(() => {
            const scrollEventValue = measureScrollVelocity()
            eventDefinitions.scroll.listeners.forEach((listener) =>
                listener(scrollEventValue),
            )
        }, eventIntervalPeriod),
    ),
    scrollend: generateEventDefinition(
        'window',
        ['scrollend'],
        throttle(() => {
            const scrollEventValue = measureScrollVelocity()

            eventDefinitions.scrollend.listeners.forEach((listener) =>
                listener(scrollEventValue),
            )
        }, eventIntervalPeriod),
    ),
    visibility: generateEventDefinition(
        'document',
        ['visibilitychange'],
        () => {
            eventDefinitions.visibility.listeners.forEach((listener) =>
                listener(!document.hidden),
            )
        },
    ),
}

export type EventType = keyof EventDefinitions

const updateGlobalListeners = (
    eventType: EventType,
    shouldActivate: boolean,
) => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
        return
    }

    const eventDefinition = eventDefinitions[eventType]
    const { active, events, target, handler, listeners } = eventDefinition

    let eventTarget: EventTarget

    switch (target) {
        case 'document':
            eventTarget = document
            break
        case 'window':
        default:
            eventTarget = window
    }

    if (shouldActivate && !active) {
        events.forEach((event) => {
            eventTarget.addEventListener(event, handler, false)
        })
        eventDefinition.active = true

        if (eventType === 'scroll') {
            lastScrollPosition = window.pageYOffset
        }
    } else if (!shouldActivate && active && !listeners.length) {
        events.forEach((event) => {
            eventTarget.removeEventListener(event, handler, false)
        })
        eventDefinition.active = false
    }
}

export function addListener(
    eventType: 'scroll',
    listener: ScrollEventListener,
): void
export function addListener(eventType: EventType, listener: EventListener): void
export function addListener(eventType: EventType, listener: any): void {
    eventDefinitions[eventType].listeners.push(listener)
    updateGlobalListeners(eventType, true)
}

export function removeListener(
    eventType: 'scroll',
    listener: ScrollEventListener,
): void
export function removeListener(
    eventType: EventType,
    listener: EventListener,
): void
export function removeListener(eventType: EventType, listener: any) {
    const listeners = eventDefinitions[eventType].listeners
    const index = listeners.indexOf(listener)
    if (index !== -1) {
        listeners.splice(index, 1)
    }
    updateGlobalListeners(eventType, false)
}

export function useWindowWidth() {
    const isHydrating = React.useContext(IsHydrating)
    const [width, setWidth] = React.useState(
        isHydrating ? undefined : window.innerWidth,
    )
    const resizeCallback = React.useCallback(() => {
        setWidth(window.innerWidth)
    }, [])

    React.useEffect(() => {
        setWidth(window.innerWidth)
        addListener('resize', resizeCallback)

        return () => removeListener('resize', resizeCallback)
    }, [resizeCallback])

    return width
}
