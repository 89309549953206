import React from 'react'
import { StyledTeaser } from '../../cards/CardText/CardText.styled'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { useProduct } from '../../__product/useProduct'

export type TeaserState = 'hidden' | 'visible'
export type TeaserBreakpoints = 'initial' | 'xs' | 'sm' | 'md' | 'lg'
export type TeaserMode =
    | TeaserState
    | Partial<Record<TeaserBreakpoints, TeaserState>>

export interface CardTeaserProps {
    hasBackground?: boolean
    className?: string
    teaserMode: TeaserMode
    teaserOrSkeletonLines: string | number
}

const H3StyledTeaser = StyledTeaser.withComponent('h3')

export const CardTeaser: React.FC<CardTeaserProps> = ({
    hasBackground,
    className,
    teaserOrSkeletonLines,
    teaserMode,
}) => {
    const product = useProduct()

    if (teaserMode === 'hidden') {
        return null
    }

    const hasBackgroundColor =
        (product === 'perthnow' || product === 'sevennews') && hasBackground
    const Teaser = product === 'sevennews' ? H3StyledTeaser : StyledTeaser

    if (typeof teaserOrSkeletonLines === 'number') {
        return (
            <Teaser cardHasBackground={hasBackground}>
                <Skeleton
                    count={teaserOrSkeletonLines || 1}
                    dark={hasBackgroundColor || false}
                />
            </Teaser>
        )
    }
    return (
        <Teaser
            className={className}
            cardHasBackground={hasBackground}
            teaserMode={teaserMode}
        >
            {teaserOrSkeletonLines}
        </Teaser>
    )
}
CardTeaser.displayName = 'CardTeaser'
