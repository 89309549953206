import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInWeeks,
    format,
} from 'date-fns'
import { ReadableTime } from './types'
import {
    DEFAULT_DATE_FORMAT,
    JUST_NOW_DELTA,
    JUST_NOW_MESSAGE,
} from './constants'
import differenceInSeconds from 'date-fns/differenceInSeconds'

// Check if the date is in the past and return an empty string.
export const pastCheck: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInSeconds = differenceInSeconds(comparisonDate, publicationDate)

    return {
        toString: '',
        predicate: pastCheckPredicate(deltaInSeconds),
    }
}

export const justNow: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInMinutes = differenceInMinutes(comparisonDate, publicationDate)

    return {
        toString: JUST_NOW_MESSAGE,
        predicate: justNowPredicate(deltaInMinutes),
    }
}

export const lessThanAnHour: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInMinutes = differenceInMinutes(comparisonDate, publicationDate)

    return {
        toString: `${deltaInMinutes} mins ago`,
        predicate: lessThanAnHourPredicate(deltaInMinutes),
    }
}

export const anHour: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInHours = differenceInHours(comparisonDate, publicationDate)

    return {
        toString: '1 hour ago',
        predicate: anHourPredicate(deltaInHours),
    }
}

export const moreThanAnHour: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInHours = differenceInHours(comparisonDate, publicationDate)

    return {
        toString: `${deltaInHours} hours ago`,
        predicate: moreThanAnHourPredicate(deltaInHours),
    }
}

export const aDay: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInDays = differenceInDays(comparisonDate, publicationDate)

    return {
        toString: '1 day ago',
        predicate: aDayPredicate(deltaInDays),
    }
}

export const moreThanADay: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInDays = differenceInDays(comparisonDate, publicationDate)

    return {
        toString: `${deltaInDays} days ago`,
        predicate: moreThanADayPredicate(deltaInDays),
    }
}

export const aWeek: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInDays = differenceInWeeks(comparisonDate, publicationDate)

    return {
        toString: '1 week ago',
        predicate: aWeekPredicate(deltaInDays),
    }
}

export const moreThanAWeek: ReadableTime = (
    publicationDate: Date,
    comparisonDate: Date,
) => {
    const deltaInDays = differenceInWeeks(comparisonDate, publicationDate)

    return {
        toString: `${deltaInDays} weeks ago`,
        predicate: moreThanAWeekPredicate(deltaInDays),
    }
}

export const catchAll: ReadableTime = (
    publicationDate: Date,
    _comparisonDate: Date,
    dateFormat = DEFAULT_DATE_FORMAT,
) => {
    return {
        toString: format(publicationDate, dateFormat),
        predicate: true,
    }
}

const pastCheckPredicate = (deltaInSeconds: number) => {
    return deltaInSeconds < 0
}

const justNowPredicate = (deltaInMinutes: number) => {
    return deltaInMinutes <= JUST_NOW_DELTA
}

const lessThanAnHourPredicate = (deltaInMinutes: number) => {
    return deltaInMinutes < 60
}

const anHourPredicate = (deltaInHours: number) => {
    return deltaInHours === 1
}

const moreThanAnHourPredicate = (deltaInHours: number) => {
    return deltaInHours <= 24
}

const aDayPredicate = (deltaInDays: number) => {
    return deltaInDays === 1
}

const moreThanADayPredicate = (deltaInDays: number) => {
    return deltaInDays <= 6
}

const aWeekPredicate = (deltaInWeeks: number) => {
    return deltaInWeeks === 1
}

const moreThanAWeekPredicate = (deltaInWeeks: number) => {
    return deltaInWeeks <= 4
}
