import styled from '@emotion/styled'
import {
    breakpointMax,
    calcRem,
    colors,
    metrics,
} from '@news-mono/component-library'
import { AppState } from '@news-mono/web-common'
import { useSelector } from 'react-redux'

export interface Props {
    isAmpRendered?: boolean
}

export const StyledBaseGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: '100%',
    position: 'relative',
    width: '100%',
    margin: '0 auto',
})

export const StyledAmpContent = styled('div')<Props>({
    width: '100%',
    maxWidth: metrics.thewest.ampContentWidth,
    padding: calcRem(metrics.thewest.margins.md),
    margin: '0 auto',
})

interface StyledCellProps {
    hasBackground?: boolean
}

export const StyledCell = styled('div')<StyledCellProps>(
    ({ hasBackground }) => {
        return {
            display: 'block',
            backgroundColor: hasBackground
                ? colors.thewest.greyMouse
                : undefined,
        }
    },
)

export const StyledFooterCell = styled(StyledCell)<{
    disableStickyFooterAd: boolean
}>(({ disableStickyFooterAd }) => {
    const { isLive } = useSelector((state: AppState) => state.theWestLive)
    // DPT-1778 offset for west live based on scheduling
    const radioOffset = isLive ? 69 : undefined
    const stickyFooterAdUnitHeight = 60

    if (disableStickyFooterAd) {
        return {
            backgroundColor: colors.thewest.greyDolphin,
            paddingBottom: 0,
        }
    }

    return {
        backgroundColor: colors.thewest.greyDolphin,
        paddingBottom: radioOffset && calcRem(radioOffset),
        // DPT-856: Spacing for sticky footer ad-unit
        [breakpointMax('sm')]: {
            paddingBottom: radioOffset
                ? calcRem(radioOffset + stickyFooterAdUnitHeight)
                : calcRem(stickyFooterAdUnitHeight),
        },
    }
})

export const StyledNestedGrid = styled('div')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
})

export const StyledNestedCell = styled('div')({
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: metrics.thewest.siteMetrics.mainContentWidth,
    padding: `0 ${metrics.thewest.margins.outerMargin}px`,
    margin: '0 auto',
})
