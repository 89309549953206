/* eslint-disable @typescript-eslint/no-var-requires */
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoute,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/Landing_page_Banner_Desktop@2x.jpg')
const bannerDesktopX1 = require<string>('./assets/Landing_page_Banner_Desktop@1x.jpg')
const bannerTabletX2 = require<string>('./assets/Landing_page_Banner_Tablet@2x.jpg')
const bannerTabletX1 = require<string>('./assets/Landing_page_Banner_Tablet@1x.jpg')
const bannerMobileX2 = require<string>('./assets/Landing_page_Banner_Mobile@2x.jpg')
const bannerMobileX1 = require<string>('./assets/Landing_page_Banner_Mobile@1x.jpg')
const socialImage = require('./assets/social.jpg')

export const getSocialImageMeta = (image: string) => [
    {
        property: 'og:image',
        content: image,
    },
    { name: 'twitter:image', content: image },
    {
        property: 'og:image:height',
        content: '628',
    },
    {
        property: 'og:image:width',
        content: '1200',
    },
]

const madMondayRouteInfo: StaticRoute<TheWestSection> = ({
    getAdTargeting,
    config,
    store,
    resolution,
}) => {
    const enableRoute = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'mad-monday-route',
    )

    if (!enableRoute) {
        return null
    }

    const canonicalUrl = config.publicUrl + '/sport/afl/mad-monday'

    const topic: Topic = {
        id: getResolvedTopicPageMeta(resolution)?.id || 'sport/afl/mad-monday',
        metadata: {},
        title:
            getResolvedTopicPageMeta(resolution)?.title ||
            getResolvedTopicPageMeta(resolution)?.seoTitle ||
            '',
        seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
        seoDescription:
            getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
    }

    const customTopicForAds = {
        id: 'sport/afl/mad-monday',
        title: 'Mad Monday',
        parent: {
            id: 'sport',
            title: 'Sport',
            metadata: {},
            seoTitle: 'Sport',
        },
        metadata: {},
        seoTitle: 'Mad Monday',
    }

    return {
        kind: 'page',
        heading: topic.title,
        pageType: 'topic',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: topic.title,
            description: topic.seoDescription,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            meta: getSocialImageMeta(socialImage),
        },
        socialMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
        },
        adTargeting: getAdTargeting('home', 'default', customTopicForAds),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: bannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText: 'Mad Monday',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: bannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerDesktopX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: bannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerTabletX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: bannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: bannerMobileX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'janus',
                            props: {
                                layoutRatio: '3:1',
                                stretchColumns: true,
                                verticalSpacing: 'xl',
                            },
                            contentAreas: {
                                left: [
                                    layout.component({
                                        type: 'sierra',
                                        props: {
                                            fixedRatios: ['16:9'],
                                            heroImageLayout:
                                                ResponsivePictureLayout.ObjectFitContain,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                                maxBreakpoint: 'md',
                                            },
                                            cardLayout: ['hero-video'],
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: [
                                                    'sport/afl/mad-monday',
                                                ],
                                                paging: {
                                                    page: 1,
                                                    pageSize: 1,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                right: [
                                    layout.component({
                                        type: 'tw-mad-monday-promo-card',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/afl/mad-monday'],
                                    paging: {
                                        pageSize: 4,
                                        page: 1,
                                        pageOffset: 1,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'md',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/afl/mad-monday'],
                                    paging: {
                                        pageSize: 4,
                                        page: 2,
                                    },
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const getMadMondayRouteInfo: StaticRoutes<TheWestSection> = {
    '/sport/afl/mad-monday': madMondayRouteInfo,
}
