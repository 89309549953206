import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Zeus } from '../../compositions/Zeus/Zeus'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'

export interface ZeusRouteProps extends BaseCompositionProps {
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | keyof ThemeMargins
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent?: boolean
}

export const ZeusRegistration = createRegisterableComposition<
    'main' | 'sidebar'
>()(
    'zeus',
    (contentAreas, props: ZeusRouteProps) => (
        <ContentWrapper
            stretchColumns={props.stretchColumns}
            stretchSelf={props.stretchSelf}
        >
            <Zeus
                {...props}
                mainContent={contentAreas.main}
                sidebarContent={contentAreas.sidebar}
            />
        </ContentWrapper>
    ),
    (
        contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: ZeusRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        if (contentArea === 'main') {
            return {
                containerWidthRatios: {
                    desktop: 0.66 * containerWidthRatios.desktop,
                    tablet: 0.66 * containerWidthRatios.tablet,
                    mobile: 1 * containerWidthRatios.mobile,
                },
            }
        }

        return {
            containerWidthRatios: {
                desktop: 0.33 * containerWidthRatios.desktop,
                tablet: 0.33 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
