import { AdState } from '@news-mono/web-common'
import React from 'react'
import { StyledDebugAdUnitList } from './DebugAdUnitsList.styled'
import { DebugAdUnitsTable } from './DebugAdUnitsTable'

export const AdsByBreakpoint: React.FC<{ ads: AdState; pageAds: string }> = ({
    ads,
    pageAds,
}) => {
    return (
        <StyledDebugAdUnitList>
            {ads.breakpoints.map((rangeStart, i) => {
                const rangeEnd = ads.breakpoints[i + 1]

                return (
                    <React.Fragment key={rangeStart}>
                        <h2 id="ads-overview">
                            Ads Overview (width {'>='} {rangeStart}
                            {rangeEnd ? ` & < ${rangeEnd}` : ''})
                            {rangeStart > window.innerWidth &&
                            rangeEnd &&
                            ads.breakpoints[ads.breakpoints.length - 1] ===
                                rangeStart &&
                            rangeEnd < window.innerWidth
                                ? '[Current]'
                                : ''}
                        </h2>
                        <h3>Site ads</h3>
                        <DebugAdUnitsTable
                            adState={ads}
                            ads={ads.definition.filter((ad) => {
                                return ad.isSitewideAd
                            })}
                            forBreakpoint={rangeStart}
                        />
                        <h3>{pageAds}</h3>
                        <DebugAdUnitsTable
                            adState={ads}
                            ads={ads.definition.filter(
                                (ad) => !ad.isSitewideAd,
                            )}
                            forBreakpoint={rangeStart}
                        />
                    </React.Fragment>
                )
            })}
        </StyledDebugAdUnitList>
    )
}
AdsByBreakpoint.displayName = 'AdsByBreakpoint'
