import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoute, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../get-topic-sponsor'

export const getLatestRouteInfo =
    (topic: Topic): StaticRoute<TheWestSection> =>
    ({ getAdTargeting }) => ({
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        pageMeta: {
            title: 'Latest News',
        },
        adTargeting: getAdTargeting('home', 'default', topic),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'zeus',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    hasBackgroundFill: true,
                    stretchSelf: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'lima',
                            props: {
                                remainingCardLayout: {
                                    type: 'landscape',
                                    format: 'landscape-teaser',
                                },
                                cardLayout: [
                                    [
                                        {
                                            type: 'landscape',
                                            format: 'landscape-teaser',
                                        },
                                    ],
                                ],
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 1,
                                finalColumns: 1,
                                hasBackground: true,
                                timestamp: 'rounded-in-kicker',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [],
                                    includeSubTopics: true,
                                    paging: {
                                        page: 1,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'latestnews-leaderboard',
                                    size: 'leaderboardResponsive',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                remainingCardLayout: {
                                    type: 'landscape',
                                    format: 'landscape-teaser',
                                },
                                cardLayout: [
                                    [
                                        {
                                            type: 'landscape',
                                            format: 'landscape-teaser',
                                        },
                                    ],
                                ],
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 1,
                                finalColumns: 1,
                                hasBackground: true,
                                loadMoreEnabled: true,
                                timestamp: 'rounded-in-kicker',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [],
                                    includeSubTopics: true,
                                    paging: {
                                        page: 2,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                    ],
                    sidebar: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                stickyOffset: 155,
                                fillContainer: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'below-center',
                                            padding: [0, 0, 13, 0],
                                            slot: {
                                                id: 'sidebar-top',
                                                size: 'desktopMrecHalfPage',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: false,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'thor',
                            props: {
                                hasBackgroundFill: false,
                                containerWidth: '100%',
                                verticalGutters: false,
                                horizontalGutters: true,
                            },
                            contentAreas: {
                                main: [...billboardLeaderboardMrec('one')],
                            },
                        }),
                    ],
                },
            }),
        ],
    })
