import React from 'react'

interface IconStarProps {
    title?: string
    className?: string
}

export const IconStar: React.FC<IconStarProps> = ({ title, className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 15"
            width="16"
            height="15"
            role="img"
            className={className}
        >
            <title>{title} || Star</title>
            <path
                fill="currentColor"
                d="M8 12.1l4.9 2.9-1.3-5.5L16 5.7l-5.8-.5L8 0 5.8 5.2 0 5.7l4.4 3.8L3.1 15z"
            />
        </svg>
    )
}
