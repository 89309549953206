import {
    createSlug,
    VideoMetaDTO,
} from '@west-australian-newspapers/publication-types'
import { PublicationCardItemWithVideo, Topic } from '../../../client-data-types'

export function getVideoWatchPageUrl(
    topic: Topic,
    video: VideoMetaDTO,
): string {
    return `/video/${topic.id}/${createSlug({
        type: 'brightcove',
        title: String(video.name),
        id: video.id,
    })}`
}

export interface MapListVideoOptions {
    publicUrl: string
}

export function mapListVideo(
    videoMeta: VideoMetaDTO,
    options: MapListVideoOptions,
): PublicationCardItemWithVideo {
    const trailerHeadline = videoMeta.name || videoMeta.description
    const teaser = videoMeta.name ? videoMeta.description : ''
    const unknownTopic = {
        id: 'unknown',
        title: 'unknown',
        metadata: {},
        seoTitle: 'unknown',
    }
    const topic = videoMeta.topicMeta || unknownTopic
    const link = getVideoWatchPageUrl(topic, videoMeta)

    return {
        cardType: 'publication',
        id: videoMeta.id,
        headline: trailerHeadline,
        shortHeadline: trailerHeadline,
        link,
        _self: `${options.publicUrl}${link}`,
        primaryTopic: videoMeta.topicMeta ? videoMeta.topicMeta : unknownTopic,
        secondaryTopics: [],
        kicker: trailerHeadline,
        byline: videoMeta.byline || 'Digital Team',
        publicationKind: 'video',
        source: videoMeta.source ?? '',
        teaser: videoMeta.longDescription ?? '',
        isLive: false,
        isSponsored: false,
        requiredAccess: { level: 'anonymous' },
        allowCommenting: false,
        image: videoMeta.posterImage
            ? {
                  altText: videoMeta.posterImage.altText || '',
                  captionText: videoMeta.posterImage.captionText || '',
                  byline: videoMeta.posterImage.byline || '',
                  credit: videoMeta.posterImage.credit || '',
                  crops: {
                      '16:9':
                          videoMeta.posterImage.ratio === '16:9'
                              ? {
                                    ratio: { width: 16, height: 9 },
                                    height: videoMeta.posterImage.height,
                                    reference: videoMeta.posterImage.reference,
                                    width: videoMeta.posterImage.width,
                                }
                              : undefined,
                      original: {
                          ratio: {
                              width: videoMeta.posterImage.width,
                              height: videoMeta.posterImage.height,
                          },
                          height: videoMeta.posterImage.height,
                          reference: videoMeta.posterImage.reference,
                          width: videoMeta.posterImage.width,
                      },
                  },
              }
            : undefined,
        video: {
            id: videoMeta.id,
            accountId: videoMeta.accountId,
            heading: String(videoMeta.name),
            source: videoMeta.source ?? undefined,
            duration: `${videoMeta.duration}`,
            videoType: 'vod',
        },
        profiles: videoMeta.profiles,
        readTimeMinutes: null,
    }
}
