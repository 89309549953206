import React from 'react'

export interface IconLinkProps {
    title?: string
    className?: string
}

export const IconLink: React.FC<IconLinkProps> = ({ className, title }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        className={className}
    >
        <title>{title || 'Copy the Link'}</title>
        <path d="M11.23 7.4l-.64-.63c-1.4-1.4-3.7-1.4-5.1 0L1.3 10.9c-1.4 1.4-1.4 3.7 0 5.1l.64.64c.67.68 1.57 1.05 2.53 1.05.97 0 1.87-.4 2.55-1.1L9 14.63c.37-.35.37-.92 0-1.27-.34-.37-.9-.37-1.26 0L5.8 15.33c-.67.68-1.86.68-2.54 0l-.64-.63c-.7-.7-.7-1.83 0-2.53l4.14-4.13c.68-.68 1.87-.68 2.55 0l.7.63c.36.35.9.35 1.28 0 .35-.35.35-.92 0-1.27" />
        <path d="M16.64 2L16 1.36C15.32.68 14.42.3 13.46.3c-.97 0-1.87.4-2.55 1.06l-1.7 1.8c-.35.34-.35.9 0 1.26.35.35.92.35 1.27 0l1.8-1.78c.67-.68 1.86-.7 2.54 0l.64.63c.7.7.7 1.85 0 2.55L11.3 9.95c-.7.68-1.88.68-2.56 0-.35-.35-.92-.35-1.27 0-.33.36-.33.92 0 1.28 1.4 1.4 3.7 1.4 5.1 0L16.7 7.1c1.4-1.4 1.4-3.7 0-5.1" />
    </svg>
)
IconLink.displayName = 'IconLink'
