import React from 'react'

export interface IconVideoProps {
    className?: string
}

export const IconVideo: React.FC<IconVideoProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 15"
    >
        <g>
            <path d="M10.27 9.92H3.2c-.2 0-.37-.16-.37-.36 0-.2.16-.35.36-.35h7.07c.2 0 .36.17.36.36 0 .2-.16.36-.36.36m5.6-1.83l-3.12 1.3V8.14c0-.6-.48-1.07-1.06-1.07H1.76C1.2 7.08.7 7.56.7 8.15v5.66c0 .6.5 1.07 1.07 1.07h9.92c.57 0 1.05-.47 1.05-1.06v-1.23l3.1 1.3c.6.24 1.15-.12 1.15-.77V8.85c0-.63-.55-1-1.14-.76M3.2 6.38c1.74 0 3.17-1.44 3.17-3.2C6.38 1.44 4.95 0 3.2 0 1.42 0 0 1.43 0 3.2c0 1.74 1.43 3.17 3.2 3.17M9.2 6.38c1.18 0 2.13-.96 2.13-2.13s-.95-2.13-2.12-2.13c-1.16 0-2.12.96-2.12 2.13s.96 2.13 2.13 2.13" />
        </g>
    </svg>
)
