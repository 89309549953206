import { tokens } from '@news-mono/design-tokens'
import {
    BreakpointState,
    TheNightlyThemeConfig,
    ThemeConfig,
    calcRem,
    fonts,
    metrics,
} from '../../__styling'

export type TheNightlyAppDLCardTheme<T extends ThemeConfig> = {
    fontSize: string
    padding: string
    height: BreakpointState<T, string>

    img: {
        display: BreakpointState<T, string>
        padding: string
    }
    heading: {
        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                lineHeight: string
            }
        >
    }
    actionCard: {
        padding: string
        gap: string
    }
    row: {
        gap: string
    }
    buttonGroup: {
        gap: string
        marginTop: BreakpointState<T, string>
    }
    description: {
        color: string
        fontFamily: string
        fontSize: string
        lineHeight: string
    }
    logoWrapper: {
        background: string
        borderRadius: string
    }
}

export const theNightlyAppDLCardTheme: TheNightlyAppDLCardTheme<TheNightlyThemeConfig> =
    {
        fontSize: calcRem(32),
        padding: calcRem(0, metrics.thenightly.margins.m2l),
        height: {
            default: calcRem(298),
            sm: calcRem(306),
            lg: calcRem(326),
        },

        img: {
            padding: calcRem(0, 37),
            display: {
                default: 'none',
                sm: 'block',
            },
        },
        heading: {
            breakpointState: {
                default: {
                    fontSize: calcRem(32),
                    lineHeight: calcRem(40),
                },
                sm: {
                    fontSize: calcRem(36),
                    lineHeight: calcRem(40),
                },
                lg: {
                    fontSize: calcRem(40),
                    lineHeight: calcRem(52),
                },
            },
        },
        actionCard: {
            padding: calcRem(metrics.thenightly.margins.lg, 0),
            gap: calcRem(16),
        },
        row: {
            gap: calcRem(metrics.thenightly.margins.md),
        },
        buttonGroup: {
            gap: calcRem(16),
            marginTop: {
                default: calcRem(0),
                sm: calcRem(8),
                lg: calcRem(16),
            },
        },
        description: {
            color: tokens.thenightly.colors.palette.neutral[100],
            fontFamily: fonts.thenightly.sansSerif,
            fontSize: calcRem(16),
            lineHeight: '150%',
        },
        logoWrapper: {
            background: tokens.thenightly.colors.palette.neutral[100],
            borderRadius: calcRem(10),
        },
    }
