import React from 'react'
import { BylineProps } from './Byline'
import { StyledByline } from './Byline.styled'
import { BylineText } from './components/BylineText'

export const SevenByline: React.FC<BylineProps> = ({
    text,
    isFullWidth,
    requiredAccess,
    profile,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    allowCommenting,
    publicationKind,
}) => {
    const isEvent = publicationKind === 'event'

    return (
        <StyledByline isFullWidth={isFullWidth}>
            <BylineText
                inlineTimestamp={true} // Always true on Seven non-Bauhaus themed
                profile={profile}
                fundingType={fundingType}
                source={source}
                timestamp={timestamp}
                text={text}
                requiredAccess={requiredAccess}
                timestampUpdated={timestampUpdated}
                allowCommenting={allowCommenting}
                timestampUpdatedLabel={isEvent ? 'Last updated' : 'Updated:'}
            />
        </StyledByline>
    )
}
