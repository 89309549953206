import styled from '@emotion/styled'
import React from 'react'
import { easing } from '../../__styling/settings/easing'
import { NativeLazyLoading, FetchPriority } from './Image.web.types'

export interface StyledImageProps {
    show?: boolean
    loading?: NativeLazyLoading
    fetchpriority?: FetchPriority
}

declare module 'react' {
    interface HTMLAttributes<T>
        extends React.AriaAttributes,
            React.DOMAttributes<T> {
        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#fetchpriority
         */
        fetchpriority?: FetchPriority
    }
}

type ImageProps = JSX.IntrinsicElements['img']
const ImageWithFetchPriority: React.FC<ImageProps> = ({
    fetchpriority,
    ...props
}) => (
    <img fetchpriority={fetchpriority} {...props}>
        {props.children}
    </img>
)

export const StyledImage = styled(ImageWithFetchPriority)<StyledImageProps>(
    {
        display: 'block',
        transition: `opacity ${easing.easeOut.med}`,
        objectFit: 'cover',
    },
    (props) => ({
        opacity: props.show === false ? 0 : 1,
    }),
)
