import React from 'react'

export interface IconMessengerProps {
    title?: string
    className?: string
}

export const IconMessenger: React.FC<IconMessengerProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38 40"
        className={className}
    >
        <title>{title || 'Messenger Icon'}</title>
        <g fill="none" fillRule="evenodd">
            <path
                d="M19 0C8.5 0 0 8.102 0 18c0 5.199 2.301 9.801 6 13.102V40l7.801-4.699c1.597.398 3.398.699 5.199.699 10.5 0 19-8.102 19-18S29.5 0 19 0"
                fill="black"
            />
            <path fill="#FFF" d="M7 24l10-11 5 5 9-5-10 11-5-5z" />
        </g>
    </svg>
)
IconMessenger.displayName = 'IconMessenger'
