import {
    AmpRenderTargetContext,
    RenderTargetContext,
} from '@news-mono/web-common'
import React from 'react'

// See https://www.ampproject.org/docs/reference/common_attributes for attribute documentation
interface AmpElementAttributes extends React.Attributes {
    fallback?: boolean
    heights?: string
    layout?: string
    media?: string
    noloading?: boolean
    on?: string
    placeholder?: string
    sizes?: string
    width?: number
    height?: number
}

/**
 * In amp, elements (e.g. <div> or <p>) can have non-standard attributes which describe how they
 * interact with other amp elements. the ampElement function allows you to create an element for
 * amp pages, but retain type safety whilst allowing you to use these non-standard attributes.
 *
 * Usage: const Div = ampElement<'div', { additionalProps: string }>('div')
 */
export function ampElement<Tag extends keyof JSX.IntrinsicElements, Tprops>(
    tag: keyof JSX.IntrinsicElements,
) {
    type ComponentProps = React.FC<
        Tprops & AmpElementAttributes & JSX.IntrinsicElements[Tag]
    >
    const Component: ComponentProps = (props) => React.createElement(tag, props)
    return Component
}

export type CallbackParams = {
    customElement: string
    src: string
}

export type Directive =
    | 'ad'
    | 'embed'
    | 'img'
    | 'pixel'
    | 'video'
    | 'access'
    | 'accordion'
    | 'analytics'
    | 'anim'
    | 'audio'
    | 'brid-player'
    | 'brightcove'
    | 'carousel'
    | 'base-carousel'
    | 'dailymotion'
    | 'dynamic-css-classes'
    | 'facebook'
    | 'fit-text'
    | 'font'
    | 'iframe'
    | 'image-lightbox'
    | 'instagram'
    | 'install-serviceworker'
    | 'kaltura-player'
    | 'lightbox'
    | 'list'
    | 'live-list'
    | 'mustache'
    | 'pinterest'
    | 'reach-player'
    | 'slides'
    | 'social-share'
    | 'soundcloud'
    | 'springboard-player'
    | 'twitter'
    | 'user-notification'
    | 'vimeo'
    | 'vine'
    | 'youtube'
    | 'sticky-ad'
    | 'fx-flying-carpet'
    | 'skimlinks'

const builtins: string[] = ['img', 'pixel', 'video']
const extensions: string[] = [
    'ad',
    'embed',
    'access',
    'accordion',
    'analytics',
    'anim',
    'audio',
    'brid-player',
    'brightcove',
    'carousel',
    'base-carousel',
    'dailymotion',
    'dynamic-css-classes',
    'facebook',
    'fit-text',
    'font',
    'iframe',
    'image-lightbox',
    'instagram',
    'install-serviceworker',
    'kaltura-player',
    'lightbox',
    'list',
    'live-list',
    'mustache',
    'pinterest',
    'reach-player',
    'slides',
    'social-share',
    'soundcloud',
    'springboard-player',
    'twitter',
    'user-notification',
    'vimeo',
    'vine',
    'youtube',
    'sticky-ad',
    'fx-flying-carpet',
    'skimlinks',
]

const aliases: { [key: string]: string } = {
    'amp-embed': 'amp-ad',
}

export type Callback = (props: CallbackParams) => any

export function amphtml<TProps>(
    directive: Directive,
    additionalScripts?: string[],
): React.FC<TProps> {
    const name = `amp-${directive}`
    const Component = name
    let isExtension = false
    if (extensions.some((value) => value === directive)) {
        isExtension = true
    } else if (!builtins.some((value) => value === directive)) {
        throw new Error(`"${directive}" is not a valid amphtml directive.`)
    }

    const AmpScriptLoader: React.FC<TProps> = (props) => {
        const renderTargetContext = React.useContext(
            RenderTargetContext,
        ) as AmpRenderTargetContext

        // This is purposefully not in an effect because they don't run on SSR
        if (isExtension) {
            const scriptType = aliases[name] ? aliases[name] : name
            if (!renderTargetContext.extensionMounted) {
                throw new Error(
                    `Incorrect Render context, renderTarget=${renderTargetContext.renderTarget}`,
                )
            }

            renderTargetContext.extensionMounted(scriptType, additionalScripts)
        }

        return React.createElement(Component, props)
    }

    AmpScriptLoader.displayName = 'AmpScriptLoader'

    return AmpScriptLoader
}
