import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { useSelector } from 'react-redux'
import { MAX_ANON_USER_IDENTIFIER } from '../data'
import {
    A_B_TESTING_FEATURE_NAME,
    isFeatureName,
    TogglesReduxState,
} from '../feature-togglings/reducer'
import { ABTestingFeature, Features } from '../features'
import { AppState } from '../store'

export interface ToggleDescription {
    group: string
    displayName: string
}

export function evaluateABFeature(
    feature: ABTestingFeature | undefined,
    originalFeatureValue: unknown,
    anonymousUserId?: number,
) {
    if (anonymousUserId == null || !feature) {
        return originalFeatureValue
    }

    /*
        Test anonymoususerids with percentages
        384f3d70 = 21%
        f5e10000 = 96%
        fffffff8 = 99%
    */

    const percentage = (anonymousUserId / MAX_ANON_USER_IDENTIFIER) * 100
    const variations = feature.variants
    const varLength = variations.length
    const workingPercentage = percentage

    let prevPercentage = 0

    for (let i = 0; i < varLength; i++) {
        const variant = variations[i]

        if (
            workingPercentage > prevPercentage &&
            workingPercentage <= variant.on.percentage
        ) {
            return variant.on.value
        }

        prevPercentage = variant.on.percentage

        if (prevPercentage > 100) {
            return
        }
    }

    return variations[0]?.defaultValue
}

function mapStateToProps({ toggles, authentication }: AppState): {
    toggles: TogglesReduxState
    anonymousUserId?: number
} {
    const aBTestingFeatures = toggles[A_B_TESTING_FEATURE_NAME]
    if (!aBTestingFeatures) {
        return {
            toggles,
            anonymousUserId: authentication?.anonymousUserIdentifier,
        }
    }

    const togglesWithABTestingValues = { toggles: { ...toggles } }
    for (const featureKey in aBTestingFeatures) {
        if (
            isFeatureName(featureKey) &&
            featureKey !== A_B_TESTING_FEATURE_NAME
        ) {
            togglesWithABTestingValues.toggles[featureKey] = evaluateABFeature(
                aBTestingFeatures[featureKey],
                toggles[featureKey],
                authentication?.anonymousUserIdentifier,
            )
        }
    }

    return togglesWithABTestingValues
}

export function getToggleState(globalState: AppState) {
    return mapStateToProps(globalState)
}

export function getABTestingFeatures({
    toggles,
    anonymousUserId,
}: {
    toggles: TogglesReduxState
    anonymousUserId?: number
}): TogglesReduxState {
    const abTestingFeatures = toggles[A_B_TESTING_FEATURE_NAME]
    if (!abTestingFeatures) {
        return {}
    }

    const returnVal: TogglesReduxState = {}
    for (const key in abTestingFeatures) {
        if (isFeatureName(key) && key !== A_B_TESTING_FEATURE_NAME) {
            returnVal[key] = evaluateABFeature(
                abTestingFeatures[key],
                toggles[key],
                anonymousUserId,
            )
        }
    }

    return returnVal
}

export function useToggleState() {
    const { toggles } = useSelector(mapStateToProps)
    return toggles
}

export function useABTestingFeatures(): TogglesReduxState {
    const features = useSelector(mapStateToProps)
    return getABTestingFeatures(features)
}

export function useFeature(feature: Features, fallback?: boolean) {
    const toggleState = useToggleState()

    return isFeatureEnabled(toFeatureState(toggleState), feature, fallback)
}
