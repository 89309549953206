import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint, colors, themedValue } from '../../__styling'
import { calcRem } from '../../__styling/style-functions/calc-rem'

// Only displayed on smaller screens when form is hidden
export const StyledSearchLink = styled(WebLink, {
    shouldForwardProp: (prop) => prop !== 'isLarge',
})<{ isLarge: boolean }>(({ theme, isLarge }) => ({
    position: 'relative',
    padding: calcRem(0, theme.margins.sm, 0, 0),
    display: 'flex',
    width: calcRem(25),
    height: calcRem(25),
    alignItems: 'center',
    textAlign: 'center',

    '&::after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 0,
        height: calcRem(20),
        borderLeft: themedValue(theme, {
            thewest: `1px solid ${colors.thewest.greyDolphin}`,
            sevennews: `1px solid ${colors.sevennews.stormGrey}`,
            perthnow: `1px solid ${colors.perthnow.greyMarble}`,
            fallback: undefined,
        }),
    },

    [breakpoint('sm')]: {
        display: 'none',
    },
}))
StyledSearchLink.displayName = 'SearchPageLink'
