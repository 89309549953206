import React, { FC } from 'react'
import { AccordionContainer, Divider } from './SearchFiltersAccordion.styled'
import { Accordion } from '../../../navigation/TNAccordion/Accordion'
import { SearchFilters } from '../SearchFilters'
import { FilterEvent } from '../../../result-filters/filter-events-util'

interface SearchFiltersAccordionProps {
    onEvent: (event: FilterEvent) => void
}

export const SearchFiltersAccordion: FC<SearchFiltersAccordionProps> = ({
    onEvent,
}) => {
    return (
        <AccordionContainer>
            <Accordion headerText="Filter">
                <SearchFilters showHeader={false} onEvent={onEvent} />
            </Accordion>
            <Divider />
        </AccordionContainer>
    )
}
