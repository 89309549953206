import React from 'react'
import {
    StyledCardImage,
    StyledCardImageContainer,
    StyledInlineContentCard,
    StyledLinkWrapper,
    StyledText,
    StyledTextContainer,
    StyledTimeToRead,
} from './InlineContentCard.styled'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CollectionEvent,
    PublicationCardItem,
    createCardClickedEvent,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { getFundingType } from '../../../templates'

export interface InlineContentCardProps {
    item: MaybeLoaded<PublicationCardItem>
    cardNumber: number
    onEvent: (event: CollectionEvent) => void
    isSideBarCmp?: boolean
    numberOfCards?: number
}

export const InlineContentCard: React.FC<InlineContentCardProps> = ({
    item,
    onEvent,
    cardNumber,
    isSideBarCmp,
    numberOfCards,
}) => {
    const storyClassification = true

    if (!item?.loaded) {
        return null
    }

    function linkClicked(
        e: React.MouseEvent<HTMLElement>,
        item: MaybeLoaded<PublicationCardItem>,
        cardNumber: number,
    ) {
        if (!item.loaded) {
            e.preventDefault()
            return
        }
        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'SevenNewsInlineRelatedContentCard',
                'InlineRelatedContent',
                cardNumber,
                getFundingType(item.result, storyClassification),
                'InlineRelatedCollection',
            ),
        )
    }

    const timeToRead = item?.result?.readTimeMinutes
        ? `${Math.round(item.result.readTimeMinutes)} min read`
        : ''

    return (
        <StyledLinkWrapper
            to={toLinkWithHint(item)}
            onClick={(event) => {
                linkClicked(event, item, cardNumber)
            }}
        >
            <StyledInlineContentCard
                isSideBarCmp={isSideBarCmp}
                numberOfCards={numberOfCards}
            >
                <StyledTextContainer>
                    <StyledText
                        item={item}
                        fontScale={1}
                        onEvent={onEvent}
                        teaserMode={'hidden'}
                        kickerMode={'hidden'}
                        headlineAsDiv={true}
                    />
                    {timeToRead && (
                        <StyledTimeToRead>{timeToRead}</StyledTimeToRead>
                    )}
                </StyledTextContainer>

                <StyledCardImageContainer isSideBarCmp={isSideBarCmp}>
                    <StyledCardImage
                        item={item}
                        imageWidths={{
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '100vw',
                            fallbackWidth: 135,
                        }}
                        hasBackground={true}
                        onEvent={onEvent}
                        mediaMode={'default'}
                        fixedRatio={'16:9'}
                        displayNoScriptImage={true}
                    />
                </StyledCardImageContainer>
            </StyledInlineContentCard>
        </StyledLinkWrapper>
    )
}
