import { cx } from '@emotion/css'
import {
    CardEvents,
    CollectionEvent,
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import SevenNewsInlineRelatedContextTextCardArrow from './assets/SevenNewsInlineRelatedContextTextCardArrow'
import {
    StyledTextCard,
    StyledTextCardsContainer,
    StyledTextCardText,
} from './SevenNewsInlineRelatedContentTextCards.styled'

interface SevenNewsInlineRelatedContentTextCardProps {
    items: MaybeLoaded<PublicationCardItem[]>
    expectedCards: number
    onEvent: (event: CardEvents | CollectionEvent) => void
}
export const SevenNewsInlineRelatedContentTextCard: React.FC<SevenNewsInlineRelatedContentTextCardProps> =
    (props) => {
        const items = invertMaybeLoadedItems(props.items, props.expectedCards)
        const layout = props.expectedCards >= 2 ? 'portrait' : 'landscape'

        const storyClassification = true

        function linkClicked(
            e: React.MouseEvent<HTMLElement>,
            item: MaybeLoaded<PublicationCardItem>,
            cardNumber: number,
        ) {
            if (!item.loaded) {
                e.preventDefault()
                return
            }
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    item.result,
                    'SevenNewsInlineRelatedContentCard',
                    'InlineRelatedContent',
                    cardNumber,
                    getFundingType(item.result, storyClassification),
                    'InlineRelatedCollection',
                ),
            )
        }

        return (
            <StyledTextCardsContainer
                layout={layout}
                className={cx('hide-print')}
            >
                {items.map((item, index) => {
                    if (item.loaded) {
                        return (
                            <StyledTextCard
                                key={item.result.id}
                                to={toLinkWithHint(item)}
                                layout={layout}
                                onClick={(event) => {
                                    linkClicked(event, item, index)
                                }}
                            >
                                <StyledTextCardText
                                    item={item}
                                    kickerMode={'hidden'}
                                    teaserMode={'hidden'}
                                    onEvent={props.onEvent}
                                    innerRef={undefined}
                                    fontScale={0.83}
                                    hasPadding={true}
                                />
                                <SevenNewsInlineRelatedContextTextCardArrow />
                            </StyledTextCard>
                        )
                    }
                })}
            </StyledTextCardsContainer>
        )
    }
