export const click_origin_subscribe = 'online'
export const click_origin_breach_screen = 'breach'
export const click_origin_breach_screen_inline = 'breach.inline'

export interface SSWQueryString extends SSWTransaction {
    // Click origin is basically infered from the original utm_medium that is on the subscribe now/packages buttons
    clickOrigin:
        | typeof click_origin_subscribe
        | typeof click_origin_breach_screen
        | typeof click_origin_breach_screen_inline
}

export interface SSWTransaction {
    transactionId: number
    transactionTotal: number
    transactionTax: number
    productSku: string
    productName: string
}
