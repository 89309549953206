/* eslint-disable @typescript-eslint/no-var-requires */
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

const AFLDraftBannerDesktopX2 = require<string>('./assets/afl-draft/TW-AFLDraft-desktop-2440x300.jpg')

const AFLDraftBannerDesktopX1 = require<string>('./assets/afl-draft/TW-AFLDraft-desktop-1220x150.jpg')

const AFLDraftBannerTabletX2 = require<string>('./assets/afl-draft/TW-AFLDraft-tablet-1476x200.jpg')

const AFLDraftBannerTabletX1 = require<string>('./assets/afl-draft/TW-AFLDraft-tablet-738x100.jpg')

const AFLDraftBannerMobileX2 = require<string>('./assets/afl-draft/TW-AFLDraft-mobile-822x134.jpg')

const AFLDraftBannerMobileX1 = require<string>('./assets/afl-draft/TW-AFLDraft-mobile-411x61.jpg')

export const getAFLDraftRouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: AFLDraftBannerDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText:
                    "The West Australian's 2019 AFL Draft Hub. Check out WA's top draft prospects. We're for AFL!",
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: AFLDraftBannerDesktopX2, density: '2x' },
                            { src: AFLDraftBannerDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: AFLDraftBannerTabletX2, density: '2x' },
                            { src: AFLDraftBannerTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: AFLDraftBannerMobileX2, density: '2x' },
                            { src: AFLDraftBannerMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'tw-afl-draft-header',
            props: {
                header: 'Top 10 WA AFL Draft Prospects',
                body: `All you need to know about the State’s leading AFL hopefuls.`,
            },
        }),
        layout.component({
            type: 'whiskey',
            feature: 'afl-draft',
            props: {
                initialColumns: 2,
                intermediateColumns: 4,
                finalColumns: 5,
                verticalSpacing: 'xl',

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'afl-draft-profiles',
                    offset: 0,
                    pageSize: 20,
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'tw-afl-draft-header',
            props: {
                header: 'Top 10 WA AFLW Draft Prospects',
                body: `All you need to know about the State’s leading AFLW hopefuls.`,
            },
        }),
        layout.component({
            type: 'whiskey',
            feature: 'afl-draft',
            props: {
                initialColumns: 2,
                intermediateColumns: 4,
                finalColumns: 5,
                verticalSpacing: 'xl',

                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'afl-draft-national-profiles',
                    offset: 0,
                    pageSize: 20,
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: false,

                sectionHeader: {
                    heading: `Today's Highlights`,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'afl-draft-highlights',
                    offset: 0,
                    pageSize: 4,
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: false,

                sectionHeader: {
                    heading: 'Latest AFL Draft News',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/afl-draft'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                        pageOffset: 0,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/afl-draft'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 4,
                        pageOffset: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('three'),
    ]
}
