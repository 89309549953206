import { layout } from '../../App.routing'

export const doubleSportSegment = layout.nestedComposition({
    type: 'janus',
    props: {
        stretchColumns: true,
        verticalSpacing: 'lg',
        hasBorder: true,
    },
    contentAreas: {
        left: [
            layout.component({
                type: 'lima',
                props: {
                    dataDefinitionArgs: {
                        type: 'curation',
                        name: 'cricket',
                        offset: 0,
                        pageSize: 4,
                    },
                    sectionHeader: {
                        heading: 'Cricket',
                        headingUrl: '/sport/cricket',
                    },
                    verticalSpacing: 'sm',
                    fixedRatios: ['16:9'],
                    finalColumns: 2,
                    intermediateColumns: 2,
                    stretchSelf: true,
                    hasBorder: false,
                    cardOrientation: {
                        type: 'Landscape-Portrait',
                    },
                    cardLayout: [
                        ['teaserWithBackground'],
                        [
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                        ],
                    ],
                },
            }),
        ],
        right: [
            layout.component({
                type: 'lima',
                props: {
                    dataDefinitionArgs: {
                        type: 'curation',
                        name: 'aflw',
                        offset: 0,
                        pageSize: 4,
                    },
                    sectionHeader: {
                        heading: 'AFLW',
                        headingUrl: '/sport/aflw',
                    },
                    verticalSpacing: 'sm',
                    fixedRatios: ['16:9'],
                    finalColumns: 2,
                    intermediateColumns: 2,
                    stretchSelf: true,
                    hasBorder: false,
                    cardOrientation: {
                        type: 'Landscape-Portrait',
                    },
                    cardLayout: [
                        ['teaserWithBackground'],

                        [
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                            {
                                type: 'landscape',
                                format: 'landscape-xsTeaser-smNoTeaser',
                            },
                        ],
                    ],
                },
            }),
        ],
    },
})
