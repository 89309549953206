import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Desktop@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Desktop@1x.jpg')

const bannerTabletX2 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Tablet@2x.jpg')

const bannerTabletX1 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Tablet@1x.jpg')

const bannerMobileX2 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Mobile@2x.jpg')

const bannerMobileX1 = require<string>('./assets/2022-Bikie-Code-Feature-banner-Mobile@1x.jpg')

const socialImage = require('./assets/2022-Bikie-social-share-@2x.jpg')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '627',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

export const getBikieCodeRouteInfo: StaticRoutes<TheWestSection> = {
    '/features/bikie-code': ({ getAdTargeting, config, store, resolution }) => {
        const canonicalUrl = config.publicUrl + '/features/bikie-code'

        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'bikie-code-feature-route',
        )

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'features/bikie-code',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                '',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        if (!enableRoute) {
            return null
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'video-series',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting: getAdTargeting('home', 'default', topic.id),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: bannerDesktopX2,
                                    verticalGutters: ['unset', 'md'],
                                    altText: topic.seoTitle,
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: bannerDesktopX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerDesktopX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: bannerTabletX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerTabletX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: bannerMobileX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerMobileX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    layoutRatio: '3:1',
                                    stretchColumns: true,
                                    verticalSpacing: 'xl',
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },

                                                cardLayout: ['hero-video'],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'bikie-code',
                                                    offset: 0,
                                                    pageSize: 1,
                                                },
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'tw-subscribe-promo-card',
                                            props: {
                                                btnOptions: {
                                                    link: 'offercheck/thewest?redirect=true&offerCode=BIKEDI30',
                                                    offerCode: 'BIKEDI30',
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'Watch the episodes',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'bikie-code',
                                        offset: 1,
                                        pageSize: 8,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: 'Latest Bikie News',
                                        headingUrl: '/news/bikies',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: ['news/bikies'],
                                        includeSubTopics: true,
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                                link: '/news/bikies',
                            }),
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
