declare const window: Window & {
    MutationObserver: MutationObserver
    WebKitMutationObserver: any
}

// Test for the existence
export function supportsMutationObserver() {
    return (
        typeof window !== 'undefined' &&
        (!!window.MutationObserver || !!window.WebKitMutationObserver) &&
        process.env.NODE_ENV !== 'test'
    )
}
