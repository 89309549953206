import { Product } from '@news-mono/web-common'

/**
 * aka "IX Header Tag Wrapper"
 * We get different scripts depending on the product.
 */
export function getIndexExchangeScriptUri(product: Product, section: string) {
    switch (product) {
        case Product.PerthNow:
            return '//js-sec.indexww.com/ht/p/187190-36709770095197.js'
        case Product.SevenNews:
            return '//js-sec.indexww.com/ht/p/187711-43333188457161.js'
        case Product.TheWest: {
            if (section === 'default') {
                return '//js-sec.indexww.com/ht/p/187190-179646541005488.js'
            }
            // Regionals
            return '//js-sec.indexww.com/ht/p/187190-278650467728878.js'
        }
        case Product.TheNightly: {
            // Todo: update this when available
            return '//js-sec.indexww.com/ht/p/187190-179646541005488.js'
        }
    }
}
