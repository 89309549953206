import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { ResponsivePicture } from '../../content/Picture/Picture'
import { FigCaption } from '../../publication/FigCaption/FigCaption'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export interface StyledFigureProps {
    isHeroImage?: boolean
    align?: 'right' | 'left'
    isTwin?: boolean
    imageRenderType?: string
}

const pnMarginSml = metrics.perthnow.margins.sm
const pnMarginMd = metrics.perthnow.margins.md

export const StyledFigure = styled('figure')<StyledFigureProps>(
    ({ theme, isHeroImage, align, isTwin, imageRenderType }) => {
        const marginToKeep = align === 'left' ? 'marginLeft' : 'marginRight'
        const marginToReset = align === 'left' ? 'marginRight' : 'marginLeft'
        const horizontalPadding =
            align === 'left' ? 'paddingRight' : 'paddingLeft'

        return [
            {
                display: 'block',
                clear: 'both',
                marginTop: themedValue(theme, {
                    perthnow: isHeroImage
                        ? 0
                        : calcRem(metrics.perthnow.margins.lg),
                    sevennews: calcRem(metrics.sevennews.margins.xs),
                    fallback: undefined,
                }),
                marginBottom: themedValue(theme, {
                    thewest: isHeroImage
                        ? undefined
                        : calcRem(metrics.thewest.margins.md),
                    thenightly: isHeroImage
                        ? undefined
                        : metrics.thenightly.articleBlockMargin,
                    fallback: isHeroImage
                        ? undefined
                        : calcRem(theme.margins.lg),
                }),
                marginLeft: themedValue(theme, {
                    perthnow: calcRem(-metrics.perthnow.margins.md),
                    thewest: calcRem(-metrics.thewest.margins.outerMargin),
                    fallback: 'auto',
                }),
                marginRight: themedValue(theme, {
                    perthnow: calcRem(-metrics.perthnow.margins.md),
                    thewest: calcRem(-metrics.thewest.margins.outerMargin),
                    fallback: 'auto',
                }),
                width: themedValue(theme, {
                    perthnow: `calc(100% + (${calcRem(
                        metrics.perthnow.margins.md,
                    )} * 2))`,
                    thewest: `calc(100% + (${calcRem(
                        metrics.thewest.margins.outerMargin,
                    )} * 2))`,
                    fallback: '100%',
                }),

                [breakpoint('md')]: {
                    marginLeft: themedValue(theme, {
                        perthnow: 'auto',
                        fallback: 0,
                    }),
                    marginRight: themedValue(theme, {
                        perthnow: 'auto',
                        fallback: 0,
                    }),
                    width: themedValue(theme, {
                        perthnow: '100%',
                        thewest: '100%',
                        fallback: undefined,
                    }),
                },
            },

            /**
             * DPT-2738: fix alignment at smaller breakpoints
             */
            imageRenderType === 'feature' && {
                marginLeft: 0,
                marginRight: 0,
                maxWidth: '100%',
            },

            // Determines whether the image is left or right aligned and sets appropriate padding/margin
            align !== undefined && {
                [breakpoint('sm')]: {
                    clear: align,
                    float: align,
                    width: '50%',
                    marginTop: 'unset',

                    // Determines left/right padding and margin based on the alignment of the image
                    [marginToKeep]:
                        theme.kind === 'perthnow' ? -pnMarginSml : 0,
                    [marginToReset]: 'unset',
                    [horizontalPadding]: !isTwin && 24, // TODO: Where did 24 come from?
                },

                [breakpoint('md')]: {
                    // Detrmines left/right padding based on alignment of the image
                    [horizontalPadding]: !isTwin && 24, // TODO: Where did 24 come from?
                },
            },
        ]
    },
)

export interface StyledArticleImageProps {
    isHeroImage?: boolean
    imageRenderType?: 'feature'
    isHeroImageAmp?: boolean
}

export const StyledArticleImage = styled(
    ResponsivePicture,
)<StyledArticleImageProps>(
    ({ theme, isHeroImage, imageRenderType, isHeroImageAmp }) => ({
        display: 'block',
        width: '100%',
        maxWidth: 'none',
        marginBottom: themedValue(theme, {
            perthnow: isHeroImage
                ? undefined
                : calcRem(metrics.perthnow.margins.sm),
            thewest: calcRem(10),
            sevennews: isHeroImageAmp
                ? calcRem(metrics.sevennews.margins.md)
                : calcRem(metrics.sevennews.margins.sm),
            fallback: undefined,
        }),
        overflow: isHeroImage ? 'hidden' : undefined,

        // Allows the image to stretch full height of the figure
        // only want to strech on breakpoints > sm as below that we show a 4:3 for feature
        // logic in ArticleImage.tsx
        [breakpoint('sm')]: {
            height: imageRenderType === 'feature' ? '100%' : undefined,
        },

        '& img': {
            width: '100%',
            maxWidth: 'none',
        },
    }),
)

export const StyledImageLink = styled(WebLink)(({ theme }) => ({
    position: 'relative',
    display: 'block',

    [`${StyledFigcaption} span`]: {
        transition: `color ${easing.easeOut.fast}`,
    },

    'picture::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderWidth: themedValue(theme, {
            thewest: '0 0 4px',
            perthnow: '4px',
            fallback: undefined,
        }),
        borderStyle: themedValue(theme, {
            sevennews: 'none',
            fallback: 'solid',
        }),
        borderColor: 'transparent',
        ...transition({ properties: ['border-color'] }),
    },

    '&:hover, &:active': {
        picture: {
            '&::after': {
                borderColor: themedValue(theme, {
                    thewest: colors.thewest.westblue,
                    perthnow: colors.white50,
                    fallback: undefined,
                }),
            },
            opacity: themedValue(theme, {
                sevennews: '90%',
                fallback: undefined,
            }),
        },
        [`${StyledFigcaption} span`]: {
            color: themedValue(theme, {
                sevennews: colors.sevennews.red,
                thewest: colors.thewest.westblue,
                perthnow: colors.perthnow.blueKyanite,
                fallback: undefined,
            }),
            textDecoration: themedValue(theme, {
                sevennews: 'underline',
                fallback: undefined,
            }),
        },
    },
    '&:focus, &:active': {
        picture: {
            '&::after': {
                borderColor: themedValue(theme, {
                    perthnow: colors.perthnow.greyBasalt,
                    fallback: undefined,
                }),
            },
        },
        [`${StyledFigcaption} span`]: {
            textDecoration: themedValue(theme, {
                perthnow: 'underline',
                thewest: 'underline',
                fallback: undefined,
            }),
        },
    },
}))

export const StyledFigcaption = styled(FigCaption)<StyledFigureProps>(
    ({ theme, isHeroImage, imageRenderType }) => ({
        backgroundColor: themedValue(theme, {
            perthnow: isHeroImage ? colors.black : undefined,
            fallback: undefined,
        }),

        // Fix dark fig caption on PN
        color: themedValue(theme, {
            perthnow: isHeroImage ? colors.perthnow.greyMarble : undefined,
            fallback: undefined,
        }),
        svg: {
            path: {
                fill: themedValue(theme, {
                    perthnow: isHeroImage
                        ? colors.perthnow.greyMarble
                        : undefined,
                    fallback: undefined,
                }) as string,
            },
        },

        borderBottom: themedValue(theme, {
            thewest: `2px solid ${colors.thewest.greySeal}`,
            perthnow: isHeroImage
                ? undefined
                : `2px solid ${colors.perthnow.greyPyrite}`,
            sevennews: `1px solid ${colors.sevennews.alto}`,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            thewest: calcRem(
                0,
                metrics.thewest.margins.md,
                metrics.thewest.margins.sm,
            ),
            perthnow: calcRem(
                0,
                metrics.perthnow.margins.sm,
                metrics.perthnow.margins.sm,
            ),
            sevennews: calcRem(0, 0, metrics.sevennews.margins.sm, 0),
            fallback: undefined,
        }),
        paddingTop: themedValue(theme, {
            perthnow: isHeroImage ? calcRem(metrics.perthnow.margins.sm) : 0,
            fallback: undefined,
        }),

        marginBottom: imageRenderType == 'gallery' ? 16 : 0,

        [breakpoint('xs')]: {
            padding: themedValue(theme, {
                perthnow: isHeroImage
                    ? `${calcRem(pnMarginSml)} ${calcRem(pnMarginMd)}`
                    : undefined,
                fallback: undefined,
            }),
        },

        [breakpoint('sm')]: {
            padding: themedValue(theme, {
                thewest: calcRem(0, 0, metrics.thewest.margins.sm, 0),
                fallback: undefined,
            }),
        },
    }),
)
