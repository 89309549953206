import {
    AdEvent,
    IsHydrating,
    SlotRenderEndedEvent,
} from '@news-mono/web-common'
import React from 'react'
import { IsViewable } from '../../__helpers/is-viewable'
import { AdUnit } from './AdUnit'
import { StyledAdUnit, VisiblityBreakpoint } from './AdUnit.styled'
import { AdSlotProps } from './AdUnitWrapper'
import { shouldShowBackground } from './util'

/**
 * Raises the viewable event and wraps the actual ad unit with it's styles
 */
export interface ViewabilityAdUnitProps extends AdSlotProps {
    removeAdContainerStyles: boolean
    breakoutMargins: {
        left: number
        right: number
    }
    recievedAdSlot: boolean | undefined
    onEvent: (event: AdEvent) => void
    onSlotRenderEnded: (event: SlotRenderEndedEvent) => void
    onSlotMutation: (id: string, mutations: MutationRecord[]) => void
    breakoutContainerRef: React.MutableRefObject<HTMLDivElement | null>
    shouldHide: boolean
    visibilityBreakpoints: VisiblityBreakpoint[]
}

export const ViewabilityAdUnit: React.FC<ViewabilityAdUnitProps> = ({
    removeAdContainerStyles,
    unitId,
    adState,
    breakoutMargins,
    recievedAdSlot,
    onEvent,
    onSlotRenderEnded,
    onSlotMutation,
    breakoutContainerRef,
    shouldHide,
    visibilityBreakpoints,
}) => {
    const showBackground = shouldShowBackground()
    const isHydrating = React.useContext(IsHydrating)

    return (
        <IsViewable viewableThresholdPercent={50}>
            {(ref, isViewable, threshold) => (
                <StyledAdUnit
                    ref={ref}
                    className={`ad-${unitId}`}
                    breakoutMargins={breakoutMargins}
                    showBackground={showBackground}
                    removeAdContainerStyles={removeAdContainerStyles}
                    hidden={shouldHide}
                    visibilityBreakpoints={visibilityBreakpoints}
                >
                    {!isHydrating && (
                        <AdUnit
                            unitId={unitId}
                            adState={adState}
                            isLoaded={recievedAdSlot}
                            isViewable={isViewable}
                            threshold={threshold}
                            onEvent={onEvent}
                            onSlotRenderEnded={onSlotRenderEnded}
                            onSlotMutation={onSlotMutation}
                            breakoutContainerRef={breakoutContainerRef}
                        />
                    )}
                </StyledAdUnit>
            )}
        </IsViewable>
    )
}
