import React from 'react'

export type RenderTarget = RenderTargetContext['renderTarget']

// Base work context
export interface BaseWorkContext {
    registerWork: (key: string, work: Promise<any>) => void
    getWorkResult: (key: string) => any
}

// Base Render context
export interface BaseRenderTargetContext {
    renderTarget: 'app' | 'rss' | 'web' | 'preview' | 'static-error-page'
}

// AMP Render & Work Context
export interface AmpWorkContext extends BaseWorkContext {
    extensionMounted: (extensionName: string, scripts?: string[]) => void
}

export interface AmpRenderTargetContext
    extends BaseWorkContext,
        AmpWorkContext {
    renderTarget: 'amp'
}

export type RenderTargetContext =
    | BaseRenderTargetContext
    | AmpRenderTargetContext

// Default value
export const RenderTargetContext = React.createContext<RenderTargetContext>({
    renderTarget: 'web',
})
