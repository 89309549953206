import { SectionMetaInfoDTO } from '@west-australian-newspapers/publication-types'
import { useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { debugSwg } from '../../authentication'
import { getLoginUrl } from '../../authentication/helpers'
import { isArticle } from '../../client-data-types'
import { BreachContext } from '../../context-providers/BreachContextProvider'
import { ConfigurationContext } from '../../context-providers/configuration-context'
import { getPageUrl } from '../../helpers/getPageUrl'
import { AppState } from '../../store'
import {
    buildRedirectUrl,
    handleSwGPurchase,
    sendSwGNewStartMessage,
    SwgMetaInfo,
} from './subscriptions'

export const useSubscribeWithGoogle = (
    buttonRef: HTMLButtonElement,
    googleSKU: string,
    sectionMetaInfo: SectionMetaInfoDTO,
    breachContext: BreachContext | undefined,
    swgMetaInfo?: SwgMetaInfo,
) => {
    /** READ THIS BEFORE MODIFIYING THE CODE BELOW:
     *  If you modify the code below, make sure to check the following three places while testing:
     *  - Standard Breach Screens (SwG Button) (SubscriptionPackage.tsx)
     *  - Black Friday Breach Screens (SwG Button) (BlackFridaySubsriptionPackage.tsx)
     *  - RegionalsSwgPromoPage (SwG Button) (RegionalsSwGPromoPage.tsx)
     *  Make sure you also lookout for any extra renders if you modify any of the useEffect dependencies below.
     */

    const { subscriptions, eventManagerLoaded, entitlementsPromise } =
        useSelector((state: AppState) => state.SwG)
    const {
        isLoggedIn,
        userEmail,
        checkingTokenState,
        hasAttemptedValidation,
        userName,
        wanUserId,
    } = useSelector((state: AppState) => state.authentication)

    const localConfig = useContext(ConfigurationContext)

    const location = useLocation()

    const breachInitialised = Boolean(breachContext)

    const handleSubscribeWithGoogleButton = useCallback(
        (sku?: string, swgMetaInfo?: SwgMetaInfo) => {
            if (subscriptions && sku) {
                debugSwg(breachContext)
                // Setup callback for payment response, noting that this callback only gets triggered when the user successfully subscribes to the SKU
                subscriptions.setOnPaymentResponse((paymentResponse) => {
                    if ((window as any).OnGooglePayment === true) return
                    ;(window as any).OnGooglePayment = true
                    paymentResponse.then(async (response) => {
                        // At this point, we need to handle the userData as well as purchaseData from the user
                        // We first need to publish the SKU purchase message to the analytics firehose
                        const article =
                            breachContext?.publication &&
                            isArticle(breachContext?.publication)
                                ? breachContext?.publication
                                : undefined

                        // The user can theorectically resubscribe here, so we shouldn't count as a new start.
                        const entitlements = await entitlementsPromise

                        const hasSwGSubscription =
                            entitlements?.entitlements.filter(
                                (entitlement) =>
                                    entitlement.source === 'google',
                            )

                        // We don't want to send a message if we have no context for it.
                        if (breachContext && article) {
                            const pageUrl = getPageUrl(
                                sectionMetaInfo,
                                localConfig.publicUrl,
                                location.pathname,
                            )

                            debugSwg(pageUrl)

                            const {
                                purchaseToken,
                                orderId,
                            }: { purchaseToken: string; orderId: string } =
                                JSON.parse(response.purchaseData.data)

                            const googleId = response.userData.id

                            debugSwg('Sending newstart message...')

                            await sendSwGNewStartMessage({
                                wanUserId: wanUserId,
                                pageUrl: pageUrl,
                                purchaseToken: purchaseToken,
                                orderId: orderId,
                                googleId,
                                pageTitle: article?.heading,
                                pageSlug: article?.slug,
                                pageSource: article?.source,
                                requiredAccessLevel:
                                    breachContext?.requiredAccessLevel ||
                                    'subscriber',
                                masthead: sectionMetaInfo.Title,
                                callToAction: 'swg-breach',
                                breachScreenVersion:
                                    breachContext?.breachCreativeName ||
                                    'nav-subscription-button',
                                pageByline: article.byline || '',
                                email: userEmail || response.userData.email,
                                rateCode: 'Google_Everyday_Digi',
                                name: userName || response.userData.name,
                            })
                        }

                        // Cast responseObject to object.
                        const responseObject = await handleSwGPurchase(
                            localConfig,
                            response,
                            swgMetaInfo,
                        )

                        // Update the frontend prompt to tell that the user that their account has been created.
                        response.complete().then(() => {
                            // Once the user has acknowledged that their account has been created, we need to then redirect to the authSite to finish their login
                            if (responseObject) {
                                // Need to see if the user is logged in to the account they bought the SKU with
                                const isSameAccount =
                                    response.userData.email === userEmail

                                window.location.href =
                                    isLoggedIn && isSameAccount
                                        ? buildRedirectUrl(window.location)
                                        : getLoginUrl({
                                              prompt: 'google',
                                              login_hint: responseObject.sub,
                                          })
                            }
                        })
                    })
                })

                subscriptions?.subscribe(sku)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoggedIn, subscriptions, userEmail, localConfig, breachInitialised],
    )

    // We want to attach the SwG Button listeners when the following conditions have been fulfilled:
    // 1. SwG.js is loaded (subscriptions)
    // 2. Button is rendered as an element
    // 3. Event Manager in swg.js is initialised
    // 4. The user's authentication status has been confirmed.

    useEffect(() => {
        const set =
            subscriptions &&
            buttonRef !== null &&
            eventManagerLoaded &&
            !checkingTokenState &&
            hasAttemptedValidation &&
            breachInitialised

        if (set) {
            debugSwg('attaching button', breachContext)
            // setTimeout(() => {
            subscriptions.attachButton(buttonRef, () =>
                handleSubscribeWithGoogleButton(googleSKU, swgMetaInfo),
            )
            // }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        subscriptions,
        googleSKU,
        handleSubscribeWithGoogleButton,
        eventManagerLoaded,
        checkingTokenState,
        hasAttemptedValidation,
        buttonRef,
        breachInitialised,
        swgMetaInfo,
    ])
}
