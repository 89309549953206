import * as React from 'react'
import {
    StyledButton,
    StyledButtonInnerContainer,
    StyledSpinner,
} from './LiveEventLoadButton.styled'

export interface LiveEventButtonProps {
    isLoading: boolean
    onClick: () => void
}

export const LiveEventLoadButton: React.FC<LiveEventButtonProps> = ({
    onClick,
    isLoading,
}) => {
    return (
        <StyledButton isLoading={isLoading} onClick={onClick}>
            <StyledButtonInnerContainer isLoading={isLoading}>
                {isLoading ? (
                    <>
                        <StyledSpinner />
                        <span>Loading</span>
                    </>
                ) : (
                    <span>Load more</span>
                )}
            </StyledButtonInnerContainer>
        </StyledButton>
    )
}
