import styled from '@emotion/styled'
import { calcRem, metrics } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { HorizontalDividerProps } from './HorizontalDivider'

export const StyledHorizontalDivider = styled('hr')<HorizontalDividerProps>(
    ({ verticalGutters, verticalSpacing }) => [
        {
            border: 0,
            borderTop: '1px solid ',
            borderColor:
                tokens.sevennews.colors.palette.articlesV2.horizontalLine,
            width: 'auto',
            margin: '0 auto',

            marginBottom:
                verticalSpacing &&
                calcRem(metrics.sevennews.margins[verticalSpacing]),
            marginTop:
                verticalSpacing &&
                calcRem(metrics.sevennews.margins[verticalSpacing]),
            paddingTop:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[0]]),
            paddingBottom:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[1]]),
        },
    ],
)
