import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import {
    FundingLabel,
    FundingLabelProps,
} from '../../../advertising/FundingLabel/FundingLabel'
import { styledMediaClassName } from '../../../cards/CardMedia/StyledMedia.styled'
import { CardTeaser } from '../../../cards/CardText/CardTeaser'
import { colors } from '../../../__styling/settings/colors'
import { metrics } from '../../../__styling/settings/metrics'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { transition } from '../../../__styling/style-mixins'

const styledTextClassName = 'LandscapeWideCard-Text-Wrapper'
export const styledWrapperClassName = 'Card-Wrapper'

const margins = metrics.perthnow.margins
const sm = calcRem(margins.sm)
const textOffset = calcRem(metrics.perthnow.cards.textOffset)

export type StyledWrapperProps = { isLoading: boolean }

export const StyledWrapper = withClass(styledWrapperClassName)(
    styled(WebLink, {
        shouldForwardProp: createPropFilter<WebLinkProps>()(['isLoading']),
    })<StyledWrapperProps>(
        {
            color: colors.thewest.greyGorilla,
            lineHeight: 1.375,
            display: 'flex',
            flex: '1 3 200px',
            textDecoration: 'none',
            flexWrap: 'wrap',
            transform: `translateY(${-textOffset})`, // offset for font alignment
            alignItems: 'flex-start',
            ...transition({ properties: ['color'] }),

            '&:focus': {
                outline: 'none',

                [`& .${styledMediaClassName}`]: {
                    '&::after': {
                        borderColor: colors.white50,
                    },
                },
            },
        },
        (props: StyledWrapperProps) =>
            props.isLoading && {
                cursor: 'default',
            },
    ),
)

export const StyledText = withClass(styledTextClassName)(
    styled('div')({
        flex: '1 1 200px',
    }),
)

export const StyledTeaser = styled(CardTeaser)({
    flex: '1 1 200px',
    fontSize: calcRem(14),
})

export const StyledFundingLabel = styled(FundingLabel)<FundingLabelProps>(
    {
        alignSelf: 'flex-start',
        color: colors.perthnow.greySlate,
        display: 'inline-block',
        fontSize: calcRem(12),
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.sansSerifCond,
    }),
)

export const StyledSponsoredByline = styled('span')(
    {
        fontWeight: 400,
        fontSize: calcRem(13),
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: sm,
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.sansSerifCond,
    }),
)
