export interface TeamBrandData {
    teamId: string
    logo: string
    hexCode: string
    darkBgLogo: string
}

export const teamBrandData: TeamBrandData[] = [
    {
        // Adelaide Crows
        teamId: '10',
        logo: require('../assets/adelaide-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/adelaide-logo.svg'),
        hexCode: '#305E94',
    },
    {
        // St Kilda
        teamId: '130',
        logo: require('../assets/St_KIlda_LOGO_1.svg'),
        darkBgLogo: require('../assets/for-dark-bg/St_KIlda_LOGO_1.svg'),
        hexCode: '#E04B4C',
    },
    {
        // GC Suns
        teamId: '1000',
        logo: require('../assets/gold-coast-suns-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/gold-coast-suns-logo.svg'),
        hexCode: '#C83F30',
    },
    {
        // Hawthorn Hawks
        teamId: '80',
        logo: require('../assets/hawthorn-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/hawthorn-logo.svg'),
        hexCode: '#604336',
    },
    {
        // Essendon
        teamId: '50',
        logo: require('../assets/essendon-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/essendon-logo.svg'),
        hexCode: '#000000',
    },
    {
        // North Melbourne
        teamId: '100',
        logo: require('../assets/north-melbourne-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/north-melbourne-logo.svg'),
        hexCode: '#2852A4',
    },
    {
        // Carlton
        teamId: '30',
        logo: require('../assets/carlton-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/carlton-logo.svg'),
        hexCode: '#203443',
    },
    {
        // Melbourne
        teamId: '90',
        logo: require('../assets/melbourne-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/melbourne-logo.svg'),
        hexCode: '#0F1131',
    },
    {
        // Western Bulldogs
        teamId: '140',
        logo: require('../assets/western-bulldogs-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/western-bulldogs-logo.svg'),
        hexCode: '#20539D',
    },
    {
        // Geelong Cats
        teamId: '70',
        logo: require('../assets/geelong-cats-football-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/geelong-cats-football-logo.svg'),
        hexCode: '#002244',
    },
    {
        // Collingwood
        teamId: '40',
        logo: require('../assets/collingwood-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/collingwood-logo.svg'),
        hexCode: '#1A1A1A',
    },
    {
        // Richmond
        teamId: '120',
        logo: require('../assets/richmond-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/richmond-logo.svg'),
        hexCode: '#FFD200',
    },
    {
        // Fremantle Dockers
        teamId: '60',
        logo: require('../assets/fremantle-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/fremantle-logo.svg'),
        hexCode: '#2A0D54',
    },
    {
        // Sydney Swans
        teamId: '160',
        logo: require('../assets/sydney-swans-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/sydney-swans-logo.svg'),
        hexCode: '#E1251B',
    },
    {
        // Brisbane Lions
        teamId: '20',
        logo: require('../assets/brisbane-lions-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/brisbane-lions-logo.svg'),
        hexCode: '#A30046',
    },
    {
        // Westcoast Eagles
        teamId: '150',
        logo: require('../assets/west-coast-eagles-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/west-coast-eagles-logo.svg'),
        hexCode: '#F2A900',
    },
    {
        // Port Adelaide
        teamId: '110',
        logo: require('../assets/port-adelaide-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/port-adelaide-logo.svg'),
        hexCode: '#008AAB',
    },
    {
        // Giants
        teamId: '1010',
        logo: require('../assets/GWS_Giants_logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/GWS_Giants_logo.svg'),
        hexCode: '#EB873F',
    },

    //WOMENS
    {
        // Adelaide Crows
        teamId: '8098',
        logo: require('../assets/adelaide-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/adelaide-logo.svg'),
        hexCode: '#305E94',
    },
    {
        // St Kilda
        teamId: '8796',
        logo: require('../assets/St_KIlda_LOGO_1.svg'),
        darkBgLogo: require('../assets/for-dark-bg/St_KIlda_LOGO_1.svg'),
        hexCode: '#E04B4C',
    },
    {
        // GC Suns
        teamId: '8786',
        logo: require('../assets/gold-coast-suns-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/gold-coast-suns-logo.svg'),
        hexCode: '#C83F30',
    },
    {
        // Hawthorn Hawks
        teamId: '9407',
        logo: require('../assets/hawthorn-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/hawthorn-logo.svg'),
        hexCode: '#604336',
    },
    {
        // Essendon
        teamId: '9406',
        logo: require('../assets/essendon-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/essendon-logo.svg'),
        hexCode: '#000000',
    },
    {
        // North Melbourne Kangaroos
        teamId: '8466',
        logo: require('../assets/north-melbourne-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/north-melbourne-logo.svg'),
        hexCode: '#2852A4',
    },
    {
        // Carlton
        teamId: '8096',
        logo: require('../assets/carlton-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/carlton-logo.svg'),
        hexCode: '#203443',
    },
    {
        // Melbourne
        teamId: '7386',
        logo: require('../assets/melbourne-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/melbourne-logo.svg'),
        hexCode: '#0F1131',
    },
    {
        // Western Bulldogs
        teamId: '7387',
        logo: require('../assets/western-bulldogs-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/western-bulldogs-logo.svg'),
        hexCode: '#20539D',
    },
    {
        // Geelong Cats
        teamId: '8467',
        logo: require('../assets/geelong-cats-football-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/geelong-cats-football-logo.svg'),
        hexCode: '#002244',
    },
    {
        //Collingwood
        teamId: '8097',
        logo: require('../assets/collingwood-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/collingwood-logo.svg'),
        hexCode: '#1A1A1A',
    },
    {
        // Richmond
        teamId: '8788',
        logo: require('../assets/richmond-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/richmond-logo.svg'),
        hexCode: '#FFD200',
    },
    {
        // Fremantle Dockers
        teamId: '7886',
        logo: require('../assets/fremantle-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/fremantle-logo.svg'),
        hexCode: '#2A0D54',
    },
    {
        // Sydney Swans
        teamId: '9408',
        logo: require('../assets/sydney-swans-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/sydney-swans-logo.svg'),
        hexCode: '#E1251B',
    },
    {
        // Brisbane Lions
        teamId: '7887',
        logo: require('../assets/brisbane-lions-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/brisbane-lions-logo.svg'),
        hexCode: '#A30046',
    },
    {
        // Westcoast Eagles
        teamId: '8787',
        logo: require('../assets/west-coast-eagles-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/west-coast-eagles-logo.svg'),
        hexCode: '#F2A900',
    },
    {
        //Port Adelaide
        teamId: '9409',
        logo: require('../assets/port-adelaide-logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/port-adelaide-logo.svg'),
        hexCode: '#008AAB',
    },
    {
        // Giants
        teamId: '7889',
        logo: require('../assets/GWS_Giants_logo.svg'),
        darkBgLogo: require('../assets/for-dark-bg/GWS_Giants_logo.svg'),
        hexCode: '#EB873F',
    },
]
