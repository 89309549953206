import { Issue } from '@news-mono/web-common'
import React from 'react'
import { StyledEpaperCollectionPapersStyledImg } from './EpaperCollection.styled'
import { calcRem } from '../../../__styling'
import {
    StyledDualImageBackImage,
    StyledDualImageContainer,
    StyledDualImageFrontImage,
} from './EpaperDualImage.styled'

export interface EpaperDualImageProps {
    frontIssue: Issue
    backIssue: Issue
}

export const EpaperDualImage: React.FC<EpaperDualImageProps> = ({
    frontIssue,
    backIssue,
}) => {
    return (
        <StyledDualImageContainer>
            <StyledDualImageBackImage
                src={backIssue.coverUrl}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = require('../assets/paper.jpg')
                }}
            />
            <StyledDualImageFrontImage
                src={frontIssue.coverUrl}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = require('../assets/paper.jpg')
                }}
            />
        </StyledDualImageContainer>
    )
}
