import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { linearGradient } from 'polished'
import { StyledBylineTextClassName } from '../../cards/CardByline/CardByline.styled'
import { styledMediaClassName } from '../../cards/CardMedia/StyledMedia.styled'
import { StyledHeadlineText } from '../../cards/CardText/CardText.styled'
import { StyledLink } from '../../cards/CardText/CardTextInteractions.styled'
import { StyledIconCamera, StyledIconVideo2 } from '../../cards/Kicker/Kicker'
import { StyledCommentsIconClassName } from '../../cards/Kicker/Kicker.styled'
import { StyledHeroCardLink } from '../../cards/TheWest/HeroCard/HeroCard.styled'
import { StyledHeroSupportCardClassName } from '../../cards/TheWest/HeroSupportCard/HeroSupportCard.styled'
import { IconArrowCircle } from '../../icons'
import { TheWest190SmallLogo } from '../../logos/TheWest190Small/TheWest190Small'
import { TheWestLiveLogo } from '../../logos/TheWestLiveLogo/TheWestLiveLogo'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import {
    StyledChevronRight,
    HeadingLink,
    SectionHeaderContainer,
    WebNavLink,
    Divider,
} from '../../section-header/SectionHeader/SectionHeader.styled'
import {
    breakpoint,
    breakpointMax,
    themedValue,
    ThemeOverrideTypes,
} from '../../__styling'
import {
    ContainerWidth,
    metrics,
    ThemeMargins,
} from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

type Gutters = boolean | keyof ThemeMargins

export interface StyledThorProps {
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: Gutters
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: Gutters
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    useStylingForHiddenPodcast?: boolean
}

interface ThemedMetrics {
    margins: ThemeMargins
}

const getGutterSize = (
    themedMetrics: ThemedMetrics,
    gutter?: Gutters,
): string | number => {
    return gutter === false
        ? 0
        : calcRem(
              typeof gutter === 'string'
                  ? themedMetrics.margins[gutter]
                  : themedMetrics.margins.outerMargin,
          )
}

export const StyledThor = styled('div')<StyledThorProps>(
    {
        margin: '0 auto',
        width: '100%',
        display: 'block',
    },
    ({
        theme,
        verticalSpacing,
        horizontalGutters,
        verticalGutters,
        hasBackgroundFill,
        containerWidth,
        useStylingForHiddenPodcast,
    }) => {
        const themedMetrics = metrics[theme.kind]

        return {
            marginBottom: useStylingForHiddenPodcast
                ? theme.margins.xl
                : verticalSpacing &&
                  calcRem(themedMetrics.margins[verticalSpacing]),
            paddingLeft: getGutterSize(themedMetrics, horizontalGutters),
            paddingRight: getGutterSize(themedMetrics, horizontalGutters),
            paddingTop: getGutterSize(themedMetrics, verticalGutters),
            paddingBottom: useStylingForHiddenPodcast
                ? undefined
                : getGutterSize(themedMetrics, verticalGutters),
            background: hasBackgroundFill
                ? theme.colors.background.secondary
                : 'transparent',
            color: theme.colors.text.primary,
            maxWidth: containerWidth,
        }
    },
)

export interface StyledFeatureContainerProps {
    hasExtraBottomMargin?: boolean
    customPadding?: number
    initialFlexDirection?: 'column' | 'row'
    unsetFeatureContainerBackground?: boolean
    isMobile?: boolean
    layout?: '4-card' | '8-card' | '7-card'
    // {TODO} Get rid of the bellow props after AFL Grand Final
    freoWon?: boolean
}

export const StyledFeatureContainer = styled(
    'div',
)<StyledFeatureContainerProps>(
    ({ theme, unsetFeatureContainerBackground }) => ({
        background: theme.colors.background.primary,
        color: theme.colors.text.primary,

        [`& ${StyledThor}`]: {
            background: unsetFeatureContainerBackground
                ? 'none'
                : theme.colors.background.primary,
        },

        [`& .${StyledHeroSupportCardClassName}`]: {
            [breakpoint('md')]: {
                borderBottom: 'none',
            },
        },

        [`& ${StyledLink}:hover, & ${StyledHeroCardLink}:hover`]: {
            [`& ${StyledHeadlineText}`]: {
                color: theme.colors.actions.link.hover,
            },

            [`& .${styledMediaClassName}`]: {
                '&::after': {
                    borderColor: theme.colors.actions.link.hover,
                },
            },
        },
    }),
)

// TODO replace usage of this with StyledFeatureTitleContainer
export const StyledFeatureContainer__Deprecated = styled(
    StyledFeatureContainer,
)<StyledFeatureContainerProps>([
    ({
        theme,
        hasExtraBottomMargin,
        customPadding,
        initialFlexDirection,
        unsetFeatureContainerBackground,
    }) => ({
        alignItems: 'flex-start',
        background: unsetFeatureContainerBackground
            ? 'none'
            : theme.colors.background.secondary,
        color: theme.colors.text.primary,
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        flexDirection: initialFlexDirection ? initialFlexDirection : 'column',
        padding: customPadding
            ? calcRem(customPadding)
            : calcRem(theme.margins.sm, theme.margins.md),
        paddingBottom: hasExtraBottomMargin
            ? theme.margins.md
            : theme.margins.sm,
        [breakpoint('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    }),
    ({ theme, layout }) =>
        // We want to show the extraBottomBorder under the following conditions
        // If layout is 4 card, we want to only show it in mobile
        layout === '4-card' && {
            [breakpointMax('xs')]: {
                '&::after': {
                    position: 'absolute',
                    bottom: 0,
                    left: '3%',
                    width: '94%',
                    content: `''`,
                    height: calcRem(1),
                    backgroundColor: theme.colors.borders.secondary,
                },
                marginBottom: calcRem(12),
            },
        },
])

export interface StyledBackgroundWrapProps {
    themeOverride: ThemeOverrideTypes
}

export const StyledBackgroundWrap = styled('div')<StyledBackgroundWrapProps>(
    ({ theme }) => ({
        background: theme.colors.background.primary,
    }),
    ({ theme, themeOverride }) =>
        (themeOverride === 'grand-final-freo' ||
            themeOverride === 'grand-final') && {
            [`& .${StyledBylineTextClassName}`]: {
                color: theme.colors.text.tertiary,
            },

            [`& .${StyledCommentsIconClassName}`]: {
                fill: theme.colors.text.secondary,
            },

            [`& .${StyledIconCamera}`]: {
                fill: theme.colors.text.secondary,
            },

            [`& .${StyledIconVideo2}`]: {
                fill: theme.colors.text.secondary,
            },
        },
)

// DTEC-788: Federal Election
export const StyledGradientWrap = styled('div')<{
    linearGradientColors?: Array<string>
    boxShadow?: boolean
}>(({ theme, linearGradientColors, boxShadow }) => [
    {
        padding: calcRem(4),
        paddingTop: themedValue(theme, {
            perthnow: calcRem(21),
            fallback: undefined,
        }),
        boxShadow: boxShadow ? '0px 8px 20px 0px rgb(0 0 0 / 47%)' : undefined,
        marginBottom: theme.margins.xl,
        overflow: 'hidden',

        [`& ${HeadingLink}`]: {
            color: theme.colors.brand,
        },

        [`& ${StyledChevronRight}`]: {
            fill: theme.colors.brand,
        },
    },
    linearGradientColors && {
        ...linearGradient({
            colorStops: linearGradientColors,
            fallback: 'transparent',
            toDirection: '90deg',
        }),
    },
])

export const StyledFeatureBorder = styled('h1')(({ theme }) => ({
    borderBottom: `4px double ${theme.colors.borders.secondary}`,
    display: 'block',
    width: '100%',
    height: 1,
    marginBottom: theme.margins.xl,
}))

export const StyledGradientSectionTitleH1 = styled('h1')(({ theme }) => ({
    fontSize: calcRem(32),
    fontWeight: 700,
    margin: 0,
    fontFamily: theme.fonts.sansSerifCond,
    textTransform: 'uppercase',
}))

export const StyledGradientSectionTitle = styled('h2')(({ theme }) => ({
    fontSize: calcRem(32),
    fontWeight: 700,
    margin: 0,
    fontFamily: theme.fonts.sansSerifCond,
    textTransform: 'uppercase',
}))

export const StyledFeatureSectionHeader = styled(SectionHeader)(() => ({
    flexDirection: 'row-reverse',

    [`& ${Divider}`]: {
        display: 'none',
    },
}))

// DTEC-850: Federal Election - Banner
export const StyledPollieRaterBannerWrap = styled('div')(({ theme }) => ({
    background: theme.colors.palette.white,
    padding: calcRem(8, 14, 16, 14),
}))

export const StyledPollieRaterBanner = styled('div')(({ theme }) => ({
    ...linearGradient({
        colorStops: ['#1E04E2', '#B03D28'],
        fallback: 'transparent',
        toDirection: '90deg',
    }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: calcRem(72),
    width: '100%',
    padding: calcRem(16),
    borderRadius: 4,

    [breakpoint('sm')]: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
}))

export const StyledPollieRaterBannerTitle = styled('h3')(({ theme }) => ({
    fontSize: calcRem(24),
    fontWeight: 600,
    lineHeight: calcRem(24),
    margin: calcRem(0, 0, 8, 0),
    fontFamily: theme.fonts.sansSerif,
    color: theme.colors.palette.white,
    textAlign: 'center',
    [breakpoint('sm')]: {
        margin: calcRem(0, 32, 0, 0),
    },
}))

export const StyledPollieRaterBannerButton = styled(WebNavLink)(
    ({ theme }) => ({
        width: calcRem(144),
        height: calcRem(32),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.colors.palette.white,
        color: theme.colors.brand,
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(13),
        fontWeight: 700,
        textDecoration: 'none',
        textAlign: 'center',
        borderRadius: '2px',

        '&:focus': {
            backgroundColor:
                theme.colors.actions.button.secondary!.background.hover,
            color: theme.colors.actions.button.secondary!.text.hover,
        },
    }),
)

const footerLayoutBreakpoint = 'xxs'

export const StyledTheWestLiveLogo = styled(TheWestLiveLogo)(({ theme }) => ({
    gridArea: 'westlive',
    height: 56,
    width: 161,
    marginBottom: calcRem(theme.margins.sm),
    marginRight: calcRem(theme.margins.sm),

    [breakpoint(footerLayoutBreakpoint)]: {
        justifySelf: 'end',
        margin: 0,
        marginRight: calcRem(theme.margins.xl),
        gridRow: '1 / 3',
    },
}))

export const StyledTheWest190SmallLogo = styled(TheWest190SmallLogo)(
    (props) => ({
        width: 197,
        height: 44,
        fill: props.fill ? props.fill : '#000',
    }),
)

export const StyledGoMatildasLogoContainer = styled('div')({
    marginBottom: '10px',
    [breakpoint('sm')]: {
        marginBottom: '-5px',
    },
})

export const StyledListenButton = styled(WebNavLink)(({ theme }) => ({
    backgroundColor: theme.colors.actions.button.secondary!.background.default,
    border: `1px solid ${theme.colors.actions.button.secondary!.border}`,
    color: theme.colors.actions.button.secondary!.text.default,
    display: 'block',
    gridArea: 'button',
    margin: 0,

    '&:not(:first-child)': {
        marginLeft: 0,
    },

    '&:hover': {
        backgroundColor:
            theme.colors.actions.button.secondary!.background.hover,
        color: theme.colors.actions.button.secondary!.text.hover,
    },

    '&:focus': {
        backgroundColor:
            theme.colors.actions.button.secondary!.background.hover,
        color: theme.colors.actions.button.secondary!.text.hover,
    },

    [breakpoint(footerLayoutBreakpoint)]: {
        gridArea: 'text',
        gridRow: 2,
        width: 'auto',
        justifySelf: 'start',
    },
}))

export const StyledTitle = styled(WebLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.colors.text.primary,

    ['h2']: {
        fontWeight: 'normal',
    },
}))

export const StyledCNBCLogo = styled('img')(({ theme }) => ({
    marginTop: calcRem(theme.margins.sm),
    height: calcRem(40),
    maxWidth: '100%',
}))

export const StyledEconomistLogo = styled('img')(({ theme }) => ({
    marginTop: calcRem(theme.margins.sm),
    height: calcRem(24),
    width: 'auto',
}))

/** Commonwealth Games Specific Elements */
export const StyledCommonwealthGamesContainer = styled('div')({
    marginTop: calcRem(11),
})

export const StyledCommonwealthGamesText = styled('span')(({ theme }) => ({
    display: 'block',
    fontWeight: 700,
    lineHeight: calcRem(32),
    fontSize: calcRem(32),
    color: theme.colors.palette.white,
    fontFamily: theme.fonts.sansSerifCond,
    marginBottom: calcRem(theme.margins.sm),

    [breakpoint('md')]: {
        '& > br': {
            display: 'none',
        },
        margin: 0,
    },
}))

export const StyledFeatureSectionHeaderContainer = styled('div')(
    ({ theme }) => ({
        [breakpoint('md')]: {
            marginTop: calcRem(theme.margins.sm),
        },
    }),
)

export const StyledGrandFinalText = styled('span')(({ theme }) => ({
    lineHeight: calcRem(theme.margins.lg),
    fontSize: calcRem(theme.margins.lg),
    textTransform: 'uppercase',
    fontFamily: theme.fonts.sansSerifCond,
    fontWeight: 700,
    color: theme.colors.palette.white,
}))

export const StyledIconArrowCircle = styled(IconArrowCircle)({
    width: calcRem(26),
    height: calcRem(26),

    [breakpoint('sm')]: {
        marginTop: '4px',
    },
})

export const StyledGrandFinalWebLink = styled(WebLink)({
    textDecoration: 'none',
})

export const StyledGrandFinalSectionHeader = styled('span')(({ theme }) => ({
    fontWeight: 400,
    fontSize: calcRem(22),
    lineHeight: calcRem(26),
    fontFamily: theme.fonts.sansSerif,
    color: theme.colors.palette.white,
    marginRight: theme.margins.sm,
    transform: 'translate(0px, -7px)',
    display: 'none',

    [breakpoint('xs')]: {
        display: 'inline-block',
    },
}))

export const StyledFreoTag = styled('span')(({ theme }) => ({
    color: 'rgb(255,255,255,0.8)',
    fontWeight: 600,
    lineHeight: calcRem(16),
    fontSize: calcRem(20),
    fontFamily: theme.fonts.sansSerif,
    marginLeft: 0,
    marginTop: calcRem(4),

    [breakpoint('xs')]: {
        marginLeft: theme.margins.md,
        marginTop: 0,
    },
}))

export const StyledTitleHeader = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    [breakpoint('xs')]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
})

const featurePadding = calcRem(16)

export const StyledFeatureHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: featurePadding,
    justifyContent: 'space-between',
}))

export const StyledFeatureTitleContainer = styled('div')(({ theme }) => ({
    fontWeight: 700,
    fontFamily: theme.fonts.sansSerifCond,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& img': {
        marginRight: featurePadding,
        marginBottom: calcRem(16),
        display: 'block',

        [breakpoint('xs')]: {
            marginBottom: 0,
        },
    },

    '& h2': {
        fontSize: calcRem(32),
        lineHeight: 1,
        margin: 0,
    },
}))

export const KingsCoronationTitleContainer = styled('div')(({ theme }) => ({
    fontFamily: theme.fonts.serif,
    fontWeight: 500,
    fontSize: 32,
    textTransform: 'uppercase',
    color: 'transparent',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.text.primary,
    backgroundImage: theme.colors.text.heading,
    WebkitBackgroundClip: 'text',
}))

export const StyledClickthrough = styled(WebLink)(({ theme }) => ({
    textDecoration: 'none',
    display: 'none',

    [breakpoint('sm')]: {
        alignItems: 'center',
        display: 'flex',
    },
}))

export const Styled190TopPadding = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.brand,
    height: metrics.thewest.margins.sm,

    [breakpoint('xs')]: {
        height: metrics.thewest.margins.md,
    },
}))

interface StyledClickthroughTextProps {
    fontStyle?: 'serif' | 'sans-serif'
}

export const StyledClickthroughText = styled(
    'span',
)<StyledClickthroughTextProps>(({ theme, fontStyle }) => ({
    fontWeight: 400,
    fontSize: calcRem(16),
    lineHeight: calcRem(26),
    color: theme.colors.actions.link.default,
    paddingRight: featurePadding,
    fontFamily:
        fontStyle === 'sans-serif' ? theme.fonts.sansSerif : theme.fonts.serif,
}))
