import React from 'react'
import { TeamSide } from './AflPlayerStatsWidget'
import {
    StyledAflPlayerStatsWidgetTeamButton,
    StyledAflPlayerStatsWidgetTeamControls,
} from './AflPlayerStatsWidget.styled'

export interface TeamControlsProps {
    teamSide: TeamSide
    handleTeamChange: (side: TeamSide) => void
}
export const TeamControls = ({
    handleTeamChange,
    teamSide,
}: TeamControlsProps) => {
    return (
        <StyledAflPlayerStatsWidgetTeamControls>
            <StyledAflPlayerStatsWidgetTeamButton
                isActive={teamSide === 'home'}
                onClick={() => handleTeamChange('home')}
            >
                Home
            </StyledAflPlayerStatsWidgetTeamButton>
            <StyledAflPlayerStatsWidgetTeamButton
                isActive={teamSide === 'both'}
                onClick={() => handleTeamChange('both')}
            >
                Both
            </StyledAflPlayerStatsWidgetTeamButton>
            <StyledAflPlayerStatsWidgetTeamButton
                isActive={teamSide === 'away'}
                onClick={() => handleTeamChange('away')}
            >
                away
            </StyledAflPlayerStatsWidgetTeamButton>
        </StyledAflPlayerStatsWidgetTeamControls>
    )
}
