import React from 'react'

import { tokens } from '@news-mono/design-tokens'
import { useAuthActions } from '../../../authentication'
import { useSelector } from 'react-redux'
import { AppState, SignupOrigin } from '@news-mono/web-common'
import { CTAText } from '../../../warden'
import {
    StyledAccountModifyBodyText,
    StyledAccountModifyHeading,
    StyledAccountModifyTextLink,
    StyledAccountModifyTextWrapper,
    StyledAccountModifyWardenButton,
} from '../../../warden/account-modify'

type LoginContentProps = {
    text: CTAText
    componentOrigin?: SignupOrigin
}

export const LoginContent = ({ text, componentOrigin }: LoginContentProps) => {
    const { onRegisterClick, onLoginClick } = useAuthActions()
    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'

    return (
        <>
            <StyledAccountModifyTextWrapper>
                <StyledAccountModifyHeading>
                    {text.title}
                </StyledAccountModifyHeading>
                <StyledAccountModifyBodyText>
                    {text.body}
                </StyledAccountModifyBodyText>
            </StyledAccountModifyTextWrapper>

            <StyledAccountModifyWardenButton
                onClick={() => onRegisterClick(componentOrigin, renditionType)}
                backgroundColors={{
                    default:
                        tokens.thenightly.colors.palette.utility.error.default,
                    hover: tokens.thenightly.colors.palette.utility.error.hover,
                }}
                roundEdges={true}
                useWidePadding={true}
            >
                CREATE FREE ACCOUNT
            </StyledAccountModifyWardenButton>

            <StyledAccountModifyBodyText>
                Already have an account?
                <StyledAccountModifyTextLink onClick={onLoginClick}>
                    Log in here
                </StyledAccountModifyTextLink>
            </StyledAccountModifyBodyText>
        </>
    )
}
