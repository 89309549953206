import {
    createRegisterableComponent,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import { getVideoQueue } from '../../templates/Publication/lib/get-video-queue'
import { shouldExcludeVideoSeries } from '../../templates/Publication/lib/should-include-video-series'
import { getPublicationInfoFromRouteResolution } from '../../templates/Publication/SharedPublication.routing'
import { FigcaptionRenderOptions } from '../ArticleImage/ArticleImage'
import { ArticleHero } from './ArticleHero'

export interface ArticleHeroRegistrationProps {
    adUnitPath: string
    heading: string
    publicationPathname?: string
    figcaptionRenderOptions?: FigcaptionRenderOptions
    imageRenderType?: 'feature'
}
export const ArticleHeroRegistration = createRegisterableComponent(
    'article-hero',
    (props: ArticleHeroRegistrationProps, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)
        if (!isArticleLikeType(publicationInfo.publication)) {
            throw new Error('Article component rendered for non-article')
        }

        return (
            <ArticleHero
                heroImage={publicationInfo.publication.heroImage}
                heroVideo={publicationInfo.publication.heroVideo}
                heading={publicationInfo.publication.heading}
                isVideoStickyEnabled={false}
                showVideoTitles={true}
                publicationPathname={publicationInfo.publication.slug}
                onEvent={services.onEvent}
                getVideoQueue={() =>
                    getVideoQueue(
                        services,
                        publicationInfo.publication.id,
                        shouldExcludeVideoSeries(
                            publicationInfo.publication,
                            services.store.getState().toggles,
                        ),
                    )
                }
                figcaptionRenderOptions={props.figcaptionRenderOptions}
                imageRenderType={props.imageRenderType}
                adUnitPath={props.adUnitPath}
            />
        )
    },
)
