import styled from '@emotion/styled'
import {
    breakpointSwitch,
    breakpoint,
    calcRem,
    getComponentTheme,
    Theme,
} from '../../__styling'
import { IconChevronDownTN, IconChevronLeftDoubleTN } from '../../icons'
import { WebLink } from '@news-mono/web-common'
import { DisplayProps } from './components/PaginationItem'
import { CSSObject } from '@emotion/react'

export const StyledPaginationList = styled.ul(({ theme }) => {
    const paginationTheme = getComponentTheme(theme, 'pagination')
    return [
        {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            li: {
                listStyle: 'none',
            },

            fontFamily: paginationTheme.fontFamily,
            width: paginationTheme.width,
            padding: paginationTheme.padding,
            margin: paginationTheme.margin,
        },
        paginationTheme.gap &&
            breakpointSwitch(paginationTheme.gap, (gap) => ({
                gap,
            })),
    ]
})

export const Ellipses = styled.div(({ theme }) => {
    const paginationTheme = getComponentTheme(
        theme,
        'pagination',
    ).paginationItem

    return {
        display: 'none',

        [breakpoint('sm')]: {
            display: 'block',
            textDecoration: paginationTheme.textDecoration,
            backgroundColor: paginationTheme.backgroundColor,
            textTransform: paginationTheme.textTransform,
            fontSize: paginationTheme.fontSize,
            fontWeight: paginationTheme.fontWeight,
            lineHeight: paginationTheme.lineHeight,
            color: '#a8a8a8',
            pointerevents: 'none',
        },
    }
})

type ItemProps = {
    hasIcon?: boolean
    display: DisplayProps
}
export const getButtonStyles = ({
    theme,
    hasIcon,
    display,
}: ItemProps & { theme: Theme }) => {
    const paginationTheme = getComponentTheme(
        theme,
        'pagination',
    ).paginationItem

    const styles: CSSObject = {
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        gap: paginationTheme.gap,
        textDecoration: paginationTheme.textDecoration,
        backgroundColor: paginationTheme.backgroundColor,
        textTransform: paginationTheme.textTransform,
        borderRadius: paginationTheme.borderRadius,
        color: paginationTheme.color,
        fontSize: paginationTheme.fontSize,
        fontWeight: paginationTheme.fontWeight,
        lineHeight: paginationTheme.lineHeight,
        outlineOffset: -1,

        '&:hover': {
            background: '#F4F4F4',
        },
        '&.enabled-active': {
            background: '#F4F4F4',
        },
        '&.enabled': {},

        '&.disabled': {
            color: '#a8a8a8',
            pointerevents: 'none',
            cursor: 'not-allowed',
        },

        transition: 'background 0.25s',
    }
    return [
        styles,
        hasIcon
            ? { padding: calcRem(12, 8), gap: 0 }
            : paginationTheme.padding &&
              breakpointSwitch(paginationTheme.padding, (padding) => ({
                  padding,
              })),
        display &&
            breakpointSwitch(display, ({ display }) => {
                return {
                    display: display,
                }
            }),
    ]
}

export const PaginationListItem = styled.li<{
    display: DisplayProps
    flexGrow?: boolean
}>(({ display, flexGrow = false }) => [
    {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: flexGrow ? 1 : undefined,
    },
    breakpointSwitch(display, (style) => style),
])

export const StyledPaginationItem = styled.button<ItemProps>((props) => [
    {
        // Reset button styles.
        padding: 0,
        border: 'none',
        background: 'transparent',
    },
    getButtonStyles(props),
])

export const StyledPaginationLink = styled(WebLink)<ItemProps>((props) => [
    {
        // Reset anchor styles.
        color: 'inherit',
        textDecoration: 'none',
    },
    getButtonStyles(props),
])

export const StyledText = styled.span<{ hideTextAtMobile?: boolean }>(
    ({ theme, hideTextAtMobile }) => {
        return [
            hideTextAtMobile && {
                display: 'none',
                [breakpoint('sm')]: {
                    display: 'flex',
                },
            },
        ]
    },
)
export const StyledPageNumberWrapper = styled.div(({ theme }) => {
    return {
        display: 'flex',
        overflow: 'hidden',
        flexWrap: 'wrap',

        height: calcRem(48),
    }
})
interface IconProps {
    isLeft?: boolean
    isActive?: boolean
}
export const StyledChevronIcon = styled(IconChevronDownTN)<IconProps>(
    ({ theme, isLeft, isActive }) => {
        return {
            transform: isLeft ? 'rotate(90deg)' : 'rotate(270deg)',
            width: calcRem(24),
            height: calcRem(24),
            fill: 'none',

            path: {
                stroke: isActive ? '#A8A8A8' : '#161616',
            },
        }
    },
)
export const StyledDoubleChevronIcon = styled(
    IconChevronLeftDoubleTN,
)<IconProps>(({ theme, isLeft, isActive }) => {
    return {
        transform: isLeft ? '' : 'rotate(180deg)',
        width: calcRem(24),
        height: calcRem(24),
        fill: 'none',

        path: {
            stroke: isActive ? '#A8A8A8' : '#161616',
        },
    }
})
