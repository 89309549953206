import { ShouldLoadProps } from './with-lazy-loading'

export function getShouldLoadProps(ref: HTMLElement): ShouldLoadProps {
    const viewportHeight = window.innerHeight
    let percentVisible = 0
    // getBoundingClientRect() may cause a forced synchronous layout
    // Do NOT alter state while we are still iterating components to see which are onscreen
    // Altering state may make another forced synchronous layout necessary after every component AKA "layout thrashing"
    // top and bottom are relative to the top left of the viewport
    const { top, bottom, height } = ref.getBoundingClientRect()
    // If bottom is -ve control is entirely above the viewport
    // If top is > viewportHeight control is entirely below the viewport
    if (bottom > 0 && top < viewportHeight) {
        // Some part of control is onscreen. Calculate % onscreen
        const visibleBottom = Math.min(bottom, viewportHeight)
        const visibleTop = Math.max(top, 0)
        // Will be -ve if off top of screen. Equals gap between top of screen and bottom of control
        // Will be +ve if off bottom of screen. Equals gap between bottom of screen and top of control
        const heightOnScreen = visibleBottom - visibleTop
        // if element's border-boxes are empty then a rectangle is returned with height zero
        const noHeight = height === 0
        percentVisible = noHeight
            ? 100
            : // top/bottom of element or viewport
              Math.min(Math.round((heightOnScreen / height) * 100), 100)
    }
    // distance from viewport calculation
    // 0 if onscreen
    // if bottom < 0, -bottom == distance from top of viewport to bottom of control
    // otherwise control is below viewport. distance from top of control to bottom or viewport
    const distanceFromViewport =
        percentVisible === 0
            ? Math.round(bottom < 0 ? -bottom : top - viewportHeight)
            : 0
    return {
        percentVisible,
        viewportHeight,
        distanceFromViewport,
    }
}
