import { useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    ComponentServices,
    createCardClickedEvent,
    entitledToAll,
    FixedRatio,
    isPublicationCardItemWithVideo,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { FundingLabel } from '../../advertising/FundingLabel/FundingLabel'
import { CardByline } from '../../cards/CardByline/CardByline'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledHeroCard,
    StyledHeroCardMedia,
    StyledHeroCardText,
    StyledLink,
} from '../../cards/HeroCard/HeroCard.styled'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { videoQueueDebug } from '../../content/Video/providers/debug-log'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import {
    getVideoQueue,
    VideoQueue,
} from '../../templates/Publication/lib/get-video-queue'
import { shouldExcludeVideoSeries } from '../../templates/Publication/lib/should-include-video-series'
import { showBylineDateByTopic } from '../../__helpers/show-byline-date-by-topic'
import { isVideoClick } from '../../__helpers/video-card-helpers'
import { FontScales } from '../../__styling/settings/fontScale'
import { themedValue } from '../../__styling/themed-value'
import { TeaserMode } from '../CardText/CardTeaser'
import { KickerPosition } from '../Kicker/Kicker'
import {
    StyledHeroCardTextContainer,
    StyledHeroVideoCardCell,
} from './HeroVideoCard.styled'

export type HeroVideoLayoutRatios = '1:1' | '3:1' | '1:3'

export type VideoPositionLayouts = 'video-on-left' | 'video-on-right'

export type VideoPlaybackRestrictionTypes =
    | 'entitled'
    | 'registered'
    | 'all'
    | undefined

export interface HeroVideoCardProps extends CommonCardProps {
    hasBackground?: boolean
    imageLayout?: ResponsivePictureLayout
    kickerPosition?: KickerPosition
    teaserMode: TeaserMode
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem>
    getVideoQueue?: GetVideoQueue
    cardServices?: ComponentServices
    layoutRatio?: HeroVideoLayoutRatios
    videoPosition?: VideoPositionLayouts
    restrictedVideoPlaybackMode?: VideoPlaybackRestrictionTypes
    largePlayButton?: boolean
    hasHeroBorder?: boolean
    adUnitPath: string | undefined
}

export const HeroVideoCard: React.FC<HeroVideoCardProps> = (props) => {
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )

    const toggleState = useSelector((state: AppState) => state.toggles)

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        //Ensures image link in hero card position on The West homepage works for non-subscribers when there is an inline video DPT-3960
        if (allowInlinePlayback && isVideoClick(e.nativeEvent.target)) {
            e.preventDefault()
            return
        } else {
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    props.item.result,
                    'InternalHeroCard',
                    'HeroCard',
                    props.cardNumber,
                    fundingType,
                ),
            )
        }
    }

    const returnVideoQueue = () => {
        videoQueueDebug('returning video queue for HeroVideoCard')
        if (!props.item.loaded) {
            videoQueueDebug('Item not loaded: Empty array returned')
            return Promise.resolve<VideoQueue>({
                type: 'not-playlist-video',
                items: [],
            })
        }

        if (props.item.loaded && props.item.result.id && props.cardServices) {
            videoQueueDebug('Standard video queue returned')

            const isVideoSeries =
                isPublicationCardItemWithVideo(props.item.result) &&
                props.item.result.video.videoSeries

            videoQueueDebug(
                'Deciding if we should exclude Video Series... %o',
                [props.item.result, isVideoSeries],
            )

            return getVideoQueue(
                props.cardServices,
                props.item.result.id,
                shouldExcludeVideoSeries(props.item.result, toggleState),
            )
        }

        videoQueueDebug('Neither conditional enforced - empty array returned.')

        return Promise.resolve<VideoQueue>({
            type: 'not-playlist-video',
            items: [],
        })
    }

    const theme = useTheme()
    const {
        className,
        hasBackground,
        imageLayout,
        hideByline,
        teaserMode,
        onEvent,
        item,
        fixedRatio,
        layoutRatio = '1:1',
        videoPosition,
        largePlayButton,
        restrictedVideoPlaybackMode,
        hasHeroBorder = true,
    } = props

    const showByline = theme.kind !== 'perthnow' && !hideByline
    const storyClassification = true
    const fundingType = item.loaded
        ? getFundingType(item.result, storyClassification)
        : undefined

    const primaryTopic = item.loaded ? item.result.primaryTopic.id : undefined
    const showPublicationDate = showBylineDateByTopic(primaryTopic)

    const fontScale = themedValue(theme, {
        thewest: 1.75,
        sevennews: 1.7,
        fallback: 2,
    }) as FontScales

    const isEntitled = entitledToAll(authentication)

    const allowInlinePlayback =
        restrictedVideoPlaybackMode !== 'entitled' ||
        (restrictedVideoPlaybackMode === 'entitled' && isEntitled)

    const cardTextCell = (
        <StyledHeroVideoCardCell
            layoutRatio={layoutRatio}
            videoPosition={videoPosition}
        >
            <StyledHeroCardTextContainer videoPosition={videoPosition}>
                <StyledHeroCardText
                    item={item}
                    hasBackground={hasBackground}
                    kickerMode={{ size: 'large' }}
                    teaserMode={teaserMode}
                    fontScale={fontScale}
                    onEvent={onEvent}
                />
                {showByline && (
                    <CardByline
                        item={item}
                        onEvent={onEvent}
                        showProfile={true}
                        showPublicationDate={showPublicationDate}
                    />
                )}
                {fundingType && (
                    <FundingLabel
                        hasBackground={hasBackground}
                        fundingType={fundingType}
                    />
                )}
            </StyledHeroCardTextContainer>
        </StyledHeroVideoCardCell>
    )

    return (
        <StyledHeroCard
            className={className}
            data-topic={primaryTopic}
            isSponsored={!!fundingType}
            hasBackground={hasBackground}
            primaryTopic={primaryTopic}
            hasHeroBorder={hasHeroBorder}
        >
            <StyledLink
                to={toLinkWithHint(item)}
                onClick={linkClicked}
                hasBackground={hasBackground}
                isLoading={!item.loaded}
            >
                {!videoPosition ||
                    (videoPosition &&
                        videoPosition === 'video-on-right' &&
                        cardTextCell)}
                <StyledHeroVideoCardCell
                    layoutRatio={layoutRatio}
                    videoPosition={videoPosition}
                >
                    <StyledHeroCardMedia
                        isLarge={largePlayButton}
                        item={item}
                        fixedRatio={fixedRatio}
                        hasBackground={hasBackground}
                        onEvent={onEvent}
                        className={className}
                        // Hero card should never be lazy loaded
                        disableImageLazyLoad={true}
                        willPlayVideoInline={allowInlinePlayback}
                        getVideoQueue={returnVideoQueue}
                        imageLayout={
                            imageLayout ||
                            ResponsivePictureLayout.ObjectFitContain
                        }
                        imageWidths={{
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '628px',
                            fallbackWidth: 628,
                        }}
                        adUnitPath={props.adUnitPath}
                    />
                </StyledHeroVideoCardCell>
                {videoPosition &&
                    videoPosition === 'video-on-left' &&
                    cardTextCell}
            </StyledLink>
        </StyledHeroCard>
    )
}
HeroVideoCard.displayName = 'HeroVideoCard'
