import {
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import { LazyTheWestLiveInArticleBanner } from '../../../content/TheWestLive/TheWestLiveInArticleBanner/TheWestInArticleBanner.lazy'
import {
    InlineContentTypes,
    InlineWestLiveBanner,
} from '../SharedPublication.routing'

export interface GetInlinePositionedImageLinkProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlineWestLiveBanner(
    content: InlineContentTypes,
): content is InlineWestLiveBanner {
    return content.kind === 'the-west-live-in-article-banner'
}

export function getInlinePositionedWestLiveBanner({
    publication,
    inlinePublicationContent,
}: GetInlinePositionedImageLinkProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineWestLiveBanner)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: <LazyTheWestLiveInArticleBanner />,
            }
            return mappedContent
        })
}
