export const getLinkedInOnClick = (url: string) => () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url,
    )}`
    // LinkedIn will throw an error unless the URL is HTTPS which isn't a thing when running locally, use the below instead...
    const devTestUrl = `https://www.linkedin.com/shareArticle?mini=true&url=https://thewest.com.au/`
    const options = 'toolbar=0,status=0,resizable=1,width=626,height=436'

    const linkedInWindow = window.open('', 'sharer', options)
    if (linkedInWindow) {
        linkedInWindow.opener = null // noopener equivalent
        linkedInWindow.location.href = linkedInUrl
    }
}
