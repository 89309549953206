import React from 'react'

export interface PerthNowMarkProps {
    className?: string
}

export const PerthNowMark: React.FC<PerthNowMarkProps> = ({ className }) => (
    <svg
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>PerthNow</title>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#FFF"
                d="M6.562 7.92a2.299 2.299 0 1 0 1.883 3.615 2.54 2.54 0 0 0 .387-1.36c0-.232-.03-.46-.089-.679a2.299 2.299 0 0 0-2.181-1.575"
            />
            <g transform="translate(0 .001)">
                <mask id="b" fill="#fff">
                    <path id="a" d="M0 .069h20.932V20.93H0z" />
                </mask>
                <path
                    fill="#FFF"
                    d="M18.549 14.092h-1.942v-3.945c0-.86-.064-2.289-1.423-2.289-1.278 0-1.78.942-1.78 1.98v4.254h-1.942V6.3h1.845v1.25h.032c.356-.78 1.084-1.445 2.378-1.445 2.087 0 2.832 1.477 2.832 3.068v4.919zm-11.987.356a4.21 4.21 0 0 1-2.28-.67v3.017H2.32v-6.499l.012-.02c0-.019-.003-.038-.003-.058a4.232 4.232 0 0 1 4.234-4.231 4.232 4.232 0 1 1 0 8.461zM17.456.068H3.476C1.566.069 0 1.639 0 3.558v13.885c0 1.918 1.565 3.488 3.477 3.488h13.979c1.912 0 3.476-1.57 3.476-3.488V3.557c0-1.919-1.564-3.488-3.476-3.488z"
                    mask="url(#b)"
                />
            </g>
        </g>
    </svg>
)
