import { DataLoaderResources } from 'react-ssr-data-loader'
import { Logger } from 'typescript-log'
import { BaseClientConfig } from '../client/BaseClientConfig'
import { IRouteCache } from '../data/route-cache'
import { Store } from '../store'

export interface DataLoaderGlobalParams {
    routeCache: IRouteCache
    config: BaseClientConfig
    log: Logger
    store: Store
    appVersion: string
}

export const resources = new DataLoaderResources<DataLoaderGlobalParams>()
