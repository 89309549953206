/* eslint-disable @typescript-eslint/no-var-requires */
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

const bannerDesktopX2 = require<string>('./assets/breakfast-brunch/breakfast-brunch-desktop-1220x150@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/breakfast-brunch/breakfast-brunch-desktop-1220x150.jpg')

const bannerTabletX2 = require<string>('./assets/breakfast-brunch/breakfast-brunch-tablet-738x57@2x.jpg')

const bannerTabletX1 = require<string>('./assets/breakfast-brunch/breakfast-brunch-tablet-738x57.jpg')

const bannerMobileX2 = require<string>('./assets/breakfast-brunch/breakfast-brunch-mobile-411x93@2x.jpg')

const bannerMobileX1 = require<string>('./assets/breakfast-brunch/breakfast-brunch-mobile-411x93.jpg')

export const getBreakfastBrunchRouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: bannerDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText:
                    'Perth Breakfast and Brunch food reviews with Kate Emery - the hits and misses. Email Kate at kate.emery@wanews.com.au',
                url: 'mailto:kate.emery@wanews.com.au?subject=Website Enquiry for Breakfast and Brunch&body=For Kate Emery,%0D%0A%0D%0AIn reference to your website column at https://thewest.com.au/lifestyle/perth-breakfast-brunch.%0D%0A%0D%0APlease type your message here...',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: bannerDesktopX2, density: '2x' },
                            { src: bannerDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: bannerTabletX2, density: '2x' },
                            { src: bannerTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: bannerMobileX2, density: '2x' },
                            { src: bannerMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/perth-breakfast-brunch'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/perth-breakfast-brunch'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/perth-breakfast-brunch'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
