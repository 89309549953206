import {
    createRegisterableComponentWithData,
    ePaperLatestEditionDataDefinitionLoader,
} from '@news-mono/web-common'
import React from 'react'
import { LatestEdition } from './LatestEdition'

export const ePaperPageLatestEditionRegistration =
    createRegisterableComponentWithData(
        'epaper-page-latest-edition',
        ePaperLatestEditionDataDefinitionLoader,
        ({}, data, services) => {
            if (data.loaded) {
                return (
                    <LatestEdition
                        latestEdition={data.result.latestIssues[0]}
                        allEditions={data.result.latestIssues}
                        onEvent={services.onEvent}
                    />
                )
            }
            return (
                //maybe send down some default values if data no loaded
                null
            )
        },
    )
