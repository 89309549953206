import { ConfigurationContext } from '@news-mono/web-common'
import {
    GuestbookMessage,
    PaginatedContentfulMessage,
} from '@west-australian-newspapers/contentful-retrieval-utilities'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { Logger } from 'typescript-log'
import { GuestBookItem } from './types'

export const useGuestBookData = (
    campaign: string,
    logger: Logger,
    skip: string,
    pageSize: string,
) => {
    const config = useContext(ConfigurationContext)
    const contentful = config.contentful
    const encodedCampaign = encodeURIComponent(campaign)

    const guestBookData = useQuery(
        ['guest-book-data', skip],
        async () => {
            const dataLocation = `https://cdn.contentful.com/spaces/${contentful?.spaceId}/environments/${contentful?.environmentId}/entries?skip=${skip}&limit=${pageSize}&content_type=guestbookMessage&fields.campaign[in]=${encodedCampaign}&fields.approval[in]=Approve`
            const response = await fetch(dataLocation, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${config.contentful?.accessToken}`,
                },
            })

            if (!response.ok) {
                logger.debug({ campaign }, 'Unable to retrieve Guest Book data')
                throw new Error('Unable to retrieve Guest Book data')
            }

            const res = await response.json()
            return res
        },
        {
            // Reading the docs, it seems that staleTime === maxAge, cacheTime === time before cache is garbage collected
            staleTime: 120000, // 2 * 60 * 1000
            cacheTime: 130000, // 2* 60 * 1000
            // Since we want the user to manually refresh, we shouldn't refresh on window focus.
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    )

    return guestBookData
}

export const mapData = (
    data: PaginatedContentfulMessage<GuestbookMessage> | undefined,
): Array<GuestBookItem> | undefined => {
    if (!data) {
        return
    }
    // We only really want the firstName/lastName/message fields to populate the Guestbook component
    return data.items.map((guestbookMessage) => {
        return {
            author:
                guestbookMessage.fields.firstName +
                ' ' +
                guestbookMessage.fields.lastName,
            message: guestbookMessage.fields.message,
        }
    })
}
