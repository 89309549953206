import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledProfileWrap = styled('div')({
    borderBottom: 'none',
    marginBottom: calcRem(20),
    [breakpoint('md')]: {
        padding: `0 ${calcRem(40)} ${calcRem(16)}`,
    },
})

export const StyledSharing = styled('div')({
    borderTop: 'solid 2px #E7E7E7',
    marginTop: calcRem(20),
    [breakpoint('md')]: {
        marginTop: calcRem(40),
        borderTop: 'solid 4px #E7E7E7',
    },
})
