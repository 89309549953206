import { getGooTest } from '../advertising/get-goo-test'
import { GptAdSlotDefinition } from '../advertising/gpt-ad-slot-definintion'
import { sizeProps, SizePropsKey } from '../advertising/size-props'
import { RenderTarget } from '../context-providers'
import { getAdByBreakpoints } from './ad-breakpoint-helpers'
import { getAdSize, pickFirstSizeMapping } from './get-ad-size'

const arrayUnique = <T>(arr: T[]): T[] =>
    arr.filter((el, pos) => arr.indexOf(el) === pos)

export interface GetAdIdProps {
    type: string
    id: string
}

export const getAdId = (props: GetAdIdProps) =>
    `gpt-ad-slot-${props.type}-${props.id}`

export const normalizeTopicKeywords = (topics: string[]): string[] => {
    const normalizedTopics = topics.map((topic) =>
        topic.replace(/["'=!+#;^()<>[\]]/g, ''),
    )

    return arrayUnique(normalizedTopics)
}

export interface AdTargeting {
    /**
     * Ad ops need an alpha numeric Id to target article pages in particular,
     * non alpha-numeric characters will be cleaned automatically
     */
    pageId: string
    /**
     * ad unit path used by dfp for targetting
     */
    adUnitPath: string
    /**
     * Used by dfp for server to server calls
     */
    ssAdUnits: string[]
    topics: string[]
}

interface AdsUsed {
    [key: string]: number
}

export interface AdDefinition {
    id: string
    size: SizePropsKey
    lazyLoadingDistance?: number
    exclusionLabels?: string[]
    pageType?: string
    isSitewideAd?: boolean
    disableLazyLoading?: boolean
}

export const cleanPageId = (pageTitle: string) =>
    pageTitle.replace(/[^a-z0-9A-Z ]/g, '').toLowerCase()

export function getAds(
    adOptions: AdTargeting,
    adDefintions: AdDefinition[],
    renderTarget: RenderTarget,
): {
    breakpoints: number[]
    slotMap: AdSlotMap
} {
    const adsUsed: AdsUsed = {}
    const generatedAdProps: AdSlotMap = {}
    const cleanedPageId = cleanPageId(adOptions.pageId)
    const gootest = getGooTest()

    const adsByBreakpoints = getAdByBreakpoints(
        adDefintions.map((ad) => {
            const adSizeProps = sizeProps[ad.size]
            return {
                companion: adSizeProps.companion,
                adPosCategory: adSizeProps.adPosCategory,
                sizeMapping: adSizeProps.sizeMapping,
                outOfPage: adSizeProps.outOfPage,
                forceSafeFrame: adSizeProps.forceSafeFrame,
                originalDefinition: ad,
            }
        }),
    )

    for (const adBreakpoint of adsByBreakpoints.ads) {
        for (const ad of adBreakpoint.ads) {
            const key = `${adBreakpoint.start}-${ad.adPosCategory}`
            const id = getAdSlotId(adBreakpoint.start, ad.originalDefinition.id)
            if (!adsUsed[key]) {
                adsUsed[key] = 1
            }
            const position = (adsUsed[key]++).toString()

            const adSize = getAdSize(ad.sizeMapping, adBreakpoint.start)
            const firstSize = pickFirstSizeMapping(adSize)

            generatedAdProps[id] = {
                adUnitPath: adOptions.adUnitPath,
                targeting: {
                    slotId: ad.originalDefinition.id,
                    pos: position,
                    // pagetitle is used as a page id
                    pagetitle: cleanedPageId,
                    ss_adunits: adOptions.ssAdUnits,
                    gootest,
                    topics:
                        adOptions.topics &&
                        normalizeTopicKeywords(adOptions.topics),
                    exclusionLabels: ad.originalDefinition.exclusionLabels,
                    pagetype: ad.originalDefinition.pageType,
                    platform: renderTarget,
                },
                id: id,
                sizeMapping: ad.sizeMapping,
                companion: ad.companion,
                disableLazyLoading: ad.originalDefinition.disableLazyLoading,
                lazyLoadingDistance: ad.originalDefinition.lazyLoadingDistance,
                originalDefinition: ad.originalDefinition,
                forceSafeFrame: ad.forceSafeFrame,
                outOfPage: ad.outOfPage,
                exclusionLabels: ad.originalDefinition.exclusionLabels,
                placeholderHeight:
                    // If the size mapping has a placeholder use it, otherwise use the first size width
                    adSize && adSize.placeholderHeight !== undefined
                        ? adSize.placeholderHeight
                        : firstSize
                        ? firstSize[1]
                        : undefined,
            }
        }
    }

    return {
        slotMap: generatedAdProps,
        breakpoints: adsByBreakpoints.breakpoints,
    }
}

export function getAdSlotId(
    adBreakpoint: number,
    /** We have two ids currently, the slot id and the ad id. Slot id is unique per breakpoint and  */
    adId: string,
) {
    return `${adBreakpoint}-${adId}`
}

export interface AdSlotMap {
    [key: string]: GptAdSlotDefinition
}

export const adSlotsAsArray = (slots: AdSlotMap): GptAdSlotDefinition[] =>
    Object.keys(slots)
        .map((key) => slots[key])
        .filter((entry) => entry !== undefined)
