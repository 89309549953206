import {
    BreakpointState,
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../__styling'

export type ArticleCardListTheme<T extends ThemeConfig> = {
    gap: BreakpointState<T, string>
    divider: {
        color?: string
    }
}

export const theNightlyArticleCardListTheme = (scheme: TheNightlyTheme) => {
    const theme: ArticleCardListTheme<TheNightlyThemeConfig> = {
        gap: {
            default: calcRem(24),
            sm: calcRem(scheme.margins.lg),
            lg: calcRem(24),
        },
        divider: {
            color: scheme.colors.borders.secondary,
        },
    }
    return theme
}
