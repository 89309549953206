import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    AflFixturesScoreboardWidget,
    AflFixturesScoreboardWidgetProps,
} from './AflFixturesScoreboardWidget'

export const AflFixturesScoreboardWidgetRegistration =
    createRegisterableComponent(
        'afl-fixtures-scoreboard',
        (props: AflFixturesScoreboardWidgetProps, services) => {
            return <AflFixturesScoreboardWidget {...props} />
        },
    )
