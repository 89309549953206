import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoute, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../get-topic-sponsor'

const cleoBannerDesktopX2 = require<string>('./assets/cleo/cleo-desktop-1220x150@2x.jpg')
const cleoBannerTabletX2 = require<string>('./assets/cleo/cleo-tablet-738x57@2x.jpg')
const cleoBannerMobileX2 = require<string>('./assets/cleo/cleo-mobile-411x93@2x.jpg')

const cleoBannerDesktop = require<string>('./assets/cleo/cleo-desktop-1220x150.jpg')
const cleoBannerTablet = require<string>('./assets/cleo/cleo-tablet-738x57.jpg')
const cleoBannerMobile = require<string>('./assets/cleo/cleo-mobile-411x93.jpg')

export const getCleoRouteInfo =
    (topic: Topic): StaticRoute<TheWestSection> =>
    ({ getAdTargeting }) => ({
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        includeFromSectionInMetaDescription: true,
        pageMeta: {
            description:
                'Missing girl Cleo Smith and all the latest news and information relating to her disappearance at the Blowholes campsite in Carnarvon and the subsequent investigation.',
        },
        adTargeting: getAdTargeting('home', 'default', topic),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: cleoBannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText:
                                    'My Name Is Cleo: The 18-Day Police Miracle',
                                url: '#cleo-podcast',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: cleoBannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cleoBannerDesktop,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: cleoBannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cleoBannerTablet,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: cleoBannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: cleoBannerMobile,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.component({
                            type: 'sierra',
                            props: {
                                verticalSpacing: 'gridGap',
                                fixedRatios: ['16:9'],
                                cardOrientation: {
                                    type: 'Landscape-Portrait',
                                    maxBreakpoint: 'md',
                                },
                                cardLayout: [
                                    'hero',
                                    'teaser-xsVisible-mdHidden-Image',
                                ],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'cleo',
                                    offset: 0,
                                    pageSize: 2,
                                },
                            },
                        }),
                        layout.component({
                            type: 'section-header',
                            props: {
                                heading: 'My name is Cleo Podcast',
                                idTagName: 'cleo-podcast',
                                hasBottomMargin: true,
                            },
                        }),
                        layout.component({
                            type: 'omny-web',
                            props: {
                                height: 391, // magic number to get the height we want while avoiding the last item displaying a bottom border
                                verticalSpacing: 'xs',
                                link: 'https://omny.fm/shows/my-name-is-cleo/playlists/podcast/embed?style=cover',
                                smallPlaylist: true,
                            },
                        }),
                        layout.component({
                            type: 'uniform',
                            props: {
                                verticalSpacing: 'xl',
                                section: 'default',
                                cardOrientation: { type: 'Landscape-Portrait' },
                                cardLayout: [
                                    ['teaserWithBackground'],
                                    ['teaserWithBackground'],
                                    [
                                        'xsImage-mdNoImage-withBG',
                                        'xsImage-mdNoImage-withBG',
                                    ],
                                    ['teaserWithBackground'],
                                ],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'cleo',
                                    offset: 2,
                                    pageSize: 7,
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),

                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                cardOrientation: { type: 'Landscape-Portrait' },

                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['news/cleo-smith'],
                                    includeSubTopics: false,
                                    paging: {
                                        page: 1,
                                        pageSize: 8,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('two'),
                    ],
                },
            }),
        ],
    })
