import {
    Block,
    ComponentServices,
    createRegisterableComponent,
    createRegisterableComponentWithData,
    DataLoaderGlobalParams,
    getStaticContent,
} from '@news-mono/web-common'
import { DataDefinition } from 'json-react-layouts-data-loader'
import React from 'react'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { ArticleBlockContent } from '../../publication/ArticleBlockContent/ArticleBlockContent'

// Inline Content
export interface InlineContentRouteProps {
    blocks: Block[]
}

export const InlineContentRegistration = createRegisterableComponent(
    'inline-content',
    (props: InlineContentRouteProps, services) => {
        return (
            <ArticleBlockContent
                blocks={props.blocks}
                log={services.log}
                onEvent={services.onEvent}
                publicationPathname={services.location.pathname}
                adUnitPath={services.adState.targeting.adUnitPath}
            />
        )
    },
)

// Static Content
export interface StaticContentRouteProps {}

export interface StaticContentData {
    id: string
}

const loadStaticContentData = async (
    services: DataLoaderGlobalParams,
    pageId: string,
): Promise<Block[]> => {
    return await getStaticContent(services, pageId)
}

export type StaticContentDataDefinition = DataDefinition<
    StaticContentData,
    Block[],
    ComponentServices
>

export const StaticContentDataDefinitionLoader: StaticContentDataDefinition = {
    loadData: (props, services) => loadStaticContentData(services, props.id),
}

export const StaticContentRegistration = createRegisterableComponentWithData(
    'static-content',
    StaticContentDataDefinitionLoader,
    (_: StaticContentRouteProps, data, services) => {
        if (data.loaded) {
            return (
                <ArticleBlockContent
                    blocks={data.result}
                    onEvent={services.onEvent}
                    log={services.log}
                    publicationPathname={services.location.pathname}
                    adUnitPath={services.adState.targeting.adUnitPath}
                />
            )
        } else {
            return (
                <div>
                    <p>
                        <Skeleton count={4} />
                    </p>
                </div>
            )
        }
    },
)

export default { InlineContentRegistration, StaticContentRegistration }
