import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem } from '../../__styling'
import { SubHeadingElements } from '../../typography/Subhead/Subhead'

export interface StyledHeaderProps {
    textCenter?: boolean
    specificFontSize?: number
    borderWidth?: number
    hasLogo?: boolean
}

export const StyledHeader = (hSize: SubHeadingElements) =>
    styled(hSize)<StyledHeaderProps>(
        ({ textCenter, specificFontSize, hasLogo }) => [
            {
                textAlign: textCenter ? 'center' : 'initial',
                fontSize: calcRem(specificFontSize || 17),
                fontWeight: 900,
                paddingBottom: calcRem(10),
                display: hasLogo ? 'inline-flex' : 'inline-block',
                gap: hasLogo ? calcRem(5) : undefined,
                alignItems: hasLogo ? 'center' : undefined,
                borderBottom: `${calcRem(2)} solid ${
                    tokens.sevennews.colors.palette.black
                }`,
                paddingRight: calcRem(30),

                '& img': {
                    height: calcRem(18),
                },
            },
        ],
    )

export const StyledHeaderLogo = styled('img')({})
