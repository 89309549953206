import { Profile, scaleImage } from '@news-mono/web-common'
import React from 'react'
import { createRenderTarget } from '../../../render-target'
import {
    StyledHeadshot,
    StyledHeadshotAmp,
    StyledHeadshotWrapper,
    StyledHeadshotWrapperLink,
} from '../Byline.styled'
import { getProfileLink } from '../helpers/getProfileLink'

const PROFILE_IMAGE_RES_SIZE = 110
export const PROFILE_IMAGE_DISPLAY_SIZE = 53
export const NIGHTLY_PROFILE_IMAGE_DISPLAY_SIZE = 40

export interface BylineProfileProps {
    profilePageLink?: boolean
    profile: Profile[]
}

export const BylineProfile: React.FC<BylineProfileProps> = ({
    profile,
    profilePageLink,
}) => {
    const profileImage = getProfileImage(profile)
    const profileLink = getProfileLink(profile)
    console.log('>>>profileLink', { profileLink, profilePageLink })

    if (!profileImage) {
        return null
    }

    if (profileLink && profilePageLink) {
        return (
            <StyledHeadshotWrapperLink to={profileLink}>
                <Headshot
                    source={profileImage}
                    alt={
                        profile[0]
                            ? `Headshot of ${profile[0].name}`
                            : undefined
                    }
                />
            </StyledHeadshotWrapperLink>
        )
    }

    return (
        <StyledHeadshotWrapper>
            <Headshot
                source={profileImage}
                alt={profile[0] ? `Headshot of ${profile[0].name}` : undefined}
            />
        </StyledHeadshotWrapper>
    )
}

interface HeadshotProps {
    source: string
    alt: string | undefined
}

export const Headshot = createRenderTarget<HeadshotProps>('Headshot', {
    web: ({ alt, source }) => <StyledHeadshot alt={alt} src={source} />,
    amp: ({ alt, source }) => (
        <StyledHeadshotAmp
            width={PROFILE_IMAGE_DISPLAY_SIZE}
            height={PROFILE_IMAGE_DISPLAY_SIZE}
            alt={alt}
            src={source}
        />
    ),
    rss: ({ alt, source }) => <img src={source} alt={alt} />,
    preview: ({ alt, source }) => <StyledHeadshot alt={alt} src={source} />,
    app: ({ alt, source }) => <StyledHeadshot alt={alt} src={source} />,
})
Headshot.displayName = 'Headshot'

export function getProfileImage(profiles: Profile[]): string | undefined {
    for (const profile of profiles) {
        if (profile.thumbnailPhoto) {
            return scaleImage(profile.thumbnailPhoto, PROFILE_IMAGE_RES_SIZE)
        }
    }
    return undefined
}
