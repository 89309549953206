/**
 * Some browsers don't support 'smooth' behavior on the scrollIntoView method,
 * so we only use it if the browser supports it.
 *
 * See: SWM-5162
 */
export function smoothScrollTo(targetElement: Element) {
    if (document && document.documentElement) {
        const willSmoothScroll =
            'scrollBehavior' in document.documentElement.style

        if (targetElement && willSmoothScroll) {
            targetElement.scrollIntoView({ behavior: 'smooth' })
        } else if (targetElement) {
            targetElement.scrollIntoView()
        }
    }
}
