import {
    createRegisterableComponentWithData,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import {
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../../templates/Publication/SharedPublication.routing'
import { ArticlePublicationDataDefinitionLoader } from '../ArticlePublicationDataLoader'
import { getVideoQueue } from '../lib/get-video-queue'
import { shouldExcludeVideoSeries } from '../lib/should-include-video-series'
import { getWestInlinePositionedContent } from './get-west-inline-positioned-content'
import { TheWestFeature } from './TheWestFeature'

export const TheWestFeatureRegistration = createRegisterableComponentWithData(
    'article-feature',
    ArticlePublicationDataDefinitionLoader,
    (props: PublicationRouteProps, data, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)
        if (!isArticleLikeType(publicationInfo.publication)) {
            throw new Error('Article component rendered for non-article')
        }

        if (data.loaded) {
            const inlinePositionedContent = getWestInlinePositionedContent({
                adState: services.adState,
                publication: publicationInfo.publication,
                meta: props.meta,
                log: services.log,
                onEvent: services.onEvent,
                toggles: services.store.getState().toggles,
                westLiveState: services.store.getState().theWestLive,
            })

            return (
                // DPT-1865 - Page Addtional props component wrapping this moved to FeatureArticleBreach.routing to fix analytics
                <TheWestFeature
                    data={publicationInfo.publication}
                    inlinePositionedContent={inlinePositionedContent}
                    onEvent={services.onEvent}
                    log={services.log}
                    getVideoQueue={() =>
                        getVideoQueue(
                            services,
                            publicationInfo.publication.id,
                            shouldExcludeVideoSeries(
                                publicationInfo.publication,
                                services.store.getState().toggles,
                            ),
                        )
                    }
                    heroOptions={{
                        isVideoStickyEnabled: props.isVideoStickyEnabled,
                    }}
                    relatedPublications={data.result.relatedPublications}
                    location={services.location}
                    adState={services.adState}
                />
            )
        }
        return null
    },
)
