import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../App.routing'

const socialImage = require('./topic/custom/assets/pollie-rater-landing-page/socialImage.png')
export const pollieRater: StaticRoutes<TheWestSection> = {
    '/politics/federal-election/pollie-rater': ({
        getAdTargeting,
        store,
        config,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/politics/federal-election/pollie-rater'
        const topic: Topic = {
            id: 'politics/federal-election/pollie-rater',
            metadata: {},
            title: 'Pollie Rater',
            seoTitle:
                'Pollie Rater: Rate your Federal politicians ahead of the election',
            seoDescription: `Pollie Rater lets you have your say on Federal politicians, candidates and cabinet ministers ahead of the election.`,
        }

        const getSocialImageMeta = [
            {
                property: 'og:image',
                content: socialImage,
            },
            { name: 'twitter:image', content: socialImage },
            {
                property: 'og:image:height',
                content: '643',
            },
            {
                property: 'og:image:width',
                content: '1224',
            },
            {
                property: 'og:description',
                content: topic.seoDescription,
            },
            {
                property: 'twitter:description',
                content: topic.seoDescription,
            },
        ]

        return {
            kind: 'page',
            heading: 'Pollie Rater',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'athena',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        elementType: 'div',
                        sidebarOptions: 'hiddenMobile',
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'pollie-rater-banner',
                                props: {},
                            }),
                            layout.component({
                                type: 'pollie-rater',
                                props: {
                                    dataDefinitionArgs: {},
                                },
                            }),
                        ],

                        // SIDEBAR
                        sidebar: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'sidebar-top',
                                        size: 'desktopMrecHalfPage',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),

                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset: 350,
                                    fillContainer: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                padding: [
                                                    0,
                                                    0,
                                                    metrics.thewest.margins.md,
                                                    0,
                                                ],
                                                slot: {
                                                    id: 'sidebar-middle',
                                                    size: 'desktopMrec',
                                                    pageType: 'static',
                                                },
                                                adType: 'inline',
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
