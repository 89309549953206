import React from 'react'

export interface IconInstagramProps {
    title?: string
    className?: string
}

export const IconInstagram: React.FC<IconInstagramProps> = ({
    className,
    title,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        className={className}
    >
        <title>{title || 'Share to Instagram'}</title>
        <path
            d="M22.52,8.1a4.6,4.6,0,0,0-2.63-2.63A6.6,6.6,0,0,0,17.71,5c-1,0-1.27,0-3.71,0s-2.75,0-3.71,0a6.6,6.6,0,0,0-2.18.42A4.6,4.6,0,0,0,5.48,8.1a6.6,6.6,0,0,0-.42,2.18c0,1,0,1.27,0,3.71s0,2.71,0,3.71a6.6,6.6,0,0,0,.42,2.18,4.6,4.6,0,0,0,2.63,2.63,6.6,6.6,0,0,0,2.18.42c1,0,1.27,0,3.71,0s2.71,0,3.71,0a6.6,6.6,0,0,0,2.18-.42,4.6,4.6,0,0,0,2.63-2.63,6.6,6.6,0,0,0,.42-2.18c0-1,0-1.27,0-3.71s0-2.71,0-3.71A6.6,6.6,0,0,0,22.52,8.1Zm-1.19,9.54A5,5,0,0,1,21,19.31,3,3,0,0,1,19.31,21a5,5,0,0,1-1.67.31c-1,0-1.23,0-3.64,0s-2.69,0-3.64,0A5,5,0,0,1,8.69,21,3,3,0,0,1,7,19.31a5,5,0,0,1-.31-1.67c0-1,0-1.23,0-3.64s0-2.69,0-3.64A5,5,0,0,1,7,8.69,3,3,0,0,1,8.69,7a5,5,0,0,1,1.67-.31c1,0,1.24,0,3.64,0s2.68,0,3.64,0A5,5,0,0,1,19.31,7,3,3,0,0,1,21,8.69a5,5,0,0,1,.31,1.67c0,1,0,1.23,0,3.64S21.33,16.69,21.33,17.64Z"
            transform="translate(-5.01 -5)"
        />
        <path
            d="M14,9.37A4.62,4.62,0,1,0,18.62,14,4.62,4.62,0,0,0,14,9.37ZM14,17a3,3,0,1,1,3-3A3,3,0,0,1,14,17Z"
            transform="translate(-5.01 -5)"
        />
        <path
            d="M18.8,8.07a1.08,1.08,0,1,0,1.08,1.12v0A1.08,1.08,0,0,0,18.8,8.07Z"
            transform="translate(-5.01 -5)"
        />
    </svg>
)
IconInstagram.displayName = 'IconInstagram'
