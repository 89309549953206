import React, { useContext, useState, useEffect, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { ConsentLevel, useConsentGiven, AppState } from '@news-mono/web-common'
import { LoggerContext } from '../../../diagnostics/LoggerContext'
import { StyledIframe, StyledTNIframe } from './AmuseLabsIframe.styled'
import { calcRem } from '../../../__styling/style-functions/calc-rem/index'

export type AmuseLabsIframeProps = {
    link: string
    sandbox?: string
    frameBorder?: number
    consentRequiredLevel?: ConsentLevel
    requiresEntitlement?: boolean // Used specifically for TN
}

export const AmuseLabsIframe = ({
    link,
    sandbox,
    frameBorder,
    consentRequiredLevel = ConsentLevel.Essential,
    requiresEntitlement,
}: AmuseLabsIframeProps) => {
    const logger = useContext(LoggerContext)
    const [amuselabsHeight, setAmuseLabsHeight] = useState(700)
    const consentGiven = useConsentGiven({
        consentRequiredLevel,
    })
    const authState = useSelector((state: AppState) => state.authentication)

    // Handle iframe height updates.
    useEffect(() => {
        function receiveMessage(event: unknown) {
            if (!isAmuseLabsEvent(event, link)) return

            const frameData: unknown = JSON.parse(event.data) as unknown

            if (isFrameHeightEvent(frameData)) {
                setAmuseLabsHeight(frameData.frameHeight)
            }
        }

        window.addEventListener('message', receiveMessage, false)
        return () => window.removeEventListener('message', receiveMessage)
    }, [link])

    if (!consentGiven) {
        return null
    }

    let puzzleSrc = link
    if (authState?.hashedUserEmail) {
        puzzleSrc += `&uid=${authState?.hashedUserEmail}`
    } else {
        authState &&
            logger.info(
                `Invalid hashedUserEmail: ${authState?.hashedUserEmail}`,
            )
    }

    if (requiresEntitlement) {
        return (
            <StyledTNIframe
                src={puzzleSrc}
                height={calcRem(amuselabsHeight)}
                sandbox={sandbox}
                frameBorder={frameBorder}
                entitled={authState.isLoggedIn && authState.emailVerified}
            >
                Sorry, we can't show this content as your browser does not
                support iframes.
            </StyledTNIframe>
        )
    }

    return (
        <StyledIframe
            src={puzzleSrc}
            height={calcRem(amuselabsHeight)}
            sandbox={sandbox}
            frameBorder={frameBorder}
        >
            Sorry, we can't show this content as your browser does not support
            iframes.
        </StyledIframe>
    )
}

type FrameHeightEvent = {
    frameHeight: number
}

/**
 * Type validator for FrameHeightEvent
 */
const isFrameHeightEvent = (
    eventData: unknown,
): eventData is FrameHeightEvent =>
    eventData !== null &&
    typeof eventData === 'object' &&
    'frameHeight' in eventData &&
    eventData.frameHeight !== null &&
    typeof eventData.frameHeight === 'number'

type AmuseLabsEvent = {
    origin: string
    data: string
}
/**
 * Type validator for AmuseLabsEvent
 * @param event The event object received.
 * @param amuseLabsURL The initial link provided. (Used to match domain with the event)
 * @returns If the event is an AmuseLabsEvent
 */
export const isAmuseLabsEvent = (
    event: unknown,
    amuseLabsURL: string,
): event is AmuseLabsEvent =>
    event !== null &&
    typeof event === 'object' &&
    'origin' in event && // Verify the origin is amuselabs.
    typeof event.origin === 'string' &&
    event.origin.indexOf(new URL(amuseLabsURL).host) !== -1 &&
    'data' in event &&
    typeof event.data === 'string'
