import React from 'react'
import { addListener, removeListener } from '../__helpers/global-dom-events'

export interface ResponsiveOutsideProps {
    breakpoints?: number[]
}

export interface ResponsiveProps {
    viewportWidth: number
}

export function responsiveComponent<P>(
    WrappedComponent:
        | React.ComponentClass<P & ResponsiveProps>
        | React.FC<P & ResponsiveProps>,
): React.ComponentClass<P & ResponsiveOutsideProps> {
    return class ResponsiveComponent extends React.Component<
        P & ResponsiveOutsideProps,
        ResponsiveProps
    > {
        constructor(props: P & ResponsiveOutsideProps) {
            super(props)

            this.state = {
                viewportWidth: this.getViewportWidth(),
            }
        }

        componentDidMount() {
            this.updateViewportWidth()
            addListener('resize', this.updateViewportWidth)
        }

        componentWillUnmount() {
            removeListener('resize', this.updateViewportWidth)
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    viewportWidth={this.state.viewportWidth}
                />
            )
        }

        getViewportWidth() {
            return typeof window !== 'undefined' ? window.innerWidth : 0
        }

        shouldUpdateViewportWidth(oldWidth: number, newWidth: number) {
            // TypeScript can't infer this correctly
            const breakpoints = this.props.breakpoints as number[] | undefined

            if (oldWidth === newWidth) {
                return false
            }

            if (!breakpoints) {
                return true
            }

            // find a breakpoint that is between the old and the new width
            return breakpoints.some(
                (breakpoint) =>
                    (oldWidth < breakpoint && newWidth >= breakpoint) ||
                    (oldWidth >= breakpoint && newWidth < breakpoint),
            )
        }

        updateViewportWidth = () => {
            const viewportWidth = this.getViewportWidth()

            if (
                this.shouldUpdateViewportWidth(
                    this.state.viewportWidth,
                    viewportWidth,
                )
            ) {
                this.setState({ viewportWidth })
            }
        }
    }
}
