import {
    BreakpointRatios,
    FixedRatio,
    ImageSource,
} from '@news-mono/web-common'
import React from 'react'
import { fixedRatioToBreakpointRatios, getCrops } from './utils'

export interface SimpleImgProps {
    image?: ImageSource
    fixedRatio: FixedRatio | BreakpointRatios
    renderPicture?: (pic: React.ReactElement) => React.ReactElement
}

export const SimpleImg: React.FC<SimpleImgProps> = ({
    image,
    fixedRatio,
    renderPicture = (pic) => pic,
}) => {
    if (!image) {
        return null
    }
    const breakpointCrops = getCrops(
        image.crops,
        fixedRatioToBreakpointRatios(fixedRatio || 'original'),
        false,
    )
    if (!breakpointCrops) {
        return null
    }
    return breakpointCrops
        ? renderPicture(
              <img
                  src={breakpointCrops.default.reference}
                  alt={image.altText}
              />,
          )
        : null
}
SimpleImg.displayName = 'SimpleImg'
