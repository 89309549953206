import {
    ArticleAvailableEvent,
    BreachEvent,
    click_origin_breach_screen,
    DataLayerEventName,
    PublicationType,
    sophiDebug,
    SSWQueryString,
} from '@news-mono/web-common'
import H from 'history'
import queryString from 'query-string'
import { eventQueue } from '../../__App/client'
import { mappedPublicationPropsToGa4 } from '../../__helpers/map-publication-props-to-ga4'

export function createIsEntitledChangeHandler(
    location: H.Location,
    onEvent: (event: BreachEvent | ArticleAvailableEvent) => void,
    publication: PublicationType,
): (event: { entitled: boolean; loggedInStatusChecked: boolean }) => void {
    return ({ entitled, loggedInStatusChecked }) => {
        if (!loggedInStatusChecked) {
            return
        }

        function sendSophiBreachEvent() {
            sophiDebug('Sending Sophi Wall Hit Event', []),
                window.sophi.sendEvent({
                    type: 'wall_hit',
                    data: {
                        type: 'paywall-hard',
                        name: 'regular',
                    },
                })
        }

        if (entitled) {
            const query = queryString.parse(location.search)

            const articleAvailablePayload = mappedPublicationPropsToGa4(publication)
            if (articleAvailablePayload) {
                onEvent({
                    type: DataLayerEventName.articleAvailable,
                    originator: 'breachscreen',
                    payload: articleAvailablePayload,
                })
            }

            // Need to ensure we convert the necessary items to numbers for the logic check below
            const sswQueryString: SSWQueryString = {
                transactionId: Number(query.transactionId),
                transactionTotal: Number(query.transactionTotal),
                transactionTax: Number(query.transactionTax),
                productSku: query.productSku,
                productName: query.productName,
                clickOrigin: query.clickOrigin,
            }

            const { clickOrigin, ...sswTransaction } = sswQueryString
            // Only raise when click origin is breach or breach inline
            if (
                sswTransaction.transactionId &&
                clickOrigin === click_origin_breach_screen
            ) {
                if (window.sophi && window.sophi.data) {
                    window.sophi.data.subscription_add = {
                        name: sswTransaction.productName,
                        transactionId: '', // TODO: Revisit this parameter later when we are able to send hashed transaction IDs from SSW
                        subscriptionType: '', // TODO: Revisit this and needs to be either 'print' or 'digital'
                    }
                }

                if (window.sophi && window.sophi.sendEvent) {
                    window.sophi.sendEvent({
                        type: 'payment',
                        data: {
                            transactionId: '', // TODO: Revisit this parameter later when we are able to send hashed transaction IDs from SSW
                        },
                    })
                }
            }
        } else {
            //Instead of sending the wall-hit event directly, store it here so that we can trigger it when we do a page-view.
            eventQueue.addEvent({
                eventName: 'sophi-wall-hit',
                callbackFunctions: [sendSophiBreachEvent],
            })
        }
    }
}
