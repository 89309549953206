import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'

// TODO update these :P
export const desktopLandingBanner1x = require('./assets/landing-desktop-1x.jpg')
export const desktopLandingBanner2x = require('./assets/landing-desktop-2x.jpg')
export const mobileLandingBanner1x = require('./assets/landing-mobile-1x.jpg')
export const mobileLandingBanner2x = require('./assets/landing-mobile-2x.jpg')
export const tabletLandingBanner1x = require('./assets/landing-tablet-1x.jpg')
export const tabletLandingBanner2x = require('./assets/landing-tablet-2x.jpg')
export const socialShareImage1x = require('./assets/social-share.jpg')
export const socialShareImage2x = require('./assets/social-share@2x.jpg')

export const goMatildasMessagesRoute: StaticRoutes<TheWestSection> = {
    '/sport/fifa-women-s-world-cup/go-matildas': ({
        getAdTargeting,
        store,
        config,
        resolution,
        log,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/sport/fifa-women-s-world-cup/go-matildas'

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                'sport/fifa-women-s-world-cup/go-matildas',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                'Go Matildas',
            seoTitle:
                getResolvedTopicPageMeta(resolution)?.seoTitle || 'Go Matildas',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription ||
                `Go Matildas`,
        }

        const getSocialImageMeta = [
            {
                property: 'og:image',
                content: socialShareImage1x,
            },
            { name: 'twitter:image', content: socialShareImage1x },
            {
                property: 'og:image:height',
                content: '628',
            },
            {
                property: 'og:image:width',
                content: '1200',
            },
            {
                property: 'og:description',
                content: topic.seoDescription,
            },
            {
                property: 'twitter:description',
                content: topic.seoDescription,
            },
        ]

        const isEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'go-matildas-messages-route',
            false,
        )

        if (!isEnabled) {
            return null
        }

        const { pageMeta, socialMeta } = {
            pageMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
        }

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'topic',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta,
            socialMeta,
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'athena',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        elementType: 'div',
                        sidebarOptions: 'hiddenMobile',
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: desktopLandingBanner2x,
                                    verticalGutters: ['unset', 'md'],
                                    hiddenHeading: topic.title,
                                    altText:
                                        'Leave your message for the Matildas',
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: desktopLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: desktopLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: tabletLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: tabletLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: mobileLandingBanner1x,
                                                    density: '1x',
                                                },
                                                {
                                                    src: mobileLandingBanner2x,
                                                    density: '2x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'guestbook',
                                props: {
                                    campaign: `Go Matildas`,
                                    logger: log,
                                    onEvent: () => {},
                                },
                            }),
                        ],

                        // SIDEBAR
                        sidebar: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'below-center',
                                    padding: [
                                        0,
                                        0,
                                        metrics.thewest.margins.md,
                                        0,
                                    ],
                                    slot: {
                                        id: 'sidebar-top',
                                        size: 'desktopMrecHalfPage',
                                        pageType: 'static',
                                    },
                                    adType: 'inline',
                                },
                            }),

                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset: 350,
                                    fillContainer: true,
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                noticePosition: 'below-center',
                                                padding: [
                                                    0,
                                                    0,
                                                    metrics.thewest.margins.md,
                                                    0,
                                                ],
                                                slot: {
                                                    id: 'sidebar-middle',
                                                    size: 'desktopMrec',
                                                    pageType: 'static',
                                                },
                                                adType: 'inline',
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
