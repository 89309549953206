import { useTheme } from '@emotion/react'
import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    Product,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommentButton } from '../..'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledFundingLabel,
    StyledHeader,
    StyledHeadline,
    StyledHeadlineSkeleton,
    StyledLandscapeStacked,
    StyledLink,
    StyledMedia,
    StyledTeaser,
    StyledText,
} from '../../cards/LandscapeStacked/LandscapeStacked.styled'
import { getEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { useProduct } from '../../__product/useProduct'
import { Section } from '../../__styling/settings/sections'
import { themedValue } from '../../__styling/themed-value'
import { MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'
import { KickerMode } from '../Kicker/Kicker'

export interface LandscapeStackedProps extends CommonCardProps {
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    cardNumber: number
    innerRef?: React.RefObject<any>
    item: MaybeLoaded<PublicationCardItem>
    mediaMode?: MediaMode
    teaserMode: TeaserMode
    kickerMode?: KickerMode
    hasBackground?: boolean
}

export interface TimeStampProps {
    section?: Section
}

export const LandscapeStacked: React.FC<LandscapeStackedProps> = (props) => {
    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            e.preventDefault()
            return
        }

        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalLandscapeStacked',
                'LandscapeStacked',
                props.cardNumber,
                fundingType,
            ),
        )
    }

    const product = useProduct()
    const theme = useTheme()
    const { fixedRatio = '4:3' } = props
    const storyClassification = true
    const fundingType = props.item.loaded
        ? getFundingType(props.item.result, storyClassification)
        : undefined
    const editorialType = props.item.loaded
        ? getEditorialType(props.item.result, storyClassification)
        : undefined

    // Sponsored cards in perthnow do not display kickers
    const isPerthNowFunded = !!fundingType && product === 'perthnow'
    const showPerthNowComments =
        useFeature('comments') &&
        theme.kind === Product.PerthNow &&
        props.item.loaded &&
        props.item.result.allowCommenting

    const setKickerMode = isPerthNowFunded ? 'hidden' : props.kickerMode

    const fundedSevenByline = !!fundingType && product === 'sevennews'

    const cardItem = props.item

    return (
        <StyledLandscapeStacked
            ref={props.innerRef}
            className={props.className}
            hasBackground={props.hasBackground}
            data-topic={props.item.loaded && props.item.result.primaryTopic.id}
        >
            <StyledLink
                to={toLinkWithHint(props.item)}
                onClick={linkClicked}
                isLoading={!props.item.loaded}
                hasBackground={false}
            >
                <StyledMedia
                    item={cardItem}
                    imageWidths={themedValue(theme, {
                        thewest: {
                            mobile: '110px',
                            tablet: '110px',
                            desktop: '110px',
                            fallbackWidth: 110,
                            mode: 'fixed',
                        },
                        perthnow: {
                            mobile: '106px',
                            tablet: '106px',
                            desktop: '106px',
                            fallbackWidth: 106,
                            mode: 'fixed',
                        },
                        sevennews: {
                            mobile: '126px',
                            tablet: '126px',
                            desktop: '126px',
                            fallbackWidth: 126,
                            mode: 'fixed',
                        },
                        fallback: {
                            mobile: '110px',
                            tablet: '110px',
                            desktop: '110px',
                            fallbackWidth: 110,
                            mode: 'fixed',
                        },
                    })}
                    fixedRatio={fixedRatio}
                    onEvent={props.onEvent}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    mediaMode={props.mediaMode}
                />

                <StyledHeader>
                    {cardItem.loaded ? (
                        <StyledHeadline
                            kickerText={cardItem.result.kicker}
                            headlineText={cardItem.result.shortHeadline}
                            kickerMode={setKickerMode}
                            fontScale={product === 'perthnow' ? 1.2 : 0.88}
                            isSponsored={!!fundingType}
                            publicationKind={cardItem.result.publicationKind}
                            editorialType={editorialType}
                        />
                    ) : (
                        <StyledHeadlineSkeleton
                            count={3}
                            fontScale={product === 'perthnow' ? 1.2 : 0.88}
                        />
                    )}
                    {/* sevennews funding label */}
                    {fundingType && fundedSevenByline && cardItem.loaded && (
                        <StyledFundingLabel
                            source={cardItem.result.byline}
                            fundingType={fundingType}
                        />
                    )}
                </StyledHeader>
                {props.teaserMode === 'hidden' ? null : (
                    <React.Fragment>
                        <StyledText sponsoredStyles={!!fundingType}>
                            <StyledTeaser
                                teaserMode={props.teaserMode}
                                teaserOrSkeletonLines={
                                    cardItem.loaded ? cardItem.result.teaser : 3
                                }
                            />
                        </StyledText>
                        {fundingType &&
                            !fundedSevenByline &&
                            cardItem.loaded && (
                                <StyledFundingLabel
                                    source={cardItem.result.byline}
                                    fundingType={fundingType}
                                />
                            )}
                    </React.Fragment>
                )}
                {showPerthNowComments && (
                    <CommentButton
                        customLink={
                            cardItem.loaded ? cardItem.result.link : undefined
                        }
                    />
                )}
            </StyledLink>
        </StyledLandscapeStacked>
    )
}
LandscapeStacked.displayName = 'LandscapeStacked'
