import React from 'react'
import { CommentButton, FeatureToggle } from '../..'
import { BylineProps } from './Byline'
import { StyledButtonContainer, StyledByline } from './Byline.styled'
import { BylineMail } from './components/BylineMail'
import { BylineProfile } from './components/BylineProfile'
import { BylineText } from './components/BylineText'

export const PerthNowByline: React.FC<BylineProps> = ({
    text,
    isFullWidth,
    requiredAccess,
    profile,
    fundingType,
    source,
    timestamp,
    timestampUpdated,
    allowCommenting,
    url,
    title,
    publicationKind,
}) => {
    const isEvent = publicationKind === 'event'

    return (
        <StyledByline isFullWidth={isFullWidth}>
            {profile && <BylineProfile profile={profile} />}
            <BylineText
                profile={profile}
                fundingType={fundingType}
                source={source}
                timestamp={timestamp}
                timestampUpdated={timestampUpdated}
                timestampUpdatedLabel={isEvent ? 'Updated' : undefined}
                text={text}
                requiredAccess={requiredAccess}
                allowCommenting={allowCommenting}
            />
            <StyledButtonContainer
                showEmailButton={profile?.length === 0 ? false : true}
            >
                <FeatureToggle
                    feature="email-author"
                    on={() => (
                        <BylineMail
                            profiles={profile}
                            url={url}
                            title={title}
                        />
                    )}
                />
                {allowCommenting && <CommentButton />}
            </StyledButtonContainer>
        </StyledByline>
    )
}
