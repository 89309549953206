import styled from '@emotion/styled'
import { WebLink, withClass } from '@news-mono/web-common'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const styledPointerClassName = 'pointer'

export const StyledPointer = withClass(styledPointerClassName)(
    styled('div')({
        display: 'block',
        maxWidth: '728px',
        margin: `0 auto ${calcRem(metrics.thewest.textMargin / 2)}`,
    }),
)

export const StyledPointerLink = styled(WebLink)({
    display: 'block',
})

export const StyledPointerPicture = styled('picture')({
    display: 'block',
    position: 'relative',
})

export const StyledPointerImg = styled('img')({
    width: '100%',
    height: 'auto',
    display: 'block',
    position: 'relative',
    top: 0,
    left: 0,
})
