export const truskinMinWidth = 1366
export const purgeTruskin = () => {
    if (
        typeof bonzai === 'object' &&
        typeof bonzai.destroy === 'function' &&
        typeof window !== 'undefined'
    ) {
        const selectors = [
            'body',
            '#root',
            '.js-ts-nav',
            '.js-ts-sticky-container',
            '.js-ts-base',
            '.js-ts-nav-container',
        ]

        const elements = document.querySelectorAll(selectors.join(','))
        for (const element of Array.from(elements)) {
            element.removeAttribute('style')
        }

        try {
            bonzai.destroy()
        } catch (err) {
            // bonzai goofed

            // This is a modified implementation of Bonzai's destroy script. Ideally this shouldn't run but it's a backup for issue DPT-759
            // when their script throws in Edge. If their script changes, we wont know, so ideally we need to keep communicating with them
            // to resolve the issue their destroy method has and then remove this block of code (but not the try/catch)
            const custom_containers = document.querySelectorAll(
                '.bz-custom-container',
            )
            const frame_Element = document.querySelector('.frameElement')

            for (let i = 0; i < custom_containers.length; i++) {
                custom_containers[i].remove()
            }

            if (frame_Element) {
                frame_Element.remove()
            }
        }
    }
}
