import loadable from '@loadable/component'
import { createRegisterableComponent } from '@news-mono/web-common'
import queryString from 'query-string'
import React from 'react'

const LazyUpgradeApp = loadable(() => import('./UpgradeApp'))

export const UpgradeAppRegistration = createRegisterableComponent(
    'upgrade-app',
    ({}, { location }) => {
        const parsed: {
            p?: string
        } = queryString.parse(location.search)
        return (
            <LazyUpgradeApp
                platform={
                    parsed.p === 'ios' || parsed.p === 'android'
                        ? parsed.p
                        : undefined
                }
            />
        )
    },
)
