import styled from '@emotion/styled'
import { CardHeadline } from '../../cards/CardText/CardHeadline'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { StyledKicker } from '../../cards/Kicker/Kicker.styled'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { FundingLabel } from '../FundingLabel/FundingLabel'
import {
    StyledLinkTitle,
    StyledSource,
    StyledTitle,
} from '../FundingLabel/FundingLabel.styled'

export const StyledRippleCard = styled(CardTextInteractions)(({ theme }) => ({
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    position: 'relative',
    textDecorationSkipInk: 'auto',
    background: themedValue(theme, {
        perthnow: colors.white,
        fallback: undefined,
    }),
    borderBottom: themedValue(theme, {
        perthnow: `1px solid ${colors.perthnow.greyMarble}`,
        fallback: undefined,
    }),

    '&:focus': {
        [`${StyledKicker}`]: {
            color: colors.white,
            background: colors.black,
        },

        [`& picture`]: {
            '&::after': {
                borderColor: colors.thewest.greyGorilla,
            },
        },
    },

    '&:hover picture': {
        '&::after': {
            borderColor: colors.white50,
        },
    },
}))

StyledRippleCard.displayName = 'RippleCard'

export const StyledPicture = styled('picture')({
    position: 'relative',
    display: 'block',

    '&::after': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        content: `''`,
        background: 'transparent',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        ...transition({ properties: ['height', 'border-color'] }),
    },
})
StyledPicture.displayName = 'Picture'

export const StyledImage = styled('img')({
    width: '100%',
    height: 'auto',
    display: 'block',
})

export const StyledText = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: calcRem(theme.cards.outerMargin),
}))
StyledText.displayName = 'TextWrapper'

export const StyledCardHeadline = styled(CardHeadline)(({ theme }) => ({
    flex: 'none',
    fontWeight: themedValue(theme, {
        thewest: 700,
        fallback: undefined,
    }),
}))

export const StyledFundingLabel = styled(FundingLabel)(({ theme }) => ({
    justifySelf: 'flex-end',
    fontWeight: themedValue(theme, {
        thewest: 400,
        perthnow: 600,
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        thewest: calcRem(12),
        fallback: calcRem(13),
    }),
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.serif,
        fallback: undefined,
    }),
    margin: themedValue(theme, {
        thewest: `${calcRem(theme.margins.sm)} 0`,
        perthnow: `${calcRem(theme.margins.sm)} 0 0 0`,
        fallback: undefined,
    }),

    [`& ${StyledTitle}, & ${StyledLinkTitle}`]: {
        color: theme.colors.text.primary,
        fontFamily: themedValue(theme, {
            thewest: theme.fonts.sansSerif,
            perthnow: theme.fonts.sansSerifCond,
            fallback: undefined,
        }),
        marginBottom: themedValue(theme, {
            thewest: theme.margins.sm,
            fallback: undefined,
        }),
    },

    [`& ${StyledSource}`]: {
        color: themedValue(theme, {
            thewest: colors.black,
            perthnow: colors.perthnow.greySlate,
            fallback: undefined,
        }),
        textTransform: 'none',
        fontWeight: themedValue(theme, {
            thewest: 700,
            fallback: undefined,
        }),
        marginBottom: themedValue(theme, {
            thewest: theme.margins.xs,
            fallback: undefined,
        }),
    },
}))
