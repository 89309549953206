import { useTheme } from '@emotion/react'
import {
    BreakpointRatios,
    FixedRatio,
    ImageSource,
} from '@news-mono/web-common'
import React from 'react'
import { colors } from '../../__styling/settings/colors'
import { themedValue } from '../../__styling/themed-value'
import { PictureWithIntrinsicRatio } from './Picture.styled'
import { tokens } from '@news-mono/design-tokens'

export interface PicturePlaceholderProps {
    fixedRatio: FixedRatio | BreakpointRatios
    loadingBackgroundColor?: string
    image: ImageSource | undefined
}

export const PicturePlaceholder = ({
    loadingBackgroundColor,
    fixedRatio,
    image,
}: PicturePlaceholderProps) => {
    const theme = useTheme()
    const backgroundColor =
        loadingBackgroundColor ||
        themedValue(theme, {
            thewest: colors.thewest.greyErmine,
            perthnow:
                loadingBackgroundColor ||
                tokens.perthnow.colors.palette.blackShade[5],
            sevennews: loadingBackgroundColor || colors.sevennews.alto,
            fallback: undefined,
        })

    return (
        <PictureWithIntrinsicRatio
            image={image}
            fixedRatio={fixedRatio}
            backgroundColor={backgroundColor}
        />
    )
}
