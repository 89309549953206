import cuid from 'cuid'
import React from 'react'

// Function takes in an initialId, and then on actual
// client side re-renders generates a new id
export function useUniqueIdOnReRender(initialId: string) {
    const hasMounted = React.useRef(false)
    React.useEffect(() => {
        hasMounted.current = true
    }, [])
    return hasMounted.current ? cuid.slug() : initialId
}
