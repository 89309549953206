import { layout } from '../../App.routing'

export const courtsCrimeSegment = layout.component({
    type: 'uniform',
    props: {
        section: 'news',
        verticalSpacing: 'xl',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            ['teaserWithBackground'],
            {
                type: 'november',
                listLength: 5,
                hasTimestamp: true,
            },
        ],

        sectionHeader: {
            heading: 'Courts and Crime',
            headingUrl: '/news/crime',
        },
        dataDefinitionArgs: {
            type: 'curation',
            name: 'courts-crime',
            offset: 0,
            pageSize: 8,
        },
    },
})
