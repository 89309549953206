import { keyframes } from '@emotion/css'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

const progress = keyframes({
    '0%': {
        backgroundPosition: `-${calcRem(400)} 0`,
    },
    '100%': {
        backgroundPosition: `calc(${calcRem(400)} + 100%) 0`,
    },
})

const color = (theme: Theme) =>
    themedValue(theme, {
        thewest: colors.thewest.greyErmine,
        perthnow: tokens.perthnow.colors.palette.blackShade[5],
        sevennews: colors.sevennews.alto,
        thenightly: tokens.thenightly.colors.palette.neutral[10],
        fallback: undefined,
    })
const highlight = (theme: Theme) =>
    themedValue(theme, {
        thewest: colors.thewest.greyMouse,
        perthnow: colors.perthnow.greyPyrite,
        sevennews: colors.sevennews.seashell,
        thenightly: tokens.thenightly.colors.palette.neutral[20],
        fallback: undefined,
    })

const darkColor = (theme: Theme) =>
    themedValue(theme, {
        fallback: colors.perthnow.greyBasalt90,
        sevennews: colors.sevennews.charade,
        thenightly: tokens.thenightly.colors.palette.neutral[20],
    })
const darkHighlight = (theme: Theme) =>
    themedValue(theme, {
        fallback: colors.perthnow.greyFlint,
        sevennews: colors.sevennews.tundora,
        thenightly: tokens.thenightly.colors.palette.neutral[10],
    })

let skeletonGradientDisabled = false

export const disableSkeletonGradient = () => {
    skeletonGradientDisabled = true
}

export interface StyledSkeletonProps {
    dark?: boolean
    round?: boolean
    customHeight?: number
    customWidth?: number
}

export const StyledSkeleton = withClass('skeleton')(
    styled('span')<StyledSkeletonProps>(
        {
            animation: `${progress} 1.8s ease-in-out infinite`,
            backgroundSize: '400px 100%',
            backgroundRepeat: 'no-repeat',
            borderRadius: '2px',
            display: 'inline-block',
            lineHeight: 0.9,
            margin: `${calcRem(3)} 0`,
            width: '100%',
        },
        (props) => {
            const skeletonColour = props.dark
                ? darkColor(props.theme)
                : color(props.theme)
            const skeletonHighlight = props.dark
                ? darkHighlight(props.theme)
                : highlight(props.theme)

            return {
                height: props.customHeight
                    ? `${calcRem(props.customHeight)}`
                    : 'auto',
                width: props.customWidth
                    ? `${calcRem(props.customWidth)}`
                    : '100%',
                borderRadius: props.round ? '100%' : 0,
                backgroundColor: skeletonColour,
                backgroundImage: skeletonGradientDisabled
                    ? `none`
                    : `linear-gradient(90deg, ${skeletonColour}, ${skeletonHighlight}, ${skeletonColour})`,
            }
        },
    ),
)
