import React from 'react'
import { SomethingWentWrong } from '../SomethingWentWrong'
import { PageError } from '@news-mono/web-common'

type TheWestSomethingWentWrongProps = {
    error: PageError
}

export const TheWestSomethingWentWrong = ({
    error,
}: TheWestSomethingWentWrongProps) => (
    <SomethingWentWrong error={error} attribution />
)
