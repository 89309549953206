import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts, themedValue } from '../../../__styling'

const backgroundColor = '#EAEAEA'
const foregroundColor = '#333333'
const donutWidth = '50%'
const borderWidth = 0
const maskColor = '#f0ff'

export const StyledPieChart = styled('div')(() => ({
    position: 'relative',
    paddingBottom: '50%',
    width: '50%',
    borderRadius: '50%',
    background: maskColor,
    display: 'block',
}))

export const StyledPieChartContainer = styled('div')(({ theme }) => ({
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        fallback: fonts.thewest.sansSerif,
    }),
    marginTop: calcRem(20),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const StyledPieChartPie = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: borderWidth,
    right: borderWidth,
    bottom: borderWidth,
    left: borderWidth,
    borderRadius: '50%',
    backgroundColor: backgroundColor,
    backgroundImage: `linear-gradient(to right, transparent 50%, ${foregroundColor} 0)`,
    textAlign: 'center',

    '&::before': {
        position: 'absolute',
        top: '0',
        left: '50%',
        width: '50%',
        height: '100%',
        borderRadius: '0 100% 100% 0 / 50%',
        backgroundColor: 'inherit',
        content: '""',
        transformOrigin: 'left',
        animation: `${spin} 50s linear infinite, ${bg} 100s step-end infinite`,
        animationPlayState: 'paused',
        animationDelay: 'inherit',
    },

    '&::after': {
        position: 'absolute',
        top: donutWidth,
        right: donutWidth,
        bottom: donutWidth,
        left: donutWidth,
        borderRadius: '100%',
        background: maskColor,
        content: '""',
    },

    // fix for rendering bug when the donut is set at 0
    "&[data-percent='0']::before": {
        boxShadow: `-2px 0 0 0 ${backgroundColor}`,
    },

    // Set donut to be "filled"
    "&[data-percent='100']": {
        backgroundColor: foregroundColor,

        '&::before': {
            backgroundColor: foregroundColor,
        },
    },
}))

export const StyledPieChartText = styled('div')<{
    noMarginOnVoteCountedText: boolean
}>(({ noMarginOnVoteCountedText }) => ({
    zIndex: 2,
    margin: '0',

    lineHeight: '1',

    display: 'block',
    position: 'relative',

    marginLeft: noMarginOnVoteCountedText ? `-${calcRem(12)}` : calcRem(12),

    [breakpoint('sm')]: {
        marginLeft: calcRem(12),
    },
}))

export const StyledPieChartPercentage = styled('span')(() => ({
    backgroundImage: 'linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)',
    backgroundSize: '100%',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontSize: calcRem(50),
    fontWeight: 700,
}))

export const StyledPieChartVotesCounted = styled('span')(() => ({
    fontSize: calcRem(13),
    fontWeight: 700,
    display: 'block',
    textTransform: 'uppercase',
    color: '#2B2D2E',
}))

const spin = keyframes`
  to {
    transform: rotate(.5turn)
  }
`
const bg = keyframes`
  50% {
    background-color: ${foregroundColor}
  }
`
