import { layout } from '../../../App.routing'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getWorldRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),

        // News World Asia
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Asia',
                    headingUrl: '/news/asia',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/asia'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World North America
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'North America',
                    headingUrl: '/news/north-america',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/north-america'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World Oceania
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Oceania',
                    headingUrl: '/news/oceania',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/oceania'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World Latin America
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Latin America',
                    headingUrl: '/news/latin-america',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/latin-america'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World Europe
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Europe',
                    headingUrl: '/news/europe',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/europe'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World Middle East
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Middle East',
                    headingUrl: '/news/middle-east',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/middle-east'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),

        // News World Africa
        layout.component({
            type: 'lima',
            props: {
                feature: 'world-collection',
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardLayout: [
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                    ['teaserWithBackground'],
                ],
                cardOrientation: { type: 'Landscape-Portrait' },
                sectionHeader: {
                    heading: 'Africa',
                    headingUrl: '/news/africa',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['news/africa'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
