import { Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from '../buttons/Button/Button'
import { metrics } from '../__styling/settings'
import { fonts } from '../__styling/settings/fonts'
import { calcRem } from '../__styling/style-functions/calc-rem'
import { breakWords, fluidTypography } from '../__styling/style-mixins'
import { themedValue } from '../__styling/themed-value'
import { breakpoint } from '../__styling/style-functions'

const themedFluidTypography = (theme: Theme) =>
    themedValue(theme, {
        sevennews: fluidTypography(0, 1000, 36, 40),
        perthnow: fluidTypography(0, 1020, 36, 40),
        thewest: fluidTypography(0, 1220, 36, 40),
        thenightly: fluidTypography(0, 1220, 36, 40),
        // Todo: update this
        fallback: fluidTypography(0, 1000, 36, 40),
    })

export const GiveConsentButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    paddingLeft:
        theme.kind != 'thenightly' ? calcRem(theme.margins.md) : undefined,
    paddingRight:
        theme.kind != 'thenightly' ? calcRem(theme.margins.md) : undefined,
}))

export const StyledBodyContainer = styled('div')(({ theme }) => ({
    maxWidth: metrics.thenightly.siteMetrics.mainContentWidth,
    margin: 'auto',

    'form p:first-of-type': {
        marginBottom: themedValue(theme, {
            thenightly: calcRem(metrics.thenightly.margins.m2l),
            fallback: undefined,
        }),

        [breakpoint('xs')]: {
            marginBottom: themedValue(theme, {
                thenightly: calcRem(metrics.thenightly.margins.lg),
                fallback: undefined,
            }),
        },
    },
}))

export const StyledHeading = styled('h3')(({ theme }) => ({
    flexBasis: calcRem(230),
    flexGrow: 1,
    flexShrink: 0,
    margin: 0,
    fontFamily: theme.fonts.heading,
    fontSize: calcRem(25),
    fontWeight: 600,
    lineHeight: 1.275,
    padding: calcRem(theme.margins.sm),
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: calcRem(metrics.thenightly.margins.m2l),
    maxWidth: calcRem(340),

    [breakpoint('xs')]: {
        paddingBottom: calcRem(theme.margins.lg),
    },
}))

export const StyledParagraph = styled('p')(({ theme }) => ({
    ...breakWords,

    marginBottom: themedValue(theme, {
        thenightly: metrics.thenightly.margins.m2l,
        fallback: undefined,
    }),

    fontSize: themedValue(theme, {
        thenightly: calcRem(16),
        fallback: calcRem(14),
    }),
    fontFamily: themedValue(theme, {
        thewest: fonts.thewest.serif,
        perthnow: fonts.perthnow.sansSerif,
        sevennews: fonts.sevennews.sansSerif,
        fallback: undefined,
    }),

    lineHeight: themedValue(theme, {
        thewest: 1.4,
        thenightly: calcRem(24),
        fallback: 1.5,
    }),

    maxWidth: themedValue(theme, {
        thenightly: metrics.thenightly.siteMetrics.condensedBody,
        fallback: undefined,
    }),

    '&:first-of-type': {
        marginTop: 0,
    },

    [breakpoint('xs')]: {
        marginBottom: themedValue(theme, {
            thenightly: metrics.thenightly.margins.lg,
            fallback: undefined,
        }),
    },
}))

export const StyledFieldset = styled('fieldset')(
    ({ theme }) => ({
        border: 0,
        padding: 0,
        margin: `0 0 ${calcRem(theme.margins.xxl)} 0`,
    }),
    ({ theme }) =>
        theme.kind === 'thenightly' && {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: calcRem(metrics.thenightly.margins.m2l),

            fontSize: calcRem(16),

            [breakpoint('xs')]: {
                display: 'block',
                marginBottom: calcRem(theme.margins.lg),
            },
        },
)

export const StyledLegend = styled('legend')(({ theme }) => ({
    display: 'block',
    marginBottom: themedValue(theme, {
        thenightly: calcRem(metrics.thenightly.margins.m2l),
        fallback: calcRem(theme.margins.md),
    }),
    fontWeight: 600,
    [breakpoint('xs')]: {
        display: 'block',
        marginBottom: themedValue(theme, {
            thenightly: calcRem(metrics.thenightly.margins.lg),
            fallback: calcRem(theme.margins.md),
        }),
    },
}))

export const StyledLabel = styled('label')(
    {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',

        '& + &': {
            marginLeft: calcRem(21),
        },

        '& > input': {
            marginRight: calcRem(6),
        },
    },
    ({ theme }) =>
        theme.kind === 'thenightly' && {
            '& + &': {
                marginLeft: 0,
                marginTop: calcRem(24),

                [breakpoint('xs')]: {
                    marginLeft: calcRem(21),
                    marginTop: 0,
                },
            },
        },
)

export const CheckMark = styled('div')(({ theme }) => ({
    backgroundColor: 'currentColor',
    width: 12,
    height: 3,
    marginLeft: themedValue(theme, {
        thewest: 14,
        perthnow: 12,
        sevennews: 14,
        thenightly: 12,
        fallback: undefined,
    }),
    transform: `rotate(-53deg) rotateY(180deg)`,

    '&::before': {
        content: '""',
        display: 'block',
        backgroundColor: 'inherit',
        width: 7,
        height: 3,
        transform: `rotate(90deg) translateY(-2px)`,
        transformOrigin: 'bottom right',
    },
}))
