import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import { CardHeadline } from '../../../cards/CardText/CardHeadline'
import { StyledHeadlineText } from '../../../cards/CardText/CardText.styled'
import { cardInteractionFocusStylesPerthNow } from '../../../cards/CardText/CardTextInteractions.styled'
import { colors } from '../../../__styling/settings/colors'
import { metrics } from '../../../__styling/settings/metrics'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

const styledHeaderClassName = 'Card-Header'

const cardSpacing = metrics.perthnow.cards.spacing
const textOffset = metrics.perthnow.cards.textOffset

export type StyledHeaderProps = { isLoading: boolean }

export const StyledHeader = withClass(styledHeaderClassName)(
    styled(WebLink, {
        shouldForwardProp: createPropFilter<WebLinkProps>()(['isLoading']),
    })<StyledHeaderProps>(
        {
            color: colors.thewest.greyGorilla,
            fontSize: calcRem(16),
            lineHeight: 1.375,
            display: 'block',
            width: '100%',
            textDecoration: 'none',
            ...cardInteractionFocusStylesPerthNow,
        },
        (props: StyledHeaderProps) =>
            props.isLoading && {
                cursor: 'default',
            },
    ),
)

export const StyledHeadline = styled(CardHeadline)(
    {
        display: 'block',
        margin: `0 0 ${calcRem(cardSpacing - textOffset)} 0`, // 12 - 5
        flex: '0 0 100%',
        alignSelf: 'flex-start',

        [`& ${StyledHeadlineText}`]: {
            display: 'block',
            transform: `translateY(-${calcRem(textOffset)})`, // offset for font alignment
        },
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.heading,
    }),
)
