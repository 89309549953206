import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { CardByline } from '../../../cards/CardByline/CardByline'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { CardText } from '../../../cards/CardText/CardText'
import {
    styledHeadlineClassName,
    StyledHeadlineText,
    StyledKickerEditorialLabel,
} from '../../../cards/CardText/CardText.styled'
import {
    StyledCommentsIcon,
    StyledKicker,
    StyledPremiumIcon,
} from '../../../cards/Kicker/Kicker.styled'
import {
    StyledEditorialLabelText,
    StyledTheWestMark,
} from '../../../typography/EditorialLabel/EditorialLabel.styled'
import { breakpoint, calcRem, fonts } from '../../../__styling'

export const StyledCardContainer = styled('div')({})

export const StyledCard = styled(WebLink)(({ theme }) => ({
    backgroundColor: theme.colors.background.primary,
    margin: 'auto',
    display: 'block',
    textDecoration: 'none',
}))

interface StyledInlineContainerProps {
    hasBorderBottom: boolean
}

export const StyledInlineContainer = styled('div')<StyledInlineContainerProps>(
    ({ hasBorderBottom, theme }) => ({
        display: 'flex',
        borderBottom: hasBorderBottom
            ? `2px solid ${theme.colors.borders.tertiary}`
            : 'none',
        borderTop: `2px solid ${theme.colors.borders.tertiary}`,
        margin: 0,
        position: 'relative',
        [`&:hover ${StyledHeadlineText}`]: {
            textDecoration: 'underline',
        },
    }),
)

export const StyledImageContainer = styled('div')({})

export const StyledCardMedia = styled(CardMedia)({
    minWidth: calcRem(118),
    height: '100%',
    marginBottom: 0,

    [breakpoint('xs')]: {
        minWidth: calcRem(250),
    },

    [`& .Card-Media-Content`]: {
        height: 'inherit',
    },

    [`& Picture`]: {
        height: '100%',
        width: '100%',
    },

    ['& img']: {
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },
})

export const StyledTextContainer = styled('div')(({ theme }) => ({
    margin: calcRem(theme.margins.sm, 13, theme.margins.sm, theme.margins.md),
    '&::before': {
        position: 'absolute',
        top: 0,
        content: `''`,
        width: calcRem(50),
        height: calcRem(3),
        backgroundColor: theme.colors.brand,
        display: 'block',
    },
    [breakpoint('xs')]: {
        margin: calcRem(24, 64, theme.margins.md, 24),
    },
}))

export const StyledCardText = styled(CardText)(({ theme }) => ({
    marginBottom: calcRem(theme.margins.xs),

    [breakpoint('xs')]: {
        marginBottom: calcRem(theme.margins.md),
    },

    [`& .${styledHeadlineClassName}`]: {
        margin: 0,
    },

    [`& ${StyledKicker}`]: {
        fontWeight: 300,
        marginBottom: calcRem(theme.margins.xs),
        color: theme.colors.text.secondary,
        fontSize: calcRem(14),
        letterSpacing: calcRem(1.31),
        lineHeight: calcRem(16),

        [breakpoint('xs')]: {
            marginBottom: calcRem(theme.margins.md),
        },
    },

    [`& ${StyledHeadlineText}`]: {
        fontSize: calcRem(16),
        fontFamily: fonts.thewest.heading,
        lineHeight: calcRem(20),
        minHeight: calcRem(40),
        display: 'block',
        margin: 0,

        [breakpoint('xs')]: {
            fontSize: calcRem(20),
            lineHeight: calcRem(24),
            minHeight: calcRem(48),
        },
    },

    //The following is all styling to handle items that may occur inside of the Kicker (Premium Icon, Comments Icon, etc)
    //This doesn't occur for PN.

    [`& ${StyledPremiumIcon}`]: {
        height: calcRem(20),
        width: calcRem(20),
        position: 'absolute',
        top: 7,
        right: 0,

        [breakpoint('xs')]: {
            top: 24,
        },
    },

    [`& ${StyledCommentsIcon}`]: {
        height: calcRem(15),
        width: calcRem(15),
    },

    [`& ${StyledKickerEditorialLabel}`]: {
        height: calcRem(16),

        [`& ${StyledTheWestMark}`]: {
            width: calcRem(16),
            height: calcRem(14),
        },

        [`& ${StyledEditorialLabelText}`]: {
            fontSize: calcRem(14),
        },
    },
}))

export const StyledByline = styled(CardByline)(({ theme }) => ({
    fontFamily: fonts.thewest.serif,
    fontWeight: 400,
    color: theme.colors.text.secondary,
    margin: 0,
    pointerEvents: 'none',

    ['& .CardByline-Text']: {
        fontSize: calcRem(14),
        lineHeight: calcRem(16),
        [breakpoint('sm')]: {
            lineHeight: calcRem(24),
            fontSize: calcRem(16),
        },
    },
}))
