import { useTheme } from '@emotion/react'
import { AppState, getSectionMetaInfo } from '@news-mono/web-common'
import { useSelector } from 'react-redux'
import { Section } from '../__styling'

export function useSectionMetaInfo() {
    const theme = useTheme()
    const sectionMeta = useSelector((state: AppState) => state.meta.sectionMeta)
    return getSectionMetaInfo(sectionMeta, theme.section)
}

export function useSection<T = Section>(): T {
    const { section } = useTheme()
    return section as unknown as T
}
