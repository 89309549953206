export const metricsPN = {
    spacing: {
        none: 0,
        '5XS': 2,
        '4XS': 4,
        '3XS': 6,
        '2XS': 8,
        XS: 12,
        S: 16,
        M: 20,
        L: 24,
        XL: 32,
        '2XL': 40,
        '3XL': 48,
        '4XL': 80,
        '5XL': 112,
    },
    radius: {
        none: 0,
        S: 4,
        M: 6,
        L: 8,
        XL: 99,
    },
    layout: {
        section: {
            desktop: 56,
            tablet: 64,
            mobile: 24,
        },
        site: {
            mainContentWidth: 1192 as const,
        },
    },
} as const

export type PNMetricsType = typeof metricsPN
