import { Intention, LinkClickedEvent } from '@news-mono/web-common'
import React from 'react'
import { createIntents } from '../../typography/TextItem/helpers/create_intents'
import { TextLink } from '../../typography/TextLink/TextLink'

export interface TextIntention {
    kind: 'text'
    value: string
    nestedIntents: HierarchicalIntent[]
}
export interface LineBreakIntention {
    kind: 'line-break'
    // Should always be empty for line break intentions, but having it makes it easier to deal with the types
    nestedIntents: HierarchicalIntent[]
}
export interface LinkIntention {
    kind: 'link'
    href: string
    nestedIntents: HierarchicalIntent[]
}
export interface EmphasizedIntention {
    kind: 'emphasized'
    nestedIntents: HierarchicalIntent[]
}
export interface ImportantIntention {
    kind: 'important'
    nestedIntents: HierarchicalIntent[]
}

export type HierarchicalIntent =
    | LinkIntention
    | EmphasizedIntention
    | ImportantIntention
    | TextIntention
    | LineBreakIntention

export type ValidTextElements =
    | 'p'
    | 'span'
    | 'li'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'

export type OnEventHandler = (event: LinkClickedEvent) => void
export interface TextItemProps {
    text: string
    intentions?: Intention[]
    tag?: ValidTextElements
    className?: string
    openLinksInNewWindow?: boolean
    idAttribute?: string
    onEvent: OnEventHandler
    isNoFollow?: boolean
}

function renderIntents(
    intent: HierarchicalIntent,
    idx: number,
    onEvent?: OnEventHandler,
    openLinksInNewWindow?: boolean,
    isNoFollow?: boolean,
) {
    const { nestedIntents } = intent

    const renderIntentContent: () =>
        | string
        | React.ReactElement<any>
        | Array<React.ReactElement<any> | string> = () => {
        if (nestedIntents && nestedIntents.length > 0) {
            const renderedNestedIntents = nestedIntents.map((nestedIntent, i) =>
                renderIntents(nestedIntent, i, onEvent, openLinksInNewWindow),
            )
            return renderedNestedIntents.length === 1
                ? renderedNestedIntents[0]
                : renderedNestedIntents
        }
        return ''
    }

    switch (intent.kind) {
        case 'emphasized':
            return <em key={idx}>{renderIntentContent()}</em>
        case 'important':
            return <strong key={idx}>{renderIntentContent()}</strong>
        case 'link': {
            const { href } = intent

            return (
                <TextLink
                    key={idx}
                    href={href}
                    dataLinkType="article-inline"
                    openInNewWindow={openLinksInNewWindow}
                    isNoFollow={isNoFollow}
                    onEvent={onEvent}
                >
                    {renderIntentContent()}
                </TextLink>
            )
        }
        case 'line-break':
            return <br key={idx} />
        case 'text':
        default:
            return intent.value
    }
}

export const TextItem: React.FC<TextItemProps> = ({
    text,
    intentions,
    onEvent,
    openLinksInNewWindow,
    isNoFollow,
    tag,
    idAttribute,
    className,
}) => {
    const intents = React.useMemo(() => {
        const intents = createIntents(intentions || [], text)

        return intents.map((intent, i) =>
            renderIntents(intent, i, onEvent, openLinksInNewWindow, isNoFollow),
        )
    }, [intentions, text, onEvent, openLinksInNewWindow, isNoFollow])

    // Added to hack around React needing components that return more than one React component
    // to be wrapped in a container - TODO remove when React 16 is in use.
    const CustomTag = tag || 'p'

    return (
        <CustomTag className={className} id={idAttribute}>
            {/* By returning a single element rather than an array, we don't output a react-text comment */}
            {intents.length === 1 ? intents[0] : intents}
        </CustomTag>
    )
}

TextItem.displayName = 'TextItem'
