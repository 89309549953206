import React from 'react'
import {
    StyledIconTwitter,
    SyledProfileTwitterButton,
} from './ProfileTwitterButton.styled'

export interface ProfileTwitterButtonProps {
    className?: string
    twitter: string
}

export const ProfileTwitterButton: React.FC<ProfileTwitterButtonProps> = ({
    className,
    twitter,
}) => {
    if (!twitter) {
        return null
    }

    return (
        <SyledProfileTwitterButton
            className={className}
            to={`https://twitter.com/${twitter}`}
        >
            <StyledIconTwitter title={'Twitter'} /> @{twitter}
        </SyledProfileTwitterButton>
    )
}

ProfileTwitterButton.displayName = 'ProfileTwitterButton'
