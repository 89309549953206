import {
    regionalSiteConfig,
    TheWestRegionalSections,
    TheWestSection,
} from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType } from '@news-mono/web-common'
import { isWestRegionalSection } from '@west-australian-newspapers/publication-types'
import { layout } from '../../App.routing'

interface OurPapersRouteProps {
    section: TheWestSection
    getAdTargeting: GetRouteAdTargeting
}

export const getOurPapersRoute = ({
    section,
    getAdTargeting,
}: OurPapersRouteProps): PageType<TheWestSection> => ({
    kind: 'page',
    heading: 'Regional Publications',
    hideHeading: true,
    pageType: 'static',
    adTargeting: getAdTargeting('home', section, 'our-papers'),
    section,
    compositions: [
        layout.composition({
            type: 'box',
            props: {
                containerWidth: metrics.thewest.siteMetrics.mainContentWidth,
                horizontalGutters: 'outerMargin',
                verticalGutters: ['outerMargin', 'unset'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'breadcrumb',
                        props: {
                            title: 'Regional Publications',
                            enableHeaderTag: true,
                        },
                    }),
                ],
            },
        }),
        layout.composition({
            type: 'zeus',
            props: {
                containerWidth: metrics.thewest.siteMetrics.mainContentWidth,
                horizontalGutters: true,
                hasBackgroundFill: true,
                stretchSelf: true,
            },
            contentAreas: {
                main: [
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'md',
                            verticalGutters: ['md', 'md'],
                            breakpoints: {
                                sm: {
                                    horizontalGutters: 'lg',
                                },
                                lg: {
                                    horizontalGutters: 'xl',
                                },
                            },
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'our-publications',
                                    props: {},
                                }),
                            ],
                        },
                    }),
                ],
                sidebar: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'below-center',
                            padding: [0, 0, metrics.thewest.margins.md, 0],
                            slot: {
                                id: 'sidebar-top',
                                size: 'desktopMrecHalfPage',
                                pageType: 'article',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'juliet',
                        props: {
                            cardType: {
                                type: 'landscape',
                                format: 'landscape-smallImage',
                            },
                            removeHorizontalGutters: true,
                            kickerMode: 'hidden',
                            contentIsTangential: true,
                            sectionHeader: {
                                heading: 'Local News',
                            },
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: [
                                    isWestRegionalSection(section)
                                        ? regionalSiteConfig[
                                              section as TheWestRegionalSections
                                          ].mastheadTopic
                                        : 'news/wa',
                                ],
                                paging: {
                                    page: 1,
                                    pageSize: 3,
                                },
                            },
                        },
                    }),
                ],
            },
        }),
    ],
})
