import { Profile as ProfileData } from '@news-mono/web-common'
import React from 'react'
import { BioBlockV2 } from '../../SevenNewsV2'
import { StyledProfileWrap } from './Profile.styled'

export interface SevenNewsProfileProps {
    profile: ProfileData
    profileUrl: string
}

export const SevenNewsProfile: React.FC<SevenNewsProfileProps> = ({
    profile,
}: SevenNewsProfileProps) => {
    return (
        <StyledProfileWrap>
            <BioBlockV2 profile={profile} />
        </StyledProfileWrap>
    )
}
