import { PublicationCardItem } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { LandscapeProps } from '../../cards/Landscape/Landscape'
import { CommonCardProps } from '../CardItem.Props'

type CommonLayoutProps = Omit<LandscapeProps, 'cardNumber' | 'item' | 'onEvent'>

export const landscapeLayouts = [
    'landscape-common',
    'landscape-teaser',
    'landscape-smallImage',
    'landscape-noBackground',
    'landscape-withPadding',
    'landscape-full-timestamp',
    'landscape-smTeaser-mdNoTeaser',
    'landscape-xsTeaser-smNoTeaser',
    'landscape-xsNoTeaser-mdTeaser-5050',
    'landscape-xsNoTeaser-mdTeaser-5050-simple',
    'landscape-inline-content-perthnow',
    'landscape-noTeaserNoKicker',
] as const

export interface LandscapeLayout {
    type: 'landscape'
    format: CommonLayoutProps | typeof landscapeLayouts[number]
}

export const commonProps: CommonLayoutProps = {
    fixedRatio: '4:3',
    teaserMode: 'hidden',
    hideByline: true,
}

export const commonLandscapeProps: CommonLayoutProps = {
    ...commonProps,
    hasPadding: false,
    hasBackground: true,
}

export const noBackgroundLandscapeProps: CommonLayoutProps = {
    ...commonProps,
    hasPadding: false,
    hasBackground: false,
}

export const paddingLandscapeProps: CommonLayoutProps = {
    ...commonProps,
    hasPadding: true,
    hasBackground: true,
}

export const xsTeaserMdNoTeaserProps: CommonLayoutProps = {
    ...commonProps,
    hasPadding: true,
    hasBackground: true,
    kickerMode: {
        kickerVisibility: { initial: 'visible', xs: 'visible', md: 'hidden' },
    },
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'visible',
        md: 'hidden',
    },
}

export const xsTeasersmNoTeaserProps: CommonLayoutProps = {
    ...commonProps,
    hasPadding: true,
    hasBackground: true,
    kickerMode: {
        kickerVisibility: { initial: 'visible', xs: 'visible', sm: 'hidden' },
    },
    teaserMode: {
        initial: 'visible',
        xs: 'visible',
        sm: 'hidden',
        md: 'hidden',
    },
}

export const xsNoTeaser5050LandscapeProps: CommonLayoutProps = {
    hasBackground: true,
    fixedRatio: '16:9',
    fontScale: 1.2,
    teaserMode: {
        initial: 'hidden',
        xs: 'hidden',
        sm: 'hidden',
        md: 'visible',
    },
    imageSize: 'medium',
    hideBorder: true,
    hideByline: true,
    teaserFontScale: 0.88,
}

export const xsNoTeaser5050SimpleLandscapeProps: CommonLayoutProps = {
    hasBackground: true,
    fixedRatio: '16:9',
    fontScale: 1,
    teaserMode: {
        initial: 'hidden',
        xs: 'hidden',
        sm: 'hidden',
        md: 'visible',
    },
    imageSize: 'large',
    hideBorder: true,
    hideByline: true,
}

export const inlineContentPerthNowLandscapeProps: CommonLayoutProps = {
    hasBackground: true,
    fixedRatio: '16:9',
    fontScale: 1,
    teaserMode: {
        initial: 'hidden',
        xs: 'visible',
        sm: 'visible',
        md: 'visible',
    },
    imageSize: 'large',
    hideByline: true,
    kickerMode: 'hidden',
}

export const smallImageLandscapeProps: CommonLayoutProps = {
    ...commonProps,
    imageSize: 'small', //TODO Make this part of media mode
    hasPadding: true,
    hasBackground: true,
}

export const teaserLandscapeProps: CommonLayoutProps = {
    hasPadding: true,
    hasBackground: true,
    fixedRatio: ['4:3', '16:9'],
    teaserMode: 'visible',
    hideByline: true,
}

export const fullTimestampLandscapeProps: CommonLayoutProps = {
    hasPadding: true,
    hasBackground: true,
    fixedRatio: '4:3',
    teaserMode: 'visible',
    hideByline: false,
    kickerMode: 'hidden',
    timestamp: 'full-at-bottom',
}

export const noTeaserNoKickerProps: CommonLayoutProps = {
    teaserMode: 'hidden',
    kickerMode: 'hidden',
}

const layoutProps: { [formatName: string]: CommonLayoutProps } = {
    'landscape-common': commonLandscapeProps,
    'landscape-teaser': teaserLandscapeProps,
    'landscape-smallImage': smallImageLandscapeProps,
    'landscape-noBackground': noBackgroundLandscapeProps,
    'landscape-withPadding': paddingLandscapeProps,
    'landscape-full-timestamp': fullTimestampLandscapeProps,
    'landscape-smTeaser-mdNoTeaser': xsTeaserMdNoTeaserProps,
    'landscape-xsTeaser-smNoTeaser': xsTeasersmNoTeaserProps,
    'landscape-xsNoTeaser-mdTeaser-5050': xsNoTeaser5050LandscapeProps,
    'landscape-xsNoTeaser-mdTeaser-5050-simple':
        xsNoTeaser5050SimpleLandscapeProps,
    'landscape-inline-content-perthnow': inlineContentPerthNowLandscapeProps,
    'landscape-noTeaserNoKicker': noTeaserNoKickerProps,
}

function getPropsForLayout(layout: LandscapeLayout): CommonLayoutProps {
    if (typeof layout.format === 'object') {
        return layout.format
    }

    return layoutProps[layout.format]
}

export const getLandscapeCardItemProps = (
    item: MaybeLoaded<PublicationCardItem>,
    layout: LandscapeLayout,
    props: CommonCardProps,
    cardNumber: number,
) => {
    const commonProps: CommonLayoutProps = getPropsForLayout(layout)

    const loadedProps: LandscapeProps = {
        ...commonProps,
        ...props,
        item,
        cardNumber,
    }

    return loadedProps
}
