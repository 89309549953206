import {
    AnyAction,
    applyMiddleware,
    compose,
    createStore,
    Dispatch,
    Middleware as ReduxMiddleware,
    Store as ReduxStore,
} from 'redux'
import { createStateSyncMiddleware } from 'redux-state-sync'
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { BaseClientConfig } from './client/BaseClientConfig'
import { createRootReducer } from './client/reducers'
import { MiscellaneousState, SwGState } from './data'
import {
    AuthenticationActions,
    AuthenticationState,
} from './data/authentication/reducer'
import { ConsentState } from './data/consent/consent.redux'
import { LocationState } from './data/location-detection/reducer'
import { NavigationState } from './data/navigation/navigation-open'
import { RenderState } from './data/render/render'
import { TheWestLiveState } from './data/west-live/reducer'
import { TogglesReduxState } from './feature-togglings/reducer'
import { MetaState } from './meta/meta.redux'

export interface AppState {
    toggles: TogglesReduxState
    geoLocation: LocationState
    navigation: NavigationState
    authentication: AuthenticationState
    meta: MetaState
    consent: ConsentState
    theWestLive: TheWestLiveState
    render: RenderState
    SwG: SwGState
    misc: MiscellaneousState
}

export type Middleware = ReduxMiddleware<{}, AppState, Dispatch<AnyAction>>

export interface ExtraThunkArgument {
    config: BaseClientConfig
}

export type Store = ReduxStore<AppState, AnyAction> & {
    dispatch: ThunkDispatch<AppState, ExtraThunkArgument, AnyAction>
}

export type SWMThunkAction<T extends AnyAction> = ThunkAction<
    void,
    AppState,
    ExtraThunkArgument,
    T
>
export type SWMAsyncThunkAction<T extends AnyAction> = ThunkAction<
    Promise<any>,
    AppState,
    ExtraThunkArgument,
    T
>

const useReduxDevToolsExtension = () =>
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    typeof (window as any).__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'

// Not a hook just a name clash
// eslint-disable-next-line react-hooks/rules-of-hooks
const devTools = useReduxDevToolsExtension()
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f

export function configureStore(
    config: BaseClientConfig,
    options: {
        initialState?: AppState
        syncState?: boolean
        middlewares?: Middleware[]
    } = {},
): Store {
    const { initialState, syncState, middlewares } = options

    const stateSyncOptions = {
        whitelist: [
            AuthenticationActions.LOGGED_IN,
            AuthenticationActions.LOGGED_OUT,
        ],
    }

    let stateSyncMiddleware

    try {
        if (syncState) {
            stateSyncMiddleware = [createStateSyncMiddleware(stateSyncOptions)]
        }
    } catch {
        stateSyncMiddleware = undefined
    }

    const store: Store = createStore(
        createRootReducer(),
        initialState as any,
        compose(
            applyMiddleware(
                ...(middlewares || []),
                ...(stateSyncMiddleware || []),
                thunk.withExtraArgument({ config }) as ThunkMiddleware<
                    AppState,
                    AnyAction,
                    ExtraThunkArgument
                >,
            ),
            devTools,
        ),
    )

    return store
}
