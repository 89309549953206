import { useTheme } from '@emotion/react'
import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    PublicationCardItem,
    toLinkWithHint,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledCardText,
    StyledCardTextContainer,
    StyledCTA,
    StyledLink,
    StyledPromotionalCard,
} from '../../cards/PromotionalCard/PromotionalCard.styled'
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { themedValue } from '../../__styling/themed-value'
import { CardMedia, MediaMode } from '../CardMedia/CardMedia'

export type AvailableLayouts = 'landscape' | 'portrait'

export interface PromotionalCardProps extends CommonCardProps {
    verticalSpacing?: keyof ThemeMargins
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    fontScale?: FontScales
    disableImageLazyLoad?: boolean
    hasPadding?: boolean
    showCTA?: boolean
    mediaMode?: MediaMode
    mobileOnly?: true
    cardNumber: number
    innerRef?: React.RefObject<any>
    item: MaybeLoaded<PublicationCardItem>
    promotionType?: string
}

export const PromotionalCard: React.FC<PromotionalCardProps> = ({
    className,
    item,
    verticalSpacing,
    fixedRatio,
    fontScale,
    hasPadding,
    disableImageLazyLoad,
    onEvent,
    mediaMode,
    cardNumber,
    showCTA,
    innerRef,
    mobileOnly,
    promotionType = 'default',
}) => {
    const theme = useTheme()
    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'PromotionalCard',
                'PromotionalCard',
                cardNumber,
            ),
        )
    }

    const setFontScale = themedValue(theme, {
        thewest: fontScale || 0.9,
        perthnow: fontScale,
        sevennews: fontScale || 1.2,
        fallback: undefined,
    }) as FontScales

    return (
        <StyledPromotionalCard
            ref={innerRef}
            className={className}
            data-topic={item.loaded ? item.result.primaryTopic.id : undefined}
            verticalSpacing={verticalSpacing}
            isLoading={!item.loaded}
            mobileOnly={mobileOnly}
            cardTheme={promotionType}
        >
            <StyledLink
                to={toLinkWithHint(item)}
                onClick={linkClicked}
                isLoading={!item.loaded}
                theme={theme}
                hasPadding={hasPadding}
                cardTheme={promotionType}
            >
                <CardMedia
                    item={item}
                    fixedRatio={fixedRatio}
                    imageWidths={themedValue(theme, {
                        thewest: {
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '100vw',
                            fallbackWidth: 628,
                        },
                        perthnow: {
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '100vw',
                            fallbackWidth: 628,
                        },
                        sevennews: {
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '628px',
                            fallbackWidth: 628,
                        },
                        fallback: {
                            mobile: '100vw',
                            tablet: '100vw',
                            desktop: '100vw',
                            fallbackWidth: 628,
                        },
                    })}
                    onEvent={onEvent}
                    disableImageLazyLoad={disableImageLazyLoad}
                    mediaMode={mediaMode}
                />

                <StyledCardTextContainer
                    applyNegativeMarginToCardText={
                        item.loaded && item.result.kicker
                    }
                >
                    <StyledCardText
                        item={item}
                        fontScale={setFontScale}
                        onEvent={onEvent}
                        teaserMode="hidden"
                        kickerMode={{ kickerType: 'block', icons: [] }}
                    />

                    {showCTA && <StyledCTA>Find out More</StyledCTA>}
                </StyledCardTextContainer>
            </StyledLink>
        </StyledPromotionalCard>
    )
}
PromotionalCard.displayName = 'PromotionalCard'
