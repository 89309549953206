import React from 'react'
import {
    StyledPieChart,
    StyledPieChartContainer,
    StyledPieChartPercentage,
    StyledPieChartPie,
    StyledPieChartText,
    StyledPieChartVotesCounted,
} from './PieChartWidget.styled'

export interface PieChartWidgetProps {
    theme: string
    percentage: number
    /* {TODO} DELETE AFTER FEDERAL ELECTION */
    noMarginOnVoteCountedText?: boolean
}

export const PieChartWidget: React.FunctionComponent<PieChartWidgetProps> = (
    props,
) => {
    const { theme, percentage } = props
    return (
        <StyledPieChartContainer>
            <StyledPieChart data-theme={theme}>
                <StyledPieChartPie
                    style={{ animationDelay: `-${percentage}s` }}
                    data-percent={percentage}
                ></StyledPieChartPie>
            </StyledPieChart>
            <StyledPieChartText
                noMarginOnVoteCountedText={
                    props.noMarginOnVoteCountedText || false
                }
            >
                <StyledPieChartPercentage>
                    {percentage}%
                </StyledPieChartPercentage>
                <StyledPieChartVotesCounted>
                    votes counted
                </StyledPieChartVotesCounted>
            </StyledPieChartText>
        </StyledPieChartContainer>
    )
}
