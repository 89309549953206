import { createRegisterableComponent } from '@news-mono/web-common'
import * as React from 'react'
import { LiveScores } from './LiveScores'

export const LiveScoresRegistration = createRegisterableComponent(
    'livescores',
    (props, services) => (
        <LiveScores statsPerformToken={services.config.statsPerformToken} />
    ),
)
