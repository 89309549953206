import { scriptExists } from '@news-mono/web-common'

interface loadedScriptElements {
    cssScript: HTMLLinkElement
    jsScript: HTMLScriptElement
    settingsScript: HTMLScriptElement
}

export const loadCricketWidgetScripts = (
    statsPerformToken: string | undefined,
    updateScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>,
): loadedScriptElements | undefined => {
    const cricketStyleSheetScript =
        '//secure.widget.cloud.opta.net/v3/css/v3.cricket.opta-widgets.css'
    const cricketScript = '//secure.widget.cloud.opta.net/v3/v3.opta-widgets.js'
    const cricketScriptBody = `var opta_settings = {
        subscription_id: '${statsPerformToken}',
        language: 'en_GB',
        timezone: 'user',
        show_logo: false
    }`

    const isLocalEnv =
        typeof window !== 'undefined' &&
        window.location.href.includes('localhost')
    const identifier = isLocalEnv ? 'http' : 'https'

    //Checked to see if the scripts are already loaded so we avoid duplicates
    if (!scriptExists(`${identifier}:${cricketScript}`)) {
        const cssScript = document.createElement('link')
        cssScript.rel = 'stylesheet'
        cssScript.href = cricketStyleSheetScript
        cssScript.type = 'text/css'
        document.head.appendChild(cssScript)

        // Add the JS and Settings Scripts
        const jsScript = document.createElement('script')
        jsScript.src = cricketScript
        document.body.appendChild(jsScript)
        const settingsScript = document.createElement('script')
        settingsScript.text = cricketScriptBody
        document.body.appendChild(settingsScript)

        jsScript.onload = () => {
            updateScriptLoaded(true)
        }

        return {
            cssScript,
            jsScript,
            settingsScript,
        }
    }
    return undefined
}
