import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { IsHydrating } from '@news-mono/web-common'
import React from 'react'
import { breakpointMax } from '../../../__styling/style-functions'

export interface CricketAnimationState {
    mounted: boolean
}

const grassBackground = require('./grass-tile-2x.png')
const cricketBallImage = require('./cricket-ball.png')

const ballYAnimation = keyframes({
    '0%': {
        transform: 'translate3d(0, -40px, 0)',
    },
    '50%': {
        transform: 'translate3d(0, 80px, 0)',
    },
    '100%': {
        transform: 'translate3d(0, 54px, 0)',
    },
})

const ballXAnimation = keyframes({
    '0%': {
        transform: 'translate3d(100px, 0, 0) rotate(0)',
    },
    '60%': {
        transform: 'translate3d(600px, 0, 0) rotate(720deg)',
    },
    '100%': {
        transform: 'translate3d(920px, 0, 0) rotate(1440deg)',
    },
})

const StyledCricketAnimation = styled('div')({
    display: 'block',
    pointerEvents: 'none',
})

const StyledBallContainer = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
})

const StyledGrass = styled('div')({
    position: 'absolute',
    width: '99.5vw',
    height: 16,
    right: '50%',
    bottom: 0,
    left: '50%',
    marginRight: '-50vw',
    marginLeft: '-50vw',
    backgroundImage: `url(${grassBackground})`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'contain',
})

interface StyledCricketBallProps {
    mounted: boolean
    animate: boolean
}

function setAnimation(kf: string) {
    if (process.env.NODE_ENV === 'test') {
        return undefined
    }

    return `2s linear 2s ${kf} forwards`
}

const StyledCricketBall = styled('div')<StyledCricketBallProps>(
    ({ animate }) => ({
        position: 'absolute',
        width: 40,
        height: 40,
        ...(animate
            ? {
                  animation: setAnimation(ballYAnimation),
                  transform: 'translate3d(0, -40px, 0)',
              }
            : {
                  transform: 'translate3d(0, 54px, 0)',
              }),
        willChange: 'transform',

        '&::after': {
            display: 'block',
            width: 40,
            height: 40,
            backgroundImage: `url(${cricketBallImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            ...(animate
                ? {
                      animation: setAnimation(ballXAnimation),
                  }
                : {
                      transform: 'translate3d(920px, 0, 0) rotate(1440deg)',
                  }),
            content: `''`,
            position: 'absolute',
            bottom: 22,

            [breakpointMax('lg')]: {
                position: 'static',
            },
        },

        [breakpointMax('sm')]: {
            width: 30,
            height: 30,

            '&::after': {
                width: 30,
                height: 30,
            },
        },

        [breakpointMax('lg')]: {
            right: 0,
            bottom: -4,
            animation: 'none',
            transform: 'rotate(10deg)',

            '&::after': {
                animation: 'none',
            },
        },
    }),
    ({ mounted }) => ({
        [breakpointMax('lg')]: {
            animationPlayState: mounted ? 'running' : 'paused',

            '&::after': {
                animationPlayState: mounted ? 'running' : 'paused',
            },
        },
    }),
)

export const CricketAnimation: React.FC = () => {
    const hydrating = React.useContext(IsHydrating)
    const [mounted, setMounted] = React.useState(false)
    const [animate, setAnimate] = React.useState(true)

    React.useEffect(() => {
        if (hydrating) {
            const isIE =
                (!!(window as any).MSInputMethodContext &&
                    !!(document as any).documentMode) ||
                window.navigator.userAgent.indexOf('Edge/')
            if (isIE) {
                // If we detect IE do another render pass before we set to mounted
                setAnimate(false)
                return
            }

            setMounted(true)
        }
    }, [hydrating, animate])

    return (
        <StyledCricketAnimation>
            <StyledGrass />
            <StyledBallContainer>
                <StyledCricketBall mounted={mounted} animate={animate} />
            </StyledBallContainer>
        </StyledCricketAnimation>
    )
}
