import React from 'react'

export interface IconActionCommentProps {
    className?: string
    isNeutralGrey?: boolean
}

export const IconActionComment: React.FC<IconActionCommentProps> = ({
    className,
}) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M20.6665 10H7.33317V7.33334H20.6665V10Z" />
        <path d="M7.33317 15.3333H20.6665V12.6667H7.33317V15.3333Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.666504 22V0.666672H27.3332V22H19.3332V27.3333H16.6665C13.721 27.3333 11.3332 24.9455 11.3332 22H0.666504ZM13.9998 19.3333V22C13.9998 23.4728 15.1937 24.6667 16.6665 24.6667V19.3333H24.6665V3.33334H3.33317V19.3333H13.9998Z"
        />
    </svg>
)
IconActionComment.displayName = 'IconActionComment'
