import { Theme } from '@emotion/react'

/**
 * Returns the latest of publicationDate / lastUpdated but only for sevennews, other themes
 * just return publicationDate || ''
 */
export function getLatestTime(
    theme: Theme,
    publicationDate?: string,
    lastUpdated?: string,
) {
    if (theme.kind !== 'sevennews' || !lastUpdated) {
        return publicationDate || ''
    } else {
        if (!publicationDate) {
            return ''
        }
        // Return the latest of publicationDate / lastUpdated
        const dates = [publicationDate, lastUpdated]
        const maxDateTime = dates.reduce(function (a, b) {
            return a > b ? a : b
        })
        return maxDateTime
    }
}
