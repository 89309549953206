import { IsHydrating } from '@news-mono/web-common'
import React, { ReactNode } from 'react'
import { StyledToastContainer } from './ToastNotification.styled'

interface ToastNotificationProps {
    className?: string
    ariaLabel?: string
    children?: ReactNode
    westLiveBannerUp?: boolean
}

export const ToastNotification = React.forwardRef<
    HTMLElement,
    ToastNotificationProps
>(({ children, className, ariaLabel, westLiveBannerUp = false }, ref) => {
    const isHydrating = React.useContext(IsHydrating)
    return !isHydrating ? (
        <StyledToastContainer
            aria-label={ariaLabel}
            aria-modal="true"
            role="alertdialog"
            ref={ref}
            tabIndex={-1}
            className={className}
            westLiveBannerUp={westLiveBannerUp}
        >
            {children}
        </StyledToastContainer>
    ) : null
})
