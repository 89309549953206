import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { FeatureComposition } from '../../compositions/FeatureComposition/FeatureComposition'
import {
    ContainerWidth,
    ThemeMarginKeys,
} from '../../__styling/settings/metrics'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'

export interface FeatureCompositionRouteProps extends BaseCompositionProps {
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | ThemeMarginKeys
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | ThemeMarginKeys
    verticalSpacing?: ThemeMarginKeys
    containerWidth?: ContainerWidth
    horizontalGap?: ThemeMarginKeys
}

export const FeatureCompositionRegistration = createRegisterableComposition<
    'main' | 'sidebar' | 'skinny'
>()(
    'feature-composition',
    (contentAreas, props: FeatureCompositionRouteProps) => (
        <ContentWrapper
            stretchColumns={props.stretchColumns}
            stretchSelf={props.stretchSelf}
        >
            <FeatureComposition
                {...props}
                skinnyContent={contentAreas.skinny}
                mainContent={contentAreas.main}
                sidebarContent={contentAreas.sidebar}
            />
        </ContentWrapper>
    ),
)
