import { Product, useFeature } from '@news-mono/web-common'
import React from 'react'
import { IndexExchange, MagniteWrapper, Section } from '..'

interface HeaderWrapperProps {
    section: Section
    product: Product
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
    section,
    product,
}) => {
    const isIxToggleEnabled = useFeature('ix-header-tag-wrapper')
    const isMagniteToggleEnabled = useFeature('magnite-header-tag-wrapper')

    if (isMagniteToggleEnabled) {
        return <MagniteWrapper product={product} />
    }

    if (isIxToggleEnabled) {
        return <IndexExchange product={product} section={section} />
    }

    return <></>
}
