import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { CardText } from '../../../cards/CardText/CardText'
import { breakpoint, calcRem, fonts, metrics } from '../../../__styling'

type LayoutTypes = 'landscape' | 'portrait'

interface Layout {
    layout: LayoutTypes
    className?: string
}

export const StyledTextCardsContainer = styled('div', {
    shouldForwardProp: createPropFilter<Layout>()(['layout', 'className']),
})<Layout>(({ layout }) => [
    {
        display: layout === 'portrait' ? 'grid' : 'block',
        gridTemplateColumns: '1fr',
        gridGap: `${metrics.sevennews.margins.sm}px ${metrics.sevennews.margins.gridGap}px`,

        [breakpoint('xxs')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
])

interface StyledEntertainmentCardProps {
    layout: LayoutTypes
}

export const StyledTextCard = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['layout', 'topic']),
})<StyledEntertainmentCardProps>(({ layout, theme }) => [
    {
        fontFamily: fonts.sevennews.sansSerifCond,
        textDecoration: 'none',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: `1px 1px 6px 0 rgba(0,0,0,0.16)`,
        background: theme.colors.background.primary,
        padding: calcRem(15),
        display: 'flex',
        borderBottom: `2px solid ${theme.sectionValues.primaryColor}`,
        transition:
            'border 0.250s cubic-bezier(0.55, 0, 0.1, 1),background 0.250s cubic-bezier(0.55, 0, 0.1, 1)',
        ['&:hover']: {
            //33 at the and transform to 8-digit hexadecimal notation for color it equal to 0.2 opacity
            background: `${theme.sectionValues.primaryColor}33`,
        },
        ['&:focus']: {
            border: `2px solid ${theme.sectionValues.primaryColor}`,
        },
        ['& svg']: {
            alignSelf: 'flex-end',
            marginLeft: calcRem(10),
            ['& path']: {
                stroke: theme.sectionValues.primaryColor,
            },
        },
    },
    layout === 'landscape' && {
        width: `100%`,
    },
])

export const StyledTextCardText = styled(CardText)({
    fontWeight: 'bold',
    [`& span`]: {
        color: '#333333',
    },
})
