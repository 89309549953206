import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts, themedValue } from '../../__styling'

export const AflMatchLadderWidgetContainer = styled('div')(({ theme }) => ({
    display: 'block',
    '.Opta': {
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            perthnow: fonts.perthnow.sansSerif,
            thewest: fonts.thewest.sansSerif,
            fallback: undefined,
        }),
        '.Opta_W': {
            marginTop: themedValue(theme, {
                perthnow: calcRem(8),
                thewest: calcRem(16),
                fallback: undefined,
            }),
            [breakpoint('sm')]: {
                marginTop: themedValue(theme, {
                    perthnow: calcRem(16),
                    fallback: undefined,
                }),
            },
            [breakpoint('md')]: {
                marginTop: themedValue(theme, {
                    perthnow: calcRem(32),
                    fallback: undefined,
                }),
            },
            '.Opta-Cf': {
                '.Opta-Table-Scroll-One-Liner': {
                    '.Opta-Striped': {
                        th: {
                            backgroundColor: themedValue(theme, {
                                sevennews:
                                    tokens.sevennews.colors.palette
                                        .bauhausWorld,
                                perthnow:
                                    tokens.perthnow.colors.palette.pinkThulite,
                                thewest: tokens.thewest.colors.palette.westblue,
                                fallback: undefined,
                            }),
                            textAlign: 'center',
                            ':nth-child(2)': {
                                textAlign: 'left',
                            },
                            paddingTop: calcRem(10),
                            paddingBottom: calcRem(10),
                            abbr: {
                                fontSize: calcRem(15),
                                fontWeight: 600,
                                color: tokens.sevennews.colors.palette.white,
                                [breakpoint('md')]: {
                                    fontSize: calcRem(16),
                                },
                            },
                        },
                        td: {
                            fontSize: themedValue(theme, {
                                sevennews: calcRem(16),
                                perthnow: calcRem(14),
                                fallback: undefined,
                            }),
                            padding: themedValue(theme, {
                                sevennews: calcRem(10, 0),
                                fallback: calcRem(7, 0),
                            }),
                            textAlign: 'center',
                            [breakpoint('md')]: {
                                fontSize: calcRem(16),
                                paddingTop: calcRem(10),
                                paddingBottom: calcRem(10),
                            },
                            ':last-of-type': {
                                fontWeight: 'bold',
                            },
                            ':nth-child(2)': {
                                textAlign: 'left',
                            },
                        },
                    },
                },
            },
        },
        '.Opta-error': {
            marginTop: 0,
            marginBottom: calcRem(24),
            background: 'transparent',
            div: {
                background: 'transparent',

                p: {
                    fontFamily: themedValue(theme, {
                        perthnow: fonts.perthnow.serif,
                        fallback: undefined,
                    }),
                    fontSize: themedValue(theme, {
                        sevennews: calcRem(15),
                        fallback: calcRem(16),
                    }),
                    fontWeight: themedValue(theme, {
                        sevennews: 500,
                        fallback: undefined,
                    }),
                    lineHeight: 1.5,
                    padding: 0,
                    color: themedValue(theme, {
                        perthnow: tokens.perthnow.colors.palette.greyCoal,
                        fallback: undefined,
                    }),
                },
            },
        },
    },
}))
