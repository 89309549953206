import debug from 'debug'

export const debugIpsos = debug('ipsos')

export type IpsosRenderType = 'web' | 'amp' | 'ajax'

export interface IpsosID {
    albanyadvertiser: BaseIpsosCategories
    amrtimes: BaseIpsosCategories
    broomead: BaseIpsosCategories
    bunburyherald: BaseIpsosCategories
    bdtimes: BaseIpsosCategories
    countryman: BaseIpsosCategories
    geraldtonguardian: BaseIpsosCategories
    gsherald: BaseIpsosCategories
    harveyreporter: BaseIpsosCategories
    kalminer: BaseIpsosCategories
    mbtimes: BaseIpsosCategories
    midwesttimes: BaseIpsosCategories
    narroginobserver: BaseIpsosCategories
    northwesttelegraph: BaseIpsosCategories
    perthnow: PerthNowCategories
    pilbaranews: BaseIpsosCategories
    soundtelegraph: BaseIpsosCategories
    swtimes: BaseIpsosCategories
    kimberleyecho: BaseIpsosCategories
    thewest: TheWestCategories
    sevennews: SevenNewsCategories
    thenightly: TheNightlyCategories
}

export type IpsosSiteNames =
    | 'albanyadvertiser'
    | 'amrtimes'
    | 'broomead'
    | 'bunburyherald'
    | 'bdtimes'
    | 'countryman'
    | 'geraldtonguardian'
    | 'gsherald'
    | 'harveyreporter'
    | 'kalminer'
    | 'mbtimes'
    | 'midwesttimes'
    | 'narroginobserver'
    | 'northwesttelegraph'
    | 'perthnow'
    | 'pilbaranews'
    | 'soundtelegraph'
    | 'swtimes'
    | 'kimberleyecho'
    | 'thewest'
    | 'sevennews'
    | 'thenightly'

export type BaseIpsosCategories = {
    homepage: number
    other: number
}

export type ExtraIpsosCategories = {
    business: number
    entertainment: number
    lifestyle: number
    news: number
    politics: number
    sport: number
    technology: number
    opinion: number
}

export type IpsosPathNames = keyof ExtraIpsosCategories

export type SevenNewsCategories = BaseIpsosCategories & {
    news: number
    sport: number
    lifestyle: number
    entertainment: number
    technology: number
    politics: number
    business: number
    motoring: number
    'lifestyle/motoring': number
}

export type TheNightlyCategories = BaseIpsosCategories & {
    editions: number
    australia: number
    business: number
    culture: number
    lifestyle: number
    opinion: number
    politics: number
    society: number
    sport: number
    world: number
    motoring: number
    'lifestyle/motoring': number
}

export type TheWestCategories = BaseIpsosCategories & {
    business: number
    entertainment: number
    lifestyle: number
    news: number
    opinion: number
    politics: number
    sport: number
}

export type PerthNowCategories = BaseIpsosCategories & {
    business: number
    entertainment: number
    lifestyle: number
    news: number
    politics: number
    sport: number
    technology: number
    north: number
    central: number
    south: number
    mandurah: number
    edition_joondalup: number
    edition_wanneroo: number
    edition_stirling: number
    'edition_western-suburbs': number
    edition_central: number
    edition_southern: number
    edition_canning: number
    edition_cockburn: number
    edition_fremantle: number
    edition_melville: number
    'edition_mandurah-times': number
    motoring: number
    'lifestyle/motoring': number
}

export const sevenNewsCategories = [
    'news',
    'sport',
    'lifestyle',
    'entertainment',
    'technology',
    'politics',
    'business',
    'motoring',
    'lifestyle/motoring',
]

export const theNightlyCategories = [
    'editions',
    'australia',
    'business',
    'culture',
    'lifestyle',
    'opinion',
    'politics',
    'society',
    'sport',
    'world',
    'motoring',
    'lifestyle/motoring',
]

export const perthNowCategories = [
    'business',
    'entertainment',
    'lifestyle',
    'news',
    'politics',
    'sport',
    'technology',
    'north',
    'central',
    'south',
    'mandurah',
    'edition_joondalup',
    'edition_wanneroo',
    'edition_stirling',
    'edition_western-suburbs',
    'edition_central',
    'edition_southern',
    'edition_canning',
    'edition_cockburn',
    'edition_fremantle',
    'edition_melville',
    'edition_mandurah-times',
    'motoring',
    'lifestyle/motoring',
]

export const perthNowNorth = [
    'north',
    'perthnow-wanneroo',
    'perthnow-joondalup',
    'perthnow-stirling',
]

export const perthNowCentral = [
    'central',
    'perthnow-western-suburbs',
    'perthnow-central',
    'perthnow-southern',
]

export const perthNowSouth = [
    'south',
    'perthnow-fremantle',
    'perthnow-melville',
    'perthnow-canning',
    'perthnow-cockburn',
]

export const theWestCategories = [
    'business',
    'entertainment',
    'lifestyle',
    'news',
    'opinion',
    'politics',
    'sport',
]

export const isSevenNewsCategories = (
    path: string,
): path is keyof SevenNewsCategories => {
    return sevenNewsCategories.includes(path)
}
export const isTheNightlyCategories = (
    path: string,
): path is keyof TheNightlyCategories => {
    return theNightlyCategories.includes(path)
}

export const isPerthNowCategories = (
    path: string,
): path is keyof PerthNowCategories => {
    return perthNowCategories.includes(path)
}

export const isTheWestCategories = (
    path: string,
): path is keyof TheWestCategories => {
    return theWestCategories.includes(path)
}

export const IpsosSet: IpsosID = {
    albanyadvertiser: {
        homepage: 12953,
        other: 12954,
    },
    amrtimes: {
        homepage: 12957,
        other: 12958,
    },
    broomead: {
        homepage: 12961,
        other: 12962,
    },
    bunburyherald: {
        homepage: 12969,
        other: 12970,
    },
    bdtimes: {
        homepage: 12959,
        other: 12960,
    },
    countryman: {
        homepage: 12987,
        other: 12988,
    },
    geraldtonguardian: {
        homepage: 12965,
        other: 12966,
    },
    gsherald: {
        homepage: 12955,
        other: 12956,
    },
    harveyreporter: {
        homepage: 12971,
        other: 12972,
    },
    kalminer: {
        homepage: 12977,
        other: 12978,
    },
    mbtimes: {
        homepage: 12973,
        other: 12974,
    },
    midwesttimes: {
        homepage: 12967,
        other: 12968,
    },
    narroginobserver: {
        homepage: 12979,
        other: 12980,
    },
    northwesttelegraph: {
        homepage: 12981,
        other: 12982,
    },
    perthnow: {
        homepage: 12949,
        business: 13054,
        entertainment: 13050,
        lifestyle: 13052,
        news: 13049,
        politics: 13053,
        sport: 13051,
        technology: 13055,
        other: 12950,
        north: 14558,
        central: 14559,
        south: 14560,
        mandurah: 14561,
        edition_joondalup: 14106,
        edition_wanneroo: 14111,
        edition_stirling: 14110,
        'edition_western-suburbs': 14112,
        edition_central: 14103,
        edition_southern: 14109,
        edition_canning: 14102,
        edition_cockburn: 14104,
        edition_fremantle: 14105,
        edition_melville: 14108,
        'edition_mandurah-times': 14107,
        motoring: 14670,
        'lifestyle/motoring': 14670,
    },
    pilbaranews: {
        homepage: 12983,
        other: 12984,
    },
    soundtelegraph: {
        homepage: 12985,
        other: 12986,
    },
    swtimes: {
        homepage: 12975,
        other: 12976,
    },
    kimberleyecho: {
        homepage: 12963,
        other: 12964,
    },
    thewest: {
        homepage: 12947,
        business: 13044,
        entertainment: 13046,
        lifestyle: 13047,
        news: 13042,
        opinion: 13048,
        politics: 13045,
        sport: 13043,
        other: 12948,
    },
    sevennews: {
        homepage: 12989,
        news: 13165,
        sport: 13166,
        lifestyle: 13167,
        entertainment: 13168,
        technology: 13169,
        politics: 13170,
        business: 13171,
        other: 12990,
        motoring: 14655,
        'lifestyle/motoring': 14655,
    },
    thenightly: {
        homepage: 14952,
        australia: 15073,
        business: 15074,
        culture: 15075,
        lifestyle: 15076,
        opinion: 15077,
        politics: 15078,
        society: 15079,
        sport: 15080,
        world: 15081,
        editions: 14951,
        other: 14950,
        motoring: 15153,
        'lifestyle/motoring': 15153,
    },
}
