import { slugify } from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
export function createLinkToSeriesEpisode(
    section: string,
    series: string,
    season: number,
    trailerHeadline: string,
    trailer: VideoMetaDTO,
): string {
    return `/${section}/${slugify(series)}/${season}/episode/${slugify(
        trailerHeadline,
    )}-${trailer.id}`
}
