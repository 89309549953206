import {
    CollectionDataLoaded,
    ComponentServices,
    ContentDataDefinitionLoader,
    ContentDataTypes,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    Product,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../data-controllers/LoadMore/LoadMore'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import { Whiskey } from '../../collections/whiskey/Whiskey'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { useProduct } from '../../__product/useProduct'

export interface TheWestProps extends WhiskeyProps {
    imageWidths?: number
}

interface WhiskeyProps
    extends CollectionRouteProps,
        LoadMoreRouteProps,
        ResponsiveContainer {
    initialColumns?: number
    intermediateColumns?: number
    finalColumns?: number
}

const renderWhiskey = (
    props: WhiskeyProps & TheWestProps,
    data: MaybeLoaded<CollectionDataLoaded> & {
        dataDefinitionArgs: ContentDataTypes
    },
    services: ComponentServices,
    product: Product,
) => {
    return (
        <LoadMore
            dataDefinition={data.dataDefinitionArgs}
            services={services}
            loadMoreEnabled={props.loadMoreEnabled === true}
            data={data}
            onLoadMore={(loadMoreData) => {
                services.onEvent(
                    createCollectionAvailableEvent(
                        loadMoreData.result.publications,
                        'whiskey',
                        product,
                        services.onEvent,
                    ),
                )
            }}
            render={(loadMoreData) => {
                return (
                    <Whiskey
                        items={
                            loadMoreData.loaded
                                ? {
                                      loaded: true,
                                      result: loadMoreData.result.publications,
                                  }
                                : { loaded: false }
                        }
                        expectedCards={calculateExpectedCardCountFromContentData(
                            data.dataDefinitionArgs,
                        )}
                        initialColumns={props.initialColumns}
                        intermediateColumns={props.intermediateColumns}
                        finalColumns={props.finalColumns}
                        verticalSpacing={props.verticalSpacing}
                        containerWidthRatios={props.containerWidthRatios}
                        onEvent={services.onEvent}
                    />
                )
            }}
        />
    )
}

export const WhiskeyRegistration = createRegisterableComponentWithData(
    'whiskey',
    ContentDataDefinitionLoader,
    (props: TheWestProps, data, services) => {
        const product = useProduct()
        return renderWhiskey(
            { ...props, imageWidths: props.imageWidths },
            data,
            services,
            product,
        )
    },
)
