import { TheWestSection } from '@news-mono/common'
import { BaseSectionValues } from '.'
import { colors } from '../../../__styling/settings/colors'

export interface TheWestSectionValues extends BaseSectionValues {
    title: string
}

export type TheWestSectionProps = {
    [section in TheWestSection]: TheWestSectionValues
}

export const theWestSectionDefinitions: TheWestSectionProps = {
    default: {
        title: 'The West Australian',
        primaryColor: colors.thewest.westblue,
    },
    genwest: {
        title: 'The West Australian',
        primaryColor: colors.thewest.westblue,
    },
    'albany-advertiser': {
        title: 'Albany Advertiser',
        primaryColor: colors.thewest.albanyadvertiserBlue,
    },
    'augusta-margaret-river-times': {
        title: 'Augusta-Margaret River Times',
        primaryColor: colors.thewest.augustamargaretrivertimesOrange,
    },
    'broome-advertiser': {
        title: 'Broome Advertiser',
        primaryColor: colors.thewest.broomeadvertiserGreen,
    },
    'bunbury-herald': {
        title: 'Bunbury Herald',
        primaryColor: colors.thewest.bunburyheraldBlue,
    },
    'busselton-dunsborough-times': {
        title: 'Busselton-Dunsborough Times',
        primaryColor: colors.thewest.busseltondunsboroughtimesTeal,
    },
    'geraldton-guardian': {
        title: 'Geraldton Guardian',
        primaryColor: colors.thewest.geraldtonguardianRed,
    },
    'great-southern-herald': {
        title: 'Great Southern Herald',
        primaryColor: colors.thewest.greatsouthernheraldBlue,
    },
    'harvey-waroona-reporter': {
        title: 'Harvey Waroona Reporter',
        primaryColor: colors.thewest.harveywaroonareporterGreen,
    },
    'kalgoorlie-miner': {
        title: 'Kalgoorlie Miner',
        primaryColor: colors.thewest.kalgoorlieminerRed,
    },
    'manjimup-bridgetown-times': {
        title: 'Manjimup Bridgetown Times',
        primaryColor: colors.thewest.manjimupbridgetowntimesBlue,
    },
    'midwest-times': {
        title: 'Midwest Times',
        primaryColor: colors.thewest.midwesttimesRed,
    },
    'narrogin-observer': {
        title: 'Narrogin Observer',
        primaryColor: colors.thewest.narroginobserverBlue,
    },
    'north-west-telegraph': {
        title: 'North West Telegraph',
        primaryColor: colors.thewest.northwesttelegraphOcre,
    },
    'pilbara-news': {
        title: 'Pilbara News',
        primaryColor: colors.thewest.pilbaranewsOrange,
    },
    'sound-telegraph': {
        title: 'Sound Telegraph',
        primaryColor: colors.thewest.soundtelegraphBlue,
    },
    'south-western-times': {
        title: 'South Western Times',
        primaryColor: colors.thewest.southwesterntimesRed,
    },
    'the-kimberley-echo': {
        title: 'The Kimberley Echo',
        primaryColor: colors.thewest.thekimberleyechoRed,
    },
    countryman: {
        title: 'Countryman',
        primaryColor: colors.thewest.countrymanRed,
    },
}
