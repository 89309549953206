import React, { useEffect, useState } from 'react'
import {
    OlympicWidgetWrap,
    OlympicsWidgetSize,
} from '../OlympicsWidgetWrap/OlympicsWidgetWrap'
import { StyledWidgetContainer } from '../OlympicsWidgetWrap/OlympicsWidgetWrap.styled'
export interface MedalTallyProps {
    size: OlympicsWidgetSize
    listSize?: number
    svgColourOverride?: string
    widgetTitle?: string
    hideLinks?: boolean
}
import xss, { IFilterXSSOptions } from 'xss'

export function MedalTally({
    size,
    listSize = 10,
    svgColourOverride,
    widgetTitle,
    hideLinks,
}: MedalTallyProps) {
    const [html, setHtml] = useState('')

    const OLYMPICS_NAME = 'paris-2024' // Name of which Olympics to show medals from
    const COUNTRY_CODE = 'cy17' // Always shows this country regardless of position (17 = Australia)
    const LIST_SIZE = `li${listSize}` // Limit to 10 items by default
    // Add any additional parameters here
    const WIDGET_PARAMS = [COUNTRY_CODE, LIST_SIZE, OLYMPICS_NAME]

    const embedUrl = `https://api.olympics.statsperform.com/sevenwestmedia-29jk524nst2o/content/en/iframe/medal-table/${WIDGET_PARAMS.join(
        '/',
    )}`

    useEffect(() => {
        async function getHtml() {
            try {
                const html = await fetch(embedUrl)

                // These options remove all unwanted html tags from the response allowing us to style ourselves
                const options: IFilterXSSOptions = {
                    whiteList: {
                        table: ['class'],
                        th: ['class'],
                        tr: ['class'],
                        tbody: ['class'],
                        thead: ['class'],
                        td: ['class'],
                        img: ['src', 'alt', 'title'],
                    },
                    stripIgnoreTag: true,
                    stripIgnoreTagBody: ['link', 'head', 'h2', 'a'],
                }
                const response = await html.text()
                const sanitized = xss(response, options)

                if (sanitized) {
                    setHtml(sanitized)
                }
            } catch (error) {
                console.warn(
                    'An error has occurred loading the Medal Tally embed: ',
                    error,
                )
            }
        }

        getHtml()
    }, [embedUrl])

    return (
        <OlympicWidgetWrap
            size={size}
            svgColourOverride={svgColourOverride}
            widgetTitle={widgetTitle}
            hideLinks={hideLinks}
        >
            {html ? (
                <StyledWidgetContainer
                    size={size}
                    dangerouslySetInnerHTML={{ __html: html }}
                    tabIndex={0}
                ></StyledWidgetContainer>
            ) : (
                <>Loading...</> // Todo: Fix loading ?
            )}
        </OlympicWidgetWrap>
    )
}
