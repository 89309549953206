import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { AllSportsLiveCombinedLogo } from '../../logos/AllSportsLiveCombinedLogo/AllSportsLiveCombinedLogo'
import { WeAreForYouLogo } from '../../logos/WeAreForYouLogo/WeAreForYouLogo'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export interface StyledOpportunityProps {
    layoutMode?: string
    size?: string
}

export interface StyledAngleProps {
    layoutMode?: string
}

export const StyledTextEmphasis = styled('em')({
    fontStyle: 'normal',
    borderBottom: `3px solid ${colors.thewest.westblue}`,
})

export const StyledSubscribeButton = styled(WebLink)({
    backgroundColor: colors.thewest.westblue,
    border: `1px solid ${colors.thewest.westblue}`,
    padding: calcRem(metrics.thewest.margins.sm, metrics.thewest.margins.md),
    borderRadius: calcRem(2),
    color: colors.white,
    fontFamily: fonts.thewest.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(14),
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    zIndex: zIndex.common.videoTrailerCardContent,
    [breakpoint('sm')]: {
        marginLeft: 'auto',
    },

    [breakpoint('md')]: {
        marginLeft: 0,
        marginTop: calcRem(metrics.thewest.margins.xxl),
        display: 'inline-block',
    },

    '&:hover, &:focus': {
        backgroundImage: 'none',
        outline: 'none',
    },

    '&:hover': {
        backgroundColor: 'transparent',
        color: colors.thewest.westblue,
    },

    '&:focus': {
        backgroundColor: colors.thewest.greyKoala,
        textDecoration: 'underline',
    },
})

export const StyledWeAreForYouLogo = styled(WeAreForYouLogo)({
    position: 'absolute',
    bottom: calcRem(12),
    right: calcRem(12),
    width: calcRem(120),
    height: calcRem(30),
})

export const StyledBundleImage = styled('picture')({
    display: 'none',
    width: calcRem(140),
    maxWidth: '100%',
    marginLeft: 'auto',

    img: {
        width: '100%',
    },

    [breakpoint('md')]: {
        display: 'block',
    },

    '@media screen and (min-width: 1120px)': {
        width: calcRem(220),
    },
})

const StyledCardText = styled('p')({
    fontFamily: fonts.thewest.serif,
    fontWeight: 'bold',
    fontSize: calcRem(22),
    lineHeight: 1.2,
    margin: 0,
})

const StyledContainer = styled('div')({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    padding: calcRem(metrics.thewest.margins.md),
})

export const StyledOpportunityContainer = styled(
    StyledContainer,
)<StyledOpportunityProps>(
    {
        background: 'linear-gradient(90deg, #043DE2 0%, #1257E0 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 170,
        color: colors.black,
        position: 'relative',
        backgroundPosition: '100% 50%',
        overflow: 'hidden',
        margin: '0 auto',
        width: '100%',
        maxWidth: 700,

        [breakpoint('xxs')]: {
            minHeight: 130,
        },

        [breakpoint('xs')]: {
            minHeight: 'auto',
        },

        [breakpoint('sm')]: {
            display: 'flex',
            justifyContent: 'normal',
        },
        [breakpoint('md')]: {
            borderLeft: 'none',
            borderRight: 'none',
            maxWidth: '100%',
        },
    },
    (props) => ({
        [breakpoint('sm')]: {
            flexDirection: props.layoutMode === 'single' ? 'column' : 'row',
        },
    }),
)

export const StyledOpportunityText = styled(
    StyledCardText,
)<StyledOpportunityProps>(
    {
        position: 'relative',
        marginBottom: calcRem(metrics.thewest.margins.sm),
        color: colors.white,
        fontSize: calcRem(16),
        fontWeight: 500,
        [breakpoint('sm')]: {
            marginRight: 0,
            marginBottom: calcRem(metrics.thewest.margins.md),
            marginLeft: calcRem(metrics.thewest.margins.md),
        },

        [breakpoint('md')]: {
            marginLeft: 0,
        },
    },
    (props) => ({
        [breakpoint('xs')]: {
            display: props.layoutMode === 'double' ? 'none' : 'block',
        },
    }),
)

export const StyledOpportunityVideoWrap = styled('div')<StyledOpportunityProps>(
    {
        position: 'relative',
        zIndex: 1,
        width: calcRem(258),
        height: calcRem(146),
        flexShrink: 0,
        [breakpoint('sm')]: {
            width: calcRem(289),
            height: calcRem(163),
        },
    },
    (props) => ({
        [breakpoint('sm')]: {
            marginRight: props.layoutMode === 'double' ? calcRem(20) : '0',
        },
    }),
)

export const StyledOpportunityContentWrap = styled(
    'div',
)<StyledOpportunityProps>(
    {
        position: 'relative',
        height: '100%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: calcRem(255),
        marginTop: calcRem(10),
        [breakpoint('md')]: {
            width: calcRem(255),
            marginTop: calcRem(10),
        },
    },
    (props) => ({
        [breakpoint('sm')]: {
            marginTop: props.layoutMode === 'double' ? '0px' : '10px',
            width: props.layoutMode === 'double' ? undefined : '255px',
            flex: props.layoutMode === 'double' ? '1 1 auto' : undefined,
        },
    }),
)

export const StyledOpportunityHeaderWrap = styled('div')({
    display: 'none',
    fontSize: calcRem(20),
    marginBottom: calcRem(20),
    [breakpoint('sm')]: {
        display: 'block',
    },
    [breakpoint('md')]: {
        marginBottom: calcRem(40),
    },
})

export const StyledOpportunityHeader = styled(
    StyledCardText,
)<StyledOpportunityProps>(
    {
        color: colors.white,
    },
    (props) => ({
        fontSize:
            props.size === 'sm'
                ? calcRem(20)
                : props.size === 'md'
                ? calcRem(24)
                : props.size === 'lg'
                ? calcRem(28)
                : calcRem(20),
    }),
)

export const StyledOpportunityLink = styled(WebLink)<StyledOpportunityProps>(
    {
        fontFamily: fonts.thewest.serif,
        color: colors.thewest.sunshineYellow,
        fontWeight: 'bold',
    },
    (props) => ({
        fontSize:
            props.size === 'sm'
                ? calcRem(20)
                : props.size === 'md'
                ? calcRem(24)
                : props.size === 'lg'
                ? calcRem(28)
                : calcRem(20),
    }),
)

export const StyledEntitledContainer = styled(StyledContainer)({
    backgroundColor: colors.thewest.westblue,
    color: colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    background: 'linear-gradient(90deg, #052EB7 0%, #043DE2 100%)',
    [breakpoint('sm')]: {
        alignItems: 'center',
        justifyContent: 'normal',
        minHeight: 'auto',
        display: 'none',
    },

    [breakpoint('md')]: {
        backgroundSize: '100%',
        backgroundColor: 'transparent',
        display: 'block',
    },
})

export const StyledEntitledText = styled(StyledCardText)({
    flexBasis: '100%',
    flexGrow: 1,
    marginBottom: calcRem(metrics.thewest.margins.md),
    textAlign: 'center',

    [breakpoint('xs')]: {
        fontSize: calcRem(24),
        lineHeight: calcRem(32),
    },

    [breakpoint('sm')]: {
        textAlign: 'left',
        flexBasis: '0%',
        marginBottom: 0,
        maxWidth: '50%',
    },

    [breakpoint('md')]: {
        flexBasis: '0%',
        marginBottom: calcRem(44),
        fontSize: calcRem(20),
        lineHeight: 1.2,
        flexGrow: 0,
        maxWidth: '90%',
    },

    '@media screen and (min-width: 1120px)': {
        fontSize: calcRem(24),
    },
})

export const StyledLiveSportsLogo = styled(AllSportsLiveCombinedLogo)({
    height: calcRem(53),
})
export const StyledAngle = styled('div')<StyledAngleProps>(
    {
        background: 'white',
        position: 'absolute',
        bottom: '-1px',
        left: '0',
        width: '101%',
        [`${StyledEntitledContainer} &`]: {
            display: 'none',
            [breakpoint('sm')]: {
                display: 'block',
            },
        },
    },
    (props) => ({
        height: props.layoutMode === 'single' ? '50%' : '70%',
        clipPath:
            props.layoutMode === 'single'
                ? 'polygon(0 5%, 100% 50%, 100% 101%, 0 100%)'
                : 'polygon(0 10%, 100% 77%, 100% 100%, 0 100%)',
    }),
)
