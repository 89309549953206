import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getRTS2019RouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        ...sectionBuilder.getCuratedSection(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Keynotes',
                    headingUrl: '/business/rts2019-keynotes',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/rts2019-keynotes'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Panels',
                    headingUrl: '/business/rts2019-panels',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/rts2019-panels'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Exhibition',
                    headingUrl: '/business/rts2019-exhibition',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/rts2019-exhibition'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                loadMoreEnabled: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Latest',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/rts2019'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
