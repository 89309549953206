import { RawFeatureValues } from '@etrigan/feature-toggles-client'
import { Action } from 'redux'
import { ABTestingFeatures, Features, testFeatureState } from '../features'

export const A_B_TESTING_FEATURE_NAME = 'a-b-testing-features'

/**
 * Redux state for feature toggling in the west
 */
export type TogglesReduxState = {
    [name in Features]?: unknown
} & { [A_B_TESTING_FEATURE_NAME]?: ABTestingFeatures }

/**
 * Typeguard to ensure string is a name in Features
 */
export function isFeatureName(feature: string): feature is Features {
    return feature in testFeatureState
}

/**
 * This action does a shallow merge with existing toggles. This is to ensure that toggles are not lost in error.
 * See: https://jira.swmdigital.io/browse/DPT-127
 */
export const LOAD_FEATURE_TOGGLES = 'toggles/LOAD_FEATURE_TOGGLES'
export interface LOAD_FEATURE_TOGGLES extends Action {
    type: 'toggles/LOAD_FEATURE_TOGGLES'
    payload: {
        features: RawFeatureValues
    }
}

export type FeatureActions = LOAD_FEATURE_TOGGLES

export const loadFeatures = (
    features: RawFeatureValues,
): LOAD_FEATURE_TOGGLES => ({
    type: LOAD_FEATURE_TOGGLES,
    payload: { features },
})

export const isfeatureToggleAction = (
    action: Action,
): action is FeatureActions => {
    return action.type === LOAD_FEATURE_TOGGLES
}

const defaultState = {}

const toggles = (
    state: TogglesReduxState = defaultState,
    action: Action,
): TogglesReduxState => {
    if (!isfeatureToggleAction(action)) {
        return state
    }
    switch (action.type) {
        /**
         * This action does a shallow merge with existing toggles. This is to ensure that toggles are not lost in error.
         * See: https://jira.swmdigital.io/browse/DPT-127
         */
        case LOAD_FEATURE_TOGGLES: {
            const newState: TogglesReduxState = {
                ...state,
                ...action.payload.features,
            }

            return newState
        }
    }
}
export default toggles
