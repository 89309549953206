import React from 'react'
import { IconStar } from '../..'
import { IconLightning, IconPin, IconPinTN } from '../../icons'
import { ChipVariant } from '../Chip/Chip'
import { StyledChipIcon } from '../Chip/Chip.styled'
import { Theme } from '@emotion/react'
import { Product } from '@news-mono/web-common'
const aquatics = require('../Chip/assets/CommonwealthGames/aquatics.svg')
const athletics = require('../Chip/assets/CommonwealthGames/athletics.svg')
const badminton = require('../Chip/assets/CommonwealthGames/badminton.svg')
const basketball = require('../Chip/assets/CommonwealthGames/basketball.svg')
const cricket = require('../Chip/assets/CommonwealthGames/cricket.svg')
const cycling = require('../Chip/assets/CommonwealthGames/cycling.svg')
const gymnastics = require('../Chip/assets/CommonwealthGames/gymnastics.svg')
const hockey = require('../Chip/assets/CommonwealthGames/hockey.svg')
const judo = require('../Chip/assets/CommonwealthGames/judo.svg')
const rugby = require('../Chip/assets/CommonwealthGames/rugby.svg')
const swimming = require('../Chip/assets/CommonwealthGames/swimming.svg')
const tabletennis = require('../Chip/assets/CommonwealthGames/tabletennis.svg')
const volleyball = require('../Chip/assets/CommonwealthGames/volleyball.svg')
const weightlifting = require('../Chip/assets/CommonwealthGames/weightlifting.svg')
const wrestling = require('../Chip/assets/CommonwealthGames/wrestling.svg')
const ceremony = require('../Chip/assets/CommonwealthGames/ceremony.svg')
const medalMoment = require('../Chip/assets/CommonwealthGames/medal-moment.svg')
const aussieMoment = require('../Chip/assets/CommonwealthGames/aussie-moment.svg')
const medal = require('../Chip/assets/CommonwealthGames/medal.svg')
const worldRecord = require('../Chip/assets/CommonwealthGames/world-record.svg')
const q = require('../Chip/assets/AFL/q.svg')
const goal = require('../Chip/assets/AFL/goal.svg')
const ball = require('../Chip/assets/AFL/ball.svg')
const behind = require('../Chip/assets/AFL/behind.svg')
const bigCall = require('../Chip/assets/AFL/bigCall.svg')
const brutalHit = require('../Chip/assets/AFL/brutalHit.svg')
const injury = require('../Chip/assets/AFL/injury.svg')
const newsUpdate = require('../Chip/assets/AFL/newsUpdate.svg')
const ouch = require('../Chip/assets/AFL/ouch.svg')
const premiers = require('../Chip/assets/AFL/premiers.svg')
const scoreUpdate = require('../Chip/assets/AFL/scoreUpdate.svg')
const topMoments = require('../Chip/assets/AFL/topMoments.svg')
const compensation = require('../Chip/assets/AFL/compensation.svg')
const confirmed = require('../Chip/assets/AFL/confirmed.svg')
const delisted = require('../Chip/assets/AFL/delisted.svg')
const doneDeal = require('../Chip/assets/AFL/doneDeal.svg')
const pickSwap = require('../Chip/assets/AFL/pickSwap.svg')
const tradeAlert = require('../Chip/assets/AFL/TA.svg')
const prediction = require('../Chip/assets/AFL/prediction.svg')
const reaction = require('../Chip/assets/AFL/reaction.svg')
const rumour = require('../Chip/assets/AFL/rumour.svg')
const statement = require('../Chip/assets/AFL/statement.svg')
const whispers = require('../Chip/assets/AFL/whispers.svg')

const tryIcon = require('../Chip/assets/NRL/try.svg')
const penalty = require('../Chip/assets/NRL/penalty.svg')
const bigHit = require('../Chip/assets/NRL/bigHit.svg')
const captainsChallenge = require('../Chip/assets/NRL/captainsChallenge.svg')
const conversionSuccesful = require('../Chip/assets/NRL/conversionSuccesful.svg')
const conversionUnsuccesful = require('../Chip/assets/NRL/conversionUnsuccesful.svg')
const error = require('../Chip/assets/NRL/error.svg')
const interchange = require('../Chip/assets/NRL/interchange.svg')
const kickoff = require('../Chip/assets/NRL/kickoff.svg')
const lineBreak = require('../Chip/assets/NRL/lineBreak.svg')
const lineDropout = require('../Chip/assets/NRL/lineDropout.svg')
const noTry = require('../Chip/assets/NRL/noTry.svg')

// soccer
const kickoffSoccer = require('../Chip/assets/soccer/kickoff.svg')
const time = require('../Chip/assets/soccer/time.svg')
const penaltyShootout = require('../Chip/assets/soccer/penaltyShootout.svg')
const offside = require('../Chip/assets/soccer/offside.svg')
const freeKick = require('../Chip/assets/soccer/freeKick.svg')
const penaltySoccer = require('../Chip/assets/soccer/penalty.svg')
const worldieAlert = require('../Chip/assets/soccer/worldieAlert.svg')
const card = require('../Chip/assets/soccer/card.svg')
const substitution = require('../Chip/assets/soccer/substitution.svg')
const tackle = require('../Chip/assets/soccer/tackle.svg')
const block = require('../Chip/assets/soccer/block.svg')
const shot = require('../Chip/assets/soccer/shot.svg')
const worldCupWinners = require('../Chip/assets/soccer/worldCupWinners.svg')
const socceroos = require('../Chip/assets/soccer/socceroos.svg')
const soccerGoal = require('../Chip/assets/soccer/goal.svg')
const soccerTime = require('../Chip/assets/soccer/time2.svg')

export function getChipIcon(
    variant: ChipVariant,
    theme: Theme,
    text?: string,
    entryType?: string,
) {
    // We don't want to show icons on Chips on TheNightly
    if (theme.kind === Product.TheNightly) {
        if (variant === 'pinned') {
            return <IconPinTN />
        } else {
            return
        }
    }

    switch (variant) {
        case 'pinned':
            return <IconPin />
        case 'happening':
            switch (text?.toLowerCase()) {
                // commonwealth games
                case 'wrestling':
                    return <StyledChipIcon src={wrestling} />
                case 'aquatics':
                    return <StyledChipIcon src={aquatics} />
                case 'athletics':
                    return <StyledChipIcon src={athletics} />
                case 'badminton':
                    return <StyledChipIcon src={badminton} />
                case 'basketball':
                    return <StyledChipIcon src={basketball} />
                case 'cricket':
                    return <StyledChipIcon src={cricket} />
                case 'cycling':
                    return <StyledChipIcon src={cycling} />
                case 'gymnastics':
                    return <StyledChipIcon src={gymnastics} />
                case 'hockey':
                    return <StyledChipIcon src={hockey} />
                case 'judo':
                    return <StyledChipIcon src={judo} />
                case 'rugby':
                    return <StyledChipIcon src={rugby} />
                case 'swimming':
                    return <StyledChipIcon src={swimming} />
                case 'table tennis':
                    return <StyledChipIcon src={tabletennis} />
                case 'beach volleyball':
                    return <StyledChipIcon src={volleyball} />
                case 'weightlifting':
                    return <StyledChipIcon src={weightlifting} />
                case 'opening ceremony':
                case 'closing ceremony':
                    return <StyledChipIcon src={ceremony} />
                case 'gold medal':
                case 'silver medal':
                case 'bronze medal':
                    return <StyledChipIcon src={medal} />
                case 'world record':
                    return <StyledChipIcon src={worldRecord} />
                case 'aussie moment':
                    return <StyledChipIcon src={aussieMoment} />
                case 'medal moment':
                    return <StyledChipIcon src={medalMoment} />
                // AFL
                case 'q1':
                case 'q2':
                case 'q3':
                case 'q4':
                case 'half-time':
                case 'full-time':
                case 'quarter-time':
                    if (entryType === 'soccer') {
                        return <StyledChipIcon src={soccerTime} />
                    }
                    return <StyledChipIcon src={q} />
                case 'goal':
                    if (entryType === 'soccer') {
                        return <StyledChipIcon src={soccerGoal} />
                    }
                    return <StyledChipIcon src={goal} />
                case 'baaaallll!':
                    return <StyledChipIcon src={ball} />
                case 'behind':
                    return <StyledChipIcon src={behind} />
                case 'big call':
                    return <StyledChipIcon src={bigCall} />
                case 'brutal hit':
                    return <StyledChipIcon src={brutalHit} />
                case 'injury':
                    return <StyledChipIcon src={injury} />
                case 'news update':
                    return <StyledChipIcon src={newsUpdate} />
                case 'ouch':
                    return <StyledChipIcon src={ouch} />
                case 'premiers':
                    return <StyledChipIcon src={premiers} />
                case 'score update':
                    return <StyledChipIcon src={scoreUpdate} />
                case 'top moments':
                    return <StyledChipIcon src={topMoments} />
                case 'rumour':
                    return <StyledChipIcon src={rumour} />
                case 'confirmed':
                    return <StyledChipIcon src={confirmed} />
                case 'reaction':
                    return <StyledChipIcon src={reaction} />
                case 'statement':
                    return <StyledChipIcon src={statement} />
                case 'done deal':
                    return <StyledChipIcon src={doneDeal} />
                case 'pick swap':
                    return <StyledChipIcon src={pickSwap} />
                case 'trade alert':
                    return <StyledChipIcon src={tradeAlert} />
                // Missing icon for trade alert
                case 'whispers':
                    return <StyledChipIcon src={whispers} />
                case 'prediction':
                    return <StyledChipIcon src={prediction} />
                case 'delisted':
                    return <StyledChipIcon src={delisted} />
                case 'compensation':
                    return <StyledChipIcon src={compensation} />

                // NRL
                case 'try':
                    return <StyledChipIcon src={tryIcon} />
                case 'no try':
                    return <StyledChipIcon src={noTry} />
                case 'penalty':
                case 'video referee':
                    return <StyledChipIcon src={penalty} />
                case 'conversion successful':
                    return <StyledChipIcon src={conversionSuccesful} />
                case 'conversion unsuccessful':
                    return <StyledChipIcon src={conversionUnsuccesful} />
                case 'kickoff':
                case 'kick-off':
                    return <StyledChipIcon src={kickoff} />
                case 'interchange':
                    return <StyledChipIcon src={interchange} />
                case 'big hit':
                    return <StyledChipIcon src={bigHit} />
                case 'rrror':
                    return <StyledChipIcon src={error} />
                case 'line break':
                    return <StyledChipIcon src={lineBreak} />
                case "captain's challenge":
                    return <StyledChipIcon src={captainsChallenge} />
                case 'line dropout':
                    return <StyledChipIcon src={lineDropout} />
                // Soccer
                case 'group stage':
                case 'round of 16':
                case 'third place playoff':
                case 'quarterfinal':
                case 'semifinal':
                case 'final':
                    return <StyledChipIcon src={kickoffSoccer} />
                case 'extra time':
                case 'half time':
                case 'full time':
                case 'first half':
                case 'second half':
                case 'quarter time':
                    return <StyledChipIcon src={time} />
                case 'penalty shootout':
                    return <StyledChipIcon src={penaltyShootout} />
                case 'offside':
                    return <StyledChipIcon src={offside} />
                case 'free kick':
                    return <StyledChipIcon src={freeKick} />
                case 'worldie alert':
                    return <StyledChipIcon src={worldieAlert} />
                case 'yellow card':
                case 'red card':
                    return <StyledChipIcon src={card} />
                case 'substitution':
                    return <StyledChipIcon src={substitution} />
                case 'tackle':
                    return <StyledChipIcon src={tackle} />
                case 'shot':
                    return <StyledChipIcon src={shot} />
                case 'block/save':
                    return <StyledChipIcon src={block} />
                case 'world cup winners':
                    return <StyledChipIcon src={worldCupWinners} />
                case 'socceroos':
                    return <StyledChipIcon src={socceroos} />

                default:
                    return <IconLightning />
            }
        case 'label':
            return <IconStar />
        default:
            return
    }
}
