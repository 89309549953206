export declare type Asset = Asset_Image | Asset_Video

export interface Asset_Video {
    kind: 'video'
    id: string
    accountId: string
    name: string
    referenceId: string
    heading: string
    videoDescription: string
    posterImage: CropDTO | null
    originalData?: any // This is the Brightcove types...
    videoType: 'vod' | 'live' | null
    /** oovvuu, wildmoka, vizstory, unknown, maybe others */
    ingestChannel: string | null
}

export interface Asset_MissingVideo {
    kind: 'missing-video'
    id: string
    accountId: string
    name: string
    referenceId: string
}

export interface Asset_Image extends CopyrightCaption {
    /** @deprecated */
    id: ''
    /** @deprecated */
    name: ''

    kind: 'image'
    original: CropDTO
    crops: CropDTO[]
    link: LinkDTO | undefined
    /** Users can select what crop they want displayed */
    userSelectedCrop?: KnownRatio
}

export interface LinkDTO {
    href: string
    title?: string
    target?: '_self' | '_blank'
}

export interface CropDTO {
    format?: string
    reference: string
    dimensions: string
    width: number
    height: number
    ratio: RatioDTO | KnownRatio
}

export type KnownRatio = '4:3' | '16:9' | '161:229' | '316:421'

export const KnownRatioToRatio: { [ratio in KnownRatio]: RatioDTO } = {
    '161:229': {
        width: 161,
        height: 229,
    },
    '16:9': {
        width: 16,
        height: 9,
    },
    '316:421': {
        width: 316,
        height: 421,
    },
    '4:3': {
        width: 4,
        height: 3,
    },
}
export const KnownRatioList: RatioDTO[] = Object.keys(KnownRatioToRatio).map(
    (ratio) => KnownRatioToRatio[ratio as KnownRatio],
)

export function simplifyIfKnownRatio(ratio: RatioDTO): KnownRatio | RatioDTO {
    if (ratio.width === 16 && ratio.height === 9) {
        return '16:9'
    }
    if (ratio.width === 4 && ratio.height === 3) {
        return '4:3'
    }
    if (ratio.width === 161 && ratio.height === 229) {
        return '161:229'
    }
    if (ratio.width === 316 && ratio.height === 421) {
        return '316:421'
    }
    return ratio
}

export interface RatioDTO {
    width: number
    height: number
}

export interface CopyrightCaption {
    copyrightCredit: string | null
    copyrightByline: string | null
    altText: string | null
    captionText: string
    byline: string | null
    credit: string | null
}

export type Alignment = 'left' | 'right' | 'center' | undefined
