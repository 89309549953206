import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getThemedSegment } from '../../home/get-themed-segment'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

export const getCommonwealthGamesRouteInfo: GetMainSectionFn = (
    sectionBuilder,
    store,
) => {
    return [
        getThemedSegment({
            featureToggle: 'commonwealth-games-sports-page-curation',
            layoutVersion: '7-card',
            curation: 'commonwealth-games',
            themeOverride: 'commonwealth-games',
            cardExtensionProps: {
                sevenCardExtension: {
                    kind: '7-card',
                    thorProps: {
                        hasBackgroundFill: true,
                        verticalGutters: false,
                        hideHeadingLink: true,
                    },
                    limaProps: {
                        verticalSpacing: 'unset',
                        hideBottomBorder: true,
                    },
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/commonwealth-games'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'md',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,

                loadMoreEnabled: true,
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['sport/commonwealth-games'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
