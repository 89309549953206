import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import {
    StyledOlympicWidgetWrap,
    StyledWidgetRow,
} from './OlympicsWidgetWrap.styled'
import { OlympicWidgetHeading } from '../OlympicsWidgetHeading/OlympicsWidgetHeading'
import { OlympicWidgetLinks } from '../OlympicsWidgetLinks/OlympicsWidgetLinks'

export type OlympicsWidgetSize = 'full' | 'embed' | 'small' | 'in-article'

export interface OlympicWidgetWrapProps {
    size: OlympicsWidgetSize
    children?: JSX.Element
    svgColourOverride?: string
    widgetTitle?: string
    hideLinks?: boolean
}
export function OlympicWidgetWrap(props: OlympicWidgetWrapProps) {
    const [pageType, setPageType] = useState<string>('')
    const location = useLocation()

    useEffect(() => {
        const currentPageType = location.pathname.split('/')[3]
        if (currentPageType !== undefined) {
            setPageType(currentPageType)
        }
    }, [location.pathname])

    const { size, children, svgColourOverride, widgetTitle, hideLinks } = props

    return (
        <StyledOlympicWidgetWrap size={size}>
            <section className="medalTally__heading-row">
                <OlympicWidgetHeading
                    size={size}
                    pageType={pageType}
                    svgColourOverride={svgColourOverride}
                    widgetTitle={widgetTitle}
                    hideLinks={hideLinks}
                />
            </section>

            <StyledWidgetRow size={size}>{children}</StyledWidgetRow>
            <section className="medalTally__link-row">
                <OlympicWidgetLinks size={size} pageType={pageType} />
            </section>
        </StyledOlympicWidgetWrap>
    )
}

OlympicWidgetWrap.displayName = 'OlympicWidgetWrap'
