import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

export interface ButtonProps {
    buttonWidth?: string
    isSentenceCase?: boolean
}

export const StyledButton = styled('button')<ButtonProps>(
    {
        background: 'transparent',
        fontSize: calcRem(14),
        textTransform: 'uppercase',
        fontWeight: 700,
        cursor: 'pointer',

        [breakpoint('xs')]: {
            flex: '0 0 auto',
        },

        '&:focus': {
            color: colors.white,
            outline: 'none',
        },

        '&:hover': {
            color: colors.white,
        },

        '&:disabled': {
            pointerEvents: 'none',
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    },
    ({ theme, buttonWidth }) =>
        theme.kind === 'thewest' && {
            background: theme.sectionValues.primaryColor,
            color: colors.white,
            fontFamily: fonts.thewest.sansSerif,
            padding:
                buttonWidth === 'wide'
                    ? calcRem(
                          metrics.thewest.margins.sm,
                          metrics.thewest.margins.lg,
                      )
                    : calcRem(metrics.thewest.margins.sm),
            borderRadius: '2px',
            border: 'none',
            flex: '0 0 auto',
            fontSize: calcRem(11),
            ...transition({ properties: ['background', 'color'] }),

            '&:focus': {
                background: colors.thewest.greyKoala,
            },

            '&:hover': {
                background: colors.thewest.greyGorilla,
            },

            marginBottom: buttonWidth === 'gallery' ? '16px' : '0',
            [breakpoint('xs')]: {
                marginBottom: buttonWidth === 'gallery' ? '0px' : '',
            },

            [breakpoint('xxs')]: {
                fontSize: calcRem(14),
            },
        },

    ({ theme }) =>
        theme.kind === 'perthnow' && {
            color: colors.perthnow.blueKyanite,
            fontFamily: fonts.perthnow.heading,
            padding: calcRem(metrics.perthnow.margins.sm, 0),
            border: `1px solid ${colors.perthnow.blueKyanite}`,
            width: calcRem(130),
            display: 'block',
            margin: '0 auto',
            ...transition({ properties: ['background', 'color', 'border'] }),

            [breakpoint('xs')]: {
                margin: 0,
            },

            '&:focus': {
                color: colors.white,
                background: colors.perthnow.pinkThulite,
                border: `1px solid ${colors.perthnow.pinkThulite}`,
            },

            '&:hover': {
                background: colors.perthnow.blueKyanite,
                border: `1px solid ${colors.perthnow.blueKyanite}`,
            },
        },

    ({ theme }) =>
        theme.kind === 'sevennews' && {
            background: themedValue(theme, {
                thewest: colors.black,
                perthnow: colors.black,
                sevennews: (section) => section.button.primaryColor,
                fallback: undefined,
            }),
            color: colors.white,
            fontFamily: fonts.sevennews.sansSerif,
            padding: calcRem(metrics.sevennews.margins.sm),
            borderRadius: calcRem(4),
            border: 'none',
            ...transition({ properties: ['background', 'color'] }),
            flex: '0 0 auto',
            fontSize: calcRem(15),
            textTransform: 'none',

            '&:focus': {
                background: themedValue(theme, {
                    thewest: colors.black,
                    perthnow: colors.black,
                    sevennews: (section) => section.button.hoverColor,
                    fallback: undefined,
                }),
                textDecoration: 'underline',
            },

            '&:hover': {
                background: themedValue(theme, {
                    thewest: colors.black,
                    perthnow: colors.black,
                    sevennews: (section) => section.button.hoverColor,
                    fallback: undefined,
                }),
            },
        },

    ({ theme, buttonWidth }) =>
        theme.kind === 'thenightly' && {
            background:
                tokens.thenightly.colors.actions.button.primary.background
                    .default,
            color: tokens.thenightly.colors.actions.button.primary.text.default,
            fontFamily: fonts.thenightly.sansSerif,
            fontWeight: 600,
            padding:
                buttonWidth === 'wide'
                    ? calcRem(
                          metrics.thenightly.margins.sm2l,
                          metrics.thenightly.margins.lg,
                      )
                    : calcRem(
                          metrics.thenightly.margins.sm2l,
                          metrics.thenightly.margins.m2l,
                      ),
            borderRadius: '8px',
            border: 'none',
            flex: '0 0 auto',
            fontSize: calcRem(16),
            ...transition({ properties: ['background', 'color'] }),

            '&:focus': {
                background:
                    tokens.thenightly.colors.actions.button.primary.background
                        .focus,
            },

            '&:hover': {
                background:
                    tokens.thenightly.colors.actions.button.primary.background
                        .hover,
            },

            marginBottom: buttonWidth === 'gallery' ? '16px' : '0',
            [breakpoint('xs')]: {
                marginBottom: buttonWidth === 'gallery' ? '0px' : '',
            },

            [breakpoint('xxs')]: {
                fontSize: calcRem(14),
            },
        },
)
