import {
    AllEvents,
    ArticleLikePublication,
    isArticle,
} from '@news-mono/web-common'
import React from 'react'
import {
    InlineContentTypes,
    InlineNightlyPromoBanner,
} from '../SharedPublication.routing'
import { TNPromoBanner } from '../../../banners/TNPromoBanner/TNPromoBanner'

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isTNPromoBanner(
    content: InlineContentTypes,
): content is InlineNightlyPromoBanner {
    return content.kind === 'inline-nightly-promo-banner'
}

export function getInlinedPositionedNightlyPromoBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (!isArticle(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isTNPromoBanner)
        .filter((content) => content.toggle)
        .map((content: InlineNightlyPromoBanner) => {
            return {
                position: content.insertAfter,
                element: <TNPromoBanner />,
            }
        })
}
