import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    themedValue,
} from '../../__styling'
import { tokens } from '@news-mono/design-tokens'
const { uplate, white } = tokens.thenightly.colors.palette
import { TheNightlyHorizontalLogo } from '../../logos/TheNightlyLogo/TheNightlyHorizontalLogo'

export const StyledTNPromoBannerContainer = styled.div(({ theme }) => ({
    clear: 'both',
    borderTop: `1px solid ${theme.colors.borders.primary}`,
    borderBottom: `1px solid ${theme.colors.borders.primary}`,
    marginBottom: themedValue(theme, {
        sevennews: calcRem(18),
        fallback: calcRem(40),
    }),
    padding: themedValue(theme, {
        sevennews: calcRem(24),
        fallback: calcRem(24, 0),
    }),
    display: 'flex',
    gap: themedValue(theme, {
        sevennews: calcRem(10),
        fallback: calcRem(24),
    }),
    flexDirection: 'column',
    backgroundColor: themedValue(theme, {
        sevennews: colors.sevennews.gray98,
        fallback: undefined,
    }),

    [breakpoint('xs')]: {
        padding: themedValue(theme, {
            sevennews: calcRem(24),
            fallback: calcRem(48, 0),
        }),
    },
}))

export const StyledHeading = styled.h2(({ theme }) => ({
    fontFamily: fonts.thenightly.sansSerif,
    fontSize: themedValue(theme, {
        sevennews: calcRem(20),
        fallback: calcRem(32),
    }),
    fontWeight: 600,
    lineHeight: themedValue(theme, {
        sevennews: 'normal',
        fallback: calcRem(40),
    }),
    letterSpacing: themedValue(theme, {
        sevennews: undefined,
        fallback: '-1.6px',
    }),
    color: tokens.thenightly.colors.text.primary,
    margin: 0,

    [breakpoint('xs')]: {
        fontSize: themedValue(theme, {
            sevennews: calcRem(26),
            fallback: calcRem(40),
        }),
        lineHeight: calcRem(48),
    },
}))

export const StyledTextContainer = styled.div(({ theme }) => ({
    display: themedValue(theme, {
        sevennews: 'block',
        fallback: 'flex',
    }),
    flexDirection: 'column',
    gap: calcRem(24),

    '& p': {
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            fallback: fonts.thenightly.serif,
        }),
        fontSize: themedValue(theme, {
            sevennews: calcRem(16),
            fallback: calcRem(22),
        }),
        color: themedValue(theme, {
            sevennews: tokens.sevennews.colors.palette.charade,
            fallback: tokens.thenightly.colors.text.tertiary,
        }),
        margin: 0,
        fontWeight: themedValue(theme, {
            sevennews: 300,
            fallback: undefined,
        }),

        [breakpoint('xs')]: {
            maxWidth: '75%',
            fontSize: themedValue(theme, {
                sevennews: calcRem(16),
                fallback: calcRem(24),
            }),
        },
    },

    [breakpoint('xs')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    },
}))

export const StyledButton = styled(WebLink)(({ theme }) => ({
    background: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.black,
        fallback: uplate.default,
    }),
    color: white,
    textDecoration: 'unset',
    display: themedValue(theme, {
        sevennews: 'inline-block',
        fallback: 'grid',
    }),
    placeItems: 'center',
    padding: themedValue(theme, {
        sevennews: calcRem(8, 40),
        fallback: calcRem(12, 24),
    }),
    borderRadius: themedValue(theme, {
        sevennews: calcRem(4),
        fallback: calcRem(8),
    }),
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        fallback: fonts.thenightly.sansSerif,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(14),
        fallback: calcRem(16),
    }),
    fontWeight: 600,
    lineHeight: themedValue(theme, {
        sevennews: calcRem(16),
        fallback: calcRem(24),
    }),
    textTransform: themedValue(theme, {
        sevennews: 'none',
        fallback: 'uppercase',
    }),
    textWrap: 'nowrap',
    marginRight: themedValue(theme, {
        sevennews: calcRem(16),
        fallback: undefined,
    }),
    marginTop: themedValue(theme, {
        sevennews: calcRem(16),
        fallback: undefined,
    }),

    '&:hover': {
        opacity: 0.8,
    },
    transition: 'opacity 0.25s',

    [breakpoint('xs')]: {
        marginTop: 0,
    },
}))

export const StyledTheNightlyLogo = styled(TheNightlyHorizontalLogo)(
    ({ theme }) => ({
        display: 'block',
        left: themedValue(theme, {
            sevennews: calcRem(-4),
            fallback: undefined,
        }),
        position: themedValue(theme, {
            sevennews: 'relative',
            fallback: undefined,
        }),
    }),
)
