import { ListBlock, SubheadBlock } from '../client-data-types/blocks'
import { createHeading } from '../client/helpers/block-helpers'
import { createDiagExample } from '../routing/createDiagExample'
import { PageRouteInformation, StaticRoutes } from '../routing/page-definition'

export const diagFailResolveRoute = (path: string) => {
    // matched path so regionals work / sevennews sections work
    if (new RegExp(`${errorRoutes.routeInfoFailed.route}$`).test(path)) {
        throw new Error('resolveRoute test failure')
    }
    if (new RegExp(`${errorRoutes.routeInfoFailed400.route}$`).test(path)) {
        throw new Error('400')
    }
    return
}

export const errorRoutes = {
    missingPage: {
        route: '/diag/404',
        info: 'Example of when a page cannot be found',
    },
    missingData: {
        route: '/diag/content-404',
        info: 'Example of when a page cannot load data',
    },
    dataLoaderError: {
        route: '/diag/500_component-data-loader-error',
        info: 'Example of when a component fails to load data',
    },
    componentRenderError: {
        route: '/diag/500_component-render-error',
        info: 'Example of when a page fails to render due to component error (React)',
    },
    routeInfoFailed: {
        route: '/diag/500_route-info-resolver-failure',
        info: 'Example of when the route info resolver fails (ie. content api calls fail)',
    },
    routeInfoFailed400: {
        route: '/diag/400_route-info-resolver-failure',
        info: 'Example when url causes a content API bad request',
    },
}

const missingDataErrorPage: PageRouteInformation<string> = {
    kind: 'page',
    heading: 'Renders failure text, no data loaded',
    pageType: 'static',
    section: 'default',
    adTargeting: {
        pageId: 'fail-data-load',
        adUnitPath: '',
        ssAdUnits: [],
        topics: [],
    },
    compositions: [
        {
            type: 'box',
            props: {},
            contentAreas: {
                main: [
                    {
                        type: 'fail-load-content-404',
                        props: {
                            dataDefinitionArgs: {},
                        },
                    },
                ],
            },
        } as any,
    ],
}

const dataLoaderErrorPage: PageRouteInformation<string> = {
    kind: 'page',
    heading: 'Renders failure text, no data loaded',
    pageType: 'static',
    section: 'default',
    adTargeting: {
        pageId: 'fail-data-load',
        adUnitPath: '',
        ssAdUnits: [],
        topics: [],
    },
    compositions: [
        {
            type: 'box',
            props: {},
            contentAreas: {
                main: [
                    {
                        type: 'fail-load-content', // renders failure text
                        props: {
                            dataDefinitionArgs: {},
                        },
                    },
                ],
            },
        } as any,
    ],
}

const componentRenderErrorPage: PageRouteInformation<string> = {
    kind: 'page',
    heading: 'Failed to render page',
    pageType: 'static',
    section: 'default',
    adTargeting: {
        pageId: 'failed-render',
        adUnitPath: '',
        ssAdUnits: [],
        topics: [],
    },
    compositions: [
        {
            type: 'box',
            props: {},
            contentAreas: {
                main: [
                    {
                        type: 'component-renderer-error-test',
                        props: {},
                    },
                ],
            },
        } as any,
    ],
}

/** You need to register `fail-data-load`, `fail-data-load-404` and `component-renderer-error-test` components */
// Used for regionals to match diag pages
export const matchedErrorRoutePages: StaticRoutes<any, any> = {
    [`${errorRoutes.missingData.route}$`]: () => missingDataErrorPage,
    [`${errorRoutes.dataLoaderError.route}$`]: () => dataLoaderErrorPage,
    [`${errorRoutes.componentRenderError.route}$`]: () =>
        componentRenderErrorPage,
}

const filterErrorRoutes = (type: '404' | '!404') => {
    return Object.keys(errorRoutes).filter((routeKey) => {
        const route = (errorRoutes as any)[routeKey].route
        if (type === '404') {
            return route.indexOf('404') > -1
        }
        return route.indexOf('404') === -1
    })
}

// Make it relative for the page, to allow it to work with sectioned pages
// Cant be relative for tests
function makeRelativeLink(routeLink: string) {
    if (routeLink.indexOf('/') === 0 && process.env.NODE_ENV !== 'test') {
        return routeLink.substr(1, routeLink.length - 1)
    }
    return routeLink
}

// Error Routes list split up by expected errors
export const errorRoutesList: Array<ListBlock | SubheadBlock> = [
    createHeading('Page Not Found Errors'),
    {
        kind: 'ordered-list',
        items: [
            {
                kind: 'list-item',
                blocks: [
                    ...filterErrorRoutes('404').map((routeKey) =>
                        createDiagExample(
                            makeRelativeLink(
                                (errorRoutes as any)[routeKey].route,
                            ),
                            (errorRoutes as any)[routeKey].info,
                        ),
                    ),
                ],
            },
        ],
    },
    createHeading('Something Went Wrong Errors'),
    {
        kind: 'ordered-list',
        items: [
            {
                kind: 'list-item',
                blocks: [
                    ...filterErrorRoutes('!404').map((routeKey) =>
                        createDiagExample(
                            makeRelativeLink(
                                (errorRoutes as any)[routeKey].route,
                            ),
                            (errorRoutes as any)[routeKey].info,
                        ),
                    ),
                ],
            },
        ],
    },
]
