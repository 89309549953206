export const getFacebookOnClick =
    (url: string, text: string, fbAppId: string) => () => {
        const fbUrl =
            `https://www.facebook.com/dialog/share?app_id=${fbAppId}` +
            `&display=popup&caption=${text}` +
            `&href=${encodeURIComponent(url)}`
        const options = 'toolbar=0,status=0,resizable=1,width=626,height=436'

        const fbWindow = window.open('', 'sharer', options)
        if (fbWindow) {
            fbWindow.opener = null // noopener equivalent
            fbWindow.location.href = fbUrl
        }
    }
