import styled from '@emotion/styled'
import { TextItem } from '../../typography/TextItem/TextItem'
import { colors } from '../../__styling/settings/colors'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { tokens } from '@news-mono/design-tokens'

export const StyledSubhead = styled(TextItem)(
    {
        ...breakWords,
        fontWeight: 700 as const,
        lineHeight: 1,
        clear: 'both',
    },
    ({ theme }) => ({
        fontFamily: theme.fonts.subheading,

        fontSize: themedValue(theme, {
            thewest: calcRem(18),
            perthnow: calcRem(22),
            sevennews: calcRem(26),
            thenightly: calcRem(20),
            fallback: undefined,
        }),
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.greyCoal,
            sevennews: undefined,
            thenightly: tokens.thenightly.colors.text.heading,
            fallback: undefined,
        }),
        textTransform: themedValue(theme, {
            thewest: 'none' as const,
            perthnow: 'uppercase' as const,
            sevennews: undefined,
            fallback: undefined,
        }),
        marginTop: themedValue(theme, {
            thewest: calcRem(32),
            perthnow: undefined,
            sevennews: undefined,
            fallback: undefined,
        }),
        marginBottom: themedValue(theme, {
            thewest: undefined,
            perthnow: calcEm(5),
            sevennews: undefined,
            fallback: undefined,
        }),
    }),
)
