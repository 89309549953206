import React from 'react'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import {
    StyledSkeletonCard,
    StyledSkeletonMedia,
    StyledSkeletonText,
    StyledSkeletonTimeToRead,
} from './SkeletonCard.styled'
import { Skeleton } from '../../../content'

export interface SkeletonCardProps extends ResponsiveContainer {
    textLines?: number
    showTimeToRead?: boolean
    gridAreaName?: string
    overrideWidth?: number
    overrideHeight?: number
    isVerticalCard?: boolean
    isHomeHeroCard?: boolean
}

export const SkeletonCard: React.FC<SkeletonCardProps> = ({
    textLines,
    gridAreaName,
    overrideHeight,
    overrideWidth,
    isVerticalCard,
    isHomeHeroCard,
}) => {
    return (
        <StyledSkeletonCard
            gridAreaName={gridAreaName}
            overrideHeight={overrideHeight}
            overrideWidth={overrideWidth}
            isVerticalCard={isVerticalCard}
            isHomeHeroCard={isHomeHeroCard}
        >
            <StyledSkeletonText isVerticalCard={isVerticalCard}>
                <Skeleton count={textLines || 2} height={23} />
                <StyledSkeletonTimeToRead>
                    <Skeleton count={1} />
                </StyledSkeletonTimeToRead>
            </StyledSkeletonText>
            <StyledSkeletonMedia isVerticalCard={isVerticalCard}>
                <Skeleton count={1} />
            </StyledSkeletonMedia>
        </StyledSkeletonCard>
    )
}
