import { RedirectRouteInformation } from '../routing/page-definition'

export const getRedirectRouteInfo = (to: string): RedirectRouteInformation => {
    return {
        kind: 'redirect',
        redirectTo: {
            targetUrl: to,
        },
    }
}
