import { layout } from '../../App.routing'

export const indigenousAffairsSegment = layout.nestedComposition({
    type: 'janus',
    props: {
        layoutRatio: '1:3',
        stretchColumns: true,
        verticalSpacing: 'xl',
    },
    contentAreas: {
        left: [
            layout.component({
                type: 'section-header',
                props: {
                    heading: 'Promotional',
                    hasBottomMargin: true,
                    invisibleHeaderBreakpoint: 'md',
                },
            }),

            layout.component({
                type: 'curated-promotional-card',
                props: {
                    fixedRatio: '4:3',
                    hasBackground: true,
                    hasPadding: true,
                    mediaMode: 'edgeToEdge',
                    fontScale: 0.88,
                    showCTA: true,
                    cardOrientation: {
                        defaultLayout: 'landscape',
                        alternativeLayoutOptions: {
                            breakpoint: 'md',
                            layout: 'portrait',
                        },
                    },
                    verticalSpacing: 'md',
                    dataDefinitionArgs: {
                        type: 'active-promotion',
                        promotionSlotId: 'homepage-position-3',
                    },
                },
            }),
        ],
        right: [
            layout.component({
                type: 'lima',
                props: {
                    fixedRatios: ['16:9'],
                    intermediateColumns: 3,
                    finalColumns: 3,
                    teaserMode: 'hidden',
                    hideByline: false,
                    hasBackground: true,
                    cardOrientation: { type: 'Landscape-Portrait' },

                    cardLayout: [
                        ['teaserWithBackground'],
                        ['teaserWithBackground'],
                        ['teaserWithBackground'],
                    ],
                    sectionHeader: {
                        heading: 'Indigenous Affairs',
                        headingUrl: '/news/indigenous-australians',
                    },
                    dataDefinitionArgs: {
                        type: 'curation',
                        name: 'indigenous-affairs',
                        offset: 0,
                        pageSize: 3,
                    },
                },
            }),
        ],
    },
})
