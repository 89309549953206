import { TheWestSection } from '@news-mono/common'
import {
    salesforceRedirectPath,
    StaticRoute,
    StaticRoutes,
} from '@news-mono/web-common'

const createEmailPreferencePage: StaticRoute<TheWestSection> = ({
    config,
    location,
}) => {
    // redirect to a server-side only route to generate a salesforce JWT for logged in users
    return {
        kind: 'redirect',
        redirectTo: {
            httpStatusCode: 302,
            targetUrl: `${config.publicUrl}${salesforceRedirectPath}${location.search}`,
        },
    }
}

export const getEmailPreferenceRouteInfo: StaticRoutes<TheWestSection> = {
    '/manage-email-preferences': createEmailPreferencePage,
}
