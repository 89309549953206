import {
    CollectionDataLoaded,
    ComponentServices,
    createRegisterableComponentWithData,
    DataLayerEventName,
    DataLoaderGlobalParams,
    getSearchPublications,
    ListingCollectionDataLoaded,
    mapListPublication,
    TopicListingOptions,
} from '@news-mono/web-common'
import subMonths from 'date-fns/subMonths'
import { DataDefinition } from 'json-react-layouts-data-loader'
import React from 'react'
import { SearchResults } from './SearchResults'
import { Paging } from '../../data-controllers/Pagination/usePagination'
import { getPublishedSince } from '../../result-filters'
import { getSortOrder } from '../../result-filters'
import { useQueryParams } from '../../../../web-common/src/hooks/useQueryParams'
import { getSearchEventArgs } from '../../result-filters/filter-events-util'

export interface SearchRouteProps {
    searchTerm: string
}

export interface SearchDataLoaderConfig {
    searchTerm: string
    timeFilter?: string
    sortBy?: string
    topic?: string
    masthead?: string
    options: TopicListingOptions
}

const loadSearchData = async (
    props: SearchDataLoaderConfig,
    services: DataLoaderGlobalParams,
): Promise<CollectionDataLoaded> => {
    if (!props.searchTerm) {
        return {
            loadMorePossible: false,
            kind: 'listing',
            publications: [],
            total: 0,
        }
    }

    let publishedSince: string | undefined = undefined
    let sort: string | undefined = 'desc'
    let topics: string[] = []

    if (props.masthead === 'thenightly') {
        publishedSince = getPublishedSince(props.timeFilter)
        sort = getSortOrder(props.sortBy)
        if (props.topic) {
            topics = props.topic.split(',')
        }
    }

    const searchedPublications = await getSearchPublications(
        services,
        props.searchTerm,
        {
            ...props.options,
            publishedSince,
            paging: props.options.paging ?? { pageSize: 20 },
            sort,
            topics,
        },
    )
    const mappedListing = searchedPublications.documents.map(
        (listPublication) => mapListPublication(listPublication),
    )
    return {
        loadMorePossible: searchedPublications.morePublicationsAvailable,
        kind: 'listing',
        publications: mappedListing,
        total: searchedPublications.total,
    }
}

const searchDataDefinitionLoader: DataDefinition<
    SearchDataLoaderConfig,
    CollectionDataLoaded,
    ComponentServices
> = {
    // Requires custom cache key so search terms will update when searching on the search page. Search.
    useRuntimeParams: (props) => {
        return {
            searchTerm: JSON.stringify(props.searchTerm),
            timeFilter: props.timeFilter,
            sortBy: props.sortBy,
            topics: props.topic,
            masthead: props.masthead,
        }
    },
    loadData: (props, services) => loadSearchData(props, services),
}

export const SearchRegistration = createRegisterableComponentWithData(
    'search',
    searchDataDefinitionLoader,
    (props: SearchRouteProps, data, services) => {
        const { queryParams } = useQueryParams()
        const setPage = (page: number) => {
            if (data.dataDefinitionArgs?.options.paging) {
                data.dataDefinitionArgs.options.paging.page = page
            }
        }

        if (data.loaded) {
            services.onEvent({
                type: DataLayerEventName.searchResults,
                originator: 'SearchResults',
                payload: {
                    ...getSearchEventArgs(props.searchTerm, queryParams),
                    totalResults:
                        (data.result as ListingCollectionDataLoaded).total ?? 0,
                },
            })

            return (
                <SearchResults
                    onEvent={services.onEvent}
                    results={data.result}
                    paging={data.dataDefinitionArgs?.options.paging as Paging}
                    setPage={setPage}
                    searchTerm={props.searchTerm}
                />
            )
        }
        return null
    },
)
