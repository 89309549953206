import { Request } from 'express'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { retrieveCookie, storeCookie } from '../cookies/cookies'
import { CountryCode } from './country-codes'
import { AustralianMarketCodes } from './location-detection'

export type SelectedRegion = AustralianMarketCodes | undefined

export interface LocationState {
    countryCode: CountryCode | undefined
    region: string | undefined
    userSelectedRegion: SelectedRegion
}

export interface RegionState {
    userSelectedRegion: AustralianMarketCodes
}

export interface RegionCookieState {
    region: SelectedRegion
}

export const LOAD_LOCATION = 'LOAD_LOCATION'
export const LOAD_USER_LOCATION = 'LOAD_USER_LOCATION'

export interface LOAD_LOCATION extends Action {
    type: typeof LOAD_LOCATION
    payload: LocationState
}

export interface LOAD_USER_LOCATION extends Action {
    type: typeof LOAD_USER_LOCATION
    payload: RegionState
}

export const loadLocation = (locationState: LocationState): LOAD_LOCATION => ({
    type: LOAD_LOCATION,
    payload: locationState,
})

export const userLocationKey = 'userLocation'
const expires = 1 // Days until cookie expires

export const loadUserLocation =
    (region: RegionState): ThunkAction<void, any, any, LOAD_USER_LOCATION> =>
    (dispatch) => {
        storeCookie<RegionCookieState>(
            userLocationKey,
            { region: region.userSelectedRegion },
            { expires },
        )

        dispatch({
            type: LOAD_USER_LOCATION,
            payload: region,
        })
    }

export type LocationActions = LOAD_LOCATION | LOAD_USER_LOCATION

const defaultState: LocationState = {
    countryCode: undefined,
    region: undefined,
    userSelectedRegion: 'NSW',
}

export const locationReducer = (
    state: LocationState = defaultState,
    action: Action,
): LocationState => {
    switch (action.type) {
        case LOAD_LOCATION: {
            const loadUserAction = action as LOAD_LOCATION
            return { ...state, ...loadUserAction.payload }
        }
        case LOAD_USER_LOCATION: {
            const loadUserLocationAction = action as LOAD_USER_LOCATION
            const { userSelectedRegion } = loadUserLocationAction.payload
            return { ...state, userSelectedRegion }
        }
        default:
            return state
    }
}

export const checkLocationCookie = (req: Request): SelectedRegion => {
    const cookie = retrieveCookie<RegionCookieState>(userLocationKey, req)
    return cookie?.region
}
