/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react'
import { ImageSourceWithCaptions } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import {
    StyledCount,
    StyledDescription,
    StyledDescriptionAuthor,
    StyledGalleryItem,
    StyledImageWrap,
    StyledText,
} from '../../../../content/Gallery/components/GalleryItem/GalleryItem.styled'
import { ResponsivePicture } from '../../../../content/Picture/Picture'
import { PicturePlaceholder } from '../../../../content/Picture/PicturePlaceholder'
import { ResponsivePictureSizes } from '../../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../../content/Picture/ResponsivePictureLayouts'
import { Skeleton } from '../../../../content/Skeleton/Skeleton'
import { colors } from '../../../../__styling/settings/colors'
import { themedValue } from '../../../../__styling/themed-value'

export interface GalleryItemProps {
    image: MaybeLoaded<ImageSourceWithCaptions>
    id: string
    count: string
}

function getImageEl(props: GalleryItemProps & { theme: Theme }) {
    const maxWidth = themedValue(props.theme, {
        perthnow: 736,
        thewest: 860,
        sevennews: 650,
        fallback: 860,
    })

    const imageWidths: ResponsivePictureSizes = {
        mobile: '100vw',
        tablet: '100vw',
        desktop: `${maxWidth}px`,
        fallbackWidth: maxWidth,
    }

    if (!props.image.loaded) {
        return <PicturePlaceholder fixedRatio="16:9" image={undefined} />
    }

    if (props.theme.kind === 'perthnow') {
        return (
            <ResponsivePicture
                css={{
                    background: colors.perthnow.greyBasalt,
                    '& img': {
                        height: '100%',
                        margin: 'auto',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                }}
                layout={ResponsivePictureLayout.None}
                image={props.image.result}
                fixedRatio="16:9"
                allowOriginalCrop
                imageWidths={imageWidths}
            />
        )
    }

    return (
        <ResponsivePicture
            layout={ResponsivePictureLayout.ObjectFitContain}
            image={props.image.result}
            fixedRatio="original"
            imageWidths={imageWidths}
        />
    )
}

const Description: React.FC<{
    image: ImageSourceWithCaptions
    themeKind: string
}> = ({ image, themeKind }) => {
    if (themeKind === 'sevennews') {
        return (
            <React.Fragment>
                {image.captionText}
                <StyledDescriptionAuthor>
                    {image.byline || image.credit ? ' Picture: ' : null}
                    {image.byline}
                    {image.byline && image.credit && <span>&#47;</span>}
                    {image.credit}
                </StyledDescriptionAuthor>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {image.captionText}
            {image.byline || image.credit ? ' Picture: ' : null}
            {image.byline}
            {image.byline && image.credit && <span>&#47;</span>}
            {image.credit}
        </React.Fragment>
    )
}

export const GalleryItem: React.FC<GalleryItemProps> = (props) => {
    const theme = useTheme()

    return (
        <StyledGalleryItem id={props.id}>
            <StyledImageWrap>{getImageEl({ ...props, theme })}</StyledImageWrap>
            <StyledText>
                <StyledCount>{props.count}</StyledCount>
                <StyledDescription>
                    {!props.image.loaded ? (
                        <Skeleton count={6} />
                    ) : (
                        <Description
                            image={props.image.result}
                            themeKind={theme.kind}
                        />
                    )}
                </StyledDescription>
            </StyledText>
        </StyledGalleryItem>
    )
}
GalleryItem.displayName = 'GalleryItem'
