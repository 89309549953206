import { createRegisterableComponent, Topic } from '@news-mono/web-common'
import React from 'react'
import { SponsoredTopicProps } from '../../advertising/SponsoredTopic/SponsoredTopic'
import { Breadcrumb, LinkProps } from '../../navigation/Breadcrumb/Breadcrumb'
import { ThemeMargins } from '../../__styling/settings/metrics'

export interface BreadcrumbTopicRouteProps {
    topic?: Topic
    profile?: string
    title?: string
    items?: LinkProps[]
    enableHeaderTag?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    horizontalGutters?: keyof ThemeMargins | undefined
}

export type BreadcrumbRouteProps = BreadcrumbTopicRouteProps &
    SponsoredTopicProps
export const BreadcrumbRegistration = createRegisterableComponent(
    'breadcrumb',
    (props: BreadcrumbRouteProps) => {
        const items = []
        const sponsor = props.sponsor

        if (props.topic) {
            const topic = props.topic
            const parent = topic.parent

            if (parent) {
                items.push({
                    text: parent.title,
                    href: `/${parent.id}`,
                    sponsorLink: parent.sponsorLogo,
                })
            }
            items.push({
                text: topic.title,
                href: `/${topic.id}`,
                sponsorLink: topic.sponsorLogo,
            })

            return (
                <Breadcrumb
                    enableHeaderTag={props.enableHeaderTag}
                    items={items}
                    sponsor={sponsor}
                    verticalSpacing={props.verticalSpacing}
                    horizontalGutters={props.horizontalGutters}
                />
            )
        }

        if (props.profile) {
            items.push({ text: 'Profile' })
            items.push({ text: props.profile })

            return (
                <Breadcrumb
                    items={items}
                    sponsor={sponsor}
                    verticalSpacing={props.verticalSpacing}
                    horizontalGutters={props.horizontalGutters}
                />
            )
        }

        if (props.items) {
            return (
                <Breadcrumb
                    items={props.items}
                    enableHeaderTag={props.enableHeaderTag ?? true}
                    verticalSpacing={props.verticalSpacing}
                    horizontalGutters={props.horizontalGutters}
                />
            )
        }

        if (props.title) {
            return (
                <Breadcrumb
                    items={[{ text: props.title }]}
                    enableHeaderTag={props.enableHeaderTag ?? true}
                    verticalSpacing={props.verticalSpacing}
                    horizontalGutters={props.horizontalGutters}
                />
            )
        }

        return null
    },
)
