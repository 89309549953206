const RETRY_INTERVAL = 1000

export const initRealEstateViewWidgetWithRetry = () => {
    const intervalId = setInterval(() => {
        if (typeof window !== 'undefined' && window.mw) {
            try {
                window.mw('init', { elementId: 'rev-widget-el' })
                clearInterval(intervalId)
            } catch (err) {
                console.error('Failed to start REV widget', err)
            }
        }
    }, RETRY_INTERVAL)
}
