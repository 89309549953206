import {
    DataLayerEventName,
    searchCrawlerMastheadWhitelist,
} from '@news-mono/web-common'
import React from 'react'
import { StyledTextLink } from '../../typography/TextLink/TextLink.styled'
import { TextLinkProps } from './TextLink'

export class WebTextLink extends React.Component<TextLinkProps, {}> {
    static displayName = 'WebTextLink'

    render() {
        const {
            href,
            className,
            dataLinkType,
            isNoFollow,
            openInNewWindow,
            onEvent,
            type,
        } = this.props

        const isCrawlerWhitelisted = searchCrawlerMastheadWhitelist(href)

        const rel = isNoFollow
            ? 'nofollow'
            : openInNewWindow || !isCrawlerWhitelisted
            ? 'noopener'
            : undefined

        return (
            <StyledTextLink
                to={href}
                className={className}
                type={type}
                data-link-type={dataLinkType}
                target={openInNewWindow ? '_blank' : undefined}
                rel={rel}
                onClick={() => {
                    if (onEvent) {
                        const text =
                            typeof this.props.children === 'string'
                                ? this.props.children
                                : 'ReactElement'

                        onEvent({
                            type: DataLayerEventName.linkClicked,
                            originator: 'TextLink',
                            payload: {
                                text,
                                linkType: dataLinkType,
                                opensInNewWindow: !!openInNewWindow,
                                url: href,
                            },
                        })
                    }
                }}
            >
                {this.props.children}
            </StyledTextLink>
        )
    }
}
