import { RequiredAccessDTO } from '@west-australian-newspapers/publication-types'
import debug from 'debug'
import { RouteServices } from '../routing/page-definition'

export const sophiDebug = debug('sophi')

interface SophiPageContentData {
    page: SophiPageData
    content: SophiContentData
}

export function getSophiContent(
    routeServices: RouteServices,
): SophiDataType | undefined {
    sophiDebug('Creating sophi payload')
    const environment = 'prod'
    const resolution = routeServices.resolution

    if (resolution.type === 'server') {
        const resolved = resolution.resolution

        if (resolved.type === 'publication') {
            const publication = resolved.publication

            const sectionName = publication.topics.primary.parent
                ? publication.topics.primary.parent?.id
                : publication.topics.primary.id

            sophiDebug('Resolved article payload')
            return {
                data: {
                    page: {
                        type: 'article',
                        datePublished: publication.publicationDate,
                        breadcrumb: mapSophiBreadcrumb(
                            publication.topics.primary.id,
                        ),
                        sectionName,
                    },
                    content: {
                        accessCategory: mapAccessCategory(
                            publication.requiredAccess,
                        ),
                        contentId: publication.id,
                        type: publication.kind,
                    },
                    environment: {
                        environment,
                        version: routeServices.appVersion,
                    },
                },
            }
        }

        if (resolved.type === 'topic') {
            const topic = resolved.topic

            if (!topic) {
                return
            }

            const mappedTopicData = getTopicRouteData(topic.id, topic.title)

            sophiDebug('Resolved topic payload')
            return {
                data: {
                    ...mappedTopicData,
                    environment: {
                        environment,
                        version: routeServices.appVersion,
                    },
                },
            }
        }
    }

    if (resolution.type === 'static') {
        const topic = resolution.key

        const title = topic.substring(topic.lastIndexOf('/') + 1, topic.length)

        const mappedTopicData = getTopicRouteData(topic, title)

        sophiDebug('Resolved static payload')
        return {
            data: {
                ...mappedTopicData,
                environment: {
                    environment,
                    version: routeServices.appVersion,
                },
            },
        }
    }

    return undefined
}

export function mapSophiBreadcrumb(topicId: string) {
    if (topicId.charAt(0) === '/') {
        topicId = topicId.slice(1)
    }
    return topicId.replace('/', ':')
}

function getTopicRouteData(
    breadcrumb: string,
    sectionName: string,
): SophiPageContentData {
    const isProbablyHomePage = breadcrumb === '' && sectionName === ''

    sophiDebug('Creating topic payload')
    return {
        page: {
            type: 'section',
            breadcrumb: mapSophiBreadcrumb(breadcrumb),
            sectionName: isProbablyHomePage
                ? 'home'
                : sectionName.toLowerCase(),
        },
        content: {
            accessCategory: undefined,
            contentId: undefined,
            type: undefined,
        },
    }
}

function mapAccessCategory(
    type: RequiredAccessDTO,
): 'free access' | 'subscribers only' {
    switch (type.level) {
        case 'subscriber':
            return 'subscribers only'

        default:
            return 'free access'
    }
}
