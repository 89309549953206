import styled from '@emotion/styled'
import { TextItem } from '../../../../../typography/TextItem/TextItem'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../../__styling/style-mixins'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'

export const StyledPNParagraph = styled(TextItem)(() => ({
    margin: calcRem(metricsPN.spacing.L),
    ...breakWords,
    ...perthNowNGNTextMixin.body.L.regular,

    a: {
        ...perthNowNGNTextMixin.body.L.semibold,
        textDecoration: 'none',
        color: colorsPN.text.brand,
    },
}))
