import { useTheme } from '@emotion/react'
import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import format from 'date-fns/format'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    LandscapeStyledSharing,
    StyledLandscapeWide,
} from '../../cards/LandscapeWide/LandscapeWide.styled'
import {
    StyledHeader,
    StyledHeadline,
} from '../../cards/LandscapeWide/Styled/StyledHeader.styled'
import {
    StyledMedia,
    StyledTime,
    StyledTimestampBlock,
} from '../../cards/LandscapeWide/Styled/StyledMedia.styled'
import {
    StyledFundingLabel,
    StyledSponsoredByline,
    StyledTeaser,
    StyledText,
    StyledWrapper,
} from '../../cards/LandscapeWide/Styled/StyledText.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { getEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { themedValue } from '../../__styling/themed-value'
import { MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'
import { KickerMode } from '../Kicker/Kicker'
import { StyledHeadlineSkeleton } from '../LandscapeStacked/LandscapeStacked.styled'

export interface LandscapeWideProps extends CommonCardProps {
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    mediaMode?: MediaMode
    teaserMode: TeaserMode
    kickerMode?: KickerMode
    hasBackground?: boolean
    timestamp?: TimestampType
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem>
}

export const LandscapeWide: React.FC<LandscapeWideProps> = (props) => {
    const theme = useTheme()

    const storyClassification = true
    const fundingType = props.item.loaded
        ? getFundingType(props.item.result, storyClassification)
        : undefined
    const editorialType = props.item.loaded
        ? getEditorialType(props.item.result, storyClassification)
        : undefined

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.item.loaded) {
            e.preventDefault()
            return
        }

        props.onEvent(
            createCardClickedEvent(
                props.onEvent,
                props.item.result,
                'InternalLandscapeWide',
                'LandscapeWide',
                props.cardNumber,
                fundingType,
            ),
        )
    }

    const renderImageOrTimestamp = () => {
        // Never show timestamps when the item is funded, but it has preference over showing the image
        if (
            props.timestamp === 'list-style' &&
            props.item.loaded &&
            !fundingType &&
            props.item.result.publicationDate
        ) {
            const humanReadableTimestamp = format(
                new Date(props.item.result.publicationDate),
                'h:mma',
            )
            return (
                <StyledTimestampBlock>
                    <StyledTime>{humanReadableTimestamp}</StyledTime>
                </StyledTimestampBlock>
            )
        }

        return (
            <StyledMedia
                item={props.item}
                fixedRatio={props.fixedRatio || '4:3'}
                imageWidths={themedValue(theme, {
                    thewest: {
                        mobile: '110px',
                        tablet: '110px',
                        desktop: '110px',
                        fallbackWidth: 110,
                        mode: 'fixed',
                    },
                    perthnow: {
                        mobile: '106px',
                        tablet: '106px',
                        desktop: '106px',
                        fallbackWidth: 106,
                        mode: 'fixed',
                    },
                    sevennews: {
                        mobile: '126px',
                        tablet: '126px',
                        desktop: '126px',
                        fallbackWidth: 126,
                        mode: 'fixed',
                    },
                    fallback: {
                        mobile: '110px',
                        tablet: '110px',
                        desktop: '110px',
                        fallbackWidth: 110,
                        mode: 'fixed',
                    },
                })}
                onEvent={props.onEvent}
                disableImageLazyLoad={props.disableImageLazyLoad}
                hideMediaIcon={true}
                mediaMode={props.mediaMode}
            />
        )
    }

    const renderCardText = () => {
        if (!props.item.loaded) {
            return (
                <StyledTeaser
                    teaserOrSkeletonLines={3}
                    hasBackground={props.hasBackground}
                    teaserMode={props.teaserMode}
                />
            )
        }

        if (fundingType) {
            return (
                <StyledText>
                    <StyledTeaser
                        teaserOrSkeletonLines={props.item.result.teaser}
                        hasBackground={props.hasBackground}
                        teaserMode={props.teaserMode}
                    />
                    <StyledFundingLabel fundingType={fundingType} />
                    <StyledSponsoredByline>
                        {props.item.result.byline}
                    </StyledSponsoredByline>
                </StyledText>
            )
        }

        return (
            <StyledTeaser
                teaserOrSkeletonLines={props.item.result.teaser}
                hasBackground={props.hasBackground}
                teaserMode={props.teaserMode}
            />
        )
    }

    const { className, hasBackground } = props

    const cardItem = props.item

    return (
        <StyledLandscapeWide
            className={className}
            data-topic={props.item.loaded && props.item.result.primaryTopic.id}
        >
            <StyledHeader
                to={toLinkWithHint(props.item)}
                onClick={linkClicked}
                isLoading={!props.item.loaded}
            >
                {cardItem.loaded ? (
                    <StyledHeadline
                        headlineText={cardItem.result.shortHeadline}
                        hasBackground={hasBackground}
                        kickerMode="hidden"
                        fontScale={1.2}
                        isSponsored={!!fundingType}
                        publicationKind={cardItem.result.publicationKind}
                        editorialType={editorialType}
                    />
                ) : (
                    <StyledHeadlineSkeleton
                        hasBackground={hasBackground}
                        fontScale={1.2}
                    />
                )}
            </StyledHeader>
            <StyledWrapper
                to={toLinkWithHint(props.item)}
                onClick={linkClicked}
                isLoading={!props.item.loaded}
            >
                {renderImageOrTimestamp()}
                {renderCardText()}
            </StyledWrapper>

            <LandscapeStyledSharing
                loading={!props.item.loaded}
                url={!props.item.loaded ? '' : props.item.result._self}
                text={!props.item.loaded ? '' : props.item.result.shortHeadline}
                onEvent={props.onEvent}
                isGhostStyle={true}
                shareOptions={['facebook', 'twitter', 'email', 'clipboard']}
            />
        </StyledLandscapeWide>
    )
}
LandscapeWide.displayName = 'LandscapeWide'
