import styled, { StyledComponent } from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import React from 'react'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { Theme } from '../../__styling/themes'

export type PixelOffset = {
    // in %
    top: number
    // in %
    left: number
}
interface StyledIconButtonProps {
    baseColor: string
    iconScale?: number
    noBorder?: boolean
    offset?: PixelOffset
}

export const StyledIconButton = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'baseColor',
        'iconScale',
        'noBorder',
        'offset',
    ]),
})<StyledIconButtonProps>(
    ({ offset }) => ({
        display: 'inline-block',
        borderRadius: '50%',
        width: calcRem(34),
        height: calcRem(34),
        outline: 0,
        verticalAlign: 'middle',
        overflow: 'hidden',

        '& > svg': {
            display: 'block',
            position: 'relative',
            top: offset ? `${offset.top}%` : '50%',
            left: offset ? `${offset.left}%` : '50%',
            transform: 'translate(-50%, -50%)',
        },

        '& > svg path': {
            fill: 'white',
        },
    }),
    ({ iconScale }) => {
        const size = iconScale ? iconScale * 100 + '%' : '67%'
        return {
            '& > svg': {
                width: size,
                height: size,
            },
        }
    },
    ({ baseColor, noBorder }) => {
        return {
            background: baseColor,
            border: noBorder === true ? undefined : `2px solid ${baseColor}`,

            '&:focus': {
                border: `2px solid ${colors.darkGrey}`,
                background: 'transparent',
            },

            '&:focus > svg path': {
                fill: colors.darkGrey,
            },
        }
    },
    ({ theme, baseColor }) =>
        theme.kind === 'perthnow' && {
            '&:hover': {
                border: `1px solid ${baseColor}`,
                backgroundColor: colors.white,
                svg: {
                    path: {
                        fill: baseColor,
                    },
                },
            },
            '&:focus': {
                border: `1px solid ${colors.perthnow.greyBasalt}`,
                backgroundColor: colors.white,
                svg: {
                    path: {
                        fill: colors.perthnow.greyBasalt,
                    },
                },
            },
        },
    ({ theme }) =>
        theme.kind === 'thewest' && {
            '&:hover': {
                borderColor: theme.sectionValues.primaryColor,
                backgroundColor: theme.sectionValues.primaryColor,
                svg: {
                    path: {
                        fill: colors.white,
                    },
                },
            },
        },
    ({ theme }) =>
        theme.kind === 'sevennews' && {
            background: 'transparent',
            border: 'none',
            borderRadius: 0,
            '&:focus': {
                border: 'none',
            },
            '& svg path': {
                fill: colors.sevennews.charade70,
                '&:hover': {
                    fill: colors.sevennews.charade,
                },
            },
        },
)

// This is mainly for rss social buttons so the ssr is hit and not a csr
export const StyledIconButtonAnchorTag = StyledIconButton.withComponent(
    'a',
) as StyledComponent<
    {
        theme?: Theme | undefined
    } & StyledIconButtonProps,
    React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >
>
