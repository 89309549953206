import styled from '@emotion/styled'
import { textMixin } from '../../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '../../../../../__styling'

export const TopicBylineWrapper = styled.div(({ theme }) => [
    textMixin<TheNightlyThemeConfig>(theme, 'byline-primary'),
    {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: theme.colors.text.secondary,
    },
])
