/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { ConsentLevel, Product, useConsentGiven } from '@news-mono/web-common'
import React from 'react'
import { ConsentFallback } from '../../../tracking/ConsentFallback/ConsentFallback'
import { metrics, ThemeMargins } from '../../../__styling/settings/metrics'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

export interface OmnyProps {
    link: string
    width?: number
    verticalSpacing?: keyof ThemeMargins
    height?: number
    smallPlaylist?: boolean
}

const embedLink = '/embed'

const processEmbedLink = (link: string) => {
    if (link.indexOf(embedLink) === -1) {
        return `${link}${embedLink}`
    }
    return link
}

export const isOmny = (link: string) => {
    return link.indexOf('omny.fm') >= 0
}

function isPlaylistEmbed(link: string) {
    return link.indexOf('playlists') !== -1
}

const addNightlyStyling = (link: string) => {
    const embeddedLink = processEmbedLink(link)
    return link.indexOf('?') === -1
        ? `${embeddedLink}?&foreground=31173d&background=f5f5f5&highlight=ffe4d3`
        : `${embeddedLink}&foreground=31173d&background=f5f5f5&highlight=ffe4d3`
}

export function omnyHeightValue(props: OmnyProps): string {
    //custom height for 3 items in playlist
    if (isPlaylistEmbed(props.link) && props.smallPlaylist) {
        return '390px'
    }

    if (isPlaylistEmbed(props.link)) {
        return '500px'
    }

    if (!isPlaylistEmbed(props.link) && props.height) {
        return `${props.height}px`
    }

    return '180px'
}

export const Omny: React.FC<OmnyProps> = (props) => {
    const theme = useTheme()

    const link =
        theme.kind === Product.TheNightly
            ? addNightlyStyling(props.link)
            : processEmbedLink(props.link)

    return (
        <iframe
            css={{
                marginBottom:
                    props.verticalSpacing &&
                    calcRem(metrics.thewest.margins[props.verticalSpacing]),
            }}
            width={props.width ? `${props.width}px` : '100%'}
            height={omnyHeightValue(props)}
            frameBorder={0}
            src={link}
            sandbox={
                'allow-same-origin allow-popups allow-scripts allow-popups-to-escape-sandbox'
            }
        />
    )
}
