import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    Theme,
    breakpoint,
    calcRem,
    colors,
    fluidTypography,
    themedValue,
    transition,
    breakpointMax,
} from '../../../..'

export const CardsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: calcRem(40),
    paddingBottom: calcRem(60),
    fontFamily: theme.fonts.sansSerif,

    '&.container-with-sidebar a': {
        width: '23%',
        padding: calcRem(18),
        p: {
            fontSize: calcRem(18),
        },
    },

    [breakpointMax('xs')]: {
        flexWrap: 'wrap',
        paddingTop: calcRem(20),
        paddingBottom: calcRem(40),
        justifyContent: 'space-evenly',
    },
}))

export const CardLink = styled('a')(() => ({
    width: '25%',
    margin: calcRem(0, 12),
    padding: calcRem(24),
    fontFamily: 'inherit',
    textDecoration: 'none',
    borderRadius: '14px',
    border: `1px solid ${tokens.thewest.colors.palette.greyDolphin}`,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    transition: '0.5s ease',

    [breakpointMax('xs')]: {
        width: '45%',
        margin: 0,
        marginBottom: calcRem(20),
        padding: calcRem(14),
        '&:nth-child(2n)': {
            marginLeft: calcRem(10),
        },
    },

    '&:hover': {
        transform: 'scale(1.05)',
    },

    '&.puzzles-card.disabled': {
        pointerEvents: 'none',
        cursor: 'default',
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '-0.5%',
            left: '-0.5%',
            width: '101%',
            height: '101%',
            borderRadius: '14px',
            background: 'rgba(255,255,255,0.7)',
        },
    },
}))

export const CardImage = styled('img')(() => ({
    width: '100%',
}))

export const CardTitle = styled('p')(() => ({
    marginBottom: 0,
    marginTop: calcRem(16),
    fontWeight: 700,
    fontSize: calcRem(24),
    fontFamily: 'inherit',
    color: tokens.thewest.colors.palette.black,

    [breakpointMax('md')]: {
        fontSize: calcRem(18),
    },
    [breakpointMax('xs')]: {
        marginTop: calcRem(10),
    },
}))
