import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
}

export const StyledOscar = styled('div')<GridProps>(
    {
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'stretch',
        flexDirection: 'column',
        flexGrow: 1, // Added so that it works with additional component wrapper from product
    },
    ({ theme, verticalSpacing }) => ({
        backgroundColor: themedValue(theme, {
            perthnow: colors.white,
            thewest: 'transparent',
            fallback: undefined,
        }),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
    }),
)

export const StyledHeader = styled('div')(
    {
        display: 'block',
        width: '100%',
    },
    ({ theme }) => ({
        marginBottom: themedValue(theme, {
            perthnow: undefined,
            fallback: calcRem(theme.margins.md),
        }),
    }),
)

export const StyledGridItem = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
    maxWidth: '100%',
    alignItems: 'flex-start',
    flex: '0 0 auto',
})

export const StyledGridList = styled('ul')<{ grow: boolean }>(
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
    },
    (props) => ({
        flex: props.grow ? '1 1 auto' : undefined,
    }),
    ({ theme }) => ({
        borderBottom: themedValue(theme, {
            perthnow: `1px solid ${colors.perthnow.greyMarble}`,
            fallback: undefined,
        }),
        background: themedValue(theme, {
            thewest: colors.white,
            fallback: undefined,
        }),
        padding: themedValue(theme, {
            perthnow: `${calcRem(metrics.perthnow.margins.sm)} 0`,
            fallback: 0,
        }),
    }),
)

export const StyledRelatedLinks = styled('ul')(
    {
        margin: 0,
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
    },
    ({ theme }) => ({
        borderBottom: themedValue(theme, {
            perthnow: `1px solid ${colors.perthnow.greyMarble}`,
            fallback: undefined,
        }),
        background: themedValue(theme, {
            thewest: colors.white,
            fallback: undefined,
        }),
        padding: `${calcRem(theme.margins.sm)} 0`,
    }),
)

export const StyledRelatedLinksHeading = styled('span')(
    {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: calcRem(16),
    },
    ({ theme }) => ({
        margin: `0 ${calcRem(theme.margins.sm)}`,
        fontFamily: theme.fonts.sansSerif,
        color:
            theme.section === 'community-news'
                ? colors.perthnow.blueCerulean
                : themedValue(theme, {
                      sevennews: (section) => section.primaryColor,
                      fallback: colors.black,
                  }),
    }),
)

export const StyledListItem = styled('li')({
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    height: calcRem(26),
})

export const StyledRelatedLink = styled(WebLink, {})(
    {
        textDecoration: 'none',
        lineHeight: 1.154,
        display: 'block',
        fontWeight: 400,
        fontSize: calcRem(14),

        '&:focus': {
            outline: 'none',
            textDecoration: 'underline',
            color: colors.black,
        },
    },
    ({ theme }) => ({
        margin: `0 ${calcRem(theme.margins.sm)}`,
        '&:hover': {
            color: themedValue(theme, {
                perthnow: colors.perthnow.blueKyanite,
                thewest: colors.thewest.westblue,
                sevennews: (section) => section.primaryColor,
                fallback: undefined,
            }),
        },

        color: themedValue(theme, {
            perthnow: colors.perthnow.greyCoal,
            fallback: colors.black,
        }),
        fontFamily: theme.fonts.sansSerif,
        overflow: 'hidden',
    }),
    ({ theme }) =>
        theme.kind === 'perthnow' ? transition({ properties: ['color'] }) : {},
)
