import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts, themedValue } from '../../../__styling'

const laborColor = '#980000'
const laborLikelyColor = '#98000066'
const coalitionColor = '#0067BD'
const coalitionLikelyColor = '#0067BD66'
const undecidedColor = '#8E8E8E'
const undecidedLikelyColor = '#8E8E8E66'
const greensColor = '#008A2C'
const greensLikelyColor = '#008A2C66'
const otherColor = '#470079'
const otherLikelyColor = '#47007966'

export const StyledLowerHouseSeatsContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        fallback: fonts.thewest.sansSerif,
    }),
}))

interface StyledLowerHouseSeatsSvgProps {
    selectedParty: string
}

export const StyledLowerHouseSeatsSvg = styled(
    'svg',
)<StyledLowerHouseSeatsSvgProps>(({ selectedParty }) => ({
    width: '100%',
    height: '100%',

    "circle[data-party='Coalition']": {
        fill: coalitionColor,
        opacity: selectedParty && selectedParty !== 'Coalition' ? 0.4 : 1,
    },
    "circle[data-party='Coalition Likely']": {
        fill: coalitionLikelyColor,
        opacity: selectedParty && selectedParty !== 'Coalition' ? 0.4 : 1,
    },
    "circle[data-party='Labor']": {
        fill: laborColor,
        opacity: selectedParty && selectedParty !== 'Labor' ? 0.4 : 1,
    },
    "circle[data-party='Labor Likely']": {
        fill: laborLikelyColor,
        opacity: selectedParty && selectedParty !== 'Labor' ? 0.4 : 1,
    },
    "circle[data-party='Undecided']": {
        fill: undecidedColor,
        opacity: selectedParty && selectedParty !== 'Undecided' ? 0.4 : 1,
    },
    "circle[data-party='Undecided Likely']": {
        fill: undecidedLikelyColor,
        opacity: selectedParty && selectedParty !== 'Undecided' ? 0.4 : 1,
    },
    "circle[data-party='Greens']": {
        fill: greensColor,
        opacity: selectedParty && selectedParty !== 'Greens' ? 0.4 : 1,
    },
    "circle[data-party='Greens Likely']": {
        fill: greensLikelyColor,
        opacity: selectedParty && selectedParty !== 'Greens' ? 0.4 : 1,
    },
    "circle[data-party='Other']": {
        fill: otherColor,
        opacity: selectedParty && selectedParty !== 'Other' ? 0.4 : 1,
    },
    "circle[data-party='Other Likely']": {
        fill: otherLikelyColor,
        opacity: selectedParty && selectedParty !== 'Other' ? 0.4 : 1,
    },
}))

export const StyledLowerHouseSeatsCount = styled('div')(() => ({
    position: 'absolute',
    top: '18%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: calcRem(80),
    textAlign: 'center',
    fontWeight: 700,

    left: `calc(50% - ${calcRem(40)})`,
    [breakpoint('sm')]: {
        top: '20%',
    },

    "&[data-party='Coalition']": {
        color: coalitionColor,
    },

    "&[data-party='Labor']": {
        color: laborColor,
    },

    "&[data-party='Greens']": {
        color: greensColor,
    },

    "&[data-party='Other']": {
        color: otherColor,
    },

    "&[data-party='Undecided']": {
        color: undecidedColor,
    },
}))

export const StyledLowerHouseSeatsCountTitle = styled('h3')(() => ({
    fontSize: calcRem(20),
    fontWeight: 700,
    margin: 0,
    textTransform: 'uppercase',
    lineHeight: 1,

    [breakpoint('sm')]: {
        fontSize: calcRem(32),
    },
}))

export const StyledLowerHouseSeatsCountNumber = styled('p')(() => ({
    fontSize: calcRem(64),
    fontWeight: 700,
    margin: 0,
    textTransform: 'uppercase',
    lineHeight: 0.9,

    [breakpoint('sm')]: {
        fontSize: calcRem(96),
    },
}))

export const StyledGraphDescriptionDesktop = styled('div')(() => ({
    position: 'absolute',
    left: `calc(50% - ${calcRem(50)})`,
    top: '20%',
    width: calcRem(100),

    fontSize: calcRem(16),
    fontWeight: 400,
    textAlign: 'center',
    display: 'none',

    [breakpoint('sm')]: {
        display: 'block',
    },
}))

export const StyledGraphDescriptionMobile = styled('div')(() => ({
    position: 'absolute',
    left: `calc(50% - ${calcRem(50)})`,
    top: '18%',
    width: calcRem(100),

    fontSize: calcRem(16),
    fontWeight: 400,
    textAlign: 'center',
    display: 'block',

    [breakpoint('sm')]: {
        display: 'none',
    },
}))
