import { CSSObject } from '@emotion/css'

export type InteractionState<T> = {
    default: T
    hover?: T
    focus?: T
    active?: T
    disabled?: T
}

/**
 * When provided an InteractionState, and a builder function which takes the
 * InteractionState's inner object that returns CSS styles, it will generate
 * all the relevant interaction selector styles for the states.
 *
 * Do not destructure this object into other CSS styles, as the media-queries
 * will override other ones in the same place. Instead use the array syntax
 * to return multiple styles, including this one to correctly merge them.
 */
export const interactionSwitch = <T>(
    {
        default: defaultValues,
        hover,
        focus,
        active,
        disabled,
    }: InteractionState<T>,
    builder: (state: T) => CSSObject,
) => {
    const styles: CSSObject = {
        ...builder(defaultValues),
        '&:hover': hover ? builder(hover) : undefined,
        '&:focus': focus ? builder(focus) : undefined,
        '&:active': active ? builder(active) : undefined,
        '&:disabled': disabled ? builder(disabled) : undefined,
    }
    return styles
}
