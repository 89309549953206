const thewestSerif = `'Guardian Text Egyptian Web', 'Times New Roman', serif`
const thewestSansSerif = `'Guardian Sans Web', 'Helvetica Neue', Helvetica, Arial, sans-serif`
const thewestSansSerifCondensed = `'Guardian Sans Cond Web', Impact, Helvetica, Arial, sans-serif`

const thenightlySansSerif = `'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif`
const thenightlySerif = `'Source Serif 4', 'Times New Roman', serif`

const perthnowSerif = `'Guardian Text Egyptian Web', 'Times New Roman', serif`
const perthnowSansSerif = `'Guardian Sans Web', 'Helvetica Neue', Helvetica, Arial, sans-serif`
const perthnowSansSerifCondensed = `'Guardian Sans Cond Web', Impact, Helvetica, Arial, sans-serif`

const sevenwestSansSerifV2 = `HeyWow, 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif`

const designSystemSerif = thewestSerif
const designSystemSansSerif = thewestSansSerif
export interface Fonts {
    serif?: string
    sansSerif: string
    sansSerifCond: string
    heading: string
    subheading: string
    body: string
    cards: {
        headline: string
        headlineAlternative?: string
    }
    pullQuote: {
        text: string
        attribution: string
    }
    publication: {
        headline: string
        byline: string
    }
    sponsored: {
        label: string
    }
    subscriber: { text: string }
    navigation: string
}

export const fonts = {
    designSystem: {
        serif: designSystemSerif,
        sansSerif: designSystemSansSerif,
        sansSerifCond: designSystemSansSerif,
        heading: designSystemSansSerif,
        subheading: designSystemSansSerif,
        body: designSystemSerif,
        pullQuote: {
            text: designSystemSerif,
            attribution: designSystemSerif,
        },
        sponsored: {
            label: designSystemSansSerif,
        },
        publication: {
            headline: designSystemSansSerif,
        },
    },

    thewest: {
        serif: thewestSerif,
        sansSerif: thewestSansSerif,
        sansSerifCond: thewestSansSerifCondensed,
        heading: thewestSerif,
        subheading: thewestSerif,
        body: thewestSerif,
        navigation: thewestSansSerif,
        cards: {
            headline: thewestSerif,
            headlineAlternative: thewestSansSerif,
        },
        pullQuote: {
            text: thewestSerif,
            attribution: thewestSerif,
        },
        sponsored: {
            label: thewestSansSerif,
        },
        publication: {
            headline: thewestSerif,
            byline: thewestSerif,
        },
        subscriber: {
            text: thewestSansSerif,
        },
    },

    thenightly: {
        serif: thenightlySerif,
        sansSerif: thenightlySansSerif,
        sansSerifCond: thenightlySansSerif,
        heading: thenightlySansSerif,
        subheading: thenightlySansSerif,
        body: thenightlySerif,
        navigation: thenightlySansSerif,
        cards: {
            headline: thenightlySansSerif,
            headlineAlternative: thenightlySansSerif,
        },
        pullQuote: {
            text: thenightlySansSerif,
            attribution: thenightlySansSerif,
        },
        sponsored: {
            label: thenightlySansSerif,
        },
        publication: {
            headline: thenightlySansSerif,
            byline: thenightlySansSerif,
        },
        subscriber: {
            text: thenightlySansSerif,
        },
    },

    perthnow: {
        serif: perthnowSerif,
        sansSerif: perthnowSansSerif,
        sansSerifCond: perthnowSansSerifCondensed,
        heading: perthnowSansSerifCondensed,
        subheading: perthnowSansSerifCondensed,
        body: perthnowSerif,
        navigation: perthnowSansSerifCondensed,
        cards: {
            headline: perthnowSansSerifCondensed,
        },
        pullQuote: {
            text: perthnowSerif,
            attribution: perthnowSansSerif,
        },
        sponsored: {
            label: perthnowSansSerifCondensed,
        },
        publication: {
            headline: perthnowSansSerifCondensed,
            byline: perthnowSansSerif,
        },
        subscriber: {
            text: perthnowSansSerif,
        },
    },

    sevennews: {
        sansSerif: sevenwestSansSerifV2,
        sansSerifCond: sevenwestSansSerifV2,
        heading: sevenwestSansSerifV2,
        subheading: sevenwestSansSerifV2,
        body: sevenwestSansSerifV2,
        navigation: sevenwestSansSerifV2,
        cards: {
            headline: sevenwestSansSerifV2,
        },
        pullQuote: {
            text: sevenwestSansSerifV2,
            attribution: sevenwestSansSerifV2,
        },
        sponsored: {
            label: sevenwestSansSerifV2,
        },
        publication: {
            headline: sevenwestSansSerifV2,
            byline: sevenwestSansSerifV2,
        },
        subscriber: {
            text: sevenwestSansSerifV2,
        },
    },
}
