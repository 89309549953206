import React from 'react'
import { Product, PublicationCardItem } from '@news-mono/web-common'
import {
    StyledOlympicsMiniCollection,
    StyledOlympicsMiniCell,
    StyledOlympicsMiniColumn,
} from './OlympicsCollectionMini.styled'
import { useProduct } from '../../__product'
import { OlympicsCollectionArticle } from '../OlympicsCollectionArticle/OlympicsCollectionArticle'

export interface OlympicsCollectionMiniProps {
    /** The articles to fill this mini collection with, length must be 4 */
    articles: PublicationCardItem[]
    onEvent: (event: any) => void
}

/**
 * Builds a 'mini' collection for use within the OlympicsCollection. This mini collection is built
 * to contain a 2x2 grid of articles on TheWest, and a 1x2 grid of articles on PerthNow. Due to the
 * differences in the use of this across mastheads, the collection has a hard check for isTheWest
 * to display the 2nd column of articles.
 */
export const OlympicsCollectionMini: React.FC<OlympicsCollectionMiniProps> = ({
    articles,
    onEvent,
}) => {
    const product = useProduct()

    // Let's make sure the articles amount matches each masthead
    if (
        (product === Product.TheWest && articles.length !== 4) ||
        (product === Product.PerthNow && articles.length != 2)
    ) {
        console.error(
            'Uh oh! OlympicsCollectionMini did not meet the needed length of articles! Empty articles will be inserted to fulfill the spaces missing.',
        )
    }

    const isPerthNow = product === Product.PerthNow
    const CollectionLayout = isPerthNow
        ? PerthNowMiniCollectionLayout
        : DefaultMiniCollectionLayout

    return <CollectionLayout onEvent={onEvent} articles={articles} />
}

interface MiniCollectionLayoutProps {
    onEvent: (event: any) => void
    articles: PublicationCardItem[]
}

export const PerthNowMiniCollectionLayout: React.FC<MiniCollectionLayoutProps> =
    ({ onEvent, articles }) => {
        return (
            <StyledOlympicsMiniCollection>
                <StyledOlympicsMiniColumn column={'first'}>
                    <StyledOlympicsMiniCell row={'first'}>
                        {/* Article #1 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[0]}
                            onEvent={onEvent}
                            cardNumber={4}
                            isMiniCollection={true}
                        />
                    </StyledOlympicsMiniCell>
                    <StyledOlympicsMiniCell row={'second'}>
                        {/* Article #2 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[1]}
                            onEvent={onEvent}
                            cardNumber={5}
                            isMiniCollection={true}
                        />
                    </StyledOlympicsMiniCell>
                </StyledOlympicsMiniColumn>
            </StyledOlympicsMiniCollection>
        )
    }

export const DefaultMiniCollectionLayout: React.FC<MiniCollectionLayoutProps> =
    ({ onEvent, articles }) => {
        return (
            <StyledOlympicsMiniCollection>
                <StyledOlympicsMiniColumn column={'first'}>
                    <StyledOlympicsMiniCell row={'first'}>
                        {/* Article #1 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[0]}
                            onEvent={onEvent}
                            cardNumber={4}
                        />
                    </StyledOlympicsMiniCell>
                    <StyledOlympicsMiniCell row={'second'}>
                        {/* Article #3 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[2]}
                            onEvent={onEvent}
                            cardNumber={6}
                        />
                    </StyledOlympicsMiniCell>
                </StyledOlympicsMiniColumn>
                {/* Only show the second column on TheWest */}
                <StyledOlympicsMiniColumn column={'second'}>
                    <StyledOlympicsMiniCell row={'first'}>
                        {/* Article #2 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[1]}
                            onEvent={onEvent}
                            cardNumber={5}
                        />
                    </StyledOlympicsMiniCell>
                    <StyledOlympicsMiniCell row={'second'}>
                        {/* Article #4 */}
                        <OlympicsCollectionArticle
                            articleType={'article'}
                            item={articles[3]}
                            onEvent={onEvent}
                            cardNumber={7}
                        />
                    </StyledOlympicsMiniCell>
                </StyledOlympicsMiniColumn>
            </StyledOlympicsMiniCollection>
        )
    }
