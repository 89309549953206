const paths: string[] = [
    require('./everyday_digital_and_weekend_papers_june23.png'),
    require('./everyday_digital_may_2024.png'),
    require('./everyday_digital_weekend_papers_may_2024.png'),
    require('./everyday_digital_and_weekend_papers_cropped.png'),
    require('./everyday_digital_and_weekend_papers.png'),
    require('./everyday_digital_june23.png'),
    require('./everyday_digital_cropped.png'),
    require('./everyday_digital.png'),
    require('./mon-sat_and_everyday_digital_cropped.png'),
    require('./mon-sat_and_everyday_digital.png'),
    require('./mon-sun_and_everyday_digital_cropped.png'),
    require('./mon-sun_and_everyday_digital.png'),
    require('./sunday_times_cropped.png'),
    require('./sunday_times.png'),
    require('./twa_sat_cropped.png'),
    require('./twa_sat.png'),
    require('./weekend_cropped.png'),
    require('./weekend.png'),
    // Footy pack images
    require('./everyday_digital_weekend_papers_footy2024.png'),
    require('./everyday_digital_footy2024.png'),
    require('./mon_sat_everyday_digital_footy2024.png'),
    require('./mon_sun_everyday_digital_footy2024.png'),
    require('./weekend_papers_footy2024.png'),
    require('./sunday_times_footy2024.png'),
    require('./saturday_west_footy2024.png'),
]

const mobilePaths: string[] = [
    require('./mobile/everyday_digital_and_weekend_papers_june23.png'),
    require('./mobile/everyday_digital_may_2024.png'),
    require('./mobile/everyday_digital_weekend_papers_may_2024.png'),
    require('./mobile/everyday_digital_and_weekend_papers_cropped.png'),
    require('./mobile/everyday_digital_and_weekend_papers.png'),
    require('./mobile/everyday_digital_june23.png'),
    require('./mobile/everyday_digital_cropped.png'),
    require('./mobile/everyday_digital.png'),
    require('./mobile/mon-sat_and_everyday_digital_cropped.png'),
    require('./mobile/mon-sat_and_everyday_digital.png'),
    require('./mobile/mon-sun_and_everyday_digital_cropped.png'),
    require('./mobile/mon-sun_and_everyday_digital.png'),
    require('./mobile/sunday_times_cropped.png'),
    require('./mobile/sunday_times.png'),
    require('./mobile/twa_sat_cropped.png'),
    require('./mobile/twa_sat.png'),
    require('./mobile/weekend_cropped.png'),
    require('./mobile/weekend.png'),
    // Footy pack images
    require('./mobile/everyday_digital_weekend_papers_footy2024.png'),
    require('./mobile/everyday_digital_footy2024.png'),
    require('./mobile/mon_sat_everyday_digital_footy2024.png'),
    require('./mobile/mon_sun_everyday_digital_footy2024.png'),
    require('./mobile/weekend_papers_footy2024.png'),
    require('./mobile/sunday_times_footy2024.png'),
    require('./mobile/saturday_west_footy2024.png'),
]

export const packageImages: { [imageName: string]: string } = {}
for (let i = 0; i < paths.length; i++) {
    const path = paths[i]
    const fileName = path
        .substring(path.lastIndexOf('/') + 1)
        .replace(/\..*\.png/, '.png')
    packageImages[fileName] = path
    const mobilePath = mobilePaths[i]
    packageImages['mobile/' + fileName] = mobilePath
}
