import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { CurationV4ExpandedResponseDTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateKeyTypes } from '../../../helpers/validation'

export function validateGetCuration(value: any): CurationV4ExpandedResponseDTO {
    const errors = validateKeyTypes('curation', value, {
        id: 'string',
        title: 'string',
        minimumItems: 'number',
        itemSlots: 'number',
        lastUpdated: 'string',
        metadata: {},
        articles: [{}],
    })

    if (errors) {
        throw new Error(
            'Curation response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}

export interface GetCurationOptions {
    profiles?: boolean
    offset?: number
    pageSize?: number
    includePremium?: boolean
    excludedCurations?: string[]
    excludedSources?: string[]
    applyTrendingStoriesExclusion?: boolean
}

export const getCuration = (
    services: DataLoaderGlobalParams,
    curationId: string,
    options: GetCurationOptions,
) => {
    if (curationId === undefined) {
        throw new Error('getCuration, curationId should not be undefined')
    }

    const include_premium =
        // option defaults to true, which is then confirmed/denied by the feature toggle
        // see commments in https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/1501/overview
        options.includePremium !== false &&
        isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'digital-subscriptions',
            false,
        )
    const query = {
        profiles: options.profiles,
        from: options.offset !== undefined ? options.offset + 1 : undefined,
        to:
            options.offset !== undefined && options.pageSize
                ? options.offset + options.pageSize
                : undefined,
        include_premium: include_premium || undefined,
        exclude_curations: options.excludedCurations,
        exclude_sources: options.excludedSources,
        apply_trending_stories_exclusion:
            options.applyTrendingStoriesExclusion || undefined,
    }

    return httpGet({
        log: services.log,
        validate: validateGetCuration,
        baseUrl: services.config.contentApi,
        path: `v4/curation/${curationId}`,
        query,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}
