import styled from '@emotion/styled'
import {
    createPropFilter,
    Topic,
    WebLink,
    WebLinkProps,
} from '@news-mono/web-common'
import { playButtonIconClass } from '../../../buttons/PlayButton/PlayButton.styled'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { CardText } from '../../../cards/CardText/CardText'
import { StyledHeadlineText } from '../../../cards/CardText/CardText.styled'
import { StyledKicker } from '../../../cards/Kicker/Kicker.styled'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    hexToRGBA,
    metrics,
} from '../../../__styling'

const borderRadius = 12
const negativeMargin = 40

type LayoutTypes = 'landscape' | 'portrait'

interface Layout {
    layout: LayoutTypes
}

export const StyledCardContainer = styled('div')<Layout>(({ layout }) => [
    {
        display: layout === 'portrait' ? 'grid' : 'block',
        gridTemplateColumns: '1fr',
        gridGap: `${metrics.sevennews.margins.sm}px ${metrics.sevennews.margins.gridGap}px`,

        [breakpoint('xxs')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
])

interface StyledCardProps {
    topic: Topic | false
    layout: LayoutTypes
}

export const StyledCard = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['topic', 'layout']),
})<StyledCardProps>(({ topic, layout }) => [
    {
        fontFamily: fonts.sevennews.sansSerif,
        color: colors.white,
        textDecoration: 'none',
        position: 'relative',
        borderRadius: borderRadius,
        overflow: 'hidden',
        boxShadow: '0px 3px 8px 0px rgba(50, 50, 50, 0.2)',
        height: `calc(100% - ${negativeMargin / 2}px)`,
        background: topic ? topicColor(topic) : colors.sevennews.resolutionBlue,
    },

    layout === 'landscape' && {
        display: 'block',

        [`& ${StyledCardText}`]: {
            marginBottom: `-${negativeMargin / 2}px`,
        },

        [breakpoint('md')]: {
            display: 'flex',
            height: 'auto',
            maxWidth: '100%',
            margin: 'auto',

            [`& ${StyledCardMedia}`]: {
                flex: '1 1 100%',
                position: 'relative',
            },

            [`& ${StyledCardText}`]: {
                flex: '1 1 auto',
                transform: 'none',
                padding: calcRem(20, 10),
                position: 'relative',
            },

            [`& ${StyledCardMedia}::before`]: {
                position: 'absolute',
                right: '0',
                display: 'block',
                width: '30px',
                background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, ${topicToRGBA(
                    topic,
                )} 25px, ${topicToRGBA(topic)} 100%)`,
                height: '100%',
                content: "''",
                zIndex: 1,
            },
        },
    },

    {
        [`& .Card-Media-Content`]: {
            transform: 'scale(1.02)',
            transition: 'transform 0.3s ease-out',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['&:focus']: {
            boxShadow: `0 0 0 2px ${
                topic ? topicColor(topic) : colors.sevennews.resolutionBlue
            }`,
            outline: 'none',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['&:hover']: {
            [`& .Card-Media-Content`]: {
                transform: 'scale(1.1)',
            },
        },
    },
])

export const StyledCardMedia = styled(CardMedia)({
    marginBottom: 0,
    overflow: 'hidden',

    [`& .${playButtonIconClass}`]: {
        opacity: 0,
    },

    [`&:hover .${playButtonIconClass}, &:focus .${playButtonIconClass}`]: {
        opacity: 1,
    },
})
interface StyledCardTextProps {
    topic: Topic | false
}

export const StyledCardText = styled(CardText)<StyledCardTextProps>(
    ({ topic }) => {
        return {
            background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, ${topicToRGBA(
                topic,
            )} 35%, ${topicToRGBA(topic)} 100%)`,
            padding: calcRem(20, 10, 0),
            transform: `translateY(-${negativeMargin}px)`,
        }
    },
    {
        [`& ${StyledKicker}, & ${StyledHeadlineText}`]: {
            color: colors.white,
        },
    },
)

function topicToRGBA(topic: Topic | false, opacity?: 1): string {
    const color = topic ? topicColor(topic) : colors.sevennews.resolutionBlue

    return hexToRGBA(color, opacity)
}

function topicColor(topic: Topic): string {
    if (!topic.parent) {
        switch (topic.id) {
            case 'sunrise':
                return colors.sevennews.piper
            case 'the-morning-show':
                return colors.sevennews.endeavour
            case 'spotlight':
                return colors.sevennews.pickledBluewood
            case 'entertainment':
                return '#A8328B'
            case 'lifestyle':
                return '#B04AE3'
            case 'technology':
                return '#5C945B'
            case 'sport':
                return colors.sevennews.stormGrey
            default:
                return colors.sevennews.resolutionBlue
        }
    }

    switch (topic.parent.id) {
        case 'sunrise':
            return colors.sevennews.piper
        case 'the-morning-show':
            return colors.sevennews.endeavour
        case 'spotlight':
            return colors.sevennews.pickledBluewood
        case 'entertainment':
            return '#A8328B'
        case 'lifestyle':
            return '#B04AE3'
        case 'technology':
            return '#5C945B'
        case 'space':
            return '#646464'
        case 'viral-weird':
            return '#AA3333'
        case 'world':
            return '#3A37AE'
        case 'sport':
            return colors.sevennews.stormGrey
        case 'finance':
            return '#292A33'
        default:
            return colors.sevennews.resolutionBlue
    }
}
