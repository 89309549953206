import { useTheme } from '@emotion/react'
import {
    getAdSize,
    pickFirstSizeMapping,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { InnerAdProps } from '../../google-amp/components/AmpAdProps'
import { amphtml } from '../../google-amp/react_amp'
import { themedValue } from '../../__styling/themed-value'

interface InnerProps {
    width: number
    height: number
    type: 'doubleclick'
    'data-slot': string
    json: string
    layout?: 'responsive' | 'fixed'
}
const AmpAdElement = amphtml<InnerProps>('ad')

interface AmpAdRealTimeConfig {
    vendors: {
        [vendor: string]: {
            [macro: string]: string
        }
    }
    timeoutMillis: number
}

function resolveRtcAttribute(ixSiteId?: string) {
    if (!ixSiteId) {
        return {}
    }

    const rtc: AmpAdRealTimeConfig = {
        vendors: {
            indexexchange: {
                SITE_ID: ixSiteId,
            },
        },
        timeoutMillis: 750,
    }
    return {
        'rtc-config': JSON.stringify(rtc),
    }
}

export const AmpAd: React.FC<InnerAdProps> = (props) => {
    const adUnit = props.adState.unitLookup(props.unitId, 0)
    const theme = useTheme()
    const ixHeaderTagEnabled = useFeature('ix-header-tag-wrapper')
    const ixSiteId = themedValue(theme, {
        thewest: '283531',
        perthnow: '283534',
        sevennews: '354406',
        fallback: '283531',
    })

    if (!adUnit) {
        return null
    }

    const { adUnitPath, targeting, sizeMapping } = adUnit
    const adSize = getAdSize(sizeMapping, 0)
    const firstSize = pickFirstSizeMapping(adSize)
    if (!firstSize) {
        return null
    }

    const [width, height] = firstSize

    return (
        <AmpAdElement
            width={width}
            height={height}
            layout={props.layout}
            type="doubleclick"
            data-slot={adUnitPath}
            json={JSON.stringify({ targeting })}
            {...(ixHeaderTagEnabled && resolveRtcAttribute(ixSiteId))}
        />
    )
}
