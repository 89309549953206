import {
    BreakpointRatios,
    CardBreakpointRatios,
    FixedRatio,
    ImageCrop,
    ImageCrops,
    isCropBreakpoint,
} from '@news-mono/web-common'
import { mapCropBreakpoint } from '../../content/Picture/utils'

// Returns compatible ie matched ratio against preferred ratio's, will return an empty array if no compatible ratio's are found
function getCompatibleRatio(
    imageCrops: ImageCrops,
    fixedRatios: FixedRatio | FixedRatio[],
): FixedRatio | undefined {
    if (Array.isArray(fixedRatios)) {
        for (let i = 0; i < fixedRatios.length; i++) {
            const ratio = fixedRatios[i]
            const crop = imageCrops[ratio]
            if (crop) {
                return ratio
            }
        }
        return
    }

    return imageCrops[fixedRatios] ? fixedRatios : undefined
}

// Returns compatible breakpoint ratios, whereby there is an image crop present for the given iterated ratio
export function getCompatibleBreakpointRatios(
    imageCrops: ImageCrops,
    breakpointRatio: CardBreakpointRatios | undefined,
    fallback: FixedRatio = '16:9',
): FixedRatio | BreakpointRatios {
    if (breakpointRatio) {
        // return the default fixedRatio crop, we only want to return the first image crop
        // as that would be the most relevant.
        return (
            mapCropBreakpoint(breakpointRatio, (fixedRatios) => {
                const compatibleRatio = getCompatibleRatio(
                    imageCrops,
                    Array.isArray(fixedRatios) ? fixedRatios : [fixedRatios],
                )
                if (compatibleRatio) {
                    return compatibleRatio
                }

                // If we can't find a compatible ratio we just take the first specified ratio as the fallback
                const fallbackImageRatio = Array.isArray(fixedRatios)
                    ? fixedRatios[0]
                    : fixedRatios
                return fallbackImageRatio
            }) || fallback
        )
    }

    return fallback
}

// Returns compatible ie matched crops for given fixed ratio's, will return an empty array if no compatible crops are found
export function getCompatibleCrops(
    imageCrops: ImageCrops,
    fixedRatios: FixedRatio[],
) {
    const compatibleCrops: ImageCrop[] = []
    for (let i = 0; i < fixedRatios.length; i++) {
        const ratio = fixedRatios[i]
        const crop = imageCrops[ratio]
        if (crop) {
            compatibleCrops.push(crop)
        }
    }
    return compatibleCrops
}

// Converts fixedRatio | fixedRatio[] to CardBreakpointRatios used by CardMedia only for now
export function fixedRatiosToCardBreakpoints(
    fixedRatio: FixedRatio | FixedRatio[] | CardBreakpointRatios,
    fallbackRatio: FixedRatio,
) {
    if (Array.isArray(fixedRatio)) {
        return { default: fixedRatio[0] || fallbackRatio }
    }

    if (typeof fixedRatio === 'string') {
        return { default: fixedRatio }
    }

    let breakpointState: BreakpointRatios

    // Init default values.
    if (Array.isArray(fixedRatio.default)) {
        breakpointState = { default: fixedRatio.default[0] ?? fallbackRatio }
    } else {
        breakpointState = { default: fixedRatio.default }
    }

    // Set values for each breakpoint.
    for (const [breakpoint, state] of Object.entries(fixedRatio)) {
        if (!isCropBreakpoint(breakpoint)) continue

        if (Array.isArray(state)) {
            breakpointState[breakpoint] = state[0] ?? fallbackRatio
        } else {
            breakpointState[breakpoint] = state
        }
    }

    return breakpointState
}

// tests if a particular ratio exists within a variety of fixed ratio values
export function doesParticularRatioExist(
    fixedRatio: FixedRatio | FixedRatio[] | CardBreakpointRatios,
    ratioToLookFor: FixedRatio,
) {
    if (typeof fixedRatio === 'object') {
        if (Array.isArray(fixedRatio)) {
            return fixedRatio.indexOf(ratioToLookFor) >= 0
        }
        for (const breakpoint in fixedRatio) {
            const narrowedRatio =
                fixedRatio[breakpoint as keyof CardBreakpointRatios]
            if (!narrowedRatio) continue
            if (Array.isArray(narrowedRatio)) {
                return narrowedRatio.indexOf(ratioToLookFor) >= 0
            } else if (narrowedRatio === ratioToLookFor) {
                return true
            }
        }
        return false
    }
    return fixedRatio === ratioToLookFor
}
