import queryString from 'query-string'

export const getGooTest = () => {
    if (typeof location === 'undefined') {
        return undefined
    }

    const parsed: {
        gootest?: string
    } = queryString.parse(location.search)

    const gootest = parsed.gootest

    return gootest
}
