import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'

export const StyledConsentFallback = styled('section')(({ theme }) => ({
    padding: calcRem(theme.margins.md),
    marginBottom: calcRem(theme.margins.md),
    backgroundColor: themedValue(theme, {
        thewest: colors.thewest.greyErmine,
        fallback: colors.white,
    }),
    fontFamily: themedValue(theme, {
        thewest: fonts.thewest.sansSerif,
        perthnow: fonts.perthnow.sansSerif,
        sevennews: fonts.sevennews.sansSerif,
        fallback: undefined,
    }),
    borderColor: themedValue(theme, {
        thewest: colors.thewest.greySeal,
        perthnow: colors.perthnow.greyPyrite,
        sevennews: colors.sevennews.alto,
        fallback: undefined,
    }),
    borderStyle: 'solid',
    borderWidth: 5,
    textAlign: 'center',

    p: {
        margin: 0,

        '&:not(:last-child)': {
            marginBottom: calcRem(theme.margins.sm),
        },
    },
}))
StyledConsentFallback.displayName = 'StyledConsentFallback'

export const StyledConsentHeader = styled('h2')(({ theme }) => ({
    margin: 0,
    marginBottom: calcRem(theme.margins.md),
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(metrics.baseFontSize),
}))
StyledConsentHeader.displayName = 'StyledConsentHeader'
