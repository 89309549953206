import {
    ContentDataTypes,
    DataLoaderGlobalParams,
    getSearchPublications,
    mapListPublication,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { useEffect, useState } from 'react'
import {
    CollectionDataLoaded,
    loadCollectionData,
} from 'web-common/src/data/loader-definitions'
import { SearchDataLoaderConfig7News } from '../../SevenNewsV2'
import { mapSearchVideoMetaToCard } from '../../video-series'

export type LoadedData = Exclude<
    MaybeLoaded<CollectionDataLoaded>,
    { loaded: false }
>

/**
 * A hook which returns all that is necessary to 'load more' data using infinite scroll.
 * @param initialDataDefinition The initial data-definition to load data.
 * @param initialData The initial data already loaded.
 * @param onLoadMore A callback function that is run once new data is loaded.
 * @param services Services required for loading mroe data.
 * @returns
 */
export const useInfiniteScroll = (
    initialDataDefinition: ContentDataTypes,
    initialData: MaybeLoaded<CollectionDataLoaded>,
    services: DataLoaderGlobalParams,
    onLoadMore?: (data: LoadedData) => void,
) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(initialData)
    const [dataDefinition, setDataDefinition] = useState(
        excludeAggregated(initialDataDefinition),
    )

    // When the data or data definition from the props change, reset to initial state.
    useEffect(() => {
        setIsLoading(false)
        setData(initialData)
        setDataDefinition(excludeAggregated(initialDataDefinition))
    }, [initialData, initialDataDefinition])

    async function nextPage() {
        if (
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.paging
        ) {
            const newDataDefinition = {
                ...dataDefinition,
                paging: {
                    ...dataDefinition.paging,
                    page: dataDefinition.paging.page + 1,
                },
            }

            setDataDefinition(newDataDefinition)
            return await loadCollectionData(services, newDataDefinition)
        }
        return undefined
    }

    async function loadMore(loadedData: LoadedData) {
        setIsLoading(true)

        const result = await nextPage()

        if (
            result &&
            result.kind === 'listing' &&
            loadedData.result.kind === 'listing'
        ) {
            // Lets update the data with some more data, adhering to the loadedData type format
            const updatedData: LoadedData = {
                loaded: true,
                result: {
                    publications: loadedData.result.publications.concat(
                        result.publications,
                    ),
                    kind: 'listing',
                    loadMorePossible: result.loadMorePossible,
                },
            }
            onLoadMore?.({
                loaded: true,
                result: {
                    publications: result.publications,
                    kind: 'listing',
                    loadMorePossible: result.loadMorePossible,
                },
            })
            // Timeout is fix for rendering issue causing images to jump across cards
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
            setData(updatedData)
        } else {
            throw new Error('Error loading next page')
        }
    }

    return {
        loadMore,
        isLoading,
        dataDefinition,
        data,
    }
}

export const useSearchInfiniteScroll = (
    initialDataDefinition: SearchDataLoaderConfig7News,
    initialData: MaybeLoaded<CollectionDataLoaded>,
    services: DataLoaderGlobalParams,
) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(initialData)
    const [dataDefinition, setDataDefinition] = useState(initialDataDefinition)

    // When the data or data definition from the props change, reset to initial state.
    useEffect(() => {
        setIsLoading(false)
        setData(initialData)
        setDataDefinition(initialDataDefinition)
    }, [initialData, initialDataDefinition])

    async function nextPage() {
        if (
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.options.paging &&
            dataDefinition.options.paging.page &&
            dataDefinition.searchTerm
        ) {
            const newDataDefinition = {
                ...dataDefinition,
                options: {
                    paging: {
                        ...dataDefinition.options.paging,
                        page: dataDefinition.options.paging.page + 1,
                    },
                },
            }

            setDataDefinition(newDataDefinition)

            return await getSearchPublications(
                services,
                dataDefinition.searchTerm,
                newDataDefinition.options,
            )
        }
        return undefined
    }

    async function loadMore(loadedData: LoadedData) {
        setIsLoading(true)
        const result = await nextPage()

        if (result && loadedData.result.kind === 'listing') {
            const mappedListing = result.documents.map((listPublication) =>
                mapListPublication(listPublication),
            )
            const videos = result.videos?.map((videoMeta) =>
                mapSearchVideoMetaToCard(videoMeta),
            )

            // Lets update the data with some more data, adhering to the loadedData type format
            const updatedData: MaybeLoaded<CollectionDataLoaded> = {
                loaded: true,
                result: {
                    publications: data.loaded
                        ? data.result.publications.concat(mappedListing)
                        : [],
                    kind: 'listing',
                    // loadMorePossible: result.morePublicationsAvailable,
                    loadMorePossible: true,
                    videos:
                        data.loaded && videos
                            ? data.result.videos?.concat(videos)
                            : [],
                },
            }

            // Timeout is fix for rendering issue causing images to jump across cards
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
            setData(updatedData)
        } else {
            throw new Error('Error loading next page')
        }
    }

    return {
        loadMore,
        isLoading,
        dataDefinition,
        data,
    }
}

const excludeAggregated = (dataDefinition: ContentDataTypes) =>
    dataDefinition.type !== 'aggregated' ? dataDefinition : undefined
