import {
    FlexDirectionProperty,
    FlexWrapProperty,
    ObjectFitProperty,
    PositionProperty,
    TextAlignProperty,
    TextTransformProperty,
    TransformProperty,
} from 'csstype'
import {
    BreakpointState,
    calcRem,
    PerthNowTheme,
    PerthNowThemeConfig,
    SevenNewsTheme,
    SevenNewsThemeConfig,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
} from '../../__styling'
import { textCrop } from '../../__styling/style-mixins'
import { CSSObject } from '@emotion/css'
import { tokens } from '@news-mono/design-tokens'

const { neutral } = tokens.thenightly.colors.palette

export type BioBlockTheme<T extends ThemeConfig> = {
    margin: string | undefined
    background?: string
    gap?: BreakpointState<T, string>
    breakpointState?: BreakpointState<
        T,
        {
            flexWrap?: FlexWrapProperty
            flexDirection: FlexDirectionProperty
            justifyContent?: string
            alignItems: string
            padding?: string
            maxWidth?: string
        }
    >
    text: {
        borderBottom?: string
        textAlign?: TextAlignProperty
        padding?: string
        width?: string

        breakpointState?: BreakpointState<
            T,
            {
                flex?: string
                width?: string
                padding?: string
                order?: number
                alignSelf?: string
                textAlign?: TextAlignProperty
            }
        >
    }
    author: {
        margin?: string
        textTransform?: TextTransformProperty
        lineHeight?: string
        display?: string
        flexDirection?: FlexDirectionProperty
        fontFamily?: string
        color?: string
        borderBottom?: string
        paddingBottom?: string
        flexWrap?: FlexWrapProperty
        justifyContent?: string
        transform?: string
        textAlign?: TextAlignProperty
        fontSize?: BreakpointState<T, string>
        after?: CSSObject
    }
    name: {
        marginBottom?: BreakpointState<T, string>
        textCrop?: CSSObject
        fontSize?: string
        fontFamily?: string
        flex?: string
        fontWeight?: number
        lineHeight?: string
    }
    position?: {
        textTransform?: TextTransformProperty
        fontWeight: number
        lineHeight?: string
        color: string
        fontFamily?: string
        fontSize: string
    }
    bioText: {
        lineHeight?: string
        margin?: string
        color: string
        fontWeight?: number
        fontFamily?: string
        fontSize?: string
        marginTop?: string
    }
    imageContainer: {
        flex: string
        padding?: string
        alignSelf?: string
        breakpointState: BreakpointState<
            T,
            {
                margin?: string
                width?: string
                height?: string
                overflow?: string
                borderRadius?: string
                order?: number
            }
        >
    }
    image: {
        filter?: string
        objectFit?: ObjectFitProperty
        breakpointState?: BreakpointState<
            T,
            {
                width?: string
                height?: string
                position?: PositionProperty
                transform?: TransformProperty
                top?: string
                left?: string
            }
        >
    }
    divider?: {
        width: string
        height: string
        backgroundColor: string
        margin: BreakpointState<T, string>
    }
    header?: {
        fontWeight: number
        margin: string
        width: string

        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                lineHeight: string
                letterSpacing: string
            }
        >
    }
}

const desktopImageSize = 292

export const theWestBioBlockTheme = (scheme: TheWestTheme) => {
    const theme: BioBlockTheme<TheWestThemeConfig> = {
        margin: calcRem(0, 0, scheme.margins.sm, 0),
        background: scheme.colors.palette.white,

        text: {
            breakpointState: {
                default: {
                    flex: '1 2 100%',
                    padding: calcRem(40, 48),
                    alignSelf: 'center',
                    order: 0,
                },
                sm: {
                    order: 0,
                    flex: '1 1 auto',
                    width: '50%',
                    padding: calcRem(32, 33),
                },
                md: {
                    padding: calcRem(50, 58),
                },
                lg: {
                    padding: calcRem(45, 65),
                },
            },
        },
        author: {
            textTransform: 'uppercase',
            lineHeight: '1',
            display: 'flex',
            flexDirection: 'column',
            fontSize: {
                default: calcRem(28),
                md: calcRem(36),
            },
            fontFamily: scheme.fonts.sansSerifCond,
            color: scheme.colors.text.heading,

            after: {
                '&::after': {
                    content: `''`,
                    width: '4px',
                    height: '100%',
                    position: 'absolute',
                    left: calcRem(-13),
                    top: '0',
                    background: scheme.sectionValues.primaryColor,
                },
            },
        },
        name: {
            marginBottom: {
                default: calcRem(5),
                md: calcRem(14),
            },
            textCrop: textCrop({
                fontSize: 36,
                desiredLineHeight: 1.2,
                cropLineHeight: 1.2,
                topCrop: 10,
                bottomCrop: 8,
            }),

            fontSize: calcRem(36),
            flex: '0 0 auto',
        },
        position: {
            textTransform: 'none',
            fontWeight: 700,
            color: '#74787a', //TODO: Find `greyKoala`
            fontFamily: scheme.fonts.serif,
            fontSize: calcRem(18),
        },
        bioText: {
            lineHeight: '1.375',
            color: scheme.colors.text.secondary,
            margin: calcRem(16, 0, 21),
        },
        imageContainer: {
            flex: '0 0 auto',

            breakpointState: {
                default: {
                    width: '100%',
                    height: 'auto',
                },
                sm: {
                    order: 1,
                    margin: '38px 60px 38px 0',
                    width: calcRem(desktopImageSize),
                    height: calcRem(desktopImageSize),
                    overflow: 'hidden',
                    borderRadius: '50%',
                },
            },
        },
        image: {
            filter: 'grayscale(100%)',

            breakpointState: {
                default: {
                    width: '100%',
                    height: 'auto',
                },
                // On desktop make the image rounded for the west
                sm: {
                    position: 'absolute',
                    transform: `translate(${calcRem(-desktopImageSize / 4)})`, // Offset so the image centres.
                    width: 'auto',
                    height: '100%',
                },
            },
        },
    }
    return theme
}
export const sevenNewsBioBlockTheme = (scheme: SevenNewsTheme) => {
    const theme: BioBlockTheme<SevenNewsThemeConfig> = {
        margin: undefined,
        background: scheme.colors.palette.white,

        breakpointState: {
            default: {
                flexWrap: 'nowrap',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0',
            },
            md: {
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: calcRem(0, 16, 0, 0),
            },
        },
        text: {
            width: '100%',
            textAlign: 'center',
        },
        author: {
            textAlign: 'center',
            margin: calcRem(scheme.margins.md, 0, 0, 0),
        },
        name: {
            marginBottom: {
                default: calcRem(16),
            },
            textCrop: textCrop({
                fontSize: 36,
                desiredLineHeight: 1.2,
                cropLineHeight: 1.2,
                topCrop: 10,
                bottomCrop: 8,
            }),
            fontSize: calcRem(36),
            fontFamily: scheme.fonts.sansSerif,
            flex: '0 0 auto',
            fontWeight: 700,
        },
        position: {
            lineHeight: '1',
            fontWeight: 300,
            fontSize: calcRem(18),
            color: 'rgba(51, 51, 51, 0.5)',
            fontFamily: scheme.fonts.sansSerif,
        },
        bioText: {
            color: 'rgba(39,38,38,.8)',
            fontWeight: 300,
            fontFamily: scheme.fonts.sansSerif,
            fontSize: calcRem(18),
            lineHeight: calcRem(23),
            margin: '0',
            marginTop: calcRem(10),
        },
        imageContainer: {
            flex: '0 0 auto',
            alignSelf: 'center',

            breakpointState: {
                default: {
                    width: calcRem(95),
                    height: calcRem(95),
                    overflow: 'hidden',
                    borderRadius: '50%',
                    order: 0,
                },
                sm: {
                    margin: calcRem(0),
                    width: calcRem(100),
                    height: calcRem(100),
                },
            },
        },
        image: {
            breakpointState: {
                default: {
                    transform: `translate(${calcRem(-111 / 4)})`, // Offset so the image centres.
                    width: 'auto',
                    height: '100%',
                },
            },
        },
    }
    return theme
}
export const perthNowBioBlockTheme = (scheme: PerthNowTheme) => {
    const theme: BioBlockTheme<PerthNowThemeConfig> = {
        margin: calcRem(0, 0, scheme.margins.sm, 0),
        background: scheme.colors.palette.white,

        text: {
            padding: calcRem(
                scheme.margins.md,
                scheme.margins.md,
                0,
                scheme.margins.md,
            ),
            width: '100%',
            borderBottom: `1px solid ${scheme.margins.sm}`,
        },
        author: {
            borderBottom: `4px solid ${scheme.colors.borders.primary}`,
            paddingBottom: calcRem(scheme.margins.sm),
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            lineHeight: '1',
            transform: `translateY(${calcRem(-5)})`, //TODO: Update to use metrics.perthnow.textOffset
            fontSize: {
                default: calcRem(32),
                md: calcRem(42),
            },
            fontFamily: scheme.fonts.sansSerifCond,
            color: scheme.colors.text.primary,
        },
        name: {
            lineHeight: '1.3',
        },
        position: {
            lineHeight: '1',
            fontWeight: 400,
            fontSize: calcRem(14),
            color: scheme.colors.text.secondary,
            fontFamily: scheme.fonts.sansSerif,
        },
        bioText: {
            color: scheme.colors.text.heading,
            lineHeight: '1.625',
            margin: calcRem(16, 0, 21),
        },
        imageContainer: {
            flex: '1 0 auto',
            alignSelf: 'center',
            padding: '56.25% 0 0 0',

            breakpointState: {
                default: {
                    height: '0',
                    width: '100%',
                    overflow: 'hidden',
                },
                sm: {
                    width: '20%',
                },
            },
        },
        image: {
            breakpointState: {
                default: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: 'auto',
                },
            },
        },
    }
    return theme
}

export const theNightlyBioBlockTheme = (scheme: TheNightlyTheme) => {
    const theme: BioBlockTheme<TheNightlyThemeConfig> = {
        margin: calcRem(0),
        gap: {
            default: calcRem(24),
            sm: calcRem(32),
        },

        breakpointState: {
            default: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: calcRem(40, 24),
                maxWidth: calcRem(770),
            },
            sm: {
                flexDirection: 'row',
                alignItems: 'start',
                justifyContent: 'center',
                padding: calcRem(0, 0, 32, 0),
            },
        },
        text: {
            breakpointState: {
                default: {
                    flex: '1 1 100%',
                    textAlign: 'center',
                    alignSelf: 'center',
                },
                sm: {
                    flex: '1 1 auto',
                    textAlign: 'initial',
                    width: '50%',
                },
            },
        },
        author: {
            lineHeight: calcRem(48),
            fontSize: {
                default: calcRem(28),
                lg: calcRem(40),
            },
            color: scheme.colors.text.heading,
        },
        name: {
            marginBottom: {
                default: calcRem(8),
            },
            fontSize: calcRem(40),
            flex: '0 0 auto',
        },
        bioText: {
            lineHeight: calcRem(24),
            color: neutral[70],
            margin: calcRem(0, 0, 24, 0),
        },
        imageContainer: {
            flex: '0 0 auto',

            breakpointState: {
                default: {
                    width: calcRem(160),
                    height: calcRem(160),
                    borderRadius: '50%',
                    overflow: 'hidden',
                },
            },
        },
        image: {
            objectFit: 'cover',
            filter: 'grayscale(100%)',
            breakpointState: {
                default: {
                    width: '100%',
                    height: '100%',
                },
            },
        },
        divider: {
            width: '100%',
            height: calcRem(0.5),
            backgroundColor: neutral[20],

            margin: {
                default: calcRem(16, 0),
                sm: calcRem(0),
            },
        },
        header: {
            fontWeight: 700,
            margin: '0',
            width: '100%',

            breakpointState: {
                default: {
                    fontSize: calcRem(20),
                    lineHeight: calcRem(24),
                    letterSpacing: '-0.44px',
                },
                sm: {
                    fontSize: calcRem(25),
                    lineHeight: calcRem(32),
                    letterSpacing: '-0.55px',
                },
            },
        },
    }
    return theme
}
