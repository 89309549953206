import { metrics } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'

export function topicParallax() {
    return [
        layout.component({
            type: 'ad-unit',
            feature: 'running-parallax',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                breakout: {
                    kind: 'percentage',
                    value: 5,
                    breakpoint: metrics.thewest.siteMetrics.mainContentWidth,
                },
                padding: [0, 0, metrics.thewest.margins.lg, 0],
                slot: {
                    id: 'parallax',
                    size: 'parallax',
                },
                adType: 'inline',
            },
        }),
        ...billboardLeaderboardMrec('parrallax'),
    ]
}
