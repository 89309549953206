import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateTopicListingResponse } from '../../../__content-api/v4/get-topic-listing'
import { Paging } from '../../../__content-api/v4/paging'

export interface Options {
    isSponsored?: boolean
    topics?: string[]
    parentTopics?: string[]
    publicationKind?: 'article' | 'gallery'
    paging?: Paging
    source?: string
    publishedSince?: string
    profiles?: boolean
    includePremium?: boolean
    includeVideo?: boolean
    sort?: string
}

export const getSearchPublications = (
    services: DataLoaderGlobalParams,
    keywords: string,
    options: Options,
) => {
    const include_premium =
        // option defaults to true, which is then confirmed/denied by the feature toggle
        // see commments in https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/1501/overview
        options.includePremium !== false &&
        isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'digital-subscriptions',
            false,
        )

    const { paging = {} as Paging } = options
    const query = {
        page: paging.page || 1,
        page_size: paging.pageSize || 8,
        page_offset: paging.pageOffset || 0,
        idOrKeyword: keywords,
        isSponsored: options.isSponsored,
        kind: options.publicationKind,
        topics: options.topics,
        parentTopic: options.parentTopics,
        source: options.source,
        publishedSince: options.publishedSince,
        profiles: options.profiles,
        include_premium: include_premium || undefined,
        sort: options.sort || 'desc',
        includeVideo: options.includeVideo,
        excludeRegional: false,
    }

    return httpGet({
        log: services.log,
        validate: validateTopicListingResponse,
        baseUrl: services.config.contentApi,
        path: `v4/publication/`,
        query,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}
