import { Theme } from '@emotion/react'
import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { playButtonClass } from '../../buttons/PlayButton/PlayButton.styled'
import { TheWestHeroCardClassName } from '../../cards/TheWest/HeroCard/HeroCard.styled'
import { StyledHeroSupportCardClassName } from '../../cards/TheWest/HeroSupportCard/HeroSupportCard.styled'
import {
    StyledContentWrapClassName,
    StyledLargeCardClassName,
} from '../../cards/TheWest/LargeCard/LargeCard.styled'
import { StyledUniformCardContainerClassName } from '../../collections/Uniform/Uniform.styled'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    verticalDividers,
} from '../../__styling'

const silver = tokens['thewest'].colors.palette.silver
const westBlue = tokens['thewest'].colors.palette.westblue

/**
 * Short form way of setting vertical dividers with a specific condition
 * @param condition
 * Condition to display the divider
 * @param theme
 * Theme object, used to set the color
 * @returns
 * A CSSObject
 */
export const generateDivider = (condition: string, theme: Theme): CSSObject => {
    return {
        [condition]: {
            ...verticalDividers,
            backgroundColor: theme.colors.borders.primary,
        },
    }
}
export interface StyledDevelopingNewsProps {
    numUniformStories: number
}

export const StyledDevelopingNews = styled('div')<StyledDevelopingNewsProps>(
    ({ theme, numUniformStories }) => [
        {
            // Replicating Thor verticalGutters with * 2 (pad left + pad right)
            maxWidth: calcRem(theme.siteMetrics.mainContentWidth),
            margin: '0 auto',
        },
        {
            [`.${playButtonClass}::before`]: {
                // The Play Button is the only icon that cannot be coloured white and needs to stay west blue instead.
                borderLeftColor: westBlue,
            },
        },
        numUniformStories === 1 && {
            // Styles the rows/columns for tablet/desktop view for the uniform portion
            // Since we want the one story to take up the entire row, we don't define any gridTemplateColumns.
            [`.${StyledUniformCardContainerClassName}`]: {
                [breakpoint('sm')]: {
                    gridTemplateColumns: undefined,
                },
                [breakpoint('md')]: {
                    gridTemplateColumns: undefined,
                },
            },
            // Uniform has some default padding but it doesn't line up with the Sierra if there's only one card in the container.
            [`.${StyledContentWrapClassName}`]: {
                [breakpoint('md')]: {
                    padding: 0,
                },
            },
        },
        numUniformStories === 2 && {
            // Create two columns and add a vertical divider to the second card in the row (for tablet)
            // The desktop dividers are all generated at the bottom.
            [`.${StyledUniformCardContainerClassName}`]: {
                [breakpoint('sm')]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    ...generateDivider('& > :nth-child(2)::after', theme),
                },
                [breakpoint('md')]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                },
            },
        },
        numUniformStories === 3 && {
            // Create three columns and add two vertical divider to the second card in the row (for tablet)
            [`.${StyledUniformCardContainerClassName}`]: {
                [breakpoint('sm')]: {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    ...generateDivider(
                        '& > :is(:nth-child(2), :nth-child(3))::after',
                        theme,
                    ),
                },
                [breakpoint('md')]: {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                },
            },
        },
        numUniformStories === 4 && {
            [`.${StyledUniformCardContainerClassName}`]: {
                [breakpoint('sm')]: {
                    // Create two columns and two rows and add vertical dividers for every even card(tablet)
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    ...generateDivider('& > :nth-child(even)::after', theme),

                    position: 'relative',
                    // This after psuedo element is the divider between the two Uniform rows.
                    '&::after': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: calcRem(1),
                        backgroundColor: theme.colors.borders.primary,
                        position: 'absolute',
                        top: '50%',
                    },
                },
                [breakpoint('md')]: {
                    // Create four columns and one row
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridTemplateRows: '1fr',

                    '&::after': {
                        display: 'none',
                    },
                },
            },
        },
        numUniformStories === 5 && {
            [`.${StyledUniformCardContainerClassName}`]: {
                [breakpoint('sm')]: {
                    // Divides the two rows into six columns each
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gridTemplateRows: 'repeat(2, 1fr)',

                    // Each card in the first row spans two columns (total 3 cards so 6 columns)
                    [`& > a:nth-child(-n+3)`]: {
                        gridColumn: 'span 2',
                        gridRow: 'span 1',
                    },

                    // Fourth card spans 3 columns in the second row
                    ['& > a:nth-last-child(1)']: {
                        gridRow: '2',
                        gridColumn: '1 / span 3',
                    },

                    // Fifth card spans the last 3 columns
                    ['& > a:nth-last-child(2)']: {
                        gridRow: '2',
                        gridColumn: '4 / span 3',
                    },

                    // Vertical dividers span the last 3 cards
                    ...generateDivider(
                        '& > :is(:nth-child(3), :nth-child(2), :nth-child(4))::after',
                        theme,
                    ),

                    position: 'relative',
                    // Divider between first and second row.
                    '&::after': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: calcRem(1),
                        backgroundColor: theme.colors.borders.primary,
                        position: 'absolute',
                        top: '50%',
                    },
                },
                [breakpoint('md')]: {
                    // Five columns for desktop view
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridTemplateRows: '1fr',
                    // Reset gridColumn/gridRow vals from tablet viewport
                    [`& > a:nth-child(-n+3)`]: {
                        gridColumn: 'initial',
                        gridRow: 'initial',
                    },

                    ['& > a:nth-last-child(1)']: {
                        gridRow: 'initial',
                        gridColumn: 'initial',
                    },

                    ['& > a:nth-last-child(2)']: {
                        gridRow: 'initial',
                        gridColumn: 'initial',
                    },

                    '&::after': {
                        display: 'none',
                    },
                },
            },
        },
        {
            // dividers for desktop view
            [breakpoint('md')]: {
                [`.${StyledUniformCardContainerClassName}`]: {
                    '& > :not(:first-child)::after': {
                        ...verticalDividers,
                        backgroundColor: theme.colors.borders.primary,
                    },
                },
            },
        },
        {
            [breakpointMax('xs')]: {
                [`.${StyledLargeCardClassName}`]: {
                    borderBottom: `1px solid ${theme.colors.borders.primary}`,
                },
            },
        },
    ],
)

export const SierraClassName = 'DevelopingNews-Sierra'

// Purely used to style the child Sierra component (beats passing props)
export const StyledSierraModifier = styled('div')<{
    numUniformItems?: number
    numSierraItems?: number
}>(({ numSierraItems, numUniformItems, theme }) => [
    {
        // Removes borderBottom for the support cards
        [`.${StyledHeroSupportCardClassName}`]: {
            [breakpoint('md')]: {
                borderBottom: 'none',
            },
        },
        // Makes the Hero Card take up full height (issue in SierraCardContainer)
        [`.${TheWestHeroCardClassName}`]: {
            [breakpoint('sm')]: { height: '100%' },
        },
    },
    numSierraItems === 1 && {
        // We don't want a bottom border for the hero card if there's just one story,
        // outside of the desktop viewport.
        [`.${TheWestHeroCardClassName}`]: {
            borderBottom: 'none',

            [breakpoint('md')]: {
                borderBottom: `1px solid ${theme.colors.borders.secondary}`,
            },
        },
    },
    numUniformItems === 0 && {
        // Remove the bottom border of the final card if there are no uniform items.
        [`.${SierraClassName} > a:nth-last-child(1) > .${StyledHeroSupportCardClassName}`]:
            {
                borderBottom: 'none',
            },
    },
])

export const StyledHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    marginBottom: calcRem(theme.margins.md),

    [breakpoint('md')]: {
        marginBottom: calcRem(41),
    },

    '&::after': {
        content: '""',
        display: 'block',
        width: calcRem(48),
        height: calcRem(4),
        backgroundColor: theme.colors.palette.white,
        position: 'absolute',
        bottom: calcRem(-1 * theme.margins.sm),
    },
}))

export const StyledArticleContainer = styled('div')({})

export const StyledHeadline = styled('h2')(({ theme }) => ({
    color: theme.colors.text.heading,
    fontWeight: 700,
    fontSize: calcRem(22),
    lineHeight: calcRem(24),
    fontFamily: theme.fonts.serif,
    margin: 0,
}))

export const StyledContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    // Magic number for left/right padding to get it to line up with the mainContentSection's thor underneath.
    padding: calcRem(theme.margins.md, 10),
}))

export const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#001D56',
    marginBottom: calcRem(theme.margins.md),
}))
