import { createRegisterableComponent } from '@news-mono/web-common'
import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { PerthNowProfile } from '../../content/Profile/PerthNowProfile'
import { SevenNewsProfile } from '../../content/Profile/SevenNewsProfile'
import { TheWestProfile } from '../../content/Profile/TheWestProfile'
import { ThemedRender } from '../../__styling/themed-render'
import { TheNightlyProfile } from './TheNightlyProfile'

export interface ProfileRouteProps {
    profileUrl: string
    profile: ProfileV4DTO
}

export const ProfileRegistration = createRegisterableComponent(
    'profile',
    ({ profileUrl, profile }: ProfileRouteProps) => {
        const profileProps = { profile: profile, profileUrl }

        return (
            <ThemedRender
                perthnow={() => <PerthNowProfile {...profileProps} />}
                sevennews={() => <SevenNewsProfile {...profileProps} />}
                thewest={() => <TheWestProfile {...profileProps} />}
                thenightly={() => <TheNightlyProfile {...profileProps} />}
            />
        )
    },
)
