import { getThemedSegment } from './get-themed-segment'

export const commonwealthGamesSegment = () => {
    return getThemedSegment({
        featureToggle: 'commonwealth-games-4card-curation',
        layoutVersion: '4-card',
        curation: 'commonwealth-games',
        themeOverride: 'commonwealth-games',
        cardExtensionProps: {
            fourCardExtension: {
                kind: '4-card',
                limaProps: {
                    hideBottomBorder: true,
                    hideEditorialType: true,
                },
            },
        },
    })
}
