import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { RippleAd, RippleProps } from '../../advertising/Ripple/Ripple'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'

export const RippleRegistration = createRegisterableComponent(
    'ripple',
    (props: RippleProps, services) => {
        const pathname = services.location.pathname

        return (
            <FeatureToggle
                feature="ripple"
                on={() => <RippleAd key={`ripple-ad${pathname}`} {...props} />}
            />
        )
    },
)
