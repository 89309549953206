import styled from '@emotion/styled'
import { CardBodyWrapper, Headline, ReadMore } from '../components'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { WebLink } from '@news-mono/web-common'
import { calcRem, TheNightlyThemeConfig } from '../../../__styling'

export const StyledCardBodyWrapper = styled(CardBodyWrapper)({
    padding: 'unset',
})

export const StyledHeadline = styled(Headline)(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'article'),
)

export const CardLinkWrapper = styled(WebLink)({
    display: 'flex',
    flexDirection: 'column',

    // Remove default anchor styling.
    color: 'unset',
    textDecoration: 'none',
    position: 'relative',

    // Underline Headline when hovered or active.
    [`&:is(:hover, :focus) ${StyledHeadline}`]: {
        textDecoration: 'underline',
    },
})

export const StyledIconWrapper = styled('div')(({ theme }) => ({
    height: calcRem(12),
    width: calcRem(12),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.text.secondary,
}))
