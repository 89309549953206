import styled from '@emotion/styled'

export const StyledReadMoreWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
})

export const ReadMoreStyled = styled.div(({ theme }) => ({
    alignSelf: 'flex-end',
    fontFamily: theme.fonts.sansSerif,
    fontSize: 16,
    textDecoration: 'underline',
    color: theme.colors.text.tertiary,
}))
