import { PublicationCardItemWithVideo } from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import { createLinkToSeriesEpisode } from './createLinkToSeriesEpisode'

export function mapVideoMetaToCard(
    section: string,
    series: string,
    season: number,
    publicUrl: string,
    videoMeta: VideoMetaDTO,
): PublicationCardItemWithVideo {
    const trailerHeadline = videoMeta.name || videoMeta.description
    const teaser = videoMeta.name ? videoMeta.description : ''
    const link = createLinkToSeriesEpisode(
        section,
        series,
        season,
        trailerHeadline,
        videoMeta,
    )

    return {
        cardType: 'publication',
        id: videoMeta.id,
        headline: trailerHeadline,
        shortHeadline: trailerHeadline,
        link,
        _self: `${publicUrl}${link}`,
        primaryTopic: {
            id: '',
            metadata: {},
            seoTitle: videoMeta.primaryTopic,
            title: videoMeta.primaryTopic,
        },
        secondaryTopics: [],
        kicker: `Episode ${videoMeta.seriesInfo?.episode}`,
        byline: videoMeta.byline || '',
        publicationKind: 'article',
        source: videoMeta.source ?? '',
        teaser,
        isLive: false,
        isSponsored: false,
        requiredAccess: { level: 'anonymous' },
        allowCommenting: false,
        image: videoMeta.posterImage
            ? {
                  altText: videoMeta.posterImage.altText || '',
                  captionText: videoMeta.posterImage.captionText || '',
                  byline: videoMeta.posterImage.byline || '',
                  credit: videoMeta.posterImage.credit || '',
                  crops: {
                      '16:9':
                          videoMeta.posterImage.ratio === '16:9'
                              ? {
                                    ratio: { width: 16, height: 9 },
                                    height: videoMeta.posterImage.height,
                                    reference: videoMeta.posterImage.reference,
                                    width: videoMeta.posterImage.width,
                                }
                              : undefined,
                  },
              }
            : undefined,
        video: {
            id: videoMeta.id,
            accountId: videoMeta.accountId,
            heading: videoMeta.name ?? series,
            source: videoMeta.source ?? undefined,
            duration: `${videoMeta.duration}`,
            videoType: 'vod',
        },
        readTimeMinutes: null,
    }
}

export function mapSearchVideoMetaToCard(
    videoMeta: VideoMetaDTO,
): PublicationCardItemWithVideo {
    const trailerHeadline = videoMeta.name || videoMeta.description
    const teaser = videoMeta.name ? videoMeta.description : ''
    const fullUrl = `/video/${videoMeta.secondaryTopics[0]}/${videoMeta.slug}`
    return {
        cardType: 'publication',
        id: videoMeta.id,
        headline: trailerHeadline,
        shortHeadline: trailerHeadline,
        link: fullUrl,
        _self: fullUrl,
        primaryTopic: {
            id: '',
            metadata: {},
            seoTitle: videoMeta.primaryTopic,
            title: videoMeta.primaryTopic,
        },
        secondaryTopics: [],
        kicker: videoMeta.description,
        byline: videoMeta.byline || '',
        publicationKind: 'article',
        publicationDate: videoMeta.publicationDate,
        source: videoMeta.source ?? '',
        teaser,
        isLive: false,
        isSponsored: false,
        requiredAccess: { level: 'anonymous' },
        allowCommenting: false,
        image: videoMeta.posterImage
            ? {
                  altText: videoMeta.posterImage.altText || '',
                  captionText: videoMeta.posterImage.captionText || '',
                  byline: videoMeta.posterImage.byline || '',
                  credit: videoMeta.posterImage.credit || '',
                  crops: {
                      '16:9':
                          videoMeta.posterImage.ratio === '16:9'
                              ? {
                                    ratio: { width: 16, height: 9 },
                                    height: videoMeta.posterImage.height,
                                    reference: videoMeta.posterImage.reference,
                                    width: videoMeta.posterImage.width,
                                }
                              : undefined,
                  },
              }
            : undefined,
        video: {
            id: videoMeta.id,
            accountId: videoMeta.accountId,
            heading: videoMeta.name ?? '',
            source: videoMeta.source ?? undefined,
            duration: `${videoMeta.duration}`,
            videoType: 'vod',
        },
        readTimeMinutes: null,
    }
}

// ;`${config.publicUrl}/video/${dtoMeta.secondaryTopics[0]}/${video.slug}`
