import React from 'react'
import { Sharing } from '../../../buttons/Sharing/Sharing'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import {
    AllEvents,
    PublicationCardItemWithVideo,
    ShareOption,
} from '@news-mono/web-common'
import { StyledSharingWrapper } from './Share.styled'

export interface ShareProps {
    article?: ArticleLikeV4DTO
    videoItem?: PublicationCardItemWithVideo
    onEvent: (event: AllEvents) => void
    url?: string
    text?: string
    sharingOptions?: ShareOption[]
    isLiveBlog?: boolean
}
export interface StaticShareProps {
    share: { url: string; text: string }
    onEvent: (event: AllEvents) => void
}

export const Share: React.FC<ShareProps> = ({
    article,
    onEvent,
    videoItem,
    url,
    text,
    sharingOptions,
    isLiveBlog,
}) => {
    const shareUrl = url
        ? url
        : article
        ? article._self
        : videoItem?._self || ''
    const shareText = text
        ? text
        : article
        ? article.heading
        : videoItem?.teaser || ''

    return (
        <StyledSharingWrapper isLiveBlog={isLiveBlog}>
            <Sharing
                url={shareUrl}
                text={shareText}
                isGhostStyle={true}
                onEvent={onEvent}
                shareOptions={
                    sharingOptions
                        ? sharingOptions
                        : [
                              'clipboard',
                              'facebookRounded',
                              'linkedin',
                              'email',
                              'x',
                          ]
                }
            />
        </StyledSharingWrapper>
    )
}

export const StaticShare: React.FC<StaticShareProps> = ({
    share,
    onEvent,
}: StaticShareProps) => {
    return (
        <StyledSharingWrapper>
            <Sharing
                url={share.url}
                text={share.text}
                isGhostStyle={true}
                onEvent={onEvent}
                shareOptions={[
                    'clipboard',
                    'facebookRounded',
                    'linkedin',
                    'email',
                    'x',
                ]}
            />
        </StyledSharingWrapper>
    )
}
