import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    Intention,
    LinkClickedEvent,
    useToggleState,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { StyledParagraph } from '../../publication/Paragraph/Paragraph.styled'
import { useProduct } from '../../__product'

export interface ParagraphProps {
    text: string
    intentions?: Intention[]
    tag?: 'p'
    onEvent: (event: LinkClickedEvent) => void
}

export const Paragraph: React.FC<ParagraphProps> = (props) => {
    const toggles = useToggleState()
    const product = useProduct()
    const location = useLocation()
    const isNoFollow =
        product &&
        location &&
        product === 'sevennews' &&
        location.pathname.startsWith('/best-picks')

    const isFontIncreasedOnTheWestArticle = isFeatureEnabled(
        toFeatureState(toggles),
        'the-west-increased-article-font',
    )
    const [isIncreasedFont, setFontIncrease] = useState(false)

    useEffect(() => {
        setFontIncrease(isFontIncreasedOnTheWestArticle)
    }, [isFontIncreasedOnTheWestArticle])

    return (
        <StyledParagraph
            tag="p"
            text={props.text}
            intentions={props.intentions}
            onEvent={props.onEvent}
            isIncreasedFont={isIncreasedFont}
            isNoFollow={isNoFollow}
        />
    )
}
Paragraph.displayName = 'Paragraph'
