import { useTheme } from '@emotion/react'
import {
    BreakpointRatios,
    FixedRatio,
    ImageSource,
    RenderTargetContext,
} from '@news-mono/web-common'
import { LinkDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'
import {
    StyledArticleImage,
    StyledFigcaption,
    StyledFigure,
    StyledImageLink,
} from '../../publication/ArticleImage/ArticleImage.styled'
import {
    FigcaptionLayout,
    FigCaptionProps,
} from '../../publication/FigCaption/FigCaption'
import { createRenderTarget } from '../../render-target'
import { themedValue } from '../../__styling/themed-value'
import { FetchPriority } from '../../content/Picture/Image.web.types'

export type FigcaptionRenderOptions = 'hidden' | 'feature-article-position'

export interface ArticleImageProps extends ResponsiveContainer {
    align?: 'left' | 'right'
    isTwin?: true
    image: ImageSource
    imageRenderType?: 'feature'
    figCaption?: FigCaptionProps
    isHeroImage?: boolean
    hideCaption?: boolean
    className?: string
    editButtons?: React.ReactElement<any>
    figcaptionRenderOptions?: FigcaptionRenderOptions
    link?: LinkDTO | undefined
    imageFetchPriority?: FetchPriority
    isHeroImageAmp?: boolean
}

const hiddenCaptionRegex = /^\s*(Promo|Logos?|Call To Action)\s*:\s*/i

function isHiddenCaption(captionText?: string) {
    return captionText?.match(hiddenCaptionRegex)
}

const shouldShowFigcaption = (
    figCaption?: FigCaptionProps,
    figcaptionRenderOptions?: FigcaptionRenderOptions,
): figcaptionRenderOptions is FigcaptionLayout | undefined => {
    if (!figCaption || figcaptionRenderOptions === 'hidden') {
        return false
    }

    const { captionText, credit, byline } = figCaption

    if (isHiddenCaption(captionText)) {
        return false
    }

    if (captionText || credit || byline) {
        return true
    }

    return false
}

const WebArticleImage: React.FC<ArticleImageProps> = (props) => {
    const {
        align,
        image,
        isTwin,
        figCaption,
        isHeroImage,
        imageFetchPriority,
        className,
        figcaptionRenderOptions,
        imageRenderType,
        containerWidthRatios,
        link,
        isHeroImageAmp,
        ...extraProps
    } = props
    const theme = useTheme()
    const imageLayout =
        imageRenderType === 'feature'
            ? ResponsivePictureLayout.ObjectFitCover
            : ResponsivePictureLayout.ObjectFitContain
    const { renderTarget } = React.useContext(RenderTargetContext)

    // if the render type is a feature we add a breakpoint ratio fallback of 4:3, otherwise existing
    // logic is followed
    const fixedRatioOrBreakpointRatio: FixedRatio | BreakpointRatios =
        isHeroImage
            ? imageRenderType === 'feature'
                ? { default: '16:9', sm: '4:3' }
                : '16:9'
            : image.userSelectedCrop || 'original'

    const articleImage = (
        <StyledArticleImage
            isHeroImage={isHeroImage}
            isHeroImageAmp={isHeroImageAmp}
            image={image}
            imageFetchPriority={imageFetchPriority}
            layout={imageLayout}
            fixedRatio={fixedRatioOrBreakpointRatio}
            imageWidths={applyContainerRatio(
                containerWidthRatios,
                themedValue(theme, {
                    thewest: {
                        mobile: '90vw',
                        tablet: '90vw',
                        desktop:
                            imageRenderType === 'feature' ? '1224px' : '810px',
                        fallbackWidth:
                            imageRenderType === 'feature' ? 1224 : 810,
                    },
                    perthnow: {
                        mobile: '100vw',
                        tablet: '100vw',
                        desktop: '668px',
                        fallbackWidth: 668,
                    },
                    sevennews: {
                        mobile: '95vw',
                        tablet: '50vw',
                        desktop: '650px',
                        fallbackWidth: 650,
                    },
                    fallback: {
                        mobile: '90vw',
                        tablet: '90vw',
                        desktop:
                            imageRenderType === 'feature' ? '1224px' : '810px',
                        fallbackWidth:
                            imageRenderType === 'feature' ? 1224 : 810,
                    },
                }),
            )}
            disableLazyLoad={
                isHeroImage
                    ? true
                    : themedValue(theme, {
                          thewest: true,
                          perthnow: true,
                          sevennews: false,
                          fallback: false,
                      })
            }
            imageRenderType={imageRenderType}
            renderPicture={(picture) => {
                const captionedPicture = (
                    <React.Fragment>
                        {picture}
                        {shouldShowFigcaption(
                            figCaption,
                            figcaptionRenderOptions,
                        ) && (
                            <StyledFigcaption
                                isHeroImage={isHeroImage}
                                figcaptionLayout={figcaptionRenderOptions}
                                {...figCaption}
                            />
                        )}
                    </React.Fragment>
                )

                return (
                    <StyledFigure
                        isHeroImage={isHeroImage}
                        align={align}
                        isTwin={isTwin}
                        className={className}
                        aria-labelledby={
                            figcaptionRenderOptions === 'hidden'
                                ? 'media-caption'
                                : undefined
                        }
                        imageRenderType={imageRenderType}
                        // For draftjs to handle clicks etc
                        {...extraProps}
                    >
                        {link && renderTarget !== 'rss' ? (
                            <StyledImageLink
                                to={link.href}
                                title={link.title}
                                openExternalInNewWindow={
                                    link.target === '_blank'
                                }
                            >
                                {captionedPicture}
                            </StyledImageLink>
                        ) : (
                            captionedPicture
                        )}
                    </StyledFigure>
                )
            }}
        />
    )
    return articleImage
}
WebArticleImage.displayName = 'WebArticleImage'

export const ArticleImage = createRenderTarget<ArticleImageProps>(
    'ArticleImage',
    {
        web: WebArticleImage,
        amp: WebArticleImage,
        rss: WebArticleImage,
        preview: WebArticleImage,
        app: WebArticleImage,
    },
)
