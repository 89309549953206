import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TelethonCountdown, TelethonCountdownProps } from './TelethonCountdown'

export const TelethonCountdownRegistration = createRegisterableComponent(
    'telethon-countdown',
    ({ targetDate }: TelethonCountdownProps) => {
        return <TelethonCountdown targetDate={targetDate} />
    },
)
