import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { FlourishIframe, FlourishIframeProps } from './FlourishIframe'

export const FlourishIframeRegistration = createRegisterableComponent(
    'flourish-iframe',
    (props: FlourishIframeProps) => {
        return (
            <FlourishIframe
                imgHeight={props.imgHeight}
                imgWidth={props.imgWidth}
                link={props.link}
                height={props.height}
            ></FlourishIframe>
        )
    },
)
