import { Profile } from '@news-mono/web-common'

export function getProfileLink(
    profiles: Profile[] | undefined,
): string | undefined {
    if (!profiles) {
        return undefined
    }

    for (const profile of profiles) {
        if (profile.thumbnailPhoto && profile.slug) {
            return `/profile/${profile.slug}`
        }
    }
    return undefined
}
