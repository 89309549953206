import { ElectionDayData } from '@west-australian-newspapers/contentful-retrieval-utilities'
import * as React from 'react'
import {
    StyledBarGraph,
    StyledBarGraphContainer,
    StyledBarGraphMarkerText,
    StyledBarGraphParty,
    StyledBarGraphPartyContainer,
    StyledBarGraphPartyLabelContainer,
    StyledBarGraphPartyLikelyText,
    StyledBarGraphRowLabel,
    StyledBarGraphRowsContainer,
    StyledPartyNameLabel,
    StyledSeatsLabel,
    StyledSeatsRemaining,
} from './BarGraphWidget.styled'

export const totalSeats = 151

export const BarGraphWidget: React.FunctionComponent<ElectionDayData> = (
    props,
) => {
    const parties = [
        {
            partyName: 'Coalition',
            seats: {
                won: props.coalition,
                likely: props.coalitionLikely,
            },
        },
        {
            partyName: 'Labor',
            seats: {
                won: props.labor,
                likely: props.laborLikely,
            },
        },
        {
            partyName: 'Greens',
            seats: {
                won: props.greens,
                likely: props.greensLikely,
            },
        },
        {
            partyName: 'Other',
            seats: {
                won: props.other,
                likely: props.otherLikely,
            },
        },
    ]

    const partiesList = parties.map((party) => {
        return (
            <StyledBarGraphPartyContainer key={party.partyName}>
                <StyledBarGraphParty>
                    <div
                        className="bar-graph__bar"
                        data-party={party.partyName.toLowerCase()}
                    >
                        <div className="bar-graph__label">
                            {party.seats.won} {party.partyName}
                        </div>
                        <div
                            className="bar-graph__seats-likely"
                            style={{
                                width:
                                    ((party.seats.likely + party.seats.won) /
                                        totalSeats) *
                                        100 +
                                    '%',
                            }}
                            data-count={party.seats.likely}
                            aria-label={
                                party.partyName +
                                ' +' +
                                party.seats.likely +
                                ' seats likely'
                            }
                            data-party={party.partyName.toLowerCase()}
                        />
                        <div
                            className="bar-graph__seats-won"
                            style={{
                                width:
                                    (party.seats.won / totalSeats) * 100 + '%',
                            }}
                            aria-label={
                                party.partyName +
                                ' ' +
                                party.seats.won +
                                ' seats won'
                            }
                        />
                        {party.seats.likely !== 0 && (
                            <StyledBarGraphPartyLikelyText
                                totalPartySeats={
                                    party.seats.likely + party.seats.won
                                }
                            >
                                + {party.seats.likely}
                            </StyledBarGraphPartyLikelyText>
                        )}
                    </div>
                </StyledBarGraphParty>
            </StyledBarGraphPartyContainer>
        )
    })

    let totalSeatsWon = 0

    for (let i = 0; i < parties.length; i++) {
        totalSeatsWon = totalSeatsWon + parties[i].seats.won
    }

    const seatsUndecided = totalSeats - totalSeatsWon

    return (
        <StyledBarGraphContainer>
            <StyledBarGraphPartyLabelContainer>
                <StyledBarGraphRowLabel>
                    <StyledSeatsLabel>{parties[0].seats.won}</StyledSeatsLabel>{' '}
                    <StyledPartyNameLabel>
                        {parties[0].partyName}
                    </StyledPartyNameLabel>
                </StyledBarGraphRowLabel>
                <StyledBarGraphRowLabel>
                    <StyledSeatsLabel>{parties[1].seats.won}</StyledSeatsLabel>{' '}
                    <StyledPartyNameLabel>
                        {parties[1].partyName}
                    </StyledPartyNameLabel>
                </StyledBarGraphRowLabel>
                <StyledBarGraphRowLabel>
                    <StyledSeatsLabel>{parties[2].seats.won}</StyledSeatsLabel>{' '}
                    <StyledPartyNameLabel>
                        {parties[2].partyName}
                    </StyledPartyNameLabel>
                </StyledBarGraphRowLabel>
                <StyledBarGraphRowLabel>
                    <StyledSeatsLabel>{parties[3].seats.won}</StyledSeatsLabel>{' '}
                    <StyledPartyNameLabel>
                        {parties[3].partyName}
                    </StyledPartyNameLabel>
                </StyledBarGraphRowLabel>
                <StyledBarGraphRowLabel>
                    <StyledSeatsRemaining>
                        <span>{seatsUndecided}&nbsp;</span>
                        <span>SEATS REMAINING</span>
                    </StyledSeatsRemaining>
                </StyledBarGraphRowLabel>
            </StyledBarGraphPartyLabelContainer>
            <StyledBarGraphRowsContainer>
                <StyledBarGraphMarkerText>76 TO WIN</StyledBarGraphMarkerText>
                <StyledBarGraph>{partiesList}</StyledBarGraph>
            </StyledBarGraphRowsContainer>
        </StyledBarGraphContainer>
    )
}
