import React from 'react'

import { StyledLoadingEllipsis } from './LoadingEllipsis.styled'

export interface LoadingEllipsisProps {
    color?: string
}

export const LoadingEllipsis: React.FC<LoadingEllipsisProps> = ({ color }) => {
    return <StyledLoadingEllipsis color={color} />
}
