import React from 'react'
import {
    StyledPointer,
    StyledPointerImg,
    StyledPointerLink,
    StyledPointerPicture,
} from '../../content/Pointer/Pointer.styled'

export interface PointerProps {
    pointerLinkUrl: string
    pointerImageAlt: string
    pointerImageSrc: string
}

export const Pointer: React.FC<PointerProps> = (props) => {
    const { pointerImageAlt, pointerImageSrc, pointerLinkUrl } = props

    if (!pointerImageSrc) {
        return null
    }

    return (
        <StyledPointer>
            <StyledPointerLink to={pointerLinkUrl}>
                <StyledPointerPicture>
                    <StyledPointerImg
                        src={pointerImageSrc}
                        alt={pointerImageAlt}
                    />
                </StyledPointerPicture>
            </StyledPointerLink>
        </StyledPointer>
    )
}

Pointer.displayName = 'Pointer'
