import { Profile, PublicationCardItem } from '@news-mono/web-common'
import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'
import React, { Fragment } from 'react'

import {
    StyledArticleByline,
    StyledArticleBylineAuthor,
    StyledArticleBylineAuthorLink,
    StyledAuthorProfilesWrap,
    StyledAuthorThumbnail,
    StyledTimeAndProfilesWrap,
} from './ByLine.styled'
import { StyledEstimatedTime } from '../ArticleHeroCard/ArticleHeroCard.styled'
import { reorderAuthors } from './reorder-authors'

const authorDefaultImage = require('./assets/author-image.jpg')
const nbcImage = require('./assets/nbc.svg')
const appImage = require('./assets/aap.svg')
const cnnImage = require('./assets/cnn.svg')
const apImage = require('./assets/ap.svg')
const theConversationImage = require('./assets/conversation.svg')
const theNightlyImage = require('./assets/nightly.svg')

const renderProfilePill = (
    profile: Profile,
    source: string,
    byline: string | undefined,
) => {
    return (
        <>
            {profile.thumbnailPhoto ? (
                <AuthorProfileLink profile={profile}>
                    <StyledAuthorThumbnail
                        isLast={true}
                        src={profile.thumbnailPhoto}
                        alt={profile.name}
                    />
                </AuthorProfileLink>
            ) : (
                <StyledAuthorThumbnail
                    isLast={true}
                    src={getThumbnail(source)}
                    alt={profile.name}
                />
            )}
            {
                <AuthorProfileLink profile={profile}>
                    {byline ? (
                        `By ${byline}`
                    ) : (
                        <span itemProp="name">By {profile.name}</span>
                    )}
                </AuthorProfileLink>
            }
        </>
    )
}
const renderProfilePillWithMoreNames = (
    profiles: Profile[],
    override?: string,
) => {
    const sortedProfiles = reorderAuthors(profiles, override)

    return (
        <>
            <StyledAuthorThumbnail isLast={true} src={authorDefaultImage} />
            <StyledTimeAndProfilesWrap>
                <StyledAuthorProfilesWrap>
                    {sortedProfiles.map((profile, index) => (
                        <Fragment key={index}>
                            {(index === profiles.length - 1 && ' and ') ||
                                (index > 0 && ', ')}
                            <AuthorProfileLink profile={profile} key={index}>
                                {profile.name}
                            </AuthorProfileLink>
                        </Fragment>
                    ))}
                </StyledAuthorProfilesWrap>
            </StyledTimeAndProfilesWrap>
        </>
    )
}
export interface ByLineProps {
    profiles: ProfileV4DTO[] | Profile[] | undefined
    byline: string | undefined
    source: string
    hasBackground?: boolean
    isLiveBlog?: boolean
    readTimeMinutes?: number | null
}
export function ByLine({
    profiles,
    byline,
    hasBackground,
    isLiveBlog,
    source,
    readTimeMinutes,
}: ByLineProps) {
    if (profiles && profiles.length > 0 && profiles.length < 2) {
        return (
            <>
                {profiles.map((profile) => {
                    return (
                        <StyledArticleByline
                            hasBackground={hasBackground}
                            isLiveBlog={isLiveBlog}
                        >
                            {renderProfilePill(profile, source, byline)}
                        </StyledArticleByline>
                    )
                })}
            </>
        )
    } else if (profiles && profiles?.length >= 2) {
        return (
            <StyledArticleByline hasBackground={hasBackground}>
                {renderProfilePillWithMoreNames(profiles, byline)}
            </StyledArticleByline>
        )
    }
    return (
        <StyledArticleByline hasBackground={hasBackground}>
            <StyledAuthorThumbnail
                isLast={true}
                src={getThumbnail(source)}
                alt={byline || ''}
            />
            <StyledArticleBylineAuthor>
                By {String(byline)}
            </StyledArticleBylineAuthor>
        </StyledArticleByline>
    )
}

interface ProfileProp {
    profile: Profile
}
const AuthorProfileLink: React.FC<ProfileProp> = ({ profile, children }) => {
    return (
        <StyledArticleBylineAuthorLink
            to={`/profile/${profile.slug}`}
            itemScope
            itemType="http://schema.org/Person"
        >
            {children}
        </StyledArticleBylineAuthorLink>
    )
}

function getThumbnail(source: string | undefined) {
    switch (source) {
        case 'NBC':
            return nbcImage
        case 'CNN':
            return cnnImage
        case 'AP':
            return apImage
        case 'AAP/7NEWS':
        case 'AAP':
            return appImage
        case 'The Conversation':
            return theConversationImage
        case 'The Nightly':
            return theNightlyImage
        default:
            return authorDefaultImage
    }
}
