import styled from '@emotion/styled'
import { breakpoint } from '../../../__styling/style-functions'
import { getComponentTheme } from '../../../__styling'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

export const AccordionContainer = styled.div({
    [breakpoint('lg')]: {
        display: 'none',
    },
})

export const Divider = styled.div(({ theme }) => {
    const tagLinkTheme = getComponentTheme(theme, 'taglinkNav').divider
    return {
        margin: calcRem(0, 24, 24, 24),
        display: 'flex',
        height: calcRem(1),
        background: tagLinkTheme.color,
    }
})
