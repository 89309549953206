import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledFooterContent = styled('footer')(({ theme }) => ({
    fontWeight: 400,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(13),
    padding: calcRem(
        metrics.thewest.margins.xl,
        0,
        metrics.thewest.margins.xxl,
        0,
    ),
    display: 'grid',
    gridGap: '30px 5vw',
    gridTemplateColumns: '1fr',

    [breakpoint('xs')]: {
        gridTemplateColumns: '1fr 1fr',
        gridRow: '3fr',
    },

    // This just switches to a 3 col on regional sites, due to overlapping items.
    [breakpoint('sm')]: {
        gridTemplateColumns:
            theme.section !== 'default'
                ? 'minmax(0, 1fr) 300px'
                : 'repeat(4, 1fr)', // required on regionals to prevent overlapping
    },

    // Goes back to a 4 col for all types of sites at 900px - which is basically at an optimal point
    '@media (min-width: 900px)': {
        gridTemplateColumns:
            theme.section !== 'default' ? '2fr 1fr' : 'repeat(4, 1fr)',
    },
}))

export const StyledList = styled('ul')({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'column wrap',

    [breakpoint('sm')]: {
        maxHeight: '300px',
    },
})

export const StyledBlock = styled('div')(({ theme }) => ({
    gridRow: 'auto / span 3',

    [breakpoint('sm')]: {
        '&:nth-child(3)': {
            gridColumn: theme.section !== 'default' ? 'auto' : '4',
            gridRow: 'auto',
        },

        '&:nth-child(4)': {
            gridColumn: theme.section !== 'default' ? 'auto' : '4',
            gridRow: 2,
        },
    },
}))

export const StyledBlockHeader = styled('h2')(({ theme }) => ({
    fontWeight: 400,
    fontSize: calcRem(16),
    color: colors.black,
    textTransform: 'uppercase',
    margin: calcRem(0, 0, metrics.thewest.margins.md, 0),
    fontFamily: theme.fonts.serif,
    position: 'relative',

    '&::before': {
        content: `''`,
        position: 'absolute',
        bottom: -8,
        left: 0,
        width: 31, //The length of the AFL text so it doesn't look weird
        height: 3,
        backgroundColor: colors.black,
    },
}))

export const StyledText = styled('p')(({ theme }) => ({
    display: 'block',
    fontWeight: 400,
    color: colors.thewest.greyElephant,
    margin: `0 0 ${calcRem(metrics.thewest.margins.sm)} 0`,
}))

export const StyledTitle = styled(StyledText.withComponent('h3'))({
    fontWeight: 700,
    marginBottom: calcRem(metrics.thewest.margins.xs),
})

export const StyledLink = styled(WebLink)(({ theme }) => ({
    color: colors.thewest.greyElephant,
    display: 'inline-block',
    textDecoration: 'none',
    fontFamily: theme.fonts.sansSerif,
    lineHeight: 1,
    padding: calcRem(metrics.thewest.margins.xs, 0),

    '&:focus': {
        outline: 'none',
        textDecoration: 'underline',
        color: colors.thewest.greyGorilla,
    },

    '&:hover': {
        color: theme.sectionValues.primaryColor,
    },
}))
