import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { FactCheckerSectionHeader } from './FactCheckerSectionHeader'

export const FactCheckerSectionHeaderRegistration =
    createRegisterableComponentWithData(
        'fact-checker-section-header',
        ContentDataDefinitionLoader,
        (props: { sizing: 'smaller' | 'default' }, data) => {
            const { sizing = 'default' } = props

            if (
                !data.loaded ||
                (data.loaded && data.result.publications.length <= 0)
            ) {
                return null
            }

            return (
                <FactCheckerSectionHeader
                    sizing={sizing}
                    profiles={
                        data.loaded
                            ? data.result.publications[0] &&
                              data.result.publications[0].cardType ===
                                  'publication' &&
                              data.result.publications[0].profiles
                            : undefined
                    }
                />
            )
        },
    )
