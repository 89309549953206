import styled from '@emotion/styled'
import { calcRem, fonts, themedValue } from '../../../__styling'
import { totalSeats } from './BarGraphWidget'

const barHeight = 32
const labelOffset = calcRem(barHeight + 6)
const graphMargin = calcRem(14)
const marker = '50.84%'

const hippo = '#2B2D2E'
const ermine = '#EAEAEA'

const laborColor = '#980000'
const laborLikelyColor = '#9800004D'
const laborLikelyColorLight = '#9800004D'
const coalitionColor = '#0067BD'
const coalitionLikelyColor = '#0067BD4D'
const coalitionLikelyColorLight = '#0067BD4D'
const greensColor = '#008A2C'
const greensLikelyColor = '#008A2C4D'
const greensLikelyColorLight = '#008A2C4D'
const otherColor = '#470079'
const otherLikelyColor = '#4700794D'
const otherLikelyColorLight = '#4700794D'
const undecidedColor = '#535556'

export const StyledBarGraph = styled('div')(() => ({
    position: 'relative',
    marginTop: graphMargin,
    width: '100%',

    '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: marker,
        zIndex: 4,
        width: 0,
        height: '100%',
        borderLeft: `2px dashed ${hippo}`,
    },

    '&:after': {
        fontSize: calcRem(10),
        position: 'absolute',
        top: 0,
        left: marker,
        paddingBottom: calcRem(4),
        color: hippo,
        coentent: '30 to win',
        textTransform: 'uppercase',
        fontWeight: 600,
        lineHeight: 1,
        transform: 'translate(-50%, 100%)',
    },
}))

export const StyledBarGraphContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginTop: calcRem(22),
    position: 'relative',
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        fallback: fonts.thewest.sansSerif,
    }),
}))
export const StyledBarGraphMarkerText = styled('div')(() => ({
    position: 'absolute',
    left: 'calc(50% + 34px)',
    zIndex: 99,
    fontSize: calcRem(12),
    fontWeight: 700,
    top: calcRem(-10),
}))
export const StyledBarGraphRowsContainer = styled('div')(() => ({
    width: '100%',
}))

export const StyledBarGraphRowLabel = styled('div')(() => ({
    textTransform: 'uppercase',
    fontWeight: 600,
    height: calcRem(32),
    marginBottom: calcRem(4),
    top: '4px',
    position: 'relative',
}))

export const StyledBarGraphPartyContainer = styled('div')(() => ({}))

export const StyledBarGraphPartyLabelContainer = styled('div')(() => ({
    minWidth: '124px',
    marginTop: graphMargin,
}))

type StyledBarGraphPartyLikelyTextProps = {
    totalPartySeats: number
}
export const StyledBarGraphPartyLikelyText = styled(
    'div',
)<StyledBarGraphPartyLikelyTextProps>(({ totalPartySeats }) => ({
    position: 'absolute',
    left: `calc(${(totalPartySeats / totalSeats) * 100 + '%'} + ${calcRem(8)})`,
    fontSize: calcRem(16),
    top: calcRem(5),
}))

export const StyledSeatsLabel = styled('span')(() => ({
    fontWeight: 700,
}))

export const StyledPartyNameLabel = styled('span')(() => ({
    fontWeight: 400,
}))

export const StyledSeatsRemaining = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',

    'span:nth-child(1)': {
        fontWeight: 700,
        display: 'inline-block',
    },

    'span:nth-child(2)': {
        fontWeight: 400,
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
})

export const StyledBarGraphParty = styled('div')(() => ({
    position: 'relative',

    '.bar-graph__bar': {
        position: 'relative',
        marginBottom: calcRem(4),
        width: '100%',
        height: calcRem(barHeight),
        background: ermine,
        borderRadius: `0px 6px 6px 0px`,

        "&[data-party='coalition'] .bar-graph__label": {
            color: coalitionColor,
        },
        "&[data-party='coalition'] .bar-graph__seats-won": {
            background: coalitionColor,
        },
        "&[data-party='coalition'] .bar-graph__seats-likely": {
            background: coalitionLikelyColorLight,
            '&::after': {
                color: coalitionLikelyColor,
            },
        },

        "&[data-party='labor'] .bar-graph__label": {
            color: laborColor,
        },
        "&[data-party='labor'] .bar-graph__seats-won": {
            background: laborColor,
        },
        "&[data-party='labor'] .bar-graph__seats-likely": {
            background: laborLikelyColorLight,
            '&::after': {
                color: laborLikelyColor,
            },
        },

        "&[data-party='other'] .bar-graph__label": {
            color: otherColor,
        },
        "&[data-party='other'] .bar-graph__seats-won": {
            background: otherColor,
        },
        "&[data-party='other'] .bar-graph__seats-likely": {
            background: otherLikelyColorLight,
            '&::after': {
                color: otherLikelyColor,
            },
        },

        "&[data-party='greens'] .bar-graph__label": {
            color: greensColor,
        },
        "&[data-party='greens'] .bar-graph__seats-won": {
            background: greensColor,
        },
        "&[data-party='greens'] .bar-graph__seats-likely": {
            background: greensLikelyColorLight,
            '&::after': {
                color: greensLikelyColor,
            },
        },

        '.bar-graph__label': {
            fontSize: calcRem(16),
            position: 'relative',
            top: labelOffset,
            textTransform: 'uppercase',
            fontWeight: 700,
            display: 'none',
        },

        '.bar-graph__seats-won, .bar-graph__seats-likely, .bar-graph__undecided':
            {
                position: 'absolute',
                top: 0,
                height: '100%',
                transition: '0.3s ease',
                borderRadius: `6px 0px 0px 6px`,
            },
        '.bar-graph__undecided': {
            background: undecidedColor,
            borderRadius: `6px 0px 0px 6px`,
        },
    },
}))
