import styled from '@emotion/styled'
import { AlertType } from '../TNToastNotification.routing'
import { breakpoint, calcRem, metrics } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export interface AlertComponentContainerProps {
    type: AlertType
    useMaxWidth: boolean
    forceMaxWidth?: boolean
}
export const StyledToastComponentContainer = styled(
    'div',
)<AlertComponentContainerProps>(
    ({ type, useMaxWidth, forceMaxWidth = false }) => ({
        display: 'flex',
        padding: calcRem(8, 16),
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'flex-start',
        borderRadius: calcRem(8),
        background:
            type === 'success'
                ? tokens.thenightly.colors.palette.utility.success.light
                : type === 'error'
                ? tokens.thenightly.colors.palette.utility.error.light
                : type === 'warning'
                ? tokens.thenightly.colors.palette.utility.warning.light
                : tokens.thenightly.colors.palette.neutral[20],
        marginBottom: metrics.thenightly.margins.md,

        p: {
            marginTop: 0,
            marginBottom: 0,
        },

        // Apply styling to anything smaller than a desktop
        width: '100%',

        // Apply styling to anything bigger than a tablet
        [breakpoint('mlg')]: {
            width: forceMaxWidth ? '100%' : 'fit-content',
            maxWidth: useMaxWidth ? calcRem(400) : undefined,
        },
    }),
)
StyledToastComponentContainer.displayName = 'StyledToastComponentContainer'
