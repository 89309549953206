import H from 'history'
import {
    ContentAreaData,
    getComponentsInCompositions,
    LayoutApi,
} from 'json-react-layouts'
import { getComponentDataArgs } from 'json-react-layouts-data-loader'
import { LoadData } from 'json-react-layouts-data-loader/dist/DataLoading'
import React from 'react'
import { renderToString } from 'react-dom/server'
import { Provider } from 'react-redux'
import { resources } from '../data/resources'
import { enableRouteLogs } from '../route-logs-enabled'
import {
    ComponentServices,
    PageRouteInformation,
    RouteServices,
} from '../routing/page-definition'

export function primeRoute(
    layout: LayoutApi<any, any, ComponentServices, any, any>,
    routeInfo: PageRouteInformation<string>,
    routeServices: RouteServices,
    location: H.Location,
) {
    const componentServices: ComponentServices = {
        ...routeServices,
        layout,
        location,
        adState: {
            definition: [],
            pageKey: '',
            targeting: {
                pageId: '',
                topics: [],
                ssAdUnits: [],
                adUnitPath: '',
            },
            breakpoints: [],
            unitLookup: () => undefined,
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onEvent: () => {},
    }

    const contentAreaDataLoads = getComponentsInCompositions(
        routeInfo.compositions,
        undefined,
    ).reduce<Promise<any>[]>((promises, component) => {
        const dataLoaded = loadContentAreaData(
            componentServices,
            component,
            layout,
        )

        if (dataLoaded) {
            promises.push(dataLoaded)
        }

        return promises
    }, [])

    componentServices.log.debug(
        { loading: contentAreaDataLoads.length },
        'Loading content area data',
    )

    // load all content areas
    return Promise.all(contentAreaDataLoads)
}

function loadContentAreaData(
    componentServices: ComponentServices,
    component: ContentAreaData,
    layout: LayoutApi<any, any, ComponentServices, any, any>,
): Promise<Array<any | undefined>> | undefined {
    if (enableRouteLogs()) {
        componentServices.log.debug(
            { componentRenderPath: component.componentRenderPath },
            'Loading content area data',
        )
    }
    const promises: Array<Promise<any | undefined>> = []

    // dataDefinition is a hidden runtime property on the registered component
    // if the component needs data

    const registration = layout.componentRegistrations.get(component.type)

    if (registration) {
        const dataDefinition = getComponentDataArgs(registration)

        if (dataDefinition) {
            let componentRouteDataConfig = (component.props as any)
                .dataDefinitionArgs
            if (dataDefinition.useRuntimeParams) {
                /**
                 * useRuntimeParams supports hooks, this means we can only call it in the context of
                 * React.
                 * The below creates a React component which executes the hook, captures it's output then
                 * renders nothing.
                 *
                 * NOTE: If the hooks access more context, those context providers will need to be added here.
                 */
                let runtimeParams: object = {}
                const ExtractParams = () => {
                    runtimeParams = dataDefinition.useRuntimeParams!(
                        componentRouteDataConfig,
                        componentServices,
                    )
                    return null
                }

                renderToString(
                    <Provider store={componentServices.store}>
                        <ExtractParams />
                    </Provider>,
                )

                componentRouteDataConfig = {
                    ...componentRouteDataConfig,
                    ...runtimeParams,
                }
            }
            const paramsCacheKey = resources.generateCacheKey(
                'component-data-loader',
                {
                    dataDefinitionArgs: componentRouteDataConfig,
                },
            )
            promises.push(
                cacheDataLoad(dataDefinition.loadData)(
                    componentRouteDataConfig,
                    componentServices,
                    {
                        resourceType: 'component-data-loader',
                        paramsCacheKey,
                    },
                ).then((data) => ({
                    data: { loaded: true, result: data },
                })),
            )
        } else {
            // This can be passed straight through, types will mean
            // this property won't end up on components which don't have data
            // but it will exist at runtime as its required to perform the content area index mapping, otherwise
            // data loaded for [index] and its index wont correlate to the component within the contentArea[index]
            promises.push(Promise.resolve<any | undefined>(undefined))
        }
    }

    if (promises.length === 0) {
        return undefined
    }

    return Promise.all(promises)
}

export const cacheDataLoad: (
    loadData: LoadData<any, any, ComponentServices>,
) => LoadData<any, any, ComponentServices> =
    (load) => (args, services, context) => {
        return services.routeCache.getOrLoad(
            context.resourceType,
            context.paramsCacheKey,
            args,
            () => load(args, services, context),
        )
    }
