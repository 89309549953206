import styled, { Interpolation } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { hexToRGBA } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'
import { PlayButtonProps } from './PlayButton'

export const sevennewsPlayArrow = require('./assets/7news-play-arrow.svg')
export const thenightlyPlayArrow = require('./assets/the-nightly-play-icon.svg')

interface LeftSize {
    left: number
}

type BorderWidthAndLeftSize = {
    borderWidth?: string
} & LeftSize

interface BreakpointSizeIcon {
    sm: BorderWidthAndLeftSize
    default: BorderWidthAndLeftSize
}

interface BreakpointSize {
    normal: {
        sm: number
        default: number
    }
    small: number
}

interface PlayButtonSize {
    container: BreakpointSize
    iconCentered: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
        withBorder?: BorderWidthAndLeftSize
    }
    icon: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
    }
}

const sevenSizes: PlayButtonSize = {
    container: {
        normal: { sm: 70, default: 60 },
        small: 32,
    },
    iconCentered: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 12 },
        withBorder: { left: 10 },
    },
    icon: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 5 },
    },
}

const thenightlySizes: PlayButtonSize = {
    container: {
        normal: { sm: 64, default: 64 },
        small: 24,
    },
    iconCentered: {
        normal: {
            sm: {
                left: 16,
            },
            default: { left: 16 },
        },
        small: { left: 6 },
    },
    icon: {
        normal: {
            sm: {
                left: 16,
            },
            default: { left: 16 },
        },
        small: { left: 5 },
    },
}

const standardSizes: PlayButtonSize = {
    container: {
        normal: {
            sm: 100,
            default: 80,
        },
        small: 42,
    },
    iconCentered: {
        normal: {
            sm: { left: 40 },
            default: {
                borderWidth: `${calcRem(17)} 0 ${calcRem(17)} ${calcRem(26)}`,
                left: 30,
            },
        },
        small: {
            left: 16,
            borderWidth: `${calcRem(9)} 0 ${calcRem(9)} ${calcRem(14)}`,
        },
    },
    icon: {
        normal: {
            sm: {
                left: 23,
                borderWidth: `${calcRem(17)} 0 ${calcRem(17)} ${calcRem(26)}`,
            },
            default: {
                left: 16,
            },
        },
        small: {
            left: 25,
            borderWidth: `${calcRem(12)} 0 ${calcRem(12)} ${calcRem(18)}`,
        },
    },
}

const sizing = {
    thewest: standardSizes,
    perthnow: standardSizes,
    sevennews: sevenSizes,
    thenightly: thenightlySizes,
}

export const playButtonClass = 'video_play_button'
export const playButtonIconClass = 'video_play_button_icon'

const internalPlayButtonStates = (theme: Theme, isInversed?: boolean) => {
    return {
        backgroundColor: themedValue(theme, {
            thewest: isInversed ? colors.black : colors.white,
            perthnow: colors.perthnow.pinkThulite,
            sevennews: undefined, //Hover done by the Play Overlay
            thenightly: undefined,
        }),
        outline: 'none',

        '&::before': {
            borderLeftColor: themedValue(theme, {
                thewest: isInversed ? colors.white : colors.black,
                perthnow: isInversed
                    ? colors.perthnow.greyBasalt
                    : colors.white,
                sevennews: undefined,
                thenightly: undefined,
            }),
        },
    }
}

export const playButtonStates = (theme: Theme) => {
    return {
        '&:hover, &:active, &:focus': {
            [`& .${playButtonClass}`]: {
                ...internalPlayButtonStates(theme),
            },
        },
    }
}

const getPlayIconStyles = (
    theme: Theme,
    isInversed?: boolean,
    isSmall?: boolean,
) => {
    if (theme.kind === 'sevennews') {
        return {
            left: calcRem(12),
            transition: `background ${easing.easeOut.fast}`,
            background: `url(${sevennewsPlayArrow})`,
            backgroundRepeat: 'no-repeat',
            width: isSmall
                ? sevenSizes.container.small
                : sevenSizes.container.normal.default,
            height: isSmall ? calcRem(14) : calcRem(29),
            backgroundSize: isSmall
                ? `${calcRem(12)} ${calcRem(14)}`
                : `${calcRem(25)} ${calcRem(29)}`,
        }
    } else if (theme.kind === 'thenightly') {
        return {
            left: isSmall ? calcRem(6) : calcRem(16),
            transition: `background ${easing.easeOut.fast}`,
            background: `url(${thenightlyPlayArrow})`,
            backgroundRepeat: 'no-repeat',
            width: isSmall
                ? thenightlySizes.container.small
                : thenightlySizes.container.normal.default,
            height: isSmall ? calcRem(15) : calcRem(35),
            backgroundSize: isSmall
                ? `${calcRem(15)} ${calcRem(15)}`
                : `${calcRem(35)} ${calcRem(35)}`,

            [breakpoint('sm')]: {
                left: calcRem(20),
            },
        }
    } else {
        return {
            left: calcRem(15),
            width: 0,
            height: 0,
            borderWidth: `${calcRem(12)} 0 ${calcRem(12)} ${calcRem(18)}`,
            borderStyle: 'solid',
            borderColor: 'transparent',
            transition: `border-color ${easing.easeOut.fast}`,
            borderLeftColor: themedValue(theme, {
                thewest: isInversed
                    ? colors.white
                    : theme.colors.icons?.playButtonOverride
                    ? theme.colors.icons?.playButtonOverride
                    : theme.sectionValues.primaryColor,
                perthnow: colors.white,
                sevennews: undefined,
                thenightly: colors.thenightly.neutral[0],
            }),
        }
    }
}

const getNonCenteredPlayBackgroundStyles = (theme: Theme, isSmall: boolean) => {
    if (theme.kind === 'thenightly') {
        return {
            top: 'auto',
            bottom: isSmall ? calcRem(4) : calcRem(16),
            left: isSmall ? calcRem(4) : calcRem(16),
            width: isSmall ? calcRem(24) : calcRem(64),
            height: isSmall ? calcRem(24) : calcRem(64),

            '&::before': {
                [breakpoint('sm')]: {
                    left: isSmall
                        ? sizing[theme.kind].icon.small.left
                        : sizing[theme.kind].icon.normal.sm.left,
                    borderWidth: isSmall
                        ? sizing[theme.kind].icon.small.borderWidth
                        : sizing[theme.kind].icon.normal.sm.borderWidth,
                },
            },
        }
    } else {
        return {
            top: 'auto',
            bottom: calcRem(16),
            left: calcRem(16),

            [breakpoint('sm')]: {
                bottom: calcRem(20),
                left: calcRem(23),
                width: calcRem(64),
                height: calcRem(64),
            },

            '&::before': {
                [breakpoint('sm')]: {
                    left: isSmall
                        ? sizing[theme.kind].icon.small.left
                        : sizing[theme.kind].icon.normal.sm.left,
                    borderWidth: isSmall
                        ? sizing[theme.kind].icon.small.borderWidth
                        : sizing[theme.kind].icon.normal.sm.borderWidth,
                },
            },
        }
    }
}

type StyledPlayButtonProps = Omit<
    PlayButtonProps & {
        isSmall?: boolean
        isIcon?: boolean
    },
    'onClick'
>

export const StyledPlayElementStyles: Interpolation<
    StyledPlayButtonProps & { theme: Theme }
>[] = [
    () => ({
        position: 'absolute',
        zIndex: zIndex.common.videoPlayButton,
        margin: 0,
        padding: 0,
        width: calcRem(44),
        height: calcRem(44),
        borderRadius: '100%',
        fontSize: 0,
        lineHeight: 1,
        transition: `opacity ${easing.easeOut.med}, background-color ${easing.easeOut.fast}`,
        cursor: 'pointer',
        border: 'none',

        '&::before': {
            position: 'absolute',
            top: '50%',
            display: 'block',
            content: `''`,
            transform: 'translateY(-50%)',
        },

        '& > span': {
            display: 'none',
        },
    }),
    ({ theme, isCentered, isInversed, isSmall }) => ({
        backgroundColor: themedValue(theme, {
            thewest: isInversed
                ? theme.sectionValues.primaryColor
                : colors.white,
            perthnow: !isCentered
                ? colors.perthnow.pinkThulite
                : colors.perthnow.greyBasalt60,
            sevennews: hexToRGBA(colors.black, 0.05),
            thenightly: isInversed
                ? theme.sectionValues.primaryColor
                : colors.thenightly.neutral[100],
        }),
        opacity: themedValue(theme, {
            thenightly: 0.8,
            fallback: 1,
        }),
        backdropFilter: themedValue(theme, {
            thewest: undefined,
            perthnow: 'blur(13.5914px)',
            sevennews: 'blur(13.5914px)', // Minimal browser support
            thenightly: undefined,
        }),

        '&::before': getPlayIconStyles(theme, isInversed, isSmall),
    }),
    // Only want hover/active/focus when a button is rendered
    ({ isIcon, theme, isInversed }) =>
        isIcon !== true && {
            '&:hover, &:active, &:focus': {
                ...internalPlayButtonStates(theme, isInversed),
            },
        },

    // isCentered can probably be moved away from here just used for TitleOverlay for PN where flex is used
    ({ isCentered, theme, isSmall, isBauhaus }) => {
        // !isCentered is just the previous behaviour, not setting sizing for each theme
        if (!isCentered) {
            return getNonCenteredPlayBackgroundStyles(theme, isSmall || false)
        }

        const defaultSize = isSmall
            ? sizing[theme.kind].container.small
            : sizing[theme.kind].container.normal.default
        const defaultMargin = defaultSize / 2

        const smSize = isSmall
            ? sizing[theme.kind].container.small
            : sizing[theme.kind].container.normal.sm
        const smMargin = smSize / 2

        const beforeSizing = isSmall
            ? {
                  left: sizing[theme.kind].iconCentered.small.left,
                  borderWidth:
                      sizing[theme.kind].iconCentered.small.borderWidth,
              }
            : {
                  left: calcRem(
                      sizing[theme.kind].iconCentered.normal.default.left,
                  ),
                  borderWidth:
                      sizing[theme.kind].iconCentered.normal.default
                          .borderWidth,

                  [breakpoint('sm')]: {
                      left: sizing[theme.kind].iconCentered.normal.sm.left,
                  },
              }

        if (isBauhaus) {
            return {
                bottom: calcRem(32),
                left: calcRem(32),
                width: calcRem(defaultSize),
                height: calcRem(defaultSize),

                [breakpoint('sm')]: {
                    width: calcRem(smSize),
                    height: calcRem(smSize),
                },

                '&::before': beforeSizing,
            }
        }
        return {
            top: '50%',
            left: '50%',
            marginLeft: `-${calcRem(defaultMargin)}`,
            marginTop: `-${calcRem(defaultMargin)}`,
            width: calcRem(defaultSize),
            height: calcRem(defaultSize),

            [breakpoint('sm')]: {
                top: '50%',
                left: '50%',
                marginLeft: `-${calcRem(smMargin)}`,
                marginTop: `-${calcRem(smMargin)}`,
                width: calcRem(smSize),
                height: calcRem(smSize),
            },

            '&::before': beforeSizing,
        }
    },
]

export const StyledPlayButton = withClass(playButtonClass)(
    styled('button')<StyledPlayButtonProps>(...StyledPlayElementStyles),
)

export const StyledPlayIcon = withClass(playButtonIconClass)(
    styled('div')<StyledPlayButtonProps>(...StyledPlayElementStyles),
)
