import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getThemedSegment } from '../../home/get-themed-segment'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/190-Desktop-Banner@2x.jpg')

const bannerDesktopX1 = require<string>('./assets/190-Desktop-Banner@1x.jpg')

const bannerTabletX2 = require<string>('./assets/190-Ipad-Banner@2x.jpg')

const bannerTabletX1 = require<string>('./assets/190-Ipad-Banner@1x.jpg')

const bannerMobileX2 = require<string>('./assets/190-Mobile-Banner@2x.jpg')

const bannerMobileX1 = require<string>('./assets/190-Mobile-Banner@1x.jpg')

const socialImage = require('./assets/190-Social-Banner@2x.jpg')

export const getSocialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '627',
    },
    {
        property: 'og:image:width',
        content: '1220',
    },
]

export const get190YearsRouteInfo: StaticRoutes<TheWestSection> = {
    '/news/190-years-of-the-west-australian': ({
        getAdTargeting,
        config,
        store,
        resolution,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/news/190-years-of-the-west-australian'

        const topic: Topic = {
            id:
                getResolvedTopicPageMeta(resolution)?.id ||
                '/news/190-years-of-the-west-australian',
            metadata: {},
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                '',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }
        const adTargeting = getAdTargeting(
            'News/190yearsofTheWestAustralian',
            TheWestSections.default,
            'news/190-years-of-the-west-australian',
        )

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'video-series',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
            },
            adTargeting: adTargeting,
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        adTargeting: adTargeting,
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        adTargeting: adTargeting,
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: bannerDesktopX2,
                                    verticalGutters: ['unset', 'md'],
                                    altText: topic.seoTitle,
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: bannerDesktopX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerDesktopX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: bannerTabletX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerTabletX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: bannerMobileX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerMobileX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            getThemedSegment({
                                featureToggle: undefined,
                                layoutVersion: '6-card',
                                curation: '190-years-of-the-west',
                                themeOverride: '190-years-of-the-west',
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    adTargeting: adTargeting,
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'The Stories That Make Us',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: '190-years-of-the-west-ext',
                                        offset: 0,
                                        pageSize: 24,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('one'),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'md',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    loadMoreEnabled: true,
                                    sectionHeader: {
                                        heading: '190 Years of The West',
                                        headingUrl:
                                            'news/190-years-of-the-west-australian',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        topics: [
                                            'news/190-years-of-the-west-australian',
                                        ],
                                        includeSubTopics: false,
                                        paging: {
                                            page: 1,
                                            pageSize: 8,
                                            pageOffset: 0,
                                        },
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
