import { PromotionV4InfoDTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers'
import { validateKeyTypes } from '../../../helpers/validation'

export function validateGetActivePromotion(value: any): PromotionV4InfoDTO {
    const errors = validateKeyTypes('active-promotion', value, {
        id: 'string',
        title: 'string',
        activePublication: {},
        lastUpdated: 'string',
    })

    if (errors) {
        throw new Error(
            'Active promotion response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}
interface Options {
    promotionSlotId: string
    metadataKey?: string | undefined
}
export const getActivePromotion = async (
    services: DataLoaderGlobalParams,
    { promotionSlotId, metadataKey }: Options,
) => {
    if (promotionSlotId === undefined) {
        throw new Error(
            'getActivePromotion, promotionSlotId should not be undefined',
        )
    }
    const response = await httpGet({
        log: services.log,
        validate: validateGetActivePromotion,
        baseUrl: services.config.contentApi,
        path: `v4/promotion-curation/${promotionSlotId}`,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
    if (!response.activePublication) {
        throw new Error(
            'Active publication not found on Active promotion response',
        )
    }
    return response.activePublication
}
