import { useSelector } from 'react-redux'
import { ConsentLevel } from '../../data/consent/consent.redux'
import { AppState } from '../../store'
import { isEuMember } from '../location-detection/location-detection'
import { LocationState } from '../location-detection/reducer'

export function getDefaultConsentLevel(
    locationState: LocationState,
): ConsentLevel {
    const countryCode = locationState.countryCode
    if (!countryCode) return ConsentLevel.Essential
    return isEuMember(countryCode) ? ConsentLevel.Essential : ConsentLevel.All
}

export function useDefaultConsentLevel(): ConsentLevel {
    const state = useSelector<AppState, LocationState>(
        (state) => state.geoLocation,
    )
    return getDefaultConsentLevel(state)
}
