import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { NewsletterSignup } from '../../banners/NewsletterSignup/NewsletterSignup'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { TNNewsletterSignup } from '../../banners/NewsletterSignup/TNNewsletterSignup'
import { RecaptchaProvider } from '../../recaptcha/Recaptcha'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

export type NewsletterVariants =
    | 'default'
    | 'journo-profile'
    | 'sidebar-article'
    | 'inline-article'
export interface NewsletterSignupRouteProps {
    text: string
    buttonLabel: string
    verticalSpacing?: keyof ThemeMargins
    subText?: string
    mode: NewsletterVariants
    persistent?: boolean
}

export const NewsletterSignupRegistration = createRegisterableComponent(
    'newsletter-signup',
    (props: NewsletterSignupRouteProps, services) => (
        <NewsletterSignup
            {...props}
            api={services.config.contentApi}
            onEvent={services.onEvent}
        />
    ),
)

export const TNNewsletterSignupRegistration = createRegisterableComponent(
    'tn-newsletter-signup',
    (props: NewsletterSignupRouteProps, services) => {
        if (!services.config.recaptureSiteKey || !services.config.sendgridEnv) {
            return <></>
        }
        return (
            <RecaptchaProvider siteKey={services.config.recaptureSiteKey}>
                <QueryClientProvider client={queryClient}>
                    <TNNewsletterSignup {...props} onEvent={services.onEvent} />
                </QueryClientProvider>
            </RecaptchaProvider>
        )
    },
)
