import { SectionMetaInfoDTO } from '@west-australian-newspapers/publication-types'
import H from 'history'

export function locationToFullPath(location: H.Location) {
    return `${location.pathname}${location.hash ? `#${location.hash}` : ''}${
        location.search ? `?${location.search}` : ''
    }`
}

export function getBaseUrl(
    publicUrl: string,
    sectionInfo?: SectionMetaInfoDTO,
) {
    if (sectionInfo && sectionInfo.Hostname && sectionInfo.DedicatedDomain) {
        return `https://${sectionInfo.Hostname}`
    }
    return publicUrl
}
