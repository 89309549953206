import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, themedValue } from '../../__styling'

export const StyledWidgetErrorContainer = styled('div')({
    marginBottom: calcRem(24),
})

export const StyledMatchTimelineWidgetContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        position: 'relative',
        margin: themedValue(theme, {
            perthnow: calcRem(24, 0, 32, 0),
            thewest: calcRem(16, 0),
            fallback: undefined,
        }),
    }),
)

export const StyledMatchTimelineInnerContainer = styled('div')({
    display: 'flex',
    width: '85%',
    flexDirection: 'column',
    height: calcRem(205),
    [breakpoint('sm')]: {
        height: calcRem(340),
        width: 'calc(100% - 152px)',
    },
})
interface StyledTimelineTooltipContainerProps {
    leftPosition: number
}
export const StyledTimelineTooltipContainer = styled(
    'div',
)<StyledTimelineTooltipContainerProps>(({ leftPosition }) => ({
    display: 'block',
    position: 'absolute',
    top: calcRem(-32),
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9,
    [breakpoint('sm')]: {
        top: calcRem(10),
        left: leftPosition,
        transform: 'none',
        pointerEvents: 'none',
    },
}))

const StyledMatchTimelineLabel = styled('span')({
    fontWeight: 900,
    fontSize: calcRem(92),
    opacity: 0.2,
    position: 'absolute',
    top: '50%',

    transform: 'translate(-50%,-50%);',
    color: tokens.sevennews.colors.palette.bauhausBlack,
    userSelect: 'none',
    pointerEvents: 'none',
    display: 'none',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

export const StyledMatchTimelineLabelOne = styled(StyledMatchTimelineLabel)({
    left: '12.5%',
})
export const StyledMatchTimelineLabelTwo = styled(StyledMatchTimelineLabel)({
    left: '37.5%',
})
export const StyledMatchTimelineLabelThree = styled(StyledMatchTimelineLabel)({
    left: '62.5%',
})
export const StyledMatchTimelineLabelFour = styled(StyledMatchTimelineLabel)({
    left: '87.5%',
})

export const StyledMatchTimelineTeamLogoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    '&:first-child': {
        marginRight: calcRem(8),
        width: '15%',
    },
    '&:last-child': {
        display: 'none',
    },

    [breakpoint('sm')]: {
        '&:first-child': {
            marginRight: calcRem(16),
            width: 'unset',
        },
        '&:last-child': {
            marginLeft: calcRem(16),
            display: 'flex',
        },
        width: calcRem(60),
        maxWidth: calcRem(60),
    },
})

export const StyledMatchTimelineTeamLogo = styled('img')({
    height: calcRem(48),

    [breakpoint('sm')]: {
        height: calcRem(60),
    },
})

export const StyledQuarterScoreContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    height: 'auto',
    position: 'absolute',
    transform: 'translateY(-50%)',
    width: '85%',
    '&:first-child': {
        top: '25%',
    },
    '&:last-child': {
        top: '75%',
    },
    zIndex: 9,

    [breakpoint('sm')]: {
        width: '100%',
        height: calcRem(16),
        position: 'unset',
        transform: 'none',
    },
})

export const StyledTimelineChartContainer = styled('div')({
    position: 'relative',
    height: calcRem(300),
    width: '100%',
    [breakpoint('sm')]: {
        width: '100%',
        height: '90%',
    },
})
export const StyledQuarterScoreLabel = styled('span')({
    fontSize: calcRem(13),
    color: tokens.sevennews.colors.palette.bauhausBlack + '80',

    display: 'none',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

export const StyledQuarterScoreInnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
    pointerEvents: 'none',
})

export const StyledMobileQuarterScoreLabel = styled('span')({
    fontSize: calcRem(13),
    color: tokens.sevennews.colors.palette.bauhausBlack + '80',
    fontWeight: 700,
    [breakpoint('sm')]: {
        display: 'none',
    },
})

export const StyledMobileQuarterTotalScoreLabel = styled('span')({
    fontSize: calcRem(25),
    lineHeight: calcRem(30),
    fontWeight: 900,

    [breakpoint('sm')]: {
        display: 'none',
    },
})
