import { FilterQueryParam } from '../FilterQueryParam'
import { subDays, subWeeks, subYears, subMonths } from 'date-fns'

export const defaultTimeFilterOption: FilterQueryParam = {
    displayName: 'Last month',
    paramName: 'lastMonth',
}

export const timeFilterOptions: FilterQueryParam[] = [
    {
        displayName: 'Last 24 hours',
        paramName: 'lastDay',
    },
    {
        displayName: 'Last week',
        paramName: 'lastWeek',
    },
    defaultTimeFilterOption,
    {
        displayName: 'All time',
        paramName: 'allTime',
    },
]

export const TIME_FILTER_PARAM = 'when'

export const getPublishedSince = (publishedSinceValue?: string) => {
    switch (publishedSinceValue) {
        case 'lastDay':
            return subDays(new Date(), 1).toISOString()
        case 'lastWeek':
            return subWeeks(new Date(), 1).toISOString()
        case 'allTime':
            return subYears(new Date(), 100).toISOString()
        case 'lastMonth':
        default:
            return subMonths(new Date(), 1).toISOString()
    }
}
