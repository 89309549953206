import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Janus, JanusLayoutRatio } from '../../compositions/Janus/Janus'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'
import { JanusBreakpointOptions } from './Janus.styled'

export interface JanusRouteProps
    extends BaseCompositionProps,
        ResponsiveContainer {
    hasBackgroundFill?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    stretchColumns: boolean
    layoutRatio?: JanusLayoutRatio
    heroVideoCardHack?: boolean
    breakpoints?: JanusBreakpointOptions
    hasSidebar?: boolean
    themeOverride?: 'tokyo'
    hasBorder?: boolean
}

export const JanusRegistration = createRegisterableComposition<
    'left' | 'right'
>()(
    'janus',
    (contentAreas, props: JanusRouteProps) => (
        <ThemeOverrider override={props.themeOverride}>
            <ContentWrapper
                stretchColumns={props.stretchColumns}
                stretchSelf={props.stretchSelf}
            >
                <Janus
                    {...props}
                    content={[contentAreas.left, contentAreas.right]}
                />
            </ContentWrapper>
        </ThemeOverrider>
    ),
    (
        _contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
            layoutRatio,
        }: JanusRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        if (layoutRatio === '1:1' || layoutRatio === undefined) {
            return {
                containerWidthRatios: {
                    desktop: 0.5 * containerWidthRatios.desktop,
                    tablet: 0.5 * containerWidthRatios.tablet,
                    mobile: 1 * containerWidthRatios.mobile,
                },
            }
        } else {
            return {
                containerWidthRatios: {
                    desktop: 0.66 * containerWidthRatios.desktop,
                    tablet: 1 * containerWidthRatios.tablet,
                    mobile: 1 * containerWidthRatios.mobile,
                },
            }
        }
    },
)
