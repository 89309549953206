import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { CardText } from '../../../cards/CardText/CardText'
import {
    styledHeadlineClassName,
    StyledHeadlineText,
} from '../../../cards/CardText/CardText.styled'
import { StyledKicker } from '../../../cards/Kicker/Kicker.styled'
import { breakpoint, calcRem, fonts } from '../../../__styling'

export const StyledCardContainer = styled('div')({})

export const StyledCard = styled(WebLink)(({ theme }) => ({
    textDecoration: 'none',
    margin: 'auto',
    display: 'block',
    marginBottom: calcRem(10),
    backgroundColor: theme.colors.background.tertiary,
}))

export const StyledInlineContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: 0,
    ['&:hover']: {
        textDecoration: 'underline',
        textDecorationColor: theme.colors.text.primary,
    },
}))

export const StyledImageContainer = styled('div')({})

export const StyledCardMedia = styled(CardMedia)({
    minWidth: calcRem(116),
    height: '100%',
    marginBottom: 0,

    [breakpoint('xs')]: {
        minWidth: calcRem(227),
    },

    [`& .Card-Media-Content`]: {
        height: 'inherit',
    },

    [`& Picture`]: {
        height: '100%',
        width: '100%',
    },

    ['& img']: {
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },
})

export const StyledTextContainer = styled('div')(({ theme }) => ({
    position: 'relative',

    //This increases the specificiy of this styling to overwrite the default styling that's coming from the
    //PerthContentArticle styles.
    ['&&&']: {
        margin: calcRem(
            theme.margins.sm,
            8,
            theme.margins.sm,
            theme.margins.sm,
        ),
    },

    [breakpoint('xs')]: {
        ['&&&']: {
            margin: calcRem(24, 40, 14, 24),
        },
    },
}))

export const StyledCardText = styled(CardText)(({ theme }) => ({
    [`& .${styledHeadlineClassName}`]: {
        margin: 0,
    },

    [`& ${StyledKicker}`]: {
        fontFamily: fonts.perthnow.sansSerifCond,
        marginBottom: calcRem(8),
        fontSize: calcRem(14),
        lineHeight: calcRem(12),

        [breakpoint('xs')]: {
            fontSize: calcRem(16),
            lineHeight: calcRem(16),
        },
    },

    [`& ${StyledHeadlineText}`]: {
        fontSize: calcRem(16),
        fontFamily: fonts.perthnow.sansSerifCond,
        lineHeight: calcRem(16),
        minHeight: calcRem(32),
        fontWeight: 600,
        margin: 0,
        color: theme.colors.text.primary,

        [breakpoint('xs')]: {
            fontSize: calcRem(22),
            lineHeight: calcRem(29),
            minHeight: calcRem(58),
        },
    },
}))
