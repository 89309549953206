import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TWStaticTradingUpCard } from '../TWStaticTradingUpCard/TWStaticTradingUpCard'

export const StaticTradingUpPromoCardRegistration = createRegisterableComponent(
    'tw-trading-up-promo-card',
    ({}, services) => {
        const isStakeBannerImageActive = isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'trading-up-stake-images',
        )

        return (
            <TWStaticTradingUpCard
                isStakeBannerActive={isStakeBannerImageActive}
            />
        )
    },
)
