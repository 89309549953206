import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'
import { TNShareButtonProps } from './TNShareButton'
import { tokens } from '@news-mono/design-tokens'

export interface LiveEventShareButtonProps {
    shareButtonsVisible: boolean
    compactTextPaddingOnMobile: boolean
}

export const StyledLiveEventShareButtonContainer = styled(
    'div',
)<TNShareButtonProps>(({ theme, buttonPosition = 'left' }) => ({
    display: 'inline-flex',

    // Add padding to it to align it to the left or right
    width: 'fit-content',
    marginLeft: buttonPosition === 'left' ? 0 : 'auto',
    marginRight: buttonPosition === 'left' ? 'auto' : 0,
}))

export const StyledShareButtonContainer = styled('button')<
    TNShareButtonProps & LiveEventShareButtonProps
>(({ theme, shareButtonsVisible, compactTextPaddingOnMobile }) => [
    {
        display: 'flex',
        padding: compactTextPaddingOnMobile
            ? calcRem(theme.margins.sm, 29.408)
            : calcRem(theme.margins.sm, theme.margins.md, theme.margins.sm, 12),
        justifyContent: 'center',
        alignItems: 'center',

        height: 'fit-content',
        width: 'fit-content',

        borderRadius: calcRem(100),
        background: tokens.thenightly.colors.palette.neutral[10],
        border: `1px solid ${tokens.thenightly.colors.palette.neutral[10]}`,
        cursor: 'pointer',

        marginRight: calcRem(theme.margins.sm),

        [breakpoint('md')]: {
            padding: calcRem(
                theme.margins.sm,
                theme.margins.md,
                theme.margins.sm,
                12,
            ),
            marginRight: calcRem(theme.margins.md),
        },

        '&:hover': {
            background: tokens.thenightly.colors.palette.neutral[20],
        },
    },
    // Settings for when the buttons are visible
    shareButtonsVisible && {
        padding: compactTextPaddingOnMobile
            ? calcRem(theme.margins.sm, theme.margins.md)
            : calcRem(theme.margins.sm, 30.4),
        background: tokens.thenightly.colors.palette.neutral[0],
        border: `1px solid ${tokens.thenightly.colors.palette.neutral[100]}`,

        // Settings for padding with laptops and bigger
        [breakpoint('md')]: {
            padding: calcRem(theme.margins.sm, 30.4),
        },

        '&:hover': {
            background: tokens.thenightly.colors.palette.neutral[10],
        },
    },
])

export const StyledShareButtonText = styled('span')<{
    shareButtonsVisible: boolean
    hideTextOnMobile: boolean
}>(({ theme, shareButtonsVisible, hideTextOnMobile }) => [
    {
        color: tokens.thenightly.colors.palette.neutral[100],

        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(16),
        lineHeight: calcRem(24),
        fontWeight: 400,
        letterSpacing: calcRem(-0.32),
        textDecoration: 'uppercase',
        marginLeft: calcRem(theme.margins.sm),

        // Sort out the text hide on mobile, this is used to compact the sharing
        // component down
        display: hideTextOnMobile ? 'none' : 'block',
        [breakpoint('md')]: {
            display: 'block',
        },
    },
    shareButtonsVisible && {
        marginLeft: 0,
        display: 'block',
    },
])
