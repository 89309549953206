import loadable from '@loadable/component'
import {
    createRenderTarget,
    ProviderChildProps,
} from '@news-mono/component-library'
import { Product } from '@news-mono/web-common'
import H from 'history'
import { SiteProps } from '../Site'
import { BaseTheWestWeb } from './BaseTheWest.web'

export interface BaseTheWestProps extends SiteProps {
    location: H.Location
    flyOutProps: ProviderChildProps
    product: Product
    hostname: string
}
const LazyBaseTheWestAmp = loadable(() => import('./BaseTheWest.amp'))

export const BaseTheWest = createRenderTarget<BaseTheWestProps>('BaseTheWest', {
    web: BaseTheWestWeb,
    amp: LazyBaseTheWestAmp,
    preview: BaseTheWestWeb,
    rss: BaseTheWestWeb,
    app: BaseTheWestWeb,
})
