import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../../__styling'

export const CardBodyWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.margins.md,
    padding: calcRem(0, 24),

    [breakpoint('sm')]: {
        padding: 'unset',
    },

    transition: 'padding 0.25s',
}))
