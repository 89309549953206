import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem, colors, fonts } from '../../../__styling'

interface StyledArticleBylineProps {
    hasBackground?: boolean
    isLiveBlog?: boolean
}

export const StyledArticleByline = styled('div')<StyledArticleBylineProps>(
    ({ hasBackground, isLiveBlog }) => ({
        fontSize: calcRem(12),
        textAlign: 'center',
        fontFamily: fonts.sevennews.sansSerif,
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        color: colors.sevennews.bauhausBlack80,
        background: hasBackground ? colors.sevennews.neonSilver30 : undefined,
        paddingRight: isLiveBlog ? calcRem(8) : calcRem(20),
        borderRadius: calcRem(22),
        marginRight: isLiveBlog ? 0 : calcRem(10),
        [breakpoint('xxs')]: {
            paddingRight: isLiveBlog ? calcRem(16) : calcRem(20),
        },
    }),
)

export const StyledArticleBylineAuthor = styled('span')({})

export const StyledArticleBylineAuthorLink = styled(WebLink)({
    textDecoration: 'none',
    color: 'inherit',
    height: calcRem(30),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        textDecoration: 'underline',
    },
})

interface StyledAuthorThumbnailProps {
    isLast: boolean
}

export const StyledAuthorThumbnail = styled('img')<StyledAuthorThumbnailProps>(
    ({ isLast }) => ({
        width: calcRem(30),
        height: calcRem(30),
        borderRadius: '50%',
        marginRight: isLast ? calcRem(12) : 0,
    }),
)
export const StyledTimeAndProfilesWrap = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}))
export const StyledAuthorProfilesWrap = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    height: calcRem(20),
}))
