import styled from "@emotion/styled";
import { breakpoint, calcRem, metrics } from "../../__styling";
import { TheWestMark, TheWestMarkProps } from "../../logos/TheWestMark/TheWestMark";

const backgroundColor = '#222222'
const hoverColor = '#333333'

export const StyledBlackFridayStrapWrapper = styled('a')(
    ({}) => ({
        display: 'flex',
        justifyContent: 'center',
        background: backgroundColor,
        textDecoration: 'none',
        height: 40,
        marginBottom: calcRem(16),
        boxShadow: '0 2px 5px 0 rgba(0,0,0,0.5)',

        // make sure the space wrapped in a <p> is shown when multiple
        // straps are merged into one
        p: {
            display: 'none',
            [breakpoint('sm')]: {
                display: 'flex',
            },
        },
        // add the appropriate margin only on the devices that also utilise it
        [breakpoint('md')]: {
            marginLeft: calcRem(metrics.thewest.margins.outerMargin),
            marginRight: calcRem(metrics.thewest.margins.outerMargin),
        },
        [breakpoint('lg')]: {
            height: 46,
        },

        // change the hover color slightly
        '&:hover,&:focus': {
            background: hoverColor,
        },
}))

export const StyledBlackFridayStrap = styled('div')({
        display: 'inline-flex',
        background: 'transparent',

        // show both when on large devices, but only one when on small
        // devices.
        ':nth-of-type(2)': {
            [breakpoint('sm')]: {
                display: 'inline-flex',
            },
            display: 'none',
        },
})

interface StyledTextProps {
    hideForGalaxyFold?: boolean
}

const StyledText = styled('h1')<StyledTextProps>(({ theme, hideForGalaxyFold }) => ({
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(16),
        fontWeight: 700,
        display:
            hideForGalaxyFold
                ? 'none'
                : 'flex',
        margin: 'auto',
        alignItems: 'center',

        '@media(min-width: 320px)': {
            fontSize: calcRem(18),
        },
        '@media(min-width: 370px)': {
            fontSize: calcRem(14),
            display: 'flex',
        },
        [breakpoint('xxs')]: {
          fontSize: calcRem(17.6),
        },
        [breakpoint('sm')]: {
            fontSize: calcRem(15.2),
        },
        [breakpoint('md')]: {
            fontSize: calcRem(20),
        },
        [breakpoint('mlg')]: {
            fontSize: calcRem(21.6),
        },
        [breakpoint('lg')]: {
            fontSize: calcRem(24),
        },
}))

const aquaColor = '#08fcf4'
export const StyledBlackFridayAquaText = styled(StyledText)<StyledTextProps>(({ theme }) => ({
        color: aquaColor,
}))

export const StyledBlackFridayGlowText = styled(StyledText)<StyledTextProps>(({ theme }) => ({
        color: 'transparent',
        WebkitTextStroke: `0.6px ${aquaColor}`,

        [breakpoint('sm')]: {
            WebkitTextStroke: `0.7px ${aquaColor}`,
        },
        [breakpoint('md')]: {
            WebkitTextStroke: `0.8px ${aquaColor}`,
        },
        [breakpoint('mlg')]: {
            WebkitTextStroke: `1px ${aquaColor}`,
        },
}))

export const StyledTheWestMiniLogo = styled(TheWestMark)<TheWestMarkProps>({
    margin: 'auto',
    height: 16,
    width: 16,

    '&.left': {
        left: 0,
        marginLeft: calcRem(4.5),
    },
    '&.right': {
        right: 0,
        marginRight: calcRem(4.5),
    },

    [breakpoint('md')]: {
        height: 20,
        width: 20,
    },
    [breakpoint('mlg')]: {
        height: 24,
        width: 24,
    },
})
