import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors, fonts } from '../../../../__styling/settings'
import { breakpoint } from '../../../../__styling/style-functions'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { visuallyhidden } from '../../../../__styling/style-mixins'

interface StyledListProps {
    shouldBeVisible: boolean
}
export const StyledList = styled('ul')<StyledListProps>(
    {
        margin: calcRem(0, 8.5, 15),
        padding: calcRem(0),

        [breakpoint('md')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
    },
    ({ shouldBeVisible }) => !shouldBeVisible && { ...visuallyhidden },
)

export const StyledListItem = styled('li')(({ theme }) => ({
    margin: calcRem(7.5, 0),
    listStyle: 'none',

    [breakpoint('md')]: {
        flexBasis: theme.section === 'default' ? '50%' : '100%', // regional designs require 100%
    },
}))

export const StyledLink = styled(WebLink)({
    color: colors.white,
    textDecoration: 'none',
    fontFamily: fonts.thewest.sansSerif,

    '&:hover': {
        textDecoration: 'underline',
    },
})
