import {
    createRegisterableComponentWithData,
    EpaperPublicationType,
    ePaperReadersAlsoLoveDataDefinitionLoader,
} from '@news-mono/web-common'
import React from 'react'
import { ReadersAlsoLove } from './ReadersAlsoLove'

export const ePaperPageReadersAlsoLoveRegistration =
    createRegisterableComponentWithData(
        'epaper-page-readers-also-love',
        ePaperReadersAlsoLoveDataDefinitionLoader,
        (
            props: { border?: boolean; section: EpaperPublicationType },
            data,
            services,
        ) => {
            if (data.loaded) {
                return (
                    <ReadersAlsoLove
                        allEditions={data.result.issues}
                        section={props.section}
                        border={props.border}
                        onEvent={services.onEvent}
                    />
                )
            }
            return null
        },
    )
