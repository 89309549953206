import { tokens } from '@news-mono/design-tokens'
import {
    BreakpointState,
    metrics,
    ThemeConfig,
    TheNightlyThemeConfig,
} from '../../__styling'

export type LunaTheme<T extends ThemeConfig> = {
    // Component tokens
    maxWidth: string
    primary: {
        width: string
    }
    aside: {
        width: string
    }
    divider: {
        thickness: string
        background: string
    }
    breakpointState: BreakpointState<
        T,
        {
            // Declare tokens that depend on breakpoints.
            padding: string
            gap: {
                row: number
                column: number
            }
        }
    >
}

export const theNightlyLunaTheme: LunaTheme<TheNightlyThemeConfig> = {
    maxWidth: `${metrics.thenightly.siteMetrics.mainContentWidth}px`,
    primary: {
        width: '770px',
    },
    aside: {
        width: 'auto',
    },
    divider: {
        thickness: '1px',
        background: tokens.thenightly.colors.palette.neutral['20'],
    },
    // Breakpoint states.
    breakpointState: {
        default: {
            padding: '0',
            gap: {
                row: metrics.thenightly.margins.xxl,
                column: 0,
            },
        },
        sm: {
            padding: '0 20px',
            gap: {
                row: 64,
                column: 0,
            },
        },
        lg: {
            padding: '0',
            gap: {
                row: metrics.thenightly.margins.xxxxl,
                column: metrics.thenightly.margins.lg,
            },
        },
    },
}
