import { toFeatureState } from '@etrigan/feature-toggles-client'
import {
    DataLayerEventName,
    isComponentEnabled,
    NavEventArgs,
    NavItem,
    usePageEvents,
    useToggleState,
} from '@news-mono/web-common'
import React, { FC } from 'react'
import { QuickLinksProps } from './QuickLinks'
import {
    StyledLink,
    StyledNav,
    StyledNavItem,
    StyledNavList,
    StyledQuickLinks,
} from './QuickLinks.styled'
import kebabCase from 'lodash'

export const QuickLinksWeb: FC<QuickLinksProps> = React.memo(
    ({ links, onEvent, hasBackgroundFill }) => {
        usePageEvents(() => {
            onEvent({
                type: DataLayerEventName.navAvailable,
                originator: 'QuickLinks',
                payload: {},
            })
        }, ['page-load-complete'])

        const handleClick = (args: NavEventArgs) => {
            onEvent({
                type: DataLayerEventName.navClicked,
                originator: 'QuickLinks',
                payload: {
                    navName: 'QuickLinks',
                    navLocation: 'Header',
                    navPos: args.navPos,
                    navText: args.navText,
                    navLink: args.navLink,
                },
            })
        }

        const featureState = useToggleState()

        return (
            <StyledQuickLinks hasBackgroundFill={hasBackgroundFill || false}>
                <StyledNav>
                    <StyledNavList>
                        {links
                            .filter((item: NavItem) => {
                                if (item.feature) {
                                    return isComponentEnabled(
                                        toFeatureState(featureState),
                                        {
                                            feature: item.feature,
                                            invertFeatureToggle:
                                                item.invertFeatureToggle,
                                        },
                                    )
                                }
                                return true
                            })
                            .map((item, index) => (
                                <StyledNavItem key={item.link}>
                                    <StyledLink
                                        to={item.link}
                                        target={
                                            item.newWindow
                                                ? '_blank'
                                                : undefined
                                        }
                                        rel={
                                            item.newWindow
                                                ? 'noopener'
                                                : undefined
                                        }
                                        onClick={() => {
                                            handleClick({
                                                navPos: index,
                                                navText: item.name,
                                                navLink: item.link,
                                            })
                                        }}
                                        id={`${kebabCase(
                                            item.name,
                                        ).lowerCase()}-quicklink`}
                                    >
                                        {item.name}
                                    </StyledLink>
                                </StyledNavItem>
                            ))}
                    </StyledNavList>
                </StyledNav>
            </StyledQuickLinks>
        )
    },
)
QuickLinksWeb.displayName = 'QuickLinks'
