import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getOlympicsSegment } from '../../home/olypmics-segment'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getOlympicsRouteInfo: GetMainSectionFn = (
    sectionBuilder,
    store,
) => {
    return [
        layout.nestedComposition({
            type: 'thor',
            props: {
                hasBackgroundFill: true,
                containerWidth: '100%',
                verticalGutters: false,
                horizontalGutters: false,
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'breadcrumb',
                        props: {
                            items: [
                                { text: 'sport', href: '/sport' },
                                {
                                    text: 'Paralympics',
                                    href: '/sport/Paralympics',
                                },
                            ],
                        },
                    }),
                    layout.nestedComposition({
                        type: 'thor',
                        props: {
                            hasBackgroundFill: true,
                            containerWidth: '100%',
                            verticalGutters: false,
                            horizontalGutters: false,
                        },
                        contentAreas: {
                            main: [
                                layout.nestedComposition({
                                    type: 'thor',
                                    props: {
                                        hasBackgroundFill: false,
                                        containerWidth: '100%',
                                        verticalGutters: 'unset',
                                        horizontalGutters: false,
                                    },
                                    contentAreas: {
                                        main: store
                                            ? getOlympicsSegment(
                                                  store.getState().toggles,
                                                  false,
                                              )
                                            : [],
                                    },
                                }),
                                layout.component({
                                    type: 'lima',
                                    props: {
                                        verticalSpacing: 'sm',
                                        fontScale: 1.2,
                                        fixedRatios: ['16:9'],
                                        hasBackground: true,
                                        teaserMode: {
                                            initial: 'visible',
                                            xs: 'visible',
                                            sm: 'hidden',
                                            md: 'hidden',
                                        },
                                        loadMoreEnabled: true,
                                        initialColumns: 1,
                                        intermediateColumns: 2,
                                        finalColumns: 4,
                                        cardOrientation: {
                                            type: 'Landscape-Portrait',
                                        },
                                        sectionHeader: {
                                            heading: 'Latest Paralympics news',
                                        },

                                        dataDefinitionArgs: {
                                            type: 'listing',
                                            topics: ['sport/paralympics'],
                                            includeSubTopics: false,
                                            paging: {
                                                page: 1,
                                                pageSize: 12,
                                            },
                                        },
                                    },
                                }),
                                ...billboardLeaderboardMrec(
                                    'olympics-landing-page',
                                ),
                            ],
                        },
                    }),
                ],
            },
        }),
    ]
}
