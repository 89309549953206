import { AllEvents, CardItem } from '@news-mono/web-common'
import * as React from 'react'
import { Sierra, SierraLayouts } from '../../collections/Sierra/Sierra'
import { Uniform, UniformLayouts } from '../../collections/Uniform/Uniform'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { ThemeOverrideTypes } from '../../__styling'
import {
    SierraClassName,
    StyledArticleContainer,
    StyledBackground,
    StyledContainer,
    StyledDevelopingNews,
    StyledHeaderContainer,
    StyledHeadline,
    StyledSierraModifier,
} from './DevelopingNews.styled'

export interface DevelopingNewsProps {
    headLine: string
    themeOverrideType?: ThemeOverrideTypes
    items: CardItem[]
    onEvent: (event: AllEvents) => void
    innerRef?: React.RefObject<any>
}

const MAX_SIERRA_CARDS = 3

/** Used to return the cardLayout and expectedCards Sierra props
 */
export const returnSierraLayouts = (
    itemLength: number,
):
    | {
          cardLayout: SierraLayouts
          expectedCards: number
      }
    | undefined => {
    switch (itemLength) {
        case 1: {
            return {
                cardLayout: ['the-west-hero'],
                expectedCards: 1,
            }
        }
        case 2: {
            return {
                cardLayout: ['the-west-hero', 'hero-support'],
                expectedCards: 2,
            }
        }
        case 3: {
            return {
                cardLayout: ['the-west-hero', 'hero-support', 'hero-support'],
                expectedCards: 3,
            }
        }
    }
}
/** Used to return the cardLayout and expectedCards Uniform props
 */
export const returnUniformLayouts = (
    itemLength: number,
):
    | {
          cardLayout: UniformLayouts
          expectedCards: number
      }
    | undefined => {
    const cardLayout: UniformLayouts = [
        Array.from(Array(itemLength), () => 'large-card-mdNoImage'),
    ]

    return {
        cardLayout,
        expectedCards: itemLength,
    }
}

export const DevelopingNews: React.FC<DevelopingNewsProps> = ({
    headLine,
    themeOverrideType,
    items,
    onEvent,
    innerRef,
}) => {
    const sierraItemLengths =
        items.length <= 3 ? items.length : MAX_SIERRA_CARDS
    const uniformItemLengths = items.length > 3 ? items.length - 3 : 0

    // The functions return an array specifiying which card types to render.
    const sierraLayouts = returnSierraLayouts(sierraItemLengths)
    const uniformLayouts = returnUniformLayouts(uniformItemLengths)

    return (
        <ThemeOverrider override={themeOverrideType || 'developing-news'}>
            <StyledBackground>
                <StyledDevelopingNews
                    numUniformStories={uniformItemLengths}
                    ref={innerRef}
                >
                    <StyledContainer>
                        <StyledSierraModifier
                            numUniformItems={uniformItemLengths}
                            numSierraItems={sierraItemLengths}
                            themeOverrideType={themeOverrideType}
                        >
                            <StyledHeaderContainer
                                themeOverrideType={themeOverrideType}
                            >
                                <StyledHeadline
                                    themeOverrideType={themeOverrideType}
                                >
                                    {headLine}
                                </StyledHeadline>
                            </StyledHeaderContainer>
                            <StyledArticleContainer>
                                {sierraLayouts && (
                                    <Sierra
                                        className={SierraClassName}
                                        onEvent={onEvent}
                                        hasBorder={false}
                                        {...sierraLayouts}
                                        items={{
                                            loaded: true,
                                            result: items.slice(
                                                0,
                                                sierraItemLengths,
                                            ),
                                        }}
                                    ></Sierra>
                                )}
                                {uniformLayouts && uniformItemLengths > 0 && (
                                    <Uniform
                                        onEvent={onEvent}
                                        {...uniformLayouts}
                                        items={{
                                            loaded: true,
                                            result: items.slice(
                                                3,
                                                items.length,
                                            ),
                                        }}
                                        stopBaseGridConfiguration={true}
                                        hasCustomBorders={true}
                                        hasBorder={false}
                                    ></Uniform>
                                )}
                            </StyledArticleContainer>
                        </StyledSierraModifier>
                    </StyledContainer>
                </StyledDevelopingNews>
            </StyledBackground>
        </ThemeOverrider>
    )
}
