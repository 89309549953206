import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { PageType, RouteResolution, RouteServices } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'
import { rampAdUnit } from '../home'
import { cnbcSegment } from '../home/cnbc-segment'
import { inCaseYouMissedItSegment } from '../home/in-case-you-missed-it-segment'
import { subscriberExclusive } from '../home/subscriber-exclusive'
import { webStoriesSegment } from '../home/web-stories-segment'
import {
    RegionalHomepageOptions,
    regionalNewsSegment,
    waNewsSegment,
} from './get-regional-homepage'

function heroSegment(curationId: string) {
    return layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'gridGap',
            fixedRatios: ['16:9'],
            heroImageLayout: ResponsivePictureLayout.ObjectFitContain,

            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },
            cardLayout: ['hero', 'commonWithBackground'],

            dataDefinitionArgs: {
                type: 'curation',
                name: curationId,
                offset: 0,
                pageSize: 2,
            },
        },
    })
}

function subheroSegment(curationId: string) {
    return layout.component({
        type: 'uniform',
        props: {
            section: 'default',
            verticalSpacing: 'xl',
            cardOrientation: { type: 'Landscape-Portrait' },
            cardLayout: [
                { type: 'november', listLength: 5 },
                ['teaserWithBackground'],
                ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
                ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
            ],

            dataDefinitionArgs: {
                type: 'aggregated',
                sources: [
                    {
                        type: 'curation',
                        name: curationId,
                        offset: 2,
                        pageSize: 6,
                    },
                    {
                        type: 'curation',
                        name: curationId,
                        offset: 8,
                        pageSize: 4,
                    },
                ],
                keepDuplicates: true,
            },
        },
    })
}

function revChronSegment(topicId: string) {
    return layout.component({
        type: 'lima',
        props: {
            verticalSpacing: 'xl',
            fontScale: 1.2,
            fixedRatios: ['16:9'],
            hasBackground: true,
            teaserMode: 'hidden',
            initialColumns: 1,
            intermediateColumns: 2,
            finalColumns: 4,
            cardOrientation: { type: 'Landscape-Portrait' },
            cardLayout: [
                ['teaserWithBackground'],
                [
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                    {
                        type: 'landscape',
                        format: 'landscape-smTeaser-mdNoTeaser',
                    },
                ],
                ['teaserWithBackground'],
                ['teaserWithBackground'],
            ],
            dataDefinitionArgs: {
                type: 'listing',
                topics: [topicId],
                includeSubTopics: false,
                paging: {
                    page: 1,
                    pageSize: 6,
                },
            },
        },
    })
}

export function getCuratedRegionalHomepage(
    services: RouteServices<RouteResolution>,
    options: RegionalHomepageOptions & { curationId: string; topicId: string },
): PageType<TheWestSection> {
    const adTargeting = services.getAdTargeting('home', options.section, 'home')

    return {
        kind: 'page',
        heading: options.title,
        hideHeading: true,
        pageType: 'homepage',
        pageMeta: {
            title: options.title,
            description: options.description,
        },
        socialMeta: {
            description: options.socialDescription,
        },
        noMetaTitleTemplate: true,
        adTargeting,
        section: options.section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    hasBackgroundFill: false,
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    verticalGutters: true,
                },
                contentAreas: {
                    main: [
                        heroSegment(options.curationId),
                        subheroSegment(options.curationId),
                        ...billboardLeaderboardMrec('one'),
                        revChronSegment(options.topicId),
                        rampAdUnit,
                        subscriberExclusive(),
                        inCaseYouMissedItSegment(true),
                        ...billboardLeaderboardMrec('two'),
                        regionalNewsSegment(),
                        webStoriesSegment(),
                        cnbcSegment(),
                        waNewsSegment(options, false),
                        ...billboardLeaderboardMrec('three'),
                        layout.component({
                            type: 'taboola',
                            feature: 'taboola-regionals',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-home-thumbnails',
                                locationType: 'home',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
