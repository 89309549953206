import { PageCtaProps } from '../TNCreateAccountCTA'

export const coralTNCreateAccountCTAProps: PageCtaProps = {
    notLoggedInText: {
        title: 'Join the conversation',
        body: 'Log in or create a free account to make comments',
    },
    emailVerifyText: {
        title: 'Join the conversation.',
        body: `Your email address hasn't been verified. Verify your email to access comments.`,
    },
    blockStyle: 'comments',
    componentOrigin: 'article-comments',
}

export const puzzleTNCreateAccountCTAProps: PageCtaProps = {
    notLoggedInText: {
        title: 'Join The Nightly to access Puzzles.',
        body: `Get The Nightly, weekly to your inbox. \n Add your comment. Never miss a story.`,
    },
    emailVerifyText: {
        title: 'Verify your email to access Puzzles.',
        body: `Your email address hasn't been verified. Verify your email to access puzzles.`,
    },
    blockStyle: 'default',
    componentOrigin: 'puzzles-cta',
}
