import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    metrics,
    themedValue,
    ThemeMargins,
} from '../../__styling'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}
export const FixturesScoreboardWidgetWrapper = styled('div')<GridProps>(
    ({ verticalSpacing, verticalGutters, theme }) => ({
        display: 'flex',
        alignItems: 'center',
        minHeight: calcRem(154),
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
    }),
)

export const FixturesScoreboardWidgetLogo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerif,
        perthnow: fonts.perthnow.sansSerif,
        thewest: fonts.thewest.sansSerif,
        fallback: undefined,
    }),
    marginRight: calcRem(10),
    [breakpoint('sm')]: {
        marginRight: 0,
    },
}))

export const FixturesScoreboardWidgetLogoTop = styled('span')(({ theme }) => ({
    fontSize: themedValue(theme, {
        sevennews: calcRem(29),
        perthnow: calcRem(23),
        thewest: calcRem(23),
        fallback: undefined,
    }),
    lineHeight: themedValue(theme, {
        sevennews: calcRem(32),
        perthnow: calcRem(28),
        thewest: calcRem(28),
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        sevennews: 900,
        perthnow: 700,
        thewest: 700,
        fallback: undefined,
    }),
    textTransform: 'uppercase',
    textAlign: 'center',
}))

export const FixturesScoreboardWidgetLogoBottom = styled(WebLink)(
    ({ theme }) => ({
        fontSize: themedValue(theme, {
            sevennews: calcRem(11),
            perthnow: calcRem(10),
            fallback: undefined,
        }),
        lineHeight: '12px',
        fontWeight: themedValue(theme, {
            sevennews: 300,
            perthnow: 400,
            fallback: undefined,
        }),
        textAlign: 'center',
        textTransform: 'uppercase',
        textDecoration: 'none',
        background: colors.sevennews.bauhausBlack,
        color: colors.white,
        padding: calcRem(4, 10),
        width: '100%',
        borderRadius: themedValue(theme, {
            sevennews: '1px',
            perthnow: '2px',
            fallback: undefined,
        }),
        '&:hover': {
            background: themedValue(theme, {
                sevennews: colors.sevennews.red,
                perthnow: tokens.perthnow.colors.palette.pinkThulite,
                fallback: undefined,
            }),
        },

        [breakpoint('sm')]: {
            fontSize: calcRem(13),
        },
    }),
)
export const FixtureScoreboardSponsorLogo = styled('img')(({ theme }) => ({
    marginTop: calcRem(4),
}))

export const FixturesScoreboardWidgetContainer = styled('div')(({ theme }) => ({
    flex: 1,
    '.Opta': {
        fontFamily: themedValue(theme, {
            sevennews: fonts.sevennews.sansSerif,
            perthnow: fonts.perthnow.sansSerifCond,
            thewest: fonts.thewest.sansSerif,
            fallback: undefined,
        }),
        '.Opta_AFL_F.Opta_AFL_F_S': {
            height: themedValue(theme, {
                sevennews: calcRem(134),
                perthnow: calcRem(115),
                thewest: calcRem(115),
                fallback: undefined,
            }),
            '.Opta-Scroll.Opta-match-linked': {
                height: '100%',
                '.Opta-Window': {
                    height: '100%',
                    '.Opta-Room': {
                        height: '100%',
                        '.Opta-fixture': {
                            '.Opta-Team, .Opta-timings': {
                                background: themedValue(theme, {
                                    sevennews: colors.white,
                                    perthnow: 'transparent',
                                    thewest: 'transparent',
                                    fallback: undefined,
                                }),
                                borderRight: 'none',
                                width: '96%',
                                margin: '0 auto',
                                paddingRight: themedValue(theme, {
                                    sevennews: calcRem(16),
                                    fallback: undefined,
                                }),
                                [breakpoint('xs')]: {
                                    paddingRight: 0,
                                },
                                '.Opta-TeamName': {
                                    fontSize: themedValue(theme, {
                                        sevennews: calcRem(11),
                                        perthnow: calcRem(11),
                                        thewest: calcRem(12),
                                        fallback: undefined,
                                    }),
                                    textTransform: 'uppercase',
                                    fontWeight: themedValue(theme, {
                                        sevennews: 700,
                                        perthnow: 700,
                                        thewest: 600,
                                        fallback: undefined,
                                    }),
                                },
                            },
                            '.Opta-Team': {
                                height: themedValue(theme, {
                                    sevennews: calcRem(37),
                                    perthnow: calcRem(26),
                                    thewest: calcRem(26),
                                    fallback: undefined,
                                }),
                            },
                            '.Opta-match-linking': {
                                width: '96%',
                                margin: '0 auto',
                                backgroundColor: themedValue(theme, {
                                    sevennews: colors.sevennews.bauhausBlack50,
                                    perthnow:
                                        tokens.perthnow.colors.palette.AFL
                                            .neutral,
                                    thewest:
                                        tokens.thewest.colors.palette.greyHippo,
                                    fallback: undefined,
                                }),
                                textTransform: 'uppercase',
                                '& span': {
                                    fontWeight: themedValue(theme, {
                                        perthnow: 600,
                                        fallback: undefined,
                                    }),
                                },
                            },
                        },
                    },
                },
                '.Opta-Scroller': {
                    padding: themedValue(theme, {
                        sevennews: calcRem(67, 0),
                        perthnow: calcRem(56, 0),
                        thewest: calcRem(56, 0),
                        fallback: undefined,
                    }),
                    background: themedValue(theme, {
                        sevennews: colors.white,
                        perthnow: 'transparent',
                        thewest: 'transparent',
                        fallback: undefined,
                    }),
                    '&.Opta-Previous': {
                        borderRight: 'none',
                        '&.Opta-Active:after': {
                            backgroundPositionY: '-58px',
                        },
                    },
                    '&.Opta-Next.Opta-Active:after': {
                        backgroundPositionY: '-58px',
                    },
                },
            },
        },

        '.Opta_W, .Opta_W>div': {
            background: themedValue(theme, {
                sevennews: colors.white,
                perthnow: 'transparent',
                thewest: 'transparent',
                fallback: undefined,
            }),
        },
    },
}))
