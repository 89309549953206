import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'

export interface SectionHeaderRouteProps extends SectionHeaderProps {}

export const SectionHeaderRegistration = createRegisterableComponent(
    'section-header',
    (props: SectionHeaderRouteProps) => <SectionHeader {...props} />,
)
