import {
    EditionListV4ResponseDTO,
    EditionV4DTO,
} from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateKeyTypes } from '../../../helpers/validation'
import { Paging } from '../paging'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

export function validateGetEditionResponse(value: any): EditionV4DTO {
    const toValidate = value as EditionV4DTO

    if (!toValidate) {
        throw new Error('Edition data missing')
    }

    const errors = validateKeyTypes('edition', value, {
        _self: 'string',
        id: 'string',
        slug: 'string',
        status: 'string',
        source: 'string',
        publicationDate: 'string',
        created: 'string',
        lastUpdated: 'string',
    })

    if (errors) {
        throw new Error(
            'Edition response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}

export const getEdition = (
    services: DataLoaderGlobalParams,
    editionTitle: string,
) => {
    const query = {
        matchAnyId: true,
    }
    return httpGet({
        log: services.log,
        validate: validateGetEditionResponse,
        baseUrl: services.config.contentApi,
        path: `v4/edition/${editionTitle}`,
        query,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}

export function validateGetEditionsListResponse(
    value: any,
): EditionListV4ResponseDTO {
    const toValidate = value

    if (!toValidate) {
        throw new Error('EditionList data missing')
    }

    return value
}

export interface GetEditionsListOptions {
    paging?: Paging
    source?: string
    publishedSince?: string
}

export function getEditionsList(
    services: DataLoaderGlobalParams,
    options: GetEditionsListOptions,
) {
    const { paging = {} as Paging } = options

    const includeTotal = isFeatureEnabled(
        toFeatureState(services.store.getState().toggles),
        'tn-enable-pagination',
        false,
    )

    const query = {
        page: paging.page || 1,
        page_size: paging.pageSize || 8,
        page_offset: paging.pageOffset || 0,
        source: options.source,
        publishedSince: options.publishedSince,
        sort: 'desc',
        includeTotal,
    }

    return httpGet({
        log: services.log,
        validate: validateGetEditionsListResponse,
        baseUrl: services.config.contentApi,
        path: `v4/edition`,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
        query,
    })
}
