import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    AppState,
    BaseClientConfig,
    GetRouteAdTargeting,
    StaticRoutes,
} from '@news-mono/web-common'
import H from 'history'
import { AnyAction, Store } from 'redux'
import { layout } from '../App.routing'

export interface Props {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
    section: TheWestSection
    store: Store<AppState, AnyAction>
}

export const upgradeAppRoute: StaticRoutes<TheWestSection> = {
    '/upgrade-app': ({ getAdTargeting }) => ({
        kind: 'page',
        heading: 'App upgrade needed',
        hideHeading: true,
        pageType: 'static',
        pageMeta: {
            title: `App upgrade needed`,
            link: [],
        },
        adTargeting: getAdTargeting('home', 'default', 'upgrade-app'),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'outerMargin'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'upgrade-app',
                            props: {},
                        }),
                    ],
                },
            }),
        ],
    }),
}
