import { InHouseLink } from '../../navigation/TheWestMainNavigation/components/in_house_links/in_house_links'
import { createRenderTarget } from '../../render-target'
import { TheWestHeaderWeb } from './TheWestHeader.web'

export interface TheWestHeaderProps {
    className?: string
    inHouseLinks?: InHouseLink[]
    pageType?: string
    onEvent: (event: any) => void
    isRegionalSwgCampaignPage?: boolean
}

export const TheWestHeader = createRenderTarget('TheWestHeader', {
    web: TheWestHeaderWeb,
    amp: TheWestHeaderWeb,
    rss: TheWestHeaderWeb,
    preview: TheWestHeaderWeb,
    app: null,
})
TheWestHeader.displayName = 'TheWestHeader'
