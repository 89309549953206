import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { FillProperty } from 'csstype'
import { IconChevronRight } from '../../icons/IconChevronRight/IconChevronRight'
import { IconChevronRightAlt } from '../../icons/IconChevronRightAlt/IconChevronRightAlt'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import {
    fluidTypography,
    textCrop,
    transition,
} from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { Theme } from '../../__styling/themes'

const themedFluidTypography = (theme: Theme) =>
    themedValue(theme, {
        sevennews: fluidTypography(320, 1000, 20, 17),
        fallback: fluidTypography(320, 1220, 24, 32),
    })

const themedTextCrop = (theme: Theme) =>
    themedValue(theme, {
        sevennews: { lineHeight: 1 },
        fallback: textCrop({
            fontSize: 32,
            desiredLineHeight: 1.2,
            cropLineHeight: 1.3,
        }),
    })

const themedFontFamily = (theme: Theme) =>
    themedValue(theme, {
        thewest: fonts.thewest.sansSerifCond,
        perthnow: fonts.perthnow.heading,
        sevennews: fonts.sevennews.sansSerifCond,
        fallback: undefined,
    })

export interface StyledBreadcrumbProps {
    isLast?: boolean
    isLink?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
    horizontalGutters?: keyof ThemeMargins | undefined
}

export const StyledBreadcrumbContainer = styled('div')<{
    verticalSpacing?: keyof ThemeMargins | undefined
    horizontalGutters?: keyof ThemeMargins | undefined
}>(
    {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: -5, // This removes the margin top from breadcrumbs when they are all in one row. (A margin is added to them when they stack)
    },
    ({ theme, verticalSpacing, horizontalGutters }) => ({
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),

        // Horizontal gutters
        paddingLeft:
            horizontalGutters && calcRem(theme.margins[horizontalGutters]),
        paddingRight:
            horizontalGutters && calcRem(theme.margins[horizontalGutters]),
    }),
)

export const StyledBreadcrumb = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
})

export const StyledPageTitle = styled('h1')(
    {
        margin: 0,
        marginTop: calcRem(5), // This margin adds spacing between the breadcrumbs when they wrap and sit on top of eachother
    },
    ({ theme }) => ({
        ...themedFluidTypography(theme),
        ...themedTextCrop(theme),
    }),
)

export const StyledChevron = styled(IconChevronRight)(
    {
        width: calcRem(8),
        height: calcRem(12),
        marginTop: 5, // This margin adds spacing between the breadcrumbs when they wrap and sit on top of eachother
        ...transition({ properties: ['fill'] }),

        [breakpoint('sm')]: {
            width: calcRem(10),
            height: calcRem(16),
        },
    },
    ({ theme }) => ({
        marginRight: theme.margins.sm,
        fill: themedValue(theme, {
            thewest: colors.thewest.greyKoala,
            fallback: colors.black,
        }) as FillProperty,
    }),
)

export const StyledSevenChevron = styled(IconChevronRightAlt)({
    position: 'relative',
    verticalAlign: 'middle',
    marginRight: calcRem(7),
    top: 2,
    width: calcEm(14),
    height: calcEm(17),
    fill: colors.sevennews.charade,
})

export interface BreadcrumbLinkProps {
    enableHeaderTag?: boolean
    isLast: boolean
}

const themedLinkHover = (theme: Theme) =>
    themedValue(theme, {
        thewest: (section) => section.primaryColor,
        perthnow: colors.perthnow.blueKyanite,
        sevennews: (resolved) => resolved.breadCrumb.hoverColor,
        fallback: undefined,
    })

export const StyledLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'enableHeaderTag',
        'isLast',
        'section',
    ]),
})<BreadcrumbLinkProps>(
    {
        display: 'inline',
        alignItems: 'center',
        marginTop: 5, // This margin adds spacing between the breadcrumbs when they wrap and sit on top of eachother
        position: 'relative',
        textTransform: 'uppercase',
        fontWeight: 700,
        textDecoration: 'none',
        ...transition({ properties: ['color'] }),
    },
    ({ theme, isLast, enableHeaderTag }) => [
        (!enableHeaderTag || (enableHeaderTag && !isLast)) && {
            display: 'inline-block',
            ...themedTextCrop(theme),
        },
        {
            marginRight: theme.margins.sm,
            fontFamily: themedFontFamily(theme),
            ...themedFluidTypography(theme),

            color: isLast
                ? themedValue(theme, {
                      thewest: colors.thewest.greyGorilla,
                      perthnow: colors.black,
                      sevennews: (resolved) => resolved.breadCrumb.primaryColor,
                      fallback: undefined,
                  })
                : themedValue(theme, {
                      thewest: colors.thewest.greyKoala,
                      perthnow: colors.perthnow.greyMoonstone,
                      sevennews: colors.sevennews.charade,
                      fallback: undefined,
                  }),
            '&:focus': {
                outline: 'none',
                textDecoration: 'underline',
                color: themedValue(theme, {
                    thewest: colors.thewest.greyGorilla,
                    perthnow: colors.perthnow.greyStone,
                    sevennews: colors.sevennews.charade,
                    fallback: undefined,
                }),
            },
            '&:hover': {
                color: themedLinkHover(theme),
                '& .icon-chevron-right': {
                    fill: themedLinkHover(theme),
                },
            },
        },
    ],
)

export const StyledCNBCLogo = styled('img')(({ theme }) => ({
    height: 'calc(1em - 5px)',
    marginBottom: -2,
}))

export interface BreadcrumbSpanProps {
    isLink: boolean
    isLast?: boolean
}

export const StyledSpan = styled('span')<BreadcrumbSpanProps>(
    {
        position: 'relative',
        display: 'inline-block',
    },
    ({ theme, isLink, isLast }) => [
        isLink === false && {
            display: 'inline-block',
            marginTop: 5,
            position: 'relative',
            textTransform: 'uppercase',
            fontWeight: 700,
            textDecoration: 'none',
            ...themedTextCrop(theme),
        },
        {
            marginRight: theme.margins.sm,
            ...themedFluidTypography(theme),
            fontFamily: themedFontFamily(theme),
            color: isLink
                ? 'inherit'
                : themedValue(theme, {
                      thewest: isLast
                          ? colors.thewest.greyGorilla
                          : colors.thewest.greyKoala,
                      perthnow: isLast
                          ? colors.black
                          : colors.perthnow.greyMoonstone,
                      sevennews: (resolved) =>
                          isLast
                              ? resolved.breadCrumb.primaryColor
                              : colors.sevennews.charade,
                      fallback: undefined,
                  }),
        },
    ],
)
