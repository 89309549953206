import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AmpSophi } from './AmpSophi'
import { AmpSophiProps } from './AmpSophiProps'

export const AmpSophiRegistration = createRegisterableComponent(
    'amp-sophi',
    (props: AmpSophiProps) => {
        return <AmpSophi {...props} />
    },
)
