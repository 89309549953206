import {
    ConfigurationContext,
    searchCrawlerMastheadWhitelist,
} from '@news-mono/web-common'
import React from 'react'
import { TextLinkProps } from './TextLink'

export const RssTextLink: React.FC<TextLinkProps> = ({
    href,
    className,
    openInNewWindow,
    children,
}) => {
    const config = React.useContext(ConfigurationContext)

    let fullHref = href
    if (href.indexOf('http') !== 0) {
        fullHref = `${config.publicUrl}${href}`
    }

    const isCrawlerWhitelisted = searchCrawlerMastheadWhitelist(fullHref)

    return (
        <a
            href={fullHref}
            className={className}
            target={openInNewWindow ? '_blank' : undefined}
            rel={
                openInNewWindow && !isCrawlerWhitelisted
                    ? 'noopener'
                    : undefined
            }
        >
            {children}
        </a>
    )
}
