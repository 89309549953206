/* eslint-disable @typescript-eslint/no-var-requires */
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import {
    getResolvedTopicPageMeta,
    StaticRoute,
    StaticRoutes,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/Landing_page_Banner_Desktop@2x.jpg')
const bannerDesktopX1 = require<string>('./assets/Landing_page_Banner_Desktop@1x.jpg')
const bannerTabletX2 = require<string>('./assets/Landing_page_Banner_Tablet@2x.jpg')
const bannerTabletX1 = require<string>('./assets/Landing_page_Banner_Tablet@1x.jpg')
const bannerMobileX2 = require<string>('./assets/Landing_page_Banner_Mobile@2x.jpg')
const bannerMobileX1 = require<string>('./assets/Landing_page_Banner_Mobile@1x.jpg')
const socialImage = require('./assets/social.jpg')
const stakeSocialImage = require('./assets/social_STAKE.jpg')
const stakeBannerDesktopX1 = require<string>('./assets/Trading_Up_Desktop_Banner_STAKE@1x.jpg')
const stakeBannerDesktopX2 = require<string>('./assets/Trading_Up_Desktop_Banner_STAKE@2x.jpg')
const stakeBannerMobileX1 = require<string>('./assets/Trading_Up_Mobile_Banner_STAKE@1x.jpg')
const stakeBannerMobileX2 = require<string>('./assets/Trading_Up_Mobile_Banner_STAKE@2x.jpg')
const stakeBannerTabletX1 = require<string>('./assets/Trading_Up_Tablet_Banner_STAKE@1x.jpg')
const stakeBannerTabletX2 = require<string>('./assets/Trading_Up_Tablet_Banner_STAKE@2x.jpg')

export const getSocialImageMeta = (image: string) => [
    {
        property: 'og:image',
        content: image,
    },
    { name: 'twitter:image', content: image },
    {
        property: 'og:image:height',
        content: '628',
    },
    {
        property: 'og:image:width',
        content: '1200',
    },
]

const tradingUpRouteInfo: StaticRoute<TheWestSection> = ({
    getAdTargeting,
    config,
    store,
    resolution,
}) => {
    const enableRoute = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'trading-up',
    )

    const isStakeBannerImageActive = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'trading-up-stake-images',
    )

    if (!enableRoute) {
        return null
    }

    const canonicalUrl = config.publicUrl + '/business/trading-up'

    const topic: Topic = {
        id: getResolvedTopicPageMeta(resolution)?.id || 'business/trading-up',
        metadata: {},
        title:
            getResolvedTopicPageMeta(resolution)?.title ||
            getResolvedTopicPageMeta(resolution)?.seoTitle ||
            '',
        seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
        seoDescription:
            getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
    }

    const customTradingUpTopicForAds = {
        id: 'business/trading-up',
        title: 'Trading Up',
        parent: {
            id: 'business',
            title: 'Business',
            metadata: {},
            seoTitle: 'Business',
        },
        metadata: {},
        seoTitle: 'Trading Up',
    }

    return {
        kind: 'page',
        heading: topic.title,
        pageType: 'topic',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title: topic.title,
            description: topic.seoDescription,
            link: [{ rel: 'canonical', href: canonicalUrl }],
            meta: getSocialImageMeta(
                isStakeBannerImageActive ? stakeSocialImage : socialImage,
            ),
        },
        socialMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
        },
        adTargeting: getAdTargeting(
            'home',
            'default',
            customTradingUpTopicForAds,
        ),
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breaking-news-promo-strap',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news-promo-strap',
                                    offset: 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'breadcrumb',
                            props: {
                                topic,
                                sponsor: getTopicSponsor(topic),
                                enableHeaderTag: true,
                                horizontalGutters: 'outerMargin',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: isStakeBannerImageActive
                                    ? stakeBannerDesktopX2
                                    : bannerDesktopX2,
                                verticalGutters: ['unset', 'md'],
                                altText: 'Trading Up',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerDesktopX2
                                                    : bannerDesktopX2,
                                                density: '2x',
                                            },
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerDesktopX1
                                                    : bannerDesktopX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerTabletX2
                                                    : bannerTabletX2,
                                                density: '2x',
                                            },
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerTabletX1
                                                    : bannerTabletX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerMobileX2
                                                    : bannerMobileX2,
                                                density: '2x',
                                            },
                                            {
                                                src: isStakeBannerImageActive
                                                    ? stakeBannerMobileX1
                                                    : bannerMobileX1,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'janus',
                            props: {
                                layoutRatio: '3:1',
                                stretchColumns: true,
                                verticalSpacing: 'xl',
                            },
                            contentAreas: {
                                left: [
                                    layout.component({
                                        type: 'sierra',
                                        props: {
                                            fixedRatios: ['16:9'],
                                            heroImageLayout:
                                                ResponsivePictureLayout.ObjectFitContain,
                                            cardOrientation: {
                                                type: 'Landscape-Portrait',
                                                maxBreakpoint: 'md',
                                            },
                                            cardLayout: ['hero-video'],
                                            sectionHeader: {
                                                heading: 'Latest Episode',
                                            },
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['business/trading-up'],
                                                paging: {
                                                    page: 1,
                                                    pageSize: 1,
                                                },
                                            },
                                        },
                                    }),
                                ],
                                right: [
                                    layout.component({
                                        type: 'tw-trading-up-promo-card',
                                        props: {},
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                sectionHeader: {
                                    heading: 'Watch the episodes',
                                },

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['business/trading-up'],
                                    paging: {
                                        pageSize: 4,
                                        page: 1,
                                        offset: 1,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec('one'),
                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'md',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                hasBackground: true,
                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['business/trading-up'],
                                    paging: {
                                        pageSize: 4,
                                        page: 2,
                                        offset: 0,
                                    },
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const getTradingUpRouteInfo: StaticRoutes<TheWestSection> = {
    '/business/trading-up': tradingUpRouteInfo,
}
