import {
    AdEvent,
    AdUnitSize,
    SlotRenderEndedEvent,
    usePrevious,
} from '@news-mono/web-common'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'
import { useWindowWidth } from '../../__helpers/global-dom-events'
import { createAdViewableEvent } from '../ad-helpers/viewability-events'
import { GptAdSlot } from '../gpt/gpt-ad-slot'
import { AdSlotProps } from './AdUnitWrapper'
import { DebugAdUnit } from './DebugAdUnit'

export interface AdUnitProps extends AdSlotProps {
    unitSize?: AdUnitSize
    onSlotRenderEnded: (event: SlotRenderEndedEvent) => void
    onSlotMutation?: (id: string, mutations: MutationRecord[]) => void
    breakoutContainerRef: React.MutableRefObject<HTMLDivElement | null>
    isViewable: boolean
    threshold: number
    isLoaded?: boolean | undefined
    onEvent: (event: AdEvent) => void
}

export const AdUnit: React.FC<AdUnitProps> = ({
    unitId,
    adState,
    isViewable,
    isLoaded,
    onSlotRenderEnded,
    breakoutContainerRef,
    onSlotMutation,
    onEvent,
    threshold,
}) => {
    const [hasTriggeredViewable, setHasTriggeredViewable] =
        React.useState(false)
    const prevIsLoaded = usePrevious(isLoaded)
    const windowWidth = useWindowWidth()
    const unit = adState.unitLookup(unitId, windowWidth ?? 0)
    React.useEffect(() => {
        if (isLoaded && !prevIsLoaded && unit) {
            onEvent(createAdViewableEvent('adunit.loaded', unit, threshold))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, prevIsLoaded, unit])

    React.useEffect(() => {
        if (isViewable && isLoaded && !hasTriggeredViewable && unit) {
            setHasTriggeredViewable(true)
            onEvent(createAdViewableEvent('adunit.viewable', unit, threshold))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isViewable, hasTriggeredViewable, unit])

    // Callback should only be used for real GptAdSlot - not the debug version
    const handleSlotRenderEnded = React.useCallback(
        (e: googletag.events.SlotRenderEndedEvent) => {
            if (unit) onSlotRenderEnded({ ...e, unit })
        },
        [unit, onSlotRenderEnded],
    )

    if (!unit) {
        return null
    }

    let foundUnitId = unit.id

    // Make sure the ad slot presented for teads is also 'teads4x4'
    // EDIT-1507
    if (unit.originalDefinition.id === 'teads4x4') {
        foundUnitId = 'teads4x4'
    }

    return (
        <FeatureToggle
            feature="debug-ads"
            on={() => (
                <DebugAdUnit
                    isViewable={isViewable}
                    unit={unit}
                    onSlotRenderEnded={onSlotRenderEnded}
                />
            )}
            off={() => (
                <GptAdSlot
                    adContainerRef={breakoutContainerRef}
                    onSlotRenderEnded={handleSlotRenderEnded}
                    onSlotMutation={onSlotMutation}
                    id={foundUnitId}
                />
            )}
        />
    )
}
