import React from 'react'
import { HeaderV2Props, LogoTypes } from './HeaderV2.routing'
import { StyledHeader, StyledHeaderLogo } from './HeaderV2.styled'

const theNightlyLogo = require('./assets/the-nightly.svg')
export function HeaderV2(props: HeaderV2Props) {
    const {
        sectionHeader,
        textCenter,
        specificHeaderSize,
        specificFontSize,
        borderWidth,
        headerLogo,
    } = props

    const StyledHeaderV2 = StyledHeader(specificHeaderSize || 'h2')

    return (
        <StyledHeaderV2
            textCenter={textCenter}
            specificFontSize={specificFontSize}
            borderWidth={borderWidth}
            hasLogo={!!headerLogo}
        >
            {sectionHeader?.heading}
            {renderLogo(headerLogo)}
        </StyledHeaderV2>
    )
}

HeaderV2.displayName = 'HeaderV2'

const renderLogo = (logo: LogoTypes | undefined) => {
    switch (logo) {
        case 'theNightly':
            return <StyledHeaderLogo src={theNightlyLogo} />
        default:
            return
    }
}
