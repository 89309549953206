import styled from '@emotion/styled'
import { breakpoint, calcRem, getComponentTheme } from '../../__styling'
import {
    FacebookIcon,
    IconCheckmarkTN,
    IconEmailTN,
    IconForwardTN,
    IconLink,
    LinkedinIcon,
    XIcon,
} from '../../icons'
import {
    SharingAnimationType,
    appear,
    disappear,
    getAnimationCss,
} from './SharingAnimation.styled'

export const StyledSharing = styled.div<{ compactButtonsOnMobile?: boolean }>(
    ({ theme, compactButtonsOnMobile = false }) => {
        const sharingTheme = getComponentTheme(theme, 'sharing')

        // Compact the gap when specified
        return {
            display: 'flex',
            gap: compactButtonsOnMobile
                ? sharingTheme.compactGap
                : sharingTheme.gap,
            flexWrap: 'wrap',

            [breakpoint('md')]: {
                gap: sharingTheme.gap,
            },
        }
    },
)

export const StyledButton = styled.button<{
    animationType?: SharingAnimationType
    animationIndex: number
    animationTotal: number
}>(({ theme, animationType, animationIndex, animationTotal }) => {
    const buttonTheme = getComponentTheme(theme, 'sharing').button
    return {
        width: calcRem(40),
        height: calcRem(40),
        borderRadius: '50%',
        border: 'none',
        margin: 0,
        padding: 0,
        backgroundColor: buttonTheme.backgroundColor,
        cursor: 'pointer',

        // Center the internal content on the button
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        ['&:is(:hover, :focus)']: {
            backgroundColor: buttonTheme.hover.backgroundColor,
            outline: 'none',
        },

        // Apply all animation variables here!
        ...getAnimationCss(animationType, animationIndex, animationTotal),
    }
})

export const StyledShareButton = styled.button(({ theme }) => {
    const buttonTheme = getComponentTheme(theme, 'sharing').button

    return {
        backgroundColor: buttonTheme.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: calcRem(40),
        border: 'none',
        margin: 0,
        padding: calcRem(8, 18),
        textTransform: 'uppercase',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',

        ['&:is(:hover)']: {
            backgroundColor: buttonTheme.hover.backgroundColor,
            outline: 'none',
        },
    }
})

export const StyledClipboardButton = styled(StyledShareButton)<{
    animationType?: SharingAnimationType
    animationIndex: number
    animationTotal: number
    hideClipboardTextOnMobile?: boolean
}>(
    ({
        theme,
        animationType,
        animationIndex,
        animationTotal,
        hideClipboardTextOnMobile,
    }) => {
        const buttonTheme = getComponentTheme(theme, 'sharing').button

        return {
            backgroundColor: buttonTheme.backgroundColor,
            padding: hideClipboardTextOnMobile ? 0 : calcRem(8, 16),
            width: hideClipboardTextOnMobile ? calcRem(40) : 'auto',
            height: hideClipboardTextOnMobile ? calcRem(40) : 'auto',

            [breakpoint('md')]: {
                padding: calcRem(8, 16),
                width: 'auto',
                height: 'auto',
            },

            ['&:is(:hover, :focus)']: {
                backgroundColor: buttonTheme.hover.backgroundColor,
                outline: 'none',
            },

            // Apply all animation variables here!
            ...getAnimationCss(animationType, animationIndex, animationTotal),
        }
    },
)

export const StyledButtonText = styled.span(({ theme }) => {
    const buttonTheme = getComponentTheme(theme, 'sharing').button

    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: buttonTheme.color,
        fontSize: calcRem(16),
        fontWeight: 400,
        lineHeight: '150%',
        overflow: 'hidden',

        span: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: buttonTheme.gap,

            p: {
                margin: 0,
                padding: 0,
            },
        },
    }
})

export const StyledClipboardButtonText = styled(StyledButtonText)<{
    copied: boolean
    showAnimation?: boolean
    hideOnMobile?: boolean
}>(({ theme, copied, showAnimation = true, hideOnMobile = false }) => {
    const buttonTheme = getComponentTheme(theme, 'sharing').button

    return [
        {
            color: buttonTheme.color,

            span: {
                minWidth: calcRem(121),
                transition: '0.5s all ease-in-out',
            },
        },
        copied && {
            span: {
                ':first-child': {
                    opacity: 0,
                    animation: showAnimation ? `${disappear} 0.5s ease` : '',
                    display: 'none',
                },
                ':last-child': {
                    opacity: 1,
                    animation: showAnimation ? `${appear} 0.5s ease` : '',
                    display: 'flex',
                },
            },
        },
        !copied && {
            span: {
                ':first-child': {
                    opacity: 1,
                    animation: showAnimation ? `${appear} 0.5s ease` : '',
                    display: 'flex',
                    minWidth: 0,
                },
                ':last-child': {
                    opacity: 0,
                    display: 'none',
                    animation: showAnimation ? `${disappear} 0.5s ease` : '',
                },
            },
        },
        hideOnMobile && {
            span: {
                p: {
                    display: 'none',

                    [breakpoint('md')]: {
                        display: 'flex',
                    },
                },
            },
        },
    ]
})

// Styled Icons

export const StyledLinkIcon = styled(IconLink)(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'sharing').icon

    return {
        width: calcRem(22),
        height: calcRem(22),
        fill: iconTheme.fill,
    }
})
export const StyledLinkedInIcon = styled(LinkedinIcon)(({ theme }) => {
    const iconTheme = getComponentTheme(theme, 'sharing').icon

    return {
        width: calcRem(22),
        height: calcRem(22),
        fill: iconTheme.fill,
        path: {
            fill: iconTheme.fill,
        },
    }
})
export const StyledFacebookIcon = styled(FacebookIcon)({
    width: calcRem(22),
    height: calcRem(22),
})
export const StyledTwitterIcon = styled(XIcon)({
    width: calcRem(22),
    height: calcRem(22),
})
export const StyledEmailIcon = styled(IconEmailTN)({
    width: calcRem(24),
    height: calcRem(24),
    fill: 'none',
    color: 'black',
})
export const StyledCheckmarkIcon = styled(IconCheckmarkTN)({
    width: calcRem(24),
    height: calcRem(24),
})
export const StyledForwardIcon = styled(IconForwardTN)({
    width: calcRem(24),
    height: calcRem(24),
})
