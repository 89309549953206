import styled from '@emotion/styled'
import { metrics } from '../__styling'

export const ErrorTypeText = styled.p(({ theme }) => [
    {
        fontFamily: theme.fonts.sansSerif,
        fontStyle: 'normal',
        fontSize: 10,
        color: theme.colors.text.weak,

        marginTop: 50,
    },
    theme.kind === 'thewest' && {
        marginLeft: metrics.thewest.margins.lg,
    },
])
