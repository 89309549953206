import {
    ArticleLikePublication,
    isArticleLikeType,
    isLiveSportsTopic,
    Topic,
} from '@news-mono/web-common'
import { ClassificationV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import {
    InlineContentTypes,
    InlineSportsHubCallToAction,
} from '../SharedPublication.routing'

export interface getInlinePositionedSportsHubCtaProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlineCallToAction(
    content: InlineContentTypes,
): content is InlineSportsHubCallToAction {
    return content.kind === 'sports-hub-call-to-action'
}

export const isLiveSportArticle = (
    classification: ClassificationV4DTO,
    primaryTopic: Topic,
    secondaryTopics?: Topic[],
) => {
    const validLabel =
        classification.label === 'live' ||
        classification.label === 'replay' ||
        classification.label === 'upcoming'

    if (classification.kind !== 'editorial' || !validLabel) {
        return false
    }

    const validSecondaryTopics: string[] = []

    secondaryTopics &&
        secondaryTopics.forEach((topic) => {
            if (isLiveSportsTopic(topic.id)) {
                validSecondaryTopics.push(topic.id)
            }
        })

    const isValidPrimaryTopic = isLiveSportsTopic(primaryTopic.id)
    const isValidSecondaryTopic =
        validSecondaryTopics && validSecondaryTopics.length >= 1

    if (isValidPrimaryTopic || isValidSecondaryTopic) {
        return true
    }

    return false
}

export function getInlinePositionedSportsHubCta({
    publication,
    inlinePublicationContent,
}: getInlinePositionedSportsHubCtaProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    if (
        !publication.classification ||
        !isLiveSportArticle(
            publication.classification,
            publication.primaryTopic,
            publication.secondaryTopics,
        )
    ) {
        return []
    }

    const classificationType =
        publication.classification && publication.classification.label
    const message =
        classificationType === 'live'
            ? 'WATCH LIVE IN THE WINDOW ABOVE'
            : 'WATCH ON DEMAND IN THE WINDOW ABOVE'

    return inlinePublicationContent
        .filter(isInlineCallToAction)
        .map((content: InlineSportsHubCallToAction) => {
            return {
                position: content.insertAfter,
                element: (
                    <p>
                        <strong>{message}</strong>
                    </p>
                ),
            }
        })
}
