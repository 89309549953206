import { tokens } from '@news-mono/design-tokens'
import {
    BreakpointState,
    calcRem,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../__styling'
import { theNightlySectionDefinitions } from '../../__styling/settings/sections/thenightly'

export type LandscapeNightlyTheme<T extends ThemeConfig> = {
    number: {
        color: string

        breakpointState: BreakpointState<
            T,
            {
                fontSize: string
                lineHeight: string
            }
        >
    }
}

export const theNightlyLandscapeTheme = (scheme: TheNightlyTheme) => {
    const theme: LandscapeNightlyTheme<TheNightlyThemeConfig> = {
        number: {
            color:
                //is there a better way to ovveride the default colour scheme
                scheme.sectionValues.numberColor ===
                theNightlySectionDefinitions.default.numberColor
                    ? tokens.thenightly.colors.palette.neutral['50']
                    : scheme.sectionValues.numberColor,

            breakpointState: {
                default: {
                    fontSize: calcRem(32),
                    lineHeight: calcRem(40),
                },
                sm: {
                    fontSize: calcRem(36),
                    lineHeight: calcRem(40),
                },
                lg: {
                    fontSize: calcRem(40),
                    lineHeight: calcRem(52),
                },
            },
        },
    }
    return theme
}
