import { keyframes } from '@emotion/css'
import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, themedValue } from '../../..'
import { Sharing } from '../../../buttons/Sharing/Sharing'
import { colors } from '../../../__styling/settings/colors'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

const gradialBlue = tokens['thewest'].colors.palette.gradialBlue
const gradialRed = tokens['sevennews'].colors.palette.gradialRed
const gradialPink = tokens['perthnow'].colors.palette.fadedPinkThulite
const gradualGrey = tokens['thenightly'].colors.palette.neutral[20]

interface Keyframes {
    [key: string]: CSSObject
}

const shadingAnimation = (
    initialColor: string,
    finalColor: string,
): Keyframes => {
    return {
        '10%': {
            background: initialColor,
        },
        '100%': {
            background: finalColor,
        },
    }
}

export const StyledLiveEventPost = styled('div')<{
    /** Post has no bottom margin or border. Generally due to being placed in truncated container */
    noBotMargin?: boolean
    isActivePost?: boolean
    isPinnedPost?: boolean
}>(({ theme, noBotMargin, isActivePost, isPinnedPost }) => [
    {
        padding: calcRem(theme.margins.md),
        marginBottom: noBotMargin ? undefined : theme.margins.sm,
        background: theme.colors.palette.white,

        [breakpoint('sm')]: {
            margin: theme.margins.md,
            marginBottom: noBotMargin ? 0 : theme.margins.md,
        },
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        boxShadow: noBotMargin ? undefined : `0px 0px 11px rgba(0, 0, 0, 0.25)`,

        [breakpoint('md')]: {
            margin: calcRem(theme.margins.md, theme.margins.sm),
            marginBottom: noBotMargin
                ? 0
                : calcRem(theme.margins.md, theme.margins.sm),
            borderBottom: 'none',
        },
    },
    // Styling for TW
    theme.kind === 'thewest' && {
        boxShadow: noBotMargin ? undefined : `0px 2px 3px rgba(0, 0, 0, 0.08)`,

        [breakpoint('md')]: {
            padding: calcRem(theme.margins.lg, theme.margins.md),
            margin: 0,
            marginBottom: 0,
            borderBottom: noBotMargin
                ? undefined
                : `1px solid ${colors.thewest.greyQuokka}`,
        },
    },
    // Styling for PN
    theme.kind === 'perthnow' && {
        boxShadow: noBotMargin ? undefined : `0px 2px 3px rgba(0, 0, 0, 0.08)`,

        [breakpoint('md')]: {
            margin: 0,
            marginBottom: 0,
            borderBottom: noBotMargin
                ? undefined
                : `1px solid ${colors.thewest.greyQuokka}`,
        },
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        padding: isPinnedPost ? calcRem(24) : calcRem(32, 24),
        background: 'inherit',
        marginBottom: 0,
        margin: 0,
        borderBottom: isPinnedPost
            ? 'none'
            : `${calcRem(0.5)} solid ${theme.colors.borders.secondary}`,

        [breakpoint('sm')]: {
            marginBottom: 0,
            margin: 0,
        },
    },
    // ------------------------------------------------------------
    // - I S   A C T I V E   P O S T
    // ------------------------------------------------------------
    isActivePost && {
        animation: `${keyframes(
            shadingAnimation(
                themedValue(theme, {
                    thewest: gradialBlue,
                    sevennews: gradialRed,
                    perthnow: gradialPink,
                    thenightly: gradualGrey,
                    fallback: gradialBlue,
                }),
                theme.colors.palette.white,
            ),
        )} 3s ease`,
    },
])

export const StyledLiveEventTitle = styled('h2')(({ theme }) => [
    {
        margin: '',
        fontSize: calcRem(26),
        fontFamily: 'inherit',
        fontWeight: 400,
        color: theme.colors.palette.black,

        lineHeight: calcRem(31),
    },
    // Styling for TW
    theme.kind === 'thewest' && {
        margin: calcRem(theme.margins.md, 0),
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        margin: calcRem(theme.margins.sm, 0),
        fontSize: calcRem(21),
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 700,
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        margin: calcRem(theme.margins.md, 0, theme.margins.sm),

        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(20),
        fontWeight: 700,
        lineHeight: calcRem(24),
        letterSpacing: calcRem(-0.44),

        [breakpoint('md')]: {
            fontSize: calcRem(25),
            fontWeight: 700,
            lineHeight: calcRem(32),
            letterSpacing: calcRem(-0.55),
        },
    },
])

export const StyledLiveEventArticleBlockContent = styled('div')(({ theme }) => [
    {
        margin: calcRem(theme.margins.md, 0),

        '& > div > p': {
            fontFamily: 'inherit',
            fontSize: calcRem(16),
            lineHeight: calcRem(24),
        },

        '& > div > blockquote > p': {
            marginLeft: theme.margins.md,
            fontSize: calcRem(21),
            fontWeight: 400,
            fontFamily: theme.fonts.serif,

            color: 'inherit',
            lineHeight: calcRem(25),
        },
        '& > div > blockquote > div': {
            width: 4,
        },
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        '& > div > p': {
            fontFamily: theme.fonts.sansSerif,
            fontWeight: 400,
            lineHeight: calcRem(27),
        },
        '& > div > blockquote > p': {
            marginLeft: 0,
            fontSize: calcRem(18),
            fontWeight: 700,
            fontFamily: theme.fonts.sansSerif,
            color: theme.colors.brand,
        },
        '& > div > blockquote ': {
            transform: 'translateX(0)',
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
            maxWidth: '100%',
            '& > p': {
                maxWidth: '100%',
            },
        },
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        margin: 0,

        '& > div > p': {
            fontFamily: theme.fonts.serif,
            fontWeight: 400,

            fontSize: calcRem(20),
            lineHeight: calcRem(30),
            letterSpacing: calcRem(-0.4),
            margin: calcRem(0, 0, 16, 0),

            [breakpoint('md')]: {
                fontFamily: theme.fonts.serif,
                fontSize: calcRem(20),
                lineHeight: calcRem(30),
                letterSpacing: calcRem(-0.4),
                margin: calcRem(0, 0, 16, 0),
            },
        },

        '& > div > blockquote > p': {
            fontSize: calcRem(18),
            fontFamily: theme.fonts.sansSerif,
        },

        '& > div > div > p': {
            margin: 0,
        },
    },
])

export const StyledLiveEventSocialIcons = styled(Sharing)(({ theme }) => [
    {
        display: 'inline-block', // To avoid margin collapse
        '&': {
            marginBottom: 0,
            marginTop: 0,
        },
        '& > button ': {
            position: 'relative',
            color: colors.thewest.greyKoala,
            backgroundColor: colors.thewest.greyErmine,
            border: 'none',
            width: calcRem(32),
            height: calcRem(32),

            [breakpoint('md')]: {
                width: calcRem(24),
                height: calcRem(24),
            },
        },
        '& > button:hover ': {
            color: colors.thewest.greyKoala,
            backgroundColor: colors.thewest.greyElephant,
            border: 'none',
        },
        '& > button:active ': {
            color: colors.thewest.greyKoala,
            backgroundColor: colors.thewest.greyQuokka,
            border: 'none',
        },
        '& > button.gtm-clipboard-share-button:active:before': {
            opacity: 1,
            transition: '0s',
        },
        '& > button.gtm-clipboard-share-button:before': {
            position: 'absolute',
            top: calcRem(-35),
            left: calcRem(-31),
            width: calcRem(98),
            opacity: 0,
            padding: calcRem(6),
            content: '"✓ Link Copied "',
            transition: 'opacity 1.7s cubic-bezier(.0,.05,1,-0.24)',
            fontFamily: theme.fonts.sansSerif,
            fontSize: calcRem(13),
            fontWeight: 600,
            background: theme.colors.palette.black,
            color: theme.colors.palette.white,
        },
    },
    // Styling for TW
    theme.kind === 'thewest' && {
        '&': {
            marginTop: calcRem(theme.margins.sm),
        },
        '& > button:hover ': {
            backgroundColor: colors.thewest.greyDolphin,
        },
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        '& > button ': {
            color: 'inherit',
            backgroundColor: 'inherit',
            border: 'solid 1px',
            width: calcRem(40),
            height: calcRem(40),

            [breakpoint('md')]: {
                width: calcRem(40),
                height: calcRem(40),
            },
        },
        '& > button:hover ': {
            color: theme.colors.brand,
            backgroundColor: 'none',
            border: `solid ${theme.colors.brand} 1px`,
        },
        '& > button:active ': {
            color: 'inherit',
            backgroundColor: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
        '& > button.gtm-facebook-share-button > svg': {
            top: '53%',
        },
        '& > button.gtm-clipboard-share-button:before': {
            fontSize: calcRem(12),
        },
    },
])
