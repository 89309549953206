import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../../__styling/settings/colors'

export interface TheNightlyHorizontalLogoProps {
    className?: string
    fillColor?: string
    logoWidth?: '100%' | number | undefined
    logoHeight?: number | undefined
}

const StyledSVG = styled('svg')<TheNightlyHorizontalLogoProps>(
    {
        color: colors.black,
    },
    (props) => ({
        // IE11 and some android browsers requires a height and width to render correctly
        maxHeight: props.logoHeight,
        maxWidth: props.logoWidth,
        width: '100%',
    }),
)

export const TheNightlyHorizontalLogo: React.FC<TheNightlyHorizontalLogoProps> =
    ({ className, fillColor = colors.black, logoWidth, logoHeight }) => (
        <StyledSVG
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1660 200"
            logoWidth={logoWidth}
            logoHeight={logoHeight}
        >
            <g>
                <title>TheNightly</title>
                <path
                    d="M362.264 189.891V2.56055H497.274V43.5391H413.121V75.7366H490.322V116.715H413.121V148.913H496.908V189.891H362.264Z"
                    fill={fillColor}
                />
                <path
                    d="M175.518 189.891V2.56055H226.375V75.7366H293.697V2.56055H344.554V189.891H293.697V116.715H226.375V189.891H175.518Z"
                    fill={fillColor}
                />
                <path
                    d="M0 43.5391V2.56055H162.817V43.5391H106.471V189.891H56.3456V43.5391H0Z"
                    fill={fillColor}
                />
                <path
                    d="M1471.94 2.56055H1528.65L1565.24 78.6636H1566.7L1603.29 2.56055H1660L1591.21 130.984V189.891H1540.72V130.984L1471.94 2.56055Z"
                    fill={fillColor}
                />
                <path
                    d="M1372.87 189.891V2.56055H1423.72V148.913H1499.46V189.891H1372.87Z"
                    fill={fillColor}
                />
                <path
                    d="M1197.35 43.5391V2.56055H1360.16V43.5391H1303.82V189.891H1253.69V43.5391H1197.35Z"
                    fill={fillColor}
                />
                <path
                    d="M1015.72 189.891V2.56055H1066.58V75.7366H1133.9V2.56055H1184.76V189.891H1133.9V116.715H1066.58V189.891H1015.72Z"
                    fill={fillColor}
                />
                <path
                    d="M944.85 64.2433C944.057 61.019 942.807 58.1901 941.1 55.7567C939.392 53.2624 937.258 51.1635 934.697 49.4601C932.197 47.6958 929.27 46.3878 925.916 45.5361C922.623 44.6236 918.994 44.1673 915.031 44.1673C906.493 44.1673 899.206 46.2053 893.169 50.2814C887.193 54.3574 882.62 60.2281 879.449 67.8935C876.339 75.5589 874.784 84.8061 874.784 95.635C874.784 106.586 876.278 115.954 879.266 123.741C882.254 131.529 886.705 137.49 892.62 141.627C898.536 145.764 905.884 147.833 914.665 147.833C922.409 147.833 928.843 146.707 933.965 144.456C939.148 142.205 943.02 139.011 945.582 134.875C948.143 130.738 949.423 125.871 949.423 120.274L958.205 121.186H915.397V85.0494H998.451V110.966C998.451 128 994.823 142.57 987.566 154.677C980.371 166.722 970.431 175.97 957.747 182.418C945.124 188.806 930.642 192 914.299 192C896.066 192 880.059 188.137 866.277 180.411C852.496 172.684 841.733 161.673 833.988 147.376C826.305 133.08 822.463 116.076 822.463 96.365C822.463 80.9126 824.811 67.2243 829.506 55.3004C834.263 43.3764 840.848 33.308 849.264 25.0951C857.679 16.8213 867.405 10.5856 878.443 6.38783C889.48 2.12928 901.31 0 913.933 0C925.031 0 935.337 1.58175 944.85 4.74525C954.424 7.84791 962.87 12.289 970.187 18.0684C977.566 23.7871 983.511 30.5703 988.024 38.4183C992.536 46.2662 995.28 54.8745 996.256 64.2433H944.85Z"
                    fill={fillColor}
                />
                <path
                    d="M808.752 2.56055V189.891H757.895V2.56055H808.752Z"
                    fill={fillColor}
                />
                <path
                    d="M739.492 189.701V2.30078H688.627V100.393H687.163L620.563 2.30078H577.383V128.796H628.248V91.2426H629.346L697.043 189.701H739.492Z"
                    fill={fillColor}
                />
                <path
                    d="M602.595 189.701C588.658 189.701 577.359 178.4 577.359 164.46C577.359 150.52 588.658 139.22 602.595 139.22C616.532 139.22 627.83 150.52 627.83 164.46C627.83 178.4 616.532 189.701 602.595 189.701Z"
                    fill={fillColor}
                />
            </g>
        </StyledSVG>
    )
TheNightlyHorizontalLogo.displayName = 'TheNightlyHorizontalLogo'
