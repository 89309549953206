import { ArticleCardList, ArticleCardListProps } from '../ArticleCardList'
import React from 'react'
import { AdUnitWrapper } from '../../../advertising'
import {
    AdDefinition,
    AdEvent,
    AdState,
    BaseClientConfig,
} from '@news-mono/web-common'
import { CollectionEvent } from '../../../../../web-common/tsc-out/events/collection-events'
import { AdContainer } from '../ArticleCardList.styled'
import { TNNewsletterSignup } from '../../../banners/NewsletterSignup/TNNewsletterSignup'
import { RecaptchaProvider } from '../../../recaptcha/Recaptcha'
import { QueryClient, QueryClientProvider } from 'react-query'
import { NewsletterSignupEvent } from 'libs/web-common/src/events/newsletter-signup-events'

const queryClient = new QueryClient()

export type JournoProfileArticleCardListProps = Omit<
    ArticleCardListProps,
    'interlacedComponents'
> & {
    adUnits: number
    api: string
    adState: AdState
    onEvent: (event: CollectionEvent | AdEvent | NewsletterSignupEvent) => void
    config?: BaseClientConfig
}

const INITIAL_AD_OFFSET = 4
const CARDS_BETWEEN_ADS = 4
const getAdUnitId = (index: number) => `article-list-${index}`

// Builds an array of the AdUnitDefinitions.
export const getJournoProfileAdUnitDefinitions = (adUnitAmount: number) =>
    Array.from(
        { length: adUnitAmount },
        (_, i) => INITIAL_AD_OFFSET + i * CARDS_BETWEEN_ADS,
    ).map<AdDefinition>((index) => ({
        id: getAdUnitId(index),
        size: 'billboardAbove1280leaderboardAbove768MrecBelow',
    }))

/**
 * An ArticleCardList configured specifically for the Journo Profile page on the nightly.
 */
export const JournoProfileArticleCardList = ({
    adUnits,
    api,
    adState,
    onEvent,
    config,
    ...props
}: JournoProfileArticleCardListProps) => {
    return (
        <ArticleCardList
            {...props}
            onEvent={onEvent}
            interlacedComponents={{
                'newsletter-promo': {
                    component: () => (
                        <>
                            {config && config.recaptureSiteKey ? (
                                <RecaptchaProvider
                                    siteKey={config.recaptureSiteKey}
                                >
                                    <QueryClientProvider client={queryClient}>
                                        <TNNewsletterSignup
                                            text={`Sign up to The Nightly's newsletters.`}
                                            subText={
                                                'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.'
                                            }
                                            buttonLabel={'Get the Newsletter'}
                                            mode={'default'}
                                            onEvent={onEvent}
                                        />
                                    </QueryClientProvider>
                                </RecaptchaProvider>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    initialOffset: 3,
                },
                adverts: {
                    component: (index) => (
                        <AdContainer
                            margin={{ default: '16px 0', sm: '56px 0' }}
                        >
                            <AdUnitWrapper
                                adState={adState}
                                onEvent={onEvent}
                                noticePosition={'above-center'}
                                adType={'inline'}
                                unitId={getAdUnitId(index)}
                            />
                        </AdContainer>
                    ),
                    initialOffset: INITIAL_AD_OFFSET,
                    cardsBetween: CARDS_BETWEEN_ADS,
                    repetitions: adUnits,
                },
            }}
            adTargeting={props.adTargeting}
        />
    )
}
