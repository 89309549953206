export const enum Product {
    PerthNow = 'perthnow',
    TheWest = 'thewest',
    SevenNews = 'sevennews',
    TheNightly = 'thenightly',
}

export const getProductName = (product: Product, shortName?: boolean) => {
    const theWestName = shortName ? 'The West' : 'The West Australian'

    switch (product) {
        case Product.PerthNow:
            return 'PerthNow'
        case Product.TheWest:
            return theWestName
        case Product.SevenNews:
            return '7NEWS.com.au' // Product name as requested by 7NEWS, should be capital 7NEWS and appear in RSS, Meta etc.
        case Product.TheNightly:
            return 'TheNightly'
        default:
            throw new Error('Invalid Product')
    }
}
