import {
    StyledLabel,
    StyledSelect,
    StyledSelectContainer,
} from './DropdownMenu.styled'
import React, { FC } from 'react'

export interface DropdownMenuOption {
    displayName: string
    value: string
    isSelected: boolean
}

export interface DropdownMenuProps {
    name: string
    label: string
    options: DropdownMenuOption[]
    onChange: (value: string) => void
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
    name,
    label,
    options,
    onChange,
}) => {
    return (
        <StyledSelectContainer>
            <StyledLabel htmlFor={name}>{label}</StyledLabel>
            <StyledSelect
                name={name}
                id={name}
                onChange={(e) => onChange(e.currentTarget.value)}
            >
                {options.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                        selected={option.isSelected}
                    >
                        {option.displayName}
                    </option>
                ))}
            </StyledSelect>
        </StyledSelectContainer>
    )
}
