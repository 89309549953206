import styled from '@emotion/styled'
import {
    StyledHeadshot,
    StyledHeadshotWrapper,
} from '../../cards/CardByline/CardByline.styled'
import { breakpoint, calcRem } from '../../__styling'

const headshotSize = 80
const headshotSizeSmaller = 64

interface SizingProps {
    sizing?: 'default' | 'smaller'
}

export const StyledFactCheckerSectionHeader = styled('div')<SizingProps>(
    ({ theme, sizing }) => ({
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.borders.primary}`,

        [breakpoint('sm')]: {
            borderBottom: sizing === 'default' ? 'none' : undefined,
            marginBottom: calcRem(16),
        },

        '& h2': {
            fontSize: calcRem(40),
            lineHeight: 1.2,
        },

        '& h2 span': {
            fontSize: calcRem(22),
            color: theme.colors.brand,
            display: 'block',
        },
    }),
    ({ sizing, theme }) => {
        if (sizing === 'smaller') {
            return {
                borderBottom: `1px solid ${theme.colors.borders.primary}`,

                '& h2': {
                    fontSize: calcRem(22),
                    // marginBottom: calcRem(16),
                },
            }
        }
    },
)

export const StyledFactCheckerHeadshotWrapper = styled(
    StyledHeadshotWrapper,
)<SizingProps>(
    ({ theme }) => ({
        border: `1px solid ${theme.colors.borders.primary}`,
        width: calcRem(headshotSize),
        height: calcRem(headshotSize),
        marginRight: calcRem(16),
    }),
    ({ sizing }) => {
        if (sizing === 'smaller') {
            return {
                width: calcRem(headshotSizeSmaller),
                height: calcRem(headshotSizeSmaller),
            }
        }
    },
)

export const StyledFactCheckerHeadshot = styled(StyledHeadshot)<SizingProps>(
    {
        width: calcRem(headshotSize),
        height: calcRem(headshotSize),
    },
    ({ sizing }) => {
        if (sizing === 'smaller') {
            return {
                width: calcRem(headshotSizeSmaller),
                height: calcRem(headshotSizeSmaller),
            }
        }
    },
)
