import { useTheme } from '@emotion/react'
import React from 'react'
import { IconWestLiveProps } from '../../icons/IconWestLive/IconWestLive'
import {
    StyledHomeLink,
    StyledWestLiveIcon,
} from './TheWestHeaderActions.styled'

export const WestLiveAction: React.FC<IconWestLiveProps> = ({
    showEqualizer,
}) => {
    const { section } = useTheme()
    // as per DPT-2067
    const to =
        section === 'default'
            ? '/the-west-live'
            : 'https://thewest.com.au/the-west-live'
    return (
        <StyledHomeLink to={to} aria-label="The West Live">
            <StyledWestLiveIcon showEqualizer={showEqualizer} />
        </StyledHomeLink>
    )
}
WestLiveAction.displayName = 'WestLiveAction'
