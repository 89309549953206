import styled from '@emotion/styled'
import { Product, WebLink } from '@news-mono/web-common'
import { IconPremium } from '../../../icons'
import { breakpoint, calcRem, fonts, themedValue } from '../../../__styling'

export const StyledEpaperCollectionPaperMainContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        overflowX: theme.kind === 'thenightly' ? undefined : 'scroll',
        flexWrap: theme.kind === 'thenightly' ? 'wrap' : undefined,
        gap: theme.kind === 'thenightly' ? calcRem(25) : calcRem(8),
        justifyContent: 'flex-start',
        [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
            display: 'flex',
            flexWrap: 'wrap',
            overflowX: 'visible',
            paddingBottom:
                theme.kind === 'thenightly' ? calcRem(100) : undefined,
        },
    }),
)

export const StyledEpaperCollectionPaperContainer = styled('div')(
    ({ theme }) => ({
        flexGrow: 1,
        width: theme.kind === 'thenightly' ? '30%' : undefined,
        [breakpoint('xxs')]: {
            maxWidth: theme.kind === 'thenightly' ? '29.6%' : undefined,
        },
        [breakpoint('sm')]: {
            width: theme.kind === 'thenightly' ? '17%' : undefined,
        },
        [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
            flex: themedValue(theme, {
                thewest: `0 1 ${calcRem(167)}`,
                perthnow: `0 1 ${calcRem(133)}`,
                fallback: undefined,
            }),
        },
    }),
)

export const StyledEpaperCollectionPaperWrapper = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: calcRem(14),
        [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
            display: 'flex',
            alignItems: 'start',
            marginTop:
                theme.kind === Product.TheWest ? calcRem(-3) : calcRem(-4),
            paddingBottom:
                theme.kind === Product.TheWest ? calcRem(16) : calcRem(12),
        },
    }),
)

export const StyledEpaperCollectionPaperSpanText = styled('text')(
    ({ theme }) => ({
        fontSize: calcRem(20),
        fontFamily:
            theme.kind === 'thenightly'
                ? theme.fonts.heading
                : fonts.designSystem.sansSerif,
        fontWeight: theme.kind === 'thenightly' ? 700 : 500,
        textTransform: theme.kind === 'thenightly' ? 'uppercase' : undefined,
        paddingTop: theme.kind === 'thenightly' ? calcRem(8) : undefined,
        [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
            fontSize: themedValue(theme, {
                thewest: calcRem(16),
                perthnow: calcRem(14),
                thenightly: calcRem(25),
                fallback: undefined,
            }),
            lineHeight:
                theme.kind === Product.TheWest ? calcRem(20) : calcRem(17),
            paddingTop: theme.kind === 'thenightly' ? calcRem(24) : undefined,
        },
    }),
)

export const StyledEpaperCollectionPapersStyledImg = styled('img')(
    ({ theme }) => ({
        width: theme.kind === 'thenightly' ? '100%' : calcRem(190),
        height: theme.kind === 'thenightly' ? '100%' : calcRem(270),
        objectFit: 'cover',
        filter: theme.kind === Product.TheWest ? 'brightness(0.93)' : undefined,
        objectPosition: 'right',
        border:
            theme.kind === 'thenightly'
                ? `solid 1px ${theme.colors.borders.primary}`
                : undefined,
        borderRadius: theme.kind === 'thenightly' ? calcRem(4) : undefined,
        boxShadow:
            theme.kind === 'thenightly'
                ? '0px 21.8017px 28.51202px -13.87381px rgba(0, 0, 0, 0.15)'
                : undefined,
        [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
            maxWidth: '100%',
            height: themedValue(theme, {
                thewest: calcRem(233),
                perthnow: calcRem(194),
                thenightly: '100%',
                fallback: calcRem(233),
            }),
        },
    }),
)

export const StyledIconPremium = styled(IconPremium)(({ theme }) => ({
    width: calcRem(25),
    height: 'auto',
    verticalAlign: 'text-bottom',
    marginRight: calcRem(1),
    paddingTop: calcRem(4),
    flexShrink: 0,
    [breakpoint('lg')]: {
        width: themedValue(theme, {
            thewest: calcRem(20),
            perthnow: calcRem(18),
            fallback: undefined,
        }),
        marginTop: calcRem(1),
        paddingTop: 0,
    },
}))

export const StyledDate = styled('div')(({ theme }) => ({
    [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
        display: 'flex',
        flexFlow: 'column',
    },
}))

export const StyledDateSpan = styled('span')(({ theme }) => ({
    [breakpoint(theme.kind === Product.TheWest ? 'lg' : 'md')]: {
        width: '100%',
    },
}))

export const StyledNightlyLink = styled(WebLink)(({ theme }) => ({
    color: theme.colors.actions.link.active,
    '&:hover, &:focus': {
        textDecoration: 'none',
    },
    display: 'none',
    [breakpoint('md')]: {
        display: 'block',
    },
}))
