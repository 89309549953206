import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import {
    calcRem,
    colors,
    fonts,
    metrics,
    PerthNowThemeConfig,
    TheNightlyThemeConfig,
} from '../../../__styling'

// ? Do we really need yet another button component ?
export const WardenButton = styled('button')(({ theme }) => [
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',

        borderRadius: calcRem(8),
        border: 0,

        color: colors.white,
    },
    theme.kind === 'thenightly' && [
        textMixin<TheNightlyThemeConfig>(theme, 'button-label'),
        {
            padding: calcRem(
                metrics.thenightly.margins.sm2l,
                metrics.thenightly.margins.m2l,
            ),

            background: tokens.thenightly.colors.palette.uplate.default,

            '&:active, &:hover, &:focus': {
                background: tokens.thenightly.colors.palette.uplate.hover,
                cursor: 'pointer',
            },
        },
    ],
    theme.kind === 'perthnow' && [
        textMixin<PerthNowThemeConfig>(theme, 'Label/L/Semibold'),
        {
            height: 48,
            padding: calcRem(8, 24),

            backgroundColor: tokens.perthnow.colors.palette.pinkThulite,

            // Hover effect
            '&:active, &:hover, &:focus': {
                background: tokens.perthnow.colors.palette.pinkGarnet,
                cursor: 'pointer',
            },
        },
    ],
])
