import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

export const ScrollToTopOnNav = withRouter(
    class extends React.Component<RouteComponentProps<{}>, {}> {
        componentDidUpdate(prevProps: RouteComponentProps<{}>) {
            if (
                typeof window !== 'object' ||
                this.props.location === prevProps.location ||
                this.props.history.action !== 'PUSH'
            ) {
                return
            }

            // avoiding smooth scroll animation is a whole controversy. Auto option doesn't cut it
            // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195#issuecomment-977170733
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant' as ScrollBehavior,
            })
        }

        render() {
            if (!this.props.children) {
                return null
            }
            return React.Children.only(this.props.children)
        }
    },
)
