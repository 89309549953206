import React from 'react'
import {
    Product,
    PublicationCardItem,
    createCardClickedEvent,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import {
    StyledOlympicsFeatureArticle,
    StyledOlympicsFeatureArticleImage,
    StyledOlympicsFeatureArticleTeaser,
    StyledOlympicsFeatureArticleText,
    StyledOlympicsFeatureArticleTitle,
    StyledLink,
    StyledOlympicsFeatureArticleInner,
    StyledOlympicsArticleKicker,
} from './OlympicsCollectionFeatureArticle.styled'
import { OlympicsCollectionByline } from '../OlympicsCollectionByline/OlympicsCollectionByline'
import { getFundingType } from '../../templates'
import { useProduct } from '../../__product'
import { OlympicsCollectionKicker } from '../OlympicsCollectionKicker/OlympicsCollectionKicker'

export interface FeatureArticleProps {
    item: PublicationCardItem | undefined
    onEvent: (event: any) => void
    cardNumber?: number
}

export const OlympicsCollectionFeatureArticle: React.FC<FeatureArticleProps> =
    ({ item, onEvent, cardNumber = 1 }) => {
        const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
            if (!item) {
                // We don't want the link to do anything
                e.preventDefault()
                return
            }

            // Fire off the appropriate article click events
            onEvent(
                createCardClickedEvent(
                    onEvent,
                    item,
                    'OlympicsCollectionFeatureArticleCard',
                    'OlympicsFeatureArticleCard',
                    cardNumber,
                    fundingType,
                ),
            )
        }

        const storyClassification = true
        const product = useProduct()

        // Item isn't loaded or is undefined, to stop everything breaking we need to return
        // an empty react component
        if (!item) {
            return <></>
        }

        const fundingType = getFundingType(item, storyClassification)
        const isPerthNow = product === Product.PerthNow

        return (
            <StyledOlympicsFeatureArticle>
                <StyledLink
                    to={toLinkWithHint({ loaded: true, result: item })}
                    onClick={linkClicked}
                    hasBackground={true}
                    isLoading={false}
                >
                    <StyledOlympicsFeatureArticleInner>
                        <StyledOlympicsFeatureArticleText>
                            {/* Article Kicker */}
                            {item.kicker !== undefined && (
                                <StyledOlympicsArticleKicker>
                                    <OlympicsCollectionKicker item={item}>
                                        {item.kicker}
                                    </OlympicsCollectionKicker>
                                </StyledOlympicsArticleKicker>
                            )}
                            {/* Display article title */}
                            <StyledOlympicsFeatureArticleTitle>
                                {item.shortHeadline}
                            </StyledOlympicsFeatureArticleTitle>
                            {/* Display article teaser text */}
                            <StyledOlympicsFeatureArticleTeaser
                                wrappedTeaser={true}
                            >
                                {item.teaser}
                            </StyledOlympicsFeatureArticleTeaser>
                            {/* Display article byline */}
                            <OlympicsCollectionByline
                                type={'feature-article'}
                                profiles={item.profiles}
                                text={item.byline}
                            />
                        </StyledOlympicsFeatureArticleText>
                        <StyledOlympicsFeatureArticleImage
                            item={{
                                loaded: true,
                                result: item,
                            }}
                            imageWidths={{
                                mobile: '320px',
                                tablet: '640px',
                                desktop: '640px',
                                fallbackWidth: 740,
                            }}
                            hasBackground={false}
                            onEvent={onEvent}
                            mediaMode={'default'}
                            fixedRatio={isPerthNow ? '4:3' : '16:9'}
                            disableImageLazyLoad={true}
                            displayNoScriptImage={true}
                        />
                    </StyledOlympicsFeatureArticleInner>
                    {isPerthNow && (
                        <StyledOlympicsFeatureArticleTeaser
                            wrappedTeaser={false}
                        >
                            {item.teaser}
                        </StyledOlympicsFeatureArticleTeaser>
                    )}
                </StyledLink>
            </StyledOlympicsFeatureArticle>
        )
    }
