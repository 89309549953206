import React from 'react'
import { StyledLinkButton } from './ButtonLink.styled'

export interface ButtonLinkProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
    children,
    type = 'button',
    ...props
}) => (
    <StyledLinkButton type={type} {...props}>
        {children}
    </StyledLinkButton>
)

ButtonLink.displayName = 'ButtonLink'
