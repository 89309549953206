import { Product } from '@news-mono/web-common'
import React from 'react'
import {
    StyledCardHeadWrapper,
    StyledCarouselCard,
    StyledCarouselCardImage,
    StyledEntryPointCardHeadline,
    StyledStoryOverlay,
    StyledStoryTag,
    StyledStoryTagWrapper,
} from './WebStoriesCard.styled'

const sevenNewsOverlay = require('../assets/story-overlay.png')
const defaultOverlay = require('../assets/default-overlay.png')

interface CardData {
    href: string
    title: string
    posterImage: string
    tags?: string[]
}

interface WebStoriesCard {
    open?: () => void
    data: CardData
    key: number
    product: Product
}

const renderKicker = (tags?: string[]) => {
    if (tags?.length === 0) {
        return null
    }

    return <StyledStoryTag>{tags && getTagName(tags)}</StyledStoryTag>
}

export const WebStoriesCard: React.FC<WebStoriesCard> = ({
    open,
    data: { href, title, posterImage, tags },
    product,
}) => {
    return (
        <>
            <StyledCarouselCard onClick={open}>
                <StyledCarouselCardImage src={posterImage} alt={title} />
                <StyledCardHeadWrapper
                    fromBottom={product !== Product.SevenNews}
                >
                    <StyledStoryTagWrapper>
                        {renderKicker(tags)}
                    </StyledStoryTagWrapper>
                    <StyledEntryPointCardHeadline>
                        {title}
                    </StyledEntryPointCardHeadline>
                </StyledCardHeadWrapper>
                <StyledStoryOverlay
                    src={
                        product === Product.SevenNews
                            ? sevenNewsOverlay
                            : defaultOverlay
                    }
                    role="presentation"
                />
            </StyledCarouselCard>
        </>
    )
}

function getTagName(tags: string[]) {
    if (tags.length === 1) {
        return tags[0]
    }
    //if top stories is not the first tag in the array, then we need to filter the array and display the first distinct from top stories
    const filteredTags = tags.filter(
        (tag) => tag.toLowerCase() !== 'top stories',
    )
    return filteredTags[0]
}
