import { RequiredAccessDTO } from '@west-australian-newspapers/publication-types'

interface AccessLevels<SubscriberType, RegisteredType, AnonymousType> {
    subscriber?: SubscriberType
    registered?: RegisteredType
    anonymous: AnonymousType
}

/**
 * Return a different result for each level of requiredAccess, falling back to anonymous if the level is not defined.
 * @param requiredAccess
 * @param options
 */
export function requiredAccessValue<
    SubscriberType = never,
    RegisteredType = never,
    AnonymousType = never,
>(
    requiredAccess: RequiredAccessDTO | undefined,
    options: AccessLevels<SubscriberType, RegisteredType, AnonymousType>,
): SubscriberType | RegisteredType | AnonymousType {
    if (!requiredAccess) {
        return options.anonymous
    }
    return options[requiredAccess.level] ?? options.anonymous
}
