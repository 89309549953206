import React from 'react'

export interface IconFacebookRoundedProps {
    title?: string
    className?: string
}

export const IconFacebookRounded: React.FC<IconFacebookRoundedProps> = ({
    className,
    title,
}) => (
    <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title || 'Share to Facebook'}</title>
        <path d="M18.9056 9.10318C18.9056 4.15442 14.8938 0.142578 9.94499 0.142578C4.99622 0.142578 0.984375 4.15442 0.984375 9.10318C0.984375 13.3054 3.87758 16.8315 7.78046 17.8V11.8415H5.93278V9.10318H7.78046V7.92325C7.78046 4.87342 9.16075 3.45979 12.155 3.45979C12.7228 3.45979 13.7023 3.57126 14.1031 3.68238V6.16446C13.8916 6.14224 13.5242 6.13113 13.0679 6.13113C11.5988 6.13113 11.031 6.68776 11.031 8.13472V9.10318H13.9579L13.455 11.8415H11.031V17.9982C15.4679 17.4624 18.9056 13.6846 18.9056 9.10318Z" />
    </svg>
)
IconFacebookRounded.displayName = 'IconFacebookRounded'
