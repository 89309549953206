import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts, themedValue } from '../../__styling'

export const StyledAflSquadsWidgetWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: themedValue(theme, {
        perthnow: calcRem(16),
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        thewest: fonts.thewest.sansSerif,
        fallback: undefined,
    }),
    [breakpoint('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        columnGap: calcRem(17),
    },
    [breakpoint('md')]: {
        columnGap: calcRem(20),
    },
}))

export interface StyeldAflSquadsTeamWrapperProps {
    isShown: boolean
}
export const StyeldAflSquadsTeamWrapper = styled(
    'div',
)<StyeldAflSquadsTeamWrapperProps>(({ isShown }) => ({
    flex: 1,
    display: isShown ? 'block' : 'none',
}))

export const StyledAflSquadsTeamName = styled('p')(({ theme }) => ({
    fontSize: calcRem(20),
    fontWeight: themedValue(theme, {
        sevennews: 500,
        perthnow: 700,
        thewest: 700,
        fallback: undefined,
    }),
    lineHeight: '25px',
    color: tokens.sevennews.colors.palette.white,
    background: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.matchCentre.darkred,
        perthnow: tokens.perthnow.colors.palette.pinkThulite,
        thewest: tokens.thewest.colors.palette.westblue,
        fallback: undefined,
    }),
    padding: calcRem(7, 16),
    textTransform: 'uppercase',
    marginBottom: 0,
    marginTop: calcRem(10),
}))

export const StyledAflPlayerWrapper = styled('div')(({ theme }) => ({
    background: tokens.sevennews.colors.palette.white,
    ':nth-of-type(even)': {
        background: themedValue(theme, {
            sevennews: tokens.sevennews.colors.palette.matchCentre.black005,
            fallback: undefined,
        }),
    },
}))
export const StyledAflPlayerName = styled('p')(({ theme }) => ({
    marginTop: 0,
    fontSize: calcRem(16),
    fontWeight: themedValue(theme, {
        sevennews: 500,
        perthnow: 600,
        thewest: 600,
        fallback: undefined,
    }),
    lineHeight: '20px',
    padding: calcRem(10, 16),
    background: themedValue(theme, {
        sevennews: tokens.sevennews.colors.palette.matchCentre.lightRed,
        perthnow: tokens.perthnow.colors.palette.greySlate,
        thewest: tokens.thewest.colors.palette.breachNavy,
        fallback: undefined,
    }),
    color: tokens.sevennews.colors.palette.white,
}))

export const StyledAflPlayerDataWrapper = styled('div')({
    padding: calcRem(0, 8),
    paddingBottom: calcRem(9),
    display: 'flex',
    alignItems: 'center',
    columnGap: calcRem(16),
    [breakpoint('md')]: {
        columnGap: calcRem(35),
    },
})

export const StyledAflPlayerImage = styled('img')({
    height: calcRem(100),
    [breakpoint('sm')]: {
        height: calcRem(120),
    },
})

export const StyledAflPlayerData = styled('div')({})

export const StyledAflPlayerDataItem = styled('p')(({ theme }) => ({
    marginTop: 0,
    marginBottom: 0,
    fontSize: calcRem(14),
    lineHeight: '18px',
    fontWeight: themedValue(theme, {
        sevennews: 300,
        perthnow: 400,
        fallback: undefined,
    }),
    color: themedValue(theme, {
        perthnow: tokens.perthnow.colors.palette.greyMoonstone,
        thewest: tokens.thewest.colors.palette.greyKoala,
        fallback: undefined,
    }),
    span: {
        marginRight: themedValue(theme, {
            sevennews: calcRem(16),
            perthnow: calcRem(10),
            fallback: undefined,
        }),
        display: 'inline-block',
        minWidth: calcRem(70),
        color: themedValue(theme, {
            perthnow: tokens.perthnow.colors.palette.black,
            thewest: tokens.thewest.colors.palette.black,
            fallback: undefined,
        }),
        [breakpoint('md')]: {
            marginRight: calcRem(45),
        },
    },
    [breakpoint('md')]: {
        fontSize: calcRem(16),
        lineHeight: '20px',
    },
}))

export const StyledTeamControlsWrapper = styled('div')({
    display: 'block',
    [breakpoint('sm')]: {
        display: 'none',
    },
})
