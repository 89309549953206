import { LinkDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { StyledBuyNowWrapper, StyledBuyNowLink } from './BuyNow.styled'

export interface BuyNowProps {
    text: string
    link: LinkDTO
}
const BuyNow: React.FC<BuyNowProps> = ({ text, link }) => {
    return (
        <StyledBuyNowWrapper>
            <StyledBuyNowLink to={link.href} target={link.target || '_blank'}>
                {text}
            </StyledBuyNowLink>
        </StyledBuyNowWrapper>
    )
}

export default BuyNow
