import { cx } from '@emotion/css'
import React from 'react'

export interface IconChevronRightProps {
    title?: string
    fillColor?: string
    className?: string
}

export const IconChevronRight: React.FC<IconChevronRightProps> = ({
    className,
    fillColor = '#00000',
    title,
}) => (
    <svg
        viewBox="0 0 14 20"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(className, 'icon-chevron-right')}
        fill={fillColor}
    >
        <title>{title || 'Chevron Right Icon'}</title>
        <path d="M8.43 9.602L.151 2.774a.39.39 0 0 1-.046-.565L1.928.143a.416.416 0 0 1 .583-.042l10.942 9.198a.394.394 0 0 1 0 .605L2.511 19.106a.421.421 0 0 1-.27.097h-.03a.416.416 0 0 1-.283-.14L.106 16.998a.39.39 0 0 1 .046-.565L8.43 9.602z" />
    </svg>
)
IconChevronRight.displayName = 'IconChevronRight'
