import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { AmuseLabsIframeProps } from './components/AmuseLabsIframe'
import { PuzzleIframe } from './PuzzleIframe'

export const PuzzleIframeRegistration = createRegisterableComponent(
    'puzzleIframe',
    (props: AmuseLabsIframeProps, services) => (
        <PuzzleIframe key={`iframe${services.location.pathname}`} {...props} />
    ),
)
