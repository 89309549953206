import React, { useContext } from 'react'
import { cleanUpScoreFlowData, secondsToTime } from '../MatchTimelineWidget'
import {
    teamBrandData,
    TeamBrandData,
} from '../MatchTimelineWidget/helpers/teamBrandData'
import { StyledWidgetErrorContainer } from '../MatchTimelineWidget/MatchTimelineWidget.styled'
import { OptaAflApiContext } from '../OptaAflApiProvider'
import { CardData, TimelineCard } from '../TimelineCard/TimelineCard'
import {
    StyledFeedCardContainer,
    StyledFeedCardIndicator,
    StyledFeedInnerContainer,
    StyledFeedQuarterContainer,
    StyledFeedQuarterIndicator,
    StyledTimelineFeedContainer,
} from './TimelineFeedWidget.styled'

const processApiDataToFeedCardData = (apiData: any): CardData[] => {
    const scoreFlowData = cleanUpScoreFlowData(
        apiData.report.aflMatchStats.scoreFlow,
    )
    const playerData = apiData.report.aflMatchStats.playerStats.player

    const cardData = scoreFlowData.map((scoreItem: any, index: number) => {
        const timeInQuarter = secondsToTime(
            parseInt(scoreItem['@attributes'].periodSeconds),
        )

        const playerWhoScored = playerData.find(
            (playerEl: any) =>
                playerEl['@attributes'].playerId ===
                scoreItem['@attributes'].playerId,
        )

        const scoreEventsUpUntilAndIncludingCurrent = scoreFlowData.slice(
            0,
            index + 1,
        )
        const totalPlayerGoals = scoreEventsUpUntilAndIncludingCurrent.filter(
            (historicDataPoint: any) =>
                historicDataPoint['@attributes'].playerId ===
                    scoreItem['@attributes'].playerId &&
                historicDataPoint['@attributes'].scoreName === 'goal',
        ).length
        const totalPlayerBehinds = scoreEventsUpUntilAndIncludingCurrent.filter(
            (historicDataPoint: any) =>
                historicDataPoint['@attributes'].playerId ===
                    scoreItem['@attributes'].playerId &&
                historicDataPoint['@attributes'].scoreName === 'behind',
        ).length
        return {
            type: scoreItem['@attributes'].scoreName,
            quarter: 'Q' + scoreItem['@attributes'].period,
            playerFirstname: playerWhoScored
                ? playerWhoScored['@attributes'].playerFirstname
                : '',
            playerLastname: playerWhoScored
                ? playerWhoScored['@attributes'].playerSurname
                : '',
            timeInQuarter,
            playerGoalsScored: totalPlayerGoals,
            playerBehindScored: totalPlayerBehinds,
            teamId: scoreItem['@attributes'].squadId,
        }
    })

    return cardData
}

const getScoreTypeInitial = (scoreType: string) => {
    switch (scoreType) {
        case 'behind':
        case 'rushed':
            return 'B'
        case 'goal':
        default:
            return 'G'
    }
}

const getQuarterEndLabel = (quarter: string) => {
    switch (quarter) {
        case 'Q1':
            return 'End of Quarter 1'
        case 'Q2':
            return 'End of Quarter 2'
        case 'Q3':
            return 'End of Quarter 3'
    }
    return undefined
}

export interface TimelineFeedWidgetProps {
    matchId: string
    apiData: any
}
export const TimelineFeedWidget = ({
    matchId,
    apiData,
}: TimelineFeedWidgetProps) => {
    const { data, error, loading } = useContext(OptaAflApiContext)

    if (!data || loading) {
        return (
            <StyledWidgetErrorContainer>
                No data found
            </StyledWidgetErrorContainer>
        )
    }

    const feedCardData = processApiDataToFeedCardData(data.rawApiData)
    const isMatchOver =
        data.rawApiData.report.aflMatchStats.matchInfo['@attributes']
            .periodCompleted === '4'
    feedCardData.reverse()
    const isMatchStarted =
        parseInt(
            data.rawApiData.report.aflMatchStats.matchInfo['@attributes']
                .period,
        ) > 0

    return (
        <StyledTimelineFeedContainer>
            {isMatchStarted ? (
                <StyledFeedInnerContainer>
                    {feedCardData &&
                        feedCardData.map((cardData: CardData, i: number) => {
                            const currentTeamBrandData = teamBrandData.find(
                                (teamData: TeamBrandData) =>
                                    teamData.teamId === cardData.teamId,
                            )
                            const isEndOfQuarter =
                                i > 0
                                    ? feedCardData[i - 1].quarter !==
                                      feedCardData[i].quarter
                                    : false
                            const isLastFeedItem = i === 0
                            if (currentTeamBrandData) {
                                return (
                                    <div key={i}>
                                        {isEndOfQuarter ? (
                                            <StyledFeedQuarterContainer>
                                                <StyledFeedQuarterIndicator>
                                                    {getQuarterEndLabel(
                                                        cardData.quarter,
                                                    )}
                                                </StyledFeedQuarterIndicator>
                                            </StyledFeedQuarterContainer>
                                        ) : isMatchOver && isLastFeedItem ? (
                                            <StyledFeedQuarterContainer>
                                                <StyledFeedQuarterIndicator>
                                                    End of Quarter 4
                                                </StyledFeedQuarterIndicator>
                                            </StyledFeedQuarterContainer>
                                        ) : null}

                                        <StyledFeedCardContainer>
                                            <TimelineCard
                                                cardData={cardData}
                                                teamBrandData={
                                                    currentTeamBrandData
                                                }
                                            />
                                            <StyledFeedCardIndicator
                                                isRushed={
                                                    cardData.type === 'rushed'
                                                }
                                                brandHexCode={
                                                    currentTeamBrandData.hexCode
                                                }
                                            >
                                                {getScoreTypeInitial(
                                                    cardData.type,
                                                )}
                                            </StyledFeedCardIndicator>
                                        </StyledFeedCardContainer>
                                    </div>
                                )
                            }
                        })}
                    <StyledFeedQuarterContainer>
                        <StyledFeedQuarterIndicator>
                            Match Start
                        </StyledFeedQuarterIndicator>
                    </StyledFeedQuarterContainer>
                </StyledFeedInnerContainer>
            ) : (
                'No data found'
            )}
        </StyledTimelineFeedContainer>
    )
}
