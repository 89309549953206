/* eslint-disable @typescript-eslint/no-var-requires */
import { TheWestSection } from '@news-mono/common'
import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getTopicSponsor } from '../../topic/get-topic-sponsor'

const bannerDesktopX2 = require<string>('./assets/Genesis-Desktop-Banner-@2-2440x300.jpg')

const bannerDesktopX1 = require<string>('./assets/Genesis-Desktop-Banner-@1-1220x150.jpg')

const bannerTabletX2 = require<string>('./assets/Genesis-Tablet-Banner-@2-1476x114.jpg')

const bannerTabletX1 = require<string>('./assets/Genesis-Tablet-Banner-@1-738x57.jpg')

const bannerMobileX2 = require<string>('./assets/Genesis-Mobile-Banner-@2-822x186.jpg')

const bannerMobileX1 = require<string>('./assets/Genesis-Mobile-Banner-@1-411x93.jpg')

const socialImage = require('./assets/Genesis-Social-1200x628.jpg')

export const socialImageMeta = [
    {
        property: 'og:image',
        content: socialImage,
    },
    { name: 'twitter:image', content: socialImage },
    {
        property: 'og:image:height',
        content: '628',
    },
    {
        property: 'og:image:width',
        content: '1200',
    },
]

const topic: Topic = {
    id: 'features/west-australian-ballets-genesis',
    metadata: {},
    seoTitle: 'West Australian Ballet’s GENESIS',
    title: 'GENESIS',
    seoDescription:
        'West Australian Ballet’s GENESIS is the State Ballet Company’s annual creative showcase, with dancers taking the lead and choreographing 11 new short works.',
    parent: {
        id: 'features',
        metadata: {},
        seoTitle: 'Features',
        title: 'Features',
    },
}

export const getGenesisRouteInfo: StaticRoutes<TheWestSection> = {
    '/features/west-australian-ballets-genesis': ({
        getAdTargeting,
        config,
        store,
    }) => {
        const canonicalUrl =
            config.publicUrl + '/features/west-australian-ballets-genesis'

        return {
            kind: 'page',
            heading: topic.title,
            pageType: 'video-series',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: socialImageMeta,
            },
            socialMeta: {
                title: topic.seoTitle,
                description:
                    'West Australian Ballet’s GENESIS is the State Ballet Company’s annual showcase of 11 new short works.',
            },
            adTargeting: getAdTargeting('home', 'default', topic.id),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'breaking-news',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breaking-news-promo-strap',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'breaking-news-promo-strap',
                                        offset: 0,
                                        pageSize: 1,
                                    },
                                },
                            }),
                            layout.component({
                                type: 'breadcrumb',
                                props: {
                                    topic,
                                    sponsor: getTopicSponsor(topic),
                                    enableHeaderTag: true,
                                    horizontalGutters: 'outerMargin',
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'image-banner',
                                props: {
                                    defaultSrc: bannerDesktopX2,
                                    verticalGutters: ['unset', 'md'],
                                    altText: topic.seoDescription!,
                                    sources: [
                                        {
                                            breakpoint: 'md',
                                            src: [
                                                {
                                                    src: bannerDesktopX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerDesktopX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            breakpoint: 'xs',
                                            src: [
                                                {
                                                    src: bannerTabletX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerTabletX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                        {
                                            src: [
                                                {
                                                    src: bannerMobileX2,
                                                    density: '2x',
                                                },
                                                {
                                                    src: bannerMobileX1,
                                                    density: '1x',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            }),
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    layoutRatio: '3:1',
                                    stretchColumns: true,
                                    verticalSpacing: 'xl',
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'sierra',
                                            props: {
                                                fixedRatios: ['16:9'],
                                                heroImageLayout:
                                                    ResponsivePictureLayout.ObjectFitContain,
                                                cardOrientation: {
                                                    type: 'Landscape-Portrait',
                                                    maxBreakpoint: 'md',
                                                },
                                                restrictHeroVideoPlayback: true,
                                                cardLayout: ['hero-video'],
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'genesis',
                                                    offset: 0,
                                                    pageSize: 1,
                                                },
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'tw-subscribe-promo-card',
                                            props: {},
                                        }),
                                    ],
                                },
                            }),
                            layout.component({
                                type: 'lima',
                                props: {
                                    verticalSpacing: 'xl',
                                    fontScale: 1.2,
                                    fixedRatios: ['16:9'],
                                    initialColumns: 1,
                                    intermediateColumns: 2,
                                    finalColumns: 4,
                                    hasBackground: true,
                                    sectionHeader: {
                                        heading: 'Watch the episodes',
                                    },

                                    dataDefinitionArgs: {
                                        type: 'curation',
                                        name: 'genesis',
                                        offset: 1,
                                        pageSize: 12,
                                    },
                                },
                            }),
                            ...billboardLeaderboardMrec('two'),
                        ],
                    },
                }),
            ],
        }
    },
}
