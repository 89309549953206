import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { CollectionRouteProps } from '..'
import { WebStories } from './WebStories'

export interface WebStoriesRouteProps extends CollectionRouteProps {}

export const WebStoriesRegistration = createRegisterableComponent(
    'web-stories',
    (props: WebStoriesRouteProps, services) => {
        return (
            <WebStories
                sectionHeader={props.sectionHeader}
                onEvent={services.onEvent}
            />
        )
    },
)
