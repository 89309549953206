/* eslint-disable react/jsx-no-useless-fragment */
import { ImageSourceWithCaptions, Product } from '@news-mono/web-common'
import React from 'react'
import { StyledStaticHr } from '../Gallery.styled'
import { GalleryItem } from '../../../content/Gallery/components/GalleryItem/GalleryItem'
import { useProduct } from '../../../__product'

type GalleryImagesProps =
    | { loading: true }
    | {
          loading?: false
          totalLoaded: number
          images: ImageSourceWithCaptions[]
          adFrequency?: number
          ads?: React.ReactElement<any>[]
          themeKind?: string
      }

export const numPerPage = 6

export const GalleryImages: React.FC<GalleryImagesProps> = (props) => {
    const product = useProduct()
    if (props.loading) {
        const placeholderImages = Array.from(Array(numPerPage)).map((_, i) => (
            <GalleryItem
                id={`image-${i}`}
                key={`image-${i}`}
                image={{ loaded: false }}
                count={`${i + 1}/`}
            />
        ))
        return <React.Fragment>{placeholderImages}</React.Fragment>
    }
    const { images, ads, adFrequency = 3 } = props
    // manually places the the first ad if no images in gallery (i.e. breach screen is showing)
    if (!images.length && ads) {
        const [firstAd] = ads
        return firstAd ? React.cloneElement(firstAd, { key: `ad-0` }) : null
    }

    const slicedImagesArr = images.slice(0, props.totalLoaded)

    const galleryImages = slicedImagesArr.reduce<React.ReactElement<any>[]>(
        (acc, image, imageIndex) => {
            if (
                ads &&
                imageIndex % adFrequency === 0 &&
                !(product === Product.SevenNews && imageIndex === 0)
            ) {
                const adIndex = imageIndex / adFrequency
                const ad = ads[adIndex]
                if (ad) {
                    acc.push(React.cloneElement(ad, { key: `ad-${adIndex}` }))
                }
            }

            acc.push(
                <GalleryItem
                    id={`image-${imageIndex}`}
                    key={`image-${imageIndex}`}
                    image={{ loaded: true, result: image }}
                    count={`${imageIndex + 1}/${images.length}`}
                />,
                <React.Fragment key={`hr-${imageIndex}`}>
                    {props.themeKind === 'sevennews' &&
                        imageIndex !== slicedImagesArr.length - 1 && (
                            <StyledStaticHr />
                        )}
                </React.Fragment>,
            )

            return acc
        },
        [],
    )

    return <React.Fragment>{galleryImages}</React.Fragment>
}
