import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Sharing } from '../../../buttons/Sharing/Sharing'
import { TheWestLiveLogo } from '../../../logos/TheWestLiveLogo/TheWestLiveLogo'
import { Headline } from '../../../publication/Headline/Headline'
import { colors } from '../../../__styling/settings/colors'
import { fonts } from '../../../__styling/settings/fonts'
import { metrics } from '../../../__styling/settings/metrics'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { SponsoredLogoContainer } from '../TheWestLiveStickyBanner/TheWestLiveStickyBanner.styled'

export const Styledheading = styled(Headline)({
    marginBottom: metrics.thewest.margins.lg,
})

export const StyledParagraph = styled('p')<{ noMargin?: boolean }>(
    ({ noMargin }) => ({
        marginTop: 0,
        marginBottom: !noMargin ? calcRem(27) : 0,
    }),
)

export const StyledScheduleNotice = styled('p')({
    margin: calcRem(10, 0),
    fontSize: calcRem(22),
    fontFamily: fonts.thewest.sansSerif,
    lineHeight: calcRem(26),

    strong: {
        fontWeight: 600,
    },
})

export const StyledIntroSection = styled('div')({
    marginBottom: calcRem(27),
    [breakpoint('xs')]: {
        display: 'flex',
        alignItems: 'center',
    },
})

export const StyledWestLiveLogo = styled(TheWestLiveLogo)({
    width: calcRem(207),
    height: calcRem(72),
    margin: `0 ${calcRem(26)} ${calcRem(11)} 0`,
    flex: `0 0 ${calcRem(207)}`, // required for ie11

    [breakpoint('xs')]: {
        marginBottom: 0,
    },
})

export const StyledWestLiveLogoIcon = styled('img')({
    display: 'block',
    width: calcRem(107),
    height: calcRem(107),
    boxShadow: `0 0 35px ${colors.black50}`,
    borderRadius: `100%`,
    marginLeft: calcRem(15),

    [breakpoint('xs')]: {
        transform: `translateX(-50%)`,
        marginLeft: 0,
    },
})

export const StyledSponsoredLogoContainer = styled(SponsoredLogoContainer)({
    height: calcRem(50),
    position: 'absolute',
    top: calcRem(52),
    right: calcRem(18),
})

const westLiveHero = require('./assets/west-live-hero.jpg')

export const StyledPlayerContainer = styled('div')({
    position: 'relative',
    backgroundColor: 'grey',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(180deg,#0A70F3 33%,#043DE2 100%)',
    boxShadow: `0 -1px 19px 0 ${colors.black20}`,

    [`&::before`]: {
        content: `""`,
        display: 'block',
        flexGrow: 1,
        height: 314, // static number based on design
        backgroundImage: `url(${westLiveHero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        clipPath: 'ellipse(100% 100% at 50% 0)',
    },

    // used to set the content wrapper div visibly "above" the ::before pseudo class
    [`& > div`]: {
        position: 'relative',
    },

    [breakpoint('xs')]: {
        backgroundImage: 'linear-gradient(180deg, #0A70F3 0%, #043DE2 45%)',
        display: 'flex',
        [`&::before`]: {
            position: 'relative',
            height: 'auto',
            clipPath: 'ellipse(100% 142% at 0 50%)',
        },
    },
})

export const StyledPlayerBranding = styled('div')({
    marginBottom: calcRem(20),
    marginTop: calcRem(-55),

    [breakpoint('xs')]: {
        marginTop: 0,
        paddingTop: calcRem(22),
        marginBottom: calcRem(8),
    },
})

export const StyledPlayerInfo = styled('div')({
    boxSizing: 'border-box',
    padding: `0 ${calcRem(15)} ${calcRem(30)} ${calcRem(15)}`,
    color: colors.white,

    [breakpoint('xs')]: {
        padding: `0 ${calcRem(18)} ${calcRem(37)} ${calcRem(33)}`,
        maxWidth: calcRem(279),
    },
})

export const StyledPlayerBox = styled('div')({
    padding: `${calcRem(25)} 0`,
    borderTop: `1px solid ${colors.white20}`,
    borderBottom: `1px solid ${colors.white20}`,
    marginBottom: calcRem(27),
})

export const PlayerStyledSharing = styled(Sharing)({
    margin: 0,

    '& > button': {
        color: colors.thewest.westblue,
        borderColor: colors.white,
        backgroundColor: colors.white,
        margin: 0,
    },
    '& > button + button': {
        marginLeft: calcRem(8),
    },
})

export const StyledPodcastLogos = styled('img')({
    display: 'block',
})

export const StyledPodcastLogoLink = styled(WebLink)({
    display: 'inline-block',
    marginBottom: calcRem(metrics.thewest.margins.sm),

    '&:not(:last-of-type)': {
        marginRight: calcRem(metrics.thewest.margins.md),
    },
})
