import React, { useLayoutEffect, useRef } from 'react'

export interface ShorthandProps {
    link: string
}

export const isShorthand = (link: string) => {
    return link.indexOf('stories.thenightly.com.au') >= 0
}

export const Shorthand: React.FC<ShorthandProps> = (props) => {
    const ref = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        if (ref.current) {
            const script = document.createElement('script')

            let link = props.link
            if (props.link.endsWith('/')) {
                link += 'embed.js'
            } else link += '/embed.js'

            script.src = link
            script.async = true

            ref.current.appendChild(script)

            return () => {
                if (window.Shorthand) {
                    window.Shorthand.remove(props.link)
                    //ensure that script has been fully removed and avoid issues with the next page load
                    window.location.reload()
                }
            }
        }
    }, [props.link, ref])

    return <div ref={ref} />
}
