import { useMutation } from 'react-query'
import { getAuth0UpdateNameActionPath } from '@news-mono/web-common'

export interface ChangeNameFormData {
    firstName: string
    lastName: string
}

interface ChangeNameRequest extends ChangeNameFormData {
    userId: string
    email?: string
    onError?: (data: any) => void
    onSuccess?: (data: any) => void
}

export const useChangeName = ({
    firstName,
    lastName,
    email,
    userId,
    onError,
    onSuccess,
}: ChangeNameRequest) => {
    const request = 'change-name-request'

    return useMutation(
        request,
        async () => {
            const response = await fetch(getAuth0UpdateNameActionPath, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    given_name: firstName,
                    family_name: lastName,
                    email,
                }),
            })

            if (!response.ok || response.status !== 201) {
                if (onError) {
                    throw response
                }
                return false
            }
            return true
        },
        {
            onError: onError,
            onSuccess: onSuccess,
        },
    )
}

interface ValidateNameResult {
    valid: boolean
    message?: string
}

export const validateName = ({
    firstName,
    lastName,
}: ChangeNameFormData): ValidateNameResult => {
    const trimmedFirstName = firstName.trim()
    const trimmedLastName = lastName.trim()

    if (!trimmedFirstName || !trimmedLastName) {
        return {
            valid: false,
            message: 'Both first name and last name are required',
        }
    }

    if (trimmedFirstName.length > 50 || trimmedLastName.length > 50) {
        return {
            valid: false,
            message:
                'First name and last name should be less than 50 characters',
        }
    }

    return { valid: true }
}
