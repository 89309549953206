import { GetDcrMeta } from '@news-mono/web-common'
import debug from 'debug'

export const nielsenDebug = debug('data-layer')

interface NielsenParams {
    sfcode: string
    apid: string
    apn: string
    nsdkv: string
}

interface NielsenMetadata {
    /** Required: assign this per asset */
    assetid: string
    /** Required: always use cms */
    dataSrc: string
    pageURL?: string
    referrerURL?: string
    /** Required: please discuss values with Nielsen */
    section: string
    segA?: string
    segB?: string
    segC?: string
    /** Required: the same for every static asset */
    type: string
    /** Option: currently used for CNG */
    subbrand?: string
}

interface NielsenSDK {
    ggInitialize: (params: NielsenParams) => void
    ggPM: (
        event: 'staticstart' | 'staticend',
        metadata: NielsenMetadata,
    ) => void
}

interface NOLCMB {
    getInstance: (apiId: string) => NielsenSDK
}

// eslint-disable-next-line no-redeclare
declare const NOLCMB: NOLCMB
declare const nielsenSdk: NielsenSDK

declare let nielsenMetadata: NielsenMetadata

let softLoadCounter = -1

declare const nielsenDcrInitialised: boolean
declare const nielsenDcrEventBuffer: (() => void)[]

interface NielsenTrac {
    pageEvent: (location: Location) => void
}

declare const window: Window & { trac: NielsenTrac }

function dcrSoftReload(getDcrMeta: GetDcrMeta, siteName: string) {
    if (!nielsenMetadata) {
        console.error('Nielsen softPageLoad was fired before hardPageLoad!')
        return
    }

    try {
        nielsenSdk.ggPM('staticend', nielsenMetadata)

        const url = window.location.href
        const urlPath = window.location.pathname

        const { section, segA, segB, segC, subbrand } = getDcrMeta(
            siteName,
            window.location.pathname,
            'web',
        )

        nielsenMetadata = {
            dataSrc: nielsenMetadata.dataSrc,
            type: nielsenMetadata.type,
            assetid: urlPath,
            pageURL: `page_${softLoadCounter}_${url}`,
            // the first soft load has its own urlPath as referrer instead of the hard load URL
            // this is intended
            referrerURL: nielsenMetadata.pageURL || url,
            section,
            ...(segA && { segA }),
            ...(segB && { segB }),
            ...(segC && { segC }),
            ...(subbrand && { subbrand }),
        }

        nielsenDebug('Firing Nielsen soft page load: %o', nielsenMetadata)

        nielsenSdk.ggPM('staticstart', nielsenMetadata)
    } catch (err) {
        console.error({ err }, 'Nielsen soft page load error')
    }
}

export function nielsenSoftPageLoad(getDcrMeta: GetDcrMeta, siteName: string) {
    // Dont do anything when its not actually there in the case of regionals
    if (typeof nielsenDcrInitialised === 'undefined') {
        return
    }

    softLoadCounter++

    // we don't want to fire a soft page load the first time this method is called
    // as it will always be the same page as the hard load was
    if (softLoadCounter === 0) {
        return
    }

    if (nielsenDcrInitialised) {
        dcrSoftReload(getDcrMeta, siteName)
    } else {
        nielsenDcrEventBuffer.push(() => dcrSoftReload(getDcrMeta, siteName))
    }
}
