import React from 'react'

export interface IconPinTNProps {
    className?: string
    fillColor?: string
}

export const IconPinTN: React.FC<IconPinTNProps> = ({
    className,
    fillColor = '#4B006E',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            className={className}
        >
            <path
                d="M7.7029 0C7.99461 0 8.29458 0.152348 8.50523 0.353976C10.2087 1.98504 10.7303 3.34902 10.1075 5.21746C11.5607 6.3313 12.4785 6.60465 14.2926 6.60465C16.7725 6.60465 18.3917 6.90362 19.4934 8.0046V8.03892C20.5243 9.06938 20.5243 10.7194 19.4934 11.749L16.4132 14.8531L21.6614 20.099C22.0909 20.5279 22.1229 21.2484 21.6934 21.6774C21.2637 22.1075 20.5417 22.1075 20.1121 21.6774L14.8444 16.4085L11.7591 19.4786C10.7281 20.5092 9.07766 20.5092 8.04664 19.4786H8.01221C6.91003 18.3775 6.60291 16.9662 6.60291 14.291C6.60291 12.5904 6.25389 11.3904 5.19713 10.1276C3.16337 10.7238 1.98011 10.1529 0.312313 8.48563C0.106066 8.27938 0.00299644 7.987 0.00299644 7.6954C0.00299644 7.30853 0.100674 6.71553 0.346741 5.97766C0.743067 4.78956 1.42539 3.5942 2.51228 2.50786C3.59929 1.42162 4.79542 0.739633 5.98417 0.343528C6.72248 0.0975706 7.31581 0 7.7029 0ZM7.27687 2.26004C6.10825 2.47695 4.93159 3.25057 4.09362 4.08821C3.25554 4.92574 2.54176 5.95158 2.24642 7.2961C3.29635 8.30324 3.93853 8.38289 4.98857 7.83014C5.40745 7.6096 5.93819 7.68758 6.27292 8.0222C8.27599 10.0242 8.80288 11.8402 8.80288 14.291C8.80288 16.425 8.92839 17.2181 9.59355 17.9331C9.78132 18.1355 10.0404 18.1047 10.2122 17.9331L17.9465 10.2035C18.1183 10.0319 18.1131 9.81188 17.9746 9.64358C17.5817 9.16508 16.7072 8.7934 14.3028 8.79472C11.4286 8.79637 9.90056 8.05619 8.01221 6.28686C7.67749 5.95225 7.59433 5.43899 7.80608 5.01572C8.37169 3.88504 8.28347 3.28907 7.27687 2.26004Z"
                fill={fillColor}
            />
        </svg>
    )
}
