import { functionTimerAsync } from '@project-watchtower/runtime'
import { Headers } from 'cross-fetch'
import cuid from 'cuid'
import qs from 'query-string'
import { Logger } from 'typescript-log'
import { addUrlParam } from './utils'

export interface SimpleResponse {
    status: number
    statusText: string
    url: string
}

export class HttpError extends Error {
    __proto__: HttpError
    response: SimpleResponse

    constructor(response: Response) {
        super(response.status.toString())

        // This is required to make `err instanceof StartupError` work
        this.constructor = HttpError
        this.__proto__ = HttpError.prototype

        this.response = {
            status: response.status,
            statusText: response.statusText,
            url: response.url,
        }
    }
}

export function checkStatus(response: Response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    }

    throw new HttpError(response)
}

export interface Query {
    [param: string]: string | string[] | number | boolean | undefined
}

export interface Get<T> {
    log: Logger
    validate: (data: any) => T
    baseUrl: string
    path: string
    query?: Query
    fetchOptions?: RequestInit
    /**
     * We include a number of custom headers for our APIs,
     * pass false when calling external APIs
     */
    customHeaders:
        | false
        | {
              caller: string
          }
}

export function httpGet<T>({
    query,
    path,
    baseUrl,
    fetchOptions,
    validate,
    customHeaders,
    log,
}: Get<T>): Promise<T> {
    const queryString = query ? qs.stringify(query) : ''
    const prefixedQueryString = queryString === '' ? '' : `?${queryString}`
    const url = `${baseUrl}/${path}${prefixedQueryString}`

    const headers = new Headers(
        fetchOptions && fetchOptions.headers ? fetchOptions.headers : {},
    )
    const requestOptions: RequestInit = {
        ...fetchOptions,
        headers,
    }

    let headerString = ''
    if (customHeaders) {
        const reqId = cuid()
        headers.append('x-request-id', reqId)
        headers.append('Caller', customHeaders.caller)

        headerString = `caller:${customHeaders.caller} x-request-id:${reqId}`
    }

    let capturedResponse: Response
    const doFetch = () =>
        getUsingFetchWithRetry(url, requestOptions, log)
            .then((response) => {
                capturedResponse = response
                return checkStatus(response)
            })
            .then((response) => response.json())
            .then(validate)

    return functionTimerAsync(
        `Fetch ${url} ${headerString}`,
        doFetch,
        log,
        () => capturedResponse.status.toString(),
    )
}

export async function getUsingFetchWithRetry(
    url: string,
    requestOptions: RequestInit | undefined,
    log: Logger,
    {
        retries = 3,
        delay = 50,
        errorCodeThreshold = 500,
    }: {
        retries?: number
        delay?: number
        errorCodeThreshold?: number
    } = {},
) {
    let error: any

    for (let i = 1; i <= retries; i++) {
        try {
            return await fetch(
                i === i ? url : addUrlParam(url, 'v', i.toString(), true),
                requestOptions,
            ).then((response) => {
                // Retry on 500 or above
                if (response.status >= errorCodeThreshold) {
                    throw new HttpError(response)
                }
                return response
            })
        } catch (err) {
            error = err
            log.warn(
                { err },
                `\nUnresponsive url, is the server running? Check ${url}`,
            )

            // We don't want to retry in tests
            if (process.env.NODE_ENV === 'test') {
                throw err
            }
            await wait(delay * i)
        }
    }

    log.error({ err: error, url, retries, delay }, 'Request with retry failed')
    throw error
}

export function wait(timeout: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve()
        }, timeout)
    })
}
