import styled from '@emotion/styled'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import { commonStyles } from '../../buttons/PollieRaterButton/PollieRaterButton.styled'
import { calcRem } from '../../__styling'
import {
    StyledFederalElectionInArticleBannerContainer,
    StyledFederalElectionInArticleBannerTextContainer,
    StyledFederalElectionInArticleBannerTitle,
} from '../FederalElectionInArticleBanner/FederalElectionInArticleBanner.styled'

export const StyledGenericArticleBannerContainer = styled(
    StyledFederalElectionInArticleBannerContainer,
)({})

export const StyledHeading = styled(StyledFederalElectionInArticleBannerTitle)<{
    dividerColor: string
}>(({ dividerColor }) => ({
    whiteSpace: 'pre-line',
    '&::before': {
        content: `''`,
        width: calcRem(56),
        height: calcRem(4),
        display: 'block',
        position: 'absolute',
        background: `${dividerColor}`,
        top: 0,
    },
}))

export const StyledTextContainer = styled(
    StyledFederalElectionInArticleBannerTextContainer,
)({})

export const StyledButton = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['buttonColor']),
})<{ buttonColor: string; buttonHoverColor?: string }>(
    ({ buttonColor, buttonHoverColor, theme }) => ({
        ...commonStyles(theme),
        background: buttonColor,
        borderColor: buttonColor,
        borderWidth: 1,
        borderStyle: 'solid',

        '&:hover, &:focus': {
            background:
                buttonHoverColor ??
                theme.colors.actions.button.primary.background.hover,
            color: buttonColor,
            boxSizing: 'border-box',
        },
    }),
)
