import {
    AppState,
    ConsentLevel,
    RenderTargetContext,
    scriptExists,
    useConsentGiven,
    useFeature,
    usePrevious,
} from '@news-mono/web-common'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventQueue } from '../__App/client'
import { useProduct } from '../__product/useProduct'

export const LiveRampATS = () => {
    const consentRequiredLevel = ConsentLevel.Advertising
    const consentGiven = useConsentGiven({ consentRequiredLevel })
    const isToggleV2Enabled = useFeature('live-ramp-ats-v2')
    const product = useProduct()
    const isTargetProduct = product === 'thewest' || 'perthnow' || 'thenightly'
    const placementID = product === 'thewest' ? 13451 : 13450
    const authState = useSelector((state: AppState) => state.authentication)
    const { renderTarget } = useContext(RenderTargetContext)

    const scriptSrc = isToggleV2Enabled
        ? 'https://ats-wrapper.privacymanager.io/ats-modules/6c7dadcd-c3c7-4597-9656-5a97108c8593/ats.js'
        : 'https://ats.rlcdn.com/ats.js'
    const previousAuthState = usePrevious(authState)

    useEffect(() => {
        const isLocalEnv = window.location.href.includes('localhost')

        if (
            isLocalEnv ||
            !isTargetProduct ||
            !consentGiven ||
            !isToggleV2Enabled ||
            renderTarget !== 'web' ||
            scriptExists(scriptSrc) ||
            !authState.isLoggedIn
        ) {
            // The reason why we check for AuthState is because LiveRampATS rerenders several times while the accessToken is checked to see if the user is logged in.
            // However, we only want to trigger the IX script to load when the we are at the final stage of the auth check and the user is confirmed to be not logged in.

            // Explanation of sequence of events:
            //  - LiveRampATS component gets mounted
            //  - AccessToken starts getting checked, useEffect triggers each time authState is changed
            //  - On the final auth check, we want to trigger the if statement below if we are in one of two situations -
            //     - The user is not logged in and liveramp is not going to load
            //     - The user is logged in and liveramp cannot load due to a factor OTHER then the authenticationState(toggle, renditionType, etc)
            // - The reason why we have the !scriptExists(scriptSrc) is that the script will already be added to the body in a previous auth check but we do not want to actually trigger resolveEvent here.
            // - We want to trigger it inside the script.onload() section instead.
            if (
                previousAuthState !== authState &&
                !authState.checkingTokenState &&
                authState.hasAttemptedValidation &&
                !scriptExists(scriptSrc)
            ) {
                eventQueue.resolveEvent('load-index-exchange-script')
            }
            return
        }

        const script = document.createElement('script')

        if (isToggleV2Enabled) {
            script.onload = () => {
                window.ats.setAdditionalData({
                    type: 'emailHashes',
                    id: [authState.hashedLiveRampEmail],
                })
                eventQueue.resolveEvent('load-index-exchange-script')
            }
        }

        if (!isToggleV2Enabled) {
            script.onload = () => {
                window.ats.start({
                    placementID: placementID,
                    storageType: 'cookie',
                    detectionType: 'url',
                    urlParameter: 'email_trigger',
                    detectionSubject: 'all',
                    emailHashes: [authState.hashedLiveRampEmail],
                    logging: 'error',
                })
                eventQueue.resolveEvent('load-index-exchange-script')
            }
        }

        script.src = scriptSrc

        // If there is an error at loading the LiveRamp ATS script,
        // we need to indicate to the IX Script to load instantly instead of creating an event.
        script.onerror = () => {
            window.liveRampError = true
            // This is just a failsafe.
            eventQueue.resolveEvent('load-index-exchange-script')
        }

        document.body.appendChild(script)
    }, [
        authState,
        isTargetProduct,
        consentGiven,
        isToggleV2Enabled,
        scriptSrc,
        renderTarget,
        placementID,
        previousAuthState,
    ])

    return <div className="liveramp-ats-tracker" />
}
