import React from 'react'
import { colorsPN } from '@news-mono/design-tokens'

const color = colorsPN.fill.base

export const IconVideoPause: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path d="M10 4H6V20H10V4Z" fill={color} />
        <path d="M18 4H14V20H18V4Z" fill={color} />
        <path
            d="M10 4H6V20H10V4Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18 4H14V20H18V4Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
