import styled from '@emotion/styled'
import { calcRem } from '../../../../../__styling'

export const StyledContainer = styled('div')(({ theme }) => ({
    position: 'relative',
}))

export const StyledMask = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '70%',
    background: 'linear-gradient(0deg, #FFFFFF 5%, transparent 140%)',
    zIndex: 5,
    bottom: `-${calcRem(theme.margins.md)}`,
    pointerEvents: 'none',
}))
