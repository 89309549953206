/* eslint-disable @typescript-eslint/no-var-requires */
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'
import { topicParallax } from '../topic-parallax'

const coverDesktopX2 = require<string>('./assets/new-homes/cover-desktop-1220x150@2x.jpg')

const coverDesktopX1 = require<string>('./assets/new-homes/cover-desktop-1220x150.jpg')

const coverTabletX2 = require<string>('./assets/new-homes/cover-tablet-738x57@2x.jpg')

const coverTabletX1 = require<string>('./assets/new-homes/cover-tablet-738x57.jpg')

const coverMobileX2 = require<string>('./assets/new-homes/cover-mobile-411x93@2x.jpg')

const coverMobileX1 = require<string>('./assets/new-homes/cover-mobile-411x93.jpg')

export const getLifestyleNewHomesRouteInfo: GetMainSectionFn = () => {
    return [
        layout.component({
            type: 'image-banner',
            props: {
                defaultSrc: coverDesktopX2,
                verticalGutters: ['unset', 'md'],
                altText: 'New Homes Online Hub - Guiding you to your new home.',
                url: 'mailto:maddisen.booth@wanews.com.au?subject=Website Enquiry from New Homes Online Hub&body=For New Homes team, In reference to your online hub at thewest.com.au/lifestyle/new-homes. (Please type your message below ...)',
                sources: [
                    {
                        breakpoint: 'md',
                        src: [
                            { src: coverDesktopX2, density: '2x' },
                            { src: coverDesktopX1, density: '1x' },
                        ],
                    },
                    {
                        breakpoint: 'xs',
                        src: [
                            { src: coverTabletX2, density: '2x' },
                            { src: coverTabletX1, density: '1x' },
                        ],
                    },
                    {
                        src: [
                            { src: coverMobileX2, density: '2x' },
                            { src: coverMobileX1, density: '1x' },
                        ],
                    },
                ],
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/new-homes'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...topicParallax(),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/new-homes'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 8,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                hasBackground: true,
                loadMoreEnabled: true,

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['lifestyle/new-homes'],
                    includeSubTopics: true,
                    paging: {
                        page: 1,
                        pageOffset: 16,
                        pageSize: 8,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
    ]
}
