import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, colors, themedValue } from '../..'
import { Timestamp } from '../../typography/Timestamp/Timestamp'

export const StyledLiveEventByline = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
}))
export const StyledLiveEventAuthorWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '& > time, span': {
        height: calcRem(20),
    },
}))

export const StyledLiveEventHeadshot = styled('img')(({ theme }) => ({
    width: calcRem(40),
    height: calcRem(40),
    borderRadius: '50%',
    marginRight: theme.margins.sm,
}))
export const StyledLiveEventHeadshotLink = styled(WebLink)(({ theme }) => ({
    display: 'flex',
}))

export const StyledLiveEventHeadshotWrapper = styled('div')(({ theme }) => ({
    width: calcRem(34),
    height: calcRem(34),
    marginRight: theme.margins.sm,
}))

export const StyledLiveEventTimestamp = styled(Timestamp)(({ theme }) => [
    {
        color: theme.colors.brand,

        fontSize: calcRem(16),
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 600,
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        fontWeight: 700,
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        fontWeight: 500,
        lineHeight: calcRem(24),
        color: colors.thenightly.neutral[100],
    },
])

export const StyledLiveEventAuthor = styled('span')(({ theme }) => [
    {
        fontSize: calcRem(16),
        fontFamily: theme.fonts.serif,
        fontWeight: 500,
        color: colors.thewest.greyKoala,
    },
    // Styling for 7N
    theme.kind === 'sevennews' && {
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 400,
    },
    // Styling for TN
    theme.kind === 'thenightly' && {
        fontFamily: theme.fonts.sansSerif,
        fontSize: calcRem(12),
        fontWeight: 400,
        lineHeight: calcRem(16),
        color: '#4B006E',
    },
])

export const StyledLiveEventAuthorLink = styled(WebLink)(({ theme }) => ({
    fontSize: themedValue(theme, {
        thewest: calcRem(16),
        perthnow: calcRem(16),
        sevennews: calcRem(16),
        thenightly: undefined,
    }),
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.serif,
        perthnow: theme.fonts.serif,
        sevennews: theme.fonts.sansSerif,
        thenightly: undefined,
    }),
    fontWeight: themedValue(theme, {
        sevennews: 400,
        fallback: 500,
    }),
    color: colors.thewest.greyKoala,

    textDecoration: 'none',

    '&:hover, &:focus': {
        textDecoration: themedValue(theme, {
            thewest: 'underline',
            fallback: 'none',
        }),
    },
}))

export const StyledLiveEventBylineTextLink = styled(WebLink)(({ theme }) => ({
    textDecoration: 'none',
    'span:hover, span:focus': {
        textDecoration: themedValue(theme, {
            thewest: 'underline',
            fallback: 'none',
        }),
    },
}))

export const StyledLiveEventBylineText = styled('div')(({ theme }) => ({
    lineHeight: 1.1,
    marginRight: 'auto',
    fontSize: themedValue(theme, {
        thewest: calcRem(16),
        perthnow: calcRem(16),
        sevennews: calcRem(16),
        fallback: undefined,
    }),
    fontFamily: themedValue(theme, {
        thewest: theme.fonts.serif,
        perthnow: theme.fonts.serif,
        sevennews: theme.fonts.sansSerif,
        fallback: undefined,
    }),
    fontWeight: themedValue(theme, {
        sevennews: 400,
        fallback: 500,
    }),
    color: colors.thewest.greyKoala,
}))

export const StyledButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
}))
