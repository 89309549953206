import { CSSObject } from '@emotion/css'
import { PictureLayout } from './layouts'

const pictureStyles: CSSObject = {
    position: 'relative',
    display: 'block',
    '& img': {
        display: 'block',
        position: 'absolute',
    },
}

export const pictureLayoutNone: PictureLayout = {
    pictureStyles: () => [pictureStyles],
}
