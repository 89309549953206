import styled from '@emotion/styled'
import { colors } from '../../../../__styling/settings/colors'
import { fonts } from '../../../../__styling/settings/fonts'
import { metrics } from '../../../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointMax,
} from '../../../../__styling/style-functions'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../__styling/style-mixins'

export const StyledGalleryItem = styled('div')(
    {
        [breakpoint('md')]: {
            display: 'flex',
        },
    },
    (props) => [
        props.theme.kind === 'perthnow' && {
            marginBottom: calcRem(metrics.perthnow.margins.md),

            [breakpoint('md')]: {
                border: `1px solid ${colors.perthnow.greyMarble}`,
            },
        },
        props.theme.kind === 'thewest' && {
            marginBottom: calcRem(metrics.thewest.margins.md),

            [breakpoint('md')]: {
                padding: calcRem(0, metrics.thewest.margins.lg),
            },
        },
        props.theme.kind === 'sevennews' && {
            marginTop: calcRem(metrics.sevennews.margins.md),
            marginBottom: calcRem(metrics.sevennews.margins.md),
            flexDirection: 'row-reverse',
        },
    ],
)

export const StyledImageWrap = styled('div')(
    {
        width: '100%',

        [breakpoint('md')]: {
            flex: '0 0 auto',
            width: calcRem(736),
        },
    },
    (props) => [
        props.theme.kind === 'thewest' && {
            [breakpointMax('md')]: {
                width: `calc(100% + ${calcRem(
                    metrics.thewest.margins.mobSm * 2,
                )})`,
                margin: calcRem(
                    0,
                    -metrics.thewest.margins.mobSm,
                    metrics.thewest.margins.sm,
                    -metrics.thewest.margins.mobSm,
                ),
            },
            [breakpoint('md')]: {
                order: 1,
                width: '77%',
            },
        },
        props.theme.kind === 'perthnow' && {
            [breakpoint('md')]: {
                width: calcRem(736),
            },
        },
        props.theme.kind === 'sevennews' && {
            [breakpoint('md')]: {
                width: '100%',
                flex: 'inherit',
            },
        },
    ],
)

export const StyledText = styled('div')(
    {
        [breakpoint('md')]: {
            display: 'flex',
            flex: '1 1 auto',
            flexWrap: 'wrap',
        },
    },
    (props) => [
        (props.theme.kind === 'perthnow' || props.theme.kind === 'thewest') && {
            padding:
                props.theme.kind === 'perthnow'
                    ? calcRem(4, metrics.perthnow.margins.sm, 14)
                    : calcRem(0, props.theme.margins.outerMargin),
            lineHeight: 1.4,

            [breakpointMax('md')]: {
                marginBottom:
                    props.theme.kind === 'perthnow'
                        ? 0
                        : calcRem(metrics.thewest.margins.lg),
            },

            [breakpoint('md')]: {
                alignContent:
                    props.theme.kind === 'perthnow'
                        ? 'space-between'
                        : 'flex-start',
                padding:
                    props.theme.kind === 'perthnow'
                        ? calcRem(metrics.perthnow.margins.md)
                        : calcRem(0, metrics.perthnow.margins.lg, 0, 0),
            },
        },
        props.theme.kind === 'sevennews' && {
            marginTop: calcRem(metrics.sevennews.margins.md),
            display: 'flex',
            marginBottom: 0,

            [breakpoint('md')]: {
                alignContent: 'flex-start',
                paddingRight: metrics.sevennews.margins.md,
                width: '42%',
            },
        },
    ],
)

export const StyledCount = styled('span')(
    {
        fontSize: calcRem(14),
        fontWeight: 700,
        paddingRight: calcRem(2),

        [breakpoint('md')]: {
            flex: '0 0 auto',
            display: 'block',
            fontSize: calcRem(18),
            width: '100%',
        },
    },
    (props) => [
        props.theme.kind === 'perthnow' && {
            fontFamily: fonts.perthnow.heading,
            color: colors.perthnow.greyCoal,
        },
        props.theme.kind === 'thewest' && {
            fontFamily: fonts.thewest.body,
            color: colors.thewest.greyGorilla,
            display: 'block',
        },
        props.theme.kind === 'sevennews' && {
            paddingRight: calcRem(12),
        },
    ],
)

export const StyledDescription = styled('p')(
    {
        ...breakWords,
        display: 'inline',
        fontSize: calcRem(14),
        margin: 0,

        [breakpoint('md')]: {
            flex: '0 0 auto',
            display: 'block',
            width: '100%',
        },
    },
    (props) => [
        props.theme.kind === 'perthnow' && {
            fontFamily: fonts.perthnow.sansSerif,
            color: colors.perthnow.greyCoal,
        },
        props.theme.kind === 'thewest' && {
            fontFamily: fonts.thewest.body,
            color: colors.thewest.greyGorilla,
        },
        props.theme.kind === 'sevennews' && {
            fontSize: calcRem(16),
            fontWeight: 300,
            [breakpoint('md')]: {
                marginTop: calcRem(10),
            },
        },
    ],
)

export const StyledDescriptionAuthor = styled('span')({}, (props) => [
    props.theme.kind === 'sevennews' && {
        fontFamily: fonts.sevennews.body,
        fontSize: calcRem(9),
        color: colors.black50,
        display: 'block',
        marginTop: calcRem(4),
    },
])
