import React from 'react'
import { useOptaWidgetStart } from '../../cricket-match-centre/useOptaWidgetStart'
import { AflMatchLadderWidgetContainer } from './AflMatchLadderWidget.styled'

export interface AflMatchLadderWidgetProps {
    competition: string
    season: string
}
export const AflMatchLadderWidget = ({
    competition,
    season,
}: AflMatchLadderWidgetProps) => {
    useOptaWidgetStart()
    return (
        <AflMatchLadderWidgetContainer>
            <opta-widget
                widget="standings"
                template="normal"
                competition={competition}
                season={season}
                show_rank="true"
                show_key="true"
                sorting="false"
                show_logo="false"
                show_title="false"
                breakpoints="460"
                sport="afl"
            ></opta-widget>
        </AflMatchLadderWidgetContainer>
    )
}
