import {
    AddToCartEvent,
    ExtendedAccessEvent,
    SubscribeWithGoogleContent,
    SubscribeWithGoogleEvent,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { AuthenticationProviderContext } from '../user-registration/authentication/AuthenticationProvider'

interface SubscribeWithGoogleProps {
    onEvent: (
        event: ExtendedAccessEvent | SubscribeWithGoogleEvent | AddToCartEvent,
    ) => void
}

export const SubscribeWithGoogle: React.FC<SubscribeWithGoogleProps> = ({
    onEvent,
}) => {
    // I have this here solely because we can only call useContext for AuthenticationProviderContext inside component-library
    const authContext = useContext(AuthenticationProviderContext)

    if (authContext) {
        return (
            <SubscribeWithGoogleContent
                onEvent={onEvent}
                getSubscribeLink={authContext.getSubscribeLink}
            />
        )
    }

    return <></>
}
