import React from 'react'
import {
    Product,
    PublicationCardItem,
    createCardClickedEvent,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import {
    StyledOlympicsArticleImage,
    StyledOlympicsArticle,
    StyledOlympicsArticleText,
    StyledOlympicsArticleKicker,
    StyledOlympicsArticleHeadline,
    StyledOlympicsArticleByline,
    StyledOlympicsArticleTeaser,
    StyledOlympicsInnerArticle,
    StyledLink,
} from './OlympicsCollectionArticle.styled'
import { OlympicsCollectionKicker } from '../OlympicsCollectionKicker/OlympicsCollectionKicker'
import { OlympicsCollectionByline } from '..'
import { getFundingType } from '../../templates'
import { useProduct } from '../../__product'

export type OlympicsCollectionArticleType =
    /** Used to show an article with no teaser for the top row on TheWest */
    | 'articleWithNoTeaser'
    /** Used to determine padding left/right */
    | 'articleWithTeaserAndImageLeft'
    /** Used to determine padding left/right */
    | 'articleWithTeaserAndImageRight'
    /** The normal article type used in PerthNow (other than the feature), also used in the Mini Collection on TheWest */
    | 'article'

export interface OlympicsCollectionArticleProps {
    item: PublicationCardItem | undefined
    articleType: OlympicsCollectionArticleType
    onEvent: (event: any) => void
    cardNumber: number
    isMiniCollection?: boolean
}

/**
 * Build a component to support the display of an olympics article within the OlympicsCollection
 */
export const OlympicsCollectionArticle: React.FC<OlympicsCollectionArticleProps> =
    ({ item, articleType, onEvent, cardNumber, isMiniCollection = false }) => {
        const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
            if (!item) {
                // We don't want the link to do anything
                e.preventDefault()
                return
            }

            // Fire off the appropriate article click events
            onEvent(
                createCardClickedEvent(
                    onEvent,
                    item,
                    'OlympicsCollectionArticleCard',
                    'OlympicsArticleCard',
                    cardNumber,
                    fundingType,
                ),
            )
        }

        const storyClassification = true
        const product = useProduct()

        // No item found, so return an empty element to stop the page from breaking
        if (!item) {
            return null
        }

        const fundingType = getFundingType(item, storyClassification)
        const isPerthNow = product === Product.PerthNow

        return (
            <StyledOlympicsArticle
                articleType={articleType}
                isMiniCollection={isMiniCollection}
            >
                <StyledLink
                    to={toLinkWithHint({ loaded: true, result: item })}
                    onClick={linkClicked}
                    hasBackground={true}
                    isLoading={false}
                >
                    {/* We add an inner container to handle all the borders successfully */}
                    <StyledOlympicsInnerArticle articleType={articleType}>
                        {/* Make sure that the article actually is clickable to an article! */}
                        {/* Make sure to only display the image on the articles which require it */}
                        <StyledOlympicsArticleImage
                            item={{
                                loaded: true,
                                result: item,
                            }}
                            imageWidths={{
                                mobile: '160px',
                                tablet: '320px',
                                desktop: '320px',
                                fallbackWidth: 320,
                            }}
                            hasBackground={false}
                            onEvent={onEvent}
                            mediaMode={'default'}
                            fixedRatio={
                                product === Product.PerthNow ? '4:3' : '16:9'
                            }
                            disableImageLazyLoad={true}
                            displayNoScriptImage={true}
                            articleType={articleType}
                        />
                        {/* All the text for each article block */}
                        <StyledOlympicsArticleText articleType={articleType}>
                            {/* Article Kicker */}
                            {item.kicker !== undefined && (
                                <StyledOlympicsArticleKicker>
                                    <OlympicsCollectionKicker item={item}>
                                        {item.kicker}
                                    </OlympicsCollectionKicker>
                                </StyledOlympicsArticleKicker>
                            )}
                            {/* Article Headline */}
                            <StyledOlympicsArticleHeadline>
                                {item.shortHeadline}
                            </StyledOlympicsArticleHeadline>
                            {/* Article Teaser */}
                            <StyledOlympicsArticleTeaser
                                wrappedTeaser={true}
                                articleType={articleType}
                            >
                                {item.teaser}
                            </StyledOlympicsArticleTeaser>
                            {/* Article Byline */}
                            {!isPerthNow && (
                                <StyledOlympicsArticleByline>
                                    <OlympicsCollectionByline
                                        type={'article'}
                                        showProfile={false}
                                        text={item.byline}
                                    />
                                </StyledOlympicsArticleByline>
                            )}
                        </StyledOlympicsArticleText>
                    </StyledOlympicsInnerArticle>
                    {isPerthNow && (
                        <StyledOlympicsArticleTeaser
                            wrappedTeaser={false}
                            articleType={articleType}
                        >
                            {item.teaser}
                        </StyledOlympicsArticleTeaser>
                    )}
                </StyledLink>
            </StyledOlympicsArticle>
        )
    }
