import { layout } from '../../App.routing'

export const webStoriesSegment = () => {
    return layout.component({
        type: 'web-stories',
        feature: 'web-stories',
        props: {
            sectionHeader: { heading: 'News Flash' },
        },
    })
}
