import { TogglesReduxState } from '@news-mono/web-common'
import { layout } from '../../App.routing'

export const getOlympicsSegment = (
    toggles: TogglesReduxState,
    isHomepage: boolean,
) => {
    const toggleActive = toggles['paris-olympics-homepage-collection'] === true

    return toggleActive
        ? [
              layout.nestedComposition({
                  type: 'thor',
                  feature: 'paris-olympics-homepage-collection',
                  props: {
                      hasBackgroundFill: false,
                      containerWidth: '100%',
                      verticalGutters: false,
                      horizontalGutters: false,
                  },
                  contentAreas: {
                      main: [
                          layout.component({
                              type: 'olympics-collection',
                              props: {
                                  isHomepage,
                                  dataDefinitionArgs: {
                                      type: 'curation',
                                      name: 'olympics',
                                      pageSize: 8,
                                      offset: 0,
                                  },
                              },
                          }),
                      ],
                  },
              }),
          ]
        : []
}
