import { BlockV4DTO } from '@west-australian-newspapers/publication-types'
import { Block } from '../../../client-data-types/blocks'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateKeyTypes } from '../../../helpers/validation'
import { mapContentBlock } from '../../../__api-mapping/v4/publication/map/content-blocks'

export interface Content {
    kind: 'content'
    blocks: BlockV4DTO[]
}

export const validateStaticContentValue = (value: any): Content => {
    const toValidate = value as Content

    if (!toValidate) {
        throw new Error('static content data missing')
    }

    const errors = validateKeyTypes('content', toValidate, {
        kind: 'string',
        blocks: [{}],
    })

    if (errors) {
        throw new Error(
            'Static content response invalid\n    ' + errors.join('    \n'),
        )
    }

    return toValidate
}

const fetchStaticContent = (services: DataLoaderGlobalParams, id: string) => {
    return httpGet({
        log: services.log,
        validate: validateStaticContentValue,
        baseUrl: services.config.contentApi,
        path: `v4/static/${id}`,
        query: {},
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}

export const getStaticContent = async (
    services: DataLoaderGlobalParams,
    id: string,
): Promise<Block[]> => {
    const content: Block[] = []
    const rawContent = await fetchStaticContent(services, id)

    for (const apiBlock of rawContent.blocks) {
        const block = mapContentBlock(apiBlock)

        if (block) {
            content.push(block)
        }
    }
    return content
}
