import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../__styling'
import { StyledEpaperCollectionPapersStyledImg } from './EpaperCollection.styled'

export const StyledDualImageContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    height: calcRem(270),
    width: calcRem(167),
    [breakpoint('lg')]: {
        height: calcRem(233),
    },
}))

export const StyledDualImageBackImage = styled(
    StyledEpaperCollectionPapersStyledImg,
)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    height: '65%',
    width: 'auto',
    [breakpoint('lg')]: {
        height: '70%',
    },
}))

export const StyledDualImageFrontImage = styled(
    StyledEpaperCollectionPapersStyledImg,
)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '80%',
    width: 'auto',
    [breakpoint('lg')]: {
        height: '90%',
    },
}))
