import React, { FC } from 'react'
import { QueryParamDropdown } from '../components/DropdownMenu/QueryParamDropdown'
import { RESULT_SORT_PARAM } from './util'
import { FilterQueryParam } from '../FilterQueryParam'
import { FilterEventProps } from '../filter-events-util'

interface ResultSortProps extends FilterEventProps {
    defaultOption: FilterQueryParam
    options: FilterQueryParam[]
}

export const ResultSort: FC<ResultSortProps> = ({
    defaultOption,
    options,
    onEvent,
    dataLayerEventName,
}: ResultSortProps) => {
    return (
        <QueryParamDropdown
            defaultOption={defaultOption}
            menuOptions={options}
            name="Result Sort"
            label="Sort"
            originator="ResultSort"
            queryParamName={RESULT_SORT_PARAM}
            onEvent={onEvent}
            dataLayerEventName={dataLayerEventName}
        />
    )
}
