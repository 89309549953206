import { Product } from '@news-mono/web-common'
import { Theme } from '../../__styling'

export function getChipType<T extends string = never>(
    theme: Theme,
    text?: T,
    entryType?: string,
) {
    if (theme.kind === Product.TheNightly) {
        return 'tni-black'
    }

    switch (text?.toLowerCase()) {
        case 'athletics':
        case 'cycling':
            return 'pink'
        case 'swimming':
        case 'aquatics':
        case 'gymnastics':
        case 'Judo':
            return 'purple'
        case 'badminton':
        case 'basketball':
        case 'table tennis':
        case 'weightlifting':
        case 'wrestling':
            return 'orange'
        case 'beach volleyball':
            return 'blue'
        case 'cricket':
        case 'hockey':
        case 'rugby':
            return 'green'
        // AFL
        case 'q1':
        case 'q2':
        case 'q3':
        case 'q4':
            return 'afl-red'
        case 'half-time':
        case 'full-time':
        case 'quarter-time':
            if (entryType === 'soccer') {
                return 'nrl-black'
            }
            return 'afl-blue'
        case 'goal':
            if (entryType === 'soccer') {
                return 'nrl-yellow'
            }
            return 'afl-main'
        case 'baaaallll!':
        case 'behind':
        case 'big call':
        case 'brutal hit':
        case 'injury':
        case 'ouch':
        case 'score update':
        case 'rumour':
        case 'confirmed':
        case 'reaction':
        case 'statement':
        case 'done deal':
        case 'pick swap':
        case 'trade alert':
        case 'whispers':
        case 'prediction':
        case 'delisted':
        case 'compensation':
            return 'afl-main'
        // NRL
        case 'kickoff':
        case 'kick-off':
        case 'half time':
        case 'full time':
        case 'first half': // SOCCER TOO
        case 'second half': // SOCCER TOO
        case 'extra time': // SOCCER
        case 'penalty shootout': // SOCCER
            return 'nrl-black'
        case 'try':
        case 'conversion successful':
            return 'nrl-green'
        case 'penalty':
        case 'video referee':
        case "captain's challenge":
            return 'nrl-yellow'
        case 'no try':
        case 'conversion unsuccessful':
        case 'error':
        case 'breaking':
        case 'news update':
        case 'red card':
            return 'nrl-red'
        case 'top moments':
        case 'premiers':
        case 'world cup winners':
            return 'nrl-blue'
        case 'big hit':
            return 'nrl-orange'
        case 'interchange':
        case 'line dropout':
        case 'offside':
        case 'free kick':
        case 'substitution':
        case 'tackle':
        case 'shot':
        case 'block/save':
            return 'nrl-grey'
        case 'group stage':
        case 'round of 16':
        case 'third place playoff':
        case 'quarterfinal':
        case 'semifinal':
        case 'final':
            return 'soccer-maroon'
        default:
            return text
    }
}

export const ElectionChipTypes: string[] = [
    'Polls open',
    'Polls closed',
    'Count update',
    'Prediction',
    'Seat declared',
    'Concession',
    'Announcement',
    'Press conference',
]
export const BusinessChipTypes: string[] = [
    'Announcement',
    'ASX update',
    'Aussie dollar',
    'Broker reaction',
    'Cost of living',
    'Energy',
    'Investor call',
    'Rates to rise',
    'Rates to fall',
    'Loss',
    'Market dip',
    'Market rally',
    'Mining',
    'Profit',
    'Property',
    'Retail',
]
export const GenericChipTypes: string[] = [
    'Announcement',
    'Press conference',
    'Arrival',
    'Reading',
    'Tribute',
    'Song',
]
export const CourtChipTypes: string[] = [
    'Evidence',
    'Bail',
    'New witness',
    'Case adjourned',
    'Court adjourned',
    'Court resumes',
    'Cross examination',
    'Verdict',
    'Sentencing',
]
export const InternationalGamesChipTypes: string[] = [
    'Gold medal',
    'Silver medal',
    'Bronze medal',
    'Aussie moment',
    'Medal moment',
    'World record',
    'Comm games record',
    'Opening ceremony',
    'Closing ceremony',
    'Athletics',
    'Aquatics',
    'Badminton',
    'Basketball',
    'Beach volleyball',
    'Cricket',
    'Cycling',
    'Gymnastics',
    'Hockey',
    'Judo',
    'Rugby',
    'Swimming',
    'Table tennis',
    'Weightlifting',
    'Wrestling',
]
export const RedCarpetChipTypes: string[] = [
    'Red carpet',
    'Fashion win',
    'Fashion fail',
    'Interview',
    'Award',
    'Speech',
]
export const DraftTradesChipTypes: string[] = [
    'Rumour',
    'Confirmed',
    'Reaction',
    'Statement',
    'Done deal',
    'Pick swap',
    'Trade alert',
    'Whispers',
    'Predicition',
    'Delisted',
    'Compensation',
    'Key interview',
    'Eagles draft pick',
    'Dockers draft pick',
    'WA players drafted',
    'Pick swap',
]
export const AFLChipTypes: string[] = [
    'Goal',
    'Behind',
    'Injury',
    'Top moments',
    'Half-time',
    'Full-time',
    'Big call',
    'News update',
    'Breaking',
    'Premiers',
    'Baaaallll!',
    'Umpire???',
    'Ouch',
    'Brutal hit',
    'Quarter-time',
    'Q1',
    'Q2',
    'Q3',
    'Q4',
]
export const NRLChipTypes: string[] = [
    'Try',
    'No try',
    'Penalty',
    'Conversion successful',
    'Conversion unsuccessful',
    'Kick-off',
    'Half-time',
    'Full-time',
    'Interchange',
    'Big hit',
    'Error',
    'Linebreak',
    'Video referee',
    "Captain's challenge",
    'Line dropout',
    'First half',
    'Second half',
    'Top moments',
    'Big call',
    'Premiers',
    'News update',
    'Breaking',
    'Extra time',
    'Penalty shootout',
    'Goal',
    'Offside',
    'Free kick',
    'Penalty',
    'Worldie alert',
    'Yellow card',
    'Red card',
    'Substitution',
    'Tackle',
    'Shot',
    'Block/save',
    'Group stage',
    'Round of 16',
    'Quarterfinal',
    'Semifinal',
    'Final',
    'Third place playoff',
    'World Cup winners',
    'Socceroos',
]
