import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem, fonts, metrics } from '../../../__styling'
import { TNToastProps } from '../TNToastNotification.routing'

export const StyledToastText = styled('p')<TNToastProps>((props) => ({
    color:
        props.type === 'success'
            ? tokens.thenightly.colors.palette.utility.success.dark
            : props.type === 'warning'
            ? tokens.thenightly.colors.palette.utility.warning.dark
            : props.type === 'error'
            ? tokens.thenightly.colors.palette.utility.error.dark
            : tokens.thenightly.colors.palette.neutral[100],
    fontWeight: 600,
    letterSpacing: calcRem(-0.32),

    fontFamily: fonts.thenightly.sansSerif,
    fontSize: calcRem(metrics.thenightly.baseFontSize),
    lineHeight: calcRem(24),

    // Handle formatting on all clickable links below:
    a: {
        textDecorationLine: 'underline',
    },
}))
StyledToastText.displayName = 'StyledToastText'
