import React from 'react'
import { StyledPNParagraph } from './PNParagraph.styled'
import { ParagraphProps } from '../../../../../publication/Paragraph/Paragraph'

export const PNParagraph: React.FC<ParagraphProps> = (props) => {
    return (
        <StyledPNParagraph
            tag="p"
            text={props.text}
            intentions={props.intentions}
            onEvent={props.onEvent}
        />
    )
}
PNParagraph.displayName = 'PNParagraph'
