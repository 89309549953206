import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { textCrop } from '../../__styling/style-mixins'

export const StyledPromoBlock = styled('div')(({ theme }) => ({
    marginBottom: calcRem(80),

    [breakpoint('sm')]: {
        padding: `${calcRem(0, 33)}`,
    },
    [breakpoint('md')]: {
        padding: `${calcRem(0, 58)}`,
    },

    [breakpoint('lg')]: {
        padding: `${calcRem(0, 65)}`,
        marginTop: calcRem(-50),
    },
}))

export const StyledHeading = styled('span')(({ theme }) => ({
    fontSize: calcRem(28),
    fontFamily: theme.fonts.sansSerifCond,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    ...textCrop({
        fontSize: 28,
        desiredLineHeight: 1.2,
        cropLineHeight: 1.2,
        topCrop: 10,
        bottomCrop: 8,
    }),
    [breakpoint('sm')]: {
        fontSize: calcRem(36),
        ...textCrop({
            fontSize: 36,
            desiredLineHeight: 1.2,
            cropLineHeight: 1.2,
            topCrop: 10,
            bottomCrop: 8,
        }),
    },
}))

export const StyledSubHeading = styled('span')(({ theme }) => ({
    fontSize: calcRem(18),
    fontFamily: theme.fonts.heading,
    color: colors.thewest.greyKoala,
    fontWeight: 'bold',
}))
export const StyledHeadingWrap = styled('div')(({ theme }) => ({
    marginBottom: theme.margins.md,
}))
