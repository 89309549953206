import styled from '@emotion/styled'
import { Theme, calcRem, colors } from '../..'
import { ChipVariant } from './Chip'
import { tokens } from '@news-mono/design-tokens'

export interface StyledChipProps {
    iconSide?: 'left' | 'right'
    variant: ChipVariant
    buttonType?: string
}

export const StyledChip = styled('button')<StyledChipProps>(
    ({ theme, variant, buttonType, iconSide }) => [
        {
            flexDirection: iconSide === 'right' ? 'row-reverse' : 'row',
            margin: theme.margins.xs,
            height: calcRem(32),
            padding: calcRem(8, 10),
            border: calcRem(0),
            borderRadius: calcRem(24),

            fontSize: calcRem(16),
            fontFamily: theme.fonts.sansSerif,
            fontWeight: 500,

            display: 'flex',

            '& > svg': {
                width: calcRem(14),
                height: calcRem(14),
            },
            '& > span': {
                position: 'relative',
                bottom: 0,
                paddingLeft: iconSide === 'right' ? 0 : calcRem(4),
                paddingRight: iconSide === 'right' ? calcRem(4) : 0,
            },
        },
        // Styling for TW
        theme.kind === 'thewest' && {
            '& > span': {
                bottom: 2,
            },
        },
        // Styling for 7N
        theme.kind === 'sevennews' && {
            fontWeight: 400,
        },
        // Styling for TN
        theme.kind === 'thenightly' && {
            fontWeight: 600,
            lineHeight: calcRem(24),
            textTransform: 'none',

            color: theme.colors.palette.white,
            backgroundColor: theme.colors.palette.black,
            margin: 0,
            padding: calcRem(10, 0),

            height: 'fit-content',

            '& > span': {
                alignSelf: 'center',
                paddingLeft: 0,
                paddingRight: 0,
            },
        },

        // ------------------------------------------------------------
        // - P I N N E D
        // ------------------------------------------------------------
        // Extra default styling for the Pinned variant
        variant === 'pinned' && {
            color: theme.colors.palette.white,
            backgroundColor: theme.colors.brand,
        },
        // Styling for TW Pinned
        variant === 'pinned' &&
            theme.kind === 'thewest' && {
                backgroundColor: colors.thewest.breachNavy,
            },
        // Styling for 7N Pinned
        variant === 'pinned' &&
            theme.kind === 'sevennews' && {
                backgroundColor: colors.sevennews.darkRed,
            },
        // Styling for TN Pinned
        variant === 'pinned' &&
            theme.kind === 'thenightly' && {
                textTransform: 'uppercase',
                backgroundColor: 'transparent',
                color: '#4B006E',

                '& > svg': {
                    alignSelf: 'center',
                    width: calcRem(22),
                    height: 'fit-content',
                },
                '& > span': {
                    paddingLeft: iconSide === 'right' ? 0 : calcRem(10),
                    paddingRight: iconSide === 'right' ? calcRem(10) : 0,
                },
            },

        // ------------------------------------------------------------
        // - L A B E L
        // ------------------------------------------------------------
        variant === 'label' && {
            color: theme.colors.palette.white,
            backgroundColor: theme.colors.brand,
        },
        // Styling for TN Label
        variant === 'label' &&
            theme.kind === 'thenightly' && {
                padding: calcRem(4, 12),
                color: theme.colors.palette.white,
                backgroundColor: theme.colors.palette.black,
            },

        // ------------------------------------------------------------
        // - H A P P E N I N G
        // ------------------------------------------------------------
        variant === 'happening' && {
            color: getHappeningColor(theme, buttonType),
            background: getHappeningBackgroundColor(buttonType),
        },
        // Styling for TN Happening
        variant === 'happening' &&
            theme.kind === 'thenightly' && {
                padding: calcRem(4, 12),

                color: theme.colors.palette.white,
                backgroundColor: theme.colors.palette.black,
            },
    ],
)

export const StyledChipIcon = styled('img')({
    margin: 'auto',
    height: '16px',
})

export const StyledChipSnapshotContainer = styled('div')({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',

    width: 'fit-content',
})

const getHappeningColor = (theme: Theme, buttonType?: string): string => {
    switch (buttonType) {
        case 'pink':
        case 'purple':
        case 'Medal Moment':
        case 'Opening Ceremony':
        case 'Closing Ceremony':
        case 'afl-red':
        case 'afl-blue':
        case 'afl-main':
        case 'nrl-black':
        case 'nrl-red':
        case 'nrl-grey':
        case 'nrl-blue':
        case 'nrl-green':
        case 'Goal':
        case 'Yellow Card':
        case 'soccer-maroon':
        case 'soccer-blue':
        case 'Socceroos':
            return theme.colors.palette.white
        case 'orange':
        case 'blue':
        case 'green':
        case 'Gold Medal':
        case 'Silver Medal':
        case 'Bronze Medal':
        case 'Aussie Moment':
        case 'World Record':
        case 'nrl-yellow':
        case 'nrl-orange':
            return theme.colors.palette.black
        default:
            return 'inherit'
    }
}
const getHappeningBackgroundColor = (buttonType?: string): string => {
    switch (buttonType) {
        case 'pink':
            return tokens.sevennews.colors.palette.commonwealthGames.hotMagenta
        case 'purple':
            return tokens.sevennews.colors.palette.commonwealthGames.slateBlue
        case 'Medal Moment':
            return `linear-gradient(299.44deg, #BD37AC 22.53%, #0E26C1 75.43%), #F1F1F1`
        case 'Opening Ceremony':
            return `linear-gradient(292.86deg, #BD37AC 47.57%, #0E26C1 84.84%), linear-gradient(317.4deg, #BD37AC 29.33%, #0E26C1 85.95%), #F1F1F1`
        case 'Closing Ceremony':
            return `linear-gradient(92.45deg, #BD37AB 23.34%, #F08031 51.74%), linear-gradient(61.53deg, #B8F8B2 15.3%, #70FBF9 75.62%), linear-gradient(317.4deg, #BD37AC 29.33%, #0E26C1 85.95%), #F1F1F1`
        case 'afl-red':
            return `linear-gradient(0deg, #ED020A, #ED020A)`
        case 'afl-blue':
            return tokens.sevennews.colors.palette.AFL.blue
        case 'afl-main':
            return `linear-gradient(259.19deg, #F50007 2.24%, #002C82 76.47%), linear-gradient(91.46deg, #01529B 0%, #E21E30 101.31%), #F1F1F1`
        case 'nrl-black':
            return tokens.sevennews.colors.palette.NRL.black
        case 'nrl-red':
            return tokens.sevennews.colors.palette.NRL.red
        case 'nrl-grey':
            return tokens.sevennews.colors.palette.NRL.grey
        case 'nrl-blue':
            return tokens.sevennews.colors.palette.NRL.blue
        case 'nrl-green':
            return `linear-gradient(261.45deg, #0D713B 5.39%, #022E23 93.47%), linear-gradient(272.66deg, #0D713B 14.07%, #022E23 80.79%), #0D713B`
        case 'Goal':
            return tokens.sevennews.colors.palette.soccer.green
        case 'Yellow Card':
            return `linear-gradient(0deg, #FEE533, #FEE533), linear-gradient(91.46deg, #01529B 0%, #E21E30 101.31%), #F1F1F1`
        case 'soccer-maroon':
            return tokens.sevennews.colors.palette.soccer.maroon
        case 'soccer-blue':
            return `linear-gradient(0deg, #0038FF, #0038FF), linear-gradient(91.46deg, #01529B 0%, #E21E30 101.31%), #F1F1F1`
        case 'Socceroos':
            return `linear-gradient(88.79deg, #FAFF00 -17.53%, #007C57 90.32%), linear-gradient(0deg, #0038FF, #0038FF), linear-gradient(91.46deg, #01529B 0%, #E21E30 101.31%), #F1F1F1`
        case 'orange':
            return tokens.sevennews.colors.palette.commonwealthGames.crayola
        case 'blue':
            return tokens.sevennews.colors.palette.commonwealthGames.blueBolt
        case 'green':
            return tokens.sevennews.colors.palette.commonwealthGames.bitterLemon
        case 'Gold Medal':
            return `linear-gradient(104.66deg, #FEE177 0.04%, #A5850E 90.14%), #F1F1F1`
        case 'Silver Medal':
            return `linear-gradient(98.41deg, #D8D8D8 -2.17%, #ACACAC 100.79%), #F1F1F1`
        case 'Bronze Medal':
            return `linear-gradient(98.91deg, #C48B68 5.91%, #A97142 79.24%), #F1F1F1`
        case 'Aussie Moment':
            return `linear-gradient(90.35deg, #FBE64D -12.69%, #F08031 74.62%), #DAF75C`
        case 'World Record':
            return `linear-gradient(91.42deg, #FD94C0 9.27%, #F7AA48 51.35%), linear-gradient(90.66deg, #FBE64D 11.14%, #F08031 109.05%), #F1F1F1`
        case 'nrl-yellow':
            return tokens.sevennews.colors.palette.NRL.yellow
        case 'nrl-orange':
            return tokens.sevennews.colors.palette.NRL.orange
        default:
            return 'inherit'
    }
}
