import React from 'react'
import {
    StyledActionLink,
    StyledActionTextUsername,
    usernameButtonClassname,
} from './TheWestHeaderLogin.styled'

const className = 'ActionTextUsername'

export const UserNameLink: React.FC<{ userName: string }> = ({ userName }) => {
    return (
        <StyledActionTextUsername className={className}>
            Hi{' '}
            <StyledActionLink
                className={usernameButtonClassname}
                to={'https://subscriber.thewest.com.au/myaccount'}
                rel="noopener"
                openExternalInNewWindow={true}
                isPrimary={false}
            >
                {userName}
            </StyledActionLink>
        </StyledActionTextUsername>
    )
}

export const UserName: React.FC<{ userName: string }> = ({ userName }) => {
    return (
        <StyledActionTextUsername className={className}>
            Hi {userName}
        </StyledActionTextUsername>
    )
}
