import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { tokens } from '@news-mono/design-tokens'
import { TNToastComponentProps } from '../../../../content'
import { TNToastComponent } from '../../../../content/TNToastNotification/TNToastNotification'
import {
    StyledSaveNameContentLayout,
    StyledSaveNameContentInput,
    StyledSaveNameContentInputWrapper,
    StyledSaveNameContentValidationError,
    StyledSaveNameContentColumnA,
    StyledSaveNameContentColumnB,
    StyledSaveNameContentBodyText,
    StyledSaveNameContentForm,
    StyledSaveNameContentButtonWrapper,
    StyledSaveNameContentSpinner,
    VerifyEmailContentProps,
    StyledAccountModifyHeading,
    StyledAccountModifyWardenButton,
} from '../../../warden/account-modify'
import { useChangeName } from '../../../../user-setting/hooks/useChangeName'
import { authCheckAccessToken } from '@news-mono/web-common'
import {
    validateNames,
    ValidationError,
} from '../../../warden/account-modify/validateNames'

export type FormData = {
    firstName: string
    lastName: string
}

export const SaveNameContent = ({
    userId,
    userEmail,
    onEvent,
    log,
    config,
}: VerifyEmailContentProps) => {
    const dispatch = useDispatch()

    const [statusBanner, setStatusBanner] =
        useState<TNToastComponentProps | null>(null)

    const [isRefreshingAuth, setIsRefreshingAuth] = useState<boolean>(false)

    const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
        [],
    )

    const hasError = validationErrors.length > 0

    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
    })

    const { mutateAsync: changeName, isLoading } = useChangeName({
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        email: userEmail,
        userId,
        onSuccess: () => {
            setIsRefreshingAuth(true)

            dispatch(
                authCheckAccessToken({
                    onEvent,
                    log,
                    config,
                    invocation: 'auto',
                    forceRefresh: true,
                    onInvocationEvent: onEvent,
                }),
            )
            setInterval(() => {
                // Just incase something goes wrong with the auth refresh
                setIsRefreshingAuth(false)
            }, 5000)
        },
        onError: () => {
            setStatusBanner({
                type: 'error',
                text: 'Something went wrong, please try again.',
                useMaxWidth: false,
                forceMaxWidth: true,
            })
        },
    })

    const isBusy = isLoading || isRefreshingAuth

    const onSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setStatusBanner(null)

        const errors = validateNames(
            formData.firstName.trim(),
            formData.lastName.trim(),
        )
        setValidationErrors(errors)

        if (errors.length > 0) {
            return
        }

        changeName()
    }

    return (
        <>
            {statusBanner && <TNToastComponent {...statusBanner} />}
            <StyledSaveNameContentLayout>
                <StyledSaveNameContentColumnA>
                    <StyledAccountModifyHeading>
                        Want to leave a comment?
                    </StyledAccountModifyHeading>
                    <StyledSaveNameContentBodyText>
                        Finish your profile by entering your first and last
                        name. You can change this later in your Account.
                    </StyledSaveNameContentBodyText>
                </StyledSaveNameContentColumnA>

                <StyledSaveNameContentColumnB>
                    <StyledSaveNameContentForm method="POST" onSubmit={onSave}>
                        <StyledSaveNameContentInputWrapper>
                            <StyledSaveNameContentInput
                                name="first-name"
                                disabled={isBusy}
                                id="savename-first-name"
                                value={formData.firstName}
                                type="text"
                                placeholder="First name"
                                hasError={hasError}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        firstName: e.currentTarget.value,
                                    })
                                }
                            />
                        </StyledSaveNameContentInputWrapper>
                        {validationErrors
                            .filter(({ type }) => type === 'first-name')
                            .map((error) => {
                                return (
                                    <StyledSaveNameContentValidationError>
                                        {error.message}
                                    </StyledSaveNameContentValidationError>
                                )
                            })}
                        <StyledSaveNameContentInputWrapper>
                            <StyledSaveNameContentInput
                                name="last-name"
                                disabled={isBusy}
                                id="savename-last-name"
                                value={formData.lastName}
                                type="text"
                                placeholder="Last name"
                                hasError={hasError}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        lastName: e.currentTarget.value,
                                    })
                                }
                            />
                        </StyledSaveNameContentInputWrapper>
                        {validationErrors
                            .filter(({ type }) => type === 'last-name')
                            .map((error) => {
                                return (
                                    <StyledSaveNameContentValidationError>
                                        {error.message}
                                    </StyledSaveNameContentValidationError>
                                )
                            })}

                        <StyledSaveNameContentButtonWrapper>
                            <StyledAccountModifyWardenButton
                                type="submit"
                                disabled={isBusy}
                                backgroundColors={{
                                    default:
                                        tokens.thenightly.colors.palette.utility
                                            .error.default,
                                    hover: tokens.thenightly.colors.palette
                                        .utility.error.hover,
                                }}
                                roundEdges={true}
                                fullWidthButton={true}
                            >
                                {isBusy ? (
                                    <StyledSaveNameContentSpinner />
                                ) : (
                                    'SAVE'
                                )}
                            </StyledAccountModifyWardenButton>
                        </StyledSaveNameContentButtonWrapper>
                    </StyledSaveNameContentForm>
                </StyledSaveNameContentColumnB>
            </StyledSaveNameContentLayout>
        </>
    )
}
