import { Action } from 'redux'

const MAIN_NAVIGATION_OPEN = 'MAIN_NAVIGATION_OPEN'

export interface MAIN_NAVIGATION_OPEN_ACTION extends Action {
    type: typeof MAIN_NAVIGATION_OPEN
}

export const openNavigation = () => ({
    type: MAIN_NAVIGATION_OPEN,
})

const MAIN_NAVIGATION_CLOSE = 'MAIN_NAVIGATION_CLOSE'

export interface MAIN_NAVIGATION_CLOSE_ACTION extends Action {
    type: typeof MAIN_NAVIGATION_CLOSE
}

export const closeNavigation = () => ({
    type: MAIN_NAVIGATION_CLOSE,
})

export interface NavigationState {
    mainNavOpen?: boolean
}

export const navigationReducer = (
    state: NavigationState = { mainNavOpen: false },
    action: MAIN_NAVIGATION_OPEN_ACTION | MAIN_NAVIGATION_CLOSE_ACTION,
) => {
    switch (action.type) {
        case MAIN_NAVIGATION_OPEN:
        case MAIN_NAVIGATION_CLOSE:
            return { mainNavOpen: action.type === MAIN_NAVIGATION_OPEN }
        default:
            return state
    }
}
