import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions/breakpoint'
import { calcRem } from '../../__styling/style-functions/calc-rem'

const dark = '#535556'

interface BaseDropdownProps {
    isOpen: boolean
}
interface StyledDropdownProps extends BaseDropdownProps {
    isEntitled: boolean
}

export const StyledTheWestHeaderDropdownWrap = styled('div')({
    position: 'relative',
    height: calcRem(40),
})

export const StyledActionUsername = styled('button')<BaseDropdownProps>(
    ({ theme, isOpen }) => ({
        color: isOpen ? theme.colors.background.primary : theme.colors.brand,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: isOpen ? theme.colors.brand : 'transparent',
        transition: 'background .2s ease',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        marginRight: '-' + calcRem(10),
        '.StyledActionUsernameIcon': {
            height: calcRem(8),
            path: {
                fill: isOpen
                    ? theme.colors.background.primary
                    : theme.colors.brand,
            },
            marginLeft: calcRem(8),
        },

        '.flex-center': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: calcRem(0, 8),
        },

        '.username': {
            alignSelf: 'center',
            fontSize: calcRem(14),
            fontWeight: 500,
            display: 'block',
            marginRight: calcRem(5),
        },

        ':hover': {
            background: isOpen
                ? theme.colors.brand
                : theme.colors.actions.button.secondary?.background.default,
            cursor: 'pointer',
        },

        [breakpoint('md')]: {
            marginRight: 0,
        },
    }),
)
export const StyledMobileCloseIcon = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    height: calcRem(48),
    width: calcRem(48),
    background: dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [breakpoint('md')]: {
        display: 'none',
    },
})

export const StyledLinkBlockingContainer = styled('div')<BaseDropdownProps>(
    ({ theme, isOpen }) => ({
        display: isOpen ? 'block' : 'none',
        zIndex: zIndex.thewest.tooltipFullscreenOverlay,
        position: 'fixed',
        top: 0,
        left: 0,
        content: `''`,
        height: '100vh',
        width: '100vw',
    }),
)

export const StyledDropdown = styled('div')<StyledDropdownProps>(
    ({ theme, isOpen }) => ({
        overflowY: 'auto',
        position: 'fixed',
        padding: calcRem(56, 0),
        right: '0',
        top: 0,
        width: calcRem(300),
        height: '100vh',
        background: '#2B2D2E',
        color: theme.colors.background.primary,
        pointerEvents: isOpen ? 'auto' : 'none',
        transform: isOpen ? 'translateX(0%)' : 'translateX(100%)',
        transition: 'transform 0.2s ease',
        zIndex: zIndex.thewest.flyoutNav,
        boxShadow: isOpen
            ? '-1px 0px 4px rgba(0, 0, 0, 0.3), -8px 0px 16px rgba(0, 0, 0, 0.2)'
            : 'none',
        [breakpoint('md')]: {
            position: 'absolute',
            right: 0,
            top: 40,
            height: 'auto',
            padding: calcRem(32, 0),
            transform: 'translateX(0%)',
            boxShadow:
                ' 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 16px 24px -8px rgba(0, 0, 0, 0.32)',
            borderRadius: '16px 0px 16px 16px',
            opacity: isOpen ? 1 : 0,
            transition: 'opacity 0.2s ease',
        },
    }),
)

export const StyledUserSection = styled('section')(({ theme }) => ({
    padding: calcRem(0, 24, 24, 24),
    '.username': {
        fontSize: calcRem(22),
        display: 'block',
        lineHeight: calcRem(22),
        marginBottom: calcRem(16),
    },
    '.heading-font': {
        fontFamily: theme.fonts.heading,
        fontWeight: 700,
    },
}))
export const StyledInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: '#B5B9BA',
    fontWeight: 500,
})
export const StyledFeatureSection = styled('section')<{
    isEntitled?: boolean
}>(({ isEntitled }) => ({
    padding: calcRem(24),
    position: 'relative',
    background:
        'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #2B2D2E 100%),linear-gradient(0deg, rgba(37, 55, 225, 0.37) 0%, rgba(211, 0, 195, 0.37) 96.95%)',
    ':before, :after': {
        content: `''`,
        height: '1px',
        background: '#74787A',
        width: '100%',
        left: 0,
        top: 0,
        display: 'inline-block',
        position: 'absolute',
    },
    ':after': {
        top: 'unset',
        bottom: 0,
    },
    '.block-heading-font': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        opacity: isEntitled ? '1' : '0.4',
    },
}))

export const StyledPremiumFeatureItem = styled('li')<{
    visible: boolean
    active: boolean
}>(({ visible, active }) => ({
    display: visible ? 'block' : 'none',
    a: {
        opacity: active ? '1' : '0.4',
        pointerEvents: active ? 'unset' : 'none',
        ':hover': {
            textDecoration: active ? 'underline' : 'none',
        },
    },
}))

export const StyledFeatureTitleWrap = styled('section')({
    marginBottom: calcRem(21),
    display: 'flex',
    span: {
        marginLeft: calcRem(8),
    },
})

export const StyledList = styled('ul')<{ isEntitled?: boolean }>({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    li: {
        lineHeight: calcRem(16),
        marginBottom: calcRem(32),
        '&:last-child': {
            marginBottom: 0,
        },
        'a:hover': {
            textDecoration: 'underline',
        },
    },
})

export const StyledWebLink = styled(WebLink)({
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 500,
    display: 'block',
    marginTop: calcRem(24),
})

export const StyledActionSection = styled('section')({
    padding: calcRem(0, 24),
})

export const StyledLogoutButton = styled('button')({
    background: 'none',
    color: 'inherit',
    border: 'none',
    fontWeight: 500,
    cursor: 'pointer',
    padding: 0,
    ':hover': {
        textDecoration: 'underline',
    },
})

export const StyledDesktopIcon = styled('div')({
    display: 'none',
    [breakpoint('md')]: {
        display: 'block',
    },
})

export const StyledMobileIcon = styled('div')({
    display: 'block',
    height: calcRem(24),
    [breakpoint('md')]: {
        display: 'none',
    },
})

export const StyledSubscribeCTA = styled('button')({
    border: '1px solid white',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '2px',
    fontWeight: 'bold',
    fontSize: calcRem(13),
    textTransform: 'uppercase',
    color: 'white',
    width: '100%',
    padding: calcRem(8, 0),
    marginBottom: calcRem(16),
    transition: 'all .1s ease',
    textDecoration: 'none',
    cursor: 'pointer',
    ':hover': {
        border: `1px solid ${dark}`,
        color: dark,
        background: 'white',
    },
})
