import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../__styling'

export const StyledAccordionBodyContainer = styled('div')<{
    isOpen: boolean
    hidePadding: boolean
}>(({ theme, isOpen, hidePadding }) => ({
    background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
    padding: hidePadding
        ? undefined
        : calcRem(0, theme.margins.xs, theme.margins.xs, theme.margins.xs),
    marginTop: `-${calcRem(theme.margins.xs)}`,
    maxHeight: isOpen ? '1200px' : '0',
    transition: `all 0.2s ease-in-out`,
}))
export const StyledAccordionBody = styled('div')<{ isOpen: boolean }>(
    ({ theme, isOpen }) => ({
        backgroundColor: isOpen ? theme.colors.palette.white : 'transparent',
    }),
)

export const StyledAccordionInnerBody = styled('div')<{ isOpen: boolean }>(
    ({ theme, isOpen }) => ({
        padding: calcRem(
            theme.margins.md * 2,
            theme.margins.md,
            theme.margins.md + theme.margins.xs,
            theme.margins.md,
        ),
        '& > div': {
            transition: `opacity ${isOpen ? '0.2s' : '0s'} ease-in-out`,
            transitionDelay: isOpen ? '0.2s' : '0s',
            opacity: isOpen ? '1' : '0',
        },
    }),
)

export const StyledAccordionWidgetsContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '3fr',
    gridGap: theme.margins.lg,
    [breakpoint('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
    },
}))

export const StyledWidgetsLabelContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.margins.md,
}))

export const StyledHorseshoeContainer = styled('div')(({ theme }) => ({
    '& > div': {
        '& > div': {
            '& > p': {
                fontSize: calcRem(40),
            },
            '& > h3': {
                fontSize: calcRem(18),
            },
        },
    },
    [breakpoint('xs')]: {
        width: '75%',
        margin: `${calcRem(22)} auto auto auto`,
    },
    [breakpoint('sm')]: {
        margin: `${calcRem(6)} auto auto auto`,
        width: '100%',
        borderRight: `1px solid ${theme.colors.borders.primary}`,
        paddingRight: theme.margins.lg,
    },
}))

export const StyledVotesCountedContainer = styled('div')<{
    isFullWidth: boolean
}>(({ theme, isFullWidth }) => ({
    width: '294px',
    margin: 'auto',
    [breakpoint('sm')]: {
        marginLeft: 'auto',
    },
    [breakpoint('lg')]: isFullWidth && {
        marginTop: calcRem(theme.margins.md),
    },
}))

export const StyledTwoWidgetsContainer = styled('div')(({ theme }) => ({
    display: 'grid',

    [breakpoint('sm')]: {
        marginLeft: theme.margins.lg,
    },
}))

export const StyledVotesCountedWidget = styled('div')<{ isFullWidth: boolean }>(
    ({ theme, isFullWidth }) => ({
        marginTop: theme.margins.md * 2,
        '& > div': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            marginTop: 0,
            [breakpoint('sm')]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: 'repeat(2, 1fr)',
                marginTop: 'auto',
            },
            [breakpoint('md')]: isFullWidth && {
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: '1fr',
            },
            'div:nth-child(1)': {
                gridColumn: '1/3',
                gridRow: '1/3',
            },
            'div:nth-child(2)': {
                gridColumn: '2/4',
                gridRow: '1/3',
                [breakpoint('md')]: isFullWidth && {
                    gridRow: '1/2',
                },
            },
        },
    }),
)

export const StyledHorseShoeWidgetTextOuterContainer = styled('div')(
    ({ theme }) => ({
        background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        fontFamily: theme.fonts.sansSerif,
        paddingBottom: calcRem(2),
        marginBottom: calcRem(18),
    }),
)

export const StyledHorseShoeWidgetTextInnerContainer = styled('div')(
    ({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.colors.palette.white,

        'span:nth-of-type(1)': {
            fontWeight: 400,
        },
    }),
)
