import styled from '@emotion/styled'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '@news-mono/component-library'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../../../__styling'

export const StyledLatestLiveBlogContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    margin: calcRem(16, 0),
    padding: calcRem(16),
    background: tokens.thenightly.colors.palette.neutral[10],
    borderRadius: calcRem(12),

    [breakpoint('sm')]: {
        padding: calcRem(16, 24),
    },
})

export const StyledPostTime = styled.div(({ theme }) => [
    {
        color: tokens.thenightly.colors.palette.neutral[70],
        marginBottom: calcRem(16),
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-primary-large'),
])

export const StyledTitled = styled.time(({ theme }) => [
    {
        marginBottom: calcRem(8),
    },
    textMixin<TheNightlyThemeConfig>(theme, 'subheading'),
])

export const StyledContent = styled.div(({ theme }) => [
    {
        color: tokens.thenightly.colors.palette.neutral[70],
    },
    textMixin<TheNightlyThemeConfig>(theme, 'body-main'),
])
