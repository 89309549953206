import { tokens } from '@news-mono/design-tokens'
import {
    BreakpointState,
    ThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
} from '../../__styling'
import { ActiveState } from './TagLinkNavigation.styled'

export type TagLinkNavigationTheme<T extends ThemeConfig> = {
    tagLink: BreakpointState<
        T,
        Record<
            ActiveState,
            {
                textColor: string
                background: string
            }
        > & {
            textType: T['textType']
        }
    >
    divider: {
        color?: string
    }
}

const { neutral } = tokens.thenightly.colors.palette

export const theNightlyTagLinkNavigationTheme = (
    scheme: TheNightlyTheme,
): TagLinkNavigationTheme<TheNightlyThemeConfig> => ({
    tagLink: {
        default: {
            textType: 'body-alt',
            active: {
                textColor: scheme.colors.palette.white,
                background: scheme.sectionValues.primaryColor,
            },
            default: {
                textColor: neutral[70],
                background: 'unset',
            },
        },
        lg: {
            textType: 'menu-item',
            active: {
                textColor: scheme.sectionValues.primaryColor,
                background: 'unset',
            },
            default: {
                textColor: scheme.colors.text.primary,
                background: 'unset',
            },
        },
    },
    divider: {
        color: scheme.colors.borders.secondary,
    },
})
