import React from 'react'
import { ThemedRender } from '../../__styling'
import { AmuseLabsIframeProps } from './components/AmuseLabsIframe'
import { PuzzleIframe as TheWestPuzzleIframe } from './TheWest/PuzzleIframe'
import {
    PuzzleIframe as TheNightlyPuzzleIframe,
    PuzzleIframeProps as TheNightlyPuzzleIframeProps,
} from './TheNightly/PuzzleIframe'

type PuzzleIframeProps = AmuseLabsIframeProps | TheNightlyPuzzleIframeProps

export const PuzzleIframe = (props: PuzzleIframeProps) => {
    return (
        <ThemedRender
            thewest={() => <TheWestPuzzleIframe {...props} />}
            thenightly={() =>
                'title' in props ? <TheNightlyPuzzleIframe {...props} /> : null
            }
        />
    )
}
