import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import crypto from 'crypto-js'
import { get as getCookie, set as setCookie } from 'js-cookie'
import {
    BeginLoadingEvent,
    EndLoadingEvent,
} from 'react-ssr-data-loader/dist/cjs/events'
import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Logger } from 'typescript-log'
import { debugAuth, debugSwg } from '../../authentication/debug'
import {
    AccessTokenState,
    retrieveAccessToken,
    stopGoogleAccountLinking,
    stopGoogleAutoLogin,
} from '../../authentication/helpers'
import { RequiredAccess } from '../../client-data-types'
import { BaseClientConfig } from '../../client/BaseClientConfig'
import { THEWEST_ALL } from '../../entitlements'
import { isServerEnvironment } from '../../environment'
import { BreachScreenType } from '../../events'
import {
    ExtendedAccessEvent,
    SubscribeWithGoogleEvent,
} from '../../events/extended-access-events'
import { AddToCartEvent } from '../../events/subscribeEventTypes'
import {
    LoginEvent,
    LoginRefreshEvent,
    LogoutEvent,
    NetworkErrorEvent,
    SessionExpiredEvent,
    UserAuthEvents,
} from '../../events/user-event-types'
import { DataLayerEventName } from '../../helpers/DataLayerEventName'
import { AppState } from '../../store'
import { fetchGoogleAccessToken } from '../google/account-linking'
import { deferredAccountCreation } from '../google/deferred-account-creation'
import { SwGInitialisedPromise } from '../google/extended-access'
import {
    REQUEST_SWG_ENTITLEMENTS,
    REQUEST_SWG_ENTITLEMENTS_ACTION,
    SWG_ENTITLEMENTS_VERIFIED,
    triggerEA,
    UPDATE_SWG_ENTITLEMENTS_VERIFIED_ACTION,
    UPDATE_USER_SWG_STATE_ACTION,
    USER_SWG_STATE,
} from '../google/reducer'
import { generateSwGPromise, initiateAutoLogin } from '../google/subscriptions'
import {
    entitlementsResponseType,
    GoogleState,
    SwgEntitlementPublisher,
} from '../google/swg-api'
import { checkAccessTokenAndRefresh } from './auth-methods'

export const MAX_ANON_USER_IDENTIFIER = 0xffff_ffff
export const ANON_USER_ID_STORAGE_KEY = 'auth-anonymous-user-identifier'

export type SubscriptionType =
    | 'none'
    | 'subscriber'
    | 'corporate'
    | 'corporate-site'
    | 'corporate-school'
    | 'swg'

export type GoogleLoginStatus =
    | 'relinking'
    | 'intervention'
    | 'linking'
    | 'complete'

export interface SubscribeClickOverrides {
    offerCode?: string
    packagePath?: string
    callToAction?: string
    breachScreenType?: BreachScreenType
}

export const reducerDebug = debugAuth.extend('reducer')
export interface GoogleUserState {
    metering: {
        state: {
            id: string
            standardAttributes: {
                registered_user: {
                    timestamp: number
                }
            }
        }
    }
}

export interface EntitledStatus {
    isEntitled?: boolean
    isEntitledChecked?: boolean
    requiredAccess?: RequiredAccess | undefined
    articleSlug?: string | undefined
}

export interface AuthenticationState {
    isLoggedIn: boolean
    userName: string
    hasUserName?: boolean
    firstName?: string
    lastName?: string
    loginProvider: string
    socialProviders: string
    wanUserId: string
    auth0UserId?: string
    occupantId?: string
    entitlements: string[]
    hasAttemptedValidation: boolean
    isLoading: boolean
    hasSignedupToNewsletter?: boolean
    hasOptedInNewsletter?: boolean
    /** Bypass the paywall for a specific URL. Googlebot, MMOs, FB News etc */
    bypassSlug?: string
    sessionExpired?: boolean
    networkError?: boolean
    checkingTokenState: boolean
    lastTokenValidation?: string
    subscriptionType: SubscriptionType
    subscriptionId?: string
    googleLogin?: GoogleLoginStatus
    googleUserState?: GoogleUserState
    coralToken?: string
    hashedSophiUserID?: string
    hashedLiveRampEmail?: string
    hashedUserEmail?: string
    isEntitled?: boolean
    isEntitledChecked?: boolean
    requiredAccess?: RequiredAccess
    articleSlug?: string | undefined
    userEmail?: string
    googleLinkedPublications?: string[]
    emailToken?: string
    anonymousUserIdentifier?: number
    registrationTimestamp?: number
    emailVerified?: boolean
}

interface AuthenticationPayload
    extends Omit<
        AuthenticationState,
        'hasAttemptedValidation' | 'checkingTokenState'
    > {}

export const AuthenticationActions = {
    LOGGED_IN: '@@AUTH/LOGGED_IN',
    EMAIL_VERIFIED: '@@AUTH/EMAIL_VERIFIED',
    LOGIN_REFRESHED: '@@AUTH/LOGIN_REFRESHED',
    LOGGED_OUT: '@@AUTH/LOGGED_OUT',
    AUTH_IS_LOADING: '@@AUTH/AUTH_IS_LOADING',
    AUTH_HAS_SIGNEDUP_NEWSLETTER: '@@AUTH/AUTH_HAS_SIGNEDUP_NEWSLETTER',
    AUTH_HAS_OPTED_IN_NEWSLETTER: '@@AUTH/AUTH_HAS_OPTED_IN_NEWSLETTER',
    ENTITLEMENTS_CHECKED: '@@AUTH/ENTITLEMENTS_CHECKED',
    PAYWALL_BYPASS: '@@AUTH/PAYWALL_BYPASS',
    SESSION_EXPIRED: '@@AUTH/SESSION_EXPIRED',
    NETWORK_ERROR: '@@AUTH/NETWORK_ERROR',
    CHECKING_ACCESS_TOKEN_EXPIRY: '@@AUTH/CHECKING_ACCESS_TOKEN_EXPIRY',
    CHECK_ACCESS_TOKEN_EXPIRY: '@@AUTH/CHECK_ACCESS_TOKEN_EXPIRY',
    AUTH_GRANT_ENTITLEMENTS: '@@AUTH/AUTH_GRANT_ENTITLEMENTS',
    GOOGLE_LOGIN: '@@AUTH/GOOGLE_LOGIN',
    GOOGLE_EXTENDED_ACCESS: '@@AUTH/GOOGLE_EXTENDED_ACCESS',
    IS_ENTITLED_CHECK: '@@AUTH/IS_ENTITLED_CHECK',
    EMAIL_TOKEN: '@@AUTH/EMAIL_TOKEN',
} as const
export type AuthenticationActions = typeof AuthenticationActions

export interface AUTH_LOGGED_IN extends Action {
    type: AuthenticationActions['LOGGED_IN']
    payload: AuthenticationPayload
}
export interface AUTH_LOGIN_REFRESHED extends Action {
    type: AuthenticationActions['LOGIN_REFRESHED']
    payload: AuthenticationPayload
}

export interface AUTH_LOGGED_OUT extends Action {
    type: AuthenticationActions['LOGGED_OUT']
}

interface PaywallBypassActionPayload {
    bypassSlug: string
    reason: string
}

interface GrantEntitlementsActionPayload {
    entitlements: string[]
}

// The status to see if Auth actions are finished to a certain extent
export interface AUTH_IS_LOADING extends Action {
    type: AuthenticationActions['AUTH_IS_LOADING']
    payload: { isLoading: boolean }
}
export interface AUTH_HAS_SIGNEDUP_NEWSLETTER extends Action {
    type: AuthenticationActions['AUTH_HAS_SIGNEDUP_NEWSLETTER']
    payload: { hasSignedupToNewsletter: boolean }
}

export interface AUTH_HAS_OPTED_IN_NEWSLETTER extends Action {
    type: AuthenticationActions['AUTH_HAS_OPTED_IN_NEWSLETTER']
    payload: { hasOptedInNewsletter: boolean }
}

export interface AUTH_GRANT_ENTITLEMENTS extends Action {
    type: AuthenticationActions['AUTH_GRANT_ENTITLEMENTS']
    payload: GrantEntitlementsActionPayload
}

export interface AUTH_PAYWALL_BYPASS extends Action {
    type: AuthenticationActions['PAYWALL_BYPASS']
    payload: PaywallBypassActionPayload
}
export interface AUTH_ENTITLEMENTS_CHECKED extends Action {
    type: AuthenticationActions['ENTITLEMENTS_CHECKED']
}

export interface AUTH_SESSION_EXPIRED extends Action {
    type: AuthenticationActions['SESSION_EXPIRED']
}

export interface AUTH_NETWORK_ERROR extends Action {
    type: AuthenticationActions['NETWORK_ERROR']
}

export interface AUTH_CHECKING_ACCESS_TOKEN_EXPIRY extends Action {
    type: AuthenticationActions['CHECKING_ACCESS_TOKEN_EXPIRY']
}

export interface AUTH_CHECK_ACCESS_TOKEN_EXPIRY extends Action {
    type: AuthenticationActions['CHECK_ACCESS_TOKEN_EXPIRY']
}

export interface AUTH_WEB_CHECK_ACCESS_TOKEN_EXPIRY_AND_REFRESH extends Action {
    type: AuthenticationActions['CHECK_ACCESS_TOKEN_EXPIRY']
}

export interface AUTH_GOOGLE_LOGIN extends Action {
    type: AuthenticationActions['GOOGLE_LOGIN']
    payload: {
        googleLogin: GoogleLoginStatus
        googleUserState?: GoogleUserState
    }
}
export interface AUTH_GOOGLE_EXTENDED_ACCESS extends Action {
    type: AuthenticationActions['GOOGLE_EXTENDED_ACCESS']
}

export interface AUTH_IS_ENTITLED_CHECK extends Action {
    type: AuthenticationActions['IS_ENTITLED_CHECK']
    payload: EntitledStatus
}
export interface AUTH_EMAIL_TOKEN extends Action {
    type: AuthenticationActions['EMAIL_TOKEN']
    payload: {
        emailToken: string
    }
}

export interface AUTH_EMAIL_VERIFIED extends Action {
    type: AuthenticationActions['EMAIL_VERIFIED']
    payload: {
        emailVerified: boolean
    }
}

export type AUTH_LOGIN_THUNK = ThunkAction<void, any, any, AUTH_LOGGED_IN>
export type AUTH_LOGGED_OUT_THUNK = ThunkAction<void, any, any, AUTH_LOGGED_OUT>
export type AUTH_LOGIN_REFRESHED_THUNK = ThunkAction<
    void,
    any,
    any,
    AUTH_LOGIN_REFRESHED
>
export type AUTH_SESSION_EXPIRED_THUNK = ThunkAction<
    void,
    any,
    any,
    AUTH_SESSION_EXPIRED
>
export type AUTH_NETWORK_ERROR_THUNK = ThunkAction<
    void,
    any,
    any,
    AUTH_NETWORK_ERROR
>
export type AUTH_CHECK_ACCESS_TOKEN_EXPIRY_THUNK = ThunkAction<
    Promise<void>,
    AppState,
    any,
    | AUTH_CHECKING_ACCESS_TOKEN_EXPIRY
    | AUTH_CHECK_ACCESS_TOKEN_EXPIRY
    | AUTH_IS_ENTITLED_CHECK
    | UPDATE_SWG_ENTITLEMENTS_VERIFIED_ACTION
    | REQUEST_SWG_ENTITLEMENTS_ACTION
    | UPDATE_USER_SWG_STATE_ACTION
>

export type AUTH_CHECK_EXTENDED_ACCESS_THUNK = ThunkAction<
    void,
    AppState,
    any,
    AUTH_PAYWALL_BYPASS
>

export type AUTH_IS_ENTTILED_CHECK_THUNK = ThunkAction<
    void,
    any,
    any,
    AUTH_IS_ENTITLED_CHECK
>

type AuthAction =
    | AUTH_ENTITLEMENTS_CHECKED
    | AUTH_GRANT_ENTITLEMENTS
    | AUTH_PAYWALL_BYPASS
    | AUTH_LOGGED_IN
    | AUTH_LOGGED_OUT
    | AUTH_LOGIN_REFRESHED
    | AUTH_SESSION_EXPIRED
    | AUTH_NETWORK_ERROR
    | AUTH_CHECKING_ACCESS_TOKEN_EXPIRY
    | AUTH_CHECK_ACCESS_TOKEN_EXPIRY
    | AUTH_GOOGLE_LOGIN
    | AUTH_GOOGLE_EXTENDED_ACCESS
    | AUTH_IS_ENTITLED_CHECK
    | AUTH_EMAIL_TOKEN
    | AUTH_EMAIL_VERIFIED
    | AUTH_IS_LOADING
    | AUTH_HAS_SIGNEDUP_NEWSLETTER
    | AUTH_HAS_OPTED_IN_NEWSLETTER

export type LoginDetails = Omit<
    AuthenticationState,
    | 'isLoggedIn'
    | 'isLoading'
    | 'hasAttemptedValidation'
    | 'isMMOReferrer'
    | 'checkingTokenState'
>

export const authLogin = (
    details: LoginDetails & {
        onEvent: (loginEvent: LoginEvent) => void
    },
): AUTH_LOGIN_THUNK => {
    return (dispatch) => {
        const { onEvent, ...args } = details
        onEvent({
            originator: 'authenticator-reducer',
            type: DataLayerEventName.authLogin,
            payload: args,
        })

        reducerDebug('LOGGED_IN')
        return dispatch({
            type: AuthenticationActions.LOGGED_IN,
            payload: {
                isLoggedIn: true,
                isLoading: false,
                ...args,
            },
        })
    }
}

export const authRefreshLogin = (
    details: LoginDetails & {
        onEvent: (loginEvent: LoginRefreshEvent) => void
    },
): AUTH_LOGIN_REFRESHED_THUNK => {
    return (dispatch) => {
        const { onEvent, ...args } = details
        onEvent({
            originator: 'authenticator-reducer',
            type: DataLayerEventName.authLoginRefresh,
            payload: args,
        })

        reducerDebug('LOGIN_REFRESHED')
        return dispatch({
            type: AuthenticationActions.LOGIN_REFRESHED,
            payload: {
                isLoggedIn: true,
                isLoading: false,
                ...args,
            },
        })
    }
}

export const authLogout = (options: {
    onEvent: (logoutEvent: LogoutEvent) => void
}): AUTH_LOGGED_OUT_THUNK => {
    return (dispatch) => {
        options.onEvent({
            originator: 'authenticator-reducer',
            type: DataLayerEventName.authLogout,
            payload: {},
        })

        reducerDebug('LOGGED_OUT')
        return dispatch({
            type: AuthenticationActions.LOGGED_OUT,
        })
    }
}

export const authEntitlementsChecked = (): AUTH_ENTITLEMENTS_CHECKED => {
    return {
        type: AuthenticationActions.ENTITLEMENTS_CHECKED,
    }
}

export const authPaywallBypass = (
    args: PaywallBypassActionPayload,
): AUTH_PAYWALL_BYPASS => {
    return {
        type: AuthenticationActions.PAYWALL_BYPASS,
        payload: {
            ...args,
        },
    }
}
export const authGrantEntitlements = (
    args: GrantEntitlementsActionPayload,
): AUTH_GRANT_ENTITLEMENTS => {
    return {
        type: AuthenticationActions.AUTH_GRANT_ENTITLEMENTS,
        payload: {
            ...args,
        },
    }
}

export const authEmailToken = (args: {
    emailToken: string
}): AUTH_EMAIL_TOKEN => {
    return {
        type: AuthenticationActions.EMAIL_TOKEN,
        payload: {
            ...args,
        },
    }
}

export const authNetworkError = (options: {
    onEvent: (networkErrorEvent: NetworkErrorEvent) => void
}): AUTH_NETWORK_ERROR_THUNK => {
    return (dispatch) => {
        options.onEvent({
            originator: 'authenticator-reducer',
            type: DataLayerEventName.authNetworkError,
            payload: {},
        })
        reducerDebug('NETWORK_ERROR')
        return dispatch({
            type: AuthenticationActions.NETWORK_ERROR,
        })
    }
}

export const authIsLoadingState = (args: boolean): AUTH_IS_LOADING => {
    return {
        type: AuthenticationActions.AUTH_IS_LOADING,
        payload: {
            isLoading: args,
        },
    }
}

export const authHasSignedUpNewsletterState = (
    args: boolean,
): AUTH_HAS_SIGNEDUP_NEWSLETTER => {
    debugAuth({ args }, 'dispatching signedUpToNewsletter update')
    return {
        type: AuthenticationActions.AUTH_HAS_SIGNEDUP_NEWSLETTER,
        payload: {
            hasSignedupToNewsletter: args,
        },
    }
}

export const authHasOptedInNewsletterState = (
    args: boolean,
): AUTH_HAS_OPTED_IN_NEWSLETTER => {
    debugAuth({ args }, 'dispatching optedInNewsletter update')
    return {
        type: AuthenticationActions.AUTH_HAS_OPTED_IN_NEWSLETTER,
        payload: {
            hasOptedInNewsletter: args,
        },
    }
}

export const authSessionExpired = (options: {
    onEvent: (sessionExpiredEvent: SessionExpiredEvent) => void
}): AUTH_SESSION_EXPIRED_THUNK => {
    return (dispatch) => {
        options.onEvent({
            originator: 'authenticator-reducer',
            type: DataLayerEventName.authSessionExpired,
            payload: {},
        })
        reducerDebug('SESSION_EXPIRED')
        return dispatch({
            type: AuthenticationActions.SESSION_EXPIRED,
        })
    }
}

export interface SmediaCookieFormat {
    accessToken: string
}

export const authCheckAccessToken = (options: {
    onEvent: (
        sessionExpiredEvent:
            | UserAuthEvents
            | ExtendedAccessEvent
            | SubscribeWithGoogleEvent
            | AddToCartEvent,
    ) => void
    config: BaseClientConfig
    log: Logger
    invocation: 'manual' | 'auto'
    forceRefresh?: boolean
    onInvocationEvent: (
        sessionExpiredEvent: BeginLoadingEvent | EndLoadingEvent,
    ) => void
}): AUTH_CHECK_ACCESS_TOKEN_EXPIRY_THUNK => {
    return async (dispatch, getState) => {
        const state = getState()

        const { auth0 } = options.config

        let isTheNightly = undefined

        // Check if The Nightly as we do not want EA triggered
        if (auth0) {
            isTheNightly = auth0.issuer.includes('thenightly')
        }

        if (state.authentication.checkingTokenState) {
            reducerDebug('Skipping token check, in progress')
            return
        }
        reducerDebug('CHECKING_ACCESS_TOKEN_EXPIRY')

        dispatch({
            type: AuthenticationActions.CHECKING_ACCESS_TOKEN_EXPIRY,
        })

        // Given that this is called on every single route change - I think it's safe to reset the entitled check here.
        dispatch({
            type: AuthenticationActions.IS_ENTITLED_CHECK,
            payload: {
                isEntitledChecked: false,
            },
        })

        const { onEvent, onInvocationEvent, invocation, forceRefresh } = options
        const { renditionType } = state.render

        const accessTokenState = retrieveAccessToken()

        await checkAccessTokenAndRefresh({
            renditionType,
            accessTokenState,
            dispatch,
            authState: state.authentication,
            onEvent,
            options: {
                invocation,
                autoRefreshToken: renditionType !== 'app',
            },
            swgState: state.SwG,
            toggleState: state.toggles,
            forceRefresh,
            onInvocationEvent,
        })

        reducerDebug('CHECK_ACCESS_TOKEN_EXPIRY')

        dispatch({
            type: AuthenticationActions.CHECK_ACCESS_TOKEN_EXPIRY,
        })

        if (isTheNightly) {
            reducerDebug('THE NIGHTLY')
            return
        }

        // Retrieve the updated authentication object and check to see if the user is logged in - this will eventually be where the SWG Entitlement check is called
        let authState = getState().authentication
        await triggerEA(dispatch, getState, options.config, onEvent)

        try {
            if (
                authState.hasAttemptedValidation &&
                !authState.checkingTokenState &&
                renditionType !== 'app' &&
                renditionType !== 'amp' &&
                SwGInitialisedPromise
            ) {
                authState = doSwgThings(
                    dispatch,
                    getState,
                    authState,
                    options,
                    accessTokenState,
                )
            }
        } catch (e) {
            debugSwg('Error in SwG/EA Entitlement Checks', { e })
        }
    }
}

function doSwgThings(
    dispatch: Dispatch,
    getState: () => AppState,
    authState: AuthenticationState,
    options: {
        onEvent: (
            sessionExpiredEvent:
                | UserAuthEvents
                | ExtendedAccessEvent
                | SubscribeWithGoogleEvent
                | AddToCartEvent,
        ) => void
        config: BaseClientConfig
        log: Logger
        invocation: 'manual' | 'auto'
    },
    accessTokenState: AccessTokenState | undefined,
) {
    SwGInitialisedPromise?.then(async () => {
        await triggerEA(dispatch, getState, options.config, options.onEvent)

        // We need to update authState here as EA may have logged the user in.
        authState = getState().authentication

        const subscribeWithGoogleEnabled = isFeatureEnabled(
            toFeatureState(getState().toggles),
            'subscribe-with-google',
        )
        const isEntitlementCheckEnabled =
            isFeatureEnabled(
                toFeatureState(getState().toggles),
                'swg-entitlements-check',
            ) && subscribeWithGoogleEnabled

        const isAccountLinkingEnabled =
            isFeatureEnabled(
                toFeatureState(getState().toggles),
                'swg-account-linking',
            ) && subscribeWithGoogleEnabled

        debugSwg('Swg Entitlement Check Status', {
            isEntitlementCheckEnabled,
        })

        // We know for certain that SWG is now initialised
        // Now we need to get the user's entitlements from the SwG Library
        const { subscriptions, entitlementsPromise } = getState().SwG

        if (!subscriptions || !entitlementsPromise) {
            debugSwg(
                'SwG was not initialised despite promise being fulfilled.',
                {
                    authenticationState: getState().authentication,
                },
            )
            return
        }

        // Get updated user entitlements if they haven't already been requested
        if (!getState().SwG.entitlementsRequested) {
            debugSwg('Fetching entitlements for SwG')
            subscriptions.getEntitlements(
                getState().authentication.googleUserState,
            )

            dispatch({
                type: REQUEST_SWG_ENTITLEMENTS,
                payload: {
                    requested: true,
                },
            })
        }

        const entitlements = await entitlementsPromise

        // We need to check the claim array for the apex domain
        const apex = window.location.hostname.replace(/^www\./, '')

        const swgState: GoogleState = {
            googleEntitlement: entitlements.entitlements.some(
                (value) => value.source === 'google',
            ),
            googleLinked: entitlements.entitlements.some(
                (value) => value.source === apex,
            ),
            isCrawler: entitlements.entitlements.some(
                (value) => value.source === 'privileged',
            ),
            publisherLoggedIn: authState.isLoggedIn,
            publisherEntitlement: authState.entitlements.includes(THEWEST_ALL),
        }
        // Store it in redux so we can access it in other places
        dispatch({
            type: USER_SWG_STATE,
            payload: swgState,
        })

        debugSwg('Checking SwG Entitlements', {
            swgState,
            entitlements,
        })

        if (isEntitlementCheckEnabled && !getCookie(stopGoogleAutoLogin)) {
            debugSwg('Entitlements verified. Starting Auto Login Flow')

            if (
                !swgState.publisherLoggedIn &&
                entitlements.entitlements.length > 0
            ) {
                // Filtering out google:metering
                const filteredEntitlements = entitlements.entitlements.filter(
                    (entitlement) => entitlement.source !== 'google:metering',
                )

                if (filteredEntitlements.length === 0) {
                    debugSwg(
                        'User has no relevant entitlements(publisher/google) or is logged in, swg will not be triggered',
                    )
                } else {
                    // Check if they are registered / linked to google account
                    const entitlementsResponse: Promise<
                        | entitlementsResponseType
                        | SwgEntitlementPublisher
                        | undefined
                    > = generateSwGPromise(
                        swgState.googleLinked,
                        options.config.auth?.issuer,
                        entitlements,
                        options.log,
                    )

                    await subscriptions
                        .waitForSubscriptionLookup(entitlementsResponse)
                        .then(
                            async (
                                accounts:
                                    | entitlementsResponseType
                                    | SwgEntitlementPublisher
                                    | undefined,
                            ) => {
                                // We know that if it has reached that point, that the entitlements can be trusted.
                                dispatch({
                                    type: SWG_ENTITLEMENTS_VERIFIED,
                                    payload: {
                                        isVerified: true,
                                    },
                                })

                                if (!swgState.isCrawler) {
                                    // If we don't recongise the subscriptionToken, the initiate the Deferred Account Creation Flow
                                    if (!accounts) {
                                        await deferredAccountCreation(
                                            options.config.auth!.issuer,
                                            subscriptions,
                                            entitlements,
                                            authState,
                                        )
                                    } else {
                                        await initiateAutoLogin(
                                            subscriptions,
                                            accounts,
                                            options.log,
                                        )
                                    }
                                }
                            },
                        )
                }
            } else {
                debugSwg(
                    'User has no entitlements(publisher/google) or is logged in, swg will not be triggered',
                )
            }
        } else {
            debugSwg(
                'Stop Google Auto Login Cookie is in effect or Entitlements Check is not active',
            )
        }

        debugSwg("Checking to see if we should link the user's account", {
            subscribeWithGoogleEnabled,
            isLoggedIn: authState.isLoggedIn,
            cookieValue: getCookie(stopGoogleAccountLinking),
            featureValue: isAccountLinkingEnabled,
        })

        // Do the account linking prompt here.
        // If the user hasn't already linked their account, the subscriptions object is initialised and they do not have a google entitlement
        // The reason why we pass back the linked publication state to the frontend from the Auth Site and we do not check the googleEntitlements
        // is because 3rd party cookies could be blocked and the entitlements would be empty as a result.
        if (
            subscribeWithGoogleEnabled &&
            authState.isLoggedIn &&
            swgState.publisherEntitlement &&
            !swgState.googleEntitlement &&
            !swgState.googleLinked &&
            !getCookie(stopGoogleAccountLinking) &&
            isAccountLinkingEnabled
        ) {
            debugSwg('Starting Account link')
            // Pass the user's google entitlements access token to Google here.
            subscriptions
                .saveSubscription(
                    async () =>
                        await fetchGoogleAccessToken(
                            accessTokenState!.accessToken,
                            options.config.auth!.issuer,
                        ),
                )
                .then((result) => {
                    if (result) {
                        // If the user successfully linked their WAN account to google - we don't need to do much here since it'll be updated in the user's metadata the next time Google calls the EntitlementAPI
                        debugSwg('Account linking succeeded')
                    } else {
                        // Account linking failed or the user chose not to link
                        debugSwg('Account linking failed')
                        setCookie(stopGoogleAccountLinking, 'true', {
                            expires: 90, //days
                        })
                    }
                })
        }
    })
    return authState
}

export function checkExtendedAccessMetering(options: {
    articleSlug: string
}): AUTH_CHECK_EXTENDED_ACCESS_THUNK {
    return (dispatch, _getState) => {
        reducerDebug('PAYWALL_BYPASS')
        dispatch({
            type: AuthenticationActions.PAYWALL_BYPASS,
            payload: {
                reason: 'Extended Access Metering',
                bypassSlug: options.articleSlug,
            },
        })
    }
}

export function isEntitledCheck({
    isEntitled,
    requiredAccess,
    articleSlug,
}: Omit<EntitledStatus, 'isEntitledChecked'>): AUTH_IS_ENTTILED_CHECK_THUNK {
    return (dispatch, _getState) => {
        // We essentially need to store two things..., whether the entitled check has occured and whether the user is entitled to view the content.
        reducerDebug('ENTITLED_CHECK')
        dispatch({
            type: AuthenticationActions.IS_ENTITLED_CHECK,
            payload: {
                isEntitled,
                requiredAccess,
                articleSlug,
                isEntitledChecked: true,
            },
        })
    }
}

function toUnsignedInt(number: number) {
    return number >>> 0
}

function createAndStoreAnonymousUserIdentifier() {
    if (isServerEnvironment()) {
        return undefined
    }
    const hash = crypto?.MD5(navigator?.userAgent)
    const first32Bits = hash.words[0]
    const anonymousUserIdentifier = toUnsignedInt(first32Bits)
    global?.localStorage?.setItem(
        ANON_USER_ID_STORAGE_KEY,
        anonymousUserIdentifier.toString(16),
    )
    return anonymousUserIdentifier
}

export const getStoredAnonymousUserId = () =>
    global?.localStorage?.getItem(ANON_USER_ID_STORAGE_KEY)
export function getAnonymousUserIdentifier() {
    const storedAnonymousUserId = getStoredAnonymousUserId()
    return storedAnonymousUserId != null
        ? Number.parseInt(storedAnonymousUserId, 16)
        : createAndStoreAnonymousUserIdentifier()
}

export const defaultState: AuthenticationState = {
    isLoggedIn: false,
    checkingTokenState: false,
    loginProvider: '',
    socialProviders: '',
    userName: '',
    wanUserId: '',
    auth0UserId: '',
    occupantId: '',
    entitlements: [],
    subscriptionType: 'none',
    /* Whether validation has been attempted regardless of outcome */
    hasAttemptedValidation: false,
    isLoading: true,
    anonymousUserIdentifier: getAnonymousUserIdentifier(),
    registrationTimestamp: 0,
}

export const authenticationReducer = (
    state = defaultState,
    action:
        | AuthAction
        | { type: '@@INIT' /** This represents all other actions */ },
): AuthenticationState => {
    reducerDebug('Reducing: %o', action)
    switch (action.type) {
        case AuthenticationActions.LOGGED_IN: {
            return { ...state, ...action.payload }
        }
        case AuthenticationActions.LOGIN_REFRESHED: {
            return {
                ...state,
                ...action.payload,
                networkError: false,
            }
        }
        case AuthenticationActions.LOGGED_OUT: {
            return {
                ...state,
                coralToken: undefined,
                isLoggedIn: false,
                entitlements: [],
            }
        }
        case AuthenticationActions.AUTH_GRANT_ENTITLEMENTS: {
            return { ...state, entitlements: action.payload.entitlements }
        }
        case AuthenticationActions.ENTITLEMENTS_CHECKED: {
            return { ...state, hasAttemptedValidation: true }
        }
        case AuthenticationActions.PAYWALL_BYPASS: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case AuthenticationActions.SESSION_EXPIRED: {
            return {
                ...state,
                isLoggedIn: false,
                entitlements: [],
                sessionExpired: true,
                networkError: false,
                hasAttemptedValidation: true,
            }
        }
        case AuthenticationActions.NETWORK_ERROR: {
            return { ...state, networkError: true }
        }
        case AuthenticationActions.CHECKING_ACCESS_TOKEN_EXPIRY: {
            return { ...state, checkingTokenState: true }
        }
        case AuthenticationActions.CHECK_ACCESS_TOKEN_EXPIRY: {
            return {
                ...state,
                lastTokenValidation: new Date().toISOString(),
                checkingTokenState: false,
            }
        }
        case AuthenticationActions.GOOGLE_LOGIN:
        case AuthenticationActions.IS_ENTITLED_CHECK:
        case AuthenticationActions.EMAIL_TOKEN:
        case AuthenticationActions.EMAIL_VERIFIED: {
            return { ...state, ...action.payload }
        }
        case AuthenticationActions.AUTH_IS_LOADING: {
            return { ...state, isLoading: false }
        }
        case AuthenticationActions.AUTH_HAS_SIGNEDUP_NEWSLETTER:
        case AuthenticationActions.AUTH_HAS_OPTED_IN_NEWSLETTER: {
            return { ...state, ...action.payload }
        }
        default:
            return {
                ...state,
                anonymousUserIdentifier: getAnonymousUserIdentifier(),
            }
    }
}
