import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { tokens } from '@news-mono/design-tokens'
import {
    IsHydrating,
    useFeature,
    useToggleState,
    WebLink,
} from '@news-mono/web-common'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import {
    KingsCoronationTitleContainer,
    StyledBackgroundWrap,
    StyledClickthrough,
    StyledClickthroughText,
    StyledCNBCLogo,
    StyledCommonwealthGamesContainer,
    StyledCommonwealthGamesText,
    StyledFeatureBorder,
    StyledFeatureContainer,
    StyledFeatureContainer__Deprecated,
    StyledFeatureHeaderContainer,
    StyledFeatureSectionHeader,
    StyledFeatureSectionHeaderContainer,
    StyledFeatureTitleContainer,
    StyledGoMatildasLogoContainer,
    StyledGradientSectionTitle,
    StyledGradientWrap,
    StyledGrandFinalSectionHeader,
    StyledGrandFinalText,
    StyledGrandFinalWebLink,
    StyledIconArrowCircle,
    StyledTheWest190SmallLogo,
    StyledThor,
    StyledTitleHeader,
} from '../../compositions/Thor/Thor.styled'
import { isMobileViewport } from '../../content/Video/utils/is-mobile-viewport'
import { GoMatildasLogo } from '../../logos/GoMatildasLogo/GoMatildasLogo'
import { RoyHillLogo } from '../../logos/RoyHill/RoyHillLogo'
import { Breadcrumb, LinkProps } from '../../navigation'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { colors } from '../../__styling'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { ThemeOverrideTypes } from '../../__styling/themes'
import { ComponentOverrider } from '../../themes/ThemeOverrider/ComponentOverrider'
import { sponsoredSectionHeaderOverride } from '../../section-header'
import { StyledCircleChevronRight } from '../../section-header/SectionHeader/SectionHeader.styled'

export interface ThorProps {
    mainContent: React.ReactElement<any>
    hasBackgroundFill?: boolean
    /** If true or undefined, will default to the outer margin of the theme */
    horizontalGutters?: boolean | keyof ThemeMargins
    /** If true or undefined, will default to the outer margin of the theme */
    verticalGutters?: boolean | keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    themeOverride?: ThemeOverrideTypes
    hideHeadingLink?: boolean
    // Pollie Rater / Federal Election specific props
    hidePollieRaterBanner?: boolean
    isHeadingH1?: boolean
    // {TODO} Move these from apps/thewest/src/app/routes/home/get-themed-segment.ts and into component-library
    layouts?: '4-card' | '8-card' | '7-card'
    hasBorder?: boolean
    // {TODO} Discuss if this should be retained
    unsetFeatureContainerBackground?: boolean
    hideFeatureHeader?: boolean
    isGenwest?: boolean
}

export const Thor: React.FC<ThorProps> = ({
    mainContent,
    hasBackgroundFill,
    horizontalGutters,
    verticalGutters,
    verticalSpacing,
    containerWidth,
    themeOverride,
    hideHeadingLink,
    hidePollieRaterBanner,
    isHeadingH1,
    layouts,
    hasBorder,
    unsetFeatureContainerBackground,
    hideFeatureHeader,
    isGenwest,
}) => {
    const styledThor = (
        <StyledThor
            hasBackgroundFill={hasBackgroundFill}
            horizontalGutters={horizontalGutters}
            verticalGutters={verticalGutters}
            verticalSpacing={verticalSpacing}
            containerWidth={containerWidth || 1020}
        >
            {mainContent}
        </StyledThor>
    )

    const location = useLocation()
    const isHydrating = useContext(IsHydrating)
    const isMobile = isMobileViewport()

    useEffect(() => {
        if (location.hash && !isHydrating) {
            //hardcoding drop on mobile viewports
            if (isMobile && location.hash === '#cnbc') {
                window.scrollTo({ top: 2100, behavior: 'smooth' })
            } else {
                const yOffset = -20
                const element = document.querySelector(location.hash)
                if (element) {
                    const y =
                        element.getBoundingClientRect().top +
                        window.pageYOffset +
                        yOffset
                    window.scrollTo({ top: y, behavior: 'smooth' })
                }
            }
        }
    }, [location, isHydrating, isMobile])

    const toggles = useToggleState()

    if (themeOverride === 'grand-final') {
        return (
            <ThemeOverrider override={themeOverride}>
                <StyledBackgroundWrap themeOverride={themeOverride}>
                    <StyledFeatureContainer__Deprecated
                        initialFlexDirection={'row'}
                        customPadding={16}
                        hasExtraBottomMargin={true}
                        layout={layouts}
                        unsetFeatureContainerBackground={
                            unsetFeatureContainerBackground
                        }
                    >
                        <StyledTitleHeader>
                            <StyledGrandFinalText>
                                AFL Finals 2022
                            </StyledGrandFinalText>
                        </StyledTitleHeader>
                        {!hideHeadingLink && (
                            <div>
                                <StyledGrandFinalWebLink to={'/sport/afl'}>
                                    <StyledGrandFinalSectionHeader>
                                        More Coverage
                                    </StyledGrandFinalSectionHeader>
                                    <StyledIconArrowCircle />
                                </StyledGrandFinalWebLink>
                            </div>
                        )}
                    </StyledFeatureContainer__Deprecated>
                    {styledThor}
                </StyledBackgroundWrap>
            </ThemeOverrider>
        )
    }

    if (themeOverride === 'cnbc' || themeOverride === 'cnbcEconomist') {
        //We don't want to display anything if we don't have any content.
        if (!mainContent) {
            return (
                <ThemeOverrider override={themeOverride}>
                    {styledThor}
                </ThemeOverrider>
            )
        }

        const CNBCLogoSource =
            themeOverride === 'cnbc'
                ? require('../../logos/CNBCLogos/CNBC_Logo_White.svg')
                : require('../../logos/CNBCLogos/CNBC_Economist_Logo_White.svg')

        return (
            <>
                <a id={'cnbc'} style={{ position: 'absolute' }}></a>
                <ThemeOverrider override={themeOverride}>
                    <StyledFeatureContainer__Deprecated>
                        <WebLink to="/business">
                            <StyledCNBCLogo src={CNBCLogoSource} />
                        </WebLink>
                        <ComponentOverrider
                            override={() => sponsoredSectionHeaderOverride}
                        >
                            <StyledFeatureSectionHeader
                                heading="More Stories"
                                headingUrl={
                                    themeOverride === 'cnbc'
                                        ? '/business/cnbc'
                                        : '/business#cnbc'
                                }
                                showChevron
                                chevron={
                                    <StyledCircleChevronRight fillColor="currentColor" />
                                }
                            />
                        </ComponentOverrider>
                    </StyledFeatureContainer__Deprecated>
                    {styledThor}
                </ThemeOverrider>
            </>
        )
    }

    if (themeOverride === 'the-economist') {
        //We don't want to display anything if we don't have any content.
        if (!mainContent) {
            return (
                <ThemeOverrider override={themeOverride}>
                    {styledThor}
                </ThemeOverrider>
            )
        }

        const EconomistLogoSource = require('../../logos/CNBCLogos/Economist_Logo.svg')

        return (
            <ThemeOverrider override={themeOverride}>
                <StyledFeatureContainer__Deprecated>
                    <WebLink to="/business">
                        <StyledCNBCLogo src={EconomistLogoSource} />
                    </WebLink>
                    <ComponentOverrider
                        override={() => sponsoredSectionHeaderOverride}
                    >
                        <StyledFeatureSectionHeader
                            heading="More Stories"
                            headingUrl="/business/the-economist"
                            showChevron
                            chevron={
                                <StyledCircleChevronRight fillColor="currentColor" />
                            }
                        />
                    </ComponentOverrider>
                </StyledFeatureContainer__Deprecated>
                {styledThor}
            </ThemeOverrider>
        )
    }

    if (themeOverride === 'best-australian-yarn') {
        //We don't want to display anything if we don't have any content.
        if (!mainContent) {
            return (
                <ThemeOverrider override={themeOverride}>
                    {styledThor}
                </ThemeOverrider>
            )
        }

        const logo = require('../../logos/BestAustralianYarn/CurationLogo2024.png')

        const isTop14 = isFeatureEnabled(toFeatureState(toggles), 'BAY-top-14')

        const isTop50 = isFeatureEnabled(toFeatureState(toggles), 'BAY-top-50')

        let title = ''

        if (isTop14) {
            title = '2024 SHORT STORY WINNERS'
        } else if (isTop50) {
            title = 'TOP 50 SHORT STORY ENTRIES'
        } else {
            title = 'WINNERS ANNOUNCED NOVEMBER 22'
        }

        const link = isGenwest
            ? '/genwest/best-australian-yarn'
            : '/lifestyle/best-australian-yarn'

        return (
            <ThemeOverrider override={themeOverride}>
                <StyledFeatureContainer>
                    {!hideFeatureHeader && (
                        <StyledFeatureHeaderContainer>
                            <StyledFeatureTitleContainer>
                                <WebLink to={link}>
                                    <img
                                        src={logo}
                                        height="40"
                                        alt="Top 50 Short Stories"
                                        style={{
                                            display: 'block',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </WebLink>
                                <h2>{title}</h2>
                            </StyledFeatureTitleContainer>

                            <StyledClickthrough to={link}>
                                <StyledClickthroughText fontStyle="sans-serif">
                                    More Coverage
                                </StyledClickthroughText>
                                <StyledIconArrowCircle />
                            </StyledClickthrough>
                        </StyledFeatureHeaderContainer>
                    )}

                    {styledThor}
                </StyledFeatureContainer>
            </ThemeOverrider>
        )
    }

    if (themeOverride === 'kings-coronation') {
        return (
            <ThemeOverrider override={themeOverride}>
                <StyledFeatureContainer>
                    {!hideFeatureHeader && (
                        <StyledFeatureHeaderContainer
                            style={{
                                backgroundColor: colors.thewest.coronationRed,
                            }}
                        >
                            <WebLink
                                to="/news/king-charles-iii"
                                style={{ textDecoration: 'none' }}
                            >
                                <KingsCoronationTitleContainer>
                                    THE KING'S CORONATION
                                </KingsCoronationTitleContainer>
                            </WebLink>

                            <StyledFeatureSectionHeader
                                heading="More Stories"
                                headingUrl={'/news/king-charles-iii'}
                                showChevron
                            />
                        </StyledFeatureHeaderContainer>
                    )}
                    {styledThor}
                </StyledFeatureContainer>
            </ThemeOverrider>
        )
    }

    if (themeOverride === 'go-matildas') {
        return (
            <ThemeOverrider override={themeOverride}>
                <StyledFeatureContainer>
                    {!hideFeatureHeader && (
                        <StyledFeatureHeaderContainer
                            style={{
                                backgroundColor: colors.thewest.matildasGreen,
                            }}
                        >
                            <StyledGoMatildasLogoContainer>
                                <GoMatildasLogo />
                            </StyledGoMatildasLogoContainer>
                            <StyledFeatureSectionHeader
                                heading="Leave your message of support"
                                headingUrl={'/matildasmessage'}
                                showChevron
                            />
                        </StyledFeatureHeaderContainer>
                    )}
                    {styledThor}
                </StyledFeatureContainer>
            </ThemeOverrider>
        )
    }

    if (themeOverride === '190-years-of-the-west') {
        return (
            <ThemeOverrider override={themeOverride}>
                <StyledFeatureContainer unsetFeatureContainerBackground={false}>
                    <StyledFeatureHeaderContainer
                        style={{
                            backgroundColor:
                                tokens.thewest.colors.palette.anniversaryPurple,
                            fill: '#fff',
                            justifyContent:
                                layouts === '4-card' ? undefined : 'flex-end',
                        }}
                    >
                        {layouts === '4-card' && (
                            <>
                                <WebLink
                                    to="/news/190-years-of-the-west-australian"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <StyledTheWest190SmallLogo fill="#fff" />
                                </WebLink>

                                <StyledFeatureSectionHeader
                                    heading="More coverage"
                                    headingUrl={
                                        '/news/190-years-of-the-west-australian'
                                    }
                                    showChevron
                                />
                            </>
                        )}
                        {layouts !== '4-card' && (
                            <RoyHillLogo
                                logoHeight={22}
                                logoWidth={202}
                                fillColor={tokens.thewest.colors.palette.white}
                            />
                        )}
                    </StyledFeatureHeaderContainer>

                    {styledThor}
                </StyledFeatureContainer>
            </ThemeOverrider>
        )
    }

    if (themeOverride === 'commonwealth-games') {
        if (layouts === '4-card') {
            return (
                <ThemeOverrider override={themeOverride}>
                    <StyledFeatureContainer__Deprecated
                        hasExtraBottomMargin={true}
                    >
                        <StyledCommonwealthGamesContainer>
                            <StyledCommonwealthGamesText>
                                COMMONWEALTH &nbsp;
                                <br />
                                GAMES 2022
                            </StyledCommonwealthGamesText>
                        </StyledCommonwealthGamesContainer>
                        <StyledFeatureSectionHeaderContainer>
                            <StyledFeatureSectionHeader
                                heading="Full Coverage"
                                headingUrl="/sport/commonwealth-games"
                                showChevron
                                colorOverride={'#fff'}
                            />
                        </StyledFeatureSectionHeaderContainer>
                    </StyledFeatureContainer__Deprecated>
                    {styledThor}
                </ThemeOverrider>
            )
        } else if (layouts === '7-card') {
            const commonwealthGamesHeaders: LinkProps[] = [
                {
                    text: 'Sport',
                    href: '/sport',
                },
                {
                    text: 'Commonwealth Games',
                    href: '/sport/commonwealth-games',
                },
            ]

            const headingText =
                location.pathname === '/sport' ? (
                    <StyledGradientSectionTitle>
                        Commonwealth Games
                    </StyledGradientSectionTitle>
                ) : (
                    <>
                        <Breadcrumb
                            items={commonwealthGamesHeaders}
                            enableHeaderTag={true}
                            verticalSpacing={'md'}
                        />
                    </>
                )

            return (
                <React.Fragment>
                    <StyledGradientWrap
                        linearGradientColors={['#021487', '#6E259B', '#BD37AC']}
                    >
                        <StyledFeatureContainer__Deprecated>
                            {headingText}
                            {!hideHeadingLink && (
                                <StyledFeatureSectionHeader
                                    heading="Full Coverage"
                                    headingUrl="/sport/commonwealth-games"
                                    showChevron
                                    colorOverride={'#fff'}
                                />
                            )}
                        </StyledFeatureContainer__Deprecated>
                        {styledThor}
                    </StyledGradientWrap>
                    {hasBorder && <StyledFeatureBorder />}
                </React.Fragment>
            )
        }
    }

    return (
        <ThemeOverrider override={themeOverride}>{styledThor}</ThemeOverrider>
    )
}

Thor.displayName = 'ThorComposition'
