import styled from '@emotion/styled'
import { metrics } from '../__styling'
import { breakpoint } from '../__styling/style-functions/breakpoint'
import { calcRem } from '../__styling/style-functions/calc-rem'

export const StyledHeading = styled('h2')(({ theme }) => ({
    padding: calcRem(0, 24),
    fontSize: calcRem(40),
    fontWeight: 700,
    fontFamily: theme.fonts.heading,
    margin: calcRem(metrics.thenightly.margins.m2l, 0),
    letterSpacing: calcRem(-1.6),
    lineHeight: calcRem(24),
    overflowWrap: 'break-word',
    wordBreak: 'break-word',

    [breakpoint('sm')]: {
        display: 'none',
        padding: 0,
    },
}))

export const StyledActionBox = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',

    [breakpoint('sm')]: {
        flexDirection: 'row',
    },
})

export const StyledContainer = styled('div')(({ theme }) => ({
    padding: `0 ${calcRem(16)}`,

    [breakpoint('sm')]: {
        padding: 0,
    },
}))
