import { metrics } from '@news-mono/component-library'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../App.routing'

/**
 * Helper to create multiple ad units to display a mrec on mobile,
 * and billboard/leaderboards on tablet/desktop
 **/
export function billboardLeaderboardMrec(
    id: string,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    id: string,
    bottomPadding: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    billboardLeaderboardId: string,
    bottomPadding?: number,
): Array<CheckedComponentInformation>
export function billboardLeaderboardMrec(
    mrecId: string,
    billboardLeaderboardIdOrBottomPadding?: string | number,
    bottomPadding?: number,
): Array<CheckedComponentInformation> {
    const actualBottomPadding =
        typeof billboardLeaderboardIdOrBottomPadding === 'number'
            ? billboardLeaderboardIdOrBottomPadding
            : bottomPadding || metrics.thewest.margins.xl

    return [
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id:
                        billboardLeaderboardIdOrBottomPadding &&
                        typeof billboardLeaderboardIdOrBottomPadding ===
                            'string'
                            ? mrecId
                            : `mrec-${mrecId}`,
                    size: 'below768Mrec',
                },
                adType: 'inline',
            },
        }),
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                padding: [0, 0, actualBottomPadding, 0],
                slot: {
                    id:
                        billboardLeaderboardIdOrBottomPadding &&
                        typeof billboardLeaderboardIdOrBottomPadding ===
                            'string'
                            ? billboardLeaderboardIdOrBottomPadding
                            : `billboard-leaderboard-${mrecId}`,
                    size: 'leaderboardBillboard',
                },
                adType: 'inline',
            },
        }),
    ]
}
