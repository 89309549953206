import styled from '@emotion/styled'
import loadable from '@loadable/component'
import React from 'react'

export interface SponsoredTopicProps {
    sponsor?: 'bullsnbears'
    className?: string
}

const LogoPlaceholder = styled('div')({ width: '200px', height: '40px' })

export const LazyBullsNBearsLogo = loadable(() => import('./BullsNBearsLogo'), {
    fallback: <LogoPlaceholder />,
})

export const SponsoredTopic: React.FC<SponsoredTopicProps> = (props) => {
    if (props.sponsor === 'bullsnbears') {
        return <LazyBullsNBearsLogo className={props.className} />
    }

    return null
}
SponsoredTopic.displayName = 'SponsoredTopic'
