import {
    AdDefinition,
    AdEvent,
    AdState,
    ComponentServices,
    createRegisterableComponent,
} from '@news-mono/web-common'
import React from 'react'
import { useLocation } from 'react-router'
import { VideoHub } from '.'
import { AdUnitWrapper, CollectionRouteProps } from '..'
import { ThemeMargins } from '../__styling'

const getVideoInfoFromRouteResolution = (services: ComponentServices) => {
    if (services.resolution.type !== 'server') {
        throw new Error('Videos must be server resolved')
    }

    if (
        services.resolution.resolution.type !== 'video' &&
        services.resolution.resolution.type !== 'video-topic'
    ) {
        throw new Error(
            'Server resolution is not video, should not be rendering video',
        )
    }

    if (services.resolution.resolution.type === 'video-topic') {
        return {
            videoMeta: services.resolution.resolution.videoMeta,
        }
    }

    // collate resolved url slug video with related videos to return single video array
    const currentVideoMeta = services.resolution.resolution.videoMeta
    const relatedVideoMeta = services.resolution.resolution.relatedMeta
    const videoMeta = [currentVideoMeta].concat(relatedVideoMeta)

    return {
        videoMeta: videoMeta,
    }
}

export interface VideoHubRouteProps extends CollectionRouteProps {
    adUnitPath?: string
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isVideoWatchPage?: boolean
}

export const VideoHubRegistration = createRegisterableComponent(
    'video-hub',
    (props: VideoHubRouteProps, services) => {
        const videoData = getVideoInfoFromRouteResolution(services)
        const location = useLocation()
        const adStateDefinition: AdDefinition[] = [
            ...services.adState.definition,
            { id: 'videohub-mrec-one', size: 'above1280Mrec' },
            { id: 'mobile-videohub-mrec-one', size: 'below768Mrec' },
        ]
        const adState = {
            ...services.adState,
            definition: adStateDefinition,
        }

        return (
            <VideoHub
                item={videoData.videoMeta[0]}
                onEvent={services.onEvent}
                config={services.config}
                adUnitPath={String(props.adUnitPath)}
                currentPath={location.pathname}
                verticalGutters={props.verticalGutters}
                verticalSpacing={props.verticalSpacing}
                getVideoQueue={() => {
                    return new Promise((resolve) =>
                        resolve({
                            items: videoData.videoMeta,
                            type: 'topic-related',
                        }),
                    )
                }}
                isVideoWatchPage={props.isVideoWatchPage}
                adElement={
                    <VideoHubAdUnit
                        adState={adState}
                        onEvent={services.onEvent}
                    />
                }
            />
        )
    },
)

const VideoHubAdUnit: React.FC<{
    adState: AdState
    onEvent: (event: AdEvent) => void
}> = ({ adState, onEvent }) => {
    return (
        <>
            <AdUnitWrapper
                adState={adState}
                unitId={'videohub-mrec-one'}
                onEvent={onEvent}
                hiddenUntilLoaded={false}
                noticePosition={'none'}
                adType={'inline'}
            />
            <AdUnitWrapper
                adState={adState}
                unitId={'mobile-videohub-mrec-one'}
                onEvent={onEvent}
                hiddenUntilLoaded={false}
                noticePosition={'none'}
                adType={'inline'}
            />
        </>
    )
}
