import React from 'react'
import {
    StyledLabelContainer,
    StyledLabelHeadingMain,
    StyledLabelWrapper,
} from './WidgetsLabel.styled'

export const WidgetsLabel: React.FunctionComponent = ({}) => {
    return (
        <StyledLabelWrapper>
            <StyledLabelContainer>
                <div>
                    <StyledLabelHeadingMain>SEATS WON</StyledLabelHeadingMain>
                    <span>+ SEATS LIKELY</span>
                </div>
            </StyledLabelContainer>
        </StyledLabelWrapper>
    )
}
