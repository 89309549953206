import {
    FeatureState,
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { RendererMiddleware } from 'json-react-layouts'
import React, { PropsWithChildren } from 'react'
import { useToggleState } from '../feature-togglings/features'
import { Features } from '../features'
import { ComponentServices } from '../routing/page-definition'

export interface FeatureConfig {
    /**
     * If the component should be conditionally rendered according to a feature toggle.
     * If you wish for the component to rendered when the toggle is disabled,
     * set `invertFeatureToggle` to true.
     */
    feature?: Features
    /**
     * If the component should only be rendered when the feature is disabled.
     */
    invertFeatureToggle?: boolean
}

export function isComponentEnabled(
    featureState: FeatureState,
    { feature, invertFeatureToggle }: FeatureConfig,
) {
    // No toggle, always render.
    if (!feature) return true

    if (invertFeatureToggle) {
        // If inverted, render when disabled.
        return !isFeatureEnabled(featureState, feature)
    } else {
        // Otherwise only render when enabled.
        return isFeatureEnabled(featureState, feature)
    }
}

export function FeatureEnabled({
    children,
    feature,
    invertFeatureToggle,
}: PropsWithChildren<FeatureConfig>) {
    const isEnabled = isComponentEnabled(toFeatureState(useToggleState()), {
        feature,
        invertFeatureToggle,
    })
    if (!isEnabled) {
        return null
    }

    return <React.Fragment>{children}</React.Fragment>
}

export const componentFeatureToggleMiddleware: RendererMiddleware<
    ComponentServices,
    FeatureConfig
> = (componentProps, middlewareProps, services, next) => {
    if (middlewareProps.feature) {
        return (
            <FeatureEnabled
                feature={middlewareProps.feature}
                invertFeatureToggle={middlewareProps.invertFeatureToggle}
            >
                {next(componentProps, middlewareProps, services)}
            </FeatureEnabled>
        )
    }

    return next(componentProps, middlewareProps, services)
}
