import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { calcRem, fonts } from '../../../../__styling'

export const StyledReadersAlsoLoveHeading = styled('h2')<{
    border: boolean | undefined
}>(({ theme, border }) => ({
    fontFamily: fonts.thewest.sansSerifCond,
    fontSize: calcRem(28),
    marginBottom: 0,
    borderWidth: calcRem(1),
    borderColor: '#74787A',
    borderTopStyle: border ? 'solid' : undefined,
    paddingTop: border
        ? theme.kind === Product.TheWest
            ? calcRem(32)
            : calcRem(24)
        : undefined,
}))
