import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling'
import { GenericInArticleBannerProps } from './GenericInArticleBanner'

const LazyGeneralInArticleBannerComponent = loadable(
    () => import('./GenericInArticleBanner'),
)

export const LazyGeneralInArticleBanner = (
    props: GenericInArticleBannerProps,
) => {
    if (!props.feature) {
        return <LazyGeneralInArticleBannerComponent {...props} />
    }

    return (
        <FeatureToggle
            feature={props.feature}
            on={() => <LazyGeneralInArticleBannerComponent {...props} />}
        />
    )
}
